<div
  class="modal-container"
  [ngClass]="'modal-' + this.modalStyle"
  efpKeyboardShortcut
  (escShortcut)="dismissModalWithAction()">
  <div class="title-container">
    <ion-icon [src]="this.iconPath" *ngIf="this.iconPath" class="iconSize24"></ion-icon>
    <div class="modal-title">{{ title }}</div>
  </div>

  <div class="d-flex-col gap-16 inner-wrapper">
    <div class="description-container" *ngIf="text">
      {{ text }}
    </div>

    <ng-container
      *ngComponentOutlet="dynamicComponent; inputs: dynamicComponentProps"></ng-container>

    <div class="btn-container" *ngIf="cancelButtonLabel || confirmButtonLabel">
      <ion-button
        shape="round"
        *ngIf="cancelButtonLabel"
        (click)="dismissModalWithAction(modalAction.CANCEL)"
        fill="outline"
        expand="block"
        data-test-selector="simple-modal-cancel"
        >{{ cancelButtonLabel }}
      </ion-button>
      <ion-button
        shape="round"
        *ngIf="criticalActionButtonLabel"
        (click)="dismissModalWithAction(modalAction.CRITICAL_ACTION)"
        expand="block"
        color="danger"
        fill="outline"
        data-test-selector="simple-modal-critical-action"
        >{{ criticalActionButtonLabel }}
      </ion-button>
      <ion-button
        shape="round"
        *ngIf="confirmButtonLabel"
        (click)="dismissModalWithAction(modalAction.CONFIRM)"
        fill="solid"
        expand="block"
        data-test-selector="simple-modal-submit"
        [disabled]="confirmButtonDisabled"
        >{{ confirmButtonLabel }}</ion-button
      >
    </div>
  </div>
</div>
