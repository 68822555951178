import { OnboardingHintSeries } from '../../models/onboarding/onboarding-hint'
import { OnboardingHintSeriesKey } from '../../models/onboarding/onboarding-hint-series-key'

export const ONBOARDING_PLANNER_FIRST_STEPS_WEB: OnboardingHintSeries = {
  id: OnboardingHintSeriesKey.PLANNER_FIRST_STEPS_WEB,
  hints: [
    {
      title: 'ONBOARDING.PLANNER_FIRST_STEPS.WEB.CREATE_WALL.TITLE',
      textDescriptions: ['ONBOARDING.PLANNER_FIRST_STEPS.WEB.CREATE_WALL.TEXT_DESCRIPTION'],
      videoUrl: 'assets/videos/1-DrawingMode.mp4',
      steps: [
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.CREATE_WALL.STEP_1',
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.CREATE_WALL.STEP_2',
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.CREATE_WALL.STEP_3',
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.CREATE_WALL.STEP_4',
      ],
    },
    {
      title: 'ONBOARDING.PLANNER_FIRST_STEPS.WEB.EDIT_WALL.TITLE',
      textDescriptions: [
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.EDIT_WALL.TEXT_DESCRIPTION_1',
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.EDIT_WALL.TEXT_DESCRIPTION_2',
      ],
      videoUrl: 'assets/videos/2-EditMode.mp4',
      steps: [
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.EDIT_WALL.STEP_1',
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.EDIT_WALL.STEP_2',
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.EDIT_WALL.STEP_3',
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.EDIT_WALL.STEP_4',
      ],
    },
    {
      title: 'ONBOARDING.PLANNER_FIRST_STEPS.WEB.UNDO.TITLE',
      textDescriptions: [],
      videoUrl: 'assets/videos/3-UndoRedoDelete.png',
      steps: [
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.UNDO.STEP_1',
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.UNDO.STEP_2',
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.UNDO.STEP_3',
      ],
    },
    {
      title: 'ONBOARDING.PLANNER_FIRST_STEPS.WEB.ZOOM.TITLE',
      videoUrl: 'assets/videos/4-PanZoomFocus.mp4',
      steps: [
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.ZOOM.STEP_1',
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.ZOOM.STEP_2',
        'ONBOARDING.PLANNER_FIRST_STEPS.WEB.ZOOM.STEP_3',
      ],
    },
  ],
}

export const ONBOARDING_PLANNER_FIRST_STEPS_NATIVE: OnboardingHintSeries = {
  id: OnboardingHintSeriesKey.PLANNER_FIRST_STEPS_NATIVE,
  hints: [
    {
      title: 'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.CREATE_WALL.TITLE',
      textDescriptions: ['ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.CREATE_WALL.TEXT_DESCRIPTION'],
      videoUrl: 'assets/videos/1-DrawingMode-S.mp4',
      steps: [
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.CREATE_WALL.STEP_1',
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.CREATE_WALL.STEP_2',
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.CREATE_WALL.STEP_3',
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.CREATE_WALL.STEP_4',
      ],
    },
    {
      title: 'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.EDIT_WALL.TITLE',
      textDescriptions: [
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.EDIT_WALL.TEXT_DESCRIPTION_1',
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.EDIT_WALL.TEXT_DESCRIPTION_2',
      ],
      videoUrl: 'assets/videos/2-EditMode-S.mp4',
      steps: [
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.EDIT_WALL.STEP_1',
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.EDIT_WALL.STEP_2',
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.EDIT_WALL.STEP_3',
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.EDIT_WALL.STEP_4',
      ],
    },
    {
      title: 'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.UNDO.TITLE',
      textDescriptions: [],
      videoUrl: 'assets/videos/3-UndoRedoDelete-S.png',

      steps: [
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.UNDO.STEP_1',
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.UNDO.STEP_2',
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.UNDO.STEP_3',
      ],
    },
    {
      title: 'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.ZOOM.TITLE',
      videoUrl: 'assets/videos/4-PanZoomFocus-S.mp4',
      steps: [
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.ZOOM.STEP_1',
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.ZOOM.STEP_2',
        'ONBOARDING.PLANNER_FIRST_STEPS.NATIVE.ZOOM.STEP_3',
      ],
    },
  ],
}
