import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { ONBOARDING_CONFIG } from '../../../constants/onboarding/onboarding-config'
import { OnboardingHintSeriesKey } from '../../../models/onboarding/onboarding-hint-series-key'
import { OnboardingService } from '../../../services/onboarding.service'
import { Subject, takeUntil } from 'rxjs'
import { OnboardingHintTemplateComponent } from './onboarding-hint-template/onboarding-hint-template.component'

@Component({
  selector: 'efp-onboarding-hints',
  templateUrl: './onboarding-hints.component.html',
})
export class OnboardingHintsComponent implements OnInit, OnDestroy {
  @Input() onboardingHintSeriesKey!: OnboardingHintSeriesKey

  private readonly pageLeave$ = new Subject<void>()

  constructor(
    private readonly onboardingService: OnboardingService,
    private readonly modalCtrl: ModalController
  ) {}

  ngOnInit(): void {
    this.onboardingService
      .getTriggerCondition(this.onboardingHintSeriesKey)
      .pipe(takeUntil(this.pageLeave$))
      .subscribe(() => {
        void this.handleOnboardingTriggered()
      })
  }

  public async createOnboardingModal(): Promise<void> {
    const hints = ONBOARDING_CONFIG.find((c) => c.id === this.onboardingHintSeriesKey)?.hints

    const modal = await this.modalCtrl.create({
      component: OnboardingHintTemplateComponent,
      backdropDismiss: false,
      cssClass: 'soft-edges-modal',
      componentProps: { hints },
    })
    await modal.present()
    await modal.onDidDismiss()
  }

  ngOnDestroy(): void {
    this.pageLeave$.next()
  }

  private async handleOnboardingTriggered(): Promise<void> {
    const hasBeenShown = await this.onboardingService.hasAlreadyBeenShownToTheUser(
      this.onboardingHintSeriesKey
    )
    if (!hasBeenShown) {
      await this.onboardingService.trackOnboarding(this.onboardingHintSeriesKey)
      await this.createOnboardingModal()
    }
  }
}
