import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core'
import { of } from 'rxjs'
import { checkIfMobileLayout } from '../../../constants/layout'

@Component({
  selector: 'efp-scrollbar',
  templateUrl: './scrollbar.component.html',
  styleUrls: ['./scrollbar.component.scss'],
})
export class ScrollbarComponent implements AfterViewInit {
  @Input() useBottomSafeArea = false

  public isCalculated$ = of(false)
  @ViewChild('scrollbar') scrollbarContainer!: ElementRef
  @ViewChild('shadowOverlayRight') shadowOverlayRight!: ElementRef
  @ViewChild('shadowOverlayLeft') shadowOverlayLeft!: ElementRef
  @ViewChild('scrollRow') scrollRow!: ElementRef

  constructor() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.manageScrollbarFog()
    }, 50)

    const resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        this.manageScrollbarFog()
      }, 50)
    })

    const scrollbarEl = this.scrollbarContainer.nativeElement
    resizeObserver.observe(scrollbarEl)
  }

  /*
  creates a shadow within scrollbar, necessary for knowing there are
  more steps hidden in scrollbar */
  manageScrollbarFog(): void {
    const el: HTMLElement = this.scrollbarContainer?.nativeElement
    const rect = el.getBoundingClientRect()
    const shadowLeft = this.shadowOverlayLeft.nativeElement
    const shadowRight = this.shadowOverlayRight.nativeElement
    if (checkIfMobileLayout()) {
      if (rect) {
        if (this.shadowOverlayLeft && this.shadowOverlayRight) {
          if (rect?.left < 0) {
            shadowLeft.classList.add('visible')
          } else shadowLeft.classList.remove('visible')
          if (window.innerWidth - rect?.right < 0) {
            shadowRight.classList.add('visible')
          } else shadowRight.classList.remove('visible')
        }
      }
    } else {
      shadowLeft.classList.remove('visible')
      shadowRight.classList.remove('visible')
    }
  }

  scrollToRight(): void {
    const el: HTMLElement = this.scrollRow?.nativeElement
    el.scrollLeft += 200
  }
}
