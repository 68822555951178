import { Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import JSZip from 'jszip'
import { ErrorPopupService } from './error-popup.service'
import { FavouritesService } from './favourites.service'
import { FileService } from './file.service'
import { EfpImportService } from './share/efp-import.service'
import { StockService } from './stock.service'
import { Translation } from './translation.service'
import { ZoomAndPanService } from './zoom-and-pan.service'
import { ProjectRepository } from '../repositories/project.repository'
import { StockRepository } from '../repositories/stock.repository'
import { firstValueFrom } from 'rxjs'

export enum ImportFileType {
  efp,
  ma7,
  csv,
  favefp,
  fav,
}

@Injectable({
  providedIn: 'root',
})
export class FileHandlerService {
  private fileToHandle: string | null = null

  constructor(
    private readonly fileService: FileService,
    private readonly stockService: StockService,
    private readonly router: Router,
    private readonly ngZone: NgZone,
    private readonly efpImportService: EfpImportService,
    private readonly errorPopupService: ErrorPopupService,
    private readonly translation: Translation,
    private readonly favouritesService: FavouritesService,
    private readonly zoomAndPanService: ZoomAndPanService,
    private readonly stockRepository: StockRepository,
    private readonly projectRepository: ProjectRepository
  ) {}

  // - Static methods

  public static getFileType(file: ArrayBuffer, url: string): ImportFileType | null {
    const uintArray = new Uint8Array(file)

    // file has zip header: PK\x03\x04
    if (
      uintArray.length > 4 &&
      String.fromCharCode(uintArray[0], uintArray[1]) === 'PK' &&
      uintArray[2] === 3 &&
      uintArray[3] === 4
    ) {
      return ImportFileType.efp
    }

    const text = new TextDecoder('UTF-8').decode(file).trim()
    if (FileHandlerService.isJSON(text) || url.endsWith('.favefp')) {
      return ImportFileType.favefp
    } else if (
      text.startsWith('<?xml version="1.0" encoding="utf-8"?><Schalungsstile>') ||
      url.endsWith('.fav')
    ) {
      return ImportFileType.fav
    } else if (text.startsWith('<?xml') || text.startsWith('<TiposDocument>')) {
      return ImportFileType.ma7
    } else if (FileHandlerService.isCsvFormat(text)) {
      return ImportFileType.csv
    }

    return null
  }

  public static isJSON(text: string): boolean {
    try {
      JSON.parse(text)
      return true
    } catch (e: unknown) {
      return false
    }
  }

  private static isCsvFormat(text: string): boolean {
    const lines = text.split(/\r?\n/)

    const isValidCsvLine = (line: string): boolean =>
      line.startsWith('#') || line.indexOf(';') !== -1

    return lines.map((line) => line.trim()).every((line) => isValidCsvLine(line))
  }

  // - Public methods

  public registerFile(url: string): void {
    this.fileToHandle = url
  }

  public async handleFile(): Promise<void> {
    if (this.fileToHandle == null) {
      return Promise.resolve()
    }

    const url = this.fileToHandle
    this.fileToHandle = null
    const file = await this.fileService.readGlobalFile(url)
    const type = FileHandlerService.getFileType(file, url)

    switch (type) {
      case ImportFileType.csv:
      case ImportFileType.ma7:
        await this.importStock(file, type)
        break
      case ImportFileType.efp:
        await this.importEfpPlan(file)
        break
      case ImportFileType.favefp:
        await this.importFavourite(file, url)
        break
      case ImportFileType.fav:
        await this.importFavourite(file, url)
        break
      default:
        const message = this.translation.translate('IMPORT.ERROR.UNSUPPORTED_FILE')
        await this.errorPopupService.showError(message)
    }
  }

  // - Private methods

  private async importStock(file: ArrayBuffer, type: ImportFileType): Promise<void> {
    const text = new TextDecoder('UTF-8').decode(file)
    const name = type === ImportFileType.ma7 ? 'stock.ma7' : 'stock.csv'

    // Currently not using optimistic creation here, to avoid create/update articles with temp ids
    const success = await this.stockService.importStockListFromFile(text, name, false)

    if (success) {
      await this.stockRepository.fetchAll()
      const id = await firstValueFrom(this.stockRepository.stocks$).then(
        (stocks) => stocks[stocks.length - 1].id
      )
      await this.ngZone.run(async () => this.router.navigate(['/stockdetail', id]))
    }
  }

  private async importFavourite(file: ArrayBuffer, url: string): Promise<void> {
    const favouriteId = await this.favouritesService.importFavouriteFromData(file, url)

    if (favouriteId) {
      await this.ngZone.run(async () => {
        await this.router.navigate(['/favourites-overview'])
      })
    }
  }

  private async importEfpPlan(file: ArrayBuffer): Promise<void> {
    const zip = await JSZip.loadAsync(file, { base64: false })
    const defaultProject = await this.projectRepository.findOne(1, false)
    if (!defaultProject) {
      throw new Error('FileHandlerService.importEfpPlan: default project not found')
    }

    try {
      const plan = await this.efpImportService.importEfpPlanFileWithLoadingSpinner(
        zip,
        defaultProject.id
      )
      this.beforeOpeningPlan()
      await this.ngZone.run(async () => {
        await this.router.navigateByUrl('/' + plan.currentStep + '/' + plan.id, {
          replaceUrl: true,
        })
      })
    } catch (e: unknown) {
      if (e instanceof Error) {
        const message = this.translation.translate(e.message)
        await this.errorPopupService.showError(message)
      }
    }
  }

  private beforeOpeningPlan(): void {
    this.zoomAndPanService.reset()
  }
}
