import { NgModule } from '@angular/core'
import { PlannerComponent } from './components'
import { CommonModule } from '@angular/common'
import { ScaleBarComponent } from './components/scale-bar/scale-bar.component'
import { WatchParentDimensionsDirective } from './directives'

const PUBLIC_COMPONENTS = [PlannerComponent, WatchParentDimensionsDirective]

@NgModule({
  imports: [CommonModule],
  declarations: [PUBLIC_COMPONENTS, ScaleBarComponent],
  exports: [PUBLIC_COMPONENTS],
})
export class FormworkPlannerModule {}
