<div class="mobile-container">
  <div class="d-flex p-top-12">
    <ion-label class="sub-title">{{
      'FAVOURITES.PROFILE.LIST_SOLUTIONS_HEADING' | translate
    }}</ion-label>
  </div>

  <p class="header-description">
    <ion-note>
      {{ 'FAVOURITES.PLANSETTINGS.FAVOURITES_CALC_HINT' | translate }}
    </ion-note>
  </p>
</div>

<div *ngIf="this.selectedTypes" class="list">
  <ng-container *ngFor="let type of this.selectedTypes">
    <efp-list-tile-styling>
      <div class="d-flex-col gap-8">
        <div class="mobile-container">
          <ion-label class="label">
            {{ type.ID | favTranslate }}
          </ion-label>
        </div>
        <div class="mobile-container full-width">
          <ion-item lines="none" button="true" (click)="navToFavouriteDetail(type.ID)">
            <div class="d-flex flex-1 gap-8">
              <efp-thumbnail-action-item
                class="basis-56"
                [title]="type.ID | favTranslate"
                [imgSrc]="
                  'https://azwestprodesdcdnapps.blob.core.windows.net/efpcont/live/FavouritesImages/' +
                  type.Loesung[0].ID +
                  '.png?sp=rl&st=2021-09-15T09:21:34Z&se=2025-01-01T18:21:34Z&spr=https&sv=2020-08-04&sr=c&sig=mZ%2By7AAFFsTnAm%2BJrMp9SZ7u1VqLUylAHC7hMHrq%2B5A%3D'
                "></efp-thumbnail-action-item>
              <div class="flex-6 d-flex-col title-subtitle-container" color="primary">
                <div class="primary-title">
                  {{ type.Loesung[0].ID | favTranslate }}
                </div>
                <efp-badge [isActive]="true">
                  {{ 'FAVOURITES.PROFILE.SOLUTIONS.RANK_FAVOURED' | translate }}
                </efp-badge>
              </div>
              <div class="icon-container">
                <ion-icon src="/assets/icon/ic_edit.svg" color="primary"> </ion-icon>
              </div>
            </div>
          </ion-item>
        </div>
      </div>
    </efp-list-tile-styling>
  </ng-container>
  <div class="d-flex link-container p-bottom-12">
    <ion-button fill="clear" (click)="navToFavouriteDetail()">
      <span>{{ 'FAVOURITES.PROFILE.SOLUTIONS.SHOW_ALL' | translate }}</span>
      <ion-icon slot="end" src="/assets/icon/ic_arrow-right-stroke.svg" color="primary"> </ion-icon>
    </ion-button>
  </div>
</div>
