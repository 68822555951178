import { Component, Input } from '@angular/core'

@Component({
  selector: 'efp-thumbnail-preview',
  templateUrl: './thumbnail-preview.component.html',
  styleUrls: ['./thumbnail-preview.component.scss'],
})
export class ThumbnailPreviewComponent {
  constructor() {}

  @Input() title?: string
  @Input() artNr?: string
  @Input() imgSrc?: string
}
