import { Component, Input } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { Clipboard } from '@capacitor/clipboard'

@Component({
  selector: 'efp-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  constructor(private readonly modalCtrl: ModalController) {}

  public copiedToClipboard = false

  @Input() header?: string
  @Input() message?: string
  @Input() email?: string

  async copyToClipboard(): Promise<void> {
    await Clipboard.write({
      string: this.email,
    })

    this.copiedToClipboard = true
    setTimeout(() => {
      this.copiedToClipboard = false
    }, 5000)
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }
}
