<div class="d-flex-col gap-20">
  <ng-container *ngFor="let uploadProps of allUploadProperties">
    <div class="d-flex-col gap-4">
      <div class="d-flex justify-between title-wrapper">
        <div class="d-flex gap-8 align-center">
          <div class="data-title">
            {{ 'NATIVE_TO_BACKEND_MIGRATION.' + uploadProps.name | translate }}
          </div>
          <div>({{ uploadProps.uploadCount.value }}/{{ uploadProps.maxSize }})</div>
        </div>
        <ion-icon
          src="assets/icon/ic_success.svg"
          class="iconSize24"
          *ngIf="getProgress(uploadProps) == 1"></ion-icon>
      </div>
      <div class="d-flex gap-4 align-center progress-container">
        <ion-progress-bar
          [value]="getProgress(uploadProps)"
          *ngIf="getProgress(uploadProps) < 1"></ion-progress-bar>
      </div>
    </div>
  </ng-container>
</div>
