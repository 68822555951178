import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { DeployEnvironment } from './app/models/deploy-environment'
import { environment } from './environments/environment'

if (environment.deployment !== DeployEnvironment.LOCAL) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
