import { Injectable, NgZone } from '@angular/core'
import paper from 'paper/dist/paper-core'
import { Observable, ReplaySubject, Subject } from 'rxjs'
import { PlannerInteractionEvent } from '../model'
import { Margins } from './zoom.service'
import { Capacitor } from '@capacitor/core'

/**
 * Provides access to the current paper scope, once initialized
 */
@Injectable()
export class PlannerStateService {
  /*
   * The scope need to be initialized via the init method, since Paper.js can only be initialized when the canvas
   * element is fully available. However, since there's no point of having this service without Paper.js we don't mark
   * the paper scope optional to avoid excessive null checks.
   */
  paper!: paper.PaperScope

  private tool: paper.Tool | null = null
  private centerViewFunction?: (modelBounds: paper.Rectangle, margins?: Margins) => void
  private setZoomAndPanFunction?: (zoomInMeters: number, pan?: paper.Point) => void

  private readonly interactionDown = new Subject<PlannerInteractionEvent>()
  private readonly interactionDragged = new Subject<PlannerInteractionEvent>()
  private readonly interactionUp = new Subject<PlannerInteractionEvent>()
  private readonly interactionMoved = new Subject<PlannerInteractionEvent>()
  private readonly ready = new ReplaySubject<void>(1)

  private middleMouseButtonAsPrimary = false

  get interactionDown$(): Observable<PlannerInteractionEvent> {
    return this.interactionDown
  }

  get interactionDragged$(): Observable<PlannerInteractionEvent> {
    return this.interactionDragged
  }

  get interactionUp$(): Observable<PlannerInteractionEvent> {
    return this.interactionUp
  }

  get interactionMoved$(): Observable<PlannerInteractionEvent> {
    return this.interactionMoved
  }

  get ready$(): Observable<void> {
    return this.ready
  }

  constructor(private readonly ngZone: NgZone) {
    this.monkeyPatchZoom()
  }

  /**
   * Initializes the Paper JS library inside a canvas and sub-components.
   * @param drawCanvas The target canvas to use for rendering.
   * @param centerViewFunction Function to center the view on given bounds. This is a slight workaround so the
   *   planner component can have complete control over zooming and panning,
   *   but the center function is also available from outside the component if needed.
   * @param setZoomAndPanFunction Function to set the zoom to the given scale and optionally center on a given point
   * @param initToolEvents Boolean value deciding if tool events should be initialized (or the canvas is just read only)
   * @return a controller object to expose planner interactions via this service.
   */
  init(
    drawCanvas: HTMLCanvasElement,
    centerViewFunction: (modelBounds: paper.Rectangle, margins?: Margins) => void,
    setZoomAndPanFunction: (zoomInMeters: number, pan?: paper.Point) => void,
    initToolEvents: boolean = true,
    middleMouseButtonAsPrimary: boolean = false
  ): void {
    // Prevent browser right-click menu from popping up when interacting with canvas
    drawCanvas.addEventListener('contextmenu', (e) => e.preventDefault())

    this.middleMouseButtonAsPrimary = middleMouseButtonAsPrimary

    this.paper = new paper.PaperScope()
    this.paper.setup(drawCanvas)
    this.paper.settings.insertItems = false
    this.paper.activate()
    this.centerViewFunction = centerViewFunction
    this.setZoomAndPanFunction = setZoomAndPanFunction

    if (initToolEvents) {
      this.tool = new this.paper.Tool()
      // Setting min distance ensure we don't need to constantly check if we moved enough in any dragging related code
      this.tool.minDistance = 5
      this.tool.onMouseDown = (e: paper.ToolEvent) => this.onMouseDown(e)
      this.tool.onMouseDrag = (e: paper.ToolEvent) => this.onMouseDragged(e)
      this.tool.onMouseUp = (e: paper.ToolEvent) => this.onMouseUp(e)
      this.tool.onMouseMove = (e: paper.ToolEvent) => this.onMouseMove(e)
    }
    this.ready.next()
  }

  resetPaper(): void {
    if (this.paper.view) {
      this.paper.view.remove()
      this.paper.project.remove()
      this.tool?.remove()
      this.tool = null
    }
  }

  /**
   * Center the view on the given bounds.
   */
  centerView(modelBounds: paper.Rectangle, margins?: Margins): void {
    if (this.centerViewFunction) {
      this.centerViewFunction(modelBounds, margins)
    }
  }

  /**
   * setZoom Level and optionally Center the view on the given point.
   */
  setZoomAndPan(zoomInMeters: number, pan?: paper.Point): void {
    if (this.setZoomAndPanFunction) {
      this.setZoomAndPanFunction(zoomInMeters, pan)
    }
  }

  /**
   * Creates a new named layer and ensures it is added to the current project scope.
   * @param name The name of the layer
   */
  createLayer(name: string): paper.Layer {
    const layer = new paper.Layer()
    layer.name = name
    this.paper.project.addLayer(layer)

    return layer
  }

  /**
   * This monkey patches the getZoom function of the view in order to support
   * negative scaling, which is used to flip all coordinates around to support
   * cartesian coordinate rendering.
   *
   * This is needed due to the following open issue:
   * https://github.com/paperjs/paper.js/issues/1893
   */
  private monkeyPatchZoom(): void {
    const viewProto = paper.View.prototype
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    viewProto.getZoom = function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const scaling = (this as never)._decompose().scaling
      return (Math.abs(scaling.x) + Math.abs(scaling.y)) / 2
    }
    Object.defineProperty(viewProto, 'zoom', {
      get() {
        return this.getZoom()
      },
      set(value) {
        this.setZoom(value)
      },
    })
  }

  private onMouseDown(event: paper.ToolEvent): void {
    this.ngZone.run(() => this.interactionDown.next(this.determinePlannerMouseEvent(event)))
  }

  private onMouseDragged(event: paper.ToolEvent): void {
    this.ngZone.run(() => this.interactionDragged.next(this.determinePlannerMouseEvent(event)))
  }

  private onMouseUp(event: paper.ToolEvent): void {
    this.ngZone.run(() => this.interactionUp.next(this.determinePlannerMouseEvent(event)))
  }

  private onMouseMove(event: paper.ToolEvent): void {
    this.ngZone.run(() => this.interactionMoved.next(this.determinePlannerMouseEvent(event)))
  }

  private determinePlannerMouseEvent(event: paper.ToolEvent): PlannerInteractionEvent {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let rawEvent = (event as never).event
    let primaryButton: boolean

    rawEvent = Capacitor.isNativePlatform() ? (rawEvent as TouchEvent) : (rawEvent as PointerEvent)

    if (rawEvent instanceof PointerEvent) {
      primaryButton = event.type === 'mouseup'
    } else if (rawEvent.touches !== undefined) {
      if (event.type === 'mouseup') {
        primaryButton = rawEvent.touches.length === 0
      } else {
        primaryButton = rawEvent.touches.length === 1
      }
    } else {
      if (event.type === 'mousedrag') {
        primaryButton =
          rawEvent.buttons === 1 || (this.middleMouseButtonAsPrimary && rawEvent.buttons === 4)
      } else {
        primaryButton =
          rawEvent.button === 0 || (this.middleMouseButtonAsPrimary && rawEvent.button === 1)
      }
    }

    return {
      get targetItem(): paper.Item | null {
        return event.item
      },
      get dragDirection(): paper.Point {
        return event.point.subtract(event.downPoint)
      },
      primaryInteraction: primaryButton,
      downPoint: event.downPoint,
      point: event.point,
    }
  }
}
