export const SNAP_FALLBACK_ANGLE_INTERVAL = 1

export const MIN_SNAP_ANGLE_INTERVAL = 5
export const MAX_SNAP_ANGLE_INTERVAL = 90

export const SNAP_LENGTH_INTERVAL = 10
export const MIN_SNAP_LENGTH_INTERVAL = 5
export const MAX_SNAP_LENGTH_INTERVAL = 100
export const SNAP_LENGTH_INTERVAL_IMPERIAL = 7.62
export const MIN_SNAP_LENGTH_INTERVAL_IMPERIAL = 5.08
export const MAX_SNAP_LENGTH_INTERVAL_IMPERIAL = 121.92

export const WALL_LENGTH_MIN = 1
export const WALL_LENGTH_MAX = 10000
export const SLAB_LENGTH_MIN = 1
export const SLAB_LENGTH_MAX = 10000

export const DEF_WALL_THICKNESS = 20
export const MIN_WALL_THICKNESS = 12
export const MAX_WALL_THICKNESS = 40

export const DEF_WALL_THICKNESS_IMPERIAL = 20.32
export const MIN_WALL_THICKNESS_IMPERIAL = 12.7
export const MAX_WALL_THICKNESS_IMPERIAL = 40.005

export const DEF_WALL_HEIGHT = 260
export const MIN_WALL_HEIGHT = 10
export const DEF_WALL_HEIGHT_IMPERIAL = 243.84
export const MIN_WALL_HEIGHT_IMPERIAL = 10.16

export const DEF_SLAB_THICKNESS = 20
export const MIN_SLAB_THICKNESS = 12
export const MAX_SLAB_THICKNESS = 30
export const DEF_SLAB_THICKNESS_IMPERIAL = 25.4
export const MIN_SLAB_THICKNESS_IMPERIAL = 12.7
export const MAX_SLAB_THICKNESS_IMPERIAL = 30.48

export const DEF_SLAB_HEIGHT = 280
export const MIN_SLAB_HEIGHT = 220 // Tipos Bug, height < 220cm -> error
export const MAX_SLAB_HEIGHT = 550
export const DEF_SLAB_HEIGHT_IMPERIAL = 269.24
export const MIN_SLAB_HEIGHT_IMPERIAL = 220.345
export const MAX_SLAB_HEIGHT_IMPERIAL = 548.65

export const ACCESSORY_LINE_CLICK_DISTANCE = 20
export const CYCLE_CROSSHAIR_CLICK_DISTANCE = 50
export const CYCLE_CROSSHAIR_SNAPPING_DISTANCE = 30
export const CYCLE_BOUNDARIES_MIN_DISTANCE = 100
export const HIGHLIGHTED_STROKE_WIDTH = 3
export const ACCESSORY_STROKE_WIDTH = 1
export const CENTER_POINT_LENGTH = 20
export const ACCESSORY_DASHED_ARRAY = [2, 1]
export const ACCESSORY_DASHED_ARRAY_HOVER = [8, 8]
export const ACCESSORY_ZOOM_INCREASE_FACTOR = 1
export const ACCESSORY_LINE_HOVER_AREA = 15

export const SCALE_BAR_OFFSET_TOP = 29
export const ZOOM_LABEL_OFFSET = 40
export const NAVBAR_HEIGHT = 56
export const HAS_MOVED_SUFFICIENTLY_TRESHOLD = 5

export const AMOUNT_OF_RECENT_PLANS = 10

export const ROUNDING_THRESHOLD = 1e-10
