import { createStore } from '@ngneat/elf'
import { getAllEntities, selectAllEntities, setEntities, withEntities } from '@ngneat/elf-entities'
import { Injectable } from '@angular/core'
import { AdvertisementModel } from '../../generated/efp-api'
import { AdvertisementDao, TargetPlatform } from '../services/dao/advertisement.dao'
import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import { asyncFilter } from '../utils/asyncFilter'

const advertisementsStore = createStore(
  { name: 'advertisements' },
  withEntities<AdvertisementModel>()
)

const ADS_TIMEOUT_MS = 24 * 60 * 60 * 1000

@Injectable({
  providedIn: 'root',
})
export class AdvertisementRepository {
  public readonly advertisements$ = advertisementsStore.pipe(selectAllEntities())
  private currentAdIndex = 0
  private lastAdvertised?: Date

  constructor(private readonly advertisementDao: AdvertisementDao) {}

  public async fetchAll(languageCode: string, countryCode: string): Promise<void> {
    try {
      const platform = Capacitor.isNativePlatform() ? TargetPlatform.MOBILE : TargetPlatform.WEB
      const advertisements = await this.advertisementDao.findAll(
        languageCode,
        countryCode,
        platform
      )
      advertisementsStore.update(setEntities(advertisements))
    } catch (error) {
      console.error('Error fetching advertisements', error)
    }
  }

  public handleRetrigger(): void {
    //reset ads if longer than 24hours ago (mobile)
    if (
      this.lastAdvertised instanceof Date &&
      new Date().getTime() - this.lastAdvertised.getTime() > ADS_TIMEOUT_MS
    ) {
      this.currentAdIndex = 0
    }
  }

  public async getFirst(): Promise<AdvertisementModel | undefined> {
    const advertisements = advertisementsStore.query(getAllEntities())
    const activeAdvertisements = advertisements.filter((advertisement) => {
      const now = new Date()
      const startDate = new Date(advertisement.startDate)
      const endDate = new Date(advertisement.endDate)
      return startDate <= now && now <= endDate
    })

    const filteredAdvertisements = await asyncFilter(
      activeAdvertisements,
      async (advertisement) => {
        const { value } = await Preferences.get({
          key: `ADVERTISEMENT_${advertisement.id}_ACTION_COUNT`,
        })
        if (value) {
          return +value < advertisement.repeatCount
        }

        return true
      }
    )

    return filteredAdvertisements.length > 0
      ? filteredAdvertisements[this.currentAdIndex]
      : undefined
  }

  public async increaseActionCount(advertisement: AdvertisementModel): Promise<void> {
    const { value } = await Preferences.get({
      key: `ADVERTISEMENT_${advertisement.id}_ACTION_COUNT`,
    })
    this.currentAdIndex++
    const newCount = value ? +value + 1 : 1
    await Preferences.set({
      key: `ADVERTISEMENT_${advertisement.id}_ACTION_COUNT`,
      value: newCount.toString(),
    })

    this.lastAdvertised = new Date()
  }
}
