<efp-collapsable-menu
  *ngIf="(hasNavigation || isHomeScreen) && !disablePrimaryMenu"></efp-collapsable-menu>

<ion-header>
  <ion-toolbar>
    <ion-row>
      <ion-col class="flex-1">
        <ion-buttons class="d-flex nav-button-wrapper">
          <efp-open-menu-button
            *ngIf="(hasNavigation || isHomeScreen) && !disablePrimaryMenu"
            [ngClass]="{ 'media-mobile-only': !webCollapsableEnabled }"></efp-open-menu-button>
          <ng-container>
            <ng-content select="#logo"></ng-content>
          </ng-container>
          <ion-button
            *ngIf="hasNavigation"
            (click)="navigate()"
            [disabled]="navDisabledAndGray"
            data-test-selector="header-navigate">
            <ion-icon
              *ngIf="isNavStep"
              color="primary"
              slot="icon-only"
              shape="round"
              src="/assets/icon/home.svg"></ion-icon>
            <ion-icon
              *ngIf="!isNavStep"
              slot="icon-only"
              shape="round"
              name="arrow-back-outline"
              color="primary"></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
          <ion-button
            *ngIf="isModal"
            (click)="dismiss()"
            [disabled]="navDisabledAndGray"
            data-test-selector="header-dismiss">
            <ion-icon slot="icon-only" name="close" color="primary"></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
        </ion-buttons>
      </ion-col>
      <ion-col class="ion-text-center ion-justify-content-center flex-3 overflow-trunc">
        <ion-title
          class="removeInlinePadding overflow-hidden"
          [ngClass]="{ 'show-title-on-web': showTitleOnWeb }">
          {{ title }}
        </ion-title>
      </ion-col>
      <ion-col class="ion-justify-content-end flex-1 gap-16">
        <efp-request-sync-indicator
          *ngIf="!isNative() && showSyncIndicator"></efp-request-sync-indicator>
        <div class="d-flex gap-8">
          <efp-reset-onboarding-button></efp-reset-onboarding-button>
          <ng-content></ng-content>
          <ion-buttons>
            <ng-content select="[buttons]"></ng-content>
          </ion-buttons>
        </div>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>
