import { Injectable, inject } from '@angular/core'
import { AccApiClient, AccHubData, AccProjectData } from '../../generated/efp-api'
import { Observable, firstValueFrom, map } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AccDataService {
  private readonly accApiClient = inject(AccApiClient)

  constructor() {}

  initAccAuth(): Observable<string> {
    return this.accApiClient.auth()
  }

  createAccToken(code: string): Observable<string> {
    return this.accApiClient.token({ code })
  }

  logout(): Observable<void> {
    return this.accApiClient.logout()
  }

  getHubs(): Observable<AccHubData[]> {
    return this.accApiClient.hubs()
  }

  getProjects(): Observable<AccProjectData[]> {
    return this.accApiClient.projects()
  }

  getProjectLink(hubId: string, projectId: string): Observable<string> {
    const body = {
      projectId,
      hubId: hubId ?? '',
    }

    return this.accApiClient
      .project(body)
      .pipe(map((projectsData: AccProjectData) => projectsData.webLink))
  }

  async uploadresult(
    folderName: string,
    hubId: string,
    planName: string,
    projectId: string,
    pdfFileData: string,
    csvFileData: string
  ): Promise<void> {
    const body = {
      folderName,
      hubId,
      pdfFileData,
      planName,
      projectId,
      csvFileData,
    }
    await firstValueFrom(this.accApiClient.upload({ body }))
  }
}
