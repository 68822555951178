import { Injectable } from '@angular/core'
import { BlacklistArticleModel, CatalogArticle, CatalogArticleGroup, FormworkId } from '@efp/api'
import {
  PlanType,
  SimplifiedMesh,
  SlabMeshXmlWriter,
  WallMeshXmlWriter,
} from 'formwork-planner-lib'
import { COUNTRY_BRANCH_MAPPING } from '../../constants/countryBranchMapping'
import {
  ARTICLE_NUMBER_ATTRIBUTE,
  BRANCH,
  CURRENT_SLAB_SYSTEM,
  CURRENT_WALL_SYSTEM,
  emptyTiposXml,
  FORMWORK_MODE_SLAB,
  FORMWORK_MODE_WALL,
  FORMWORK_SYSTEM,
  INITIAL_STOCK,
  INTERNAL,
  MANUFACTURER_ATTRIBUTE,
  PRINT_LANGUAGE,
  PROJECT_DATA,
  QUANTITY_BUILDING_SITE_ATTRIBUTE,
  QUANTITY_BUILDING_YARD_ATTRIBUTE,
  QUANTITY_VENDOR_ATTRIBUTE,
  STOCK,
} from '../../constants/tiposXml'
import { Article } from '../../models/article'
import { FavouriteSystem } from '../../models/favourites'
import { Plan } from '../../models/plan'
import { PlanOutline } from '../../models/plan/PlanOutline'
import { PlanSettings } from '../../models/planSettings'
import { AccessoryService } from '../../pages/accessory/services/accessory.service'
import { AppSettingsRepository } from '../../repositories/app-settings.repository'
import { CycleRepository } from '../../repositories/cycle.repository'
import { FavouriteRepository } from '../../repositories/favourite.repository'
import { PlanOutlineRepository } from '../../repositories/plan-outline.repository'
import { StockRepository } from '../../repositories/stock.repository'
import { XMLAttribute, XmlWriter } from '../../utils/xmlWriter'
import { AuthenticationService } from '../authentication.service'
import { FavouritesService } from '../favourites.service'
import { PlanSettingsService } from '../plan-settings.service'
import { Translation } from '../translation.service'
import { BlacklistArticleRepository } from '../../repositories/blacklist-article.repository'
import { CatalogRepository } from '../../repositories/catalog.repository'
import { Observable, firstValueFrom, of } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class TiposXmlService {
  constructor(
    private readonly translation: Translation,
    private readonly planOutlineRepository: PlanOutlineRepository,
    private readonly planSettingsService: PlanSettingsService,
    private readonly authService: AuthenticationService,
    private readonly favouritesService: FavouritesService,
    private readonly cycleRepository: CycleRepository,
    private readonly accessoryService: AccessoryService,
    private readonly favouriteRepository: FavouriteRepository,
    private readonly stockRepository: StockRepository,
    private readonly appSettingsRepository: AppSettingsRepository,
    private readonly blacklistArticleRepository: BlacklistArticleRepository,
    private readonly catalogRepository: CatalogRepository
  ) {}

  public async generateXmlForPlan(plan: Plan): Promise<string | undefined> {
    const planOutline = await this.planOutlineRepository.findAllOutlinesByPlanId(plan.id)
    if (!plan.serializedMesh) {
      return undefined
    }
    const planSettings = await this.planSettingsService.getPlanSettingsAndSetLastUnit(
      plan.settingsId
    )
    if (!planSettings) {
      throw new Error('generateXmlForPlan - Plan settings not found')
    }

    let tiposXml = '' + emptyTiposXml
    switch (plan.buildingType) {
      case PlanType.SLAB:
        tiposXml = this.writeSlabXmlData(tiposXml, plan, planSettings, planOutline)
        break
      case PlanType.WALL:
        tiposXml = await this.writeWallXmlData(tiposXml, plan, planSettings, planOutline)
        break
    }

    const favId =
      plan.buildingType === PlanType.SLAB ? planSettings.slabFavId : planSettings.wallFavId

    tiposXml = await this.insertStockXML(tiposXml, plan.stockId, favId)
    tiposXml = this.insertFormworkIntoXml(tiposXml, planSettings)
    tiposXml = await this.insertBackendData(tiposXml, plan.buildingType)
    tiposXml = this.insertLanguageIntoXml(tiposXml)
    tiposXml = await this.addFavouritesToXML(tiposXml, plan.buildingType, planSettings)

    //TODO make changeable; ALL PRESET IN XML FOR NOW
    //xml = Utils.setLanguageOfXmlString(xml, code);
    //xml = Utils.setCurrentCycleOfXmlString(xml, drawingMode);
    //xml = Utils.setCurrentScope(xml, drawingMode);
    //String wallFormworkId = plan.getWallFormworkId();
    //xml = Utils.setWallFormworkString(xml, wallFormworkId);
    //String slabFormworkId = plan.getSlabFormworkId();
    //xml = Utils.setSlabFormworkString(xml, slabFormworkId);

    return tiposXml
  }

  private parseSimplifiedMesh(serializedMesh: string | undefined): SimplifiedMesh | undefined {
    if (!serializedMesh) {
      return undefined
    }
    // TODO Validation?
    return JSON.parse(serializedMesh) as SimplifiedMesh
  }

  private writeSlabXmlData(
    tiposXml: string,
    plan: Plan,
    planSettings: PlanSettings,
    planOutline: PlanOutline[]
  ): string {
    const simpleMesh = this.parseSimplifiedMesh(plan.serializedMesh)
    if (!simpleMesh) {
      // TODO Should we throw here? Outline should exist with serialized mesh
      return tiposXml
    }

    const slabOutline = planOutline.filter((it) => it.outlineType === PlanType.SLAB)
    const wallOutline = planOutline.filter((it) => it.outlineType === PlanType.WALL)
    const slabWriter = new SlabMeshXmlWriter(tiposXml)
    slabWriter.writeSlabTiposDataToXml(
      slabOutline,
      planSettings.slabHeight,
      planSettings.slabThickness
    )
    slabWriter.writeSupportWallDataToXml(
      slabOutline,
      wallOutline,
      planSettings.slabHeight,
      planSettings.slabThickness
    )

    return slabWriter.getXml()
  }

  private async writeWallXmlData(
    tiposXml: string,
    plan: Plan,
    planSettings: PlanSettings,
    planOutline: PlanOutline[]
  ): Promise<string> {
    const simpleMesh = this.parseSimplifiedMesh(plan.serializedMesh)
    if (!simpleMesh || !plan.serializedMesh) {
      // TODO Should we throw here? Outline should exist with serialized mesh
      return tiposXml
    }

    const wallMeshWriter = new WallMeshXmlWriter(tiposXml)
    wallMeshWriter.writeWallTiposDataToXml(planOutline, simpleMesh, planSettings.wallHeight)

    const cycleBoundaries = await this.cycleRepository.findAllCycleBoundariesByPlanId(plan.id)
    const cycleSymbols = await this.cycleRepository.findAllCycleSymbolsByPlanId(plan.id)
    if (cycleBoundaries.length > 0 || cycleSymbols.length > 0) {
      wallMeshWriter.writeCycleBoundariesToTiposXml(cycleBoundaries, cycleSymbols)
    }

    const accessoryLines = await this.accessoryService.loadAccessoryLinesForPlanAndFormwork(
      plan.id,
      planSettings.formworkWall
    )
    if (accessoryLines.length > 0) {
      wallMeshWriter.writeWallTiposDataToXmlFromAccessoryLines(
        planSettings.wallHeight,
        simpleMesh,
        accessoryLines
      )
    }
    return wallMeshWriter.getXml()
  }

  private async insertStockXML(
    initialXML: string,
    stockId: number | null,
    favoriteId: number | null
  ): Promise<string> {
    const stock = stockId != null ? await this.stockRepository.loadByIdWithArticles(stockId) : null
    const stockArticles = stock?.articles ?? []
    const blacklistArticles =
      favoriteId != null
        ? await this.blacklistArticleRepository.findAllByFavouriteProfileId(favoriteId)
        : []

    let notRentalArticles = ''
    if (favoriteId != null) {
      const favorite = await this.favouriteRepository.findOneById(favoriteId)

      // remove XframiS condition when groups are finished
      if (favorite?.useOnlyRentableArticles && favorite.formworkSystemId) {
        notRentalArticles = await this.loadAllNotRentableArticles(favorite.formworkSystemId)
      }
    }

    const writer = new XmlWriter(initialXML)
    const stockElement = this.createStockElement(
      stockArticles,
      blacklistArticles,
      notRentalArticles,
      writer
    )
    writer.setTagChild(PROJECT_DATA, stockElement)

    return writer.getXml()
  }

  // if the toggle is active in a plan the articles that fulfill the conditions below should be added to the xml (like stock) but with 0 in the 3rd instance (supplier)
  // article is part of the current formwork system
  // article number starts with 58...
  // article is NOT rentable
  // article has a life cycle that would allow usage: G, I, O, P

  public async loadAllNotRentableArticles(formworkSystem: FormworkId): Promise<string> {
    const articleGroups: CatalogArticleGroup[] = await this.catalogRepository.getArticleGroups(
      formworkSystem
    )
    const articlesToExclude = await firstValueFrom(this.getAllExcludedArticles(articleGroups))

    return articlesToExclude.map((value) => value.articleId.toString()).join(',') ?? ''
  }

  private getAllExcludedArticles(
    articleOrGroups: CatalogArticleGroup[] | CatalogArticle[]
  ): Observable<CatalogArticle[]> {
    const accumulateFilteredArticles = (
      items: CatalogArticleGroup[] | CatalogArticle[],
      acc: CatalogArticle[] = []
    ): CatalogArticle[] => {
      items.forEach((item) => {
        if ('subgroups' in item && 'articles' in item) {
          accumulateFilteredArticles(item.subgroups, acc)
          if (item.articles.length > 0) {
            accumulateFilteredArticles(item.articles, acc)
          }
        } else if (
          'articleId' in item &&
          !item.isRentable &&
          item.articleId.startsWith('58') // articles without 58 are not excluded, because some of them are not rentable but still necessary to calculate
        ) {
          acc.push(item)
        }
      })
      return acc
    }

    return of(accumulateFilteredArticles(articleOrGroups))
  }

  private createStockElement(
    articles: Article[],
    blacklistedArticles: BlacklistArticleModel[],
    notRentalArticles: string,
    writer: XmlWriter
  ): HTMLElement {
    const blacklistArticleIds = new Set(blacklistedArticles.map((article) => article.articleId))
    const notRentalArticleIds = new Set(notRentalArticles.split(',').filter((i) => i))

    // add not rental material in blacklisted articles - both have the same 'exclusion' logic for Tipos
    notRentalArticleIds.forEach((id) => {
      if (!blacklistArticleIds.has(id)) {
        blacklistArticleIds.add(id)
      }
    })

    const articleElements = articles.map((article: Article) => {
      const articleId = `${article.articleId}`
      const attributes = [
        { name: MANUFACTURER_ATTRIBUTE, value: 'DOKA' },
        { name: ARTICLE_NUMBER_ATTRIBUTE, value: `${article.articleId}` },
        { name: QUANTITY_BUILDING_SITE_ATTRIBUTE, value: `${article.amount}` },
        { name: QUANTITY_BUILDING_YARD_ATTRIBUTE, value: '0' },
        {
          name: QUANTITY_VENDOR_ATTRIBUTE,
          value: blacklistArticleIds.has(articleId) ? '0' : '9999',
        },
      ]

      if (blacklistArticleIds.has(articleId)) {
        blacklistArticleIds.delete(articleId)
      }
      return writer.createElementWithAttributes(STOCK, attributes)
    })

    const blacklistElements = this.createBlacklistElement(
      Array.from(blacklistArticleIds.values()),
      writer
    )
    articleElements.push(...blacklistElements)

    return writer.createElementWithChilds(INITIAL_STOCK, articleElements)
  }

  private createBlacklistElement(blacklistArticleIds: string[], writer: XmlWriter): HTMLElement[] {
    const articleElements = blacklistArticleIds.map((articleId: string) => {
      const attributes = [
        { name: MANUFACTURER_ATTRIBUTE, value: 'DOKA' },
        { name: ARTICLE_NUMBER_ATTRIBUTE, value: articleId },
        { name: QUANTITY_BUILDING_SITE_ATTRIBUTE, value: '0' },
        { name: QUANTITY_BUILDING_YARD_ATTRIBUTE, value: '0' },
        { name: QUANTITY_VENDOR_ATTRIBUTE, value: '0' },
      ]

      return writer.createElementWithAttributes(STOCK, attributes)
    })

    return articleElements
  }

  private insertFormworkIntoXml(initialXML: string, planSettings: PlanSettings): string {
    const writer = new XmlWriter(initialXML)
    const formworkWallElement = this.createFormworkSystemElement(planSettings.formworkWall, writer)
    const formworkSlabElement = this.createFormworkSystemElement(planSettings.formworkSlab, writer)
    const currentWallSystemElement = this.createCurrentWallSystemElement(
      planSettings.formworkWall,
      writer
    )
    const currentSlabSystemElement = this.createCurrentSlabSystemElement(
      planSettings.formworkSlab,
      writer
    )

    writer.setTagChild(FORMWORK_MODE_WALL, formworkWallElement)
    writer.setTagChild(FORMWORK_MODE_SLAB, formworkSlabElement)
    writer.setTagChild(PROJECT_DATA, currentWallSystemElement)
    writer.setTagChild(PROJECT_DATA, currentSlabSystemElement)

    return writer.getXml()
  }

  private createFormworkSystemElement(formworkSystem: string, writer: XmlWriter): HTMLElement {
    const element = writer.createElementWithChilds(FORMWORK_SYSTEM, [])
    element.textContent = formworkSystem

    return element
  }

  private createCurrentWallSystemElement(
    wallFormworkSystem: string,
    writer: XmlWriter
  ): HTMLElement {
    const attributes = this.getFormworkSystemAttributes(wallFormworkSystem)

    return writer.createElementWithAttributes(CURRENT_WALL_SYSTEM, attributes)
  }

  private createCurrentSlabSystemElement(
    slabFormworkSystem: string,
    writer: XmlWriter
  ): HTMLElement {
    const attributes = this.getFormworkSystemAttributes(slabFormworkSystem)

    return writer.createElementWithAttributes(CURRENT_SLAB_SYSTEM, attributes)
  }

  private getFormworkSystemAttributes(slabFormworkSystem: string): XMLAttribute[] {
    return [
      { name: 'manufacturer', value: 'DOKA' },
      { name: 'systemId', value: slabFormworkSystem },
    ]
  }

  private insertLanguageIntoXml(initialXML: string): string {
    const writer = new XmlWriter(initialXML)
    writer.setTagContent(PRINT_LANGUAGE, this.translation.getCurrentTiposLanguage())
    return writer.getXml()
  }

  public async addFavouritesToXML(
    planXML: string,
    buildingType: string,
    planSettings: PlanSettings
  ): Promise<string> {
    const exist = await this.favouritesService.checkIfStandardFavouritesExist()
    if (!exist) {
      return planXML
    } else {
      let formworksystem: FormworkId
      let favId: number | null = null
      const parser = new DOMParser()
      const xml = parser.parseFromString(planXML, 'text/xml')

      if (buildingType === PlanType.WALL) {
        favId = planSettings.wallFavId
        formworksystem = planSettings.formworkWall
      } else {
        favId = planSettings.slabFavId
        formworksystem = planSettings.formworkSlab
      }

      if (favId != null) {
        const formworkXml = await this.getFavouriteSystemXml(formworksystem, favId)
        const favXml: string = XmlWriter.OBJtoXML(formworkXml)
        const xmlFavWrapper = xml.createElement('favorites')
        const schalungsstile = xml.createElement('Schalungsstile')
        const schalsystem = xml.createElement('Schalsystem')
        schalsystem.innerHTML = favXml
        schalungsstile.appendChild(schalsystem)
        xmlFavWrapper.appendChild(schalungsstile)
        xml.querySelector(PROJECT_DATA)?.appendChild(xmlFavWrapper)
      }

      return new XMLSerializer().serializeToString(xml)
    }
  }

  private async getFavouriteSystemXml(
    currentSystem: FormworkId,
    favId: number
  ): Promise<FavouriteSystem> {
    const favouriteProfile = await this.favouriteRepository.findOneById(favId)
    if (!favouriteProfile) {
      throw new Error('TiposXmlService.getFavouriteSystemXml - Favourite profile not found')
    }
    return favouriteProfile.values.Schalungsstile.Schalsystem.filter(
      (system) => system.ID === currentSystem
    )[0]
  }

  private async insertBackendData(xml: string, planType: PlanType): Promise<string> {
    const branch = planType === PlanType.SLAB ? '80' : await this.getBranch()
    const internal = await this.authService.isDokaUser()

    const writer = new XmlWriter(xml)
    writer.setTagContent(BRANCH, branch)
    writer.setTagContent(INTERNAL, internal.toString())
    return writer.getXml()
  }

  private async getBranch(): Promise<string> {
    const country = (await this.appSettingsRepository.getAppSettings()).country
      .toString()
      .toUpperCase()
    const branch = COUNTRY_BRANCH_MAPPING.find((x) => x.COUNTRY_CODE === country)
    // 111 default branch for all countries
    return branch ? branch.BRANCH : '111'
  }
}
