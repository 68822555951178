<efp-scrollbar [useBottomSafeArea]="true">
  <div class="navbar-header">
    {{ 'NAVBAR.HEADING' | translate }}
  </div>

  <button
    *ngFor="let page of pages; index as i"
    (click)="navToStep(page)"
    [disabled]="!wallIsCreated && i > 1"
    class="nav-stepper-item"
    attr.data-test-selector="{{ currentStep }}-navbar-nav-step-{{ i }}"
    [ngClass]="{
      active: page === this.currentStep,
      result: page == 'result'
    }">
    <span *ngIf="page !== waitingNavStep || !syncRunning" class="index-box">
      {{ i + 1 }}
    </span>
    <span *ngIf="page === waitingNavStep && syncRunning" class="index-box">
      <ion-spinner class="sync-icon" name="circular"></ion-spinner>
    </span>
    <span class="page-name-box" *ngIf="page == 'plansettings'">
      {{ 'NAVBAR.PLANSETTINGS' | translate }}
    </span>
    <span class="page-name-box" *ngIf="page == 'planner'">
      {{ 'NAVBAR.DRAWING' | translate }}
    </span>
    <span class="page-name-box" *ngIf="page == 'cycles'">
      {{ 'NAVBAR.CYCLES' | translate }}
    </span>
    <span class="page-name-box" *ngIf="page == 'accessory'">
      {{ 'NAVBAR.ACCESSORY' | translate }}
    </span>
    <span class="page-name-box" *ngIf="page == 'result'">
      {{ 'NAVBAR.RESULT' | translate }}
    </span>
  </button>

  <ng-container *ngIf="isCalculated$ | async">
    <button
      class="nav-stepper-item result-detail"
      (click)="navToResultTab(resultStep.Result3D)"
      [ngClass]="{ active: this.resultTabService.getResultStep() === resultStep.Result3D }"
      data-test-selector="planner-navbar-resultstep-3d-view">
      <span class="index-box three-d-view"> </span>
      <span class="page-name-box">
        {{ 'NAVBAR.RESULT_TABS.3D_VIEW' | translate }}
      </span>
    </button>
    <button
      class="nav-stepper-item result-detail active"
      [ngClass]="{ active: this.resultTabService.getResultStep() === resultStep.Result2D }"
      (click)="navToResultTab(resultStep.Result2D)"
      data-test-selector="planner-navbar-resultstep-2d-views">
      <span class="index-box two-d-views"> </span>
      <span class="page-name-box">
        {{ 'NAVBAR.RESULT_TABS.2D_VIEWS' | translate }}
      </span>
    </button>
    <button
      class="nav-stepper-item result-detail"
      [ngClass]="{ active: this.resultTabService.getResultStep() === resultStep.ResultList }"
      (click)="navToResultTab(resultStep.ResultList)"
      data-test-selector="planner-navbar-resultstep-piece-list">
      <span class="index-box piece-list"> </span>
      <span class="page-name-box">
        {{ 'NAVBAR.RESULT_TABS.PIECE_LIST' | translate }}
      </span>
    </button>
  </ng-container>
</efp-scrollbar>
