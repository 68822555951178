import { Component, Input, OnInit } from '@angular/core'
import { FavouriteProfile } from '../../../models/favourites'
import { FavouritesService } from '../../../services/favourites.service'
import { Router } from '@angular/router'
import { BlacklistArticleRepository } from '../../../repositories/blacklist-article.repository'
import {
  EditWarningResult,
  FavouriteChangeAlertService,
} from '../../../services/favourite-change-alert.service'

@Component({
  selector: 'efp-exclusion-settings',
  templateUrl: './exclusion-settings.component.html',
  styleUrls: ['./exclusion-settings.component.scss'],
})
export class ExclusionSettingsComponent implements OnInit {
  private _favouriteProfile!: FavouriteProfile

  @Input()
  public get favouriteProfile(): FavouriteProfile {
    return this._favouriteProfile
  }

  public set favouriteProfile(value: FavouriteProfile) {
    this._favouriteProfile = value
    this.setArticleCount()
  }

  @Input() withProfileTitle = true
  @Input() bottomPadding = true
  @Input() planId: number | undefined

  public articleCount?: number

  constructor(
    private readonly favoritesService: FavouritesService,
    private readonly blackListArticleRepository: BlacklistArticleRepository,
    private readonly favouriteChangeAlertService: FavouriteChangeAlertService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.setArticleCount()
  }

  private setArticleCount(favoriteProfile: FavouriteProfile = this.favouriteProfile): void {
    if (favoriteProfile) {
      void this.blackListArticleRepository
        .findAllByFavouriteProfileId(this.favouriteProfile.id)
        .then((articles) => {
          this.articleCount = articles.length
        })
    }
  }

  public async navToBlacklist(): Promise<void> {
    const parentRoute = this.router.url.split('/')
    if (parentRoute[1] === 'plansettings') {
      await this.router.navigate([
        '/plansettings',
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        this.planId !== undefined ? this.planId : parentRoute[2],
        'favourites',
        this.favouriteProfile.id,
        'blacklist',
      ])
    } else {
      await this.router.navigate([
        '/favourites-overview/favourites',
        this.favouriteProfile.id,
        'blacklist',
      ])
    }
  }

  public async changedUseOnlyRentableArticles(): Promise<void> {
    const shouldEdit = await this.favouriteChangeAlertService.getResultOfEditWarning(
      this.favouriteProfile,
      this.planId
    )

    if (shouldEdit === EditWarningResult.editExisting) {
      await this.favoritesService.updateFavourite(this.favouriteProfile)
      return
    } else if (shouldEdit === EditWarningResult.duplicateAndEdit && this.favouriteProfile) {
      const newProfile = {
        ...this.favouriteProfile,
      }
      await this.duplicateProfileAndNavigate(newProfile)
    } else {
      this.favouriteProfile.useOnlyRentableArticles = !this.favouriteProfile.useOnlyRentableArticles
      return
    }
  }

  private async duplicateProfileAndNavigate(newProfile: FavouriteProfile): Promise<void> {
    const duplicatedProfile = await this.favoritesService.duplicateFavourite(newProfile)

    if (duplicatedProfile) {
      const parentRoute = this.router.url.split('/')
      if (parentRoute[1] === 'plansettings') {
        await this.favouriteChangeAlertService.changeFavProfileOfPlan(
          +parentRoute[2],
          duplicatedProfile
        )

        // We are on the plan settings page and we are duplicating a favourite profile
        if (parentRoute.length === 3) {
          return
        }
      }

      await this.favouriteChangeAlertService.navToDuplicatedFavourite(
        duplicatedProfile.id,
        parentRoute
      )
    }
  }
}
