import {
  asMeshPoint,
  calculateEdgeOutline,
  calculateOuterCornerPoint,
  CompoundPathWithEdgeMap,
  Edge,
  ExtendedMeshPoint,
  intersectLines,
  isSnapEdgeInfo,
  LinePosition,
  LineSide,
  MeshPoint,
  PlanType,
  radiansToDegrees,
  SNAP_ANGLE_INTERVAL,
  SnapEdgeInfo,
  SNAPPING_DISTANCE,
  SNAPPING_ERROR_TOLERANCE,
  SnapResult,
  toExtendedMeshPoint,
  TransformationLengthDirection,
  TransformationThicknessDirection,
  WallMesh,
} from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { DrawSettings } from '../../../models/draw-settings'
import { UndoRedoHistory } from '../../../models/history/undoRedoHistory'
import { flatMap } from '../../../utils/flatMap'
import { Line2D } from '../../../utils/geometry/Line2D'
import { EdgeAttributes } from '../types/edgeAttributes'
import { WallUndoRedoHistory } from './history/WalUndoRedoHistory'
import { parseSimplifiedWallMesh, serializeMesh } from './MeshSerializer'
import { Model } from './Model'
import { CYCLE_BOUNDARIES_MIN_DISTANCE } from './snapping/constants'
import { snapAnchoredPoint, snapFreePoint } from './snapping/snapPoint'
import { updateAnchorPositions } from './updateAnchorPositions'

/**
 * Contains mesh data for a wall model, methods to manipulate the underlying mesh as well as an undo/redo history.
 */
export class WallModel extends Model<WallMesh> {
  private pathWithEdgeMap?: CompoundPathWithEdgeMap

  constructor(
    paperScope: paper.PaperScope,
    drawSetting: DrawSettings,
    cachedHistory?: UndoRedoHistory<WallMesh>
  ) {
    super(paperScope, drawSetting, cachedHistory)
  }

  clone(): Model<WallMesh> {
    const clonedMesh = parseSimplifiedWallMesh(this.paperScope, serializeMesh(this.mesh))
    const clonedModel = new WallModel(this.paperScope, this.drawSetting)
    clonedModel.mesh = clonedMesh
    clonedModel.finalize()
    return clonedModel
  }

  getSurroundingLines(edges: Edge[], generateOuterEdges: boolean = true): Line2D[] {
    return flatMap(
      edges.map((edge) => {
        const outerLengthLines = generateOuterEdges
          ? this.getWallLengthSegments(edge)
              .map((s) => s.curve)
              .filter((c) => c != null)
              .map((c) => new Line2D(c.point1, c.point2))
          : []
        if (outerLengthLines.length === 0) {
          return this.getExistingOuterLinesOfEdge(edge, generateOuterEdges)
        } else {
          return [
            ...outerLengthLines,
            ...this.getExistingOuterLinesOfEdge(edge, generateOuterEdges),
          ]
        }
      })
    )
  }

  /**
   * Returns mesh points in the mesh that are connecting edges (they are not the open end of a wall)
   */
  getConnectingAndCloseDisconnectedMeshPoints(): MeshPoint[] {
    const allEdges = [...this.mesh.getAllEdges()]
    const connectingMeshPoints: MeshPoint[] = []
    this.mesh.points.forEach((point) => {
      if (point.edges.size > 1) {
        connectingMeshPoints.push(point)
      } else {
        const edge = [...point.edges][0]
        allEdges.forEach((e) => {
          const closeDisconnectedPoints = edge.getCloseDisconnectedPoints(e)
          if (closeDisconnectedPoints && closeDisconnectedPoints[0] === point) {
            connectingMeshPoints.push(point)
          }
        })
      }
    })

    return connectingMeshPoints
  }

  private getExistingOuterLinesOfEdge(edge: Edge, generateOuterEdges: boolean): Line2D[] {
    const edgeVector = edge.endPoint.subtract(edge.startPoint)
    const outerPoints = calculateEdgeOutline(edge)
    const leftOuterLine = outerPoints[LineSide.LEFT]
    const rightOuterLine = outerPoints[LineSide.RIGHT]

    const leftOuterStartPoint = leftOuterLine[LinePosition.START]
    const rightOuterStartPoint = rightOuterLine[LinePosition.START]

    const outerEdges: Line2D[] = []
    if (generateOuterEdges) {
      outerEdges.push(
        new Line2D(leftOuterLine[LinePosition.START], leftOuterLine[LinePosition.END]),
        new Line2D(rightOuterLine[LinePosition.START], rightOuterLine[LinePosition.END])
      )
    }

    const wallFaces: Line2D[] = []
    if (edge.startPoint.edges.size === 1) {
      wallFaces.push(new Line2D(rightOuterStartPoint, leftOuterStartPoint))
    }

    if (edge.endPoint.edges.size === 1) {
      wallFaces.push(
        new Line2D(rightOuterStartPoint.add(edgeVector), leftOuterStartPoint.add(edgeVector))
      )
    }

    return [...outerEdges, ...wallFaces]
  }

  protected createEmptyMesh(): WallMesh {
    return new WallMesh(this.paperScope)
  }

  protected createUndoRedoHistory(): UndoRedoHistory<WallMesh> {
    return new WallUndoRedoHistory(this.paperScope, this.mesh, this.drawSetting)
  }

  protected generatePath(): paper.CompoundPath {
    this.pathWithEdgeMap = this.mesh.generatePath()
    return this.pathWithEdgeMap
  }

  protected getEdgeAttributes(edge: Edge): EdgeAttributes {
    const lengthSegments = this.getWallLengthSegments(edge)
    const center = edge.startPoint.add(edge.endPoint.subtract(edge.startPoint).multiply(0.5))
    const maxDistanceToCenter = edge.thickness / 2

    const innerSegment = lengthSegments.find((it) => {
      const hasInnerLength = it.curve.length < edge.length()
      // Segments of adjacent walls could be included, if their angle is 180° (T-Connection)
      const distanceToCenter = it.curve.getNearestPoint(center).getDistance(center)
      const isSegmentOfEdge =
        Math.abs(distanceToCenter - maxDistanceToCenter) < SNAPPING_ERROR_TOLERANCE
      return hasInnerLength && isSegmentOfEdge
    })

    return {
      outerLength: edge.length(),
      innerLength: innerSegment?.curve?.length ?? edge.length(),
      thickness: edge.thickness,
    }
  }

  /**
   * Creates snapping lines in the middle of edges to place cycle boundaries on
   * Cycle boundaries can only be placed a 100 cm away from the end of the walls to ensure a minimum cycle length of 1m
   */
  public getCycleBorderSnappingLines(): paper.Path.Line[] {
    const snappingLines: paper.Path.Line[] = []
    const edges = this.getAllEdges()
    edges.forEach((edge) => {
      const center = edge.getCenterPoint()
      const lengthSegments = this.getWallLengthSegments(edge)

      const startOriginal: paper.Point = edge.startPoint
      const endOriginal: paper.Point = edge.endPoint
      const edgeVector = edge.endPoint.subtract(edge.startPoint).normalize()

      const mainLine = new paper.Path.Line(startOriginal, endOriginal)
      if (mainLine.length <= CYCLE_BOUNDARIES_MIN_DISTANCE) {
        return
      }

      let start: paper.Point = startOriginal
      let end: paper.Point = endOriginal
      lengthSegments.forEach((segment) => {
        let lengthStart = segment.curve.point1
        let lengthEnd = segment.curve.point2
        if (lengthStart.getDistance(startOriginal) > lengthEnd.getDistance(startOriginal)) {
          ;[lengthStart, lengthEnd] = [lengthEnd, lengthStart] // SWAP
        }

        const newStart = mainLine.getNearestPoint(lengthStart)
        if (newStart && newStart.getDistance(center) < start.getDistance(center)) {
          start = newStart
        }

        const newEnd = mainLine.getNearestPoint(lengthEnd)
        if (newEnd && newEnd.getDistance(center) < end.getDistance(center)) {
          end = newEnd
        }
      })

      // checking whether edge has close disconnected neighbors (if yes, the snapping line doesn't have to be shortened)
      const disconnectedNeighbors = edge.getCloseDisconnectedNeighbors(this.mesh.getAllEdges(), [])
      let startPointHasDisconnectedNeighbor = false
      let endPointHasDisconnectedNeighbor = false
      disconnectedNeighbors.forEach((neighbor) => {
        const disconnectedPoints = edge.getCloseDisconnectedPoints(neighbor)
        if (disconnectedPoints) {
          if (disconnectedPoints[0] === edge.startPoint) {
            startPointHasDisconnectedNeighbor = true
          }

          if (disconnectedPoints[0] === edge.endPoint) {
            endPointHasDisconnectedNeighbor = true
          }
        }
      })

      // cycle boundaries cannot be placed with a distance of <1m to the end of a wall
      if (edge.startPoint.edges.size === 1 && !startPointHasDisconnectedNeighbor) {
        start = start.add(edgeVector.normalize(CYCLE_BOUNDARIES_MIN_DISTANCE))
      }

      if (edge.endPoint.edges.size === 1 && !endPointHasDisconnectedNeighbor) {
        end = end.subtract(edgeVector.normalize(CYCLE_BOUNDARIES_MIN_DISTANCE))
      }

      const result = new paper.Path.Line(start, end)
      snappingLines.push(result)
    })
    return snappingLines
  }

  protected updateEdgeThickness(
    edge: Edge,
    thickness: number,
    thicknessDirection: TransformationThicknessDirection
  ): void {
    if (edge.thickness === thickness) {
      return
    }

    const startFromLeftOrBottom = edge.startsFromLeft || (edge.startsFromBottom && edge.isVertical)
    const startPoint: MeshPoint = startFromLeftOrBottom ? edge.startPoint : edge.endPoint
    const endPoint: MeshPoint = startFromLeftOrBottom ? edge.endPoint : edge.startPoint
    let detachedStart: MeshPoint | undefined = undefined
    let detachedEnd: MeshPoint | undefined = undefined

    if (
      thicknessDirection === TransformationThicknessDirection.outsideIn ||
      thicknessDirection === TransformationThicknessDirection.insideOut
    ) {
      const thicknessDifference = edge.thickness - thickness
      const edgeVector = endPoint.subtract(startPoint)

      let pointDisplacement = edgeVector.normalize(thicknessDifference / 2)
      if (thicknessDirection === TransformationThicknessDirection.insideOut) {
        pointDisplacement = pointDisplacement.rotate(-90)
      } else {
        pointDisplacement = pointDisplacement.rotate(90)
      }

      // checking whether start and endpoint could be detached
      const startDetached =
        (startPoint.edges.size === 3 && !edge.isTWall(startPoint)) || startPoint.canMergeEdges(true)
      const endDetached =
        (endPoint.edges.size === 3 && !edge.isTWall(endPoint)) || endPoint.canMergeEdges(true)

      if (startDetached) {
        detachedStart = this.detachPointForThicknessChange(startPoint, edge)
      }

      if (endDetached) {
        detachedEnd = this.detachPointForThicknessChange(endPoint, edge)
      }

      const startToMove = detachedStart ?? startPoint
      startToMove.set(startToMove.add(pointDisplacement))

      const endToMove = detachedEnd ?? endPoint
      endToMove.set(endToMove.add(pointDisplacement))
    }

    edge.thickness = thickness

    // checking whether moved points could be merged with other meshpoints after changing width
    this.getAllEdges()
      .filter((e) => e !== edge)
      .forEach((e) => {
        const disconnectedPoints = edge.getCloseDisconnectedPoints(e)
        if (!disconnectedPoints) {
          return
        }

        // see https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/7302/T-walls-with-different-thickness?anchor=merging-walls-after-width-change for explanation
        if (
          (disconnectedPoints[1].getDistance(disconnectedPoints[0]) === 0 &&
            e.isParallelTo(edge)) ||
          disconnectedPoints[1].subtract(disconnectedPoints[0]).getAngle(edge.getDirection()) %
            180 ===
            0
        ) {
          if (disconnectedPoints[0].edges.size === 1) {
            disconnectedPoints[0].set(disconnectedPoints[1])
            this.mergePoints(disconnectedPoints[0], disconnectedPoints[1])
          }
          if (disconnectedPoints[1].edges.size === 1) {
            disconnectedPoints[1].set(disconnectedPoints[0])
            this.mergePoints(disconnectedPoints[0], disconnectedPoints[1])
          }
        }
      })
  }

  /**
   * When the thickness of an edge is changed, a meshpoint of the edge might need to be detached from its neighbors.
   *
   * @param pointToDetach either the end or the start point of the edge, this point will be detached from its neighbors
   * @param edge the edge with the changed thickness
   * @returns the detached point if the point could be successfully detached, undefined otherwise
   */
  private detachPointForThicknessChange(
    pointToDetach: MeshPoint,
    edge: Edge
  ): MeshPoint | undefined {
    const detachedPoint = pointToDetach.detachFromEdge(edge)
    if (!detachedPoint) {
      return undefined
    }

    this.mesh.points.add(detachedPoint)
    // in case of a t-wall, disconnected endpoint has to be set to the right location to be shown as connected
    if (pointToDetach.edges.size === 2) {
      const perpendicularEdge = [...pointToDetach.edges].find((e) => e.isPerpendicular(edge))
      if (perpendicularEdge) {
        const dirVector = detachedPoint.subtract(edge.getOtherPoint(detachedPoint))
        detachedPoint.set(
          detachedPoint.subtract(dirVector.normalize(perpendicularEdge.thickness / 2))
        )
      }
    }

    return detachedPoint
  }

  protected rasterOnEdge(point: paper.Point, edge: Edge, movedEdge: Edge): paper.Point {
    // re-calculating path for correct distances
    this.generatePath()

    // creating new edges to calculate offsets (normally the moved edge is detached)
    const newEndPoint = asMeshPoint(point, this.mesh)
    const originalEndPoint =
      newEndPoint.getDistance(movedEdge.startPoint) < newEndPoint.getDistance(movedEdge.endPoint)
        ? movedEdge.startPoint
        : movedEdge.endPoint
    const originalStartPoint = movedEdge.getOtherPoint(originalEndPoint)
    const moveVector = newEndPoint.subtract(originalEndPoint)
    const newStartPoint = asMeshPoint(originalStartPoint.add(moveVector), this.mesh)

    const tWall = new Edge(newStartPoint, newEndPoint, movedEdge.thickness)
    const edge1 = new Edge(edge.startPoint, newEndPoint, edge.thickness)
    const edge2 = new Edge(edge.endPoint, newEndPoint, edge.thickness)

    // searching for the lengths belonging to the edge
    const lengthSegments = this.getWallLengthSegments(edge)
    const tWallSide = lengthSegments.sort(
      (s1, s2) =>
        s1.curve.getNearestPoint(newStartPoint).getDistance(newStartPoint) -
        s2.curve.getNearestPoint(newStartPoint).getDistance(newStartPoint)
    )[0]

    const edgeVector = edge.endPoint.subtract(edge.startPoint)
    const extendedEdgeLine = new paper.Path.Line(
      edge.startPoint.subtract(edgeVector),
      edge.endPoint.add(edgeVector)
    )

    const start = extendedEdgeLine.getNearestPoint(tWallSide.curve.point1)
    const end = extendedEdgeLine.getNearestPoint(tWallSide.curve.point2)

    // calculating offsets
    const angleOffset1 = tWall.getAngleOffset(edge1)
    const outerIntersectionOffset1 = tWall.getOuterIntersectionOffset(edge1)
    const innerCornerVector1 = point
      .subtract(edge1.startPoint)
      .normalize(-outerIntersectionOffset1 - angleOffset1)
    const innerCorner1 = point.add(innerCornerVector1)

    const angleOffset2 = tWall.getAngleOffset(edge2)
    const outerIntersectionOffset2 = tWall.getOuterIntersectionOffset(edge2)
    const innerCornerVector2 = point
      .subtract(edge2.startPoint)
      .normalize(-outerIntersectionOffset2 - angleOffset2)
    const innerCorner2 = point.add(innerCornerVector2)

    let distance1: number, distance2: number
    let correctionVector1: paper.Point, correctionVector2: paper.Point
    if (innerCorner1.getDistance(start) < innerCorner2.getDistance(start)) {
      distance1 = innerCorner1.getDistance(start)
      distance2 = innerCorner2.getDistance(end)
      correctionVector1 = end
      correctionVector2 = start
    } else {
      distance1 = innerCorner1.getDistance(end)
      distance2 = innerCorner2.getDistance(start)
      correctionVector1 = start
      correctionVector2 = end
    }

    // rastering lengths
    const correctedDistance1 =
      Math.round(distance1 / this.drawSetting.lengthRastering) * this.drawSetting.lengthRastering
    const correction1 = correctedDistance1 - distance1
    correctionVector1 = correctionVector1.subtract(innerCorner1).normalize(correction1)
    const rasteredPoint1 = innerCorner1.add(correctionVector1).subtract(innerCornerVector1)

    const correctedDistance2 =
      Math.round(distance2 / this.drawSetting.lengthRastering) * this.drawSetting.lengthRastering
    const correction2 = correctedDistance2 - distance2
    correctionVector2 = correctionVector2.subtract(innerCorner2).normalize(correction2)
    const rasteredPoint2 = innerCorner2.add(correctionVector2).subtract(innerCornerVector2)

    return rasteredPoint1.getDistance(point) < rasteredPoint2.getDistance(point)
      ? rasteredPoint1
      : rasteredPoint2
  }

  protected snapToEdgeOrMeshPoint(
    movedPoint: paper.Point,
    originalPoint: MeshPoint,
    edges: Edge[]
  ): SnapResult {
    const snapToEdgeResult = this.mesh.snapPointToEdge(movedPoint, edges)
    if (!snapToEdgeResult) {
      return undefined
    }

    let snapToMeshPoint: MeshPoint | undefined
    const distanceToStart = snapToEdgeResult.edge.startPoint.getDistance(snapToEdgeResult.point)
    const distanceToEnd = snapToEdgeResult.edge.endPoint.getDistance(snapToEdgeResult.point)
    if (distanceToStart <= SNAPPING_DISTANCE || distanceToEnd <= SNAPPING_DISTANCE) {
      snapToMeshPoint =
        distanceToStart <= distanceToEnd
          ? snapToEdgeResult.edge.startPoint
          : snapToEdgeResult.edge.endPoint
    }

    const movedEdge = [...originalPoint.edges][0]
    const otherPoint = movedEdge.getOtherPoint(originalPoint)

    const snapMoveVector = snapToEdgeResult.point.subtract(originalPoint).normalize()
    const snappedEdgeDirection = snapToEdgeResult.edge.getDirection().normalize()
    const moveVectorParallel =
      snapMoveVector.isClose(snappedEdgeDirection, SNAPPING_ERROR_TOLERANCE) ||
      snapMoveVector.isClose(snappedEdgeDirection.multiply(-1), SNAPPING_ERROR_TOLERANCE)
    const hasParallelNeighbor = [...otherPoint.edges].some(
      (e) => e.isParallelTo(snapToEdgeResult.edge) && edges.includes(e)
    )
    const shouldStayOnEdge = moveVectorParallel || hasParallelNeighbor

    if (snapToMeshPoint) {
      return this.snapToMeshPoint(originalPoint, movedPoint, snapToMeshPoint, shouldStayOnEdge)
    } else {
      return this.extendToEdge(originalPoint, movedPoint, snapToEdgeResult, shouldStayOnEdge)
    }
  }

  /**
   * snaps a point to another mesh point
   * @param originalPoint - the original mesh point we are snapping
   * @param movedPoint - the new position of the original mesh point
   * @param snapResult - the mesh point we are snapping to
   * @param shouldStayOnEdge - if true, the length of the moved edge shouldn't change
   * @see https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/4283/Technical-Documentation?anchor=extendedmeshpoints
   */
  private snapToMeshPoint(
    originalPoint: MeshPoint,
    movedPoint: paper.Point,
    snapResult: MeshPoint,
    shouldStayOnEdge: boolean = false
  ): ExtendedMeshPoint | MeshPoint {
    // only extend mesh point if both mesh points are open ended (otherwise angles would be changed)
    if (originalPoint.edges.size !== 1 || snapResult.edges.size > 2) {
      return snapResult
    }

    const movedEdge = [...originalPoint.edges][0]
    const parallelEdge = [...snapResult.edges].find((e) => e.isParallelTo(movedEdge))
    if (parallelEdge) {
      // if snapped to rectangular corner of two other edges a special snapping case is allowed
      if (snapResult.edges.size === 2) {
        const perpendicularEdge = [...snapResult.edges].find((e) => e.isPerpendicular(movedEdge))
        if (!perpendicularEdge) {
          return snapResult
        }

        if (
          perpendicularEdge.thickness === movedEdge.thickness &&
          parallelEdge.thickness === movedEdge.thickness
        ) {
          return snapResult
        }

        const movedEdgeVector = originalPoint
          .subtract(movedEdge.getOtherPoint(originalPoint))
          .normalize()
        const parallelEdgeVector = snapResult
          .subtract(parallelEdge.getOtherPoint(snapResult))
          .normalize()
        if (movedEdgeVector.isClose(parallelEdgeVector, SNAPPING_ERROR_TOLERANCE)) {
          return snapResult
        }

        const perpendicularEdgeVector = snapResult.subtract(
          perpendicularEdge.getOtherPoint(snapResult)
        )

        const cornerPoint = snapResult
          .add(parallelEdgeVector.normalize(perpendicularEdge.thickness / 2))
          .add(
            perpendicularEdgeVector.normalize(parallelEdge.thickness / 2 - movedEdge.thickness / 2)
          )

        return movedPoint.getDistance(cornerPoint) < movedPoint.getDistance(snapResult)
          ? asMeshPoint(cornerPoint, this.mesh)
          : snapResult
      } else {
        const widthVector = parallelEdge
          .getDirection()
          .rotate(90)
          .normalize(parallelEdge.thickness / 2 - movedEdge.thickness / 2)
        const corner1 = snapResult.add(widthVector)
        const corner2 = snapResult.subtract(widthVector)

        return [snapResult, asMeshPoint(corner1, this.mesh), asMeshPoint(corner2, this.mesh)].sort(
          (p1, p2) => p1.getDistance(movedPoint) - p2.getDistance(movedPoint)
        )[0]
      }
    }

    return this.extendToMeshPoint(originalPoint, movedPoint, snapResult, shouldStayOnEdge)
  }

  /**
   * Extends a mesh point to the closest of 4 possible locations
   * @param originalPoint - the point that is being moved
   * @param movedPoint - the position that is used for snapping
   * @param snapResult - the mesh point that we snapped to
   * @param shouldStayOnEdge - if true, the length of the moved edge shouldn't change
   * @see https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/4283/Technical-Documentation?anchor=extendedmeshpoints
   */
  private extendToMeshPoint(
    originalPoint: MeshPoint,
    movedPoint: paper.Point,
    snapResult: MeshPoint,
    shouldStayOnEdge: boolean = false
  ): ExtendedMeshPoint | MeshPoint {
    const movedEdge = [...originalPoint.edges][0]
    const snapToEdge = [...snapResult.edges][0]

    const otherPointOfMovedEdge = movedEdge.getOtherPoint(originalPoint)
    const snapVector = snapResult.subtract(originalPoint)
    let resultPoint: paper.Point
    const snappedMovingEdge = new Edge(
      snapResult,
      asMeshPoint(otherPointOfMovedEdge.add(snapVector), this.mesh),
      movedEdge.thickness
    )

    const angleOffset = snapToEdge.getAngleOffset(snappedMovingEdge)
    const outerIntersectionOffset = snapToEdge.getOuterIntersectionOffset(snappedMovingEdge)
    const movedInnerVector = snapResult
      .subtract(snappedMovingEdge.getOtherPoint(snapResult))
      .normalize(-outerIntersectionOffset - angleOffset)

    const movedOuterVector = snapResult
      .subtract(snappedMovingEdge.getOtherPoint(snapResult))
      .normalize(+outerIntersectionOffset + angleOffset)

    const movedInnerPoint = snapResult.add(movedInnerVector)
    const movedOuterPoint = snapResult.add(movedOuterVector)

    const distanceToMovedInnerPoint = movedPoint.getDistance(movedInnerPoint)
    const distanceToMovedOuterPoint = movedPoint.getDistance(movedOuterPoint)

    // calculating vectors to move the endpoint of the static edge to retain original length
    const staticAngleOffset = snappedMovingEdge.getAngleOffset(snapToEdge)
    const staticOuterIntersectionOffset = snappedMovingEdge.getOuterIntersectionOffset(snapToEdge)

    const staticInnerVector = snapResult
      .subtract(snapToEdge.getOtherPoint(snapResult))
      .normalize(-staticOuterIntersectionOffset - staticAngleOffset)

    const staticOuterVector = snapResult
      .subtract(snapToEdge.getOtherPoint(snapResult))
      .normalize(+staticOuterIntersectionOffset + staticAngleOffset)

    const staticInnerPoint = snapResult.add(staticInnerVector)
    const staticOuterPoint = snapResult.add(staticOuterVector)

    const distanceToStaticInnerPoint = movedPoint.getDistance(staticInnerPoint)
    const distanceToStaticOuterPoint = movedPoint.getDistance(staticOuterPoint)

    if (!shouldStayOnEdge) {
      if (distanceToStaticInnerPoint < distanceToStaticOuterPoint) {
        if (distanceToMovedInnerPoint < distanceToMovedOuterPoint) {
          resultPoint = staticInnerPoint.add(movedInnerVector)
        } else {
          resultPoint = staticInnerPoint.add(movedOuterVector)
        }
        return toExtendedMeshPoint(
          resultPoint,
          this.mesh,
          originalPoint,
          staticInnerPoint,
          snapResult
        )
      } else {
        if (distanceToMovedInnerPoint < distanceToMovedOuterPoint) {
          resultPoint = staticOuterPoint.add(movedInnerVector)
        } else {
          resultPoint = staticOuterPoint.add(movedOuterVector)
        }
        return toExtendedMeshPoint(
          resultPoint,
          this.mesh,
          originalPoint,
          staticOuterPoint,
          snapResult
        )
      }
    } else {
      if (distanceToStaticInnerPoint < distanceToStaticOuterPoint) {
        return toExtendedMeshPoint(
          staticInnerPoint,
          this.mesh,
          originalPoint,
          staticInnerPoint,
          snapResult
        )
      } else {
        return toExtendedMeshPoint(
          staticOuterPoint,
          this.mesh,
          originalPoint,
          staticOuterPoint,
          snapResult
        )
      }
    }
  }

  /**
   * Extends a mesh point to one of two sides of an edge
   * @param originalPoint - the point that is being moved
   * @param movedPoint - the position that is used for snapping
   * @param snapResult - the snap result containing the edge to which the point snapped
   * @param shouldStayOnEdge - if true, the point should stay on the edge and no extension is needed
   */
  private extendToEdge(
    originalPoint: MeshPoint,
    movedPoint: paper.Point,
    snapResult: SnapEdgeInfo,
    shouldStayOnEdge: boolean = false
  ): ExtendedMeshPoint | paper.Point {
    const movedEdge = [...originalPoint.edges][0]
    const otherPoint = movedEdge.getOtherPoint(originalPoint)
    const rasteredPoint = this.rasterOnEdge(snapResult.point, snapResult.edge, movedEdge)

    if (!shouldStayOnEdge && originalPoint.edges.size === 1) {
      const edgeLineLeft = snapResult.edge.getLineOnSide(LineSide.LEFT)
      const edgeLineRight = snapResult.edge.getLineOnSide(LineSide.RIGHT)
      const lineLeft = new paper.Path.Line(
        edgeLineLeft[LinePosition.START],
        edgeLineLeft[LinePosition.END]
      )
      const lineRight = new paper.Path.Line(
        edgeLineRight[LinePosition.START],
        edgeLineRight[LinePosition.END]
      )

      if (movedEdge.isPerpendicular(snapResult.edge)) {
        const leftSnapPoint = lineLeft.getNearestPoint(movedPoint)
        const rightSnapPoint = lineRight.getNearestPoint(movedPoint)
        const snapPoint =
          leftSnapPoint.getDistance(movedPoint) < rightSnapPoint.getDistance(movedPoint)
            ? leftSnapPoint
            : rightSnapPoint
        const rasterVector = rasteredPoint.subtract(snapResult.point)
        return toExtendedMeshPoint(
          snapPoint.add(rasterVector),
          this.mesh,
          originalPoint,
          rasteredPoint
        )
      } else if (!movedEdge.isParallelTo(snapResult.edge)) {
        const thicknessVector = movedEdge
          .getDirection()
          .rotate(90)
          .normalize(movedEdge.thickness / 2)
        const corner1 = movedPoint.add(thicknessVector)
        const corner2 = movedPoint.subtract(thicknessVector)

        const movedOtherPoint = otherPoint.add(movedPoint.subtract(originalPoint))
        const snapLine =
          lineLeft.getNearestPoint(movedOtherPoint).getDistance(movedOtherPoint) <
          lineRight.getNearestPoint(movedOtherPoint).getDistance(movedOtherPoint)
            ? lineLeft
            : lineRight

        const snappedCorner1 = snapLine.getNearestPoint(corner1)
        const snappedCorner2 = snapLine.getNearestPoint(corner2)

        let corner, snappedCorner
        if (snappedCorner1.getDistance(corner1) < snappedCorner2.getDistance(corner2)) {
          corner = corner1
          snappedCorner = snappedCorner1
        } else {
          corner = corner2
          snappedCorner = snappedCorner2
        }

        const cornerSnapVector = snappedCorner.subtract(corner)
        const insertionPoint = intersectLines(
          {
            start: movedPoint.add(cornerSnapVector),
            end: movedOtherPoint.add(cornerSnapVector),
          },
          {
            start: snapResult.edge.startPoint,
            end: snapResult.edge.endPoint,
          }
        )

        if (insertionPoint) {
          const snapPoint = movedPoint.add(cornerSnapVector)
          const rasteredInsertionPoint = this.rasterOnEdge(
            insertionPoint,
            snapResult.edge,
            movedEdge
          )
          const rasterVector = rasteredInsertionPoint.subtract(insertionPoint)
          return toExtendedMeshPoint(
            snapPoint.add(rasterVector),
            this.mesh,
            originalPoint,
            rasteredInsertionPoint
          )
        }
      }
    }
    return rasteredPoint
  }

  protected updateEdgeLength(
    edge: Edge,
    outerLength: number,
    lengthDirection: TransformationLengthDirection
  ): void {
    edge.changeLength(outerLength, lengthDirection)
    // Check if any open ends are close to another edge and we can combine them
    const openEnds = [edge.startPoint, edge.endPoint].filter((it) => it.edges.size === 1)
    if (openEnds.length > 0) {
      openEnds.forEach((openEnd) => {
        const start = edge.startPoint === openEnd ? edge.startPoint : edge.endPoint
        const end = edge.getOtherPoint(start)

        this.createEdgeInMesh(start, end, edge.thickness, true, false, edge)
      })
    }
    this.splitOverlappingWalls(edge)
  }

  /**
   * Creates a completely new edge or replaces an edge. See: https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/7325/Edge-creation-modification
   * @param startPoint the start point of the edge (usually where the user starts the drag interaction)
   * @param endPoint the end point of the edge (usually where the user ends the drag interaction)
   * @param thickness the thickness of the created edge
   * @param skipEndSnapping if set to true, the end point of the new edge is taken as it is (and is not snapped)
   * @param updateAnchor if set to true, the updateAnchorPositions function is called and the start point is adjusted to keep the angles between edges
   * @param intoEdge if provided, this edge will be replaced by the newly created edge (in case the length / width / angle of that edge is changed, we replace it and create a new one)
   */
  protected createEdgeInMesh(
    startPoint: MeshPoint | paper.Point,
    endPoint: MeshPoint | paper.Point,
    thickness: number,
    skipEndSnapping: boolean,
    updateAnchor: boolean = true,
    intoEdge?: Edge
  ): Edge | undefined {
    const startMeshPoint: MeshPoint = this.determineNewEdgeStartPoint(startPoint, intoEdge)
    const startCorner = getCornerOfSingleNeighbor(startMeshPoint, endPoint, thickness)

    // calculating whether the updateAnchorPositions function should be called at the end (if the angle is close to 180° and the edges have the same thickness, the function is not called)
    let shouldRotateOuterSide = true
    if (startMeshPoint.edges.size > 0) {
      const neighborEdge = [...startMeshPoint.edges][0]
      const edgeDirection = endPoint.subtract(startMeshPoint)
      const neighborOtherPoint = neighborEdge.getOtherPoint(startMeshPoint)
      const neighborDirection = neighborEdge.getDirection(
        neighborEdge.getPosition(neighborOtherPoint)
      )
      if (
        neighborEdge.thickness !== thickness &&
        edgeDirection.getAngle(neighborDirection) <= SNAP_ANGLE_INTERVAL / 2
      ) {
        shouldRotateOuterSide = false
      }
    }

    const endMeshPoint = skipEndSnapping
      ? asMeshPoint(endPoint, this.mesh)
      : this.determineNewEdgeEndPoint(
          endPoint,
          thickness,
          startMeshPoint,
          shouldRotateOuterSide,
          startCorner,
          intoEdge
        )
    const endCorner = getCornerOfSingleNeighbor(endMeshPoint, startMeshPoint, thickness)

    const edge = this.mesh.setEdge(startMeshPoint, endMeshPoint, thickness, intoEdge)
    if (edge) {
      edge.thickness = thickness

      // updating the positions of the start and end point so that the correct angle is kept
      if (updateAnchor && shouldRotateOuterSide) {
        updateAnchorPositions(edge, startCorner, endCorner)
      }

      // if the new edge is crossing another edge, the edges are split
      this.splitOverlappingWalls(edge)
    }

    return edge
  }

  /**
   * Determines the position of the start point of a newly created edge
   * @param point the original start point (usually where the user stops the drag interaction)
   * @param intoEdge if provided, the new edge will replace intoEdge
   */
  private determineNewEdgeStartPoint(point: paper.Point, intoEdge?: Edge): MeshPoint {
    const snappedStart = this.mesh.snapPointToEdgeOrPoint(point)
    if (isSnapEdgeInfo(snappedStart)) {
      // snap to edge
      return this.insertPointOnEdge(snappedStart.point, snappedStart.edge)
    } else if (snappedStart instanceof MeshPoint) {
      let isSnappedToDisconnected = false
      if (intoEdge) {
        isSnappedToDisconnected = [...snappedStart.edges].some((e) => {
          const closeDisconnectedPoints = e.getCloseDisconnectedPoints(intoEdge)
          return !!(closeDisconnectedPoints && closeDisconnectedPoints[0] === snappedStart)
        })
      }
      // snap to existing point or side of width side of wall
      return isSnappedToDisconnected ? asMeshPoint(point, this.mesh) : snappedStart
    } else {
      // take the point as is
      return asMeshPoint(point, this.mesh)
    }
  }

  /**
   * Snaps the end point of an edge to possible other edges / mesh points / guidelines, determining the correct position
   * @link https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/7325/Edge-creation-modification
   * @param endPoint - the original end point before snapping (usually where the user stops the drag interaction)
   * @param thickness - the thickness of the (newly created) edge
   * @param startMeshPoint - the start point of the edge
   * @param shouldSnapWithAnchoring - if set true, the edge's length and angle is snapped with the snapAnchoredPoint function - assuming it has neighbors
   * @param startCorner - the calculated outer corner at the start of the edge
   * @param intoEdge - if given, the newly created edge will replace this edge
   */
  private determineNewEdgeEndPoint(
    endPoint: paper.Point,
    thickness: number,
    startMeshPoint: MeshPoint,
    shouldSnapWithAnchoring: boolean,
    startCorner?: paper.Point,
    intoEdge?: Edge
  ): MeshPoint {
    // snapping the end point with length and angle rastering
    let lengthSnappedPoint: paper.Point | undefined
    const freePoint = snapFreePoint(
      endPoint,
      startMeshPoint,
      PlanType.WALL,
      this.drawSetting.angleRastering,
      this.drawSetting.lengthRastering
    )

    const neighborEdge = [...startMeshPoint.edges][0]
    let anchoredDirection: paper.Point | undefined
    // if the start point has no other edges, the end point is snapped as a free point
    if (startMeshPoint.edges.size === 0 || !shouldSnapWithAnchoring) {
      lengthSnappedPoint = freePoint
    } else {
      // otherwise the end point is snapped as an anchored point
      const snapResult = snapAnchoredPoint(
        startMeshPoint,
        endPoint,
        thickness,
        PlanType.WALL,
        this.drawSetting.angleRastering,
        this.drawSetting.lengthRastering,
        startCorner,
        intoEdge
      )
      if (snapResult) {
        lengthSnappedPoint = snapResult.point
        anchoredDirection = snapResult.anchoredDirection
      }
    }

    // if the length and angle snapping is unsuccessful, the point is simply returned
    if (!lengthSnappedPoint) {
      return asMeshPoint(endPoint, this.mesh)
    }

    anchoredDirection = anchoredDirection ?? lengthSnappedPoint.subtract(startMeshPoint).normalize()
    const direction = this.getDirectionFromSnappingPoint(
      lengthSnappedPoint,
      startMeshPoint,
      thickness,
      startCorner,
      neighborEdge
    )

    // snapping the end point to guidelines
    const snappedGuideLinePoint = this.snapPointToAuxiliaryGuideline(
      lengthSnappedPoint,
      direction,
      this.getAllEdges()
    )

    const snappedEndPoint = snappedGuideLinePoint ?? lengthSnappedPoint
    let closeEdgeMeshPoint: MeshPoint | undefined
    let intersectionPoint: paper.Point | undefined

    // snapping the end point to edges (new point can be inserted on an edge)
    const snapToEdge = this.mesh.snapPointToEdge(snappedEndPoint)
    if (snapToEdge) {
      // before updateAnchorPositions is called the direction of the edge is not final, therefore we need to use the anchoredDirection instead of the direction of the edge
      const correctedAnchorPoint = snappedEndPoint.add(anchoredDirection.rotate(180))

      // calculating the intersection point (the new point will be inserted on the edge in this position)
      intersectionPoint = intersectLines(
        { start: correctedAnchorPoint, end: snappedEndPoint },
        { start: snapToEdge.edge.startPoint, end: snapToEdge.edge.endPoint }
      )

      // // search for close mesh points on the edge the point has been snapped to
      if (intersectionPoint) {
        closeEdgeMeshPoint = this.mesh.snapPointToMeshPoints(
          intersectionPoint,
          thickness / 2 + SNAPPING_ERROR_TOLERANCE
        )
        // another wall is made longer or shorter and snapped to the currently created
        // only snap to open ended walls (don't move mesh points of corners)
        if (closeEdgeMeshPoint && closeEdgeMeshPoint.edges.size === 1) {
          const extendedMeshPoint = this.insertPointOnEdge(intersectionPoint, snapToEdge.edge)
          this.mesh.setEdge(startMeshPoint, extendedMeshPoint, thickness, intoEdge)
          this.mergePoints(extendedMeshPoint, closeEdgeMeshPoint)
          return extendedMeshPoint
        }
      }
    }

    // snap to mesh points
    const snapToMeshPoint = this.mesh.snapPointToMeshPoints(endPoint)
    if (snapToMeshPoint) {
      this.mesh.setEdge(startMeshPoint, snapToMeshPoint, thickness, intoEdge)
      return snapToMeshPoint
    }

    // snap to edges - intersection point is inserted on edge if it is not too close to the start or end point of that edge
    if (
      intersectionPoint &&
      snapToEdge &&
      !snapToEdge.edge.hasPoint(snapToEdge.point) &&
      !closeEdgeMeshPoint
    ) {
      return this.insertPointOnEdge(intersectionPoint, snapToEdge.edge)
    }

    // snapping length & angles or to guidelines by default
    const endMeshPoint = asMeshPoint(endPoint, this.mesh)
    endMeshPoint.set(snappedEndPoint)
    return endMeshPoint
  }

  /**
   * documentation: https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/4283/Technical-Documentation?anchor=getdirectionfromsnappingpoint
   */
  private getDirectionFromSnappingPoint(
    snappingPoint: paper.Point,
    anchorPoint: MeshPoint,
    edgeThickness: number,
    outerCorner?: paper.Point,
    neighborEdge?: Edge
  ): paper.Point {
    if (outerCorner == null || outerCorner.equals(anchorPoint) || neighborEdge == null) {
      return snappingPoint.subtract(anchorPoint).normalize()
    }

    const diagonal = snappingPoint.subtract(outerCorner)
    const diagonalVector = diagonal.normalize()
    const neighborVector = neighborEdge.getOtherPoint(anchorPoint).subtract(anchorPoint).normalize()

    const dot = neighborVector.x * -diagonalVector.y + neighborVector.y * diagonalVector.x
    const sign = dot < 0 ? 1 : -1
    const angle = sign * radiansToDegrees(Math.asin(edgeThickness / 2 / diagonal.length))

    return diagonal.rotate(angle).normalize()
  }

  protected updatedEdgesAfterMove(movedEdges: Edge[]): Edge[] {
    // If we snapped to the middle of another wall, we'll need to split it
    const newEdges = movedEdges.map((it) => this.splitOverlappingWalls(it)).flat()

    this.mesh.points.forEach((point) => {
      const closePoint = Array.from(this.mesh.points).find(
        (other) => point !== other && point.isClose(other, SNAPPING_ERROR_TOLERANCE)
      )
      if (closePoint) {
        this.mergePoints(point, closePoint)
      }
    })

    newEdges.forEach((edge) => {
      if (edge.length() <= this.drawSetting.wallThickness) {
        if (edge.endPoint.edges.size === 1) {
          this.mergePoints(edge.startPoint, edge.endPoint)
        } else if (edge.startPoint.edges.size === 1) {
          this.mergePoints(edge.endPoint, edge.startPoint)
        }
      }
    })
    return newEdges
  }

  /**
   * Check if the given wall has other walls intersecting it and split them, if possible.
   *
   * @param edge The edge to check for intersections.
   * @return returns all edges resulting from the split, if any are split.
   */
  private splitOverlappingWalls(edge: Edge): Edge[] {
    for (const otherEdge of this.getAllEdges()) {
      if (edge === otherEdge || edge.getConnectedPoint(otherEdge)) {
        continue
      }

      const intersectionPoint = intersectLines(
        { start: edge.startPoint, end: edge.endPoint },
        { start: otherEdge.startPoint, end: otherEdge.endPoint }
      )

      if (!intersectionPoint) {
        continue
      }

      let point: MeshPoint | undefined

      const newEdges: Edge[] = []

      // point is on the edge
      if (
        isIntersectionOnEdge(intersectionPoint, edge, edge.thickness - SNAPPING_ERROR_TOLERANCE)
      ) {
        // point is on the other edge too - both walls are split
        if (
          isIntersectionOnEdge(
            intersectionPoint,
            otherEdge,
            otherEdge.thickness - SNAPPING_ERROR_TOLERANCE
          )
        ) {
          point = this.insertPointOnEdge(intersectionPoint, edge)
          const otherInsertPoint = this.insertPointOnEdge(intersectionPoint, otherEdge)
          newEdges.push(
            ...Array.from(otherInsertPoint.edges).filter((it) => it !== edge && it !== otherEdge)
          )
          this.mergePoints(otherInsertPoint, point)
        } else if (
          intersectionPoint.getDistance(otherEdge.startPoint) <=
          edge.thickness / 2 + SNAPPING_ERROR_TOLERANCE
        ) {
          // start point of other edge inserted
          point = this.insertPointOnEdge(intersectionPoint, edge)
          this.mergePoints(point, otherEdge.startPoint)
        } else if (
          intersectionPoint.getDistance(otherEdge.endPoint) <=
          edge.thickness / 2 + SNAPPING_ERROR_TOLERANCE
        ) {
          // end point of other edge inserted
          point = this.insertPointOnEdge(intersectionPoint, edge)
          this.mergePoints(point, otherEdge.endPoint)
        }
      } else if (
        isIntersectionOnEdge(
          intersectionPoint,
          otherEdge,
          edge.thickness > otherEdge.thickness
            ? edge.thickness / 2 - SNAPPING_ERROR_TOLERANCE
            : otherEdge.thickness / 2 - SNAPPING_ERROR_TOLERANCE
        )
      ) {
        // point is only intersection on other edge
        if (
          intersectionPoint.getDistance(edge.startPoint) <=
          otherEdge.thickness / 2 + SNAPPING_ERROR_TOLERANCE
        ) {
          point = this.insertPointOnEdge(intersectionPoint, otherEdge)
          this.mergePoints(point, edge.startPoint)
        } else if (
          intersectionPoint.getDistance(edge.endPoint) <=
          otherEdge.thickness / 2 + SNAPPING_ERROR_TOLERANCE
        ) {
          point = this.insertPointOnEdge(intersectionPoint, otherEdge)
          this.mergePoints(point, edge.endPoint)
        }
      }

      if (point) {
        newEdges.push(...Array.from(point.edges).filter((it) => it !== edge && it !== otherEdge))
        point.edges.forEach((it) => newEdges.push(...this.splitOverlappingWalls(it)))

        return newEdges
      }
    }

    return []
  }

  private getWallLengthSegments(edge: Edge): paper.Segment[] {
    const edgeVector = edge.endPoint.subtract(edge.startPoint)
    const segments: paper.Segment[] = []
    if (this.pathWithEdgeMap) {
      this.pathWithEdgeMap.edgeMap.forEach((pathSteps, segment) => {
        if (pathSteps.some((it) => it.edge === edge)) {
          segments.push(segment)
        }
      })
    }

    return segments.filter((it) => !!it.curve && it.curve.getTangentAt(0).isCollinear(edgeVector))
  }
}

const getCornerOfSingleNeighbor = (
  anchorPoint: MeshPoint,
  otherPoint: paper.Point,
  thickness: number
): paper.Point | undefined => {
  if (anchorPoint.edges.size !== 1) {
    return undefined
  }

  const neighborEdge = Array.from(anchorPoint.edges)[0]
  return calculateOuterCornerPoint(anchorPoint, {
    edge: neighborEdge,
    anchor: anchorPoint,
    destination: otherPoint,
    thickness,
  })
}

const isIntersectionOnEdge = (
  intersection: paper.Point,
  edge: Edge,
  tolerance: number
): boolean => {
  const distanceToStart = intersection.getDistance(edge.startPoint)
  const distanceToEnd = intersection.getDistance(edge.endPoint)
  const edgeLine = new paper.Path.Line(edge.startPoint, edge.endPoint)
  const distanceToEdge = edgeLine.getNearestPoint(intersection).getDistance(intersection)

  return (
    distanceToStart >= tolerance &&
    distanceToEnd >= tolerance &&
    distanceToEnd <= edge.startPoint.getDistance(edge.endPoint) &&
    distanceToStart <= edge.startPoint.getDistance(edge.endPoint) &&
    distanceToEdge <= edge.thickness / 2
  )
}
