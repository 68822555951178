import { intersectLines, Line, SNAPPING_ERROR_TOLERANCE } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { flatMap } from '../flatMap'

export class Line2D implements Line {
  constructor(public readonly start: paper.Point, public readonly end: paper.Point) {}

  // returns the slope of the line equation (y = mx + c => the m is the slope); null for vertical lines
  public getSlope(): number | null {
    const diffX = this.end.x - this.start.x

    if (Math.abs(diffX) < SNAPPING_ERROR_TOLERANCE) {
      return null
    }

    return (this.end.y - this.start.y) / diffX
  }

  // returns the y-intercept of the line equation (y = mx + c => the c is the y-intercept); null for vertical lines
  public getVerticalIntercept(): number | null {
    const m = this.getSlope()
    if (m == null) {
      return null
    }
    return this.start.y - m * this.start.x
  }

  public getDistanceToParallelLine(other: Line2D): number | null {
    if (!this.isParallelTo(other)) {
      return null
    }

    const m = this.getSlope()
    const c1 = this.getVerticalIntercept() ?? 0
    const c2 = other.getVerticalIntercept() ?? 0

    if (m == null) {
      return Math.abs(this.start.x - other.start.x)
    }

    if (
      Math.abs(c2 - c1) < SNAPPING_ERROR_TOLERANCE &&
      Math.abs(Math.pow(m, 2) - 1) < SNAPPING_ERROR_TOLERANCE
    ) {
      return 0
    }

    return Math.abs(Math.abs(c2 - c1) / Math.sqrt(Math.pow(m, 2) + 1))
  }

  /**
   * documentation https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/4283/Technical-Documentation?anchor=findoverlappinglines
   * @param lines the lines to check against
   * @param tolerance the maximum distance (exclusive) between this and any other line
   */
  public findOverlappingLines(
    lines: Line2D[],
    tolerance: number = SNAPPING_ERROR_TOLERANCE
  ): Line2D[] {
    return lines
      .map((otherLine) => {
        const distance = this.getDistanceToParallelLine(otherLine) ?? Number.MAX_VALUE
        return [otherLine, distance]
      })
      .filter(([, distance]) => (distance as number) < tolerance)
      .sort(([, dist1], [, dist2]) => (dist1 as number) - (dist2 as number))
      .map(([otherLine]) => otherLine) as Line2D[]
  }

  public isParallelTo(other: Line2D): boolean {
    const m1 = this.getSlope()
    const m2 = other.getSlope()

    // handle vertical lines which have an undefined slope
    if (m1 == null && m2 == null) {
      return true
    } else if (m1 == null || m2 == null) {
      return false
    }

    return Math.abs(m1 - m2) <= SNAPPING_ERROR_TOLERANCE
  }

  public intersect(other: Line2D): paper.Point | undefined {
    return intersectLines(this, other)
  }

  public extend(other: Line2D): Line2D {
    const startPoints = [this.start, other.start]

    const endPoints = [this.end, other.end]

    return flatMap(
      startPoints.map((startPoint) => endPoints.map((endPoint) => new Line2D(startPoint, endPoint)))
    ).reduce((line1, line2) => (line1.getLength() > line2.getLength() ? line1 : line2))
  }

  public getLength(): number {
    return this.start.getDistance(this.end)
  }

  public equals(other: Line2D): boolean {
    return this.start.equals(other.start) && this.end.equals(other.end)
  }
}
