import { inject, Injectable } from '@angular/core'
import { Country, PrivacyPolicyApiClient, PrivacyPolicyModel } from '@efp/api'
import { firstValueFrom } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class PrivacyPoliciesDao {
  private readonly privacyPolicyApiClient = inject(PrivacyPolicyApiClient)

  async findOneByCountry(country: Country, language: string): Promise<PrivacyPolicyModel> {
    return firstValueFrom(
      this.privacyPolicyApiClient.getCountryPrivacyPolicy({
        country,
        language,
      })
    )
  }

  async findAll(): Promise<PrivacyPolicyModel[]> {
    return firstValueFrom(this.privacyPolicyApiClient.getAllPrivacyPolicies())
  }
}
