// all Articles from default.fav / xml -> json

// Be aware of \" or other not parsable parts in content strings of the xml file. You've to remove them manually!
export const favouritesArticlesJSON = `{
    "Schalungsstile": {
        "Schalsystem": [
            {
                "Hersteller": "DOKA",
                "ID": "XFRPLUS",
                "Schalungsthema": [
                    {
                        "ID": "XFRPLUS_A7",
                        "Bezeichnung": "Basic panel",
                        "Loesung": [
                            {
                                "ID": "XFRPLUS_A7_AW2",
                                "Bezeichnung": "3.30m"
                            },
                            {
                                "ID": "XFRPLUS_A7_AW1",
                                "Bezeichnung": "2.70m"
                            },
                            {
                                "ID": "XFRPLUS_A7_AW4",
                                "Bezeichnung": "1.35m"
                            },
                            {
                                "ID": "XFRPLUS_A7_AW3",
                                "Bezeichnung": "0.60m"
                            },
                            {
                                "ID": "XFRPLUS_A7_AW#",
                                "Bezeichnung": "XFRPLUS_A7_AW#"
                            }
                        ]
                    },
                    {
                        "ID": "XFRPLUS_A1",
                        "Bezeichnung": "Stacking variants",
                        "Loesung": [
                            {
                                "ID": "XFRPLUS_A1_AW1",
                                "Bezeichnung": "Single panels w/o stacking"
                            },
                            {
                                "ID": "XFRPLUS_A1_AW3",
                                "Bezeichnung": "stacked horiz."
                            },
                            {
                                "ID": "XFRPLUS_A1_AW2",
                                "Bezeichnung": "stacked upright"
                            }
                        ]
                    },
                    {
                        "ID": "XFRPLUS_A9",
                        "Bezeichnung": "Connection of stacking",
                        "Loesung": [
                            {
                                "ID": "XFRPLUS_A9_AW1",
                                "Bezeichnung": "Quick acting clamp RU"
                            },
                            {
                                "ID": "XFRPLUS_A9_AW2",
                                "Bezeichnung": "Multi function clamp"
                            },
                            {
                                "ID": "XFRPLUS_A9_AW#",
                                "Bezeichnung": "XFRPLUS_A9_AW#"
                            }
                        ]
                    },
                    {
                        "ID": "XFRPLUS_A11",
                        "Bezeichnung": "Large panel",
                        "Loesung": [
                            {
                                "ID": "XFRPLUS_A11_AW1",
                                "Bezeichnung": "upright"
                            },
                            {
                                "ID": "XFRPLUS_A11_AW2",
                                "Bezeichnung": "horizontal"
                            }
                        ]
                    },
                    {
                        "ID": "XFRPLUS_A2",
                        "Bezeichnung": "Stop-end-formwork",
                        "Loesung": [
                            {
                                "ID": "XFRPLUS_A2_AW3",
                                "Bezeichnung": "Universal waling with universal fixing bolt"
                            },
                            {
                                "ID": "XFRPLUS_A2_AW4",
                                "Bezeichnung": "Universal waling with universal fixing bolt and joint strip"
                            },
                            {
                                "ID": "XFRPLUS_A2_AW2",
                                "Bezeichnung": "Universal panel (0.90 wide)"
                            },
                            {
                                "ID": "XFRPLUS_A2_AW1",
                                "Bezeichnung": "Stop-end waler tie"
                            }
                        ]
                    },
                    {
                        "ID": "XFRPLUS_A3",
                        "Bezeichnung": "Closures",
                        "Loesung": [
                            {
                                "ID": "XFRPLUS_A3_AW1",
                                "Bezeichnung": "Filler with Framax multi function clamp"
                            },
                            {
                                "ID": "XFRPLUS_A3_AW2",
                                "Bezeichnung": "Filler with Framax adjustable clamp"
                            },
                            {
                                "ID": "XFRPLUS_A3_AW6",
                                "Bezeichnung": "Alu-filler with Framax multi function clamp"
                            },
                            {
                                "ID": "XFRPLUS_A3_AW7",
                                "Bezeichnung": "Alu-filler with Framax adjustable clamp"
                            },
                            {
                                "ID": "XFRPLUS_A3_AW3",
                                "Bezeichnung": "with formwork sheet 27mm and universal waling"
                            },
                            {
                                "ID": "XFRPLUS_A3_AW4",
                                "Bezeichnung": "with formwork sheet 21mm and universal waling"
                            },
                            {
                                "ID": "XFRPLUS_A3_AW5",
                                "Bezeichnung": "with formwork sheet 18mm and universal waling"
                            }
                        ]
                    },
                    {
                        "ID": "XFRPLUS_A4",
                        "Bezeichnung": "Corner solutions",
                        "Loesung": [
                            {
                                "ID": "XFRPLUS_A4_AW1",
                                "Bezeichnung": "Outside corner and inside corner"
                            },
                            {
                                "ID": "XFRPLUS_A4_AW5",
                                "Bezeichnung": "Outside corner and plus inside corner 30/30cm"
                            },
                            {
                                "ID": "XFRPLUS_A4_AW7",
                                "Bezeichnung": "Plus outside corner 10/10cm and inside corner"
                            },
                            {
                                "ID": "XFRPLUS_A4_AW8",
                                "Bezeichnung": "Plus outside corner 10/10cm and plus inside corner 30/30cm"
                            },
                            {
                                "ID": "XFRPLUS_A4_AW3",
                                "Bezeichnung": "Universal panel and inside corner"
                            },
                            {
                                "ID": "XFRPLUS_A4_AW6",
                                "Bezeichnung": "Universal panel and plus inside corner 30/30cm"
                            },
                            {
                                "ID": "XFRPLUS_A4_AW10",
                                "Bezeichnung": "Hinged inside corner inside and outside"
                            },
                            {
                                "ID": "XFRPLUS_A4_AW11",
                                "Bezeichnung": "Hinged inside corner and hinged outside corner"
                            },
                            {
                                "ID": "XFRPLUS_A4_AW9",
                                "Bezeichnung": "Stripping corner, inside (one-sided corner, see also user infomation)"
                            }
                        ]
                    },
                    {
                        "ID": "XFRPLUS_A8",
                        "Bezeichnung": "T-connection",
                        "Loesung": [
                            {
                                "ID": "XFRPLUS_A8_AW5",
                                "Bezeichnung": "Xlife inside corner with WS10"
                            },
                            {
                                "ID": "XFRPLUS_A8_AW4",
                                "Bezeichnung": "Plus inside corner 30/30cm with WS10"
                            },
                            {
                                "ID": "XFRPLUS_A8_AW3",
                                "Bezeichnung": "Xlife inside corner (Tie rod system 20.0)"
                            }
                        ]
                    },
                    {
                        "ID": "XFRPLUS_A5",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "XFRPLUS_A5_AW5",
                                "Bezeichnung": "Framax Xlife plus form tie I 15-30cm"
                            },
                            {
                                "ID": "XFRPLUS_A5_AW6",
                                "Bezeichnung": "Framax Xlife plus form tie I 25-40cm"
                            },
                            {
                                "ID": "XFRPLUS_A5_AW14",
                                "Bezeichnung": "Framax Xlife plus form tie I 45-60cm"
                            },
                            {
                                "ID": "XFRPLUS_A5_AW1",
                                "Bezeichnung": "Framax Xlife plus form tie 15-30cm"
                            },
                            {
                                "ID": "XFRPLUS_A5_AW4",
                                "Bezeichnung": "Framax Xlife plus form tie 25-40cm"
                            },
                            {
                                "ID": "XFRPLUS_A5_AW13",
                                "Bezeichnung": "Framax Xlife plus form tie 45-60cm"
                            },
                            {
                                "ID": "XFRPLUS_A5_AW2",
                                "Bezeichnung": "Tie rod system 20.0 galvanised with reducer"
                            },
                            {
                                "ID": "XFRPLUS_A5_AW3",
                                "Bezeichnung": "Tie rod system 20.0 non-treated with reducer"
                            },
                            {
                                "ID": "XFRPLUS_A5_AW7",
                                "Bezeichnung": "Tie rod system 20.0 galvanised without reducer"
                            },
                            {
                                "ID": "XFRPLUS_A5_AW8",
                                "Bezeichnung": "Tie rod system 20.0 non-treated without reducer"
                            },
                            {
                                "ID": "XFRPLUS_A5_AW9",
                                "Bezeichnung": "Tie rod system 15.0 galvanised without reducer"
                            },
                            {
                                "ID": "XFRPLUS_A5_AW10",
                                "Bezeichnung": "Tie rod system 15.0 non-treated without reducer"
                            },
                            {
                                "ID": "XFRPLUS_A5_AW11",
                                "Bezeichnung": "Head anchor 15-40cm"
                            },
                            {
                                "ID": "XFRPLUS_A5_AW12",
                                "Bezeichnung": "Head anchor 15-100cm"
                            }
                        ]
                    },
                    {
                        "ID": "XFRPLUS_A6",
                        "Bezeichnung": "Bracing",
                        "Loesung": [
                            {
                                "ID": "XFRPLUS_A6_AW1",
                                "Bezeichnung": "Universal waling 0.90m"
                            },
                            {
                                "ID": "XFRPLUS_A6_AW2",
                                "Bezeichnung": "Universal waling 1.50m"
                            }
                        ]
                    },
                    {
                        "ID": "XFRPLUS_A10",
                        "Bezeichnung": "Foundation solution",
                        "Loesung": [
                            {
                                "ID": "XFRPLUS_A10_AW1",
                                "Bezeichnung": "with head anchor 15-40cm"
                            },
                            {
                                "ID": "XFRPLUS_A10_AW2",
                                "Bezeichnung": "with head anchor 15-100cm"
                            },
                            {
                                "ID": "XFRPLUS_A10_AW3",
                                "Bezeichnung": "with foundation clamp"
                            },
                            {
                                "ID": "XFRPLUS_A10_AW4",
                                "Bezeichnung": "with floor fixing plate and head anchor 15-40cm"
                            },
                            {
                                "ID": "XFRPLUS_A10_AW5",
                                "Bezeichnung": "with floor fixing plate and head anchor 15-100cm"
                            },
                            {
                                "ID": "XFRPLUS_A10_AW6",
                                "Bezeichnung": "Single panel (for supporting construction accessory system)"
                            }
                        ]
                    },
                    {
                        "ID": "XFRPLUS_A12",
                        "Bezeichnung": "In-line connections",
                        "Loesung": [
                            {
                                "ID": "XFRPLUS_A12_AW2",
                                "Bezeichnung": "Use panels of previous cycle"
                            },
                            {
                                "ID": "XFRPLUS_A12_AW1",
                                "Bezeichnung": "Standard connection"
                            }
                        ]
                    },
                    {
                        "ID": "XFRPLUS_A13",
                        "Bezeichnung": "Column",
                        "Loesung": [
                            {
                                "ID": "XFRPLUS_A13_AW1",
                                "Bezeichnung": "Universal panel 0.90 with column formwork platform"
                            },
                            {
                                "ID": "XFRPLUS_A13_AW2",
                                "Bezeichnung": "Universal panel 0.90 with bracket"
                            },
                            {
                                "ID": "XFRPLUS_A13_AW3",
                                "Bezeichnung": "Universal panel 1.20 with column formwork platform"
                            },
                            {
                                "ID": "XFRPLUS_A13_AW4",
                                "Bezeichnung": "Universal panel 1.20 with bracket"
                            },
                            {
                                "ID": "XFRPLUS_A13_AW5",
                                "Bezeichnung": "Panels with outside corners"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "XSTAHLFR",
                "Schalungsthema": [
                    {
                        "ID": "XSTAHLFR_A2",
                        "Bezeichnung": "Connection of stacking",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A2_AW3",
                                "Bezeichnung": "not stacked preferred"
                            },
                            {
                                "ID": "XSTAHLFR_A2_AW1",
                                "Bezeichnung": "Quick acting clamp RU+KS"
                            },
                            {
                                "ID": "XSTAHLFR_A2_AW2",
                                "Bezeichnung": "Multi function clamp"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A4",
                        "Bezeichnung": "Panel type",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A4_AW2",
                                "Bezeichnung": "Standard panel (1.35,0.90,0.60,0.55,0.45,0.30 wide)"
                            },
                            {
                                "ID": "XSTAHLFR_A4_AW3",
                                "Bezeichnung": "Universal panel (1.20,0.90,0.75 wide)"
                            },
                            {
                                "ID": "XSTAHLFR_A4_AW1",
                                "Bezeichnung": "Large panel (2.40,2.70 wide)"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A5",
                        "Bezeichnung": "Basic panel 3.30",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A5_AW1",
                                "Bezeichnung": "not preferred"
                            },
                            {
                                "ID": "XSTAHLFR_A5_AW2",
                                "Bezeichnung": "preferred"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A3",
                        "Bezeichnung": "Shifting units",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A3_AW2",
                                "Bezeichnung": "not preferred"
                            },
                            {
                                "ID": "XSTAHLFR_A3_AW1",
                                "Bezeichnung": "preferred"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A6",
                        "Bezeichnung": "Stop-end-formwork",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A6_AW1",
                                "Bezeichnung": "Stop-end tie without panel"
                            },
                            {
                                "ID": "XSTAHLFR_A6_AW10",
                                "Bezeichnung": "Stop-end tie with panel"
                            },
                            {
                                "ID": "XSTAHLFR_A6_AW3",
                                "Bezeichnung": "Universal fixing bolt with panel"
                            },
                            {
                                "ID": "XSTAHLFR_A6_AW4",
                                "Bezeichnung": "Universal fixing bolt with panel and joint strip"
                            },
                            {
                                "ID": "XSTAHLFR_A6_AW5",
                                "Bezeichnung": "Outside corner"
                            },
                            {
                                "ID": "XSTAHLFR_A6_AW6",
                                "Bezeichnung": "Universal panel (0.90,0.75 wide)"
                            },
                            {
                                "ID": "XSTAHLFR_A6_AW7",
                                "Bezeichnung": "Universal panel with panel and joint strip"
                            },
                            {
                                "ID": "XSTAHLFR_A6_AW8",
                                "Bezeichnung": "Moulded timber adjustable clamp"
                            },
                            {
                                "ID": "XSTAHLFR_A6_AW9",
                                "Bezeichnung": "Moulded timber adjustable clamp and joint strip"
                            },
                            {
                                "ID": "XSTAHLFR_A6_AW11",
                                "Bezeichnung": "Universal panel (1.20 wide)"
                            },
                            {
                                "ID": "XSTAHLFR_A6_AW12",
                                "Bezeichnung": "Stop-end waler tie"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A16",
                        "Bezeichnung": "Closure type",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A16_AW2",
                                "Bezeichnung": "Filler block by site, ties through panel"
                            },
                            {
                                "ID": "XSTAHLFR_A16_AW7",
                                "Bezeichnung": "Filler block by site, ties through closure"
                            },
                            {
                                "ID": "XSTAHLFR_A16_AW1",
                                "Bezeichnung": "Fitting timber Doka, ties through panel"
                            },
                            {
                                "ID": "XSTAHLFR_A16_AW6",
                                "Bezeichnung": "Fitting timber Doka, ties through closure"
                            },
                            {
                                "ID": "XSTAHLFR_A16_AW9",
                                "Bezeichnung": "Alu-filler, ties through panel"
                            },
                            {
                                "ID": "XSTAHLFR_A16_AW8",
                                "Bezeichnung": "Alu-filler, ties through closure"
                            },
                            {
                                "ID": "XSTAHLFR_A16_AW4",
                                "Bezeichnung": "with formwork sheet 27mm and universal waling 0.90m"
                            },
                            {
                                "ID": "XSTAHLFR_A16_AW5",
                                "Bezeichnung": "with formwork sheet 27mm and universal waling 1.50m"
                            },
                            {
                                "ID": "XSTAHLFR_A16_AW10",
                                "Bezeichnung": "with formwork sheet 21mm and universal waling 0.90m"
                            },
                            {
                                "ID": "XSTAHLFR_A16_AW11",
                                "Bezeichnung": "with formwork sheet 21mm and universal waling 1.50m"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A7",
                        "Bezeichnung": "Compensation connection",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A7_AW1",
                                "Bezeichnung": "Multi function clamp"
                            },
                            {
                                "ID": "XSTAHLFR_A7_AW2",
                                "Bezeichnung": "Adjustable clamp"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A8",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A8_AW1",
                                "Bezeichnung": "Inside corner and universal panel (0.90,0.75 wide)"
                            },
                            {
                                "ID": "XSTAHLFR_A8_AW2",
                                "Bezeichnung": "Inside corner and outside corner"
                            },
                            {
                                "ID": "XSTAHLFR_A8_AW10",
                                "Bezeichnung": "Hinged inside corner galv. and universal panel"
                            },
                            {
                                "ID": "XSTAHLFR_A8_AW11",
                                "Bezeichnung": "Hinged inside corner galv.and outside corner"
                            },
                            {
                                "ID": "XSTAHLFR_A8_AW12",
                                "Bezeichnung": "Hinged inside corner galv. inside and outside"
                            },
                            {
                                "ID": "XSTAHLFR_A8_AW13",
                                "Bezeichnung": "Hinged inside corner galv.and hinged outside corner galv."
                            },
                            {
                                "ID": "XSTAHLFR_A8_AW3",
                                "Bezeichnung": "Hinged inside corner and universal panel"
                            },
                            {
                                "ID": "XSTAHLFR_A8_AW4",
                                "Bezeichnung": "Hinged inside corner and outside corner"
                            },
                            {
                                "ID": "XSTAHLFR_A8_AW5",
                                "Bezeichnung": "Hinged inside corner inside and outside"
                            },
                            {
                                "ID": "XSTAHLFR_A8_AW6",
                                "Bezeichnung": "Hinged inside corner and hinged outside corner"
                            },
                            {
                                "ID": "XSTAHLFR_A8_AW7",
                                "Bezeichnung": "Stripping corner and universal panel"
                            },
                            {
                                "ID": "XSTAHLFR_A8_AW8",
                                "Bezeichnung": "Stripping corner and outside corner"
                            },
                            {
                                "ID": "XSTAHLFR_A8_AW9",
                                "Bezeichnung": "Inside corner and universal panel (1.20 wide)"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A9",
                        "Bezeichnung": "Wall connections",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A9_AW1",
                                "Bezeichnung": "Fitting timbers"
                            },
                            {
                                "ID": "XSTAHLFR_A9_AW2",
                                "Bezeichnung": "Fitting timbers with multi function clamp"
                            },
                            {
                                "ID": "XSTAHLFR_A9_AW3",
                                "Bezeichnung": "Fitting timbers with adjustable clamp"
                            },
                            {
                                "ID": "XSTAHLFR_A9_AW4",
                                "Bezeichnung": "Squared timbers"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A1",
                        "Bezeichnung": "Stacking steel on steel or alu on steel",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A1_AW1",
                                "Bezeichnung": "Steel on steel"
                            },
                            {
                                "ID": "XSTAHLFR_A1_AW2",
                                "Bezeichnung": "Alu on steel"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A10",
                        "Bezeichnung": "Upright or horiz. panels",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A10_AW1",
                                "Bezeichnung": "Upright panels"
                            },
                            {
                                "ID": "XSTAHLFR_A10_AW2",
                                "Bezeichnung": "Horiz. panels"
                            },
                            {
                                "ID": "XSTAHLFR_A10_AW3",
                                "Bezeichnung": "Horiz. panels with foundation clamp"
                            },
                            {
                                "ID": "XSTAHLFR_A10_AW4",
                                "Bezeichnung": "Horiz. panels with floor fixing plate"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A14",
                        "Bezeichnung": "Upright or horiz. stacking of panels",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A14_AW1",
                                "Bezeichnung": "w/o stacking"
                            },
                            {
                                "ID": "XSTAHLFR_A14_AW3",
                                "Bezeichnung": "stacked horiz."
                            },
                            {
                                "ID": "XSTAHLFR_A14_AW2",
                                "Bezeichnung": "stacked upright"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A11",
                        "Bezeichnung": "Column",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A11_AW1",
                                "Bezeichnung": "Universal panel 0.90 with column formwork platform"
                            },
                            {
                                "ID": "XSTAHLFR_A11_AW4",
                                "Bezeichnung": "Universal panel 0.90 with bracket"
                            },
                            {
                                "ID": "XSTAHLFR_A11_AW3",
                                "Bezeichnung": "Universal panel 1.20 with column formwork platform"
                            },
                            {
                                "ID": "XSTAHLFR_A11_AW5",
                                "Bezeichnung": "Universal panel 1.20 with bracket"
                            },
                            {
                                "ID": "XSTAHLFR_A11_AW2",
                                "Bezeichnung": "Panels with outside corners"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A12",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A12_AW2",
                                "Bezeichnung": "Tie rod 15.0 galvanised"
                            },
                            {
                                "ID": "XSTAHLFR_A12_AW1",
                                "Bezeichnung": "Tie rod 15.0 non-treated"
                            },
                            {
                                "ID": "XSTAHLFR_A12_AW4",
                                "Bezeichnung": "Tie rod 20.0 galvanised"
                            },
                            {
                                "ID": "XSTAHLFR_A12_AW3",
                                "Bezeichnung": "Tie rod 20.0 non-treated"
                            },
                            {
                                "ID": "XSTAHLFR_A12_AW5",
                                "Bezeichnung": "Head anchor 15-40cm"
                            },
                            {
                                "ID": "XSTAHLFR_A12_AW6",
                                "Bezeichnung": "Head anchor 15-100cm"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A13",
                        "Bezeichnung": "One-sided corner and crossing",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A13_AW1",
                                "Bezeichnung": "Stripping corner"
                            },
                            {
                                "ID": "XSTAHLFR_A13_AW2",
                                "Bezeichnung": "Outside or inside corner"
                            },
                            {
                                "ID": "XSTAHLFR_A13_AW6",
                                "Bezeichnung": "Hinged outside or hinged inside corner galv."
                            },
                            {
                                "ID": "XSTAHLFR_A13_AW3",
                                "Bezeichnung": "Hinged outside or hinged inside corner"
                            },
                            {
                                "ID": "XSTAHLFR_A13_AW4",
                                "Bezeichnung": "Universal panel 0.90"
                            },
                            {
                                "ID": "XSTAHLFR_A13_AW5",
                                "Bezeichnung": "Universal panel 1.20"
                            }
                        ]
                    },
                    {
                        "ID": "XSTAHLFR_A15",
                        "Bezeichnung": "Longitudinal connection",
                        "Loesung": [
                            {
                                "ID": "XSTAHLFR_A15_AW4",
                                "Bezeichnung": "Use panels of previous cycle"
                            },
                            {
                                "ID": "XSTAHLFR_A15_AW1",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "XSTAHLFR_A15_AW2",
                                "Bezeichnung": "Standard panel"
                            },
                            {
                                "ID": "XSTAHLFR_A15_AW3",
                                "Bezeichnung": "Large panel"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "XFR30",
                "Schalungsthema": [
                    {
                        "ID": "XFR30_A1",
                        "Bezeichnung": "Panel type",
                        "Loesung": [
                            {
                                "ID": "XFR30_A1_AW1",
                                "Bezeichnung": "Standard panel (1.35,0.90,0.60,0.45,0.30 wide)"
                            },
                            {
                                "ID": "XFR30_A1_AW2",
                                "Bezeichnung": "Universal panel"
                            }
                        ]
                    },
                    {
                        "ID": "XFR30_A2",
                        "Bezeichnung": "Stop-end-formwork",
                        "Loesung": [
                            {
                                "ID": "XFR30_A2_AW1",
                                "Bezeichnung": "Stop-end tie without panel"
                            },
                            {
                                "ID": "XFR30_A2_AW2",
                                "Bezeichnung": "Stop-end tie with panel"
                            },
                            {
                                "ID": "XFR30_A2_AW6",
                                "Bezeichnung": "Universal fixing bolt w/o panel"
                            },
                            {
                                "ID": "XFR30_A2_AW3",
                                "Bezeichnung": "Universal fixing bolt with panel"
                            },
                            {
                                "ID": "XFR30_A2_AW4",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "XFR30_A2_AW5",
                                "Bezeichnung": "Stop-end waler tie"
                            }
                        ]
                    },
                    {
                        "ID": "XFR30_A3",
                        "Bezeichnung": "Closures",
                        "Loesung": [
                            {
                                "ID": "XFR30_A3_AW1",
                                "Bezeichnung": "Filler block w/ multifunction clamp, ties through panel"
                            },
                            {
                                "ID": "XFR30_A3_AW7",
                                "Bezeichnung": "Filler block w/ multifunction clamp, ties through closure"
                            },
                            {
                                "ID": "XFR30_A3_AW2",
                                "Bezeichnung": "Filler block w/ adjustable clamp"
                            },
                            {
                                "ID": "XFR30_A3_AW9",
                                "Bezeichnung": "Filler block w/ universal fixing bolt 10-16cm"
                            },
                            {
                                "ID": "XFR30_A3_AW10",
                                "Bezeichnung": "Filler block w/ universal fixing bolt 10-25cm"
                            },
                            {
                                "ID": "XFR30_A3_AW3",
                                "Bezeichnung": "Fitting timber with multifunction clamp, ties through panel"
                            },
                            {
                                "ID": "XFR30_A3_AW8",
                                "Bezeichnung": "Fitting timber w/ multifunction clamp, ties through closure"
                            },
                            {
                                "ID": "XFR30_A3_AW4",
                                "Bezeichnung": "Fitting timber with adjustable clamp"
                            },
                            {
                                "ID": "XFR30_A3_AW5",
                                "Bezeichnung": "with formwork sheet 27mm and universal waling 0.90m"
                            },
                            {
                                "ID": "XFR30_A3_AW6",
                                "Bezeichnung": "with formwork sheet 27mm and universal waling 1.50m"
                            },
                            {
                                "ID": "XFR30_A3_AW11",
                                "Bezeichnung": "with formwork sheet 21mm and universal waling 0.90m"
                            },
                            {
                                "ID": "XFR30_A3_AW12",
                                "Bezeichnung": "with formwork sheet 21mm and universal waling 1.50m"
                            }
                        ]
                    },
                    {
                        "ID": "XFR30_A4",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "XFR30_A4_AW1",
                                "Bezeichnung": "Inside corner and universal panel"
                            },
                            {
                                "ID": "XFR30_A4_AW2",
                                "Bezeichnung": "Inside corner and outside corner"
                            },
                            {
                                "ID": "XFR30_A4_AW3",
                                "Bezeichnung": "Hinged inside corner galv. inside and outside"
                            }
                        ]
                    },
                    {
                        "ID": "XFR30_A5",
                        "Bezeichnung": "Wall connections",
                        "Loesung": [
                            {
                                "ID": "XFR30_A5_AW4",
                                "Bezeichnung": "Use panels of previous cycle"
                            },
                            {
                                "ID": "XFR30_A5_AW1",
                                "Bezeichnung": "Standard panel"
                            },
                            {
                                "ID": "XFR30_A5_AW2",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "XFR30_A5_AW3",
                                "Bezeichnung": "Squared timbers-Universal waling"
                            }
                        ]
                    },
                    {
                        "ID": "XFR30_A7",
                        "Bezeichnung": "Upright or horiz. stacking of panels",
                        "Loesung": [
                            {
                                "ID": "XFR30_A7_AW1",
                                "Bezeichnung": "w/o stacking"
                            },
                            {
                                "ID": "XFR30_A7_AW2",
                                "Bezeichnung": "stacked upright"
                            },
                            {
                                "ID": "XFR30_A7_AW3",
                                "Bezeichnung": "stacked horiz."
                            }
                        ]
                    },
                    {
                        "ID": "XFR30_A8",
                        "Bezeichnung": "Column",
                        "Loesung": [
                            {
                                "ID": "XFR30_A8_AW1",
                                "Bezeichnung": "Universal panel 0.90"
                            },
                            {
                                "ID": "XFR30_A8_AW2",
                                "Bezeichnung": "Panels with outside corners"
                            }
                        ]
                    },
                    {
                        "ID": "XFR30_A9",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "XFR30_A9_AW1",
                                "Bezeichnung": "Tie rod 15.0 non-treated"
                            },
                            {
                                "ID": "XFR30_A9_AW2",
                                "Bezeichnung": "Tie rod 15.0 galvanised"
                            },
                            {
                                "ID": "XFR30_A9_AW3",
                                "Bezeichnung": "Tie rod 20.0 non-treated"
                            },
                            {
                                "ID": "XFR30_A9_AW4",
                                "Bezeichnung": "Tie rod 20.0 galvanised"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "XFRECO",
                "Schalungsthema": [
                    {
                        "ID": "XFRECO_A1",
                        "Bezeichnung": "Panel type",
                        "Loesung": [
                            {
                                "ID": "XFRECO_A1_AW1",
                                "Bezeichnung": "Standard panel (1.35,0.90,0.60,0.55,0.45,0.30 wide)"
                            },
                            {
                                "ID": "XFRECO_A1_AW2",
                                "Bezeichnung": "Universal panel"
                            }
                        ]
                    },
                    {
                        "ID": "XFRECO_A2",
                        "Bezeichnung": "Stop-end-formwork",
                        "Loesung": [
                            {
                                "ID": "XFRECO_A2_AW1",
                                "Bezeichnung": "Stop-end tie without panel"
                            },
                            {
                                "ID": "XFRECO_A2_AW2",
                                "Bezeichnung": "Stop-end tie with panel"
                            },
                            {
                                "ID": "XFRECO_A2_AW6",
                                "Bezeichnung": "Universal fixing bolt w/o panel"
                            },
                            {
                                "ID": "XFRECO_A2_AW3",
                                "Bezeichnung": "Universal fixing bolt with panel"
                            },
                            {
                                "ID": "XFRECO_A2_AW4",
                                "Bezeichnung": "Universal panel 0.90m"
                            },
                            {
                                "ID": "XFRECO_A2_AW7",
                                "Bezeichnung": "Universal panel 1.20m"
                            },
                            {
                                "ID": "XFRECO_A2_AW5",
                                "Bezeichnung": "Stop-end waler tie"
                            }
                        ]
                    },
                    {
                        "ID": "XFRECO_A3",
                        "Bezeichnung": "Closures",
                        "Loesung": [
                            {
                                "ID": "XFRECO_A3_AW1",
                                "Bezeichnung": "Filler block w/ multifunction clamp, ties through panel"
                            },
                            {
                                "ID": "XFRECO_A3_AW7",
                                "Bezeichnung": "Filler block w/ multifunction clamp, ties through closure"
                            },
                            {
                                "ID": "XFRECO_A3_AW2",
                                "Bezeichnung": "Filler block w/ adjustable clamp"
                            },
                            {
                                "ID": "XFRECO_A3_AW9",
                                "Bezeichnung": "Filler block w/ universal fixing bolt 10-16cm"
                            },
                            {
                                "ID": "XFRECO_A3_AW10",
                                "Bezeichnung": "Filler block w/ universal fixing bolt 10-25cm"
                            },
                            {
                                "ID": "XFRECO_A3_AW3",
                                "Bezeichnung": "Fitting timber with multifunction clamp, ties through panel"
                            },
                            {
                                "ID": "XFRECO_A3_AW8",
                                "Bezeichnung": "Fitting timber w/ multifunction clamp, ties through closure"
                            },
                            {
                                "ID": "XFRECO_A3_AW4",
                                "Bezeichnung": "Fitting timber with adjustable clamp"
                            },
                            {
                                "ID": "XFRECO_A3_AW5",
                                "Bezeichnung": "with formwork sheet 27mm and universal waling 0.90m"
                            },
                            {
                                "ID": "XFRECO_A3_AW6",
                                "Bezeichnung": "with formwork sheet 27mm and universal waling 1.50m"
                            },
                            {
                                "ID": "XFRECO_A3_AW11",
                                "Bezeichnung": "with formwork sheet 21mm and universal waling 0.90m"
                            },
                            {
                                "ID": "XFRECO_A3_AW12",
                                "Bezeichnung": "with formwork sheet 21mm and universal waling 1.50m"
                            }
                        ]
                    },
                    {
                        "ID": "XFRECO_A4",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "XFRECO_A4_AW1",
                                "Bezeichnung": "Inside corner and universal panel 0.90m"
                            },
                            {
                                "ID": "XFRECO_A4_AW3",
                                "Bezeichnung": "Inside corner and universal panel 1.20m"
                            },
                            {
                                "ID": "XFRECO_A4_AW2",
                                "Bezeichnung": "Inside corner and outside corner"
                            }
                        ]
                    },
                    {
                        "ID": "XFRECO_A5",
                        "Bezeichnung": "Wall connections",
                        "Loesung": [
                            {
                                "ID": "XFRECO_A5_AW4",
                                "Bezeichnung": "Use panels of previous cycle"
                            },
                            {
                                "ID": "XFRECO_A5_AW1",
                                "Bezeichnung": "Standard panel"
                            },
                            {
                                "ID": "XFRECO_A5_AW2",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "XFRECO_A5_AW3",
                                "Bezeichnung": "Squared timbers-Universal waling"
                            }
                        ]
                    },
                    {
                        "ID": "XFRECO_A8",
                        "Bezeichnung": "Column",
                        "Loesung": [
                            {
                                "ID": "XFRECO_A8_AW1",
                                "Bezeichnung": "Universal panel 0.90m"
                            },
                            {
                                "ID": "XFRECO_A8_AW3",
                                "Bezeichnung": "Universal panel 1.20m"
                            },
                            {
                                "ID": "XFRECO_A8_AW2",
                                "Bezeichnung": "Panels with outside corners"
                            }
                        ]
                    },
                    {
                        "ID": "XFRECO_A9",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "XFRECO_A9_AW1",
                                "Bezeichnung": "Tie rod 15.0 non-treated"
                            },
                            {
                                "ID": "XFRECO_A9_AW2",
                                "Bezeichnung": "Tie rod 15.0 galvanised"
                            },
                            {
                                "ID": "XFRECO_A9_AW3",
                                "Bezeichnung": "Tie rod 20.0 non-treated"
                            },
                            {
                                "ID": "XFRECO_A9_AW4",
                                "Bezeichnung": "Tie rod 20.0 galvanised"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "XSAFEPLU",
                "Schalungsthema": [
                    {
                        "ID": "XSAFEPLU_A17",
                        "Bezeichnung": "Number of platform levels",
                        "Loesung": [
                            {
                                "ID": "XSAFEPLU_A17_AW2",
                                "Bezeichnung": "Minimal"
                            },
                            {
                                "ID": "XSAFEPLU_A17_AW1",
                                "Bezeichnung": "Maximal"
                            }
                        ]
                    },
                    {
                        "ID": "XSAFEPLU_A4",
                        "Bezeichnung": "Panel type",
                        "Loesung": [
                            {
                                "ID": "XSAFEPLU_A4_AW4",
                                "Bezeichnung": "Panel with plattform (2.70 wide) with ladder"
                            },
                            {
                                "ID": "XSAFEPLU_A4_AW1",
                                "Bezeichnung": "Panel with plattform (1.35 wide) with ladder"
                            },
                            {
                                "ID": "XSAFEPLU_A4_AW6",
                                "Bezeichnung": "Panel with plattform (2.70 wide) without ladder"
                            },
                            {
                                "ID": "XSAFEPLU_A4_AW5",
                                "Bezeichnung": "Panel with plattform (1.35 wide) without ladder"
                            },
                            {
                                "ID": "XSAFEPLU_A4_AW2",
                                "Bezeichnung": "Standard panel (1.35,0.90,0.60,0.55,0.45,0.30 wide)"
                            },
                            {
                                "ID": "XSAFEPLU_A4_AW3",
                                "Bezeichnung": "Universal panel (0.90,0.75 wide)"
                            }
                        ]
                    },
                    {
                        "ID": "XSAFEPLU_A5",
                        "Bezeichnung": "Basic panel",
                        "Loesung": [
                            {
                                "ID": "XSAFEPLU_A5_AW1",
                                "Bezeichnung": "2.70 preferred"
                            },
                            {
                                "ID": "XSAFEPLU_A5_AW2",
                                "Bezeichnung": "3.30 preferred"
                            },
                            {
                                "ID": "XSAFEPLU_A5_AW3",
                                "Bezeichnung": "2.40x2.70 preferred"
                            },
                            {
                                "ID": "XSAFEPLU_A5_AW4",
                                "Bezeichnung": "2.70x2.70 preferred"
                            },
                            {
                                "ID": "XSAFEPLU_A5_AW5",
                                "Bezeichnung": "2.70x3.30 preferred"
                            }
                        ]
                    },
                    {
                        "ID": "XSAFEPLU_A6",
                        "Bezeichnung": "Stop-end-formwork",
                        "Loesung": [
                            {
                                "ID": "XSAFEPLU_A6_AW13",
                                "Bezeichnung": "Universal fixing bolt with panel+270plattform with ladder"
                            },
                            {
                                "ID": "XSAFEPLU_A6_AW12",
                                "Bezeichnung": "Universal fixing bolt with panel+135plattform with ladder"
                            },
                            {
                                "ID": "XSAFEPLU_A6_AW1",
                                "Bezeichnung": "Stop-end tie without panel"
                            },
                            {
                                "ID": "XSAFEPLU_A6_AW10",
                                "Bezeichnung": "Stop-end tie with panel"
                            },
                            {
                                "ID": "XSAFEPLU_A6_AW3",
                                "Bezeichnung": "Universal fixing bolt with panel"
                            },
                            {
                                "ID": "XSAFEPLU_A6_AW4",
                                "Bezeichnung": "Universal fixing bolt with panel and joint strip"
                            },
                            {
                                "ID": "XSAFEPLU_A6_AW5",
                                "Bezeichnung": "Outside corner"
                            },
                            {
                                "ID": "XSAFEPLU_A6_AW6",
                                "Bezeichnung": "Universal panel (0.90,0.75 wide)"
                            },
                            {
                                "ID": "XSAFEPLU_A6_AW8",
                                "Bezeichnung": "Moulded timber adjustable clamp"
                            },
                            {
                                "ID": "XSAFEPLU_A6_AW9",
                                "Bezeichnung": "Moulded timber adjustable clamp and joint strip"
                            },
                            {
                                "ID": "XSAFEPLU_A6_AW11",
                                "Bezeichnung": "Universal panel (1.20 wide)"
                            }
                        ]
                    },
                    {
                        "ID": "XSAFEPLU_A16",
                        "Bezeichnung": "Closure type",
                        "Loesung": [
                            {
                                "ID": "XSAFEPLU_A16_AW2",
                                "Bezeichnung": "Filler block by site, ties through panel"
                            },
                            {
                                "ID": "XSAFEPLU_A16_AW7",
                                "Bezeichnung": "Filler block by site, ties through closure"
                            },
                            {
                                "ID": "XSAFEPLU_A16_AW1",
                                "Bezeichnung": "Fitting timber Doka, ties through panel"
                            },
                            {
                                "ID": "XSAFEPLU_A16_AW6",
                                "Bezeichnung": "Fitting timber Doka, ties through closure"
                            },
                            {
                                "ID": "XSAFEPLU_A16_AW9",
                                "Bezeichnung": "Alu-filler, ties through panel"
                            },
                            {
                                "ID": "XSAFEPLU_A16_AW8",
                                "Bezeichnung": "Alu-filler, ties through closure"
                            },
                            {
                                "ID": "XSAFEPLU_A16_AW4",
                                "Bezeichnung": "with formwork sheet 27mm and universal waling 0.90m"
                            },
                            {
                                "ID": "XSAFEPLU_A16_AW5",
                                "Bezeichnung": "with formwork sheet 27mm and universal waling 1.50m"
                            },
                            {
                                "ID": "XSAFEPLU_A16_AW10",
                                "Bezeichnung": "with formwork sheet 21mm and universal waling 0.90m"
                            },
                            {
                                "ID": "XSAFEPLU_A16_AW11",
                                "Bezeichnung": "with formwork sheet 21mm and universal waling 1.50m"
                            }
                        ]
                    },
                    {
                        "ID": "XSAFEPLU_A7",
                        "Bezeichnung": "Compensation connection",
                        "Loesung": [
                            {
                                "ID": "XSAFEPLU_A7_AW1",
                                "Bezeichnung": "Multi function clamp"
                            },
                            {
                                "ID": "XSAFEPLU_A7_AW2",
                                "Bezeichnung": "Adjustable clamp"
                            }
                        ]
                    },
                    {
                        "ID": "XSAFEPLU_A8",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "XSAFEPLU_A8_AW10",
                                "Bezeichnung": "Inside corner and outside corner with plattform"
                            },
                            {
                                "ID": "XSAFEPLU_A8_AW11",
                                "Bezeichnung": "Inside corner and universal panel with plattform"
                            },
                            {
                                "ID": "XSAFEPLU_A8_AW1",
                                "Bezeichnung": "Inside corner and universal panel (0.90,0.75 wide)"
                            },
                            {
                                "ID": "XSAFEPLU_A8_AW2",
                                "Bezeichnung": "Inside corner and outside corner"
                            },
                            {
                                "ID": "XSAFEPLU_A8_AW12",
                                "Bezeichnung": "Hinged inside corner galv. and universal panel"
                            },
                            {
                                "ID": "XSAFEPLU_A8_AW13",
                                "Bezeichnung": "Hinged inside corner galv.and outside corner"
                            },
                            {
                                "ID": "XSAFEPLU_A8_AW14",
                                "Bezeichnung": "Hinged inside corner galv. inside and outside"
                            },
                            {
                                "ID": "XSAFEPLU_A8_AW15",
                                "Bezeichnung": "Hinged inside corner galv.and hinged outside corner galv."
                            },
                            {
                                "ID": "XSAFEPLU_A8_AW3",
                                "Bezeichnung": "Hinged inside corner and universal panel"
                            },
                            {
                                "ID": "XSAFEPLU_A8_AW4",
                                "Bezeichnung": "Hinged inside corner and outside corner"
                            },
                            {
                                "ID": "XSAFEPLU_A8_AW5",
                                "Bezeichnung": "Hinged inside corner inside and outside"
                            },
                            {
                                "ID": "XSAFEPLU_A8_AW6",
                                "Bezeichnung": "Hinged inside corner and hinged outside corner"
                            },
                            {
                                "ID": "XSAFEPLU_A8_AW7",
                                "Bezeichnung": "Stripping corner and universal panel"
                            },
                            {
                                "ID": "XSAFEPLU_A8_AW8",
                                "Bezeichnung": "Stripping corner and outside corner"
                            },
                            {
                                "ID": "XSAFEPLU_A8_AW9",
                                "Bezeichnung": "Inside corner and universal panel (1.20 wide)"
                            }
                        ]
                    },
                    {
                        "ID": "XSAFEPLU_A9",
                        "Bezeichnung": "Wall connections",
                        "Loesung": [
                            {
                                "ID": "XSAFEPLU_A9_AW1",
                                "Bezeichnung": "Fitting timbers"
                            },
                            {
                                "ID": "XSAFEPLU_A9_AW2",
                                "Bezeichnung": "Fitting timbers with multi function clamp"
                            },
                            {
                                "ID": "XSAFEPLU_A9_AW3",
                                "Bezeichnung": "Fitting timbers with adjustable clamp"
                            },
                            {
                                "ID": "XSAFEPLU_A9_AW4",
                                "Bezeichnung": "Squared timbers"
                            }
                        ]
                    },
                    {
                        "ID": "XSAFEPLU_A11",
                        "Bezeichnung": "Column",
                        "Loesung": [
                            {
                                "ID": "XSAFEPLU_A11_AW1",
                                "Bezeichnung": "Universal panel 0.90 with column formwork platform"
                            },
                            {
                                "ID": "XSAFEPLU_A11_AW4",
                                "Bezeichnung": "Universal panel 0.90 with bracket"
                            },
                            {
                                "ID": "XSAFEPLU_A11_AW3",
                                "Bezeichnung": "Universal panel 1.20 with column formwork platform"
                            },
                            {
                                "ID": "XSAFEPLU_A11_AW5",
                                "Bezeichnung": "Universal panel 1.20 with bracket"
                            },
                            {
                                "ID": "XSAFEPLU_A11_AW2",
                                "Bezeichnung": "Panels with outside corners"
                            }
                        ]
                    },
                    {
                        "ID": "XSAFEPLU_A12",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "XSAFEPLU_A12_AW1",
                                "Bezeichnung": "Tie rod 15.0 non-treated"
                            },
                            {
                                "ID": "XSAFEPLU_A12_AW2",
                                "Bezeichnung": "Tie rod 15.0 galvanised"
                            },
                            {
                                "ID": "XSAFEPLU_A12_AW3",
                                "Bezeichnung": "Tie rod 20.0 non-treated"
                            },
                            {
                                "ID": "XSAFEPLU_A12_AW4",
                                "Bezeichnung": "Tie rod 20.0 galvanised"
                            }
                        ]
                    },
                    {
                        "ID": "XSAFEPLU_A13",
                        "Bezeichnung": "One-sided corner and crossing",
                        "Loesung": [
                            {
                                "ID": "XSAFEPLU_A13_AW1",
                                "Bezeichnung": "Stripping corner"
                            },
                            {
                                "ID": "XSAFEPLU_A13_AW2",
                                "Bezeichnung": "Outside or inside corner"
                            },
                            {
                                "ID": "XSAFEPLU_A13_AW4",
                                "Bezeichnung": "Hinged outside or hinged inside corner galv."
                            },
                            {
                                "ID": "XSAFEPLU_A13_AW3",
                                "Bezeichnung": "Hinged outside or hinged inside corner"
                            }
                        ]
                    },
                    {
                        "ID": "XSAFEPLU_A15",
                        "Bezeichnung": "Longitudinal connection",
                        "Loesung": [
                            {
                                "ID": "XSAFEPLU_A15_AW1",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "XSAFEPLU_A15_AW2",
                                "Bezeichnung": "Standard panel"
                            },
                            {
                                "ID": "XSAFEPLU_A15_AW3",
                                "Bezeichnung": "Large panel"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "XALUFR",
                "Schalungsthema": [
                    {
                        "ID": "XALUFR_A17",
                        "Bezeichnung": "Basic panel 3.00m",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A17_AW1",
                                "Bezeichnung": "not preferred"
                            },
                            {
                                "ID": "XALUFR_A17_AW2",
                                "Bezeichnung": "preferred"
                            }
                        ]
                    },
                    {
                        "ID": "XALUFR_A2",
                        "Bezeichnung": "Connection of stacking",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A2_AW3",
                                "Bezeichnung": "not stacked preferred"
                            },
                            {
                                "ID": "XALUFR_A2_AW1",
                                "Bezeichnung": "Quick acting clamp RU+KS"
                            },
                            {
                                "ID": "XALUFR_A2_AW2",
                                "Bezeichnung": "Multi function clamp"
                            }
                        ]
                    },
                    {
                        "ID": "XALUFR_A4",
                        "Bezeichnung": "Panel type",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A4_AW2",
                                "Bezeichnung": "Standard panel"
                            },
                            {
                                "ID": "XALUFR_A4_AW3",
                                "Bezeichnung": "Universal panel"
                            }
                        ]
                    },
                    {
                        "ID": "XALUFR_A6",
                        "Bezeichnung": "Stop-end formwork",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A6_AW1",
                                "Bezeichnung": "Stop-end tie without panel"
                            },
                            {
                                "ID": "XALUFR_A6_AW10",
                                "Bezeichnung": "Stop-end tie with panel"
                            },
                            {
                                "ID": "XALUFR_A6_AW3",
                                "Bezeichnung": "Universal fixing bolt with panel"
                            },
                            {
                                "ID": "XALUFR_A6_AW5",
                                "Bezeichnung": "Outisde corner"
                            },
                            {
                                "ID": "XALUFR_A6_AW6",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "XALUFR_A6_AW8",
                                "Bezeichnung": "Moulded timber adjustable clamp"
                            },
                            {
                                "ID": "XALUFR_A6_AW9",
                                "Bezeichnung": "Moulded timber adjustable clamp and joint strip"
                            },
                            {
                                "ID": "XALUFR_A6_AW11",
                                "Bezeichnung": "Stop-end waler tie"
                            }
                        ]
                    },
                    {
                        "ID": "XALUFR_A16",
                        "Bezeichnung": "Closure type",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A16_AW1",
                                "Bezeichnung": "Fitting timber Doka, ties through panel"
                            },
                            {
                                "ID": "XALUFR_A16_AW6",
                                "Bezeichnung": "Fitting timber Doka, ties through closure"
                            },
                            {
                                "ID": "XALUFR_A16_AW2",
                                "Bezeichnung": "Filler block by site, ties through panel"
                            },
                            {
                                "ID": "XALUFR_A16_AW7",
                                "Bezeichnung": "Filler block by site, ties through closure"
                            },
                            {
                                "ID": "XALUFR_A16_AW8",
                                "Bezeichnung": "Alu-filler, ties through closure"
                            },
                            {
                                "ID": "XALUFR_A16_AW4",
                                "Bezeichnung": "with formwork sheet 27mm and universal waling 0.90m"
                            },
                            {
                                "ID": "XALUFR_A16_AW5",
                                "Bezeichnung": "with formwork sheet 27mm and universal waling 1.50m"
                            },
                            {
                                "ID": "XALUFR_A16_AW9",
                                "Bezeichnung": "with formwork sheet 21mm and universal waling 0.90m"
                            },
                            {
                                "ID": "XALUFR_A16_AW10",
                                "Bezeichnung": "with formwork sheet 21mm and universal waling 1.50m"
                            }
                        ]
                    },
                    {
                        "ID": "XALUFR_A7",
                        "Bezeichnung": "Compensation connection",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A7_AW1",
                                "Bezeichnung": "Multi function clamp"
                            },
                            {
                                "ID": "XALUFR_A7_AW2",
                                "Bezeichnung": "Adjustable clamp"
                            }
                        ]
                    },
                    {
                        "ID": "XALUFR_A8",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A8_AW1",
                                "Bezeichnung": "Inside corner and universal panel (0.90,0.75 wide)"
                            },
                            {
                                "ID": "XALUFR_A8_AW2",
                                "Bezeichnung": "Inside corner and outside corner"
                            },
                            {
                                "ID": "XALUFR_A8_AW7",
                                "Bezeichnung": "Hinged inside corner galv. and universal panel"
                            },
                            {
                                "ID": "XALUFR_A8_AW8",
                                "Bezeichnung": "Hinged inside corner galv.and outside corner galv."
                            },
                            {
                                "ID": "XALUFR_A8_AW9",
                                "Bezeichnung": "Hinged inside corner galv. inside and outside"
                            },
                            {
                                "ID": "XALUFR_A8_AW10",
                                "Bezeichnung": "Hinged inside corner galv. and hinged outside corner galv."
                            },
                            {
                                "ID": "XALUFR_A8_AW3",
                                "Bezeichnung": "Hinged inside corner and universal panel"
                            },
                            {
                                "ID": "XALUFR_A8_AW4",
                                "Bezeichnung": "Hinged inside corner and outside corner"
                            },
                            {
                                "ID": "XALUFR_A8_AW5",
                                "Bezeichnung": "Hinged inside corner inside and outside"
                            },
                            {
                                "ID": "XALUFR_A8_AW6",
                                "Bezeichnung": "Hinged inside corner and hinged outside corner"
                            }
                        ]
                    },
                    {
                        "ID": "XALUFR_A9",
                        "Bezeichnung": "Wall connections",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A9_AW1",
                                "Bezeichnung": "Fitting timbers"
                            },
                            {
                                "ID": "XALUFR_A9_AW2",
                                "Bezeichnung": "Fitting timbers with multi function clamp"
                            },
                            {
                                "ID": "XALUFR_A9_AW3",
                                "Bezeichnung": "Fitting timbers with adjustable clamp"
                            },
                            {
                                "ID": "XALUFR_A9_AW4",
                                "Bezeichnung": "Squared timbers"
                            }
                        ]
                    },
                    {
                        "ID": "XALUFR_A1",
                        "Bezeichnung": "Stacking Alu on Alu or Steel on Alu",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A1_AW2",
                                "Bezeichnung": "Framax Alu"
                            },
                            {
                                "ID": "XALUFR_A1_AW3",
                                "Bezeichnung": "Steel on Alu"
                            }
                        ]
                    },
                    {
                        "ID": "XALUFR_A10",
                        "Bezeichnung": "Upright or horiz. panels",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A10_AW1",
                                "Bezeichnung": "Upright panels"
                            },
                            {
                                "ID": "XALUFR_A10_AW2",
                                "Bezeichnung": "Horiz. panels"
                            }
                        ]
                    },
                    {
                        "ID": "XALUFR_A14",
                        "Bezeichnung": "Upright or horiz. stacking of panels",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A14_AW1",
                                "Bezeichnung": "w/o stacking"
                            },
                            {
                                "ID": "XALUFR_A14_AW2",
                                "Bezeichnung": "stacked upright"
                            },
                            {
                                "ID": "XALUFR_A14_AW3",
                                "Bezeichnung": "stacked horiz."
                            }
                        ]
                    },
                    {
                        "ID": "XALUFR_A11",
                        "Bezeichnung": "Column",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A11_AW5",
                                "Bezeichnung": "Alu universal panels with column formwork platform"
                            },
                            {
                                "ID": "XALUFR_A11_AW6",
                                "Bezeichnung": "Alu universal panels with bracket"
                            },
                            {
                                "ID": "XALUFR_A11_AW2",
                                "Bezeichnung": "Panels with outside corners"
                            }
                        ]
                    },
                    {
                        "ID": "XALUFR_A12",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A12_AW2",
                                "Bezeichnung": "Tie rod 15.0 galvanised"
                            },
                            {
                                "ID": "XALUFR_A12_AW1",
                                "Bezeichnung": "Tie rod 15.0 non-treated"
                            },
                            {
                                "ID": "XALUFR_A12_AW3",
                                "Bezeichnung": "Head anchor 15-40cm"
                            },
                            {
                                "ID": "XALUFR_A12_AW4",
                                "Bezeichnung": "Head anchor 15-100cm"
                            }
                        ]
                    },
                    {
                        "ID": "XALUFR_A13",
                        "Bezeichnung": "One-sided corner and crossing",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A13_AW1",
                                "Bezeichnung": "Stripping corner"
                            },
                            {
                                "ID": "XALUFR_A13_AW2",
                                "Bezeichnung": "Outside or inside corner"
                            },
                            {
                                "ID": "XALUFR_A13_AW4",
                                "Bezeichnung": "Hinged outside or hinged inside corner galv."
                            },
                            {
                                "ID": "XALUFR_A13_AW3",
                                "Bezeichnung": "Hinged outside or hinged inside corner"
                            }
                        ]
                    },
                    {
                        "ID": "XALUFR_A15",
                        "Bezeichnung": "In-line connections",
                        "Loesung": [
                            {
                                "ID": "XALUFR_A15_AW2",
                                "Bezeichnung": "Use panels of previous cycle"
                            },
                            {
                                "ID": "XALUFR_A15_AW1",
                                "Bezeichnung": "Standard connection"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "XFRAMA_S",
                "Schalungsthema": [
                    {
                        "ID": "XFRAMA_S_A3",
                        "Bezeichnung": "Panel type",
                        "Loesung": [
                            {
                                "ID": "XFRAMA_S_A3_AW1",
                                "Bezeichnung": "Standard panels (1.35,1.05,0.90,0.75,0.60,0.45,0.30 wide)"
                            },
                            {
                                "ID": "XFRAMA_S_A3_AW2",
                                "Bezeichnung": "Large panels (2.40 wide)"
                            },
                            {
                                "ID": "XFRAMA_S_A3_AW3",
                                "Bezeichnung": "Universal panels (1.22 wide)"
                            },
                            {
                                "ID": "XFRAMA_S_A3_AW4",
                                "Bezeichnung": "Universal panels (0.90 wide)"
                            },
                            {
                                "ID": "XFRAMA_S_A3_AW5",
                                "Bezeichnung": "Foundation horizontal"
                            },
                            {
                                "ID": "XFRAMA_S_A3_AW6",
                                "Bezeichnung": "Foundation upright"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMA_S_A4",
                        "Bezeichnung": "Stop-end-formwork",
                        "Loesung": [
                            {
                                "ID": "XFRAMA_S_A4_AW1",
                                "Bezeichnung": "Universal waling and universal fixing bolt"
                            },
                            {
                                "ID": "XFRAMA_S_A4_AW2",
                                "Bezeichnung": "Universal waling and stop-end tie"
                            },
                            {
                                "ID": "XFRAMA_S_A4_AW3",
                                "Bezeichnung": "Universal panel 1.22 and universal fixing bolt"
                            },
                            {
                                "ID": "XFRAMA_S_A4_AW5",
                                "Bezeichnung": "Universal panel 0.90 and universal fixing bolt"
                            },
                            {
                                "ID": "XFRAMA_S_A4_AW4",
                                "Bezeichnung": "Universal waling and universal fixing bolt with joint strip"
                            },
                            {
                                "ID": "XFRAMA_S_A4_AW6",
                                "Bezeichnung": "Stop-end waler tie"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMA_S_A8",
                        "Bezeichnung": "Connection of stacking",
                        "Loesung": [
                            {
                                "ID": "XFRAMA_S_A8_AW1",
                                "Bezeichnung": "not stacked preferred"
                            },
                            {
                                "ID": "XFRAMA_S_A8_AW2",
                                "Bezeichnung": "Quick acting clamp RU (high walls universal waling)"
                            },
                            {
                                "ID": "XFRAMA_S_A8_AW3",
                                "Bezeichnung": "Multi function clamp (high walls multi-purpose waling)"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMA_S_A9",
                        "Bezeichnung": "Upright or horizontal stacking",
                        "Loesung": [
                            {
                                "ID": "XFRAMA_S_A9_AW1",
                                "Bezeichnung": "w/o stacking"
                            },
                            {
                                "ID": "XFRAMA_S_A9_AW2",
                                "Bezeichnung": "stacked upright"
                            },
                            {
                                "ID": "XFRAMA_S_A9_AW3",
                                "Bezeichnung": "stacked horiz."
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMA_S_A1",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "XFRAMA_S_A1_AW2",
                                "Bezeichnung": "Taper tie 1 1/2 - 1 1/4"
                            },
                            {
                                "ID": "XFRAMA_S_A1_AW1",
                                "Bezeichnung": "Rolled thread rod 1x$$ + she-bolt"
                            },
                            {
                                "ID": "XFRAMA_S_A1_AW3",
                                "Bezeichnung": "Rolled thread rod 1 all lengths without she-bolt"
                            },
                            {
                                "ID": "XFRAMA_S_A1_AW4",
                                "Bezeichnung": "Tie rod system 20.0 galvanised"
                            },
                            {
                                "ID": "XFRAMA_S_A1_AW5",
                                "Bezeichnung": "Tie rod system 20.0 non-treated"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMA_S_A2",
                        "Bezeichnung": "Shifting units",
                        "Loesung": [
                            {
                                "ID": "XFRAMA_S_A2_AW1",
                                "Bezeichnung": "not preferred"
                            },
                            {
                                "ID": "XFRAMA_S_A2_AW2",
                                "Bezeichnung": "preferred"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMA_S_A5",
                        "Bezeichnung": "Compensation connection",
                        "Loesung": [
                            {
                                "ID": "XFRAMA_S_A5_AW2",
                                "Bezeichnung": "Squared timber w/ multi function clamp, ties through panel"
                            },
                            {
                                "ID": "XFRAMA_S_A5_AW6",
                                "Bezeichnung": "Squared timber w/ multi function clamp, ties through closure"
                            },
                            {
                                "ID": "XFRAMA_S_A5_AW7",
                                "Bezeichnung": "Squared timber w/ adjustable clamp, ties through closure"
                            },
                            {
                                "ID": "XFRAMA_S_A5_AW10",
                                "Bezeichnung": "Fitting timber with multifunction clamp, ties through panel"
                            },
                            {
                                "ID": "XFRAMA_S_A5_AW9",
                                "Bezeichnung": "Fitting timber w/ multifunction clamp, ties through closure"
                            },
                            {
                                "ID": "XFRAMA_S_A5_AW11",
                                "Bezeichnung": "Fitting timber w/ adjustable clamp, ties through closure"
                            },
                            {
                                "ID": "XFRAMA_S_A5_AW5",
                                "Bezeichnung": "Formwork sheet and moulded timber with universal waling 1.50m"
                            },
                            {
                                "ID": "XFRAMA_S_A5_AW8",
                                "Bezeichnung": "Formwork sheet and moulded timber with universal waling 0.90m"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMA_S_A6",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "XFRAMA_S_A6_AW1",
                                "Bezeichnung": "Inside corner and universal panel 1.22"
                            },
                            {
                                "ID": "XFRAMA_S_A6_AW5",
                                "Bezeichnung": "Inside corner and universal panel 0.90"
                            },
                            {
                                "ID": "XFRAMA_S_A6_AW2",
                                "Bezeichnung": "Inside corner and outside corner"
                            },
                            {
                                "ID": "XFRAMA_S_A6_AW9",
                                "Bezeichnung": "Hinged inside corner galv.and hinged outside corner galv."
                            },
                            {
                                "ID": "XFRAMA_S_A6_AW10",
                                "Bezeichnung": "Hinged inside corner galv. inside and outside"
                            },
                            {
                                "ID": "XFRAMA_S_A6_AW3",
                                "Bezeichnung": "Hinged inside corner and hinged outside corner"
                            },
                            {
                                "ID": "XFRAMA_S_A6_AW4",
                                "Bezeichnung": "Hinged inside corner inside and outside"
                            },
                            {
                                "ID": "XFRAMA_S_A6_AW6",
                                "Bezeichnung": "Stripping corner and universal panel 1.22"
                            },
                            {
                                "ID": "XFRAMA_S_A6_AW8",
                                "Bezeichnung": "Stripping corner and universal panel 0.90"
                            },
                            {
                                "ID": "XFRAMA_S_A6_AW7",
                                "Bezeichnung": "Stripping corner and outside corner"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMA_S_A7",
                        "Bezeichnung": "Column",
                        "Loesung": [
                            {
                                "ID": "XFRAMA_S_A7_AW4",
                                "Bezeichnung": "Universal panel 0.90 with column formwork platform"
                            },
                            {
                                "ID": "XFRAMA_S_A7_AW5",
                                "Bezeichnung": "Universal panel 0.90 w/o column formwork platform"
                            },
                            {
                                "ID": "XFRAMA_S_A7_AW1",
                                "Bezeichnung": "Universal panel 1.22 with column formwork platform"
                            },
                            {
                                "ID": "XFRAMA_S_A7_AW3",
                                "Bezeichnung": "Universal panel 1.22 w/o column formwork platform"
                            },
                            {
                                "ID": "XFRAMA_S_A7_AW2",
                                "Bezeichnung": "Panels with outside corners"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMA_S_A10",
                        "Bezeichnung": "T-connection, one-sided corner and crossing",
                        "Loesung": [
                            {
                                "ID": "XFRAMA_S_A10_AW2",
                                "Bezeichnung": "Outside corner + wedge pin + tensioning wedge"
                            },
                            {
                                "ID": "XFRAMA_S_A10_AW1",
                                "Bezeichnung": "Inside corner, outside corner"
                            },
                            {
                                "ID": "XFRAMA_S_A10_AW5",
                                "Bezeichnung": "Hinged inside corner galv."
                            },
                            {
                                "ID": "XFRAMA_S_A10_AW6",
                                "Bezeichnung": "Hinged outside corner galv."
                            },
                            {
                                "ID": "XFRAMA_S_A10_AW3",
                                "Bezeichnung": "Hinged inside corner"
                            },
                            {
                                "ID": "XFRAMA_S_A10_AW4",
                                "Bezeichnung": "Hinged outside corner"
                            },
                            {
                                "ID": "XFRAMA_S_A10_AW7",
                                "Bezeichnung": "Pilaster panel"
                            },
                            {
                                "ID": "XFRAMA_S_A10_AW8",
                                "Bezeichnung": "Stripping corner I"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMA_S_A11",
                        "Bezeichnung": "In-line connections",
                        "Loesung": [
                            {
                                "ID": "XFRAMA_S_A11_AW2",
                                "Bezeichnung": "Use panels of previous cycle"
                            },
                            {
                                "ID": "XFRAMA_S_A11_AW1",
                                "Bezeichnung": "Standard connection"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "FF20_27",
                "Schalungsthema": [
                    {
                        "ID": "FF20_27_A2",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "FF20_27_A2_AW1",
                                "Bezeichnung": "Hitting panels with outside angle plate FF20"
                            },
                            {
                                "ID": "FF20_27_A2_AW2",
                                "Bezeichnung": "Shifted panels with outside angle plate FF20"
                            },
                            {
                                "ID": "FF20_27_A2_AW4",
                                "Bezeichnung": "Hitting panels with universal angle tie bracket"
                            },
                            {
                                "ID": "FF20_27_A2_AW3",
                                "Bezeichnung": "Hinged corners"
                            }
                        ]
                    },
                    {
                        "ID": "FF20_27_A3",
                        "Bezeichnung": "Stop-end, wall sockets, closures",
                        "Loesung": [
                            {
                                "ID": "FF20_27_A3_AW1",
                                "Bezeichnung": "P-beam top"
                            },
                            {
                                "ID": "FF20_27_A3_AW2",
                                "Bezeichnung": "N-beam top"
                            },
                            {
                                "ID": "FF20_27_A3_AW3",
                                "Bezeichnung": "P-beam eco"
                            },
                            {
                                "ID": "FF20_27_A3_AW4",
                                "Bezeichnung": "N-beam eco"
                            },
                            {
                                "ID": "FF20_27_A3_AW5",
                                "Bezeichnung": "P-beam"
                            },
                            {
                                "ID": "FF20_27_A3_AW6",
                                "Bezeichnung": "N-beam"
                            },
                            {
                                "ID": "FF20_27_A3_AW7",
                                "Bezeichnung": "without beam (closure)"
                            },
                            {
                                "ID": "FF20_27_A3_AW8",
                                "Bezeichnung": "Closure plate (closure)"
                            }
                        ]
                    },
                    {
                        "ID": "FF20_27_A1",
                        "Bezeichnung": "Panel width",
                        "Loesung": [
                            {
                                "ID": "FF20_27_A1_AW1",
                                "Bezeichnung": "Width 2.00 m"
                            },
                            {
                                "ID": "FF20_27_A1_AW2",
                                "Bezeichnung": "Width 1.00 m"
                            },
                            {
                                "ID": "FF20_27_A1_AW3",
                                "Bezeichnung": "Width 0.75 m"
                            },
                            {
                                "ID": "FF20_27_A1_AW4",
                                "Bezeichnung": "Width 0.50 m"
                            },
                            {
                                "ID": "FF20_27_A1_AW5",
                                "Bezeichnung": "Width 0.75/1.00 m (corner)"
                            }
                        ]
                    },
                    {
                        "ID": "FF20_27_A4",
                        "Bezeichnung": "Panel connection",
                        "Loesung": [
                            {
                                "ID": "FF20_27_A4_AW1",
                                "Bezeichnung": "with pull-off function"
                            },
                            {
                                "ID": "FF20_27_A4_AW2",
                                "Bezeichnung": "without pull-off function"
                            }
                        ]
                    },
                    {
                        "ID": "FF20_27_A5",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "FF20_27_A5_AW2",
                                "Bezeichnung": "Tie rod 15.0 galvanised"
                            },
                            {
                                "ID": "FF20_27_A5_AW1",
                                "Bezeichnung": "Tie rod 15.0 non-treated"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "FF20_21",
                "Schalungsthema": [
                    {
                        "ID": "FF20_21_A3",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "FF20_21_A3_AW1",
                                "Bezeichnung": "Hitting panels with outside angle plate FF20"
                            },
                            {
                                "ID": "FF20_21_A3_AW2",
                                "Bezeichnung": "Shifted panels with outside angle plate FF20"
                            },
                            {
                                "ID": "FF20_21_A3_AW5",
                                "Bezeichnung": "Hitting panels with universal angle tie bracket"
                            },
                            {
                                "ID": "FF20_21_A3_AW3",
                                "Bezeichnung": "Swiveling corners with panel 0,50"
                            },
                            {
                                "ID": "FF20_21_A3_AW4",
                                "Bezeichnung": "Swiveling corners with panel 0,75"
                            }
                        ]
                    },
                    {
                        "ID": "FF20_21_A4",
                        "Bezeichnung": "Stop-end, wall sockets",
                        "Loesung": [
                            {
                                "ID": "FF20_21_A4_AW1",
                                "Bezeichnung": "P-beam top"
                            },
                            {
                                "ID": "FF20_21_A4_AW2",
                                "Bezeichnung": "N-beam top"
                            },
                            {
                                "ID": "FF20_21_A4_AW3",
                                "Bezeichnung": "P-beam eco"
                            },
                            {
                                "ID": "FF20_21_A4_AW4",
                                "Bezeichnung": "N-beam eco"
                            },
                            {
                                "ID": "FF20_21_A4_AW5",
                                "Bezeichnung": "P-beam"
                            },
                            {
                                "ID": "FF20_21_A4_AW6",
                                "Bezeichnung": "N-beam"
                            },
                            {
                                "ID": "FF20_21_A4_AW7",
                                "Bezeichnung": "without beam"
                            }
                        ]
                    },
                    {
                        "ID": "FF20_21_A1",
                        "Bezeichnung": "Panel height",
                        "Loesung": [
                            {
                                "ID": "FF20_21_A1_AW1",
                                "Bezeichnung": "with 3.75 panel"
                            },
                            {
                                "ID": "FF20_21_A1_AW2",
                                "Bezeichnung": "without 3.75 panel"
                            },
                            {
                                "ID": "FF20_21_A1_AW3",
                                "Bezeichnung": "with 6.50 panel"
                            }
                        ]
                    },
                    {
                        "ID": "FF20_21_A2",
                        "Bezeichnung": "Stacking element",
                        "Loesung": [
                            {
                                "ID": "FF20_21_A2_AW1",
                                "Bezeichnung": "Yes"
                            },
                            {
                                "ID": "FF20_21_A2_AW2",
                                "Bezeichnung": "No"
                            }
                        ]
                    },
                    {
                        "ID": "FF20_21_A5",
                        "Bezeichnung": "Panel connection",
                        "Loesung": [
                            {
                                "ID": "FF20_21_A5_AW1",
                                "Bezeichnung": "with pull-off function"
                            },
                            {
                                "ID": "FF20_21_A5_AW2",
                                "Bezeichnung": "without pull-off function"
                            }
                        ]
                    },
                    {
                        "ID": "FF20_21_A6",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "FF20_21_A6_AW2",
                                "Bezeichnung": "Tie rod 15.0 galvanised"
                            },
                            {
                                "ID": "FF20_21_A6_AW1",
                                "Bezeichnung": "Tie rod 15.0 non-treated"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "FF100TEC",
                "Schalungsthema": [
                    {
                        "ID": "FF100TEC_A1",
                        "Bezeichnung": "Straight infill wall",
                        "Loesung": [
                            {
                                "ID": "FF100TEC_A1_AW1",
                                "Bezeichnung": "not stacked"
                            },
                            {
                                "ID": "FF100TEC_A1_AW2",
                                "Bezeichnung": "stacked"
                            },
                            {
                                "ID": "FF100TEC_A1_AW3",
                                "Bezeichnung": "not stacked, element rotated"
                            }
                        ]
                    },
                    {
                        "ID": "FF100TEC_A2",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "FF100TEC_A2_AW1",
                                "Bezeichnung": "Tie rod 20.0 galvanised"
                            },
                            {
                                "ID": "FF100TEC_A2_AW2",
                                "Bezeichnung": "Tie rod 20.0 non-treated"
                            }
                        ]
                    },
                    {
                        "ID": "FF100TEC_A3",
                        "Bezeichnung": "Closure",
                        "Loesung": [
                            {
                                "ID": "FF100TEC_A3_AW2",
                                "Bezeichnung": "Formwork element connector"
                            },
                            {
                                "ID": "FF100TEC_A3_AW1",
                                "Bezeichnung": "Adjustable waling extension"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "FL20",
                "Schalungsthema": [
                    {
                        "ID": "FL20_A1",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "FL20_A1_AW1",
                                "Bezeichnung": "Hitting panels"
                            },
                            {
                                "ID": "FL20_A1_AW2",
                                "Bezeichnung": "Shifted panels"
                            },
                            {
                                "ID": "FL20_A1_AW3",
                                "Bezeichnung": "Swiveling corner"
                            }
                        ]
                    },
                    {
                        "ID": "FL20_A3",
                        "Bezeichnung": "Stop-end, wall junctions, closures",
                        "Loesung": [
                            {
                                "ID": "FL20_A3_AW1",
                                "Bezeichnung": "P-beam top"
                            },
                            {
                                "ID": "FL20_A3_AW3",
                                "Bezeichnung": "P-beam eco"
                            },
                            {
                                "ID": "FL20_A3_AW5",
                                "Bezeichnung": "P-beam"
                            },
                            {
                                "ID": "FL20_A3_AW6",
                                "Bezeichnung": "Planks"
                            }
                        ]
                    },
                    {
                        "ID": "FL20_A4",
                        "Bezeichnung": "Panel connection",
                        "Loesung": [
                            {
                                "ID": "FL20_A4_AW1",
                                "Bezeichnung": "with connector plate LZ"
                            },
                            {
                                "ID": "FL20_A4_AW2",
                                "Bezeichnung": "with connector plate L"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "XFRAMI",
                "Schalungsthema": [
                    {
                        "ID": "XFRAMI_A6",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_A6_AW1",
                                "Bezeichnung": "Inside corner and universal panel 0.75"
                            },
                            {
                                "ID": "XFRAMI_A6_AW6",
                                "Bezeichnung": "Inside corner and universal panel 0.90"
                            },
                            {
                                "ID": "XFRAMI_A6_AW2",
                                "Bezeichnung": "Inside corner and outside corner"
                            },
                            {
                                "ID": "XFRAMI_A6_AW8",
                                "Bezeichnung": "Hinged inside corner galv. and universal panel 0.75"
                            },
                            {
                                "ID": "XFRAMI_A6_AW11",
                                "Bezeichnung": "Hinged inside corner galv. and universal panel 0.90"
                            },
                            {
                                "ID": "XFRAMI_A6_AW9",
                                "Bezeichnung": "Hinged inside galv.and hinged outside corner galv."
                            },
                            {
                                "ID": "XFRAMI_A6_AW10",
                                "Bezeichnung": "Hinged inside corner galv. inside and outside"
                            },
                            {
                                "ID": "XFRAMI_A6_AW3",
                                "Bezeichnung": "Hinged inside corner and universal panel 0.75"
                            },
                            {
                                "ID": "XFRAMI_A6_AW7",
                                "Bezeichnung": "Hinged inside corner and universal panel 0.90"
                            },
                            {
                                "ID": "XFRAMI_A6_AW4",
                                "Bezeichnung": "Hinged inside and hinged outside corner"
                            },
                            {
                                "ID": "XFRAMI_A6_AW5",
                                "Bezeichnung": "Hinged inside corner inside and outside"
                            },
                            {
                                "ID": "XFRAMI_A6_AW12",
                                "Bezeichnung": "Stripping corner (only on one side)"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_A5",
                        "Bezeichnung": "Panel type",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_A5_AW1",
                                "Bezeichnung": "Standard panels"
                            },
                            {
                                "ID": "XFRAMI_A5_AW2",
                                "Bezeichnung": "Universal panels (0.90, 0.75 wide)"
                            },
                            {
                                "ID": "XFRAMI_A5_AW3",
                                "Bezeichnung": "Anchoring panels"
                            },
                            {
                                "ID": "XFRAMI_A5_AW#",
                                "Bezeichnung": "XFRAMI_A5_AW#"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_A10",
                        "Bezeichnung": "Shifting units preferred Yes/No",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_A10_AW2",
                                "Bezeichnung": "Preferred No"
                            },
                            {
                                "ID": "XFRAMI_A10_AW1",
                                "Bezeichnung": "Preferred Yes"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_A12",
                        "Bezeichnung": "Large panel",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_A12_AW1",
                                "Bezeichnung": "without"
                            },
                            {
                                "ID": "XFRAMI_A12_AW2",
                                "Bezeichnung": "upright"
                            },
                            {
                                "ID": "XFRAMI_A12_AW3",
                                "Bezeichnung": "horizontal"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_A1",
                        "Bezeichnung": "Basic panel height",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_A1_AW1",
                                "Bezeichnung": "1.20 and 1.50 m"
                            },
                            {
                                "ID": "XFRAMI_A1_AW2",
                                "Bezeichnung": "2.70 m"
                            },
                            {
                                "ID": "XFRAMI_A1_AW3",
                                "Bezeichnung": "3.00 m"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_A3",
                        "Bezeichnung": "Stacking",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_A3_AW3",
                                "Bezeichnung": "w/o stacking"
                            },
                            {
                                "ID": "XFRAMI_A3_AW4",
                                "Bezeichnung": "stacking w/o bracing"
                            },
                            {
                                "ID": "XFRAMI_A3_AW1",
                                "Bezeichnung": "Aligning clamp"
                            },
                            {
                                "ID": "XFRAMI_A3_AW2",
                                "Bezeichnung": "Universal waling"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_A2",
                        "Bezeichnung": "Upright or horiz. panels",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_A2_AW1",
                                "Bezeichnung": "upright"
                            },
                            {
                                "ID": "XFRAMI_A2_AW2",
                                "Bezeichnung": "horizontal"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_A9",
                        "Bezeichnung": "Tie rod galvanised or non-treated",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_A9_AW1",
                                "Bezeichnung": "Tie rod galvanised"
                            },
                            {
                                "ID": "XFRAMI_A9_AW2",
                                "Bezeichnung": "Tie rod non-treated"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_A4",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_A4_AW4",
                                "Bezeichnung": "Tie rod"
                            },
                            {
                                "ID": "XFRAMI_A4_AW2",
                                "Bezeichnung": "Foundation clamp and flat tie-rod"
                            },
                            {
                                "ID": "XFRAMI_A4_AW1",
                                "Bezeichnung": "Foundation clamp and tie-holder bracket"
                            },
                            {
                                "ID": "XFRAMI_A4_AW5",
                                "Bezeichnung": "Floor fixing plate and flat tie-rod"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_A7",
                        "Bezeichnung": "Wall end",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_A7_AW1",
                                "Bezeichnung": "Universal walings"
                            },
                            {
                                "ID": "XFRAMI_A7_AW2",
                                "Bezeichnung": "Universal panel 0.75"
                            },
                            {
                                "ID": "XFRAMI_A7_AW3",
                                "Bezeichnung": "Universal panel 0.90"
                            },
                            {
                                "ID": "XFRAMI_A7_AW4",
                                "Bezeichnung": "Stop-end waler tie"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_A8",
                        "Bezeichnung": "Connections, closures",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_A8_AW12",
                                "Bezeichnung": "Use panels of previous cycle"
                            },
                            {
                                "ID": "XFRAMI_A8_AW1",
                                "Bezeichnung": "Fitting timber Doka, w/o universal waling"
                            },
                            {
                                "ID": "XFRAMI_A8_AW2",
                                "Bezeichnung": "Squared timber by site, w/o universal waling"
                            },
                            {
                                "ID": "XFRAMI_A8_AW7",
                                "Bezeichnung": "Fitting timber Doka, with universal waling"
                            },
                            {
                                "ID": "XFRAMI_A8_AW8",
                                "Bezeichnung": "Squared timber by site, with universal waling"
                            },
                            {
                                "ID": "XFRAMI_A8_AW3",
                                "Bezeichnung": "Universal panel 0.75"
                            },
                            {
                                "ID": "XFRAMI_A8_AW9",
                                "Bezeichnung": "Universal panel 0.90"
                            },
                            {
                                "ID": "XFRAMI_A8_AW4",
                                "Bezeichnung": "Anchoring panel"
                            },
                            {
                                "ID": "XFRAMI_A8_AW10",
                                "Bezeichnung": "Standard panel"
                            },
                            {
                                "ID": "XFRAMI_A8_AW5",
                                "Bezeichnung": "Plywood support and formwork sheet 27 mm"
                            },
                            {
                                "ID": "XFRAMI_A8_AW6",
                                "Bezeichnung": "Plywood support and formwork sheet 21 mm"
                            },
                            {
                                "ID": "XFRAMI_A8_AW11",
                                "Bezeichnung": "Plywood support and formwork sheet 18 mm"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_A11",
                        "Bezeichnung": "Column",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_A11_AW1",
                                "Bezeichnung": "Universal panel 0.75"
                            },
                            {
                                "ID": "XFRAMI_A11_AW2",
                                "Bezeichnung": "Universal panel 0.90"
                            },
                            {
                                "ID": "XFRAMI_A11_AW3",
                                "Bezeichnung": "Universal panel 0.75 and panels"
                            },
                            {
                                "ID": "XFRAMI_A11_AW4",
                                "Bezeichnung": "Universal panel 0.90 and panels"
                            },
                            {
                                "ID": "XFRAMI_A11_AW5",
                                "Bezeichnung": "Panels with outside corner"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "FRAMIECO",
                "Schalungsthema": [
                    {
                        "ID": "FRAMIECO_A12",
                        "Bezeichnung": "Panel connection",
                        "Loesung": [
                            {
                                "ID": "FRAMIECO_A12_AW1",
                                "Bezeichnung": "Frami clamp"
                            },
                            {
                                "ID": "FRAMIECO_A12_AW2",
                                "Bezeichnung": "Frami clip"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMIECO_A6",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "FRAMIECO_A6_AW1",
                                "Bezeichnung": "Inside corner and universal panel"
                            },
                            {
                                "ID": "FRAMIECO_A6_AW2",
                                "Bezeichnung": "Inside corner and outside corner"
                            },
                            {
                                "ID": "FRAMIECO_A6_AW6",
                                "Bezeichnung": "Hinged inside corner galv. and universal panel"
                            },
                            {
                                "ID": "FRAMIECO_A6_AW7",
                                "Bezeichnung": "Hinged inside galv.and hinged outside corner galv."
                            },
                            {
                                "ID": "FRAMIECO_A6_AW8",
                                "Bezeichnung": "Hinged inside corner galv. inside and outside"
                            },
                            {
                                "ID": "FRAMIECO_A6_AW3",
                                "Bezeichnung": "Hinged inside corner and univ. panel"
                            },
                            {
                                "ID": "FRAMIECO_A6_AW4",
                                "Bezeichnung": "Hinged inside and hinged outside corner"
                            },
                            {
                                "ID": "FRAMIECO_A6_AW5",
                                "Bezeichnung": "Hinged inside corner inside and outside"
                            },
                            {
                                "ID": "FRAMIECO_A6_AW9",
                                "Bezeichnung": "Stripping corner (only on one side)"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMIECO_A5",
                        "Bezeichnung": "Panel type",
                        "Loesung": [
                            {
                                "ID": "FRAMIECO_A5_AW1",
                                "Bezeichnung": "Standard panels"
                            },
                            {
                                "ID": "FRAMIECO_A5_AW2",
                                "Bezeichnung": "Universal panels"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMIECO_A10",
                        "Bezeichnung": "Shifting units preferred Yes/No",
                        "Loesung": [
                            {
                                "ID": "FRAMIECO_A10_AW2",
                                "Bezeichnung": "Preferred No"
                            },
                            {
                                "ID": "FRAMIECO_A10_AW1",
                                "Bezeichnung": "Preferred Yes"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMIECO_A1",
                        "Bezeichnung": "Basic panel height",
                        "Loesung": [
                            {
                                "ID": "FRAMIECO_A1_AW1",
                                "Bezeichnung": "1.20 m"
                            },
                            {
                                "ID": "FRAMIECO_A1_AW2",
                                "Bezeichnung": "2.70 m"
                            },
                            {
                                "ID": "FRAMIECO_A1_AW3",
                                "Bezeichnung": "3.00 m"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMIECO_A3",
                        "Bezeichnung": "Stacking",
                        "Loesung": [
                            {
                                "ID": "FRAMIECO_A3_AW1",
                                "Bezeichnung": "Aligning clamp"
                            },
                            {
                                "ID": "FRAMIECO_A3_AW2",
                                "Bezeichnung": "Universal waling"
                            },
                            {
                                "ID": "FRAMIECO_A3_AW3",
                                "Bezeichnung": "w/o stacking"
                            },
                            {
                                "ID": "FRAMIECO_A3_AW4",
                                "Bezeichnung": "stacking w/o bracing"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMIECO_A2",
                        "Bezeichnung": "Upright or horiz. panels",
                        "Loesung": [
                            {
                                "ID": "FRAMIECO_A2_AW1",
                                "Bezeichnung": "upright"
                            },
                            {
                                "ID": "FRAMIECO_A2_AW2",
                                "Bezeichnung": "horizontal"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMIECO_A9",
                        "Bezeichnung": "Tie rod galvanised or non-treated",
                        "Loesung": [
                            {
                                "ID": "FRAMIECO_A9_AW1",
                                "Bezeichnung": "Tie rod galvanised"
                            },
                            {
                                "ID": "FRAMIECO_A9_AW2",
                                "Bezeichnung": "Tie rod non-treated"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMIECO_A4",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "FRAMIECO_A4_AW4",
                                "Bezeichnung": "Tie rod"
                            },
                            {
                                "ID": "FRAMIECO_A4_AW1",
                                "Bezeichnung": "Foundation clamp and tie-holder bracket"
                            },
                            {
                                "ID": "FRAMIECO_A4_AW2",
                                "Bezeichnung": "Foundation clamp and flat tie-rod"
                            },
                            {
                                "ID": "FRAMIECO_A4_AW5",
                                "Bezeichnung": "Floor fixing plate and flat tie-rod"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMIECO_A7",
                        "Bezeichnung": "Wall end",
                        "Loesung": [
                            {
                                "ID": "FRAMIECO_A7_AW1",
                                "Bezeichnung": "Universal walings"
                            },
                            {
                                "ID": "FRAMIECO_A7_AW2",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "FRAMIECO_A7_AW3",
                                "Bezeichnung": "Stop-end waler tie"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMIECO_A8",
                        "Bezeichnung": "Connections, closures",
                        "Loesung": [
                            {
                                "ID": "FRAMIECO_A8_AW1",
                                "Bezeichnung": "Fitting timber Doka, w/o universal waling"
                            },
                            {
                                "ID": "FRAMIECO_A8_AW2",
                                "Bezeichnung": "Squared timber by site, w/o universal waling"
                            },
                            {
                                "ID": "FRAMIECO_A8_AW7",
                                "Bezeichnung": "Fitting timber Doka, with universal waling"
                            },
                            {
                                "ID": "FRAMIECO_A8_AW8",
                                "Bezeichnung": "Squared timber by site, with universal waling"
                            },
                            {
                                "ID": "FRAMIECO_A8_AW3",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "FRAMIECO_A8_AW4",
                                "Bezeichnung": "Anchoring panel"
                            },
                            {
                                "ID": "FRAMIECO_A8_AW5",
                                "Bezeichnung": "Plywood support and formwork sheet 27 mm"
                            },
                            {
                                "ID": "FRAMIECO_A8_AW6",
                                "Bezeichnung": "Plywood support and formwork sheet 21 mm"
                            },
                            {
                                "ID": "FRAMIECO_A8_AW9",
                                "Bezeichnung": "Plywood support and formwork sheet 18 mm"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMIECO_A11",
                        "Bezeichnung": "Column",
                        "Loesung": [
                            {
                                "ID": "FRAMIECO_A11_AW1",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "FRAMIECO_A11_AW2",
                                "Bezeichnung": "Universal panel and panel"
                            },
                            {
                                "ID": "FRAMIECO_A11_AW3",
                                "Bezeichnung": "Panels with outside corner"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMIECO_A13",
                        "Bezeichnung": "In-line connections",
                        "Loesung": [
                            {
                                "ID": "FRAMIECO_A13_AW2",
                                "Bezeichnung": "Use panels of previous cycle"
                            },
                            {
                                "ID": "FRAMIECO_A13_AW1",
                                "Bezeichnung": "Standard connection"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "XFRAMI_S",
                "Schalungsthema": [
                    {
                        "ID": "XFRAMI_S_A10",
                        "Bezeichnung": "Shifting units preferred Yes/No",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_S_A10_AW1",
                                "Bezeichnung": "Shifting units preferred"
                            },
                            {
                                "ID": "XFRAMI_S_A10_AW2",
                                "Bezeichnung": "Shifting units not preferred"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_S_A8",
                        "Bezeichnung": "Straight infill wall",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_S_A8_AW7",
                                "Bezeichnung": "Panels without stacking"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW6",
                                "Bezeichnung": "stacked upright Base 9'"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW9",
                                "Bezeichnung": "stacked upright Base 6'"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW10",
                                "Bezeichnung": "stacked upright Base 4'"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW11",
                                "Bezeichnung": "stacked upright Base 3'"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW1",
                                "Bezeichnung": "Upright panels stacked horiz. (width 9')"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW18",
                                "Bezeichnung": "Upright panels stacked horiz. (width 6')"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW8",
                                "Bezeichnung": "Stacked upright and horiz. (width 9')"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW19",
                                "Bezeichnung": "Stacked upright and horiz. (width 6')"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW16",
                                "Bezeichnung": "Upright universal panels"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW3",
                                "Bezeichnung": "Foundation horizontal with foundation clamp"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW12",
                                "Bezeichnung": "Foundation horizontal with floor fixing plate"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW4",
                                "Bezeichnung": "Foundation horizontal universal panels"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW13",
                                "Bezeichnung": "Foundation upright with foundation clamp"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW14",
                                "Bezeichnung": "Foundation upright with floor fixing plate"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW2",
                                "Bezeichnung": "Foundation upright with tie-holder bracket"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW17",
                                "Bezeichnung": "Foundation upright universal panels with tie-holder bracket"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW5",
                                "Bezeichnung": "Foundation upright universal panels with foundation clamp"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW15",
                                "Bezeichnung": "Foundation upright universal panels with floor fixing plate"
                            },
                            {
                                "ID": "XFRAMI_S_A8_AW20",
                                "Bezeichnung": "Horizontal panels"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_S_A7",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_S_A7_AW1",
                                "Bezeichnung": "Inside corner and universal panel"
                            },
                            {
                                "ID": "XFRAMI_S_A7_AW2",
                                "Bezeichnung": "Inside corner and outside corner"
                            },
                            {
                                "ID": "XFRAMI_S_A7_AW9",
                                "Bezeichnung": "Hinged inside + hinged outside corner galv."
                            },
                            {
                                "ID": "XFRAMI_S_A7_AW10",
                                "Bezeichnung": "Hinged inside corner galv. inside and outside"
                            },
                            {
                                "ID": "XFRAMI_S_A7_AW3",
                                "Bezeichnung": "Hinged inside and hinged outside corner"
                            },
                            {
                                "ID": "XFRAMI_S_A7_AW4",
                                "Bezeichnung": "Hinged inside corner inside and outside"
                            },
                            {
                                "ID": "XFRAMI_S_A7_AW5",
                                "Bezeichnung": "Wall thickness 30 panel as closure, w/ inside corner"
                            },
                            {
                                "ID": "XFRAMI_S_A7_AW6",
                                "Bezeichnung": "Stripping corner and outside corner"
                            },
                            {
                                "ID": "XFRAMI_S_A7_AW7",
                                "Bezeichnung": "Stripping corner and universal panel"
                            },
                            {
                                "ID": "XFRAMI_S_A7_AW8",
                                "Bezeichnung": "Wall thickness 30 panel as closure, w/ stripping corner"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_S_A4",
                        "Bezeichnung": "Wall end",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_S_A4_AW1",
                                "Bezeichnung": "Universal walings"
                            },
                            {
                                "ID": "XFRAMI_S_A4_AW2",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "XFRAMI_S_A4_AW3",
                                "Bezeichnung": "Framed panel"
                            },
                            {
                                "ID": "XFRAMI_S_A4_AW4",
                                "Bezeichnung": "Stop-end waler tie"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_S_A2",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_S_A2_AW1",
                                "Bezeichnung": "Taper-tie system 3/4 to 1"
                            },
                            {
                                "ID": "XFRAMI_S_A2_AW2",
                                "Bezeichnung": "She-bolt system 7/8"
                            },
                            {
                                "ID": "XFRAMI_S_A2_AW3",
                                "Bezeichnung": "15.0 Tie rod non-treated"
                            },
                            {
                                "ID": "XFRAMI_S_A2_AW4",
                                "Bezeichnung": "15.0 Tie rod galvanised"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_S_A3",
                        "Bezeichnung": "One-sided corner",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_S_A3_AW1",
                                "Bezeichnung": "Inside corner + outside corner"
                            },
                            {
                                "ID": "XFRAMI_S_A3_AW5",
                                "Bezeichnung": "Hinged inside + hinged outside corner galv."
                            },
                            {
                                "ID": "XFRAMI_S_A3_AW3",
                                "Bezeichnung": "Hinged inside + hinged outside corner"
                            },
                            {
                                "ID": "XFRAMI_S_A3_AW4",
                                "Bezeichnung": "Stripping corner"
                            },
                            {
                                "ID": "XFRAMI_S_A3_AW2",
                                "Bezeichnung": "Pilaster panel with universal fixing bolt"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_S_A5",
                        "Bezeichnung": "Closures",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_S_A5_AW1",
                                "Bezeichnung": "Steel closure plate, ties through closure"
                            },
                            {
                                "ID": "XFRAMI_S_A5_AW4",
                                "Bezeichnung": "Steel closure plate, ties through panel"
                            },
                            {
                                "ID": "XFRAMI_S_A5_AW3",
                                "Bezeichnung": "Filler block"
                            },
                            {
                                "ID": "XFRAMI_S_A5_AW2",
                                "Bezeichnung": "Framed panel"
                            },
                            {
                                "ID": "XFRAMI_S_A5_AW5",
                                "Bezeichnung": "Formwork sheet and plywood support"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_S_A6",
                        "Bezeichnung": "Column",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_S_A6_AW4",
                                "Bezeichnung": "Universal panel with triangular ledge"
                            },
                            {
                                "ID": "XFRAMI_S_A6_AW1",
                                "Bezeichnung": "Universal panel and framed panel"
                            },
                            {
                                "ID": "XFRAMI_S_A6_AW2",
                                "Bezeichnung": "Panels with outside corner"
                            },
                            {
                                "ID": "XFRAMI_S_A6_AW3",
                                "Bezeichnung": "Framed panel with column hinge"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_S_A1",
                        "Bezeichnung": "Wall connections",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_S_A1_AW3",
                                "Bezeichnung": "Use panels of previous cycle"
                            },
                            {
                                "ID": "XFRAMI_S_A1_AW1",
                                "Bezeichnung": "Squared timber"
                            },
                            {
                                "ID": "XFRAMI_S_A1_AW2",
                                "Bezeichnung": "Formwork element"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMI_S_A9",
                        "Bezeichnung": "T-connection",
                        "Loesung": [
                            {
                                "ID": "XFRAMI_S_A9_AW2",
                                "Bezeichnung": "w/ inside corner"
                            },
                            {
                                "ID": "XFRAMI_S_A9_AW3",
                                "Bezeichnung": "w/ stripping corner"
                            },
                            {
                                "ID": "XFRAMI_S_A9_AW1",
                                "Bezeichnung": "w/ pilaster panel"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "XLIGHT",
                "Schalungsthema": [
                    {
                        "ID": "XLIGHT_A8",
                        "Bezeichnung": "Basic panel height",
                        "Loesung": [
                            {
                                "ID": "XLIGHT_A8_AW1",
                                "Bezeichnung": "1.50m"
                            },
                            {
                                "ID": "XLIGHT_A8_AW2",
                                "Bezeichnung": "1.00m"
                            },
                            {
                                "ID": "XLIGHT_A8_AW3",
                                "Bezeichnung": "3.00m"
                            },
                            {
                                "ID": "XLIGHT_A8_AW#",
                                "Bezeichnung": "XLIGHT_A8_AW#"
                            }
                        ]
                    },
                    {
                        "ID": "XLIGHT_A2",
                        "Bezeichnung": "Stacking",
                        "Loesung": [
                            {
                                "ID": "XLIGHT_A2_AW1",
                                "Bezeichnung": "w/o stacking"
                            },
                            {
                                "ID": "XLIGHT_A2_AW2",
                                "Bezeichnung": "stacked horiz."
                            },
                            {
                                "ID": "XLIGHT_A2_AW3",
                                "Bezeichnung": "stacked upright"
                            },
                            {
                                "ID": "XLIGHT_A2_AW#",
                                "Bezeichnung": "XLIGHT_A2_AW#"
                            }
                        ]
                    },
                    {
                        "ID": "XLIGHT_A3",
                        "Bezeichnung": "Upright or horiz. panels",
                        "Loesung": [
                            {
                                "ID": "XLIGHT_A3_AW1",
                                "Bezeichnung": "Upright panels"
                            },
                            {
                                "ID": "XLIGHT_A3_AW2",
                                "Bezeichnung": "Horiz. panels"
                            },
                            {
                                "ID": "XLIGHT_A3_AW#",
                                "Bezeichnung": "XLIGHT_A3_AW#"
                            }
                        ]
                    },
                    {
                        "ID": "XLIGHT_A9",
                        "Bezeichnung": "Foundation solution",
                        "Loesung": [
                            {
                                "ID": "XLIGHT_A9_AW1",
                                "Bezeichnung": "Single panel (for supporting construction accessory system)"
                            },
                            {
                                "ID": "XLIGHT_A9_AW2",
                                "Bezeichnung": "with tie-holder bracket"
                            },
                            {
                                "ID": "XLIGHT_A9_AW3",
                                "Bezeichnung": "with foundation clamp"
                            },
                            {
                                "ID": "XLIGHT_A9_AW4",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "XLIGHT_A9_AW5",
                                "Bezeichnung": "Upright panels with tie-holder bracket and tie rod"
                            },
                            {
                                "ID": "XLIGHT_A9_AW6",
                                "Bezeichnung": "Upright panels with tie-holder bracket and floor fixing plate"
                            },
                            {
                                "ID": "XLIGHT_A9_AW#",
                                "Bezeichnung": "XLIGHT_A9_AW#"
                            }
                        ]
                    },
                    {
                        "ID": "XLIGHT_A7",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "XLIGHT_A7_AW1",
                                "Bezeichnung": "Inside corner and outside corner"
                            },
                            {
                                "ID": "XLIGHT_A7_AW2",
                                "Bezeichnung": "Inside corner and universal panel"
                            },
                            {
                                "ID": "XLIGHT_A7_AW3",
                                "Bezeichnung": "Hinged inside corner and hinged outside corner"
                            },
                            {
                                "ID": "XLIGHT_A7_AW4",
                                "Bezeichnung": "Hinged inside corner inside and outside"
                            }
                        ]
                    },
                    {
                        "ID": "XLIGHT_A4",
                        "Bezeichnung": "Stop-end formwork",
                        "Loesung": [
                            {
                                "ID": "XLIGHT_A4_AW1",
                                "Bezeichnung": "Frami universal waling"
                            },
                            {
                                "ID": "XLIGHT_A4_AW2",
                                "Bezeichnung": "Frami stop-end waler tie"
                            },
                            {
                                "ID": "XLIGHT_A4_AW3",
                                "Bezeichnung": "Universal panel"
                            }
                        ]
                    },
                    {
                        "ID": "XLIGHT_A5",
                        "Bezeichnung": "Closure",
                        "Loesung": [
                            {
                                "ID": "XLIGHT_A5_AW2",
                                "Bezeichnung": "Ties through closure"
                            },
                            {
                                "ID": "XLIGHT_A5_AW1",
                                "Bezeichnung": "Ties through panel"
                            },
                            {
                                "ID": "XLIGHT_A5_AW3",
                                "Bezeichnung": "with formwork sheet"
                            }
                        ]
                    },
                    {
                        "ID": "XLIGHT_A1",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "XLIGHT_A1_AW1",
                                "Bezeichnung": "Tie rod 15.0 galvanised"
                            },
                            {
                                "ID": "XLIGHT_A1_AW2",
                                "Bezeichnung": "Tie rod 15.0 non-treated"
                            }
                        ]
                    },
                    {
                        "ID": "XLIGHT_A6",
                        "Bezeichnung": "Connections",
                        "Loesung": [
                            {
                                "ID": "XLIGHT_A6_AW1",
                                "Bezeichnung": "w/o closure"
                            },
                            {
                                "ID": "XLIGHT_A6_AW2",
                                "Bezeichnung": "with closure"
                            },
                            {
                                "ID": "XLIGHT_A6_AW3",
                                "Bezeichnung": "with closure and 0,30m panel"
                            },
                            {
                                "ID": "XLIGHT_A6_AW4",
                                "Bezeichnung": "Universal panel"
                            }
                        ]
                    },
                    {
                        "ID": "XLIGHT_A10",
                        "Bezeichnung": "Column",
                        "Loesung": [
                            {
                                "ID": "XLIGHT_A10_AW1",
                                "Bezeichnung": "Universal panel with bracket"
                            },
                            {
                                "ID": "XLIGHT_A10_AW2",
                                "Bezeichnung": "Univ. panel with bracket + counter railing"
                            },
                            {
                                "ID": "XLIGHT_A10_AW3",
                                "Bezeichnung": "Universal panel and panel"
                            },
                            {
                                "ID": "XLIGHT_A10_AW4",
                                "Bezeichnung": "Panels with outside corners"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "XFRAMINI",
                "Schalungsthema": [
                    {
                        "ID": "XFRAMINI_A4",
                        "Bezeichnung": "Panel connection",
                        "Loesung": [
                            {
                                "ID": "XFRAMINI_A4_AW1",
                                "Bezeichnung": "Framini clip"
                            },
                            {
                                "ID": "XFRAMINI_A4_AW2",
                                "Bezeichnung": "Frami clamp"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMINI_A1",
                        "Bezeichnung": "Basic panel height",
                        "Loesung": [
                            {
                                "ID": "XFRAMINI_A1_AW1",
                                "Bezeichnung": "1.00m"
                            },
                            {
                                "ID": "XFRAMINI_A1_AW2",
                                "Bezeichnung": "1.50m"
                            },
                            {
                                "ID": "XFRAMINI_A1_AW#",
                                "Bezeichnung": "XFRAMINI_A1_AW#"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMINI_A3",
                        "Bezeichnung": "Panel type",
                        "Loesung": [
                            {
                                "ID": "XFRAMINI_A3_AW1",
                                "Bezeichnung": "Standard panel"
                            },
                            {
                                "ID": "XFRAMINI_A3_AW2",
                                "Bezeichnung": "Universal panel"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMINI_A7",
                        "Bezeichnung": "Upright or horiz. panels",
                        "Loesung": [
                            {
                                "ID": "XFRAMINI_A7_AW1",
                                "Bezeichnung": "horizontal"
                            },
                            {
                                "ID": "XFRAMINI_A7_AW2",
                                "Bezeichnung": "upright"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMINI_A5",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "XFRAMINI_A5_AW1",
                                "Bezeichnung": "Universal panel 0.45m and Inside corner"
                            },
                            {
                                "ID": "XFRAMINI_A5_AW2",
                                "Bezeichnung": "Universal panel 0.75m and Inside corner"
                            },
                            {
                                "ID": "XFRAMINI_A5_AW3",
                                "Bezeichnung": "Outside corner and inside corner"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMINI_A6",
                        "Bezeichnung": "Stacking",
                        "Loesung": [
                            {
                                "ID": "XFRAMINI_A6_AW1",
                                "Bezeichnung": "without stacking"
                            },
                            {
                                "ID": "XFRAMINI_A6_AW2",
                                "Bezeichnung": "stacked horiz."
                            },
                            {
                                "ID": "XFRAMINI_A6_AW3",
                                "Bezeichnung": "stacked upright"
                            },
                            {
                                "ID": "XFRAMINI_A6_AW#",
                                "Bezeichnung": "XFRAMINI_A6_AW#"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMINI_A2",
                        "Bezeichnung": "Second panel height",
                        "Loesung": [
                            {
                                "ID": "XFRAMINI_A2_AW1",
                                "Bezeichnung": "1.00m"
                            },
                            {
                                "ID": "XFRAMINI_A2_AW2",
                                "Bezeichnung": "1.50m"
                            },
                            {
                                "ID": "XFRAMINI_A2_AW#",
                                "Bezeichnung": "XFRAMINI_A2_AW#"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMINI_A8",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "XFRAMINI_A8_AW1",
                                "Bezeichnung": "Tie rod 15.0 galvanised"
                            },
                            {
                                "ID": "XFRAMINI_A8_AW2",
                                "Bezeichnung": "Tie rod 15.0 non-treated"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMINI_A9",
                        "Bezeichnung": "Stop-end-formwork",
                        "Loesung": [
                            {
                                "ID": "XFRAMINI_A9_AW3",
                                "Bezeichnung": "Universal waling"
                            },
                            {
                                "ID": "XFRAMINI_A9_AW2",
                                "Bezeichnung": "Universal panel 0.45m"
                            },
                            {
                                "ID": "XFRAMINI_A9_AW1",
                                "Bezeichnung": "Universal panel 0.75m"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMINI_A10",
                        "Bezeichnung": "Closure",
                        "Loesung": [
                            {
                                "ID": "XFRAMINI_A10_AW1",
                                "Bezeichnung": "Filler block by site, ties through panel"
                            },
                            {
                                "ID": "XFRAMINI_A10_AW2",
                                "Bezeichnung": "Filler block by site, ties through closure"
                            },
                            {
                                "ID": "XFRAMINI_A10_AW3",
                                "Bezeichnung": "with formwork sheet 18mm and universal waling"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMINI_A11",
                        "Bezeichnung": "Connections",
                        "Loesung": [
                            {
                                "ID": "XFRAMINI_A11_AW7",
                                "Bezeichnung": "Use panels of previous cycle"
                            },
                            {
                                "ID": "XFRAMINI_A11_AW1",
                                "Bezeichnung": "without closure and filler block"
                            },
                            {
                                "ID": "XFRAMINI_A11_AW2",
                                "Bezeichnung": "with closure and filler block"
                            },
                            {
                                "ID": "XFRAMINI_A11_AW3",
                                "Bezeichnung": "0.30m panel and filler block"
                            },
                            {
                                "ID": "XFRAMINI_A11_AW4",
                                "Bezeichnung": "Standard panels"
                            },
                            {
                                "ID": "XFRAMINI_A11_AW5",
                                "Bezeichnung": "Filler block"
                            },
                            {
                                "ID": "XFRAMINI_A11_AW6",
                                "Bezeichnung": "Universal panel"
                            }
                        ]
                    },
                    {
                        "ID": "XFRAMINI_A12",
                        "Bezeichnung": "Column",
                        "Loesung": [
                            {
                                "ID": "XFRAMINI_A12_AW2",
                                "Bezeichnung": "Universal panel 0.45m"
                            },
                            {
                                "ID": "XFRAMINI_A12_AW1",
                                "Bezeichnung": "Universal panel 0.75m"
                            },
                            {
                                "ID": "XFRAMINI_A12_AW4",
                                "Bezeichnung": "Universal panel 0.45m with panel"
                            },
                            {
                                "ID": "XFRAMINI_A12_AW3",
                                "Bezeichnung": "Universal panel 0.75m with panel"
                            },
                            {
                                "ID": "XFRAMINI_A12_AW5",
                                "Bezeichnung": "Panels with outside corners"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "XREFORMX",
                "Schalungsthema": [
                    {
                        "ID": "XREFORMX_A7",
                        "Bezeichnung": "Panel type",
                        "Loesung": [
                            {
                                "ID": "XREFORMX_A7_AW1",
                                "Bezeichnung": "Standard panel"
                            },
                            {
                                "ID": "XREFORMX_A7_AW2",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "XREFORMX_A7_AW#",
                                "Bezeichnung": "XREFORMX_A7_AW#"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMX_A1",
                        "Bezeichnung": "Basic panel height",
                        "Loesung": [
                            {
                                "ID": "XREFORMX_A1_AW1",
                                "Bezeichnung": "1.50m"
                            },
                            {
                                "ID": "XREFORMX_A1_AW2",
                                "Bezeichnung": "3.00m"
                            },
                            {
                                "ID": "XREFORMX_A1_AW3",
                                "Bezeichnung": "3.30m"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMX_A6",
                        "Bezeichnung": "Stackings",
                        "Loesung": [
                            {
                                "ID": "XREFORMX_A6_AW1",
                                "Bezeichnung": "w/o stacking"
                            },
                            {
                                "ID": "XREFORMX_A6_AW2",
                                "Bezeichnung": "stacked horiz."
                            },
                            {
                                "ID": "XREFORMX_A6_AW3",
                                "Bezeichnung": "1.20m horizontal + stacked horiz."
                            },
                            {
                                "ID": "XREFORMX_A6_AW5",
                                "Bezeichnung": "stacked upright"
                            },
                            {
                                "ID": "XREFORMX_A6_AW6",
                                "Bezeichnung": "Stacked upright and horiz."
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMX_A2",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "XREFORMX_A2_AW1",
                                "Bezeichnung": "Universal panel and inside corner"
                            },
                            {
                                "ID": "XREFORMX_A2_AW2",
                                "Bezeichnung": "Outside corner and inside corner"
                            },
                            {
                                "ID": "XREFORMX_A2_AW3",
                                "Bezeichnung": "Hinged inside corner and hinged outside corner"
                            },
                            {
                                "ID": "XREFORMX_A2_AW4",
                                "Bezeichnung": "Hinged inside corner inside and outside"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMX_A9",
                        "Bezeichnung": "Stop-end formwork",
                        "Loesung": [
                            {
                                "ID": "XREFORMX_A9_AW3",
                                "Bezeichnung": "Universal waling with stop-end tie"
                            },
                            {
                                "ID": "XREFORMX_A9_AW2",
                                "Bezeichnung": "Universal waling with universal fixing bolt"
                            },
                            {
                                "ID": "XREFORMX_A9_AW1",
                                "Bezeichnung": "Universal panel"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMX_A3",
                        "Bezeichnung": "Closure",
                        "Loesung": [
                            {
                                "ID": "XREFORMX_A3_AW1",
                                "Bezeichnung": "Ties through panel"
                            },
                            {
                                "ID": "XREFORMX_A3_AW2",
                                "Bezeichnung": "Ties through closure"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMX_A4",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "XREFORMX_A4_AW1",
                                "Bezeichnung": "Tie rod 15.0 galvanised"
                            },
                            {
                                "ID": "XREFORMX_A4_AW2",
                                "Bezeichnung": "Tie rod 15.0 non-treated"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMX_A5",
                        "Bezeichnung": "Column",
                        "Loesung": [
                            {
                                "ID": "XREFORMX_A5_AW1",
                                "Bezeichnung": "Universal panel 0.90 with bracket"
                            },
                            {
                                "ID": "XREFORMX_A5_AW3",
                                "Bezeichnung": "Universal panel 1.20 with bracket"
                            },
                            {
                                "ID": "XREFORMX_A5_AW2",
                                "Bezeichnung": "Panels with outside corners"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMX_A10",
                        "Bezeichnung": "Connections",
                        "Loesung": [
                            {
                                "ID": "XREFORMX_A10_AW1",
                                "Bezeichnung": "Universal panel 0.90"
                            },
                            {
                                "ID": "XREFORMX_A10_AW2",
                                "Bezeichnung": "Universal panel 1.20"
                            },
                            {
                                "ID": "XREFORMX_A10_AW3",
                                "Bezeichnung": "Filler block"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMX_A11",
                        "Bezeichnung": "Foundation solutions",
                        "Loesung": [
                            {
                                "ID": "XREFORMX_A11_AW3",
                                "Bezeichnung": "without"
                            },
                            {
                                "ID": "XREFORMX_A11_AW1",
                                "Bezeichnung": "Foundation clamp"
                            },
                            {
                                "ID": "XREFORMX_A11_AW2",
                                "Bezeichnung": "Floor fixing plate"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMX_A12",
                        "Bezeichnung": "One-sided corner",
                        "Loesung": [
                            {
                                "ID": "XREFORMX_A12_AW1",
                                "Bezeichnung": "Outside or inside corner"
                            },
                            {
                                "ID": "XREFORMX_A12_AW2",
                                "Bezeichnung": "Stripping corner I"
                            },
                            {
                                "ID": "XREFORMX_A12_AW3",
                                "Bezeichnung": "Hinged inside corner or hinged outside corner"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "XREFORMA",
                "Schalungsthema": [
                    {
                        "ID": "XREFORMA_A7",
                        "Bezeichnung": "Panel type",
                        "Loesung": [
                            {
                                "ID": "XREFORMA_A7_AW1",
                                "Bezeichnung": "Standard panel"
                            },
                            {
                                "ID": "XREFORMA_A7_AW2",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "XREFORMA_A7_AW3",
                                "Bezeichnung": "Large panel"
                            },
                            {
                                "ID": "XREFORMA_A7_AW#",
                                "Bezeichnung": "XREFORMA_A7_AW#"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMA_A1",
                        "Bezeichnung": "Basic panel height",
                        "Loesung": [
                            {
                                "ID": "XREFORMA_A1_AW1",
                                "Bezeichnung": "1.50m"
                            },
                            {
                                "ID": "XREFORMA_A1_AW2",
                                "Bezeichnung": "3.00m"
                            },
                            {
                                "ID": "XREFORMA_A1_AW3",
                                "Bezeichnung": "3.30m"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMA_A8",
                        "Bezeichnung": "1.35m panel width",
                        "Loesung": [
                            {
                                "ID": "XREFORMA_A8_AW1",
                                "Bezeichnung": "No"
                            },
                            {
                                "ID": "XREFORMA_A8_AW2",
                                "Bezeichnung": "Yes"
                            },
                            {
                                "ID": "XREFORMA_A8_AW#",
                                "Bezeichnung": "XREFORMA_A8_AW#"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMA_A6",
                        "Bezeichnung": "Stackings",
                        "Loesung": [
                            {
                                "ID": "XREFORMA_A6_AW1",
                                "Bezeichnung": "w/o stacking"
                            },
                            {
                                "ID": "XREFORMA_A6_AW2",
                                "Bezeichnung": "stacked horiz."
                            },
                            {
                                "ID": "XREFORMA_A6_AW3",
                                "Bezeichnung": "1.20m horizontal + stacked horiz."
                            },
                            {
                                "ID": "XREFORMA_A6_AW4",
                                "Bezeichnung": "1.35m horizontal + stacked horiz."
                            },
                            {
                                "ID": "XREFORMA_A6_AW6",
                                "Bezeichnung": "Stacked upright and horiz."
                            },
                            {
                                "ID": "XREFORMA_A6_AW5",
                                "Bezeichnung": "stacked upright"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMA_A2",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "XREFORMA_A2_AW1",
                                "Bezeichnung": "Universal panel and inside corner"
                            },
                            {
                                "ID": "XREFORMA_A2_AW2",
                                "Bezeichnung": "Outside corner and inside corner"
                            },
                            {
                                "ID": "XREFORMA_A2_AW3",
                                "Bezeichnung": "Hinged inside corner and hinged outside corner"
                            },
                            {
                                "ID": "XREFORMA_A2_AW4",
                                "Bezeichnung": "Hinged inside corner inside and outside"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMA_A9",
                        "Bezeichnung": "Stop-end formwork",
                        "Loesung": [
                            {
                                "ID": "XREFORMA_A9_AW3",
                                "Bezeichnung": "Universal waling with stop-end tie"
                            },
                            {
                                "ID": "XREFORMA_A9_AW2",
                                "Bezeichnung": "Universal waling with universal fixing bolt"
                            },
                            {
                                "ID": "XREFORMA_A9_AW1",
                                "Bezeichnung": "Universal panel"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMA_A3",
                        "Bezeichnung": "Closure",
                        "Loesung": [
                            {
                                "ID": "XREFORMA_A3_AW1",
                                "Bezeichnung": "Ties through panel"
                            },
                            {
                                "ID": "XREFORMA_A3_AW2",
                                "Bezeichnung": "Ties through closure"
                            },
                            {
                                "ID": "XREFORMA_A3_AW4",
                                "Bezeichnung": "Closure plate 20 w/ adjustable clamp"
                            },
                            {
                                "ID": "XREFORMA_A3_AW3",
                                "Bezeichnung": "Closure plate 20 with tie rod"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMA_A4",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "XREFORMA_A4_AW1",
                                "Bezeichnung": "Tie rod 15.0 galvanised"
                            },
                            {
                                "ID": "XREFORMA_A4_AW2",
                                "Bezeichnung": "Tie rod 15.0 non-treated"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMA_A5",
                        "Bezeichnung": "Column",
                        "Loesung": [
                            {
                                "ID": "XREFORMA_A5_AW1",
                                "Bezeichnung": "Universal panel with bracket"
                            },
                            {
                                "ID": "XREFORMA_A5_AW2",
                                "Bezeichnung": "Panels with outside corners"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMA_A10",
                        "Bezeichnung": "Connections",
                        "Loesung": [
                            {
                                "ID": "XREFORMA_A10_AW1",
                                "Bezeichnung": "Universal panels"
                            },
                            {
                                "ID": "XREFORMA_A10_AW2",
                                "Bezeichnung": "Large panel"
                            },
                            {
                                "ID": "XREFORMA_A10_AW3",
                                "Bezeichnung": "Filler block"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMA_A11",
                        "Bezeichnung": "Foundation solutions",
                        "Loesung": [
                            {
                                "ID": "XREFORMA_A11_AW3",
                                "Bezeichnung": "without"
                            },
                            {
                                "ID": "XREFORMA_A11_AW1",
                                "Bezeichnung": "Foundation clamp"
                            },
                            {
                                "ID": "XREFORMA_A11_AW2",
                                "Bezeichnung": "Floor fixing plate"
                            }
                        ]
                    },
                    {
                        "ID": "XREFORMA_A12",
                        "Bezeichnung": "One-sided corner",
                        "Loesung": [
                            {
                                "ID": "XREFORMA_A12_AW1",
                                "Bezeichnung": "Outside or inside corner"
                            },
                            {
                                "ID": "XREFORMA_A12_AW2",
                                "Bezeichnung": "Stripping corner I"
                            },
                            {
                                "ID": "XREFORMA_A12_AW3",
                                "Bezeichnung": "Hinged inside corner or hinged outside corner"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "FRAMECO",
                "Schalungsthema": [
                    {
                        "ID": "FRAMECO_A1",
                        "Bezeichnung": "Connection of stacking",
                        "Loesung": [
                            {
                                "ID": "FRAMECO_A1_AW2",
                                "Bezeichnung": "Multi function clamp"
                            },
                            {
                                "ID": "FRAMECO_A1_AW1",
                                "Bezeichnung": "Quick acting clamp RU"
                            },
                            {
                                "ID": "FRAMECO_A1_AW3",
                                "Bezeichnung": "not stacked preferred"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMECO_A2",
                        "Bezeichnung": "Panel type",
                        "Loesung": [
                            {
                                "ID": "FRAMECO_A2_AW1",
                                "Bezeichnung": "Framed panel"
                            },
                            {
                                "ID": "FRAMECO_A2_AW2",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "FRAMECO_A2_AW3",
                                "Bezeichnung": "Framed panel 50 wide"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMECO_A3",
                        "Bezeichnung": "Stop-end formwork",
                        "Loesung": [
                            {
                                "ID": "FRAMECO_A3_AW1",
                                "Bezeichnung": "Stop-end tie"
                            },
                            {
                                "ID": "FRAMECO_A3_AW2",
                                "Bezeichnung": "Universal waling Universal fixing bolt"
                            },
                            {
                                "ID": "FRAMECO_A3_AW3",
                                "Bezeichnung": "Universal panel with framed panel"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMECO_A4",
                        "Bezeichnung": "Connections",
                        "Loesung": [
                            {
                                "ID": "FRAMECO_A4_AW1",
                                "Bezeichnung": "Framed panel"
                            },
                            {
                                "ID": "FRAMECO_A4_AW2",
                                "Bezeichnung": "Universal panel"
                            },
                            {
                                "ID": "FRAMECO_A4_AW3",
                                "Bezeichnung": "Squared timber"
                            }
                        ]
                    },
                    {
                        "ID": "FRAMECO_A5",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "FRAMECO_A5_AW1",
                                "Bezeichnung": "Inside corner and outside corner"
                            },
                            {
                                "ID": "FRAMECO_A5_AW2",
                                "Bezeichnung": "Inside corner and universal panel"
                            },
                            {
                                "ID": "FRAMECO_A5_AW3",
                                "Bezeichnung": "Hinged inside and hinged outside corner"
                            },
                            {
                                "ID": "FRAMECO_A5_AW4",
                                "Bezeichnung": "Hinged inside and hinged inside corner"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "DOKASETF",
                "Schalungsthema": [
                    {
                        "ID": "DOKASETF_A1",
                        "Bezeichnung": "Side railing",
                        "Loesung": [
                            {
                                "ID": "DOKASETF_A1_AW1",
                                "Bezeichnung": "double-ended opened"
                            },
                            {
                                "ID": "DOKASETF_A1_AW2",
                                "Bezeichnung": "left opened"
                            },
                            {
                                "ID": "DOKASETF_A1_AW3",
                                "Bezeichnung": "right opened"
                            },
                            {
                                "ID": "DOKASETF_A1_AW4",
                                "Bezeichnung": "without railing"
                            }
                        ]
                    },
                    {
                        "ID": "DOKASETF_A2",
                        "Bezeichnung": "Corner",
                        "Loesung": [
                            {
                                "ID": "DOKASETF_A2_AW1",
                                "Bezeichnung": "Inside corner and universal panel"
                            },
                            {
                                "ID": "DOKASETF_A2_AW2",
                                "Bezeichnung": "Inside corner and outside corner"
                            },
                            {
                                "ID": "DOKASETF_A2_AW3",
                                "Bezeichnung": "Hinged inside corner and universal panel"
                            },
                            {
                                "ID": "DOKASETF_A2_AW4",
                                "Bezeichnung": "HInged inside corner and outside corner"
                            },
                            {
                                "ID": "DOKASETF_A2_AW5",
                                "Bezeichnung": "Hinged inside corner inside and outside"
                            },
                            {
                                "ID": "DOKASETF_A2_AW6",
                                "Bezeichnung": "Hinged inside and hinged outside corner"
                            }
                        ]
                    },
                    {
                        "ID": "DOKASETF_A3",
                        "Bezeichnung": "Wall end",
                        "Loesung": [
                            {
                                "ID": "DOKASETF_A3_AW1",
                                "Bezeichnung": "Dokaset F panel 2.70"
                            },
                            {
                                "ID": "DOKASETF_A3_AW2",
                                "Bezeichnung": "Dokaset F panel 1.35"
                            },
                            {
                                "ID": "DOKASETF_A3_AW3",
                                "Bezeichnung": "Universal waling, universal fixing bolt"
                            },
                            {
                                "ID": "DOKASETF_A3_AW4",
                                "Bezeichnung": "Universal waling, stop-end tie"
                            },
                            {
                                "ID": "DOKASETF_A3_AW5",
                                "Bezeichnung": "Oustide corners, all panel widths"
                            },
                            {
                                "ID": "DOKASETF_A3_AW6",
                                "Bezeichnung": "Universal panel 270 + universal fixing bolt"
                            },
                            {
                                "ID": "DOKASETF_A3_AW7",
                                "Bezeichnung": "Universal waling, universal fixing bolt, joint strip"
                            },
                            {
                                "ID": "DOKASETF_A3_AW8",
                                "Bezeichnung": "Moulded timber 270, adjustable clamp"
                            },
                            {
                                "ID": "DOKASETF_A3_AW9",
                                "Bezeichnung": "Moulded timber 270, joint strip, adjustable clamp"
                            }
                        ]
                    },
                    {
                        "ID": "DOKASETF_A4",
                        "Bezeichnung": "Closure",
                        "Loesung": [
                            {
                                "ID": "DOKASETF_A4_AW1",
                                "Bezeichnung": "Fitting timber Doka, ties through panel"
                            },
                            {
                                "ID": "DOKASETF_A4_AW2",
                                "Bezeichnung": "Fitting timber Doka, ties through closure"
                            },
                            {
                                "ID": "DOKASETF_A4_AW3",
                                "Bezeichnung": "Filler block by site, ties through panel"
                            },
                            {
                                "ID": "DOKASETF_A4_AW4",
                                "Bezeichnung": "Filler block by site, ties through closure"
                            },
                            {
                                "ID": "DOKASETF_A4_AW8",
                                "Bezeichnung": "Alu-filler, ties through panel"
                            },
                            {
                                "ID": "DOKASETF_A4_AW7",
                                "Bezeichnung": "Alu-filler, ties through closure"
                            },
                            {
                                "ID": "DOKASETF_A4_AW5",
                                "Bezeichnung": "with formwork sheet and universal waling 0.90m"
                            },
                            {
                                "ID": "DOKASETF_A4_AW6",
                                "Bezeichnung": "with formwork sheet and universal waling 1.50m"
                            }
                        ]
                    },
                    {
                        "ID": "DOKASETF_A6",
                        "Bezeichnung": "Compensation connection",
                        "Loesung": [
                            {
                                "ID": "DOKASETF_A6_AW1",
                                "Bezeichnung": "Multi function clamp"
                            },
                            {
                                "ID": "DOKASETF_A6_AW2",
                                "Bezeichnung": "Adjustable clamp"
                            }
                        ]
                    },
                    {
                        "ID": "DOKASETF_A5",
                        "Bezeichnung": "Anchoring",
                        "Loesung": [
                            {
                                "ID": "DOKASETF_A5_AW1",
                                "Bezeichnung": "Tie rod 20.0 galvanised"
                            },
                            {
                                "ID": "DOKASETF_A5_AW2",
                                "Bezeichnung": "Tie rod 20.0 non-treated"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "FLEX124",
                "Schalungsthema": [
                    {
                        "ID": "FLEX124__A2",
                        "Bezeichnung": "Tableform type",
                        "Loesung": [
                            {
                                "ID": "FLEX124__A2_AW1",
                                "Bezeichnung": "Dokamatic table"
                            },
                            {
                                "ID": "FLEX124__A2_AW2",
                                "Bezeichnung": "Dokaflex table"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX124__A3",
                        "Bezeichnung": "Tableform plywood",
                        "Loesung": [
                            {
                                "ID": "FLEX124__A3_AW2",
                                "Bezeichnung": "3SO-27"
                            },
                            {
                                "ID": "FLEX124__A3_AW1",
                                "Bezeichnung": "3SO-21"
                            },
                            {
                                "ID": "FLEX124__A3_AW3",
                                "Bezeichnung": "without plywood"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX124__A4",
                        "Bezeichnung": "Tableform shoring",
                        "Loesung": [
                            {
                                "ID": "FLEX124__A4_AW8",
                                "Bezeichnung": "Eurex 20 top"
                            },
                            {
                                "ID": "FLEX124__A4_AW9",
                                "Bezeichnung": "Eurex 30 top"
                            },
                            {
                                "ID": "FLEX124__A4_AW1",
                                "Bezeichnung": "Eurex 20"
                            },
                            {
                                "ID": "FLEX124__A4_AW2",
                                "Bezeichnung": "Eurex 30"
                            },
                            {
                                "ID": "FLEX124__A4_AW3",
                                "Bezeichnung": "Floor prop G"
                            },
                            {
                                "ID": "FLEX124__A4_AW4",
                                "Bezeichnung": "Floor prop N"
                            },
                            {
                                "ID": "FLEX124__A4_AW5",
                                "Bezeichnung": "Floor prop eco"
                            },
                            {
                                "ID": "FLEX124__A4_AW6",
                                "Bezeichnung": "Staxo"
                            },
                            {
                                "ID": "FLEX124__A4_AW7",
                                "Bezeichnung": "Aluxo"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX124__A5",
                        "Bezeichnung": "Tableform Shoring Edge props",
                        "Loesung": [
                            {
                                "ID": "FLEX124__A5_AW1",
                                "Bezeichnung": "Standard"
                            },
                            {
                                "ID": "FLEX124__A5_AW2",
                                "Bezeichnung": "EP repositioned, 1IP with swivel head"
                            },
                            {
                                "ID": "FLEX124__A5_AW3",
                                "Bezeichnung": "EP repositioned, 1IP with strut connection"
                            },
                            {
                                "ID": "FLEX124__A5_AW4",
                                "Bezeichnung": "EP repositioned, 2IP with swivel head"
                            },
                            {
                                "ID": "FLEX124__A5_AW5",
                                "Bezeichnung": "EP repositioned, 2IP with strut connection"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX124__A6",
                        "Bezeichnung": "Tableform heavy duty screw jack/screw jack foot",
                        "Loesung": [
                            {
                                "ID": "FLEX124__A6_AW1",
                                "Bezeichnung": "Heavy duty screw jack"
                            },
                            {
                                "ID": "FLEX124__A6_AW2",
                                "Bezeichnung": "Screw jack foot"
                            },
                            {
                                "ID": "FLEX124__A6_AW3",
                                "Bezeichnung": "without spindle"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX124__A7",
                        "Bezeichnung": "Shutter Board type",
                        "Loesung": [
                            {
                                "ID": "FLEX124__A7_AW7",
                                "Bezeichnung": "Formwork sheet 3-SO 27 mm"
                            },
                            {
                                "ID": "FLEX124__A7_AW12",
                                "Bezeichnung": "Formwork sheet 3S basic 27mm"
                            },
                            {
                                "ID": "FLEX124__A7_AW13",
                                "Bezeichnung": "Formwork sheet 3S cut 27mm"
                            },
                            {
                                "ID": "FLEX124__A7_AW6",
                                "Bezeichnung": "Dokadur panel 27"
                            },
                            {
                                "ID": "FLEX124__A7_AW5",
                                "Bezeichnung": "Dokaflex table sheet"
                            },
                            {
                                "ID": "FLEX124__A7_AW2",
                                "Bezeichnung": "Formwork sheet 3-SO 21 mm"
                            },
                            {
                                "ID": "FLEX124__A7_AW10",
                                "Bezeichnung": "Formwork sheet 3S basic 21mm"
                            },
                            {
                                "ID": "FLEX124__A7_AW11",
                                "Bezeichnung": "Formwork sheet 3S cut 21mm"
                            },
                            {
                                "ID": "FLEX124__A7_AW1",
                                "Bezeichnung": "Dokadur panel 21"
                            },
                            {
                                "ID": "FLEX124__A7_AW3",
                                "Bezeichnung": "Dokadur-Plex panel 21"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX124__A8",
                        "Bezeichnung": "Formwork beam type",
                        "Loesung": [
                            {
                                "ID": "FLEX124__A8_AW1",
                                "Bezeichnung": "Beam H20 top P"
                            },
                            {
                                "ID": "FLEX124__A8_AW2",
                                "Bezeichnung": "Beam H20 top N"
                            },
                            {
                                "ID": "FLEX124__A8_AW3",
                                "Bezeichnung": "Beam H20 eco P"
                            },
                            {
                                "ID": "FLEX124__A8_AW4",
                                "Bezeichnung": "Beam H20 eco N"
                            },
                            {
                                "ID": "FLEX124__A8_AW5",
                                "Bezeichnung": "Beam H20 rivet P"
                            },
                            {
                                "ID": "FLEX124__A8_AW6",
                                "Bezeichnung": "Beam H20 rivet N"
                            },
                            {
                                "ID": "FLEX124__A8_AW7",
                                "Bezeichnung": "Squared timber by site"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX124__A9",
                        "Bezeichnung": "Dokaflex shoring",
                        "Loesung": [
                            {
                                "ID": "FLEX124__A9_AW7",
                                "Bezeichnung": "Eurex 20 top"
                            },
                            {
                                "ID": "FLEX124__A9_AW8",
                                "Bezeichnung": "Eurex 30 top"
                            },
                            {
                                "ID": "FLEX124__A9_AW9",
                                "Bezeichnung": "Eurex 20 eco"
                            },
                            {
                                "ID": "FLEX124__A9_AW1",
                                "Bezeichnung": "Eurex 20"
                            },
                            {
                                "ID": "FLEX124__A9_AW2",
                                "Bezeichnung": "Eurex 30"
                            },
                            {
                                "ID": "FLEX124__A9_AW3",
                                "Bezeichnung": "Eurex 60"
                            },
                            {
                                "ID": "FLEX124__A9_AW4",
                                "Bezeichnung": "Floor prop G"
                            },
                            {
                                "ID": "FLEX124__A9_AW5",
                                "Bezeichnung": "Floor prop N"
                            },
                            {
                                "ID": "FLEX124__A9_AW6",
                                "Bezeichnung": "Floor prop eco"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX124__A12",
                        "Bezeichnung": "Shutter Board dimension",
                        "Loesung": [
                            {
                                "ID": "FLEX124__A12_AW3",
                                "Bezeichnung": "200/50 cm"
                            },
                            {
                                "ID": "FLEX124__A12_AW4",
                                "Bezeichnung": "250/50 cm"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX124__A15",
                        "Bezeichnung": "Dokaflex prop heads",
                        "Loesung": [
                            {
                                "ID": "FLEX124__A15_AW2",
                                "Bezeichnung": "Lowering head H20"
                            },
                            {
                                "ID": "FLEX124__A15_AW5",
                                "Bezeichnung": "Supporting head H20 DF"
                            },
                            {
                                "ID": "FLEX124__A15_AW1",
                                "Bezeichnung": "4-way head H20"
                            },
                            {
                                "ID": "FLEX124__A15_AW3",
                                "Bezeichnung": "U-head 12.5cm"
                            },
                            {
                                "ID": "FLEX124__A15_AW4",
                                "Bezeichnung": "Supporting head H20"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX124__A11",
                        "Bezeichnung": "Dokaflex drop beam type",
                        "Loesung": [
                            {
                                "ID": "FLEX124__A11_AW3",
                                "Bezeichnung": "H20 beam variable"
                            },
                            {
                                "ID": "FLEX124__A11_AW1",
                                "Bezeichnung": "Beam forming support 20"
                            },
                            {
                                "ID": "FLEX124__A11_AW2",
                                "Bezeichnung": "Squared timber by site"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX124__A16",
                        "Bezeichnung": "Removable folding tripods",
                        "Loesung": [
                            {
                                "ID": "FLEX124__A16_AW2",
                                "Bezeichnung": "Removable folding tripod top"
                            },
                            {
                                "ID": "FLEX124__A16_AW1",
                                "Bezeichnung": "Removable folding tripod"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX124__A17",
                        "Bezeichnung": "Insertion beam",
                        "Loesung": [
                            {
                                "ID": "FLEX124__A17_AW1",
                                "Bezeichnung": "Insertion beam"
                            },
                            {
                                "ID": "FLEX124__A17_AW2",
                                "Bezeichnung": "Dokamatic insertion beam"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "FLEXOPT",
                "Schalungsthema": [
                    {
                        "ID": "FLEXOPT__A2",
                        "Bezeichnung": "Tableform type",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A2_AW1",
                                "Bezeichnung": "Dokamatic table"
                            },
                            {
                                "ID": "FLEXOPT__A2_AW2",
                                "Bezeichnung": "Dokaflex table"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A3",
                        "Bezeichnung": "Tableform plywood",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A3_AW2",
                                "Bezeichnung": "3SO-27"
                            },
                            {
                                "ID": "FLEXOPT__A3_AW1",
                                "Bezeichnung": "3SO-21"
                            },
                            {
                                "ID": "FLEXOPT__A3_AW3",
                                "Bezeichnung": "without plywood"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A4",
                        "Bezeichnung": "Tableform shoring",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A4_AW8",
                                "Bezeichnung": "Eurex 20 top"
                            },
                            {
                                "ID": "FLEXOPT__A4_AW9",
                                "Bezeichnung": "Eurex 30 top"
                            },
                            {
                                "ID": "FLEXOPT__A4_AW1",
                                "Bezeichnung": "Eurex 20"
                            },
                            {
                                "ID": "FLEXOPT__A4_AW2",
                                "Bezeichnung": "Eurex 30"
                            },
                            {
                                "ID": "FLEXOPT__A4_AW3",
                                "Bezeichnung": "Floor prop G"
                            },
                            {
                                "ID": "FLEXOPT__A4_AW4",
                                "Bezeichnung": "Floor prop N"
                            },
                            {
                                "ID": "FLEXOPT__A4_AW5",
                                "Bezeichnung": "Floor prop eco"
                            },
                            {
                                "ID": "FLEXOPT__A4_AW6",
                                "Bezeichnung": "Staxo"
                            },
                            {
                                "ID": "FLEXOPT__A4_AW7",
                                "Bezeichnung": "Aluxo"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A5",
                        "Bezeichnung": "Tableform Shoring Edge props",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A5_AW1",
                                "Bezeichnung": "Standard"
                            },
                            {
                                "ID": "FLEXOPT__A5_AW2",
                                "Bezeichnung": "EP repositioned, 1IP with swivel head"
                            },
                            {
                                "ID": "FLEXOPT__A5_AW3",
                                "Bezeichnung": "EP repositioned, 1IP with strut connection"
                            },
                            {
                                "ID": "FLEXOPT__A5_AW4",
                                "Bezeichnung": "EP repositioned, 2IP with swivel head"
                            },
                            {
                                "ID": "FLEXOPT__A5_AW5",
                                "Bezeichnung": "EP repositioned, 2IP with strut connection"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A6",
                        "Bezeichnung": "Tableform heavy duty screw jack/screw jack foot",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A6_AW1",
                                "Bezeichnung": "Heavy duty screw jack"
                            },
                            {
                                "ID": "FLEXOPT__A6_AW2",
                                "Bezeichnung": "Screw jack foot"
                            },
                            {
                                "ID": "FLEXOPT__A6_AW3",
                                "Bezeichnung": "without spindle"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A7",
                        "Bezeichnung": "Shutter Board type",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A7_AW7",
                                "Bezeichnung": "Formwork sheet 3-SO 27 mm"
                            },
                            {
                                "ID": "FLEXOPT__A7_AW14",
                                "Bezeichnung": "Formwork sheet 3S basic 27mm"
                            },
                            {
                                "ID": "FLEXOPT__A7_AW15",
                                "Bezeichnung": "Formwork sheet 3S cut 27mm"
                            },
                            {
                                "ID": "FLEXOPT__A7_AW6",
                                "Bezeichnung": "Dokadur panel 27"
                            },
                            {
                                "ID": "FLEXOPT__A7_AW4",
                                "Bezeichnung": "Plywood sheet"
                            },
                            {
                                "ID": "FLEXOPT__A7_AW5",
                                "Bezeichnung": "Dokaflex table sheet"
                            },
                            {
                                "ID": "FLEXOPT__A7_AW2",
                                "Bezeichnung": "Formwork sheet 3-SO 21 mm"
                            },
                            {
                                "ID": "FLEXOPT__A7_AW12",
                                "Bezeichnung": "Formwork sheet 3S basic 21mm"
                            },
                            {
                                "ID": "FLEXOPT__A7_AW13",
                                "Bezeichnung": "Formwork sheet 3S cut 21mm"
                            },
                            {
                                "ID": "FLEXOPT__A7_AW1",
                                "Bezeichnung": "Dokadur panel 21"
                            },
                            {
                                "ID": "FLEXOPT__A7_AW3",
                                "Bezeichnung": "Dokadur-Plex panel 21"
                            },
                            {
                                "ID": "FLEXOPT__A7_AW11",
                                "Bezeichnung": "DokaPly eco 21mm"
                            },
                            {
                                "ID": "FLEXOPT__A7_AW10",
                                "Bezeichnung": "DokaPly eco 18mm"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A12",
                        "Bezeichnung": "Shutter Board dimension",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A12_AW4",
                                "Bezeichnung": "250/50 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW3",
                                "Bezeichnung": "200/50 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW1",
                                "Bezeichnung": "100/50 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW2",
                                "Bezeichnung": "150/50 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW5",
                                "Bezeichnung": "244/61 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW6",
                                "Bezeichnung": "244/122 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW7",
                                "Bezeichnung": "122/61 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW8",
                                "Bezeichnung": "50/200 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW9",
                                "Bezeichnung": "50/250 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW10",
                                "Bezeichnung": "35/200 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW11",
                                "Bezeichnung": "35/250 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW12",
                                "Bezeichnung": "14.7/200 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW13",
                                "Bezeichnung": "14.7/250 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW14",
                                "Bezeichnung": "100/100 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW15",
                                "Bezeichnung": "150/100 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW16",
                                "Bezeichnung": "200/100 cm"
                            },
                            {
                                "ID": "FLEXOPT__A12_AW17",
                                "Bezeichnung": "250/125 cm"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A8",
                        "Bezeichnung": "Formwork beam type",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A8_AW1",
                                "Bezeichnung": "Beam H20 top P"
                            },
                            {
                                "ID": "FLEXOPT__A8_AW2",
                                "Bezeichnung": "Beam H20 top N"
                            },
                            {
                                "ID": "FLEXOPT__A8_AW3",
                                "Bezeichnung": "Beam H20 eco P"
                            },
                            {
                                "ID": "FLEXOPT__A8_AW4",
                                "Bezeichnung": "Beam H20 eco N"
                            },
                            {
                                "ID": "FLEXOPT__A8_AW5",
                                "Bezeichnung": "Beam H20 rivet P"
                            },
                            {
                                "ID": "FLEXOPT__A8_AW6",
                                "Bezeichnung": "Beam H20 rivet N"
                            },
                            {
                                "ID": "FLEXOPT__A8_AW7",
                                "Bezeichnung": "Squared timber by site"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A13",
                        "Bezeichnung": "Secondary beam length for slab polygon",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A13_AW4",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "FLEXOPT__A13_AW2",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "FLEXOPT__A13_AW1",
                                "Bezeichnung": "1.25 m"
                            },
                            {
                                "ID": "FLEXOPT__A13_AW3",
                                "Bezeichnung": "2.45 m"
                            },
                            {
                                "ID": "FLEXOPT__A13_AW5",
                                "Bezeichnung": "2.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A13_AW6",
                                "Bezeichnung": "3.30 m"
                            },
                            {
                                "ID": "FLEXOPT__A13_AW7",
                                "Bezeichnung": "3.60 m"
                            },
                            {
                                "ID": "FLEXOPT__A13_AW8",
                                "Bezeichnung": "3.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A13_AW9",
                                "Bezeichnung": "4.50 m"
                            },
                            {
                                "ID": "FLEXOPT__A13_AW10",
                                "Bezeichnung": "4.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A13_AW11",
                                "Bezeichnung": "5.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A13_AW12",
                                "Bezeichnung": "8x20/100 cm"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A14",
                        "Bezeichnung": "Primary beam length for slab polygon",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A14_AW8",
                                "Bezeichnung": "3.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A14_AW4",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "FLEXOPT__A14_AW11",
                                "Bezeichnung": "5.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A14_AW10",
                                "Bezeichnung": "4.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A14_AW9",
                                "Bezeichnung": "4.50 m"
                            },
                            {
                                "ID": "FLEXOPT__A14_AW7",
                                "Bezeichnung": "3.60 m"
                            },
                            {
                                "ID": "FLEXOPT__A14_AW6",
                                "Bezeichnung": "3.30 m"
                            },
                            {
                                "ID": "FLEXOPT__A14_AW5",
                                "Bezeichnung": "2.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A14_AW3",
                                "Bezeichnung": "2.45 m"
                            },
                            {
                                "ID": "FLEXOPT__A14_AW2",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "FLEXOPT__A14_AW1",
                                "Bezeichnung": "1.25 m"
                            },
                            {
                                "ID": "FLEXOPT__A14_AW12",
                                "Bezeichnung": "8x20/100 cm"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A9",
                        "Bezeichnung": "Dokaflex shoring",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A9_AW7",
                                "Bezeichnung": "Eurex 20 top"
                            },
                            {
                                "ID": "FLEXOPT__A9_AW8",
                                "Bezeichnung": "Eurex 30 top"
                            },
                            {
                                "ID": "FLEXOPT__A9_AW9",
                                "Bezeichnung": "Eurex 20 eco"
                            },
                            {
                                "ID": "FLEXOPT__A9_AW1",
                                "Bezeichnung": "Eurex 20"
                            },
                            {
                                "ID": "FLEXOPT__A9_AW2",
                                "Bezeichnung": "Eurex 30"
                            },
                            {
                                "ID": "FLEXOPT__A9_AW3",
                                "Bezeichnung": "Eurex 60"
                            },
                            {
                                "ID": "FLEXOPT__A9_AW4",
                                "Bezeichnung": "Floor prop G"
                            },
                            {
                                "ID": "FLEXOPT__A9_AW5",
                                "Bezeichnung": "Floor prop N"
                            },
                            {
                                "ID": "FLEXOPT__A9_AW6",
                                "Bezeichnung": "Floor prop eco"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A15",
                        "Bezeichnung": "Dokaflex prop heads",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A15_AW2",
                                "Bezeichnung": "Lowering head H20"
                            },
                            {
                                "ID": "FLEXOPT__A15_AW5",
                                "Bezeichnung": "Supporting head H20 DF"
                            },
                            {
                                "ID": "FLEXOPT__A15_AW1",
                                "Bezeichnung": "4-way head H20"
                            },
                            {
                                "ID": "FLEXOPT__A15_AW3",
                                "Bezeichnung": "U-head 12.5cm"
                            },
                            {
                                "ID": "FLEXOPT__A15_AW4",
                                "Bezeichnung": "Supporting head H20"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A11",
                        "Bezeichnung": "Dokaflex drop beam type",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A11_AW3",
                                "Bezeichnung": "Beam forming support 20 + H20 beam variable"
                            },
                            {
                                "ID": "FLEXOPT__A11_AW1",
                                "Bezeichnung": "Beam forming support 20"
                            },
                            {
                                "ID": "FLEXOPT__A11_AW2",
                                "Bezeichnung": "Squared timber by site"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A16",
                        "Bezeichnung": "Secondary beam length for drop beam",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A16_AW4",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "FLEXOPT__A16_AW2",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "FLEXOPT__A16_AW1",
                                "Bezeichnung": "1.25 m"
                            },
                            {
                                "ID": "FLEXOPT__A16_AW3",
                                "Bezeichnung": "2.45 m"
                            },
                            {
                                "ID": "FLEXOPT__A16_AW5",
                                "Bezeichnung": "2.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A16_AW6",
                                "Bezeichnung": "3.30 m"
                            },
                            {
                                "ID": "FLEXOPT__A16_AW7",
                                "Bezeichnung": "3.60 m"
                            },
                            {
                                "ID": "FLEXOPT__A16_AW8",
                                "Bezeichnung": "3.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A16_AW9",
                                "Bezeichnung": "4.50 m"
                            },
                            {
                                "ID": "FLEXOPT__A16_AW10",
                                "Bezeichnung": "4.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A16_AW11",
                                "Bezeichnung": "5.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A16_AW12",
                                "Bezeichnung": "8x20/100 cm"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A17",
                        "Bezeichnung": "Primary beam length for drop beam",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A17_AW8",
                                "Bezeichnung": "3.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A17_AW4",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "FLEXOPT__A17_AW11",
                                "Bezeichnung": "5.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A17_AW10",
                                "Bezeichnung": "4.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A17_AW9",
                                "Bezeichnung": "4.50 m"
                            },
                            {
                                "ID": "FLEXOPT__A17_AW7",
                                "Bezeichnung": "3.60 m"
                            },
                            {
                                "ID": "FLEXOPT__A17_AW6",
                                "Bezeichnung": "3.30 m"
                            },
                            {
                                "ID": "FLEXOPT__A17_AW5",
                                "Bezeichnung": "2.90 m"
                            },
                            {
                                "ID": "FLEXOPT__A17_AW3",
                                "Bezeichnung": "2.45 m"
                            },
                            {
                                "ID": "FLEXOPT__A17_AW2",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "FLEXOPT__A17_AW1",
                                "Bezeichnung": "1.25 m"
                            },
                            {
                                "ID": "FLEXOPT__A17_AW12",
                                "Bezeichnung": "8x20/100 cm"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A18",
                        "Bezeichnung": "Removable folding tripods",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A18_AW2",
                                "Bezeichnung": "Removable folding tripod top"
                            },
                            {
                                "ID": "FLEXOPT__A18_AW1",
                                "Bezeichnung": "Removable folding tripod"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXOPT__A19",
                        "Bezeichnung": "Insertion beam",
                        "Loesung": [
                            {
                                "ID": "FLEXOPT__A19_AW1",
                                "Bezeichnung": "Insertion beam"
                            },
                            {
                                "ID": "FLEXOPT__A19_AW2",
                                "Bezeichnung": "Dokamatic insertion beam"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "FLEX30OP",
                "Schalungsthema": [
                    {
                        "ID": "FLEX30OP__A7",
                        "Bezeichnung": "Shutter Board type",
                        "Loesung": [
                            {
                                "ID": "FLEX30OP__A7_AW2",
                                "Bezeichnung": "Formwork sheet 3-SO 21 mm"
                            },
                            {
                                "ID": "FLEX30OP__A7_AW12",
                                "Bezeichnung": "Formwork sheet 3S basic 21mm"
                            },
                            {
                                "ID": "FLEX30OP__A7_AW13",
                                "Bezeichnung": "Formwork sheet 3S cut 21mm"
                            },
                            {
                                "ID": "FLEX30OP__A7_AW1",
                                "Bezeichnung": "Dokadur panel 21"
                            },
                            {
                                "ID": "FLEX30OP__A7_AW3",
                                "Bezeichnung": "Dokadur-Plex panel 21"
                            },
                            {
                                "ID": "FLEX30OP__A7_AW11",
                                "Bezeichnung": "DokaPly eco 21mm"
                            },
                            {
                                "ID": "FLEX30OP__A7_AW4",
                                "Bezeichnung": "Plywood sheet"
                            },
                            {
                                "ID": "FLEX30OP__A7_AW5",
                                "Bezeichnung": "Dokaflex table sheet"
                            },
                            {
                                "ID": "FLEX30OP__A7_AW7",
                                "Bezeichnung": "Formwork sheet 3-SO 27 mm"
                            },
                            {
                                "ID": "FLEX30OP__A7_AW14",
                                "Bezeichnung": "Formwork sheet 3S basic 27mm"
                            },
                            {
                                "ID": "FLEX30OP__A7_AW15",
                                "Bezeichnung": "Formwork sheet 3S cut 27mm"
                            },
                            {
                                "ID": "FLEX30OP__A7_AW6",
                                "Bezeichnung": "Dokadur panel 27"
                            },
                            {
                                "ID": "FLEX30OP__A7_AW10",
                                "Bezeichnung": "DokaPly eco 18mm"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX30OP__A12",
                        "Bezeichnung": "Plywood dimension",
                        "Loesung": [
                            {
                                "ID": "FLEX30OP__A12_AW4",
                                "Bezeichnung": "250/50 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW3",
                                "Bezeichnung": "200/50 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW2",
                                "Bezeichnung": "150/50 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW1",
                                "Bezeichnung": "100/50 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW5",
                                "Bezeichnung": "244/61 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW6",
                                "Bezeichnung": "244/122 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW7",
                                "Bezeichnung": "122/61 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW8",
                                "Bezeichnung": "50/200 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW9",
                                "Bezeichnung": "50/250 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW10",
                                "Bezeichnung": "35/200 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW11",
                                "Bezeichnung": "35/250 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW12",
                                "Bezeichnung": "14.7/200 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW13",
                                "Bezeichnung": "14.7/250 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW14",
                                "Bezeichnung": "100/100 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW15",
                                "Bezeichnung": "150/100 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW16",
                                "Bezeichnung": "200/100 cm"
                            },
                            {
                                "ID": "FLEX30OP__A12_AW17",
                                "Bezeichnung": "250/125 cm"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX30OP__A8",
                        "Bezeichnung": "Formwork beam type Secondary beam",
                        "Loesung": [
                            {
                                "ID": "FLEX30OP__A8_AW1",
                                "Bezeichnung": "Beam H20 top P"
                            },
                            {
                                "ID": "FLEX30OP__A8_AW2",
                                "Bezeichnung": "Beam H20 top N"
                            },
                            {
                                "ID": "FLEX30OP__A8_AW3",
                                "Bezeichnung": "Beam H20 eco P"
                            },
                            {
                                "ID": "FLEX30OP__A8_AW4",
                                "Bezeichnung": "Beam H20 eco N"
                            },
                            {
                                "ID": "FLEX30OP__A8_AW5",
                                "Bezeichnung": "Beam H20 rivet P"
                            },
                            {
                                "ID": "FLEX30OP__A8_AW6",
                                "Bezeichnung": "Beam H20 rivet N"
                            },
                            {
                                "ID": "FLEX30OP__A8_AW7",
                                "Bezeichnung": "Squared timber by site"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX30OP__A13",
                        "Bezeichnung": "Secondary beam length for slab polygon",
                        "Loesung": [
                            {
                                "ID": "FLEX30OP__A13_AW4",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "FLEX30OP__A13_AW2",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "FLEX30OP__A13_AW1",
                                "Bezeichnung": "1.25 m"
                            },
                            {
                                "ID": "FLEX30OP__A13_AW3",
                                "Bezeichnung": "2.45 m"
                            },
                            {
                                "ID": "FLEX30OP__A13_AW5",
                                "Bezeichnung": "2.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A13_AW6",
                                "Bezeichnung": "3.30 m"
                            },
                            {
                                "ID": "FLEX30OP__A13_AW7",
                                "Bezeichnung": "3.60 m"
                            },
                            {
                                "ID": "FLEX30OP__A13_AW8",
                                "Bezeichnung": "3.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A13_AW9",
                                "Bezeichnung": "4.50 m"
                            },
                            {
                                "ID": "FLEX30OP__A13_AW10",
                                "Bezeichnung": "4.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A13_AW11",
                                "Bezeichnung": "5.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A13_AW12",
                                "Bezeichnung": "8x20/100 cm"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX30OP__A14",
                        "Bezeichnung": "Primary beam length for slab polygon (only I tec)",
                        "Loesung": [
                            {
                                "ID": "FLEX30OP__A14_AW5",
                                "Bezeichnung": "5.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A14_AW4",
                                "Bezeichnung": "5.35 m"
                            },
                            {
                                "ID": "FLEX30OP__A14_AW3",
                                "Bezeichnung": "4.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A14_AW2",
                                "Bezeichnung": "4.50 m"
                            },
                            {
                                "ID": "FLEX30OP__A14_AW1",
                                "Bezeichnung": "3.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A14_AW11",
                                "Bezeichnung": "3.60 m"
                            },
                            {
                                "ID": "FLEX30OP__A14_AW10",
                                "Bezeichnung": "3.30 m"
                            },
                            {
                                "ID": "FLEX30OP__A14_AW9",
                                "Bezeichnung": "2.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A14_AW8",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "FLEX30OP__A14_AW7",
                                "Bezeichnung": "2.45 m"
                            },
                            {
                                "ID": "FLEX30OP__A14_AW6",
                                "Bezeichnung": "1.80 m"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX30OP__A9",
                        "Bezeichnung": "Dokaflex shoring",
                        "Loesung": [
                            {
                                "ID": "FLEX30OP__A9_AW7",
                                "Bezeichnung": "Eurex 20 top"
                            },
                            {
                                "ID": "FLEX30OP__A9_AW8",
                                "Bezeichnung": "Eurex 30 top"
                            },
                            {
                                "ID": "FLEX30OP__A9_AW4",
                                "Bezeichnung": "Eurex 20 eco"
                            },
                            {
                                "ID": "FLEX30OP__A9_AW1",
                                "Bezeichnung": "Eurex 20"
                            },
                            {
                                "ID": "FLEX30OP__A9_AW2",
                                "Bezeichnung": "Eurex 30"
                            },
                            {
                                "ID": "FLEX30OP__A9_AW3",
                                "Bezeichnung": "Eurex 60"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX30OP__A15",
                        "Bezeichnung": "Dokaflex prop heads",
                        "Loesung": [
                            {
                                "ID": "FLEX30OP__A15_AW2",
                                "Bezeichnung": "Lowering head H20"
                            },
                            {
                                "ID": "FLEX30OP__A15_AW5",
                                "Bezeichnung": "Supporting head H20 DF"
                            },
                            {
                                "ID": "FLEX30OP__A15_AW1",
                                "Bezeichnung": "4-way head H20"
                            },
                            {
                                "ID": "FLEX30OP__A15_AW3",
                                "Bezeichnung": "U-head 12.5 cm"
                            },
                            {
                                "ID": "FLEX30OP__A15_AW4",
                                "Bezeichnung": "Supporting head H20"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX30OP__A11",
                        "Bezeichnung": "Dokaflex drop beam type",
                        "Loesung": [
                            {
                                "ID": "FLEX30OP__A11_AW1",
                                "Bezeichnung": "Beam forming support 20"
                            },
                            {
                                "ID": "FLEX30OP__A11_AW2",
                                "Bezeichnung": "Squared timber by site"
                            },
                            {
                                "ID": "FLEX30OP__A11_AW3",
                                "Bezeichnung": "H20 beam variable"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX30OP__A16",
                        "Bezeichnung": "Secondary beam length for drop beam",
                        "Loesung": [
                            {
                                "ID": "FLEX30OP__A16_AW4",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "FLEX30OP__A16_AW2",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "FLEX30OP__A16_AW1",
                                "Bezeichnung": "1.25 m"
                            },
                            {
                                "ID": "FLEX30OP__A16_AW3",
                                "Bezeichnung": "2.45 m"
                            },
                            {
                                "ID": "FLEX30OP__A16_AW5",
                                "Bezeichnung": "2.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A16_AW6",
                                "Bezeichnung": "3.30 m"
                            },
                            {
                                "ID": "FLEX30OP__A16_AW7",
                                "Bezeichnung": "3.60 m"
                            },
                            {
                                "ID": "FLEX30OP__A16_AW8",
                                "Bezeichnung": "3.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A16_AW9",
                                "Bezeichnung": "4.50 m"
                            },
                            {
                                "ID": "FLEX30OP__A16_AW10",
                                "Bezeichnung": "4.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A16_AW11",
                                "Bezeichnung": "5.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A16_AW12",
                                "Bezeichnung": "8x20/100 cm"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX30OP__A17",
                        "Bezeichnung": "Primary beam length for drop beam (only I tec)",
                        "Loesung": [
                            {
                                "ID": "FLEX30OP__A17_AW5",
                                "Bezeichnung": "5.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A17_AW4",
                                "Bezeichnung": "5.35 m"
                            },
                            {
                                "ID": "FLEX30OP__A17_AW3",
                                "Bezeichnung": "4.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A17_AW2",
                                "Bezeichnung": "4.50 m"
                            },
                            {
                                "ID": "FLEX30OP__A17_AW1",
                                "Bezeichnung": "3.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A17_AW11",
                                "Bezeichnung": "3.60 m"
                            },
                            {
                                "ID": "FLEX30OP__A17_AW10",
                                "Bezeichnung": "3.30 m"
                            },
                            {
                                "ID": "FLEX30OP__A17_AW9",
                                "Bezeichnung": "2.90 m"
                            },
                            {
                                "ID": "FLEX30OP__A17_AW8",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "FLEX30OP__A17_AW7",
                                "Bezeichnung": "2.45 m"
                            },
                            {
                                "ID": "FLEX30OP__A17_AW6",
                                "Bezeichnung": "1.80 m"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX30OP__A18",
                        "Bezeichnung": "Removable folding tripods",
                        "Loesung": [
                            {
                                "ID": "FLEX30OP__A18_AW2",
                                "Bezeichnung": "Removable folding tripod top"
                            },
                            {
                                "ID": "FLEX30OP__A18_AW1",
                                "Bezeichnung": "Removable folding tripod"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "FLEXS",
                "Schalungsthema": [
                    {
                        "ID": "FLEXS__A6",
                        "Bezeichnung": "Shutter board",
                        "Loesung": [
                            {
                                "ID": "FLEXS__A6_AW2",
                                "Bezeichnung": "Formwork sheet HDO 4'-0x8'-0x3/4"
                            },
                            {
                                "ID": "FLEXS__A6_AW3",
                                "Bezeichnung": "Formwork sheet HDO 4'-0x8'-0x5/8"
                            },
                            {
                                "ID": "FLEXS__A6_AW1",
                                "Bezeichnung": "Formwork sheet HDO 4'-0x8'-0x1/2"
                            },
                            {
                                "ID": "FLEXS__A6_AW4",
                                "Bezeichnung": "Formwork sheet MDO 4'-0x8'-0x3/4"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXS__A13",
                        "Bezeichnung": "Secondary beam length for slab polygon",
                        "Loesung": [
                            {
                                "ID": "FLEXS__A13_AW11",
                                "Bezeichnung": "11'-6"
                            },
                            {
                                "ID": "FLEXS__A13_AW5",
                                "Bezeichnung": "3.30 m"
                            },
                            {
                                "ID": "FLEXS__A13_AW6",
                                "Bezeichnung": "3.60 m"
                            },
                            {
                                "ID": "FLEXS__A13_AW7",
                                "Bezeichnung": "3.90 m"
                            },
                            {
                                "ID": "FLEXS__A13_AW8",
                                "Bezeichnung": "4.50 m"
                            },
                            {
                                "ID": "FLEXS__A13_AW9",
                                "Bezeichnung": "4.90 m"
                            },
                            {
                                "ID": "FLEXS__A13_AW4",
                                "Bezeichnung": "2.90 m"
                            },
                            {
                                "ID": "FLEXS__A13_AW3",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "FLEXS__A13_AW2",
                                "Bezeichnung": "2.45 m"
                            },
                            {
                                "ID": "FLEXS__A13_AW1",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "FLEXS__A13_AW10",
                                "Bezeichnung": "5.90 m"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXS__A14",
                        "Bezeichnung": "Primary beam length for slab polygon",
                        "Loesung": [
                            {
                                "ID": "FLEXS__A14_AW11",
                                "Bezeichnung": "16'-0"
                            },
                            {
                                "ID": "FLEXS__A14_AW12",
                                "Bezeichnung": "11'-6"
                            },
                            {
                                "ID": "FLEXS__A14_AW9",
                                "Bezeichnung": "4.90 m"
                            },
                            {
                                "ID": "FLEXS__A14_AW8",
                                "Bezeichnung": "4.50 m"
                            },
                            {
                                "ID": "FLEXS__A14_AW7",
                                "Bezeichnung": "3.90 m"
                            },
                            {
                                "ID": "FLEXS__A14_AW6",
                                "Bezeichnung": "3.60 m"
                            },
                            {
                                "ID": "FLEXS__A14_AW5",
                                "Bezeichnung": "3.30 m"
                            },
                            {
                                "ID": "FLEXS__A14_AW4",
                                "Bezeichnung": "2.90 m"
                            },
                            {
                                "ID": "FLEXS__A14_AW3",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "FLEXS__A14_AW2",
                                "Bezeichnung": "2.45 m"
                            },
                            {
                                "ID": "FLEXS__A14_AW1",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "FLEXS__A14_AW10",
                                "Bezeichnung": "5.90 m"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXS__A8",
                        "Bezeichnung": "Dokaflex shoring",
                        "Loesung": [
                            {
                                "ID": "FLEXS__A8_AW1",
                                "Bezeichnung": "Doka floor prop Eurex 30 top 250"
                            },
                            {
                                "ID": "FLEXS__A8_AW2",
                                "Bezeichnung": "Doka floor prop Eurex 30 top 300"
                            },
                            {
                                "ID": "FLEXS__A8_AW3",
                                "Bezeichnung": "Doka floor prop Eurex 30 top 350"
                            },
                            {
                                "ID": "FLEXS__A8_AW4",
                                "Bezeichnung": "Doka floor prop Eurex 30 top 400"
                            },
                            {
                                "ID": "FLEXS__A8_AW5",
                                "Bezeichnung": "Doka floor prop Eurex 30 top 450"
                            },
                            {
                                "ID": "FLEXS__A8_AW6",
                                "Bezeichnung": "Doka floor prop Eurex 30 top 550"
                            },
                            {
                                "ID": "FLEXS__A8_AW7",
                                "Bezeichnung": "Doka floor prop Eurex 20 top 550"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXS__A12",
                        "Bezeichnung": "Dokaflex drop beam type",
                        "Loesung": [
                            {
                                "ID": "FLEXS__A12_AW2",
                                "Bezeichnung": "Squared timber by site"
                            },
                            {
                                "ID": "FLEXS__A12_AW1",
                                "Bezeichnung": "Beam forming support 20"
                            },
                            {
                                "ID": "FLEXS__A12_AW3",
                                "Bezeichnung": "H20 beam variable"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXS__A15",
                        "Bezeichnung": "Secondary beam length for drop beam",
                        "Loesung": [
                            {
                                "ID": "FLEXS__A15_AW1",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "FLEXS__A15_AW2",
                                "Bezeichnung": "2.45 m"
                            },
                            {
                                "ID": "FLEXS__A15_AW3",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "FLEXS__A15_AW4",
                                "Bezeichnung": "2.90 m"
                            },
                            {
                                "ID": "FLEXS__A15_AW5",
                                "Bezeichnung": "3.30 m"
                            },
                            {
                                "ID": "FLEXS__A15_AW11",
                                "Bezeichnung": "11'-6"
                            },
                            {
                                "ID": "FLEXS__A15_AW6",
                                "Bezeichnung": "3.60 m"
                            },
                            {
                                "ID": "FLEXS__A15_AW7",
                                "Bezeichnung": "3.90 m"
                            },
                            {
                                "ID": "FLEXS__A15_AW8",
                                "Bezeichnung": "4.50 m"
                            },
                            {
                                "ID": "FLEXS__A15_AW9",
                                "Bezeichnung": "4.90 m"
                            },
                            {
                                "ID": "FLEXS__A15_AW10",
                                "Bezeichnung": "5.90 m"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXS__A16",
                        "Bezeichnung": "Primary beam length for drop beam",
                        "Loesung": [
                            {
                                "ID": "FLEXS__A16_AW11",
                                "Bezeichnung": "16'-0"
                            },
                            {
                                "ID": "FLEXS__A16_AW12",
                                "Bezeichnung": "11'-6"
                            },
                            {
                                "ID": "FLEXS__A16_AW4",
                                "Bezeichnung": "2.90 m"
                            },
                            {
                                "ID": "FLEXS__A16_AW5",
                                "Bezeichnung": "3.30 m"
                            },
                            {
                                "ID": "FLEXS__A16_AW6",
                                "Bezeichnung": "3.60 m"
                            },
                            {
                                "ID": "FLEXS__A16_AW7",
                                "Bezeichnung": "3.90 m"
                            },
                            {
                                "ID": "FLEXS__A16_AW8",
                                "Bezeichnung": "4.50 m"
                            },
                            {
                                "ID": "FLEXS__A16_AW9",
                                "Bezeichnung": "4.90 m"
                            },
                            {
                                "ID": "FLEXS__A16_AW10",
                                "Bezeichnung": "5.90 m"
                            },
                            {
                                "ID": "FLEXS__A16_AW1",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "FLEXS__A16_AW2",
                                "Bezeichnung": "2.45 m"
                            },
                            {
                                "ID": "FLEXS__A16_AW3",
                                "Bezeichnung": "2.65 m"
                            }
                        ]
                    },
                    {
                        "ID": "FLEXS__A17",
                        "Bezeichnung": "Guard rail",
                        "Loesung": [
                            {
                                "ID": "FLEXS__A17_AW1",
                                "Bezeichnung": "Handrail clamp S"
                            },
                            {
                                "ID": "FLEXS__A17_AW2",
                                "Bezeichnung": "Handrail post T"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "FLEX15",
                "Schalungsthema": [
                    {
                        "ID": "FLEX15__A1",
                        "Bezeichnung": "Plywood type/dimensions",
                        "Loesung": [
                            {
                                "ID": "FLEX15__A1_AW1",
                                "Bezeichnung": "DokaPly eco 18mm 250/125 cm"
                            },
                            {
                                "ID": "FLEX15__A1_AW2",
                                "Bezeichnung": "DokaPly eco 21mm 250/125 cm"
                            },
                            {
                                "ID": "FLEX15__A1_AW3",
                                "Bezeichnung": "Formwork sheet 3-SO 21mm 200/50 cm"
                            },
                            {
                                "ID": "FLEX15__A1_AW4",
                                "Bezeichnung": "Formwork sheet 3-SO 21mm 250/50 cm"
                            },
                            {
                                "ID": "FLEX15__A1_AW5",
                                "Bezeichnung": "Formwork sheet 3-SO 21mm 250/125 cm"
                            },
                            {
                                "ID": "FLEX15__A1_AW6",
                                "Bezeichnung": "Formwork sheet 3-SO 21mm 244/122 cm"
                            },
                            {
                                "ID": "FLEX15__A1_AW7",
                                "Bezeichnung": "Formwork sheet 3S basic 21mm 200/50cm"
                            },
                            {
                                "ID": "FLEX15__A1_AW8",
                                "Bezeichnung": "Formwork sheet 3S basic 21mm 250/50cm"
                            },
                            {
                                "ID": "FLEX15__A1_AW9",
                                "Bezeichnung": "Formwork sheet 3S cut 21mm 200/50cm"
                            },
                            {
                                "ID": "FLEX15__A1_AW10",
                                "Bezeichnung": "Formwork sheet 3S cut 21mm 250/50cm"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX15__A2",
                        "Bezeichnung": "Formwork beam type",
                        "Loesung": [
                            {
                                "ID": "FLEX15__A2_AW1",
                                "Bezeichnung": "Beam H16 P"
                            },
                            {
                                "ID": "FLEX15__A2_AW2",
                                "Bezeichnung": "Beam H16 N"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX15__A3",
                        "Bezeichnung": "Secondary beam length for slab polygon",
                        "Loesung": [
                            {
                                "ID": "FLEX15__A3_AW2",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "FLEX15__A3_AW3",
                                "Bezeichnung": "2.45 m"
                            },
                            {
                                "ID": "FLEX15__A3_AW4",
                                "Bezeichnung": "2.90 m"
                            },
                            {
                                "ID": "FLEX15__A3_AW1",
                                "Bezeichnung": "3.30 m"
                            },
                            {
                                "ID": "FLEX15__A3_AW5",
                                "Bezeichnung": "3.90 m"
                            },
                            {
                                "ID": "FLEX15__A3_AW6",
                                "Bezeichnung": "4.90 m"
                            },
                            {
                                "ID": "FLEX15__A3_AW7",
                                "Bezeichnung": "9.00 m"
                            }
                        ]
                    },
                    {
                        "ID": "FLEX15__A4",
                        "Bezeichnung": "Primary beam length for slab polygon",
                        "Loesung": [
                            {
                                "ID": "FLEX15__A4_AW1",
                                "Bezeichnung": "3.90 m"
                            },
                            {
                                "ID": "FLEX15__A4_AW2",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "FLEX15__A4_AW3",
                                "Bezeichnung": "2.45 m"
                            },
                            {
                                "ID": "FLEX15__A4_AW4",
                                "Bezeichnung": "2.90 m"
                            },
                            {
                                "ID": "FLEX15__A4_AW5",
                                "Bezeichnung": "3.30 m"
                            },
                            {
                                "ID": "FLEX15__A4_AW6",
                                "Bezeichnung": "4.90 m"
                            },
                            {
                                "ID": "FLEX15__A4_AW7",
                                "Bezeichnung": "9.00 m"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "XTRA",
                "Schalungsthema": [
                    {
                        "ID": "XTRA__A2",
                        "Bezeichnung": "Tableform type",
                        "Loesung": [
                            {
                                "ID": "XTRA__A2_AW1",
                                "Bezeichnung": "Dokamatic table"
                            },
                            {
                                "ID": "XTRA__A2_AW2",
                                "Bezeichnung": "Dokaflex table"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA__A3",
                        "Bezeichnung": "Tableform plywood",
                        "Loesung": [
                            {
                                "ID": "XTRA__A3_AW2",
                                "Bezeichnung": "3SO-27"
                            },
                            {
                                "ID": "XTRA__A3_AW1",
                                "Bezeichnung": "3SO-21"
                            },
                            {
                                "ID": "XTRA__A3_AW3",
                                "Bezeichnung": "without plywood"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA__A4",
                        "Bezeichnung": "Tableform shoring",
                        "Loesung": [
                            {
                                "ID": "XTRA__A4_AW8",
                                "Bezeichnung": "Eurex 20 top"
                            },
                            {
                                "ID": "XTRA__A4_AW9",
                                "Bezeichnung": "Eurex 30 top"
                            },
                            {
                                "ID": "XTRA__A4_AW1",
                                "Bezeichnung": "Eurex 20"
                            },
                            {
                                "ID": "XTRA__A4_AW2",
                                "Bezeichnung": "Eurex 30"
                            },
                            {
                                "ID": "XTRA__A4_AW3",
                                "Bezeichnung": "Floor prop G"
                            },
                            {
                                "ID": "XTRA__A4_AW4",
                                "Bezeichnung": "Floor prop N"
                            },
                            {
                                "ID": "XTRA__A4_AW5",
                                "Bezeichnung": "Floor prop eco"
                            },
                            {
                                "ID": "XTRA__A4_AW6",
                                "Bezeichnung": "Staxo"
                            },
                            {
                                "ID": "XTRA__A4_AW7",
                                "Bezeichnung": "Aluxo"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA__A5",
                        "Bezeichnung": "Tableform Shoring Edge props",
                        "Loesung": [
                            {
                                "ID": "XTRA__A5_AW1",
                                "Bezeichnung": "Standard"
                            },
                            {
                                "ID": "XTRA__A5_AW2",
                                "Bezeichnung": "EP repositioned, 1 IP with swivel head"
                            },
                            {
                                "ID": "XTRA__A5_AW3",
                                "Bezeichnung": "EP repositioned, 1 IP with prop connection"
                            },
                            {
                                "ID": "XTRA__A5_AW4",
                                "Bezeichnung": "EP repositioned, 2 IP with swivel head"
                            },
                            {
                                "ID": "XTRA__A5_AW5",
                                "Bezeichnung": "EP repositioned, 2 IP with prop connection"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA__A6",
                        "Bezeichnung": "Tableform heavy duty screw jack/screw jack foot",
                        "Loesung": [
                            {
                                "ID": "XTRA__A6_AW1",
                                "Bezeichnung": "Heavy duty screw jack"
                            },
                            {
                                "ID": "XTRA__A6_AW2",
                                "Bezeichnung": "Screw jack foot"
                            },
                            {
                                "ID": "XTRA__A6_AW3",
                                "Bezeichnung": "without spindle"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA__A7",
                        "Bezeichnung": "Shutter Board type",
                        "Loesung": [
                            {
                                "ID": "XTRA__A7_AW7",
                                "Bezeichnung": "Formwork sheet 3-SO 27 mm"
                            },
                            {
                                "ID": "XTRA__A7_AW10",
                                "Bezeichnung": "Formwork sheet 3S basic 27mm"
                            },
                            {
                                "ID": "XTRA__A7_AW11",
                                "Bezeichnung": "Formwork sheet 3S cut 27mm"
                            },
                            {
                                "ID": "XTRA__A7_AW6",
                                "Bezeichnung": "Dokadur panel 27"
                            },
                            {
                                "ID": "XTRA__A7_AW4",
                                "Bezeichnung": "Plywood sheet"
                            },
                            {
                                "ID": "XTRA__A7_AW5",
                                "Bezeichnung": "Dokaflex table sheet"
                            },
                            {
                                "ID": "XTRA__A7_AW2",
                                "Bezeichnung": "Formwork sheet 3-SO 21 mm"
                            },
                            {
                                "ID": "XTRA__A7_AW8",
                                "Bezeichnung": "Formwork sheet 3S basic 21mm"
                            },
                            {
                                "ID": "XTRA__A7_AW9",
                                "Bezeichnung": "Formwork sheet 3S cut 21mm"
                            },
                            {
                                "ID": "XTRA__A7_AW1",
                                "Bezeichnung": "Dokadur panel 21"
                            },
                            {
                                "ID": "XTRA__A7_AW3",
                                "Bezeichnung": "Dokadur-Plex panel 21"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA__A12",
                        "Bezeichnung": "Shutter Board dimension",
                        "Loesung": [
                            {
                                "ID": "XTRA__A12_AW3",
                                "Bezeichnung": "200/50 cm"
                            },
                            {
                                "ID": "XTRA__A12_AW4",
                                "Bezeichnung": "250/50 cm"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA__A8",
                        "Bezeichnung": "Formwork beam type",
                        "Loesung": [
                            {
                                "ID": "XTRA__A8_AW1",
                                "Bezeichnung": "Beam H20 top P"
                            },
                            {
                                "ID": "XTRA__A8_AW2",
                                "Bezeichnung": "Beam H20 top N"
                            },
                            {
                                "ID": "XTRA__A8_AW3",
                                "Bezeichnung": "Beam H20 eco P"
                            },
                            {
                                "ID": "XTRA__A8_AW4",
                                "Bezeichnung": "Beam H20 eco N"
                            },
                            {
                                "ID": "XTRA__A8_AW5",
                                "Bezeichnung": "Beam H20 rivet P"
                            },
                            {
                                "ID": "XTRA__A8_AW6",
                                "Bezeichnung": "Beam H20 rivet N"
                            },
                            {
                                "ID": "XTRA__A8_AW7",
                                "Bezeichnung": "Squared timber by site"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA__A13",
                        "Bezeichnung": "Secondary beam length preferred",
                        "Loesung": [
                            {
                                "ID": "XTRA__A13_AW4",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "XTRA__A13_AW2",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "XTRA__A13_AW12",
                                "Bezeichnung": "8x20/100 cm"
                            },
                            {
                                "ID": "XTRA__A13_AW13",
                                "Bezeichnung": "8x20/125 cm"
                            },
                            {
                                "ID": "XTRA__A13_AW14",
                                "Bezeichnung": "8x20/150 cm"
                            },
                            {
                                "ID": "XTRA__A13_AW15",
                                "Bezeichnung": "8x20/175 cm"
                            },
                            {
                                "ID": "XTRA__A13_AW16",
                                "Bezeichnung": "8x20/200 cm"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA__A14",
                        "Bezeichnung": "Primary beam length preferred",
                        "Loesung": [
                            {
                                "ID": "XTRA__A14_AW4",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "XTRA__A14_AW2",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "XTRA__A14_AW12",
                                "Bezeichnung": "8x20/100 cm"
                            },
                            {
                                "ID": "XTRA__A14_AW13",
                                "Bezeichnung": "8x20/125 cm"
                            },
                            {
                                "ID": "XTRA__A14_AW14",
                                "Bezeichnung": "8x20/150 cm"
                            },
                            {
                                "ID": "XTRA__A14_AW15",
                                "Bezeichnung": "8x20/175 cm"
                            },
                            {
                                "ID": "XTRA__A14_AW16",
                                "Bezeichnung": "8x20/200 cm"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA__A9",
                        "Bezeichnung": "Xtra shoring",
                        "Loesung": [
                            {
                                "ID": "XTRA__A9_AW7",
                                "Bezeichnung": "Eurex 20 top"
                            },
                            {
                                "ID": "XTRA__A9_AW8",
                                "Bezeichnung": "Eurex 30 top"
                            },
                            {
                                "ID": "XTRA__A9_AW1",
                                "Bezeichnung": "Eurex 20"
                            },
                            {
                                "ID": "XTRA__A9_AW2",
                                "Bezeichnung": "Eurex 30"
                            },
                            {
                                "ID": "XTRA__A9_AW3",
                                "Bezeichnung": "Eurex 60"
                            },
                            {
                                "ID": "XTRA__A9_AW4",
                                "Bezeichnung": "Floor prop G"
                            },
                            {
                                "ID": "XTRA__A9_AW5",
                                "Bezeichnung": "Floor prop N"
                            },
                            {
                                "ID": "XTRA__A9_AW6",
                                "Bezeichnung": "Floor prop eco"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA__A11",
                        "Bezeichnung": "Xtra drop beam type",
                        "Loesung": [
                            {
                                "ID": "XTRA__A11_AW3",
                                "Bezeichnung": "Beam forming support 20 + H20 beam variable"
                            },
                            {
                                "ID": "XTRA__A11_AW1",
                                "Bezeichnung": "Beam forming support 20"
                            },
                            {
                                "ID": "XTRA__A11_AW2",
                                "Bezeichnung": "Squared timber by site"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA__A15",
                        "Bezeichnung": "Tableform closure accessories",
                        "Loesung": [
                            {
                                "ID": "XTRA__A15_AW1",
                                "Bezeichnung": "100/50 cm"
                            },
                            {
                                "ID": "XTRA__A15_AW2",
                                "Bezeichnung": "150/50 cm"
                            },
                            {
                                "ID": "XTRA__A15_AW3",
                                "Bezeichnung": "200/50 cm"
                            },
                            {
                                "ID": "XTRA__A15_AW4",
                                "Bezeichnung": "250/50 cm"
                            },
                            {
                                "ID": "XTRA__A15_AW5",
                                "Bezeichnung": "100/100 cm"
                            },
                            {
                                "ID": "XTRA__A15_AW6",
                                "Bezeichnung": "150/100 cm"
                            },
                            {
                                "ID": "XTRA__A15_AW7",
                                "Bezeichnung": "200/100 cm"
                            },
                            {
                                "ID": "XTRA__A15_AW8",
                                "Bezeichnung": "35/200 cm"
                            },
                            {
                                "ID": "XTRA__A15_AW9",
                                "Bezeichnung": "35/250 cm"
                            },
                            {
                                "ID": "XTRA__A15_AW14",
                                "Bezeichnung": "14.7/200 cm"
                            },
                            {
                                "ID": "XTRA__A15_AW15",
                                "Bezeichnung": "14.7/250 cm"
                            },
                            {
                                "ID": "XTRA__A15_AW10",
                                "Bezeichnung": "244/61 cm"
                            },
                            {
                                "ID": "XTRA__A15_AW12",
                                "Bezeichnung": "122/61 cm"
                            },
                            {
                                "ID": "XTRA__A15_AW13",
                                "Bezeichnung": "244/122 cm"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA__A16",
                        "Bezeichnung": "Removable folding tripods",
                        "Loesung": [
                            {
                                "ID": "XTRA__A16_AW2",
                                "Bezeichnung": "Removable folding tripod top"
                            },
                            {
                                "ID": "XTRA__A16_AW1",
                                "Bezeichnung": "Removable folding tripod"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA__A17",
                        "Bezeichnung": "Insertion beam",
                        "Loesung": [
                            {
                                "ID": "XTRA__A17_AW1",
                                "Bezeichnung": "Insertion beam"
                            },
                            {
                                "ID": "XTRA__A17_AW2",
                                "Bezeichnung": "Dokamatic insertion beam"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "XTRA30",
                "Schalungsthema": [
                    {
                        "ID": "XTRA30__A2",
                        "Bezeichnung": "Tableform type",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A2_AW1",
                                "Bezeichnung": "Dokamatic table"
                            },
                            {
                                "ID": "XTRA30__A2_AW2",
                                "Bezeichnung": "Dokaflex table"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA30__A3",
                        "Bezeichnung": "Tableform plywood",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A3_AW2",
                                "Bezeichnung": "3SO-27"
                            },
                            {
                                "ID": "XTRA30__A3_AW1",
                                "Bezeichnung": "3SO-21"
                            },
                            {
                                "ID": "XTRA30__A3_AW3",
                                "Bezeichnung": "without plywood"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA30__A4",
                        "Bezeichnung": "Tableform shoring",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A4_AW9",
                                "Bezeichnung": "Eurex 30 top"
                            },
                            {
                                "ID": "XTRA30__A4_AW8",
                                "Bezeichnung": "Eurex 20 top"
                            },
                            {
                                "ID": "XTRA30__A4_AW2",
                                "Bezeichnung": "Eurex 30"
                            },
                            {
                                "ID": "XTRA30__A4_AW1",
                                "Bezeichnung": "Eurex 20"
                            },
                            {
                                "ID": "XTRA30__A4_AW3",
                                "Bezeichnung": "Floor prop G"
                            },
                            {
                                "ID": "XTRA30__A4_AW4",
                                "Bezeichnung": "Floor prop N"
                            },
                            {
                                "ID": "XTRA30__A4_AW5",
                                "Bezeichnung": "Floor prop eco"
                            },
                            {
                                "ID": "XTRA30__A4_AW6",
                                "Bezeichnung": "Staxo"
                            },
                            {
                                "ID": "XTRA30__A4_AW7",
                                "Bezeichnung": "Aluxo"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA30__A5",
                        "Bezeichnung": "Tableform Shoring Edge props",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A5_AW1",
                                "Bezeichnung": "Standard"
                            },
                            {
                                "ID": "XTRA30__A5_AW2",
                                "Bezeichnung": "EP repositioned, 1 IP with swivel head"
                            },
                            {
                                "ID": "XTRA30__A5_AW3",
                                "Bezeichnung": "EP repositioned, 1 IP with prop connection"
                            },
                            {
                                "ID": "XTRA30__A5_AW4",
                                "Bezeichnung": "EP repositioned, 2 IP with swivel head"
                            },
                            {
                                "ID": "XTRA30__A5_AW5",
                                "Bezeichnung": "EP repositioned, 2 IP with prop connection"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA30__A6",
                        "Bezeichnung": "Tableform heavy duty screw jack/screw jack food",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A6_AW1",
                                "Bezeichnung": "Heavy duty screw jack"
                            },
                            {
                                "ID": "XTRA30__A6_AW2",
                                "Bezeichnung": "Screw jack foot"
                            },
                            {
                                "ID": "XTRA30__A6_AW3",
                                "Bezeichnung": "without spindle"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA30__A7",
                        "Bezeichnung": "Shutter Board type",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A7_AW7",
                                "Bezeichnung": "Formwork sheet 3-SO 27 mm"
                            },
                            {
                                "ID": "XTRA30__A7_AW10",
                                "Bezeichnung": "Formwork sheet 3S basic 27mm"
                            },
                            {
                                "ID": "XTRA30__A7_AW11",
                                "Bezeichnung": "Formwork sheet 3S cut 27mm"
                            },
                            {
                                "ID": "XTRA30__A7_AW6",
                                "Bezeichnung": "Dokadur panel 27"
                            },
                            {
                                "ID": "XTRA30__A7_AW4",
                                "Bezeichnung": "Plywood sheet"
                            },
                            {
                                "ID": "XTRA30__A7_AW5",
                                "Bezeichnung": "Dokaflex table sheet"
                            },
                            {
                                "ID": "XTRA30__A7_AW2",
                                "Bezeichnung": "Formwork sheet 3-SO 21 mm"
                            },
                            {
                                "ID": "XTRA30__A7_AW8",
                                "Bezeichnung": "Formwork sheet 3S basic 21mm"
                            },
                            {
                                "ID": "XTRA30__A7_AW9",
                                "Bezeichnung": "Formwork sheet 3S cut 21mm"
                            },
                            {
                                "ID": "XTRA30__A7_AW1",
                                "Bezeichnung": "Dokadur panel 21"
                            },
                            {
                                "ID": "XTRA30__A7_AW3",
                                "Bezeichnung": "Dokadur-Plex panel 21"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA30__A12",
                        "Bezeichnung": "Shutter Board dimension",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A12_AW3",
                                "Bezeichnung": "200/50 cm"
                            },
                            {
                                "ID": "XTRA30__A12_AW4",
                                "Bezeichnung": "250/50 cm"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA30__A8",
                        "Bezeichnung": "Formwork beam type",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A8_AW1",
                                "Bezeichnung": "Beam H20 top P"
                            },
                            {
                                "ID": "XTRA30__A8_AW2",
                                "Bezeichnung": "Beam H20 top N"
                            },
                            {
                                "ID": "XTRA30__A8_AW3",
                                "Bezeichnung": "Beam H20 eco P"
                            },
                            {
                                "ID": "XTRA30__A8_AW4",
                                "Bezeichnung": "Beam H20 eco N"
                            },
                            {
                                "ID": "XTRA30__A8_AW5",
                                "Bezeichnung": "Beam H20 rivet P"
                            },
                            {
                                "ID": "XTRA30__A8_AW6",
                                "Bezeichnung": "Beam H20 rivet N"
                            },
                            {
                                "ID": "XTRA30__A8_AW7",
                                "Bezeichnung": "Squared timber by site"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA30__A13",
                        "Bezeichnung": "Secondary beam length preferred",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A13_AW4",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "XTRA30__A13_AW2",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "XTRA30__A13_AW12",
                                "Bezeichnung": "8x20/100 cm"
                            },
                            {
                                "ID": "XTRA30__A13_AW13",
                                "Bezeichnung": "8x20/125 cm"
                            },
                            {
                                "ID": "XTRA30__A13_AW14",
                                "Bezeichnung": "8x20/150 cm"
                            },
                            {
                                "ID": "XTRA30__A13_AW15",
                                "Bezeichnung": "8x20/175 cm"
                            },
                            {
                                "ID": "XTRA30__A13_AW16",
                                "Bezeichnung": "8x20/200 cm"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA30__A14",
                        "Bezeichnung": "Primary beam length preferred",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A14_AW4",
                                "Bezeichnung": "2.65 m"
                            },
                            {
                                "ID": "XTRA30__A14_AW2",
                                "Bezeichnung": "1.80 m"
                            },
                            {
                                "ID": "XTRA30__A14_AW12",
                                "Bezeichnung": "8x20/100 cm"
                            },
                            {
                                "ID": "XTRA30__A14_AW13",
                                "Bezeichnung": "8x20/125 cm"
                            },
                            {
                                "ID": "XTRA30__A14_AW14",
                                "Bezeichnung": "8x20/150 cm"
                            },
                            {
                                "ID": "XTRA30__A14_AW15",
                                "Bezeichnung": "8x20/175 cm"
                            },
                            {
                                "ID": "XTRA30__A14_AW16",
                                "Bezeichnung": "8x20/200 cm"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA30__A9",
                        "Bezeichnung": "Xtra shoring",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A9_AW8",
                                "Bezeichnung": "Eurex 30 top"
                            },
                            {
                                "ID": "XTRA30__A9_AW7",
                                "Bezeichnung": "Eurex 20 top"
                            },
                            {
                                "ID": "XTRA30__A9_AW2",
                                "Bezeichnung": "Eurex 30"
                            },
                            {
                                "ID": "XTRA30__A9_AW1",
                                "Bezeichnung": "Eurex 20"
                            },
                            {
                                "ID": "XTRA30__A9_AW3",
                                "Bezeichnung": "Eurex 60"
                            },
                            {
                                "ID": "XTRA30__A9_AW4",
                                "Bezeichnung": "Floor prop G"
                            },
                            {
                                "ID": "XTRA30__A9_AW5",
                                "Bezeichnung": "Floor prop N"
                            },
                            {
                                "ID": "XTRA30__A9_AW6",
                                "Bezeichnung": "Floor prop eco"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA30__A11",
                        "Bezeichnung": "Xtra drop beam type",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A11_AW3",
                                "Bezeichnung": "Beam forming support 20 + H20 beam variable"
                            },
                            {
                                "ID": "XTRA30__A11_AW1",
                                "Bezeichnung": "Beam forming support 20"
                            },
                            {
                                "ID": "XTRA30__A11_AW2",
                                "Bezeichnung": "Squared timber by site"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA30__A15",
                        "Bezeichnung": "Tableform closure accessories",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A15_AW1",
                                "Bezeichnung": "100/50 cm"
                            },
                            {
                                "ID": "XTRA30__A15_AW2",
                                "Bezeichnung": "150/50 cm"
                            },
                            {
                                "ID": "XTRA30__A15_AW3",
                                "Bezeichnung": "200/50 cm"
                            },
                            {
                                "ID": "XTRA30__A15_AW4",
                                "Bezeichnung": "250/50 cm"
                            },
                            {
                                "ID": "XTRA30__A15_AW5",
                                "Bezeichnung": "100/100 cm"
                            },
                            {
                                "ID": "XTRA30__A15_AW6",
                                "Bezeichnung": "150/100 cm"
                            },
                            {
                                "ID": "XTRA30__A15_AW7",
                                "Bezeichnung": "200/100 cm"
                            },
                            {
                                "ID": "XTRA30__A15_AW8",
                                "Bezeichnung": "35/200 cm"
                            },
                            {
                                "ID": "XTRA30__A15_AW9",
                                "Bezeichnung": "35/250 cm"
                            },
                            {
                                "ID": "XTRA30__A15_AW14",
                                "Bezeichnung": "14.7/200 cm"
                            },
                            {
                                "ID": "XTRA30__A15_AW15",
                                "Bezeichnung": "14.7/250 cm"
                            },
                            {
                                "ID": "XTRA30__A15_AW10",
                                "Bezeichnung": "244/61 cm"
                            },
                            {
                                "ID": "XTRA30__A15_AW12",
                                "Bezeichnung": "122/61 cm"
                            },
                            {
                                "ID": "XTRA30__A15_AW13",
                                "Bezeichnung": "244/122 cm"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA30__A16",
                        "Bezeichnung": "Removable folding tripods",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A16_AW2",
                                "Bezeichnung": "Removable folding tripod top"
                            },
                            {
                                "ID": "XTRA30__A16_AW1",
                                "Bezeichnung": "Removable folding tripod"
                            }
                        ]
                    },
                    {
                        "ID": "XTRA30__A17",
                        "Bezeichnung": "Insertion beam",
                        "Loesung": [
                            {
                                "ID": "XTRA30__A17_AW1",
                                "Bezeichnung": "Insertion beam"
                            },
                            {
                                "ID": "XTRA30__A17_AW2",
                                "Bezeichnung": "Dokamatic insertion beam"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "KOBMDIDI",
                "Schalungsthema": [
                    {
                        "ID": "KOBMDIDI_A1",
                        "Bezeichnung": "Type of installation",
                        "Loesung": [
                            {
                                "ID": "KOBMDIDI_A1_AW1",
                                "Bezeichnung": "Platform stand. fitting"
                            },
                            {
                                "ID": "KOBMDIDI_A1_AW2",
                                "Bezeichnung": "Platform with lengthening piece"
                            },
                            {
                                "ID": "KOBMDIDI_A1_AW3",
                                "Bezeichnung": "Platform height-shifted 1.00 m"
                            },
                            {
                                "ID": "KOBMDIDI_A1_AW4",
                                "Bezeichnung": "Platform height-shifted with lengthening piece"
                            }
                        ]
                    },
                    {
                        "ID": "KOBMDIDI_A2",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "KOBMDIDI_A2_AW2",
                                "Bezeichnung": "Corner crossing"
                            },
                            {
                                "ID": "KOBMDIDI_A2_AW1",
                                "Bezeichnung": "Corner platform M"
                            },
                            {
                                "ID": "KOBMDIDI_A2_AW3",
                                "Bezeichnung": "Planks"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "KOBMMAXA",
                "Schalungsthema": [
                    {
                        "ID": "KOBMMAXA_A1",
                        "Bezeichnung": "Type of installation",
                        "Loesung": [
                            {
                                "ID": "KOBMMAXA_A1_AW1",
                                "Bezeichnung": "Platform stand. fitting"
                            },
                            {
                                "ID": "KOBMMAXA_A1_AW2",
                                "Bezeichnung": "Platform with lengthening piece V"
                            },
                            {
                                "ID": "KOBMMAXA_A1_AW3",
                                "Bezeichnung": "Platform height-shifted 1.00 m H"
                            },
                            {
                                "ID": "KOBMMAXA_A1_AW4",
                                "Bezeichnung": "Platform height-shifted with lengthening piece HV"
                            }
                        ]
                    },
                    {
                        "ID": "KOBMMAXA_A2",
                        "Bezeichnung": "with or without platform decking 3.00m",
                        "Loesung": [
                            {
                                "ID": "KOBMMAXA_A2_AW1",
                                "Bezeichnung": "with platform decking"
                            },
                            {
                                "ID": "KOBMMAXA_A2_AW2",
                                "Bezeichnung": "without platform decking"
                            }
                        ]
                    },
                    {
                        "ID": "KOBMMAXA_A3",
                        "Bezeichnung": "Corner solution",
                        "Loesung": [
                            {
                                "ID": "KOBMDIDI_A3_AW2",
                                "Bezeichnung": "Corner crossing"
                            },
                            {
                                "ID": "KOBMDIDI_A3_AW1",
                                "Bezeichnung": "Corner platform M"
                            },
                            {
                                "ID": "KOBMDIDI_A3_AW3",
                                "Bezeichnung": "Planks"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "FALTBKG2",
                "Schalungsthema": [
                    {
                        "ID": "FALTBKG2__A1",
                        "Bezeichnung": "Suspension methods",
                        "Loesung": [
                            {
                                "ID": "FALTBKG2__A1_AW1",
                                "Bezeichnung": "Suspension with suspension cone"
                            },
                            {
                                "ID": "FALTBKG2__A1_AW2",
                                "Bezeichnung": "Suspension with suspension loop ES"
                            }
                        ]
                    },
                    {
                        "ID": "FALTBKG2__A2",
                        "Bezeichnung": "Platform width",
                        "Loesung": [
                            {
                                "ID": "FALTBKG2__A2_AW1",
                                "Bezeichnung": "Folding platform K 4.50m"
                            },
                            {
                                "ID": "FALTBKG2__A2_AW2",
                                "Bezeichnung": "Folding platform K 3.00m"
                            }
                        ]
                    },
                    {
                        "ID": "FALTBKG2__A3",
                        "Bezeichnung": "End-of-platform sideguard",
                        "Loesung": [
                            {
                                "ID": "FALTBKG2__A3_AW1",
                                "Bezeichnung": "Edge protection system XP"
                            },
                            {
                                "ID": "FALTBKG2__A3_AW2",
                                "Bezeichnung": "Handrail posts T"
                            }
                        ]
                    },
                    {
                        "ID": "FALTBKG2__A4",
                        "Bezeichnung": "Closure",
                        "Loesung": [
                            {
                                "ID": "FALTBKG2__A4_AW1",
                                "Bezeichnung": "Closure platform 3.00m"
                            },
                            {
                                "ID": "FALTBKG2__A4_AW2",
                                "Bezeichnung": "Planks by site"
                            },
                            {
                                "ID": "FALTBKG2__A4_AW3",
                                "Bezeichnung": "Platforms of single brackets"
                            }
                        ]
                    },
                    {
                        "ID": "FALTBKG2__A5",
                        "Bezeichnung": "Corners",
                        "Loesung": [
                            {
                                "ID": "FALTBKG2__A5_AW1",
                                "Bezeichnung": "Folding platform K outside corner"
                            },
                            {
                                "ID": "FALTBKG2__A5_AW2",
                                "Bezeichnung": "Folding platform with closure platform"
                            },
                            {
                                "ID": "FALTBKG2__A5_AW3",
                                "Bezeichnung": "Folding platform with planks by site"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "FALTBKG3",
                "Schalungsthema": [
                    {
                        "ID": "FALTBKG3__A1",
                        "Bezeichnung": "Suspension methods",
                        "Loesung": [
                            {
                                "ID": "FALTBKG3__A1_AW1",
                                "Bezeichnung": "Suspension with suspension cone"
                            },
                            {
                                "ID": "FALTBKG3__A1_AW2",
                                "Bezeichnung": "Suspension with suspension loop ES"
                            }
                        ]
                    },
                    {
                        "ID": "FALTBKG3__A2",
                        "Bezeichnung": "Platform width",
                        "Loesung": [
                            {
                                "ID": "FALTBKG3__A2_AW1",
                                "Bezeichnung": "Folding platform K 4.50m"
                            },
                            {
                                "ID": "FALTBKG3__A2_AW2",
                                "Bezeichnung": "Folding platform K 3.00m"
                            }
                        ]
                    },
                    {
                        "ID": "FALTBKG3__A3",
                        "Bezeichnung": "End-of-platform sideguard",
                        "Loesung": [
                            {
                                "ID": "FALTBKG3__A3_AW1",
                                "Bezeichnung": "Edge protection system XP"
                            },
                            {
                                "ID": "FALTBKG3__A3_AW2",
                                "Bezeichnung": "Handrail posts T"
                            }
                        ]
                    },
                    {
                        "ID": "FALTBKG3__A4",
                        "Bezeichnung": "Closure",
                        "Loesung": [
                            {
                                "ID": "FALTBKG3__A4_AW1",
                                "Bezeichnung": "Closure platform 3.00m"
                            },
                            {
                                "ID": "FALTBKG3__A4_AW2",
                                "Bezeichnung": "Planks by site"
                            },
                            {
                                "ID": "FALTBKG3__A4_AW3",
                                "Bezeichnung": "Platforms of single brackets"
                            }
                        ]
                    },
                    {
                        "ID": "FALTBKG3__A5",
                        "Bezeichnung": "Corners",
                        "Loesung": [
                            {
                                "ID": "FALTBKG3__A5_AW1",
                                "Bezeichnung": "Folding platform K outside corner"
                            },
                            {
                                "ID": "FALTBKG3__A5_AW2",
                                "Bezeichnung": "Folding platform with closure platform"
                            },
                            {
                                "ID": "FALTBKG3__A5_AW3",
                                "Bezeichnung": "Folding platform with planks by site"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "FALTBKG4",
                "Schalungsthema": [
                    {
                        "ID": "FALTBKG4__A1",
                        "Bezeichnung": "Suspension methods",
                        "Loesung": [
                            {
                                "ID": "FALTBKG4__A1_AW1",
                                "Bezeichnung": "Suspension with suspension cone"
                            },
                            {
                                "ID": "FALTBKG4__A1_AW2",
                                "Bezeichnung": "Suspension with suspension loop ES"
                            }
                        ]
                    },
                    {
                        "ID": "FALTBKG4__A2",
                        "Bezeichnung": "Platform width",
                        "Loesung": [
                            {
                                "ID": "FALTBKG4__A2_AW1",
                                "Bezeichnung": "Folding platform K 4.50m"
                            },
                            {
                                "ID": "FALTBKG4__A2_AW2",
                                "Bezeichnung": "Folding platform K 3.00m"
                            }
                        ]
                    },
                    {
                        "ID": "FALTBKG4__A3",
                        "Bezeichnung": "End-of-platform sideguard",
                        "Loesung": [
                            {
                                "ID": "FALTBKG4__A3_AW1",
                                "Bezeichnung": "Edge protection system XP"
                            },
                            {
                                "ID": "FALTBKG4__A3_AW2",
                                "Bezeichnung": "Handrail posts T"
                            }
                        ]
                    },
                    {
                        "ID": "FALTBKG4__A4",
                        "Bezeichnung": "Closure",
                        "Loesung": [
                            {
                                "ID": "FALTBKG4__A4_AW1",
                                "Bezeichnung": "Closure platform 3.00m"
                            },
                            {
                                "ID": "FALTBKG4__A4_AW2",
                                "Bezeichnung": "Planks by site"
                            },
                            {
                                "ID": "FALTBKG4__A4_AW3",
                                "Bezeichnung": "Platforms of single brackets"
                            }
                        ]
                    },
                    {
                        "ID": "FALTBKG4__A5",
                        "Bezeichnung": "Corners",
                        "Loesung": [
                            {
                                "ID": "FALTBKG4__A5_AW1",
                                "Bezeichnung": "Folding platform K outside corner"
                            },
                            {
                                "ID": "FALTBKG4__A5_AW2",
                                "Bezeichnung": "Folding platform with closure platform"
                            },
                            {
                                "ID": "FALTBKG4__A5_AW3",
                                "Bezeichnung": "Folding platform with planks by site"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "SEITENXP",
                "Schalungsthema": [
                    {
                        "ID": "SEITENXP_A1",
                        "Bezeichnung": "Height of handrail post",
                        "Loesung": [
                            {
                                "ID": "SEITENXP_A1_AW1",
                                "Bezeichnung": "Handrail post 1.20 m"
                            },
                            {
                                "ID": "SEITENXP_A1_AW2",
                                "Bezeichnung": "Handrail post 1.80 m (1.20+0.60)"
                            }
                        ]
                    },
                    {
                        "ID": "SEITENXP_A2",
                        "Bezeichnung": "Fixing",
                        "Loesung": [
                            {
                                "ID": "SEITENXP_A2_AW1",
                                "Bezeichnung": "Railing clamp XP 40 cm"
                            },
                            {
                                "ID": "SEITENXP_A2_AW2",
                                "Bezeichnung": "Railing clamp XP 85 cm"
                            },
                            {
                                "ID": "SEITENXP_A2_AW3",
                                "Bezeichnung": "Screw-on shoe XP"
                            },
                            {
                                "ID": "SEITENXP_A2_AW4",
                                "Bezeichnung": "Handrail-post shoe XP"
                            }
                        ]
                    },
                    {
                        "ID": "SEITENXP_A3",
                        "Bezeichnung": "Barrier",
                        "Loesung": [
                            {
                                "ID": "SEITENXP_A3_AW1",
                                "Bezeichnung": "Protective barrier XP"
                            },
                            {
                                "ID": "SEITENXP_A3_AW2",
                                "Bezeichnung": "Handrail boards (5x20cm)"
                            },
                            {
                                "ID": "SEITENXP_A3_AW3",
                                "Bezeichnung": "Scaffold tube"
                            },
                            {
                                "ID": "SEITENXP_A3_AW4",
                                "Bezeichnung": "Full planking"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBELSTZFRA",
                "Schalungsthema": [
                    {
                        "ID": "ZBELSTZFRA__A1",
                        "Bezeichnung": "Panel bracing Framax",
                        "Loesung": [
                            {
                                "ID": "ZBELSTZFRA__A1_AW1",
                                "Bezeichnung": "Panel bracing with Eurex 60 550"
                            },
                            {
                                "ID": "ZBELSTZFRA__A1_AW2",
                                "Bezeichnung": "Panel bracing with pipe brace"
                            },
                            {
                                "ID": "ZBELSTZFRA__A1_AW3",
                                "Bezeichnung": "Panel bracing for large panels"
                            }
                        ]
                    },
                    {
                        "ID": "ZBELSTZFRA__A3",
                        "Bezeichnung": "Stacking",
                        "Loesung": [
                            {
                                "ID": "ZBELSTZFRA__A3_AW1",
                                "Bezeichnung": "stacked upright"
                            },
                            {
                                "ID": "ZBELSTZFRA__A3_AW2",
                                "Bezeichnung": "stacked horiz."
                            }
                        ]
                    },
                    {
                        "ID": "ZBELSTZFRA__A2",
                        "Bezeichnung": "Basic element 3.30 m preferred Yes/No",
                        "Loesung": [
                            {
                                "ID": "ZBELSTZFRA__A2_AW2",
                                "Bezeichnung": "not preferred"
                            },
                            {
                                "ID": "ZBELSTZFRA__A2_AW1",
                                "Bezeichnung": "preferred"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBBETBUFRA",
                "Schalungsthema": [
                    {
                        "ID": "ZBBETBUFRA__A1",
                        "Bezeichnung": "Pouring platform or bracket",
                        "Loesung": [
                            {
                                "ID": "ZBBETBUFRA__A1_AW1",
                                "Bezeichnung": "Pouring platform"
                            },
                            {
                                "ID": "ZBBETBUFRA__A1_AW2",
                                "Bezeichnung": "Framax bracket 90 (planks by site)"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBKONSOL90",
                "Schalungsthema": [
                    {
                        "ID": "ZBKONSOL90__A1",
                        "Bezeichnung": "Framax bracket 90",
                        "Loesung": [
                            {
                                "ID": "ZBKONSOL90__A1_AW2",
                                "Bezeichnung": "Framax bracket 90 fixed in frame profile"
                            },
                            {
                                "ID": "ZBKONSOL90__A1_AW1",
                                "Bezeichnung": "Framax bracket 90 fixed in cross profile"
                            },
                            {
                                "ID": "ZBKONSOL90__A1_AW3",
                                "Bezeichnung": "Framax bracket 90 fixed in cross profile element horiz."
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBGEGENXPFR",
                "Schalungsthema": [
                    {
                        "ID": "ZBGEGENXPFR__A1",
                        "Bezeichnung": "Counter railing - edge protection XP",
                        "Loesung": [
                            {
                                "ID": "ZBGEGENXPFR__A1_AW2",
                                "Bezeichnung": "with handrail boards 2.4x15"
                            },
                            {
                                "ID": "ZBGEGENXPFR__A1_AW3",
                                "Bezeichnung": "with handrail boards 3x15"
                            },
                            {
                                "ID": "ZBGEGENXPFR__A1_AW4",
                                "Bezeichnung": "with handrail boards 4x15"
                            },
                            {
                                "ID": "ZBGEGENXPFR__A1_AW1",
                                "Bezeichnung": "with protective barrier XP"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBGEGENSGFR",
                "Schalungsthema": [
                    {
                        "ID": "ZBGEGENSGFR__A1",
                        "Bezeichnung": "Counter railing - handrail post 1.10m",
                        "Loesung": [
                            {
                                "ID": "ZBGEGENSGFR__A1_AW2",
                                "Bezeichnung": "up to 4m - with handrail boards 3x15"
                            },
                            {
                                "ID": "ZBGEGENSGFR__A1_AW3",
                                "Bezeichnung": "up to 4m - with handrail boards 4x15"
                            },
                            {
                                "ID": "ZBGEGENSGFR__A1_AW4",
                                "Bezeichnung": "up to 4m - with handrail boards 3x20"
                            },
                            {
                                "ID": "ZBGEGENSGFR__A1_AW1",
                                "Bezeichnung": "up to 4m - with scaffold tube 48.4mm"
                            },
                            {
                                "ID": "ZBGEGENSGFR__A1_AW6",
                                "Bezeichnung": "4m-10m - with handrail boards 3x15"
                            },
                            {
                                "ID": "ZBGEGENSGFR__A1_AW7",
                                "Bezeichnung": "4m-10m - with handrail boards 4x15"
                            },
                            {
                                "ID": "ZBGEGENSGFR__A1_AW8",
                                "Bezeichnung": "4m-10m - with handrail boards 3x20"
                            },
                            {
                                "ID": "ZBGEGENSGFR__A1_AW5",
                                "Bezeichnung": "4m-10m - with scaffold tube 48.4mm"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBBETBOFRA",
                "Schalungsthema": [
                    {
                        "ID": "ZBBETBOFRA__A1",
                        "Bezeichnung": "Pouring platform or bracket",
                        "Loesung": [
                            {
                                "ID": "ZBBETBOFRA__A1_AW1",
                                "Bezeichnung": "Pouring platform"
                            },
                            {
                                "ID": "ZBBETBOFRA__A1_AW2",
                                "Bezeichnung": "Framax bracket 90 (planks by site)"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBRA-ABSTW",
                "Schalungsthema": [
                    {
                        "ID": "ZBRA-ABSTW__A1",
                        "Bezeichnung": "Supporting construction",
                        "Loesung": [
                            {
                                "ID": "ZBRA-ABSTW__A1_AW1",
                                "Bezeichnung": "Supporting construction"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZbKon90-Al",
                "Schalungsthema": [
                    {
                        "ID": "ZbKon90-Al__A1",
                        "Bezeichnung": "Framax bracket 90",
                        "Loesung": [
                            {
                                "ID": "ZbKon90-Al__A1_AW1",
                                "Bezeichnung": "Framax bracket 90 fixed in cross profile"
                            },
                            {
                                "ID": "ZbKon90-Al__A1_AW2",
                                "Bezeichnung": "Framax bracket 90 fixed in cross profile element horiz."
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBELSFRA-S",
                "Schalungsthema": [
                    {
                        "ID": "ZBELSFRA-S__A1",
                        "Bezeichnung": "Panel bracing Framax S",
                        "Loesung": [
                            {
                                "ID": "ZBELSFRA-S__A1_AW5",
                                "Bezeichnung": "Panel strut 340"
                            },
                            {
                                "ID": "ZBELSFRA-S__A1_AW6",
                                "Bezeichnung": "Panel strut 540"
                            },
                            {
                                "ID": "ZBELSFRA-S__A1_AW7",
                                "Bezeichnung": "Pipe brace 12'-0''-21'-0''"
                            },
                            {
                                "ID": "ZBELSFRA-S__A1_AW1",
                                "Bezeichnung": "Panel strut 540 with pipe brace 22'-0''-40'-0''"
                            },
                            {
                                "ID": "ZBELSFRA-S__A1_AW2",
                                "Bezeichnung": "Panel strut 540 with Eurex 60 550"
                            },
                            {
                                "ID": "ZBELSFRA-S__A1_AW3",
                                "Bezeichnung": "Pipe brace 12'-0''-21'-0'' with pipe brace 22'-0''-40'-0''"
                            },
                            {
                                "ID": "ZBELSFRA-S__A1_AW4",
                                "Bezeichnung": "Pipe brace 12'-0''-21'-0'' with Eurex 60 550"
                            }
                        ]
                    },
                    {
                        "ID": "ZBELSFRA-S__A2",
                        "Bezeichnung": "Panel type",
                        "Loesung": [
                            {
                                "ID": "ZBELSFRA-S__A2_AW2",
                                "Bezeichnung": "Standard panel (1.35,0.90,0.60,0.55,0.45,0.30 wide)"
                            },
                            {
                                "ID": "ZBELSFRA-S__A2_AW1",
                                "Bezeichnung": "Large panel (2.40,2.70 wide)"
                            }
                        ]
                    },
                    {
                        "ID": "ZBELSFRA-S__A3",
                        "Bezeichnung": "Upright or horiz. stacking of panels",
                        "Loesung": [
                            {
                                "ID": "ZBELSFRA-S__A3_AW1",
                                "Bezeichnung": "stacked upright"
                            },
                            {
                                "ID": "ZBELSFRA-S__A3_AW2",
                                "Bezeichnung": "stacked horiz."
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBELSTZ-FF",
                "Schalungsthema": [
                    {
                        "ID": "ZBELSTZ-FF__A2",
                        "Bezeichnung": "Panel height",
                        "Loesung": [
                            {
                                "ID": "ZBELSTZ-FF__A2_AW1",
                                "Bezeichnung": "with 3.75 panel"
                            },
                            {
                                "ID": "ZBELSTZ-FF__A2_AW2",
                                "Bezeichnung": "w/o 3.75 panel"
                            }
                        ]
                    },
                    {
                        "ID": "ZBELSTZ-FF__A1",
                        "Bezeichnung": "Panel bracing FF20",
                        "Loesung": [
                            {
                                "ID": "ZBELSTZ-FF__A1_AW1",
                                "Bezeichnung": "Panel bracing for FF20 with Eurex 60 550"
                            },
                            {
                                "ID": "ZBELSTZ-FF__A1_AW2",
                                "Bezeichnung": "Panel bracing for FF20 with pipe brace"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBBETBU-FF",
                "Schalungsthema": [
                    {
                        "ID": "ZBBETBU-FF__A1",
                        "Bezeichnung": "Pouring platform or bracket",
                        "Loesung": [
                            {
                                "ID": "ZBBETBU-FF__A1_AW1",
                                "Bezeichnung": "Pouring platform"
                            },
                            {
                                "ID": "ZBBETBU-FF__A1_AW2",
                                "Bezeichnung": "Bracket 90 (planks by site)"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBKON90-FF",
                "Schalungsthema": [
                    {
                        "ID": "ZBKON90-FF__A1",
                        "Bezeichnung": "FF20 Universal bracket 90",
                        "Loesung": [
                            {
                                "ID": "ZBKON90-FF__A1_AW1",
                                "Bezeichnung": "FF20 Universal bracket 90"
                            },
                            {
                                "ID": "ZBKON90-FF__A1_AW2",
                                "Bezeichnung": "FF20 Universal bracket 90 stacking element"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBKONL-FF",
                "Schalungsthema": [
                    {
                        "ID": "ZBKonL-FF__A1",
                        "Bezeichnung": "FF20 Top scaffold bracket L",
                        "Loesung": [
                            {
                                "ID": "ZBKonL-FF__A1_AW1",
                                "Bezeichnung": "FF20 Top scaffold bracket L"
                            },
                            {
                                "ID": "ZBKonL-FF__A1_AW2",
                                "Bezeichnung": "FF20 Top scaffold bracket L stacking element"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBGEGENXPT",
                "Schalungsthema": [
                    {
                        "ID": "ZBGEGENXPT__A1",
                        "Bezeichnung": "Counter railing - edge protection XP",
                        "Loesung": [
                            {
                                "ID": "ZBGEGENXPT__A1_AW2",
                                "Bezeichnung": "with handrail boards 2.4x15"
                            },
                            {
                                "ID": "ZBGEGENXPT__A1_AW3",
                                "Bezeichnung": "with handrail boards 3x15"
                            },
                            {
                                "ID": "ZBGEGENXPT__A1_AW4",
                                "Bezeichnung": "with handrail boards 4x15"
                            },
                            {
                                "ID": "ZBGEGENXPT__A1_AW1",
                                "Bezeichnung": "with protective barrier XP"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBBETBO-FF",
                "Schalungsthema": [
                    {
                        "ID": "ZBBETBO-FF__A1",
                        "Bezeichnung": "Pouring platform or bracket",
                        "Loesung": [
                            {
                                "ID": "ZBBETBO-FF__A1_AW1",
                                "Bezeichnung": "Pouring platform"
                            },
                            {
                                "ID": "ZBBETBO-FF__A1_AW2",
                                "Bezeichnung": "Bracket 90 (planks by site)"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBELFF100",
                "Schalungsthema": [
                    {
                        "ID": "ZBELFF100__A1",
                        "Bezeichnung": "Panel bracing",
                        "Loesung": [
                            {
                                "ID": "ZBELFF100__A1_AW2",
                                "Bezeichnung": "Panel bracing platform lower"
                            },
                            {
                                "ID": "ZBELFF100__A1_AW1",
                                "Bezeichnung": "Panel bracing platform top"
                            }
                        ]
                    },
                    {
                        "ID": "ZBELFF100__A2",
                        "Bezeichnung": "Panel",
                        "Loesung": [
                            {
                                "ID": "ZBELFF100__A2_AW1",
                                "Bezeichnung": "Panel normal upright"
                            },
                            {
                                "ID": "ZBELFF100__A2_AW2",
                                "Bezeichnung": "Panel rotated"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBKONFF100",
                "Schalungsthema": [
                    {
                        "ID": "ZBKONFF100__A1",
                        "Bezeichnung": "FF100 universal bracket 90",
                        "Loesung": [
                            {
                                "ID": "ZBKONFF100__A1_AW2",
                                "Bezeichnung": "FF100 universal bracket 90 lower"
                            },
                            {
                                "ID": "ZBKONFF100__A1_AW1",
                                "Bezeichnung": "FF100 universal bracket 90 top"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBJUSTSPIN",
                "Schalungsthema": [
                    {
                        "ID": "ZBJUSTSPIN__A1",
                        "Bezeichnung": "Plumbing accessories",
                        "Loesung": [
                            {
                                "ID": "ZBJUSTSPIN__A1_AW1",
                                "Bezeichnung": "Frami plumbing strut 260"
                            },
                            {
                                "ID": "ZBJUSTSPIN__A1_AW2",
                                "Bezeichnung": "Panel strut 340"
                            },
                            {
                                "ID": "ZBJUSTSPIN__A1_AW3",
                                "Bezeichnung": "Panel strut 540"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBKONSOL60",
                "Schalungsthema": [
                    {
                        "ID": "ZBKONSOL60__A1",
                        "Bezeichnung": "Frami bracket 60",
                        "Loesung": [
                            {
                                "ID": "ZBKONSOL60__A1_AW1",
                                "Bezeichnung": "Frami bracket 60 fixed in frame profile"
                            },
                            {
                                "ID": "ZBKONSOL60__A1_AW2",
                                "Bezeichnung": "Frami bracket 60 fixed in cross profile"
                            },
                            {
                                "ID": "ZBKONSOL60__A1_AW3",
                                "Bezeichnung": "Frami bracket 60 fixed in cross profile element horiz."
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBSTANDKON",
                "Schalungsthema": [
                    {
                        "ID": "ZBSTANDKON__A1",
                        "Bezeichnung": "Frami adjustment frame",
                        "Loesung": [
                            {
                                "ID": "ZBSTANDKON__A1_AW1",
                                "Bezeichnung": "Frami adjustment frame"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBGEGENXPFI",
                "Schalungsthema": [
                    {
                        "ID": "ZBGEGENXPFI__A1",
                        "Bezeichnung": "Counter railing - edge protection XP",
                        "Loesung": [
                            {
                                "ID": "ZBGEGENXPFI__A1_AW2",
                                "Bezeichnung": "with handrail boards 2.4x15"
                            },
                            {
                                "ID": "ZBGEGENXPFI__A1_AW3",
                                "Bezeichnung": "with handrail boards 3x15"
                            },
                            {
                                "ID": "ZBGEGENXPFI__A1_AW4",
                                "Bezeichnung": "with handrail boards 4x15"
                            },
                            {
                                "ID": "ZBGEGENXPFI__A1_AW1",
                                "Bezeichnung": "with protective barrier XP"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBRA-ABSTWI",
                "Schalungsthema": [
                    {
                        "ID": "ZBRA-ABSTWI__A1",
                        "Bezeichnung": "Supporting construction",
                        "Loesung": [
                            {
                                "ID": "ZBRA-ABSTWI__A1_AW1",
                                "Bezeichnung": "Supporting construction"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBJUSTSP-S",
                "Schalungsthema": [
                    {
                        "ID": "ZBJUSTSP-S__A1",
                        "Bezeichnung": "Straight infill wall",
                        "Loesung": [
                            {
                                "ID": "ZBJUSTSP-S__A1_AW1",
                                "Bezeichnung": "stacked upright"
                            },
                            {
                                "ID": "ZBJUSTSP-S__A1_AW2",
                                "Bezeichnung": "stacked horiz."
                            },
                            {
                                "ID": "ZBJUSTSP-S__A1_AW3",
                                "Bezeichnung": "Stacked upright and horiz."
                            }
                        ]
                    },
                    {
                        "ID": "ZBJUSTSP-S__A2",
                        "Bezeichnung": "Fixing the struts on the formwork (from height 12')",
                        "Loesung": [
                            {
                                "ID": "ZBJUSTSP-S__A2_AW1",
                                "Bezeichnung": "Prop head"
                            },
                            {
                                "ID": "ZBJUSTSP-S__A2_AW2",
                                "Bezeichnung": "Connection profile"
                            }
                        ]
                    },
                    {
                        "ID": "ZBJUSTSP-S__A3",
                        "Bezeichnung": "Prop selection",
                        "Loesung": [
                            {
                                "ID": "ZBJUSTSP-S__A3_AW1",
                                "Bezeichnung": "Height 6'-18': strut 340 or 540"
                            },
                            {
                                "ID": "ZBJUSTSP-S__A3_AW2",
                                "Bezeichnung": "Height 6'-18': pipe brace 12'-21'"
                            },
                            {
                                "ID": "ZBJUSTSP-S__A3_AW3",
                                "Bezeichnung": "Height 21'-24': strut 340 + Eurex 60 550"
                            },
                            {
                                "ID": "ZBJUSTSP-S__A3_AW4",
                                "Bezeichnung": "Height 21'-24': strut 340 + pipe brace 22'-40'"
                            },
                            {
                                "ID": "ZBJUSTSP-S__A3_AW5",
                                "Bezeichnung": "Height 21'-24': strut 540 + Eurex 60 550"
                            },
                            {
                                "ID": "ZBJUSTSP-S__A3_AW6",
                                "Bezeichnung": "Height 27': strut 540 + pipe brace 22'-40'"
                            },
                            {
                                "ID": "ZBJUSTSP-S__A3_AW7",
                                "Bezeichnung": "Height 27': pipe brace 12'-21' + Eurex 60 550"
                            },
                            {
                                "ID": "ZBJUSTSP-S__A3_AW8",
                                "Bezeichnung": "Height 27': pipe brace 12'-21' + pipe brace 22'-40'"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBKONS60-S",
                "Schalungsthema": [
                    {
                        "ID": "ZBKONS60-S__A1",
                        "Bezeichnung": "Frami S bracket 60",
                        "Loesung": [
                            {
                                "ID": "ZBKONS60-S__A1_AW1",
                                "Bezeichnung": "Frami S bracket 60 fixed in frame profile"
                            },
                            {
                                "ID": "ZBKONS60-S__A1_AW2",
                                "Bezeichnung": "Frami S bracket 60 fixed in cross profile"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBJUSPIXLI",
                "Schalungsthema": [
                    {
                        "ID": "ZBJUSPIXLI__A1",
                        "Bezeichnung": "Plumbing accessories",
                        "Loesung": [
                            {
                                "ID": "ZBJUSPIXLI__A1_AW1",
                                "Bezeichnung": "Plumbing strut 260"
                            },
                            {
                                "ID": "ZBJUSPIXLI__A1_AW2",
                                "Bezeichnung": "Panel strut 340"
                            },
                            {
                                "ID": "ZBJUSPIXLI__A1_AW3",
                                "Bezeichnung": "Panel strut 540"
                            }
                        ]
                    },
                    {
                        "ID": "ZBJUSPIXLI__A3",
                        "Bezeichnung": "Basic panel height",
                        "Loesung": [
                            {
                                "ID": "ZBJUSPIXLI__A3_AW1",
                                "Bezeichnung": "1.50m"
                            },
                            {
                                "ID": "ZBJUSPIXLI__A3_AW2",
                                "Bezeichnung": "3.00m"
                            },
                            {
                                "ID": "ZBJUSPIXLI__A3_AW3",
                                "Bezeichnung": "1.00m"
                            }
                        ]
                    },
                    {
                        "ID": "ZBJUSPIXLI__A2",
                        "Bezeichnung": "Stacking",
                        "Loesung": [
                            {
                                "ID": "ZBJUSPIXLI__A2_AW1",
                                "Bezeichnung": "w/o stacking"
                            },
                            {
                                "ID": "ZBJUSPIXLI__A2_AW2",
                                "Bezeichnung": "stacked horiz. or upright"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBKON60XLI",
                "Schalungsthema": [
                    {
                        "ID": "ZBKON60XLI__A1",
                        "Bezeichnung": "DokaXlight bracket 60",
                        "Loesung": [
                            {
                                "ID": "ZBKON60XLI__A1_AW1",
                                "Bezeichnung": "DokaXlight bracket 60"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBRA-ABSTWX",
                "Schalungsthema": [
                    {
                        "ID": "ZBRA-ABSTWX__A1",
                        "Bezeichnung": "Supporting construction",
                        "Loesung": [
                            {
                                "ID": "ZBRA-ABSTWX__A1_AW1",
                                "Bezeichnung": "Supporting construction"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBGEGENXPDX",
                "Schalungsthema": [
                    {
                        "ID": "ZBGEGENXPDX__A1",
                        "Bezeichnung": "Counter railing - edge protection XP",
                        "Loesung": [
                            {
                                "ID": "ZBGEGENXPDX__A1_AW1",
                                "Bezeichnung": "with protective barrier XP"
                            },
                            {
                                "ID": "ZBGEGENXPDX__A1_AW2",
                                "Bezeichnung": "with handrail boards 2.4x15"
                            },
                            {
                                "ID": "ZBGEGENXPDX__A1_AW3",
                                "Bezeichnung": "with handrail boards 3x15"
                            },
                            {
                                "ID": "ZBGEGENXPDX__A1_AW4",
                                "Bezeichnung": "with handrail boards 4x15"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBELSTZFINI",
                "Schalungsthema": [
                    {
                        "ID": "ZBELSTZFINI__A1",
                        "Bezeichnung": "Panel strut",
                        "Loesung": [
                            {
                                "ID": "ZBELSTZFINI__A1_AW1",
                                "Bezeichnung": "Plumbing strut 260"
                            },
                            {
                                "ID": "ZBELSTZFINI__A1_AW2",
                                "Bezeichnung": "Panel strut 340"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBKONFINI",
                "Schalungsthema": [
                    {
                        "ID": "ZBKONFINI__A1",
                        "Bezeichnung": "Framini bracket 60",
                        "Loesung": [
                            {
                                "ID": "ZBKONFINI__A1_AW1",
                                "Bezeichnung": "Framini bracket 60 fixed in panel connection"
                            },
                            {
                                "ID": "ZBKONFINI__A1_AW2",
                                "Bezeichnung": "Framini bracket 60 fixed in frame profile"
                            },
                            {
                                "ID": "ZBKONFINI__A1_AW3",
                                "Bezeichnung": "Framini bracket 60 fixed in cross profile"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBELSTZREX",
                "Schalungsthema": [
                    {
                        "ID": "ZBELSTZREX__A1",
                        "Bezeichnung": "Basic panel height",
                        "Loesung": [
                            {
                                "ID": "ZBELSTZREX__A1_AW1",
                                "Bezeichnung": "3.00m"
                            },
                            {
                                "ID": "ZBELSTZREX__A1_AW2",
                                "Bezeichnung": "3.30m"
                            }
                        ]
                    },
                    {
                        "ID": "ZBELSTZREX__A2",
                        "Bezeichnung": "Stacking",
                        "Loesung": [
                            {
                                "ID": "ZBELSTZREX__A2_AW1",
                                "Bezeichnung": "stacked upright"
                            },
                            {
                                "ID": "ZBELSTZREX__A2_AW2",
                                "Bezeichnung": "stacked horiz."
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBKONREFOR",
                "Schalungsthema": [
                    {
                        "ID": "ZBKONREFOR__A1",
                        "Bezeichnung": "ReForma bracket",
                        "Loesung": [
                            {
                                "ID": "ZBKONREFOR__A1_AW2",
                                "Bezeichnung": "ReForma bracket fixed in frame profile"
                            },
                            {
                                "ID": "ZBKONREFOR__A1_AW1",
                                "Bezeichnung": "ReForma bracket fixed in cross profile"
                            },
                            {
                                "ID": "ZBKONREFOR__A1_AW3",
                                "Bezeichnung": "ReForma bracket fixed in cross profile element horiz."
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBELSTZREF",
                "Schalungsthema": [
                    {
                        "ID": "ZBELSTZREF__A1",
                        "Bezeichnung": "Basic panel height",
                        "Loesung": [
                            {
                                "ID": "ZBELSTZREF__A1_AW1",
                                "Bezeichnung": "3.00m"
                            },
                            {
                                "ID": "ZBELSTZREF__A1_AW2",
                                "Bezeichnung": "3.30m"
                            },
                            {
                                "ID": "ZBELSTZREF__A1_AW3",
                                "Bezeichnung": "2.40m"
                            }
                        ]
                    },
                    {
                        "ID": "ZBELSTZREF__A2",
                        "Bezeichnung": "Stacking",
                        "Loesung": [
                            {
                                "ID": "ZBELSTZREF__A2_AW1",
                                "Bezeichnung": "stacked upright"
                            },
                            {
                                "ID": "ZBELSTZREF__A2_AW2",
                                "Bezeichnung": "stacked horiz."
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBELSTZ-FO",
                "Schalungsthema": [
                    {
                        "ID": "ZBELSTZ-FO__A1",
                        "Bezeichnung": "Panel bracing Frameco",
                        "Loesung": [
                            {
                                "ID": "ZBELSTZ-FO__A1_AW1",
                                "Bezeichnung": "Panel bracing Frameco"
                            }
                        ]
                    }
                ]
            },
            {
                "Hersteller": "DOKA",
                "ID": "ZBFRAMECO",
                "Schalungsthema": [
                    {
                        "ID": "ZBFRAMECO__A1",
                        "Bezeichnung": "ZBFRAMECO__A1",
                        "Loesung": [
                            {
                                "ID": "ZBFRAMECO__A1_AW1",
                                "Bezeichnung": "ZBFRAMECO__A1_AW1"
                            }
                        ]
                    }
                ]
            }
        ]
    }
}`
