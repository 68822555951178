import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class PrimaryMenuService {
  public sidebarShow = false
  public immediateCollapse = false

  public open(): void {
    this.sidebarShow = true
  }

  public close(immediate: boolean = false): void {
    this.sidebarShow = false

    this.immediateCollapse = immediate
    if (this.immediateCollapse) {
      setTimeout(() => {
        this.immediateCollapse = false
      }, 1000)
    }
  }
}
