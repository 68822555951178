<ion-footer class="ion-no-border">
  <ion-toolbar class="footer-toolbar">
    <div class="d-flex">
      <ion-buttons class="d-flex flex-1 mand-btn-container justify-start">
        <!-- start button -->
        <ng-content select="[button-start]"></ng-content>
      </ion-buttons>
      <div class="hint-status-indicators d-flex flex-4 justify-center">
        <!-- status indicators -->
        <ng-content select="[status-indicators]"></ng-content>
      </div>
      <ion-buttons slot="end" class="d-flex mand-btn-container flex-1 justify-end">
        <!-- end button-->
        <ng-content select="[button-end]"></ng-content>
      </ion-buttons>
    </div>
  </ion-toolbar>
</ion-footer>
