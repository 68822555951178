import { Component, Input, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { ModalController } from '@ionic/angular'
import { Router } from '@angular/router'
import { PlanService } from '../../../services/plan.service'
import { AuthenticationService } from '../../../services/authentication.service'
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx'
import { Translation } from '../../../services/translation.service'
import { Device } from '@awesome-cordova-plugins/device/ngx'
import { LoadingSpinnerService } from '../../../services/loading-spinner.service'
import { EfpExportService } from '../../../services/share/efp-export.service'
import { Capacitor } from '@capacitor/core'
import { Plan } from '../../../models/plan'
import JSZip from 'jszip'
import { ErrorPopupService } from '../../../services/error-popup.service'
import { FeedbackClient, ZipDataEntry } from 'feedbackapiclient'
import { NgxCaptureService } from 'ngx-capture'
import { PlanResultProtocol } from '../../../models/planResultProtocol'
import UAParser from 'ua-parser-js'
import { ResultMessageService } from '../../../pages/result/services/result-message.service'
import { feedbackEnvironment } from '../../../../environments/environment'
import { FileService } from '../../../services/file.service'
import { MultiStepModalComponent } from '../multi-step-modal/multi-step-modal.component'

@Component({
  selector: 'efp-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent extends MultiStepModalComponent implements OnInit {
  @Input() screenshotData = ''

  constructor(
    private readonly captureService: NgxCaptureService,
    private readonly translate: Translation,
    private readonly device: Device,
    private readonly appVersion: AppVersion,
    private readonly loadingSpinner: LoadingSpinnerService,
    private readonly exportService: EfpExportService,
    private readonly authService: AuthenticationService,
    private readonly planService: PlanService,
    private readonly router: Router,
    private readonly errorPopupService: ErrorPopupService,
    private readonly messageService: ResultMessageService,
    private readonly fileService: FileService,
    modalCtrl: ModalController
  ) {
    super(modalCtrl)
  }
  public index = 0
  public feedbackSent = false
  public modalTitle = this.translate.translate('FEEDBACK.ALERT_TITLE')
  public isNative = Capacitor.isNativePlatform()
  public downloadScreenshotUrl?: string
  public screenshot?: Uint8Array
  public planData?: Blob
  public downloadPlan?: string
  public planName?: string
  public contextInformation?: string
  public userMail?: string
  public showMessageCheckbox = false
  private zipDataEntries: ZipDataEntry[] = []
  private resultMessageProtocol: PlanResultProtocol = { Errors: [], Warnings: [], Messages: [] }

  async ngOnInit(): Promise<void> {
    await this.loadingSpinner.doWithLoadingSpinner(async (load) => {
      this.getPlanWarningMessages()
      await this.getPlanData()
      await this.takeScreenshot()
      this.userMail = this.authService.mailAdress
      this.showMessageCheckbox = this.attachWarningsErrorsNecessary()
      await load.dismiss()
    })
  }

  public findModalTitle(): string {
    if (this.index === 0) {
      return this.translate.translate('FEEDBACK.ALERT_TITLE')
    } else if (this.feedbackSent)
      return this.translate.translate('FEEDBACK.ALERT_TITLE_MESSAGE_SENT')
    else return this.translate.translate('FEEDBACK.MAIL_TYPE_' + this.feedbackTypeControl.value)
  }

  get isFeedbackFormValid(): boolean {
    return this.feedbackForm.valid
  }

  get feedbackTypeControl(): AbstractControl {
    return this.feedbackForm.controls.feedbackType
  }

  public feedbackForm = new FormGroup({
    feedbackType: new FormControl('BUG', {
      validators: [Validators.required],
    }),
    feedbackMessage: new FormControl('', {
      validators: [Validators.required],
    }),
    attachScreenshot: new FormControl(true),
    attachPlanData: new FormControl(true),
    attachContextInformation: new FormControl(true),
    attachResultProtocol: new FormControl(true),
  })

  async onSubmit(): Promise<void> {
    await this.loadingSpinner.doWithLoadingSpinner(async (load) => {
      this.feedbackSent = await this.sendFeedback(load)
      if (this.feedbackSent !== true) {
        const errMessage = this.translate.translate('FEEDBACK.ERROR_SEND_FEEDBACK')
        await this.errorPopupService.showError(errMessage)
      }
    })
  }

  private async attachItems(): Promise<string> {
    let message =
      '<h2>' +
      this.translate.translate('FEEDBACK.MAIL_TYPE_' + this.feedbackForm.value.feedbackType) +
      '</h2>' +
      '<p>' +
      this.feedbackForm.value.feedbackMessage +
      '</p>'

    if (this.feedbackForm.value.attachContextInformation) {
      message +=
        '<i>This mail was sent from:</i> <p>' + (await this.getContextInformation()) + ' </p>'
    }

    if (this.feedbackForm.value.attachPlanData && this.planData && this.planName) {
      this.attachToZip(this.planName + '.efp', new Uint8Array(await this.planData.arrayBuffer()))
    }

    if (this.feedbackForm.value.attachScreenshot && this.screenshot && this.downloadScreenshotUrl) {
      this.attachToZip('screenshot.png', this.screenshot)
    }

    if (this.showMessageCheckbox && this.feedbackForm.value.attachResultProtocol) {
      this.attachToZip('resultProtocol.json', JSON.stringify(this.resultMessageProtocol))
    }
    return message
  }

  private async getContextInformation(): Promise<string> {
    if (Capacitor.isNativePlatform()) {
      return await this.addDeviceInfos()
    } else {
      const parser = new UAParser()
      const userAgent = navigator.userAgent
      const result = parser.setUA(userAgent).getResult()

      return (
        'Browser: ' +
        result.browser.name +
        ' ' +
        result.browser.version +
        ', Operating System: ' +
        result.os.name +
        '' +
        result.os.version
      )
    }
  }

  private async addDeviceInfos(): Promise<string> {
    return this.appVersion.getVersionNumber().then((appVersion) => {
      return (
        '<b>System: </b>' +
        this.device.platform +
        ' ' +
        this.device.version +
        '<br>' +
        '<b>Version: </b>' +
        appVersion +
        '<br>' +
        '<b>Device: </b>' +
        this.device.manufacturer +
        ' ' +
        this.device.model
      )
    })
  }

  private attachToZip(name: string, data: Uint8Array | string): void {
    const entry = new ZipDataEntry(name, data)
    this.zipDataEntries?.push(entry)
  }

  async sendFeedback(loadingElement: HTMLIonLoadingElement): Promise<boolean> {
    const message = await this.attachItems()

    //create empty file, feedback api needs an attachment
    if (!this.zipDataEntries) {
      this.attachToZip('empty.txt', 'no attachments included')
    }

    const token = this.authService.getAccessToken()
    if (!token) return false
    const client = new FeedbackClient(feedbackEnvironment, token)

    const zip = new JSZip()

    if (this.zipDataEntries) {
      this.zipDataEntries.forEach((entry) => {
        zip.file(entry.EntryName, entry.Data, { binary: true, compression: 'DEFLATE' })
      })

      const byteArray = await zip.generateAsync({ type: 'uint8array' })

      const success = await client.sendFeedbackAsync(message, 'EFP', byteArray)
      await loadingElement.dismiss()
      return success
    }
    return false
  }

  private getPlanWarningMessages(): void {
    this.messageService.warningMessages$.subscribe((messages) => {
      messages.forEach((message) => {
        this.resultMessageProtocol.Warnings.push(JSON.stringify(message))
      })
    })

    this.messageService.errorMessages$.subscribe((messages) => {
      messages.forEach((message) => {
        this.resultMessageProtocol.Errors.push(JSON.stringify(message))
      })
    })

    this.messageService.infoMessages$.subscribe((messages) => {
      messages.forEach((message) => {
        this.resultMessageProtocol.Messages.push(JSON.stringify(message))
      })
    })
  }

  private async getPlanData(): Promise<Plan | undefined> {
    const planId = +this.router.url.split('/')[2]

    if (planId) {
      const plan = await this.addPlanData(planId)
      this.planName = plan.name
      return plan
    }
    return undefined
  }

  private async addPlanData(planId: number): Promise<Plan> {
    const plan = await this.planService.findOne(planId)
    try {
      this.planData = await this.exportService.generateZip(plan)
      this.downloadPlan = window.URL.createObjectURL(this.planData)
    } catch (e: unknown) {
      console.error(e)
      throw Error('### Failed to generate zip file for feedback')
    }
    return plan
  }

  private async takeScreenshot(): Promise<void> {
    if (Capacitor.getPlatform() === 'ios') {
      this.convertBase64ToImage(this.screenshotData)
        .then((data) => {
          this.screenshot = data
          if (this.screenshot)
            this.downloadScreenshotUrl = window.URL.createObjectURL(new Blob([this.screenshot]))
        })
        .catch((e) => {
          console.error(e)
        })
    } else {
      const page = document.querySelector('ion-app > :nth-child(2)') as HTMLElement

      if (page) {
        this.captureService.getImage(page, true).subscribe((val) => {
          this.convertBase64ToImage(val)
            .then((data) => {
              this.screenshot = data
              if (this.screenshot)
                this.downloadScreenshotUrl = window.URL.createObjectURL(new Blob([this.screenshot]))
            })
            .catch((e) => {
              console.error(e)
            })
        })
      }
    }
  }

  private async convertBase64ToImage(resultPNG: string): Promise<Uint8Array | undefined> {
    const arr = resultPNG.split(',')

    if (arr.length > 0) {
      // The base64 string might not contain prefix data:image/png;base64,
      const bstr = atob(arr.length === 2 ? arr[1] : arr[0])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return u8arr
    } else {
      return undefined
    }
  }

  private attachWarningsErrorsNecessary(): boolean {
    const isOnResultPage = this.router.url.split('/')[1]
    if (isOnResultPage === 'result') {
      return (
        this.resultMessageProtocol.Errors.length > 0 ||
        this.resultMessageProtocol.Warnings.length > 0 ||
        this.resultMessageProtocol.Messages.length > 0
      )
    }
    return false
  }

  public async download(content: Blob, filename: string = 'download'): Promise<void> {
    if (this.screenshot) await this.fileService.shareOrDownloadBlobFile(content, filename, filename)
  }

  public async downloadScreenshot(): Promise<void> {
    if (this.screenshot) await this.download(new Blob([this.screenshot]), 'screenshot.png')
  }

  public async downloadPlanFile(): Promise<void> {
    if (this.planData) await this.download(this.planData, this.planName + '.efp')
  }
}
