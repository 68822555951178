import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { WidgetService } from '../../services/widget.service'

@Injectable({
  providedIn: 'root',
})
export class WidgetGuard {
  constructor(private readonly router: Router, private readonly widgetService: WidgetService) {}

  async canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (!this.widgetService.isOnWidgetHostname || this.widgetService.isOnWidgetPath) {
      return true
    } else {
      await this.router.navigate(['/widget'])
      return false
    }
  }
}
