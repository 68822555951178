import { AuthConfig } from 'angular-oauth2-oidc'
import { DeployEnvironment } from '../app/models/deploy-environment'
import { Environment } from 'feedbackapiclient'

export const environment = {
  deployment: DeployEnvironment.DEVELOPMENT,
  debugDrawing: false,
  backendUrl: 'https://efp-api.dev.doka.com',
  applicationReady: false,
  appInsightsConnectionString:
    // eslint-disable-next-line max-len
    'InstrumentationKey=c71796d3-72a3-4f34-9aad-31dd2bdbae79;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
}

export const authConfigWeb: AuthConfig = {
  issuer: 'https://login.test.doka.com',
  redirectUri: 'https://efp-web.dev.doka.com',
  strictDiscoveryDocumentValidation: false,
  clientId: '25f8db23-b492-43d8-8139-75be03310a99',
  responseType: 'code',
  scope: 'openid profile email offline_access efp.doka.com/legacyformwork dfds.doka.com/feedback',
  oidc: true,
  showDebugInformation: true,
}

export const authConfigACC: AuthConfig = {
  issuer: 'https://login.test.doka.com',
  redirectUri: 'https://efp-widget.dev.doka.com',
  strictDiscoveryDocumentValidation: false,
  clientId: '25f8db23-b492-43d8-8139-75be03310a99',
  responseType: 'code',
  scope: 'openid profile email offline_access efp.doka.com/legacyformwork dfds.doka.com/feedback',
  oidc: true,
  showDebugInformation: true,
}

export const authConfigNative: AuthConfig = {
  issuer: 'https://login.test.doka.com',
  redirectUri: 'com.doka.efp.dev://oauth',
  strictDiscoveryDocumentValidation: false,
  clientId: '2abc412c-a955-4542-a970-9beac0c01eaa',
  responseType: 'code',
  scope: 'openid profile email offline_access efp.doka.com/legacyformwork dfds.doka.com/feedback',
  oidc: true,
  showDebugInformation: true,
  logoutUrl: 'https://login.test.doka.com/connect/endsession',
}

export const accAuthConfig = {
  url: 'https://developer.api.autodesk.com/authentication/v2/authorize',
  client_id: '7KnfOpSm2nGKfFqdVFNlTjIvWFHmpIoyeM91XImdAGSOUw73',
  scope: 'data:read data:create data:write account:read openid bucket:create bucket:read',
}

export const feedbackEnvironment: string = Environment.dev
