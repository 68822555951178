import { Injectable, NgZone } from '@angular/core'
import { App } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'
import { ToastController } from '@ionic/angular'
import { AuthConfig, OAuthService, UserInfo } from 'angular-oauth2-oidc'
import { BehaviorSubject, firstValueFrom } from 'rxjs'
import { filter, take } from 'rxjs/operators'
import { authConfigACC, authConfigNative, authConfigWeb } from '../../environments/environment'
import { AuthenticationRepository } from '../repositories/authentication-repository.service'
import { Translation } from './translation.service'
import { UserRepository } from '../repositories/user.repository'
import { Router } from '@angular/router'
import { WidgetService } from './widget.service'

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public mailAdress = ''
  private authConfig: AuthConfig

  private initialized = new BehaviorSubject<boolean>(false)
  public readonly waitUntilInitialized = firstValueFrom(
    this.initialized.pipe(
      filter((x) => x),
      take(1)
    )
  )
  private readonly profileUrl: string
  private readonly signUpUrl: string

  constructor(
    private readonly router: Router,
    private oauthService: OAuthService,
    private zone: NgZone,
    private toastController: ToastController,
    private readonly translate: Translation,
    private readonly userRepository: UserRepository,
    private readonly authenticationRepository: AuthenticationRepository,
    private readonly widgetService: WidgetService
  ) {
    if (!Capacitor.isNativePlatform()) {
      this.authConfig = this.widgetService.isOnWidgetHostname ? authConfigACC : authConfigWeb
      this.authConfig.redirectUri = window.location.origin + '/login'
    } else {
      this.authConfig = authConfigNative
    }
    this.profileUrl = `${this.authConfig.issuer}/User`
    this.signUpUrl = `${this.authConfig.issuer}/Account/SignUp`
  }

  public async register(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      await Browser.open({ url: this.signUpUrl })
    } else if (this.widgetService.isOnWidgetHostname) {
      window.open(this.signUpUrl, '_blank')
    } else {
      location.href = this.signUpUrl
    }
  }

  public login(): void {
    if (this.isOAuthLoggedIn) {
      this.authenticationRepository.authenticated$.next(true)
      void this.router.navigate(['/homepage'])
    } else {
      this.oauthService.initLoginFlow()
    }
  }

  public async logoutAndRedirectToLogin(): Promise<void> {
    return this.authenticationRepository.logoutAndRedirectToLogin()
  }

  public async configureOauth2(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      this.authConfig.openUri = (url) => void Browser.open({ url })
      await this.configureAutoLogin()
      await this.configureAuthAppListener()
    } else {
      await this.configureAutoLogin()
    }
  }

  private async configureAutoLogin(): Promise<void> {
    this.oauthService.configure(this.authConfig)
    this.oauthService.timeoutFactor = 0.5
    this.oauthService.setupAutomaticSilentRefresh(undefined, 'access_token')

    try {
      await this.oauthService.loadDiscoveryDocumentAndTryLogin()

      if (!this.isOAuthLoggedIn && this.oauthService.getRefreshToken()?.length > 0) {
        await this.oauthService.refreshToken()
      }

      this.setMailAdress()
      await this.updateAuthentication(this.isOAuthLoggedIn)
    } catch (err: unknown) {
      console.error(err)
    }

    this.initialized.next(true)
  }

  private async configureAuthAppListener(): Promise<void> {
    await App.addListener('appUrlOpen', (data) => {
      void this.zone.run(async () => {
        // ios doesn't automatically close Browser after login
        if (Capacitor.getPlatform() !== 'android') {
          await Browser.close() // web & ios only
        }

        if (data.url.includes('?')) {
          await this.oauthService.tryLogin({
            customHashFragment: data.url.substring(data.url.indexOf('?')),
          })

          await this.updateAuthentication(this.isOAuthLoggedIn)
          this.setMailAdress()

          if (this.isOAuthLoggedIn) {
            const toast = await this.toastController.create({
              message: this.translate.translate('AUTH.LOGIN_SUCCESS'),
              position: 'bottom',
              duration: 2000,
              cssClass: 'ion-text-center',
              color: 'primary',
            })
            await toast.present()

            await this.router.navigate(['/homepage'])
          } else {
            await this.router.navigate(['/login'])
          }
          this.initialized.next(true)
        }
      })
    })
  }

  get isOAuthLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()
  }

  public getAccessToken(): string | null {
    return this.oauthService.getAccessToken()
  }

  public async isDokaUser(): Promise<boolean> {
    try {
      // method crashes if we have a trial user (no user - access token)
      const profile = (await this.oauthService.loadUserProfile()) as UserInfo
      const isDokaUser = profile.info.email.indexOf('@doka.com') !== -1

      return isDokaUser
    } catch {
      return false
    }
  }

  public async refreshToken(): Promise<void> {
    if (!this.oauthService.hasValidAccessToken()) {
      this.oauthService.timeoutFactor = 0.5
      this.oauthService.setupAutomaticSilentRefresh(undefined, 'access_token')
      await this.oauthService.refreshToken()
    }
  }

  public async openProfilManagement(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      await Browser.open({ url: this.profileUrl })
    } else {
      location.href = this.profileUrl
    }
  }

  private setMailAdress(): void {
    if (this.isOAuthLoggedIn) {
      void this.oauthService.loadUserProfile().then((val) => {
        const profile = val as UserInfo
        this.mailAdress = profile.info.email
      })
    }
  }

  public async updateAuthentication(isLoggedIn: boolean): Promise<void> {
    if (isLoggedIn || Capacitor.isNativePlatform()) {
      await this.userRepository.registerUser()
    }
    this.authenticationRepository.updateAuthentication(isLoggedIn)
  }

  public async getUserName(): Promise<string> {
    const userInfo = (await this.oauthService.loadUserProfile()) as UserInfo
    return userInfo.info.name
  }
}
