/* eslint-disable */

export const favouritesLanguageJSON = `{
    "translations": [
        {
            "ID": "CIRC_ALUF",
            "de": "Rundschalung Alu-Framax Xlife",
            "en-gb": "Circular formwork Alu-Framax Xlife",
            "en-us": "Circular formwork Alu-Framax Xlife",
            "es": "Encofrado circular Alu-Framax Xlife",
            "fr": "Coffrage courbe Alu-Framax Xlife",
            "it": "Cassero circolare Alu-Framax Xlife",
            "hu": "Körzsalu Alu-Framax Xlife",
            "ru": "Круглая опалубка Alu-Framax Xlife",
            "sk": "Kruhovom debnení Alu-Framax Xlife",
            "pl": "Deskowanie łukowe Alu-Framax Xlife"
        },
        {
            "ID": "CIRC_FRA",
            "de": "Rundschalung Framax Xlife",
            "en-gb": "Circular formwork Framax Xlife",
            "en-us": "Circular formwork Framax Xlife",
            "es": "Encofrado circular Framax Xlife",
            "fr": "Coffrage courbe Framax Xlife",
            "it": "Cassero circolare Framax Xlife",
            "hu": "Körzsalu Framax Xlife",
            "ru": "Круглая опалубка Framax Xlife",
            "sk": "Kruhovom debnení Framax Xlife",
            "pl": "Deskowanie łukowe Framax Xlife"
        },
        {
            "ID": "CIRC_H20",
            "de": "Rundschalung H20",
            "en-gb": "Circular formwork H20",
            "en-us": "Circular formwork H20",
            "es": "Encofrado circular H20",
            "fr": "Coffrage courbe H20",
            "it": "Cassero circolare H20",
            "hu": "Körzsalu H20",
            "ru": "Круглая опалубка H20",
            "sk": "Kruhovom debnení H20",
            "pl": "Deskowanie łukowe H20"
        },
        {
            "ID": "CIRCULAR",
            "de": "Rundschalung Frami Xlife",
            "en-gb": "Circular formwork Frami Xlife",
            "en-us": "Circular formwork Frami Xlife",
            "es": "Encofrado circular Frami Xlife",
            "fr": "Coffrage courbe Frami Xlife",
            "it": "Cassero circolare Frami Xlife",
            "hu": "Körzsalu Frami Xlife",
            "ru": "Круглая опалубка Frami Xlife",
            "sk": "Kruhovom debnení Frami Xlife",
            "pl": "Deskowanie łukowe Frami Xlife"
        },
        {
            "ID": "DOKADEK",
            "de": "Dokadek",
            "en-gb": "Dokadek",
            "en-us": "Dokadek",
            "es": "Dokadek",
            "fr": "Dokadek",
            "it": "Dokadek",
            "hu": "Dokadek",
            "ru": "Dokadek",
            "sk": "Dokadek",
            "pl": "Dokadek"
        },
        {
            "ID": "DOKASETF_A1",
            "de": "Seitengeländer",
            "en-gb": "Side railing",
            "en-us": "Side railing",
            "es": "Barandilla lateral",
            "fr": "Fermeture d'extrémité",
            "it": "Parapetto laterale",
            "hu": "Oldalkorlát",
            "ru": "Боковое ограждение",
            "sk": "Bocné zábradlie",
            "pl": "Poręcz boczna"
        },
        {
            "ID": "DOKASETF_A1_AW1",
            "de": "beidseitig geöffnet",
            "en-gb": "double-ended opened",
            "en-us": "double-ended opened",
            "es": "Doble abierto",
            "fr": "Deux faces ouvert",
            "it": "Aperto sui due lati",
            "hu": "mindkét oldalon nyitott",
            "ru": "открыто с двух сторон",
            "sk": "otvorené obojstranne",
            "pl": "otwór z obu końców"
        },
        {
            "ID": "DOKASETF_A1_AW2",
            "de": "links geöffnet",
            "en-gb": "left opened",
            "en-us": "left opened",
            "es": "Izquierda abierto",
            "fr": "Gauche ouvert",
            "it": "Aperto a sinistra",
            "hu": "bal oldalon nyitott",
            "ru": "открыто слева",
            "sk": "otvorené vľavo",
            "pl": "otwór po lewej stronie"
        },
        {
            "ID": "DOKASETF_A1_AW3",
            "de": "rechts geöffnet",
            "en-gb": "right opened",
            "en-us": "right opened",
            "es": "Derecho abierto",
            "fr": "Droite ouvert",
            "it": "Aperto a destra",
            "hu": "jobb oldalon nyitott",
            "ru": "открыто справа",
            "sk": "otvorené vpravo",
            "pl": "otwór po prawej stronie"
        },
        {
            "ID": "DOKASETF_A1_AW4",
            "de": "ohne Geländer",
            "en-gb": "without railing",
            "en-us": "without railing",
            "es": "Sin barandilla",
            "fr": "Sans garde-corps",
            "it": "senza parapetto",
            "hu": "korlát nélkül",
            "ru": "без ограждения",
            "sk": "bez zábradlia",
            "pl": "bez poręczy"
        },
        {
            "ID": "DOKASETF_A2",
            "de": "Eck-Ausführung",
            "en-gb": "Corner",
            "en-us": "Corner",
            "es": "Realización de esquina",
            "fr": "Config angle",
            "it": "Esecuzione angolare",
            "hu": "Sarokkialakítás",
            "ru": "Исполнение угла",
            "sk": "Vytvorenie rohu",
            "pl": "Rozszerzenie narożne"
        },
        {
            "ID": "DOKASETF_A2_AW1",
            "de": "Innenecke und Uni-Element",
            "en-gb": "Inside corner and universal panel",
            "en-us": "Inside corner and universal panel",
            "es": "Esquina int. y elem. univ.",
            "fr": "Angle intérieur et pann. univ.",
            "it": "Angolo interno ed elemento uni",
            "hu": "Belso sarok és uni elem",
            "ru": "Внутренний угол и унив.элементом",
            "sk": "Vnút. roh a ový univ.panel.",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna"
        },
        {
            "ID": "DOKASETF_A2_AW2",
            "de": "Innenecke und Außenecke",
            "en-gb": "Inside corner and outside corner",
            "en-us": "Inside corner and outside corner",
            "es": "Esqu. int. y esqu. ext",
            "fr": "Angle intérieur et angle extérieur",
            "it": "Angolo interno ed angolo esterno",
            "hu": "Belso sarok és Külso sarok",
            "ru": "Внутренний угол и внешний угол",
            "sk": "Vnút. roh a vonk. roh",
            "pl": "Narożnik wewnętrzny i narożnik zewnętrzny"
        },
        {
            "ID": "DOKASETF_A2_AW3",
            "de": "S-Innenecke und Universalelement",
            "en-gb": "Hinged inside corner and universal panel",
            "en-us": "Hinged inside corner and universal panel",
            "es": "Esqu. bisagra int. y elem. univ.",
            "fr": "Angle charnière int et pann. univ.",
            "it": "Angolo interno S ed elem. universale",
            "hu": "Csukl.bel.sarok és Uni elem",
            "ru": "Шарнирный внутренний угол и универсальный элемент",
            "sk": "Kĺb.vnút.roh a ový univ.panel.",
            "pl": "Narożnik wewnętrzny zawiasowy i płyta uniwersalna"
        },
        {
            "ID": "DOKASETF_A2_AW4",
            "de": "S-Innenecke und Außenecke",
            "en-gb": "HInged inside corner and outside corner",
            "en-us": "HInged inside corner and outside corner",
            "es": "Esqu. bisagra int. y esqu. ext",
            "fr": "Angle charnière int et angle extérieur",
            "it": "Angolo interno S ed angolo esterno",
            "hu": "Csukl.bel.sarok és Külso sarok",
            "ru": "Шарнирный внутренний угол и внешний угол",
            "sk": "Kĺb.vnút.roh a vonk. roh",
            "pl": "Narożnik wewnętrzny zawiasowy i narożnik zewnętrzny"
        },
        {
            "ID": "DOKASETF_A2_AW5",
            "de": "S-Innenecke innen und außen",
            "en-gb": "Hinged inside corner inside and outside",
            "en-us": "Hinged inside corner inside and outside",
            "es": "Esqu. bisagra int. int. y ext.",
            "fr": "Angle charnière int int et ext",
            "it": "Angolo interno S int. ed est.",
            "hu": "Csukl.bel.sarok belül és kívül",
            "ru": "Шарнирный внутренний угол изнутри и снаружи",
            "sk": "Kĺb.vnút.roh zvnút. a zvon.",
            "pl": "Narożnik wewnętrzny zawiasowy wewnątrz i na zewnątrz"
        },
        {
            "ID": "DOKASETF_A2_AW6",
            "de": "S-Innenecke und S-Außenecke",
            "en-gb": "Hinged inside and hinged outside corner",
            "en-us": "Hinged inside and hinged outside corner",
            "es": "Esqu. bisagra int. y esqu. bisagra ext.",
            "fr": "Angle charnière int et angle chanière ext",
            "it": "Angolo interno S ed angolo esterno S",
            "hu": "Csukl.bel.sarok és csukl.kül.sarok",
            "ru": "Шарнирный внутренний угол и шарнирный внешний угол",
            "sk": "Kĺb.vnút.roh a kĺb.vonkaj.roh",
            "pl": "Zawiasy wewnątrz + narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "DOKASETF_A3",
            "de": "Wandende",
            "en-gb": "Wall end",
            "en-us": "Wall end",
            "es": "Fin del muro",
            "fr": "Fin de mur",
            "it": "Fine del muro",
            "hu": "Falvég",
            "ru": "Конец стены",
            "sk": "Ukončenie steny",
            "pl": "Koniec ściany"
        },
        {
            "ID": "DOKASETF_A3_AW1",
            "de": "Dokaset F-Element 2,70",
            "en-gb": "Dokaset F panel 2.70",
            "en-us": "Dokaset F panel 2.70",
            "es": "Elemento marco Dokaset F 2,70",
            "fr": "Dokaset F Px 2,70",
            "it": "Elemento a telaio Dokaset F 2,70",
            "hu": "Dokaset F elem 2,70",
            "ru": "Dokaset F элемент 2,70",
            "sk": "Dokaset F element 2,70",
            "pl": "Element Dokaset F 2,70"
        },
        {
            "ID": "DOKASETF_A3_AW2",
            "de": "Dokaset F-Element 1,35",
            "en-gb": "Dokaset F panel 1.35",
            "en-us": "Dokaset F panel 1.35",
            "es": "Elemento marco Dokaset F 1,35",
            "fr": "Dokaset F Px 1,35",
            "it": "Elemento a telaio Dokaset F 1,35",
            "hu": "Dokaset F elem 1,35",
            "ru": "Dokaset F элемент 1,35",
            "sk": "Dokaset F element 1,35",
            "pl": "Element Dokaset F 1,35"
        },
        {
            "ID": "DOKASETF_A3_AW3",
            "de": "Klemmschiene, Universalverbinder",
            "en-gb": "Universal waling, universal fixing bolt",
            "en-us": "Universal waling, universal fixing bolt",
            "es": "Riel de fijación, conector univ",
            "fr": "Rail de blocage , boulon d'assemblage univ",
            "it": "Rotaia di fissaggio, Tirante universale",
            "hu": "Kötosín, Univerzális kötocsavar",
            "ru": "Зажимная шина, универсальный соединитель",
            "sk": "Upín.koľajnica, univerzálna spojka",
            "pl": "Szyna dociskowa, łącznik uniwersalny"
        },
        {
            "ID": "DOKASETF_A3_AW4",
            "de": "Klemmschiene, Stirnanker",
            "en-gb": "Universal waling, stop-end tie",
            "en-us": "Universal waling, stop-end tie",
            "es": "Riel de fijación, anclaje de tape",
            "fr": "Rail de blocage , ancrage d'about",
            "it": "Rotaia di fissaggio, Morsetto per testata",
            "hu": "Kötosín, Homlokanker",
            "ru": "Зажимная шина, торцевой анкер",
            "sk": "Upín.koľajnica, čelová kotva",
            "pl": "Szyna dociskowa, ściąg czołowy"
        },
        {
            "ID": "DOKASETF_A3_AW5",
            "de": "Außenecken, alle Elementbreiten",
            "en-gb": "Oustide corners, all panel widths",
            "en-us": "Oustide corners, all panel widths",
            "es": "Esqu. ext., todas anchos de elementos",
            "fr": "Angle ext, Ttes largeur de px",
            "it": "Angoli esterni, tutte le largh. elemento",
            "hu": "Külső sarok, összes elemszélesség",
            "ru": "Внешние углы, элементы любой ширины",
            "sk": "Vonkajšie rohy, všetky šírky panelov",
            "pl": "Narożniki zewnętrzne, wszystkie szerokości płyt"
        },
        {
            "ID": "DOKASETF_A3_AW6",
            "de": "Universal-Element 270 + Universalverbinder",
            "en-gb": "Universal panel 270 + universal fixing bolt",
            "en-us": "Universal panel 270 + universal fixing bolt",
            "es": "Elem. univ. 270 + conector univ.",
            "fr": "Pann. univ. 270 + boulon d'ass. univ",
            "it": "Elemento uni 270 + Tirante universale",
            "hu": "Uni elem 270 + Univerzális kötocsavar",
            "ru": "Универсальный элемент 270 + универсальный соединитель",
            "sk": "Univerzálne panel 270 + univerzálna spojka",
            "pl": "Płyta uniwersalna 270 + Łącznik uniwersalny"
        },
        {
            "ID": "DOKASETF_A3_AW7",
            "de": "Klemmschiene, Universalverbinder, Fugenband",
            "en-gb": "Universal waling, universal fixing bolt, joint strip",
            "en-us": "Universal waling, universal fixing bolt, joint strip",
            "es": "Riel de fijación Frami, conector univ,Junta de estanqu",
            "fr": "Rail de blocage , boulon d'ass. univ, joint dil",
            "it": "Rotaia di fissaggio, Tirante universale, Nastro giunti",
            "hu": "Kötosín, Univerzális kötocsavar, Fúgaszalag",
            "ru": "Зажимная шина, универсальный соединитель, лента для уплотнения швов",
            "sk": "Upín.koľajnica, univerzálna spojka, tesn.pás",
            "pl": "Uniwersalny rygiel ścienny z łącznikiem uniwersalnym, listwa"
        },
        {
            "ID": "DOKASETF_A3_AW8",
            "de": "Profilholz 270, Ausgleichspanner",
            "en-gb": "Moulded timber 270, adjustable clamp",
            "en-us": "Molded timber 270, adjustable clamp",
            "es": "Perfil d.madera 270, grapa de comp.",
            "fr": "Liteau profilé  270, tendeur de comp",
            "it": "Profilo in legno 270, Morsetto di compensazione",
            "hu": "Profilos fabetét 270, Kiegyenlíto kapcsoló",
            "ru": "Профильный брус 270, компенсационный зажим",
            "sk": "Drev.prof. 270, vyrovn. upínač",
            "pl": "Drewno profilowane 270, zacisk regulowany"
        },
        {
            "ID": "DOKASETF_A3_AW9",
            "de": "Profilholz 270, Fugenband, Ausgleichspanner",
            "en-gb": "Moulded timber 270, joint strip, adjustable clamp",
            "en-us": "Molded timber 270, joint strip, adjustable clamp",
            "es": "Perfil d.madera 270, junta de estanqu, grapa de comp",
            "fr": "Liteau profilé  270, joint dil, tendeur de comp",
            "it": "Profilo in legno 270, Nastro giunti, Morsetto di compensazione",
            "hu": "Profilos fabetét 270, Fúgaszalag, Kiegyenlíto kapcsoló",
            "ru": "Профильный брус 270, лента для уплотнения швов, компенсационный зажим",
            "sk": "Drev.prof. 270, tesn.pás, vyrovn. upínač",
            "pl": "Drewno profilowane 270, listwa, zacisk regulowany"
        },
        {
            "ID": "DOKASETF_A4",
            "de": "Ausgleich",
            "en-gb": "Closure",
            "en-us": "Filler",
            "es": "Compensación",
            "fr": "Compensation",
            "it": "Compensazione",
            "hu": "Kiegyenlítés",
            "ru": "Компенсация",
            "sk": "Vyrovnanie",
            "pl": "Wypełnienie"
        },
        {
            "ID": "DOKASETF_A4_AW1",
            "de": "Passholz Doka, Ankerung im Element",
            "en-gb": "Fitting timber Doka, ties through panel",
            "en-us": "Fitting timber Doka, ties through panel",
            "es": "Tablón de ajuste Doka, anclaje en el elemento",
            "fr": "Fourrure en bois Doka,  ancrage dans le panneau",
            "it": "Tavola di compensazione Doka, anc. nell'elemento",
            "hu": "Illeszto fabetét Doka, ankerozás az elemben",
            "ru": "Пригнанный брус Doka, анкерование в элементе",
            "sk": "Vyrovnávací hranol Doka, kotvenie v paneli",
            "pl": "Wstawka komp. Doka, ściagi przez płytę"
        },
        {
            "ID": "DOKASETF_A4_AW2",
            "de": "Passholz Doka, Ankerung im Ausgleich",
            "en-gb": "Fitting timber Doka, ties through closure",
            "en-us": "Fitting timber Doka, ties through filler",
            "es": "Tablón de ajuste Doka, anclaje en compensación",
            "fr": "Fourrure en bois Doka, ancrage dans le compens.",
            "it": "Tavola di compensazione Doka, anc. nella compens.",
            "hu": "Illeszto fabetét Doka, ankerozás a kiegyenlítésen keresztül",
            "ru": "Пригнанный брус Doka, анкерование в компенсатор",
            "sk": "Vyrovnávací hranol Doka, kotvenie vo vyrovnaní",
            "pl": "Wstawka komp. Doka, ściagi przez wstawkę"
        },
        {
            "ID": "DOKASETF_A4_AW3",
            "de": "Füllkörper bauseits, Ankerung im Element",
            "en-gb": "Filler block by site, ties through panel",
            "en-us": "Filler block by site, ties through panel",
            "es": "Cuerpo de relleno (por parte de obra), anclaje en el elemento",
            "fr": "Corps de remplissage (chantier), ancrage dans le panneau",
            "it": "Compensazione (a carico cant.), anc. nell'elemento",
            "hu": "Kiegészítő betét építéshelyi, ankerozás az elemben",
            "ru": "Вкладыш (силами заказчика), анкерование в элементе",
            "sk": "Výplň (zo strany stavby), kotvenie v paneli",
            "pl": "Wstawka po str. budowy, ściagi przez płytę"
        },
        {
            "ID": "DOKASETF_A4_AW4",
            "de": "Füllkörper bauseits, Ankerung im Ausgleich",
            "en-gb": "Filler block by site, ties through closure",
            "en-us": "Filler block by site, ties through filler",
            "es": "Cuerpo de relleno (por parte de obra), anclaje en compensación",
            "fr": "Corps de remplissage (chantier), ancrage dans le panneau",
            "it": "Compensazione (a carico cant.), anc. nella compens.",
            "hu": "Kiegészítő betét építéshelyi, ankerozás a kiegyenlítésen keresztül",
            "ru": "Вкладыш (силами заказчика), анкерование в компенсатор",
            "sk": "Výplň (zo strany stavby), kotvenie vo vyrovnaní",
            "pl": "Wstawka po str. budowy, ściagi przez wstawkę"
        },
        {
            "ID": "DOKASETF_A4_AW5",
            "de": "mit Schalungsplatte und Klemmschiene 0,90m",
            "en-gb": "with formwork sheet and universal waling 0.90m",
            "en-us": "with formwork sheet and universal waling 0.90m",
            "es": "con tablero de encofrado y riel de fijación 0,90m",
            "fr": "avec contreplaqué et rail de blocage 0,90m",
            "it": "con pannello e rotaia di fissaggio 0,90m",
            "hu": "zsaluhéjjal és 0,90m-es kötősínnel",
            "ru": "с опалубочная плита и зажимная шина 0,90м",
            "sk": "s debniacou doskou a upínacou koľajnicou 0,90m",
            "pl": "ze sklejką i uniwersalnym ryglem ściennym 0,90m"
        },
        {
            "ID": "DOKASETF_A4_AW6",
            "de": "mit Schalungsplatte und Klemmschiene 1,50m",
            "en-gb": "with formwork sheet and universal waling 1.50m",
            "en-us": "with formwork sheet and universal waling 1.50m",
            "es": "con tablero de encofrado y riel de fijación 1,50m",
            "fr": "avec contreplaqué et rail de blocage 1,50m",
            "it": "con pannello e rotaia di fissaggio 1,50m",
            "hu": "zsaluhéjjal és 1,50m-es kötősínnel",
            "ru": "с опалубочная плита и зажимная шина 1,50м",
            "sk": "s debniacou doskou a upínacou koľajnicou 1,50m",
            "pl": "ze sklejką i uniwersalnym ryglem ściennym 1,50m"
        },
        {
            "ID": "DOKASETF_A4_AW7",
            "de": "Alu-Ausgleich, Ankerung im Ausgleich",
            "en-gb": "Alu-filler, ties through closure",
            "en-us": "Alu-filler, ties through filler",
            "es": "Compensación de alu, anclaje en compensación",
            "fr": "Pièce de compensation alu, ancrage dans le compens.",
            "it": "Compensazioni in alu, anc. nella compens.",
            "hu": "Alu-betét, ankerozás a kiegyenlítésen keresztül",
            "ru": "Alu-компенсатор, анкерование в компенсатор",
            "sk": "Alu-vyrovnanie, kotvenie vo vyrovnaní",
            "pl": "Wstawka aluminiowa, ściagi przez wstawkę"
        },
        {
            "ID": "DOKASETF_A4_AW8",
            "de": "Alu-Ausgleich, Ankerung im Element",
            "en-gb": "Alu-filler, ties through panel",
            "en-us": "Alu-filler, ties through panel",
            "es": "Compensación de alu, anclaje en el elemento",
            "fr": "Pièce de compensation alu, ancrage dans le panneau",
            "it": "Compensazioni in alu, anc. nell'elemento",
            "hu": "Alu-betét, ankerozás az elemben",
            "ru": "Alu-компенсатор, анкерование в элементе",
            "sk": "Alu-vyrovnanie, kotvenie v paneli",
            "pl": "Wstawka aluminiowa, ściagi przez płytę"
        },
        {
            "ID": "DOKASETF_A5",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "DOKASETF_A5_AW1",
            "de": "Ankerstab 20,0 verzinkt",
            "en-gb": "Tie rod 20.0 galvanised",
            "en-us": "Tie rod 20.0 galvanized",
            "es": "Barra de ancl. 20,0 galv.",
            "fr": "Tige d'ancrage 20,0 galvanisée",
            "it": "Barra ancorante 20,0 non trattata",
            "hu": "Ankerrúd 20,0 horganyzott",
            "ru": "Анкерный стержень 20,0 оцинкованный",
            "sk": "Kotev. tyč 20,0 neupravovaná",
            "pl": "Ściąg 20,0 ocynkowany"
        },
        {
            "ID": "DOKASETF_A5_AW2",
            "de": "Ankerstab 20,0 unbehandelt",
            "en-gb": "Tie rod 20.0 non-treated",
            "en-us": "Tie rod 20.0 non-treated",
            "es": "Barra de ancl. 20,0 galv.",
            "fr": "Tige d'ancrage 20,0 non traitée",
            "it": "Barra ancorante 20,0 non trattata",
            "hu": "Ankerrúd 20,0 kezeletlen",
            "ru": "Анкерный стержень 20,0 необработанный",
            "sk": "Kotev. tyč 20,0 neupravovaná",
            "pl": "Ściąg 20,0 nieocynkowany"
        },
        {
            "ID": "DOKASETF_A6",
            "de": "Ausgleichsverbindung",
            "en-gb": "Compensation connection",
            "en-us": "Compensation connection",
            "es": "Unión de compensación",
            "fr": "Connexion de compensation",
            "it": "Giunzione per compensazione",
            "hu": "Kiegyenlítés összekötés",
            "ru": "Компенсационное соединение",
            "sk": "Spojenie vo vyrovnaní",
            "pl": "Połączenie kompensacyjne"
        },
        {
            "ID": "DOKASETF_A6_AW1",
            "de": "Uni-Spanner",
            "en-gb": "Multi function clamp",
            "en-us": "Multi function clamp",
            "es": "Grapa unión univ.",
            "fr": "Tendeur rapide universel",
            "it": "Morsetto universale",
            "hu": "Uni. kapcsoló",
            "ru": "Универсальный зажим",
            "sk": "Univ. upínač",
            "pl": "Zamek uniwersalny"
        },
        {
            "ID": "DOKASETF_A6_AW2",
            "de": "Ausgleichsspanner",
            "en-gb": "Adjustable clamp",
            "en-us": "Adjustable clamp",
            "es": "Grapa de compensación",
            "fr": "Tendeur de compensation",
            "it": "Morsetto di compensazione",
            "hu": "Kiegyenlíto kapcsoló",
            "ru": "Компенсационный зажим",
            "sk": "Vyrovn. upínac",
            "pl": "Zamek wyrównujący"
        },
        {
            "ID": "FALTBKG2__A1",
            "de": "Aufhängevarianten",
            "en-gb": "Suspension methods",
            "en-us": "Suspension variant",
            "es": "Versiónes de suspensión",
            "fr": "Variante de suspension",
            "it": "Tipologie di sospensioni",
            "hu": "Függesztési lehetoségek",
            "ru": "Варианты навешивания",
            "sk": "Varianty zavesenia",
            "pl": "Metody zawieszenia"
        },
        {
            "ID": "FALTBKG2__A1_AW1",
            "de": "Aufhängung mit Aufhängekonus",
            "en-gb": "Suspension with suspension cone",
            "en-us": "Suspension with suspension cone",
            "es": "Suspensión con cono de suspensión",
            "fr": "Suspension avec cône de suspension",
            "it": "Sospensione con cono d'aggancio",
            "hu": "Felfüggesztés függesztokónusz",
            "ru": "Навешивание с навесным конусом",
            "sk": "Zavesenie so záves. kónusom",
            "pl": "Suspension with suspension cone"
        },
        {
            "ID": "FALTBKG2__A1_AW2",
            "de": "Aufhängung mit Einhängeschlaufe ES",
            "en-gb": "Suspension with suspension loop ES",
            "en-us": "Suspension with suspension loop ES",
            "es": "Suspensión con lazo de suspensión ES",
            "fr": "Suspension avec crochet de suspension ES",
            "it": "Sospensione con gancio di sospensione ES",
            "hu": "Felfüggesztés függesztõ kapocs ES",
            "ru": "Навешивание с навесной петлей ES",
            "sk": "Zavesenie so závesným strmeňom ES",
            "pl": "Suspension with suspension loop ES"
        },
        {
            "ID": "FALTBKG2__A2",
            "de": "Bühnenbreite",
            "en-gb": "Platform width",
            "en-us": "Platform width",
            "es": "Ancho de plataforma",
            "fr": "Langeur de passerelle",
            "it": "Larghezza del piano di lavoro",
            "hu": "Állványszélesség",
            "ru": "Ширина подмостей",
            "sk": "Šírka plošiny",
            "pl": "Szerokość pomostu"
        },
        {
            "ID": "FALTBKG2__A2_AW1",
            "de": "Faltbühne K 4,50m",
            "en-gb": "Folding platform K 4.50m",
            "en-us": "Folding platform K 4.50m",
            "es": "Ménsula plegable K 4,50m",
            "fr": "Passerelle repliable K 4,5m",
            "it": "Ponte pieghevole K 4,5m",
            "hu": "Összecsukható állvány K 4,5m",
            "ru": "Складные подмостиК 4,5 м",
            "sk": "Skl. ploš. K 4,5m",
            "pl": "Pomost składany K 4,50m"
        },
        {
            "ID": "FALTBKG2__A2_AW2",
            "de": "Faltbühne K 3,00m",
            "en-gb": "Folding platform K 3.00m",
            "en-us": "Folding platform K 3.00m",
            "es": "Ménsula plegable K 3,00m",
            "fr": "Passerelle repliable K 3,0m",
            "it": "Ponte pieghevole K 3,0m",
            "hu": "Összecsukható állvány K 3,0m",
            "ru": "Складные подмостиК 3,0 м",
            "sk": "Skl. ploš. K 3,0m",
            "pl": "Pomost składany K 3,00m"
        },
        {
            "ID": "FALTBKG2__A3",
            "de": "Stirnseitiger Seitenschutz",
            "en-gb": "Sideguards on exposed platform-ends",
            "en-us": "Sideguards on exposed platform-ends",
            "es": "Protección lateral en el tape",
            "fr": "Garde-corps de protection latérale",
            "it": "Parapetto per bordo mensola",
            "hu": "Védőkorlát munkaállvány végein",
            "ru": "Боковые защитные узкий",
            "sk": "Ochrana čiel a bokov",
            "pl": "Osłony boczne na odsłoniętych końcach pomostów"
        },
        {
            "ID": "FALTBKG2__A3_AW1",
            "de": "Seitenschutzsystem XP",
            "en-gb": "Edge protection system XP",
            "en-us": "Edge protection system XP",
            "es": "Sistema de protección lateral XP",
            "fr": "Système de protection XP",
            "it": "Sistema di protezione perimetrale XP",
            "hu": "XP védőkorlát rendszer",
            "ru": "Система защитных перил XP",
            "sk": "Bočný ochranný systém XP",
            "pl": "Boczna barierka ochronna XP"
        },
        {
            "ID": "FALTBKG2__A3_AW2",
            "de": "Seitenschutzgeländer T",
            "en-gb": "Handrail posts T",
            "en-us": "Handrail posts T",
            "es": "Barandillas de seguridad T",
            "fr": "Garde-corps de protection T",
            "it": "Parapetto di testa T",
            "hu": "Oldal védőkorlát T",
            "ru": "Боковые защитные перила Т",
            "sk": "Postranné ochranné zábradlie T",
            "pl": "Boczna barierka ochronna T"
        },
        {
            "ID": "FALTBKG2__A4",
            "de": "Ausgleich",
            "en-gb": "Closure",
            "en-us": "Filler",
            "es": "Ajuste",
            "fr": "Compensation",
            "it": "Chiusura",
            "hu": "Kiegyenlítés",
            "ru": "Компенсация",
            "sk": "Vyrovnanie",
            "pl": "Wypełnienie"
        },
        {
            "ID": "FALTBKG2__A4_AW1",
            "de": "Ausgleichsbühne 3,00m",
            "en-gb": "Closure platform 3.00m",
            "en-us": "Closure platform 3.00m",
            "es": "Plataforma de compensación 3,00m",
            "fr": "Passerelle de compensation 3,00m",
            "it": "Piattaforma di chiusura 3,00m",
            "hu": "Kiegyenlítő állvány 3,00m",
            "ru": "Компенсационные подмости 3,00м",
            "sk": "Vyrovnávacia plošina 3,00m",
            "pl": "Pomost wyrównujący 3,00m"
        },
        {
            "ID": "FALTBKG2__A4_AW2",
            "de": "Bohlen bauseits",
            "en-gb": "Planks by site",
            "en-us": "Planks by site",
            "es": "Tablones por parte de obra",
            "fr": "Madriers fourniture chantier",
            "it": "Tavoloni in cantiere",
            "hu": "Palló helyszíni anyagból",
            "ru": "Брусья силами заказчика",
            "sk": "Fošne zo strany dodávateľa stavby",
            "pl": "Deski po str. budowy"
        },
        {
            "ID": "FALTBKG2__A4_AW3",
            "de": "Bühne aus Einzelkonsolen",
            "en-gb": "Platforms of single brackets",
            "en-us": "Platforms of single brackets",
            "es": "Plataformas de ménsulas independientes",
            "fr": "Passerelle composée de consoles",
            "it": "Piattaforma su singola mensola",
            "hu": "Munkaszint egyedi konzolokból",
            "ru": "Подмост из одиночниe консоли",
            "sk": "Plošina zo samostatných konzol",
            "pl": "Pomosty z pojedyńczych konsol"
        },
        {
            "ID": "FALTBKG2__A5",
            "de": "Eckausbildung",
            "en-gb": "Corners",
            "en-us": "Corners",
            "es": "Esquinas",
            "fr": "Réalisation d'angle",
            "it": "Angoli",
            "hu": "Sarokképzés",
            "ru": "Уголки",
            "sk": "Zhotovenie rohov",
            "pl": "Narożniki"
        },
        {
            "ID": "FALTBKG2__A5_AW1",
            "de": "Faltbühne K Außenecke",
            "en-gb": "Folding platform K outside corner",
            "en-us": "Folding platform K outside corner",
            "es": "Ménsula plegable K con esquina exterior",
            "fr": "Passerelle repliable K angle extérieur",
            "it": "Piattaforma pieghevole K angolo esterno",
            "hu": "Összecsukható munkaállvány K külső sarok",
            "ru": "Cкладные подмости К внешняя угловая част",
            "sk": "Skladacia plošina K, vonkajší roh",
            "pl": "Pomost składany K narożnik zewnętrzny"
        },
        {
            "ID": "FALTBKG2__A5_AW2",
            "de": "Faltbühne mit Ausgleichsbühne",
            "en-gb": "Folding platform with closure platform",
            "en-us": "Folding platform with closure platform",
            "es": "Plataforma plegable con plataforma de compensación",
            "fr": "Ménsula plegable con andamio de compensación",
            "it": "Piattaforma pieghevole con tavole",
            "hu": "Összecsukh. munkaállv. kiegyenlítő állvánnyal",
            "ru": "Складные подмости c компенсационнoй подмостoй",
            "sk": "Skladacia plošina s vyrovnávacou plošinou",
            "pl": "Pomost składany z pomostem wypełnienia"
        },
        {
            "ID": "FALTBKG2__A5_AW3",
            "de": "Faltbühne mit Bohlen bauseits",
            "en-gb": "Folding platform with planks by site",
            "en-us": "Folding platform with planks by site",
            "es": "Plataforma plegable con compensación por parte de obra",
            "fr": "Passerelle repliable avec madriers fourniture chantier",
            "it": "Piattaforma pieghevole con tavole a cura cantiere",
            "hu": "Összecsukh. munkaállv. helyszíni pallózással",
            "ru": "Складные подмости c брусами от заказчики",
            "sk": "Skladacia plošina s fošňami zo strany dodávateľa stavby",
            "pl": "Pomost składany z deskami po str. budowy"
        },
        {
            "ID": "FALTBKG3__A1",
            "de": "Aufhängevarianten",
            "en-gb": "Suspension methods",
            "en-us": "Suspension variant",
            "es": "Versiónes de suspensión",
            "fr": "Variante de suspension",
            "it": "Tipologie di sospensioni",
            "hu": "Függesztési lehetoségek",
            "ru": "Варианты навешивания",
            "sk": "Varianty zavesenia",
            "pl": "Metody zawieszenia"
        },
        {
            "ID": "FALTBKG3__A1_AW1",
            "de": "Aufhängung mit Aufhängekonus",
            "en-gb": "Suspension with suspension cone",
            "en-us": "Suspension with suspension cone",
            "es": "Suspensión con cono de suspensión",
            "fr": "Suspension avec cône de suspension",
            "it": "Sospensione con cono d'aggancio",
            "hu": "Felfüggesztés függesztokónusz",
            "ru": "Навешивание с навесным конусом",
            "sk": "Zavesenie so záves. kónusom",
            "pl": "Suspension with suspension cone"
        },
        {
            "ID": "FALTBKG3__A1_AW2",
            "de": "Aufhängung mit Einhängeschlaufe ES",
            "en-gb": "Suspension with suspension loop ES",
            "en-us": "Suspension with suspension loop ES",
            "es": "Suspensión con lazo de suspensión ES",
            "fr": "Suspension avec crochet de suspension ES",
            "it": "Sospensione con gancio di sospensione ES",
            "hu": "Felfüggesztés függesztõ kapocs ES",
            "ru": "Навешивание с навесной петлей ES",
            "sk": "Zavesenie so závesným strmeňom ES",
            "pl": "Suspension with suspension loop ES"
        },
        {
            "ID": "FALTBKG3__A2",
            "de": "Bühnenbreite",
            "en-gb": "Platform width",
            "en-us": "Platform width",
            "es": "Ancho de plataforma",
            "fr": "Langeur de passerelle",
            "it": "Larghezza del piano di lavoro",
            "hu": "Állványszélesség",
            "ru": "Ширина подмостей",
            "sk": "Šírka plošiny",
            "pl": "Szerokość pomostu"
        },
        {
            "ID": "FALTBKG3__A2_AW1",
            "de": "Faltbühne K 4,50m",
            "en-gb": "Folding platform K 4.50m",
            "en-us": "Folding platform K 4.50m",
            "es": "Ménsula plegable K 4,50m",
            "fr": "Passerelle repliable K 4,50m",
            "it": "Ponte pieghevole K 4,5m",
            "hu": "Összecsukható állvány K 4,5m",
            "ru": "Складные подмостиК 4,5 м",
            "sk": "Skl. ploš. K 4,5m",
            "pl": "Pomost składany K 4,50m"
        },
        {
            "ID": "FALTBKG3__A2_AW2",
            "de": "Faltbühne K 3,00m",
            "en-gb": "Folding platform K 3.00m",
            "en-us": "Folding platform K 3.00m",
            "es": "Ménsula plegable K 3,00m",
            "fr": "Passerelle repliable K 3,00m",
            "it": "Ponte pieghevole K 3,0m",
            "hu": "Összecsukható állvány K 3,0m",
            "ru": "Складные подмостиК 3,0 м",
            "sk": "Skl. ploš. K 3,0m",
            "pl": "Pomost składany K 3,00m"
        },
        {
            "ID": "FALTBKG3__A3",
            "de": "Stirnseitiger Seitenschutz",
            "en-gb": "Sideguards on exposed platform-ends",
            "en-us": "Sideguards on exposed platform-ends",
            "es": "Protección lateral en el tape",
            "fr": "Garde-corps de protection latérale",
            "it": "Parapetto per bordo mensola",
            "hu": "Védőkorlát munkaállvány végein",
            "ru": "Боковые защитные узкий",
            "sk": "Ochrana čiel a bokov",
            "pl": "Osłony boczne na odsłoniętych końcach pomostów"
        },
        {
            "ID": "FALTBKG3__A3_AW1",
            "de": "Seitenschutzsystem XP",
            "en-gb": "Edge protection system XP",
            "en-us": "Edge protection system XP",
            "es": "Sistema de protección lateral XP",
            "fr": "Système de protection XP",
            "it": "Sistema di protezione perimetrale XP",
            "hu": "XP védőkorlát rendszer",
            "ru": "Система защитных перил XP",
            "sk": "Bočný ochranný systém XP",
            "pl": "Boczna barierka ochronna XP"
        },
        {
            "ID": "FALTBKG3__A3_AW2",
            "de": "Seitenschutzgeländer T",
            "en-gb": "Handrail posts T",
            "en-us": "Handrail posts T",
            "es": "Barandillas de seguridad T",
            "fr": "Garde-corps de protection T",
            "it": "Parapetto di testa T",
            "hu": "Oldal védőkorlát T",
            "ru": "Боковые защитные перила Т",
            "sk": "Postranné ochranné zábradlie T",
            "pl": "Boczna barierka ochronna T"
        },
        {
            "ID": "FALTBKG3__A4",
            "de": "Ausgleich",
            "en-gb": "Closure",
            "en-us": "Filler",
            "es": "Ajuste",
            "fr": "Compensation",
            "it": "Chiusura",
            "hu": "Kiegyenlítés",
            "ru": "Компенсация",
            "sk": "Vyrovnanie",
            "pl": "Wypełnienie"
        },
        {
            "ID": "FALTBKG3__A4_AW1",
            "de": "Ausgleichsbühne 3,00m",
            "en-gb": "Closure platform 3.00m",
            "en-us": "Closure platform 3.00m",
            "es": "Plataforma de compensación 3,00m",
            "fr": "Passerelle de compensation 3,00m",
            "it": "Piattaforma di chiusura 3,00m",
            "hu": "Kiegyenlítő állvány 3,00m",
            "ru": "Компенсационные подмости 3,00м",
            "sk": "Vyrovnávacia plošina 3,00m",
            "pl": "Pomost wyrównujący 3,00m"
        },
        {
            "ID": "FALTBKG3__A4_AW2",
            "de": "Bohlen bauseits",
            "en-gb": "Planks by site",
            "en-us": "Planks by site",
            "es": "Tablones por parte de obra",
            "fr": "Madriers fourniture chantier",
            "it": "Tavoloni in cantiere",
            "hu": "Palló helyszíni anyagból",
            "ru": "Брусья силами заказчика",
            "sk": "Fošne zo strany dodávateľa stavby",
            "pl": "Deski po str. budowy"
        },
        {
            "ID": "FALTBKG3__A4_AW3",
            "de": "Bühne aus Einzelkonsolen",
            "en-gb": "Platforms of single brackets",
            "en-us": "Platforms of single brackets",
            "es": "Plataformas de ménsulas independientes",
            "fr": "Passerelle composée de consoles",
            "it": "Piattaforma su singola mensola",
            "hu": "Munkaszint egyedi konzolokból",
            "ru": "Подмост из одиночниe консоли",
            "sk": "Plošina zo samostatných konzol",
            "pl": "Pomosty z pojedyńczych konsol"
        },
        {
            "ID": "FALTBKG3__A5",
            "de": "Eckausbildung",
            "en-gb": "Corners",
            "en-us": "Corners",
            "es": "Esquinas",
            "fr": "Réalisation d'angle",
            "it": "Angoli",
            "hu": "Sarokképzés",
            "ru": "Уголки",
            "sk": "Zhotovenie rohov",
            "pl": "Narożniki"
        },
        {
            "ID": "FALTBKG3__A5_AW1",
            "de": "Faltbühne K Außenecke",
            "en-gb": "Folding platform K outside corner",
            "en-us": "Folding platform K outside corner",
            "es": "Ménsula plegable K con esquina exterior",
            "fr": "Passerelle repliable K angle extérieur",
            "it": "Piattaforma pieghevole K angolo esterno",
            "hu": "Összecsukható munkaállvány K külső sarok",
            "ru": "Складные подмости К внешняя угловая част",
            "sk": "Skladacia plošina K, vonkajší roh",
            "pl": "Pomost składany K narożnik zewnętrzny"
        },
        {
            "ID": "FALTBKG3__A5_AW2",
            "de": "Faltbühne mit Ausgleichsbühne",
            "en-gb": "Folding platform with closure platform",
            "en-us": "Folding platform with closure platform",
            "es": "Plataforma plegable con plataforma de compensación",
            "fr": "Ménsula plegable con andamio de compensación",
            "it": "Piattaforma pieghevole con tavole",
            "hu": "Összecsukh. munkaállv. kiegyenlítő állvánnyal",
            "ru": "Складные подмости c компенсационнoй подмостoй",
            "sk": "Skladacia plošina s vyrovnávacou plošinou",
            "pl": "Pomost składany z pomostem wypełnienia"
        },
        {
            "ID": "FALTBKG3__A5_AW3",
            "de": "Faltbühne mit Bohlen bauseits",
            "en-gb": "Folding platform with planks by site",
            "en-us": "Folding platform with planks by site",
            "es": "Plataforma plegable con compensación por parte de obra",
            "fr": "Passerelle repliable avec madriers fourniture chantier",
            "it": "Piattaforma pieghevole con tavole a cura cantiere",
            "hu": "Összecsukh. munkaállv. helyszíni pallózással",
            "ru": "Складные подмости c брусами от заказчики",
            "sk": "Skladacia plošina s fošňami zo strany dodávateľa stavby",
            "pl": "Pomost składany z deskami po str. budowy"
        },
        {
            "ID": "FALTBKG4__A1",
            "de": "Aufhängevarianten",
            "en-gb": "Suspension methods",
            "en-us": "Suspension variant",
            "es": "Versiónes de suspensión",
            "fr": "Variante de suspension",
            "it": "Tipologie di sospensioni",
            "hu": "Függesztési lehetoségek",
            "ru": "Варианты навешивания",
            "sk": "Varianty zavesenia",
            "pl": "Metody zawieszenia"
        },
        {
            "ID": "FALTBKG4__A1_AW1",
            "de": "Aufhängung mit Aufhängekonus",
            "en-gb": "Suspension with suspension cone",
            "en-us": "Suspension with suspension cone",
            "es": "Suspensión con cono de suspensión",
            "fr": "Suspension avec cône de suspension",
            "it": "Sospensione con cono d'aggancio",
            "hu": "Felfüggesztés Függesztokónusz",
            "ru": "Навешивание с навесным конусом",
            "sk": "Zavesenie so záves. kónusom",
            "pl": "Suspension with suspension cone"
        },
        {
            "ID": "FALTBKG4__A1_AW2",
            "de": "Aufhängung mit Einhängeschlaufe ES",
            "en-gb": "Suspension with suspension loop ES",
            "en-us": "Suspension with suspension loop ES",
            "es": "Suspensión con lazo de suspensión ES",
            "fr": "Suspension avec crochet de suspension ES",
            "it": "Sospensione con gancio di sospensione ES",
            "hu": "Felfüggesztés függesztõ kapocsES",
            "ru": "Навешивание с навесной петлей ES",
            "sk": "Zavesenie so závesným strmeňom ES",
            "pl": "Suspension with suspension loop ES"
        },
        {
            "ID": "FALTBKG4__A2",
            "de": "Bühnenbreite",
            "en-gb": "Platform width",
            "en-us": "Platform width",
            "es": "Ancho de plataforma",
            "fr": "Langeur de passerelle",
            "it": "Larghezza del piano di lavoro",
            "hu": "Állványszélesség",
            "ru": "Ширина подмостей",
            "sk": "Šírka plošiny",
            "pl": "Szerokość pomostu"
        },
        {
            "ID": "FALTBKG4__A2_AW1",
            "de": "Faltbühne K 4,50m",
            "en-gb": "Folding platform K 4.50m",
            "en-us": "Folding platform K 4.50m",
            "es": "Ménsula plegable K 4,50m",
            "fr": "Passerelle repliable K 4,5m",
            "it": "Ponte pieghevole K 4,5m",
            "hu": "Összecsukható állvány K 4,5m",
            "ru": "Складные подмостиК 4,5 м",
            "sk": "Skl. ploš. K 4,5m",
            "pl": "Pomost składany K 4,50m"
        },
        {
            "ID": "FALTBKG4__A2_AW2",
            "de": "Faltbühne K 3,00m",
            "en-gb": "Folding platform K 3.00m",
            "en-us": "Folding platform K 3.00m",
            "es": "Ménsula plegable K 3,00m",
            "fr": "Passerelle repliable K 3,0m",
            "it": "Ponte pieghevole K 3,0m",
            "hu": "Összecsukható állvány K 3,0m",
            "ru": "Складные подмостиК 3,0 м",
            "sk": "Skl. ploš. K 3,0m",
            "pl": "Pomost składany K 3,00m"
        },
        {
            "ID": "FALTBKG4__A3",
            "de": "Stirnseitiger Seitenschutz",
            "en-gb": "Sideguards on exposed platform-ends",
            "en-us": "Sideguards on exposed platform-ends",
            "es": "Protección lateral en el tape",
            "fr": "Garde-corps de protection latérale",
            "it": "Parapetto per bordo mensola",
            "hu": "Védőkorlát munkaállvány végein",
            "ru": "Боковые защитные узкий",
            "sk": "Ochrana čiel a bokov",
            "pl": "Osłony boczne na odsłoniętych końcach pomostów"
        },
        {
            "ID": "FALTBKG4__A3_AW1",
            "de": "Seitenschutzsystem XP",
            "en-gb": "Edge protection system XP",
            "en-us": "Edge protection system XP",
            "es": "Sistema de protección lateral XP",
            "fr": "Système de protection XP",
            "it": "Sistema di protezione perimetrale XP",
            "hu": "XP védőkorlát rendszer",
            "ru": "Система защитных перил XP",
            "sk": "Bočný ochranný systém XP",
            "pl": "Boczna barierka ochronna XP"
        },
        {
            "ID": "FALTBKG4__A3_AW2",
            "de": "Seitenschutzgeländer T",
            "en-gb": "Handrail posts T",
            "en-us": "Handrail posts T",
            "es": "Barandillas de seguridad T",
            "fr": "Garde-corps de protection T",
            "it": "Parapetto di testa T",
            "hu": "Oldal védőkorlát T",
            "ru": "Боковые защитные перила Т",
            "sk": "Postranné ochranné zábradlie T",
            "pl": "Boczna barierka ochronna T"
        },
        {
            "ID": "FALTBKG4__A4",
            "de": "Ausgleich",
            "en-gb": "Closure",
            "en-us": "Filler",
            "es": "Ajuste",
            "fr": "Compensation",
            "it": "Chiusura",
            "hu": "Kiegyenlítés",
            "ru": "Компенсация",
            "sk": "Vyrovnanie",
            "pl": "Wypełnienie"
        },
        {
            "ID": "FALTBKG4__A4_AW1",
            "de": "Ausgleichsbühne 3,00m",
            "en-gb": "Closure platform 3.00m",
            "en-us": "Closure platform 3.00m",
            "es": "Plataforma de compensación 3,00m",
            "fr": "Passerelle de compensation 3,00m",
            "it": "Piattaforma di chiusura 3,00m",
            "hu": "Kiegyenlítő állvány 3,00m",
            "ru": "Компенсационные подмости 3,00м",
            "sk": "Vyrovnávacia plošina 3,00m",
            "pl": "Pomost wyrównujący 3,00m"
        },
        {
            "ID": "FALTBKG4__A4_AW2",
            "de": "Bohlen bauseits",
            "en-gb": "Planks by site",
            "en-us": "Planks by site",
            "es": "Tablones por parte de obra",
            "fr": "Madriers fourniture chantier",
            "it": "Tavoloni in cantiere",
            "hu": "Palló helyszíni anyagból",
            "ru": "Брусья силами заказчика",
            "sk": "Fošne zo strany dodávateľa stavby",
            "pl": "Deski po str. budowy"
        },
        {
            "ID": "FALTBKG4__A4_AW3",
            "de": "Bühne aus Einzelkonsolen",
            "en-gb": "Platforms of single brackets",
            "en-us": "Platforms of single brackets",
            "es": "Plataformas de ménsulas independientes",
            "fr": "Passerelle composée de consoles",
            "it": "Piattaforma su singola mensola",
            "hu": "Munkaszint egyedi konzolokból",
            "ru": "Подмост из одиночниe консоли",
            "sk": "Plošina zo samostatných konzol",
            "pl": "Pomosty z pojedyńczych konsol"
        },
        {
            "ID": "FALTBKG4__A5",
            "de": "Eckausbildung",
            "en-gb": "Corners",
            "en-us": "Corners",
            "es": "Esquinas",
            "fr": "Réalisation d'angle",
            "it": "Angoli",
            "hu": "Sarokképzés",
            "ru": "Уголки",
            "sk": "Zhotovenie rohov",
            "pl": "Narożniki"
        },
        {
            "ID": "FALTBKG4__A5_AW1",
            "de": "Faltbühne K Außenecke",
            "en-gb": "Folding platform K outside corner",
            "en-us": "Folding platform K outside corner",
            "es": "Ménsula plegable K con esquina exterior",
            "fr": "Passerelle repliable K angle extérieur",
            "it": "Piattaforma pieghevole K angolo esterno",
            "hu": "Összecsukható munkaállvány K külső sarok",
            "ru": "Складные подмости К внешняя угловая част",
            "sk": "Skladacia plošina K, vonkajší roh",
            "pl": "Pomost składany K narożnik zewnętrzny"
        },
        {
            "ID": "FALTBKG4__A5_AW2",
            "de": "Faltbühne mit Ausgleichsbühne",
            "en-gb": "Folding platform with closure platform",
            "en-us": "Folding platform with closure platform",
            "es": "Plataforma plegable con plataforma de compensación",
            "fr": "Ménsula plegable con andamio de compensación",
            "it": "Piattaforma pieghevole con tavole",
            "hu": "Összecsukh. munkaállv. kiegyenlítő állvánnyal",
            "ru": "Складные подмости c компенсационнoй подмостoй",
            "sk": "Skladacia plošina s vyrovnávacou plošinou",
            "pl": "Pomost składany z pomostem wypełnienia"
        },
        {
            "ID": "FALTBKG4__A5_AW3",
            "de": "Faltbühne mit Bohlen bauseits",
            "en-gb": "Folding platform with planks by site",
            "en-us": "Folding platform with planks by site",
            "es": "Plataforma plegable con compensación por parte de obra",
            "fr": "Passerelle repliable avec madriers fourniture chantier",
            "it": "Piattaforma pieghevole con tavole a cura cantiere",
            "hu": "Összecsukh. munkaállv. helyszíni pallózással",
            "ru": "Складные подмости c брусами от заказчики",
            "sk": "Skladacia plošina s fošňami zo strany dodávateľa stavby",
            "pl": "Pomost składany z deskami po str. budowy"
        },
        {
            "ID": "FF100TEC_A1",
            "de": "Gerade Füllwand",
            "en-gb": "Straight infill wall",
            "en-us": "Straight infill wall",
            "es": "Muro recto",
            "fr": "Voile à compléter",
            "it": "Muro dritto di tamponamento",
            "hu": "egyenes kitöltő falazat",
            "ru": "Прямая пустотелая стена",
            "sk": "Rovná plná stena",
            "pl": "Prosta ściana wypełniająca"
        },
        {
            "ID": "FF100TEC_A1_AW1",
            "de": "nicht aufgestockt",
            "en-gb": "not stacked",
            "en-us": "not stacked",
            "es": "sin apilar",
            "fr": "non rehaussé",
            "it": "senza sovrapposizione",
            "hu": "magasítás nélküli",
            "ru": "без наращивания",
            "sk": "bez nadstavenia",
            "pl": "bez nadstawienia"
        },
        {
            "ID": "FF100TEC_A1_AW2",
            "de": "aufgestockt",
            "en-gb": "stacked",
            "en-us": "stacked",
            "es": "apilado",
            "fr": "Rehaussé",
            "it": "sovrapposizione",
            "hu": "magasított",
            "ru": "с наращиванием",
            "sk": "nadstavené",
            "pl": "nadstawienie poziome"
        },
        {
            "ID": "FF100TEC_A1_AW3",
            "de": "nicht aufgestockt, Element gedreht",
            "en-gb": "not stacked, element rotated",
            "en-us": "not stacked, element rotated",
            "es": "sin apilar, elemento girado",
            "fr": "non rehaussé, panneau tourné",
            "it": "senza sovrapposizione, elemento ruotato",
            "hu": "magasítás nélküli, elforgatott elem",
            "ru": "без наращивания, элемент повернутый",
            "sk": "bez nadstavenia, panel obrátený",
            "pl": "bez nadstawienia, element obrócony"
        },
        {
            "ID": "FF100TEC_A2",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "FF100TEC_A2_AW1",
            "de": "Ankerstab 20,0 verzinkt",
            "en-gb": "Tie rod 20.0 galvanised",
            "en-us": "Tie rod 20.0 galvanized",
            "es": "Barra de ancl. 20,0 galv.",
            "fr": "Tige d'ancrage 20,0 galvanisée",
            "it": "Barra ancorante 20,0 zincata",
            "hu": "Ankerrúd 20,0 horganyzott",
            "ru": "Анкерный стержень 20,0 оцинкованный",
            "sk": "Kotev. tyč 20,0 pozinkov.",
            "pl": "Ściąg 20,0 ocynkowany"
        },
        {
            "ID": "FF100TEC_A2_AW2",
            "de": "Ankerstab 20,0 unbehandelt",
            "en-gb": "Tie rod 20.0 non-treated",
            "en-us": "Tie rod 20.0 non-treated",
            "es": "Barra de ancl. 20,0 no tratada",
            "fr": "Tige d'ancrage 20,0 non traitée",
            "it": "Barra ancorante 20,0 non trattata",
            "hu": "Ankerrúd 20,0 kezeletlen",
            "ru": "Анкерный стержень 20,0 необработанный",
            "sk": "Kotev. tyč 20,0 neupravovaná",
            "pl": "Ściąg 20,0 nieocynkowany"
        },
        {
            "ID": "FF100TEC_A3",
            "de": "Ausgleich",
            "en-gb": "Closure",
            "en-us": "Filler",
            "es": "Compensación",
            "fr": "Compensation",
            "it": "Estensione",
            "hu": "Kiegyenlítés",
            "ru": "Компенсация",
            "sk": "Vyrovnanie",
            "pl": "Wypełnienie"
        },
        {
            "ID": "FF100TEC_A3_AW1",
            "de": "Ausgleichslasche",
            "en-gb": "Adjustable waling extension",
            "en-us": "Adjustable waling extension",
            "es": "Regleta de compensación",
            "fr": "Eclisse de compensation",
            "it": "Prolunga di compensazione",
            "hu": "Kiegyenlítő heveder",
            "ru": "Компенсирующая накладка",
            "sk": "Vyrovnávacia príložka",
            "pl": "Łącznik wyrównujący"
        },
        {
            "ID": "FF100TEC_A3_AW2",
            "de": "Elementverbinder",
            "en-gb": "Formwork element connector",
            "en-us": "Formwork element connector",
            "es": "Regleta de unión",
            "fr": "Eclisse de panneaux",
            "it": "Elemento di collegamento",
            "hu": "Elemösszekötő",
            "ru": "Соединительный элемент",
            "sk": "Panelová spojka",
            "pl": "Łączniki elementów szalunku"
        },
        {
            "ID": "FF20_21_A1",
            "de": "Elementhöhe",
            "en-gb": "Panel height",
            "en-us": "Panel height",
            "es": "Altura del elemento",
            "fr": "Hauteur d'élément",
            "it": "Altezza del elemento",
            "hu": "Elemmagasság",
            "ru": "Высота элемента",
            "sk": "Výška panelu",
            "pl": "Wysokość płyty"
        },
        {
            "ID": "FF20_21_A1_AW1",
            "de": "mit 3,75 Element",
            "en-gb": "with 3.75 panel",
            "en-us": "with 3.75 panel",
            "es": "Con 3,75 elemento",
            "fr": "avec 3,75 Px",
            "it": "con 3,75 elemento",
            "hu": "3,75-ös elemmel",
            "ru": "с элементом 3,75",
            "sk": "s 3,75 panel",
            "pl": "z płytą 3,75"
        },
        {
            "ID": "FF20_21_A1_AW2",
            "de": "ohne 3,75 Element",
            "en-gb": "without 3.75 panel",
            "en-us": "without 3.75 panel",
            "es": "sin 3,75 elemento",
            "fr": "sans 3,75 Px",
            "it": "senza 3,75 elemento",
            "hu": "3,75-ös elem nélkül",
            "ru": "без элемента 3,75",
            "sk": "bez 3,75 panel",
            "pl": "bez płyty 3,75"
        },
        {
            "ID": "FF20_21_A1_AW3",
            "de": "mit 6,50 Element",
            "en-gb": "with 6.50 panel",
            "en-us": "with 6.50 panel",
            "es": "Con 6,50 elemento",
            "fr": "avec 6,50 Px",
            "it": "con 6,50 elemento",
            "hu": "6,50-ös elemmel",
            "ru": "с элементом 6,50",
            "sk": "s 6,50 panel",
            "pl": "z płytą 6,50"
        },
        {
            "ID": "FF20_21_A2",
            "de": "Aufstockelement",
            "en-gb": "Stacking element",
            "en-us": "Stacking element",
            "es": "Panel para apilar",
            "fr": "Px de rehausse",
            "it": "Elemento sovrapp.",
            "hu": "Magasító elem",
            "ru": "Элемент надстройки",
            "sk": "Nadstavenia",
            "pl": "Element nadstawienia"
        },
        {
            "ID": "FF20_21_A2_AW1",
            "de": "Ja",
            "en-gb": "Yes",
            "en-us": "Yes",
            "es": "Si",
            "fr": "Oui",
            "it": "Si",
            "hu": "Igen",
            "ru": "Да",
            "sk": "áno",
            "pl": "Tak"
        },
        {
            "ID": "FF20_21_A2_AW2",
            "de": "Nein",
            "en-gb": "No",
            "en-us": "No",
            "es": "No",
            "fr": "Non",
            "it": "Non",
            "hu": "Nem",
            "ru": "Нет",
            "sk": "nie",
            "pl": "Nie"
        },
        {
            "ID": "FF20_21_A3",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "FF20_21_A3_AW1",
            "de": "Stoßende Elemente mit Außenecklasche FF20",
            "en-gb": "Hitting panels with outside angle plate FF20",
            "en-us": "Hitting panels with outside angle plate FF20",
            "es": "ElSin 6,50 elementoementos a tope con regleta de esquina exterior FF20",
            "fr": "Px joints avec éclisse d'angle extérieur FF20",
            "it": "Elementi a giunto con listello angolo esterno FF20",
            "hu": "Csatlakozó elemek külső sarokheveder FF20-al",
            "ru": "Стык элементов",
            "sk": "Spoj panelov pomocou vonkajšej rohovej príložky FF20",
            "pl": "Płyty przylegające z zewnętrzną płytą kątową FF20"
        },
        {
            "ID": "FF20_21_A3_AW2",
            "de": "Versetzte Elemente mit Außenecklasche FF20",
            "en-gb": "Shifted panels with outside angle plate FF20",
            "en-us": "Shifted panels with outside angle plate FF20",
            "es": "ElPanel para apilarementos desplazados con regleta de esquina exterior FF20",
            "fr": "Px décalés avec éclisse d'angle extérieur FF20",
            "it": "Elementi sfalsati con listello angolo esterno FF20",
            "hu": "Eltolt elemek külső sarokheveder FF20-al",
            "ru": "Смещенные элементы",
            "sk": "Zapustenie panelov pomocou vonkajšej rohovej príložky FF20",
            "pl": "Płyty z przesunięciem z zewnętrzną płytą kątową FF20"
        },
        {
            "ID": "FF20_21_A3_AW3",
            "de": "Gelenkecken mit 50er Element",
            "en-gb": "Swiveling corners with panel 0,50",
            "en-us": "Swiveling corners with panel 0,50",
            "es": "Esquinas bisagra 50",
            "fr": "Angle articulé Px 50",
            "it": "Angoli a cerniera con 50er elemento",
            "hu": "Csuklós sarkok 50-es elem",
            "ru": "Шарнирные углы с элементом 50",
            "sk": "Kĺb. rohy s 50-ový panel",
            "pl": "Narożniki przegubowe z płytą 0,50"
        },
        {
            "ID": "FF20_21_A3_AW4",
            "de": "Gelenkecken mit 75er Element",
            "en-gb": "Swiveling corners with panel 0,75",
            "en-us": "Swiveling corners with panel 0,75",
            "es": "Esquinas bisagra 75",
            "fr": "Angle articulé Px 75",
            "it": "Angoli a cerniera con 75er elemento",
            "hu": "Csuklós sarkok 75-ös elem",
            "ru": "Шарнирные углы с элементом 75",
            "sk": "Kĺb. rohy s 75-ový panel",
            "pl": "Narożniki przegubowe z płytą 0,75"
        },
        {
            "ID": "FF20_21_A3_AW5",
            "de": "Stoßende Elemente mit Universal-Winkelspanner",
            "en-gb": "Hitting panels with universal angle tie bracket",
            "en-us": "Hitting panels with universal angle tie bracket",
            "es": "Elementos a tope con tensor de ángulo universal",
            "fr": "Px joints avec tendeur d'angle universel",
            "it": "Elementi a giunto con piastra tenditore universale",
            "hu": "Csatlakozó elemek univerzális sarokhevederrel",
            "ru": "Примыкающие элементы с универсальным угловым зажимным приспособлением",
            "sk": "Spoj panelov pomocou univerzálneho rohového upínača",
            "pl": "Płyty przylegające z uniwersalnym wspornikiem kątowym"
        },
        {
            "ID": "FF20_21_A4",
            "de": "Stirnabschalung, Wandanschlüsse",
            "en-gb": "Stop-end, wall sockets",
            "en-us": "Bulkhead, wall sockets",
            "es": "Tape, Acometidas de muros",
            "fr": "Coffrage d'about, connexions de mur",
            "it": "Chiusura di testa, Agganci per parete",
            "hu": "Véglezárás, Falcsatlakozás",
            "ru": "Торцевая опалубка, стыки стен",
            "sk": "Debnenie čela, napájanie stien",
            "pl": "Szalunek czołowy, gniazda ścienne"
        },
        {
            "ID": "FF20_21_A4_AW1",
            "de": "P-Träger top",
            "en-gb": "P-beam top",
            "en-us": "P-beam top",
            "es": "P-viga top",
            "fr": "Poutrelle P top",
            "it": "Trave P top",
            "hu": "P-fatartó top",
            "ru": "P-балка top",
            "sk": "P-nosník top",
            "pl": "Dźwigar P top"
        },
        {
            "ID": "FF20_21_A4_AW2",
            "de": "N-Träger top",
            "en-gb": "N-beam top",
            "en-us": "N-beam top",
            "es": "N-viga top",
            "fr": "Poutrelle N top",
            "it": "Trave N top",
            "hu": "N-fatartó top",
            "ru": "N-балка top",
            "sk": "N-nosník top",
            "pl": "Dźwigar N top"
        },
        {
            "ID": "FF20_21_A4_AW3",
            "de": "P-Träger eco",
            "en-gb": "P-beam eco",
            "en-us": "P-beam eco",
            "es": "P-viga eco",
            "fr": "Poutrelle P eco",
            "it": "Trave P eco",
            "hu": "P-fatartó eco",
            "ru": "P-балка eco",
            "sk": "P-nosník eco",
            "pl": "Dźwigar P eco"
        },
        {
            "ID": "FF20_21_A4_AW4",
            "de": "N-Träger eco",
            "en-gb": "N-beam eco",
            "en-us": "N-beam eco",
            "es": "N-viga eco",
            "fr": "Poutrelle N eco",
            "it": "Trave N eco",
            "hu": "N-fatartó eco",
            "ru": "N-балка eco",
            "sk": "N-nosník eco",
            "pl": "Dźwigar N eco"
        },
        {
            "ID": "FF20_21_A4_AW5",
            "de": "P-Träger",
            "en-gb": "P-beam",
            "en-us": "P-beam",
            "es": "P-viga",
            "fr": "Poutrelle P",
            "it": "Trave P",
            "hu": "P-fatartó",
            "ru": "P-балка",
            "sk": "P-nosník",
            "pl": "Dźwigar P"
        },
        {
            "ID": "FF20_21_A4_AW6",
            "de": "N-Träger",
            "en-gb": "N-beam",
            "en-us": "N-beam",
            "es": "N-viga",
            "fr": "Poutrelle N",
            "it": "Trave N",
            "hu": "N-fatartó",
            "ru": "N-балка",
            "sk": "N-nosník",
            "pl": "Dźwigar N"
        },
        {
            "ID": "FF20_21_A4_AW7",
            "de": "ohne Träger",
            "en-gb": "without beam",
            "en-us": "without beam",
            "es": "Sin viga",
            "fr": "Sans poutrelles",
            "it": "senza trave",
            "hu": "tartó nélkül",
            "ru": "без балок",
            "sk": "bez nosník",
            "pl": "bez belki"
        },
        {
            "ID": "FF20_21_A5",
            "de": "Elementverbindung",
            "en-gb": "Panel connection",
            "en-us": "Panel connection",
            "es": "Unión para elemento",
            "fr": "Assemblage px",
            "it": "Giunzioni di elementi",
            "hu": "Elemkapcsolat",
            "ru": "Соединение элементов",
            "sk": "Panelová spojka",
            "pl": "Łączenie płyt"
        },
        {
            "ID": "FF20_21_A5_AW1",
            "de": "mit Zugfunktion",
            "en-gb": "with pull-off function",
            "en-us": "with pull function",
            "es": "A tracción",
            "fr": "avec fonction de traction",
            "it": "con funzione di serraggio",
            "hu": "húzófunkcióval",
            "ru": "с функцией натяжения",
            "sk": "s funkciou tahu",
            "pl": "z funkcją odciągania"
        },
        {
            "ID": "FF20_21_A5_AW2",
            "de": "ohne Zugfunktion",
            "en-gb": "without pull-off function",
            "en-us": "without pull function",
            "es": "No apto para tracción",
            "fr": "sans fonction de traction",
            "it": "senza funzione di serraggio",
            "hu": "húzófunkció nélkül",
            "ru": "без функции натяжения",
            "sk": "bez funkcie tahu",
            "pl": "bez funkcji odciągania"
        },
        {
            "ID": "FF20_21_A6",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "FF20_21_A6_AW1",
            "de": "Ankerstab 15,0 unbehandelt",
            "en-gb": "Tie rod 15.0 non-treated",
            "en-us": "Tie rod 15.0 non-treated",
            "es": "Barra de ancl. 15,0 no tratada",
            "fr": "Tige d'ancrage 15,0 non traitée",
            "it": "Barra ancorante 15,0 non trattata",
            "hu": "Ankerrúd 15,0 kezeletlen",
            "ru": "Анкерный стержень 15,0 необработанный",
            "sk": "Kotev. tyč 15,0 neupravov.",
            "pl": "Ściąg 15,0 nieocynkowany"
        },
        {
            "ID": "FF20_21_A6_AW2",
            "de": "Ankerstab 15,0 verzinkt",
            "en-gb": "Tie rod 15.0 galvanised",
            "en-us": "Tie rod 15.0 galvanized",
            "es": "Barra de ancl. 15,0 galv.",
            "fr": "Tige d'ancrage 15,0 galvanisée",
            "it": "Barra ancorante 15,0 zincata",
            "hu": "Ankerrúd 15,0 horganyzott",
            "ru": "Анкерный стержень 15,0 оцинкованный",
            "sk": "Kotev. tyč 15,0 pozink.",
            "pl": "Ściąg 15,0 ocynkowany"
        },
        {
            "ID": "FF20_27_A1",
            "de": "Elementbreite",
            "en-gb": "Panel width",
            "en-us": "Panel width",
            "es": "Ancho de elemento",
            "fr": "Largeur de px",
            "it": "Larghezza del elemento",
            "hu": "Elemszélesség",
            "ru": "Ширина элемента",
            "sk": "Šírka panelu",
            "pl": "Szerokość płyty"
        },
        {
            "ID": "FF20_27_A1_AW1",
            "de": "Breite 2,00 m",
            "en-gb": "Width 2.00 m",
            "en-us": "Width 2.00m",
            "es": "Ancho 2,00m",
            "fr": "Largeur 2,00m",
            "it": "Larghezza 2,00m",
            "hu": "Szélesség 2,00m",
            "ru": "Ширина 2,00 м",
            "sk": "Šírka 2,00m",
            "pl": "Szerokość 2,00 m"
        },
        {
            "ID": "FF20_27_A1_AW2",
            "de": "Breite 1,00 m",
            "en-gb": "Width 1.00 m",
            "en-us": "Width 1.00m",
            "es": "Ancho 1,00m",
            "fr": "Largeur 1,00m",
            "it": "Larghezza 1,00m",
            "hu": "Szélesség 1,00m",
            "ru": "Ширина 1,00 м",
            "sk": "Šírka 1,00m",
            "pl": "Szerokość 1,00 m"
        },
        {
            "ID": "FF20_27_A1_AW3",
            "de": "Breite 0,75 m",
            "en-gb": "Width 0.75 m",
            "en-us": "Width 0.75m",
            "es": "Ancho 0,75m",
            "fr": "Largeur 0,75m",
            "it": "Larghezza 0,75m",
            "hu": "Szélesség 0,75m",
            "ru": "Ширина 0,75 м",
            "sk": "Šírka 0,75m",
            "pl": "Szerokość 0,75 m"
        },
        {
            "ID": "FF20_27_A1_AW4",
            "de": "Breite 0,50 m",
            "en-gb": "Width 0.50 m",
            "en-us": "Width 0.50m",
            "es": "Ancho 0,50m",
            "fr": "Largeur 0,50m",
            "it": "Larghezza 0,50m",
            "hu": "Szélesség 0,50m",
            "ru": "Ширина 0,50 м",
            "sk": "Šírka 0,50m",
            "pl": "Szerokość 0,50 m"
        },
        {
            "ID": "FF20_27_A1_AW5",
            "de": "Breite 0,75/1,00 m (Ecke)",
            "en-gb": "Width 0.75/1.00 m (corner)",
            "en-us": "Width 0.75/1.00 m (corner)",
            "es": "Ancho 075/1,00m (esqu.)",
            "fr": "Largeur 075/1,00m (angle)",
            "it": "Larghezza 075/1,00m (Angolo)",
            "hu": "Szélesség 075/1,00m (Sarok)",
            "ru": "Ширина 075/1,00 м (угол)",
            "sk": "Šírka 075/1,00m (roh)",
            "pl": "Szerokość 075/1,00 m (corner)"
        },
        {
            "ID": "FF20_27_A2",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "FF20_27_A2_AW1",
            "de": "Stoßende Elemente mit Außenecklasche FF20",
            "en-gb": "Hitting panels with outside angle plate FF20",
            "en-us": "Hitting panels with outside angle plate FF20",
            "es": "Elementos a tope con regleta de esquina exterior FF20",
            "fr": "Px joints avec éclisse d'angle extérieur FF20",
            "it": "Elementi a giunto con listello angolo esterno FF20",
            "hu": "Csatlakozó elemek külső sarokheveder FF20-al",
            "ru": "Примыкающие элементы с внешней угловой накладкой FF20",
            "sk": "Spoj panelov pomocou vonkajšej rohovej príložky FF20",
            "pl": "Płyty przylegające z zewnętrzną płytą kątową FF20"
        },
        {
            "ID": "FF20_27_A2_AW2",
            "de": "Versetzte Elemente mit Außenecklasche FF20",
            "en-gb": "Shifted panels with outside angle plate FF20",
            "en-us": "Shifted panels with outside angle plate FF20",
            "es": "Elementos desplazados con regleta de esquina exterior FF20",
            "fr": "Px décalés avec éclisse d'angle extérieur FF20",
            "it": "Elementi sfalsati con listello angolo esterno FF20",
            "hu": "Eltolt elemek külső sarokheveder FF20-al",
            "ru": "Перемещаемые элементы с внешней угловой накладкой FF20",
            "sk": "Zapustenie panelov pomocou vonkajšej rohovej príložky FF20",
            "pl": "Płyty z przesunięciem z zewnętrzną płytą kątową FF20"
        },
        {
            "ID": "FF20_27_A2_AW3",
            "de": "Scharnierecken",
            "en-gb": "Hinged corners",
            "en-us": "Hinged corners",
            "es": "Esquinas bisagra",
            "fr": "Angle charnière",
            "it": "Angoli a cerniera",
            "hu": "Csukl.sarkok",
            "ru": "шарнирные угловые части",
            "sk": "Kĺbové rohy",
            "pl": "Narożniki zawiasowy"
        },
        {
            "ID": "FF20_27_A2_AW4",
            "de": "Stoßende Elemente mit Universal-Winkelspanner",
            "en-gb": "Hitting panels with universal angle tie bracket",
            "en-us": "Hitting panels with universal angle tie bracket",
            "es": "Elementos a tope con tensor de ángulo universal",
            "fr": "Px joints avec tendeur d'angle universel",
            "it": "Elementi a giunto con piastra tenditore universale",
            "hu": "Csatlakozó elemek univerzális sarokhevederrel",
            "ru": "Примыкающие элементы с универсальным угловым зажимным приспособлением",
            "sk": "Spoj panelov pomocou univerzálneho rohového upínača",
            "pl": "Płyty przylegające z uniwersalnym wspornikiem kątowym"
        },
        {
            "ID": "FF20_27_A3",
            "de": "Stirnabschalung, Wandanschlüsse, Ausgleiche",
            "en-gb": "Stop-end, wall sockets, closures",
            "en-us": "Bulkhead, wall sockets, filler",
            "es": "Tape, Acometidas de muros, compencaciónes",
            "fr": "Coffrage d'about, connexions de mur,compensations",
            "it": "Chiusura di testa, Agganci per parete, Compensazioni",
            "hu": "Véglezárás, Falcsatlakozás, Kiegyenlítés",
            "ru": "Торцевая опалубка, стыки стен, компенсации",
            "sk": "Debnenie čela, napájanie stien, vyrovnania",
            "pl": "Szalunek czołowy, gniazda ścienne, wypełnienia"
        },
        {
            "ID": "FF20_27_A3_AW1",
            "de": "P-Träger top",
            "en-gb": "P-beam top",
            "en-us": "P-beam top",
            "es": "P-viga top",
            "fr": "Poutrelle P top",
            "it": "Trave P top",
            "hu": "P-fatartó top",
            "ru": "P-балка top",
            "sk": "P-nosník top",
            "pl": "Dźwigar P top"
        },
        {
            "ID": "FF20_27_A3_AW2",
            "de": "N-Träger top",
            "en-gb": "N-beam top",
            "en-us": "N-beam top",
            "es": "N-viga top",
            "fr": "Poutrelle N top",
            "it": "Trave N top",
            "hu": "N-fatartó top",
            "ru": "N-балка top",
            "sk": "N-nosník top",
            "pl": "Dźwigar N top"
        },
        {
            "ID": "FF20_27_A3_AW3",
            "de": "P-Träger eco",
            "en-gb": "P-beam eco",
            "en-us": "P-beam eco",
            "es": "P-viga eco",
            "fr": "Poutrelle P eco",
            "it": "Trave P eco",
            "hu": "P-fatartó eco",
            "ru": "P-балка eco",
            "sk": "P-nosník eco",
            "pl": "Dźwigar P eco"
        },
        {
            "ID": "FF20_27_A3_AW4",
            "de": "N-Träger eco",
            "en-gb": "N-beam eco",
            "en-us": "N-beam eco",
            "es": "N-viga eco",
            "fr": "Poutrelle N eco",
            "it": "Trave N eco",
            "hu": "N-fatartó eco",
            "ru": "N-балка eco",
            "sk": "N-nosník eco",
            "pl": "Dźwigar N eco"
        },
        {
            "ID": "FF20_27_A3_AW5",
            "de": "P-Träger",
            "en-gb": "P-beam",
            "en-us": "P-beam",
            "es": "P-viga",
            "fr": "Poutrelle P",
            "it": "Trave P",
            "hu": "P-fatartó",
            "ru": "P-балка",
            "sk": "P-nosník",
            "pl": "Dźwigar P"
        },
        {
            "ID": "FF20_27_A3_AW6",
            "de": "N-Träger",
            "en-gb": "N-beam",
            "en-us": "N-beam",
            "es": "N-viga",
            "fr": "Poutrelle N",
            "it": "Trave N",
            "hu": "N-fatartó",
            "ru": "N-балка",
            "sk": "N-nosník",
            "pl": "Dźwigar N"
        },
        {
            "ID": "FF20_27_A3_AW7",
            "de": "ohne Träger (Ausgleich)",
            "en-gb": "without beam (closure)",
            "en-us": "without beam (filler)",
            "es": "Sin viga (compensación)",
            "fr": "sans poutrelle (compensation)",
            "it": "senza trave (Compensazione)",
            "hu": "tartó nélkül (Kiegyenlítés)",
            "ru": "Без балки (компенсация)",
            "sk": "bez nosníkov (vyrovnanie)",
            "pl": "bez belki (wypełnienie)"
        },
        {
            "ID": "FF20_27_A3_AW8",
            "de": "Ausgleichsblech (Ausgleich)",
            "en-gb": "Closure plate (closure)",
            "en-us": "Closure plate (filler)",
            "es": "Chapa de compensación (compensación)",
            "fr": "Pièce de compensation (compensation)",
            "it": "Lamiera di compensazione (Compensazione)",
            "hu": "Kiegyenlíto lemez (Kiegyenlítés)",
            "ru": "Компенсационный лист (компенсация)",
            "sk": "Vyrovnáv. plech (vyrovnanie)",
            "pl": "Blacha wyrównująca (wypełnienie)"
        },
        {
            "ID": "FF20_27_A4",
            "de": "Elementverbindung",
            "en-gb": "Panel connection",
            "en-us": "Panel connection",
            "es": "Unión para elemento",
            "fr": "Assemblage px",
            "it": "Giunzioni di elementi",
            "hu": "Elemkapcsolat",
            "ru": "Соединение элементов",
            "sk": "Panelová spojka",
            "pl": "Łączenie płyt"
        },
        {
            "ID": "FF20_27_A4_AW1",
            "de": "mit Zugfunktion",
            "en-gb": "with pull-off function",
            "en-us": "with pull function",
            "es": "A tracción",
            "fr": "avec fonction de traction",
            "it": "con funzione di serraggio",
            "hu": "húzófunkcióval",
            "ru": "с функцией натяжения",
            "sk": "s funkciou tahu",
            "pl": "z funkcją odciągania"
        },
        {
            "ID": "FF20_27_A4_AW2",
            "de": "ohne Zugfunktion",
            "en-gb": "without pull-off function",
            "en-us": "without pull function",
            "es": "No apto para tracción",
            "fr": "sans fonction de traction",
            "it": "senza funzione di serraggio",
            "hu": "húzófunkció nélkül",
            "ru": "без функции натяжения",
            "sk": "bez funkcie tahu",
            "pl": "bez funkcji odciągania"
        },
        {
            "ID": "FF20_27_A5",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "FF20_27_A5_AW1",
            "de": "Ankerstab 15,0 unbehandelt",
            "en-gb": "Tie rod 15.0 non-treated",
            "en-us": "Tie rod 15.0 non-treated",
            "es": "Barra de ancl. 15,0 no tratada",
            "fr": "Tige d'ancrage 15,0 non traitée",
            "it": "Barra ancorante 15,0 non trattata",
            "hu": "Ankerrúd 15,0 kezeletlen",
            "ru": "Анкерный стержень 15,0 необработанный",
            "sk": "Kotev. tyč 15,0 neupravov.",
            "pl": "Ściąg 15,0 nieocynkowany"
        },
        {
            "ID": "FF20_27_A5_AW2",
            "de": "Ankerstab 15,0 verzinkt",
            "en-gb": "Tie rod 15.0 galvanised",
            "en-us": "Tie rod 15.0 galvanized",
            "es": "Barra de ancl. 15,0 galv.",
            "fr": "Tige d'ancrage 15,0 galvanisée",
            "it": "Barra ancorante 15,0 zincata",
            "hu": "Ankerrúd 15,0 horganyzott",
            "ru": "Анкерный стержень 15,0 оцинкованный",
            "sk": "Kotev. tyč 15,0 pozink.",
            "pl": "Ściąg 15,0 ocynkowany"
        },
        {
            "ID": "FL20_A1",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "FL20_A1_AW1",
            "de": "Stoßende Elemente",
            "en-gb": "Hitting panels",
            "en-us": "Hitting panels",
            "es": "Elementos a tope",
            "fr": "Px joints",
            "it": "Elementi a giunto",
            "hu": "csatlakozó elemek",
            "ru": "Стык элементов",
            "sk": "Styk panelov",
            "pl": "Płyty przylegające"
        },
        {
            "ID": "FL20_A1_AW2",
            "de": "Versetzte Elemente",
            "en-gb": "Shifted panels",
            "en-us": "Shifted panels",
            "es": "Elementos desplazados",
            "fr": "Px décalés",
            "it": "Elementi sfalsati",
            "hu": "elcsúsztatott elemek",
            "ru": "Смещенные элементы",
            "sk": "Presah panelov",
            "pl": "Płyty z przesunięciem"
        },
        {
            "ID": "FL20_A1_AW3",
            "de": "Gelenkecke",
            "en-gb": "Swiveling corner",
            "en-us": "Swiveling corner",
            "es": "Esqu. bisagra",
            "fr": "Angle articulé",
            "it": "Angolo a snodo",
            "hu": "Csukl. sarok",
            "ru": "Шарнирный угол",
            "sk": "Kĺbový roh",
            "pl": "Narożnik przegubowy"
        },
        {
            "ID": "FL20_A3",
            "de": "Stirnabschalung, Wandanschlüsse, Ausgleiche",
            "en-gb": "Stop-end, wall junctions, closures",
            "en-us": "Bulkhead, wall sockets, filler",
            "es": "Tape, Acometidas de muros, compensaciónes",
            "fr": "Coffrage d'about, connexions de mur, compensations",
            "it": "Chiusura di testa, Agganci per parete, Compensazioni",
            "hu": "Véglezárás, Falcsatlakozás, Kiegyenlítés",
            "ru": "Торцевая опалубка, стыки стен, компенсации",
            "sk": "Debnenie čela, napájanie stien, vyrovnania",
            "pl": "Szalunek czołowy, styk ścian, wypełnienia"
        },
        {
            "ID": "FL20_A3_AW1",
            "de": "P-Träger top",
            "en-gb": "P-beam top",
            "en-us": "P-beam top",
            "es": "P-viga top",
            "fr": "Poutrelle P top",
            "it": "Trave P top",
            "hu": "P-fatartó top",
            "ru": "P-балка top",
            "sk": "P-nosník top",
            "pl": "Dźwigar P top"
        },
        {
            "ID": "FL20_A3_AW3",
            "de": "P-Träger eco",
            "en-gb": "P-beam eco",
            "en-us": "P-beam eco",
            "es": "P-viga eco",
            "fr": "Poutrelle P eco",
            "it": "Trave P eco",
            "hu": "P-fatartó eco",
            "ru": "P-балка eco",
            "sk": "P-nosník eco",
            "pl": "Dźwigar P eco"
        },
        {
            "ID": "FL20_A3_AW5",
            "de": "P-Träger",
            "en-gb": "P-beam",
            "en-us": "P-beam",
            "es": "P-viga",
            "fr": "Poutrelle P",
            "it": "Trave P",
            "hu": "P-fatartó",
            "ru": "P-балка",
            "sk": "P-nosník",
            "pl": "Dźwigar P"
        },
        {
            "ID": "FL20_A3_AW6",
            "de": "Bohlen",
            "en-gb": "Planks",
            "en-us": "Planks",
            "es": "Tablones",
            "fr": "Planches",
            "it": "Tavoloni",
            "hu": "Palló",
            "ru": "Брусья",
            "sk": "Fošne",
            "pl": "Deski"
        },
        {
            "ID": "FL20_A4",
            "de": "Elementverbindung",
            "en-gb": "Panel connection",
            "en-us": "Panel connection",
            "es": "Unión para elemento",
            "fr": "Assemblage px",
            "it": "Giunzioni di elementi",
            "hu": "Elemkapcsolat",
            "ru": "Соединение элементов",
            "sk": "Panelová spojka",
            "pl": "Łączenie płyt"
        },
        {
            "ID": "FL20_A4_AW1",
            "de": "mit Verbinderlasche LZ",
            "en-gb": "with connector plate LZ",
            "en-us": "with connector plate LZ",
            "es": "Con Placa conectora LZ",
            "fr": "Avec éclisse LZ",
            "it": "con listello giunzione LZ",
            "hu": "Összeköto elem LZ",
            "ru": "с соединительной накладкой LZ",
            "sk": "s spojov. príložka LZ",
            "pl": "z płytką łączeniową LZ"
        },
        {
            "ID": "FL20_A4_AW2",
            "de": "mit Verbinderlasche L",
            "en-gb": "with connector plate L",
            "en-us": "with connector plate L",
            "es": "Con Placa conectora L",
            "fr": "Avec éclisse L",
            "it": "con listello giunzione L",
            "hu": "Összeköto elem L",
            "ru": "с соединительной накладкой L",
            "sk": "s spojov. príložka L",
            "pl": "z płytką łączeniową L"
        },
        {
            "ID": "FLEX124__A11",
            "de": "Dokaflex Unterzug Ausführung",
            "en-gb": "Dokaflex drop beam type",
            "en-us": "Dokaflex drop beam type",
            "es": "Diseño de jácenas Dokaflex",
            "fr": "Retombée poutre exécution Dokaflex",
            "it": "Dokaflex Trave Esecuzione",
            "hu": "Dokaflex gerenda kialakítás",
            "ru": "Исполнение прогона Dokaflex",
            "sk": "Dokaflex prievlaku vytvorenie",
            "pl": "Dokaflex – rodzaj podciągu"
        },
        {
            "ID": "FLEX124__A11_AW1",
            "de": "Balkenzwinge 20",
            "en-gb": "Beam forming support 20",
            "en-us": "Beam forming support 20",
            "es": "Soporte viga 20",
            "fr": "Équerre de poutre 20",
            "it": "Morsa a sq. per blocc. trave 20",
            "hu": "Gerendazsaluzó kaloda 20",
            "ru": "Балочный зажим 20",
            "sk": "Prievlaková klieština 20",
            "pl": "Zacisk belkowy 20"
        },
        {
            "ID": "FLEX124__A11_AW2",
            "de": "bauseitige Kanthölzer",
            "en-gb": "Squared timber by site",
            "en-us": "Squared timber by site",
            "es": "Listónes por parte de obra",
            "fr": "Bois chantier",
            "it": "Travi squadrate in cantiere",
            "hu": "Fabetét helyi anyag",
            "ru": "деревянные брусья (силами заказчика)",
            "sk": "Hranoly dod. stavby",
            "pl": "Kantówka (po stronie budowy)"
        },
        {
            "ID": "FLEX124__A11_AW3",
            "de": "H20 Träger variabel",
            "en-gb": "H20 beam variable",
            "en-us": "H20 Beam variable",
            "es": "H20 viga variable",
            "fr": "H20 poutrelle variable",
            "it": "Trave H20 variabili",
            "hu": "H20 variábilis fatartó",
            "ru": "H20-балки различные",
            "sk": "Variabilná nosník H20",
            "pl": "Dźwigar H20 - dł. zmienna"
        },
        {
            "ID": "FLEX124__A12",
            "de": "Schaltafel Größe",
            "en-gb": "Shutter Board dimension",
            "en-us": "Shutter Board dimension",
            "es": "Tamaño del tablero de encofrado",
            "fr": "Contreplaqué taille",
            "it": "Grandezza della tavola di casseratura",
            "hu": "Zsaluhéj méret",
            "ru": "Размер опалубочного щита",
            "sk": "Veľkosť dosky stola",
            "pl": "Wymiary deski szalunkowej"
        },
        {
            "ID": "FLEX124__A12_AW3",
            "de": "200/50 cm",
            "en-gb": "200/50 cm",
            "en-us": "200/50 cm",
            "es": "200/50 cm",
            "fr": "200/50 cm",
            "it": "200/50 cm",
            "hu": "200/50 cm",
            "ru": "200/50 cм",
            "sk": "200/50 cm",
            "pl": "200/50 cm"
        },
        {
            "ID": "FLEX124__A12_AW4",
            "de": "250/50 cm",
            "en-gb": "250/50 cm",
            "en-us": "250/50 cm",
            "es": "250/50 cm",
            "fr": "250/50 cm",
            "it": "250/50 cm",
            "hu": "250/50 cm",
            "ru": "250/50 cм",
            "sk": "250/50 cm",
            "pl": "250/50 cm"
        },
        {
            "ID": "FLEX124__A15",
            "de": "Dokaflex Stützenköpfe",
            "en-gb": "Dokaflex prop heads",
            "en-us": "Dokaflex prop heads",
            "es": "Cabezales Dokaflex",
            "fr": "Têtes d'étançon Dokaflex",
            "it": "Teste dei puntelli Dokaflex",
            "hu": "Dokaflex támaszfej",
            "ru": "Головки колонн Dokaflex",
            "sk": "Dokaflex hlavice podpier",
            "pl": "Dokaflex – głowice podpory"
        },
        {
            "ID": "FLEX124__A15_AW1",
            "de": "Vierwegkopf H20",
            "en-gb": "4-way head H20",
            "en-us": "4-way head H20",
            "es": "Cabeza de cuatro vías H20",
            "fr": "Tête en croix H20",
            "it": "Testa a 4 vie H20",
            "hu": "Villásfej H20",
            "ru": "Четырехходовая головка H20:",
            "sk": "Štvorcestná hlavica H20",
            "pl": "Głowica czterokierunkowa H20"
        },
        {
            "ID": "FLEX124__A15_AW2",
            "de": "Absenkkopf H20",
            "en-gb": "Lowering head H20",
            "en-us": "Lowering head H20",
            "es": "Cabeza de descimbrado H20",
            "fr": "Tête de décoffrage H20",
            "it": "Testa di disarmo H20",
            "hu": "Ejtofej H20",
            "ru": "Опускаемая головка H20",
            "sk": "Spúšťacia hlavica H20",
            "pl": "Głowica opuszczalna H20"
        },
        {
            "ID": "FLEX124__A15_AW3",
            "de": "Kopfgabel 12,5 cm",
            "en-gb": "U-head 12.5cm",
            "en-us": "U-head 12.5cm",
            "es": "Cabezal 12,5cm",
            "fr": "Tête à fourche 12,5cm",
            "it": "Forcella di testa 12,5 cm",
            "hu": "Villás fej 12,5 cm",
            "ru": "Вилка головки 12,5 см",
            "sk": "Hlavová vidlica 12,5 cm",
            "pl": "Widły do głowicy 12,5cm"
        },
        {
            "ID": "FLEX124__A15_AW4",
            "de": "Haltekopf H20",
            "en-gb": "Supporting head H20",
            "en-us": "Supporting head H20",
            "es": "Cabeza de soporte H20",
            "fr": "Tête de support H20",
            "it": "Testa d'appoggio H20",
            "hu": "Rugós tartófej H20",
            "ru": "Удерживающая головка H20",
            "sk": "Pridržovacia hlavica H20",
            "pl": "Głowica utrzymująca H20"
        },
        {
            "ID": "FLEX124__A15_AW5",
            "de": "Haltekopf H20 DF",
            "en-gb": "Supporting head H20 DF",
            "en-us": "Supporting head H20 DF",
            "es": "Cabeza de soporte H20 DF",
            "fr": "Tête de support H20 DF",
            "it": "Testa d'appoggio H20 DF",
            "hu": "Rugós tartófej H20 DF",
            "ru": "Удерживающая головка H20 DF",
            "sk": "Pridržovacia hlavica H20 DF",
            "pl": "Głowica utrzymująca H20 DF"
        },
        {
            "ID": "FLEX124__A16",
            "de": "Stützbeine",
            "en-gb": "Removable folding tripods",
            "en-us": "Removable folding tripods",
            "es": "Trípodes plegables",
            "fr": "Trépieds amovibles",
            "it": "Treppiedi amovibili",
            "hu": "Támasztólábak",
            "ru": "треноги",
            "sk": "Oporné trojnožky",
            "pl": "Trójnogi składane"
        },
        {
            "ID": "FLEX124__A16_AW1",
            "de": "Stützbein",
            "en-gb": "Removable folding tripod",
            "en-us": "Removable folding tripod",
            "es": "Trípode plegable",
            "fr": "Trépied amovible",
            "it": "Treppiede amovibile",
            "hu": "Támasztóláb",
            "ru": "Тренога",
            "sk": "Oporná trojnožka",
            "pl": "Trójnóg"
        },
        {
            "ID": "FLEX124__A16_AW2",
            "de": "Stützbein top",
            "en-gb": "Removable folding tripod top",
            "en-us": "Removable folding tripod top",
            "es": "Trípode plegable top",
            "fr": "Trépied amovible top",
            "it": "Treppiede amovibile top",
            "hu": "Támasztóláb top",
            "ru": "Тренога top",
            "sk": "Oporná trojnožka top",
            "pl": "Trójnóg top"
        },
        {
            "ID": "FLEX124__A17",
            "de": "Einschubträger",
            "en-gb": "Insertion beam",
            "en-us": "Insertion beam",
            "es": "Viga de extensión",
            "fr": "Poutrelle de compensation",
            "it": "Prolunga orizzontale",
            "hu": "Kiegészítéstartó",
            "ru": "Вдвижная балка",
            "sk": "Zasúvací nosník",
            "pl": "Dźwigar wsuwany"
        },
        {
            "ID": "FLEX124__A17_AW1",
            "de": "Einschubträger",
            "en-gb": "Insertion beam",
            "en-us": "Insertion beam",
            "es": "Viga de extensión",
            "fr": "Poutrelle de compensation",
            "it": "Prolunga orizzontale",
            "hu": "Kiegészítéstartó",
            "ru": "Вдвижная балка",
            "sk": "Zasúvací nosník",
            "pl": "Dźwigar wsuwany"
        },
        {
            "ID": "FLEX124__A17_AW2",
            "de": "Dokamatic-Einschubträger",
            "en-gb": "Dokamatic insertion beam",
            "en-us": "Dokamatic insertion beam",
            "es": "Viga de extensión Dokamatic",
            "fr": "Poutrelle de compensation Dokamatic",
            "it": "Prolunga orizzontale Dokamatic",
            "hu": "Dokamatic kihúzható tartó",
            "ru": "Dokamatic вдвижная балка",
            "sk": "Dokamatic-zasúvací nosník",
            "pl": "Dźwigar wsuwany Dokamatic"
        },
        {
            "ID": "FLEX124__A2",
            "de": "Deckentisch Ausführung",
            "en-gb": "Tableform type",
            "en-us": "Tableform type",
            "es": "Mesa del techo realisación",
            "fr": "Table exécution",
            "it": "Esecuzione per tavolo solaio",
            "hu": "Asztalzsalu kialakítás",
            "ru": "Исполнение опалубочного стола",
            "sk": "Stropný stôl-vytvorenie",
            "pl": "Typ stołu"
        },
        {
            "ID": "FLEX124__A2_AW1",
            "de": "Dokamatic-Tisch",
            "en-gb": "Dokamatic table",
            "en-us": "Dokamatic table",
            "es": "Mesa Dokamatic",
            "fr": "Table Dokamatic",
            "it": "Tavolo Dokamatic",
            "hu": "Dokamatic asztal",
            "ru": "Стол Dokamatic",
            "sk": "Dokamatic-stôl",
            "pl": "Stolik Dokamatic"
        },
        {
            "ID": "FLEX124__A2_AW2",
            "de": "Dokaflex-Tisch",
            "en-gb": "Dokaflex table",
            "en-us": "Dokaflex table",
            "es": "Mesa Dokaflex",
            "fr": "Table Dokaflex",
            "it": "Tavolo Dokaflex",
            "hu": "Dokaflex asztal",
            "ru": "Стол Dokaflex",
            "sk": "Dokaflex-stôl",
            "pl": "Stolik Dokaflex"
        },
        {
            "ID": "FLEX124__A3",
            "de": "Deckentisch Schalhaut",
            "en-gb": "Tableform plywood",
            "en-us": "Tableform plywood",
            "es": "Mesa del techo forro",
            "fr": "Table contreplaqué",
            "it": "Pannello per tavolo solaio",
            "hu": "Asztalzsalu zsaluhéj",
            "ru": "Опалубочный стол палуба",
            "sk": "Stropný stôl deb. doska",
            "pl": "Sklejka do stołu"
        },
        {
            "ID": "FLEX124__A3_AW1",
            "de": "3SO-21",
            "en-gb": "3SO-21",
            "en-us": "3SO-21",
            "es": "3SO-21",
            "fr": "3SO-21",
            "it": "3SO-21",
            "hu": "3SO-21",
            "ru": "3SO-21",
            "sk": "3SO-21",
            "pl": "3SO-21"
        },
        {
            "ID": "FLEX124__A3_AW2",
            "de": "3SO-27",
            "en-gb": "3SO-27",
            "en-us": "3SO-27",
            "es": "3SO-27",
            "fr": "3SO-27",
            "it": "3SO-27",
            "hu": "3SO-27",
            "ru": "3SO-27",
            "sk": "3SO-27",
            "pl": "3SO-27"
        },
        {
            "ID": "FLEX124__A3_AW3",
            "de": "ohne Schalhaut",
            "en-gb": "without plywood",
            "en-us": "without plywood",
            "es": "Sin forro",
            "fr": "sans contreplaqué",
            "it": "senza pannello",
            "hu": "zsaluhéj nélkül",
            "ru": "без палубы",
            "sk": "bez deb. doska",
            "pl": "bez sklejki"
        },
        {
            "ID": "FLEX124__A4",
            "de": "Deckentisch Unterstellung",
            "en-gb": "Tableform shoring",
            "en-us": "Tableform shoring",
            "es": "Mesa del techo partes inferiores",
            "fr": "Table étaiement",
            "it": "Puntellazione per tavolo solaio",
            "hu": "Asztalzsalu Alátámasztás",
            "ru": "Опалубочный стол основание",
            "sk": "Stropný stôl-podopretie",
            "pl": "System podpór stołu"
        },
        {
            "ID": "FLEX124__A4_AW1",
            "de": "Eurex 20",
            "en-gb": "Eurex 20",
            "en-us": "Eurex 20",
            "es": "Eurex 20",
            "fr": "Eurex 20",
            "it": "Eurex 20",
            "hu": "Eurex 20",
            "ru": "Eurex 20",
            "sk": "Eurex 20",
            "pl": "Eurex 20"
        },
        {
            "ID": "FLEX124__A4_AW2",
            "de": "Eurex 30",
            "en-gb": "Eurex 30",
            "en-us": "Eurex 30",
            "es": "Eurex 30",
            "fr": "Eurex 30",
            "it": "Eurex 30",
            "hu": "Eurex 30",
            "ru": "Eurex 30",
            "sk": "Eurex 30",
            "pl": "Eurex 30"
        },
        {
            "ID": "FLEX124__A4_AW3",
            "de": "Stütze G",
            "en-gb": "Column G",
            "en-us": "Column G",
            "es": "Puntal G",
            "fr": "Etançon G",
            "it": "Puntello G",
            "hu": "Támasz G",
            "ru": "Колонна G",
            "sk": "Podpera G",
            "pl": "Podpora G"
        },
        {
            "ID": "FLEX124__A4_AW4",
            "de": "Stütze N",
            "en-gb": "Column N",
            "en-us": "Column N",
            "es": "Puntal N",
            "fr": "Etançon N",
            "it": "Puntello N",
            "hu": "Támasz N",
            "ru": "Колонна N",
            "sk": "Podpera N",
            "pl": "Podpora N"
        },
        {
            "ID": "FLEX124__A4_AW5",
            "de": "Stütze ECO",
            "en-gb": "Column ECO",
            "en-us": "Column ECO",
            "es": "Puntal Eco",
            "fr": "Etançon ECO",
            "it": "Puntello eco",
            "hu": "Támasz ECO",
            "ru": "Колонна ЕСО",
            "sk": "Podpera eco",
            "pl": "Podpora ECO"
        },
        {
            "ID": "FLEX124__A4_AW6",
            "de": "Staxo",
            "en-gb": "Staxo",
            "en-us": "Staxo",
            "es": "Staxo",
            "fr": "Staxo",
            "it": "Staxo",
            "hu": "Staxo",
            "ru": "Staxo",
            "sk": "Staxo",
            "pl": "Staxo"
        },
        {
            "ID": "FLEX124__A4_AW7",
            "de": "Aluxo",
            "en-gb": "Aluxo",
            "en-us": "Aluxo",
            "es": "Aluxo",
            "fr": "Aluxo",
            "it": "Aluxo",
            "hu": "Aluxo",
            "ru": "Aluxo",
            "sk": "Aluxo",
            "pl": "Aluxo"
        },
        {
            "ID": "FLEX124__A4_AW8",
            "de": "Eurex 20 top",
            "en-gb": "Eurex 20 top",
            "en-us": "Eurex 20 top",
            "es": "Eurex 20 top",
            "fr": "Eurex 20 top",
            "it": "Eurex 20 top",
            "hu": "Eurex 20 top",
            "ru": "Eurex 20 top",
            "sk": "Eurex 20 top",
            "pl": "Eurex 20 top"
        },
        {
            "ID": "FLEX124__A4_AW9",
            "de": "Eurex 30 top",
            "en-gb": "Eurex 30 top",
            "en-us": "Eurex 30 top",
            "es": "Eurex 30 top",
            "fr": "Eurex 30 top",
            "it": "Eurex 30 top",
            "hu": "Eurex 30 top",
            "ru": "Eurex 30 top",
            "sk": "Eurex 30 top",
            "pl": "Eurex 30 top"
        },
        {
            "ID": "FLEX124__A5",
            "de": "Deckentisch Unterstellung Randstützen",
            "en-gb": "Tableform Shoring Edge props",
            "en-us": "Tableform Shoring Edge props",
            "es": "Mesa del techo partes inferiores puntales marginales",
            "fr": "Table étaiement étain de poutrelle de rive",
            "it": "Puntellazione per tavolo solaio Puntelli per i bordi",
            "hu": "Asztalzsalu Alátámasztás Szélso támasz",
            "ru": "Опалубочный стол основание краевые колонны",
            "sk": "Stropný stôl-podopretie okrajové podpery",
            "pl": "Stół – podpory krawędzi systemu podpór"
        },
        {
            "ID": "FLEX124__A5_AW1",
            "de": "Standard",
            "en-gb": "Standard",
            "en-us": "Standard",
            "es": "Estándar",
            "fr": "Standard",
            "it": "Standard",
            "hu": "Standard",
            "ru": "Стандарт",
            "sk": "Standard",
            "pl": "Standard"
        },
        {
            "ID": "FLEX124__A5_AW2",
            "de": "RS versetzt, 1ZS mit Schwenkkopf",
            "en-gb": "EP repositioned, 1IP with swivel head",
            "en-us": "RS shifted, 1ZS with swivel head",
            "es": "RS desplazado,1PI con cabezal abatible",
            "fr": "EB déplacé, 1 EI avec tête orientable",
            "it": "PP spostato, 1PI con testa d'inclinazione",
            "hu": "SZT áthelyezve, 1KT billentofejjel",
            "ru": "RS смещенный, 1ZS с поворотной головкой",
            "sk": "OP odsadená, 1MP s výkyvnou hlavicou",
            "pl": "Zmiana położenia EP, 1 IP z głowicą obrotową"
        },
        {
            "ID": "FLEX124__A5_AW3",
            "de": "RS versetzt, 1ZS mit Stützenanschluss",
            "en-gb": "EP repositioned, 1IP with strut connection",
            "en-us": "RS shifted, 1ZS with strut connection",
            "es": "RS desplazado,1PI con cabezal con. de punt.",
            "fr": "EB déplacé, 1 EI avec raccord d'étai",
            "it": "PP spostato, 1PI con collegamento",
            "hu": "SZT áthelyezve, 1KT támaszcsatlakozóval",
            "ru": "RS смещенный, 1ZS с переходником для колонны",
            "sk": "OP odsadená, 1MP so spojkou podpery",
            "pl": "Zmiana położenia EP, 1 IP z połączeniem podpory"
        },
        {
            "ID": "FLEX124__A5_AW4",
            "de": "RS versetzt, 2ZS mit Schwenkkopf",
            "en-gb": "EP repositioned, 2IP with swivel head",
            "en-us": "RS shifted, 2ZS with swivel head",
            "es": "RS desplazado,2PI con cabezal abatible",
            "fr": "EB déplacé, 2 EI avec tête orientable",
            "it": "PP spostato, 2PI con testa d'inclinazione",
            "hu": "SZT áthelyezve, 2KT billentofejjel",
            "ru": "RS смещенный, 2ZS с поворотной головкой",
            "sk": "OP odsadená, 2MP s výkyvnou hlavicou",
            "pl": "Zmiana położenia EP, 2 IP z głowicą obrotową"
        },
        {
            "ID": "FLEX124__A5_AW5",
            "de": "RS versetzt, 2ZS mit Stützenanschluss",
            "en-gb": "EP repositioned, 2IP with strut connection",
            "en-us": "RS shifted, 2ZS with strut connection",
            "es": "RS desplazado,2PI con cabezal con. de punt.",
            "fr": "EB déplacé, 2 EI avec raccord d'étai",
            "it": "PP spostato, 2PI con collegamento",
            "hu": "SZT áthelyezve, 2KT támaszcsatlakozóval",
            "ru": "RS смещенный, 2ZS с переходником для колонны",
            "sk": "OP odsadená, 2MP so spojkou podpery",
            "pl": "Zmiana położenia EP, 2 IP z połączeniem podpory"
        },
        {
            "ID": "FLEX124__A6",
            "de": "Deckentisch Last-/Fußspindel",
            "en-gb": "Tableform heavy duty screw jack/screw jack foot",
            "en-us": "Floor table heavy duty screw jack/screw jack foot",
            "es": "Mesa del techo gato atornillable/pie con husillo",
            "fr": "Table de plancher vérin rep/vérin pied",
            "it": "Piede / puntello registrabile per tavolo solaio",
            "hu": "Asztalzsalu Teherhordó orsó/Láborsó",
            "ru": "Опалубочный стол шпиндель повышенной нагрузки/опорный шпиндель",
            "sk": "Stropný stôl vret.noha/pätk.vret",
            "pl": "Stół – dźwignik śrubowy o dużej wytrzymałości / podstawa dźwignika śrubowego"
        },
        {
            "ID": "FLEX124__A6_AW1",
            "de": "Lastspindel",
            "en-gb": "Heavy duty screw jack",
            "en-us": "Heavy duty screw jack",
            "es": "Gato atornillable",
            "fr": "Vérin rep",
            "it": "Puntello regolabile",
            "hu": "Teherhordó orsó",
            "ru": "Шпиндель повышенной нагрузки",
            "sk": "Vret.noha",
            "pl": "Stopa regulowana wzmocniona"
        },
        {
            "ID": "FLEX124__A6_AW2",
            "de": "Fußspindel",
            "en-gb": "Screw jack foot",
            "en-us": "Screw jack foot",
            "es": "Pie con husillo",
            "fr": "Vérin pied",
            "it": "Piede registrabile",
            "hu": "Láborsó",
            "ru": "Опорный шпиндель",
            "sk": "Pätk.vret",
            "pl": "Stopa regulowana"
        },
        {
            "ID": "FLEX124__A6_AW3",
            "de": "ohne Spindel",
            "en-gb": "without spindle",
            "en-us": "without spindle",
            "es": "Sin husillo",
            "fr": "sans vérin",
            "it": "senza puntello",
            "hu": "orsó nélkül",
            "ru": "без шпинделя",
            "sk": "bez vretena",
            "pl": "bez wrzeciona"
        },
        {
            "ID": "FLEX124__A7",
            "de": "Schaltafel Typ",
            "en-gb": "Shutter Board type",
            "en-us": "Shutter Board type",
            "es": "Tipo di tablero de encofrado",
            "fr": "Type de panneau",
            "it": "Tipo tavola di casseratura",
            "hu": "Zsaluhéj típus",
            "ru": "Тип опалубочного щита",
            "sk": "Typ dosky stola",
            "pl": "Rodzaj deski szalunkowej"
        },
        {
            "ID": "FLEX124__A7_AW1",
            "de": "Dokadur-Paneel 21",
            "en-gb": "Dokadur panel 21",
            "en-us": "Dokadur panel 21",
            "es": "Panel Dokadur 21",
            "fr": "Panneau Dokadur 21",
            "it": "Pannello Dokadur 21",
            "hu": "Dokadur tábla 21",
            "ru": "Dokadur панель 21",
            "sk": "Dokadur panel 21",
            "pl": "Panel Dokadur 21"
        },
        {
            "ID": "FLEX124__A7_AW10",
            "de": "Schalungsplatte 3S basic 21mm",
            "en-gb": "Formwork sheet 3S basic 21mm",
            "en-us": "Formwork sheet 3S basic 21mm",
            "es": "Tablero de encofrado 3S basic 21mm",
            "fr": "Panneau 3S basic 21mm",
            "it": "Pannello 3S basic 21mm",
            "hu": "Zsaluhéj 3S basic 21mm",
            "ru": "Опалубочная плита 3S basic 21мм",
            "sk": "Debniaca doska 3S basic 21mm",
            "pl": "Sklejka 3S basic 21mm"
        },
        {
            "ID": "FLEX124__A7_AW11",
            "de": "Schalungsplatte 3S cut 21mm",
            "en-gb": "Formwork sheet 3S cut 21mm",
            "en-us": "Formwork sheet 3S cut 21mm",
            "es": "Tablero de encofrado 3S cut 21mm",
            "fr": "Panneau 3S cut 21mm",
            "it": "Pannello 3S cut 21mm",
            "hu": "Zsaluhéj 3S cut 21mm",
            "ru": "Опалубочная плита 3S cut 21мм",
            "sk": "Debniaca doska 3S cut 21mm",
            "pl": "Sklejka 3S cut 21mm"
        },
        {
            "ID": "FLEX124__A7_AW12",
            "de": "Schalungsplatte 3S basic 27mm",
            "en-gb": "Formwork sheet 3S basic 27mm",
            "en-us": "Formwork sheet 3S basic 27mm",
            "es": "Tablero de encofrado 3S basic 27mm",
            "fr": "Panneau 3S basic 27mm",
            "it": "Pannello 3S basic 27mm",
            "hu": "Zsaluhéj 3S basic 27mm",
            "ru": "Опалубочная плита 3S basic 27мм",
            "sk": "Debniaca doska 3S basic 27mm",
            "pl": "Sklejka 3S basic 27mm"
        },
        {
            "ID": "FLEX124__A7_AW13",
            "de": "Schalungsplatte 3S cut 27mm",
            "en-gb": "Formwork sheet 3S cut 27mm",
            "en-us": "Formwork sheet 3S cut 27mm",
            "es": "Tablero de encofrado 3S cut 27mm",
            "fr": "Panneau 3S cut 27mm",
            "it": "Pannello 3S cut 27mm",
            "hu": "Zsaluhéj 3S cut 27mm",
            "ru": "Опалубочная плита 3S cut 27мм",
            "sk": "Debniaca doska 3S cut 27mm",
            "pl": "Sklejka 3S cut 27mm"
        },
        {
            "ID": "FLEX124__A7_AW2",
            "de": "Schalungsplatte 3-SO 21 mm",
            "en-gb": "Formwork sheet 3-SO 21 mm",
            "en-us": "Formwork sheet 3-SO 21mm",
            "es": "Tablero de encofrado 3-SO 21mm",
            "fr": "Panneau 3-SO21mm",
            "it": "Pannello 3-SO 21mm",
            "hu": "Zsaluhéj 3-SO 21mm",
            "ru": "Опалубочная плита 3-SO 21мм",
            "sk": "Debniaca doska 3-SO 21mm",
            "pl": "Sklejka 3-SO 21 mm"
        },
        {
            "ID": "FLEX124__A7_AW3",
            "de": "Dokadur-Plex Paneel 21",
            "en-gb": "Dokadur-Plex panel 21",
            "en-us": "Dokadur-Plex panel 21",
            "es": "Dokadur-Plex panel 21",
            "fr": "Panneau Dokadur-Plex 21",
            "it": "Pannello Dokadur-Plex 21",
            "hu": "Dokadur-Plex tábla 21",
            "ru": "Dokadur-Plex панель 21",
            "sk": "Dokadur-Plex panel 21",
            "pl": "Panel Dokadur Plex 21"
        },
        {
            "ID": "FLEX124__A7_AW5",
            "de": "Dokaflex-Tischplatte",
            "en-gb": "Dokaflex table sheet",
            "en-us": "Dokaflex table sheet",
            "es": "Dokaflex Tablero para mesa",
            "fr": "Dessus de table Dokaflex",
            "it": "Pannello tavolo Dokaflex",
            "hu": "Dokaflex-asztalhéj",
            "ru": "Опалубочная плита Dokaflex",
            "sk": "Dokaflex-doska stola",
            "pl": "Dokaflex – sklejka stołu"
        },
        {
            "ID": "FLEX124__A7_AW6",
            "de": "Dokadur-Paneel 27",
            "en-gb": "Dokadur panel 27",
            "en-us": "Dokadur panel 27",
            "es": "Dokadur panel 27",
            "fr": "Panneau Dokadur 27",
            "it": "Pannello Dokadur 27",
            "hu": "Dokadur tábla 27",
            "ru": "Dokadur панель 27",
            "sk": "Dokadur panel 27",
            "pl": "Panel Dokadur 21"
        },
        {
            "ID": "FLEX124__A7_AW7",
            "de": "Schalungsplatte 3-SO 27 mm",
            "en-gb": "Formwork sheet 3-SO 27 mm",
            "en-us": "Formwork sheet 3-SO 27mm",
            "es": "Tablero de encofrado 3-SO 27mm",
            "fr": "Panneau 3-SO 27mm",
            "it": "Pannello 3-SO 27mm",
            "hu": "Zsaluhéj 3-SO 27mm",
            "ru": "Опалубочная плита 3-SO 27мм",
            "sk": "Debniaca doska 3-SO 27mm",
            "pl": "Sklejka  3-SO 27mm"
        },
        {
            "ID": "FLEX124__A8",
            "de": "Schalungsträger Typ",
            "en-gb": "Formwork beam type",
            "en-us": "Formwork beam type",
            "es": "Tipo di viga de madera",
            "fr": "Type de poutrelle",
            "it": "Tipo trave",
            "hu": "Tartó típus",
            "ru": "Тип опалубочной балки",
            "sk": "Typ debniaceho nosníka",
            "pl": "Typ dźwigara"
        },
        {
            "ID": "FLEX124__A8_AW1",
            "de": "Träger H20 top P",
            "en-gb": "Beam H20 top P",
            "en-us": "Beam H20 top P",
            "es": "Viga H20 top P",
            "fr": "Poutrelle H20 top P",
            "it": "Trave H20 top P",
            "hu": "Fatartó H20 top P",
            "ru": "Балка H20 top P",
            "sk": "Nosník H20 top P",
            "pl": "Dźwigar H20 top P"
        },
        {
            "ID": "FLEX124__A8_AW2",
            "de": "Träger H20 top N",
            "en-gb": "Beam H20 top N",
            "en-us": "Beam H20 top N",
            "es": "Viga H20 top N",
            "fr": "Poutrelle H20 top N",
            "it": "Trave H20 top N",
            "hu": "Fatartó H20 top N",
            "ru": "Балка H20 top N",
            "sk": "Nosník H20 top N",
            "pl": "Dźwigar H20 top N"
        },
        {
            "ID": "FLEX124__A8_AW3",
            "de": "Träger H20 eco P",
            "en-gb": "Beam H20 eco P",
            "en-us": "Beam H20 eco P",
            "es": "Viga H20 eco P",
            "fr": "Poutrelle H20 eco P",
            "it": "Trave H20 eco P",
            "hu": "Fatartó H20 eco P",
            "ru": "Балка H20 eco P",
            "sk": "Nosník H20 eco P",
            "pl": "Dźwigar H20 eco P"
        },
        {
            "ID": "FLEX124__A8_AW4",
            "de": "Träger H20 eco N",
            "en-gb": "Beam H20 eco N",
            "en-us": "Beam H20 eco N",
            "es": "Viga H20 eco N",
            "fr": "Poutrelle H20 eco N",
            "it": "Trave H20 eco N",
            "hu": "Fatartó H20 eco N",
            "ru": "Балка H20 eco N",
            "sk": "Nosník H20 eco N",
            "pl": "Dźwigar H20 eco N"
        },
        {
            "ID": "FLEX124__A8_AW5",
            "de": "Träger H20 Niet P",
            "en-gb": "Beam H20 rivet P",
            "en-us": "Beam H20 rivet P",
            "es": "Viga H20 remache P",
            "fr": "Poutrelle H20 rivet P",
            "it": "Trave H20 Niet P",
            "hu": "Fatartó H20 P szegecselt végű",
            "ru": "Балка H20 заклепка P",
            "sk": "Nosník H20 P s nitmi",
            "pl": "Dźwigar H20 rivet P"
        },
        {
            "ID": "FLEX124__A8_AW6",
            "de": "Träger H20 Niet N",
            "en-gb": "Beam H20 rivet N",
            "en-us": "Beam H20 rivet N",
            "es": "Viga H20 remache N",
            "fr": "Poutrelle H20 rivet N",
            "it": "Trave H20 Niet N",
            "hu": "Fatartó H20 N szegecselt végű",
            "ru": "Балка H20 заклепка N",
            "sk": "Nosník H20 N s nitmi",
            "pl": "Dźwigar H20 rivet N"
        },
        {
            "ID": "FLEX124__A8_AW7",
            "de": "Kantholz bauseits",
            "en-gb": "Squared timber by site",
            "en-us": "Squared timber by site",
            "es": "Listón por parte de obra",
            "fr": "Bois équarri chantier",
            "it": "Legno squadrato a carico cant.",
            "hu": "Furfa helyi anyag",
            "ru": "Деревянный брус (предоставляется заказчиком)",
            "sk": "Hranol dod. stavby",
            "pl": "Kantówka (po stronie budowy)"
        },
        {
            "ID": "FLEX124__A9",
            "de": "Dokaflex Unterstellung",
            "en-gb": "Dokaflex shoring",
            "en-us": "Dokaflex shoring",
            "es": "Apoyo Dokaflex",
            "fr": "Etaiement Dokaflex",
            "it": "Puntellazione Dokaflex",
            "hu": "Dokaflex Alátámasztás",
            "ru": "Основание Dokaflex",
            "sk": "Dokaflex podopretie",
            "pl": "Dokaflex system podpór"
        },
        {
            "ID": "FLEX124__A9_AW1",
            "de": "Eurex 20",
            "en-gb": "Eurex 20",
            "en-us": "Eurex 20",
            "es": "Eurex 20",
            "fr": "Eurex 20",
            "it": "Eurex 20",
            "hu": "Eurex 20",
            "ru": "Eurex 20",
            "sk": "Eurex 20",
            "pl": "Eurex 20"
        },
        {
            "ID": "FLEX124__A9_AW2",
            "de": "Eurex 30",
            "en-gb": "Eurex 30",
            "en-us": "Eurex 30",
            "es": "Eurex 30",
            "fr": "Eurex 30",
            "it": "Eurex 30",
            "hu": "Eurex 30",
            "ru": "Eurex 30",
            "sk": "Eurex 30",
            "pl": "Eurex 30"
        },
        {
            "ID": "FLEX124__A9_AW3",
            "de": "Eurex 60",
            "en-gb": "Eurex 60",
            "en-us": "Eurex 60",
            "es": "Eurex 60",
            "fr": "Eurex 60",
            "it": "Eurex 60",
            "hu": "Eurex 60",
            "ru": "Eurex 60",
            "sk": "Eurex 60",
            "pl": "Eurex 60"
        },
        {
            "ID": "FLEX124__A9_AW4",
            "de": "Stütze G",
            "en-gb": "Column G",
            "en-us": "Column G",
            "es": "Puntal G",
            "fr": "Etançon G",
            "it": "Puntello G",
            "hu": "Támasz G",
            "ru": "Колонна G",
            "sk": "Podpera G",
            "pl": "Podpora G"
        },
        {
            "ID": "FLEX124__A9_AW5",
            "de": "Stütze N",
            "en-gb": "Column N",
            "en-us": "Column N",
            "es": "Puntal N",
            "fr": "Etançon N",
            "it": "Puntello N",
            "hu": "Támasz N",
            "ru": "Колонна N",
            "sk": "Podpera N",
            "pl": "Podpora N"
        },
        {
            "ID": "FLEX124__A9_AW6",
            "de": "Stütze ECO",
            "en-gb": "Column ECO",
            "en-us": "Column ECO",
            "es": "Puntal Eco",
            "fr": "Etançon ECO",
            "it": "Puntello eco",
            "hu": "Támasz ECO",
            "ru": "Колонна ЕСО",
            "sk": "Podpera ECO",
            "pl": "Podpora ECO"
        },
        {
            "ID": "FLEX124__A9_AW7",
            "de": "Eurex 20 top",
            "en-gb": "Eurex 20 top",
            "en-us": "Eurex 20 top",
            "es": "Eurex 20 top",
            "fr": "Eurex 20 top",
            "it": "Eurex 20 top",
            "hu": "Eurex 20 top",
            "ru": "Eurex 20 top",
            "sk": "Eurex 20 top",
            "pl": "Eurex 20 top"
        },
        {
            "ID": "FLEX124__A9_AW8",
            "de": "Eurex 30 top",
            "en-gb": "Eurex 30 top",
            "en-us": "Eurex 30 top",
            "es": "Eurex 30 top",
            "fr": "Eurex 30 top",
            "it": "Eurex 30 top",
            "hu": "Eurex 30 top",
            "ru": "Eurex 30 top",
            "sk": "Eurex 30 top",
            "pl": "Eurex 30 top"
        },
        {
            "ID": "FLEX124__A9_AW9",
            "de": "Eurex 20 eco",
            "en-gb": "Eurex 20 eco",
            "en-us": "Eurex 20 eco",
            "es": "Eurex 20 eco",
            "fr": "Eurex 20 eco",
            "it": "Eurex 20 eco",
            "hu": "Eurex 20 eco",
            "ru": "Eurex 20 eco",
            "sk": "Eurex 20 eco",
            "pl": "Eurex 20 eco"
        },
        {
            "ID": "FLEX15__A1",
            "de": "Schaltafel Typ/Maße",
            "en-gb": "Plywood type/dimensions",
            "en-us": "Plywood type/dimensions",
            "es": "Viga de extensión",
            "fr": "Type de panneau/dimension",
            "it": "Tipo tavola di casseratura/misure",
            "hu": "Zsaluhéj típus/Maße",
            "ru": "Тип опалубочного щита/Maße",
            "sk": "Typ dosky stola/Maße",
            "pl": "Rodzaj/wymiary sklejki"
        },
        {
            "ID": "FLEX15__A1_AW1",
            "de": "DokaPly eco 18mm 250/125 cm",
            "en-gb": "DokaPly eco 18mm 250/125 cm",
            "en-us": "DokaPly eco 18mm 250/125 cm",
            "es": "DokaPly eco 18mm 250/125 cm",
            "fr": "DokaPly eco 18mm 250/125 cm",
            "it": "DokaPly eco 18mm 250/125 cm",
            "hu": "DokaPly eco 18mm 250/125 cm",
            "ru": "DokaPly eco 18mm 250/125 cм",
            "sk": "DokaPly eco 18mm 250/125 cm",
            "pl": "DokaPly eco 18mm 250/125 cm"
        },
        {
            "ID": "FLEX15__A1_AW10",
            "de": "Schalungsplatte 3S cut 21mm 250/50cm",
            "en-gb": "Formwork sheet 3S cut 21mm 250/50cm",
            "en-us": "Formwork sheet 3S cut 21mm 250/50cm",
            "es": "Tablero de encofrado 3S cut 21mm 250/50cm",
            "fr": "Panneau 3S cut 21mm 250/50cm",
            "it": "Pannello 3S cut 21mm 250/50cm",
            "hu": "Zsaluhéj 3S cut 21mm 250/50cm",
            "ru": "Опалубочная плита 3S cut 21мм 250/50см",
            "sk": "Debniaca doska 3S cut 21mm 250/50cm",
            "pl": "Sklejka 3S cut 21mm 250/50cm"
        },
        {
            "ID": "FLEX15__A1_AW2",
            "de": "DokaPly eco 21mm 250/125 cm",
            "en-gb": "DokaPly eco 21mm 250/125 cm",
            "en-us": "DokaPly eco 21mm 250/125 cm",
            "es": "DokaPly eco 21mm 250/125 cm",
            "fr": "DokaPly eco 21mm 250/125 cm",
            "it": "DokaPly eco 21mm 250/125 cm",
            "hu": "DokaPly eco 21mm 250/125 cm",
            "ru": "DokaPly eco 21mm 250/125 cм",
            "sk": "DokaPly eco 21mm 250/125 cm",
            "pl": "DokaPly eco 21mm 250/125 cm"
        },
        {
            "ID": "FLEX15__A1_AW3",
            "de": "Schalungsplatte 3-SO 21mm 200/50 cm",
            "en-gb": "Formwork sheet 3-SO 21mm 200/50 cm",
            "en-us": "Formwork sheet 3-SO 21mm 200/50 cm",
            "es": "Tablero de encofrado 3-SO 21mm 200/50 cm",
            "fr": "Panneau 3-SO 21mm 200/50 cm",
            "it": "Panello 3-SO 21mm 200/50 cm",
            "hu": "Zsaluhéj 3-SO 21mm 200/50 cm",
            "ru": "Oпалубочная плита 3-SO 21мм 200/50cм",
            "sk": "Debniaca doska 3-SO 21mm 200/50 cm",
            "pl": "Sklejka 3-SO 21mm 200/50 cm"
        },
        {
            "ID": "FLEX15__A1_AW4",
            "de": "Schalungsplatte 3-SO 21mm 250/50 cm",
            "en-gb": "Formwork sheet 3-SO 21mm 250/50 cm",
            "en-us": "Formwork sheet 3-SO 21mm 250/50 cm",
            "es": "Tablero de encofrado 3-SO 21mm 250/50 cm",
            "fr": "Panneau 3-SO 21mm 250/50 cm",
            "it": "Panello 3-SO 21mm 250/50 cm",
            "hu": "Zsaluhéj 3-SO 21mm 250/50 cm",
            "ru": "Oпалубочная плита 3-SO 21мм 250/50cм",
            "sk": "Debniaca doska 3-SO 21mm 250/50 cm",
            "pl": "Sklejka 3-SO 21mm 250/50 cm"
        },
        {
            "ID": "FLEX15__A1_AW5",
            "de": "Schalungsplatte 3-SO 21mm 250/125 cm",
            "en-gb": "Formwork sheet 3-SO 21mm 250/125 cm",
            "en-us": "Formwork sheet 3-SO 21mm 250/125 cm",
            "es": "Tablero de encofrado 3-SO 21mm 250/125 cm",
            "fr": "Panneau 3-SO 21mm 250/125 cm",
            "it": "Panello 3-SO 21mm 250/125 cm",
            "hu": "Zsaluhéj 3-SO 21mm 250/125 cm",
            "ru": "Oпалубочная плита 3-SO 21мм 250/125cм",
            "sk": "Debniaca doska 3-SO 21mm 250/125 cm",
            "pl": "Sklejka 3-SO 21mm 250/125 cm"
        },
        {
            "ID": "FLEX15__A1_AW6",
            "de": "Schalungsplatte 3-SO 21mm 244/122 cm",
            "en-gb": "Formwork sheet 3-SO 21mm 244/122 cm",
            "en-us": "Formwork sheet 3-SO 21mm 244/122 cm",
            "es": "Tablero de encofrado 3-SO 21mm 244/122 cm",
            "fr": "Panneau 3-SO 21mm 244/122 cm",
            "it": "Panello 3-SO 21mm 244/122 cm",
            "hu": "Zsaluhéj 3-SO 21mm 244/122 cm",
            "ru": "Oпалубочная плита 3-SO 21мм 244/122cм",
            "sk": "Debniaca doska 3-SO 21mm 244/122 cm",
            "pl": "Sklejka 3-SO 21mm 244/122 cm"
        },
        {
            "ID": "FLEX15__A1_AW7",
            "de": "Schalungsplatte 3S basic 21mm 200/50cm",
            "en-gb": "Formwork sheet 3S basic 21mm 200/50cm",
            "en-us": "Formwork sheet 3S basic 21mm 200/50cm",
            "es": "Tablero de encofrado 3S basic 21mm 200/50cm",
            "fr": "Panneau 3S basic 21mm 200/50cm",
            "it": "Pannello 3S basic 21mm 200/50cm",
            "hu": "Zsaluhéj 3S basic 21mm 200/50cm",
            "ru": "Опалубочная плита 3S basic 21мм 200/50см",
            "sk": "Debniaca doska 3S basic 21mm 200/50cm",
            "pl": "Sklejka 3S basic 21mm 200/50cm"
        },
        {
            "ID": "FLEX15__A1_AW8",
            "de": "Schalungsplatte 3S basic 21mm 250/50cm",
            "en-gb": "Formwork sheet 3S basic 21mm 250/50cm",
            "en-us": "Formwork sheet 3S basic 21mm 250/50cm",
            "es": "Tablero de encofrado 3S basic 21mm 250/50cm",
            "fr": "Panneau 3S basic 21mm 250/50cm",
            "it": "Pannello 3S basic 21mm 250/50cm",
            "hu": "Zsaluhéj 3S basic 21mm 250/50cm",
            "ru": "Опалубочная плита 3S basic 21мм 250/50см",
            "sk": "Debniaca doska 3S basic 21mm 250/50cm",
            "pl": "Sklejka 3S basic 21mm 250/50cm"
        },
        {
            "ID": "FLEX15__A1_AW9",
            "de": "Schalungsplatte 3S cut 21mm 200/50cm",
            "en-gb": "Formwork sheet 3S cut 21mm 200/50cm",
            "en-us": "Formwork sheet 3S cut 21mm 200/50cm",
            "es": "Tablero de encofrado 3S cut 21mm 200/50cm",
            "fr": "Panneau 3S cut 21mm 200/50cm",
            "it": "Pannello 3S cut 21mm 200/50cm",
            "hu": "Zsaluhéj 3S cut 21mm 200/50cm",
            "ru": "Опалубочная плита 3S cut 21мм 200/50см",
            "sk": "Debniaca doska 3S cut 21mm 200/50cm",
            "pl": "Sklejka 3S cut 21mm 200/50cm"
        },
        {
            "ID": "FLEX15__A2",
            "de": "Schalungsträger Typ",
            "en-gb": "Formwork beam type",
            "en-us": "Formwork beam type",
            "es": "Tipo di viga de madera",
            "fr": "Type de poutrelle",
            "it": "Tipo trave",
            "hu": "Tartó típus",
            "ru": "Тип опалубочной балки",
            "sk": "Typ debniaceho nosníka",
            "pl": "Typ dźwigara"
        },
        {
            "ID": "FLEX15__A2_AW1",
            "de": "Träger H16 P",
            "en-gb": "Beam H16 P",
            "en-us": "Beam H16 P",
            "es": "Viga H16 P",
            "fr": "Poutrelle H16 P",
            "it": "Trave H16 P",
            "hu": "Fatartó H16 P",
            "ru": "Балка H16 P",
            "sk": "Nosník H16 P",
            "pl": "Dźwigar H16 P"
        },
        {
            "ID": "FLEX15__A2_AW2",
            "de": "Träger H16 N",
            "en-gb": "Beam H16 N",
            "en-us": "Beam H16 N",
            "es": "Viga H16 N",
            "fr": "Poutrelle H16 N",
            "it": "Trave H16 N",
            "hu": "Fatartó H16 N",
            "ru": "Балка H16 N",
            "sk": "Nosník H16 N",
            "pl": "Dźwigar H16 N"
        },
        {
            "ID": "FLEX15__A3",
            "de": "Querträgerlänge Deckenfeld",
            "en-gb": "Secondary beam length for slab polygon",
            "en-us": "Joist length for slab polygon",
            "es": "Largo de vigas transversales para sector de techo",
            "fr": "Longueur poutrelle secondaire champ de dalle",
            "it": "Lunghezza trave secondaria Zona solaio",
            "hu": "Kereszttartóhossz födémmezo",
            "ru": "Длина поперечной балки пролета",
            "sk": "Dĺžka priečnych nos. stropného poľa",
            "pl": "Długość dźwigara rusztu górnego Obszar stropu"
        },
        {
            "ID": "FLEX15__A3_AW1",
            "de": "3,30 m",
            "en-gb": "3.30 m",
            "en-us": "3,30 m",
            "es": "3,30 m",
            "fr": "3,30 m",
            "it": "3,30 m",
            "hu": "3,30 m",
            "ru": "3,30 м",
            "sk": "3,30 m",
            "pl": "3,30 m"
        },
        {
            "ID": "FLEX15__A3_AW2",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1,80 m",
            "es": "1,80 m",
            "fr": "1,80 m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "FLEX15__A3_AW3",
            "de": "2,45 m",
            "en-gb": "2.45 m",
            "en-us": "2,45 m",
            "es": "2,45 m",
            "fr": "2,45 m",
            "it": "2,45 m",
            "hu": "2,45 m",
            "ru": "2,45 м",
            "sk": "2,45 m",
            "pl": "2,45 m"
        },
        {
            "ID": "FLEX15__A3_AW4",
            "de": "2,90 m",
            "en-gb": "2.90 m",
            "en-us": "2,90 m",
            "es": "2,90 m",
            "fr": "2,90 m",
            "it": "2,90 m",
            "hu": "2,90 m",
            "ru": "2,90 м",
            "sk": "2,90 m",
            "pl": "2,90 m"
        },
        {
            "ID": "FLEX15__A3_AW5",
            "de": "3,90 m",
            "en-gb": "3.90 m",
            "en-us": "3,90 m",
            "es": "3,90 m",
            "fr": "3,90 m",
            "it": "3,90 m",
            "hu": "3,90 m",
            "ru": "3,90 м",
            "sk": "3,90 m",
            "pl": "3,90 m"
        },
        {
            "ID": "FLEX15__A3_AW6",
            "de": "4,90 m",
            "en-gb": "4.90 m",
            "en-us": "4,90 m",
            "es": "4,90 m",
            "fr": "4,90 m",
            "it": "4,90 m",
            "hu": "4,90 m",
            "ru": "4,90 м",
            "sk": "4,90 m",
            "pl": "4,90 m"
        },
        {
            "ID": "FLEX15__A3_AW7",
            "de": "9,00 m",
            "en-gb": "9.00 m",
            "en-us": "9,00 m",
            "es": "9,00 m",
            "fr": "9,00 m",
            "it": "9,00 m",
            "hu": "9,00 m",
            "ru": "9,00 м",
            "sk": "9,00 m",
            "pl": "9,00 m"
        },
        {
            "ID": "FLEX15__A4",
            "de": "Jochträgerlänge Deckenfeld",
            "en-gb": "Primary beam length for slab polygon",
            "en-us": "Stringer length for slab polygon",
            "es": "Largo de vigas principales para sector de techo",
            "fr": "Longueur dalle raid. champ de dalle",
            "it": "Lunghezza trave principale Zona solaio",
            "hu": "Főtartóhossz födémmező",
            "ru": "Длина главных балок пролета",
            "sk": "Dĺžka pozdĺžnych nos. stropného poľa",
            "pl": "Długość dźwigara rusztu dolnego Płyta stropu"
        },
        {
            "ID": "FLEX15__A4_AW1",
            "de": "3,90 m",
            "en-gb": "3.90 m",
            "en-us": "3,90 m",
            "es": "3,90 m",
            "fr": "3,90 m",
            "it": "3,90 m",
            "hu": "3,90 m",
            "ru": "3,90 м",
            "sk": "3,90 m",
            "pl": "3,90 m"
        },
        {
            "ID": "FLEX15__A4_AW2",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1,80 m",
            "es": "1,80 m",
            "fr": "1,80 m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "FLEX15__A4_AW3",
            "de": "2,45 m",
            "en-gb": "2.45 m",
            "en-us": "2,45 m",
            "es": "2,45 m",
            "fr": "2,45 m",
            "it": "2,45 m",
            "hu": "2,45 m",
            "ru": "2,45 м",
            "sk": "2,45 m",
            "pl": "2,45 m"
        },
        {
            "ID": "FLEX15__A4_AW4",
            "de": "2,90 m",
            "en-gb": "2.90 m",
            "en-us": "2,90 m",
            "es": "2,90 m",
            "fr": "2,90 m",
            "it": "2,90 m",
            "hu": "2,90 m",
            "ru": "2,90 м",
            "sk": "2,90 m",
            "pl": "2,90 m"
        },
        {
            "ID": "FLEX15__A4_AW5",
            "de": "3,30 m",
            "en-gb": "3.30 m",
            "en-us": "3,30 m",
            "es": "3,30 m",
            "fr": "3,30 m",
            "it": "3,30 m",
            "hu": "3,30 m",
            "ru": "3,30 м",
            "sk": "3,30 m",
            "pl": "3,30 m"
        },
        {
            "ID": "FLEX15__A4_AW6",
            "de": "4,90 m",
            "en-gb": "4.90 m",
            "en-us": "4,90 m",
            "es": "4,90 m",
            "fr": "4,90 m",
            "it": "4,90 m",
            "hu": "4,90 m",
            "ru": "4,90 м",
            "sk": "4,90 m",
            "pl": "4,90 m"
        },
        {
            "ID": "FLEX15__A4_AW7",
            "de": "9,00 m",
            "en-gb": "9.00 m",
            "en-us": "9,00 m",
            "es": "9,00 m",
            "fr": "9,00 m",
            "it": "9,00 m",
            "hu": "9,00 m",
            "ru": "9,00 м",
            "sk": "9,00 m",
            "pl": "9,00 m"
        },
        {
            "ID": "FLEX30OP__A11",
            "de": "Dokaflex Unterzug Ausführung",
            "en-gb": "Dokaflex drop beam type",
            "en-us": "Dokaflex drop beam type",
            "es": "Diseño de jácenas Dokaflex",
            "fr": "Retombée poutre exécution Dokaflex",
            "it": "Dokaflex Trave Esecuzione",
            "hu": "Dokaflex gerenda kialakítás",
            "ru": "Исполнение прогона Dokaflex",
            "sk": "Dokaflex prievlaku vytvorenie",
            "pl": "Dokaflex rodzaj podciągu"
        },
        {
            "ID": "FLEX30OP__A11_AW1",
            "de": "Balkenzwinge 20",
            "en-gb": "Beam forming support 20",
            "en-us": "Beam forming support 20",
            "es": "Soporte viga 20",
            "fr": "Équerre de poutre 20",
            "it": "Morsa a squadra per bloccaggio trave 20",
            "hu": "Gerendazsaluzó kaloda 20",
            "ru": "Балочный зажим 20",
            "sk": "Prievlaková klieština 20",
            "pl": "Zacisk belkowy 20"
        },
        {
            "ID": "FLEX30OP__A11_AW2",
            "de": "bauseitige Kanthölzer",
            "en-gb": "Squared timber by site",
            "en-us": "Squared timber by site",
            "es": "Listón por parte de obra",
            "fr": "Bois fourn. chantier",
            "it": "Travi squadrate in cantiere",
            "hu": "Fabetét helyi anyag",
            "ru": "деревянные брусья (силами заказчика)",
            "sk": "Hranoly dod. stavby",
            "pl": "Kantówka (po stronie budowy)"
        },
        {
            "ID": "FLEX30OP__A11_AW3",
            "de": "H20 Träger variabel",
            "en-gb": "H20 beam variable",
            "en-us": "H20 beam variable",
            "es": "Vigas H20 variable",
            "fr": "H20 Poutrelle variabel",
            "it": "H20 trave variabile",
            "hu": "H20 Fatartó variabel",
            "ru": "H20-балки различные",
            "sk": "Variabilná nosník H20",
            "pl": "Dźwigar H20 - dł. zmienna"
        },
        {
            "ID": "FLEX30OP__A12",
            "de": "Schaltafel Größe",
            "en-gb": "Plywood dimension",
            "en-us": "Plywood dimension",
            "es": "Tamaño del tablero de encofrado",
            "fr": "Contreplaqué taille",
            "it": "Grandezza della tavola di casseratura",
            "hu": "Zsaluhéj méret",
            "ru": "Размер опалубочного щита",
            "sk": "Veľkosť dosky stola",
            "pl": "Wymiary sklejki"
        },
        {
            "ID": "FLEX30OP__A12_AW1",
            "de": "100/50 cm",
            "en-gb": "100/50 cm",
            "en-us": "100/50 cm",
            "es": "100/50 cm",
            "fr": "100/50 cm",
            "it": "100/50 cm",
            "hu": "100/50 cm",
            "ru": "100/50 cм",
            "sk": "100/50 cm",
            "pl": "100/50 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW10",
            "de": "35/200 cm",
            "en-gb": "35/200 cm",
            "en-us": "35/200 cm",
            "es": "35/200 cm",
            "fr": "35/200 cm",
            "it": "35/200 cm",
            "hu": "35/200 cm",
            "ru": "35/200 cм",
            "sk": "35/200 cm",
            "pl": "35/200 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW11",
            "de": "35/250 cm",
            "en-gb": "35/250 cm",
            "en-us": "35/250 cm",
            "es": "35/250 cm",
            "fr": "35/250 cm",
            "it": "35/250 cm",
            "hu": "35/250 cm",
            "ru": "35/250 cм",
            "sk": "35/250 cm",
            "pl": "35/250 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW12",
            "de": "14,7/200 cm",
            "en-gb": "14.7/200 cm",
            "en-us": "14.7/200 cm",
            "es": "14,7/200 cm",
            "fr": "14,7/200 cm",
            "it": "14,7/200 cm",
            "hu": "14,7/200 cm",
            "ru": "14,7/200 cм",
            "sk": "14,7/200 cm",
            "pl": "14,7/200 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW13",
            "de": "14,7/250 cm",
            "en-gb": "14.7/250 cm",
            "en-us": "14.7/250 cm",
            "es": "14,7/250 cm",
            "fr": "14,7/250 cm",
            "it": "14,7/250 cm",
            "hu": "14,7/250 cm",
            "ru": "14,7/250 cм",
            "sk": "14,7/250 cm",
            "pl": "14,7/250 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW14",
            "de": "100/100 cm",
            "en-gb": "100/100 cm",
            "en-us": "100/100 cm",
            "es": "100/100 cm",
            "fr": "100/100 cm",
            "it": "100/100 cm",
            "hu": "100/100 cm",
            "ru": "100/100 cм",
            "sk": "100/100 cm",
            "pl": "100/100 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW15",
            "de": "150/100 cm",
            "en-gb": "150/100 cm",
            "en-us": "150/100 cm",
            "es": "150/100 cm",
            "fr": "150/100 cm",
            "it": "150/100 cm",
            "hu": "150/100 cm",
            "ru": "150/100 cм",
            "sk": "150/100 cm",
            "pl": "150/100 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW16",
            "de": "200/100 cm",
            "en-gb": "200/100 cm",
            "en-us": "200/100 cm",
            "es": "200/100 cm",
            "fr": "200/100 cm",
            "it": "200/100 cm",
            "hu": "200/100 cm",
            "ru": "200/100 cм",
            "sk": "200/100 cm",
            "pl": "200/100 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW17",
            "de": "250/125 cm",
            "en-gb": "250/125 cm",
            "en-us": "250/125 cm",
            "es": "250/125 cm",
            "fr": "250/125 cm",
            "it": "250/125 cm",
            "hu": "250/125 cm",
            "ru": "250/125 cм",
            "sk": "250/125 cm",
            "pl": "250/125 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW2",
            "de": "150/50 cm",
            "en-gb": "150/50 cm",
            "en-us": "150/50 cm",
            "es": "150/50 cm",
            "fr": "150/50 cm",
            "it": "150/50 cm",
            "hu": "150/50 cm",
            "ru": "150/50 cм",
            "sk": "150/50 cm",
            "pl": "150/50 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW3",
            "de": "200/50 cm",
            "en-gb": "200/50 cm",
            "en-us": "200/50 cm",
            "es": "200/50 cm",
            "fr": "200/50 cm",
            "it": "200/50 cm",
            "hu": "200/50 cm",
            "ru": "200/50 cм",
            "sk": "200/50 cm",
            "pl": "200/50 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW4",
            "de": "250/50 cm",
            "en-gb": "250/50 cm",
            "en-us": "250/50 cm",
            "es": "250/50 cm",
            "fr": "250/50 cm",
            "it": "250/50 cm",
            "hu": "250/50 cm",
            "ru": "250/50 cм",
            "sk": "250/50 cm",
            "pl": "250/50 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW5",
            "de": "244/61 cm",
            "en-gb": "244/61 cm",
            "en-us": "244/61 cm",
            "es": "244/61 cm",
            "fr": "244/61 cm",
            "it": "244/61 cm",
            "hu": "244/61 cm",
            "ru": "244/61 cм",
            "sk": "244/61 cm",
            "pl": "244/61 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW6",
            "de": "244/122 cm",
            "en-gb": "244/122 cm",
            "en-us": "244/122 cm",
            "es": "244/122 cm",
            "fr": "244/122 cm",
            "it": "244/122 cm",
            "hu": "244/122 cm",
            "ru": "244/122 cм",
            "sk": "244/122 cm",
            "pl": "244/122 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW7",
            "de": "122/61 cm",
            "en-gb": "122/61 cm",
            "en-us": "122/61 cm",
            "es": "122/61 cm",
            "fr": "122/61 cm",
            "it": "122/61 cm",
            "hu": "122/61 cm",
            "ru": "122/61 cм",
            "sk": "122/61 cm",
            "pl": "122/61 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW8",
            "de": "50/200 cm",
            "en-gb": "50/200 cm",
            "en-us": "50/200 cm",
            "es": "50/200 cm",
            "fr": "50/200 cm",
            "it": "50/200 cm",
            "hu": "50/200 cm",
            "ru": "50/200 cм",
            "sk": "50/200 cm",
            "pl": "50/200 cm"
        },
        {
            "ID": "FLEX30OP__A12_AW9",
            "de": "50/250 cm",
            "en-gb": "50/250 cm",
            "en-us": "50/250 cm",
            "es": "50/250 cm",
            "fr": "50/250 cm",
            "it": "50/250 cm",
            "hu": "50/250 cm",
            "ru": "50/250 cм",
            "sk": "50/250 cm",
            "pl": "50/250 cm"
        },
        {
            "ID": "FLEX30OP__A13",
            "de": "Querträgerlänge Deckenfeld",
            "en-gb": "Secondary beam length for slab polygon",
            "en-us": "Joist length for slab polygon",
            "es": "Largo de vigas transversales para sector de techo",
            "fr": "Longueur poutrelle secondaire champ de dalle",
            "it": "Lunghezza trave secondaria Zona solaio",
            "hu": "Kereszttartóhossz födémmezo",
            "ru": "Длина поперечной балки пролета",
            "sk": "Dĺžka priečnych nos. stropného poľa",
            "pl": "Długość dźwigara rusztu górnego Obszar stropu"
        },
        {
            "ID": "FLEX30OP__A13_AW1",
            "de": "1,25 m",
            "en-gb": "1.25 m",
            "en-us": "1.25 m",
            "es": "1,25 m",
            "fr": "1,25 m",
            "it": "1,25 m",
            "hu": "1,25 m",
            "ru": "1,25 м",
            "sk": "1,25 m",
            "pl": "1,25 m"
        },
        {
            "ID": "FLEX30OP__A13_AW10",
            "de": "4,90 m",
            "en-gb": "4.90 m",
            "en-us": "4.90 m",
            "es": "4,90 m",
            "fr": "4,90 m",
            "it": "4,90 m",
            "hu": "4,90 m",
            "ru": "4,90 м",
            "sk": "4,90 m",
            "pl": "4,90 m"
        },
        {
            "ID": "FLEX30OP__A13_AW11",
            "de": "5,90 m",
            "en-gb": "5.90 m",
            "en-us": "5.90 m",
            "es": "5,90 m",
            "fr": "5,90 m",
            "it": "5,90 m",
            "hu": "5,90 m",
            "ru": "5,90 м",
            "sk": "5,90 m",
            "pl": "5,90 m"
        },
        {
            "ID": "FLEX30OP__A13_AW12",
            "de": "8x20/100 cm",
            "en-gb": "8x20/100 cm",
            "en-us": "8x20/100 cm",
            "es": "8x20/100 cm",
            "fr": "8x20/100 cm",
            "it": "8x20/100 cm",
            "hu": "8x20/100 cm",
            "ru": "8x20/100 cм",
            "sk": "8x20/100 cm",
            "pl": "8x20/100 cm"
        },
        {
            "ID": "FLEX30OP__A13_AW2",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80 m",
            "es": "1,80 m",
            "fr": "1,80 m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "FLEX30OP__A13_AW3",
            "de": "2,45 m",
            "en-gb": "2.45 m",
            "en-us": "2.45 m",
            "es": "2,45 m",
            "fr": "2,45 m",
            "it": "2,45 m",
            "hu": "2,45 m",
            "ru": "2,45 м",
            "sk": "2,45 m",
            "pl": "2,45 m"
        },
        {
            "ID": "FLEX30OP__A13_AW4",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65 m",
            "es": "2,65 m",
            "fr": "2,65 m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "FLEX30OP__A13_AW5",
            "de": "2,90 m",
            "en-gb": "2.90 m",
            "en-us": "2.90 m",
            "es": "2,90 m",
            "fr": "2,90 m",
            "it": "2,90 m",
            "hu": "2,90 m",
            "ru": "2,90 м",
            "sk": "2,90 m",
            "pl": "2,90 m"
        },
        {
            "ID": "FLEX30OP__A13_AW6",
            "de": "3,30 m",
            "en-gb": "3.30 m",
            "en-us": "3.30 m",
            "es": "3,30 m",
            "fr": "3,30 m",
            "it": "3,30 m",
            "hu": "3,30 m",
            "ru": "3,30 м",
            "sk": "3,30 m",
            "pl": "3,30 m"
        },
        {
            "ID": "FLEX30OP__A13_AW7",
            "de": "3,60 m",
            "en-gb": "3.60 m",
            "en-us": "3.60 m",
            "es": "3,60 m",
            "fr": "3,60 m",
            "it": "3,60 m",
            "hu": "3,60 m",
            "ru": "3,60 м",
            "sk": "3,60 m",
            "pl": "3,60 m"
        },
        {
            "ID": "FLEX30OP__A13_AW8",
            "de": "3,90 m",
            "en-gb": "3.90 m",
            "en-us": "3.90 m",
            "es": "3,90 m",
            "fr": "3,90 m",
            "it": "3,90 m",
            "hu": "3,90 m",
            "ru": "3,90 м",
            "sk": "3,90 m",
            "pl": "3,90 m"
        },
        {
            "ID": "FLEX30OP__A13_AW9",
            "de": "4,50 m",
            "en-gb": "4.50 m",
            "en-us": "4.50 m",
            "es": "4,50 m",
            "fr": "4,50 m",
            "it": "4,50 m",
            "hu": "4,50 m",
            "ru": "4,50 м",
            "sk": "4,50 m",
            "pl": "4,50 m"
        },
        {
            "ID": "FLEX30OP__A14",
            "de": "Jochträgerlänge Deckenfeld (nur I tec)",
            "en-gb": "Primary beam length for slab polygon (only I tec)",
            "en-us": "Stringer length for slab polygon (only I tec)",
            "es": "Largo de vigas principales para sector de techo (solo I tec)",
            "fr": "Longueur dalle raid. champ de dalle (I tec uniquement)",
            "it": "Lunghezza trave principale Zona solaio (solo I tec)",
            "hu": "Főtartóhossz födémmező (csak I tec)",
            "ru": "Длина продольных потолочных балок (только I tec)",
            "sk": "Dĺžka pozdĺžnych nos. stropného poľa (I tec)",
            "pl": "Długość dźwigara rusztu dolnego Obszar stropu (tylko I Tec)"
        },
        {
            "ID": "FLEX30OP__A14_AW1",
            "de": "3,90 m",
            "en-gb": "3.90 m",
            "en-us": "3.90 m",
            "es": "3,90 m",
            "fr": "3,90 m",
            "it": "3,90 m",
            "hu": "3,90 m",
            "ru": "3,90 м",
            "sk": "3,90 m",
            "pl": "3,90 m"
        },
        {
            "ID": "FLEX30OP__A14_AW10",
            "de": "3,30 m",
            "en-gb": "3.30 m",
            "en-us": "3.30 m",
            "es": "3,30 m",
            "fr": "3,30 m",
            "it": "3,30 m",
            "hu": "3,30 m",
            "ru": "3,30 м",
            "sk": "3,30 m",
            "pl": "3,30 m"
        },
        {
            "ID": "FLEX30OP__A14_AW11",
            "de": "3,60 m",
            "en-gb": "3.60 m",
            "en-us": "3.60 m",
            "es": "3,60 m",
            "fr": "3,60 m",
            "it": "3,60 m",
            "hu": "3,60 m",
            "ru": "3,60 м",
            "sk": "3,60 m",
            "pl": "3,60 m"
        },
        {
            "ID": "FLEX30OP__A14_AW2",
            "de": "4,50 m",
            "en-gb": "4.50 m",
            "en-us": "4.50 m",
            "es": "4,50 m",
            "fr": "4,50 m",
            "it": "4,50 m",
            "hu": "4,50 m",
            "ru": "4,50 м",
            "sk": "4,50 m",
            "pl": "4,50 m"
        },
        {
            "ID": "FLEX30OP__A14_AW3",
            "de": "4,90 m",
            "en-gb": "4.90 m",
            "en-us": "4.90 m",
            "es": "4,90 m",
            "fr": "4,90 m",
            "it": "4,90 m",
            "hu": "4,90 m",
            "ru": "4,90 м",
            "sk": "4,90 m",
            "pl": "4,90 m"
        },
        {
            "ID": "FLEX30OP__A14_AW4",
            "de": "5,35 m",
            "en-gb": "5.35 m",
            "en-us": "5.35 m",
            "es": "5,35 m",
            "fr": "5,35 m",
            "it": "5,35 m",
            "hu": "5,35 m",
            "ru": "5,35 м",
            "sk": "5,35 m",
            "pl": "5,35 m"
        },
        {
            "ID": "FLEX30OP__A14_AW5",
            "de": "5,90 m",
            "en-gb": "5.90 m",
            "en-us": "5.90 m",
            "es": "5,90 m",
            "fr": "5,90 m",
            "it": "5,90 m",
            "hu": "5,90 m",
            "ru": "5,90 м",
            "sk": "5,90 m",
            "pl": "5,90 m"
        },
        {
            "ID": "FLEX30OP__A14_AW6",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80 m",
            "es": "1,80 m",
            "fr": "1,80 m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "FLEX30OP__A14_AW7",
            "de": "2,45 m",
            "en-gb": "2.45 m",
            "en-us": "2.45 m",
            "es": "2,45 m",
            "fr": "2,45 m",
            "it": "2,45 m",
            "hu": "2,45 m",
            "ru": "2,45 м",
            "sk": "2,45 m",
            "pl": "2,45 m"
        },
        {
            "ID": "FLEX30OP__A14_AW8",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65 m",
            "es": "2,65 m",
            "fr": "2,65 m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "FLEX30OP__A14_AW9",
            "de": "2,90 m",
            "en-gb": "2.90 m",
            "en-us": "2.90 m",
            "es": "2,90 m",
            "fr": "2,90 m",
            "it": "2,90 m",
            "hu": "2,90 m",
            "ru": "2,90 м",
            "sk": "2,90 m",
            "pl": "2,90 m"
        },
        {
            "ID": "FLEX30OP__A15",
            "de": "Dokaflex Stützenköpfe",
            "en-gb": "Dokaflex prop heads",
            "en-us": "Dokaflex prop heads",
            "es": "Cabezales Dokaflex",
            "fr": "Têtes d'étançon Dokaflex",
            "it": "Teste dei puntelli Dokaflex",
            "hu": "Dokaflex támaszfej",
            "ru": "Головки колонн Dokaflex",
            "sk": "Dokaflex hlavice podpier",
            "pl": "Dokaflex głowice podpory"
        },
        {
            "ID": "FLEX30OP__A15_AW1",
            "de": "Vierwegkopf H20",
            "en-gb": "4-way head H20",
            "en-us": "4-way head H20",
            "es": "Cabeza de cuatro vías H20",
            "fr": "Tête en croix H20",
            "it": "Testa a 4 vie H20",
            "hu": "Villásfej H20",
            "ru": "Четырехходовая головка H20",
            "sk": "Štvorcestná hlavica H20",
            "pl": "Głowica czterokierunkowa H20"
        },
        {
            "ID": "FLEX30OP__A15_AW2",
            "de": "Absenkkopf H20",
            "en-gb": "Lowering head H20",
            "en-us": "Lowering head H20",
            "es": "Cabeza de descimbrado H20",
            "fr": "Tête de décoffrage H20",
            "it": "Testa di disarmo H20",
            "hu": "Ejtőfej H20",
            "ru": "Опускаемая головка H20",
            "sk": "Spúšťacia hlavica H20",
            "pl": "Głowica opuszczalna H20"
        },
        {
            "ID": "FLEX30OP__A15_AW3",
            "de": "Kopfgabel 12,5 cm",
            "en-gb": "U-head 12.5 cm",
            "en-us": "U-head 12.5 cm",
            "es": "Cabezal en U 12,5 cm",
            "fr": "Tête à fourche 12,5 cm",
            "it": "Forcella di testa 12,5 cm",
            "hu": "Villás fej 12,5 cm",
            "ru": "Вилка головки 12,5 cм",
            "sk": "Hlavová vidlica 12,5 cm",
            "pl": "Widły do głowicy 12,5cm"
        },
        {
            "ID": "FLEX30OP__A15_AW4",
            "de": "Haltekopf H20",
            "en-gb": "Supporting head H20",
            "en-us": "Supporting head H20",
            "es": "Cabeza de soporte H20",
            "fr": "Tête de support H20",
            "it": "Testa d'appoggio H20",
            "hu": "Rugós tartófej H20",
            "ru": "Удерживающая головка H20",
            "sk": "Pridržovacia hlavica H20",
            "pl": "Głowica utrzymująca H20"
        },
        {
            "ID": "FLEX30OP__A15_AW5",
            "de": "Haltekopf H20 DF",
            "en-gb": "Supporting head H20 DF",
            "en-us": "Supporting head H20 DF",
            "es": "Cabeza de soporte H20 DF",
            "fr": "Tête de support H20 DF",
            "it": "Testa d'appoggio H20 DF",
            "hu": "Rugós tartófej H20 DF",
            "ru": "Удерживающая головка H20 DF",
            "sk": "Pridržovacia hlavica H20 DF",
            "pl": "Głowica utrzymująca H20 DF"
        },
        {
            "ID": "FLEX30OP__A16",
            "de": "Querträgerlänge Unterzug",
            "en-gb": "Secondary beam length for drop beam",
            "en-us": "Joist length for drop beam",
            "es": "Largo de vigas transversales para jácenas",
            "fr": "Longueur poutrelle secondaire retombée poutre",
            "it": "Lunghezza trave secondaria Trave",
            "hu": "Kereszttartóhossz gerenda",
            "ru": "Длина поперечных балок прогона",
            "sk": "Dĺžka priečnych nos. prievlaku",
            "pl": "Długość dźwigara rusztu górnego Podciąg"
        },
        {
            "ID": "FLEX30OP__A16_AW1",
            "de": "1,25 m",
            "en-gb": "1.25 m",
            "en-us": "1.25 m",
            "es": "1,25 m",
            "fr": "1,25 m",
            "it": "1,25 m",
            "hu": "1,25 m",
            "ru": "1,25 м",
            "sk": "1,25 m",
            "pl": "1,25 m"
        },
        {
            "ID": "FLEX30OP__A16_AW10",
            "de": "4,90 m",
            "en-gb": "4.90 m",
            "en-us": "4.90 m",
            "es": "4,90 m",
            "fr": "4,90 m",
            "it": "4,90 m",
            "hu": "4,90 m",
            "ru": "4,90 м",
            "sk": "4,90 m",
            "pl": "4,90 m"
        },
        {
            "ID": "FLEX30OP__A16_AW11",
            "de": "5,90 m",
            "en-gb": "5.90 m",
            "en-us": "5.90 m",
            "es": "5,90 m",
            "fr": "5,90 m",
            "it": "5,90 m",
            "hu": "5,90 m",
            "ru": "5,90 м",
            "sk": "5,90 m",
            "pl": "5,90 m"
        },
        {
            "ID": "FLEX30OP__A16_AW12",
            "de": "8x20/100 cm",
            "en-gb": "8x20/100 cm",
            "en-us": "8x20/100 cm",
            "es": "8x20/100 cm",
            "fr": "8x20/100 cm",
            "it": "8x20/100 cm",
            "hu": "8x20/100 cm",
            "ru": "8x20/100 cм",
            "sk": "8x20/100 cm",
            "pl": "8x20/100 cm"
        },
        {
            "ID": "FLEX30OP__A16_AW2",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80 m",
            "es": "1,80 m",
            "fr": "1,80 m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "FLEX30OP__A16_AW3",
            "de": "2,45 m",
            "en-gb": "2.45 m",
            "en-us": "2.45 m",
            "es": "2,45 m",
            "fr": "2,45 m",
            "it": "2,45 m",
            "hu": "2,45 m",
            "ru": "2,45 м",
            "sk": "2,45 m",
            "pl": "2,45 m"
        },
        {
            "ID": "FLEX30OP__A16_AW4",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65 m",
            "es": "2,65 m",
            "fr": "2,65 m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "FLEX30OP__A16_AW5",
            "de": "2,90 m",
            "en-gb": "2.90 m",
            "en-us": "2.90 m",
            "es": "2,90 m",
            "fr": "2,90 m",
            "it": "2,90 m",
            "hu": "2,90 m",
            "ru": "2,90 м",
            "sk": "2,90 m",
            "pl": "2,90 m"
        },
        {
            "ID": "FLEX30OP__A16_AW6",
            "de": "3,30 m",
            "en-gb": "3.30 m",
            "en-us": "3.30 m",
            "es": "3,30 m",
            "fr": "3,30 m",
            "it": "3,30 m",
            "hu": "3,30 m",
            "ru": "3,30 м",
            "sk": "3,30 m",
            "pl": "3,30 m"
        },
        {
            "ID": "FLEX30OP__A16_AW7",
            "de": "3,60 m",
            "en-gb": "3.60 m",
            "en-us": "3.60 m",
            "es": "3,60 m",
            "fr": "3,60 m",
            "it": "3,60 m",
            "hu": "3,60 m",
            "ru": "3,60 м",
            "sk": "3,60 m",
            "pl": "3,60 m"
        },
        {
            "ID": "FLEX30OP__A16_AW8",
            "de": "3,90 m",
            "en-gb": "3.90 m",
            "en-us": "3.90 m",
            "es": "3,90 m",
            "fr": "3,90 m",
            "it": "3,90 m",
            "hu": "3,90 m",
            "ru": "3,90 м",
            "sk": "3,90 m",
            "pl": "3,90 m"
        },
        {
            "ID": "FLEX30OP__A16_AW9",
            "de": "4,50 m",
            "en-gb": "4.50 m",
            "en-us": "4.50 m",
            "es": "4,50 m",
            "fr": "4,50 m",
            "it": "4,50 m",
            "hu": "4,50 m",
            "ru": "4,50 м",
            "sk": "4,50 m",
            "pl": "4,50 m"
        },
        {
            "ID": "FLEX30OP__A17",
            "de": "Jochträgerlänge Unterzug (nur I tec)",
            "en-gb": "Primary beam length for drop beam (only I tec)",
            "en-us": "Stringer length for drop beam (only I tec)",
            "es": "Largo de vigas principales para jácenas (solo I tec)",
            "fr": "Longueur dalle raid. retombée poutre (I tec uniquement)",
            "it": "Lunghezza trave principale Trave (solo I tec)",
            "hu": "Főtartóhossz gerenda (csak I tec)",
            "ru": "Длина главных балок прогон (nur I tec)",
            "sk": "Dĺžka pozdĺžnych nos. prievlaku (I tec)",
            "pl": "Długość dźwigara rusztu dolnego Podciąg (tylko I tec)"
        },
        {
            "ID": "FLEX30OP__A17_AW1",
            "de": "3,90 m",
            "en-gb": "3.90 m",
            "en-us": "3.90 m",
            "es": "3,90 m",
            "fr": "3,90 m",
            "it": "3,90 m",
            "hu": "3,90 m",
            "ru": "3,90 м",
            "sk": "3,90 m",
            "pl": "3,90 m"
        },
        {
            "ID": "FLEX30OP__A17_AW10",
            "de": "3,30 m",
            "en-gb": "3.30 m",
            "en-us": "3.30 m",
            "es": "3,30 m",
            "fr": "3,30 m",
            "it": "3,30 m",
            "hu": "3,30 m",
            "ru": "3,30 м",
            "sk": "3,30 m",
            "pl": "3,30 m"
        },
        {
            "ID": "FLEX30OP__A17_AW11",
            "de": "3,60 m",
            "en-gb": "3.60 m",
            "en-us": "3.60 m",
            "es": "3,60 m",
            "fr": "3,60 m",
            "it": "3,60 m",
            "hu": "3,60 m",
            "ru": "3,60 м",
            "sk": "3,60 m",
            "pl": "3,60 m"
        },
        {
            "ID": "FLEX30OP__A17_AW2",
            "de": "4,50 m",
            "en-gb": "4.50 m",
            "en-us": "4.50 m",
            "es": "4,50 m",
            "fr": "4,50 m",
            "it": "4,50 m",
            "hu": "4,50 m",
            "ru": "4,50 м",
            "sk": "4,50 m",
            "pl": "4,50 m"
        },
        {
            "ID": "FLEX30OP__A17_AW3",
            "de": "4,90 m",
            "en-gb": "4.90 m",
            "en-us": "4.90 m",
            "es": "4,90 m",
            "fr": "4,90 m",
            "it": "4,90 m",
            "hu": "4,90 m",
            "ru": "4,90 м",
            "sk": "4,90 m",
            "pl": "4,90 m"
        },
        {
            "ID": "FLEX30OP__A17_AW4",
            "de": "5,35 m",
            "en-gb": "5.35 m",
            "en-us": "5.35 m",
            "es": "5,35 m",
            "fr": "5,35 m",
            "it": "5,35 m",
            "hu": "5,35 m",
            "ru": "5,35 м",
            "sk": "5,35 m",
            "pl": "5,35 m"
        },
        {
            "ID": "FLEX30OP__A17_AW5",
            "de": "5,90 m",
            "en-gb": "5.90 m",
            "en-us": "5.90 m",
            "es": "5,90 m",
            "fr": "5,90 m",
            "it": "5,90 m",
            "hu": "5,90 m",
            "ru": "5,90 м",
            "sk": "5,90 m",
            "pl": "5,90 m"
        },
        {
            "ID": "FLEX30OP__A17_AW6",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80 m",
            "es": "1,80 m",
            "fr": "1,80 m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "FLEX30OP__A17_AW7",
            "de": "2,45 m",
            "en-gb": "2.45 m",
            "en-us": "2.45 m",
            "es": "2,45 m",
            "fr": "2,45 m",
            "it": "2,45 m",
            "hu": "2,45 m",
            "ru": "2,45 м",
            "sk": "2,45 m",
            "pl": "2,45 m"
        },
        {
            "ID": "FLEX30OP__A17_AW8",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65 m",
            "es": "2,65 m",
            "fr": "2,65 m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "FLEX30OP__A17_AW9",
            "de": "2,90 m",
            "en-gb": "2.90 m",
            "en-us": "2.90 m",
            "es": "2,90 m",
            "fr": "2,90 m",
            "it": "2,90 m",
            "hu": "2,90 m",
            "ru": "2,90 м",
            "sk": "2,90 m",
            "pl": "2,90 m"
        },
        {
            "ID": "FLEX30OP__A18",
            "de": "Stützbeine",
            "en-gb": "Removable folding tripods",
            "en-us": "Removable folding tripods",
            "es": "Trípodes plegables",
            "fr": "Trépieds amovibles",
            "it": "Treppiedi amovibili",
            "hu": "Támasztólábak",
            "ru": "треноги",
            "sk": "Oporné trojnožky",
            "pl": "Trójnogi składane"
        },
        {
            "ID": "FLEX30OP__A18_AW1",
            "de": "Stützbein",
            "en-gb": "Removable folding tripod",
            "en-us": "Removable folding tripod",
            "es": "Trípode plegable",
            "fr": "Trépied amovible",
            "it": "Treppiede amovibile",
            "hu": "Támasztóláb",
            "ru": "Тренога",
            "sk": "Oporná trojnožka",
            "pl": "Trójnóg"
        },
        {
            "ID": "FLEX30OP__A18_AW2",
            "de": "Stützbein top",
            "en-gb": "Removable folding tripod top",
            "en-us": "Removable folding tripod top",
            "es": "Trípode plegable top",
            "fr": "Trépied amovible top",
            "it": "Treppiede amovibile top",
            "hu": "Támasztóláb top",
            "ru": "Тренога top",
            "sk": "Oporná trojnožka top",
            "pl": "Trójnóg top"
        },
        {
            "ID": "FLEX30OP__A7",
            "de": "Schaltafel Typ",
            "en-gb": "Shutter Board type",
            "en-us": "Shutter Board type",
            "es": "Tipo di tablero de encofrado",
            "fr": "Type de panneau",
            "it": "Tipo tavola di cassertaura",
            "hu": "Zsaluhéj típus",
            "ru": "Тип опалубочного щита",
            "sk": "Typ dosky stola",
            "pl": "Rodzaj deski szalunkowej"
        },
        {
            "ID": "FLEX30OP__A7_AW1",
            "de": "Dokadur-Paneel 21",
            "en-gb": "Dokadur panel 21",
            "en-us": "Dokadur panel 21",
            "es": "Panel Dokadur 21",
            "fr": "Panneau Dokadur 21",
            "it": "Pannello Dokadur 21",
            "hu": "Dokadur tábla 21",
            "ru": "Dokadur панель 21",
            "sk": "Dokadur panel 21",
            "pl": "Panel Dokadur 21"
        },
        {
            "ID": "FLEX30OP__A7_AW10",
            "de": "DokaPly eco 18mm",
            "en-gb": "DokaPly eco 18mm",
            "en-us": "DokaPly eco 18mm",
            "es": "DokaPly eco 18mm",
            "fr": "DokaPly eco 18mm",
            "it": "DokaPly eco 18mm",
            "hu": "DokaPly eco 18mm",
            "ru": "DokaPly eco 18мм",
            "sk": "DokaPly eco 18mm",
            "pl": "DokaPly eco 18mm"
        },
        {
            "ID": "FLEX30OP__A7_AW11",
            "de": "DokaPly eco 21mm",
            "en-gb": "DokaPly eco 21mm",
            "en-us": "DokaPly eco 21mm",
            "es": "DokaPly eco 21mm",
            "fr": "DokaPly eco 21mm",
            "it": "DokaPly eco 21mm",
            "hu": "DokaPly eco 21mm",
            "ru": "DokaPly eco 21мм",
            "sk": "DokaPly eco 21mm",
            "pl": "DokaPly eco 21mm"
        },
        {
            "ID": "FLEX30OP__A7_AW12",
            "de": "Schalungsplatte 3S basic 21mm",
            "en-gb": "Formwork sheet 3S basic 21mm",
            "en-us": "Formwork sheet 3S basic 21mm",
            "es": "Tablero de encofrado 3S basic 21mm",
            "fr": "Panneau 3S basic 21mm",
            "it": "Pannello 3S basic 21mm",
            "hu": "Zsaluhéj 3S basic 21mm",
            "ru": "Опалубочная плита 3S basic 21мм",
            "sk": "Debniaca doska 3S basic 21mm",
            "pl": "Sklejka 3S basic 21mm"
        },
        {
            "ID": "FLEX30OP__A7_AW13",
            "de": "Schalungsplatte 3S cut 21mm",
            "en-gb": "Formwork sheet 3S cut 21mm",
            "en-us": "Formwork sheet 3S cut 21mm",
            "es": "Tablero de encofrado 3S cut 21mm",
            "fr": "Panneau 3S cut 21mm",
            "it": "Pannello 3S cut 21mm",
            "hu": "Zsaluhéj 3S cut 21mm",
            "ru": "Опалубочная плита 3S cut 21мм",
            "sk": "Debniaca doska 3S cut 21mm",
            "pl": "Sklejka 3S cut 21mm"
        },
        {
            "ID": "FLEX30OP__A7_AW14",
            "de": "Schalungsplatte 3S basic 27mm",
            "en-gb": "Formwork sheet 3S basic 27mm",
            "en-us": "Formwork sheet 3S basic 27mm",
            "es": "Tablero de encofrado 3S basic 27mm",
            "fr": "Panneau 3S basic 27mm",
            "it": "Pannello 3S basic 27mm",
            "hu": "Zsaluhéj 3S basic 27mm",
            "ru": "Опалубочная плита 3S basic 27мм",
            "sk": "Debniaca doska 3S basic 27mm",
            "pl": "Sklejka 3S basic 27mm"
        },
        {
            "ID": "FLEX30OP__A7_AW15",
            "de": "Schalungsplatte 3S cut 27mm",
            "en-gb": "Formwork sheet 3S cut 27mm",
            "en-us": "Formwork sheet 3S cut 27mm",
            "es": "Tablero de encofrado 3S cut 27mm",
            "fr": "Panneau 3S cut 27mm",
            "it": "Pannello 3S cut 27mm",
            "hu": "Zsaluhéj 3S cut 27mm",
            "ru": "Опалубочная плита 3S cut 27мм",
            "sk": "Debniaca doska 3S cut 27mm",
            "pl": "Sklejka 3S cut 27mm"
        },
        {
            "ID": "FLEX30OP__A7_AW2",
            "de": "Schalungsplatte 3-SO 21 mm",
            "en-gb": "Formwork sheet 3-SO 21 mm",
            "en-us": "Formwork sheet 3-SO 21mm",
            "es": "Tablero de encofrado 3-SO 21mm",
            "fr": "Panneau 3-SO 21mm",
            "it": "Pannello 3-SO 21 mm",
            "hu": "Zsaluhéj 3-SO 21mm",
            "ru": "Oпалубочная плита 3-SO 21мм",
            "sk": "Debniaca doska 3-SO 21mm",
            "pl": "Sklejka 3-SO 21 mm"
        },
        {
            "ID": "FLEX30OP__A7_AW3",
            "de": "Dokadur-Plex Paneel 21",
            "en-gb": "Dokadur-Plex panel 21",
            "en-us": "Dokadur-Plex panel 21",
            "es": "Dokadur-Plex panel 21",
            "fr": "Panneau Dokadur-Plex 21",
            "it": "Pannello Dokadur-Plex 21",
            "hu": "Dokadur-Plex tábla 21",
            "ru": "Dokadur-Plex панель 21",
            "sk": "Dokadur-Plex panel 21",
            "pl": "Panel Dokadur Plex 21"
        },
        {
            "ID": "FLEX30OP__A7_AW4",
            "de": "Sperrholzplatte",
            "en-gb": "Plywood sheet",
            "en-us": "Plywood sheet",
            "es": "Tablero contrachapado",
            "fr": "Pan. contreplaqué",
            "it": "Pannello in legno compensato",
            "hu": "Rétegeltlemez",
            "ru": "Распорная плита",
            "sk": "Preglejka",
            "pl": "Arkusz sklejki"
        },
        {
            "ID": "FLEX30OP__A7_AW5",
            "de": "Dokaflex-Tischplatte",
            "en-gb": "Dokaflex table sheet",
            "en-us": "Dokaflex table sheet",
            "es": "Dokaflex Tablero para mesa",
            "fr": "Panneau table Dokaflex",
            "it": "Pannello tavolo Dokaflex",
            "hu": "Dokaflex-asztalhéj",
            "ru": "Опалубочная плита Dokaflex",
            "sk": "Dokaflex-doska stola",
            "pl": "Dokaflex – sklejka stołu"
        },
        {
            "ID": "FLEX30OP__A7_AW6",
            "de": "Dokadur-Paneel 27",
            "en-gb": "Dokadur panel 27",
            "en-us": "Dokadur panel 27",
            "es": "Panel Dokadur 27",
            "fr": "Panneau Dokadur 27",
            "it": "Pannello Dokadur 27",
            "hu": "Dokadur tábla 27",
            "ru": "Dokadur панель 27",
            "sk": "Dokadur panel 27",
            "pl": "Panel Dokadur 27"
        },
        {
            "ID": "FLEX30OP__A7_AW7",
            "de": "Schalungsplatte 3-SO 27 mm",
            "en-gb": "Formwork sheet 3-SO 27 mm",
            "en-us": "Formwork sheet 3-SO 27mm",
            "es": "Tablero de encofrado 3-SO 27mm",
            "fr": "Panneau 3-SO 27mm",
            "it": "Pannello 3-SO 27mm",
            "hu": "Zsaluhéj 3-SO 27mm",
            "ru": "Oпалубочная плита 3-SO 27мм",
            "sk": "Debniaca doska 3-SO 27mm",
            "pl": "Sklejka  3-SO 27mm"
        },
        {
            "ID": "FLEX30OP__A8",
            "de": "Schalungsträger Typ Querträger",
            "en-gb": "Formwork beam type Secondary beam",
            "en-us": "Formwork beam type Joist",
            "es": "Vigas de encofrado tipo vigas transversales",
            "fr": "Type de poutrelle secondaire",
            "it": "Trave Typ Lunghezza trave secondaria",
            "hu": "Fatartó típusa kereszttartó",
            "ru": "Опалубочные балки поперечные",
            "sk": "Debniaci nosník, typ priečny nosník",
            "pl": "Typ dźwigara pod sklejkę"
        },
        {
            "ID": "FLEX30OP__A8_AW1",
            "de": "Träger H20 top P",
            "en-gb": "Beam H20 top P",
            "en-us": "Beam H20 top P",
            "es": "Viga H20 top P",
            "fr": "Poutrelle H20 top P",
            "it": "Trave H20 top P",
            "hu": "Fatartó H20 top P",
            "ru": "Балка  H20 top P",
            "sk": "Nosník H20 top P",
            "pl": "Dźwigar H20 top P"
        },
        {
            "ID": "FLEX30OP__A8_AW2",
            "de": "Träger H20 top N",
            "en-gb": "Beam H20 top N",
            "en-us": "Beam H20 top N",
            "es": "Viga H20 top N",
            "fr": "Poutrelle H20 top N",
            "it": "Trave H20 top N",
            "hu": "Fatartó H20 top N",
            "ru": "Балка  H20 top N",
            "sk": "Nosník H20 top N",
            "pl": "Dźwigar H20 top N"
        },
        {
            "ID": "FLEX30OP__A8_AW3",
            "de": "Träger H20 eco P",
            "en-gb": "Beam H20 eco P",
            "en-us": "Beam H20 eco P",
            "es": "Viga H20 eco P",
            "fr": "Poutrelle H20 eco P",
            "it": "Trave H20 eco P",
            "hu": "Fatartó H20 eco P",
            "ru": "Балка  H20 eco P",
            "sk": "Nosník H20 eco P",
            "pl": "Dźwigar H20 eco P"
        },
        {
            "ID": "FLEX30OP__A8_AW4",
            "de": "Träger H20 eco N",
            "en-gb": "Beam H20 eco N",
            "en-us": "Beam H20 eco N",
            "es": "Viga H20 eco N",
            "fr": "Poutrelle H20 eco N",
            "it": "Trave H20 eco N",
            "hu": "Fatartó H20 eco N",
            "ru": "Балка  H20 eco N",
            "sk": "Nosník H20 eco N",
            "pl": "Dźwigar H20 eco N"
        },
        {
            "ID": "FLEX30OP__A8_AW5",
            "de": "Träger H20 Niet P",
            "en-gb": "Beam H20 rivet P",
            "en-us": "Beam H20 rivet P",
            "es": "Viga H20 remache P",
            "fr": "Poutrelle H20 rivet P",
            "it": "Trave H20 Niet P",
            "hu": "Fatartó H20 P szegecselt végű",
            "ru": "Балка  H20 заклепка P",
            "sk": "Nosník H20 P s nitmi",
            "pl": "Dźwigar H20 rivet P"
        },
        {
            "ID": "FLEX30OP__A8_AW6",
            "de": "Träger H20 Niet N",
            "en-gb": "Beam H20 rivet N",
            "en-us": "Beam H20 rivet N",
            "es": "Viga H20 remache N",
            "fr": "Poutrelle H20 rivet N",
            "it": "Trave H20 Niet N",
            "hu": "Fatartó H20 N szegecselt végű",
            "ru": "Балка  H20 заклепка N",
            "sk": "Nosník H20 N s nitmi",
            "pl": "Dźwigar H20 rivet N"
        },
        {
            "ID": "FLEX30OP__A8_AW7",
            "de": "Kantholz bauseits",
            "en-gb": "Squared timber by site",
            "en-us": "Squared timber by site",
            "es": "Listón por parte de obra",
            "fr": "Bois équarri chantier",
            "it": "Legno squadrato a carico cant.",
            "hu": "Furfa helyi anyag",
            "ru": "Деревянный брус (предоставляется заказчиком)",
            "sk": "Hranol dod. stavby",
            "pl": "Kantówka (po stronie budowy)"
        },
        {
            "ID": "FLEX30OP__A9",
            "de": "Dokaflex Unterstellung",
            "en-gb": "Dokaflex shoring",
            "en-us": "Dokaflex shoring",
            "es": "Apoyo Dokaflex",
            "fr": "Etaiement Dokaflex",
            "it": "Puntellazione Dokaflex",
            "hu": "Dokaflex Alátámasztás",
            "ru": "Основание Dokaflex",
            "sk": "Dokaflex podopretie",
            "pl": "Dokaflex – system podpór"
        },
        {
            "ID": "FLEX30OP__A9_AW1",
            "de": "Eurex 20",
            "en-gb": "Eurex 20",
            "en-us": "Eurex 20",
            "es": "Eurex 20",
            "fr": "Eurex 20",
            "it": "Eurex 20",
            "hu": "Eurex 20",
            "ru": "Eurex 20",
            "sk": "Eurex 20",
            "pl": "Eurex 20"
        },
        {
            "ID": "FLEX30OP__A9_AW2",
            "de": "Eurex 30",
            "en-gb": "Eurex 30",
            "en-us": "Eurex 30",
            "es": "Eurex 30",
            "fr": "Eurex 30",
            "it": "Eurex 30",
            "hu": "Eurex 30",
            "ru": "Eurex 30",
            "sk": "Eurex 30",
            "pl": "Eurex 30"
        },
        {
            "ID": "FLEX30OP__A9_AW3",
            "de": "Eurex 60",
            "en-gb": "Eurex 60",
            "en-us": "Eurex 60",
            "es": "Eurex 60",
            "fr": "Eurex 60",
            "it": "Eurex 60",
            "hu": "Eurex 60",
            "ru": "Eurex 60",
            "sk": "Eurex 60",
            "pl": "Eurex 60"
        },
        {
            "ID": "FLEX30OP__A9_AW4",
            "de": "Eurex 20 eco",
            "en-gb": "Eurex 20 eco",
            "en-us": "Eurex 20 eco",
            "es": "Eurex 20 eco",
            "fr": "Eurex 20 eco",
            "it": "Eurex 20 eco",
            "hu": "Eurex 20 eco",
            "ru": "Eurex 20 eco",
            "sk": "Eurex 20 eco",
            "pl": "Eurex 20 eco"
        },
        {
            "ID": "FLEX30OP__A9_AW7",
            "de": "Eurex 20 top",
            "en-gb": "Eurex 20 top",
            "en-us": "Eurex 20 top",
            "es": "Eurex 20 top",
            "fr": "Eurex 20 top",
            "it": "Eurex 20 top",
            "hu": "Eurex 20 top",
            "ru": "Eurex 20 top",
            "sk": "Eurex 20 top",
            "pl": "Eurex 20 top"
        },
        {
            "ID": "FLEX30OP__A9_AW8",
            "de": "Eurex 30 top",
            "en-gb": "Eurex 30 top",
            "en-us": "Eurex 30 top",
            "es": "Eurex 30 top",
            "fr": "Eurex 30 top",
            "it": "Eurex 30 top",
            "hu": "Eurex 30 top",
            "ru": "Eurex 30 top",
            "sk": "Eurex 30 top",
            "pl": "Eurex 30 top"
        },
        {
            "ID": "FLEXOPT__A11",
            "de": "Dokaflex Unterzug Ausführung",
            "en-gb": "Dokaflex drop beam type",
            "en-us": "Dokaflex drop beam type",
            "es": "Diseño de jácenas Dokaflex",
            "fr": "Retombée poutre exécution Dokaflex",
            "it": "Dokaflex Trave Esecuzione",
            "hu": "Dokaflex gerenda kialakítás",
            "ru": "Исполнение прогона Dokaflex",
            "sk": "Dokaflex prievlaku vytvorenie",
            "pl": "Dokaflex – rodzaj podciągu"
        },
        {
            "ID": "FLEXOPT__A11_AW1",
            "de": "Balkenzwinge 20",
            "en-gb": "Beam forming support 20",
            "en-us": "Beam forming support 20",
            "es": "Soporte viga 20",
            "fr": "Équerre de poutre 20",
            "it": "Morsa a squadra per bloccaggio trave 20",
            "hu": "Gerendazsaluzó kaloda 20",
            "ru": "Балочный зажим 20",
            "sk": "Prievlaková klieština 20",
            "pl": "Zacisk belkowy 20"
        },
        {
            "ID": "FLEXOPT__A11_AW2",
            "de": "bauseitige Kanthölzer",
            "en-gb": "Squared timber by site",
            "en-us": "Squared timber by site",
            "es": "Listónes por parte de obra",
            "fr": "Bois chantier",
            "it": "Travi squadrate in cantiere",
            "hu": "Fabetét helyi anyag",
            "ru": "деревянные брусья (силами заказчика)",
            "sk": "Hranoly dod. stavby",
            "pl": "Kantówka (po stronie budowy)"
        },
        {
            "ID": "FLEXOPT__A11_AW3",
            "de": "Balkenzwinge 20 + H20 Träger variabel",
            "en-gb": "Beam forming support 20 + H20 beam variable",
            "en-us": "Beam forming support 20 + H20 beam variable",
            "es": "Soporte viga 20 + H20 viga variable",
            "fr": "Équerre de poutre 20 + H20 poutrelle variable",
            "it": "Morsa a sq. per blocc. trave 20 + trave H20 variabili",
            "hu": "Gerendazsaluzó kaloda 20 + H20 variábilis fatartó",
            "ru": "Балочный зажим 20 + Н20 балка различные",
            "sk": "Prievlaková klieština 20 + Variabilná nosník H20",
            "pl": "Zacisk belkowy 20 + H20"
        },
        {
            "ID": "FLEXOPT__A12",
            "de": "Schaltafel Größe",
            "en-gb": "Shutter Board dimension",
            "en-us": "Shutter Board dimension",
            "es": "Tamaño del tablero de encofrado",
            "fr": "Contreplaqué taille",
            "it": "Grandezza della tavola di casseratura",
            "hu": "Zsaluhéj méret",
            "ru": "Размер опалубочного щита",
            "sk": "Veľkosť dosky stola",
            "pl": "Wymiary deski szalunkowej"
        },
        {
            "ID": "FLEXOPT__A12_AW1",
            "de": "100/50 cm",
            "en-gb": "100/50 cm",
            "en-us": "100/50 cm",
            "es": "100/50 cm",
            "fr": "100/50 cm",
            "it": "100/50 cm",
            "hu": "100/50 cm",
            "ru": "100/50 cм",
            "sk": "100/50 cm",
            "pl": "100/50 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW10",
            "de": "35/200 cm",
            "en-gb": "35/200 cm",
            "en-us": "35/200 cm",
            "es": "35/200 cm",
            "fr": "35/200 cm",
            "it": "35/200 cm",
            "hu": "35/200 cm",
            "ru": "35/200 cм",
            "sk": "35/200 cm",
            "pl": "35/200 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW11",
            "de": "35/250 cm",
            "en-gb": "35/250 cm",
            "en-us": "35/250 cm",
            "es": "35/250 cm",
            "fr": "35/250 cm",
            "it": "35/250 cm",
            "hu": "35/250 cm",
            "ru": "35/250 cм",
            "sk": "35/250 cm",
            "pl": "35/250 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW12",
            "de": "14,7/200 cm",
            "en-gb": "14,7/200 cm",
            "en-us": "14.7/200cm",
            "es": "14,7/200cm",
            "fr": "14,7/200cm",
            "it": "14,7/200 cm",
            "hu": "14,7/200 cm",
            "ru": "14,7/200 cм",
            "sk": "14,7/200 cm",
            "pl": "14,7/200 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW13",
            "de": "14,7/250 cm",
            "en-gb": "14,7/250 cm",
            "en-us": "14.7/250cm",
            "es": "14,7/250cm",
            "fr": "14,7/250cm",
            "it": "14,7/250 cm",
            "hu": "14,7/250 cm",
            "ru": "14,7/250 cм",
            "sk": "14,7/250 cm",
            "pl": "14,7/250 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW14",
            "de": "100/100 cm",
            "en-gb": "100/100 cm",
            "en-us": "100/100 cm",
            "es": "100/100 cm",
            "fr": "100/100 cm",
            "it": "100/100 cm",
            "hu": "100/100 cm",
            "ru": "100/100 cм",
            "sk": "100/100 cm",
            "pl": "100/100 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW15",
            "de": "150/100 cm",
            "en-gb": "150/100 cm",
            "en-us": "150/100cm",
            "es": "150/100cm",
            "fr": "150/100cm",
            "it": "150/100 cm",
            "hu": "150/100 cm",
            "ru": "150/100 cм",
            "sk": "150/100 cm",
            "pl": "150/100 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW16",
            "de": "200/100 cm",
            "en-gb": "200/100 cm",
            "en-us": "200/100 cm",
            "es": "200/100 cm",
            "fr": "200/100 cm",
            "it": "200/100 cm",
            "hu": "200/100 cm",
            "ru": "200/100 cм",
            "sk": "200/100 cm",
            "pl": "200/100 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW17",
            "de": "250/125 cm",
            "en-gb": "250/125 cm",
            "en-us": "250/125 cm",
            "es": "250/125 cm",
            "fr": "250/125 cm",
            "it": "250/125 cm",
            "hu": "250/125 cm",
            "ru": "250/125 cм",
            "sk": "250/125 cm",
            "pl": "250/125 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW2",
            "de": "150/50 cm",
            "en-gb": "150/50 cm",
            "en-us": "150/50 cm",
            "es": "150/50 cm",
            "fr": "150/50 cm",
            "it": "150/50 cm",
            "hu": "150/50 cm",
            "ru": "150/50 cм",
            "sk": "150/50 cm",
            "pl": "150/50 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW3",
            "de": "200/50 cm",
            "en-gb": "200/50 cm",
            "en-us": "200/50 cm",
            "es": "200/50 cm",
            "fr": "200/50 cm",
            "it": "200/50 cm",
            "hu": "200/50 cm",
            "ru": "200/50 cм",
            "sk": "200/50 cm",
            "pl": "200/50 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW4",
            "de": "250/50 cm",
            "en-gb": "250/50 cm",
            "en-us": "250/50 cm",
            "es": "250/50 cm",
            "fr": "250/50 cm",
            "it": "250/50 cm",
            "hu": "250/50 cm",
            "ru": "250/50 cм",
            "sk": "250/50 cm",
            "pl": "250/50 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW5",
            "de": "244/61 cm",
            "en-gb": "244/61 cm",
            "en-us": "244/61 cm",
            "es": "244/61 cm",
            "fr": "244/61 cm",
            "it": "244/61 cm",
            "hu": "244/61 cm",
            "ru": "244/61 cм",
            "sk": "244/61 cm",
            "pl": "244/61 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW6",
            "de": "244/122 cm",
            "en-gb": "244/122 cm",
            "en-us": "244/122 cm",
            "es": "244/122 cm",
            "fr": "244/122 cm",
            "it": "244/122 cm",
            "hu": "244/122 cm",
            "ru": "44/122 cм",
            "sk": "244/122 cm",
            "pl": "244/122 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW7",
            "de": "122/61 cm",
            "en-gb": "122/61 cm",
            "en-us": "122/61 cm",
            "es": "122/61 cm",
            "fr": "122/61 cm",
            "it": "122/61 cm",
            "hu": "122/61 cm",
            "ru": "122/61 cм",
            "sk": "122/61 cm",
            "pl": "122/61 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW8",
            "de": "50/200 cm",
            "en-gb": "50/200 cm",
            "en-us": "50/200 cm",
            "es": "50/200 cm",
            "fr": "50/200 cm",
            "it": "50/200 cm",
            "hu": "50/200 cm",
            "ru": "50/200 cм",
            "sk": "50/200 cm",
            "pl": "50/200 cm"
        },
        {
            "ID": "FLEXOPT__A12_AW9",
            "de": "50/250 cm",
            "en-gb": "50/250 cm",
            "en-us": "50/250 cm",
            "es": "50/250 cm",
            "fr": "50/250 cm",
            "it": "50/250 cm",
            "hu": "50/250 cm",
            "ru": "50/250 cм",
            "sk": "50/250 cm",
            "pl": "50/250 cm"
        },
        {
            "ID": "FLEXOPT__A13",
            "de": "Querträgerlänge Deckenfeld",
            "en-gb": "Secondary beam length for slab polygon",
            "en-us": "Joist length for slab polygon",
            "es": "Largo de vigas transversales para sector de techo",
            "fr": "Longueur poutrelle secondaire champ de dalle",
            "it": "Lunghezza trave secondaria Zona solaio",
            "hu": "Kereszttartóhossz födémmezo",
            "ru": "Длина поперечной балки пролета",
            "sk": "Dĺžka priečnych nos. stropného poľa",
            "pl": "Długość dźwigara rusztu górnego Obszar stropu"
        },
        {
            "ID": "FLEXOPT__A13_AW1",
            "de": "1,25 m",
            "en-gb": "1.25 m",
            "en-us": "1.25m",
            "es": "1,25m",
            "fr": "1,25m",
            "it": "1,25 m",
            "hu": "1,25 m",
            "ru": "1,25 м",
            "sk": "1,25 m",
            "pl": "1,25 m"
        },
        {
            "ID": "FLEXOPT__A13_AW10",
            "de": "4,90 m",
            "en-gb": "4.90 m",
            "en-us": "4.90 m",
            "es": "4,90 m",
            "fr": "4,90 m",
            "it": "4,90 m",
            "hu": "4,90 m",
            "ru": "4,90 м",
            "sk": "4,90 m",
            "pl": "4,90 m"
        },
        {
            "ID": "FLEXOPT__A13_AW11",
            "de": "5,90 m",
            "en-gb": "5.90 m",
            "en-us": "5.90m",
            "es": "5,90m",
            "fr": "5,90m",
            "it": "5,90 m",
            "hu": "5,90 m",
            "ru": "5,90 м",
            "sk": "5,90 m",
            "pl": "5,90 m"
        },
        {
            "ID": "FLEXOPT__A13_AW12",
            "de": "8x20/100 cm",
            "en-gb": "8x20/100 cm",
            "en-us": "8x20/100 cm",
            "es": "8x20/100 cm",
            "fr": "8x20/100 cm",
            "it": "8x20/100 cm",
            "hu": "8x20/100 cm",
            "ru": "8x20/100 см",
            "sk": "8x20/100 cm",
            "pl": "8x20/100 cm"
        },
        {
            "ID": "FLEXOPT__A13_AW13",
            "de": "8x20/125 cm",
            "en-gb": "8x20/125 cm",
            "en-us": "8x20/125 cm",
            "es": "8x20/125 cm",
            "fr": "8x20/125 cm",
            "it": "8x20/125 cm",
            "hu": "8x20/125 cm",
            "ru": "8x20/125 см",
            "sk": "8x20/125 cm",
            "pl": "8x20/125 cm"
        },
        {
            "ID": "FLEXOPT__A13_AW14",
            "de": "8x20/150 cm",
            "en-gb": "8x20/150 cm",
            "en-us": "8x20/150 cm",
            "es": "8x20/150 cm",
            "fr": "8x20/150 cm",
            "it": "8x20/150 cm",
            "hu": "8x20/150 cm",
            "ru": "8x20/150 см",
            "sk": "8x20/150 cm",
            "pl": "8x20/150 cm"
        },
        {
            "ID": "FLEXOPT__A13_AW15",
            "de": "8x20/175 cm",
            "en-gb": "8x20/175 cm",
            "en-us": "8x20/175 cm",
            "es": "8x20/175 cm",
            "fr": "8x20/175 cm",
            "it": "8x20/175 cm",
            "hu": "8x20/175 cm",
            "ru": "8x20/175 см",
            "sk": "8x20/175 cm",
            "pl": "8x20/175 cm"
        },
        {
            "ID": "FLEXOPT__A13_AW16",
            "de": "8x20/200 cm",
            "en-gb": "8x20/200 cm",
            "en-us": "8x20/200 cm",
            "es": "8x20/200 cm",
            "fr": "8x20/200 cm",
            "it": "8x20/200 cm",
            "hu": "8x20/200 cm",
            "ru": "8x20/200 см",
            "sk": "8x20/200 cm",
            "pl": "8x20/200 cm"
        },
        {
            "ID": "FLEXOPT__A13_AW2",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80m",
            "es": "1,80m",
            "fr": "1,80m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "FLEXOPT__A13_AW3",
            "de": "2,45 m",
            "en-gb": "2.45 m",
            "en-us": "2.45m",
            "es": "2,45m",
            "fr": "2,45m",
            "it": "2,45 m",
            "hu": "2,45 m",
            "ru": "2,45 м",
            "sk": "2,45 m",
            "pl": "2,45 m"
        },
        {
            "ID": "FLEXOPT__A13_AW4",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65m",
            "es": "2,65m",
            "fr": "2,65m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "FLEXOPT__A13_AW5",
            "de": "2,90 m",
            "en-gb": "2.90 m",
            "en-us": "2.90m",
            "es": "2,90m",
            "fr": "2,90m",
            "it": "2,90 m",
            "hu": "2,90 m",
            "ru": "2,90 м",
            "sk": "2,90 m",
            "pl": "2,90 m"
        },
        {
            "ID": "FLEXOPT__A13_AW6",
            "de": "3,30 m",
            "en-gb": "3.30 m",
            "en-us": "3.30m",
            "es": "3,30m",
            "fr": "3,30m",
            "it": "3,30 m",
            "hu": "3,30 m",
            "ru": "3,30 м",
            "sk": "3,30 m",
            "pl": "3,30 m"
        },
        {
            "ID": "FLEXOPT__A13_AW7",
            "de": "3,60 m",
            "en-gb": "3.60 m",
            "en-us": "3.60m",
            "es": "3,60m",
            "fr": "3,60m",
            "it": "3,60 m",
            "hu": "3,60 m",
            "ru": "3,60 м",
            "sk": "3,60 m",
            "pl": "3,60 m"
        },
        {
            "ID": "FLEXOPT__A13_AW8",
            "de": "3,90 m",
            "en-gb": "3.90 m",
            "en-us": "3.90m",
            "es": "3,90m",
            "fr": "3,90m",
            "it": "3,90 m",
            "hu": "3,90 m",
            "ru": "3,90 м",
            "sk": "3,90 m",
            "pl": "3,90 m"
        },
        {
            "ID": "FLEXOPT__A13_AW9",
            "de": "4,50 m",
            "en-gb": "4.50 m",
            "en-us": "4.50 m",
            "es": "4,50 m",
            "fr": "4,50 m",
            "it": "4,50 m",
            "hu": "4,50 m",
            "ru": "4,50 м",
            "sk": "4,50 m",
            "pl": "4,50 m"
        },
        {
            "ID": "FLEXOPT__A14",
            "de": "Jochträgerlänge Deckenfeld",
            "en-gb": "Primary beam length for slab polygon",
            "en-us": "Stringer length for slab polygon",
            "es": "Largo de vigas principales para sector de techo",
            "fr": "Longueur dalle raid. champ de dalle",
            "it": "Lunghezza trave principale Zona solaio",
            "hu": "Főtartóhossz födémmező",
            "ru": "Длина главных балок пролета",
            "sk": "Dĺžka pozdĺžnych nos. stropného poľa",
            "pl": "Długość dźwigara rusztu dolnegoj Obszar stropu"
        },
        {
            "ID": "FLEXOPT__A14_AW1",
            "de": "1,25 m",
            "en-gb": "1.25 m",
            "en-us": "1.25m",
            "es": "1,25m",
            "fr": "1,25m",
            "it": "1,25 m",
            "hu": "1,25 m",
            "ru": "1,25 м",
            "sk": "1,25 m",
            "pl": "1,25 m"
        },
        {
            "ID": "FLEXOPT__A14_AW10",
            "de": "4,90 m",
            "en-gb": "4.90 m",
            "en-us": "4.90 m",
            "es": "4,90 m",
            "fr": "4,90 m",
            "it": "4,90 m",
            "hu": "4,90 m",
            "ru": "4,90 м",
            "sk": "4,90 m",
            "pl": "4,90 m"
        },
        {
            "ID": "FLEXOPT__A14_AW11",
            "de": "5,90 m",
            "en-gb": "5.90 m",
            "en-us": "5.90m",
            "es": "5,90m",
            "fr": "5,90m",
            "it": "5,90 m",
            "hu": "5,90 m",
            "ru": "5,90 м",
            "sk": "5,90 m",
            "pl": "5,90 m"
        },
        {
            "ID": "FLEXOPT__A14_AW12",
            "de": "8x20/100 cm",
            "en-gb": "8x20/100 cm",
            "en-us": "8x20/100 cm",
            "es": "8x20/100 cm",
            "fr": "8x20/100 cm",
            "it": "8x20/100 cm",
            "hu": "8x20/100 cm",
            "ru": "8x20/100 см",
            "sk": "8x20/100 cm",
            "pl": "8x20/100 cm"
        },
        {
            "ID": "FLEXOPT__A14_AW13",
            "de": "8x20/125 cm",
            "en-gb": "8x20/125 cm",
            "en-us": "8x20/125 cm",
            "es": "8x20/125 cm",
            "fr": "8x20/125 cm",
            "it": "8x20/125 cm",
            "hu": "8x20/125 cm",
            "ru": "8x20/125 см",
            "sk": "8x20/125 cm",
            "pl": "8x20/125 cm"
        },
        {
            "ID": "FLEXOPT__A14_AW14",
            "de": "8x20/150 cm",
            "en-gb": "8x20/150 cm",
            "en-us": "8x20/150 cm",
            "es": "8x20/150 cm",
            "fr": "8x20/150 cm",
            "it": "8x20/150 cm",
            "hu": "8x20/150 cm",
            "ru": "8x20/150 см",
            "sk": "8x20/150 cm",
            "pl": "8x20/150 cm"
        },
        {
            "ID": "FLEXOPT__A14_AW15",
            "de": "8x20/175 cm",
            "en-gb": "8x20/175 cm",
            "en-us": "8x20/175 cm",
            "es": "8x20/175 cm",
            "fr": "8x20/175 cm",
            "it": "8x20/175 cm",
            "hu": "8x20/175 cm",
            "ru": "8x20/175 см",
            "sk": "8x20/175 cm",
            "pl": "8x20/175 cm"
        },
        {
            "ID": "FLEXOPT__A14_AW16",
            "de": "8x20/200 cm",
            "en-gb": "8x20/200 cm",
            "en-us": "8x20/200 cm",
            "es": "8x20/200 cm",
            "fr": "8x20/200 cm",
            "it": "8x20/200 cm",
            "hu": "8x20/200 cm",
            "ru": "8x20/200 см",
            "sk": "8x20/200 cm",
            "pl": "8x20/200 cm"
        },
        {
            "ID": "FLEXOPT__A14_AW2",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80m",
            "es": "1,80m",
            "fr": "1,80m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "FLEXOPT__A14_AW3",
            "de": "2,45 m",
            "en-gb": "2.45 m",
            "en-us": "2.45m",
            "es": "2,45m",
            "fr": "2,45m",
            "it": "2,45 m",
            "hu": "2,45 m",
            "ru": "2,45 м",
            "sk": "2,45 m",
            "pl": "2,45 m"
        },
        {
            "ID": "FLEXOPT__A14_AW4",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65m",
            "es": "2,65m",
            "fr": "2,65m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "FLEXOPT__A14_AW5",
            "de": "2,90 m",
            "en-gb": "2.90 m",
            "en-us": "2.90m",
            "es": "2,90m",
            "fr": "2,90m",
            "it": "2,90 m",
            "hu": "2,90 m",
            "ru": "2,90 м",
            "sk": "2,90 m",
            "pl": "2,90 m"
        },
        {
            "ID": "FLEXOPT__A14_AW6",
            "de": "3,30 m",
            "en-gb": "3.30 m",
            "en-us": "3.30m",
            "es": "3,30m",
            "fr": "3,30m",
            "it": "3,30 m",
            "hu": "3,30 m",
            "ru": "3,30 м",
            "sk": "3,30 m",
            "pl": "3,30 m"
        },
        {
            "ID": "FLEXOPT__A14_AW7",
            "de": "3,60 m",
            "en-gb": "3.60 m",
            "en-us": "3.60m",
            "es": "3,60m",
            "fr": "3,60m",
            "it": "3,60 m",
            "hu": "3,60 m",
            "ru": "3,60 м",
            "sk": "3,60 m",
            "pl": "3,60 m"
        },
        {
            "ID": "FLEXOPT__A14_AW8",
            "de": "3,90 m",
            "en-gb": "3.90 m",
            "en-us": "3.90m",
            "es": "3,90m",
            "fr": "3,90m",
            "it": "3,90 m",
            "hu": "3,90 m",
            "ru": "3,90 м",
            "sk": "3,90 m",
            "pl": "3,90 m"
        },
        {
            "ID": "FLEXOPT__A14_AW9",
            "de": "4,50 m",
            "en-gb": "4.50 m",
            "en-us": "4.50 m",
            "es": "4,50 m",
            "fr": "4,50 m",
            "it": "4,50 m",
            "hu": "4,50 m",
            "ru": "4,50 м",
            "sk": "4,50 m",
            "pl": "4,50 m"
        },
        {
            "ID": "FLEXOPT__A15",
            "de": "Dokaflex Stützenköpfe",
            "en-gb": "Dokaflex prop heads",
            "en-us": "Dokaflex prop heads",
            "es": "Cabezales Dokaflex",
            "fr": "Têtes d'étançon Dokaflex",
            "it": "Teste dei puntelli Dokaflex",
            "hu": "Dokaflex támaszfej",
            "ru": "Головки колонн Dokaflex",
            "sk": "Dokaflex hlavice podpier",
            "pl": "Dokaflex głowice podpory"
        },
        {
            "ID": "FLEXOPT__A15_AW1",
            "de": "Vierwegkopf H20",
            "en-gb": "4-way head H20",
            "en-us": "4-way head H20",
            "es": "Cabeza de cuatro vías H20",
            "fr": "Tête en croix H20",
            "it": "Testa a 4 vie H20",
            "hu": "Villásfej H20",
            "ru": "Четырехходовая головка H20:",
            "sk": "Štvorcestná hlavica H20",
            "pl": "Głowica czterokierunkowa H20"
        },
        {
            "ID": "FLEXOPT__A15_AW2",
            "de": "Absenkkopf H20",
            "en-gb": "Lowering head H20",
            "en-us": "Lowering head H20",
            "es": "Cabeza de descimbrado H20",
            "fr": "Tête de décoffrage H20",
            "it": "Testa di disarmo H20",
            "hu": "Ejtofej H20",
            "ru": "Опускаемая головка H20",
            "sk": "Spúšťacia hlavica H20",
            "pl": "Głowica opuszczalna H20"
        },
        {
            "ID": "FLEXOPT__A15_AW3",
            "de": "Kopfgabel 12,5 cm",
            "en-gb": "U-head 12.5cm",
            "en-us": "U-head 12.5cm",
            "es": "Cabezal 12,5cm",
            "fr": "Tête à fourche 12,5cm",
            "it": "Forcella di testa 12,5cm",
            "hu": "Villás fej 12,5 cm",
            "ru": "Вилка головки 12,5 см",
            "sk": "Hlavová vidlica 12,5 cm",
            "pl": "Widły do głowicy 12,5cm"
        },
        {
            "ID": "FLEXOPT__A15_AW4",
            "de": "Haltekopf H20",
            "en-gb": "Supporting head H20",
            "en-us": "Supporting head H20",
            "es": "Cabeza de soporte H20",
            "fr": "Tête de support H20",
            "it": "Testa d'appoggio H20",
            "hu": "Rugós tartófej H20",
            "ru": "Удерживающая головка H20",
            "sk": "Pridržovacia hlavica H20",
            "pl": "Głowica utrzymująca H20"
        },
        {
            "ID": "FLEXOPT__A15_AW5",
            "de": "Haltekopf H20 DF",
            "en-gb": "Supporting head H20 DF",
            "en-us": "Supporting head H20 DF",
            "es": "Cabeza de soporte H20 DF",
            "fr": "Tête de support H20 DF",
            "it": "Testa d'appoggio H20 DF",
            "hu": "Rugós tartófej H20 DF",
            "ru": "Удерживающая головка H20 DF",
            "sk": "Pridržovacia hlavica H20 DF",
            "pl": "Głowica utrzymująca H20 DF"
        },
        {
            "ID": "FLEXOPT__A16",
            "de": "Querträgerlänge Unterzug",
            "en-gb": "Secondary beam length for drop beam",
            "en-us": "Joist length for drop beam",
            "es": "Largo de vigas transversales para jácenas",
            "fr": "Longueur poutrelle secondaire retombée poutre",
            "it": "Lunghezza trave secondaria Trave",
            "hu": "Kereszttartóhossz gerenda",
            "ru": "Длина поперечных балок прогона",
            "sk": "Dĺžka priečnych nos. prievlaku",
            "pl": "Długość dźwigara rusztu górnego Podciąg"
        },
        {
            "ID": "FLEXOPT__A16_AW1",
            "de": "1,25 m",
            "en-gb": "1.25 m",
            "en-us": "1.25 m",
            "es": "1,25m",
            "fr": "1,25 m",
            "it": "1,25 m",
            "hu": "1,25 m",
            "ru": "1,25 м",
            "sk": "1,25 m",
            "pl": "1,25 m"
        },
        {
            "ID": "FLEXOPT__A16_AW10",
            "de": "4,90 m",
            "en-gb": "4.90 m",
            "en-us": "4.90 m",
            "es": "4,90 m",
            "fr": "4,90 m",
            "it": "4,90 m",
            "hu": "4,90 m",
            "ru": "4,90 м",
            "sk": "4,90 m",
            "pl": "4,90 m"
        },
        {
            "ID": "FLEXOPT__A16_AW11",
            "de": "5,90 m",
            "en-gb": "5.90 m",
            "en-us": "5.90 m",
            "es": "5,90m",
            "fr": "5,90 m",
            "it": "5,90 m",
            "hu": "5,90 m",
            "ru": "5,90 м",
            "sk": "5,90 m",
            "pl": "5,90 m"
        },
        {
            "ID": "FLEXOPT__A16_AW12",
            "de": "8x20/100 cm",
            "en-gb": "8x20/100 cm",
            "en-us": "8x20/100 cm",
            "es": "8x20/100 cm",
            "fr": "8x20/100 cm",
            "it": "8x20/100 cm",
            "hu": "8x20/100 cm",
            "ru": "8x20/100 см",
            "sk": "8x20/100 cm",
            "pl": "8x20/100 cm"
        },
        {
            "ID": "FLEXOPT__A16_AW13",
            "de": "8x20/125 cm",
            "en-gb": "8x20/125 cm",
            "en-us": "8x20/125 cm",
            "es": "8x20/125 cm",
            "fr": "8x20/125 cm",
            "it": "8x20/125 cm",
            "hu": "8x20/125 cm",
            "ru": "8x20/125 см",
            "sk": "8x20/125 cm",
            "pl": "8x20/125 cm"
        },
        {
            "ID": "FLEXOPT__A16_AW14",
            "de": "8x20/150 cm",
            "en-gb": "8x20/150 cm",
            "en-us": "8x20/150 cm",
            "es": "8x20/150 cm",
            "fr": "8x20/150 cm",
            "it": "8x20/150 cm",
            "hu": "8x20/150 cm",
            "ru": "8x20/150 см",
            "sk": "8x20/150 cm",
            "pl": "8x20/150 cm"
        },
        {
            "ID": "FLEXOPT__A16_AW15",
            "de": "8x20/175 cm",
            "en-gb": "8x20/175 cm",
            "en-us": "8x20/175 cm",
            "es": "8x20/175 cm",
            "fr": "8x20/175 cm",
            "it": "8x20/175 cm",
            "hu": "8x20/175 cm",
            "ru": "8x20/175 см",
            "sk": "8x20/175 cm",
            "pl": "8x20/175 cm"
        },
        {
            "ID": "FLEXOPT__A16_AW16",
            "de": "8x20/200 cm",
            "en-gb": "8x20/200 cm",
            "en-us": "8x20/200 cm",
            "es": "8x20/200 cm",
            "fr": "8x20/200 cm",
            "it": "8x20/200 cm",
            "hu": "8x20/200 cm",
            "ru": "8x20/200 см",
            "sk": "8x20/200 cm",
            "pl": "8x20/200 cm"
        },
        {
            "ID": "FLEXOPT__A16_AW2",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80 m",
            "es": "1,80m",
            "fr": "1,80 m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "FLEXOPT__A16_AW3",
            "de": "2,45 m",
            "en-gb": "2.45 m",
            "en-us": "2.45 m",
            "es": "2,45m",
            "fr": "2,45 m",
            "it": "2,45 m",
            "hu": "2,45 m",
            "ru": "2,45 м",
            "sk": "2,45 m",
            "pl": "2,45 m"
        },
        {
            "ID": "FLEXOPT__A16_AW4",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65 m",
            "es": "2,65m",
            "fr": "2,65 m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "FLEXOPT__A16_AW5",
            "de": "2,90 m",
            "en-gb": "2.90 m",
            "en-us": "2.90 m",
            "es": "2,90m",
            "fr": "2,90 m",
            "it": "2,90 m",
            "hu": "2,90 m",
            "ru": "2,90 м",
            "sk": "2,90 m",
            "pl": "2,90 m"
        },
        {
            "ID": "FLEXOPT__A16_AW6",
            "de": "3,30 m",
            "en-gb": "3.30 m",
            "en-us": "3.30 m",
            "es": "3,30m",
            "fr": "3,30 m",
            "it": "3,30 m",
            "hu": "3,30 m",
            "ru": "3,30 м",
            "sk": "3,30 m",
            "pl": "3,30 m"
        },
        {
            "ID": "FLEXOPT__A16_AW7",
            "de": "3,60 m",
            "en-gb": "3.60 m",
            "en-us": "3.60 m",
            "es": "3,60m",
            "fr": "3,60 m",
            "it": "3,60 m",
            "hu": "3,60 m",
            "ru": "3,60 м",
            "sk": "3,60 m",
            "pl": "3,60 m"
        },
        {
            "ID": "FLEXOPT__A16_AW8",
            "de": "3,90 m",
            "en-gb": "3.90 m",
            "en-us": "3.90 m",
            "es": "3,90m",
            "fr": "3,90 m",
            "it": "3,90 m",
            "hu": "3,90 m",
            "ru": "3,90 м",
            "sk": "3,90 m",
            "pl": "3,90 m"
        },
        {
            "ID": "FLEXOPT__A16_AW9",
            "de": "4,50 m",
            "en-gb": "4.50 m",
            "en-us": "4.50 m",
            "es": "4,50 m",
            "fr": "4,50 m",
            "it": "4,50 m",
            "hu": "4,50 m",
            "ru": "4,50 м",
            "sk": "4,50 m",
            "pl": "4,50 m"
        },
        {
            "ID": "FLEXOPT__A17",
            "de": "Jochträgerlänge Unterzug",
            "en-gb": "Primary beam length for drop beam",
            "en-us": "Stringer length for drop beam",
            "es": "Largo de vigas principales para jácenas",
            "fr": "Longueur dalle raid. retombée poutre",
            "it": "Lunghezza trave principale Trave",
            "hu": "Főtartóhossz gerenda",
            "ru": "Длина главных балок прогон",
            "sk": "Dĺžka pozdĺžnych nos. prievlaku",
            "pl": "Długość dźwigara rusztu dolnego Podciąg"
        },
        {
            "ID": "FLEXOPT__A17_AW1",
            "de": "1,25 m",
            "en-gb": "1.25 m",
            "en-us": "1.25 m",
            "es": "1,25m",
            "fr": "1,25 m",
            "it": "1,25 m",
            "hu": "1,25 m",
            "ru": "1,25 м",
            "sk": "1,25 m",
            "pl": "1,25 m"
        },
        {
            "ID": "FLEXOPT__A17_AW10",
            "de": "4,90 m",
            "en-gb": "4.90 m",
            "en-us": "4.90 m",
            "es": "4,90 m",
            "fr": "4,90 m",
            "it": "4,90 m",
            "hu": "4,90 m",
            "ru": ",90 м",
            "sk": "4,90 m",
            "pl": "4,90 m"
        },
        {
            "ID": "FLEXOPT__A17_AW11",
            "de": "5,90 m",
            "en-gb": "5.90 m",
            "en-us": "5.90 m",
            "es": "5,90m",
            "fr": "5,90 m",
            "it": "5,90 m",
            "hu": "5,90 m",
            "ru": ",90 м",
            "sk": "5,90 m",
            "pl": "5,90 m"
        },
        {
            "ID": "FLEXOPT__A17_AW12",
            "de": "8x20/100 cm",
            "en-gb": "8x20/100 cm",
            "en-us": "8x20/100 cm",
            "es": "8x20/100 cm",
            "fr": "8x20/100 cm",
            "it": "8x20/100 cm",
            "hu": "8x20/100 cm",
            "ru": "8x20/100 см",
            "sk": "8x20/100 cm",
            "pl": "8x20/100 cm"
        },
        {
            "ID": "FLEXOPT__A17_AW13",
            "de": "8x20/125 cm",
            "en-gb": "8x20/125 cm",
            "en-us": "8x20/125 cm",
            "es": "8x20/125 cm",
            "fr": "8x20/125 cm",
            "it": "8x20/125 cm",
            "hu": "8x20/125 cm",
            "ru": "8x20/125 см",
            "sk": "8x20/125 cm",
            "pl": "8x20/125 cm"
        },
        {
            "ID": "FLEXOPT__A17_AW14",
            "de": "8x20/150 cm",
            "en-gb": "8x20/150 cm",
            "en-us": "8x20/150 cm",
            "es": "8x20/150 cm",
            "fr": "8x20/150 cm",
            "it": "8x20/150 cm",
            "hu": "8x20/150 cm",
            "ru": "8x20/150 см",
            "sk": "8x20/150 cm",
            "pl": "8x20/150 cm"
        },
        {
            "ID": "FLEXOPT__A17_AW15",
            "de": "8x20/175 cm",
            "en-gb": "8x20/175 cm",
            "en-us": "8x20/175 cm",
            "es": "8x20/175 cm",
            "fr": "8x20/175 cm",
            "it": "8x20/175 cm",
            "hu": "8x20/175 cm",
            "ru": "8x20/175 см",
            "sk": "8x20/175 cm",
            "pl": "8x20/175 cm"
        },
        {
            "ID": "FLEXOPT__A17_AW16",
            "de": "8x20/200 cm",
            "en-gb": "8x20/200 cm",
            "en-us": "8x20/200 cm",
            "es": "8x20/200 cm",
            "fr": "8x20/200 cm",
            "it": "8x20/200 cm",
            "hu": "8x20/200 cm",
            "ru": "8x20/200 см",
            "sk": "8x20/200 cm",
            "pl": "8x20/200 cm"
        },
        {
            "ID": "FLEXOPT__A17_AW2",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80 m",
            "es": "1,80m",
            "fr": "1,80 m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "FLEXOPT__A17_AW3",
            "de": "2,45 m",
            "en-gb": "2.45 m",
            "en-us": "2.45 m",
            "es": "2,45m",
            "fr": "2,45 m",
            "it": "2,45 m",
            "hu": "2,45 m",
            "ru": "2,45 м",
            "sk": "2,45 m",
            "pl": "2,45 m"
        },
        {
            "ID": "FLEXOPT__A17_AW4",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65 m",
            "es": "2,65m",
            "fr": "2,65 m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "FLEXOPT__A17_AW5",
            "de": "2,90 m",
            "en-gb": "2.90 m",
            "en-us": "2.90 m",
            "es": "2,90m",
            "fr": "2,90 m",
            "it": "2,90 m",
            "hu": "2,90 m",
            "ru": "2,90 м",
            "sk": "2,90 m",
            "pl": "2,90 m"
        },
        {
            "ID": "FLEXOPT__A17_AW6",
            "de": "3,30 m",
            "en-gb": "3.30 m",
            "en-us": "3.30 m",
            "es": "3,30m",
            "fr": "3,30 m",
            "it": "3,30 m",
            "hu": "3,30 m",
            "ru": "3,30 м",
            "sk": "3,30 m",
            "pl": "3,30 m"
        },
        {
            "ID": "FLEXOPT__A17_AW7",
            "de": "3,60 m",
            "en-gb": "3.60 m",
            "en-us": "3.60 m",
            "es": "3,60m",
            "fr": "3,60 m",
            "it": "3,60 m",
            "hu": "3,60 m",
            "ru": "3,60 м",
            "sk": "3,60 m",
            "pl": "3,60 m"
        },
        {
            "ID": "FLEXOPT__A17_AW8",
            "de": "3,90 m",
            "en-gb": "3.90 m",
            "en-us": "3.90 m",
            "es": "3,90m",
            "fr": "3,90 m",
            "it": "3,90 m",
            "hu": "3,90 m",
            "ru": "3,90 м",
            "sk": "3,90 m",
            "pl": "3,90 m"
        },
        {
            "ID": "FLEXOPT__A17_AW9",
            "de": "4,50 m",
            "en-gb": "4.50 m",
            "en-us": "4.50 m",
            "es": "4,50 m",
            "fr": "4,50 m",
            "it": "4,50 m",
            "hu": "4,50 m",
            "ru": "4,50 м",
            "sk": "4,50 m",
            "pl": "4,50 m"
        },
        {
            "ID": "FLEXOPT__A18",
            "de": "Stützbeine",
            "en-gb": "Removable folding tripods",
            "en-us": "Removable folding tripods",
            "es": "Trípodes plegables",
            "fr": "Trépieds amovibles",
            "it": "Treppiedi amovibili",
            "hu": "Támasztólábak",
            "ru": "треноги",
            "sk": "Oporné trojnožky",
            "pl": "Trójnogi składane"
        },
        {
            "ID": "FLEXOPT__A18_AW1",
            "de": "Stützbein",
            "en-gb": "Removable folding tripod",
            "en-us": "Removable folding tripod",
            "es": "Trípode plegable",
            "fr": "Trépied amovible",
            "it": "Treppiede amovibile",
            "hu": "Támasztóláb",
            "ru": "Тренога",
            "sk": "Oporná trojnožka",
            "pl": "Trójnóg"
        },
        {
            "ID": "FLEXOPT__A18_AW2",
            "de": "Stützbein top",
            "en-gb": "Removable folding tripod top",
            "en-us": "Removable folding tripod top",
            "es": "Trípode plegable top",
            "fr": "Trépied amovible top",
            "it": "Treppiede amovibile top",
            "hu": "Támasztóláb top",
            "ru": "Тренога top",
            "sk": "Oporná trojnožka top",
            "pl": "Trójnóg top"
        },
        {
            "ID": "FLEXOPT__A19",
            "de": "Einschubträger",
            "en-gb": "Insertion beam",
            "en-us": "Insertion beam",
            "es": "Viga de extensión",
            "fr": "Poutrelle de compensation",
            "it": "Prolunga orizzontale",
            "hu": "Kiegészítéstartó",
            "ru": "Вдвижная балка",
            "sk": "Zasúvací nosník",
            "pl": "Dźwigar wsuwany"
        },
        {
            "ID": "FLEXOPT__A19_AW1",
            "de": "Einschubträger",
            "en-gb": "Insertion beam",
            "en-us": "Insertion beam",
            "es": "Viga de extensión",
            "fr": "Poutrelle de compensation",
            "it": "Prolunga orizzontale",
            "hu": "Kiegészítéstartó",
            "ru": "Вдвижная балка",
            "sk": "Zasúvací nosník",
            "pl": "Dźwigar wsuwany"
        },
        {
            "ID": "FLEXOPT__A19_AW2",
            "de": "Dokamatic-Einschubträger",
            "en-gb": "Dokamatic insertion beam",
            "en-us": "Dokamatic insertion beam",
            "es": "Viga de extensión Dokamatic",
            "fr": "Poutrelle de compensation Dokamatic",
            "it": "Prolunga orizzontale Dokamatic",
            "hu": "Dokamatic kihúzható tartó",
            "ru": "Dokamatic вдвижная балка",
            "sk": "Dokamatic-zasúvací nosník",
            "pl": "Dźwigar wsuwany Dokamatic"
        },
        {
            "ID": "FLEXOPT__A2",
            "de": "Deckentisch Ausführung",
            "en-gb": "Tableform type",
            "en-us": "Tableform type",
            "es": "Mesa del techo realisación",
            "fr": "Table exécution",
            "it": "Esecuzione per tavolo solaio",
            "hu": "Asztalzsalu kialakítás",
            "ru": "Исполнение опалубочного стола",
            "sk": "Stropný stôl-vytvorenie",
            "pl": "Typ stołu"
        },
        {
            "ID": "FLEXOPT__A2_AW1",
            "de": "Dokamatic-Tisch",
            "en-gb": "Dokamatic table",
            "en-us": "Dokamatic table",
            "es": "Mesa Dokamatic",
            "fr": "Table Dokamatic",
            "it": "Tavolo Dokamatic",
            "hu": "Dokamatic asztal",
            "ru": "Стол Dokamatic",
            "sk": "Dokamatic-stôl",
            "pl": "Stolik Dokamatic"
        },
        {
            "ID": "FLEXOPT__A2_AW2",
            "de": "Dokaflex-Tisch",
            "en-gb": "Dokaflex table",
            "en-us": "Dokaflex table",
            "es": "Mesa Dokaflex",
            "fr": "Table Dokaflex",
            "it": "Tavolo Dokaflex",
            "hu": "Dokaflex asztal",
            "ru": "Стол Dokaflex",
            "sk": "Dokaflex-stôl",
            "pl": "Stolik Dokaflex"
        },
        {
            "ID": "FLEXOPT__A3",
            "de": "Deckentisch Schalhaut",
            "en-gb": "Tableform plywood",
            "en-us": "Tableform plywood",
            "es": "Mesa del techo forro",
            "fr": "Table contreplaqué",
            "it": "Pannello per tavolo solaio",
            "hu": "Asztalzsalu zsaluhéj",
            "ru": "Опалубочный стол палуба",
            "sk": "Stropný stôl deb. doska",
            "pl": "Sklejka do stołu"
        },
        {
            "ID": "FLEXOPT__A3_AW1",
            "de": "3SO-21",
            "en-gb": "3SO-21",
            "en-us": "3SO-21",
            "es": "3SO-21",
            "fr": "3SO-21",
            "it": "3SO-21",
            "hu": "3SO-21",
            "ru": "3SO-21",
            "sk": "3SO-21",
            "pl": "3SO-21"
        },
        {
            "ID": "FLEXOPT__A3_AW2",
            "de": "3SO-27",
            "en-gb": "3SO-27",
            "en-us": "3SO-27",
            "es": "3SO-27",
            "fr": "3SO-27",
            "it": "3SO-27",
            "hu": "3SO-27",
            "ru": "3SO-27",
            "sk": "3SO-27",
            "pl": "3SO-27"
        },
        {
            "ID": "FLEXOPT__A3_AW3",
            "de": "ohne Schalhaut",
            "en-gb": "without plywood",
            "en-us": "without plywood",
            "es": "Sin forro",
            "fr": "sans contreplaqué",
            "it": "senza pannello",
            "hu": "zsaluhéj nélkül",
            "ru": "без палубы",
            "sk": "bez deb. doska",
            "pl": "bez sklejki"
        },
        {
            "ID": "FLEXOPT__A4",
            "de": "Deckentisch Unterstellung",
            "en-gb": "Tableform shoring",
            "en-us": "Tableform shoring",
            "es": "Mesa del techo partes inferiores",
            "fr": "Table étaiement",
            "it": "Puntellazione per tavolo solaio",
            "hu": "Asztalzsalu Alátámasztás",
            "ru": "Опалубочный стол основание",
            "sk": "Stropný stôl-podopretie",
            "pl": "System podpór stołu"
        },
        {
            "ID": "FLEXOPT__A4_AW1",
            "de": "Eurex 20",
            "en-gb": "Eurex 20",
            "en-us": "Eurex 20",
            "es": "Eurex 20",
            "fr": "Eurex 20",
            "it": "Eurex 20",
            "hu": "Eurex 20",
            "ru": "Eurex 20",
            "sk": "Eurex 20",
            "pl": "Eurex 20"
        },
        {
            "ID": "FLEXOPT__A4_AW2",
            "de": "Eurex 30",
            "en-gb": "Eurex 30",
            "en-us": "Eurex 30",
            "es": "Eurex 30",
            "fr": "Eurex 30",
            "it": "Eurex 30",
            "hu": "Eurex 30",
            "ru": "Eurex 30",
            "sk": "Eurex 30",
            "pl": "Eurex 30"
        },
        {
            "ID": "FLEXOPT__A4_AW3",
            "de": "Stütze G",
            "en-gb": "Column G",
            "en-us": "Column G",
            "es": "Puntal G",
            "fr": "Etançon G",
            "it": "Puntello G",
            "hu": "Támasz G",
            "ru": "Колонна G",
            "sk": "Podpera G",
            "pl": "Podpora G"
        },
        {
            "ID": "FLEXOPT__A4_AW4",
            "de": "Stütze N",
            "en-gb": "Column N",
            "en-us": "Column N",
            "es": "Puntal N",
            "fr": "Etançon N",
            "it": "Puntello N",
            "hu": "Támasz N",
            "ru": "Колонна N",
            "sk": "Podpera N",
            "pl": "Podpora N"
        },
        {
            "ID": "FLEXOPT__A4_AW5",
            "de": "Stütze ECO",
            "en-gb": "Column ECO",
            "en-us": "Column ECO",
            "es": "Puntal Eco",
            "fr": "Etançon ECO",
            "it": "Puntello eco",
            "hu": "Támasz Eco",
            "ru": "Колонна ЕСО",
            "sk": "Podpera eco",
            "pl": "Podpora ECO"
        },
        {
            "ID": "FLEXOPT__A4_AW6",
            "de": "Staxo",
            "en-gb": "Staxo",
            "en-us": "Staxo",
            "es": "Staxo",
            "fr": "Staxo",
            "it": "Staxo",
            "hu": "Staxo",
            "ru": "Staxo",
            "sk": "Staxo",
            "pl": "Staxo"
        },
        {
            "ID": "FLEXOPT__A4_AW7",
            "de": "Aluxo",
            "en-gb": "Aluxo",
            "en-us": "Aluxo",
            "es": "Aluxo",
            "fr": "Aluxo",
            "it": "Aluxo",
            "hu": "Aluxo",
            "ru": "Aluxo",
            "sk": "Aluxo",
            "pl": "Aluxo"
        },
        {
            "ID": "FLEXOPT__A4_AW8",
            "de": "Eurex 20 top",
            "en-gb": "Eurex 20 top",
            "en-us": "Eurex 20 top",
            "es": "Eurex 20 top",
            "fr": "Eurex 20 top",
            "it": "Eurex 20 top",
            "hu": "Eurex 20 top",
            "ru": "Eurex 20 top",
            "sk": "Eurex 20 top",
            "pl": "Eurex 20 top"
        },
        {
            "ID": "FLEXOPT__A4_AW9",
            "de": "Eurex 30 top",
            "en-gb": "Eurex 30 top",
            "en-us": "Eurex 30 top",
            "es": "Eurex 30 top",
            "fr": "Eurex 30 top",
            "it": "Eurex 30 top",
            "hu": "Eurex 30 top",
            "ru": "Eurex 30 top",
            "sk": "Eurex 30 top",
            "pl": "Eurex 30 top"
        },
        {
            "ID": "FLEXOPT__A5",
            "de": "Deckentisch Unterstellung Randstützen",
            "en-gb": "Tableform Shoring Edge props",
            "en-us": "Tableform Shoring Edge props",
            "es": "Mesa del techo partes inferiores puntales marginales",
            "fr": "Table étaiement étain de poutrelle de rive",
            "it": "Puntellazione per tavolo solaio Puntelli per i bordi",
            "hu": "Asztalzsalu Alátámasztás Szélso támasz",
            "ru": "Опалубочный стол основание краевые колонны",
            "sk": "Stropný stôl-podopretie okrajové podpery",
            "pl": "Stół – podpory krawędzi systemu podpór"
        },
        {
            "ID": "FLEXOPT__A5_AW1",
            "de": "Standard",
            "en-gb": "Standard",
            "en-us": "Standard",
            "es": "Estándar",
            "fr": "Standard",
            "it": "Standard",
            "hu": "Standard",
            "ru": "Стандарт",
            "sk": "Standard",
            "pl": "Standard"
        },
        {
            "ID": "FLEXOPT__A5_AW2",
            "de": "RS versetzt, 1ZS mit Schwenkkopf",
            "en-gb": "EP repositioned, 1IP with swivel head",
            "en-us": "RS shifted, 1ZS with swivel head",
            "es": "RS desplazado,1PI con cabezal abatible",
            "fr": "EB déplacé, 1 EI avec tête orientable",
            "it": "PP spostato, 1PI con testa d'inclinazione",
            "hu": "SZT áthelyezve, 1KT billentofejjel",
            "ru": "RS смещенный, 1ZS с поворотной головкой",
            "sk": "OP odsadená, 1MP s výkyvnou hlavicou",
            "pl": "Zmiana położenia EP, 1 IP z głowicą obrotową"
        },
        {
            "ID": "FLEXOPT__A5_AW3",
            "de": "RS versetzt, 1ZS mit Stützenanschluss",
            "en-gb": "EP repositioned, 1IP with strut connection",
            "en-us": "RS shifted, 1ZS with strut connection",
            "es": "RS desplazado,1PI con cabezal con. de punt.",
            "fr": "EB déplacé, 1 EI avec raccord d'étai",
            "it": "PP spostato, 1PI con collegamento",
            "hu": "SZT áthelyezve, 1KT támaszcsatlakozóval",
            "ru": "RS смещенный, 1ZS с переходником для колонны",
            "sk": "OP odsadená, 1MP so spojkou podpery",
            "pl": "Zmiana położenia EP, 1 IP z połączeniem podpory"
        },
        {
            "ID": "FLEXOPT__A5_AW4",
            "de": "RS versetzt, 2ZS mit Schwenkkopf",
            "en-gb": "EP repositioned, 2IP with swivel head",
            "en-us": "RS shifted, 2ZS with swivel head",
            "es": "RS desplazado,2PI con cabezal abatible",
            "fr": "EB déplacé, 2 EI avec tête orientable",
            "it": "PP spostato, 2PI con testa d'inclinazione",
            "hu": "SZT áthelyezve, 2KT billentofejjel",
            "ru": "RS смещенный, 2ZS с поворотной головкой",
            "sk": "OP odsadená, 2MP s výkyvnou hlavicou",
            "pl": "Zmiana położenia EP, 2 IP z głowicą obrotową"
        },
        {
            "ID": "FLEXOPT__A5_AW5",
            "de": "RS versetzt, 2ZS mit Stützenanschluss",
            "en-gb": "EP repositioned, 2IP with strut connection",
            "en-us": "RS shifted, 2ZS with strut connection",
            "es": "RS desplazado,2PI con cabezal con. de punt.",
            "fr": "EB déplacé, 2 EI avec raccord d'étai",
            "it": "PP spostato, 2PI con collegamento",
            "hu": "SZT áthelyezve, 2KT támaszcsatlakozóval",
            "ru": "RS смещенный, 2ZS с переходником для колонны",
            "sk": "OP odsadená, 2MP so spojkou podpery",
            "pl": "Zmiana położenia EP, 2 IP z połączeniem podpory"
        },
        {
            "ID": "FLEXOPT__A6",
            "de": "Deckentisch Last-/Fußspindel",
            "en-gb": "Tableform heavy duty screw jack/screw jack foot",
            "en-us": "Floor table heavy duty screw jack/screw jack foot",
            "es": "Mesa del techo gato atornillable/pie con husillo",
            "fr": "Table de plancher vérin rep/vérin pied",
            "it": "Piede / puntello registrabile per tavolo solaio",
            "hu": "Asztalzsalu Teherhordó orsó/Láborsó",
            "ru": "Опалубочный стол шпиндель повышенной нагрузки/опорный шпиндель",
            "sk": "Stropný stôl vret.noha/pätk.vret",
            "pl": "Stół – dźwignik śrubowy o dużej wytrzymałości / podstawa dźwignika śrubowego"
        },
        {
            "ID": "FLEXOPT__A6_AW1",
            "de": "Lastspindel",
            "en-gb": "Heavy duty screw jack",
            "en-us": "Heavy duty screw jack",
            "es": "Gato atornillable",
            "fr": "Vérin rep",
            "it": "Puntello regolabile",
            "hu": "Teherhordó orsó",
            "ru": "Шпиндель повышенной нагрузки",
            "sk": "Vret.noha",
            "pl": "Stopa regulowana wzmocniona"
        },
        {
            "ID": "FLEXOPT__A6_AW2",
            "de": "Fußspindel",
            "en-gb": "Screw jack foot",
            "en-us": "Screw jack foot",
            "es": "Pie con husillo",
            "fr": "Vérin pied",
            "it": "Piede registrabile",
            "hu": "Láborsó",
            "ru": "Опорный шпиндель",
            "sk": "Pätk.vret",
            "pl": "Stopa regulowana"
        },
        {
            "ID": "FLEXOPT__A6_AW3",
            "de": "ohne Spindel",
            "en-gb": "without spindle",
            "en-us": "without spindle",
            "es": "Sin husillo",
            "fr": "sans vérin",
            "it": "senza puntello",
            "hu": "orsó nélkül",
            "ru": "без шпинделя",
            "sk": "bez vretena",
            "pl": "bez wrzeciona"
        },
        {
            "ID": "FLEXOPT__A7",
            "de": "Schaltafel Typ",
            "en-gb": "Shutter Board type",
            "en-us": "Shutter Board type",
            "es": "Tipo di tablero de encofrado",
            "fr": "Type de panneau",
            "it": "Tipo tavola di cassertura",
            "hu": "Zsaluhéj típus",
            "ru": "Тип опалубочного щита",
            "sk": "Typ dosky stola",
            "pl": "Rodzaj deski szalunkowej"
        },
        {
            "ID": "FLEXOPT__A7_AW1",
            "de": "Dokadur-Paneel 21",
            "en-gb": "Dokadur panel 21",
            "en-us": "Dokadur panel 21",
            "es": "Panel Dokadur 21",
            "fr": "Panneau Dokadur 21",
            "it": "Pannello Dokadur 21",
            "hu": "Dokadur tábla 21",
            "ru": "Dokadur панель 21",
            "sk": "Dokadur panel 21",
            "pl": "Panel Dokadur 21"
        },
        {
            "ID": "FLEXOPT__A7_AW10",
            "de": "DokaPly eco 18mm",
            "en-gb": "DokaPly eco 18mm",
            "en-us": "DokaPly eco 18mm",
            "es": "DokaPly eco 18mm",
            "fr": "DokaPly eco 18mm",
            "it": "DokaPly eco 18mm",
            "hu": "DokaPly eco 18mm",
            "ru": "DokaPly eco 18мм",
            "sk": "DokaPly eco 18mm",
            "pl": "DokaPly eco 18mm"
        },
        {
            "ID": "FLEXOPT__A7_AW11",
            "de": "DokaPly eco 21mm",
            "en-gb": "DokaPly eco 21mm",
            "en-us": "DokaPly eco 21mm",
            "es": "DokaPly eco 21mm",
            "fr": "DokaPly eco 21mm",
            "it": "DokaPly eco 21mm",
            "hu": "DokaPly eco 21mm",
            "ru": "DokaPly eco 21мм",
            "sk": "DokaPly eco 21mm",
            "pl": "DokaPly eco 21mm"
        },
        {
            "ID": "FLEXOPT__A7_AW12",
            "de": "Schalungsplatte 3S basic 21mm",
            "en-gb": "Formwork sheet 3S basic 21mm",
            "en-us": "Formwork sheet 3S basic 21mm",
            "es": "Tablero de encofrado 3S basic 21mm",
            "fr": "Panneau 3S basic 21mm",
            "it": "Pannello 3S basic 21mm",
            "hu": "Zsaluhéj 3S basic 21mm",
            "ru": "Опалубочная плита 3S basic 21мм",
            "sk": "Debniaca doska 3S basic 21mm",
            "pl": "Sklejka 3S basic 21mm"
        },
        {
            "ID": "FLEXOPT__A7_AW13",
            "de": "Schalungsplatte 3S cut 21mm",
            "en-gb": "Formwork sheet 3S cut 21mm",
            "en-us": "Formwork sheet 3S cut 21mm",
            "es": "Tablero de encofrado 3S cut 21mm",
            "fr": "Panneau 3S cut 21mm",
            "it": "Pannello 3S cut 21mm",
            "hu": "Zsaluhéj 3S cut 21mm",
            "ru": "Опалубочная плита 3S cut 21мм",
            "sk": "Debniaca doska 3S cut 21mm",
            "pl": "Sklejka 3S cut 21mm"
        },
        {
            "ID": "FLEXOPT__A7_AW14",
            "de": "Schalungsplatte 3S basic 27mm",
            "en-gb": "Formwork sheet 3S basic 27mm",
            "en-us": "Formwork sheet 3S basic 27mm",
            "es": "Tablero de encofrado 3S basic 27mm",
            "fr": "Panneau 3S basic 27mm",
            "it": "Pannello 3S basic 27mm",
            "hu": "Zsaluhéj 3S basic 27mm",
            "ru": "Опалубочная плита 3S basic 27мм",
            "sk": "Debniaca doska 3S basic 27mm",
            "pl": "Sklejka 3S basic 27mm"
        },
        {
            "ID": "FLEXOPT__A7_AW15",
            "de": "Schalungsplatte 3S cut 27mm",
            "en-gb": "Formwork sheet 3S cut 27mm",
            "en-us": "Formwork sheet 3S cut 27mm",
            "es": "Tablero de encofrado 3S cut 27mm",
            "fr": "Panneau 3S cut 27mm",
            "it": "Pannello 3S cut 27mm",
            "hu": "Zsaluhéj 3S cut 27mm",
            "ru": "Опалубочная плита 3S cut 27мм",
            "sk": "Debniaca doska 3S cut 27mm",
            "pl": "Sklejka 3S cut 27mm"
        },
        {
            "ID": "FLEXOPT__A7_AW2",
            "de": "Schalungsplatte 3-SO 21mm",
            "en-gb": "Formwork sheet 3-SO 21 mm",
            "en-us": "Formwork sheet 3-SO 21 mm",
            "es": "Tablero de encofrado 3-SO 21mm",
            "fr": "Panneau 3-SO 21mm",
            "it": "Pannello 3-SO 21mm",
            "hu": "Zsaluhéj 3-SO 21mm",
            "ru": "Опалубочная плита 3-SO 21мм",
            "sk": "Debniaca doska 3-SO 21mm",
            "pl": "Sklejka 3-SO 21mm"
        },
        {
            "ID": "FLEXOPT__A7_AW3",
            "de": "Dokadur-Plex Paneel 21",
            "en-gb": "Dokadur-Plex panel 21",
            "en-us": "Dokadur-Plex panel 21",
            "es": "Dokadur-Plex panel 21",
            "fr": "Panneau Dokadur-Plex 21",
            "it": "Pannello Dokadur-Plex 21",
            "hu": "Dokadur-Plex tábla 21",
            "ru": "Dokadur-Plex панель 21",
            "sk": "Dokadur-Plex panel 21",
            "pl": "Panel Dokadur Plex 21"
        },
        {
            "ID": "FLEXOPT__A7_AW4",
            "de": "Sperrholzplatte",
            "en-gb": "Plywood sheet",
            "en-us": "Plywood sheet",
            "es": "Tablero contrachapado",
            "fr": "Pan. contre-plaqué",
            "it": "Pannello in legno compensato",
            "hu": "Rétegeltlemez",
            "ru": "Распорная плита",
            "sk": "Preglejka",
            "pl": "Arkusz sklejki"
        },
        {
            "ID": "FLEXOPT__A7_AW5",
            "de": "Dokaflex-Tischplatte",
            "en-gb": "Dokaflex table sheet",
            "en-us": "Dokaflex table sheet",
            "es": "Dokaflex Tablero para mesa",
            "fr": "Dessus de table Dokaflex",
            "it": "Pannello tavolo Dokaflex",
            "hu": "Dokaflex-asztalhéj",
            "ru": "Опалубочная плита Dokaflex",
            "sk": "Dokaflex-doska stola",
            "pl": "Dokaflex – sklejka stołu"
        },
        {
            "ID": "FLEXOPT__A7_AW6",
            "de": "Dokadur-Paneel 27",
            "en-gb": "Dokadur panel 27",
            "en-us": "Dokadur panel 27",
            "es": "Dokadur Panel 27",
            "fr": "Panneau Dokadur 27",
            "it": "Pannello Dokadur 27",
            "hu": "Dokadur tábla 27",
            "ru": "Dokadur панель 27",
            "sk": "Dokadur panel 27",
            "pl": "Panel Dokadur 27"
        },
        {
            "ID": "FLEXOPT__A7_AW7",
            "de": "Schalungsplatte 3-SO 27mm",
            "en-gb": "Formwork sheet 3-SO 27 mm",
            "en-us": "Formwork sheet 3-SO 27mm",
            "es": "Tablero de encofrado 3-SO 27mm",
            "fr": "Panneau 3-SO 27mm",
            "it": "Pannello 3-SO 27mm",
            "hu": "Zsaluhéj 3-SO 27mm",
            "ru": "Опалубочная плита 3-SO 27мм",
            "sk": "Debniaca doska 3-SO 27mm",
            "pl": "Sklejka  3-SO 27mm"
        },
        {
            "ID": "FLEXOPT__A8",
            "de": "Schalungsträger Typ",
            "en-gb": "Formwork beam type",
            "en-us": "Formwork beam type",
            "es": "Tipo di viga de madera",
            "fr": "Type de poutrelle",
            "it": "Tipo trave",
            "hu": "Tartó típus",
            "ru": "Тип опалубочной балки",
            "sk": "Typ debniaceho nosníka",
            "pl": "Typ dźwigara"
        },
        {
            "ID": "FLEXOPT__A8_AW1",
            "de": "Träger H20 top P",
            "en-gb": "Beam H20 top P",
            "en-us": "Beam H20 top P",
            "es": "Viga H20 top P",
            "fr": "Poutrelles H20 top P",
            "it": "Trave H20 top P",
            "hu": "Fatartó H20 top P",
            "ru": "Балка H20 top P",
            "sk": "Nosník H20 top P",
            "pl": "Dźwigar H20 top P"
        },
        {
            "ID": "FLEXOPT__A8_AW2",
            "de": "Träger H20 top N",
            "en-gb": "Beam H20 top N",
            "en-us": "Beam H20 top N",
            "es": "Viga H20 top N",
            "fr": "Poutrelles H20 top N",
            "it": "Trave H20 top N",
            "hu": "Fatartó H20 top N",
            "ru": "Балка H20 top N",
            "sk": "Nosník H20 top N",
            "pl": "Dźwigar H20 top N"
        },
        {
            "ID": "FLEXOPT__A8_AW3",
            "de": "Träger H20 eco P",
            "en-gb": "Beam H20 eco P",
            "en-us": "Beam H20 eco P",
            "es": "Viga H20 eco P",
            "fr": "Poutrelles H20 eco P",
            "it": "Trave H20 eco P",
            "hu": "Fatartó H20 eco P",
            "ru": "Балка H20 eco P",
            "sk": "Nosník H20 eco P",
            "pl": "Dźwigar H20 eco P"
        },
        {
            "ID": "FLEXOPT__A8_AW4",
            "de": "Träger H20 eco N",
            "en-gb": "Beam H20 eco N",
            "en-us": "Beam H20 eco N",
            "es": "Viga H20 eco N",
            "fr": "Poutrelles H20 eco N",
            "it": "Trave H20 eco N",
            "hu": "Fatartó H20 eco N",
            "ru": "Балка H20 eco N",
            "sk": "Nosník H20 eco N",
            "pl": "Dźwigar H20 eco N"
        },
        {
            "ID": "FLEXOPT__A8_AW5",
            "de": "Träger H20 Niet P",
            "en-gb": "Beam H20 rivet P",
            "en-us": "Beam H20 rivet P",
            "es": "Viga H20 remache P",
            "fr": "Poutrelles H20 rivet P",
            "it": "Trave H20 Niet P",
            "hu": "Fatartó H20 P szegecselt végű",
            "ru": "Балка H20 заклепка P",
            "sk": "Nosník H20 P s nitmi",
            "pl": "Dźwigar H20 Niet P"
        },
        {
            "ID": "FLEXOPT__A8_AW6",
            "de": "Träger H20 Niet N",
            "en-gb": "Beam H20 rivet N",
            "en-us": "Beam H20 rivet N",
            "es": "Viga H20 remache N",
            "fr": "Poutrelles H20 rivet N",
            "it": "Trave H20 Niet N",
            "hu": "Fatartó H20 N szegecselt végű",
            "ru": "Балка H20 заклепка N",
            "sk": "Nosník H20 N s nitmi",
            "pl": "Dźwigar H20 Niet N"
        },
        {
            "ID": "FLEXOPT__A8_AW7",
            "de": "Kantholz bauseits",
            "en-gb": "Squared timber by site",
            "en-us": "Squared timber by site",
            "es": "Listón por parte de obra",
            "fr": "Bois équarri chantier",
            "it": "Legno squadrato a carico cant.",
            "hu": "Furfa helyi anyag",
            "ru": "Деревянный брус (предоставляется заказчиком)",
            "sk": "Hranol dod. stavby",
            "pl": "Kantówka (po stronie budowy)"
        },
        {
            "ID": "FLEXOPT__A9",
            "de": "Dokaflex Unterstellung",
            "en-gb": "Dokaflex shoring",
            "en-us": "Dokaflex shoring",
            "es": "Apoyo Dokaflex",
            "fr": "Etaiement Dokaflex",
            "it": "Puntellazione Dokaflex",
            "hu": "Dokaflex Alátámasztás",
            "ru": "Основание Dokaflex",
            "sk": "Dokaflex podopretie",
            "pl": "Dokaflex – system podpór"
        },
        {
            "ID": "FLEXOPT__A9_AW1",
            "de": "Eurex 20",
            "en-gb": "Eurex 20",
            "en-us": "Eurex 20",
            "es": "Eurex 20",
            "fr": "Eurex 20",
            "it": "Eurex 20",
            "hu": "Eurex 20",
            "ru": "Eurex 20",
            "sk": "Eurex 20",
            "pl": "Eurex 20"
        },
        {
            "ID": "FLEXOPT__A9_AW2",
            "de": "Eurex 30",
            "en-gb": "Eurex 30",
            "en-us": "Eurex 30",
            "es": "Eurex 30",
            "fr": "Eurex 30",
            "it": "Eurex 30",
            "hu": "Eurex 30",
            "ru": "Eurex 30",
            "sk": "Eurex 30",
            "pl": "Eurex 30"
        },
        {
            "ID": "FLEXOPT__A9_AW3",
            "de": "Eurex 60",
            "en-gb": "Eurex 60",
            "en-us": "Eurex 60",
            "es": "Eurex 60",
            "fr": "Eurex 60",
            "it": "Eurex 60",
            "hu": "Eurex 60",
            "ru": "Eurex 60",
            "sk": "Eurex 60",
            "pl": "Eurex 60"
        },
        {
            "ID": "FLEXOPT__A9_AW4",
            "de": "Stütze G",
            "en-gb": "Column G",
            "en-us": "Column G",
            "es": "Puntal G",
            "fr": "Etançon G",
            "it": "Puntello G",
            "hu": "Támasz G",
            "ru": "Колонна G",
            "sk": "Podpera G",
            "pl": "Podpora G"
        },
        {
            "ID": "FLEXOPT__A9_AW5",
            "de": "Stütze N",
            "en-gb": "Column N",
            "en-us": "Column N",
            "es": "Puntal N",
            "fr": "Etançon N",
            "it": "Puntello N",
            "hu": "Támasz N",
            "ru": "Колонна N",
            "sk": "Podpera N",
            "pl": "Podpora N"
        },
        {
            "ID": "FLEXOPT__A9_AW6",
            "de": "Stütze ECO",
            "en-gb": "Column ECO",
            "en-us": "Column ECO",
            "es": "Puntal ECO",
            "fr": "Etançon ECO",
            "it": "Puntello eco",
            "hu": "Támasz Eco",
            "ru": "Колонна ЕСО",
            "sk": "Podpera ECO",
            "pl": "Podpora ECO"
        },
        {
            "ID": "FLEXOPT__A9_AW7",
            "de": "Eurex 20 top",
            "en-gb": "Eurex 20 top",
            "en-us": "Eurex 20 top",
            "es": "Eurex 20 top",
            "fr": "Eurex 20 top",
            "it": "Eurex 20 top",
            "hu": "Eurex 20 top",
            "ru": "Eurex 20 top",
            "sk": "Eurex 20 top",
            "pl": "Eurex 20 top"
        },
        {
            "ID": "FLEXOPT__A9_AW8",
            "de": "Eurex 30 top",
            "en-gb": "Eurex 30 top",
            "en-us": "Eurex 30 top",
            "es": "Eurex 30 top",
            "fr": "Eurex 30 top",
            "it": "Eurex 30 top",
            "hu": "Eurex 30 top",
            "ru": "Eurex 30 top",
            "sk": "Eurex 30 top",
            "pl": "Eurex 30 top"
        },
        {
            "ID": "FLEXOPT__A9_AW9",
            "de": "Eurex 20 eco",
            "en-gb": "Eurex 20 eco",
            "en-us": "Eurex 20 eco",
            "es": "Eurex 20 eco",
            "fr": "Eurex 20 eco",
            "it": "Eurex 20 eco",
            "hu": "Eurex 20 eco",
            "ru": "Eurex 20 eco",
            "sk": "Eurex 20 eco",
            "pl": "Eurex 20 eco"
        },
        {
            "ID": "FLEXS__A12",
            "de": "Dokaflex Unterzug Ausführung",
            "en-gb": "Dokaflex drop beam type",
            "en-us": "Dokaflex drop beam type",
            "es": "Diseño de jácenas Dokaflex",
            "fr": "Retombée poutre exécution Dokaflex",
            "it": "Dokaflex Trave Esecuzione",
            "hu": "Dokaflex gerenda kialakítás",
            "ru": "Исполнение прогона Dokaflex",
            "sk": "Dokaflex prievlaku vytvorenie",
            "pl": "Dokaflex – rodzaj podciągu"
        },
        {
            "ID": "FLEXS__A12_AW1",
            "de": "Balkenzwinge 20",
            "en-gb": "Beam forming support 20",
            "en-us": "Beam forming support 20",
            "es": "Soporte viga 20",
            "fr": "Équerre de poutre 20",
            "it": "Morsa a squadra per bloccaggio trave 20",
            "hu": "erendazsaluzó kaloda 20",
            "ru": "Балочный зажим 20",
            "sk": "Prievlaková klieština 20",
            "pl": "Zacisk belkowy 20"
        },
        {
            "ID": "FLEXS__A12_AW2",
            "de": "bauseitige Kanthölzer",
            "en-gb": "Squared timber by site",
            "en-us": "Squared timber by site",
            "es": "Listónes por parte de obra",
            "fr": "Bois chantier",
            "it": "Travi squadrate in cantiere",
            "hu": "Fabetét helyi anyag",
            "ru": "деревянные брусья (силами заказчика)",
            "sk": "Hranoly dod. stavby",
            "pl": "Kantówka (po stronie budowy)"
        },
        {
            "ID": "FLEXS__A12_AW3",
            "de": "H20 Träger variabel",
            "en-gb": "H20 beam variable",
            "en-us": "H20 beam variable",
            "es": "H20 viga variable",
            "fr": "H20 poutrelle variable",
            "it": "Trave H20 variabili",
            "hu": "H20 variábilis fatartó",
            "ru": "H20-балки различные",
            "sk": "Variabilná nosník H20",
            "pl": "Dźwigar H20 - dł. zmienna"
        },
        {
            "ID": "FLEXS__A13",
            "de": "Querträgerlänge Deckenfeld",
            "en-gb": "Secondary beam length for slab polygon",
            "en-us": "Joist length for slab polygon",
            "es": "Largo de vigas transversales para sector de techo",
            "fr": "Longueur poutrelle secondaire champ de dalle",
            "it": "Lunghezza trave secondaria Zona solaio",
            "hu": "Kereszttartóhossz födémmezo",
            "ru": "Длина поперечной балки пролета",
            "sk": "Dĺžka priečnych nos. stropného poľa",
            "pl": "Długość dźwigara rusztu górnego Obszar stropu"
        },
        {
            "ID": "FLEXS__A13_AW1",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80 m",
            "es": "1,80 m",
            "fr": "1,80 m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "FLEXS__A13_AW10",
            "de": "5,90 m",
            "en-gb": "5.90 m",
            "en-us": "5.90 m",
            "es": "5,90 m",
            "fr": "5,90 m",
            "it": "5,90 m",
            "hu": "5,90 m",
            "ru": "5,90 м",
            "sk": "5,90 m",
            "pl": "5,90 m"
        },
        {
            "ID": "FLEXS__A13_AW11",
            "de": "11'-6\″",
            "en-gb": "11'-6\″",
            "en-us": "11'-6\″",
            "es": "11'-6\″",
            "fr": "11'-6\″",
            "it": "11'-6\″",
            "hu": "11'-6\″",
            "ru": "11'-6\″",
            "sk": "11'-6\″",
            "pl": "11'-6\″"
        },
        {
            "ID": "FLEXS__A13_AW2",
            "de": "2,45 m",
            "en-gb": "2.45 m",
            "en-us": "2.45 m",
            "es": "2,45 m",
            "fr": "2,45 m",
            "it": "2,45 m",
            "hu": "2,45 m",
            "ru": "2,45 м",
            "sk": "2,45 m",
            "pl": "2,45 m"
        },
        {
            "ID": "FLEXS__A13_AW3",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65 m",
            "es": "2,65 m",
            "fr": "2,65 m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "FLEXS__A13_AW4",
            "de": "2,90 m",
            "en-gb": "2.90 m",
            "en-us": "2.90 m",
            "es": "2,90 m",
            "fr": "2,90 m",
            "it": "2,90 m",
            "hu": "2,90 m",
            "ru": "2,90 м",
            "sk": "2,90 m",
            "pl": "2,90 m"
        },
        {
            "ID": "FLEXS__A13_AW5",
            "de": "3,30 m",
            "en-gb": "3.30 m",
            "en-us": "3.30 m",
            "es": "3,30 m",
            "fr": "3,30 m",
            "it": "3,30 m",
            "hu": "3,30 m",
            "ru": "3,30 м",
            "sk": "3,30 m",
            "pl": "3,30 m"
        },
        {
            "ID": "FLEXS__A13_AW6",
            "de": "3,60 m",
            "en-gb": "3.60 m",
            "en-us": "3.60 m",
            "es": "3,60 m",
            "fr": "3,60 m",
            "it": "3,60 m",
            "hu": "3,60 m",
            "ru": "3,60 м",
            "sk": "3,60 m",
            "pl": "3,60 m"
        },
        {
            "ID": "FLEXS__A13_AW7",
            "de": "3,90 m",
            "en-gb": "3.90 m",
            "en-us": "3.90 m",
            "es": "3,90 m",
            "fr": "3,90 m",
            "it": "3,90 m",
            "hu": "3,90 m",
            "ru": "3,90 м",
            "sk": "3,90 m",
            "pl": "3,90 m"
        },
        {
            "ID": "FLEXS__A13_AW8",
            "de": "4,50 m",
            "en-gb": "4.50 m",
            "en-us": "4.50 m",
            "es": "4,50 m",
            "fr": "4,50 m",
            "it": "4,50 m",
            "hu": "4,50 m",
            "ru": "4,50 м",
            "sk": "4,50 m",
            "pl": "4,50 m"
        },
        {
            "ID": "FLEXS__A13_AW9",
            "de": "4,90 m",
            "en-gb": "4.90 m",
            "en-us": "4.90 m",
            "es": "4,90 m",
            "fr": "4,90 m",
            "it": "4,90 m",
            "hu": "4,90 m",
            "ru": "4,90 м",
            "sk": "4,90 m",
            "pl": "4,90 m"
        },
        {
            "ID": "FLEXS__A14",
            "de": "Jochträgerlänge Deckenfeld",
            "en-gb": "Primary beam length for slab polygon",
            "en-us": "Stringer length for slab polygon",
            "es": "Largo de vigas principales para sector de techo",
            "fr": "Longueur dalle raid. champ de dalle",
            "it": "Lunghezza trave principale Zona solaio",
            "hu": "Főtartóhossz födémmező",
            "ru": "Длина главных балок пролета",
            "sk": "Dĺžka pozdĺžnych nos. stropného poľa",
            "pl": "Długość belki głównej Płyta stropu"
        },
        {
            "ID": "FLEXS__A14_AW1",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80 m",
            "es": "1,80 m",
            "fr": "1,80 m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "FLEXS__A14_AW10",
            "de": "5,90 m",
            "en-gb": "5.90 m",
            "en-us": "5.90 m",
            "es": "5,90 m",
            "fr": "5,90 m",
            "it": "5,90 m",
            "hu": "5,90 m",
            "ru": "5,90 м",
            "sk": "5,90 m",
            "pl": "5,90 m"
        },
        {
            "ID": "FLEXS__A14_AW11",
            "de": "16'-0\″",
            "en-gb": "16'-0\″",
            "en-us": "16'-0\″",
            "es": "16'-0\″",
            "fr": "16'-0\″",
            "it": "16'-0\″",
            "hu": "16'-0\″",
            "ru": "16'-0\″",
            "sk": "16'-0\″",
            "pl": "16'-0\″"
        },
        {
            "ID": "FLEXS__A14_AW12",
            "de": "11'-6\″",
            "en-gb": "11'-6\″",
            "en-us": "11'-6\″",
            "es": "11'-6\″",
            "fr": "11'-6\″",
            "it": "11'-6\″",
            "hu": "11'-6\″",
            "ru": "11'-6\″",
            "sk": "11'-6\″",
            "pl": "11'-6\″"
        },
        {
            "ID": "FLEXS__A14_AW2",
            "de": "2,45 m",
            "en-gb": "2.45 m",
            "en-us": "2.45 m",
            "es": "2,45 m",
            "fr": "2,45 m",
            "it": "2,45 m",
            "hu": "2,45 m",
            "ru": "2,45 м",
            "sk": "2,45 m",
            "pl": "2,45 m"
        },
        {
            "ID": "FLEXS__A14_AW3",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65 m",
            "es": "2,65 m",
            "fr": "2,65 m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "FLEXS__A14_AW4",
            "de": "2,90 m",
            "en-gb": "2.90 m",
            "en-us": "2.90 m",
            "es": "2,90 m",
            "fr": "2,90 m",
            "it": "2,90 m",
            "hu": "2,90 m",
            "ru": "2,90 м",
            "sk": "2,90 m",
            "pl": "2,90 m"
        },
        {
            "ID": "FLEXS__A14_AW5",
            "de": "3,30 m",
            "en-gb": "3.30 m",
            "en-us": "3.30 m",
            "es": "3,30 m",
            "fr": "3,30 m",
            "it": "3,30 m",
            "hu": "3,30 m",
            "ru": "3,30 м",
            "sk": "3,30 m",
            "pl": "3,30 m"
        },
        {
            "ID": "FLEXS__A14_AW6",
            "de": "3,60 m",
            "en-gb": "3.60 m",
            "en-us": "3.60 m",
            "es": "3,60 m",
            "fr": "3,60 m",
            "it": "3,60 m",
            "hu": "3,60 m",
            "ru": "3,60 м",
            "sk": "3,60 m",
            "pl": "3,60 m"
        },
        {
            "ID": "FLEXS__A14_AW7",
            "de": "3,90 m",
            "en-gb": "3.90 m",
            "en-us": "3.90 m",
            "es": "3,90 m",
            "fr": "3,90 m",
            "it": "3,90 m",
            "hu": "3,90 m",
            "ru": "3,90 м",
            "sk": "3,90 m",
            "pl": "3,90 m"
        },
        {
            "ID": "FLEXS__A14_AW8",
            "de": "4,50 m",
            "en-gb": "4.50 m",
            "en-us": "4.50 m",
            "es": "4,50 m",
            "fr": "4,50 m",
            "it": "4,50 m",
            "hu": "4,50 m",
            "ru": "4,50 м",
            "sk": "4,50 m",
            "pl": "4,50 m"
        },
        {
            "ID": "FLEXS__A14_AW9",
            "de": "4,90 m",
            "en-gb": "4.90 m",
            "en-us": "4.90 m",
            "es": "4,90 m",
            "fr": "4,90 m",
            "it": "4,90 m",
            "hu": "4,90 m",
            "ru": "4,90 м",
            "sk": "4,90 m",
            "pl": "4,90 m"
        },
        {
            "ID": "FLEXS__A15",
            "de": "Querträgerlänge Unterzug",
            "en-gb": "Secondary beam length for drop beam",
            "en-us": "Joist length for drop beam",
            "es": "Largo de vigas transversales para jácenas",
            "fr": "Longueur poutrelle secondaire retombée poutre",
            "it": "Lunghezza trave secondaria Trave",
            "hu": "Kereszttartóhossz gerenda",
            "ru": "Длина поперечных балок прогона",
            "sk": "Dĺžka priečnych nos. prievlaku",
            "pl": "Długość dźwigara rusztu górnego Podciąg"
        },
        {
            "ID": "FLEXS__A15_AW1",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80 m",
            "es": "1,80 m",
            "fr": "1,80 m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "FLEXS__A15_AW10",
            "de": "5,90 m",
            "en-gb": "5.90 m",
            "en-us": "5.90 m",
            "es": "5,90 m",
            "fr": "5,90 m",
            "it": "5,90 m",
            "hu": "5,90 m",
            "ru": "5,90 м",
            "sk": "5,90 m",
            "pl": "5,90 m"
        },
        {
            "ID": "FLEXS__A15_AW11",
            "de": "11'-6\″",
            "en-gb": "11'-6\″",
            "en-us": "11'-6\″",
            "es": "11'-6\″",
            "fr": "11'-6\″",
            "it": "11'-6\″",
            "hu": "11'-6\″",
            "ru": "11'-6\″",
            "sk": "11'-6\″",
            "pl": "11'-6\″"
        },
        {
            "ID": "FLEXS__A15_AW2",
            "de": "2,45 m",
            "en-gb": "2.45 m",
            "en-us": "2.45 m",
            "es": "2,45 m",
            "fr": "2,45 m",
            "it": "2,45 m",
            "hu": "2,45 m",
            "ru": "2,45 м",
            "sk": "2,45 m",
            "pl": "2,45 m"
        },
        {
            "ID": "FLEXS__A15_AW3",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65 m",
            "es": "2,65 m",
            "fr": "2,65 m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "FLEXS__A15_AW4",
            "de": "2,90 m",
            "en-gb": "2.90 m",
            "en-us": "2.90 m",
            "es": "2,90 m",
            "fr": "2,90 m",
            "it": "2,90 m",
            "hu": "2,90 m",
            "ru": "2,90 м",
            "sk": "2,90 m",
            "pl": "2,90 m"
        },
        {
            "ID": "FLEXS__A15_AW5",
            "de": "3,30 m",
            "en-gb": "3.30 m",
            "en-us": "3.30 m",
            "es": "3,30 m",
            "fr": "3,30 m",
            "it": "3,30 m",
            "hu": "3,30 m",
            "ru": "3,30 м",
            "sk": "3,30 m",
            "pl": "3,30 m"
        },
        {
            "ID": "FLEXS__A15_AW6",
            "de": "3,60 m",
            "en-gb": "3.60 m",
            "en-us": "3.60 m",
            "es": "3,60 m",
            "fr": "3,60 m",
            "it": "3,60 m",
            "hu": "3,60 m",
            "ru": "3,60 м",
            "sk": "3,60 m",
            "pl": "3,60 m"
        },
        {
            "ID": "FLEXS__A15_AW7",
            "de": "3,90 m",
            "en-gb": "3.90 m",
            "en-us": "3.90 m",
            "es": "3,90 m",
            "fr": "3,90 m",
            "it": "3,90 m",
            "hu": "3,90 m",
            "ru": "3,90 м",
            "sk": "3,90 m",
            "pl": "3,90 m"
        },
        {
            "ID": "FLEXS__A15_AW8",
            "de": "4,50 m",
            "en-gb": "4.50 m",
            "en-us": "4.50 m",
            "es": "4,50 m",
            "fr": "4,50 m",
            "it": "4,50 m",
            "hu": "4,50 m",
            "ru": "4,50 м",
            "sk": "4,50 m",
            "pl": "4,50 m"
        },
        {
            "ID": "FLEXS__A15_AW9",
            "de": "4,90 m",
            "en-gb": "4.90 m",
            "en-us": "4.90 m",
            "es": "4,90 m",
            "fr": "4,90 m",
            "it": "4,90 m",
            "hu": "4,90 m",
            "ru": "4,90 м",
            "sk": "4,90 m",
            "pl": "4,90 m"
        },
        {
            "ID": "FLEXS__A16",
            "de": "Jochträgerlänge Unterzug",
            "en-gb": "Primary beam length for drop beam",
            "en-us": "Stringer length for drop beam",
            "es": "Largo de vigas principales para jácenas",
            "fr": "Longueur dalle raid. retombée poutre",
            "it": "Lunghezza trave principale Trave",
            "hu": "Főtartóhossz gerenda",
            "ru": "Длина главных балок прогон",
            "sk": "Dĺžka pozdĺžnych nos. prievlaku",
            "pl": "Długość belki głównej Podciąg"
        },
        {
            "ID": "FLEXS__A16_AW1",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80 m",
            "es": "1,80 m",
            "fr": "1,80 m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "FLEXS__A16_AW10",
            "de": "5,90 m",
            "en-gb": "5.90 m",
            "en-us": "5.90 m",
            "es": "5,90 m",
            "fr": "5,90 m",
            "it": "5,90 m",
            "hu": "5,90 m",
            "ru": "5,90 м",
            "sk": "5,90 m",
            "pl": "5,90 m"
        },
        {
            "ID": "FLEXS__A16_AW11",
            "de": "16'-0\″",
            "en-gb": "16'-0\″",
            "en-us": "16'-0\″",
            "es": "16'-0\″",
            "fr": "16'-0\″",
            "it": "16'-0\″",
            "hu": "16'-0\″",
            "ru": "16'-0\″",
            "sk": "16'-0\″",
            "pl": "16'-0\″"
        },
        {
            "ID": "FLEXS__A16_AW12",
            "de": "11'-6\″",
            "en-gb": "11'-6\″",
            "en-us": "11'-6\″",
            "es": "11'-6\″",
            "fr": "11'-6\″",
            "it": "11'-6\″",
            "hu": "11'-6\″",
            "ru": "11'-6\″",
            "sk": "11'-6\″",
            "pl": "11'-6\″"
        },
        {
            "ID": "FLEXS__A16_AW2",
            "de": "2,45 m",
            "en-gb": "2.45 m",
            "en-us": "2.45 m",
            "es": "2,45 m",
            "fr": "2,45 m",
            "it": "2,45 m",
            "hu": "2,45 m",
            "ru": "2,45 м",
            "sk": "2,45 m",
            "pl": "2,45 m"
        },
        {
            "ID": "FLEXS__A16_AW3",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65 m",
            "es": "2,65 m",
            "fr": "2,65 m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "FLEXS__A16_AW4",
            "de": "2,90 m",
            "en-gb": "2.90 m",
            "en-us": "2.90 m",
            "es": "2,90 m",
            "fr": "2,90 m",
            "it": "2,90 m",
            "hu": "2,90 m",
            "ru": "2,90 м",
            "sk": "2,90 m",
            "pl": "2,90 m"
        },
        {
            "ID": "FLEXS__A16_AW5",
            "de": "3,30 m",
            "en-gb": "3.30 m",
            "en-us": "3.30 m",
            "es": "3,30 m",
            "fr": "3,30 m",
            "it": "3,30 m",
            "hu": "3,30 m",
            "ru": "3,30 м",
            "sk": "3,30 m",
            "pl": "3,30 m"
        },
        {
            "ID": "FLEXS__A16_AW6",
            "de": "3,60 m",
            "en-gb": "3.60 m",
            "en-us": "3.60 m",
            "es": "3,60 m",
            "fr": "3,60 m",
            "it": "3,60 m",
            "hu": "3,60 m",
            "ru": "3,60 м",
            "sk": "3,60 m",
            "pl": "3,60 m"
        },
        {
            "ID": "FLEXS__A16_AW7",
            "de": "3,90 m",
            "en-gb": "3.90 m",
            "en-us": "3.90 m",
            "es": "3,90 m",
            "fr": "3,90 m",
            "it": "3,90 m",
            "hu": "3,90 m",
            "ru": "3,90 м",
            "sk": "3,90 m",
            "pl": "3,90 m"
        },
        {
            "ID": "FLEXS__A16_AW8",
            "de": "4,50 m",
            "en-gb": "4.50 m",
            "en-us": "4.50 m",
            "es": "4,50 m",
            "fr": "4,50 m",
            "it": "4,50 m",
            "hu": "4,50 m",
            "ru": "4,50 м",
            "sk": "4,50 m",
            "pl": "4,50 m"
        },
        {
            "ID": "FLEXS__A16_AW9",
            "de": "4,90 m",
            "en-gb": "4.90 m",
            "en-us": "4.90 m",
            "es": "4,90 m",
            "fr": "4,90 m",
            "it": "4,90 m",
            "hu": "4,90 m",
            "ru": "4,90 м",
            "sk": "4,90 m",
            "pl": "4,90 m"
        },
        {
            "ID": "FLEXS__A17",
            "de": "Schutzgeländer",
            "en-gb": "Guard rail",
            "en-us": "Handrail post",
            "es": "Barandilla de seguridad",
            "fr": "Montant de garde-corps",
            "it": "Parapetti di protezione",
            "hu": "Védőkorlát",
            "ru": "Защитные перила",
            "sk": "Ochranné zábradlie",
            "pl": "Barierki ochronne"
        },
        {
            "ID": "FLEXS__A17_AW1",
            "de": "Schutzgeländerzwinge S",
            "en-gb": "Handrail clamp S",
            "en-us": "Handrail clamp S",
            "es": "Barandilla de seguridad para pasamanos S",
            "fr": "Montant de garde-corps à pince S",
            "it": "Parapetto di protezione S",
            "hu": "Védőkorlát tartó S",
            "ru": "Зажим защитных перил S",
            "sk": "Zverákový stĺpik ochranného zábradlia S",
            "pl": "Zacisk barierki ochronnej S"
        },
        {
            "ID": "FLEXS__A17_AW2",
            "de": "Einschubgeländer T",
            "en-gb": "Handrail post T",
            "en-us": "Handrail post T",
            "es": "Poste pasamanos T",
            "fr": "Montant de garde-corps T",
            "it": "Parapetto d'inserimento T",
            "hu": "Toldat korlát T",
            "ru": "Вставные перила Т",
            "sk": "Zasúvacie zábradlie T",
            "pl": "Barierka wsuwana T"
        },
        {
            "ID": "FLEXS__A6",
            "de": "Schaltafel",
            "en-gb": "Shutter board",
            "en-us": "Plywood",
            "es": "Tablero de encofrado",
            "fr": "Panneau",
            "it": "Tavola di cassertaura",
            "hu": "Zsaluhéj",
            "ru": "Опалубочного щита",
            "sk": "Dosky stola",
            "pl": "Sklejka"
        },
        {
            "ID": "FLEXS__A6_AW1",
            "de": "Schalungsplatte HDO 4'-0x8'-0\″x1/2\″",
            "en-gb": "Formwork sheet HDO 4'-0\″x8'-0\″x1/2\″",
            "en-us": "Formwork sheet HDO 4'-0\″x8'-0\″x1/2\″",
            "es": "Tablero de encofrado HDO 4'-0\″x8'-0\″x1/2\″",
            "fr": "Panneau HDO 4'-0\″x8'-0\″x1/2\″",
            "it": "Pannello HDO 4'-0\″x8'-0\″x1/2\″",
            "hu": "Zsaluhéj HDO 4'-0\″x8'-0\″x1/2\″",
            "ru": "Опалубочная плита HDO 4'-0\″x8'-0\″x1/2\″",
            "sk": "Debniaca doska HDO 4'-0\″x8'-0\″x1/2\″",
            "pl": "Sklejka  HDO 4'-0x8'-0\″x1/2\″"
        },
        {
            "ID": "FLEXS__A6_AW2",
            "de": "Schalungsplatte HDO 4'-0x8'-0\″x3/4\″",
            "en-gb": "Formwork sheet HDO 4'-0\″x8'-0\″x3/4\″",
            "en-us": "Formwork sheet HDO 4'-0\″x8'-0\″x3/4\″",
            "es": "Tablero de encofrado HDO 4'-0\″x8'-0\″x3/4\″",
            "fr": "Panneau HDO 4'-0\″x8'-0\″x3/4\″",
            "it": "Pannello HDO 4'-0\″x8'-0\″x3/4\″",
            "hu": "Zsaluhéj HDO 4'-0\″x8'-0\″x3/4\″",
            "ru": "Опалубочная плита HDO 4'-0\″x8'-0\″x3/4\″",
            "sk": "Debniaca doska HDO 4'-0\″x8'-0\″x3/4\″",
            "pl": "Sklejka  HDO 4'-0x8'-0\″x3/4\″"
        },
        {
            "ID": "FLEXS__A6_AW3",
            "de": "Schalungsplatte HDO 4'-0x8'-0\″x5/8\″",
            "en-gb": "Formwork sheet HDO 4'-0\″x8'-0\″x5/8\″",
            "en-us": "Formwork sheet HDO 4'-0\″x8'-0\″x5/8\″",
            "es": "Tablero de encofrado HDO 4'-0\″x8'-0\″x5/8\″",
            "fr": "Panneau HDO 4'-0\″x8'-0\″x5/8\″",
            "it": "Pannello HDO 4'-0\″x8'-0\″x5/8\″",
            "hu": "Zsaluhéj HDO 4'-0\″x8'-0\″x5/8\″",
            "ru": "Опалубочная плита HDO 4'-0\″x8'-0\″x5/8\″",
            "sk": "Debniaca doska HDO 4'-0\″x8'-0\″x5/8\″",
            "pl": "Sklejka  HDO 4'-0x8'-0\″x5/8\″"
        },
        {
            "ID": "FLEXS__A6_AW4",
            "de": "Schalungsplatte MDO 4'-0x8'-0\″x3/4\″",
            "en-gb": "Formwork sheet MDO 4'-0\″x8'-0\″x3/4\″",
            "en-us": "Formwork sheet MDO 4'-0\″x8'-0\″x3/4\″",
            "es": "Tablero de encofrado MDO 4'-0\″x8'-0\″x3/4\″",
            "fr": "Panneau MDO 4'-0\″x8'-0\″x3/4\″",
            "it": "Pannello MDO 4'-0\″x8'-0\″x3/4\″",
            "hu": "Zsaluhéj MDO 4'-0\″x8'-0\″x3/4\″",
            "ru": "Опалубочная плита MDO 4'-0\″x8'-0\″x3/4\″",
            "sk": "Debniaca doska MDO 4'-0\″x8'-0\″x3/4\″",
            "pl": "Sklejka  MDO 4'-0x8'-0\″x3/4\″"
        },
        {
            "ID": "FLEXS__A8",
            "de": "Dokaflex Unterstellung",
            "en-gb": "Dokaflex shoring",
            "en-us": "Dokaflex shoring",
            "es": "Apoyo Dokaflex",
            "fr": "Etaiement Dokaflex",
            "it": "Puntellazione Dokaflex",
            "hu": "Dokaflex Alátámasztás",
            "ru": "Основание Dokaflex",
            "sk": "Dokaflex podopretie",
            "pl": "Dokaflex system podpór"
        },
        {
            "ID": "FLEXS__A8_AW1",
            "de": "Doka-Deckenstütze Eurex 30 top 250",
            "en-gb": "Doka floor prop Eurex 30 top 250",
            "en-us": "Doka floor prop Eurex 30 top 250",
            "es": "Puntal Doka Eurex 30 top 250",
            "fr": "Etai Doka Eurex 30 top 250",
            "it": "Puntello Doka Eurex 30 top 250",
            "hu": "Doka Eurex 30 top födémtámasz 250",
            "ru": "Doka стойка для перекрытий Eurex 30 top 250",
            "sk": "Doka-stropná podpera Eurex 30 top 250",
            "pl": "Podpora stropowa Doka Eurex 20 top 250"
        },
        {
            "ID": "FLEXS__A8_AW2",
            "de": "Doka-Deckenstütze Eurex 30 top 300",
            "en-gb": "Doka floor prop Eurex 30 top 300",
            "en-us": "Doka floor prop Eurex 30 top 300",
            "es": "Puntal Doka Eurex 30 top 300",
            "fr": "Etai Doka Eurex 30 top 300",
            "it": "Puntello Doka Eurex 30 top 300",
            "hu": "Doka Eurex 30 top födémtámasz 300",
            "ru": "Doka стойка для перекрытий Eurex 30 top 300",
            "sk": "Doka-stropná podpera Eurex 30 top 300",
            "pl": "Podpora stropowa Doka Eurex 20 top 300"
        },
        {
            "ID": "FLEXS__A8_AW3",
            "de": "Doka-Deckenstütze Eurex 30 top 350",
            "en-gb": "Doka floor prop Eurex 30 top 350",
            "en-us": "Doka floor prop Eurex 30 top 350",
            "es": "Puntal Doka Eurex 30 top 350",
            "fr": "Etai Doka Eurex 30 top 350",
            "it": "Puntello Doka Eurex 30 top 350",
            "hu": "Doka Eurex 30 top födémtámasz 350",
            "ru": "Doka стойка для перекрытий Eurex 30 top 350",
            "sk": "Doka-stropná podpera Eurex 30 top 350",
            "pl": "Podpora stropowa Doka Eurex 20 top 350"
        },
        {
            "ID": "FLEXS__A8_AW4",
            "de": "Doka-Deckenstütze Eurex 30 top 400",
            "en-gb": "Doka floor prop Eurex 30 top 400",
            "en-us": "Doka floor prop Eurex 30 top 400",
            "es": "Puntal Doka Eurex 30 top 400",
            "fr": "Etai Doka Eurex 30 top 400",
            "it": "Puntello Doka Eurex 30 top 400",
            "hu": "Doka Eurex 30 top födémtámasz 400",
            "ru": "Doka стойка для перекрытий Eurex 30 top 400",
            "sk": "Doka-stropná podpera Eurex 30 top 400",
            "pl": "Podpora stropowa Doka Eurex 20 top 400"
        },
        {
            "ID": "FLEXS__A8_AW5",
            "de": "Doka-Deckenstütze Eurex 30 top 450",
            "en-gb": "Doka floor prop Eurex 30 top 450",
            "en-us": "Doka floor prop Eurex 30 top 450",
            "es": "Puntal Doka Eurex 30 top 450",
            "fr": "Etai Doka Eurex 30 top 450",
            "it": "Puntello Doka Eurex 30 top 450",
            "hu": "Doka Eurex 30 top födémtámasz 450",
            "ru": "Doka стойка для перекрытий Eurex 30 top 450",
            "sk": "Doka-stropná podpera Eurex 30 top 450",
            "pl": "Podpora stropowa Doka Eurex 20 top 450"
        },
        {
            "ID": "FLEXS__A8_AW6",
            "de": "Doka-Deckenstütze Eurex 30 top 550",
            "en-gb": "Doka floor prop Eurex 30 top 550",
            "en-us": "Doka floor prop Eurex 30 top 550",
            "es": "Puntal Doka Eurex 30 top 550",
            "fr": "Etai Doka Eurex 30 top 550",
            "it": "Puntello Doka Eurex 30 top 550",
            "hu": "Doka Eurex 30 top födémtámasz 550",
            "ru": "Doka стойка для перекрытий Eurex 30 top 550",
            "sk": "Doka-stropná podpera Eurex 30 top 550",
            "pl": "Podpora stropowa Doka Eurex 20 top 550"
        },
        {
            "ID": "FLEXS__A8_AW7",
            "de": "Doka-Deckenstütze Eurex 20 top 550",
            "en-gb": "Doka floor prop Eurex 20 top 550",
            "en-us": "Doka floor prop Eurex 20 top 550",
            "es": "Puntal Doka Eurex 20 top 550",
            "fr": "Etai Doka Eurex 20 top 550",
            "it": "Puntello Doka Eurex 20 top 550",
            "hu": "Doka Eurex 20 top födémtámasz 550",
            "ru": "Doka стойка для перекрытий Eurex 20 top 550",
            "sk": "Doka-stropná podpera Eurex 20 top 550",
            "pl": "Podpora stropowa Doka Eurex 20 top 550"
        },
        {
            "ID": "FRAMECO_A1",
            "de": "Verbindung der Aufstockung",
            "en-gb": "Connection of stacking",
            "en-us": "Connection of stacking",
            "es": "Conéxion sin apilar",
            "fr": "Connexion der rehausse",
            "it": "Giunzione della sovrapposizione",
            "hu": "Magasítás összekötés",
            "ru": "Соединение надстройки",
            "sk": "Spojenie nadstavenia",
            "pl": "Połączenie nadstawienia"
        },
        {
            "ID": "FRAMECO_A1_AW1",
            "de": "Schnellspanner RU",
            "en-gb": "Quick acting clamp RU",
            "en-us": "Quick acting clamp RU",
            "es": "Grapa unión ráp.RU",
            "fr": "Serrage rapide RU",
            "it": "Morsetto rapido RU",
            "hu": "Gyorskapcsoló RU",
            "ru": "Быстродействующее зажимное приспособление RU",
            "sk": "Rýchloupínač RU",
            "pl": "Zamek RU"
        },
        {
            "ID": "FRAMECO_A1_AW2",
            "de": "Uni-Spanner",
            "en-gb": "Multi function clamp",
            "en-us": "Multi function clamp",
            "es": "Grapa unión univ.",
            "fr": "Tendeur rapide universel",
            "it": "Morsetto universale",
            "hu": "Uni. kapcsoló",
            "ru": "Универсальный зажим",
            "sk": "Univ. upínač",
            "pl": "Zamek uniwersalny"
        },
        {
            "ID": "FRAMECO_A1_AW3",
            "de": "nicht aufgestockt bevorzugt",
            "en-gb": "not stacked preferred",
            "en-us": "not stacked preferred",
            "es": "No apilado",
            "fr": "Ne pas rehaussé",
            "it": "Preferibilmente senza sovrapposizione",
            "hu": "Magasítás nélküli preferált",
            "ru": "предпочтительно без надстройки",
            "sk": "nadstavenie nie je uprednostnené",
            "pl": "bez nadstawienia, preferowany"
        },
        {
            "ID": "FRAMECO_A2",
            "de": "Elementtyp",
            "en-gb": "Panel type",
            "en-us": "Panel type",
            "es": "Tipo de elemento",
            "fr": "Type d'élément",
            "it": "Tipo elemento",
            "hu": "Elemtípus",
            "ru": "Тип элемента",
            "sk": "Typ panelu",
            "pl": "Typ płyty"
        },
        {
            "ID": "FRAMECO_A2_AW1",
            "de": "Rahmenelement",
            "en-gb": "Framed panel",
            "en-us": "Framed panel",
            "es": "Elemento marco",
            "fr": "Px de cadre",
            "it": "Elemento a telaio",
            "hu": "Elem",
            "ru": "Рамный элемент",
            "sk": "Rámové panel",
            "pl": "Płyta ramowa"
        },
        {
            "ID": "FRAMECO_A2_AW2",
            "de": "Universalelement",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Univ. elem.",
            "fr": "Tendeur rapide universel",
            "it": "Elem. universale",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Univ. panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "FRAMECO_A2_AW3",
            "de": "Rahmenelement 50 breit",
            "en-gb": "Framed panel 50 wide",
            "en-us": "Framed panel 50 wide",
            "es": "Elemento marco 50 ancho",
            "fr": "Px de cadre 50 de large",
            "it": "Elemento a telaio 50 largo",
            "hu": "Elem 50 szélesség",
            "ru": "Рамный элемент ширина 50",
            "sk": "Rámové panel 50 šírky",
            "pl": "Płyta ramowa szerokość 50"
        },
        {
            "ID": "FRAMECO_A3",
            "de": "Stirnabschalung",
            "en-gb": "Stop-end formwork",
            "en-us": "Bulkhead",
            "es": "Tape",
            "fr": "Coffrage d'about",
            "it": "Chiusura di testa",
            "hu": "Véglezárás",
            "ru": "Торцевые участки опалубки",
            "sk": "Debnenie čela",
            "pl": "Szalunek czołowy"
        },
        {
            "ID": "FRAMECO_A3_AW1",
            "de": "Stirnanker",
            "en-gb": "Stop-end tie",
            "en-us": "Stop-end tie",
            "es": "Anclaje de tape",
            "fr": "Ancrage d'about",
            "it": "Morsetto per testata",
            "hu": "Homlokanker",
            "ru": "Торцевой анкер",
            "sk": "Čel.kotva",
            "pl": "Ściąg czołowy"
        },
        {
            "ID": "FRAMECO_A3_AW2",
            "de": "Klemmschiene Universalverbinder",
            "en-gb": "Universal waling Universal fixing bolt",
            "en-us": "Universal waling universal fixing bolt",
            "es": "Riel de fijación conector univ.",
            "fr": "Rail de blocage boulon d'assemblage universel",
            "it": "Rotaia di fissaggio Tirante universale",
            "hu": "Kötosín Univerzális kötocsavar",
            "ru": "Зажимная шина универсальный соединитель",
            "sk": "Upínacie koľajnice univerzálna spojka",
            "pl": "Uniwersalny rygiel ścienny Łącznik uniwersalny"
        },
        {
            "ID": "FRAMECO_A3_AW3",
            "de": "Universalelement mit Rahmenelement",
            "en-gb": "Universal panel with framed panel",
            "en-us": "Universal panel with framed panel",
            "es": "Elem. univ. con elemento marco",
            "fr": "Tendeur rapide universel avec panneau",
            "it": "Elem. universale con elemento a telaio",
            "hu": "Uni elem keretes elemmel",
            "ru": "Универсальный элемент с рамным элементом",
            "sk": "Univ. panel s rámové panel",
            "pl": "Płyta uniwersalna z płytą ramową"
        },
        {
            "ID": "FRAMECO_A4",
            "de": "Anschlüsse",
            "en-gb": "Connections",
            "en-us": "Connections",
            "es": "Conexónes",
            "fr": "Connexions",
            "it": "Collegamenta",
            "hu": "Csatlakozó",
            "ru": "Стыки",
            "sk": "Napojenia",
            "pl": "Połączenia"
        },
        {
            "ID": "FRAMECO_A4_AW1",
            "de": "Rahmenelement",
            "en-gb": "Framed panel",
            "en-us": "Framed panel",
            "es": "Elemento marco",
            "fr": "Px de cadre",
            "it": "Elemento a telaio",
            "hu": "Elem",
            "ru": "Рамный элемент",
            "sk": "Rámové panel",
            "pl": "Płyta ramowa"
        },
        {
            "ID": "FRAMECO_A4_AW2",
            "de": "Universalelement",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Tendeur rapide universel",
            "it": "Elem. universale",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Univ. panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "FRAMECO_A4_AW3",
            "de": "Kantholz",
            "en-gb": "Squared timber",
            "en-us": "Squared timber",
            "es": "Listón",
            "fr": "Bois équarri",
            "it": "Legno squadrato",
            "hu": "Fur. fa",
            "ru": "Брусок",
            "sk": "Hranol dod.",
            "pl": "Kantówka"
        },
        {
            "ID": "FRAMECO_A5",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "FRAMECO_A5_AW1",
            "de": "Innenecke und Außenecke",
            "en-gb": "Inside corner and outside corner",
            "en-us": "Inside corner and outside corner",
            "es": "Esqu. int. y esqu. ext.",
            "fr": "Angle int.+ angle ext",
            "it": "Angolo interno e angolo esterno",
            "hu": "Belso sarok és külso sarok",
            "ru": "Внутренний угол и внешний угол",
            "sk": "Vnútorný roh a vonkajší roh",
            "pl": "Narożnik wewnętrzny i narożnik zewnętrzny"
        },
        {
            "ID": "FRAMECO_A5_AW2",
            "de": "Innenecke und Universalelement",
            "en-gb": "Inside corner and universal panel",
            "en-us": "Inside corner and universal panel",
            "es": "Esqu. int. y elem. univ.",
            "fr": "Angle int. et  panneau universel",
            "it": "Angolo interno ed elem. universale",
            "hu": "Belso sarok és Uni elem",
            "ru": "Внутренний угол и универсальный элемент",
            "sk": "Vnútorný roh a univerzálny panel",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna"
        },
        {
            "ID": "FRAMECO_A5_AW3",
            "de": "S-Innenecke und S-Außenecke",
            "en-gb": "Hinged inside and hinged outside corner",
            "en-us": "Hinged inside and hinged outside corner",
            "es": "Esqu. bisagra int. y esqu. bisagra ext.",
            "fr": "Angle charnière int et angle charnière ext",
            "it": "Angolo interno S e angolo esterno S",
            "hu": "Csukl.bel.sarok és Csukl.kül.sarok",
            "ru": "Шарнирный внутренний угол и шарнирный внешний угол",
            "sk": "Kĺb.vnút.roh a kĺb.vonk.roh",
            "pl": "Zawiasy wewnątrz + narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "FRAMECO_A5_AW4",
            "de": "S-Innenecke und S-Innenecke",
            "en-gb": "Hinged inside and hinged inside corner",
            "en-us": "Hinged inside and hinged inside corner",
            "es": "Esqu. bisagra int. y esqu. bisagra int.",
            "fr": "Angle charnière int et angle charnière int",
            "it": "Angolo interno S e angolo interno S",
            "hu": "Csukl.bel.sarok és Csukl.bel.sarok",
            "ru": "Шарнирный внутренний угол и шарнирный внутренний угол",
            "sk": "Kĺb.vnút.roh a kĺb.vnút.roh",
            "pl": "Zawiasy wewnątrz + narożnik wewnętrzny zawiasowy"
        },
        {
            "ID": "FRAMIECO_A1",
            "de": "Grundelementhöhe",
            "en-gb": "Basic panel height",
            "en-us": "Basic panel height",
            "es": "Altura del elemento base",
            "fr": "Hauteur de base",
            "it": "Altezza dell’ elemento base",
            "hu": "Alapelem magasság",
            "ru": "Высота основного элемента",
            "sk": "Výška základného panelu",
            "pl": "Wysokość płyty podstawowej"
        },
        {
            "ID": "FRAMIECO_A1_AW1",
            "de": "1,20",
            "en-gb": "1.20 m",
            "en-us": "1,20 m",
            "es": "1,20 m",
            "fr": "1,20 m",
            "it": "1,20 m",
            "hu": "1,20 m",
            "ru": "1,20 м",
            "sk": "1,20 m",
            "pl": "1,20m"
        },
        {
            "ID": "FRAMIECO_A1_AW2",
            "de": "2,70",
            "en-gb": "2.70 m",
            "en-us": "2.70 m",
            "es": "2,70 m",
            "fr": "2,70 m",
            "it": "2,70 m",
            "hu": "2,70 m",
            "ru": "2,70 м",
            "sk": "2,70 m",
            "pl": "2,70 m"
        },
        {
            "ID": "FRAMIECO_A1_AW3",
            "de": "3,00",
            "en-gb": "3.00 m",
            "en-us": "3.00 m",
            "es": "3,00 m",
            "fr": "3,00 m",
            "it": "3,00 m",
            "hu": "3,00 m",
            "ru": "3,00 м",
            "sk": "3,00 m",
            "pl": "3,00 m"
        },
        {
            "ID": "FRAMIECO_A10",
            "de": "Umsetzeinheiten bevorzugt Ja/Nein",
            "en-gb": "Shifting units preferred Yes/No",
            "en-us": "Shifting units preferred Yes/No",
            "es": "Unidades de desplazamiento preferido Sí/No",
            "fr": "Unités de translation préféré oui/non",
            "it": "Unità movimentazione preferito si/non",
            "hu": "Áthelyez·egységek preferált Igen/Nem",
            "ru": "Перемещаемые блоки предпочтительно Да/Нет",
            "sk": "Transportné jednotky uprednostnené áno/nie",
            "pl": "Zestawy do przestawiania preferowane Tak/Nie"
        },
        {
            "ID": "FRAMIECO_A10_AW1",
            "de": "Bevorzugt Ja",
            "en-gb": "Preferred Yes",
            "en-us": "Preferred Yes",
            "es": "Preferido Sí",
            "fr": "Préféré oui",
            "it": "Preferito si",
            "hu": "preferált Igen",
            "ru": "Предпочтительно Да",
            "sk": "Uprdnostnené áno",
            "pl": "Preferowany Tak"
        },
        {
            "ID": "FRAMIECO_A10_AW2",
            "de": "Bevorzugt Nein",
            "en-gb": "Preferred No",
            "en-us": "Preferred No",
            "es": "Preferido No",
            "fr": "Préféré non",
            "it": "Preferito non",
            "hu": "preferált nem",
            "ru": "Предпочтительно Нет",
            "sk": "Uprdnostnené nie",
            "pl": "Preferowany Nie"
        },
        {
            "ID": "FRAMIECO_A11",
            "de": "Bauwerksstütze",
            "en-gb": "Column",
            "en-us": "Column",
            "es": "Pilar",
            "fr": "Poteau",
            "it": "Pilastro",
            "hu": "Pillér",
            "ru": "Колонна",
            "sk": "Stĺp",
            "pl": "Słup"
        },
        {
            "ID": "FRAMIECO_A11_AW1",
            "de": "Universalelement",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Px. univ.",
            "it": "Elementi universali",
            "hu": "Uni elem",
            "ru": "Универсальные элементы",
            "sk": "Uni. panely",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "FRAMIECO_A11_AW2",
            "de": "Universalelement und Element",
            "en-gb": "Universal panel and panel",
            "en-us": "Universal panel and panel",
            "es": "Elem. univ. y elementos",
            "fr": "Px. univ. et px.",
            "it": "Elementi universali e elementi",
            "hu": "Uni elem és elem",
            "ru": "Универсальные элементы и элементы",
            "sk": "Uni. panely a panely",
            "pl": "Płyta uniwersalna i płyta standardowa"
        },
        {
            "ID": "FRAMIECO_A11_AW3",
            "de": "Elemente mit Außenecke",
            "en-gb": "Panels with outside corner",
            "en-us": "Panels with outside corner",
            "es": "Elementos con esqu. ext.",
            "fr": "Px avec angle ext.",
            "it": "Elementi con angolo esterno",
            "hu": "Elem csukl.kül.sarok",
            "ru": "элементы с внешний угол",
            "sk": "Panely s vonkaj. roh",
            "pl": "Płyta z narożnikiem zewnętrznym"
        },
        {
            "ID": "FRAMIECO_A12",
            "de": "Elementverbindung",
            "en-gb": "Panel connection",
            "en-us": "Panel connection",
            "es": "Unión para elemento",
            "fr": "Assemblage px",
            "it": "Giunzioni di elementi",
            "hu": "Elemszélesség",
            "ru": "Соединение элементов",
            "sk": "Panelová spojka",
            "pl": "Łączenie płyt"
        },
        {
            "ID": "FRAMIECO_A12_AW1",
            "de": "Frami-Spanner",
            "en-gb": "Frami clamp",
            "en-us": "Frami clamp",
            "es": "Grapa Frami",
            "fr": "Serrage rapide Frami",
            "it": "Morsetto Frami",
            "hu": "Frami gyorskapcsoló",
            "ru": "Frami зажимное приспособление",
            "sk": "Frami-upínač",
            "pl": "Zamek Frami"
        },
        {
            "ID": "FRAMIECO_A12_AW2",
            "de": "Frami-Stecker",
            "en-gb": "Frami clip",
            "en-us": "Frami clip",
            "es": "Perno de bloqueo Frami",
            "fr": "Goujon de blocage Frami",
            "it": "Bullone di bloccaggio Frami",
            "hu": "Frami szorítókulcs",
            "ru": "Frami закрепляющий штифт",
            "sk": "Frami-zástrčný čap",
            "pl": "Wtyczka Frami"
        },
        {
            "ID": "FRAMIECO_A13",
            "de": "Längsanschluss",
            "en-gb": "In-line connections",
            "en-us": "In-line connections",
            "es": "Unión longitudinal",
            "fr": "Connexion en longueur",
            "it": "Collegamento longitudinale",
            "hu": "Hosszirányú csatlakozás",
            "ru": "Продольный стык",
            "sk": "Pozdĺžne pripojenie",
            "pl": "Połączenia w linii"
        },
        {
            "ID": "FRAMIECO_A13_AW1",
            "de": "Normaler Anschluss",
            "en-gb": "Standard connection",
            "en-us": "Standard connection",
            "es": "Unión normal",
            "fr": "Raccord normal",
            "it": "Raccordo normale",
            "hu": "Normál csatlakozás",
            "ru": "Обычный стык",
            "sk": "Normálne pripojenie",
            "pl": "Połączenie standardowe"
        },
        {
            "ID": "FRAMIECO_A13_AW2",
            "de": "Elemente vom Vorgängertakt verwenden",
            "en-gb": "Use panels of previous cycle",
            "en-us": "Use panels of previous pour",
            "es": "Utilizar los elementos del ciclo anterior",
            "fr": "Utiliser les éléments du coffrage précédent",
            "it": "Utilizza elementi del ciclo precedente",
            "hu": "Az előző ciklus elemeinek alkalmazása",
            "ru": "Использовать элементы предыдущего такта",
            "sk": "Použiť prvky z predchádzajúceho taktu",
            "pl": "Użyj płyt z poprzedniego taktu"
        },
        {
            "ID": "FRAMIECO_A2",
            "de": "Stehende oder liegende Elemente",
            "en-gb": "Upright or horiz. panels",
            "en-us": "Upright or horiz. panels",
            "es": "Elementos verticales o horizontales",
            "fr": "Px debouts ou couchés",
            "it": "Elementi vert. o orizz.",
            "hu": "Elemek állítva vagy fekvo",
            "ru": "Элементы, установленные горизонтально или вертикально",
            "sk": "Panely nastojato alebo naležato",
            "pl": "Płyty pionowe lub poziome"
        },
        {
            "ID": "FRAMIECO_A2_AW1",
            "de": "stehend",
            "en-gb": "upright",
            "en-us": "upright",
            "es": "vertical",
            "fr": "debout",
            "it": "verticale",
            "hu": "állítva",
            "ru": "вертикально",
            "sk": "nastojato",
            "pl": "pionowy"
        },
        {
            "ID": "FRAMIECO_A2_AW2",
            "de": "liegend",
            "en-gb": "horizontal",
            "en-us": "horizontal",
            "es": "horizontal",
            "fr": "couché",
            "it": "orizzontale",
            "hu": "fekvo",
            "ru": "горизонтально",
            "sk": "naležato",
            "pl": "poziomy"
        },
        {
            "ID": "FRAMIECO_A3",
            "de": "Aufstockung",
            "en-gb": "Stacking",
            "en-us": "Stacking",
            "es": "Apilado",
            "fr": "Rehaussé",
            "it": "Sovrapposizione",
            "hu": "Magasítás",
            "ru": "Надстройка",
            "sk": "Nadstavenia",
            "pl": "Nadstawienie"
        },
        {
            "ID": "FRAMIECO_A3_AW1",
            "de": "Richtspanner",
            "en-gb": "Aligning clamp",
            "en-us": "Aligning clamp",
            "es": "Grapa de unión rígida",
            "fr": "Oikaisuliitin",
            "it": "Morsetto di regolazione",
            "hu": "Beállító kapcsoló",
            "ru": "Юстировочное зажимное приспособление",
            "sk": "smerový upínač",
            "pl": "Zamek prostujący"
        },
        {
            "ID": "FRAMIECO_A3_AW2",
            "de": "Klemmschiene",
            "en-gb": "Universal waling",
            "en-us": "Universal waling",
            "es": "Riel de fijación",
            "fr": "Rail de blocage",
            "it": "Rotaia di fissaggio",
            "hu": "Kötősín",
            "ru": "Зажимная шина",
            "sk": "Upínacia koľajnica",
            "pl": "Szyna dociskowa"
        },
        {
            "ID": "FRAMIECO_A3_AW3",
            "de": "ohne Aufstockung",
            "en-gb": "w/o stacking",
            "en-us": "w/o stacking",
            "es": "Sin apilado",
            "fr": "sans rehausse",
            "it": "senza sopralzo",
            "hu": "Magasítás nélkül",
            "ru": "Без надстройки",
            "sk": "bez nadstavenia",
            "pl": "bez nadstawienia"
        },
        {
            "ID": "FRAMIECO_A3_AW4",
            "de": "Aufstockung ohne Aussteifung",
            "en-gb": "stacking w/o bracing",
            "en-us": "stacking w/o bracing",
            "es": "Apilado sin rigidización",
            "fr": "Rehausse sans contreventement",
            "it": "sopralzo senza controventatura",
            "hu": "Magasítás nélkül merevítés",
            "ru": "Надстройка без повышения жесткости",
            "sk": "Nadstavenia a vystuženie",
            "pl": "nadstawienie bez stężeń"
        },
        {
            "ID": "FRAMIECO_A4",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "FRAMIECO_A4_AW1",
            "de": "Fundamentspanner und Ankerhaltewinkel",
            "en-gb": "Foundation clamp and tie-holder bracket",
            "en-us": "Foundation clamp and anchoring bracket",
            "es": "Grapa base y ang. de suj. de ancl.",
            "fr": "Serrage pour fondation et équerre d'ancrage",
            "it": "Morsetto per fondazioni e squadretta d'ancoraggio",
            "hu": "Ankerszalag feszíto és Ankertartó szögvas",
            "ru": "Фундаментный зажим и упорный уголок для анкера",
            "sk": "Základový upínač a držiak kot. tyce",
            "pl": "Napinacz i uchwyt do ściągów"
        },
        {
            "ID": "FRAMIECO_A4_AW2",
            "de": "Fundamentspanner und Flachanker",
            "en-gb": "Foundation clamp and flat tie-rod",
            "en-us": "Foundation clamp and top yoke",
            "es": "Grapa base y anclaje plano",
            "fr": "Serrage pour fondation et entretoise",
            "it": "Morsetto per fondazioni e ancorante piatto",
            "hu": "Ankerszalag feszíto és Laposanker",
            "ru": "Фундаментный зажим и плоский анкер",
            "sk": "Základový upínač a plochá kotva",
            "pl": "Napinacz i ściąg płaski"
        },
        {
            "ID": "FRAMIECO_A4_AW4",
            "de": "Ankerstab",
            "en-gb": "Tie rod",
            "en-us": "Tie rod",
            "es": "Barra de ancl.",
            "fr": "Tige d'ancrage",
            "it": "Barra ancorante",
            "hu": "Ankerrúd",
            "ru": "Анкерный стержень",
            "sk": "Kotevní tyč",
            "pl": "Ściąg"
        },
        {
            "ID": "FRAMIECO_A4_AW5",
            "de": "Bodenhalter und Flachanker",
            "en-gb": "Floor fixing plate and flat tie-rod",
            "en-us": "Bracing clip and top yoke",
            "es": "Placa de fijación a suelo y Anclaje plano",
            "fr": "Support d'ancrage et entretoise",
            "it": "Piastra di fissaggio e ancorante piatto",
            "hu": "Aljzatrögzítő és Laposanker",
            "ru": "Держатель для пола и плоский анкер",
            "sk": "Držiak spodku debnenia a plochá kotva",
            "pl": "Płytka mocująca do podłoża i ściąg płaski"
        },
        {
            "ID": "FRAMIECO_A5",
            "de": "Elementtyp",
            "en-gb": "Panel type",
            "en-us": "Panel type",
            "es": "Tipo de elemento",
            "fr": "Type d'élément",
            "it": "Tipo elemento",
            "hu": "Elemtípus",
            "ru": "Тип элемента",
            "sk": "Typ panelu",
            "pl": "Typ płyty"
        },
        {
            "ID": "FRAMIECO_A5_AW1",
            "de": "Normalelemente",
            "en-gb": "Standard panels",
            "en-us": "Standard panels",
            "es": "Elementos normalos",
            "fr": "Éléments étalons",
            "it": "Elementi normali",
            "hu": "Normál táblák",
            "ru": "Унифицированные элементы",
            "sk": "Štandardné panely",
            "pl": "Płyty standardowe"
        },
        {
            "ID": "FRAMIECO_A5_AW2",
            "de": "Uni-Elemente",
            "en-gb": "Universal panels",
            "en-us": "Universal panels",
            "es": "Elem. univ.",
            "fr": "Px. univ.",
            "it": "Elementi universali",
            "hu": "Uni elem",
            "ru": "Универсальные элементы",
            "sk": "Uni. panely",
            "pl": "Płyty uniwersalne"
        },
        {
            "ID": "FRAMIECO_A6",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "FRAMIECO_A6_AW1",
            "de": "Innenecke und Uni-Element",
            "en-gb": "Inside corner and universal panel",
            "en-us": "Inside corner and universal panel",
            "es": "Esqu. int. y elem. univ",
            "fr": "Angle intérieur et pann. univ.",
            "it": "Angolo interno ed elemento uni",
            "hu": "Belso sarok és uni elem",
            "ru": "Внутренний угол с унив.элементом",
            "sk": "Vnútorný roh + univerzálny panel",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna"
        },
        {
            "ID": "FRAMIECO_A6_AW2",
            "de": "Innenecke und Außenecke",
            "en-gb": "Inside corner and outside corner",
            "en-us": "Inside corner and outside corner",
            "es": "Esqu. int. y esqu. ext.",
            "fr": "Angle int. et angle ext.",
            "it": "Angolo interno e angolo esterno",
            "hu": "Belso sarok és külso sarok",
            "ru": "Внутренний угол и внешний угол",
            "sk": "Vnútorný roh a vonkajší roh",
            "pl": "Narożnik wewnętrzny i narożnik zewnętrzny"
        },
        {
            "ID": "FRAMIECO_A6_AW3",
            "de": "S-Innenecke und Uni-Element",
            "en-gb": "Hinged inside corner and univ. panel",
            "en-us": "Hinged inside corner and univ. panel",
            "es": "Esqu. bisagra int. y elem. univ.",
            "fr": "S-Angle intérieur et pann. univ.",
            "it": "Angolo interno S ed elemento uni",
            "hu": "Csukl.bel.sarok belül és kívül",
            "ru": "Шарнирный внутренний угол и универсальный элемент",
            "sk": "Kĺb.vnút.roh + univerzálny panel",
            "pl": "Narożnik wewnętrzny zawiasowy i płyta uniwersalna"
        },
        {
            "ID": "FRAMIECO_A6_AW4",
            "de": "S-Innen und S-Außenecke",
            "en-gb": "Hinged inside and hinged outside corner",
            "en-us": "Hinged inside corner and hinged outs. corner",
            "es": "Esqu. bisagra int. y elem. univ.",
            "fr": "S-Angle int. et angle ext.",
            "it": "Angolo int. S ed est. S",
            "hu": "Belso sarok és uni elem",
            "ru": "шарнирный внутренний угол и шарнирный внешний угол",
            "sk": "Kĺb.vnút.roh + kĺb.vonk.roh",
            "pl": "Zawiasy wewnątrz i narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "FRAMIECO_A6_AW5",
            "de": "S-Innenecke Innen und Außen",
            "en-gb": "Hinged inside corner inside and outside",
            "en-us": "Hinged inside corner inside and outside",
            "es": "Esqu. bisagra int. y elem. univ.",
            "fr": "S-Angle charnière int. int. et ext.",
            "it": "Angolo interno S int. ed est.",
            "hu": "Csukl.bel.sarok és uni elem",
            "ru": "Шарнирный внутренний угол изнутри и снаружи",
            "sk": "Kĺb.vnút.roh vnút.+vonk.",
            "pl": "Narożnik wewnętrzny zawiasowy wewnątrz i na zewnątrz"
        },
        {
            "ID": "FRAMIECO_A6_AW6",
            "de": "S-Innenecke verz. und Uni-Element",
            "en-gb": "Hinged inside corner galv. and universal panel",
            "en-us": "Hinged inside corner galv. and universal panel",
            "es": "Squ. bisagra int. galv. y elem. univ.",
            "fr": "S-Angle intérieur galv. et pann. univ.",
            "it": "Angolo interno S galv. ed elemento uni",
            "hu": "Csukl.bel.sarok horg. és uni elem",
            "ru": "Шарнирный внутренний угол оцинкованный и универсальный элемент",
            "sk": "Kĺb.vnút.roh pozinkovaná + univerzálny panel",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i płyta uniwersalna"
        },
        {
            "ID": "FRAMIECO_A6_AW7",
            "de": "S-Innen verz. und S-Außenecke verz.",
            "en-gb": "Hinged inside galv.and hinged outside corner galv.",
            "en-us": "Hinged inside galv.and hinged outside corner galv.",
            "es": "Esqu. bisagra int. galv. y esqu. bisagra ext. galv.",
            "fr": "S-Angle int. galv. et angle ext. galv.",
            "it": "Angolo int. S galv. ed est. S galv.",
            "hu": "Csukl.bel. horg. és csukl.kül.sarok horg.",
            "ru": "шарнирный внутренний угол оцинкованный и шарнирный внешний угол оцинкованный",
            "sk": "Kĺb.vnút.roh pozinkovaná+ kĺb.vonk.roh pozinkovaná",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i narożnik zewnętrzny zawiasowy ocynk."
        },
        {
            "ID": "FRAMIECO_A6_AW8",
            "de": "S-Innenecke verz. Innen und Außen",
            "en-gb": "Hinged inside corner galv. inside and outside",
            "en-us": "Hinged inside corner galv. inside and outside",
            "es": "Esqu. bisagra int. galv. int. y ext.",
            "fr": "S-Angle charnière int. galv. int. et ext.",
            "it": "Angolo interno S galv. int. ed est.",
            "hu": "Csukl.bel.sarok horg. belül és kívül",
            "ru": "Шарнирный внутренний угол оцинкованный изнутри и снаружи",
            "sk": "ĺb.vnút.roh pozinkovaná vnút.+vonk.",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. wewnątrz i na zewnątrz"
        },
        {
            "ID": "FRAMIECO_A6_AW9",
            "de": "Ausschalecke (einseitig)",
            "en-gb": "Stripping corner (only on one side)",
            "en-us": "Bias cut corner (only on one side)",
            "es": "Esquina de desencofrado (de un solo lado)",
            "fr": "Angle de décoffrage (une face)",
            "it": "Angolo di disarmo (solo su un lato)",
            "hu": "Kizsaluzó sarok (egyoldali)",
            "ru": "Распалубочный уголок (с одной стороны)",
            "sk": "Oddebňovací roh (jednostranne)",
            "pl": "Narożnik rozszalowujący (tylko z jednej strony)"
        },
        {
            "ID": "FRAMIECO_A7",
            "de": "Wandende",
            "en-gb": "Wall end",
            "en-us": "Wall end",
            "es": "Fin del muro",
            "fr": "Fin de mur",
            "it": "Fine del muro",
            "hu": "Falvég",
            "ru": "Конец стены",
            "sk": "Ukončenie steny",
            "pl": "Koniec ściany"
        },
        {
            "ID": "FRAMIECO_A7_AW1",
            "de": "Klemmschienen",
            "en-gb": "Universal walings",
            "en-us": "Universal walings",
            "es": "Riel de fijación",
            "fr": "Rails de blocage",
            "it": "Rotaia di fissaggio",
            "hu": "Kötősín",
            "ru": "Зажимные шины",
            "sk": "Upínacia koľajnica",
            "pl": "Uniwersalne rygle ścienne"
        },
        {
            "ID": "FRAMIECO_A7_AW2",
            "de": "Universalelement",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Px. univ.",
            "it": "Elementi universali",
            "hu": "Uni elem",
            "ru": "Универсальные элементы",
            "sk": "Uni. panely",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "FRAMIECO_A7_AW3",
            "de": "Stirnabschalzwinge",
            "en-gb": "Stop-end waler tie",
            "en-us": "Stop-end waler tie",
            "es": "Sargento para tape",
            "fr": "Rail d'about",
            "it": "Corrente di testata",
            "hu": "Véglezáró sín",
            "ru": "Торцевая накладка",
            "sk": "Zvierka debnenia čiel",
            "pl": "Zacisk deskowania czołowego"
        },
        {
            "ID": "FRAMIECO_A8",
            "de": "Anschlüsse, Ausgleiche",
            "en-gb": "Connections, closures",
            "en-us": "Connections, fillers",
            "es": "Conexiónes, compensaciónes",
            "fr": "Connexions, compensations",
            "it": "Collegamenta, Compensazioni",
            "hu": "Csatlakozó, Kiegyenlítése",
            "ru": "Стыки, компенсаторы",
            "sk": "Napojenia, ručné vyrovnania",
            "pl": "Połączenia, wypełnienia"
        },
        {
            "ID": "FRAMIECO_A8_AW1",
            "de": "Passholz Doka, ohne Klemmschiene",
            "en-gb": "Fitting timber Doka, w/o universal waling",
            "en-us": "Fitting timber Doka, w/o universal waling",
            "es": "Tablón de ajuste Doka, sin riel de sujeción",
            "fr": "Fourrure en bois Doka, sans rail de blocage",
            "it": "Tavola di compensazione Doka, senza rotaia di fissaggio",
            "hu": "Illesztő fabetét Doka, kötősín nélkül",
            "ru": "Пригнанный брус Doka, без зажимной шины",
            "sk": "Vyrovnávací hranol Doka, bez upínacej koľajnice",
            "pl": "Wstawka komp. Doka bez uniwersalnego rygla ściennego"
        },
        {
            "ID": "FRAMIECO_A8_AW2",
            "de": "Kantholz bauseits, ohne Klemmschiene",
            "en-gb": "Squared timber by site, w/o universal waling",
            "en-us": "Squared timber by site, w/o universal waling",
            "es": "Tablón de ajuste Doka, con riel de sujeción",
            "fr": "Bois équarri chantier, sans rail de blocage",
            "it": "Legno squadrato a carico cant., senza rotaia di fissaggio",
            "hu": "Furfa helyi anyag, kötősín nélkül",
            "ru": "Деревянный брус (предоставляется заказчиком), без зажимной шины",
            "sk": "Hranol dod. stavby, bez upínacej koľajnice",
            "pl": "Kantówka po str. budowy bez uniwersalnego rygla ściennego"
        },
        {
            "ID": "FRAMIECO_A8_AW3",
            "de": "Universalelement",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Px. univ.",
            "it": "Elementi universali",
            "hu": "Uni elem",
            "ru": "Универсальные элементы",
            "sk": "Uni. panely",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "FRAMIECO_A8_AW4",
            "de": "Ankerelement",
            "en-gb": "Anchoring panel",
            "en-us": "Anchoring panel",
            "es": "Elementos anclaje",
            "fr": "Ancrage de rive",
            "it": "Elemento ancorante",
            "hu": "Ankerelem",
            "ru": "Анкерный элемент",
            "sk": "Kotv. panely",
            "pl": "Element kotwowy"
        },
        {
            "ID": "FRAMIECO_A8_AW5",
            "de": "Schalhautwinkel und Schalungsplatte 27 mm",
            "en-gb": "Plywood support and formwork sheet 27 mm",
            "en-us": "Plywood support and formwork sheet 27mm",
            "es": "Áng. de superficie de enc. y tabl. de encofr. 27mm",
            "fr": "Support de compensation et panneau 27mm",
            "it": "Supporto di compensazione e pannello 27mm",
            "hu": "Zsaluhéjtartó szögvas és zsaluhéj 27mm",
            "ru": "Опалубочный уголок и опалубочная плита 27мм",
            "sk": "Uholník k debniacej škrupine + debniaca doska 27mm",
            "pl": "Narożnik przekładniowy i sklejka 27mm"
        },
        {
            "ID": "FRAMIECO_A8_AW6",
            "de": "Schalhautwinkel und Schalungsplatte 21 mm",
            "en-gb": "Plywood support and formwork sheet 21 mm",
            "en-us": "Plywood support and formwork sheet 21mm",
            "es": "Áng. de superficie de enc. y tabl. de encofr. 21mm",
            "fr": "Support de compensation et panneau 21mm",
            "it": "Supporto di compensazione e pannello 21mm",
            "hu": "Zsaluhéjtartó szögvas és zsaluhéj 21mm",
            "ru": "Опалубочный уголок и опалубочная плита 21мм",
            "sk": "Uholník k debniacej škrupine + debniaca doska 21mm",
            "pl": "Narożnik przekładniowy i sklejka 21mm"
        },
        {
            "ID": "FRAMIECO_A8_AW7",
            "de": "Passholz Doka, mit Klemmschiene",
            "en-gb": "Fitting timber Doka, with universal waling",
            "en-us": "Fitting timber Doka, with universal waling",
            "es": "Tablón de ajuste Doka, con riel de fijación",
            "fr": "Fourrure en bois Doka, av. rail de blocage",
            "it": "Tavola di compensazione Doka, con rotaia di fissaggio",
            "hu": "Illeszto fabetét Doka, kötősínnel",
            "ru": "Пригнанный брус Doka, с зажимной шиной",
            "sk": "Vyrovnávací hranol Doka, s upínacou koľajnicou",
            "pl": "Wstawka komp. Doka bez uniwersalnego rygla ściennego"
        },
        {
            "ID": "FRAMIECO_A8_AW8",
            "de": "Kantholz bauseits, mit Klemmschiene",
            "en-gb": "Squared timber by site, with universal waling",
            "en-us": "Squared timber by site, with universal waling",
            "es": "Liston por parte de obre, con riel de fijación",
            "fr": "Bois équarri chantier, av. rail de blocage",
            "it": "Legno squadrato a carico cant., con rotaia di fissaggio",
            "hu": "Furfa helyi anyag, kötősínnel",
            "ru": "Деревянный брус (предоставляется заказчиком), с зажимной шиной",
            "sk": "Hranol dod. stavby, s upínacou koľajnicou",
            "pl": "Kantówka po str. budowy z uniwersalnym ryglem ściennym"
        },
        {
            "ID": "FRAMIECO_A8_AW9",
            "de": "Schalhautwinkel und Schalungsplatte 18 mm",
            "en-gb": "Plywood support and formwork sheet 18 mm",
            "en-us": "Plywood support and formwork sheet 18 mm",
            "es": "Áng. de superficie de enc. y tabl. de encofr. 18mm",
            "fr": "Support de compensation et panneau 18mm",
            "it": "Supporto di compensazione e pannello 18mm",
            "hu": "Zsaluhéjtartó szögvas és zsaluhéj 18mm",
            "ru": "Опалубочный уголок и опалубочная плита 18мм",
            "sk": "Uholník k debniacej škrupine + debniaca doska 18mm",
            "pl": "Narożnik przekładniowy i sklejka 18mm"
        },
        {
            "ID": "FRAMIECO_A9",
            "de": "Ankerstab verzinkt oder unbehandelt",
            "en-gb": "Tie rod galvanised or non-treated",
            "en-us": "Tie rod galvanized or non-treated",
            "es": "Barra de ancl. galv. o no tratada",
            "fr": "Tige d'ancrage galv. ou non traitée",
            "it": "Barra ancorante zincata o non trattata",
            "hu": "Ankerrúd horganyzott és kezeletlen",
            "ru": "Анкерный стержень, оцинкованный или необработанный",
            "sk": "Kotevná tyč pozinkovaná al. neupravovaná",
            "pl": "Ściąg ocynkowany lub bez zwykły"
        },
        {
            "ID": "FRAMIECO_A9_AW1",
            "de": "Ankerstab verzinkt",
            "en-gb": "Tie rod galvanised",
            "en-us": "Tie rod galvanized",
            "es": "Barra de ancl. galv.",
            "fr": "Tige d'ancrage galv.",
            "it": "Barra ancorante zincata",
            "hu": "Ankerrúd horganyzott",
            "ru": "Анкерный стержень оцинкованный",
            "sk": "Kotevná tyč pozinkovaná",
            "pl": "Ściąg ocynkowany"
        },
        {
            "ID": "FRAMIECO_A9_AW2",
            "de": "Ankerstab unbehandelt",
            "en-gb": "Tie rod non-treated",
            "en-us": "Tie rod non-treated",
            "es": "Barra de ancl. no tratada",
            "fr": "Tige d'ancrage non traitée",
            "it": "Barra ancorante non trattata",
            "hu": "Ankerrúd kezeletlen",
            "ru": "Анкерный стержень необработанный",
            "sk": "Kotevná tyč neupravovaná",
            "pl": "Ściąg nieocynkowany"
        },
        {
            "ID": "HINWEISTEXT_FF100TEC",
            "de": "Die automatische Verplanung des Schalsystems FF100 tec steht derzeit aus schalungstechnischen Gründen nicht zur Verfügung.",
            "en-gb": "The automatic planning of the formwork system FF100 tec is currently not available (because of formwork-technological reasons).",
            "en-us": "The automatic planning of the formwork system FF100 tec is currently not available (because of formwork-technological reasons).",
            "es": "La planificación automática del sistema de encofrado FF100 tec no está disponible en estos momentos por cuestiones técnicas del encofrado.",
            "fr": "La planification automatique pour le coffrage du système FF100 tec n'est pas disponible actuellement (pour des raisons de coffrage technologique).",
            "it": "La pianificazione automatica del cassero FF100tec non è attualmente disponibile (per ragioni tecniche).",
            "hu": "The automatic planning for the system formwork FF100 tec is currently not available (because of formwork-technological reasons).",
            "ru": "Автоматическое планирование системы опалубки FF100 настоящее время не доступно по техническим причинам.",
            "sk": "The automatic planning for the system formwork FF100 tec is currently not available (because of formwork-technological reasons).",
            "pl": "Automatyczne planowanie systemu szalunku FF100 tec nie jest obecnie dostępne (ze względu na technologię szalowania)."
        },
        {
            "ID": "HINWEISTEXT_FLEX124",
            "de": "Es dürfen keine Horizontallasten in die Unterstellung eingeleitet werden.Abschalungskräfte sind durch geeignete Maßnahmen abzuleiten.\\nBei Deckenspannweiten über 7,5m müssen - zusätzlich zur üblichen Bemessung - \\ndie Belastungen im Ausschalvorgang berücksichtigt werden.\\nDie Betonlasten für die Deckenstützen müssen daher in der Planung soweit reduziert werden, \\ndass beim Ausschalvorgang keine Überlastung auftritt.\\nKontaktieren Sie bitte den DOKA Techniker.",
            "en-gb": "Horizontal loads must not be led into the lower parts.\\nStripping forces have to be drawn off through suitable measures.\\nAt floor span over 7.5m - additionally to the usual dimension -\\nthe loading must be considered in the striking process.\\nThe concrete loads for the floor props must be reduced in the planning so far,\\nthat no overload occurs at the striking process.\\nPlease contact the DOKA engineer.",
            "en-us": "Horizontal loads must not be led into the lower parts.\\nStripping forces have to be drawn off through suitable measures.\\nAt floor span over 7.5m - additionally to the usual dimension - the loading must be considered in the striking process.\\nThe concrete loads for the floor props must be reduced in the planning so far, that no overload occurs at the striking process.\\nPlease contact the DOKA engineer.",
            "es": "No deben transmitirse cargas horizontales a las partes inferiores\\nLas fuerzas producidas durante el desencofrado deben ser absorbidas mediante medidas apropiadas.\\nEn anchuras de tensión en techos que superen 7,5m - además de la medición habitual -\\nen el proceso de encofrar se tienen que tener en cuenta las cargas.\\nLas cargas del hormigón que soportan los puntales deben reducirse en el proyecto\\npara evitar una sobrecarga en el proceso de encofrar.\\nPor favor, contacte con el técnico de Doka.",
            "fr": "Aucun effort horizontal ne doit être transmis dans l'étaiement.\\nLa reprise des efforts du coffrage d'about doit être prévue par des mesures appropriées.\\nPour des portées de dalle supérieures à 7,5m, en plus des calculs habituels -\\nprendre en compte les efforts qui s'exercent au décoffrage.\\nLes charges dues au béton doivent alors être diminuées en conséquence dans l'étude,\\npour qu'aucune surcharge n'intervienne lors du décoffrage.\\nVeuillez contacter le technicien Doka.",
            "it": "Non possono essere introdotti pesi orizzontali nella costruzione inferiore.\\nLe forze di disarmo devono essere deviate con misura adeguate.\\nPer solai di dimensioni superiori ai 7,5m, oltre al normale dimensionamento,\\nconsiderare anche i carichi in fase di disarmo.\\nDurante la fase di progetto, ridurre quindi i carichi di calcestruzzo sostenuti dai,\\npuntelli in modo tale che non subentri un sovraccarico durante la fase di disarmo.\\nRivolgersi ai tecnici DOKA",
            "hu": "Minden ezzel a programmal készített tervet a felhasználónak kiadás elott ellenoriznie kell!\\nA kizsaluzást a megfelelo módon kell végrehajtani.\\nA 7,5m-t meghaladó födémfesztávoknál a -   szokásos méretezés mellett a kizsaluzás folyamán -\\nfellépo terheket is figyelembe kell venni.\\nAz egyes födémtámaszokra jutó betonterhelést úgy kell csökkenteni,\\nhogy a kizsaluzás során ne lépjen fel túlterhelés.\\nVegye fel a kapcsolatot a Doka szakemberével.",
            "ru": "Передача горизонтальных нагрузок на основание не допускается.\\nСилы распалубливания должны отводиться соответствующими средствами.\\nПри расстояниях между опорами пролетов свыше 7,5 м - в дополнение к стандартным замерам -\\nдолжны учитываться нагрузки в процессе снятия опалубки.\\nПоэтому при планировании нагрузки бетона на стойки для перекрытий должны быть сокращены настолько,\\nчтобы при снятии опалубки не возникло перегрузки.\\nСвяжитесь с инженером компании Doka.",
            "sk": "Do podpery nesmú byt vedené žiadne horizontálne zataženia.\\nOddebnovacie sily sa odvádzajú prostredníctvom stanovených opatrení.\\nPri rozpätiach stropu nad 7,5 m treba - \\ndodatočne k bežn. dimenzovaniu-zohľadniť zaťaženia v procese oddebňovania.\\nZaťaženie betónu pre stropné podpery sa musí preto v projekte redukovať,\\naby v procese oddebňovania nedošlo k pre-  ťaženiu. \\nKontaktujte prosím DOKA technika.",
            "pl": "Obciążenia poziome nie mogą być wprowadzane do elementów położonych niżej.\\nSiły przy rozszalunku należy przykładać przy użyciu odpowiednich środków.\\nPrzy rozpiętości przęsła ponad 7,5 m obciążenia wykraczają poza standardowe i muszą być uwzględnione w procesie rozszalunku.\\nObciążenia od betonu w przypadku podpór muszą być redukowane na etapie projektowania w stopniu\\ntakim aby nie nastąpiło przeciążenie przy rozszalunku.\\nProsimy o kontakt z inżynierem DOKA."
        },
        {
            "ID": "HINWEISTEXT_FLEX15",
            "de": "Es dürfen keine Horizontallasten in die Unterstellung eingeleitet werden.Abschalungskräfte sind durch geeignete Maßnahmen abzuleiten.\\nBei Deckenspannweiten über 7,5 m müssen - zusätzlich zur üblichen Bemessung - \\ndie Belastungen im Ausschalvorgang berücksichtigt werden.\\nDie Betonlasten für die Deckenstützen müssen daher in der Planung soweit reduziert werden, \\ndass beim Ausschalvorgang keine Überlastung auftritt.\\nKontaktieren Sie bitte den DOKA Techniker.",
            "en-gb": "Horizontal loads must not be led into the lower parts.\\nStripping forces have to be drawn off through suitable measures.\\nAt floor span over 7.5m - additionally to the usual dimension -\\nthe loading must be considered in the striking process.\\nThe concrete loads for the floor props must be reduced in the planning so far,\\nthat no overload occurs at the striking process.\\nPlease contact the DOKA engineer.",
            "en-us": "Horizontal loads must not be led into the lower parts.\\nStripping forces have to be drawn off through suitable measures.\\nAt floor span over 7.5m - additionally to the usual dimension -\\nthe loading must be considered in the striking process.\\nThe concrete loads for the floor props must be reduced in the planning so far,\\nthat no overload occurs at the striking process.\\nPlease contact the DOKA engineer.",
            "es": "No deben transmitirse cargas horizontales a las partes inferiores\\nLas fuerzas producidas durante el desencofrado deben ser absorbidas mediante medidas apropiadas.\\nEn anchuras de tensión en techos que superen 7,5m - además de la medición habitual -\\nen el proceso de encofrar se tienen que tener en cuenta las cargas.\\nLas cargas del hormigón que soportan los puntales deben reducirse en el proyecto\\npara evitar una sobrecarga en el proceso de encofrar.\\nPor favor, contacte con el técnico de Doka.",
            "fr": "Aucun effort horizontal ne doit être transmis dans l'étaiement.\\nLa reprise des efforts du coffrage d'about doit être prévue par des mesures appropriées.\\nPour des portées de dalle supérieures à 7,5m, en plus des calculs habituels -\\nprendre en compte les efforts qui s'exercent au décoffrage.\\nLes charges dues au béton doivent alors être diminuées en conséquence dans l'étude,\\npour qu'aucune surcharge n'intervienne lors du décoffrage.\\nVeuillez contacter le technicien Doka.",
            "it": "Non possono essere introdotti pesi orizzontali nella costruzione inferiore.\\nLe forze di disarmo devono essere deviate con misura adeguate.\\nPer solai di dimensioni superiori ai 7,5m, oltre al normale dimensionamento,\\nconsiderare anche i carichi in fase di disarmo.\\nDurante la fase di progetto, ridurre quindi i carichi di calcestruzzo sostenuti dai,\\npuntelli in modo tale che non subentri un sovraccarico durante la fase di disarmo.\\nRivolgersi ai tecnici DOKA.",
            "hu": "A vízszintes terheket nem szabad az alátámasztásba bevezetni.\\nA kizsaluzást a megfelelo módon kell végrehajtani.\\nA 7,5m-t meghaladó födémfesztávoknál a -   szokásos méretezés mellett a kizsaluzás folyamán -\\nfellépo terheket is figyelembe kell venni.\\nAz egyes födémtámaszokra jutó betonterhelést úgy kell csökkenteni,\\nhogy a kizsaluzás során ne lépjen fel túlterhelés.\\nVegye fel a kapcsolatot a Doka szakemberével.",
            "ru": "Передача горизонтальных нагрузок на основание не допускается.\\nСилы распалубливания должны отводиться соответствующими средствами.\\nПри расстояниях между опорами пролетов свыше 7,5 м - в дополнение к стандартным замерам -\\nдолжны учитываться нагрузки в процессе снятия опалубки.\\nПоэтому при планировании нагрузки бетона на стойки для перекрытий должны быть сокращены настолько,\\nчтобы при снятии опалубки не возникло перегрузки.\\nСвяжитесь с инженером компании Doka.",
            "sk": "Do podpery nesmú byt vedené žiadne horizontálne zataženia.\\nOddebnovacie sily sa odvádzajú prostredníctvom stanovených opatrení.\\nPri rozpätiach stropu nad 7,5 m treba - \\nodatočne k bežn. dimenzovaniu-zohľadniť zaťaženia v procese oddebňovania.\\nZaťaženie betónu pre stropné podpery sa musí preto v projekte redukovať,\\naby v procese oddebňovania nedošlo k pre-  ťaženiu. \\nKontaktujte prosím DOKA technika.",
            "pl": "Obciążenia poziome nie mogą być wprowadzane do elementów położonych niżej.\\nSiły przy rozszalunku należy przykładać przy użyciu odpowiednich środków.\\nPrzy rozpiętości przęsła ponad 7,5 m obciążenia wykraczają poza standardowe i muszą być uwzględnione w procesie rozszalunku.\\nObciążenia od betonu w przypadku podpór muszą być redukowane na etapie projektowania w stopniu\\ntakim aby nie nastąpiło przeciążenie przy rozszalunku.\\nProsimy o kontakt z inżynierem DOKA."
        },
        {
            "ID": "HINWEISTEXT_FLEX30OP",
            "de": "Es dürfen keine Horizontallasten in die Unterstellung eingeleitet werden.Abschalungskräfte sind durch geeignete Maßnahmen abzuleiten.\\nBei Deckenspannweiten über 7,5 m müssen - zusätzlich zur üblichen Bemessung - \\ndie Belastungen im Ausschalvorgang berücksichtigt werden.\\nDie Betonlasten für die Deckenstützen müssen daher in der Planung soweit reduziert werden, \\ndass beim Ausschalvorgang keine Überlastung auftritt.\\nKontaktieren Sie bitte den DOKA Techniker.",
            "en-gb": "Horizontal loads must not be led into the lower parts.\\nStripping forces have to be drawn off through suitable measures.\\nAt floor span over 7.5m - additionally to the usual dimension -\\nthe loading must be considered in the striking process.\\nThe concrete loads for the floor props must be reduced in the planning so far,\\nthat no overload occurs at the striking process.\\nPlease contact the DOKA engineer.",
            "en-us": "Horizontal loads must not be led into the lower parts.\\nStripping forces have to be drawn off through suitable measures.\\nAt floor span over 7.5m - additionally to the usual dimension - \\nthe loading must be considered in the striking process.\\nThe concrete loads for the floor props must be reduced in the planning so far,\\nthat no overload occurs at the striking process.\\nPlease contact the DOKA engineer.",
            "es": "No deben transmitirse cargas horizontales a las partes inferiores\\nLas fuerzas producidas durante el desencofrado deben ser absorbidas mediante medidas apropiadas.\\nEn anchuras de tensión en techos que superen 7,5m - además de la medición habitual -\\nen el proceso de encofrar se tienen que tener en cuenta las cargas.\\nLas cargas del hormigón que soportan los puntales deben reducirse en el proyecto\\npara evitar una sobrecarga en el proceso de encofrar.\\nPor favor, contacte con el técnico de Doka.",
            "fr": "Aucun effort horizontal ne doit être transmis dans l'étaiement.\\nLa reprise des efforts du coffrage d'about doit être prévue par des mesures appropriées.\\nPour des portées de dalle supérieures à 7,5m, en plus des calculs habituels -\\nprendre en compte les efforts qui s'exercent au décoffrage.\\nLes charges dues au béton doivent alors être diminuées en conséquence dans l'étude,\\npour qu'aucune surcharge n'intervienne lors du décoffrage.\\nVeuillez contacter le technicien Doka.",
            "it": "Non possono essere introdotti pesi orizzontali nella costruzione inferiore.\\nLe forze di disarmo devono essere deviate con misura adeguate.\\nPer solai di dimensioni superiori ai 7,5m, oltre al normale dimensionamento,\\nconsiderare anche i carichi in fase di disarmo.\\nDurante la fase di progetto, ridurre quindi i carichi di calcestruzzo sostenuti dai,\\npuntelli in modo tale che non subentri un sovraccarico durante la fase di disarmo.\\nRivolgersi ai tecnici DOKA.",
            "hu": "A vízszintes terheket nem szabad az alátámasztásba bevezetni.\\nA kizsaluzást a megfelelo módon kell végrehajtani.\\nA 7,5m-t meghaladó födémfesztávoknál a -   szokásos méretezés mellett a kizsaluzás folyamán -\\nfellépo terheket is figyelembe kell venni.\\nAz egyes födémtámaszokra jutó betonterhelést úgy kell csökkenteni,\\nhogy a kizsaluzás során ne lépjen fel túlterhelés.\\nVegye fel a kapcsolatot a Doka szakemberével",
            "ru": "Передача горизонтальных нагрузок на основание не допускается.\\nСилы распалубливания должны отводиться соответствующими средствами.\\nПри расстояниях между опорами пролетов свыше 7,5 м - в дополнение к стандартным замерам -\\nдолжны учитываться нагрузки в процессе снятия опалубки.\\nПоэтому при планировании нагрузки бетона на стойки для перекрытий должны быть сокращены настолько,\\nчтобы при снятии опалубки не возникло перегрузки.\\nСвяжитесь с инженером компании Doka.",
            "sk": "Do podpery nesmú byt vedené žiadne horizontálne zataženia.\\nOddebnovacie sily sa odvádzajú prostredníctvom stanovených opatrení.\\nPri rozpätiach stropu nad 7,5 m treba - \\nodatočne k bežn. dimenzovaniu-zohľadniť zaťaženia v procese oddebňovania.\\nZaťaženie betónu pre stropné podpery sa musí preto v projekte redukovať,\\naby v procese oddebňovania nedošlo k pre-  ťaženiu. \\nKontaktujte prosím DOKA technika.",
            "pl": "Obciążenia poziome nie mogą być wprowadzane do elementów położonych niżej.\\nSiły przy rozszalunku należy przykładać przy użyciu odpowiednich środków.\\nPrzy rozpiętości przęsła ponad 7,5 m obciążenia wykraczają poza standardowe i muszą być uwzględnione w procesie rozszalunku.\\nObciążenia od betonu w przypadku podpór muszą być redukowane na etapie projektowania w stopniu\\ntakim aby nie nastąpiło przeciążenie przy rozszalunku.\\nProsimy o kontakt z inżynierem DOKA."
        },
        {
            "ID": "HINWEISTEXT_FLEXOPT",
            "de": "Es dürfen keine Horizontallasten in die Unterstellung eingeleitet werden.Abschalungskräfte sind durch geeignete Maßnahmen abzuleiten.\\nBei Deckenspannweiten über 7,5 m müssen - zusätzlich zur üblichen Bemessung - \\ndie Belastungen im Ausschalvorgang berücksichtigt werden.\\nDie Betonlasten für die Deckenstützen müssen daher in der Planung soweit reduziert werden, \\ndass beim Ausschalvorgang keine Überlastung auftritt.\\nKontaktieren Sie bitte den DOKA Techniker.",
            "en-gb": "Horizontal loads must not be led into the lower parts.\\nStripping forces have to be drawn off through suitable measures.\\nAt floor span over 7.5m - additionally to the usual dimension -\\nthe loading must be considered in the striking process.\\nThe concrete loads for the floor props must be reduced in the planning so far,\\nthat no overload occurs at the striking process.\\nPlease contact the DOKA engineer.",
            "en-us": "Horizontal loads must not be led into the lower parts.\\nStripping forces have to be drawn off through suitable measures.\\nAt floor span over 7.5m - additionally to the usual dimension -\\nthe loading must be considered in the striking process.\\nThe concrete loads for the floor props must be reduced in the planning so far, that no overload occurs at the striking process.\\nPlease contact the DOKA engineer.",
            "es": "No deben transmitirse cargas horizontales a las partes inferiores\\nLas fuerzas producidas durante el desencofrado deben ser absorbidas mediante medidas apropiadas.\\nEn anchuras de tensión en techos que superen 7,5m - además de la medición habitual -\\nen el proceso de encofrar se tienen que tener en cuenta las cargas.\\nLas cargas del hormigón que soportan los puntales deben reducirse en el proyecto\\npara evitar una sobrecarga en el proceso de encofrar.\\nPor favor, contacte con el técnico de Doka.",
            "fr": "Aucun effort horizontal ne doit être transmis dans l'étaiement.\\nLa reprise des efforts du coffrage d'about doit être prévue par des mesures appropriées.\\nPour des portées de dalle supérieures à 7,5m, en plus des calculs habituels -\\nprendre en compte les efforts qui s'exercent au décoffrage.\\nLes charges dues au béton doivent alors être diminuées en conséquence dans l'étude,\\npour qu'aucune surcharge n'intervienne lors du décoffrage.\\nVeuillez contacter le technicien Doka.",
            "it": "Non possono essere introdotti pesi orizzontali nella costruzione inferiore.\\nLe forze di disarmo devono essere deviate con misura adeguate.\\nPer solai di dimensioni superiori ai 7,5m, oltre al normale dimensionamento,\\nconsiderare anche i carichi in fase di disarmo.\\nDurante la fase di progetto, ridurre quindi i carichi di calcestruzzo sostenuti dai,\\npuntelli in modo tale che non subentri un sovraccarico durante la fase di disarmo.\\nRivolgersi ai tecnici DOKA.",
            "hu": "A vízszintes terheket nem szabad az alátámasztásba bevezetni.\\nA kizsaluzást a megfelelo módon kell végrehajtani.\\nA 7,5m-t meghaladó födémfesztávoknál a -   szokásos méretezés mellett a kizsaluzás folyamán -\\nfellépo terheket is figyelembe kell venni.\\nAz egyes födémtámaszokra jutó betonterhelést úgy kell csökkenteni,\\nhogy a kizsaluzás során ne lépjen fel túlterhelés.\\nVegye fel a kapcsolatot a Doka szakemberével.",
            "ru": "Передача горизонтальных нагрузок на основание не допускается.\\nСилы распалубливания должны отводиться соответствующими средствами.\\nПри расстояниях между опорами пролетов свыше 7,5 м - в дополнение к стандартным замерам -\\nдолжны учитываться нагрузки в процессе снятия опалубки.\\nПоэтому при планировании нагрузки бетона на стойки для перекрытий должны быть сокращены настолько,\\nчтобы при снятии опалубки не возникло перегрузки.\\nСвяжитесь с инженером компании Doka.",
            "sk": "Do podpery nesmú byt vedené žiadne horizontálne zataženia.\\nOddebnovacie sily sa odvádzajú prostredníctvom stanovených opatrení.\\nPri rozpätiach stropu nad 7,5 m treba - \\nodatočne k bežn. dimenzovaniu-zohľadniť zaťaženia v procese oddebňovania.\\nZaťaženie betónu pre stropné podpery sa musí preto v projekte redukovať,\\naby v procese oddebňovania nedošlo k pre-  ťaženiu. \\nKontaktujte prosím DOKA technika.",
            "pl": "Obciążenia poziome nie mogą być wprowadzane do elementów położonych niżej.\\nSiły przy rozszalunku należy przykładać przy użyciu odpowiednich środków.\\nPrzy rozpiętości przęsła ponad 7,5 m obciążenia wykraczają poza standardowe i muszą być uwzględnione w procesie rozszalunku.\\nObciążenia od betonu w przypadku podpór muszą być redukowane na etapie projektowania w stopniu\\ntakim aby nie nastąpiło przeciążenie przy rozszalunku.\\nProsimy o kontakt z inżynierem DOKA."
        },
        {
            "ID": "HINWEISTEXT_FLEXS",
            "de": "Es dürfen keine Horizontallasten in die Unterstellung eingeleitet werden.Abschalungskräfte sind durch geeignete Maßnahmen abzuleiten.\\nBei Deckenspannweiten über 7,5m müssen - zusätzlich zur üblichen Bemessung - \\ndie Belastungen im Ausschalvorgang berücksichtigt werden.\\nDie Betonlasten für die Deckenstützen müssen daher in der Planung soweit reduziert werden, \\ndass beim Ausschalvorgang keine Überlastung auftritt.\\nKontaktieren Sie bitte den DOKA Techniker.",
            "en-gb": "Horizontal loads must not be led into the lower parts.\\nStripping forces have to be drawn off through suitable measures.\\nAt floor span over 7.5m - additionally to the usual dimension -\\nthe loading must be considered in the striking process.\\nThe concrete loads for the floor props must be reduced in the planning so far,\\nthat no overload occurs at the striking process.\\nPlease contact the DOKA engineer.",
            "en-us": "Horizontal loads must not be led into the lower parts.\\nStripping forces have to be drawn off through suitable measures.\\nAt floor span over 7.5m - additionally to the usual dimension - the loading must be considered in the striking process.\\nThe concrete loads for the floor props must be reduced in the planning so far, that no overload occurs at the striking process.\\nPlease contact the DOKA engineer.",
            "es": "No deben transmitirse cargas horizontales a las partes inferiores\\nLas fuerzas producidas durante el desencofrado deben ser absorbidas mediante medidas apropiadas.\\nEn anchuras de tensión en techos que superen 7,5m - además de la medición habitual -\\nen el proceso de encofrar se tienen que tener en cuenta las cargas.\\nLas cargas del hormigón que soportan los puntales deben reducirse en el proyecto\\npara evitar una sobrecarga en el proceso de encofrar.\\nPor favor, contacte con el técnico de Doka.",
            "fr": "Aucun effort horizontal ne doit être transmis dans l'étaiement.\\nLa reprise des efforts du coffrage d'about doit être prévue par des mesures appropriées.\\nPour des portées de dalle supérieures à 7,5m, en plus des calculs habituels -\\nprendre en compte les efforts qui s'exercent au décoffrage.\\nLes charges dues au béton doivent alors être diminuées en conséquence dans l'étude,\\npour qu'aucune surcharge n'intervienne lors du décoffrage.\\nVeuillez contacter le technicien Doka.",
            "it": "Non possono essere introdotti pesi orizzontali nella costruzione inferiore.\\nLe forze di disarmo devono essere deviate con misura adeguate.\\nPer solai di dimensioni superiori ai 7,5m, oltre al normale dimensionamento,\\nconsiderare anche i carichi in fase di disarmo.\\nDurante la fase di progetto, ridurre quindi i carichi di calcestruzzo sostenuti dai,\\npuntelli in modo tale che non subentri un sovraccarico durante la fase di disarmo.\\nRivolgersi ai tecnici DOKA",
            "hu": "Minden ezzel a programmal készített tervet a felhasználónak kiadás elott ellenoriznie kell!\\nA kizsaluzást a megfelelo módon kell végrehajtani.\\nA 7,5m-t meghaladó födémfesztávoknál a -   szokásos méretezés mellett a kizsaluzás folyamán -\\nfellépo terheket is figyelembe kell venni.\\nAz egyes födémtámaszokra jutó betonterhelést úgy kell csökkenteni,\\nhogy a kizsaluzás során ne lépjen fel túlterhelés.\\nVegye fel a kapcsolatot a Doka szakemberével.",
            "ru": "Передача горизонтальных нагрузок на основание не допускается.\\nСилы распалубливания должны отводиться соответствующими средствами.\\nПри расстояниях между опорами пролетов свыше 7,5 м - в дополнение к стандартным замерам -\\nдолжны учитываться нагрузки в процессе снятия опалубки.\\nПоэтому при планировании нагрузки бетона на стойки для перекрытий должны быть сокращены настолько,\\nчтобы при снятии опалубки не возникло перегрузки.\\nСвяжитесь с инженером компании Doka.",
            "sk": "Do podpery nesmú byt vedené žiadne horizontálne zataženia.\\nOddebnovacie sily sa odvádzajú prostredníctvom stanovených opatrení.\\nPri rozpätiach stropu nad 7,5 m treba - \\ndodatočne k bežn. dimenzovaniu-zohľadniť zaťaženia v procese oddebňovania.\\nZaťaženie betónu pre stropné podpery sa musí preto v projekte redukovať,\\naby v procese oddebňovania nedošlo k pre-  ťaženiu. \\nKontaktujte prosím DOKA technika.",
            "pl": "Obciążenia poziome nie mogą być wprowadzane do elementów położonych niżej.\\nSiły przy rozszalunku należy przykładać przy użyciu odpowiednich środków.\\nPrzy rozpiętości przęsła ponad 7,5 m obciążenia wykraczają poza standardowe i muszą być uwzględnione w procesie rozszalunku.\\nObciążenia od betonu w przypadku podpór muszą być redukowane na etapie projektowania w stopniu\\ntakim aby nie nastąpiło przeciążenie przy rozszalunku.\\nProsimy o kontakt z inżynierem DOKA."
        },
        {
            "ID": "HINWEISTEXT_FRAMIECO",
            "de": "Beim Einsatz mit Fundamentspanner werden die benötigten Lochbänder in der Stückliste nicht berücksichtigt.",
            "en-gb": "At the use with foundation clamp the needed perforated tapes are not considered in the piece list.",
            "en-us": "At the use with foundation clamp the needed perforated tapes are not considered in the piece list.",
            "es": "Cuando se emplean tensores de cimientos, las cintas perforadas necesarias no se reflejan en el listado de piezas.",
            "fr": "Les bandes perforées nécessaire pour l'utilisation des serrages pour fondation ne sont pas prises en compte dans la liste de pièces.",
            "it": "Se si utilizzano morsetti per fondazioni, nella lista pezzi non vengono considerati nastri perforati necessari.",
            "hu": "Ankerszalag feszítõ alkalmazása esetén az ankerszalag mennyisége nincs az elemlistában figyelembe véve.",
            "ru": "При использовании фундаментных зажимов, необходимая перфолента в спецификации не учитывается.",
            "sk": "Pri použití základových upínačov sa potrebné dierované pásy v kusovníku nezohľadňujú.",
            "pl": "Przy użyciu z napinaczem potrzebne taśmy perforowane nie są uwzględniane na liście materiałowej."
        },
        {
            "ID": "HINWEISTEXT_TOP50TRG",
            "de": "Top50 Trägerschalung kann nicht mit 'Schalen automatisch' oder 'Schalen von Hand' geschalt werden.\\nFür das Schalen steht ein externes Schalprogramm zur Verfügung\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...\\nZur Vermeidung eventueller Überlastung der Schalelemente ist unter anderem\\ndie Anzahl der automatisch eingefügten Schalungsanker zu überprüfen.",
            "en-gb": "Top50 large-area formwork cannot be formed with 'Form automatically' or 'Form interactive'.\\nAn external formwork program is available for forming\\nCall with Formwork / Single parts interactive / in the particular formwork system /...\\nTo avoid possible overload of formwork elements,\\nthe number of automatical inserted formwork anchors are to check among other things.",
            "en-us": "T\\nTop50 large-area formwork cannot be formed with 'Form automatically' or 'Form by hand'.\\nAn external formwork program is available for forming\\nCall with formwork / Single parts manually / in the particular formwork system /...\\nTo avoid possible overload of formwork elements,\\nthe number of automatical inserted formwork anchors are to check among other things.",
            "es": "Top 50 El encofrado de pilares no se puede instalar con 'Encofrado automático o 'Encofrado manual'.\\nPara encofrar existe un programa externo\\nActivación en Encofrado / Pie&zas sueltas a mano /...en el sistema de encofrado correspondiente /...\\nPara evitar una eventual sobrecarga de los elementos del encofrado, entre otros temas\\nse tiene que comprobar el número de los anclajes que se instalaron automáticamente.",
            "fr": "Le coffrage mixte Top 50 ne peut pas être coffré avec 'coffrage automatique' ou 'coffrage manuel'.\\nLe coffrage peut être réalisé par un programme de coffrage externe disponible\\nAppeler dans Coffrage / Pièces détachées manuellement / dans le système de coffrage souhaité\\nPour éviter d'éventuelles surcharges des éléments de coffrage, contrôler entre autres\\nle nombre d'ancrages insérés automatiquement.",
            "it": "Impossibile 'montare automaticamente' o 'montare manualmente' la Cassaforma a travi Top 50.\\nPer il suo montaggio è disponibile un programma esterno di casseratura\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...\\nPer evitare un sovraccarico degli elementi, è necessario controllare tra\\nl’altro anche il numero degli ancoranti distribuiti automaticamente.",
            "hu": "Top 50 nagytáblás falzsalu 'Automatikus zsaluzás’ vagy 'Kézi zsaluzás' módban nem alkalmazható.\\nA zsaluzáshoz egy küls· zsaluprogram áll rendelkezésre\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben\\nA zsaluelemek esetleges túlterhelésének elkerülése érdekében többek között\\naz automatikusan elhelyezett zsaluankerok számát kell ellen·rizni.",
            "ru": "Балочная опалубка Top50 не может быть построена при помощи функций \″Опалубить автоматически\″ или \″Опалубить вручную\″.\\nДля опалубливания имеется специальная программа\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / ...\\nВо избежание возможной перегрузки опалубочных элементов, в частности,\\nследует проверять количество автоматически вставленных опалубочных анкеров.",
            "sk": "Top 50 nosníkové deb.nemôže byť zadeb.pomocou 'Debniť automaticky' alebo 'Debniť ručne'.\\nPre debnenie je k dispozícii externý debniaci program.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme\\nK eliminácii eventuálneho preťaženia debniacich prvkov je okrem iného\\nkontrola počtu automaticky vložených debniacich kotiev.",
            "pl": "Szalunku wielkopowierzchniowego Top50 nie można utworzyć przy użyciu komend „Utwórz automatycznie” ani „Utwórz interaktywnie”.\\nDo szalowania jest dostępny zewnętrzny asystent do szalunków\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\nAby uniknąć ewentualnego przeciążenia elementów szalunkowych, \\nnależy sprawdzić między innymi liczbę automatycznie wstawionych ściągów."
        },
        {
            "ID": "HINWEISTEXT_XFR30",
            "de": "Schalsystem Framax Xlife Betondruck: Siehe Anwenderinformation Kapitel Framax Xlife-Element im Detail und Ankersystem\″\\nAchtung: \\nBei der Rundschalung mit Framax-Bogenblechen ist der zulässige Frischbetondruck 50 kN/m².",
            "en-gb": "Formwork system Framax Xlife concrete pressure: see also user information, chapter Framax Xlife-element in detail and anchoring system\\nAttention:\\nAt the circular formwork with Framax circular forming plates the permissible fresh concrete pressure is 50 kN/m².",
            "en-us": "Formwork system Framax Xlife concrete pressure 1650 psf\\nAttention:\\nAt the circular formwork with Framax circular forming plates the permissible fresh concrete pressure is 1000 psf",
            "es": "Sistema de encofrado Framax Xlife presión de hormigonado: Consulte la información de usuario, capítulo Elemento marco Framax Xlife en detalle y sistema de anclaje\\nAtención:\\nEn el caso del encofrado circular con elementos de arco Framax la presión de hormigonado permitida es de 50 kN/m²",
            "fr": "Système coffrant Framax Xlife Pression de bétonnage : voir information à l'attention de l'utilisateur, chapitre Panneau Framax Xlife en détail et système d'ancrage\\nAttention:\\nPour le coffrage courbe avec les tôles de courbure Framax, la pression de bétonnage admissible est de 50 kN/m².",
            "it": "Sistema di casseratura Framax Xlife pressione di getto 80kN/m². Vedere la Userinformation, capitolo Framax Xlife-elemento in dettaglio e sistema di ancoraggio.\\nAttenzione:\\nPer il cassero circolare con le lamiere per tondo Framax la pressione del calcestruzzo fresco ammissibile è pari a 50 kN/m²",
            "hu": "Framax Xlife zsalurendszer megengedett betonnyomása: ld. az alkalmazási útmutatóban is, Framax Xlife elemről részletesen és az ankerrendszer fejezetekben\\nFigyelem:\\nA Framax hajlítható lemezekkel készült íves falzsaluknál a megengedett frissbetonnyomás 50 kN/m²",
            "ru": "Опалубочная система Framax Xlife давление бетона: См. информацию для пользователя глава элемент Framax Xlife в деталях и анкерная система\\nВнимание:\\nДля круглой опалубки с дугообразующими листами Framax, допустимое давление свежего бетона составляет 50 кН/м²",
            "sk": "Debniaci systém Framax Xlife - tlak betónu: pozri kapitolu v návode na montáž a používanie Framax Xlife-panel v detaile a kotevný systém \\nPozor:\\nPri kruhovom debnení s Framax-oblúkovými plechmi je dovolený tlak čerstvého betónu 50 kN/m²",
            "pl": "n\\nParcie mieszanki w systemie szalunku Framax Xlife: zob. także informacje dla użytkownika w rozdziale „Framax Xlife – szczegółowe elementy i system kotwienia″\\nUwaga: \\nW przypadku szalunku łukowego przy użyciu blach łukowych Framax dopuszczalne parcie mieszanki wynosi 50 kN/m²."
        },
        {
            "ID": "HINWEISTEXT_XFRAMA_S",
            "de": "Schalsystem Framax-S Xlife Betondruck: Siehe Anwenderinformation Kapitel Framax Element im Detail und Ankersystem\\nAchtung:\\nBei Verwendung des Universalelementes 1,22 m x 2,70 m (1,35 m) in einer Ecklösung ist der maximale Betondruck 60 kN/m².",
            "en-gb": "Formwork system Framax-S Xlife concrete pressure: see also user information, chapter Framax element in detail and anchoring system\\nAttention:\\nIn case of using the universal panel 1.22 m x 2.70 m (1.35 m) in a corner solution, the maximum concrete pressure is 60 kN/m² (1250 psf).",
            "en-us": "Formwork system Framax_S concrete pressure 1650 psf\\nAttention:\\nIn case of using the universal panel 1.22 x 2.70 ( 1.35 ) m in a corner solution maximum concrete pressure is 1250 psf.",
            "es": "Sistema de encofrado Framax-S presión de hormigonado: Consulte la información de usuario, capítulo Elemento marco Framax Xlife en detalle y sistema de anclaje\\nAtención:\\nUtilizando el elemento universal 1,22 x 2,70 (1,35)m en una solución de esquina la máxima presión de hormigonado es de 60kN/m².",
            "fr": "Système coffrant Framax-S Xlife Pression de bétonnage : voir information à l'attention de l'utilisateur, chapitre Panneau Framax en détail et système d'ancrage\\nAttention:\\nEn utilisant le panneau universel 1,22 x 2,70 (1,35) m pour une solution d'angle, la pression de bétonnage maximale est de 60 kN/m²",
            "it": "Casseforme Framax-S Xlife pressione del calcestruzzo: vedere la Userinformation, capitolo Framax Xlife-elemento in dettaglio e sistema di ancoraggio\\nAttenzione:\\nUtilizzando l‘elemento universale 1,22 x 2,70 (1,35) m in una soluzione d‘angolo la massima pressione di getto è di 60 kN/m²",
            "hu": "Framax-S zsalurendszer betonnyomás: ld. az alk. útmutatót is, Framax elemek részletesen és ankerrendszer fejezet\\nFigyelem:\\nAmennyiben 1,22x2,70 (1,35) m-es univerzális elemet alkalmazunk sarokzsaluzáshoz a maximális betonnyomás 60 kN/m",
            "ru": "Опалубочная система Framax-S Xlife давление бетона: См. информацию для пользователя глава элемент Framax в деталях и анкерная система\\nВнимание:\\nПри использовании универсального элемента 1,22 x 2,70 ( 1,35 ) м в угловом решении максимальное давление бетона составляет 60 кН/м²",
            "sk": "Debniaci systém Framax-S Xlife - tlak betónu: pozri kapitolu v návode na montáž a používanie Framax-panel v detaile a kotevný systém \\nPozor:\\nPri použití univerzálneho panelu 1,22 m x 2,70 m (1,35 m) v oblasti rohu je maximálny tlak betónu 60 kN/m².",
            "pl": "Parcie mieszanki w systemie szalunku Framax S Xlife: zob. także informacje dla użytkownika w rozdziale „Framax S Xlife – szczegółowe elementy i system kotwienia″\\nUwaga: \\nW razie użycia płyty uniwersalnej 1,22 m x 2,7 m (1,35 m) w rozwiązaniu narożnika maksymalny Parcie mieszanki wynosi 60 kN/m² (1250 psf)."
        },
        {
            "ID": "HINWEISTEXT_XFRAMI",
            "de": "Beim Einsatz mit Fundamentspanner werden die benötigten Lochbänder in der Stückliste nicht berücksichtigt.",
            "en-gb": "At the use with foundation clamp the needed perforated tapes are not considered in the piece list.",
            "en-us": "At the use with foundation clamp the needed perforated tapes are not considered in the piece list.",
            "es": "Cuando se emplean tensores de cimientos, las cintas perforadas necesarias no se reflejan en el listado de piezas.",
            "fr": "Les bandes perforées nécessaire pour l'utilisation des serrages pour fondation ne sont pas prises en compte dans la liste de pièces.",
            "it": "Se si utilizzano morsetti per fondazioni, nella lista pezzi non vengono considerati nastri perforati necessari.",
            "hu": "Ankerszalag feszítõ alkalmazása esetén az ankerszalag mennyisége nincs az elemlistában figyelembe véve.",
            "ru": "При использовании фундаментных зажимов, необходимая перфолента в спецификации не учитывается.",
            "sk": "Pri použití základových upínačov sa potrebné dierované pásy v kusovníku nezohľadňujú.",
            "pl": "Przy użyciu z napinaczem potrzebne taśmy perforowane nie są uwzględniane na liście materiałowej."
        },
        {
            "ID": "HINWEISTEXT_XFRAMI_S",
            "de": "Beim Einsatz mit Fundamentspanner werden die benötigten Lochbänder in der Stückliste nicht berücksichtigt.",
            "en-gb": "At the use with foundation clamp the needed perforated tapes are not considered in the piece list.",
            "en-us": "At the use with foundation clamp the needed perforated tapes are not considered in the piece list.",
            "es": "Cuando se emplean tensores de cimientos, las cintas perforadas necesarias no se reflejan en el listado de piezas.",
            "fr": "Les bandes perforées nécessaire pour l'utilisation des serrages pour fondation ne sont pas prises en compte dans la liste de pièces.",
            "it": "Se si utilizzano morsetti per fondazioni, nella lista pezzi non vengono considerati nastri perforati necessari.",
            "hu": "Ankerszalag feszítõ alkalmazása esetén az ankerszalag mennyisége nincs az elemlistában figyelembe véve.",
            "ru": "При использовании фундаментных зажимов, необходимая перфолента в спецификации не учитывается.",
            "sk": "Pri použití základových upínačov sa potrebné dierované pásy v kusovníku nezohľadňujú.",
            "pl": "Przy użyciu z napinaczem potrzebne taśmy perforowane nie są uwzględniane na liście materiałowej."
        },
        {
            "ID": "HINWEISTEXT_XFRAMINI",
            "de": "Beim Einsatz mit Fundamentspanner werden die benötigten Lochbänder in der Stückliste nicht berücksichtigt.",
            "en-gb": "At the use with foundation clamp the needed perforated tapes are not considered in the piece list.",
            "en-us": "At the use with foundation clamp the needed perforated tapes are not considered in the piece list.",
            "es": "Cuando se emplean tensores de cimientos, las cintas perforadas necesarias no se reflejan en el listado de piezas.",
            "fr": "Les bandes perforées nécessaire pour l'utilisation des serrages pour fondation ne sont pas prises en compte dans la liste de pièces.",
            "it": "Se si utilizzano morsetti per fondazioni, nella lista pezzi non vengono considerati nastri perforati necessari.",
            "hu": "Ankerszalag feszítõ alkalmazása esetén az ankerszalag mennyisége nincs az elemlistában figyelembe véve.",
            "ru": "При использовании фундаментных зажимов, необходимая перфолента в спецификации не учитывается.",
            "sk": "Pri použití základových upínačov sa potrebné dierované pásy v kusovníku nezohľadňujú.",
            "pl": "Przy użyciu z napinaczem potrzebne taśmy perforowane nie są uwzględniane na liście materiałowej."
        },
        {
            "ID": "HINWEISTEXT_XFRECO",
            "de": "Schalsystem Framax Xlife Betondruck: Siehe Anwenderinformation Kapitel Framax Xlife-Element im Detail und Ankersystem\″\\nAchtung: \\nBei der Rundschalung mit Framax-Bogenblechen ist der zulässige Frischbetondruck 50 kN/m².",
            "en-gb": "Formwork system Framax Xlife concrete pressure: see also user information, chapter Framax Xlife-element in detail and anchoring system\\nAttention:\\nAt the circular formwork with Framax circular forming plates the permissible fresh concrete pressure is 50 kN/m².",
            "en-us": "Formwork system Framax Xlife concrete pressure 1650 psf\\nAttention:\\nAt the circular formwork with Framax circular forming plates the permissible fresh concrete pressure is 1000 psf",
            "es": "Sistema de encofrado Framax Xlife presión de hormigonado: Consulte la información de usuario, capítulo Elemento marco Framax Xlife en detalle y sistema de anclaje\\nAtención:\\nEn el caso del encofrado circular con elementos de arco Framax la presión de hormigonado permitida es de 50 kN/m²",
            "fr": "Système coffrant Framax Xlife Pression de bétonnage : voir information à l'attention de l'utilisateur, chapitre Panneau Framax Xlife en détail et système d'ancrage\\nAttention:\\nPour le coffrage courbe avec les tôles de courbure Framax, la pression de bétonnage admissible est de 50 kN/m².",
            "it": "Sistema di casseratura Framax Xlife pressione di getto 80kN/m². Vedere la Userinformation, capitolo Framax Xlife-elemento in dettaglio e sistema di ancoraggio.\\nAttenzione:\\nPer il cassero circolare con le lamiere per tondo Framax la pressione del calcestruzzo fresco ammissibile è pari a 50 kN/m²",
            "hu": "Framax Xlife zsalurendszer megengedett betonnyomása: ld. az alkalmazási útmutatóban is, Framax Xlife elemről részletesen és az ankerrendszer fejezetekben\\nFigyelem:\\nA Framax hajlítható lemezekkel készült íves falzsaluknál a megengedett frissbetonnyomás 50 kN/m²",
            "ru": "Опалубочная система Framax Xlife давление бетона: См. информацию для пользователя глава элемент Framax Xlife в деталях и анкерная система\\nВнимание:\\nДля круглой опалубки с дугообразующими листами Framax, допустимое давление свежего бетона составляет 50 кН/м²",
            "sk": "Debniaci systém Framax Xlife - tlak betónu: pozri kapitolu v návode na montáž a používanie Framax Xlife-panel v detaile a kotevný systém \\nPozor:\\nPri kruhovom debnení s Framax-oblúkovými plechmi je dovolený tlak čerstvého betónu 50 kN/m²",
            "pl": "n\\nParcie mieszanki w systemie szalunku Framax Xlife: zob. także informacje dla użytkownika w rozdziale „Framax Xlife – szczegółowe elementy i system kotwienia″\\nUwaga: \\nW przypadku szalunku łukowego przy użyciu blach łukowych Framax dopuszczalne parcie mieszanki wynosi 50 kN/m²."
        },
        {
            "ID": "HINWEISTEXT_XFRPLUS",
            "de": "Schalsystem Framax Xlife plus Betondruck: Siehe Anwenderinformation Kapitel Framax Xlife plus-Element im Detail und Ankersystem\\nAchtung:\\nBei der Rundschalung mit Framax-Bogenblechen ist der zulässige Frischbetondruck 50 kN/m²",
            "en-gb": "Formwork system Framax Xlife plus concrete pressure: see also user information, chapter Framax Xlife plus-element in detail and anchoring system\\nAttention:\\nAt the circular formwork with Framax circular forming plates the permissible fresh concrete pressure is 50 kN/m².",
            "en-us": "Formwork system Framax Xlife plus concrete pressure: see also userinformation, chapter Framax Xlife plus-element in detail and anchoring system\\nAttention:\\nAt the circular formwork with Framax circular forming plates the permissible fresh concrete pressure is 50 kN/m².",
            "es": "Sistema de encofrado Framax Xlife plus presión de hormigonado: Consulte la información de usuario, capítulo Elemento marco Framax Xlife plus en detalle y sistema de anclaje\\nAtención:\\nEn el caso del encofrado circular con elementos de arco Framax la presión de hormigonado permitida es de 50 kN/m²",
            "fr": "Système coffrant Framax Xlife plus Pression de bétonnage : voir information à l'attention de l'utilisateur, chapitre Panneau Framax Xlife plus en détail et système d'ancrage\\nAttention:\\nPour le coffrage courbe avec les tôles de courbure Framax, la pression de bétonnage admissible est de 50 kN/m².",
            "it": "Sistema di casseratura Framax Xlife plus pressione di getto 80kN/m². Vedere la Userinformation, capitolo Framax Xlife plus-elemento in dettaglio e sistema di ancoraggio.\\nAttenzione:\\nPer il cassero circolare con le lamiere per tondo Framax la pressione del calcestruzzo fresco ammissibile è pari a 50 kN/m²",
            "hu": "Framax Xlife plus zsalurendszer megengedett betonnyomása: ld. az alkalmazási útmutatóban is, Framax Xlife plus elemről részletesen és az ankerrendszer fejezetekben\\nFigyelem:\\nA Framax hajlítható lemezekkel készült íves falzsaluknál a megengedett frissbetonnyomás 50 kN/m²",
            "ru": "Опалубочная система Framax Xlife plus давление бетона: См. информацию для пользователя глава элемент Framax Xlife plus в деталях и анкерная система\\nВнимание:\\nДля круглой опалубки с дугообразующими листами Framax, допустимое давление свежего бетона составляет 50 кН/м²",
            "sk": "Debniaci systém Framax Xlife plus - tlak betónu: pozri kapitolu v návode na montáž a používanie Framax Xlife plus-panel v detaile a kotevný systém \\nPozor:\\nPri kruhovom debnení s Framax-oblúkovými plechmi je dovolený tlak čerstvého betónu 50 kN/m²",
            "pl": "Parcie mieszanki w systemie szalunku Framax Xlife Plus: zob. także informacje dla użytkownika w rozdziale „Framax Xlife Plus – szczegółowe elementy i system kotwienia″\\nUwaga: \\nW przypadku szalunku łukowego przy użyciu blach łukowych Framax dopuszczalne parcie mieszanki wynosi 50 kN/m²."
        },
        {
            "ID": "HINWEISTEXT_XLIGHT",
            "de": "Schalsystem DokaXlight Betondruck: Siehe Anwenderinformation Kapitel DokaXlight-Element im Detail und Ankersystem",
            "en-gb": "Formwork system DokaXlight concrete pressure: see also user information, chapter DokaXlight-element in detail and anchoring system",
            "en-us": "Formwork system DokaXlight concrete pressure: see also user information, chapter DokaXlight-element in detail and anchoring system",
            "es": "Sistema de encofrado DokaXlight presión de hormigonado: Consulte la información de usuario, capítulo Elemento marco DokaXlight en detalle y sistema de anclaje",
            "fr": "Système coffrant DokaXlight Pression de bétonnage : voir information à l'attention de l'utilisateur, chapitre Panneau DokaXlight en détail et système d'ancrage",
            "it": "Sistema di casseratura DokaXlight pressione di getto: Vedere la Userinformation, capitolo DokaXlight-elemento in dettaglio e sistema di ancoraggio.",
            "hu": "DokaXlight zsalurendszer megengedett betonnyomása: ld. az alkalmazási útmutatóban is, DokaXlight elemről részletesen és az ankerrendszer fejezetekben",
            "ru": "Опалубочная система DokaXlight давление бетона: См. информацию для пользователя глава элемент DokaXlight в деталях и анкерная система",
            "sk": "Debniaci systém DokaXlight - tlak betónu: pozri kapitolu v návode na montáž a používanie DokaXlight-panel v detaile a kotevný systém",
            "pl": "Parcie mieszanki w systemie szalunku DokaXlight: zob. także informacje dla użytkownika w rozdziale „DokaXlight – szczegółowe elementy i system kotwienia”."
        },
        {
            "ID": "HINWEISTEXT_XREFORMA",
            "de": "Schalsystem ReForma Standard plus Betondruck: Siehe Anwenderinformation Kapitel ReForma SP-Element im Detail und Ankersystem",
            "en-gb": "Formwork system ReForma Standard plus concrete pressure: see also user information, chapter ReForma SP element in detail and anchoring system",
            "en-us": "Formwork system ReForma Standard plus concrete pressure 1650 psf",
            "es": "Sistema de encofrado ReForma Standard plus presión de hormigonado: Consulte la información de usuario, capítulo Elemento marco ReForma SP en detalle y sistema de anclaje",
            "fr": "Système coffrant ReForma Standard plus Pression de bétonnage : voir information à l'attention de l'utilisateur, chapitre Panneau ReForma SP en détail et système d'ancrage",
            "it": "Sistema di casseratura ReForma Standard plus pressione di getto. Vedere la Userinformation, capitolo elemento ReForma SP in dettaglio e sistema di ancoraggio.",
            "hu": "ReForma Standard plus zsalurendszer megengedett betonnyomása: ld. az alkalmazási útmutatóban is, ReForma SP elemről részletesen és az ankerrendszer fejezetekben",
            "ru": "Опалубочная система ReForma Standard plus давление бетона: См. информацию для пользователя глава элемент ReForma SP в деталях и анкерная система",
            "sk": "Debniaci systém ReForma Standard plus - tlak betónu: pozri kapitolu v návode na montáž a používanie ReForma SP-panel v detaile a kotevný systém",
            "pl": "Parcie mieszanki w systemie szalunku ReForma Standard Plus: zob. także informacje dla użytkownika w rozdziale „ReForma Standard Plus – szczegółowe elementy i system kotwienia”."
        },
        {
            "ID": "HINWEISTEXT_XSTAHLfr",
            "de": "Schalsystem Framax Xlife Betondruck: Siehe Anwenderinformation Kapitel Framax Xlife-Element im Detail und Ankersystem\\nAchtung: \\nBei der Rundschalung mit Framax-Bogenblechen ist der zulässige Frischbetondruck 50 kN/m².",
            "en-gb": "Formwork system Framax Xlife concrete pressure: see also user information, chapter Framax Xlife-element in detail and anchoring system\\nAttention:\\nAt the circular formwork with Framax circular forming plates the permissible fresh concrete pressure is 50 kN/m².",
            "en-us": "Formwork system Framax Xlife concrete pressure 1650 psf\\nAttention:\\nAt the circular formwork with Framax circular forming plates the permissible fresh concrete pressure is 1000 psf",
            "es": "Sistema de encofrado Framax Xlife presión de hormigonado: Consulte la información de usuario, capítulo Elemento marco Framax Xlife en detalle y sistema de anclaje\\nAtención:\\nEn el caso del encofrado circular con elementos de arco Framax la presión de hormigonado permitida es de 50 kN/m²",
            "fr": "Système coffrant Framax Xlife Pression de bétonnage : voir information à l'attention de l'utilisateur, chapitre Panneau Framax Xlife en détail et système d'ancrage\\nAttention:\\nPour le coffrage courbe avec les tôles de courbure Framax, la pression de bétonnage admissible est de 50 kN/m².",
            "it": "Sistema di casseratura Framax Xlife pressione di getto 80kN/m². Vedere la Userinformation, capitolo Framax Xlife-elemento in dettaglio e sistema di ancoraggio.\\nAttenzione:\\nPer il cassero circolare con le lamiere per tondo Framax la pressione del calcestruzzo fresco ammissibile è pari a 50 kN/m²",
            "hu": "Framax Xlife zsalurendszer megengedett betonnyomása: ld. az alkalmazási útmutatóban is, Framax Xlife elemről részletesen és az ankerrendszer fejezetekben\\nFigyelem:\\nA Framax hajlítható lemezekkel készült íves falzsaluknál a megengedett frissbetonnyomás 50 kN/m²",
            "ru": "Опалубочная система Framax Xlife давление бетона: См. информацию для пользователя глава элемент Framax Xlife в деталях и анкерная система\\nВнимание:\\nДля круглой опалубки с дугообразующими листами Framax, допустимое давление свежего бетона составляет 50 кН/м²",
            "sk": "Debniaci systém Framax Xlife - tlak betónu: pozri kapitolu v návode na montáž a používanie Framax Xlife-panel v detaile a kotevný systém \\nPozor:\\nPri kruhovom debnení s Framax-oblúkovými plechmi je dovolený tlak čerstvého betónu 50 kN/m²",
            "pl": "n\\nParcie mieszanki w systemie szalunku Framax Xlife: zob. także informacje dla użytkownika w rozdziale „Framax Xlife – szczegółowe elementy i system kotwienia″\\nUwaga: \\nW przypadku szalunku łukowego przy użyciu blach łukowych Framax dopuszczalne parcie mieszanki wynosi 50 kN/m²."
        },
        {
            "ID": "HINWEISTEXT_XTRA",
            "de": "Es dürfen keine Horizontallasten in die Unterstellung eingeleitet werden.Abschalungskräfte sind durch geeignete Maßnahmen abzuleiten.\\nBei Deckenspannweiten über 7,5 m müssen - zusätzlich zur üblichen Bemessung - \\ndie Belastungen im Ausschalvorgang berücksichtigt werden.\\nDie Betonlasten für die Deckenstützen müssen daher in der Planung soweit reduziert werden, \\ndass beim Ausschalvorgang keine Überlastung auftritt.\\nKontaktieren Sie bitte den DOKA Techniker.",
            "en-gb": "Horizontal loads must not be led into the lower parts.\\nStripping forces have to be drawn off through suitable measures.\\nAt floor span over 7.5m - additionally to the usual dimension -\\nthe loading must be considered in the striking process.\\nThe concrete loads for the floor props must be reduced in the planning so far,\\nthat no overload occurs at the striking process.\\nPlease contact the DOKA engineer.",
            "en-us": "Horizontal loads must not be led into the lower parts.\\nStripping forces have to be drawn off through suitable measures.\\nAt floor span over 7.5m - additionally to the usual dimension -\\nthe loading must be considered in the striking process.\\nThe concrete loads for the floor props must be reduced in the planning so far, that no overload occurs at the striking process.\\nPlease contact the DOKA engineer.",
            "es": "No deben transmitirse cargas horizontales a las partes inferiores\\nLas fuerzas producidas durante el desencofrado deben ser absorbidas mediante medidas apropiadas.\\nEn anchuras de tensión en techos que superen 7,5m - además de la medición habitual -\\nen el proceso de encofrar se tienen que tener en cuenta las cargas.\\nLas cargas del hormigón que soportan los puntales deben reducirse en el proyecto\\npara evitar una sobrecarga en el proceso de encofrar.\\nPor favor, contacte con el técnico de Doka.",
            "fr": "Aucun effort horizontal ne doit être transmis dans l'étaiement.\\nLa reprise des efforts du coffrage d'about doit être prévue par des mesures appropriées.\\nPour des portées de dalle supérieures à 7,5m, en plus des calculs habituels -\\nprendre en compte les efforts qui s'exercent au décoffrage.\\nLes charges dues au béton doivent alors être diminuées en conséquence dans l'étude,\\npour qu'aucune surcharge n'intervienne lors du décoffrage.\\nVeuillez contacter le technicien Doka.",
            "it": "Non possono essere introdotti pesi orizzontali nella costruzione inferiore.\\nLe forze di disarmo devono essere deviate con misura adeguate.\\nPer solai di dimensioni superiori ai 7,5m, oltre al normale dimensionamento,\\nconsiderare anche i carichi in fase di disarmo.\\nDurante la fase di progetto, ridurre quindi i carichi di calcestruzzo sostenuti dai,\\npuntelli in modo tale che non subentri un sovraccarico durante la fase di disarmo.\\nRivolgersi ai tecnici DOKA.",
            "hu": "A vízszintes terheket nem szabad az alátámasztásba bevezetni.\\nA kizsaluzást a megfelelo módon kell végrehajtani.\\nA 7,5m-t meghaladó födémfesztávoknál a -   szokásos méretezés mellett a kizsaluzás folyamán -\\nfellépo terheket is figyelembe kell venni.\\nAz egyes födémtámaszokra jutó betonterhelést úgy kell csökkenteni,\\nhogy a kizsaluzás során ne lépjen fel túlterhelés.\\nVegye fel a kapcsolatot a Doka szakemberével.",
            "ru": "Передача горизонтальных нагрузок на основание не допускается.\\nСилы распалубливания должны отводиться соответствующими средствами.\\nПри расстояниях между опорами пролетов свыше 7,5 м - в дополнение к стандартным замерам -\\nдолжны учитываться нагрузки в процессе снятия опалубки.\\nПоэтому при планировании нагрузки бетона на стойки для перекрытий должны быть сокращены настолько,\\nчтобы при снятии опалубки не возникло перегрузки.\\nСвяжитесь с инженером компании Doka.",
            "sk": "Do podpery nesmú byt vedené žiadne horizontálne zataženia.\\nOddebnovacie sily sa odvádzajú prostredníctvom stanovených opatrení.\\nPri rozpätiach stropu nad 7,5 m treba -\\ndodatočne k bežn. dimenzovaniu-zohľadniť zaťaženia v procese oddebňovania.\\nZaťaženie betónu pre stropné podpery sa musí preto v projekte redukovať,\\naby v procese oddebňovania nedošlo k pre-  ťaženiu.\\nKontaktujte prosím DOKA technika.",
            "pl": "Obciążenia poziome nie mogą być wprowadzane do elementów położonych niżej.\\nSiły przy rozszalunku należy przykładać przy użyciu odpowiednich środków.\\nPrzy rozpiętości przęsła ponad 7,5 m obciążenia wykraczają poza standardowe i muszą być uwzględnione w procesie rozszalunku.\\nObciążenia od betonu w przypadku podpór muszą być redukowane na etapie projektowania w stopniu\\ntakim aby nie nastąpiło przeciążenie przy rozszalunku.\\nProsimy o kontakt z inżynierem DOKA."
        },
        {
            "ID": "HINWEISTEXT_XTRA30",
            "de": "Es dürfen keine Horizontallasten in die Unterstellung eingeleitet werden.Abschalungskräfte sind durch geeignete Maßnahmen abzuleiten.\\nBei Deckenspannweiten über 7,5 m müssen - zusätzlich zur üblichen Bemessung - \\ndie Belastungen im Ausschalvorgang berücksichtigt werden.\\nDie Betonlasten für die Deckenstützen müssen daher in der Planung soweit reduziert werden, \\ndass beim Ausschalvorgang keine Überlastung auftritt.\\nKontaktieren Sie bitte den DOKA Techniker.",
            "en-gb": "Horizontal loads must not be led into the lower parts.\\nStripping forces have to be drawn off through suitable measures.\\nAt floor span over 7.5m - additionally to the usual dimension -\\nthe loading must be considered in the striking process.\\nThe concrete loads for the floor props must be reduced in the planning so far,\\nthat no overload occurs at the striking process.\\nPlease contact the DOKA engineer.",
            "en-us": "Horizontal loads must not be led into the lower parts.\\nStripping forces have to be drawn off through suitable measures.\\nAt floor span over 7.5m - additionally to the usual dimension -\\nthe loading must be considered in the striking process.\\nThe concrete loads for the floor props must be reduced in the planning so far,\\nthat no overload occurs at the striking process.\\nPlease contact the DOKA engineer.",
            "es": "No deben transmitirse cargas horizontales a las partes inferiores\\nLas fuerzas producidas durante el desencofrado deben ser absorbidas mediante medidas apropiadas.\\nEn anchuras de tensión en techos que superen 7,5m - además de la medición habitual \\nen el proceso de encofrar se tienen que tener en cuenta las cargas.\\nLas cargas del hormigón que soportan los puntales deben reducirse en el proyecto\\npara evitar una sobrecarga en el proceso de encofrar.\\nPor favor, contacte con el técnico de Doka.",
            "fr": "Aucun effort horizontal ne doit être transmis dans l'étaiement.\\nLa reprise des efforts du coffrage d'about doit être prévue par des mesures appropriées.\\nPour des portées de dalle supérieures à 7,5m, en plus des calculs habituels -\\nprendre en compte les efforts qui s'exercent au décoffrage.\\nLes charges dues au béton doivent alors être diminuées en conséquence dans l'étude,\\npour qu'aucune surcharge n'intervienne lors du décoffrage.\\nVeuillez contacter le technicien Doka.",
            "it": "Non possono essere introdotti pesi orizzontali nella costruzione inferiore.\\nLe forze di disarmo devono essere deviate con misura adeguate.\\nPer solai di dimensioni superiori ai 7,5m, oltre al normale dimensionamento,\\nconsiderare anche i carichi in fase di disarmo.\\nDurante la fase di progetto, ridurre quindi i carichi di calcestruzzo sostenuti dai,\\npuntelli in modo tale che non subentri un sovraccarico durante la fase di disarmo.\\nRivolgersi ai tecnici DOKA.",
            "hu": "Vegye fel a kapcsolatot a Doka szakemberével.\\nA kizsaluzást a megfelelo módon kell végrehajtani.\\nA 7,5m-t meghaladó födémfesztávoknál a -   szokásos méretezés mellett a kizsaluzás folyamán -\\nfellépo terheket is figyelembe kell venni.\\nAz egyes födémtámaszokra jutó betonterhelést úgy kell csökkenteni,\\nhogy a kizsaluzás során ne lépjen fel túlterhelés.\\nVegye fel a kapcsolatot a Doka szakemberével.",
            "ru": "Передача горизонтальных нагрузок на основание не допускается.\\nСилы распалубливания должны отводиться соответствующими средствами.\\nПри расстояниях между опорами пролетов свыше 7,5 м - в дополнение к стандартным замерам -\\nдолжны учитываться нагрузки в процессе снятия опалубки.\\nПоэтому при планировании нагрузки бетона на стойки для перекрытий должны быть сокращены настолько,\\nчтобы при снятии опалубки не возникло перегрузки.\\nСвяжитесь с инженером компании Doka.",
            "sk": "Do podpery nesmú byt vedené žiadne horizontálne zataženia.\\nOddebnovacie sily sa odvádzajú prostredníctvom stanovených opatrení.\\nPri rozpätiach stropu nad 7,5 m treba -\\ndodatočne k bežn. dimenzovaniu-zohľadniť zaťaženia v procese oddebňovania.\\nZaťaženie betónu pre stropné podpery sa musí preto v projekte redukovať,\\naby v procese oddebňovania nedošlo k pre-  ťaženiu. \\nKontaktujte prosím DOKA technika.",
            "pl": "Obciążenia poziome nie mogą być wprowadzane do elementów położonych niżej.\\nSiły przy rozszalunku należy przykładać przy użyciu odpowiednich środków.\\nPrzy rozpiętości przęsła ponad 7,5 m obciążenia wykraczają poza standardowe i muszą być uwzględnione w procesie rozszalunku.\\nObciążenia od betonu w przypadku podpór muszą być redukowane na etapie projektowania w stopniu\\ntakim aby nie nastąpiło przeciążenie przy rozszalunku.\\nProsimy o kontakt z inżynierem DOKA."
        },
        {
            "ID": "HINWEISTXT",
            "de": "Für weitere Details zum Einsatz der mit diesem Programm geplanten Schalungslösungen und für wichtige Hinweise zur sicheren Anwendung der Schalung beachten Sie die Anwenderinformationen, Betriebs- und Einbauanleitungen des jeweiligen Schalungssystemes bzw. Schalungsteiles.\\nFür die statische Sicherheit und die Einhaltung der arbeitssicherheitstechnischen Vorschriften trägt der Anwender die Verantwortung.\\nSämtliche Bühnenbeläge, Geländer und Abstützungen sind entsprechend den Vorschriften zum Schutze des Lebens und der Gesundheit von Dienstnehmern bei Ausführung von Bauarbeiten, Bauneben- und Bauhilfsarbeiten bauseits auszuführen.\\nJeder mit diesem Programm erstellte Plan ist vom Anwender vor der Freigabe zu überprüfen.",
            "en-gb": "For further details of the use of the planed formworks with this program and for important hints for your security, please note the records of the formwork manufacturer.\\nFor the structural security and the compliance with the work-security-technical directions, the user is responsible for it.\\nAll platform plankings, handrail posts and supportings are to execute by site adequate to the \\nrecords to the protection of life and health from the employee at execution from construction works, construction sideline and construction unskilled workings.\\nEvery created plan with this program is to be checked from the user before release.",
            "en-us": "For further details of the use of the planed formworks with this program and for important hints for your security, please note the records of the formwork manufacturer.\\nFor the structural security and the compliance with the work-security-technical directions, the user is responsible for it.\\nAll platform plankings, handrail posts and supportings are to execute by site adequate to the \″records to the protection of life and health from the employee at execution from construction works, construction sideline and construction unskilled workings.\\nEvery created plan with this program is to be checked from the user before release.",
            "es": "Para más detalles sobre la puesta en servicio de las soluciones de encofrado realizadas con este programa y para obtener indicaciones importantes sobre el uso seguro del encofrado, siga las indicaciones de las informaciones de usuario, de las instrucciones de servicio y de las instrucciones para montaje del respectivo sistema y/o elemento de encofrado.\\nLa responsabilidad sobre la seguridad estática y el cumplimiento de los reglamentos de seguridad laboral recae en el usuario.\\nTodos los recubrimientos de las plataformas, balaustradas y soportes se tienen que realizar en el lugar de la obra según las \″Normas para la protección de la vida y de la salud de los trabajadores al realizar obras, trabajos secundarios o trabajos auxiliares.\\nEl usuario tiene que comprobar cada plano realizado con este programa antes de su autorización.",
            "fr": "Pour plus de détails concernant la mise en oeuvre des solutions de coffrage étudiées dans ce programme et pour obtenir les indications importantes pour l'utilisation du coffrage en sécurité, veuillez vous conformer aux informations à l'attention de l'utilisateur, aux notices d'instruction du système de coffrage et/ou de la pièce de coffrage correspondant.\\nLa responsabilité de la sécurité du calcul statique et du respect des prescriptions techniques de sécurité incombe à l'utilisateur.\\nL'ensemble des platelages, garde-corps et butonnage doivent être réalisés par le chantier conformément aux \″Prescriptions pour la protection de la vie et de la santé des prestataires lors de l'exécution de travaux de\\nconstruction et auxiliaires.\\nTout plan créé à l'aide de ce programme doit être contrôlé par l'utilisateur avant toute validation.",
            "it": "Per ulteriori dettagli sull'impiego delle soluzioni di casseratura realizzate con questo programma, e per importanti indicazioni sull'utilizzo sicuro dei sistemi, osservate quanto indicato nelle Informazioni prodotto, Istruzioni d'uso e Procedure d'impiego del relativo sistema o componente di sistema.\\nL’utente è responsabile della sicurezza statica ed è tenuto ad osservare le norme vigenti in materia di sicurezza tecnica.\\nLa normativa per la protezione della vita e la salvaguardia della salute degli operai addetti ai lavori di costruzione, lavori supplementari ed ausiliari prevede che il cantiere metta a loro disposizione tutti i piani di camminamento, parapetti e ponteggi di protezione.\\nOgni progetto costruito con questo programma deve essere attentamente controllato dall’utente prima della sua attuazione.",
            "hu": "Ezen program megoldásaival tervezett zsaluzatok felhasználásával kapcsolatos további részletek és a zsaluzat biztonságos felhasználásával kapcsolatos fontos útmutatások tekintetében kérjük, vegye figyelembe a zsalurendszernek, ill. a zsalu alkatrészeknek megfelelő alkalmazási utasításokat, használati utasításokat és szerelési utasításokat.\\nA statikai méretezések és biztonságtechnikai eloírások betartása a felhasználó felelossége.\\nValamennyi állvány, korlát és kitámasztás biztonsági eloírásai megfelelnek a munka- és balesetvédelmi követelményeknek.\\nMinden ezzel a programmal készített tervet a felhasználónak kiadás elott ellenoriznie kell!",
            "ru": "Для получения более подробной информации о применении спроектированных при помощи данной программы опалубочных конструкций, а также важных указаний для обеспечения надежной эксплуатации опалубки необходимо соблюдать Руководство пользователя, а также Руководство по эксплуатации и монтажу для соответствующей опалубочной конструкции или элемента опалубки.\\nОтветственность за обеспечение статической надежности и соблюдение эксплуатационно-технических предписаний и требований безопасности несет эксплуатирующая организация.\\nВсе настилы подмостей, поручни и опоры должны быть выполнены со стороны заказчика в соответствии с \″Предписаниями по защите жизни и здоровья рабочих при выполнении строительных и вспомогательных строительных работ\″.\\nКаждый созданный с помощью этой программы проект должен быть проверен пользователем перед применением.",
            "sk": "Pri ďalších detailoch, potrebných pre  aplikáciu riešení debnenia, vyprojektovaných týmto programom a pri dôležitých upozorneniach týkajúcich sa bezpečného  používania debnenia dodržujte návody na montáž a používanie, návody na obsluhu a montážne návody príslušných debniacich systémov, príp. debniacich dielcov.\\nZa statickú bezpečnosť a za dodržiavanie pracovno-bezpečnostno-technických predpisov nesie zodpovednosť odberateľ.  \\nVšetky podlahy plošín, zábradlia a podopretia     sú vykonané zo strany stavby podľa predpisov na ochranu života a zdravia od-  berateľov pri vykonávaní stavebných prác, vedľajších a pomoc-ných stavebných prác.                  \\nKaždý projekt vypracovaný týmto programom je po- trebné pred vydaním skontrolovať.",
            "pl": "Aby uzyskać więcej szczegółów na temat użytkowania szalunków planowanych za pomocą tego programu i wskazówek dotyczących bezpieczeństwa, należy zanotować dane producenta szalunku.\\n zakresie bezpieczeństwa konstrukcji i zgodności z wytycznymi technicznymi dotyczącymi bezpieczeństwa pracy odpowiedzialność ponosi użytkownik.\\nWszystkie poszycia pomostów, słupki poręczy i podparcia pozostają do wykonania po stronie budowy zgodnie z \\nzapisami dotyczącymi ochrony zdrowia i życia opracowanymi przez upoważnionego pracownika zatrudnionego przy pracach konstrukcyjnych, na zapleczu konstrukcji i przy pracach konstrukcyjnych niewymagających kwalifikacji.\\nKażdy plan stworzony za pomocą tego programu powinien zostać sprawdzony przez użytkownika przed realizacją."
        },
        {
            "ID": "HSDOKA",
            "de": "Doka",
            "en-gb": "Doka",
            "en-us": "Doka",
            "es": "Doka",
            "fr": "Doka",
            "it": "Doka",
            "hu": "Doka",
            "ru": "Doka",
            "sk": "Doka",
            "pl": "Doka"
        },
        {
            "ID": "INFOTEXT_FALTBKG2",
            "de": "Es wurden auch Ausgleiche für die Gerüstgruppe 2 (150 kg/m²) nach DIN 4420 verplant.\\nSind höhere Lasten (Gerüstgruppen) notwendig, so müssen die Ausgleichsbreiten\\nentsprechend der Aufbau- und Verwendungsanweisung reduziert werden.\\nSchalungsteile für die Aufhängestellen (wiedergewinnbare und verlorene) werden in der Stückliste nicht berücksichtigt.",
            "en-gb": "Extensions for the scaff. group 2 (150 kg/m²) were also planned by DIN 4420.\\nIf higher loads are necessary (scaff.groups), the closure widths must be reduced\\nadequate to the assembly and utilization instruction.\\nFormwork parts for the suspension points (reusable and lost)\\nare not considered in the piece list.",
            "en-us": "Extensions for the scaff. group 2 (150 kg/m²) were also planned by DIN 4420.\\nIf heigher loads are necessary (scaff.groups), the closure widths must be reduced\\nadequate to the assembly and utilization instruction.\\nCircular walls can be formed with external formwork program(s)\\nare not considered in the piece list.",
            "es": "Se instalaron también compensaciones para el grupo de andamios 2 (150 kg/m²) según DIN 4420.\\nEn caso de que se necesiten cargas mayores (Grupos de andamios), las anchuras de compensación\\ntienen que reducirse según las instrucciones de montaje y utilización.\\nElementos de encofrado para las zonas de anclaje (recuperables y perdidos)\\nno se tienen en cuenta para el listado de piezas.",
            "fr": "Des compensations pour le groupe d'échafaudage 2 (150 kg/m²) ont été étudiées selon 4420.\\nPour des charges plus importantes (Groupes d'échafaudage) les largeurs de compensation doivent\\nêtre réduites conformément aux instructions de montage et de mise en oeuvre.\\nLes pièces de coffrage utilisées pour l'accrochage (réutilisables et perdues)\\nne sont pas prises en compte dans la liste de pièces.",
            "it": "Errore di distribuzione delle compensazioni nel gruppo di ponteggi 2 (150 kg/m²) a norma DIN 4420.\\nSe sono necessari carichi superiori (gruppo di ponteggi), bisogna ridurre le\\nlarghezze di compensazione come da istruzioni d’impiego e montaggio.\\nNella lista pezzi non vengono considerati i particolari di casseratura (riutilizzabili e\\nperdibili) montati nei punti di aggancio.",
            "hu": "Olyan kiegyenlítések is betervezésre kerültek, mellyek a 2-es állványcsoportnak felelnek meg DIN 4420 szerint.\\nAmennyiben nagyobb teherbírásra (állványcsoportra) van szükség, a kiegyenlítéseket\\na szerelési, használati útmutató szerint csökkenteni kell.\\nA függesztési helyekhez szükséges visszanyerheto és elveszo alkatrészeket\\na TIPOS anyagjegyzék nem tartalmazza.",
            "ru": "Также запланированы компенсаторы для группы лесов 2 (150 кг/м²) по DIN 4420.\\nПри необходимости больших нагрузок (группы лесов), ширина компенсаторов\\nдолжна быть уменьшена согласно руководству по монтажу и эксплуатации.\\nДетали опалубки для мест навешивания (много- и одноразовые)\\nв спецификации не учитываются.",
            "sk": "Pre skupinu plošín 2(150 kg/m˛) boli naprojektované aj vyrovnania podľa DIN 4420. \\nAk sú potrebné vyššie zaťaženia (skup. plošín), musia sa šírky vyrovnania\\nredukovať podľa montážneho návodu a návodu na   použitie.\\nDebniace prvky pre závesné miesta (znovu použiteľné a stratené)\\nnie sú v kusovníku zohľadnené.",
            "pl": "Uzupełnienia pomostów grupy 2 (150 kg/m²) zostały zaplanowane według normy DIN 4420.\\nJeśli obciążenie pomostu jest większe (patrz grupy rusztowań), należy zmniejszyć szerokości wypełnień\\nzgodnie z instrukcją montażu i użytkowania.\\n\\nElementy zawieszeń (wielokrotnego użytku i tracone) nie są uwzględniane na liście materiałowej."
        },
        {
            "ID": "INFOTEXT_FALTBKG3",
            "de": "Es wurden auch Ausgleiche für die Gerüstgruppe 3 (200 kg/m²) nach DIN 4420 verplant.\\nSind höhere Lasten (Gerüstgruppen) notwendig, so müssen die Ausgleichsbreiten\\nentsprechend der Aufbau- und Verwendungsanweisung reduziert werden.\\nSchalungsteile für die Aufhängestellen (wiedergewinnbare und verlorene)\\nwerden in der Stückliste nicht berücksichtigt.",
            "en-gb": "Extensions for the scaff. group 3 (200 kg/m²) were also planned by DIN 4420.\\nIf higher loads are necessary (scaff.groups), the closure widths must be reduced \\nadequate to the assembly and utilization instruction.\\nFormwork parts for the suspension points (reusable and lost)\\nare not considered in the piece list.",
            "en-us": "Extensions for the scaff. group 3 (200 kg/m²) were also planned by DIN 4420.\\nIf heigher loads are necessary (scaff.groups), the closure widths must be reduced\\nadequate to the assembly and utilization instruction.\\nCircular walls can be formed with external formwork program(s)\\nare not considered in the piece list.",
            "es": "Se instalaron también compensaciones para el grupo de andamios 3 (200 kg/m²) según DIN 4420.\\nEn caso de que se necesiten cargas mayores (Grupos de andamios), las anchuras de compensación\\ntienen que reducirse según las instrucciones de montaje y utilización.\\nElementos de encofrado para las zonas de anclaje (recuperables y perdidos)\\nno se tienen en cuenta para el listado de piezas",
            "fr": "Les compensations pour le groupe d'échafaudage 3 (200 kg/m²) selon DIN 4420 ont été étudiées.\\nPour des charges plus importantes (Groupes d'échafaudage) les largeurs de compensation doivent\\nêtre réduites conformément aux instructions de montage et de mise en oeuvre.\\nLes pièces de coffrage utilisées pour l'accrochage (réutilisables et perdues)\\nne sont pas prises en compte dans la liste de pièces.",
            "it": "Errore di distribuzione delle compensazioni nel gruppo di ponteggi 3 (200 kg/m²) a norma DIN 4420.\\nSe sono necessari carichi superiori (gruppo di ponteggi), bisogna ridurre le\\nlarghezze di compensazione come da istruzioni d’impiego e montaggio.\\nNella lista pezzi non vengono considerati i particolari di casseratura (riutilizzabili e\\nperdibili) montati nei punti di aggancio.",
            "hu": "Olyan kiegyenlítések is betervezésre kerültek, mellyek a 3-as állványcsoportnak felelnek meg DIN 4420 szerint.\\nAmennyiben nagyobb teherbírásra (állványcsoportra) van szükség, a kiegyenlítéseket\\na szerelési, használati útmutató szerint csökkenteni kell.\\nA függesztési helyekhez szükséges visszanyerheto és elveszo alkatrészeket\\na TIPOS anyagjegyzék nem tartalmazza.",
            "ru": "Также запланированы компенсаторы для группы лесов 3 (200 кг/м²) по DIN 4420.\\nПри необходимости больших нагрузок (группы лесов), ширина компенсаторов\\nдолжна быть уменьшена согласно руководству по монтажу и эксплуатации.\\nДетали опалубки для мест навешивания (много- и одноразовые)\\nв спецификации не учитываются.",
            "sk": "Pre skupinu plošín 3 (200 kg/m˛) boli naprojektované  aj vyrovania podľa DIN 4420.\\nAk sú potrebné vyššie zaťaženia (skup. plošín),  musia sa šírky vyrovnania\\nredukovať podľa montážneho návodu a návodu na použitie.\\nDebniace prvky pre závesné miesta (znovu použiteľné a stratené)\\nnie sú v kusovníku zohľadnené.",
            "pl": "Uzupełnienia pomostów grupy 3 (200 kg/m²) zostały zaplanowane według normy DIN 4420.\\nJeśli obciążenie pomostu jest większe (patrz grupy rusztowań), należy zmniejszyć szerokości wypełnień\\nzgodnie z instrukcją montażu i użytkowania.\\n\\nElementy zawieszeń (wielokrotnego użytku i tracone) nie są uwzględniane na liście materiałowej."
        },
        {
            "ID": "INFOTEXT_FALTBKG4",
            "de": "Es wurden auch Ausgleiche für die Gerüstgruppe 4 (300 kg/m²) nach DIN 4420 verplant.\\nSind höhere Lasten (Gerüstgruppen) notwendig, so müssen die Ausgleichsbreiten\\nentsprechend der Aufbau- und Verwendungsanweisung reduziert werden.\\nAnstatt als Bühne mit Gerüstgruppe 4 ist es auch möglich, Schalung entsprechend\\nAufbau- und Verwendungsanleitung aufzustellen und gleichzeitig die Nutzlast auf 150 kg/m² (Gerüstgruppe 2) zu reduzieren.\\nSchalungsteile für die Aufhängestellen (wiedergewinnbare und verlorene)werden in der Stückliste nicht berücksichtigt.",
            "en-gb": "Extensions for the scaff. group 4 (300 kg/m²) were also planned by DIN 4420.\\nIf higher loads are necessary (scaff.groups), the closure widths must be reduced\\nadequate to the assembly and utilization instruction.\\nInstead of platform with scaff. group 4 it is also possible, to use formwork adequate to\\nthe assembly and utilization instruction and to reduce at the same time the live load\\nto 150 kg/m² (scaff. group 2).\\nFormwork parts for the suspension points (reusable and lost)\\nare not considered in the piece list.",
            "en-us": "Extensions for the scaff. group 4 (300 kg/m²) were also planned by DIN 4420.\\nIf heigher loads are necessary (scaff.groups), the closure widths must be reduced\\nadequate to the assembly and utilization instruction.\\nInstead of platform with scaff. group 4 it is also possible, to set up formwork adequate to\\nassembly and utilization instruction and to reduce at the same the payload\\nto 150 kg/m² (scaff. group 2).\\nFormwork parts for the suspension places (regained and lost)\\nare not considered in the piece list.",
            "es": "Se instalaron también compensaciones para el grupo de andamios 4 (300 kg/m²) según DIN 4420.\\nEn caso de que se necesiten cargas mayores (Grupos de andamios),las anchuras de compensación\\ntienen que reducirse según las instrucciones de montaje y utilización.\\nEn vez de instalar plataforma con grupo de andamios 4 se puede instalar también encofrado\\nsegún instrucciones de montaje y utilización y reducir la carga útil a 150 kg/m² (Grupo de andamios 2).\\nElementos de encofrado para las zonas de anclaje (recuperables y perdidos)\\nno se tienen en cuenta para el listado de piezas.",
            "fr": "Les compensations pour le groupe d'échafaudage 4 (300 kg/m²) selon DIN 4420 ont été étudiées.\\nPour des charges plus importantes (Groupes d'échafaudage les largeurs de compensation doivent\\nêtre réduites conformément aux instructions de montage et de mise en oeuvre.\\nAu lieu des passerelles groupe d'échafaudage 4 il est possible de concevoir le coffrage selon les instructions\\nde montage et de mise en oeuvre et de réduire la charge utile à\\n150 kg/m² (Groupe d'échafaudage 2).\\nLes pièces de coffrage utilisées pour l'accrochage (réutilisables et perdues)\\nne sont pas prises en compte dans la liste de pièces.",
            "it": "Errore di distribuzione delle compensazioni nel gruppo di ponteggi 4 (300 kg/m²) a norma DIN 4420.\\nSe sono necessari carichi superiori (gruppo di ponteggi), bisogna ridurre le\\nlarghezze di compensazione come da istruzioni d’impiego e montaggio.\\nInvece di realizzarlo come un ponte di gruppo 4 è possibile eseguire una casseratura\\ncome descritto nelle istruzioni d’impiego e montaggio e al contempo ridurre il carico\\nutile a 150 kg/m² (gruppo di ponteggio 2).\\nNella lista pezzi non vengono considerati i particolari di casseratura (riutilizzabili e\\nperdibili) montati nei punti di aggancio.",
            "hu": "Olyan kiegyenlítések is betervezésre kerültek, mellyek a 2-es állványcsoportnak felelnek meg DIN 4420 szerint.\\nAmennyiben nagyobb teherbírásra (állványcsoportra) van szükség, a kiegyenlítéseket\\na szerelési, használati útmutató szerint csökkenteni kell.\\nA 4-es állványcsoport szerinti függesztett kialakítás helyett lehetséges, zsaluzat\\nfelállítása a szerelési és használati utasítás szerint a hasznos teher egyideju\\n150 kg/m²-re (2-es állvcs.) való csökkentése mellett.\\nA függesztési helyekhez szükséges visszanyerheto és elveszo alkatrészeket\\na TIPOS anyagjegyzék nem tartalmazza.",
            "ru": "Также запланированы компенсаторы для группы лесов 4 (300 кг/м²) по DIN 4420.\\nПри необходимости больших нагрузок (группы лесов), ширина компенсаторов\\nдолжна быть уменьшена согласно руководству по монтажу и эксплуатации.\\nВместо подмостей с группой лесов 4, можно установить опалубку в соответствии\\nс руководством по сборке и использованию и одновременно сократить полезную нагрузку\\nдо 150 кг/м² (группа лесов 2).\\nДетали опалубки для мест навешивания (много- и одноразовые)\\nв спецификации не учитываются.",
            "sk": "Pre skupinu plošín 4 (300 kg/m˛) boli naprojektované  aj vyrovnania podľa DIN 4420.\\nAk sú potrebné vyššie zaťaženia (skup. plošín),  musia sa šírky vyrovnania\\nredukovať podľa montážneho návodu a návodu na použitie.\\nNamiesto plošín skupiny 4 je možné tiež postaviť debnenie podľa\\nmontážneho návodu a návodu na použitie a súčasne užitočné zaťaženie redukovať\\nna 150 kg/m˛ (skup. plošín 2).\\nDebniace prvky pre závesné miesta (znovu použiteľné a stratené)\\nnie sú v kusovníku zohľadnené.",
            "pl": "Uzupełnienia pomostów grupy 4 (300 kg/m²) zostały zaplanowane według normy DIN 4420.\\nJeśli obciążenie pomostu jest większe (patrz grupy rusztowań), należy zmniejszyć szerokości wypełnień\\nzgodnie z instrukcją montażu i użytkowania.\\namiast pomostu w grupie rusztowań 4 można zastosować szalunek zgodnie z instrukcją montażu i użytkowania i jednocześnie zredukować obciążenie ruchome\\ndo 150 kg/m² (grupa rusztowań 2).\\nElementy zawieszeń (wielokrotnego użytku i tracone) nie są uwzględniane na liście materiałowej."
        },
        {
            "ID": "INFOTEXT_KOBMDIDI",
            "de": "Schalungsteile für die Aufhängestellen (wiedergewinnbare und verlorene)\\nwerden in der Stückliste nicht berücksichtigt.\\nBei Unterschreitung der Wandhöhe (inkl. Decke) von 2,20 m\\nwerden die Bühnen automatisch 1,0 m höher eingehängt.",
            "en-gb": "Formwork parts for the suspension points (reusable and lost)\\nare not considered in the piece list.\\nWhen undercutting the wall height (incl. floor) of 2.20 m\\nthe platforms will be hung up automatically 1.00 m higher.",
            "en-us": "Formwork parts for the suspension places (regained and lost)\\nare not considered in the piece list.\\nWhen undercutting the wall height (incl. floor)of 2.20m\\nthe platforms will be hung up automatically 1.00m higher.",
            "es": "Elementos de encofrado para las zonas de anclaje (recuperables y perdidos)\\nno se tienen en cuenta para el listado de piezas.\\nEn caso de ser inferior la altura del muro (icluido forjado) de 2,20m\\nse colgaran automáticamente las plataformas 1,0m mas altas.",
            "fr": "Les pièces de coffrage utilisées pour l'accrochage (réutilisables et perdues)\\nne sont pas prises en compte dans la liste de pièces.\\nSi la hauteur de voile (dalle incluse) de 2,20 m est dépassée,\\nles passerelles sont automatiquement accrochées 1,0 m plus haut.",
            "it": "Nella lista pezzi non vengono considerati i particolari di casseratura (riutilizzabili e\\nperdibili) montati nei punti di aggancio.\\nPer pareti con un’ altezza (incl. lo spessore del solaio) di 2,20m,\\ni piani di lavoro vengono automaticamente posizionati 1,0m più in alto.",
            "hu": "A függesztési helyekhez szükséges visszanyerheto és elveszo alkatrészeket\\na TIPOS anyagjegyzék nem tartalmazza.\\nHa a falmagasság (födémmel együtt) nem éri el a 2,20m-t,\\naz állványok automatikusan 1,0m-el magasabbra kerülnek.",
            "ru": "Детали опалубки для мест навешивания (много- и одноразовые)\\nв спецификации не учитываются.\\nЕсли высота стены (включая потолок) будет меньше 2,20 м,\\nподмости автоматически навешиваются на 1,0 м выше.",
            "sk": "Debniace prvky pre závesné miesta (znovu použiteľné a stratené)\\nnie sú v kusovníku zohľadnené.\\nPri výške steny (vrátane stropu) menšej ako 2,20m\\nsa plošiny zavesia automaticky o 1,0m vyššie.",
            "pl": "Elementy zawieszeń (wielokrotnego użytku i tracone)\\nnie są uwzględnione na liście materiałowej.\\nW przypadku zmniejszania wysokości ściany poniżej 2,20m (razem z podłożem)\\npomosty zostaną zawieszone automatycznie o 1,00m wyżej."
        },
        {
            "ID": "INFOTEXT_KOBMMAXA",
            "de": "Schalungsteile für die Aufhängestellen (wiedergewinnbare und verlorene)\\nwerden in der Stückliste nicht berücksichtigt.\\nBei Unterschreitung der Wandhöhe (inkl. Decke) von 2,20 m\\nwerden die Bühnen automatisch 1,0 m höher eingehängt.",
            "en-gb": "Formwork parts for the suspension points (reusable and lost)\\nare not considered in the piece list.\\nWhen undercutting the wall height (incl. floor) of 2.20 m\\nthe platforms will be hung up automatically 1.00 m higher.",
            "en-us": "Formwork parts for the suspension places (regained and lost)\\nare not considered in the piece list.\\nWhen undercutting the wall height (incl. floor) of 2.20m\\nthe platforms will be hung up automatically 1.00m higher.",
            "es": "Elementos de encofrado para las zonas de anclaje (recuperables y perdidos)\\nno se tienen en cuenta para el listado de piezas.\\nEn caso de ser inferior la altura del muro (icluido forjado) de 2,20m\\nse colgaran automáticamente las plataformas 1,0m mas altas.",
            "fr": "Les pièces de coffrage utilisées pour l'accrochage (réutilisables et perdues)\\nne sont pas prises en compte dans la liste de pièces.\\nSi la hauteur de voile (dalle incluse) de 2,20 m est dépassée,\\nles passerelles sont automatiquement accrochées 1,0 m plus haut.",
            "it": "Nella lista pezzi non vengono considerati i particolari di casseratura (riutilizzabili e\\nperdibili) montati nei punti di aggancio.\\nPer pareti con un’ altezza (incl. lo spessore del solaio) di 2,20m,\\ni piani di lavoro vengono automaticamente posizionati 1,0m più in alto.",
            "hu": "A függesztési helyekhez szükséges visszanyerheto és elveszo alkatrészeket\\na TIPOS anyagjegyzék nem tartalmazza.\\nHa a falmagasság (födémmel együtt) nem éri el a 2,20m-t,\\naz állványok automatikusan 1,0m-el magasabbra kerülnek.",
            "ru": "Детали опалубки для мест навешивания (много- и одноразовые)\\nв спецификации не учитываются.\\nЕсли высота стены (включая потолок) будет меньше 2,20 м,\\nподмости автоматически навешиваются на 1,0 м выше.",
            "sk": "Debniace prvky pre závesné miesta  (znovu použiteľné a stratené)\\nnie sú v kusovníku zohľadnené.\\nPri výške steny (vrátane stropu) menšej ako 2,20m\\nsa plošiny zavesia automaticky o 1,0m vyššie.",
            "pl": "Elementy zawieszeń (wielokrotnego użytku i tracone)\\nnie są uwzględnione na liście materiałowej.\\nW przypadku zmniejszania wysokości ściany poniżej 2,20 m (razem z podłożem)\\npomosty zostaną zawieszone automatycznie o 1,00 m wyżej."
        },
        {
            "ID": "INFOTEXT_SEITENXP",
            "de": "Schalungsteile für die Aufhängestellen (wiedergewinnbare und verlorene) \\nwerden in der Stückliste nicht berücksichtigt.",
            "en-gb": "Formwork parts for the suspension points (reusable and lost)\\nare not considered in the piece list.",
            "en-us": "Formwork parts for the suspension places (regained and lost)\\nare not considered in the piece list.",
            "es": "Elementos de encofrado para las zonas de anclaje (recuperables y perdidos)\\nno se tienen en cuenta para el listado de piezas.",
            "fr": "Les pièces de coffrage utilisées pour l'accrochage (réutilisables et perdues)\\nne sont pas prises en compte dans la liste de pièces.",
            "it": "Nella lista pezzi non vengono considerati i particolari di casseratura (riutilizzabili e\\nperdibili) montati nei punti di aggancio.",
            "hu": "A függesztési helyekhez szükséges visszanyerheto és elveszo alkatrészeket\\na TIPOS anyagjegyzék nem tartalmazza.",
            "ru": "Детали опалубки для мест навешивания (много- и одноразовые)\\nв спецификации не учитываются.",
            "sk": "Debniace prvky pre závesné miesta  (znovu použiteľné a stratené)\\nnie sú v kusovníku zohľadnené.",
            "pl": "Elementy zawieszeń (wielokrotnego użytku i tracone)\\nnie są uwzględnione na liście materiałowej.\\n"
        },
        {
            "ID": "KOBMDIDI_A1",
            "de": "Einbauart",
            "en-gb": "Type of installation",
            "en-us": "Type of installation",
            "es": "Tipo montaje",
            "fr": "Type d'installation",
            "it": "Tipo di montaggio",
            "hu": "Beépítési mód",
            "ru": "Тип установки",
            "sk": "Typ zabudovania",
            "pl": "Rodzaj instalacji"
        },
        {
            "ID": "KOBMDIDI_A1_AW1",
            "de": "Bühne Standardeinbau",
            "en-gb": "Platform stand. fitting",
            "en-us": "Platform stand. fitting",
            "es": "Plataf. instal. estándar",
            "fr": "Passerelle config. standard",
            "it": "Mensola construzione standard",
            "hu": "Állvány alapbeépítés",
            "ru": "Подмости стандартная установка",
            "sk": "Ploš. štand. osadenie",
            "pl": "Pomost standardowy"
        },
        {
            "ID": "KOBMDIDI_A1_AW2",
            "de": "Bühne mit Abstützverlängerung",
            "en-gb": "Platform with lengthening piece",
            "en-us": "Platform with lengthening piece",
            "es": "Plataf. con alargador",
            "fr": "Passerelle avec rallonge d'étrésillon",
            "it": "Mensola con prolunga puntellazione",
            "hu": "Állvány támaszhosszabbítás",
            "ru": "Подмости с удлинением опоры",
            "sk": "Ploš. s predĺž. opornej kozy",
            "pl": "Pomost z elementem przedłużającym"
        },
        {
            "ID": "KOBMDIDI_A1_AW3",
            "de": "Bühne höhenversetzt 1,00 m",
            "en-gb": "Platform height-shifted 1.00 m",
            "en-us": "Platform height-shifted 1.00m",
            "es": "Plataf. desplazados en alt. 1,00m",
            "fr": "Passerelle en hauteur  1,00m",
            "it": "Mensola sfalsata in altezza 1,00m",
            "hu": "Állvány mag.eltolva 1,00m",
            "ru": "Подмости со смещением по высоте 1,00 м",
            "sk": "Ploš. výškovoosadit. 1,00m",
            "pl": "Pomost z przesunięciem wysokości 1 m"
        },
        {
            "ID": "KOBMDIDI_A1_AW4",
            "de": "Bühne höhenversetzt mit Abstützverlängerung",
            "en-gb": "Platform height-shifted with lengthening piece",
            "en-us": "Platform height-shifted with lengthening piece",
            "es": "Plataf. desplazados en alt. con alargador",
            "fr": "Pass. en hauteur avec rallonge d'étrésillon",
            "it": "Mensola sfalsata in altezza con prolunga puntellazione",
            "hu": "Állvány mag.eltolva támaszhosszabbítás",
            "ru": "Подмости со смещением по высоте, с удлинением опоры",
            "sk": "Ploš. výškovoosadit. s predĺž. opornej kozy",
            "pl": "Pomost z przesunięciem wysokości z elementem przedłużającym"
        },
        {
            "ID": "KOBMDIDI_A2",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "KOBMDIDI_A2_AW1",
            "de": "Eckbühne M",
            "en-gb": "Corner platform M",
            "en-us": "Corner platform M",
            "es": "Plataf. de esquina M",
            "fr": "Passerelle d'angle M",
            "it": "Ponte angolare M",
            "hu": "Sarokállv. M",
            "ru": "Угловые подмости M",
            "sk": "Roh. plošiny M",
            "pl": "Narożny pomost M"
        },
        {
            "ID": "KOBMDIDI_A2_AW2",
            "de": "Eckübergang",
            "en-gb": "Corner crossing",
            "en-us": "Corner crossing",
            "es": "Paso de esquina",
            "fr": "Passage d'angle",
            "it": "Raccordo angolare",
            "hu": "Sarokáthidalás",
            "ru": "Угловой переход",
            "sk": "Roh. prechodu ploš.",
            "pl": "Przejście narożne"
        },
        {
            "ID": "KOBMDIDI_A2_AW3",
            "de": "Bohlen",
            "en-gb": "Planks",
            "en-us": "Planks",
            "es": "Tablónes",
            "fr": "Planches",
            "it": "Tavoloni",
            "hu": "Palló",
            "ru": "Брусья",
            "sk": "Fošne",
            "pl": "Deski"
        },
        {
            "ID": "KOBMDIDI_A3_AW1",
            "de": "Eckbühne M",
            "en-gb": "Corner platform M",
            "en-us": "Corner platform M",
            "es": "Plataf. de esquina M",
            "fr": "Passerelle d'angle M",
            "it": "Ponte angolare M",
            "hu": "Sarokállv. M",
            "ru": "Угловые подмости M",
            "sk": "Roh. plošiny M",
            "pl": "Narożny pomost M"
        },
        {
            "ID": "KOBMDIDI_A3_AW2",
            "de": "Eckübergang",
            "en-gb": "Corner crossing",
            "en-us": "Corner crossing",
            "es": "Paso de esquina",
            "fr": "Passage d'angle",
            "it": "Raccordo angolare",
            "hu": "Sarokáthidalás",
            "ru": "Угловой переход",
            "sk": "Roh. prechodu ploš.",
            "pl": "Przejście narożne"
        },
        {
            "ID": "KOBMDIDI_A3_AW3",
            "de": "Bohlen",
            "en-gb": "Planks",
            "en-us": "Planks",
            "es": "Tablónes",
            "fr": "Planches",
            "it": "Tavoloni",
            "hu": "Palló",
            "ru": "Брусья",
            "sk": "Fošne",
            "pl": "Deski"
        },
        {
            "ID": "KOBMMAXA_A1",
            "de": "Einbauart",
            "en-gb": "Type of installation",
            "en-us": "Type of installation",
            "es": "Tipo montaje",
            "fr": "Type d'installation",
            "it": "Tipo di montaggio",
            "hu": "Beépítési mód",
            "ru": "Тип установки",
            "sk": "Typ zabudovania",
            "pl": "Rodzaj instalacji"
        },
        {
            "ID": "KOBMMAXA_A1_AW1",
            "de": "Bühne Standardeinbau",
            "en-gb": "Platform stand. fitting",
            "en-us": "Platform stand. fitting",
            "es": "Plataf. instal. estándar",
            "fr": "Passerelle config. standard",
            "it": "Mensola costruzione standard",
            "hu": "Állvány alapbeépítés",
            "ru": "Подмости стандартная установка",
            "sk": "Ploš. štand. osadenie",
            "pl": "Pomost standardowy"
        },
        {
            "ID": "KOBMMAXA_A1_AW2",
            "de": "Bühne mit Abstützverlängerung V",
            "en-gb": "Platform with lengthening piece V",
            "en-us": "Platform with lengthening pieceV",
            "es": "Plataf. con alargadorV",
            "fr": "Passerelle mit rallonge d'étrésillonV",
            "it": "Mensola con prolunga puntellazioneV",
            "hu": "Állvány TámaszhosszabbításV",
            "ru": "Подмости с удлинением опорыV",
            "sk": "Ploš. s predĺž. opornej kozy V",
            "pl": "Pomost z elementem przedłużającym V"
        },
        {
            "ID": "KOBMMAXA_A1_AW3",
            "de": "Bühne höhenversetzt 1,00 m H",
            "en-gb": "Platform height-shifted 1.00 m H",
            "en-us": "Platform height-shifted 1.00 m H",
            "es": "Plataf. desplazados en alt. 1,00mH",
            "fr": "Passerelle en hauteur  1,00mH",
            "it": "Mensola sfalsata in altezza 1,00mH",
            "hu": "Állvány mag.eltolva 1,00mH",
            "ru": "Подмости со смещением по высоте 1,00 мH",
            "sk": "Ploš. výškovoosadit. 1,00m H",
            "pl": "Pomost z przesunięciem wysokości 1 m H"
        },
        {
            "ID": "KOBMMAXA_A1_AW4",
            "de": "Bühne höhenversetzt mit Abstützverlängerung HV",
            "en-gb": "Platform height-shifted with lengthening piece HV",
            "en-us": "Platform height-shifted with lengthening pieceHV",
            "es": "Plataf. desplazados en alt. con alargadorHV",
            "fr": "Pass. en hauteur avec rallonge d'étrésillonHV",
            "it": "Mensola sfalsata in altezza con prolunga puntellazioneHV",
            "hu": "Állvány mag.eltolva TámaszhosszabbításHV",
            "ru": "Подмости со смещением по высоте, с удлинением опорыHV",
            "sk": "Ploš. výškovoosadit. s predĺž. opornej kozy HV",
            "pl": "Pomost z przesunięciem wysokości z elementem przedłużającym HV"
        },
        {
            "ID": "KOBMMAXA_A2",
            "de": "mit oder ohne Bühnenbelag 3,00 m",
            "en-gb": "with or without platform decking 3.00m",
            "en-us": "with or without platform decking 3.00m",
            "es": "Con o sin recubrimiento plataforma 3,0m",
            "fr": "avec ou sans platelage 3,0m",
            "it": "con o senza piattaforma per ponti 3,00m",
            "hu": "Állványbetéttel vagy anélkül 3,0m",
            "ru": "с настилом подмостей 3,0 м или без",
            "sk": "s alebo bez podlaha ploš. 3,0m",
            "pl": "z poszyciem pomostu 3,00m lub bez niego"
        },
        {
            "ID": "KOBMMAXA_A2_AW1",
            "de": "mit Bühnenbelag",
            "en-gb": "with platform decking",
            "en-us": "with platform decking",
            "es": "Con recubrimiento platforma",
            "fr": "avec platelage",
            "it": "con piattaforma per ponti",
            "hu": "állványbetétettel",
            "ru": "с настилом подмостей",
            "sk": "s podlaha ploš.",
            "pl": "z poszyciem pomostu"
        },
        {
            "ID": "KOBMMAXA_A2_AW2",
            "de": "ohne Bühnenbelag",
            "en-gb": "without platform decking",
            "en-us": "without platform decking",
            "es": "Sin recubrimiento platforma",
            "fr": "sans platelage",
            "it": "senza piattaforma per ponti",
            "hu": "állványbetét nélkül",
            "ru": "без настила подмостей",
            "sk": "bez podlaha ploš.",
            "pl": "bez poszycia pomostu"
        },
        {
            "ID": "KOBMMAXA_A3",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "LAYCAPBZ01",
            "de": "Bez für Stempel 1",
            "en-gb": "Design. for stamp 1",
            "en-us": "Design. for stamp 1",
            "es": "Rel para Cajetín 1",
            "fr": "Dés pour cartouche 1",
            "it": "Denom.per timbro 1",
            "hu": "kapcs. az 1-es pecséthez",
            "ru": "Обозн. штампа 1",
            "sk": "ozn.pre pečiat. 1",
            "pl": "Projekt pieczęci 1"
        },
        {
            "ID": "LAYCAPBZ02",
            "de": "Bez für Stempel 2",
            "en-gb": "Design. for stamp 2",
            "en-us": "Design. for stamp 2",
            "es": "Rel para Cajetín 2",
            "fr": "Dés pour cartouche 2",
            "it": "Denom.per timbro 2",
            "hu": "kapcs. a 2-es pecséthez",
            "ru": "Обозн. штампа 2",
            "sk": "ozn.pre pečiat. 2",
            "pl": "Projekt pieczęci 2"
        },
        {
            "ID": "LAYCAPBZ03",
            "de": "Bez für Stempel 3",
            "en-gb": "Design. for stamp 3",
            "en-us": "Design. for stamp 3",
            "es": "Rel para Cajetín 3",
            "fr": "Dés pour cartouche 3",
            "it": "Denom.per timbro 3",
            "hu": "kapcs. a 3-as pecséthez",
            "ru": "Обозн. штампа 3",
            "sk": "ozn.pre pečiat. 3",
            "pl": "Projekt pieczęci 3"
        },
        {
            "ID": "LAYCAPBZ04",
            "de": "Bez für Stempel 4",
            "en-gb": "Design. for stamp 4",
            "en-us": "Design. for stamp 4",
            "es": "Rel para Cajetín 4",
            "fr": "Dés pour cartouche 4",
            "it": "Denom.per timbro 4",
            "hu": "kapcs. a 4-es pecséthez",
            "ru": "Обозн. штампа 4",
            "sk": "ozn.pre pečiat. 4",
            "pl": "Projekt pieczęci 4"
        },
        {
            "ID": "LAYCAPBZ05",
            "de": "Bez für Stempel 5",
            "en-gb": "Design. for stamp 5",
            "en-us": "Design. for stamp 5",
            "es": "Rel para Cajetín 5",
            "fr": "Dés pour cartouche 5",
            "it": "Denom.per timbro 5",
            "hu": "kapcs. az 5-ös pecséthez",
            "ru": "Обозн. штампа 5",
            "sk": "ozn.pre pečiat. 5",
            "pl": "Projekt pieczęci 5"
        },
        {
            "ID": "LAYCAPBZ06",
            "de": "Bez für Stempel 6",
            "en-gb": "Design. for stamp 6",
            "en-us": "Design. for stamp 6",
            "es": "Rel para Cajetín 6",
            "fr": "Dés pour cartouche 6",
            "it": "Denom.per timbro 6",
            "hu": "kapcs. a 6-os pecséthez",
            "ru": "Обозн. штампа 6",
            "sk": "ozn.pre pečiat. 6",
            "pl": "Projekt pieczęci 6"
        },
        {
            "ID": "LAYCAPBZ07",
            "de": "Bez für Stempel 7",
            "en-gb": "Design. for stamp 7",
            "en-us": "Design. for stamp 7",
            "es": "Rel para Cajetín 7",
            "fr": "Dés pour cartouche 7",
            "it": "Denom.per timbro 7",
            "hu": "kapcs. a 7-es pecséthez",
            "ru": "Обозн. штампа 7",
            "sk": "ozn.pre pečiat. 7",
            "pl": "Projekt pieczęci 7"
        },
        {
            "ID": "LAYCAPBZ08",
            "de": "Bez für Stempel 8",
            "en-gb": "Design. for stamp 8",
            "en-us": "Design. for stamp 8",
            "es": "Rel para Cajetín 8",
            "fr": "Dés pour cartouche 8",
            "it": "Denom.per timbro 8",
            "hu": "kapcs. a 8-as pecséthez",
            "ru": "Обозн. штампа 8",
            "sk": "ozn.pre pečiat. 8",
            "pl": "Projekt pieczęci 8"
        },
        {
            "ID": "LAYCAPBZ09",
            "de": "Bez für Stempel 9",
            "en-gb": "Design. for stamp 9",
            "en-us": "Design. for stamp 9",
            "es": "Rel para Cajetín 9",
            "fr": "Dés pour cartouche 9",
            "it": "Denom.per timbro 9",
            "hu": "kapcs. a 9-es pecséthez",
            "ru": "Обозн. штампа 9",
            "sk": "ozn.pre pečiat. 9",
            "pl": "Projekt pieczęci 9"
        },
        {
            "ID": "LAYFRMBZ01",
            "de": "Rahmen A4 liegend",
            "en-gb": "Frame A4 horiz.",
            "en-us": "Frame A4 horiz.",
            "es": "Marco A4 horizontal",
            "fr": "Cadre A4 italienne",
            "it": "Telaio A4 orizz.",
            "hu": "Fekvõ keret A4",
            "ru": "Рамка А4 альбомная",
            "sk": "formát A4 nalež.",
            "pl": "Rama A4 pozioma"
        },
        {
            "ID": "LAYFRMBZ02",
            "de": "Rahmen A3 liegend",
            "en-gb": "Frame A3 horiz.",
            "en-us": "Frame A3 horiz.",
            "es": "Marco A3 horizontal",
            "fr": "Cadre A3 italienne",
            "it": "Telaio A3 orizz.",
            "hu": "Fekvõ keret A3",
            "ru": "Рамка А3 альбомная",
            "sk": "formát A3 nalež.",
            "pl": "Rama A3 pozioma"
        },
        {
            "ID": "LAYFRMBZ03",
            "de": "Rahmen A2 liegend",
            "en-gb": "Frame A2 horiz.",
            "en-us": "Frame A2 horiz.",
            "es": "Marco A2 horizontal",
            "fr": "Cadre A2 italienne",
            "it": "Telaio A2 orizz.",
            "hu": "Fekvõ keret A2",
            "ru": "Рамка А2 альбомная",
            "sk": "formát A2 nalež.",
            "pl": "Rama A2 pozioma"
        },
        {
            "ID": "LAYFRMBZ04",
            "de": "Rahmen A1 liegend",
            "en-gb": "Frame A1 horiz.",
            "en-us": "Frame A1 horiz.",
            "es": "Marco A1 horizontal",
            "fr": "Cadre A1 italienne",
            "it": "Telaio A1 orizz.",
            "hu": "Fekvõ keret A1",
            "ru": "Рамка А1 альбомная",
            "sk": "formát A1 nalež.",
            "pl": "Rama A1 pozioma"
        },
        {
            "ID": "LAYFRMBZ05",
            "de": "Rahmen A0 liegend",
            "en-gb": "Frame A0 horiz.",
            "en-us": "Frame A0 horiz.",
            "es": "Marco A0 horizontal",
            "fr": "Cadre A0 italienne",
            "it": "Telaio A0 orizz.",
            "hu": "Fekvõ keret A0",
            "ru": "Рамка 03 альбомная",
            "sk": "formát A0 nalež.",
            "pl": "Rama A0 pozioma"
        },
        {
            "ID": "LAYLOGBZ01",
            "de": "Bez für Logo 1",
            "en-gb": "Design. for logo 1",
            "en-us": "Design. for logo 1",
            "es": "Rel para Logo 1",
            "fr": "Dés pour Logo 1",
            "it": "Denom.per Logo 1",
            "hu": "kapcs. a Logo 1-es",
            "ru": "Обозн. логотипа 1",
            "sk": "ozn.pre logo 1",
            "pl": "Projekt logo 1"
        },
        {
            "ID": "LAYLOGBZ02",
            "de": "Bez für Logo 2",
            "en-gb": "Design. for logo 2",
            "en-us": "Design. for logo 2",
            "es": "Rel para Logo 2",
            "fr": "Dés pour Logo 2",
            "it": "Denom.per Logo 2",
            "hu": "kapcs. a Logo 2-es",
            "ru": "Обозн. логотипа 2",
            "sk": "ozn.pre logo 2",
            "pl": "Projekt logo 2"
        },
        {
            "ID": "LAYLOGBZ03",
            "de": "Bez für Logo 3",
            "en-gb": "Design. for logo 3",
            "en-us": "Design. for logo 3",
            "es": "Rel para Logo 3",
            "fr": "Dés pour Logo 3",
            "it": "Denom.per Logo 3",
            "hu": "kapcs. a Logo 3-as",
            "ru": "Обозн. логотипа 3",
            "sk": "ozn.pre logo 3",
            "pl": "Projekt logo 3"
        },
        {
            "ID": "LAYLOGBZ04",
            "de": "Bez für Logo 4",
            "en-gb": "Design. for logo 4",
            "en-us": "Design. for logo 4",
            "es": "Rel para Logo 4",
            "fr": "Dés pour Logo 4",
            "it": "Denom.per Logo 4",
            "hu": "kapcs. a Logo 4-es",
            "ru": "Обозн. логотипа 4",
            "sk": "ozn.pre logo 4",
            "pl": "Projekt logo 4"
        },
        {
            "ID": "LAYLOGBZ05",
            "de": "Bez für Logo 5",
            "en-gb": "Design. for logo 5",
            "en-us": "Design. for logo 5",
            "es": "Rel para Logo 5",
            "fr": "Dés pour Logo 5",
            "it": "Denom.per Logo 5",
            "hu": "kapcs. a Logo 5-ös",
            "ru": "Обозн. логотипа 5",
            "sk": "ozn.pre logo 5",
            "pl": "Projekt logo 5"
        },
        {
            "ID": "LOADBEARTW",
            "de": "Traggerüst",
            "en-gb": "Load-bearing tower",
            "en-us": "Load-bearing tower",
            "es": "Cimbra",
            "fr": "Étaiement",
            "it": "Puntellazioni",
            "hu": "Alátámasztó állvány",
            "ru": "Опорные леса",
            "sk": "Podperné rámy",
            "pl": "Wieże podporowe"
        },
        {
            "ID": "PILASTER",
            "de": "Pilaster",
            "en-gb": "Pilaster",
            "en-us": "Pilaster",
            "es": "Pilaster",
            "fr": "Pilaster",
            "it": "Pilaster",
            "hu": "Pilaster",
            "ru": "Пилястр",
            "sk": "Pilaster",
            "pl": "Pilaster"
        },
        {
            "ID": "RWFF100TEC",
            "de": "Rundwände können in diesem Schalsystem nicht geschalt werden.\\nExternes Schalprogramme für Rundschalung mit Framax Bogenblech\\nsteht in den Schalsystemen Framax und Alu-Framax zur Verfügung.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "en-us": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "es": "Muros circulares no se pueden encofrar con este sistema de encofrado.\\nUn programa exterior para encofrado circular con chapa curvada Framax\\nse encuentra en los sistemas Framax y Alu-Framax.\\nActivación en Encofrado / Pie&zas sueltas a mano /...en el sistema de encofrado correspondiente /...",
            "fr": "Ce système de coffrage ne permet pas de coffrer les voiles circulaires.\\nLe programme de coffrage externe pour coffrage courbe avec tôles de courbure Framax\\nse trouve dans les systèmes de coffrage Framax et Alu-Framax.\\nDans  Coffrage / Pièces individuelles manuel / ...dans le système de coffrage correspondant /...",
            "it": "Pareti circolari non possono essere progettate con questo sistema.\\nNel sistema Framax ed Alu-Framax, con le lamiere circolari Framax,\\navete a disposizione un programma esterno per la progettazione di pareti circolari.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves falak ezzel a zsalurendszerrel nem zsaluzhatók.\\nKülsõ zsaluprogramm íves falzsaluzáshoz Framax hajlítható lemezzel\\na Framax és Alu-Framax zsalurendszerkben található.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены не могут быть опалублены в данной опалубочной системе.\\nВнешние опалубочные программы для круглой опалубки с дугообразующими листами Framax\\nдоступны в опалубочных системах Framax и Alu-Framax.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / ...",
            "sk": "Kruhové steny nemôžu byť zadebnené v tomto debniacom  syst.\\nExter. debn. program pre kruh. debn. s Framax oblúk. plech.\\nje k dispozícii v debniacich systémoch Framax a Alu-Framax.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme /...",
            "pl": "Ten system nie nadaje się do wykonania ściany łukowej.\\nAsystent do projektowania tego typu ścian przy użyciu blach łukowych Framax jest dostępny w systemach szalunku Framax i Alu-Framax.\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "RWFF20_21",
            "de": "Rundwände können in diesem Schalsystem nicht geschalt werden.\\nExternes Schalprogramme für Rundschalung mit Framax Bogenblech\\nsteht in den Schalsystemen Framax und Alu-Framax zur Verfügung.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "en-us": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "es": "Muros circulares no se pueden encofrar con este sistema de encofrado.\\nUn programa exterior para encofrado circular con chapa curvada Framax\\nse encuentra en los sistemas Framax y Alu-Framax.\\nActivación en Encofrado / Pie&zas sueltas a mano /...en el sistema de encofrado correspondiente /...",
            "fr": "Ce système de coffrage ne permet pas de coffrer les voiles circulaires.\\nLe programme de coffrage externe pour coffrage courbe avec tôles de courbure Framax\\nse trouve dans les systèmes de coffrage Framax et Alu-Framax.\\nDans  Coffrage / Pièces individuelles manuel / ...dans le système de coffrage correspondant /...",
            "it": "Pareti circolari non possono essere progettate con questo sistema.\\nNel sistema Framax ed Alu-Framax, con le lamiere circolari Framax,\\navete a disposizione un programma esterno per la progettazione di pareti circolari.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves falak ezzel a zsalurendszerrel nem zsaluzhatók.\\nKülsõ zsaluprogramm íves falzsaluzáshoz Framax hajlítható lemezzel\\na Framax és Alu-Framax zsalurendszerkben található.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены не могут быть опалублены в данной опалубочной системе.\\nВнешние опалубочные программы для круглой опалубки с дугообразующими листами Framax\\nдоступны в опалубочных системах Framax и Alu-Framax.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / ...",
            "sk": "Kruhové steny nemôžu byť zadebnené v tomto debniacom  syst.\\nExter. debn. program pre kruh. debn. s Framax oblúk. plech.\\nje k dispozícii v debniacich systémoch Framax a Alu-Framax.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme ",
            "pl": "Ten system nie nadaje się do wykonania ściany łukowej.\\nAsystent do projektowania tego typu ścian przy użyciu blach łukowych Framax jest dostępny w systemach szalunku Framax i Alu-Framax.\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "RWFF20_27",
            "de": "Rundwände können in diesem Schalsystem nicht geschalt werden.\\nExternes Schalprogramme für Rundschalung mit Framax Bogenblech\\nsteht in den Schalsystemen Framax und Alu-Framax zur Verfügung.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "en-us": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "es": "Muros circulares no se pueden encofrar con este sistema de encofrado.\\nUn programa exterior para encofrado circular con chapa curvada Framax\\nse encuentra en los sistemas Framax y Alu-Framax.\\nActivación en Encofrado / Piezas sueltas a mano /...en el sistema de encofrado correspondiente /...",
            "fr": "Ce système de coffrage ne permet pas de coffrer les voiles circulaires.\\nLe programme de coffrage externe pour coffrage courbe avec tôles de courbure Framax\\nse trouve dans les systèmes de coffrage Framax et Alu-Framax.\\nDans  Coffrage / Pièces individuelles manuel / ...dans le système de coffrage correspondant /...",
            "it": "Pareti circolari non possono essere progettate con questo sistema.\\nNel sistema Framax ed Alu-Framax, con le lamiere circolari Framax,\\navete a disposizione un programma esterno per la progettazione di pareti circolari.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves falak ezzel a zsalurendszerrel nem zsaluzhatók.\\nKülsõ zsaluprogramm íves falzsaluzáshoz Framax hajlítható lemezzel\\na Framax és Alu-Framax zsalurendszerkben található.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены не могут быть опалублены в данной опалубочной системе.\\nВнешние опалубочные программы для круглой опалубки с дугообразующими листами Framax\\nдоступны в опалубочных системах Framax и Alu-Framax.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / ...",
            "sk": "Kruhové steny nemôžu byť zadebnené v tomto debniacom  syst.\\nExter. debn. program pre kruh. debn. s Framax oblúk. plech.\\nje k dispozícii v debniacich systémoch Framax a Alu-Framax.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme",
            "pl": "Ten system nie nadaje się do wykonania ściany łukowej.\\nAsystent do projektowania tego typu ścian przy użyciu blach łukowych Framax jest dostępny w systemach szalunku Framax i Alu-Framax.\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "RWFL20",
            "de": "Rundwände können in diesem Schalsystem nicht geschalt werden.\\nExternes Schalprogramme für Rundschalung mit Framax Bogenblech\\nsteht in den Schalsystemen Framax und Alu-Framax zur Verfügung.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "en-us": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "es": "Muros circulares no se pueden encofrar con este sistema de encofrado.\\nUn programa exterior para encofrado circular con chapa curvada Framax\\nse encuentra en los sistemas Framax y Alu-Framax.\\nActivación en Encofrado / Piezas sueltas a mano /...en el sistema de encofrado correspondiente /...",
            "fr": "Ce système de coffrage ne permet pas de coffrer les voiles circulaires.\\nLe programme de coffrage externe pour coffrage courbe avec tôles de courbure Framax\\nse trouve dans les systèmes de coffrage Framax et Alu-Framax.",
            "it": "Pareti circolari non possono essere progettate con questo sistema.\\nNel sistema Framax ed Alu-Framax, con le lamiere circolari Framax\\navete a disposizione un programma esterno per la progettazione di pareti circolari.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves falak ezzel a zsalurendszerrel nem zsaluzhatók.\\nKülsõ zsaluprogramm íves falzsaluzáshoz Framax hajlítható lemezzel\\na Framax és Alu-Framax zsalurendszerkben található.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены не могут быть опалублены в данной опалубочной системе.\\nВнешние опалубочные программы для круглой опалубки с дугообразующими листами Framax\\nдоступны в опалубочных системах Framax и Alu-Framax.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / ...",
            "sk": "Kruhové steny nemôžu byť zadebnené v tomto debniacom syst.\\nExter. debn. program pre kruh. debn. s Framax oblúk. plech.\\nje k dispozícii v debniacich systémoch Framax a Alu-Framax.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme",
            "pl": "Ten system nie nadaje się do wykonania ściany łukowej.\\nAsystent do projektowania tego typu ścian przy użyciu blach łukowych Framax jest dostępny w systemach szalunku Framax i Alu-Framax.\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "RWFRAMECO",
            "de": "Rundwände können in diesem Schalsystem nicht geschalt werden.\\nExternes Schalprogramme für Rundschalung mit Framax Bogenblech\\nsteht in den Schalsystemen Framax und Alu-Framax zur Verfügung.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "en-us": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "es": "Muros circulares no se pueden encofrar con este sistema de encofrado.\\nUn programa exterior para encofrado circular con chapa curvada Framax\\nse encuentra en los sistemas Framax y Alu-Framax.\\nActivación en Encofrado / Piezas sueltas a mano /...en el sistema de encofrado correspondiente /...",
            "fr": "Ce système de coffrage ne permet pas de coffrer les voiles circulaires.\\nLe programme de coffrage externe pour coffrage courbe avec tôles de courbure Framax\\nse trouve dans les systèmes de coffrage Framax et Alu-Framax\\nDans  Coffrage / Pièces individuelles manuel / ...dans le système de coffrage correspondant /...",
            "it": "Pareti circolari non possono essere progettate con questo sistema.\\nNel sistema Framax ed Alu-Framax, con le lamiere circolari Framax,\\navete a disposizione un programma esterno per la progettazione di pareti circolari.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves falak ezzel a zsalurendszerrel nem zsaluzhatók.\\nKülsõ zsaluprogramm íves falzsaluzáshoz Framax hajlítható lemezzel\\na Framax és Alu-Framax zsalurendszerkben található.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены не могут быть опалублены в данной опалубочной системе.\\nВнешние опалубочные программы для круглой опалубки с дугообразующими листами Framax\\nдоступны в опалубочных системах Framax и Alu-Framax.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / ...",
            "sk": "Kruhové steny nemôžu byť zadebnené v tomto debniacom syst.\\nExter. debn. program pre kruh. debn. s Framax oblúk. plech.\\nje k dispozícii v debniacich systémoch Framax a Alu-Framax.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme",
            "pl": "Ten system nie nadaje się do wykonania ściany łukowej.\\nAsystent do projektowania tego typu ścian przy użyciu blach łukowych Framax jest dostępny w systemach szalunku Framax i Alu-Framax.\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "RWFRAMIECO",
            "de": "Rundwände können in diesem Schalsystem nicht geschalt werden.\\nExternes Schalprogramme für Rundschalung mit Framax Bogenblech\\nsteht in den Schalsystemen Framax und Alu-Framax zur Verfügung.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "en-us": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "es": "Muros circulares no se pueden encofrar con este sistema de encofrado.\\nUn programa exterior para encofrado circular con chapa curvada Framax\\nse encuentra en los sistemas Framax y Alu-Framax.\\nActivación en Encofrado / Piezas sueltas a mano /...en el sistema de encofrado correspondiente /...",
            "fr": "Ce système de coffrage ne permet pas de coffrer les voiles circulaires.\\nLe programme de coffrage externe pour coffrage courbe avec tôles de courbure Framax\\nse trouve dans les systèmes de coffrage Framax et Alu-Framax.\\nDans  Coffrage / Pièces individuelles manuel / ...dans le système de coffrage correspondant /...",
            "it": "Pareti circolari non possono essere progettate con questo sistema.\\nNel sistema Framax ed Alu-Framax, con le lamiere circolari Framax,\\navete a disposizione un programma esterno per la progettazione di pareti circolari.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves falak ezzel a zsalurendszerrel nem zsaluzhatók.\\nKülsõ zsaluprogramm íves falzsaluzáshoz Framax hajlítható lemezzel\\na Framax és Alu-Framax zsalurendszerkben található.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены не могут быть опалублены в данной опалубочной системе.\\nВнешние опалубочные программы для круглой опалубки с дугообразующими листами Framax\\nдоступны в опалубочных системах Framax и Alu-Framax.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / ...",
            "sk": "Kruhové steny nemôžu byť zadebnené v tomto debniacom syst.\\nExter. debn. program pre kruh. debn. s Framax oblúk. plech.\\nje k dispozícii v debniacich systémoch Framax a Alu-Framax.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme",
            "pl": "Ten system nie nadaje się do wykonania ściany łukowej.\\nAsystent do projektowania tego typu ścian przy użyciu blach łukowych Framax jest dostępny w systemach szalunku Framax i Alu-Framax.\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "RWXALUfr",
            "de": "Rundwände können mit externen Schalprogramm(en) geschalt werden.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls can be formed with external formwork program(s)\\nCall with formwork / Single parts interactive / in the particular formwork system /…",
            "en-us": "Circular walls can be formed with external formwork program(s)\\nCall with formwork / Single parts interactive / in the particular formwork system /…",
            "es": "Muros circulares se pueden encofrar con programas exteriores.\\nActivación en Encofrado / Pie&zas sueltas a mano /...en el sistema de encofrado correspondiente /…",
            "fr": "Les voiles courbes peuvent être coffrés à l'aide de programmes de coffrage externes.\\nDans  Coffrage / Pièces individuelles manuel / ...dans le système de coffrage correspondant /…",
            "it": "Pareti circolari possono essere progettate con un programma/i esterno/i di casseratura.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves fal(ak) zsaluzása külsõ zsaluprogrammal történik.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены могут быть опалублены при помощи внешних опалубочных программ.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / …",
            "sk": "Kruhové steny môžu byť zadebnené externým deb-niac.programom.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme",
            "pl": "Projekt szalunku ściany łukowej może zostać utworzony przy pomocy asystenta\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "RWXFR30",
            "de": "Rundwände können mit externen Schalprogramm(en) geschalt werden.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls can be formed with external formwork program(s)\\nCall with formwork / Single parts interactive / in the particular formwork system /…",
            "en-us": "Circular walls can be formed with external formwork program(s)\\nCall with formwork / Single parts interactive / in the particular formwork system /…",
            "es": "Muros circulares se pueden encofrar con programas exteriores.\\nActivación en Encofrado / Pie&zas sueltas a mano /...en el sistema de encofrado correspondiente /…",
            "fr": "Les voiles courbes peuvent être coffrés à l'aide de programmes de coffrage externes.\\nDans  Coffrage / Pièces individuelles manuel / ...dans le système de coffrage correspondant /…",
            "it": "Pareti circolari possono essere progettate con un programma/i esterno/i di casseratura.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves fal(ak) zsaluzása külsõ zsaluprogrammal történik.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены могут быть опалублены при помощи внешних опалубочных программ.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / …",
            "sk": "Kruhové steny môžu byť zadebnené externým deb-niac.programom.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme",
            "pl": "Projekt szalunku ściany łukowej może zostać utworzony przy pomocy asystenta\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "RWXFRAMA_S",
            "de": "Rundwände können in diesem Schalsystem nicht geschalt werden.\\nExternes Schalprogramme für Rundschalung mit Framax Bogenblech\\nsteht in den Schalsystemen Framax und Alu-Framax zur Verfügung.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "en-us": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "es": "Muros circulares no se pueden encofrar con este sistema de encofrado.\\nUn programa exterior para encofrado circular con chapa curvada Framax\\nse encuentra en los sistemas Framax y Alu-Framax.\\nActivación en Encofrado / Piezas sueltas a mano /...en el sistema de encofrado correspondiente /...",
            "fr": "Ce système de coffrage ne permet pas de coffrer les voiles circulaires.\\nLe programme de coffrage externe pour coffrage courbe avec tôles de courbure Framax\\nse trouve dans les systèmes de coffrage Framax et Alu-Framax.\\nDans  Coffrage / Pièces individuelles manuel / ...dans le système de coffrage correspondant /...",
            "it": "Pareti circolari non possono essere progettate con questo sistema.\\nNel sistema Framax ed Alu-Framax, con le lamiere circolari Framax,\\navete a disposizione un programma esterno per la progettazione di pareti circolari.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves falak ezzel a zsalurendszerrel nem zsaluzhatók.\\nKülsõ zsaluprogramm íves falzsaluzáshoz Framax hajlítható lemezzel\\na Framax és Alu-Framax zsalurendszerkben található.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены не могут быть опалублены в данной опалубочной системе.\\nВнешние опалубочные программы для круглой опалубки с дугообразующими листами Framax\\nдоступны в опалубочных системах Framax и Alu-Framax.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / ...",
            "sk": "Kruhové steny nemôžu byť zadebnené v tomto debniacom syst.\\nExter. debn. program pre kruh. debn. s Framax oblúk. plech.\\nje k dispozícii v debniacich systémoch Framax a Alu-Framax.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme",
            "pl": "Ten system nie nadaje się do wykonania ściany łukowej.\\nAsystent do projektowania tego typu ścian przy użyciu blach łukowych Framax jest dostępny w systemach szalunku Framax i Alu-Framax.\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "RWXFRAMI",
            "de": "Rundwände können in diesem Schalsystem nicht geschalt werden.\\nExternes Schalprogramme für Rundschalung mit Framax Bogenblech\\nsteht in den Schalsystemen Framax und Alu-Framax zur Verfügung.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "en-us": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts manually / in the particular formwork system /..",
            "es": "Muros circulares no se pueden encofrar con este sistema de encofrado.\\nUn programa exterior para encofrado circular con chapa curvada Framax\\nse encuentra en los sistemas Framax y Alu-Framax.\\nActivación en Encofrado / Piezas sueltas a mano /...en el sistema de encofrado correspondiente /...",
            "fr": "Ce système de coffrage ne permet pas de coffrer les voiles circulaires.\\nLe programme de coffrage externe pour coffrage courbe avec tôles de courbure Framax\\nse trouve dans les systèmes de coffrage Framax et Alu-Framax.\\nDans  Coffrage / Pièces individuelles manuel / ...dans le système de coffrage correspondant /...",
            "it": "Pareti circolari non possono essere progettate con questo sistema.\\nNel sistema Framax ed Alu-Framax, con le lamiere circolari Framax,\\navete a disposizione un programma esterno per la progettazione di pareti circolari.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves falak ezzel a zsalurendszerrel nem zsaluzhatók.\\nKülsõ zsaluprogramm íves falzsaluzáshoz Framax hajlítható lemezzel\\na Framax és Alu-Framax zsalurendszerkben található.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены не могут быть опалублены в данной опалубочной системе.\\nВнешние опалубочные программы для круглой опалубки с дугообразующими листами Framax\\nдоступны в опалубочных системах Framax и Alu-Framax.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / ...",
            "sk": "Kruhové steny nemôžu byť zadebnené v tomto debniacom syst.\\nExter. debn. program pre kruh. debn. s Framax oblúk. plech.\\nje k dispozícii v debniacich systémoch Framax a Alu-Framax.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme",
            "pl": "Ten system nie nadaje się do wykonania ściany łukowej.\\nAsystent do projektowania tego typu ścian przy użyciu blach łukowych Framax jest dostępny w systemach szalunku Framax i Alu-Framax.\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "RWXFRAMI_S",
            "de": "Rundwände können in diesem Schalsystem nicht geschalt werden.\\nExternes Schalprogramme für Rundschalung mit Framax Bogenblech\\nsteht in den Schalsystemen Framax und Alu-Framax zur Verfügung.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "en-us": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "es": "Muros circulares no se pueden encofrar con este sistema de encofrado.\\nUn programa exterior para encofrado circular con chapa curvada Framax\\nse encuentra en los sistemas Framax y Alu-Framax.\\nActivación en Encofrado / Piezas sueltas a mano /...en el sistema de encofrado correspondiente /...",
            "fr": "Ce système de coffrage ne permet pas de coffrer les voiles circulaires.\\nLe programme de coffrage externe pour coffrage courbe avec tôles de courbure Framax\\nse trouve dans les systèmes de coffrage Framax et Alu-Framax.\\nDans  Coffrage / Pièces individuelles manuel / ...dans le système de coffrage correspondant /...",
            "it": "Pareti circolari non possono essere progettate con questo sistema.\\nNel sistema Framax ed Alu-Framax, con le lamiere circolari Framax,\\navete a disposizione un programma esterno per la progettazione di pareti circolari.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves falak ezzel a zsalurendszerrel nem zsaluzhatók.\\nKülsõ zsaluprogramm íves falzsaluzáshoz Framax hajlítható lemezzel\\na Framax és Alu-Framax zsalurendszerkben található.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены не могут быть опалублены в данной опалубочной системе.\\nВнешние опалубочные программы для круглой опалубки с дугообразующими листами Framax\\nдоступны в опалубочных системах Framax и Alu-Framax.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / ...",
            "sk": "Kruhové steny nemôžu byť zadebnené v tomto debniacom syst.\\nExter. debn. program pre kruh. debn. s Framax oblúk. plech\\nje k dispozícii v debniacich systémoch Framax a Alu-Framax.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme /...",
            "pl": "Ten system nie nadaje się do wykonania ściany łukowej.\\nAsystent do projektowania tego typu ścian przy użyciu blach łukowych Framax jest dostępny w systemach szalunku Framax i Alu-Framax.\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "RWXFRECO",
            "de": "Rundwände können mit externen Schalprogramm(en) geschalt werden.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls can be formed with external formwork program(s)\\nCall with formwork / Single parts interactive / in the particular formwork system /…",
            "en-us": "Circular walls can be formed with external formwork program(s)\\nCall with formwork / Single parts interactive / in the particular formwork system /…",
            "es": "Muros circulares se pueden encofrar con programas exteriores.\\nActivación en Encofrado / Pie&zas sueltas a mano /...en el sistema de encofrado correspondiente /…",
            "fr": "Les voiles courbes peuvent être coffrés à l'aide de programmes de coffrage externes.\\nDans  Coffrage / Pièces individuelles manuel / ...dans le système de coffrage correspondant /…",
            "it": "Pareti circolari possono essere progettate con un programma/i esterno/i di casseratura.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves fal(ak) zsaluzása külsõ zsaluprogrammal történik.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены могут быть опалублены при помощи внешних опалубочных программ.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / …",
            "sk": "Kruhové steny môžu byť zadebnené externým deb-niac.programom.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme",
            "pl": "Projekt szalunku ściany łukowej może zostać utworzony przy pomocy asystenta\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "RWXLIGHT",
            "de": "Rundwände können in diesem Schalsystem nicht geschalt werden.\\nExternes Schalprogramme für Rundschalung mit Framax Bogenblech\\nsteht in den Schalsystemen Framax und Alu-Framax zur Verfügung.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "en-us": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "es": "Muros circulares no se pueden encofrar con este sistema de encofrado.\\nUn programa exterior para encofrado circular con chapa curvada Framax\\nse encuentra en los sistemas Framax y Alu-Framax.\\nActivación en Encofrado / Piezas sueltas a mano /...en el sistema de encofrado correspondiente /...",
            "fr": "Ce système de coffrage ne permet pas de coffrer les voiles circulaires.\\nLe programme de coffrage externe pour coffrage courbe avec tôles de courbure Framax\\nse trouve dans les systèmes de coffrage Framax et Alu-Framax.\\nDans  Coffrage / Pièces individuelles manuel / ...dans le système de coffrage correspondant /...",
            "it": "Pareti circolari non possono essere progettate con questo sistema.\\nNel sistema Framax ed Alu-Framax, con le lamiere circolari Framax,\\navete a disposizione un programma esterno per la progettazione di pareti circolari.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves falak ezzel a zsalurendszerrel nem zsaluzhatók.\\nKülsõ zsaluprogramm íves falzsaluzáshoz Framax hajlítható lemezzel\\na Framax és Alu-Framax zsalurendszerkben található.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены не могут быть опалублены в данной опалубочной системе.\\nВнешние опалубочные программы для круглой опалубки с дугообразующими листами Framax\\nдоступны в опалубочных системах Framax и Alu-Framax.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / ...",
            "sk": "Kruhové steny nemôžu byť zadebnené v tomto debniacom syst.\\nExter. debn. program pre kruh. debn. s Framax oblúk. plech\\nje k dispozícii v debniacich systémoch Framax a Alu-Framax.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme /...",
            "pl": "Ten system nie nadaje się do wykonania ściany łukowej.\\nAsystent do projektowania tego typu ścian przy użyciu blach łukowych Framax jest dostępny w systemach szalunku Framax i Alu-Framax.\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "RWXREFORMA",
            "de": "Rundwände können in diesem Schalsystem nicht geschalt werden.\\nExternes Schalprogramme für Rundschalung mit Framax Bogenblech\\nsteht in den Schalsystemen Framax und Alu-Framax zur Verfügung.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "en-us": "Circular walls cannot be formed in this formwork system.\\nExternal formwork program for circular formwork with Framax circular forming plates\\nis available in the formwork system Framax and Alu-Framax.\\nCall with formwork / Single parts interactive / in the particular formwork system /...",
            "es": "Muros circulares no se pueden encofrar con este sistema de encofrado.\\nUn programa exterior para encofrado circular con chapa curvada Framax\\nse encuentra en los sistemas Framax y Alu-Framax.\\nActivación en Encofrado / Piezas sueltas a mano /...en el sistema de encofrado correspondiente /...",
            "fr": "Ce système de coffrage ne permet pas de coffrer les voiles circulaires.\\nLe programme de coffrage externe pour coffrage courbe avec tôles de courbure Framax\\nse trouve dans les systèmes de coffrage Framax et Alu-Framax.\\nDans  Coffrage / Pièces individuelles manuel / ...dans le système de coffrage correspondant /...",
            "it": "Pareti circolari non possono essere progettate con questo sistema.\\nNel sistema Framax ed Alu-Framax, con le lamiere circolari Framax,\\navete a disposizione un programma esterno per la progettazione di pareti circolari.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves falak ezzel a zsalurendszerrel nem zsaluzhatók.\\nKülsõ zsaluprogramm íves falzsaluzáshoz Framax hajlítható lemezzel\\na Framax és Alu-Framax zsalurendszerkben található.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены не могут быть опалублены в данной опалубочной системе.\\nВнешние опалубочные программы для круглой опалубки с дугообразующими листами Framax\\nдоступны в опалубочных системах Framax и Alu-Framax.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / ...",
            "sk": "Kruhové steny nemôžu byť zadebnené v tomto debniacom syst.\\nExter. debn. program pre kruh. debn. s Framax oblúk. plech\\nje k dispozícii v debniacich systémoch Framax a Alu-Framax.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme /...",
            "pl": "Ten system nie nadaje się do wykonania ściany łukowej.\\nAsystent do projektowania tego typu ścian przy użyciu blach łukowych Framax jest dostępny w systemach szalunku Framax i Alu-Framax.\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "RWXSTAHLfr",
            "de": "Rundwände können mit externen Schalprogramm(en) geschalt werden.\\nAufruf unter Schalung / Einzelteile von Hand /...im jeweiligen Schalsystem /...",
            "en-gb": "Circular walls can be formed with external formwork program(s)\\nCall with formwork / Single parts interactive / in the particular formwork system /…",
            "en-us": "Circular walls can be formed with external formwork program(s)\\nCall with formwork / Single parts interactive / in the particular formwork system /…",
            "es": "Muros circulares se pueden encofrar con programas exteriores.\\nActivación en Encofrado / Pie&zas sueltas a mano /...en el sistema de encofrado correspondiente /…",
            "fr": "Les voiles courbes peuvent être coffrés à l'aide de programmes de coffrage externes.\\nDans  Coffrage / Pièces individuelles manuel / ...dans le système de coffrage correspondant /…",
            "it": "Pareti circolari possono essere progettate con un programma/i esterno/i di casseratura.\\nPer caricarlo: Casseforme / Pezzi singoli a mano / nella sistema di casseratura adeguata /...",
            "hu": "Íves fal(ak) zsaluzása külsõ zsaluprogrammal történik.\\nBehívása Zsaluzatok / Elemek elhelyezése kézzel / ...a mindenkori zsalurendszerben",
            "ru": "Круглые стены могут быть опалублены при помощи внешних опалубочных программ.\\nВызов программы через меню Опалубка / Детали вручную / ... в соответствующей опалубочной системе / …",
            "sk": "Kruhové steny môžu byť zadebnené externým deb-niac.programom.\\nVyvolanie pod Debnenie / Jedn. diely rucne ... /...v každom debniacom systéme",
            "pl": "Projekt szalunku ściany łukowej może zostać utworzony przy pomocy asystenta\\nCall with formwork / Pojedyncze części interaktywnie / w konkretnym systemie szalunku /…\\n"
        },
        {
            "ID": "SEITENXP_A1",
            "de": "Höhe Geländersteher",
            "en-gb": "Height of handrail post",
            "en-us": "Height of handrail post",
            "es": "Altura soporte de barandilla",
            "fr": "Hauteur de montant de garde-corps",
            "it": "Altezza asta parapetto",
            "hu": "Védőkorlát magassága",
            "ru": "Высота поручней",
            "sk": "Vysoký stĺpik zábradlia",
            "pl": "Wysokość słupka barierki"
        },
        {
            "ID": "SEITENXP_A1_AW1",
            "de": "Geländersteher 1,20 m",
            "en-gb": "Handrail post 1.20 m",
            "en-us": "Handrail post 1.20m",
            "es": "Poste de barandilla 1,20m",
            "fr": "Montant de garde-corps 1,20m",
            "it": "Asta parapetto 1,20m",
            "hu": "Korlátoszlop 1,20m",
            "ru": "Стойка для перил 1,20м",
            "sk": "Stĺpik zábradlia 1,20m",
            "pl": "Słupek barierki 1,20m"
        },
        {
            "ID": "SEITENXP_A1_AW2",
            "de": "Geländersteher 1,80 m (1,20+0,60)",
            "en-gb": "Handrail post 1.80 m (1.20+0.60)",
            "en-us": "Handrail post 1.80m (1.20+0.60)",
            "es": "Poste de barandilla 1,80m (1,20+0,60)",
            "fr": "Montant de garde-corps 1,80m (1,20+0,60)",
            "it": "Asta parapetto 1,80m (1,20+0,60)",
            "hu": "Korlátoszlop 1,80m (1,20+0,60)",
            "ru": "Стойка для перил 1,80м (1,20+0,60)",
            "sk": "Stĺpik zábradlia 1,80m (1,20+0,60)",
            "pl": "Słupek barierki 1,80m (1,20+0,60)"
        },
        {
            "ID": "SEITENXP_A2",
            "de": "Befestigung",
            "en-gb": "Fixing",
            "en-us": "Fixing",
            "es": "Sujeción",
            "fr": "Fixation",
            "it": "Fissaggio",
            "hu": "Rögzítés",
            "ru": "Закрепление",
            "sk": "Pripevnenie",
            "pl": "Mocowanie"
        },
        {
            "ID": "SEITENXP_A2_AW1",
            "de": "Geländerzwinge XP 40cm",
            "en-gb": "Railing clamp XP 40 cm",
            "en-us": "Railing clamp XP 40cm",
            "es": "Sargento para barandilla XP 40cm",
            "fr": "Fixation à pince XP 40cm",
            "it": "Scarpetta a morsa XP 40cm",
            "hu": "Korlát rögzítő XP 40cm",
            "ru": "Зажим для перил XP 40cм",
            "sk": "Zverákový stĺpik zábradlia XP 40cm",
            "pl": "Zacisk barierki XP 40cm"
        },
        {
            "ID": "SEITENXP_A2_AW2",
            "de": "Geländerzwinge XP 85cm",
            "en-gb": "Railing clamp XP 85 cm",
            "en-us": "Railing clamp XP 85cm",
            "es": "Sargento para barandilla XP 85cm",
            "fr": "Fixation à pince XP 85cm",
            "it": "Scarpetta a morsa XP 85cm",
            "hu": "Korlát rögzítő XP 85cm",
            "ru": "Зажим для перил XP 85cм",
            "sk": "Zverákový stĺpik zábradlia XP 85cm",
            "pl": "Zacisk barierki XP 85cm"
        },
        {
            "ID": "SEITENXP_A2_AW3",
            "de": "Schraubschuh XP",
            "en-gb": "Screw-on shoe XP",
            "en-us": "Screw-on shoe XP",
            "es": "Soporte de rosca XP",
            "fr": "Support à boulonner XP",
            "it": "Scarpetta a vite XP",
            "hu": "Menetes talp XP",
            "ru": "Привинчиваемый башмак XP",
            "sk": "Skrutkovacia botka XP",
            "pl": "But przykręcany XP"
        },
        {
            "ID": "SEITENXP_A2_AW4",
            "de": "Geländerschuh XP",
            "en-gb": "Handrail-post shoe XP",
            "en-us": "Handrail-post shoe XP",
            "es": "Soporte para barandilla XP",
            "fr": "Sabot garde-corps XP",
            "it": "Scarpetta per parapetto XP",
            "hu": "Korlát talp XP",
            "ru": "Башмак для перил XP",
            "sk": "Botka zábradlia XP",
            "pl": "But barierki XP"
        },
        {
            "ID": "SEITENXP_A3",
            "de": "Abschrankung",
            "en-gb": "Barrier",
            "en-us": "Barrier",
            "es": "Valla",
            "fr": "Protection latérale",
            "it": "Barriera",
            "hu": "Korlát",
            "ru": "Ограждение",
            "sk": "Zábradlie",
            "pl": "Barierka"
        },
        {
            "ID": "SEITENXP_A3_AW1",
            "de": "Schutzgitter XP",
            "en-gb": "Protective barrier XP",
            "en-us": "Protective fence XP",
            "es": "Reja de protección XP",
            "fr": "Barrière de protection XP",
            "it": "Griglia di protezione XP",
            "hu": "Védőrács XP",
            "ru": "Защитная решетка XP",
            "sk": "Ochranná mriežka XP",
            "pl": "Siatka ochronna XP"
        },
        {
            "ID": "SEITENXP_A3_AW2",
            "de": "Geländerbretter (5x20cm)",
            "en-gb": "Handrail boards (5x20cm)",
            "en-us": "Handrail boards (5x20cm)",
            "es": "Tablones de barandilla (5x20cm)",
            "fr": "Planches de garde-corps (5x20cm)",
            "it": "Tavole parapetto (5x20cm)",
            "hu": "Védőkorlát deszkák (5x20cm)",
            "ru": "Доски перил (5x20cм)",
            "sk": "Dosky zábradlia (5x20cm)",
            "pl": "Deski poręczy (5x20cm)"
        },
        {
            "ID": "SEITENXP_A3_AW3",
            "de": "Gerüstrohr",
            "en-gb": "Scaffold tube",
            "en-us": "Scaffold tube",
            "es": "Tubo de andamio",
            "fr": "Tube d'échafaudage",
            "it": "Tubo di ponteggio",
            "hu": "Állványcső",
            "ru": "Каркасная трубка",
            "sk": "Lešenárska rúra",
            "pl": "Rura rusztowaniowa"
        },
        {
            "ID": "SEITENXP_A3_AW4",
            "de": "Vollbeplankung",
            "en-gb": "Full planking",
            "en-us": "Full planking",
            "es": "Recubrimiento completo",
            "fr": "Plaques de protection complète",
            "it": "Tavolame completo",
            "hu": "Teljes pallózás",
            "ru": "Полная обшивка досками",
            "sk": "Celoplošné opláštenie",
            "pl": "Pełne poszycie"
        },
        {
            "ID": "SSDOKASETF",
            "de": "Dokaset F Rahmenschalung",
            "en-gb": "Dokaset F framed formwork",
            "en-us": "Dokaset F frame formwork",
            "es": "Encofrados marco Dokaset F",
            "fr": "Coffrage-cadre Dokaset F",
            "it": "Casseforme a telaio Dokaset F",
            "hu": "Dokaset F falzsalu",
            "ru": "амная опалубка Dokaset F",
            "sk": "Dokaset F rám. debnenie",
            "pl": "Deskowanie ramowe Dokaset F"
        },
        {
            "ID": "SSFALTBKG2",
            "de": "Faltbühne K, Gerüstgr. 2 (150kg/m²)",
            "en-gb": "Fold.platform K, scaff. group 2 (150kg/m²)",
            "en-us": "Fold.platform K, scaff. group 2 (150kg/m²)",
            "es": "Ménsula plegable K, Cimbra tam. 2 (150kg/m²)",
            "fr": "Passerelle repliable K, Gr. Échafaud. 2 (150kg/m²)",
            "it": "Ponte piegh.K, grp ponteggi 2(150kg/m²)",
            "hu": "Összecsukható munkaállvány K, Állványcsop. 2 (150kg/m²)",
            "ru": "Складные подмости K, размер лесов 2 (150 кг/м²)",
            "sk": "Skl. ploš.K, tr.lešenia 2(150kg/m˛)",
            "pl": "Pomost składany K, grupa rusztowań 2 (150 kg/m²)"
        },
        {
            "ID": "SSFALTBKG3",
            "de": "Faltbühne K, Gerüstgr. 3 (200kg/m²)",
            "en-gb": "Fold.platform K, scaff. group 3 (200kg/m²)",
            "en-us": "Fold.platform K, scaff. group 3 (200kg/m²)",
            "es": "Ménsula plegable K, Cimbra tam. 3 (200kg/m²)",
            "fr": "Passerelle repliable K, Gr. Echafaud. 3 (200kg/m²)",
            "it": "Ponte piegh.K, grp ponteggi 3(200kg/m²)",
            "hu": "Összecsukható munkaállvány K, Állványcsop. 3 (200kg/m²)",
            "ru": "Складные подмости K, размер лесов 3 (200 кг/м²)",
            "sk": "Skl. ploš.K, tr.lešenia 3(200kg/m˛)",
            "pl": "Pomost składany K, grupa rusztowań 3 (200 kg/m²)"
        },
        {
            "ID": "SSFALTBKG4",
            "de": "Faltbühne K, Gerüstgr. 4 (300kg/m²)",
            "en-gb": "Fold.platform K, scaff. group 4 (300kg/m²)",
            "en-us": "Fold.platform K, scaff. group 4 (300kg/m²)",
            "es": "Ménsula plegable K, Cimbra tam. 4 (300kg/m²)",
            "fr": "Passerelle repliable K, Gr. Echafaud. 4 (300kg/m²)",
            "it": "Ponte piegh.K, grp ponteggi 4(300kg/m²)",
            "hu": "Összecsukható munkaállvány K, Állványcsop. 4 (300kg/m²)",
            "ru": "Складные подмости K, размер лесов 4 (300 км/м²)",
            "sk": "Skl. polš.K, tr.lešenia 4(300kg/m˛)",
            "pl": "Pomost składany K, grupa rusztowań 3 (300 kg/m²)"
        },
        {
            "ID": "SSFF100TEC",
            "de": "FF100 tec Trägerschalung",
            "en-gb": "FF100 tec wall formwork",
            "en-us": "FF100 tec wall formwork",
            "es": "FF100 tec Encofrado de vigas",
            "fr": "Coffrage mixte FF100 tec",
            "it": "Cassaforma a travi FF100 tec",
            "hu": "FF100 tec fatartós falzsalu",
            "ru": "FF100 tec балочная опалубка",
            "sk": "FF100 tec nosník. debn.",
            "pl": "Deskowanie dźwigarowe FF100 tec"
        },
        {
            "ID": "SSFF20_21",
            "de": "FF20 Trägerschalung 21 mm",
            "en-gb": "FF20 wall formwork 21mm",
            "en-us": "FF20 wall formwork 21mm",
            "es": "FF20 Encofrado de vigas 21mm",
            "fr": "Coffrage mixte FF20 21mm",
            "it": "Cassaforma a travi FF20,21 mm",
            "hu": "FF20 fatartós falzsalu 21 mm",
            "ru": "FF20 балочная опалубка 21 мм",
            "sk": "FF20 nosník. debn. 21 mm",
            "pl": "Deskowanie dźwigarowe FF20 21mm"
        },
        {
            "ID": "SSFF20_27",
            "de": "FF20 Trägerschalung 27 mm",
            "en-gb": "FF20 wall formwork 27mm",
            "en-us": "FF20 wall formwork 27mm",
            "es": "FF20 Encofrado de vigas 27mm",
            "fr": "Coffrage mixte FF20 27mm",
            "it": "Cassaforma a travi FF20,27 mm",
            "hu": "FF20 fatartós falzsalu 27 mm",
            "ru": "FF20 балочная опалубка 27 мм",
            "sk": "FF20 nosník. debn. 27 mm",
            "pl": "Deskowanie dźwigarowe FF20 27mm"
        },
        {
            "ID": "SSFL20",
            "de": "FL20 U100 Trägerschalung",
            "en-gb": "FL20 U100 wall formwork",
            "en-us": "FL20 U100 wall formwork",
            "es": "FL20 U100 Encofrado de vigas",
            "fr": "Coffrage mixte FL20 U100",
            "it": "Cassaforma a travi FL20 U100",
            "hu": "FL20 U100 fatartós falzsalu",
            "ru": "FL20 U100 балочная опалубка",
            "sk": "FL20 U100 nosník. debn.",
            "pl": "Deskowanie dźwigarowe FL20 U100"
        },
        {
            "ID": "SSFLEX124",
            "de": "Dokaflex 1-2-4 Deckenschalung inkl. Tische",
            "en-gb": "Dokaflex 1-2-4 floor formwork incl. tables",
            "en-us": "Dokaflex 1-2-4 floor formwork incl. tables",
            "es": "Dokaflex 1-2-4 Encofrado de techos incl. mesas",
            "fr": "Coffrage de dalle Dokaflex 1-2-4 y compris les tables",
            "it": "Dokaflex 1-2-4 casseforme per solai incl. tavoli",
            "hu": "Dokaflex 1-2-4 födémzsalu és asztalzsalu",
            "ru": "Dokaflex 1-2-4 опалубка перекрытий, включая столы",
            "sk": "Dokaflex 1-2-4 stropné debnenie vr. stolov",
            "pl": "Dokaflex 1-2-4 szalunek podłoża ze stołami"
        },
        {
            "ID": "SSFLEX15",
            "de": "Dokaflex 15 Deckenschalung",
            "en-gb": "Dokaflex 15 floor formwork",
            "en-us": "Dokaflex 15 floor formwork",
            "es": "Dokaflex 15 Encofrado de techos",
            "fr": "Coffrage de dalle Dokaflex 15",
            "it": "Dokaflex 15 casseforme per solai",
            "hu": "Dokaflex 15 fatartós födémzsalu rendszerek",
            "ru": "Dokaflex 15 опалубка перекрытий",
            "sk": "Dokaflex 15 stropné debnenie",
            "pl": "Dokaflex 15 szalunek stropowy"
        },
        {
            "ID": "SSFLEX30OP",
            "de": "Dokaflex 30 tec Deckenschalung",
            "en-gb": "Dokaflex 30 tec floor formwork",
            "en-us": "Dokaflex 30 tec floor formwork",
            "es": "Dokaflex 30 tec Encofrado de techos",
            "fr": "Coffrage de dalle Dokaflex 30 tec",
            "it": "Dokaflex 30 tec casseforme per solai",
            "hu": "Dokaflex 30 tec fatartós födémzsalu rendszerek",
            "ru": "Dokaflex 30 tec опалубка перекрытий",
            "sk": "Dokaflex 30 tec stropné debnenie",
            "pl": "Dokaflex 20 szalunek stropowy"
        },
        {
            "ID": "SSFLEXOPT",
            "de": "Dokaflex 20 Deckenschalung inkl. Tische",
            "en-gb": "Dokaflex 20 floor formwork incl. Tables",
            "en-us": "Dokaflex 20 floor formwork incl. tables",
            "es": "Dokaflex 20 Encofrado de techos incl. mesas",
            "fr": "Coffrage de dalle Dokaflex 20 compris les tables",
            "it": "Dokaflex 20 casseforme per solai incl. tavoli",
            "hu": "Dokaflex 20 födémzsalu és asztalzsalu",
            "ru": "Dokaflex 20 опалубка перекрытий, включая столы",
            "sk": "Dokaflex 20 stropné debnenie vr. stolov",
            "pl": "Dokaflex 20 szalunek podłoża ze stołami"
        },
        {
            "ID": "SSFLEXS",
            "de": "Dokaflex S Deckenschalung",
            "en-gb": "Dokaflex S floor formwork",
            "en-us": "Dokaflex S floor formwork",
            "es": "Dokaflex S Encofrado de techos",
            "fr": "Coffrage de dalle Dokaflex S",
            "it": "Dokaflex S casseforme per solai",
            "hu": "Dokaflex S fatartós födémzsalu",
            "ru": "Dokaflex S опалубка перекрытий",
            "sk": "Dokaflex S stropné debnenie",
            "pl": "Dokaflex S szalunek stropowy"
        },
        {
            "ID": "SSFRAMECO",
            "de": "Frameco Rahmenschalung",
            "en-gb": "Frameco framed formwork",
            "en-us": "Frameco frame formwork",
            "es": "Encofrados marco Frameco",
            "fr": "Coffrage-cadre Frameco",
            "it": "Casseforme a telaio Frameco",
            "hu": "Frameco falzsalu",
            "ru": "Рамная опалубка Frameco",
            "sk": "Frameco rám. debnenie",
            "pl": "Deskowanie ramowe Frameco"
        },
        {
            "ID": "SSFRAMIECO",
            "de": "Frami eco Rahmenschalung",
            "en-gb": "Frami eco framed formwork",
            "en-us": "Frami eco frame formwork",
            "es": "Encofrados marco Frami eco",
            "fr": "Coffrage-cadre Frami eco",
            "it": "Casseforme a telaio Frami eco",
            "hu": "Frami eco falzsalu",
            "ru": "Рамная опалубка Frami eco",
            "sk": "Frami eco rám. debnenie",
            "pl": "Deskowanie ramowe Frami eco"
        },
        {
            "ID": "SSKOBMDIDI",
            "de": "Konsolbühne M, (300kg/m²)",
            "en-gb": "Bracket platf. M, (300kg/m²)",
            "en-us": "Platf.bracket M, (300kg/m²)",
            "es": "Consola M, (300kg/m²)",
            "fr": "Console M, (300kg/m²)",
            "it": "Passerella a mensola M, (300kg/m²)",
            "hu": "Konzolállvány M, (300kg/m²)",
            "ru": "Консольные подмости M, (300 кг/м²)",
            "sk": "Konz. ploš. M, (300kg/m˛)",
            "pl": "Pomost konsolowy M (300kg/m²)"
        },
        {
            "ID": "SSKOBMMAXA",
            "de": "Konsolbühne M und Bühnenbelag(300kg/m²)",
            "en-gb": "Bracket platf. M and platf.planking(300kg/m²)",
            "en-us": "Platf.bracket M and platf.planking(300kg/m²)",
            "es": "Consola M y Recubrimiento plataforma(300kg/m²)",
            "fr": "Console M et platelage(300kg/m²)",
            "it": "Passerella a mens. M e piano cammin.(300kg/m²)",
            "hu": "Konzolállvány M és pallóterítés(300kg/m²)",
            "ru": "Консольные подмости M и настил подмостей (300 кг/м²)",
            "sk": "Konz. ploš. M a podlaha ploš.(300kg/m˛)",
            "pl": "Pomost konsolowy M i odeskowanie pomostu (300 kg/m²)"
        },
        {
            "ID": "SSSEITENXP",
            "de": "Seitenschutzsystem XP am Bauwerk",
            "en-gb": "Edge protection system XP on structure",
            "en-us": "Edge protection system XP on structure",
            "es": "Sistema de protección lateral XP puesto en la estructura",
            "fr": "Système anti-chute XP á la construction",
            "it": "Sistema di protezione laterale XP sulla struttura",
            "hu": "Oldalkorlát rendszer XP on structure",
            "ru": "Система боковой защиты XP on structure",
            "sk": "Bočný ochranný systém XP on structure",
            "pl": "System ochrony krawędzi XP na konstrukcji"
        },
        {
            "ID": "SSXALUfr",
            "de": "Alu-Framax Xlife Rahmenschalung",
            "en-gb": "Alu-Framax Xlife framed formwork",
            "en-us": "Alu-Framax Xlife framed formwork",
            "es": "Encofrados marco Alu-Framax Xlife",
            "fr": "Coffrage-cadre Alu-Framax Xlife",
            "it": "Casseforme a telaio Alu-Framax Xlife",
            "hu": "Alu-Framax Xlife falzsalu",
            "ru": "Рамная опалубка Alu-Framax Xlife",
            "sk": "Alu-Framax Xlife rám. debnenie",
            "pl": "Deskowanie ramowe Alu-Framax Xlife"
        },
        {
            "ID": "SSXFR30",
            "de": "Framax Xlife 3,00m Rahmenschalung",
            "en-gb": "Framax Xlife 3.00m framed formwork",
            "en-us": "Framax Xlife 3.00m framed formwork",
            "es": "Encofrados marco Framax Xlife 3,00m",
            "fr": "Coffrage-cadre Framax Xlife 3,00m",
            "it": "Casseforme a telaio Framax Xlife 3,00m",
            "hu": "Framax Xlife 3,00m falzsalu",
            "ru": "Рамная опалубка Framax Xlife 3,00м",
            "sk": "Framax Xlife 3,00m rám. debnenie",
            "pl": "Deskowanie ramowe Framax Xlife 3,00m"
        },
        {
            "ID": "SSXFRAMA_S",
            "de": "Framax S Xlife (USA) Rahmenschalung",
            "en-gb": "Framax S Xlife (USA) framed formwork",
            "en-us": "Framax S Xlife (USA) framed formwork",
            "es": "Encofrados marco Framax S Xlife (USA)",
            "fr": "Coffrage-cadre Framax S Xlife (USA)",
            "it": "Casseforme a telaio Framax S Xlife (USA)",
            "hu": "Framax S Xlife (USA) falzsalu",
            "ru": "Рамная опалубка Framax S Xlife (США)",
            "sk": "Framax S Xlife (USA) rám. debnenie",
            "pl": "Deskowanie ramowe Framax S Xlife (USA)"
        },
        {
            "ID": "SSXFRAMI",
            "de": "Frami Xlife Rahmenschalung",
            "en-gb": "Frami Xlife framed formwork",
            "en-us": "Frami Xlife frame formwork",
            "es": "Encofrados marco Frami Xlife",
            "fr": "Coffrage-cadre Frami Xlife",
            "it": "Casseforme a telaio Frami Xlife",
            "hu": "Frami Xlife falzsalu",
            "ru": "Рамная опалубка Frami Xlife",
            "sk": "Frami Xlife rám. debnenie",
            "pl": "Deskowanie ramowe Frami Xlife"
        },
        {
            "ID": "SSXFRAMI_S",
            "de": "Frami S Xlife (USA) Rahmenschalung",
            "en-gb": "Frami S Xlife (USA) framed formwork",
            "en-us": "Frami-S Xlife (USA) frame formwork",
            "es": "Encofrados marco Frami S Xlife (USA)",
            "fr": "Coffrage-cadre Frami S Xlife (USA)",
            "it": "Casseforme a telaio Frami S Xlife (USA)",
            "hu": "Frami S Xlife (USA) falzsalu",
            "ru": "Рамная опалубка Frami S Xlife (США)",
            "sk": "Frami S Xlife (USA) rám. debnenie",
            "pl": "Deskowanie ramowe Frami S Xlife (USA)"
        },
        {
            "ID": "SSXFRAMINI",
            "de": "Framini Rahmenschalung",
            "en-gb": "Framini framed formwork",
            "en-us": "Framini framed formwork",
            "es": "Encofrados marco Framini",
            "fr": "Coffrage-cadre Framini",
            "it": "Casseforme a telaio Framini",
            "hu": "Framini falzsalu",
            "ru": "Рамная опалубка Framini",
            "sk": "Framini rám. debnenie",
            "pl": "Deskowanie ramowe Framini"
        },
        {
            "ID": "SSXFRECO",
            "de": "Framax eco Rahmenschalung",
            "en-gb": "Framax eco framed formwork",
            "en-us": "Framax eco framed formwork",
            "es": "Encofrados marco Framax eco",
            "fr": "Coffrage-cadre Framax eco",
            "it": "Casseforme a telaio Framax eco",
            "hu": "Framax eco falzsalu",
            "ru": "Рамная опалубка Framax eco",
            "sk": "Framax eco rám. debnenie",
            "pl": "Deskowanie ramowe Framax eco"
        },
        {
            "ID": "SSXFRPLUS",
            "de": "Framax Xlife plus Rahmenschalung",
            "en-gb": "Framax Xlife plus framed formwork",
            "en-us": "Framax Xlife plus framed formwork",
            "es": "Encofrados marco Framax Xlife plus",
            "fr": "Coffrage-cadre Framax Xlife plus",
            "it": "Casseforme a telaio Framax Xlife plus",
            "hu": "Framax Xlife plus falzsalu",
            "ru": "Рамная опалубка Framax Xlife plus",
            "sk": "Framax Xlife plus rám. debnenie",
            "pl": "Deskowanie ramowe Framax Xlife plus"
        },
        {
            "ID": "SSXLIGHT",
            "de": "DokaXlight Rahmenschalung",
            "en-gb": "DokaXlight framed formwork",
            "en-us": "DokaXlight framed formwork",
            "es": "Encofrados marco DokaXlight",
            "fr": "Coffrage-cadre DokaXlight",
            "it": "Casseforme a telaio DokaXlight",
            "hu": "DokaXlight falzsalu",
            "ru": "Рамная опалубка DokaXlight",
            "sk": "DokaXlight rám. debnenie",
            "pl": "Deskowanie ramowe DokaXlight"
        },
        {
            "ID": "SSXREFORMA",
            "de": "ReForma Standard plus",
            "en-gb": "ReForma Standard plus",
            "en-us": "ReForma Standard plus",
            "es": "ReForma Standard plus",
            "fr": "ReForma Standard plus",
            "it": "ReForma Standard plus",
            "hu": "ReForma Standard plus",
            "ru": "ReForma Standard plus",
            "sk": "ReForma Standard plus",
            "pl": "ReForma Standard plus"
        },
        {
            "ID": "SSXSAFEPLU",
            "de": "Framax Xlife Rahmenschalung mit Xsafe plus",
            "en-gb": "Framax Xlife framed formwork with Xsafe plus",
            "en-us": "Framax Xlife framed formwork with Xsafe plus",
            "es": "Encofrados marco Framax Xlife con Xsafe plus",
            "fr": "Coffrage-cadre Framax Xlife avec Xsafe plus",
            "it": "Casseforme a telaio Framax Xlife con Xsafe plus",
            "hu": "Framax Xlife falzsalu Xsafe plus",
            "ru": "Framax Xlife рамная опалубка с системой рабочих подмостей Xsafe plus",
            "sk": "Framax Xlife rám. debnenie s Xsafe plus",
            "pl": "Deskowanie ramowe Framax Xlife z Xsafe plus"
        },
        {
            "ID": "SSXSTAHLfr",
            "de": "Framax Xlife Rahmenschalung",
            "en-gb": "Framax Xlife framed formwork",
            "en-us": "Framax Xlife framed formwork",
            "es": "Encofrados marco Framax Xlife",
            "fr": "Coffrage-cadre Framax Xlife",
            "it": "Casseforme a telaio Framax Xlife",
            "hu": "Framax Xlife falzsalu",
            "ru": "Рамная опалубка Framax Xlife",
            "sk": "Framax Xlife rám. debnenie",
            "pl": "Deskowanie ramowe Framax Xlife"
        },
        {
            "ID": "SSXTRA",
            "de": "Xtra Deckenschalung Eurex 20 inkl. Tische",
            "en-gb": "Xtra floor formwork Eurex 20 incl. tables",
            "en-us": "Xtra floor formwork Eurex 20 incl. tables",
            "es": "Encofrado de techos Xtra Eurex20 incl. mesas",
            "fr": "Coffrage de dale Xtra Eurex20 y compris les tables",
            "it": "Xtra casseforme per solai Eurex20 incl. tavoli",
            "hu": "Xtra födémzsalu Eurex 20 és asztalzsalu",
            "ru": "Xtra оптимизированная опалубка Eurex 20, включая столы",
            "sk": "Xtra strop. debnenie Eurex 20 vr. stolov",
            "pl": "Szalunek stropowy Xtra Eurex 20 wraz ze stołami"
        },
        {
            "ID": "SSXTRA30",
            "de": "Xtra Deckenschalung Eurex 30 inkl. Tische",
            "en-gb": "Xtra floor formwork Eurex 30 incl. tables",
            "en-us": "Xtra floor formwork Eurex 30 incl. tables",
            "es": "Encofrado de techos Xtra Eurex30 incl. mesas",
            "fr": "Coffrage de dale Xtra Eurex30 y compris les tables",
            "it": "Xtra casseforme per solai Eurex30 incl. tavoli",
            "hu": "Xtra födémzsalu Eurex 30 és asztalzsalu",
            "ru": "Xtra оптимизированная опалубка Eurex 30, включая столы",
            "sk": "Xtra strop. debnenie Eurex 30 vr. stolov",
            "pl": "Szalunek stropowy Xtra Eurex 30 wraz ze stołami"
        },
        {
            "ID": "STATIK_ANSI",
            "de": "ANSI",
            "en-gb": "ANSI",
            "en-us": "ANSI",
            "es": "ANSI",
            "fr": "ANSI",
            "it": "ANSI",
            "hu": "ANSI",
            "ru": "ANSI",
            "sk": "ANSI",
            "pl": "ANSI"
        },
        {
            "ID": "STATIK_EUROCODE",
            "de": "Eurocode",
            "en-gb": "Eurocode",
            "en-us": "Eurocode",
            "es": "Eurocode",
            "fr": "Eurocode",
            "it": "Eurocode",
            "hu": "Eurocode",
            "ru": "Eurocode",
            "sk": "Eurocode",
            "pl": "Eurocode"
        },
        {
            "ID": "TOP50",
            "de": "Top 50/100",
            "en-gb": "Top 50/100",
            "en-us": "Top 50/100",
            "es": "Top 50/100",
            "fr": "Top 50/100",
            "it": "Top 50/100",
            "hu": "Top 50/100",
            "ru": "Top 50/100",
            "sk": "Top 50/100",
            "pl": "Top 50/100"
        },
        {
            "ID": "XALUFR_A1",
            "de": "Aufstockung Alu auf Alu oder Stahl auf Alu",
            "en-gb": "Stacking Alu on Alu or Steel on Alu",
            "en-us": "Stacking alu on alu or steel on alu",
            "es": "Apilado alu sobre alu o acero sobre alu",
            "fr": "Rehausse alu sur alu ou acier sur alu",
            "it": "Sovrapposizione alluminio su allumninio o acciaio su alluminio",
            "hu": "Magasítás Alun Alun vagy Acél alun",
            "ru": "Надстройка алюминий на алюминий или сталь на алюминий",
            "sk": "Nadstavenia hliník na hliník alebo ocel na hliník",
            "pl": "Nadstawienie aluminium na aluminium lub stal na aluminium"
        },
        {
            "ID": "XALUFR_A1_AW2",
            "de": "Framax Alu",
            "en-gb": "Framax Alu",
            "en-us": "Framax Alu",
            "es": "Framax Alu",
            "fr": "Framax Alu\″",
            "it": "Framax Alu",
            "hu": "Framax Alu",
            "ru": "Алюминий Framax",
            "sk": "Framax Alu",
            "pl": "Framax Alu"
        },
        {
            "ID": "XALUFR_A1_AW3",
            "de": "Stahl auf Alu",
            "en-gb": "Steel on Alu",
            "en-us": "Steel on Alu",
            "es": "Acero sobre alu",
            "fr": "Acier sur alu",
            "it": "Acciaio su allumninio",
            "hu": "Acél alun",
            "ru": "Сталь на алюминий",
            "sk": "Ocel na hliník",
            "pl": "Stal na aluminium"
        },
        {
            "ID": "XALUFR_A10",
            "de": "Stehende oder liegende Elemente",
            "en-gb": "Upright or horiz. panels",
            "en-us": "Upright or horiz. panels",
            "es": "Elementos verticales o horizontales",
            "fr": "Px debouts ou couchés",
            "it": "Elementi vert. o orizz.",
            "hu": "Elemek állítva vagy fekvo",
            "ru": "Элементы, установленные горизонтально или вертикально",
            "sk": "Panely nastojato alebo naležato",
            "pl": "Płyty pionowe lub poziome"
        },
        {
            "ID": "XALUFR_A10_AW1",
            "de": "Stehende Elemente",
            "en-gb": "Upright panels",
            "en-us": "Upright panels",
            "es": "Elementos verticales",
            "fr": "Px debout",
            "it": "Elementi vert.",
            "hu": "Elemek állítva",
            "ru": "лементы, установленные вертикально",
            "sk": "Panely nastojato",
            "pl": "Płyty pionowe"
        },
        {
            "ID": "XALUFR_A10_AW2",
            "de": "Liegende Elemente",
            "en-gb": "Horiz. panels",
            "en-us": "Horiz. panels",
            "es": "Elementos horizontales",
            "fr": "Px couchés",
            "it": "Elementi orizz.",
            "hu": "Elemek fekvo",
            "ru": "Элементы, установленные горизонтально",
            "sk": "Panely naležato",
            "pl": "Płyty poziome"
        },
        {
            "ID": "XALUFR_A11",
            "de": "Bauwerksstütze",
            "en-gb": "Column",
            "en-us": "Column",
            "es": "Pilar",
            "fr": "Poteau",
            "it": "Pilastro",
            "hu": "Pillér",
            "ru": "Колонна",
            "sk": "Stĺp",
            "pl": "Słup"
        },
        {
            "ID": "XALUFR_A11_AW2",
            "de": "Elemente mit Außenecken",
            "en-gb": "Panels with outside corners",
            "en-us": "Panels with outside corners",
            "es": "Elem. con esqu. ext.",
            "fr": "Px avec angle ext",
            "it": "Elemente con angoli esterni",
            "hu": "Elem külso sarokkal",
            "ru": "Элементы с внешними углами",
            "sk": "Panely s vonkaj. roh",
            "pl": "Płyty z narożnikami zewnętrznymi"
        },
        {
            "ID": "XALUFR_A11_AW5",
            "de": "Alu-Uni-Elemente mit Stützenbühne",
            "en-gb": "Alu universal panels with column formwork platform",
            "en-us": "Alu universal panels with column formwork platform",
            "es": "Alu elem. univ. con plataf. para pilar",
            "fr": "Px univ alu avec passerelle p.coff.poteau",
            "it": "Alu elemento uni con passerella per pilastri",
            "hu": "Alu uni elem pillérállvány",
            "ru": "Алюминиевые универсальные элементы с подмостями для колонн",
            "sk": "Alu-univerzálny panel so stlp. plošinou",
            "pl": "Aluminiowe płyty uniwersalne z pomostem do słupa"
        },
        {
            "ID": "XALUFR_A11_AW6",
            "de": "Alu-Uni-Elemente mit Konsole",
            "en-gb": "Alu universal panels with bracket",
            "en-us": "Alu universal panels with bracket",
            "es": "Alu elem. univ. con ménsula",
            "fr": "Px univ alu avec console",
            "it": "Alu elemento uni con mensola",
            "hu": "Alu uni elem betonozó konzollal",
            "ru": "Алюминиевые универсальные элементы с консолями",
            "sk": "Alu-univerzálny panel s konzolou",
            "pl": "Aluminiowe płyty uniwersalne z konsolami"
        },
        {
            "ID": "XALUFR_A12",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "XALUFR_A12_AW1",
            "de": "Ankerstab 15,0 unbehandelt",
            "en-gb": "Tie rod 15.0 non-treated",
            "en-us": "Tie rod 15.0 non-treated",
            "es": "Barra de ancl. 15,0 no tratada",
            "fr": "Tige d'ancrage 15,0 non traitée",
            "it": "Barra ancorante 15,0 non trattata",
            "hu": "Ankerrúd 15,0 kezeletlen",
            "ru": "Анкерный стержень 15,0 необработанный",
            "sk": "Kotev. tyc 15,0 neupravov.",
            "pl": "Ściąg 15,0 nieocynkowany"
        },
        {
            "ID": "XALUFR_A12_AW2",
            "de": "Ankerstab 15,0 verzinkt",
            "en-gb": "Tie rod 15.0 galvanised",
            "en-us": "Tie rod 15.0 galvanized",
            "es": "Barra de ancl. 15,0 galv.",
            "fr": "Tige d'ancrage 15,0 galvanisée",
            "it": "Barra ancorante 15,0 zincata",
            "hu": "Ankerrúd 15,0 horganyzott",
            "ru": "Анкерный стержень 15,0 оцинкованный",
            "sk": "Kotev. tyc 15,0 pozinkov.",
            "pl": "Ściąg 15,0 ocynkowany"
        },
        {
            "ID": "XALUFR_A12_AW3",
            "de": "Kopfanker 15-40cm",
            "en-gb": "Head anchor 15-40cm",
            "en-us": "Head anchor 15-40cm",
            "es": "Anclaje superior 15-40cm",
            "fr": "Ancrage de tête 15-40cm",
            "it": "Ancorante di testa 15-40cm",
            "hu": "Fejanker 15-40cm",
            "ru": "Головной анкер 15-40см",
            "sk": "Vrchná kotva 15-40cm",
            "pl": "Dystans górny 15-40cm"
        },
        {
            "ID": "XALUFR_A12_AW4",
            "de": "Kopfanker 15-100cm",
            "en-gb": "Head anchor 15-100cm",
            "en-us": "Head anchor 15-100cm",
            "es": "Anclaje superior 15-100cm",
            "fr": "Ancrage de tête 15-100cm",
            "it": "Ancorante di testa 15-100cm",
            "hu": "Fejanker 15-100cm",
            "ru": "Головной анкер 15-100см",
            "sk": "Vrchná kotva 15-100cm",
            "pl": "Dystans górny 15-100cm"
        },
        {
            "ID": "XALUFR_A13",
            "de": "Einseitige Ecke und Kreuzung",
            "en-gb": "One-sided corner and crossing",
            "en-us": "One-sided corner and crossing",
            "es": "Esquina de un solo lado y cruce",
            "fr": "Une face angle et croisement",
            "it": "Angolo unilaterale e incrocio",
            "hu": "Egyoldali sarok és keresztcsatlakozás",
            "ru": "Односторонний угол и крестообразное соединение",
            "sk": "Jednostranný roh a križovanie",
            "pl": "Narożnik jednostronny i połączenie krzyżowe"
        },
        {
            "ID": "XALUFR_A13_AW1",
            "de": "Ausschalecke",
            "en-gb": "Stripping corner",
            "en-us": "Bias cut corner",
            "es": "Esquina de desencofrado",
            "fr": "Angle de décoffrage",
            "it": "Angolo di disarmo",
            "hu": "Kizsaluzó sarokelem",
            "ru": "Распалубочный уголок",
            "sk": "Oddebnovací roh",
            "pl": "Narożnik rozszalowujący"
        },
        {
            "ID": "XALUFR_A13_AW2",
            "de": "Außen- oder Innenecke",
            "en-gb": "Outside or inside corner",
            "en-us": "Outside or inside corner",
            "es": "Esqu. ext. o esqu. int.",
            "fr": "Angle ext. ou int.",
            "it": "Angolo est. o int.",
            "hu": "Külso vagy Belso sarok",
            "ru": "Внешний или внутренний угол",
            "sk": "Vonk. roh al. vnút. roh",
            "pl": "Narożnik zewnętrzny lub wewnętrzny"
        },
        {
            "ID": "XALUFR_A13_AW3",
            "de": "Scharnier-Außen. oder Innenecke",
            "en-gb": "Hinged outside or hinged inside corner",
            "en-us": "Hinged outside corner or hinge inside corner",
            "es": "Esqu. bisagra ext. o esqu. int.",
            "fr": "Angle charnière ext ou angle int",
            "it": "Ang. cerniera est. o Angolo interno",
            "hu": "Csukl.külso sarok vagy Belso sarok",
            "ru": "Шарнирный внешний или внутренний угол",
            "sk": "Klb.vonk.roh al. vnút. roh",
            "pl": "Narożnik zewnętrzny zawiasowy lub narożnik wewnętrzny zawiasowy"
        },
        {
            "ID": "XALUFR_A13_AW4",
            "de": "Scharnier-Außen- oder Innenecke verz.",
            "en-gb": "Hinged outside or hinged inside corner galv.",
            "en-us": "Hinged outside or hinged inside corner galv.",
            "es": "Esqu. bisagra ext. o esqu. int.  galv.",
            "fr": "Angle charnière ext ou angle int  galv.",
            "it": "Ang. cerniera est. o Angolo interno  galv.",
            "hu": "Csukl.külso sarok vagy Belso sarok horg.",
            "ru": "Шарнирный внешний или внутренний угол оцинк.",
            "sk": "Klb.vonk.roh al. vnút. roh poz.",
            "pl": "Narożnik zewnętrzny zawiasowy lub narożnik wewnętrzny zawiasowy ocynk."
        },
        {
            "ID": "XALUFR_A14",
            "de": "Stehende oder liegende Aufstockung der Elemente",
            "en-gb": "Upright or horiz. stacking of panels",
            "en-us": "Upright or horiz. stacking of panels",
            "es": "Apilado horizontal o vertical de los elementos",
            "fr": "Rehausse de px debout ou couchés",
            "it": "Sovrapposizione orizzontale o verticale degli elementi",
            "hu": "állított ill. fektetett elemmagasítás",
            "ru": "Вертикальная или горизонтальная надстройка элементов",
            "sk": "Nadstavenie panelov nastojato alebo naležato",
            "pl": "Pionowe lub poziome nadstawianie płyt"
        },
        {
            "ID": "XALUFR_A14_AW1",
            "de": "ohne Aufstockung",
            "en-gb": "w/o stacking",
            "en-us": "w/o stacking",
            "es": "Sin apilado",
            "fr": "sans rehausse",
            "it": "senza sopralzo",
            "hu": "Magasítás nélkül",
            "ru": "без надстройки",
            "sk": "bez nadstavenia",
            "pl": "bez nadstawienia"
        },
        {
            "ID": "XALUFR_A14_AW2",
            "de": "Stehend aufgestockt",
            "en-gb": "stacked upright",
            "en-us": "stacked upright",
            "es": "Apilado vertical",
            "fr": "Rehaussé deboutant",
            "it": "Sovrapposizione vert.",
            "hu": "Állítva magasítva",
            "ru": "надстройка в вертикальном положении",
            "sk": "Nadstavenie nastojato",
            "pl": "nadstawienie pionowe"
        },
        {
            "ID": "XALUFR_A14_AW3",
            "de": "Liegende Aufgestockt",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz.",
            "es": "Apilado horizontal",
            "fr": "Rehaussé couchant",
            "it": "Sovrapposizione orizz.",
            "hu": "Fektetve magasítva",
            "ru": "надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "pl": "nadstawienie poziome"
        },
        {
            "ID": "XALUFR_A15",
            "de": "Längsanschluss",
            "en-gb": "In-line connections",
            "en-us": "In-line connections",
            "es": "Unión longitudinal",
            "fr": "Connexion en longueur",
            "it": "Collegamento longitudinale",
            "hu": "Hosszirányú csatlakozás",
            "ru": "Продольный стык",
            "sk": "Pozdĺžne pripojenie",
            "pl": "Połączenia w linii"
        },
        {
            "ID": "XALUFR_A15_AW1",
            "de": "Normaler Anschluss",
            "en-gb": "Standard connection",
            "en-us": "Standard connection",
            "es": "Unión normal",
            "fr": "Raccord normal",
            "it": "Raccordo normale",
            "hu": "Normál csatlakozás",
            "ru": "Обычный стык",
            "sk": "Normálne pripojenie",
            "pl": "Połączenie standardowe"
        },
        {
            "ID": "XALUFR_A15_AW2",
            "de": "Elemente vom Vorgängertakt verwenden",
            "en-gb": "Use panels of previous cycle",
            "en-us": "Use panels of previous pour",
            "es": "Utilizar los elementos del ciclo anterior",
            "fr": "Utiliser les éléments du coffrage précédent",
            "it": "Utilizza elementi del ciclo precedente",
            "hu": "Az előző ciklus elemeinek alkalmazása",
            "ru": "Использовать элементы предыдущего такта",
            "sk": "Použiť prvky z predchádzajúceho taktu",
            "pl": "Użyj płyt z poprzedniego taktu"
        },
        {
            "ID": "XALUFR_A16",
            "de": "Ausgleichstyp",
            "en-gb": "Closure type",
            "en-us": "Filler type",
            "es": "Tipo de compensación",
            "fr": "Type de compensation",
            "it": "Tipo di compensazione",
            "hu": "Kiegyenlítés típusa",
            "ru": "Тип компенсации",
            "sk": "Typ vyrovnania",
            "pl": "Rodzaj wypełnienia"
        },
        {
            "ID": "XALUFR_A16_AW1",
            "de": "Passholz Doka, Ankerung im Element",
            "en-gb": "Fitting timber Doka, ties through panel",
            "en-us": "Fitting timber Doka, ties through panel",
            "es": "Tablón de ajuste Doka, anclaje en el elemento",
            "fr": "Fourrure en bois Doka,  ancrage dans le panneau",
            "it": "Tavola di compensazione Doka, anc. nell'elemento",
            "hu": "Illeszto fabetét Doka, ankerozás az elemben",
            "ru": "Пригнанный брус Doka, анкерование в элементе",
            "sk": "Vyrovnávací hranol Doka, kotvenie v paneli",
            "pl": "Wstawka komp. Doka, ściagi przez płytę"
        },
        {
            "ID": "XALUFR_A16_AW10",
            "de": "mit Schalungsplatte 21mm und Klemmschiene 1,50m",
            "en-gb": "with formwork sheet 21mm and universal waling 1.50m",
            "en-us": "with formwork sheet 21mm and universal waling 1.50m",
            "es": "con tablero de encofrado 21mm y riel de fijación 1,50m",
            "fr": "avec contreplaqué 21mm et rails de blocage 1,50m",
            "it": "con pannello 21mm e rotaia di fissaggio 1,50m",
            "hu": "21mm-es zsaluhéjjal és 1,50m-es kötősínnel",
            "ru": "с опалубочная плита 21мм и зажимная шина 1,50м",
            "sk": "s debniacou doskou 21mm a upínacou koľajnicou 1,50m",
            "pl": "ze sklejką 21mm i uniwersalnym ryglem ściennym 1,50m"
        },
        {
            "ID": "XALUFR_A16_AW2",
            "de": "Füllkörper bauseits, Ankerung im Element",
            "en-gb": "Filler block by site, ties through panel",
            "en-us": "Filler block by site, ties through panel",
            "es": "Cuerpo de relleno (por parte de obra), anclaje en el elemento",
            "fr": "Corps de remplissage (chantier), ancrage dans le panneau",
            "it": "Compensazione (a carico cant.), anc. nell'elemento",
            "hu": "Kiegészítő betét építéshelyi, ankerozás az elemben",
            "ru": "Вкладыш (силами заказчика), анкерование в элементе",
            "sk": "Výplň (zo strany stavby), kotvenie v paneli",
            "pl": "Wstawka po str. budowy, ściagi przez płytę"
        },
        {
            "ID": "XALUFR_A16_AW4",
            "de": "mit Schalungsplatte 27mm und Klemmschiene 0,90m",
            "en-gb": "with formwork sheet 27mm and universal waling 0.90m",
            "en-us": "with formwork sheet 27mm and universal waling 0.90m",
            "es": "con tablero de encofrado 27mm y riel de fijación 0,90m",
            "fr": "avec contreplaqué 27mm et rail de blocage 0,90m",
            "it": "con pannello 27mm e rotaia di fissaggio 0,90m",
            "hu": "27mm-es zsaluhéjjal és 0,90m-es kötősínnel",
            "ru": "с опалубочная плита 27мм и зажимная шина 0,90м",
            "sk": "s debniacou doskou 27mm a upínacou koľajnicou 0,90m",
            "pl": "ze sklejką 27mm i uniwersalnym ryglem ściennym 0,90m"
        },
        {
            "ID": "XALUFR_A16_AW5",
            "de": "mit Schalungsplatte 27mm und Klemmschiene 1,50m",
            "en-gb": "with formwork sheet 27mm and universal waling 1.50m",
            "en-us": "with formwork sheet 27mm and universal waling 1.50m",
            "es": "con tablero de encofrado 27mm y riel de fijación 1,50m",
            "fr": "avec contreplaqué 27mm et rail de blocage 1,50m",
            "it": "con pannello 27mm e rotaia di fissaggio 1,50m",
            "hu": "27mm-es zsaluhéjjal és 1,50m-es kötősínnel",
            "ru": "с опалубочная плита 27мм и зажимная шина 1,50м",
            "sk": "s debniacou doskou 27mm a upínacou koľajnicou 1,50m",
            "pl": "ze sklejką 27mm i uniwersalnym ryglem ściennym 1,50m"
        },
        {
            "ID": "XALUFR_A16_AW6",
            "de": "Passholz Doka, Ankerung im Ausgleich",
            "en-gb": "Fitting timber Doka, ties through closure",
            "en-us": "Fitting timber Doka, ties through filler",
            "es": "Tablón de ajuste Doka, anclaje en compensación",
            "fr": "Fourrure en bois Doka, ancrage dans le compens.",
            "it": "Tavola di compensazione Doka, anc. nella compens.",
            "hu": "Illeszto fabetét Doka, ankerozás a kiegyenlítésen keresztül",
            "ru": "Пригнанный брус Doka, анкерование в компенсатор",
            "sk": "Vyrovnávací hranol Doka, kotvenie vo vyrovnaní",
            "pl": "Wstawka komp. Doka, ściagi przez wstawkę"
        },
        {
            "ID": "XALUFR_A16_AW7",
            "de": "Füllkörper bauseits, Ankerung im Ausgleich",
            "en-gb": "Filler block by site, ties through closure",
            "en-us": "Filler block by site, ties through filler",
            "es": "Cuerpo de relleno (por parte de obra), anclaje en compensación",
            "fr": "Corps de remplissage (chantier), ancrage dans le panneau",
            "it": "Compensazione (a carico cant.), anc. nella compens.",
            "hu": "Kiegészítő betét építéshelyi, ankerozás a kiegyenlítésen keresztül",
            "ru": "Вкладыш (силами заказчика), анкерование в компенсатор",
            "sk": "Výplň (zo strany stavby), kotvenie vo vyrovnaní",
            "pl": "Wstawka po str. budowy, ściagi przez wstawkę"
        },
        {
            "ID": "XALUFR_A16_AW8",
            "de": "Alu-Ausgleich, Ankerung im Ausgleich",
            "en-gb": "Alu-filler, ties through closure",
            "en-us": "Alu-filler, ties through filler",
            "es": "Compensación de alu, anclaje en compensación",
            "fr": "Pièce de compensation alu, ancrage dans le compens.",
            "it": "Compensazioni in alu, anc. nella compens.",
            "hu": "Alu-betét, ankerozás a kiegyenlítésen keresztül",
            "ru": "Alu-компенсатор, анкерование в компенсатор",
            "sk": "Alu-vyrovnanie, kotvenie vo vyrovnaní",
            "pl": "Wstawka aluminiowa, ściagi przez wstawkę"
        },
        {
            "ID": "XALUFR_A16_AW9",
            "de": "mit Schalungsplatte 21mm und Klemmschiene 0,90m",
            "en-gb": "with formwork sheet 21mm and universal waling 0.90m",
            "en-us": "with formwork sheet 21mm and universal waling 0.90m",
            "es": "con tablero de encofrado 21mm y riel de fijación 0,90m",
            "fr": "avec contreplaqué 21mm et rails de blocage 0,90m",
            "it": "con pannello 21mm e rotaia di fissaggio 0,90m",
            "hu": "21mm-es zsaluhéjjal és 0,90m-es kötősínnel",
            "ru": "с опалубочная плита 21мм и зажимная шина 0,90м",
            "sk": "s debniacou doskou 21mm a upínacou koľajnicou 0,90m",
            "pl": "ze sklejką 21mm i uniwersalnym ryglem ściennym 0,90m"
        },
        {
            "ID": "XALUFR_A17",
            "de": "Basiselement 3,00m",
            "en-gb": "Basic panel 3.00m",
            "en-us": "Basic panel 3.00m",
            "es": "Altura base 3,00m",
            "fr": "Élément de base 3,00m",
            "it": "Elemento di base 3,00m",
            "hu": "Báziselem 3,00m",
            "ru": "Базовый элемент 3,00м",
            "sk": "Základný panel 3,00m",
            "pl": "Płyta podstawowa 3,00m"
        },
        {
            "ID": "XALUFR_A17_AW1",
            "de": "nicht bevorzugt",
            "en-gb": "not preferred",
            "en-us": "not preferred",
            "es": "No preferido",
            "fr": "Ne pas préféré",
            "it": "non preferito",
            "hu": "nem preferált",
            "ru": "не предпочтительно",
            "sk": "nie je uprednostnené",
            "pl": "niepreferowany"
        },
        {
            "ID": "XALUFR_A17_AW2",
            "de": "bevorzugt",
            "en-gb": "preferred",
            "en-us": "preferred",
            "es": "preferido",
            "fr": "préféré",
            "it": "preferito",
            "hu": "preferált",
            "ru": "предпочтительно",
            "sk": "uprednostnené",
            "pl": "preferowany"
        },
        {
            "ID": "XALUFR_A2",
            "de": "Verbindung der Aufstockung",
            "en-gb": "Connection of stacking",
            "en-us": "Connection of stack",
            "es": "Conexión del apil.",
            "fr": "Connexion del rehausse",
            "it": "Giunzione della sovrapposizione",
            "hu": "Magasítás összekötés",
            "ru": "Соединение надстройки",
            "sk": "Spojenie nadstavenia",
            "pl": "Połączenie nadstawienia"
        },
        {
            "ID": "XALUFR_A2_AW1",
            "de": "Schnellspanner RU+KS",
            "en-gb": "Quick acting clamp RU+KS",
            "en-us": "Quick acting clamp RU+KS",
            "es": "Grapa unión ráp. RU+KS",
            "fr": "Serrage rapide RU+KS",
            "it": "Morsetto rapido RU+KS",
            "hu": "Gyorskapcsoló RU+KS",
            "ru": "Быстродействующий замок RU+KS",
            "sk": "Rýchloupínac RU+KS",
            "pl": "Zamek RU+KS"
        },
        {
            "ID": "XALUFR_A2_AW2",
            "de": "Uni-Spanner",
            "en-gb": "Multi function clamp",
            "en-us": "Multi function clamp",
            "es": "Grapa unión univ.",
            "fr": "Tendeur rapide universel",
            "it": "Morsetto universale",
            "hu": "Uni. kapcsoló",
            "ru": "Универсальный зажим",
            "sk": "Univ. upínač",
            "pl": "Zamek uniwersalny"
        },
        {
            "ID": "XALUFR_A2_AW3",
            "de": "nicht aufgestockt bevorzugt",
            "en-gb": "not stacked preferred",
            "en-us": "not stacked preferred",
            "es": "No apilado preferido",
            "fr": "Ne pas rehaussé",
            "it": "Preferibilmente senza sovrapposizione",
            "hu": "magasítás nélküli preferált",
            "ru": "предпочтительно без надстройки",
            "sk": "nadstavenie nie je uprednostnené",
            "pl": "bez nadstawienia, preferowany"
        },
        {
            "ID": "XALUFR_A4",
            "de": "Elementtyp",
            "en-gb": "Panel type",
            "en-us": "Panel type",
            "es": "Tipo de elemento",
            "fr": "Type d'élément",
            "it": "Tipo elemento",
            "hu": "Elemtípus",
            "ru": "Тип элемента",
            "sk": "Typ panelu",
            "pl": "Typ płyty"
        },
        {
            "ID": "XALUFR_A4_AW2",
            "de": "Normalelement",
            "en-gb": "Standard panel",
            "en-us": "Standard panel",
            "es": "Elemento normal",
            "fr": "Élément étalon",
            "it": "Elemento normale",
            "hu": "Normál tábla",
            "ru": "Унифицированный элемент",
            "sk": "Štandardný panel",
            "pl": "Płyta standardowa"
        },
        {
            "ID": "XALUFR_A4_AW3",
            "de": "Uni-Element",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Panneau universel",
            "it": "Elemento uni",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Univerzálny panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XALUFR_A6",
            "de": "Stirnabschalung",
            "en-gb": "Stop-end formwork",
            "en-us": "Bulkhead",
            "es": "Tape",
            "fr": "Coffrage d'about",
            "it": "Chiusura di testa",
            "hu": "Véglezárás",
            "ru": "Торцевые участки опалубки",
            "sk": "Debnenie cela",
            "pl": "Szalunek czołowy"
        },
        {
            "ID": "XALUFR_A6_AW1",
            "de": "Stirnanker ohne Element",
            "en-gb": "Stop-end tie without panel",
            "en-us": "Stop-end tie without panel",
            "es": "Anclaje de tape sin elem.",
            "fr": "Ancrage d'about sans Px",
            "it": "Morsetto per testata senza elemento",
            "hu": "Homlokanker elem nélkül",
            "ru": "Торцевой анкер без элемента",
            "sk": "Celová kotva bez panel",
            "pl": "Deskowanie czołowe bez płyty"
        },
        {
            "ID": "XALUFR_A6_AW10",
            "de": "Stirnanker mit Element",
            "en-gb": "Stop-end tie with panel",
            "en-us": "Stop-end tie with panel",
            "es": "Anclaje de tape con elem.",
            "fr": "Ancrage d'about avec Px",
            "it": "Morsetto per testata con elemento",
            "hu": "Homlokanker elemmel",
            "ru": "Торцевой анкер с элементом",
            "sk": "Celová kotva so panel",
            "pl": "Deskowanie czołowe z płytą"
        },
        {
            "ID": "XALUFR_A6_AW11",
            "de": "Stirnabschalzwinge",
            "en-gb": "Stop-end waler tie",
            "en-us": "Stop-end waler tie",
            "es": "Sargento para tape",
            "fr": "Rail d'about",
            "it": "Corrente di testata",
            "hu": "Véglezáró sín",
            "ru": "Торцевая накладка",
            "sk": "Zvierka debnenia čiel",
            "pl": "Zacisk deskowania czołowego"
        },
        {
            "ID": "XALUFR_A6_AW3",
            "de": "Universalverbinder mit Element",
            "en-gb": "Universal fixing bolt with panel",
            "en-us": "Universal fixing bolt with panel",
            "es": "Conector univ. con elem.",
            "fr": "Boulon d'assemblage universel avec Px",
            "it": "Tirante universale con elemento",
            "hu": "Univerzális kötocsavar elemmel",
            "ru": "Универсальный соединитель с элементом",
            "sk": "Univerzálna spojka so panel",
            "pl": "Łącznik uniwersalny z płytą"
        },
        {
            "ID": "XALUFR_A6_AW5",
            "de": "Außenecke",
            "en-gb": "Outisde corner",
            "en-us": "Outisde corner",
            "es": "Esqu. ext.",
            "fr": "Angle ext",
            "it": "Angolo esterno",
            "hu": "Külso sarok",
            "ru": "Внешние угловые части",
            "sk": "Vonkajší roh",
            "pl": "Narożnik zewnętrzny"
        },
        {
            "ID": "XALUFR_A6_AW6",
            "de": "Universalelement",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Px univ.",
            "it": "Elem. universale",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Uni. panelt",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XALUFR_A6_AW8",
            "de": "Profilholz Ausgleichsspanner",
            "en-gb": "Moulded timber adjustable clamp",
            "en-us": "Molded timber adjustable clamp",
            "es": "Perfil de madeira grapa de comp.",
            "fr": "Liteau profilé tendeur de compensation",
            "it": "Profilo in legno morsetto di compensazione",
            "hu": "Profilos fabetét Kiegyenlíto kapcsoló",
            "ru": "Профильный брус компенсационный зажим",
            "sk": "Drev.prof. vyrovn. upínac",
            "pl": "Drewno profilowane – zacisk regulowany"
        },
        {
            "ID": "XALUFR_A6_AW9",
            "de": "Profilholz Ausgleichsspanner und Fugenband",
            "en-gb": "Moulded timber adjustable clamp and joint strip",
            "en-us": "Molded timber adjustable clamp and joint strip",
            "es": "Perfil de madeira grapa de comp. y junta de estanqu.",
            "fr": "Liteau profilé tendeur de comp. et joint dil",
            "it": "Profilo in legno morsetto di compensazione e nastro giunti",
            "hu": "Profilos fabetét Kiegyenlíto kapcsoló és Fúgaszalag",
            "ru": "Профильный брус компенсационный зажим и лента для уплотнения швов",
            "sk": "Drev.prof. vyrovn. upínac a tesn.pás",
            "pl": "Drewno profilowane – zacisk regulowany i listwa"
        },
        {
            "ID": "XALUFR_A7",
            "de": "Ausgleichsverbindung",
            "en-gb": "Compensation connection",
            "en-us": "Compensation connection",
            "es": "Unión de compensación",
            "fr": "Connexion de compensation",
            "it": "Giunzione per compensazione",
            "hu": "Kiegyenlítés összekötés",
            "ru": "Компенсационное соединение",
            "sk": "Spojenie vo vyrovnaní",
            "pl": "Połączenie kompensacyjne"
        },
        {
            "ID": "XALUFR_A7_AW1",
            "de": "Uni-Spanner",
            "en-gb": "Multi function clamp",
            "en-us": "Multi function clamp",
            "es": "Grapa unión univ.",
            "fr": "Tendeur rapide universel",
            "it": "Morsetto universale",
            "hu": "Uni. kapcsoló",
            "ru": "Универсальный зажим",
            "sk": "Univ. upínač",
            "pl": "Zamek uniwersalny"
        },
        {
            "ID": "XALUFR_A7_AW2",
            "de": "Ausgleichsspanner",
            "en-gb": "Adjustable clamp",
            "en-us": "Adjustable clamp",
            "es": "Grapa de compensación",
            "fr": "Tendeur de compensation",
            "it": "Morsetto di compensazione",
            "hu": "Kiegyenlíto kapcsoló",
            "ru": "Компенсационный зажим",
            "sk": "Vyrovn. upínac",
            "pl": "Zamek wyrównujący"
        },
        {
            "ID": "XALUFR_A8",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "XALUFR_A8_AW1",
            "de": "Innenecke und Universal-Element (0.90,0.75 breit)",
            "en-gb": "Inside corner and universal panel (0.90,0.75 wide)",
            "en-us": "Inside corner and universal panel (0.90,0.75 wide)",
            "es": "Esqu. int. y elem. univ. (0.90,0.75 ancho)",
            "fr": "Angle int et Px univ (0.90,0.75 large)",
            "it": "Angolo interno e elemento uni (0.90,0.75 largo)",
            "hu": "Belso sarok és uni elem (0.90,0.75szélesség)",
            "ru": "Внутренний угол и универсальный элемент (ширина 0.90,0.75)",
            "sk": "Vnút. rohy + univ. panel (0.90,0.75 šírky)",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna (szerokość 0,90,0,75)"
        },
        {
            "ID": "XALUFR_A8_AW10",
            "de": "Scharnier-Innenecke verz. und Scharnier-Außenecke verz.",
            "en-gb": "Hinged inside corner galv. and hinged outside corner galv.",
            "en-us": "Hinged inside corner galv. and hinged outside corner galv.",
            "es": "Esqu. bisagra int. galv. y esqu. bisagra ext. galv.",
            "fr": "Angle charnière int galv. et angle charnière ext galv.",
            "it": "Angolo cerniera int. galv. e angolo cerniera est. galv.",
            "hu": "Csukl.belso sarok horg. és csukl.külso sarok horg.",
            "ru": "Шарнирный внутренний угол оцинк. и шарнирный внешний угол оцинк.",
            "sk": "Klbový vnútor. roh poz. a klb.vonk.roh poz.",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i narożnik zewnętrzny zawiasowy ocynk."
        },
        {
            "ID": "XALUFR_A8_AW2",
            "de": "Innenecke und Außenecke",
            "en-gb": "Inside corner and outside corner",
            "en-us": "Inside corner and outside corner",
            "es": "Esqu. int. y esqu. ext.",
            "fr": "Angle int et angle ext",
            "it": "Angolo interno e angolo esterno",
            "hu": "Belso sarok és külso sarok",
            "ru": "Внутренний угол и внешний угол",
            "sk": "Vnút. rohy a vonkaj. roh",
            "pl": "Narożnik wewnętrzny i narożnik zewnętrzny"
        },
        {
            "ID": "XALUFR_A8_AW3",
            "de": "Scharnier-Innenecke und Uni-Element",
            "en-gb": "Hinged inside corner and universal panel",
            "en-us": "Hinged inside corner and universal panel",
            "es": "Esqu. bisagra int. y elem. univ.",
            "fr": "Angle charnière int et Px univ",
            "it": "Angolo cerniera int. ed elemento uni",
            "hu": "Csukl.belso sarok és uni elem",
            "ru": "Шарнирный внутренний угол и универсальный элемент",
            "sk": "Klbový vnútor. roh a univ. panel",
            "pl": "Narożnik wewnętrzny zawiasowy i płyta uniwersalna"
        },
        {
            "ID": "XALUFR_A8_AW4",
            "de": "Scharnier-Innenecke und Außenecke",
            "en-gb": "Hinged inside corner and outside corner",
            "en-us": "Hinged inside corner and outside corner",
            "es": "Esqu. bisagra int. y esqu. ext.",
            "fr": "Angle charnière int et angle ext",
            "it": "Angolo cerniera int. e angolo esterno",
            "hu": "Csukl.belso sarok és külso sarok",
            "ru": "Шарнирный внутренний угол и внешний угол",
            "sk": "Klbový vnútor. roh a vonkaj. roh",
            "pl": "Narożnik wewnętrzny zawiasowy i narożnik zewnętrzny"
        },
        {
            "ID": "XALUFR_A8_AW5",
            "de": "Scharnier-Innenecke innen und außen",
            "en-gb": "Hinged inside corner inside and outside",
            "en-us": "Hinged inside corner inside and outside",
            "es": "Esqu. bisagra int. int. y ext.",
            "fr": "Angle charnière int int et ext",
            "it": "Angolo cerniera int. int. e est.",
            "hu": "Csukl.belso sarok belül és kívül",
            "ru": "Шарнирный внутренний угол изнутри и снаружи",
            "sk": "Klbový vnútor. roh zvnútra a zvonka",
            "pl": "Narożnik wewnętrzny zawiasowy wewnątrz i na zewnątrz"
        },
        {
            "ID": "XALUFR_A8_AW6",
            "de": "Scharnier-Innenecke und Scharnier-Außenecke",
            "en-gb": "Hinged inside corner and hinged outside corner",
            "en-us": "Hinged inside corner and hinged outside corner",
            "es": "Esqu. bisagra int. y esqu. bisagra ext.",
            "fr": "Angle charnière int et Angle charnière ext",
            "it": "Angolo cerniera int. e angolo cerniera est.",
            "hu": "Csukl.belso sarok és csukl.külso sarok",
            "ru": "Шарнирный внутренний угол и шарнирный внешний угол",
            "sk": "Klbový vnútor. roh a klb.vonk.roh",
            "pl": "Narożnik wewnętrzny zawiasowy i narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "XALUFR_A8_AW7",
            "de": "Scharnier-Innenecke verz. und Uni-Element",
            "en-gb": "Hinged inside corner galv. and universal panel",
            "en-us": "Hinged inside corner galv. and universal panel",
            "es": "Esqu. bisagra int. galv. y elem. univ.",
            "fr": "Angle charnière int galv. et Px univ",
            "it": "Angolo cerniera int. galv. ed elemento uni",
            "hu": "Csukl.belso sarok horg. és uni elem",
            "ru": "Шарнирный внутренний угол оцинк. и универсальный элемент",
            "sk": "Klbový vnútor. roh poz. a univ. panel",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i płyta uniwersalna"
        },
        {
            "ID": "XALUFR_A8_AW8",
            "de": "Scharnier-Innenecke verz. und Außenecke verz.",
            "en-gb": "Hinged inside corner galv.and outside corner galv.",
            "en-us": "Hinged inside corner galv.and outside corner galv.",
            "es": "Esqu. bisagra int. galv. y esqu. ext. galv.",
            "fr": "Angle charnière int galv. et angle ext galv.",
            "it": "Angolo cerniera int. galv. e angolo esterno galv.",
            "hu": "Csukl.belso sarok horg. és külso sarok horg.",
            "ru": "Шарнирный внутренний угол оцинк. и внешний угол оцинк.",
            "sk": "Klbový vnútor. roh poz. a vonkaj. roh poz.",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i narożnik zewnętrzny ocynk."
        },
        {
            "ID": "XALUFR_A8_AW9",
            "de": "Scharnier-Innenecke verz. innen und außen",
            "en-gb": "Hinged inside corner galv. inside and outside",
            "en-us": "Hinged inside corner galv. inside and outside",
            "es": "Esqu. bisagra int. galv. int. y ext.",
            "fr": "Angle charnière int galv. int et ext",
            "it": "Angolo cerniera int. galv. int. e est.",
            "hu": "Csukl.belso sarok horg. belül és kívül",
            "ru": "Шарнирный внутренний угол оцинкованный изнутри и снаружи",
            "sk": "Klbový vnútor. roh poz. zvnútra a zvonka",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. wewnątrz i na zewnątrz"
        },
        {
            "ID": "XALUFR_A9",
            "de": "Wandanschlüsse",
            "en-gb": "Wall connections",
            "en-us": "Wall connections",
            "es": "Acometidas de muros",
            "fr": "Connexions de mur",
            "it": "Agganci per parete",
            "hu": "Falcsatlakozás",
            "ru": "Стыки стен",
            "sk": "Napájanie stien",
            "pl": "Połączenia ścienne"
        },
        {
            "ID": "XALUFR_A9_AW1",
            "de": "Passhölzer",
            "en-gb": "Fitting timbers",
            "en-us": "Fitting timbers",
            "es": "Tablónes de ajuste",
            "fr": "Fourrure bois",
            "it": "Tavoli di compensazione",
            "hu": "Fabetétek",
            "ru": "Пригнанные брусья",
            "sk": "Vyr. hranoly",
            "pl": "Wstawki kompensacyjne"
        },
        {
            "ID": "XALUFR_A9_AW2",
            "de": "Passhölzer mit Uni-spanner",
            "en-gb": "Fitting timbers with multi function clamp",
            "en-us": "Fitting timbers with multi function clamp",
            "es": "Tablónes de ajuste con grapa unión univ.",
            "fr": "Fourrure bois avec tendeur rapide",
            "it": "Tavoli di compensazione con morsetto universale",
            "hu": "Fabetétek univerzális kapcsolóval",
            "ru": "Пригнанные брусья с универсальными зажимами",
            "sk": "Vyr. hranoly s univ. upínac",
            "pl": "Wstawki kompensacyjne z zamkiem uniwersalnym"
        },
        {
            "ID": "XALUFR_A9_AW3",
            "de": "Passhölzer mit Ausgl.spanner",
            "en-gb": "Fitting timbers with adjustable clamp",
            "en-us": "Fitting timbers with adjustable clamp",
            "es": "Tablónes de ajuste con grapa de comp",
            "fr": "Fourrure bois avec tendeur de compensation",
            "it": "Tavoli di compensazione con morsetto di compensazione",
            "hu": "Fabetétek kiegyenlíto kapcsolóval",
            "ru": "Пригнанные брусья с компенсационными зажимами",
            "sk": "Vyr. hranoly so vyrovnávací upínac",
            "pl": "Wstawka komp. z zaciskiem regulowanym"
        },
        {
            "ID": "XALUFR_A9_AW4",
            "de": "Kanthölzer",
            "en-gb": "Squared timbers",
            "en-us": "Squared timbers",
            "es": "Listónes",
            "fr": "Bois",
            "it": "Travi squadrate",
            "hu": "Fabetét",
            "ru": "Бруски",
            "sk": "Hranoly a upínac.",
            "pl": "Kantówki"
        },
        {
            "ID": "XFR30_A1",
            "de": "Elementtyp",
            "en-gb": "Panel type",
            "en-us": "Panel type",
            "es": "Tipo de elemento",
            "fr": "Type d'elément",
            "it": "Tipo elemento",
            "hu": "Elemtípus",
            "ru": "Тип элемента",
            "sk": "Typ panelu",
            "pl": "Typ płyty"
        },
        {
            "ID": "XFR30_A1_AW1",
            "de": "Normalelement (1.35,0.90,0.60,0.45,0.30 breit)",
            "en-gb": "Standard panel (1.35,0.90,0.60,0.45,0.30 wide)",
            "en-us": "Standard panel (1.35,0.90,0.60,0.45,0.30 wide)",
            "es": "Elemento normal (1.35,0.90,0.60,0.45,0.30 ancho)",
            "fr": "Élément étalon (1.35,0.90,0.60,0.45,0.30 large)",
            "it": "Elemento normale (1.35,0.90,0.60,0.45,0.30 largo)",
            "hu": "Normál tábla (1.35,0.90,0.60,0.45,0.30 szélesség)",
            "ru": "Унифицированный элемент (ширина 1.35,0.90,0.60,0.45,0.30)",
            "sk": "Štandardný panel (1.35,0.90,0.60,0.45,0.30 šírky)",
            "pl": "Płyta standardowa (szerokość 1.35,0.90,0.60,0.45,0.30)"
        },
        {
            "ID": "XFR30_A1_AW2",
            "de": "Uni-Element",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Panneau universel",
            "it": "Elemento uni",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Univerzálny panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XFR30_A2",
            "de": "Stirnabschalung",
            "en-gb": "Stop-end-formwork",
            "en-us": "Bulkhead",
            "es": "Tape",
            "fr": "Coffrage d'about",
            "it": "Chiusura di testa",
            "hu": "Véglezárás",
            "ru": "Торцевые участки опалубки",
            "sk": "Debnenie cela",
            "pl": "Szalunek czołowy"
        },
        {
            "ID": "XFR30_A2_AW1",
            "de": "Stirnanker ohne Element",
            "en-gb": "Stop-end tie without panel",
            "en-us": "Stop-end tie without panel",
            "es": "Anclaje de tape sin elem.",
            "fr": "Ancrage d'about sans px",
            "it": "Morsetto per testata senza elemento",
            "hu": "Univerzális kötocsavar elem nélkül",
            "ru": "Торцевой анкер без элемента",
            "sk": "Celová kotva bez panel",
            "pl": "Deskowanie czołowe bez płyty"
        },
        {
            "ID": "XFR30_A2_AW2",
            "de": "Stirnanker mit Element",
            "en-gb": "Stop-end tie with panel",
            "en-us": "Stop-end tie with panel",
            "es": "Anclaje de tape con elem.",
            "fr": "Ancrage d'about avec px",
            "it": "Morsetto per testata con elemento",
            "hu": "Univerzális kötocsavar elem",
            "ru": "Торцевой анкер с элементом",
            "sk": "Celová kotva so panel",
            "pl": "Deskowanie czołowe z płytą"
        },
        {
            "ID": "XFR30_A2_AW3",
            "de": "Universalverbinder mit Element",
            "en-gb": "Universal fixing bolt with panel",
            "en-us": "Universal fixing bolt with panel",
            "es": "Conector univ. con elem.",
            "fr": "Boulon d'assemblage univ. avec Px",
            "it": "Tirante universale con elemento",
            "hu": "Univerzális kötőcsavar elemmel",
            "ru": "Универсальный соединитель с элемента",
            "sk": "Univerzálna spojka s panelom",
            "pl": "Łącznik uniwersalny z płytą"
        },
        {
            "ID": "XFR30_A2_AW4",
            "de": "Universalelement",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Px univ.",
            "it": "Elemento uni",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Univerzálny panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XFR30_A2_AW5",
            "de": "Stirnabschalzwinge",
            "en-gb": "Stop-end waler tie",
            "en-us": "Stop-end waler tie",
            "es": "Sargento para tape",
            "fr": "Rail d'about",
            "it": "Corrente di testata",
            "hu": "Véglezáró sín",
            "ru": "Торцевая накладка",
            "sk": "Zvierka debnenia čiel",
            "pl": "Zacisk deskowania czołowego"
        },
        {
            "ID": "XFR30_A2_AW6",
            "de": "Universalverbinder ohne Element",
            "en-gb": "Universal fixing bolt w/o panel",
            "en-us": "Universal fixing bolt w/o panel",
            "es": "Conector univ. con elem.",
            "fr": "Boulon d'assemblage univ. sans Px",
            "it": "Tirante universale senza elemento",
            "hu": "Univerzális kötocsavar elem nélkül",
            "ru": "Универсальный соединитель без элемента",
            "sk": "Univerzálna spojka bez panel",
            "pl": "Łącznik uniwersalny bez płyty"
        },
        {
            "ID": "XFR30_A3",
            "de": "Ausgleiche",
            "en-gb": "Closures",
            "en-us": "Fillers",
            "es": "Compensaciónes",
            "fr": "Compensations",
            "it": "Compensazioni",
            "hu": "Kiegyenlítés",
            "ru": "Компенсационные элементы",
            "sk": "Ručné vyrovnania",
            "pl": "Wypełnienia"
        },
        {
            "ID": "XFR30_A3_AW1",
            "de": "Füllkörper mit Uni-Spanner, Ankerung im Element",
            "en-gb": "Filler block w/ multifunction clamp, ties through panel",
            "en-us": "Filler block w/ multifunction clamp, ties through panel",
            "es": "Cuerpo de relleno con grapa unión univ., anclaje en el elemento",
            "fr": "Corps de remplissage avec tendeur rapide universelle, ancrage dans le panneau",
            "it": "Compensazione con morsetto universale, anc. nell'elemento",
            "hu": "Építéshelyi kiegészítő betét univerzális kapcsolóval, ankerozás az elemben",
            "ru": "Заполнитель с универсал. зажимным приспособлением, анкерование в элементе",
            "sk": "Vyrovnanie s univerzálnym upínačom, kotvenie v paneli",
            "pl": "Wstawka z zamkiem uniwersalnym, ściągi przez wstawkę"
        },
        {
            "ID": "XFR30_A3_AW10",
            "de": "Füllkörper mit Universalverbinder 10-25cm",
            "en-gb": "Filler block w/ universal fixing bolt 10-25cm",
            "en-us": "Filler block w/ universal fixing bolt 10-25cm",
            "es": "Cuerpo de relleno con conector univ. 10-25cm",
            "fr": "Corps de remplissage avec boulon d'assemblage univ. 10-25cm",
            "it": "Compensazione con tirante universale 10-25cm",
            "hu": "Kidobozolás univerzális kötőcsavarral 10-25cm",
            "ru": "Закладной элемент с унив. соединителем 10-25см",
            "sk": "Vyrovnanie s univerzálnou spojkou 10-25cm",
            "pl": "Wstawka z łącznikiem uniwersalnym 10–25cm"
        },
        {
            "ID": "XFR30_A3_AW11",
            "de": "mit Schalungsplatte 21mm und Klemmschiene 0,90m",
            "en-gb": "with formwork sheet 21mm and universal waling 0.90m",
            "en-us": "with formwork sheet 21mm and universal waling 0.90m",
            "es": "con tablero de encofrado 21mm y riel de fijación 0,90m",
            "fr": "avec contreplaqué 21mm et rails de blocage 0,90m",
            "it": "con pannello 21mm e rotaia di fissaggio 0,90m",
            "hu": "21mm-es zsaluhéjjal és 0,90m-es kötősínnel",
            "ru": "с опалубочная плита 21мм и зажимная шина 0,90м",
            "sk": "s debniacou doskou 21mm a upínacou koľajnicou 0,90m",
            "pl": "ze sklejką 21mm i uniwersalnym ryglem ściennym 0,90m"
        },
        {
            "ID": "XFR30_A3_AW12",
            "de": "mit Schalungsplatte 21mm und Klemmschiene 1,50m",
            "en-gb": "with formwork sheet 21mm and universal waling 1.50m",
            "en-us": "with formwork sheet 21mm and universal waling 1.50m",
            "es": "con tablero de encofrado 21mm y riel de fijación 1,50m",
            "fr": "avec contreplaqué 21mm et rails de blocage 1,50m",
            "it": "con pannello 21mm e rotaia di fissaggio 1,50m",
            "hu": "21mm-es zsaluhéjjal és 1,50m-es kötősínnel",
            "ru": "с опалубочная плита 21мм и зажимная шина 1,50м",
            "sk": "s debniacou doskou 21mm a upínacou koľajnicou 1,50m",
            "pl": "ze sklejką 21mm i uniwersalnym ryglem ściennym 1,50m"
        },
        {
            "ID": "XFR30_A3_AW2",
            "de": "Füllkörper mit Ausgleichsspanner",
            "en-gb": "Filler block w/ adjustable clamp",
            "en-us": "Filler block w/ adjustable clamp",
            "es": "Cuerpo de relleno con grapa de compensación",
            "fr": "Corps de remplissage avec tendeur de compensation",
            "it": "Compensazione con morsetto di compensazione",
            "hu": "Építéshelyi fabetét kiegyenlítő kapcsolóval",
            "ru": "Закладной элем. с пригон. зажимн. приспособл.",
            "sk": "Vyrovnanie s vyrovnávacím upínačom",
            "pl": "Wstawka z zamkiem uniwersalnym"
        },
        {
            "ID": "XFR30_A3_AW3",
            "de": "Passholz mit Uni-Spanner, Ankerung im Element",
            "en-gb": "Fitting timber with multifunction clamp, ties through panel",
            "en-us": "Fitting timber with multifunction clamp ties through panel",
            "es": "Tablón de ajuste con grapa unión univ., anclaje en el elemento",
            "fr": "Fourrure en bois avec tendeur rapide universelle, ancrage dans le panneau",
            "it": "Tavola di compensazione con morsetto universale, anc. nell'elemento",
            "hu": "Illesztő fabetét univerzális kapcsolóval, ankerozás az elemben",
            "ru": "Пригнанный брус с универсал. зажимным приспособлением, анкерование в элементе",
            "sk": "Vyrovnávací hranol s univerzálnym upínačom, kotvenie v paneli",
            "pl": "Wstawka komp. z zamkiem uniwersalnym, ściagi przez płytę"
        },
        {
            "ID": "XFR30_A3_AW4",
            "de": "Passholz mit Ausgleichsspanner",
            "en-gb": "Fitting timber with adjustable clamp",
            "en-us": "Fitting timber with adjustable clamp",
            "es": "Tablón de ajuste con grapa de comp",
            "fr": "Fourrure en bois avec tendeur de compensation",
            "it": "Tavola di compensazione con morsetto di compensazione",
            "hu": "Illesztő fabetét kiegyenlítő kapcsolóval",
            "ru": "Пригнанный брус с пригоняемым зажимным приспособлением",
            "sk": "Vyrovnávací hranol s vyrovnávacím upínačom",
            "pl": "Wstawka komp. z zaciskiem regulowanym"
        },
        {
            "ID": "XFR30_A3_AW5",
            "de": "mit Schalungsplatte 27mm und Klemmschiene 0,90m",
            "en-gb": "with formwork sheet 27mm and universal waling 0.90m",
            "en-us": "with formwork sheet 27mm and universal waling 0.90m",
            "es": "con tablero de encofrado 27mm y riel de fijación 0,90m",
            "fr": "avec contreplaqué 27mm et rail de blocage 0,90m",
            "it": "con pannello 27mm e rotaia di fissaggio 0,90m",
            "hu": "27mm-es zsaluhéjjal és 0,90m-es kötősínnel",
            "ru": "с опалубочная плита 27мм и зажимная шина 0,90м",
            "sk": "s debniacou doskou 27mm a upínacou koľajnicou 0,90m",
            "pl": "ze sklejką 27mm i uniwersalnym ryglem ściennym 0,90m"
        },
        {
            "ID": "XFR30_A3_AW6",
            "de": "mit Schalungsplatte 27mm und Klemmschiene 1,50m",
            "en-gb": "with formwork sheet 27mm and universal waling 1.50m",
            "en-us": "with formwork sheet 27mm and universal waling 1.50m",
            "es": "con tablero de encofrado 27mm y riel de fijación 1,50m",
            "fr": "avec contreplaqué 27mm et rail de blocage 1,50m",
            "it": "con pannello 27mm e rotaia di fissaggio 1,50m",
            "hu": "27mm-es zsaluhéjjal és 1,50m-es kötősínnel",
            "ru": "с опалубочная плита 27мм и зажимная шина 1,50м",
            "sk": "s debniacou doskou 27mm a upínacou koľajnicou 1,50m",
            "pl": "ze sklejką 27mm i uniwersalnym ryglem ściennym 1,50m"
        },
        {
            "ID": "XFR30_A3_AW7",
            "de": "Füllkörper mit Uni-Spanner, Ankerung im Ausgleich",
            "en-gb": "Filler block w/ multifunction clamp, ties through closure",
            "en-us": "Filler block w/ multifunction clamp, ties through filler",
            "es": "Cuerpo de relleno con grapa unión univ., anclaje en compensación",
            "fr": "Corps de remplissage avec tendeur rapide univ., ancrage dans le compens.",
            "it": "Compensazione con morsetto universale, anc. nella compens.",
            "hu": "Építéshelyi kiegészítő betét univerzális kapcsolóval, ankerozás a kiegyenlítésen keresztül",
            "ru": "Заполнитель с универсал. зажимным приспособлением, анкерование в компенсатор",
            "sk": "Vyrovnanie s univerzálnym upínačom, kotvenie vo vyrovnaní",
            "pl": "Wstawka z zamkiem uniwersalnym, ściągi przez wstawkę"
        },
        {
            "ID": "XFR30_A3_AW8",
            "de": "Passholz mit Uni-Spanner, Ankerung im Ausgleich",
            "en-gb": "Fitting timber w/ multifunction clamp, ties through closure",
            "en-us": "Fitting timber w/ multifunction clamp, ties through filler",
            "es": "Tablón de ajuste con grapa unión univ., anclaje en compensación",
            "fr": "Fourrure en bois avec tendeur rapide univ., ancrage dans le compens.",
            "it": "Tavola di compensazione con morsetto universale, anc. nella compens.",
            "hu": "Illesztő fabetét univerzális kapcsolóval, ankerozás a kiegyenlítésen keresztül",
            "ru": "Пригнанный брус с универсал. зажимным приспособлением, анкерование в компенсатор",
            "sk": "Vyrovnávací hranol s univerzálnym upínačom, kotvenie vo vyrovnaní",
            "pl": "Wstawka komp. z zamkiem uniwersalnym, ściagi przez wstawkę"
        },
        {
            "ID": "XFR30_A3_AW9",
            "de": "Füllkörper mit Universalverbinder 10-16cm",
            "en-gb": "Filler block w/ universal fixing bolt 10-16cm",
            "en-us": "Filler block w/ universal fixing bolt 10-16cm",
            "es": "Cuerpo de relleno con conector univ. 10-16cm",
            "fr": "Corps de remplissage avec boulon d'assemblage univ. 10-16cm",
            "it": "Compensazione con tirante universale 10-16cm",
            "hu": "Kidobozolás univerzális kötőcsavarral 10-16cm",
            "ru": "Закладной элемент с унив. соединителем 10-16см",
            "sk": "Vyrovnanie s univerzálnou spojkou 10-16cm",
            "pl": "Wstawka z łącznikiem uniwersalnym 10–16cm"
        },
        {
            "ID": "XFR30_A4",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "XFR30_A4_AW1",
            "de": "Innenecke und Universal-Element",
            "en-gb": "Inside corner and universal panel",
            "en-us": "w/ formwork sheet and … 0.90m",
            "es": "Esqu. int. y elem. univ.",
            "fr": "Angle int et px univ.",
            "it": "Angolo interno e elemento uni",
            "hu": "Csukl.bel.sarok és uni elem",
            "ru": "Внутренний угол и унив.элементом",
            "sk": "Vnútorný roh + univerzálny panel",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna"
        },
        {
            "ID": "XFR30_A4_AW2",
            "de": "Innenecke und Außenecke",
            "en-gb": "Inside corner and outside corner",
            "en-us": "Inside corner and outside corner",
            "es": "Esqu. int. y esqu. ext.",
            "fr": "Angle int. et angle ext",
            "it": "Angolo interno e angolo esterno",
            "hu": "Belso sarok és külso sarok",
            "ru": "Внутренний угол и внешний угол",
            "sk": "Vnútorný roh + vonkajší roh",
            "pl": "Narożnik wewnętrzny i narożnik zewnętrzny"
        },
        {
            "ID": "XFR30_A5",
            "de": "Wandanschlüsse",
            "en-gb": "Wall connections",
            "en-us": "Wall connections",
            "es": "Acometidas de muros",
            "fr": "Connexions de mur",
            "it": "Agganci per parete",
            "hu": "Falcsatlakozás",
            "ru": "Стыки стен",
            "sk": "Napájanie stien",
            "pl": "Połączenia ścienne"
        },
        {
            "ID": "XFR30_A5_AW1",
            "de": "Normalelement",
            "en-gb": "Standard panel",
            "en-us": "Standard panel",
            "es": "Elemento normal",
            "fr": "Élément étalon",
            "it": "Elemento normale",
            "hu": "Normál tábla",
            "ru": "Унифицированный элемент",
            "sk": "Štandardný panel",
            "pl": "Płyta standardowa"
        },
        {
            "ID": "XFR30_A5_AW2",
            "de": "Uni-Element",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Px univ.",
            "it": "Elemento uni",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Univerzálny panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XFR30_A5_AW3",
            "de": "Kanthölzer-Klemmschiene",
            "en-gb": "Squared timbers-Universal waling",
            "en-us": "Squared timbers-Universal waling",
            "es": "Listónes-Riel de fijación",
            "fr": "Bois-Rails de blocage",
            "it": "Travi squadrate-Rotaia di fissaggio",
            "hu": "Fabetét-Kötosín",
            "ru": "Бруски-Зажимная шина",
            "sk": "Hranoly a upínac.-Upín.koľajnica",
            "pl": "Kantówki – uniwersalny rygiel ścienny"
        },
        {
            "ID": "XFR30_A5_AW4",
            "de": "Elemente vom Vorgängertakt verwenden",
            "en-gb": "Use panels of previous cycle",
            "en-us": "Use panels of previous pour",
            "es": "Utilizar los elementos del ciclo anterior",
            "fr": "Utiliser les éléments du coffrage précédent",
            "it": "Utilizza elementi del ciclo precedente",
            "hu": "Az előző ciklus elemeinek alkalmazása",
            "ru": "Использовать элементы предыдущего такта",
            "sk": "Použiť prvky z predchádzajúceho taktu",
            "pl": "Użyj płyt z poprzedniego taktu"
        },
        {
            "ID": "XFR30_A7",
            "de": "Stehende oder liegende Aufstockung der Elemente",
            "en-gb": "Upright or horiz. stacking of panels",
            "en-us": "Upright or horiz. stacking of panels",
            "es": "Apilado horizontal o vertical de los elementos",
            "fr": "Rehausse de px debout ou couchés",
            "it": "Sovrapposizione orizzontale o verticale degli elementi",
            "hu": "állított ill. fektetett elemmagasítás",
            "ru": "Вертикальная или горизонтальная надстройка элементов",
            "sk": "Nadstavenie panelov nastojato alebo naležato",
            "pl": "Pionowe lub poziome nadstawianie płyt"
        },
        {
            "ID": "XFR30_A7_AW1",
            "de": "ohne Aufstockung",
            "en-gb": "w/o stacking",
            "en-us": "w/o stacking",
            "es": "Sin apilado",
            "fr": "sans rehausse",
            "it": "senza sopralzo",
            "hu": "Magasítás nélkül",
            "ru": "Без надстройки",
            "sk": "bez nadstavenia",
            "pl": "bez nadstawienia"
        },
        {
            "ID": "XFR30_A7_AW2",
            "de": "Stehend aufgestockt",
            "en-gb": "stacked upright",
            "en-us": "stacked upright",
            "es": "Apilado vertical",
            "fr": "Rehaussé deboutant",
            "it": "Sovrapposizione vert.",
            "hu": "Állítva magasítva",
            "ru": "надстройка в вертикальном положении",
            "sk": "Nadstavenie nastojato",
            "pl": "nadstawienie pionowe"
        },
        {
            "ID": "XFR30_A7_AW3",
            "de": "Liegende Aufgestockt",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz.",
            "es": "Apilado horizontal",
            "fr": "Rehaussé couchant",
            "it": "Sovrapposizione orizz.",
            "hu": "Fektetve magasítva",
            "ru": "надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "pl": "nadstawienie poziome"
        },
        {
            "ID": "XFR30_A8",
            "de": "Bauwerksstütze",
            "en-gb": "Column",
            "en-us": "Column",
            "es": "Pilar",
            "fr": "Poteau",
            "it": "Pilastro",
            "hu": "Pillér",
            "ru": "Колонна",
            "sk": "Stĺp",
            "pl": "Słup"
        },
        {
            "ID": "XFR30_A8_AW1",
            "de": "Uni-Element 0,90",
            "en-gb": "Universal panel 0.90",
            "en-us": "Universal panel",
            "es": "Elem. univ. 0,90",
            "fr": "Px univ. 0,90",
            "it": "Elemento uni 0,90",
            "hu": "Uni elem 0,90",
            "ru": "Универсальный элемент 0,90",
            "sk": "Univerzálny panel 0,90",
            "pl": "Element uniwersalny 0,90"
        },
        {
            "ID": "XFR30_A8_AW2",
            "de": "Elemente mit Außenecken",
            "en-gb": "Panels with outside corners",
            "en-us": "Panels with outside corners",
            "es": "Elem. con esqu. ext.",
            "fr": "Px avec angle ext",
            "it": "Elemente con angoli esterni",
            "hu": "Elemek külső sarkokkal",
            "ru": "Элементы с внешними углами",
            "sk": "Panely s vonkaj. roh",
            "pl": "Płyty z narożnikami zewnętrznymi"
        },
        {
            "ID": "XFR30_A9",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "XFR30_A9_AW1",
            "de": "Ankerstab 15,0 unbehandelt",
            "en-gb": "Tie rod 15.0 non-treated",
            "en-us": "Tie rod 15.0 non-treated",
            "es": "Barra de ancl. 15,0 no tratada",
            "fr": "Tige d'ancrage 15,0 non traitée",
            "it": "Barra ancorante 15,0 non trattata",
            "hu": "Ankerrúd 15,0 kezeletlen",
            "ru": "Анкерный стержень 15,0 необработанный",
            "sk": "Kotev. tyc 15,0 neupravov.",
            "pl": "Ściąg 15,0 nieocynkowany"
        },
        {
            "ID": "XFR30_A9_AW2",
            "de": "Ankerstab 15,0 verzinkt",
            "en-gb": "Tie rod 15.0 galvanised",
            "en-us": "Tie rod 15.0 galvanized",
            "es": "Barra de ancl. 15,0 galv.",
            "fr": "Tige d'ancrage 15,0 galvanisée",
            "it": "Barra ancorante 15,0 zincata",
            "hu": "Ankerrúd 15,0 horganyzott",
            "ru": "Анкерный стержень 15,0 оцинкованный",
            "sk": "Kotev. tyc 15,0 pozinkov.",
            "pl": "Ściąg 15,0 ocynkowany"
        },
        {
            "ID": "XFR30_A9_AW3",
            "de": "Ankerstab 20,0 unbehandelt",
            "en-gb": "Tie rod 20.0 non-treated",
            "en-us": "Tie rod 20.0 non-treated",
            "es": "Barra de ancl. 20,0 no tratada",
            "fr": "Tige d'ancrage 20,0 non traitée",
            "it": "Barra ancorante 20,0 non trattata",
            "hu": "Ankerrúd 20,0 kezeletlen",
            "ru": "Анкерный стержень 20,0 необработанный",
            "sk": "Kotev. tyč 20,0 neupravovaná",
            "pl": "Ściąg 20,0 nieocynkowany"
        },
        {
            "ID": "XFR30_A9_AW4",
            "de": "Ankerstab 20,0 verzinkt",
            "en-gb": "Tie rod 20.0 galvanised",
            "en-us": "Tie rod 20.0 galvanized",
            "es": "Barra de ancl. 20,0 galv.",
            "fr": "Tige d'ancrage 20,0 galvanisée",
            "it": "Barra ancorante 20,0 zincata",
            "hu": "Ankerrúd 20,0 horganyzott",
            "ru": "Анкерный стержень 20,0 оцинкованный",
            "sk": "Kotev. tyc 20,0 pozinkov.",
            "pl": "Ściąg 20,0 ocynkowany"
        },
        {
            "ID": "XFRAMA_S_A1",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "XFRAMA_S_A1_AW1",
            "de": "Rollgewindestab 1x$$ + Ankerkopf",
            "en-gb": "Rolled thread rod 1x$$ + she-bolt",
            "en-us": "Rolled thread rod 1\″x$$\″ + she-bolt",
            "es": "Barra roscada 1\″x$$\″ + Cab.de ancl.",
            "fr": "Tige filetée 1\″x$$\″ + Ancrage à cône",
            "it": "Asta filettata rullo 1\″x$$\″ + manicotto ancorante",
            "hu": "Görgos menetes rúd 1\″x$$\″ + Ankerfej",
            "ru": "Стержень с накатанной резьбой 1\″x$$\″ + анкерная головка",
            "sk": "Tyč so závitom 1\″x$$\″ + kot.hlava",
            "pl": "Pręt walcowany z gwintem 1 x $$ + śruba z łbem walcowym"
        },
        {
            "ID": "XFRAMA_S_A1_AW2",
            "de": "Konischer Ankerstab 1 1/2\″ - 1 1/4\″",
            "en-gb": "Taper tie 1 1/2\″ - 1 1/4\″",
            "en-us": "Taper tie 1 1/2\″ - 1 1/4\″",
            "es": "Barra de ancl. cónica 1 1/2\″ - 1 1/4\″",
            "fr": "Tige d'ancrage cônique 1 1/2\″ - 1 1/4\″",
            "it": "Barra ancorante conica 1 1/2\″ - 1 1/4",
            "hu": "Konikus ankerrúd 1 1/2\″ - 1 1/4\″",
            "ru": "Конический анкерный стержень 1 1/2\″ - 1 1/4\″",
            "sk": "Kónická kotevná tyc 1 1/2\″ - 1 1/4\″",
            "pl": "Ściąg stożkowy 1 1/2”–1 1/4”"
        },
        {
            "ID": "XFRAMA_S_A1_AW3",
            "de": "Rollgewindestab 1  Alle Längen ohne Ankerkopf",
            "en-gb": "Rolled thread rod 1 all lengths without she-bolt",
            "en-us": "Rolled thread rod 1\″ all lengths without she-bolt",
            "es": "Barra roscada 1\″ todos longitudes sin Cab.de ancl.",
            "fr": "Tige filetée 1\″ ttes long. sans ancr. à cône",
            "it": "Asta filettata rullo 1\″ tutte lungh. senza manicotto ancorante",
            "hu": "Görgos menetes rúd 1\″ összes hossz ankerfej nélkül",
            "ru": "Стержень с накатанной резьбой 1\″ Все длины без анкерной головки",
            "sk": "Tyč so závitom 1\″ Všetky dĺžky bez kotevnej hlavy",
            "pl": "Pręt walcowany z gwintem 1 – wszystkie długości bez śruby z łbem walcowym"
        },
        {
            "ID": "XFRAMA_S_A1_AW4",
            "de": "Ankersystem 20,0 verzinkt",
            "en-gb": "Tie rod system 20.0 galvanised",
            "en-us": "Tie rod system 20.0 galvanized",
            "es": "Sistema de anclaje 20,0 galvanizada",
            "fr": "Système d‘ancrage 20,0 galvanisée",
            "it": "Barre ancoranti 20,0 zincata",
            "hu": "Ankerrendszer 20,0 horgayzott",
            "ru": "Анкерная система 20,0 оцинкованная",
            "sk": "Kotevný systém 20,0 pozinkovaná",
            "pl": "System ściągów 20,0 ocynkowany"
        },
        {
            "ID": "XFRAMA_S_A1_AW5",
            "de": "Ankersystem 20,0 unbehandelt",
            "en-gb": "Tie rod system 20.0 non-treated",
            "en-us": "Tie rod system 20.0 non-treated",
            "es": "Sistema de anclaje 20,0 non tratada",
            "fr": "Système d‘ancrage 20,0 non traitée",
            "it": "Barre ancoranti 20,0 non trattata",
            "hu": "Ankerrendszer 20,0 kezaletlen",
            "ru": "Анкерная система 20,0 необработанная",
            "sk": "Kotevný systém 20,0 neupravovaná",
            "pl": "System ściągów 20,0 nieocynkowany"
        },
        {
            "ID": "XFRAMA_S_A10",
            "de": "T-Anschluss, Einseitige Ecke und Kreuzung",
            "en-gb": "T-connection, one-sided corner and crossing",
            "en-us": "T-connection, one-sided corner and crossing",
            "es": "Unión T, esquina de un solo lado y cruce",
            "fr": "Raccord en T, une face angle et croisement",
            "it": "Raccordo a T, angolo unilaterale e incrocio",
            "hu": "T-véglezárás, egyoldali sarok és keresztcsatlakozás",
            "ru": "Т-образный стык, Односторонний угол и крестообразное соединение",
            "sk": "T-napojenie, jednostranný roh a križovanie",
            "pl": "Połączenie typu T, narożnik jednostronny i połączenie krzyżowe"
        },
        {
            "ID": "XFRAMA_S_A10_AW1",
            "de": "Innenecke, Außenecke",
            "en-gb": "Inside corner, outside corner",
            "en-us": "Inside corner, outside corner",
            "es": "Esqu. int., esqu. ext.",
            "fr": "Angle int, angle ext",
            "it": "Angolo interno, angolo esterno",
            "hu": "Belso sarok, külso sarok",
            "ru": "Внутренний угол, внешний угол",
            "sk": "Vnút. rohy , vonkaj. roh",
            "pl": "Narożnik wewnętrzny, narożnik zewnętrzny"
        },
        {
            "ID": "XFRAMA_S_A10_AW2",
            "de": "Außenecke+Keilbolzen+Spannkeil",
            "en-gb": "Outside corner + wedge pin + tensioning wedge",
            "en-us": "Outside corner + wedge pin + tensioning wedge",
            "es": "Esqu. ext. + perno de cuña + cuña de fijación",
            "fr": "Angle ext + broche à clavette + clavette de serage",
            "it": "Angolo esterno + spinotto + cuneo di fissaggio",
            "hu": "Külso sarok + ékes csapszeg + feszítő ék",
            "ru": "Внешний угол + конический болт + зажимной клин",
            "sk": "Vonkaj. Roh + klinový čap + upínací klein",
            "pl": "Narożniki zewnętrzne + sworzeń klina + klin napinający"
        },
        {
            "ID": "XFRAMA_S_A10_AW3",
            "de": "Scharnier-Innenecke",
            "en-gb": "Hinged inside corner",
            "en-us": "Hinged inside corner",
            "es": "Esqu. bisagra int.",
            "fr": "Angle charnière int",
            "it": "Angolo cerniera int.",
            "hu": "Csukl.belso sarok",
            "ru": "Шарнирный внутренний угол",
            "sk": "Kĺbový vnútor. roh",
            "pl": "Narożnik wewnętrzny zawiasowy"
        },
        {
            "ID": "XFRAMA_S_A10_AW4",
            "de": "Scharnier-Außenecke",
            "en-gb": "Hinged outside corner",
            "en-us": "Hinged outside corner",
            "es": "Esqu. bisagra ext.",
            "fr": "Angle charnière ext",
            "it": "Angolo cerniera est.",
            "hu": "Csukl.külso sarok",
            "ru": "Шарнирный внешний угол",
            "sk": "Kĺb.vonk.roh",
            "pl": "Narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "XFRAMA_S_A10_AW5",
            "de": "Scharnier-Innenecke verz.",
            "en-gb": "Hinged inside corner galv.",
            "en-us": "Hinged inside corner galv.",
            "es": "Esqu. bisagra int. galv.",
            "fr": "Angle charnière int galv.",
            "it": "Angolo cerniera int. galv.",
            "hu": "Csukl.belso sarok horg.",
            "ru": "Шарнирный внутренний угол оцинкованный",
            "sk": "Kĺbový vnútor. roh poz.",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk."
        },
        {
            "ID": "XFRAMA_S_A10_AW6",
            "de": "Scharnier-Außenecke verz.",
            "en-gb": "Hinged outside corner galv.",
            "en-us": "Hinged outside corner galv.",
            "es": "Esqu. bisagra ext. galv.",
            "fr": "Angle charnière ext galv.",
            "it": "Angolo cerniera est. galv.",
            "hu": "Csukl.külso sarok horg.",
            "ru": "Шарнирный внешний угол оцинкованный",
            "sk": "Kĺb.vonk.roh poz.",
            "pl": "Narożnik zewnętrzny zawiasowy ocynk."
        },
        {
            "ID": "XFRAMA_S_A10_AW7",
            "de": "Stützenvorlageelement",
            "en-gb": "Pilaster panel",
            "en-us": "Pilaster panel",
            "es": "Panel pilaster",
            "fr": "Élément de pil. boutant",
            "it": "Elemento pilastro",
            "hu": "Falpillér elem",
            "ru": "Элемент стойки",
            "sk": "Pilastrový panel",
            "pl": "Panel pilastra"
        },
        {
            "ID": "XFRAMA_S_A10_AW8",
            "de": "Ausschalecke I",
            "en-gb": "Stripping corner I",
            "en-us": "Bias cut corner I",
            "es": "Esquina de desencofrado int. I",
            "fr": "Angle de décoffrage I",
            "it": "Angolo di disarmo interno I",
            "hu": "Kizsaluzó sarok I",
            "ru": "Pаспалубочный угол I",
            "sk": "Oddebňovací roh I",
            "pl": "Narożnik wew. rozszalowujący I"
        },
        {
            "ID": "XFRAMA_S_A11",
            "de": "Längsanschluss",
            "en-gb": "In-line connections",
            "en-us": "In-line connections",
            "es": "Unión longitudinal",
            "fr": "Connexion en longueur",
            "it": "Collegamento longitudinale",
            "hu": "Hosszirányú csatlakozás",
            "ru": "Продольный стык",
            "sk": "Pozdĺžne pripojenie",
            "pl": "Połączenia w linii"
        },
        {
            "ID": "XFRAMA_S_A11_AW1",
            "de": "Normaler Anschluss",
            "en-gb": "Standard connection",
            "en-us": "Standard connection",
            "es": "Unión normal",
            "fr": "Raccord normal",
            "it": "Raccordo normale",
            "hu": "Normál csatlakozás",
            "ru": "Обычный стык",
            "sk": "Normálne pripojenie",
            "pl": "Połączenie standardowe"
        },
        {
            "ID": "XFRAMA_S_A11_AW2",
            "de": "Elemente vom Vorgängertakt verwenden",
            "en-gb": "Use panels of previous cycle",
            "en-us": "Use panels of previous pour",
            "es": "Utilizar los elementos del ciclo anterior",
            "fr": "Utiliser les éléments du coffrage précédent",
            "it": "Utilizza elementi del ciclo precedente",
            "hu": "Az előző ciklus elemeinek alkalmazása",
            "ru": "Использовать элементы предыдущего такта",
            "sk": "Použiť prvky z predchádzajúceho taktu",
            "pl": "Użyj płyt z poprzedniego taktu"
        },
        {
            "ID": "XFRAMA_S_A2",
            "de": "Umsetzeinheiten",
            "en-gb": "Shifting units",
            "en-us": "Shifting units",
            "es": "Unid. de desplazam.",
            "fr": "Unité de translation",
            "it": "Unità movimentazione",
            "hu": "Áthelyez·egységek",
            "ru": "Перемещаемые блоки",
            "sk": "Transportné jednotky",
            "pl": "Zestawy do przestawiania"
        },
        {
            "ID": "XFRAMA_S_A2_AW1",
            "de": "nicht bevorzugt",
            "en-gb": "not preferred",
            "en-us": "not preferred",
            "es": "No preferido",
            "fr": "ne pas préféré",
            "it": "non preferito",
            "hu": "nem preferált",
            "ru": "не предпочтительно",
            "sk": "nie je uprednostnené",
            "pl": "niepreferowany"
        },
        {
            "ID": "XFRAMA_S_A2_AW2",
            "de": "bevorzugt",
            "en-gb": "preferred",
            "en-us": "preferred",
            "es": "preferido",
            "fr": "préféré",
            "it": "preferito",
            "hu": "preferált",
            "ru": "предпочтительно",
            "sk": "uprednostnené",
            "pl": "preferowany"
        },
        {
            "ID": "XFRAMA_S_A3",
            "de": "Elementtyp",
            "en-gb": "Panel type",
            "en-us": "Panel type",
            "es": "Tipo de elemento",
            "fr": "Type d'élement",
            "it": "Tipo elemento",
            "hu": "Elemtípus",
            "ru": "Тип элемента",
            "sk": "Typ panelu",
            "pl": "Typ płyty"
        },
        {
            "ID": "XFRAMA_S_A3_AW1",
            "de": "Normalelemente (1.35,1.05,0.90,0.75,0.60,0.45,0.30 breit)",
            "en-gb": "Standard panels (1.35,1.05,0.90,0.75,0.60,0.45,0.30 wide)",
            "en-us": "Standard panels (1.35,1.05,0.90,0.75,0.60,0.45,0.30 wide)",
            "es": "Elementos norm.(1.35,1.05,0.90,0.75,0.60,0.45,0.30 ancho)",
            "fr": "Éléments étalons(1.35,1.05,0.90,0.75,0.60,0.45,0.30 d.large)",
            "it": "Elementi normali (1.35,1.05,0.90,0.75,0.60,0.45,0.30 largo)",
            "hu": "Normál táblák(1.35,1.05,0.90,0.75,0.60,0.45,0.30 szélesség)",
            "ru": "Унифицированные элементы (ширина 1.35,1.05,0.90,0.75,0.60,0.45,0.30)",
            "sk": "Štandardné panely (1.35,1.05,0.90,0.75,0.60,0.45,0.30 šírky)",
            "pl": "Płyty standardowe (szerokość 1.35,1.05,0.90,0.75,0.60,0.45,0.30)"
        },
        {
            "ID": "XFRAMA_S_A3_AW2",
            "de": "Großelemente (2.40 breit)",
            "en-gb": "Large panels (2.40 wide)",
            "en-us": "Large panels (2.40 wide)",
            "es": "Elementos largos (2.40 ancho)",
            "fr": "Grande élément (2,40 de large)",
            "it": "Elemento grande (2,40 largo)",
            "hu": "Nagytáblák (2.40szélesség)",
            "ru": "Крупноразмерные элементы (ширина 2.40)",
            "sk": "Veľkopl.panely(2,40šírky)",
            "pl": "Płyty duże (szerokość 2,40)"
        },
        {
            "ID": "XFRAMA_S_A3_AW3",
            "de": "Uni-Elemente(1.22 breit)",
            "en-gb": "Universal panels (1.22 wide)",
            "en-us": "Universal panels (1.22 wide)",
            "es": "Elem. univ.(1,22ancho)",
            "fr": "Px univ.(1.22de large)",
            "it": "Elementi uni(1,22largo)",
            "hu": "Uni elem(1.22szélesség)",
            "ru": "Универсальные элементы (ширина 1.22)",
            "sk": "Uni. panely(1,22šírky)",
            "pl": "Płyty uniwersalne (szerokość 1,22)"
        },
        {
            "ID": "XFRAMA_S_A3_AW4",
            "de": "Uni-Elemente(0.90 breit)",
            "en-gb": "Universal panels (0.90 wide)",
            "en-us": "Universal panels (0.90 wide)",
            "es": "Elem. univ.(0.90ancho)",
            "fr": "Px univ.(0.90de large)",
            "it": "Elementi uni(0,90largo)",
            "hu": "Uni elem(0.90szélesség)",
            "ru": "Универсальные элементы (ширина 0.90)",
            "sk": "Uni. panely(0,90šírky)",
            "pl": "Płyty uniwersalne (szerokość 0,9)"
        },
        {
            "ID": "XFRAMA_S_A3_AW5",
            "de": "Fundament liegend",
            "en-gb": "Foundation horizontal",
            "en-us": "Foundation horizontal",
            "es": "Base verticales",
            "fr": "Corps de remplissage couché",
            "it": "Elementi orizz.",
            "hu": "egyenes kitöltő falazat fekvo",
            "ru": "Фундамент горизонтальный",
            "sk": "Základ nalezato",
            "pl": "Fundament poziomy"
        },
        {
            "ID": "XFRAMA_S_A3_AW6",
            "de": "Fundament stehend",
            "en-gb": "Foundation upright",
            "en-us": "Foundation upright",
            "es": "Fundament horizontales",
            "fr": "Corps de remplissage debout",
            "it": "Elementi vert.",
            "hu": "egyenes kitöltő falazat állítva",
            "ru": "Фундамент вертикальный",
            "sk": "Základ nastojato",
            "pl": "Fundament pionowy"
        },
        {
            "ID": "XFRAMA_S_A4",
            "de": "Stirnabschalung",
            "en-gb": "Stop-end-formwork",
            "en-us": "Bulkhead",
            "es": "Tape",
            "fr": "Coffrage d'about",
            "it": "Chiusura di testa",
            "hu": "Véglezárás",
            "ru": "Торцевые участки опалубки",
            "sk": "Debnenie čela",
            "pl": "Szalunek czołowy"
        },
        {
            "ID": "XFRAMA_S_A4_AW1",
            "de": "Klemmschiene und Universalverbinder",
            "en-gb": "Universal waling and universal fixing bolt",
            "en-us": "Universal waling and universal fixing bolt",
            "es": "Riel de fijación y conector universal",
            "fr": "Rail de blocage et boulon d'assemblage universel",
            "it": "Rotaia di fissaggio e tirante universale",
            "hu": "Kötosín és Univerzális kötocsavar",
            "ru": "Зажимная шина и универсальный соединитель",
            "sk": "Upín.koľajnica a univerzálna spojka",
            "pl": "Uniwersalny rygiel ścienny i łącznik uniwersalny"
        },
        {
            "ID": "XFRAMA_S_A4_AW2",
            "de": "Klemmschiene und Stirnanker",
            "en-gb": "Universal waling and stop-end tie",
            "en-us": "Universal waling and stop-end tie",
            "es": "Riel de fijación y anclaje de tape",
            "fr": "Rail de blocage et ancrage d'about",
            "it": "Rotaia di fissaggio e morsetto per testata",
            "hu": "Kötosín és Homlokanker",
            "ru": "Зажимная шина и торцевой анкер",
            "sk": "Upín.koľajnica a čelová kotva",
            "pl": "Uniwersalny rygiel ścienny i ściąg czołowy"
        },
        {
            "ID": "XFRAMA_S_A4_AW3",
            "de": "Universalelement 1,22 und Universalverbinder",
            "en-gb": "Universal panel 1.22 and universal fixing bolt",
            "en-us": "Universal panel 1.22 and universal fixing bolt",
            "es": "Elem. univ. 1,22 y conector universal",
            "fr": "Px univ. 1,22 et boulon d'assemblage universel",
            "it": "Elem. universale 1,22 e tirante universale",
            "hu": "Uni elem 1,22 és Univerzális kötocsavar",
            "ru": "Универсальный элемент 1,22 и универсальный соединитель",
            "sk": "Univerzálny panel 1,22 a univerzálna spojka",
            "pl": "Płyta uniwersalna 1,22 i łącznik uniwersalny"
        },
        {
            "ID": "XFRAMA_S_A4_AW4",
            "de": "Klemmschiene und Universalverbinder mit Fugenband",
            "en-gb": "Universal waling and universal fixing bolt with joint strip",
            "en-us": "Universal waling and universal fixing bolt with joint strip",
            "es": "Riel de fijación y conector univ. con junta de estanqu.",
            "fr": "Rail de blocage et boulon d'assemblage avec joint dil",
            "it": "Rotaia di fissaggio e tirante universale con nastro giunti",
            "hu": "Kötosín és Univerzális kötocsavar Fúgaszalag",
            "ru": "Зажимная шина и универсальный соединитель с лентой для уплотнения швов",
            "sk": "Upín.koľajnica a univerzálna spojka so tesn.pás",
            "pl": "Uniwersalny rygiel ścienny i łącznik uniwersalny z listwą"
        },
        {
            "ID": "XFRAMA_S_A4_AW5",
            "de": "Universalelement 0.90 und Universalverbinder",
            "en-gb": "Universal panel 0.90 and universal fixing bolt",
            "en-us": "Universal panel 0.90 and universal fixing bolt",
            "es": "Elem. univ. 0.90 y conector universal",
            "fr": "Px univ. 0.90 et boulon d'assemblage universel",
            "it": "Elem. universale 0,90 e tirante universale",
            "hu": "Uni elem 0.90 és Univerzális kötocsavar",
            "ru": "Универсальный элемент 0,90 и универсальный соединитель",
            "sk": "Univerzálny panel 0,90 a univerzálna spojka",
            "pl": "Płyta uniwersalna 0,90 i łącznik uniwesalny"
        },
        {
            "ID": "XFRAMA_S_A4_AW6",
            "de": "Stirnabschalzwinge",
            "en-gb": "Stop-end waler tie",
            "en-us": "Stop-end waler tie",
            "es": "Sargento para tape",
            "fr": "Rail d'about",
            "it": "Corrente di testata",
            "hu": "Véglezáró sín",
            "ru": "Торцевая накладка",
            "sk": "Zvierka debnenia čiel",
            "pl": "Zacisk deskowania czołowego"
        },
        {
            "ID": "XFRAMA_S_A5",
            "de": "Ausgleichsverbindung",
            "en-gb": "Compensation connection",
            "en-us": "Compensation connection",
            "es": "Unión de compensación",
            "fr": "Connexion de compensation",
            "it": "Giunzione per compensazione",
            "hu": "Kiegyenlítés összekötés",
            "ru": "Компенсационное соединение",
            "sk": "Spojenie vo vyrovnaní",
            "pl": "Połączenie kompensacyjne"
        },
        {
            "ID": "XFRAMA_S_A5_AW10",
            "de": "Passholz mit Uni-Spanner, Ankerung im Element",
            "en-gb": "Fitting timber with multifunction clamp, ties through panel",
            "en-us": "Fitting timber with multifunction clamp, ties through panel",
            "es": "Tablón de ajuste con grapa unión univ., anclaje en el elemento",
            "fr": "Fourrure en bois avec tendeur rapide universelle, ancrage dans le panneau",
            "it": "Tavola di compensazione con morsetto universale, anc. nell'elemento",
            "hu": "Illesztő fabetét univerzális kapcsolóval, ankerozás az elemben",
            "ru": "Пригнанный брус с универсал. зажимным приспособлением, анкерование в элементе",
            "sk": "Vyrovnávací hranol s univerzálnym upínačom, kotvenie v paneli",
            "pl": "Wstawka komp. z zamkiem uniwersalnym, ściagi przez płytę"
        },
        {
            "ID": "XFRAMA_S_A5_AW11",
            "de": "Passholz mit Ausgleichsspanner, Ankerung im Ausgleich",
            "en-gb": "Fitting timber w/ adjustable clamp, ties through closure",
            "en-us": "Fitting timber w/ adjustable clamp, ties through filler",
            "es": "Tablón de ajuste con grapa de comp., anclaje en compensación",
            "fr": "Fourrure en bois avec tendeur de comp., ancrage dans le compens.",
            "it": "Tavola di compensazione con morsetto di compensazione, anc. nella compens.",
            "hu": "Illesztő fabetét kiegyenlítő kapcsolóval, ankerozás a kiegyenlítésen keresztül",
            "ru": "Пригнанный брус с пригон. зажимн. приспособлением, анкерование в компенсатор",
            "sk": "Vyrovnávací hranol s vyrovnávacím upínačom, kotvenie vo vyrovnaní",
            "pl": "Wstawka komp. z zaciskiem regulowanym, ściagi przez wstawkę"
        },
        {
            "ID": "XFRAMA_S_A5_AW2",
            "de": "Kantholz mit Uni-Spanner, Ankerung im Element",
            "en-gb": "Squared timber w/ multi function clamp, ties through panel",
            "en-us": "Squared timber w/ multi function clamp, ties through panel",
            "es": "Listón por parte con grapa unión univ., anclaje en elemento",
            "fr": "Bois chantier avec tendeur rapide univ., ancrage dans le panneau",
            "it": "Trave squadrata con morsetto universale, anc. nell'elemento",
            "hu": "Fűrfa univ. kapcsolóval, ankerozás az elemen keresztül",
            "ru": "Брусок с унив. зажим. приспособлением, анкерование в элемент",
            "sk": "Drevený hranol s univerzálnym upínačom, kotvenie v paneli",
            "pl": "Kantówka z zamkiem uniwersalnym, ściągi przez płytę"
        },
        {
            "ID": "XFRAMA_S_A5_AW5",
            "de": "Schalungsplatte und Profilholz mit Klemmschiene 1,50m",
            "en-gb": "Formwork sheet and moulded timber with universal waling 1.50m",
            "en-us": "Formwork sheet and molded timber with universal waling 1.50m",
            "es": "Tablero de encofrado y perfil de madera con riel de fijación 1,50m",
            "fr": "Panneau et Liteau profilé avec rail de blocage 1,50m",
            "it": "Pannello e profilo in legno con rotaia di fissaggio 1,50m",
            "hu": "Zsaluhéj és profilos fabetét, kötősínnel 1,50m",
            "ru": "Опалубочная плита и профильный брус с зажимной шиной 1,50м",
            "sk": "Debniaca doska a drevený profil s upínacou koľajnicou 1,50m",
            "pl": "Sklejka i drewno profilowane z uniwersalnym ryglem ściennym 1,50m"
        },
        {
            "ID": "XFRAMA_S_A5_AW6",
            "de": "Kantholz mit Uni-Spanner, Ankerung im Ausgleich",
            "en-gb": "Squared timber w/ multi function clamp, ties through closure",
            "en-us": "Squared timber w/ multi function clamp, ties through filler",
            "es": "Listón por parte con grapa unión univ., anclaje en compensación",
            "fr": "Bois chantier avec tendeur rapide univ., ancrage dans la comp.",
            "it": "Trave squadrata con morsetto universale, anc. nella compens.",
            "hu": "Fűrfa univ. kapcsolóval, ankerozás a kiegyenlítésen keresztül",
            "ru": "Брусок с унив. зажим. приспособлением, анкерование в компенсатор",
            "sk": "Drevený hranol s univerzálnym upínačom, kotvenie vo vyrovnaní",
            "pl": "Kantówka z zamkiem uniwersalnym, ściągi przez wypełnienie"
        },
        {
            "ID": "XFRAMA_S_A5_AW7",
            "de": "Kantholz mit Ausgleichsspanner, Ankerung im Ausgleich",
            "en-gb": "Squared timber w/ adjustable clamp, ties through closure",
            "en-us": "Squared timber w/ adjustable clamp, ties through filler",
            "es": "Listón por parte con grapa de comp., anclaje en compensación",
            "fr": "Bois chantier avec tendeur de comp., ancrage dans la comp.",
            "it": "Trave squadrata con morsetto di compensazione, anc. nella compens.",
            "hu": "Fűrfa kiegyenlítő kapcsolóval, ankerozás a kiegyenlítésen keresztül",
            "ru": "Брусок с пригон. зажимн. приспособлением, анкерование в компенсатор",
            "sk": "Drevený hranol s vyrovnávacím upínačom, kotvenie vo vyrovnaní",
            "pl": "Kantówka z zaciskiem regulowanym, ściągi przez wypełnienie"
        },
        {
            "ID": "XFRAMA_S_A5_AW8",
            "de": "Schalungsplatte und Profilholz mit Klemmschiene 0,90m",
            "en-gb": "Formwork sheet and moulded timber with universal waling 0.90m",
            "en-us": "Formwork sheet and molded timber with universal waling 0.90m",
            "es": "Tablero de encofrado y perfil de madera con riel de fijación 0,90m",
            "fr": "Panneau et Liteau profilé avec rail de blocage 0,90m",
            "it": "Pannello e profilo in legno con rotaia di fissaggio 0,90m",
            "hu": "Zsaluhéj és profilos fabetét, kötősínnel 0,90m",
            "ru": "Опалубочная плита и профильный брус с зажимной шиной 0,90м",
            "sk": "Debniaca doska a drevený profil s upínacou koľajnicou 0,90m",
            "pl": "Sklejka i drewno profilowane z uniwersalnym ryglem ściennym 0,90m"
        },
        {
            "ID": "XFRAMA_S_A5_AW9",
            "de": "Passholz mit Uni-Spanner, Ankerung im Ausgleich",
            "en-gb": "Fitting timber w/ multifunction clamp, ties through closure",
            "en-us": "Fitting timber w/ multifunction clamp, ties through filler",
            "es": "Tablón de ajuste con grapa unión univ., anclaje en compensación",
            "fr": "Fourrure en bois avec tendeur rapide univ., ancrage dans le compens.",
            "it": "Tavola di compensazione con morsetto universale, anc. nella compens.",
            "hu": "Illesztő fabetét univerzális kapcsolóval, ankerozás a kiegyenlítésen keresztül",
            "ru": "Пригнанный брус с универсал. зажимным приспособлением, анкерование в компенсатор",
            "sk": "Vyrovnávací hranol s univerzálnym upínačom, kotvenie vo vyrovnaní",
            "pl": "Wstawka komp. z zamkiem uniwersalnym, ściagi przez wstawkę"
        },
        {
            "ID": "XFRAMA_S_A6",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "XFRAMA_S_A6_AW1",
            "de": "Innenecke und Universal-Element 1.22",
            "en-gb": "Inside corner and universal panel 1.22",
            "en-us": "Inside corner and universal panel 1.22",
            "es": "Esqu. int. y elem. univ. 1.22",
            "fr": "Angle int et Px univ 1.22",
            "it": "Angolo interno e elemento uni 1.22",
            "hu": "Belso sarok és Uni elem 1,22",
            "ru": "Внутренний угол и универсальный элемент 1,22",
            "sk": "Vnút. roh a univ. panel 1,22",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna 1,22m"
        },
        {
            "ID": "XFRAMA_S_A6_AW10",
            "de": "Scharnier-Innenecke verz. innen und außen",
            "en-gb": "Hinged inside corner galv. inside and outside",
            "en-us": "Hinged inside corner galv. inside and outside",
            "es": "Esqu. bisagra int. galv. int. y ext.",
            "fr": "Angle charnière int galv. int et ext",
            "it": "Angolo cerniera int. galv. int. e est.",
            "hu": "Csukl.belso sarok horg. belül és kívül",
            "ru": "Шарнирный внутренний угол оцинкованный изнутри и снаружи",
            "sk": "Kĺbový vnútor. roh poz. zvnútra a zvonka",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. wewnątrz i na zewnątrz"
        },
        {
            "ID": "XFRAMA_S_A6_AW2",
            "de": "Innenecke und Außenecke",
            "en-gb": "Inside corner and outside corner",
            "en-us": "Inside corner and outside corner",
            "es": "Esqu. int. y esqu. ext.",
            "fr": "Angle int. et angle ext.",
            "it": "Angolo interno e angolo esterno",
            "hu": "Belso sarok és külso sarok",
            "ru": "Внутренний угол и внешний угол",
            "sk": "Vnút. roh a vonkaj. roh",
            "pl": "Narożnik wewnętrzny i narożnik zewnętrzny"
        },
        {
            "ID": "XFRAMA_S_A6_AW3",
            "de": "Scharnier-Innenecke und Scharnier-Außenecke",
            "en-gb": "Hinged inside corner and hinged outside corner",
            "en-us": "Hinged inside corner and hinged outside corner",
            "es": "Esqu. bisagra int. y esqu. bisagra ext.",
            "fr": "Angle charnière int et angle charnière ext",
            "it": "Angolo cerniera int. e angolo cerniera est.",
            "hu": "Csukl.belso sarok és csukl.külso sarok",
            "ru": "Шарнирный внутренний угол и шарнирный внешний угол",
            "sk": "Kĺbový vnútor. roh a kĺb.vonk.roh",
            "pl": "Narożnik wewnętrzny zawiasowy i narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "XFRAMA_S_A6_AW4",
            "de": "Scharnier-Innenecke innen und außen",
            "en-gb": "Hinged inside corner inside and outside",
            "en-us": "Hinged inside corner inside and outside",
            "es": "Esqu. bisagra int. int. y ext.",
            "fr": "Angle charnière int int et ext",
            "it": "Angolo cerniera int. int. e est.",
            "hu": "Csukl.belso sarok belül és kívül",
            "ru": "Шарнирный внутренний угол изнутри и снаружи",
            "sk": "Kĺbový vnútor. roh zvnútra a zvonka",
            "pl": "Narożnik wewnętrzny zawiasowy wewnątrz i na zewnątrz"
        },
        {
            "ID": "XFRAMA_S_A6_AW5",
            "de": "Innenecke und Universal-Element 0.90",
            "en-gb": "Inside corner and universal panel 0.90",
            "en-us": "Inside corner and universal panel 0.90",
            "es": "Esqu. int. y elem. univ. 0.90",
            "fr": "Angle int et Px univ 0.90",
            "it": "Angolo interno e elemento uni 0.90",
            "hu": "Belso sarok és Uni elem 0,90",
            "ru": "Внутренний угол и универсальный элемент 0,90",
            "sk": "Vnút. roh a univ. panel 0,90",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna 0,90"
        },
        {
            "ID": "XFRAMA_S_A6_AW6",
            "de": "Ausschalecke und Universal-Element 1.22",
            "en-gb": "Stripping corner and universal panel 1.22",
            "en-us": "Bias cut corner and universal panel 1.22",
            "es": "Esqu. de desencofrado y elem univ. 1.22",
            "fr": "Angle de décoffrage et Px univ 1.22",
            "it": "Angolo di disarmo e elemento uni 1.22",
            "hu": "Kizsaluzó sarokelem és uni elem 1,22",
            "ru": "Распалубочный уголок и универсальный элемент 1,22",
            "sk": "Oddebnovací roh a univ. panel 1,22",
            "pl": "Narożnik rozszalowujący i płyta uniwersalna 1,22"
        },
        {
            "ID": "XFRAMA_S_A6_AW7",
            "de": "Ausschalecke und Außenecke",
            "en-gb": "Stripping corner and outside corner",
            "en-us": "Bias cut corner and outside corner",
            "es": "Esqu. de desencofrado y esqu. ext.",
            "fr": "Angle de décoffrage et angle ext",
            "it": "Angolo di disarmo e angolo esterno",
            "hu": "Kizsaluzó sarokelem és külso sarok",
            "ru": "Распалубочный уголок и внешний угол",
            "sk": "Oddebnovací roh a vonkaj. roh",
            "pl": "Narożnik rozszalowujący i narożnik zewnętrzny"
        },
        {
            "ID": "XFRAMA_S_A6_AW8",
            "de": "Ausschalecke und Universal-Element 0.90",
            "en-gb": "Stripping corner and universal panel 0.90",
            "en-us": "Bias cut corner and universal panel 0.90",
            "es": "Esqu. de desencofrado y elem univ. 0.90",
            "fr": "Angle de décoffrage et Px univ 0.90",
            "it": "Angolo di disarmo e elemento uni 0.90",
            "hu": "Kizsaluzó sarokelem és uni elem 0,90",
            "ru": "Распалубочный уголок и универсальный элемент 0,90",
            "sk": "Oddebnovací roh a univ. panel 0,90",
            "pl": "Narożnik rozszalowujący i płyta uniwersalna 0,90"
        },
        {
            "ID": "XFRAMA_S_A6_AW9",
            "de": "Scharnier-Innenecke verz. und Scharnier-Außenecke verz.",
            "en-gb": "Hinged inside corner galv.and hinged outside corner galv.",
            "en-us": "Hinged inside corner galv.and hinged outside corner galv.",
            "es": "Esqu. bisagra int. galv. y esqu. bisagra ext. galv.",
            "fr": "Angle charnière int galv. et angle charnière ext galv.",
            "it": "Angolo cerniera int. galv. e angolo cerniera est. galv.",
            "hu": "Csukl.belso sarok horg. és csukl.külso sarok horg.",
            "ru": "Шарнирный внутренний угол оцинк. и шарнирный внешний угол оцинк.",
            "sk": "Kĺbový vnútor. roh poz. a kĺb.vonk.roh poz.",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i narożnik zewnętrzny zawiasowy ocynk."
        },
        {
            "ID": "XFRAMA_S_A7",
            "de": "Bauwerksstütze",
            "en-gb": "Column",
            "en-us": "Column",
            "es": "Pilar",
            "fr": "Poteau",
            "it": "Pilastro",
            "hu": "Pillér",
            "ru": "Колонна",
            "sk": "Stĺp",
            "pl": "Słup"
        },
        {
            "ID": "XFRAMA_S_A7_AW1",
            "de": "Uni-Element 1.22 mit Stützenbühne",
            "en-gb": "Universal panel 1.22 with column formwork platform",
            "en-us": "Universal panel 1.22 with column formwork platform",
            "es": "Elem. univ. 1.22 con plataf.para columna",
            "fr": "Px univ 1.22 avec passerelle pr. coffrage poteau",
            "it": "Elemento uni 1.22 con passerella per pilastri",
            "hu": "Uni elem 1.22 pillérállvány",
            "ru": "Универсальный элемент 1.22 с подмостями для колонн",
            "sk": "Uni.panel 1,22 so stĺp. plošinou",
            "pl": "Płyta uniwersalna 1,22 z pomostem do słupa"
        },
        {
            "ID": "XFRAMA_S_A7_AW2",
            "de": "Elemente mit Außenecken",
            "en-gb": "Panels with outside corners",
            "en-us": "Panels with outside corners",
            "es": "Elem. con esqu. ext.",
            "fr": "Px avec angle ext",
            "it": "Elemente con angoli esterni",
            "hu": "Elem külso sarokkal",
            "ru": "Элементы с внешними углами",
            "sk": "Panley s vonkaj. roh",
            "pl": "Płyty z narożnikami zewnętrznymi"
        },
        {
            "ID": "XFRAMA_S_A7_AW3",
            "de": "Uni-Element 1.22 ohne Stützenbühne",
            "en-gb": "Universal panel 1.22 w/o column formwork platform",
            "en-us": "Universal panel 1.22 w/o column formwork platform",
            "es": "Elem. univ. 1.22 sin plataf. para columna",
            "fr": "Px univ 1.22 sans passerelle pr. coffrage poteau",
            "it": "Elemento uni 1.22 senza passerella per pilastri",
            "hu": "Uni elem 1.22 pillérállvány nélkül",
            "ru": "Универсальный элемент 1.22 без подмостей для колонн",
            "sk": "Uni.panel 1,22 bez stĺp. plošinou",
            "pl": "Płyta uniwersalna 1,22 bez pomostu do słupa"
        },
        {
            "ID": "XFRAMA_S_A7_AW4",
            "de": "Uni-Element 0.90 mit Stützenbühne",
            "en-gb": "Universal panel 0.90 with column formwork platform",
            "en-us": "Universal panel 0.90 with column formwork platform",
            "es": "Elem. univ. 0.90 con plataf. para columna",
            "fr": "Px univ 0.90 avec passerelle pr. coffrage poteau",
            "it": "Elemento uni 0.90 con passerella per pilastri",
            "hu": "Uni elem 0.90 pillérállvány",
            "ru": "Универсальный элемент 0.90 с подмостями для колонн",
            "sk": "Uni.panel 0,90 so stĺp. Plošinou",
            "pl": "Płyta uniwersalna 0,90 z pomostem do słupa"
        },
        {
            "ID": "XFRAMA_S_A7_AW5",
            "de": "Uni-Element 0.90 ohne Stützenbühne",
            "en-gb": "Universal panel 0.90 w/o column formwork platform",
            "en-us": "Universal panel 0.90 w/o column formwork platform",
            "es": "Elem. univ. 0.90 sin plataf. para columna",
            "fr": "Px univ 0.90 sans passerelle pr. coffrage poteau",
            "it": "Elemento uni 0.90 senza passerella per pilastri",
            "hu": "Uni elem 0.90 pillérállvány nélkül",
            "ru": "Универсальный элемент 0.90 без подмостей для колонн",
            "sk": "Uni.panel 0,90 bez stĺp. plošinou",
            "pl": "Płyta uniwersalna 0,90 bez pomostu do słupa"
        },
        {
            "ID": "XFRAMA_S_A8",
            "de": "Verbindung der Aufstockung",
            "en-gb": "Connection of stacking",
            "en-us": "Connection with stuck",
            "es": "Conexión del apil.",
            "fr": "Connexion del rehausse",
            "it": "Giunzione della sovrapposizione",
            "hu": "Magasítás összekötés",
            "ru": "Соединение надстройки",
            "sk": "Spojenie nadstavenia",
            "pl": "Połączenie nadstawienia"
        },
        {
            "ID": "XFRAMA_S_A8_AW1",
            "de": "nicht aufgestockt bevorzugt",
            "en-gb": "not stacked preferred",
            "en-us": "not stacked preferred",
            "es": "No apilado preferido",
            "fr": "Ne pas rehaussé préféré",
            "it": "Preferibilmente senza sovrapposizione",
            "hu": "Magasítás nélküli preferált",
            "ru": "предпочтительно без надстройки",
            "sk": "nadstavenie nie je uprednostnené",
            "pl": "bez nadstawienia, preferowany"
        },
        {
            "ID": "XFRAMA_S_A8_AW2",
            "de": "Schnellspanner RU (hohe Wände Klemmschiene)",
            "en-gb": "Quick acting clamp RU (high walls universal waling)",
            "en-us": "Quick acting clamp RU (high walls universal waling)",
            "es": "Grapa unión ráp. RU (Muros altos riel de fijación)",
            "fr": "Serrage rapide RU (voiles grande hauteur Rail de blocage)",
            "it": "Morsetto rapido RU (pareti alte rotaia di fissaggio)",
            "hu": "Gyorskapcsoló RU (magas falak kötősín)",
            "ru": "Быстродействующее зажимное приспособление RU (высокие стены зажимная шина)",
            "sk": "Rýchloupínac RU (vysoké steny upínacia koľajnica)",
            "pl": "Zamek RU (uniwersalny rygiel ścienny do ścian wysokich)"
        },
        {
            "ID": "XFRAMA_S_A8_AW3",
            "de": "Uni-Spanner (hohe Wände Mehrzweckriegel)",
            "en-gb": "Multi function clamp (high walls multi-purpose waling)",
            "en-us": "Multi function clamp (high walls multi-purpose waling)",
            "es": "Grapa unión univ. (Muros altos riel multiuso)",
            "fr": "Tendeur rapide universel (voiles grande hauteur Filière multi-fonctions)",
            "it": "Morsetto universale (pareti alte corrente multiuso)",
            "hu": "Uni. kapcsoló (magas falak többcélú heveder)",
            "ru": "Универсальный зажим (высокие стены универсальный ригель)",
            "sk": "Univ. upínac (vysoké steny univerzálny paždík)",
            "pl": "Zamek uniwersalny (wielofunkcyjny rygiel ścienny do ścian wysokich)"
        },
        {
            "ID": "XFRAMA_S_A9",
            "de": "Stehend oder liegende Aufstockung",
            "en-gb": "Upright or horizontal stacking",
            "en-us": "Upright or horizontal stacking",
            "es": "Apilado horizontal o vertical",
            "fr": "Rehausse de px debout ou couchés",
            "it": "Sovrapposizione orizzontale o verticale degli elementi",
            "hu": "Állított ill. fektetett elemmagasítás",
            "ru": "Вертикальная или горизонтальная надстройка",
            "sk": "Nadstavenie nastojato alebo naležato!",
            "pl": "Nadstawienie pionowe lub poziome"
        },
        {
            "ID": "XFRAMA_S_A9_AW1",
            "de": "ohne Aufstockung",
            "en-gb": "w/o stacking",
            "en-us": "w/o stacking",
            "es": "Sin apilado",
            "fr": "sans rehausse",
            "it": "senza sopralzo",
            "hu": "Magasítás nélkül",
            "ru": "без надстройки",
            "sk": "bez nadstavenia",
            "pl": "bez nadstawienia"
        },
        {
            "ID": "XFRAMA_S_A9_AW2",
            "de": "Stehend aufgestockt",
            "en-gb": "stacked upright",
            "en-us": "stacked upright",
            "es": "Apilado vertical",
            "fr": "Rehaussé deboutant",
            "it": "Sovrapposizione vert.",
            "hu": "Állítva magasítva",
            "ru": "надстройка в вертикальном положении",
            "sk": "Nadstavenie nastojato",
            "pl": "nadstawienie pionowe"
        },
        {
            "ID": "XFRAMA_S_A9_AW3",
            "de": "Liegend aufgestockt",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz.",
            "es": "Apilado horizontal",
            "fr": "Rehaussé couchant",
            "it": "Sovrapposizione orizz.",
            "hu": "Fektetve magasítva",
            "ru": "надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "pl": "nadstawienie poziome"
        },
        {
            "ID": "XFRAMI_A1",
            "de": "Grundelementhöhe",
            "en-gb": "Basic panel height",
            "en-us": "Basic panel height",
            "es": "Altura del elemento base",
            "fr": "Hauteur de base",
            "it": "Altezza dell’ elemento base",
            "hu": "Alapelem magasság",
            "ru": "Высота основного элемента",
            "sk": "Výška základného panelu",
            "pl": "Wysokość płyty podstawowej"
        },
        {
            "ID": "XFRAMI_A1_AW1",
            "de": "1,20 und 1,50",
            "en-gb": "1.20 and 1.50 m",
            "en-us": "1.20 and 1.50",
            "es": "1,20 y 1,50",
            "fr": "1,20 et 1,50",
            "it": "1,20 e 1,50",
            "hu": "1,20 és 1,50",
            "ru": "1,20 и 1,50",
            "sk": "1,20 a 1,50",
            "pl": "1,20 i 1,50m"
        },
        {
            "ID": "XFRAMI_A1_AW2",
            "de": "2,70",
            "en-gb": "2.70 m",
            "en-us": "2.70 m",
            "es": "2.70 m",
            "fr": "2,70 m",
            "it": "2,70 m",
            "hu": "2,70 m",
            "ru": "2,70 м",
            "sk": "2,70 m",
            "pl": "2,70 m"
        },
        {
            "ID": "XFRAMI_A1_AW3",
            "de": "3,00",
            "en-gb": "3.00 m",
            "en-us": "3.00 m",
            "es": "3.00 m",
            "fr": "3,00 m",
            "it": "3,00 m",
            "hu": "3,00 m",
            "ru": "3,00 м",
            "sk": "3,00 m",
            "pl": "3,00 m"
        },
        {
            "ID": "XFRAMI_A10",
            "de": "Umsetzeinheiten bevorzugt Ja/Nein",
            "en-gb": "Shifting units preferred Yes/No",
            "en-us": "Shifting units preferred Yes/No",
            "es": "Unidades de desplazamiento preferido Sí/No",
            "fr": "Unités de translation préféré oui/non",
            "it": "Unità movimentazione preferito si/non",
            "hu": "Áthelyez·egységek preferált Igen/Nem",
            "ru": "Перемещаемые блоки предпочтительно Да/Нет",
            "sk": "Transportné jednotky uprednostnené áno/nie",
            "pl": "Zestawy do przestawiania preferowane Tak/Nie"
        },
        {
            "ID": "XFRAMI_A10_AW1",
            "de": "Bevorzugt Ja",
            "en-gb": "Preferred Yes",
            "en-us": "Preferred Yes",
            "es": "Preferido Sí",
            "fr": "Préféré oui",
            "it": "Preferito si",
            "hu": "preferált Igen",
            "ru": "Предпочтительно Да",
            "sk": "uprdnostnené áno",
            "pl": "Preferowany Tak"
        },
        {
            "ID": "XFRAMI_A10_AW2",
            "de": "Bevorzugt Nein",
            "en-gb": "Preferred No",
            "en-us": "Preferred No",
            "es": "Preferido No",
            "fr": "Préféré non",
            "it": "Preferito non",
            "hu": "preferált nem",
            "ru": "Предпочтительно Нет",
            "sk": "uprednostnené nie",
            "pl": "Preferowany Nie"
        },
        {
            "ID": "XFRAMI_A11",
            "de": "Bauwerksstütze",
            "en-gb": "Column",
            "en-us": "Column",
            "es": "Pilar",
            "fr": "Poteau",
            "it": "Pilastro",
            "hu": "Pillér",
            "ru": "Колонна",
            "sk": "Stĺp",
            "pl": "Słup"
        },
        {
            "ID": "XFRAMI_A11_AW1",
            "de": "Universalelement 0,75",
            "en-gb": "Universal panel 0.75",
            "en-us": "Universal panel 0.75",
            "es": "Elem. univ. 0,75",
            "fr": "Px. univ. 0.75",
            "it": "Elementi universali 0,75",
            "hu": "Uni elem 0,75",
            "ru": "Универсальный элемент 0,75",
            "sk": "Uni. panely 0,75",
            "pl": "Element uniwersalny 0,75"
        },
        {
            "ID": "XFRAMI_A11_AW2",
            "de": "Universalelement 0,90",
            "en-gb": "Universal panel 0.90",
            "en-us": "Universal panel 0.90",
            "es": "Elem. univ. 0,90",
            "fr": "Px. univ. 0.90",
            "it": "Elementi universali 0,90",
            "hu": "Uni elem 0,90",
            "ru": "Универсальный элемент 0,90",
            "sk": "Uni. panely 0,90",
            "pl": "Element uniwersalny 0,90"
        },
        {
            "ID": "XFRAMI_A11_AW3",
            "de": "Universalelement 0,75 und Elemente",
            "en-gb": "Universal panel 0.75 and panels",
            "en-us": "Universal panel 0.75 and elements",
            "es": "Elem. univ. 0,75 y elementos",
            "fr": "Px. univ. 0.75 et élément",
            "it": "Elementi universali 0,75 e elementi",
            "hu": "Uni elem 0,75 és normál táblák",
            "ru": "Универсальный элемент 0,75 и элементы",
            "sk": "Uni. panely 0,75 a panely",
            "pl": "Płyta uniwersalna 0,75 i płyty standardowe"
        },
        {
            "ID": "XFRAMI_A11_AW4",
            "de": "Universalelement 0,90 und Elemente",
            "en-gb": "Universal panel 0.90 and panels",
            "en-us": "Universal panel 0.90 and elements",
            "es": "Elem. univ. 0,90 y elementos",
            "fr": "Px. univ. 0.90 et élément",
            "it": "Elementi universali 0,90 e elementi",
            "hu": "Uni elem 0,90 és normál táblák",
            "ru": "Универсальный элемент 0,90 и элементы",
            "sk": "Uni. panely 0,90 a panely",
            "pl": "Płyta uniwersalna 0,90 i płyty standardowe"
        },
        {
            "ID": "XFRAMI_A11_AW5",
            "de": "Elemente mit Außenecke",
            "en-gb": "Panels with outside corner",
            "en-us": "Panels with outside corner",
            "es": "Elementos con esqu. ext.",
            "fr": "Px avec angle ext.",
            "it": "Elementi con angolo esterno",
            "hu": "Elem csukl.kül.sarok",
            "ru": "элементы с внешний угол",
            "sk": "Panely s vonkaj. roh",
            "pl": "Płyta z narożnikiem zewnętrznym"
        },
        {
            "ID": "XFRAMI_A12",
            "de": "Großelement",
            "en-gb": "Large panel",
            "en-us": "Large panel",
            "es": "Elemento largo",
            "fr": "Grande élément",
            "it": "Elemento grande",
            "hu": "Nagytábla",
            "ru": "Крупноразмерный элемент",
            "sk": "Velkoplošný panel",
            "pl": "Płyta duża"
        },
        {
            "ID": "XFRAMI_A12_AW1",
            "de": "ohne",
            "en-gb": "without",
            "en-us": "without",
            "es": "sin",
            "fr": "sans",
            "it": "senza",
            "hu": "nem",
            "ru": "без",
            "sk": "bez",
            "pl": "bez"
        },
        {
            "ID": "XFRAMI_A12_AW2",
            "de": "stehend",
            "en-gb": "upright",
            "en-us": "upright",
            "es": "vertical",
            "fr": "debout",
            "it": "verticale",
            "hu": "állítva",
            "ru": "вертикально",
            "sk": "nastojato",
            "pl": "pionowy"
        },
        {
            "ID": "XFRAMI_A12_AW3",
            "de": "liegend",
            "en-gb": "horizontal",
            "en-us": "horizontal",
            "es": "horizontal",
            "fr": "couché",
            "it": "orizzontale",
            "hu": "fekvo",
            "ru": "горизонтально",
            "sk": "naležato",
            "pl": "poziomy"
        },
        {
            "ID": "XFRAMI_A2",
            "de": "Stehende oder liegende Elemente",
            "en-gb": "Upright or horiz. panels",
            "en-us": "Upright or horiz. panels",
            "es": "Elementos verticales o horizontales",
            "fr": "Px debouts ou couchés",
            "it": "Elementi vert. o orizz.",
            "hu": "Elemek állítva vagy fekvo",
            "ru": "Элементы, установленные горизонтально или вертикально",
            "sk": "Panely nastojato alebo naležato",
            "pl": "Płyty pionowe lub poziome"
        },
        {
            "ID": "XFRAMI_A2_AW1",
            "de": "stehend",
            "en-gb": "upright",
            "en-us": "upright",
            "es": "vertical",
            "fr": "debout",
            "it": "verticale",
            "hu": "állítva",
            "ru": "вертикально",
            "sk": "nastojato",
            "pl": "pionowy"
        },
        {
            "ID": "XFRAMI_A2_AW2",
            "de": "liegend",
            "en-gb": "horizontal",
            "en-us": "horizontal",
            "es": "horizontal",
            "fr": "couché",
            "it": "orizzontale",
            "hu": "fekvo",
            "ru": "горизонтально",
            "sk": "naležato",
            "pl": "poziomy"
        },
        {
            "ID": "XFRAMI_A3",
            "de": "Aufstockung",
            "en-gb": "Stacking",
            "en-us": "Stacking",
            "es": "Apilado",
            "fr": "Rehaussé",
            "it": "Sovrapposizione",
            "hu": "Magasítás",
            "ru": "Надстройка",
            "sk": "Nadstavenia",
            "pl": "Nadstawienie"
        },
        {
            "ID": "XFRAMI_A3_AW1",
            "de": "Richtspanner",
            "en-gb": "Aligning clamp",
            "en-us": "Aligning clamp",
            "es": "Grapa de unión rígida",
            "fr": "Rail de serrage",
            "it": "Morsetto di regolazione",
            "hu": "Beállító kapcsoló",
            "ru": "Юстировочное зажимное приспособление",
            "sk": "smerový upínač",
            "pl": "Zamek prostujący"
        },
        {
            "ID": "XFRAMI_A3_AW2",
            "de": "Klemmschiene",
            "en-gb": "Universal waling",
            "en-us": "Universal waling",
            "es": "Riel de fijación",
            "fr": "Rail de blocage",
            "it": "Rotaia di fissaggio",
            "hu": "Kötősín",
            "ru": "Зажимная шина",
            "sk": "Upínacia koľajnica",
            "pl": "Szyna dociskowa"
        },
        {
            "ID": "XFRAMI_A3_AW3",
            "de": "ohne Aufstockung",
            "en-gb": "w/o stacking",
            "en-us": "w/o stacking",
            "es": "Sin apilado",
            "fr": "sans rehausse",
            "it": "senza sopralzo",
            "hu": "Magasítás nélkül",
            "ru": "Без надстройки",
            "sk": "bez nadstavenia",
            "pl": "bez nadstawienia"
        },
        {
            "ID": "XFRAMI_A3_AW4",
            "de": "Aufstockung ohne Aussteifung",
            "en-gb": "stacking w/o bracing",
            "en-us": "stacking w/o bracing",
            "es": "Apilado sin rigidización",
            "fr": "Rehausse sans contreventement",
            "it": "sopralzo senza controventatura",
            "hu": "Magasítás nélkül merevítés",
            "ru": "Надстройка без повышения жесткости",
            "sk": "Nadstavenia a vystuženie",
            "pl": "nadstawienie bez stężeń"
        },
        {
            "ID": "XFRAMI_A4",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "XFRAMI_A4_AW1",
            "de": "Fundamentspanner und Ankerhaltewinkel",
            "en-gb": "Foundation clamp and tie-holder bracket",
            "en-us": "Foundation clamp and anchoring bracket",
            "es": "Grapa base y ang. de suj. de ancl.",
            "fr": "Serrage pour fondation et équerre d'ancrage",
            "it": "Morsetto per fondazioni e squadretta d'ancoraggio",
            "hu": "Ankerszalag feszíto és Ankertartó szögvas",
            "ru": "Фундаментный зажим и упорный уголок для анкера",
            "sk": "Základový upínač a držiak kot. tyce",
            "pl": "Napinacz i uchwyt do ściągów"
        },
        {
            "ID": "XFRAMI_A4_AW2",
            "de": "Fundamentspanner und Flachanker",
            "en-gb": "Foundation clamp and flat tie-rod",
            "en-us": "Foundation clamp and flat tie-rod",
            "es": "Grapa base y anclaje plano",
            "fr": "Serrage pour fondation et entretoise",
            "it": "Morsetto per fondazioni e ancorante piatto",
            "hu": "Ankerszalag feszíto és Laposanker",
            "ru": "Фундаментный зажим и плоский анкер",
            "sk": "Základový upínač a plochá kotva",
            "pl": "Napinacz i ściąg płaski"
        },
        {
            "ID": "XFRAMI_A4_AW4",
            "de": "Ankerstab",
            "en-gb": "Tie rod",
            "en-us": "Tie rod",
            "es": "Barra de ancl.",
            "fr": "Tige d'ancrage",
            "it": "Barra ancorante",
            "hu": "Ankerrúd",
            "ru": "Анкерный стержень",
            "sk": "Kotevní tyč",
            "pl": "Ściąg"
        },
        {
            "ID": "XFRAMI_A4_AW5",
            "de": "Bodenhalter und Flachanker",
            "en-gb": "Floor fixing plate and flat tie-rod",
            "en-us": "Bracing clip and top yoke",
            "es": "Placa de fijación a suelo y Anclaje plano",
            "fr": "Support d'ancrage et entretoise",
            "it": "Piastra di fissaggio e ancorante piatto",
            "hu": "Aljzatrögzítő és Laposanker",
            "ru": "Держатель для пола и плоский анкер",
            "sk": "Držiak spodku debnenia a plochá kotva",
            "pl": "Płytka mocująca do podłoża … Ściąg płaski"
        },
        {
            "ID": "XFRAMI_A5",
            "de": "Elementtyp",
            "en-gb": "Panel type",
            "en-us": "Panel type",
            "es": "Tipo de elemento",
            "fr": "Type d'élément",
            "it": "Tipo elemento",
            "hu": "Elemtípus",
            "ru": "Тип элемента",
            "sk": "Typ panelu",
            "pl": "Typ płyty"
        },
        {
            "ID": "XFRAMI_A5_AW1",
            "de": "Normalelemente",
            "en-gb": "Standard panels",
            "en-us": "Standard panels",
            "es": "Elementos normalos",
            "fr": "Éléments étalons",
            "it": "Elementi normali",
            "hu": "Normál táblák",
            "ru": "Унифицированные элементы",
            "sk": "Štandardné panely",
            "pl": "Płyty standardowe"
        },
        {
            "ID": "XFRAMI_A5_AW2",
            "de": "Uni-Elemente (0,90; 0,75 breit)",
            "en-gb": "Universal panels (0.90, 0.75 wide)",
            "en-us": "Universal panel (0.90,0.75 wide)",
            "es": "Elem. univ. (0.90,0.75 ancho)",
            "fr": "Px. univ. (0.90, 0.75 large)",
            "it": "Elementi universali (0.90, 0.75 ancho)",
            "hu": "Uni elem (0.90, 0.75szélesség)",
            "ru": "Универсальные элементы (0.90, 0.75 ширина)",
            "sk": "Uni. panely (0.90,0.75 šírky)",
            "pl": "Płyty uniwersalne (szerokość 0,9/0,75)"
        },
        {
            "ID": "XFRAMI_A5_AW3",
            "de": "Ankerelemente",
            "en-gb": "Anchoring panels",
            "en-us": "Anchoring panel",
            "es": "Elementos anclaje",
            "fr": "Ancrage de rive",
            "it": "Elementi ancoranti",
            "hu": "Ankerelem",
            "ru": "Анкерные элементы",
            "sk": "Kotv. panely",
            "pl": "Płyty kotwiące"
        },
        {
            "ID": "XFRAMI_A6",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "XFRAMI_A6_AW1",
            "de": "Innenecke und Uni-Element 0,75",
            "en-gb": "Inside corner and universal panel 0.75",
            "en-us": "Inside corner and universal panel 0.75",
            "es": "Esqu. int. y elem. univ. 0,75",
            "fr": "Angle intérieur et pann. univ. 0,75",
            "it": "Angolo interno ed elemento uni 0,75",
            "hu": "Belso sarok és uni elem 0,75",
            "ru": "Внутренний угол и унив.элементом 0,75",
            "sk": "Vnútorný roh + univerzálny panel 0,75",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna 0,75"
        },
        {
            "ID": "XFRAMI_A6_AW10",
            "de": "S-Innenecke verz. innen und außen",
            "en-gb": "Hinged inside corner galv. inside and outside",
            "en-us": "Hinged inside corner galv. inside and outside",
            "es": "Esqu. bisagra int. galv. int. y ext.",
            "fr": "S-Angle charnière int. galv. int. et ext.",
            "it": "Angolo interno S galv. int. ed est.",
            "hu": "Csukl.bel.sarok horg. belül és kívül",
            "ru": "Шарнирный внутренний угол оцинкованный изнутри и снаружи",
            "sk": "ĺb.vnút.roh pozinkovaná vnút.+vonk.",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. wewnątrz i na zewnątrz"
        },
        {
            "ID": "XFRAMI_A6_AW11",
            "de": "S-Innenecke verz. und Uni-Element 0,90",
            "en-gb": "Hinged inside corner galv. and universal panel 0.90",
            "en-us": "Hinged inside corner galv. and universal panel 0.90",
            "es": "Squ. bisagra int. galv. y elem. univ. 0,90",
            "fr": "S-Angle intérieur galv. et pann. univ. 0,90",
            "it": "Angolo interno S galv. ed elemento uni 0,90",
            "hu": "Csukl.bel.sarok horg. és uni elem 0,90",
            "ru": "Шарнирный внутренний угол оцинкованный и универсальный элемент 0,90",
            "sk": "Kĺb.vnút.roh pozinkovaná + univerzálny panel 0,90",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i płyta uniwersalna 0,90"
        },
        {
            "ID": "XFRAMI_A6_AW12",
            "de": "Ausschalecke (einseitig)",
            "en-gb": "Stripping corner (only on one side)",
            "en-us": "Bias cut corner (only on one side)",
            "es": "Esquina de desencofrado (de un solo lado)",
            "fr": "Angle de décoffrage (une face)",
            "it": "Angolo di disarmo (solo su un lato)",
            "hu": "Kizsaluzó sarok (egyoldali)",
            "ru": "Распалубочный уголок (с одной стороны)",
            "sk": "Oddebňovací roh (jednostranne)",
            "pl": "Narożnik rozszalowujący (tylko z jednej strony)"
        },
        {
            "ID": "XFRAMI_A6_AW2",
            "de": "Innenecke und Außenecke",
            "en-gb": "Inside corner and outside corner",
            "en-us": "Inside corner and outside corner",
            "es": "Esqu. int. y esqu. ext.",
            "fr": "Angle int. et angle ext.",
            "it": "Angolo interno e angolo esterno",
            "hu": "Belso sarok és külso sarok",
            "ru": "Внутренний угол и внешний угол",
            "sk": "Vnútorný roh + vonkajší roh",
            "pl": "Narożnik wewnętrzny i narożnik zewnętrzny"
        },
        {
            "ID": "XFRAMI_A6_AW3",
            "de": "S-Innenecke und Uni-Element 0,75",
            "en-gb": "Hinged inside corner and universal panel 0.75",
            "en-us": "Hinged inside corner and universal panel 0.75",
            "es": "Esqu. bisagra int. y elem. univ. 0,75",
            "fr": "S-Angle intérieur et pann. univ. 0,75",
            "it": "Angolo interno S ed elemento uni 0,75",
            "hu": "Csukl.bel.sarok és uni elem 0,75",
            "ru": "Шарнирный внутренний угол и универсальный элемент 0,75",
            "sk": "Kĺb.vnút.roh + univerzálny panel 0,75",
            "pl": "Narożnik wewnętrzny zawiasowy i płyta uniwersalna 0,75"
        },
        {
            "ID": "XFRAMI_A6_AW4",
            "de": "S-Innen und S-Außenecke",
            "en-gb": "Hinged inside and hinged outside corner",
            "en-us": "Hinged inside and hinged outside corner",
            "es": "Esqu. bisagra int. y esqu. bisagra ext.",
            "fr": "S-Angle int. et angle ext.",
            "it": "Angolo int. S ed est. S",
            "hu": "Csukl.bel. és csukl.kül.sarok",
            "ru": "шарнирный внутренний угол и шарнирный внешний угол",
            "sk": "Kĺb.vnút.roh + kĺb.vonk.roh",
            "pl": "Zawiasy wewnątrz + narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "XFRAMI_A6_AW5",
            "de": "S-Innenecke innen und außen",
            "en-gb": "Hinged inside corner inside and outside",
            "en-us": "Hinged inside corner inside and outside",
            "es": "Esqu. bisagra int. int. y ext.",
            "fr": "S-Angle charnière int. int. et ext.",
            "it": "Angolo interno S int. ed est.",
            "hu": "Csukl.bel.sarok belül és kívül",
            "ru": "Шарнирный внутренний угол изнутри и снаружи",
            "sk": "ĺb.vnút.roh vnút.+vonk.",
            "pl": "Narożnik wewnętrzny zawiasowy wewnątrz i na zewnątrz"
        },
        {
            "ID": "XFRAMI_A6_AW6",
            "de": "Innenecke und Uni-Element 0,90",
            "en-gb": "Inside corner and universal panel 0.90",
            "en-us": "Inside corner and universal panel 0,90",
            "es": "Esqu. int. y elem. univ. 0,90",
            "fr": "Angle intérieur et pann. univ. 0,90",
            "it": "Angolo interno ed elemento uni 0,90",
            "hu": "Belso sarok és uni elem 0,90",
            "ru": "Внутренний угол с унив.элементом 0,90",
            "sk": "Vnútorný roh + univerzálny panel 0,90",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna 0,90"
        },
        {
            "ID": "XFRAMI_A6_AW7",
            "de": "S-Innenecke und Uni-Element 0,90",
            "en-gb": "Hinged inside corner and universal panel 0.90",
            "en-us": "Hinged inside corner and universal panel 0,90",
            "es": "Esqu. bisagra int. y elem. univ. 0,90",
            "fr": "S-Angle intérieur et pann. univ. 0,90",
            "it": "Angolo interno S ed elemento uni 0,90",
            "hu": "Csukl.bel.sarok és uni elem 0,90",
            "ru": "Шарнирный внутренний угол и универсальный элемент 0,90",
            "sk": "Kĺb.vnút.roh + univerzálny panel 0,90",
            "pl": "Narożnik wewnętrzny zawiasowy i płyta uniwersalna 0,90"
        },
        {
            "ID": "XFRAMI_A6_AW8",
            "de": "S-Innenecke verz. und Uni-Element 0,75",
            "en-gb": "Hinged inside corner galv. and universal panel 0.75",
            "en-us": "Hinged inside corner galv. and universal panel 0.75",
            "es": "Squ. bisagra int. galv. y elem. univ. 0,75",
            "fr": "S-Angle intérieur galv. et pann. univ. 0,75",
            "it": "Angolo interno S galv. ed elemento uni 0,75",
            "hu": "Csukl.bel.sarok horg. és uni elem 0,75",
            "ru": "Шарнирный внутренний угол оцинкованный и универсальный элемент 0,75",
            "sk": "Kĺb.vnút.roh pozinkovaná + univerzálny panel 0,75",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i płyta uniwersalna 0,75"
        },
        {
            "ID": "XFRAMI_A6_AW9",
            "de": "S-Innen verz. und S-Außenecke verz.",
            "en-gb": "Hinged inside galv.and hinged outside corner galv.",
            "en-us": "Hinged inside galv.and hinged outside corner galv.",
            "es": "Esqu. bisagra int. galv. y esqu. bisagra ext. galv.",
            "fr": "S-Angle int. galv. et angle ext. galv.",
            "it": "Angolo int. S galv. ed est. S galv.",
            "hu": "Csukl.bel. horg. és csukl.kül.sarok horg.",
            "ru": "шарнирный внутренний угол оцинкованный и шарнирный внешний угол оцинкованный",
            "sk": "Kĺb.vnút.roh pozinkovaná+ kĺb.vonk.roh pozinkovaná",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i narożnik zewnętrzny zawiasowy ocynk."
        },
        {
            "ID": "XFRAMI_A7",
            "de": "Wandende",
            "en-gb": "Wall end",
            "en-us": "Wall end",
            "es": "Fin del muro",
            "fr": "Fin de mur",
            "it": "Fine del muro",
            "hu": "Falvég",
            "ru": "Конец стены",
            "sk": "Ukončenie steny",
            "pl": "Koniec ściany"
        },
        {
            "ID": "XFRAMI_A7_AW1",
            "de": "Klemmschienen",
            "en-gb": "Universal walings",
            "en-us": "Universal walings",
            "es": "Riel de fijación",
            "fr": "Rails de blocage",
            "it": "Rotaia di fissaggio",
            "hu": "Kötősín",
            "ru": "Зажимные шины",
            "sk": "Upínacia koľajnica",
            "pl": "Uniwersalne rygle ścienne"
        },
        {
            "ID": "XFRAMI_A7_AW2",
            "de": "Universalelement 0,75",
            "en-gb": "Universal panel 0.75",
            "en-us": "Universal panel 0.75",
            "es": "Elem. univ. 0,75",
            "fr": "Px. univ. 0,75",
            "it": "Elementi universali 0,75",
            "hu": "Uni elem 0,75",
            "ru": "Универсальный элемент 0,75",
            "sk": "Uni. panely 0,75",
            "pl": "Element uniwersalny 0,75"
        },
        {
            "ID": "XFRAMI_A7_AW3",
            "de": "Universalelement 0,90",
            "en-gb": "Universal panel 0.90",
            "en-us": "Universal panel 0.90",
            "es": "Elem. univ. 0,90",
            "fr": "Px. univ. 0,90",
            "it": "Elementi universali 0,90",
            "hu": "Uni elem 0,90",
            "ru": "Универсальный элемент 0,90",
            "sk": "Uni. panely 0,90",
            "pl": "Element uniwersalny 0,90"
        },
        {
            "ID": "XFRAMI_A7_AW4",
            "de": "Stirnabschalzwinge",
            "en-gb": "Stop-end waler tie",
            "en-us": "Stop-end waler tie",
            "es": "Sargento para tape",
            "fr": "Rail d'about",
            "it": "Corrente di testata",
            "hu": "Véglezáró sín",
            "ru": "Торцевая накладка",
            "sk": "Zvierka debnenia čiel",
            "pl": "Zacisk deskowania czołowego"
        },
        {
            "ID": "XFRAMI_A8",
            "de": "Anschlüsse, Ausgleiche",
            "en-gb": "Connections, closures",
            "en-us": "Connections, fillers",
            "es": "Conexiónes, compensaciónes",
            "fr": "Connexions, compensations",
            "it": "Collegamenta, Compensazioni",
            "hu": "Csatlakozó, Kiegyenlítése",
            "ru": "Стыки, компенсаторы",
            "sk": "Napojenia, ručné vyrovnania",
            "pl": "Połączenia, wypełnienia"
        },
        {
            "ID": "XFRAMI_A8_AW1",
            "de": "Passholz Doka, ohne Klemmschiene",
            "en-gb": "Fitting timber Doka, w/o universal waling",
            "en-us": "Fitting timber Doka, w/o universal waling",
            "es": "Tablón de ajuste Doka, sin riel de sujeción",
            "fr": "Fourrure en bois Doka, sans rail de blocage",
            "it": "Tavola di compensazione Doka, senza rotaia di fissaggio",
            "hu": "Illesztő fabetét Doka, kötősín nélkül",
            "ru": "Пригнанный брус Doka, без зажимной шины",
            "sk": "Vyrovnávací hranol Doka, bez upínacej koľajnice",
            "pl": "Wstawka komp. Doka bez uniwersalnego rygla ściennego"
        },
        {
            "ID": "XFRAMI_A8_AW10",
            "de": "Normalelement",
            "en-gb": "Standard panel",
            "en-us": "Standard panel",
            "es": "Elemento normal",
            "fr": "Élément étalon",
            "it": "Elemento normale",
            "hu": "Normál tábla",
            "ru": "Унифицированный элемент",
            "sk": "Štandardný panel",
            "pl": "Płyta standardowa"
        },
        {
            "ID": "XFRAMI_A8_AW11",
            "de": "Schalhautwinkel und Schalungsplatte 18 mm",
            "en-gb": "Plywood support and formwork sheet 18 mm",
            "en-us": "Plywood support and formwork sheet 18 mm",
            "es": "Áng. de superficie de enc. y tabl. de encofr. 18mm",
            "fr": "Support de compensation et panneau 18mm",
            "it": "Supporto di compensazione e pannello 18mm",
            "hu": "Zsaluhéjtartó szögvas és zsaluhéj 18mm",
            "ru": "Опалубочный уголок и опалубочная плита 18мм",
            "sk": "Uholník k debniacej škrupine + debniaca doska 18mm",
            "pl": "Narożnik przekładniowy i sklejka 18 mm"
        },
        {
            "ID": "XFRAMI_A8_AW12",
            "de": "Elemente vom Vorgängertakt verwenden",
            "en-gb": "Use panels of previous cycle",
            "en-us": "Use panels of previous pour",
            "es": "Utilizar los elementos del ciclo anterior",
            "fr": "Utiliser les éléments du coffrage précédent",
            "it": "Utilizza elementi del ciclo precedente",
            "hu": "Az előző ciklus elemeinek alkalmazása",
            "ru": "Использовать элементы предыдущего такта",
            "sk": "Použiť prvky z predchádzajúceho taktu",
            "pl": "Użyj płyt z poprzedniego taktu"
        },
        {
            "ID": "XFRAMI_A8_AW2",
            "de": "Kantholz bauseits, ohne Klemmschiene",
            "en-gb": "Squared timber by site, w/o universal waling",
            "en-us": "Squared timber by site, w/o universal waling",
            "es": "Liston por parte de obre, sin riel de sujeción",
            "fr": "Bois équarri chantier, sans rail de blocage",
            "it": "Legno squadrato a carico cant., senza rotaia di fissaggio",
            "hu": "Furfa helyi anyag, kötősín nélkül",
            "ru": "Деревянный брус (предоставляется заказчиком), без зажимной шины",
            "sk": "Hranol dod. stavby, bez upínacej koľajnice",
            "pl": "Kantówka po str. budowy bez uniwersalnego rygla ściennego"
        },
        {
            "ID": "XFRAMI_A8_AW3",
            "de": "Universalelement 0,75",
            "en-gb": "Universal panel 0.75",
            "en-us": "Universal panel 0.75",
            "es": "Elem. univ. 0,75",
            "fr": "Px. univ. 0,75",
            "it": "Elementi universali 0,75",
            "hu": "Uni elem 0,75",
            "ru": "Универсальный элемент 0,75",
            "sk": "Uni. panely 0,75",
            "pl": "Element uniwersalny 0,75"
        },
        {
            "ID": "XFRAMI_A8_AW4",
            "de": "Ankerelement",
            "en-gb": "Anchoring panel",
            "en-us": "Anchoring panel",
            "es": "Elementos anclaje",
            "fr": "Ancrage de rive",
            "it": "Elemento ancorante",
            "hu": "Ankerelem",
            "ru": "Анкерный элемент",
            "sk": "Kotv. panel.",
            "pl": "Element kotwowy"
        },
        {
            "ID": "XFRAMI_A8_AW5",
            "de": "Schalhautwinkel und Schalungsplatte 27 mm",
            "en-gb": "Plywood support and formwork sheet 27 mm",
            "en-us": "Plywood support and formwork sheet 27mm",
            "es": "Áng. de superficie de enc. y tabl. de encofr. 27mm",
            "fr": "Support de compensation et panneau 27mm",
            "it": "Supporto di compensazione e pannello 27mm",
            "hu": "Zsaluhéjtartó szögvas és zsaluhéj 27mm",
            "ru": "Опалубочный уголок и опалубочная плита 27мм",
            "sk": "Uholník k debniacej škrupine + debniaca doska 27mm",
            "pl": "Narożnik przekładniowy i sklejka 27 mm"
        },
        {
            "ID": "XFRAMI_A8_AW6",
            "de": "Schalhautwinkel und Schalungsplatte 21 mm",
            "en-gb": "Plywood support and formwork sheet 21 mm",
            "en-us": "Plywood support and formwork sheet 21mm",
            "es": "Áng. de superficie de enc. y tabl. de encofr. 21mm",
            "fr": "Support de compensation et panneau 21mm",
            "it": "Supporto di compensazione e pannello 21mm",
            "hu": "Zsaluhéjtartó szögvas és zsaluhéj 21mm",
            "ru": "Опалубочный уголок и опалубочная плита 21мм",
            "sk": "Uholník k debniacej škrupine + debniaca doska 21mm",
            "pl": "Narożnik przekładniowy i sklejka 21 mm"
        },
        {
            "ID": "XFRAMI_A8_AW7",
            "de": "Passholz Doka, mit Klemmschiene",
            "en-gb": "Fitting timber Doka, with universal waling",
            "en-us": "Fitting timber Doka, with universal waling",
            "es": "Tablón de ajuste Doka, con riel de sujeción",
            "fr": "Fourrure en bois Doka, av. rail de blocage",
            "it": "Tavola di compensazione Doka, con rotaia di fissaggio",
            "hu": "Illeszto fabetét Doka, kötősínnel",
            "ru": "Пригнанный брус Doka, с зажимной шиной",
            "sk": "Vyrovnávací hranol Doka, s upínacou koľajnicou",
            "pl": "Wstawka komp. Doka bez uniwersalnego rygla ściennego"
        },
        {
            "ID": "XFRAMI_A8_AW8",
            "de": "Kantholz bauseits, mit Klemmschiene",
            "en-gb": "Squared timber by site, with universal waling",
            "en-us": "Squared timber by site, with universal walin",
            "es": "Liston por parte de obre, con riel de sujeción",
            "fr": "Bois équarri chantier, av. rail de blocage",
            "it": "Legno squadrato a carico cant., con rotaia di fissaggio",
            "hu": "Furfa helyi anyag, kötősínnel",
            "ru": "Деревянный брус (предоставляется заказчиком), с зажимной шиной",
            "sk": "Hranol dod. stavby, s upínacou koľajnicou",
            "pl": "Kantówka po str. budowy z uniwersalnym ryglem ściennym"
        },
        {
            "ID": "XFRAMI_A8_AW9",
            "de": "Universalelement 0,90",
            "en-gb": "Universal panel 0.90",
            "en-us": "Universal panel 0.90",
            "es": "Elem. univ. 0,90",
            "fr": "Px. univ. 0,90",
            "it": "Universalelement 0,90",
            "hu": "Uni elem 0,90",
            "ru": "Универсальный элемент 0,90",
            "sk": "Uni. panely 0,90",
            "pl": "Element uniwersalny 0,90"
        },
        {
            "ID": "XFRAMI_A9",
            "de": "Ankerstab verzinkt oder unbehandelt",
            "en-gb": "Tie rod galvanised or non-treated",
            "en-us": "Tie rod galvanized or non-treated",
            "es": "Barra de ancl. galv. o no tratada",
            "fr": "Tige d'ancrage galv. ou non traitée",
            "it": "Barra ancorante zincata o non trattata",
            "hu": "Ankerrúd horganyzott és kezeletlen",
            "ru": "Анкерный стержень, оцинкованный или необработанный",
            "sk": "Kotevná tyč pozinkovaná al. neupravovaná",
            "pl": "Ściąg ocynkowany lub bez zwykły"
        },
        {
            "ID": "XFRAMI_A9_AW1",
            "de": "Ankerstab verzinkt",
            "en-gb": "Tie rod galvanised",
            "en-us": "Tie rod galvanized",
            "es": "Barre de ancl. galv.",
            "fr": "Tige d'ancrage galv.",
            "it": "Barra ancorante zincata",
            "hu": "Ankerrúd horganyzott",
            "ru": "Анкерный стержень оцинкованный",
            "sk": "Kotevná tyč pozinkovaná",
            "pl": "Ściąg ocynkowany"
        },
        {
            "ID": "XFRAMI_A9_AW2",
            "de": "Ankerstab unbehandelt",
            "en-gb": "Tie rod non-treated",
            "en-us": "Tie rod non-treated",
            "es": "Barra de ancl. no tratada",
            "fr": "Tige d'ancrage non traitée",
            "it": "Barra ancorante non trattata",
            "hu": "Ankerrúd kezeletlen",
            "ru": "Анкерный стержень необработанный",
            "sk": "Kotevná tyč neupravovaná",
            "pl": "Ściąg nieocynkowany"
        },
        {
            "ID": "XFRAMI_S_A1",
            "de": "Wandanschlüsse",
            "en-gb": "Wall connections",
            "en-us": "Wall connections",
            "es": "Acometidas de muros",
            "fr": "Connexions de mur",
            "it": "Agganci per parete",
            "hu": "Falcsatlakozás",
            "ru": "Стыки стен",
            "sk": "Napájanie stien",
            "pl": "Połączenia ścienne"
        },
        {
            "ID": "XFRAMI_S_A1_AW1",
            "de": "Kantholz",
            "en-gb": "Squared timber",
            "en-us": "Squared timber",
            "es": "Listón",
            "fr": "Bois équarri",
            "it": "Legno squadrato",
            "hu": "Fur. fa",
            "ru": "Брусок",
            "sk": "Hranol dod.",
            "pl": "Kantówka"
        },
        {
            "ID": "XFRAMI_S_A1_AW2",
            "de": "Schalelement",
            "en-gb": "Formwork element",
            "en-us": "Formwork element",
            "es": "Elemento del encofrado",
            "fr": "Élément de coffrage",
            "it": "Elemento per cassero",
            "hu": "Kizsaluzó elem",
            "ru": "Опалубочный элемент",
            "sk": "Debniaci panel",
            "pl": "Element szalunku"
        },
        {
            "ID": "XFRAMI_S_A1_AW3",
            "de": "Elemente vom Vorgängertakt verwenden",
            "en-gb": "Use panels of previous cycle",
            "en-us": "Use panels of previous pour",
            "es": "Utilizar los elementos del ciclo anterior",
            "fr": "Utiliser les éléments du coffrage précédent",
            "it": "Utilizza elementi del ciclo precedente",
            "hu": "Az előző ciklus elemeinek alkalmazása",
            "ru": "Использовать элементы предыдущего такта",
            "sk": "Použiť prvky z predchádzajúceho taktu",
            "pl": "Użyj płyt z poprzedniego taktu"
        },
        {
            "ID": "XFRAMI_S_A10",
            "de": "Umsetzeinheiten bevorzugt Ja/Nein",
            "en-gb": "Shifting units preferred Yes/No",
            "en-us": "Shifting units preferred Yes/No",
            "es": "Unidades de desplazamiento preferido Si/No",
            "fr": "Unités de translation préféré Oui/Non",
            "it": "Unità di sollevamento preferito Si/Non",
            "hu": "Áthelyez·egységek preferált Igen/Nem",
            "ru": "перемещающие элементы предпочтительно Да/Нет",
            "sk": "Transp. jednotka šírka uprednostnené áno/nie",
            "pl": "Zestawy do przestawiania preferowane Tak/Nie"
        },
        {
            "ID": "XFRAMI_S_A10_AW1",
            "de": "Umsetzeinheiten bevorzugt",
            "en-gb": "Shifting units preferred",
            "en-us": "Shifting units preferred",
            "es": "Unidades de desplazamiento preferido",
            "fr": "Unités de translation préféré",
            "it": "Unità di sollevamento preferito",
            "hu": "Áthelyez·egységek preferált",
            "ru": "перемещающие элементы предпочтительно",
            "sk": "Transp. jednotka šírka uprednostnené",
            "pl": "Zestawy do przestawiania preferowane"
        },
        {
            "ID": "XFRAMI_S_A10_AW2",
            "de": "Umsetzeinheiten nicht bevorzugt",
            "en-gb": "Shifting units not preferred",
            "en-us": "Shifting units not preferred",
            "es": "Unidades de desplazamiento no preferido",
            "fr": "Unités de translation ne pas préféré",
            "it": "Unità di sollevamento non preferito",
            "hu": "Áthelyez·egységek nem preferált",
            "ru": "перемещающие элементы не предпочтительно",
            "sk": "ransp. jednotka šírka nie je uprednostnené",
            "pl": "Zestawy do przestawiania niepreferowane"
        },
        {
            "ID": "XFRAMI_S_A2",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "XFRAMI_S_A2_AW1",
            "de": "Konisches Ankersystem 3/4 auf 1",
            "en-gb": "Taper-tie system 3/4 to 1",
            "en-us": "Taper-tie system 3/4 to 1",
            "es": "Sistema de anclaje cónica 3/4 to 1",
            "fr": "Système d'ancrage conique 3/4 à 1",
            "it": "Barre ancoranti coniche 3/4 a 1",
            "hu": "Ankerrendszer 3/4-ről 1-ra",
            "ru": "Коническая анкерная система 3/4 на 1",
            "sk": "Kónická kotevný systém 3/4 auf 1",
            "pl": "Ściągi stożkowe 3/4 do 1"
        },
        {
            "ID": "XFRAMI_S_A2_AW2",
            "de": "Ankerkopfsystem 7/8",
            "en-gb": "She-bolt system 7/8",
            "en-us": "She-bolt system 7/8",
            "es": "Sistema de cabeza de anclaje 7/8",
            "fr": "Système d'ancrage à cône 7/8",
            "it": "Sist. di anc. 7/8 c. manicotti conico",
            "hu": "Ankerfej-rendszer 7/8",
            "ru": "Система анкерных головок 7/8",
            "sk": "system kotevných hláv 7/8",
            "pl": "System śrub z łbem walcowym 7/8"
        },
        {
            "ID": "XFRAMI_S_A2_AW3",
            "de": "15,0 Anker unbehandelt",
            "en-gb": "15.0 Tie rod non-treated",
            "en-us": "15.0 Tie rod non-treated",
            "es": "15,0 anclaje no-tratata",
            "fr": "15,0 ancrage non traitée",
            "it": "15,0 Ancorante non trattata",
            "hu": "15,0 Anker kezeletlen",
            "ru": "15,0 Анкерный стержень необработанный",
            "sk": "15,0 kotva neupravovaná",
            "pl": "Ściąg 15,0 nieocynkowany"
        },
        {
            "ID": "XFRAMI_S_A2_AW4",
            "de": "15,0 Anker verzinkt",
            "en-gb": "15.0 Tie rod galvanised",
            "en-us": "15.0 Tie rod galvanized",
            "es": "15,0 anclaje galvanizada",
            "fr": "15,0 ancrage galvanisée",
            "it": "15,0 Ancorante zincata",
            "hu": "15,0 Anker horganyzott",
            "ru": "15,0 Анкерный стержень оцинкованный",
            "sk": "15,0 kotva pozinkovaná",
            "pl": "Ściąg 15,0 ocynkowany"
        },
        {
            "ID": "XFRAMI_S_A3",
            "de": "Einseitige Ecke",
            "en-gb": "One-sided corner",
            "en-us": "One-sided corner",
            "es": "Esquina de un solo lado",
            "fr": "Une face angle",
            "it": "Angolo unilaterale",
            "hu": "Egyoldali sarok",
            "ru": "Односторонний угол",
            "sk": "Jednostranný roh",
            "pl": "Narożnik jednostronny"
        },
        {
            "ID": "XFRAMI_S_A3_AW1",
            "de": "Innenecke + Außenecke",
            "en-gb": "Inside corner + outside corner",
            "en-us": "Inside corner + outside corner",
            "es": "Esqu. int. + esqu. ext.",
            "fr": "Angle int et angle ext",
            "it": "Angolo interno + angolo esterno",
            "hu": "Belso sarok + külso sarok",
            "ru": "Внутренний угол + внешний угол",
            "sk": "Vnútorný roh + vonkajší roh",
            "pl": "Narożnik wewnętrzny + narożnik zewnętrzny"
        },
        {
            "ID": "XFRAMI_S_A3_AW2",
            "de": "Stützenvorlageelement mit Uni.-Verbinder",
            "en-gb": "Pilaster panel with universal fixing bolt",
            "en-us": "Pilaster panel with universal fixing bolt",
            "es": "Panel pilaster con conector universal",
            "fr": "Élément de pil. boutant avec b. d'ass. univ.",
            "it": "Elemento pilastro con tirante universale",
            "hu": "Falpillér elem univerzális kötocsavaral",
            "ru": "Опорный элемент для колонны с универсальным соединителем",
            "sk": "Pilastrový panel + uni.spoj.",
            "pl": "Panel pilastra z łącznikiem uniwersalnym"
        },
        {
            "ID": "XFRAMI_S_A3_AW3",
            "de": "S-Innenecke + S-Außenecke",
            "en-gb": "Hinged inside + hinged outside corner",
            "en-us": "Hinged inside and hinged outside corner",
            "es": "Esqu. bisagra int + esqu. bisagra ext.",
            "fr": "Angle int S et angle ext S",
            "it": "Angolo interno S + angolo esterno S",
            "hu": "Csukl.bel.sarok + Csukl.kül.sarok",
            "ru": "шарнирный внутренний угол + шарнирный внешний угол",
            "sk": "Kĺb.vnút.roh + kĺb.vonk.roh",
            "pl": "Zawiasy wewnątrz + narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "XFRAMI_S_A3_AW4",
            "de": "Ausschalecke",
            "en-gb": "Stripping corner",
            "en-us": "Bias cut corner",
            "es": "Esquina de desencofrado",
            "fr": "Angle de décoffrage",
            "it": "Angolo di disarmo",
            "hu": "Kizsaluzó sarok",
            "ru": "Распалубочный уголок",
            "sk": "Oddebňovací roh",
            "pl": "Narożnik rozszalowujący"
        },
        {
            "ID": "XFRAMI_S_A3_AW5",
            "de": "S-Innenecke + S-Außenecke verz.",
            "en-gb": "Hinged inside + hinged outside corner galv.",
            "en-us": "Hinged inside + hinged outside corner galv.",
            "es": "Esqu. bisagra int + esqu. bisagra ext. galv.",
            "fr": "Angle int S et angle ext S galv.",
            "it": "Angolo interno S + angolo esterno S zinc.",
            "hu": "Csukl.bel.sarok + Csukl.kül.sarok horg.",
            "ru": "шарнирный внутренний угол + шарнирный внешний угол оци.",
            "sk": "Kĺb.vnút.roh + kĺb.vonk.roh poz.",
            "pl": "Zawiasy wewnątrz + narożnik zewnętrzny zawiasowy ocynk."
        },
        {
            "ID": "XFRAMI_S_A4",
            "de": "Wandende",
            "en-gb": "Wall end",
            "en-us": "Wall end",
            "es": "Fin del muro",
            "fr": "Fin de mur",
            "it": "Fine del muro",
            "hu": "Falvég",
            "ru": "Конец стены",
            "sk": "Ukončenie steny",
            "pl": "Koniec ściany"
        },
        {
            "ID": "XFRAMI_S_A4_AW1",
            "de": "Klemmschienen",
            "en-gb": "Universal walings",
            "en-us": "Universal walings",
            "es": "Riel de fijación",
            "fr": "Rails de blocage",
            "it": "Rotaia di fissaggion",
            "hu": "Kötosín",
            "ru": "Зажимные шины",
            "sk": "Upínacia koľajnica",
            "pl": "Uniwersalne rygle ścienne"
        },
        {
            "ID": "XFRAMI_S_A4_AW2",
            "de": "Universalelement",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Px univ.",
            "it": "Elem. universale",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "niverzálny panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XFRAMI_S_A4_AW3",
            "de": "Rahmenelement",
            "en-gb": "Framed panel",
            "en-us": "Framed panel",
            "es": "Elemento marco",
            "fr": "Px de cadre",
            "it": "Elemento a telaio",
            "hu": "Elem",
            "ru": "Рамный элемент",
            "sk": "Rámové panel",
            "pl": "Płyta ramowa"
        },
        {
            "ID": "XFRAMI_S_A4_AW4",
            "de": "Stirnabschalzwinge",
            "en-gb": "Stop-end waler tie",
            "en-us": "Stop-end waler tie",
            "es": "Sargento para tape",
            "fr": "Rail d'about",
            "it": "Corrente di testata",
            "hu": "Véglezáró sín",
            "ru": "Торцевая накладка",
            "sk": "Zvierka debnenia čiel",
            "pl": "Zacisk deskowania czołowego"
        },
        {
            "ID": "XFRAMI_S_A5",
            "de": "Ausgleiche",
            "en-gb": "Closures",
            "en-us": "Fillers",
            "es": "Compensaciónes",
            "fr": "Compensations",
            "it": "Compensazioni",
            "hu": "Kiegyenlítés",
            "ru": "Компенсационные элементы",
            "sk": "Ručné vyrovnania",
            "pl": "Wypełnienia"
        },
        {
            "ID": "XFRAMI_S_A5_AW1",
            "de": "Stahlausgleich, Ankerung im Ausgleich",
            "en-gb": "Steel closure plate, ties through closure",
            "en-us": "Steel filler, ties through filler",
            "es": "Compensación de acero, anclaje en la compens.",
            "fr": "Pièce de compens. de l'acier, ancrage dans le compens.",
            "it": "Compens. in acciaio, anc. nella compens.",
            "hu": "Acél kiegyenlítő profil, ankerozás a kiegyenlítésen keresztül",
            "ru": "Стальной компенсатор анкерование в компенсации",
            "sk": "Oceľové vyrovnanie, kotvenie vo vyrovnaní",
            "pl": "Stalowa płyta wypełnienia, ściągi przez wypełnienie"
        },
        {
            "ID": "XFRAMI_S_A5_AW2",
            "de": "Rahmenelement",
            "en-gb": "Framed panel",
            "en-us": "Framed panel",
            "es": "Elemento marco",
            "fr": "Px de cadre",
            "it": "Elemento a telaio",
            "hu": "Elem",
            "ru": "Рамный элемент",
            "sk": "Rámové panel",
            "pl": "Płyta ramowa"
        },
        {
            "ID": "XFRAMI_S_A5_AW3",
            "de": "Füllkörper",
            "en-gb": "Filler block",
            "en-us": "Filler block",
            "es": "Cuerpo de relleno",
            "fr": "Corps de remplissage",
            "it": "Compensazione",
            "hu": "Kiegészítő betét",
            "ru": "Вкладыш",
            "sk": "Výplň",
            "pl": "Wstawka"
        },
        {
            "ID": "XFRAMI_S_A5_AW4",
            "de": "Stahlausgleich, Ankerung im Element",
            "en-gb": "Steel closure plate, ties through panel",
            "en-us": "Steel filler, ties through panel",
            "es": "Compensación de acero, anclaje en el elemento",
            "fr": "Pièce de compens., ancrage dans le panneau",
            "it": "Compens. in acciaio, anc. nell'elemento",
            "hu": "Acél kiegyenlítoprofil, ankerozás az elemben, ankerozás az elemben",
            "ru": "Стальной компенсатор, анкерование в элементе",
            "sk": "Oceľ. vyrovnanie, kotvenie v paneli",
            "pl": "Stalowa płyta wypełnienia, ściągi przez płytę"
        },
        {
            "ID": "XFRAMI_S_A5_AW5",
            "de": "Schalplatte und Schalhautwinkel",
            "en-gb": "Formwork sheet and plywood support",
            "en-us": "Formwork sheet and filler angle",
            "es": "Tablero encof. y áng. superficie encofr.",
            "fr": "Panneau et support de compensation",
            "it": "Pannello e supporto di compensazione",
            "hu": "Zsaluhéj és zsaluhéjtartó szögvas",
            "ru": "Опалубочная плита и опалубочный уголок",
            "sk": "Debniaca doska a uholník k debn. škrupine",
            "pl": "Sklejka + narożnik przekładniowy"
        },
        {
            "ID": "XFRAMI_S_A6",
            "de": "Bauwerksstütze",
            "en-gb": "Column",
            "en-us": "Column",
            "es": "Pilar",
            "fr": "Poteau",
            "it": "Pilastro",
            "hu": "Pillér",
            "ru": "Колонна",
            "sk": "Stĺp",
            "pl": "Słup"
        },
        {
            "ID": "XFRAMI_S_A6_AW1",
            "de": "Universalelement und Rahmenelement",
            "en-gb": "Universal panel and framed panel",
            "en-us": "Universal panel and framed panel",
            "es": "Elem. univ. y elemento marco",
            "fr": "Px univ. et px de cadre",
            "it": "Elem. universale e elemento a telaio",
            "hu": "Uni elem és Elem",
            "ru": "Универсальный элемент и рамный элемент",
            "sk": "Univerzálny panel + Rámové panel",
            "pl": "Płyta uniwersalna i płyta ramowa"
        },
        {
            "ID": "XFRAMI_S_A6_AW2",
            "de": "Elemente mit Außenecke",
            "en-gb": "Panels with outside corner",
            "en-us": "Panels with outside corner",
            "es": "Elementos con esquina ext.",
            "fr": "Px avec angle ext.",
            "it": "Elemento con angolo esterno",
            "hu": "Uni elem és küslo sarok",
            "ru": "Элементы с внешним углом",
            "sk": "Panely s vonkaj. roh",
            "pl": "Płyty z narożnikiem zewnętrznym"
        },
        {
            "ID": "XFRAMI_S_A6_AW3",
            "de": "Rahmenelement mit Stützengelenk",
            "en-gb": "Framed panel with column hinge",
            "en-us": "Framed panel with column hinge",
            "es": "Elemento marco con column hinge",
            "fr": "Px de cadre avec column hinge",
            "it": "Elemento a telaio con column hinge",
            "hu": "Elem és column hinge",
            "ru": "Рамный элемент с опорным шарниром",
            "sk": "Rámový panel so stĺpovým kĺbom",
            "pl": "Płyta ramowa z zawiasem do słupa"
        },
        {
            "ID": "XFRAMI_S_A6_AW4",
            "de": "Universalelement mit Dreikantleiste",
            "en-gb": "Universal panel with triangular ledge",
            "en-us": "Universal panel with triangular ledge",
            "es": "Elem. univ. con berenjeno triangular",
            "fr": "Px univ. avec liteau triangulaire",
            "it": "Elem. universale con listello triangolare",
            "hu": "Uni elem és műanyag élképző",
            "ru": "Универсальный элемент с трехгранной рейкой",
            "sk": "Univerzálny panel + trojhranná lišta",
            "pl": "Płyta uniwersalna z listwą trójkątną"
        },
        {
            "ID": "XFRAMI_S_A7",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "XFRAMI_S_A7_AW1",
            "de": "Innenecke und Uni-Element",
            "en-gb": "Inside corner and universal panel",
            "en-us": "Inside corner and universal panel",
            "es": "Esqu. int. y elem. univ",
            "fr": "Angle intérieur et px univ.",
            "it": "Angolo interno ed elemento uni",
            "hu": "Belso sarok és uni elem",
            "ru": "Внутренний угол с унив.элементом",
            "sk": "Vnútorný roh + univerzálny panel",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna"
        },
        {
            "ID": "XFRAMI_S_A7_AW10",
            "de": "Scharnier-Innenecke verz. innen und außen",
            "en-gb": "Hinged inside corner galv. inside and outside",
            "en-us": "Hinged inside corner galv. inside and outside",
            "es": "Esqu. bisagra int. galv. int. y ext.",
            "fr": "Angle charnière int galv. int et ext",
            "it": "Angolo cerniera int. galv. int. e est.",
            "hu": "Csukl.belso sarok horg. belül és kívül",
            "ru": "Шарнирный внутренний угол оцинкованный изнутри и снаружи",
            "sk": "Klbový vnútor. roh poz. zvnútra a zvonka",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. wewnątrz i na zewnątrz"
        },
        {
            "ID": "XFRAMI_S_A7_AW2",
            "de": "Innenecke und Außenecke",
            "en-gb": "Inside corner and outside corner",
            "en-us": "Inside corner and outside corner",
            "es": "Esqu. int. y esqu. ext.",
            "fr": "Angle int. et angle ext.",
            "it": "Angolo interno e angolo esterno",
            "hu": "Belso sarok és külso sarok",
            "ru": "Внутренний угол и внешний угол",
            "sk": "Vnútorný roh a vonkajší roh",
            "pl": "Narożnik wewnętrzny i narożnik zewnętrzny"
        },
        {
            "ID": "XFRAMI_S_A7_AW3",
            "de": "S-Innen und S-Außenecke",
            "en-gb": "Hinged inside and hinged outside corner",
            "en-us": "Hinged inside and hinged outside corner",
            "es": "Esqu. bisagra int. y esqu. bisagra ext.",
            "fr": "Angle charnière int. et angle charnière ext.",
            "it": "Angolo int. S ed est. S",
            "hu": "Csukl.bel. és csukl.kül.sarok",
            "ru": "шарнирный внутренний угол и шарнирный внешний угол",
            "sk": "Kĺb.vnút.roh + kĺb.vonk.roh",
            "pl": "Zawiasy wewnątrz + narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "XFRAMI_S_A7_AW4",
            "de": "S-Innenecke Innen und Außen",
            "en-gb": "Hinged inside corner inside and outside",
            "en-us": "Hinged inside corner inside and outside",
            "es": "Esqu. bisagra int. int. y ext.",
            "fr": "Angle charnière int. int. et ext.",
            "it": "Angolo interno S int. ed est.",
            "hu": "Csukl.bel.sarok belül és kívül",
            "ru": "шарнирный внутренний угол изнутри и снаружи",
            "sk": "Kĺb.vnút.roh vnút.+vonk.",
            "pl": "Narożnik wewnętrzny zawiasowy wewnątrz i na zewnątrz"
        },
        {
            "ID": "XFRAMI_S_A7_AW5",
            "de": "Wandstärke 30\″ Rahmenele. als Ausgleich, mit Innenecke",
            "en-gb": "Wall thickness 30\″ panel as closure, w/ inside corner",
            "en-us": "Wall thickness 30\″ panel as filler, w/ inside corner",
            "es": "Espesore de muro 30\″ elem. como compens., con esqu. int.",
            "fr": "Epaisseur de voile 30\″ pann. comme compens., av. angle int.",
            "it": "Spessore parete 30\″ elem. come compens., con angolo int.",
            "hu": "Falvastagság 30\″-os elem kiegészít. belső sarokkal",
            "ru": "Толщина стены 30\″ рамный элемент в качестве компенсации, с внутренним углом",
            "sk": "Hrúbka steny 30\″, rám. panel ako vyrovnanie s vnútorným rohom",
            "pl": "Grubość ściany 30” płyta jako wypełnienie, z narożnikiem wewnętrznym"
        },
        {
            "ID": "XFRAMI_S_A7_AW6",
            "de": "Ausschalecke und Außenecke",
            "en-gb": "Stripping corner and outside corner",
            "en-us": "Bias cut corner and outside corner",
            "es": "Esqu. de desencofrado y esqu. ext.",
            "fr": "Angle de décoffrage et angle ext.",
            "it": "Ang. di disarmo e angolo est.",
            "hu": "Kizsaluzó sarok és külso sarok",
            "ru": "Распалубочный уголок и внешний угол",
            "sk": "Oddebňovací roh + vonkajší roh",
            "pl": "Narożnik rozszalowujący i narożnik zewnętrzny"
        },
        {
            "ID": "XFRAMI_S_A7_AW7",
            "de": "Ausschalecke und Uni-Element",
            "en-gb": "Stripping corner and universal panel",
            "en-us": "Bias cut corner and universal panel",
            "es": "Esqu. de desencofrado y elem. univ.",
            "fr": "Angle de décoffrage et px univ.",
            "it": "Ang. di disarmo ed elemento uni",
            "hu": "Kizsaluzó sarok és uni elem",
            "ru": "Распалубочный уголок и универсальный элемент",
            "sk": "Oddebňovací roh + univerzálny panel",
            "pl": "Narożnik rozszalowujący i płyta uniwersalna"
        },
        {
            "ID": "XFRAMI_S_A7_AW8",
            "de": "Wandstärke 30\″ Rahmenele. als Ausgleich, mit Ausschalecke",
            "en-gb": "Wall thickness 30\″ panel as closure, w/ stripping corner",
            "en-us": "Wall thickness 30\″ panel as filler, w/ bias cut corner",
            "es": "Espesore de muro 30\″ elem. como compens., con esqu. de desencof.",
            "fr": "Epaisseur de voile 30\″ pann. comme compens., av. angle de décoff.",
            "it": "Spessore parete 30\″ elem. come compens., con ang. di disarmo",
            "hu": "Falvastagság 30\″-os elem kiegészít. kizsaluzó sarokkal",
            "ru": "Толщина стены 30\″ рамный элемент в качестве компенсации, с распалубочным уголком",
            "sk": "Hrúbka steny 30\″, rám. panel ako vyrovnanie s oddebňovacím rohom",
            "pl": "Grubość ściany 30” płyta jako wypełnienie, z narożnikiem rozszalowującym"
        },
        {
            "ID": "XFRAMI_S_A7_AW9",
            "de": "S-Innenecke + S-Außenecke verz.",
            "en-gb": "Hinged inside + hinged outside corner galv.",
            "en-us": "Hinged inside + hinged outside corner galv.",
            "es": "Esqu. bisagra int + esqu. bisagra ext. galv.",
            "fr": "Angle int S et angle ext S galv.",
            "it": "Angolo interno S + angolo esterno S zinc.",
            "hu": "Csukl.bel.sarok + Csukl.kül.sarok horg.",
            "ru": "шарнирный внутренний угол + шарнирный внешний угол оци.",
            "sk": "Kĺb.vnút.roh + kĺb.vonk.roh poz.",
            "pl": "Zawiasy wewnątrz + narożnik zewnętrzny zawiasowy ocynk."
        },
        {
            "ID": "XFRAMI_S_A8",
            "de": "Gerade Füllwand",
            "en-gb": "Straight infill wall",
            "en-us": "Straight infill wall",
            "es": "Muro recto",
            "fr": "Voile à compléter",
            "it": "Parete di riempimento dritta",
            "hu": "Egyenes kitöltő falazat",
            "ru": "Прямая пустотелая стена",
            "sk": "Rovná plná stena",
            "pl": "Prosta ściana wypełniająca"
        },
        {
            "ID": "XFRAMI_S_A8_AW1",
            "de": "Liegend aufgestockt (Breite 9')",
            "en-gb": "Upright panels stacked horiz. (width 9')",
            "en-us": "Upright panels stacked horiz. (width 9')",
            "es": "Elementos verticales apilados horizontales (ancho 9')",
            "fr": "Px debouts rehaussés couchants (largeur 9')",
            "it": "Elementi vert. sovrapposizione orizz. (larghezza 6')",
            "hu": "Elemek állítva, fektetve magasítva (szélesség 9')",
            "ru": "Вертикальные элементы, надстройка только горизонтально (Ширина 9')",
            "sk": "Panely nastojato, nadstavenie naležato (Šírka 9')",
            "pl": "Płyty pionowe – nadstawienie poziome (szerokość 9′)"
        },
        {
            "ID": "XFRAMI_S_A8_AW10",
            "de": "Stehend aufgestockt Basis 4'",
            "en-gb": "stacked upright Base 4'",
            "en-us": "stacked upright Base 4'",
            "es": "Apilado vertical Base 4'",
            "fr": "Rehaussé deboutant Base 4'",
            "it": "Sovrapposizione vert. base 4'",
            "hu": "Állítva magasítva Bázis 4'",
            "ru": "надстройка в вертикальном положении, основание 4'",
            "sk": "Nadstavenie nastojato Základ 4'",
            "pl": "Base 4′ nadstawienie pionowe"
        },
        {
            "ID": "XFRAMI_S_A8_AW11",
            "de": "Stehend aufgestockt Basis 3'",
            "en-gb": "stacked upright Base 3'",
            "en-us": "stacked upright Base 3'",
            "es": "Apilado vertical Base 3'",
            "fr": "Rehaussé deboutant Base 3'",
            "it": "Sovrapposizione vert. base 3'",
            "hu": "Állítva magasítva Bázis 3'",
            "ru": "надстройка в вертикальном положении, основание 3'",
            "sk": "Nadstavenie nastojato Základ 3'",
            "pl": "Base 3′ nadstawienie pionowe"
        },
        {
            "ID": "XFRAMI_S_A8_AW12",
            "de": "Fundament liegend mit Bodenhalter",
            "en-gb": "Foundation horizontal with floor fixing plate",
            "en-us": "Horizontal panels with bracing clip",
            "es": "Elementos horizontales con placa de fijación a suelo",
            "fr": "Px couchés av. support d'ancrage",
            "it": "Elementi orizz. con piastra di fissaggio",
            "hu": "Fekvő elemek aljzatrögzítővel",
            "ru": "Горизонтальные стандартные элементы с держателем для пола",
            "sk": "Normál. panely nalež. s držiakom spodku debn.",
            "pl": "Fundament poziomy z płytką mocującą"
        },
        {
            "ID": "XFRAMI_S_A8_AW13",
            "de": "Fundament stehend mit Fundamentspanner",
            "en-gb": "Foundation upright with foundation clamp",
            "en-us": "Foundation upright with foundation clamp",
            "es": "Elementos verticales con grapa de cimentación",
            "fr": "Px debouts avec serrage pour fondation",
            "it": "Elementi vert. con morsetto per fondazioni",
            "hu": "Alap zsaluzás-álló elemek ankerszalag feszítővel",
            "ru": "Вертикальные элементы с фундаментный зажим",
            "sk": "Panely nastojato s fundamentovým upínačom",
            "pl": "Fundament pionowy z napinaczem"
        },
        {
            "ID": "XFRAMI_S_A8_AW14",
            "de": "Fundament stehend mit Bodenhalter",
            "en-gb": "Foundation upright with floor fixing plate",
            "en-us": "Foundation upright with bracing clip",
            "es": "Elementos verticales con placa de fijación a suelo",
            "fr": "Px debouts avec support d'ancrage",
            "it": "Elementi vert. con piastra di fissaggio",
            "hu": "Alap zsaluzás-álló elemek aljzatrögzítővel",
            "ru": "Вертикальные элементы с держатель для пола",
            "sk": "Panely nastojato s držiakom spodku debnenia",
            "pl": "Fundament pionowy z płytką mocującą"
        },
        {
            "ID": "XFRAMI_S_A8_AW15",
            "de": "Fundament Universalelement stehend mit Bodenhalter",
            "en-gb": "Foundation upright universal panels with floor fixing plate",
            "en-us": "Foundation upright universal panels with bracing clip",
            "es": "Elem. univ. verticales con placa de fijación a suelo",
            "fr": "Px univ. debouts avec support d'ancrage",
            "it": "Elem. universali vert. con piastra di fissaggio",
            "hu": "Alap zsaluzás-álló uni elemek aljzatrögzítővel",
            "ru": "Вертикальные универсальные элементы с держателем для пола",
            "sk": "Univerz. panely nastoj. s držiakom spodku debn.",
            "pl": "Fundament pionowy – płyty uniwersalne z płytką mocującą"
        },
        {
            "ID": "XFRAMI_S_A8_AW16",
            "de": "Universalelemente mit Aufstockung",
            "en-gb": "Upright universal panels",
            "en-us": "Upright universal panels",
            "es": "Elem. univ. verticales",
            "fr": "Px univ. debouts",
            "it": "Elem. universali vert.",
            "hu": "Uni elem állítva",
            "ru": "Вертикальные универсальные элементы",
            "sk": "Univerzálne panely nastojato",
            "pl": "Płyty uniwersalne pionowe"
        },
        {
            "ID": "XFRAMI_S_A8_AW17",
            "de": "Fundament Universalelement stehend mit Ankerhaltewinkel",
            "en-gb": "Foundation upright universal panels with tie-holder bracket",
            "en-us": "Foundation upright universal panels with tie-holder bracket",
            "es": "Elem. univ. verticales con ang. de suj. de ancl.",
            "fr": "Px univ. debouts avec équerre d'ancrage",
            "it": "Elem. universali vert. con squadretta d'ancoraggio",
            "hu": "Alap zsaluzás - álló uni elemek ankertartó szögvassal",
            "ru": "Вертикальные универсальные элементы с упорным уголком для анкера",
            "sk": "Univerz. panely nastoj. s držiakom kot. tyče",
            "pl": "Fundament pionowy – płyty uniwersalne z uchwytem do ściągów"
        },
        {
            "ID": "XFRAMI_S_A8_AW18",
            "de": "Liegend aufgestockt (Breite 6')",
            "en-gb": "Upright panels stacked horiz. (width 6')",
            "en-us": "Upright panels stacked horiz. (width 6')",
            "es": "Elementos verticales apilados horizontales (ancho 6')",
            "fr": "Px debouts rehaussés couchants (largeur 6')",
            "it": "Elementi vert. sovrapposizione orizz. (larghezza 6')",
            "hu": "Elemek állítva, fektetve magasítva (szélesség 6')",
            "ru": "Вертикальные элементы, надстройка только горизонтально (Ширина 6')",
            "sk": "Panely nastojato, nadstavenie naležato (Šírka 6')",
            "pl": "Płyty pionowe – nadstawienie poziome (szerokość 6′)"
        },
        {
            "ID": "XFRAMI_S_A8_AW19",
            "de": "Stehend und liegend aufgestockt (Breite 6')",
            "en-gb": "Stacked upright and horiz. (width 6')",
            "en-us": "Stacked upright and horiz. (width 6')",
            "es": "Elementos verticales apilados vert. y horiz. (ancho 6')",
            "fr": "Px debouts rehaussés deboutants et couchants (largeur 6')",
            "it": "Elementi vert. sovrapposizione vert. e orizz. (larghezza 6')",
            "hu": "Elemek állítva, állítva és fektetve magasítva (szélesség 6')",
            "ru": "Вертикальные элементы, надстройка в вертикальном и горизонтальном положении (Ширина 6')",
            "sk": "Panely nastojato, nadst. nastojato a naležato (Šírka 6')",
            "pl": "Nadstawienie pionowe i poziome (szerokość 6′)"
        },
        {
            "ID": "XFRAMI_S_A8_AW2",
            "de": "Fundament stehend mit Ankerhaltewinkel",
            "en-gb": "Foundation upright with tie-holder bracket",
            "en-us": "Foundation upright with tie-holder bracket",
            "es": "Elementos verticales con ang. de suj. de ancl.",
            "fr": "Px debouts avec équerre d'ancrage",
            "it": "Elementi vert. con squadretta d'ancoraggio",
            "hu": "Állított elemek ankertartó szögvassal",
            "ru": "Вертикальные элементы с упорным уголком для анкера",
            "sk": "Panely nastojato s držiakom kotevnej tyče",
            "pl": "Fundament pionowy z uchwytem do ściągów"
        },
        {
            "ID": "XFRAMI_S_A8_AW20",
            "de": "Liegende Elemente",
            "en-gb": "Horizontal panels",
            "en-us": "Horizontal panels",
            "es": "Elementos horizontales",
            "fr": "Px couchés",
            "it": "Elementi orizz.",
            "hu": "Elemek fekvo",
            "ru": "Горизонтальные элементы",
            "sk": "Panely naležato",
            "pl": "Płyty poziome"
        },
        {
            "ID": "XFRAMI_S_A8_AW3",
            "de": "Fundament liegend mit Fundamentspanner",
            "en-gb": "Foundation horizontal with foundation clamp",
            "en-us": "Foundation horizontal with foundation clamp",
            "es": "Elementos horizontales con grapa de cimentación",
            "fr": "Px couchés av. serrage pour fondation",
            "it": "Elementi orizz. con morsetto per fondazioni",
            "hu": "Fekvő elemek ankerszalag feszítővel",
            "ru": "Горизонтальные стандартные элементы с фундаментным зажимом",
            "sk": "Normálne panely naležato s fundam. upínačom",
            "pl": "Fundament poziomy z napinaczem"
        },
        {
            "ID": "XFRAMI_S_A8_AW4",
            "de": "Fundament Universalelement liegend",
            "en-gb": "Foundation horizontal universal panels",
            "en-us": "Horizontal universal panels",
            "es": "Elem. univ. horizontales",
            "fr": "Px univ. couchés",
            "it": "Elem. universali orizz.",
            "hu": "Uni elem fekvo",
            "ru": "Горизонтальные универсальные элементы",
            "sk": "Univerzálne panely naležato",
            "pl": "Fundament poziomy – płyty uniwersalne"
        },
        {
            "ID": "XFRAMI_S_A8_AW5",
            "de": "Fundament Universalelement stehend mit Fundamentspanner",
            "en-gb": "Foundation upright universal panels with foundation clamp",
            "en-us": "Foundation upright universal panels with foundation clamp",
            "es": "Elem. univ. verticales con grapa de cimentación",
            "fr": "Px univ. debouts avec serrage pour fondation",
            "it": "Elem. universali vert. con morsetto per fondazioni",
            "hu": "Állított Uni elemek ankerszalag feszítővel",
            "ru": "Вертикальные универсальные элементы с фундаментным зажимом",
            "sk": "Univerz. panely nastoj. s fundam. upínačom",
            "pl": "Fundament pionowy – płyty uniwersalne pionowe z napinaczem"
        },
        {
            "ID": "XFRAMI_S_A8_AW6",
            "de": "Stehend aufgestockt Basis 9'",
            "en-gb": "stacked upright Base 9'",
            "en-us": "stacked upright Base 9'",
            "es": "Apilado vertical Base 9'",
            "fr": "Rehaussé deboutant Base 9'",
            "it": "Sovrapposizione vert. base 9'",
            "hu": "Állítva magasítva Bázis 9'",
            "ru": "надстройка в вертикальном положении, основание 9'",
            "sk": "Nadstavenie nastojato Základ 9'",
            "pl": "Base 9′ nadstawienie pionowe"
        },
        {
            "ID": "XFRAMI_S_A8_AW7",
            "de": "Elemente ohne Aufstockung",
            "en-gb": "Panels without stacking",
            "en-us": "Upright panels without stacking",
            "es": "Elementos verticales sin apliado",
            "fr": "Px debouts sans rehausse",
            "it": "Elementi vert. senza sovrapposizione",
            "hu": "Elemek állítva Elemtoldás nélkül",
            "ru": "Элементы без наращивания",
            "sk": "Panley nastojato bez nadstavenia",
            "pl": "Płyty bez nadstawienia"
        },
        {
            "ID": "XFRAMI_S_A8_AW8",
            "de": "Stehend und liegend aufgestockt (Breite 9')",
            "en-gb": "Stacked upright and horiz. (width 9')",
            "en-us": "Stacked upright and horiz. (width 9')",
            "es": "Elementos verticales apilados vert. y horiz. (ancho 9')",
            "fr": "Px debouts rehaussés deboutants et couchants (largeur 9')",
            "it": "Elementi vert. sovrapposizione vert. e orizz. (larghezza 6')",
            "hu": "Elemek állítva, állítva és fektetve magasítva (szélesség 9')",
            "ru": "Вертикальные элементы, надстройка в вертикальном и горизонтальном положении (Ширина 9')",
            "sk": "Panely nastojato, nadst. nastojato a naležato (Šírka 9')",
            "pl": "Nadstawienie pionowe i poziome (szerokość 9′)"
        },
        {
            "ID": "XFRAMI_S_A8_AW9",
            "de": "Stehend aufgestockt Basis 6'",
            "en-gb": "stacked upright Base 6'",
            "en-us": "stacked upright Base 6'",
            "es": "Apilado vertical Base 6'",
            "fr": "Rehaussé deboutant Base 6'",
            "it": "Sovrapposizione vert. base 6'",
            "hu": "Állítva magasítva Bázis 6'",
            "ru": "надстройка в вертикальном положении, основание 6'",
            "sk": "Nadstavenie nastojato Základ 6'",
            "pl": "Base 6′ nadstawienie pionowe"
        },
        {
            "ID": "XFRAMI_S_A9",
            "de": "T-Anschluss",
            "en-gb": "T-connection",
            "en-us": "T-connection",
            "es": "Unión T",
            "fr": "Raccord en T",
            "it": "Raccordo a T",
            "hu": "T-véglezárás",
            "ru": "Т-образный стык",
            "sk": "T-napojenie",
            "pl": "Połączenie typu T"
        },
        {
            "ID": "XFRAMI_S_A9_AW1",
            "de": "mit Stützenvorlageelement",
            "en-gb": "w/ pilaster panel",
            "en-us": "w/ pilaster panel",
            "es": "con panel pilaster",
            "fr": "avec élément de pil. boutant",
            "it": "con elemento pilastro",
            "hu": "falpillér elemmel",
            "ru": "с опорным элементом для колонны",
            "sk": "s pilastrovým panelom",
            "pl": "z płytą pilastra"
        },
        {
            "ID": "XFRAMI_S_A9_AW2",
            "de": "mit Innenecke",
            "en-gb": "w/ inside corner",
            "en-us": "w/ inside corner",
            "es": "con esqu. int.",
            "fr": "avec angle int.",
            "it": "con angolo int.",
            "hu": "belső sarokkal",
            "ru": "с внутренним углом",
            "sk": "s vnútorným rohom",
            "pl": "z narożnikiem wewnętrznym"
        },
        {
            "ID": "XFRAMI_S_A9_AW3",
            "de": "mit Ausschalecke",
            "en-gb": "w/ stripping corner",
            "en-us": "w/ bias cut corner",
            "es": "Con esqu. de desencof.",
            "fr": "avec angle de décoffrage",
            "it": "con ang. di disarmo",
            "hu": "kizsaluzó sarokkal",
            "ru": "с распалубочным уголком",
            "sk": "s oddebňovacím rohom",
            "pl": "z narożnikiem rozszalowującym"
        },
        {
            "ID": "XFRAMINI_A1",
            "de": "Grundelementhöhe",
            "en-gb": "Basic panel height",
            "en-us": "Basic panel height",
            "es": "Altura del elemento base",
            "fr": "Hauteur de base",
            "it": "Altezza dell’ elemento base",
            "hu": "Alapelem magasság",
            "ru": "Высота основного элемента",
            "sk": "Výška základného panelu",
            "pl": "Wysokość płyty podstawowej"
        },
        {
            "ID": "XFRAMINI_A1_AW1",
            "de": "1,00m",
            "en-gb": "1.00m",
            "en-us": "1.00m",
            "es": "1,00m",
            "fr": "1,00m",
            "it": "1,00m",
            "hu": "1,00m",
            "ru": "1,00м",
            "sk": "1,00m",
            "pl": "1,00m"
        },
        {
            "ID": "XFRAMINI_A1_AW2",
            "de": "1,50m",
            "en-gb": "1.50m",
            "en-us": "1.50m",
            "es": "1,50m",
            "fr": "1,50m",
            "it": "1,50m",
            "hu": "1,50m",
            "ru": "1,50м",
            "sk": "1,50m",
            "pl": "1,50m"
        },
        {
            "ID": "XFRAMINI_A10",
            "de": "Ausgleich",
            "en-gb": "Closure",
            "en-us": "Filler",
            "es": "Compensación",
            "fr": "Compensation",
            "it": "Compensazione",
            "hu": "Kiegyenlítés",
            "ru": "Компенсация",
            "sk": "Vyrovnanie",
            "pl": "Wypełnienie"
        },
        {
            "ID": "XFRAMINI_A10_AW1",
            "de": "Füllkörper bauseits, Ankerung im Element",
            "en-gb": "Filler block by site, ties through panel",
            "en-us": "Filler block by site, ties through panel",
            "es": "Cuerpo de relleno (por parte de obra), anclaje en el elemento",
            "fr": "Corps de remplissage (chantier), ancrage dans le panneau",
            "it": "Compensazione (a carico cant.), anc. nell'elemento",
            "hu": "Kiegészítő betét építéshelyi, ankerozás az elemben",
            "ru": "Вкладыш (силами заказчика), анкерование в элементе",
            "sk": "Výplň (zo strany stavby), kotvenie v paneli",
            "pl": "Wstawka po str. budowy, ściagi przez płytę"
        },
        {
            "ID": "XFRAMINI_A10_AW2",
            "de": "Füllkörper bauseits, Ankerung im Ausgleich",
            "en-gb": "Filler block by site, ties through closure",
            "en-us": "Filler block by site, ties through closure",
            "es": "Cuerpo de relleno (por parte de obra), anclaje en compensación",
            "fr": "Corps de remplissage (chantier), ancrage dans le panneau",
            "it": "Compensazione (a carico cant.), anc. nella compens.",
            "hu": "Kiegészítő betét építéshelyi, ankerozás a kiegyenlítésen keresztül",
            "ru": "Вкладыш (силами заказчика), анкерование в компенсатор",
            "sk": "Výplň (zo strany stavby), kotvenie vo vyrovnaní",
            "pl": "Wstawka po str. budowy, ściagi przez wstawkę"
        },
        {
            "ID": "XFRAMINI_A10_AW3",
            "de": "mit Schalungsplatte 18mm und Klemmschiene",
            "en-gb": "with formwork sheet 18mm and universal waling",
            "en-us": "with formwork sheet 18mm and universal waling",
            "es": "con tablero de encofrado 18mm y riel de fijación",
            "fr": "avec contreplaqué 18mm et rail de blocage",
            "it": "con pannello 18mm e rotaia di fissaggio",
            "hu": "18mm-es zsaluhéjjal és- kötősínnel",
            "ru": "с опалубочная плита 18мм и зажимная шина",
            "sk": "s debniacou doskou 18mm a upínacou koľajnicou",
            "pl": "ze sklejką 18mm i uniwersalnym ryglem ściennym"
        },
        {
            "ID": "XFRAMINI_A11",
            "de": "Anschlüsse",
            "en-gb": "Connections",
            "en-us": "Connections",
            "es": "Conexónes",
            "fr": "Connexions",
            "it": "Collegamenta",
            "hu": "Csatlakozó",
            "ru": "Стыки",
            "sk": "Napojenia",
            "pl": "Połączenia"
        },
        {
            "ID": "XFRAMINI_A11_AW1",
            "de": "ohne Ausgleich und Füllkörper",
            "en-gb": "without closure and filler block",
            "en-us": "without filler and filler block",
            "es": "sin ajuste y cuerpo de compesación",
            "fr": "sans compensation ni corps de remplissage",
            "it": "senza chiusura e compensazione",
            "hu": "kiegészítés és kitöltő betét nélkül",
            "ru": "без компенсаций и вставок",
            "sk": "bez vyrovnania a výplne",
            "pl": "bez wypełnienia i wstawek po stronie budowy"
        },
        {
            "ID": "XFRAMINI_A11_AW2",
            "de": "mit Ausgleich und Füllkörper",
            "en-gb": "with closure and filler block",
            "en-us": "with filler and filler block",
            "es": "con ajuste y cuerpo de compesación",
            "fr": "avec compensation et corps de remplissage",
            "it": "con chiusura e compensazione",
            "hu": "kiegészítéssel és kitöltő betéttel",
            "ru": "со вставками и компенсацией",
            "sk": "s vyrovnaním a výplňou",
            "pl": "z wypełnieniem i wstawką po stronie budowy"
        },
        {
            "ID": "XFRAMINI_A11_AW3",
            "de": "0,30m Element und Füllkörper",
            "en-gb": "0.30m panel and filler block",
            "en-us": "0.30m panel and filler block",
            "es": "0,30m elementos y cuerpo de relleno",
            "fr": "Élément de 0,30 m et corps de remplissage",
            "it": "pannello 0,30m e compensazione",
            "hu": "0,30m elem és kitöltő betét",
            "ru": "0,30м элемент и вставка",
            "sk": "0,30m panel a výplň",
            "pl": "Płyta 0,30m i blok wypełniający po str. budowy"
        },
        {
            "ID": "XFRAMINI_A11_AW4",
            "de": "Normalelemente",
            "en-gb": "Standard panels",
            "en-us": "Standard panels",
            "es": "Elementos normalos",
            "fr": "Éléments étalons",
            "it": "Elementi normali",
            "hu": "Normál táblák",
            "ru": "Унифицированные элементы",
            "sk": "Štandardné panely",
            "pl": "Płyty standardowe"
        },
        {
            "ID": "XFRAMINI_A11_AW5",
            "de": "Füllkörper",
            "en-gb": "Filler block",
            "en-us": "Filler block",
            "es": "Cuerpo de relleno",
            "fr": "Corps de remplissage",
            "it": "Compensazione",
            "hu": "Kiegészítő betét",
            "ru": "Вкладыш",
            "sk": "Výplň",
            "pl": "Wstawka"
        },
        {
            "ID": "XFRAMINI_A11_AW6",
            "de": "Uni-Element",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Panneau universel",
            "it": "Elemento uni",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Univerzálny panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XFRAMINI_A11_AW7",
            "de": "Elemente vom Vorgängertakt verwenden",
            "en-gb": "Use panels of previous cycle",
            "en-us": "Use panels of previous pour",
            "es": "Utilizar los elementos del ciclo anterior",
            "fr": "Utiliser les éléments du coffrage précédent",
            "it": "Utilizza elementi del ciclo precedente",
            "hu": "Az előző ciklus elemeinek alkalmazása",
            "ru": "Использовать элементы предыдущего такта",
            "sk": "Použiť prvky z predchádzajúceho taktu",
            "pl": "Użyj płyt z poprzedniego taktu"
        },
        {
            "ID": "XFRAMINI_A12",
            "de": "Bauwerksstütze",
            "en-gb": "Column",
            "en-us": "Column",
            "es": "Pilar",
            "fr": "Poteau",
            "it": "Pilastro",
            "hu": "Pillér",
            "ru": "Колонна",
            "sk": "Stĺp",
            "pl": "Słup"
        },
        {
            "ID": "XFRAMINI_A12_AW1",
            "de": "Uni-Element 0,75m",
            "en-gb": "Universal panel 0.75m",
            "en-us": "Universal panel 0.75m",
            "es": "Elem. univ. 0,75m",
            "fr": "Panneau universel 0,75m",
            "it": "Elemento uni 0,75m",
            "hu": "Uni elem 0,75m",
            "ru": "Универсальный элемент 0.75м",
            "sk": "Univerzálny panel 0,75m",
            "pl": "Płyta uniwersalna 0,75m"
        },
        {
            "ID": "XFRAMINI_A12_AW2",
            "de": "Uni-Element 0,45m",
            "en-gb": "Universal panel 0.45m",
            "en-us": "Universal panel 0.45m",
            "es": "Elem. univ. 0,45m",
            "fr": "Panneau universel 0,45m",
            "it": "Elemento uni 0,45m",
            "hu": "Uni elem 0,45m",
            "ru": "Универсальный элемент 0,45м",
            "sk": "Univerzálny panel 0,45m",
            "pl": "Płyta uniwersalna 0,45m"
        },
        {
            "ID": "XFRAMINI_A12_AW3",
            "de": "Uni-Element 0,75m mit Element",
            "en-gb": "Universal panel 0.75m with panel",
            "en-us": "Universal panel 0.75m with panel",
            "es": "Elemento universal 0,75m con elemento",
            "fr": "Panneau universel 0,75m avec pan.",
            "it": "Elemento uni 0,75m con elemento",
            "hu": "Uni elem 0,75m elem",
            "ru": "Универсальный элемент 0,75м с элементом",
            "sk": "Univerzálny panel 0,75m so panel",
            "pl": "Płyta uniwersalna 0,75 m z płytą standardową"
        },
        {
            "ID": "XFRAMINI_A12_AW4",
            "de": "Uni-Element 0,45m mit Element",
            "en-gb": "Universal panel 0.45m with panel",
            "en-us": "Universal panel 0.45m with panel",
            "es": "Elemento universal 0,45m con elemento",
            "fr": "Panneau universel 0,45m avec pan.",
            "it": "Elemento uni 0,45m con elemento",
            "hu": "Uni elem 0,45m elem",
            "ru": "Универсальный элемент 0,45м с элементом",
            "sk": "Univerzálny panel 0,45m so panel",
            "pl": "Płyta uniwersalna 0,45m z płytą standardową"
        },
        {
            "ID": "XFRAMINI_A12_AW5",
            "de": "Elemente mit Außenecke",
            "en-gb": "Panels with outside corners",
            "en-us": "Panels with outside corners",
            "es": "Elem. con esqu. ext.",
            "fr": "Px avec angle ext",
            "it": "Elemente con angoli esterni",
            "hu": "Elem külso sarokkal",
            "ru": "Элементы с внешними углами",
            "sk": "Panely s vonkaj. roh",
            "pl": "Płyty z narożnikami zewnętrznymi"
        },
        {
            "ID": "XFRAMINI_A2",
            "de": "Zweite Elementhöhe",
            "en-gb": "Second panel height",
            "en-us": "Second panel height",
            "es": "Altura panel apilado",
            "fr": "Seconde hauteur d’élément",
            "it": "Altezza secondo pannello",
            "hu": "második elemmagasság",
            "ru": "Высота второго элемента",
            "sk": "Druhá výška panela",
            "pl": "Wysokość drugiej płyty"
        },
        {
            "ID": "XFRAMINI_A2_AW1",
            "de": "1,00m",
            "en-gb": "1.00m",
            "en-us": "1.00m",
            "es": "1,00m",
            "fr": "1,00m",
            "it": "1,00m",
            "hu": "1,00m",
            "ru": "1,00м",
            "sk": "1,00m",
            "pl": "1,00m"
        },
        {
            "ID": "XFRAMINI_A2_AW2",
            "de": "1,50m",
            "en-gb": "1.50m",
            "en-us": "1.50m",
            "es": "1,50m",
            "fr": "1,50m",
            "it": "1,0m",
            "hu": "1,50m",
            "ru": "1,50м",
            "sk": "1,50m",
            "pl": "1,50m"
        },
        {
            "ID": "XFRAMINI_A3",
            "de": "Elementtyp",
            "en-gb": "Panel type",
            "en-us": "Panel type",
            "es": "Tipo de elemento",
            "fr": "Type d'elément",
            "it": "Tipo elemento",
            "hu": "Elemtípus",
            "ru": "Тип элемента",
            "sk": "Typ panelu",
            "pl": "Typ płyty"
        },
        {
            "ID": "XFRAMINI_A3_AW1",
            "de": "Normalelement",
            "en-gb": "Standard panel",
            "en-us": "Standard panel",
            "es": "Elemento normal",
            "fr": "Élément étalon",
            "it": "Elemento normale",
            "hu": "Normál tábla",
            "ru": "Унифицированный элемент",
            "sk": "Štandardný panel",
            "pl": "Płyta standardowa"
        },
        {
            "ID": "XFRAMINI_A3_AW2",
            "de": "Uni-Element",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Panneau universel",
            "it": "Elemento uni",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Uni. panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XFRAMINI_A4",
            "de": "Elementverbindung",
            "en-gb": "Panel connection",
            "en-us": "Panel connection",
            "es": "Unión para elemento",
            "fr": "Assemblage px",
            "it": "Giunzioni di elementi",
            "hu": "Elemszélesség",
            "ru": "Соединение элементов",
            "sk": "Panelová spojka",
            "pl": "Łączenie płyt"
        },
        {
            "ID": "XFRAMINI_A4_AW1",
            "de": "Framini-Stecker",
            "en-gb": "Framini clip",
            "en-us": "Framini clip",
            "es": "Perno de bloqueo Framini",
            "fr": "Goujon de blocage Framini",
            "it": "Bullone di bloccaggio Framini",
            "hu": "Framini szorítókulcs",
            "ru": "Framini закрепляющий штифт",
            "sk": "Framini-zástrčný čap",
            "pl": "Wtyczka Framini"
        },
        {
            "ID": "XFRAMINI_A4_AW2",
            "de": "Frami-Spanner",
            "en-gb": "Frami clamp",
            "en-us": "Frami clamp",
            "es": "Grapa Frami",
            "fr": "Serrage rapide Frami",
            "it": "Morsetto Frami",
            "hu": "Frami gyorskapcsoló",
            "ru": "Frami зажимное приспособление",
            "sk": "Frami-upínač",
            "pl": "Zamek Frami"
        },
        {
            "ID": "XFRAMINI_A5",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "XFRAMINI_A5_AW1",
            "de": "Uni-Element 0,45m und Innenecke",
            "en-gb": "Universal panel 0.45m and Inside corner",
            "en-us": "Universal panel 0.45m and Inside corner",
            "es": "Elemento universal 0,45m y esquina interior",
            "fr": "Panneau universel 0,45m et angle intérieur",
            "it": "Elemento universale 0,45m e angolo interno",
            "hu": "Uni elemmel 0,45m és belső sarokkal",
            "ru": "Универс. элементом 0,45м и внутренней угловой частью",
            "sk": "Univerzálnym panelom 0,45m a vnútorným rohom",
            "pl": "Płyta uniwersalna 0,45m i narożnik wewnętrzny"
        },
        {
            "ID": "XFRAMINI_A5_AW2",
            "de": "Uni-Element 0,75m und Innencke",
            "en-gb": "Universal panel 0.75m and Inside corner",
            "en-us": "Universal panel 0.75m and Inside corner",
            "es": "Elemento universal 0,75m y esquina interior",
            "fr": "Panneau universel 0,75m et angle intérieur",
            "it": "Elemento universale 0,75m e angolo interno",
            "hu": "Uni elemmel 0,75m és belső sarokkal",
            "ru": "Универс. элементом 0,75м и внутренней угловой частью",
            "sk": "Univerzálnym panelom 0,75m a vnútorným rohom",
            "pl": "Płyta uniwersalna 0,75 m i narożnik wewnętrzny"
        },
        {
            "ID": "XFRAMINI_A5_AW3",
            "de": "Außenecke und Innenecke",
            "en-gb": "Outside corner and inside corner",
            "en-us": "Outside corner and inside corner",
            "es": "Esquina exterior y esquina interior",
            "fr": "Angle extérieur et angle intérieur",
            "it": "Angolo esterno e angolo interno",
            "hu": "Külső sarokkal és belső sarokkal",
            "ru": "Внешней угловой частью и внутренней угловой частью",
            "sk": "Vonkajším rohom a vnútorným rohom",
            "pl": "Narożnik zewnętrzny i narożnik wewnętrzny"
        },
        {
            "ID": "XFRAMINI_A6",
            "de": "Aufstockung",
            "en-gb": "Stacking",
            "en-us": "Stacking",
            "es": "Apilado",
            "fr": "Rehaussé",
            "it": "Sovrapposizione",
            "hu": "Magasítás",
            "ru": "Надстройка",
            "sk": "Nadstavenia",
            "pl": "Nadstawienie"
        },
        {
            "ID": "XFRAMINI_A6_AW1",
            "de": "ohne Aufstockung",
            "en-gb": "without stacking",
            "en-us": "without stacking",
            "es": "Sin apilado",
            "fr": "sans rehausse",
            "it": "senza sopralzo",
            "hu": "Magasítás nélkül",
            "ru": "Без надстройки",
            "sk": "bez nadstavenia",
            "pl": "bez nadstawienia"
        },
        {
            "ID": "XFRAMINI_A6_AW2",
            "de": "Liegend aufgestockt",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz.",
            "es": "Apilado horizontal",
            "fr": "Rehaussé couchant",
            "it": "Sovrapposizione orizz.",
            "hu": "Fektetve magasítva",
            "ru": "надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "pl": "nadstawienie poziome"
        },
        {
            "ID": "XFRAMINI_A6_AW3",
            "de": "Stehend aufgestockt",
            "en-gb": "stacked upright",
            "en-us": "stacked upright",
            "es": "Apilado vertical",
            "fr": "Rehaussé deboutant",
            "it": "Sovrapposizione vert.",
            "hu": "Állítva magasítva",
            "ru": "надстройка в вертикальном положении",
            "sk": "Nadstavenie nastojato",
            "pl": "nadstawienie pionowe"
        },
        {
            "ID": "XFRAMINI_A7",
            "de": "Stehende oder liegende Elemente",
            "en-gb": "Upright or horiz. panels",
            "en-us": "upright or horiz. panels",
            "es": "Elementos verticales o horizontales",
            "fr": "Px debouts ou couchés",
            "it": "Elementi vert. o orizz.",
            "hu": "Elemek állítva vagy fekvo",
            "ru": "Элементы, установленные горизонтально или вертикально",
            "sk": "Panely nastojato alebo naležato",
            "pl": "Płyty pionowe lub poziome"
        },
        {
            "ID": "XFRAMINI_A7_AW1",
            "de": "liegend",
            "en-gb": "horizontal",
            "en-us": "horizontal",
            "es": "horizontal",
            "fr": "couché",
            "it": "orizzontale",
            "hu": "fekvo",
            "ru": "горизонтально",
            "sk": "naležato",
            "pl": "poziomy"
        },
        {
            "ID": "XFRAMINI_A7_AW2",
            "de": "stehend",
            "en-gb": "upright",
            "en-us": "upright",
            "es": "vertical",
            "fr": "debout",
            "it": "verticale",
            "hu": "állítva",
            "ru": "вертикально",
            "sk": "nastojato",
            "pl": "pionowy"
        },
        {
            "ID": "XFRAMINI_A8",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "XFRAMINI_A8_AW1",
            "de": "Ankerstab 15,00mm verzinkt",
            "en-gb": "Tie rod 15.0 galvanised",
            "en-us": "Tie rod 15.0 galvanised",
            "es": "Barra de ancl. 15,0 galv.",
            "fr": "Tige d'ancrage 15,0 galvanisée",
            "it": "Barra ancorante 15,0 zincata",
            "hu": "Ankerrúd 15,0 horganyzott",
            "ru": "Анкерный стержень 15,0 оцинкованный",
            "sk": "Kotev. tyc 15,0 pozinkov.",
            "pl": "Ściąg 15,0 ocynkowany"
        },
        {
            "ID": "XFRAMINI_A8_AW2",
            "de": "Ankerstab 15,00mm unbehandelt",
            "en-gb": "Tie rod 15.0 non-treated",
            "en-us": "Tie rod 15.0 non-treated",
            "es": "Barra de ancl. 15,0 no tratada",
            "fr": "Tige d'ancrage 15,0 non traitée",
            "it": "Barra ancorante 15,0 non trattata",
            "hu": "Ankerrúd 15,0 kezeletlen",
            "ru": "Анкерный стержень 15,0 необработанный",
            "sk": "Kotev. tyc 15,0 neupravov.",
            "pl": "Ściąg 15,0 nieocynkowany"
        },
        {
            "ID": "XFRAMINI_A9",
            "de": "Stirnabschalung",
            "en-gb": "Stop-end-formwork",
            "en-us": "Bulkhead",
            "es": "Tape",
            "fr": "Coffrage d'about",
            "it": "Chiusura di testa",
            "hu": "Véglezárás",
            "ru": "Торцевые участки опалубки",
            "sk": "Debnenie čela",
            "pl": "Szalunek czołowy"
        },
        {
            "ID": "XFRAMINI_A9_AW1",
            "de": "Uni-Element 0,75m",
            "en-gb": "Universal panel 0.75m",
            "en-us": "Universal panel 0.75m",
            "es": "Elem. univ. 0,75m",
            "fr": "Panneau universel 0,75m",
            "it": "Elemento uni 0,75m",
            "hu": "Uni elem 0,75m",
            "ru": "Универсальный элемент 0,75м",
            "sk": "Univerzálny panel 0,75m",
            "pl": "Płyta uniwersalna 0,75m"
        },
        {
            "ID": "XFRAMINI_A9_AW2",
            "de": "Uni-Element 0,45m",
            "en-gb": "Universal panel 0.45m",
            "en-us": "Universal panel 0.45m",
            "es": "Elem. univ. 0,45m",
            "fr": "Panneau universel 0,45m",
            "it": "Elemento uni 0,45m",
            "hu": "Uni elem 0,45m",
            "ru": "Универсальный элемент 0,45м",
            "sk": "Univerzálny panel 0,45m",
            "pl": "Płyta uniwersalna 0,45m"
        },
        {
            "ID": "XFRAMINI_A9_AW3",
            "de": "Klemmschiene",
            "en-gb": "Universal waling",
            "en-us": "Universal waling",
            "es": "Riel de fijación",
            "fr": "Rail de blocage",
            "it": "Rotaia di fissaggion",
            "hu": "Kötosín",
            "ru": "Зажимные шины",
            "sk": "Upínacia koľajnica",
            "pl": "Uniwersalny rygiel ścienny"
        },
        {
            "ID": "XFRECO_A1",
            "de": "Elementtyp",
            "en-gb": "Panel type",
            "en-us": "Panel type",
            "es": "Tipo de elemento",
            "fr": "Type d'elément",
            "it": "Tipo elemento",
            "hu": "Elemtípus",
            "ru": "Тип элемента",
            "sk": "Typ panelu",
            "pl": "Typ płyty"
        },
        {
            "ID": "XFRECO_A1_AW1",
            "de": "Normalelement (1.35,0.90,0.60,0.55,0.45,0.30 breit)",
            "en-gb": "Standard panel (1.35,0.90,0.60,0.55,0.45,0.30 wide)",
            "en-us": "Standard panel (1.35,0.90,0.60,0.55,0.45,0.30 wide)",
            "es": "Elemento normal (1.35,0.90,0.60,0.55,0.45,0.30 ancho)",
            "fr": "Élément étalon (1.35,0.90,0.60,0.55,0.45,0.30 large)",
            "it": "Elemento normale (1.35,0.90,0.60,0.55,0.45,0.30 largo)",
            "hu": "Normál tábla (1.35,0.90,0.60,0.55,0.45,0.30 szélesség)",
            "ru": "Унифицированный элемент (ширина1.35,0.90,0.60,0.55,0.45,0.30)",
            "sk": "Štandardný panel (1.35,0.90,0.60,0.55,0.45,0.30 šírky)",
            "pl": "Płyta standardowa (szerokość 1.35,0.90,0.60,0.55,0.45,0.30)"
        },
        {
            "ID": "XFRECO_A1_AW2",
            "de": "Uni-Element",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Panneau universel",
            "it": "Elemento uni",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Univerzálny panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XFRECO_A2",
            "de": "Stirnabschalung",
            "en-gb": "Stop-end-formwork",
            "en-us": "Bulkhead",
            "es": "Tape",
            "fr": "Coffrage d'about",
            "it": "Chiusura di testa",
            "hu": "Véglezárás",
            "ru": "Торцевые участки опалубки",
            "sk": "Debnenie čela",
            "pl": "Szalunek czołowy"
        },
        {
            "ID": "XFRECO_A2_AW1",
            "de": "Stirnanker ohne Element",
            "en-gb": "Stop-end tie without panel",
            "en-us": "Stop-end tie without panel",
            "es": "Anclaje de tape sin elemento",
            "fr": "Ancrage d'about sans Px",
            "it": "Morsetto per testata senza elemento",
            "hu": "Univerzális kötocsavar elem nélkül",
            "ru": "Торцевой анкер без элемента",
            "sk": "Celová kotva bez panel",
            "pl": "Deskowanie czołowe bez płyty"
        },
        {
            "ID": "XFRECO_A2_AW2",
            "de": "Stirnanker mit Element",
            "en-gb": "Stop-end tie with panel",
            "en-us": "Stop-end tie with panel",
            "es": "Anclaje de tape con elemento",
            "fr": "Ancrage d'about avec Px",
            "it": "Morsetto per testata con elemento",
            "hu": "Univerzális kötocsavar elem",
            "ru": "Торцевой анкер с элементом",
            "sk": "Celová kotva so panel",
            "pl": "Deskowanie czołowe z płytą"
        },
        {
            "ID": "XFRECO_A2_AW3",
            "de": "Universalverbinder mit Element",
            "en-gb": "Universal fixing bolt with panel",
            "en-us": "Universal fixing bolt with panel",
            "es": "Conector univ. con elem.",
            "fr": "Boulon d'assemblage universel avec Px",
            "it": "Tirante universale con elemento",
            "hu": "Univerzális kötocsavar elemmel",
            "ru": "Универсальный соединитель с элементом",
            "sk": "Univerzálna spojka so panel",
            "pl": "Łącznik uniwersalny z płytą"
        },
        {
            "ID": "XFRECO_A2_AW4",
            "de": "Universalelement 0,90m",
            "en-gb": "Universal panel 0.90m",
            "en-us": "Universal panel 0.90m",
            "es": "Elem. univ. 0,90m",
            "fr": "Px univ. 0,90m",
            "it": "Elemento uni 0,90m",
            "hu": "Uni elem 0,90m",
            "ru": "Универсальный элемент 0,90м",
            "sk": "Univerzálny panel 0,90m",
            "pl": "Element uniwersalny 0,90m"
        },
        {
            "ID": "XFRECO_A2_AW5",
            "de": "Stirnabschalzwinge",
            "en-gb": "Stop-end waler tie",
            "en-us": "Stop-end waler tie",
            "es": "Sargento para tape",
            "fr": "Rail d'about",
            "it": "Corrente di testata",
            "hu": "Véglezáró sín",
            "ru": "Торцевая накладка",
            "sk": "Zvierka debnenia čiel",
            "pl": "Zacisk deskowania czołowego"
        },
        {
            "ID": "XFRECO_A2_AW6",
            "de": "Universalverbinder ohne Element",
            "en-gb": "Universal fixing bolt w/o panel",
            "en-us": "Universal fixing bolt w/o panel",
            "es": "Conector univ. sin elem.",
            "fr": "Boulon d'assemblage universel sans Px",
            "it": "Tirante universale senza elemento",
            "hu": "Univerzális kötocsavar elem nélkül",
            "ru": "Универсальный соединитель без элемента",
            "sk": "Univerzálna spojka bez panel",
            "pl": "Łącznik uniwersalny bez płyty"
        },
        {
            "ID": "XFRECO_A2_AW7",
            "de": "Universalelement 1,20m",
            "en-gb": "Universal panel 1.20m",
            "en-us": "Universal panel 1.20m",
            "es": "Elem. univ. 1,20m",
            "fr": "Px univ. 1,20m",
            "it": "Elemento uni 1,20m",
            "hu": "Uni elem 1,20m",
            "ru": "Универсальный элемент 1,20м",
            "sk": "Univerzálny panel 1,20m",
            "pl": "Element uniwersalny 1,20m"
        },
        {
            "ID": "XFRECO_A3",
            "de": "Ausgleiche",
            "en-gb": "Closures",
            "en-us": "Fillers",
            "es": "Compensaciónes",
            "fr": "Compensations",
            "it": "Compensazioni",
            "hu": "Kiegyenlítés",
            "ru": "Компенсационные элементы",
            "sk": "Ručné vyrovnania",
            "pl": "Wypełnienia"
        },
        {
            "ID": "XFRECO_A3_AW1",
            "de": "Füllkörper mit Uni-Spanner, Ankerung im Element",
            "en-gb": "Filler block w/ multifunction clamp, ties through panel",
            "en-us": "Filler block w/ multifunction clamp, ties through panel",
            "es": "Cuerpo de relleno con grapa unión univ., anclaje en el elemento",
            "fr": "Corps de remplissage avec tendeur rapide universelle, ancrage dans le panneau",
            "it": "Compensazione con morsetto universale, anc. nell'elemento",
            "hu": "Építéshelyi kiegészítő betét univerzális kapcsolóval, ankerozás az elemben",
            "ru": "Заполнитель с универсал. зажимным приспособлением, анкерование в элементе",
            "sk": "Vyrovnanie s univerzálnym upínačom, kotvenie v paneli",
            "pl": "Wstawka z zamkiem uniwersalnym, ściągi przez wstawkę"
        },
        {
            "ID": "XFRECO_A3_AW10",
            "de": "Füllkörper mit Universalverbinder 10-25cm",
            "en-gb": "Filler block w/ universal fixing bolt 10-25cm",
            "en-us": "Filler block w/ universal fixing bolt 10-25cm",
            "es": "Cuerpo de relleno con conector univ. 10-25cm",
            "fr": "Corps de remplissage avec boulon d'assemblage univ. 10-25cm",
            "it": "Compensazione con tirante universale 10-25cm",
            "hu": "Kidobozolás univerzális kötőcsavarral 10-25cm",
            "ru": "Закладной элемент с унив. соединителем 10-25см",
            "sk": "Vyrovnanie s univerzálnou spojkou 10-25cm",
            "pl": "Wstawka z łącznikiem uniwersalnym 10–25cm"
        },
        {
            "ID": "XFRECO_A3_AW11",
            "de": "mit Schalungsplatte 21mm und Klemmschiene 0,90m",
            "en-gb": "with formwork sheet 21mm and universal waling 0.90m",
            "en-us": "with formwork sheet 21mm and universal waling 0.90m",
            "es": "con tablero de encofrado 21mm y riel de fijación 0,90m",
            "fr": "avec contreplaqué 21mm et rails de blocage 0,90m",
            "it": "con pannello 21mm e rotaia di fissaggio 0,90m",
            "hu": "21mm-es zsaluhéjjal és 0,90m-es kötősínnel",
            "ru": "с опалубочная плита 21мм и зажимная шина 0,90м",
            "sk": "s debniacou doskou 21mm a upínacou koľajnicou 0,90m",
            "pl": "ze sklejką 21mm i uniwersalnym ryglem ściennym 0,90m"
        },
        {
            "ID": "XFRECO_A3_AW12",
            "de": "mit Schalungsplatte 21mm und Klemmschiene 1,50m",
            "en-gb": "with formwork sheet 21mm and universal waling 1.50m",
            "en-us": "with formwork sheet 21mm and universal waling 1.50m",
            "es": "con tablero de encofrado 21mm y riel de fijación 1,50m",
            "fr": "avec contreplaqué 21mm et rails de blocage 1,50m",
            "it": "con pannello 21mm e rotaia di fissaggio 1,50m",
            "hu": "21mm-es zsaluhéjjal és 1,50m-es kötősínnel",
            "ru": "с опалубочная плита 21мм и зажимная шина 1,50м",
            "sk": "s debniacou doskou 21mm a upínacou koľajnicou 1,50m",
            "pl": "ze sklejką 21mm i uniwersalnym ryglem ściennym 1,50m"
        },
        {
            "ID": "XFRECO_A3_AW2",
            "de": "Füllkörper mit Ausgleichsspanner",
            "en-gb": "Filler block w/ adjustable clamp",
            "en-us": "Filler block w/ adjustable clamp",
            "es": "Cuerpo de relleno con grapa de compensación",
            "fr": "Corps de remplissage avec tendeur de compensation",
            "it": "Compensazione con morsetto di compensazione",
            "hu": "Építéshelyi fabetét kiegyenlítő kapcsolóval",
            "ru": "Закладной элем. с пригон. зажимн. приспособл.",
            "sk": "Vyrovnanie s vyrovnávacím upínačom",
            "pl": "Wstawka z zamkiem uniwersalnym"
        },
        {
            "ID": "XFRECO_A3_AW3",
            "de": "Passholz mit Uni-Spanner, Ankerung im Element",
            "en-gb": "Fitting timber with multifunction clamp, ties through panel",
            "en-us": "Fitting timber with multifunction clamp, ties through panel",
            "es": "Tablón de ajuste con grapa unión univ., anclaje en el elemento",
            "fr": "Fourrure en bois avec tendeur rapide universelle, ancrage dans le panneau",
            "it": "Tavola di compensazione con morsetto universale, anc. nell'elemento",
            "hu": "Illesztő fabetét univerzális kapcsolóval, ankerozás az elemben",
            "ru": "Пригнанный брус с универсал. зажимным приспособлением, анкерование в элементе",
            "sk": "Vyrovnávací hranol s univerzálnym upínačom, kotvenie v paneli",
            "pl": "Wstawka komp. z zamkiem uniwersalnym, ściagi przez płytę"
        },
        {
            "ID": "XFRECO_A3_AW4",
            "de": "Passholz mit Ausgleichsspanner",
            "en-gb": "Fitting timber with adjustable clamp",
            "en-us": "Fitting timber with adjustable clamp",
            "es": "Tablón de ajuste con grapa de comp",
            "fr": "Fourrure en bois avec tendeur de compensation",
            "it": "Tavola di compensazione con morsetto di compensazione",
            "hu": "Illesztő fabetét kiegyenlítő kapcsolóval",
            "ru": "Пригнанный брус с пригоняемым зажимным приспособлением",
            "sk": "Vyrovnávací hranol s vyrovnávacím upínačom",
            "pl": "Wstawka komp. z zaciskiem regulowanym"
        },
        {
            "ID": "XFRECO_A3_AW5",
            "de": "mit Schalungsplatte 27mm und Klemmschiene 0,90m",
            "en-gb": "with formwork sheet 27mm and universal waling 0.90m",
            "en-us": "with formwork sheet 27mm and universal waling 0.90m",
            "es": "con tablero de encofrado 27mm y riel de fijación 0,90m",
            "fr": "avec contreplaqué 27mm et rail de blocage 0,90m",
            "it": "con pannello 27mm e rotaia di fissaggio 0,90m",
            "hu": "27mm-es zsaluhéjjal és 0,90m-es kötősínnel",
            "ru": "с опалубочная плита 27мм и зажимная шина 0,90м",
            "sk": "s debniacou doskou 27mm a upínacou koľajnicou 0,90m",
            "pl": "ze sklejką 27mm i uniwersalnym ryglem ściennym 0,90m"
        },
        {
            "ID": "XFRECO_A3_AW6",
            "de": "mit Schalungsplatte 27mm und Klemmschiene 1,50m",
            "en-gb": "with formwork sheet 27mm and universal waling 1.50m",
            "en-us": "with formwork sheet 27mm and universal waling 1.50m",
            "es": "con tablero de encofrado 27mm y riel de fijación 1,50m",
            "fr": "avec contreplaqué 27mm et rail de blocage 1,50m",
            "it": "con pannello 27mm e rotaia di fissaggio 1,50m",
            "hu": "27mm-es zsaluhéjjal és 1,50m-es kötősínnel",
            "ru": "с опалубочная плита 27мм и зажимная шина 1,50м",
            "sk": "s debniacou doskou 27mm a upínacou koľajnicou 1,50m",
            "pl": "ze sklejką 27mm i uniwersalnym ryglem ściennym 1,50m"
        },
        {
            "ID": "XFRECO_A3_AW7",
            "de": "Füllkörper mit Uni-Spanner, Ankerung im Ausgleich",
            "en-gb": "Filler block w/ multifunction clamp, ties through closure",
            "en-us": "Filler block w/ multifunction clamp, ties through filler",
            "es": "Cuerpo de relleno con grapa unión univ., anclaje en compensación",
            "fr": "Corps de remplissage avec tendeur rapide univ., ancrage dans le compens.",
            "it": "Compensazione con morsetto universale, anc. nella compens.",
            "hu": "Építéshelyi kiegészítő betét univerzális kapcsolóval, ankerozás a kiegyenlítésen keresztül",
            "ru": "Заполнитель с универсал. зажимным приспособлением, анкерование в компенсатор",
            "sk": "Vyrovnanie s univerzálnym upínačom, kotvenie vo vyrovnaní",
            "pl": "Wstawka z zamkiem uniwersalnym, ściągi przez wstawkę"
        },
        {
            "ID": "XFRECO_A3_AW8",
            "de": "Passholz mit Uni-Spanner, Ankerung im Ausgleich",
            "en-gb": "Fitting timber w/ multifunction clamp, ties through closure",
            "en-us": "Fitting timber w/ multifunction clamp, ties through filler",
            "es": "Tablón de ajuste con grapa unión univ., anclaje en compensación",
            "fr": "Fourrure en bois avec tendeur rapide univ., ancrage dans le compens.",
            "it": "Tavola di compensazione con morsetto universale, anc. nella compens.",
            "hu": "Illesztő fabetét univerzális kapcsolóval, ankerozás a kiegyenlítésen keresztül",
            "ru": "Пригнанный брус с универсал. зажимным приспособлением, анкерование в компенсатор",
            "sk": "Vyrovnávací hranol s univerzálnym upínačom, kotvenie vo vyrovnaní",
            "pl": "Wstawka komp. z zamkiem uniwersalnym, ściagi przez wstawkę"
        },
        {
            "ID": "XFRECO_A3_AW9",
            "de": "Füllkörper mit Universalverbinder 10-16cm",
            "en-gb": "Filler block w/ universal fixing bolt 10-16cm",
            "en-us": "Filler block w/ universal fixing bolt 10-16cm",
            "es": "Cuerpo de relleno con conector univ. 10-16cm",
            "fr": "Corps de remplissage avec boulon d'assemblage univ. 10-16cm",
            "it": "Compensazione con tirante universale 10-16cm",
            "hu": "Kidobozolás univerzális kötőcsavarral 10-16cm",
            "ru": "Закладной элемент с унив. соединителем 10-16см",
            "sk": "Vyrovnanie s univerzálnou spojkou 10-16cm",
            "pl": "Wstawka z łącznikiem uniwersalnym 10–16cm"
        },
        {
            "ID": "XFRECO_A4",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "XFRECO_A4_AW1",
            "de": "Innenecke und Universal-Element 0,90m",
            "en-gb": "Inside corner and universal panel 0.90m",
            "en-us": "Inside corner and universal panel 0.90m",
            "es": "Esqu. int. y elem. univ. 0,90m",
            "fr": "Angle int et px univ. 0,90m",
            "it": "Angolo interno e elemento uni 0,90m",
            "hu": "Csukl.bel.sarok és uni elem 0,90m",
            "ru": "Внутренний угол и унив.элементом 0,90м",
            "sk": "Vnútorný roh + univerzálny panel 0,90m",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna 0,90"
        },
        {
            "ID": "XFRECO_A4_AW2",
            "de": "Innenecke und Außenecke",
            "en-gb": "Inside corner and outside corner",
            "en-us": "Inside corner and outside corner",
            "es": "Esqu. int. y esqu. ext.",
            "fr": "Angle int et angle ext",
            "it": "Angolo interno e angolo esterno",
            "hu": "Belso sarok és külso sarok",
            "ru": "Внутренний угол и внешний угол",
            "sk": "Vnútorný roh + vonkajší roh",
            "pl": "Narożnik wewnętrzny i narożnik zewnętrzny"
        },
        {
            "ID": "XFRECO_A4_AW3",
            "de": "Innenecke und Universal-Element 1,20m",
            "en-gb": "Inside corner and universal panel 1.20m",
            "en-us": "Inside corner and universal panel 1.20m",
            "es": "Esqu. int. y elem. univ. 1,20m",
            "fr": "Angle int et px univ. 1,20m",
            "it": "Angolo interno e elemento uni 1,20m",
            "hu": "Csukl.bel.sarok és uni elem 1,20m",
            "ru": "Внутренний угол и унив.элементом 1,20м",
            "sk": "Vnútorný roh + univerzálny panel 1,20m",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna 1,20m"
        },
        {
            "ID": "XFRECO_A5",
            "de": "Wandanschlüsse",
            "en-gb": "Wall connections",
            "en-us": "Wall connections",
            "es": "Acometidas de muros",
            "fr": "Connexions de mur",
            "it": "Agganci per parete",
            "hu": "Falcsatlakozás",
            "ru": "Стыки стен",
            "sk": "Napájanie stien",
            "pl": "Połączenia ścienne"
        },
        {
            "ID": "XFRECO_A5_AW1",
            "de": "Normalelement",
            "en-gb": "Standard panel",
            "en-us": "Standard panel",
            "es": "Elemento normal",
            "fr": "Élément étalon",
            "it": "Elemento normale",
            "hu": "Normál tábla",
            "ru": "Унифицированный элемент",
            "sk": "Štandardný panel",
            "pl": "Płyta standardowa"
        },
        {
            "ID": "XFRECO_A5_AW2",
            "de": "Uni-Element",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Px univ.",
            "it": "Elemento uni",
            "hu": "Uni elem",
            "ru": "Универсальные элементы",
            "sk": "Uni. panely",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XFRECO_A5_AW3",
            "de": "Kanthölzer-Klemmschiene",
            "en-gb": "Squared timbers-Universal waling",
            "en-us": "Squared timbers-Universal waling",
            "es": "Listónes-Riel de fijación",
            "fr": "Bois-Rails de blocage",
            "it": "Travi squadrate-Rotaia di fissaggio",
            "hu": "Fabetét-Kötosín",
            "ru": "Бруски-Зажимная шина",
            "sk": "Hranoly a upínac.-Upín.koľajnica",
            "pl": "z nadstawieniem"
        },
        {
            "ID": "XFRECO_A5_AW4",
            "de": "Elemente vom Vorgängertakt verwenden",
            "en-gb": "Use panels of previous cycle",
            "en-us": "Use panels of previous pour",
            "es": "Utilizar los elementos del ciclo anterior",
            "fr": "Utiliser les éléments du coffrage précédent",
            "it": "Utilizza elementi del ciclo precedente",
            "hu": "Az előző ciklus elemeinek alkalmazása",
            "ru": "Использовать элементы предыдущего такта",
            "sk": "Použiť prvky z predchádzajúceho taktu",
            "pl": "Użyj płyt z poprzedniego taktu"
        },
        {
            "ID": "XFRECO_A8",
            "de": "Bauwerksstütze",
            "en-gb": "Column",
            "en-us": "Column",
            "es": "Pilar",
            "fr": "Poteau",
            "it": "Pilastro",
            "hu": "Pillér",
            "ru": "Колонна",
            "sk": "Stĺp",
            "pl": "Słup"
        },
        {
            "ID": "XFRECO_A8_AW1",
            "de": "Uni-Element 0,90m",
            "en-gb": "Universal panel 0.90m",
            "en-us": "Universal panel 0.90m",
            "es": "Elem. univ. 0,90m",
            "fr": "Px univ. 0,90m",
            "it": "Elemento uni 0,90m",
            "hu": "Uni elem 0,90m",
            "ru": "Универсальный элемент 0,90м",
            "sk": "Univerzálny panel 0,90m",
            "pl": "Element uniwersalny 0,90m"
        },
        {
            "ID": "XFRECO_A8_AW2",
            "de": "Elemente mit Außenecken",
            "en-gb": "Panels with outside corners",
            "en-us": "Panels with outside corners",
            "es": "Elem. con esqu. ext.",
            "fr": "Px avec angle ext",
            "it": "Elemente con angoli esterni",
            "hu": "Elem külso sarokkal",
            "ru": "Элементы с внешними углами",
            "sk": "Panely s vonkaj. roh",
            "pl": "Płyty z narożnikami zewnętrznymi"
        },
        {
            "ID": "XFRECO_A8_AW3",
            "de": "Uni-Element 1,20m",
            "en-gb": "Universal panel 1.20m",
            "en-us": "Universal panel 1.20m",
            "es": "Elem. univ. 1,20m",
            "fr": "Px univ. 1,20m",
            "it": "Elemento uni 1,20m",
            "hu": "Uni elem 1,20m",
            "ru": "Универсальный элемент 1,20м",
            "sk": "Univerzálny panel 1,20m",
            "pl": "Element uniwersalny 1,20m"
        },
        {
            "ID": "XFRECO_A9",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "XFRECO_A9_AW1",
            "de": "Ankerstab 15,0 unbehandelt",
            "en-gb": "Tie rod 15.0 non-treated",
            "en-us": "Tie rod 15.0 non-treated",
            "es": "Barra de ancl. 15,0 no tratada",
            "fr": "Tige d'ancrage 15,0 non traitée",
            "it": "Barra ancorante 15,0 non trattata",
            "hu": "Ankerrúd 15,0 kezeletlen",
            "ru": "Анкерный стержень 15,0 необработанный",
            "sk": "Kotev. tyc 15,0 neupravov.",
            "pl": "Ściąg 15,0 nieocynkowany"
        },
        {
            "ID": "XFRECO_A9_AW2",
            "de": "Ankerstab 15,0 verzinkt",
            "en-gb": "Tie rod 15.0 galvanised",
            "en-us": "Tie rod 15.0 galvanized",
            "es": "Barra de ancl. 15,0 galv.",
            "fr": "Tige d'ancrage 15,0 galvanisée",
            "it": "Barra ancorante 15,0 zincata",
            "hu": "Ankerrúd 15,0 horganyzott",
            "ru": "Анкерный стержень 15,0 оцинкованный",
            "sk": "Kotev. tyc 15,0 pozinkov.",
            "pl": "Ściąg 15,0 ocynkowany"
        },
        {
            "ID": "XFRECO_A9_AW3",
            "de": "Ankerstab 20,0 unbehandelt",
            "en-gb": "Tie rod 20.0 non-treated",
            "en-us": "Tie rod 20.0 non-treated",
            "es": "Barra de ancl. 20,0 no tratada",
            "fr": "Tige d'ancrage 20,0 non traitée",
            "it": "Barra ancorante 20,0 non trattata",
            "hu": "Ankerrúd 20,0 kezeletlen",
            "ru": "Анкерный стержень 20,0 необработанный",
            "sk": "Kotev. tyč 20,0 neupravovaná",
            "pl": "Ściąg 20,0 nieocynkowany"
        },
        {
            "ID": "XFRECO_A9_AW4",
            "de": "Ankerstab 20,0 verzinkt",
            "en-gb": "Tie rod 20.0 galvanised",
            "en-us": "Tie rod 20.0 galvanized",
            "es": "Barra de ancl. 20,0 galv.",
            "fr": "Tige d'ancrage 20,0 galvanisée",
            "it": "Barra ancorante 20,0 zincata",
            "hu": "Ankerrúd 20,0 horganyzott",
            "ru": "Анкерный стержень 20,0 оцинкованный",
            "sk": "Kotev. tyc 20,0 pozinkov.",
            "pl": "Ściąg 20,0 ocynkowany"
        },
        {
            "ID": "XFRPLUS_A1",
            "de": "Aufstockungsvarianten",
            "en-gb": "Stacking variants",
            "en-us": "Stacking variants",
            "es": "Variantes de incremento",
            "fr": "Variante de réhausse",
            "it": "Varianti di sovrapposizione",
            "hu": "Magasítási variációk",
            "ru": "Варианты наращивания",
            "sk": "Varianty nadstavenia",
            "pl": "Warianty nadstawienia"
        },
        {
            "ID": "XFRPLUS_A1_AW1",
            "de": "Einzelelemente ohne Aufstockung",
            "en-gb": "Single panels w/o stacking",
            "en-us": "Single panels w/o stacking",
            "es": "Elementos individuales sin incremento",
            "fr": "Single elements w/o stacking",
            "it": "Elemento singolo senza sovrapposizione",
            "hu": "Egyedülálló elemek magasítás nélkül",
            "ru": "Отдельные элементы без наращивания",
            "sk": "Jednotlivé panely bez nadstavenia",
            "pl": "Płyty pojedyncze bez nadstawienia"
        },
        {
            "ID": "XFRPLUS_A1_AW2",
            "de": "Stehend aufgestockt",
            "en-gb": "stacked upright",
            "en-us": "stacked upright",
            "es": "Apilado vertical",
            "fr": "Rehaussé deboutant",
            "it": "Sovrapposizione vert.",
            "hu": "Állítva magasítva",
            "ru": "надстройка в вертикальном положении",
            "sk": "Nadstavenie nastojato",
            "pl": "nadstawienie pionowe"
        },
        {
            "ID": "XFRPLUS_A1_AW3",
            "de": "Liegend aufgestockt",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz.",
            "es": "Apilado horizontal",
            "fr": "Rehaussé couchant",
            "it": "Sovrapposizione orizz.",
            "hu": "Fektetve magasítva",
            "ru": "надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "pl": "nadstawienie poziome"
        },
        {
            "ID": "XFRPLUS_A10",
            "de": "Fundamentlösung",
            "en-gb": "Foundation solution",
            "en-us": "Foundation solution",
            "es": "Solución para fundaciones",
            "fr": "Solution de fondation",
            "it": "Soluzione per fondamenta",
            "hu": "Alapozás megoldása",
            "ru": "Решение для фундамента",
            "sk": "Riešenie základov",
            "pl": "Rozwiązanie dla fundamentów"
        },
        {
            "ID": "XFRPLUS_A10_AW1",
            "de": "mit Kopfanker 15-40cm",
            "en-gb": "with head anchor 15-40cm",
            "en-us": "with head anchor 15-40cm",
            "es": "con anclaje superior 15-40cm",
            "fr": "avec ancrage de tête 15-40cm",
            "it": "con ancorante di testa 15-40cm",
            "hu": "15-40cm-es fejankerrel",
            "ru": "с головным анкером 15-40см",
            "sk": "s vrchnou kotvou 15-40cm",
            "pl": "z kotwą 15–40cm"
        },
        {
            "ID": "XFRPLUS_A10_AW2",
            "de": "mit Kopfanker 15-100cm",
            "en-gb": "with head anchor 15-100cm",
            "en-us": "with head anchor 15-100cm",
            "es": "con anclaje superior 15-100cm",
            "fr": "avec ancrage de tête 15-100cm",
            "it": "con ancorante di testa 15-100cm",
            "hu": "15-100cm-es fejankerrel",
            "ru": "с головным анкером 15-100см",
            "sk": "s vrchnou kotvou 15-100cm",
            "pl": "z kotwą 15–100cm"
        },
        {
            "ID": "XFRPLUS_A10_AW3",
            "de": "mit Fundamentspanner",
            "en-gb": "with foundation clamp",
            "en-us": "with foundation clamp",
            "es": "con grapa de cimentación",
            "fr": "avec serrage pour fondation",
            "it": "con morsetto per fondazioni",
            "hu": "ankerszalag feszítővel",
            "ru": "с фундаментный зажим",
            "sk": "s fundamentovým upínačom",
            "pl": "z zaciskiem szalunkowym"
        },
        {
            "ID": "XFRPLUS_A10_AW4",
            "de": "mit Bodenhalter und Kopfanker 15-40cm",
            "en-gb": "with floor fixing plate and head anchor 15-40cm",
            "en-us": "with bracing clip and head anchor 15-40cm",
            "es": "Con placa de fijación al suelo y anclaje superior 15-40cm",
            "fr": "avec support d'ancrage et ancrage de tête 15-40cm",
            "it": "con piastra di fissaggio e ancorante di testa 15-40cm",
            "hu": "aljzatrögzítővel és 15-40cm-es fejankerrel",
            "ru": "с держателем для пола и головным анкером 15-40см",
            "sk": "s držiakom spodku debnenia a vrchnou kotvou 15-40cm",
            "pl": "z płytą montażową podłoża i kotwą 15–40cm"
        },
        {
            "ID": "XFRPLUS_A10_AW5",
            "de": "mit Bodenhalter und Kopfanker 15-100cm",
            "en-gb": "with floor fixing plate and head anchor 15-100cm",
            "en-us": "with bracing clip and head anchor 15-100cm",
            "es": "Con placa de fijación al suelo y anclaje superior 15-100cm",
            "fr": "avec support d'ancrage et ancrage de tête 15-100cm",
            "it": "con piastra di fissaggio e ancorante di testa 15-100cm",
            "hu": "aljzatrögzítővel és 15-100cm-es fejankerrel",
            "ru": "с держателем для пола и головным анкером 15-100см",
            "sk": "s držiakom spodku debnenia a vrchnou kotvou 15-100cm",
            "pl": "z płytą montażową podłoża i kotwą 15–100cm"
        },
        {
            "ID": "XFRPLUS_A10_AW6",
            "de": "Einzelelement (für Zubehörsystem Abstützwinkel)",
            "en-gb": "Single panel (for supporting construction accessory system)",
            "en-us": "Single panel (for supporting construction accessory system)",
            "es": "Elementos individuales (para sistema de accesorio Soporte angular)",
            "fr": "Élément individuel (pour le système d’accessoires fermette)",
            "it": "Elemento singolo (per sistema accessori puntello triangolare)",
            "hu": "Egyedülálló elem (kitámasztó szögvas tartozékokhoz)",
            "ru": "Отдельный элемент (для комплектующих опорного раскоса)",
            "sk": "Jednotlivý panel (pre oporný uholník, systém príslušenstva)",
            "pl": "Płyta pojedyncza (do systemu osprzętu konstrukcji wspierającej)"
        },
        {
            "ID": "XFRPLUS_A11",
            "de": "Großelement (2,70x3,30m)",
            "en-gb": "Large panel (2.70x3.30m)",
            "en-us": "Large panel (2.70x3.30m)",
            "es": "Elemento largo (2,70x3,30m)",
            "fr": "Grande élément (2,70x3,30m)",
            "it": "Elemento grande (2,70x3,30m)",
            "hu": "Nagytábla (2,70x3,30m)",
            "ru": "Крупноразмерный элемент (2,70x3,30м)",
            "sk": "Velkoplošný panel (2,70x3,30m)",
            "pl": "Płyty duże (szerokość 2,70x3,30m)"
        },
        {
            "ID": "XFRPLUS_A11_AW1",
            "de": "stehend",
            "en-gb": "upright",
            "en-us": "upright",
            "es": "vertical",
            "fr": "debout",
            "it": "verticale",
            "hu": "állítva",
            "ru": "вертикально",
            "sk": "nastojato",
            "pl": "pionowy"
        },
        {
            "ID": "XFRPLUS_A11_AW2",
            "de": "liegend",
            "en-gb": "horizontal",
            "en-us": "horizontal",
            "es": "horizontal",
            "fr": "couché",
            "it": "orizzontale",
            "hu": "fekvo",
            "ru": "горизонтально",
            "sk": "naležato",
            "pl": "poziomy"
        },
        {
            "ID": "XFRPLUS_A12",
            "de": "Längsanschluss",
            "en-gb": "In-line connections",
            "en-us": "In-line connections",
            "es": "Unión longitudinal",
            "fr": "Connexion en longueur",
            "it": "Collegamento longitudinale",
            "hu": "Hosszirányú csatlakozás",
            "ru": "Продольный стык",
            "sk": "Pozdĺžne pripojenie",
            "pl": "Połączenia w linii"
        },
        {
            "ID": "XFRPLUS_A12_AW1",
            "de": "Normaler Anschluss",
            "en-gb": "Standard connection",
            "en-us": "Standard connection",
            "es": "Unión normal",
            "fr": "Raccord normal",
            "it": "Raccordo normale",
            "hu": "Normál csatlakozás",
            "ru": "Обычный стык",
            "sk": "Normálne pripojenie",
            "pl": "Połączenie standardowe"
        },
        {
            "ID": "XFRPLUS_A12_AW2",
            "de": "Elemente vom Vorgängertakt verwenden",
            "en-gb": "Use panels of previous cycle",
            "en-us": "Use panels of previous pour",
            "es": "Utilizar los elementos del ciclo anterior",
            "fr": "Utiliser les éléments du coffrage précédent",
            "it": "Utilizza elementi del ciclo precedente",
            "hu": "Az előző ciklus elemeinek alkalmazása",
            "ru": "Использовать элементы предыдущего такта",
            "sk": "Použiť prvky z predchádzajúceho taktu",
            "pl": "Użyj płyt z poprzedniego taktu"
        },
        {
            "ID": "XFRPLUS_A13",
            "de": "Bauwerksstütze",
            "en-gb": "Column",
            "en-us": "Column",
            "es": "Pilar",
            "fr": "Poteau",
            "it": "Pilastro",
            "hu": "Pillér",
            "ru": "Колонна",
            "sk": "Stĺp",
            "pl": "Słup"
        },
        {
            "ID": "XFRPLUS_A13_AW1",
            "de": "Uni-Element 0,90 mit Stützenbühne",
            "en-gb": "Universal panel 0.90 with column formwork platform",
            "en-us": "Universal panel 0.90 with column formwork platform",
            "es": "Elem. univ. 0,90 con plataf. para pilar",
            "fr": "Px univ 0,90 avec passerelle p.coff.poteau",
            "it": "Elemento uni 0,90 con passerella per pilastri",
            "hu": "Uni elem 0,90 pillérállvány",
            "ru": "Универсальный элемент 0,90 с подмостями для колонн",
            "sk": "Univerzálny panel 0,90 so stlp. plošinou",
            "pl": "Płyta uniwersalna 0,90 z pomostem do słupa"
        },
        {
            "ID": "XFRPLUS_A13_AW2",
            "de": "Uni-Element 0,90 mit Konsole",
            "en-gb": "Universal panel 0.90 with bracket",
            "en-us": "Universal panel 0.90 with bracket",
            "es": "Elem. univ. 0,90 con ménsula",
            "fr": "Px univ 0,90 avec console",
            "it": "Elemento uni 0,90 con mensola",
            "hu": "Uni elem 0,90 betonozó konzollal",
            "ru": "Универсальный элемент 0,90 с консолью",
            "sk": "Univerzálny panel 0,90 s konzolou",
            "pl": "Płyta uniwersalna 0,90 z konsolami"
        },
        {
            "ID": "XFRPLUS_A13_AW3",
            "de": "Uni-Element 1,20 mit Stützenbühne",
            "en-gb": "Universal panel 1.20 with column formwork platform",
            "en-us": "Universal panel 1.20 with column formwork platform",
            "es": "Elem. univ. 1,20 con plataf. para pilar",
            "fr": "Px univ 1,20 avec passerelle p.coff.poteau",
            "it": "Elemento uni 1,20 con passerella per pilastri",
            "hu": "Uni elem 1,20 pillérállvány",
            "ru": "Универсальный элемент 1,20 с подмостями для колонн",
            "sk": "Univerzálny panel 1,20 so stlp. plošinou",
            "pl": "Płyta uniwersalna 1,20 z pomostem do słupa"
        },
        {
            "ID": "XFRPLUS_A13_AW4",
            "de": "Uni-Element 1,20 mit Konsole",
            "en-gb": "Universal panel 1.20 with bracket",
            "en-us": "Universal panel 1.20 with bracket",
            "es": "Elem. univ. 1,20 con ménsula",
            "fr": "Px univ 1,20 avec console",
            "it": "Elemento uni 1,20 con mensola",
            "hu": "Uni elem 1,20 betonozó konzollal",
            "ru": "Универсальный элемент 1,20 с консолью",
            "sk": "Univerzálny panel 1,20 s konzolou",
            "pl": "Płyta uniwersalna 1,20 z konsolami"
        },
        {
            "ID": "XFRPLUS_A13_AW5",
            "de": "Elemente mit Außenecken",
            "en-gb": "Panels with outside corners",
            "en-us": "Panels with outside corners",
            "es": "Elem. con esqu. ext.",
            "fr": "Px avec angle ext.",
            "it": "Elemente con angoli esterni",
            "hu": "Elem külso sarokkal",
            "ru": "Элементы с внешними углами",
            "sk": "Panely s vonkaj. roh",
            "pl": "Płyty z narożnikami zewnętrznymi"
        },
        {
            "ID": "XFRPLUS_A2",
            "de": "Stirnabschalung",
            "en-gb": "Stop-end-formwork",
            "en-us": "Bulkhead",
            "es": "Tape",
            "fr": "Coffrage d'about",
            "it": "Chiusura di testa",
            "hu": "Véglezárás",
            "ru": "Торцевые участки опалубки",
            "sk": "Debnenie čela",
            "pl": "Szalunek czołowy"
        },
        {
            "ID": "XFRPLUS_A2_AW1",
            "de": "Stirnabschalzwinge",
            "en-gb": "Stop-end waler tie",
            "en-us": "Stop-end waler tie",
            "es": "Sargento para tape",
            "fr": "Rail d'about",
            "it": "Corrente di testata",
            "hu": "Véglezáró sín",
            "ru": "Торцевая накладка",
            "sk": "Zvierka debnenia čiel",
            "pl": "Zacisk deskowania czołowego"
        },
        {
            "ID": "XFRPLUS_A2_AW2",
            "de": "Universalelement (0.90 breit)",
            "en-gb": "Universal panel (0.90 wide)",
            "en-us": "Universal panel (0.90 wide)",
            "es": "Elem. univ. (0,90 ancho)",
            "fr": "Px univ. (0.90 de large)",
            "it": "Elemento uni (0.90 largo)",
            "hu": "Uni elem (0,90 szélesség)",
            "ru": "Универсальный элемент (ширина 0.90)",
            "sk": "Univerzálny panel (šírka 0,90)",
            "pl": "Płyta uniwersalna (szerokość 0,90)"
        },
        {
            "ID": "XFRPLUS_A2_AW3",
            "de": "Klemmschiene mit Universalverbinder",
            "en-gb": "Universal waling with universal fixing bolt",
            "en-us": "Universal waling with universal fixing bolt",
            "es": "Riel de fijación con conector univ",
            "fr": "Rail de blocage avec boulon d'assemblage univ",
            "it": "Rotaia di fissaggio con tirante universale",
            "hu": "Kötősín univerzális kötőcsavarral",
            "ru": "Зажимная шина, универсальный соединитель",
            "sk": "Upínacia koľajnica s univerzálnou spojkou",
            "pl": "Uniwersalny rygiel ścienny z łącznikiem uniwersalnym"
        },
        {
            "ID": "XFRPLUS_A2_AW4",
            "de": "Klemmschiene mit Universalverbinder und Fugenband",
            "en-gb": "Universal waling with universal fixing bolt and joint strip",
            "en-us": "Universal waling with universal fixing bolt and joint strip",
            "es": "Riel de fijación con conector univ y junta de estanqu.",
            "fr": "Rail de blocage avec boulon d'assemblage univ et joint dil",
            "it": "Rotaia di fissaggio con tirante universale e nastro giunti",
            "hu": "Kötősín univerzális kötőcsavarral és dilatációs szalaggal",
            "ru": "Зажимная шина, универсальный соединитель и лентой для уплотнения швов",
            "sk": "Upínacia koľajnica s univerzálnou spojkou a tesniacim pásom",
            "pl": "Uniwersalny rygiel ścienny z łącznikiem uniwersalnym"
        },
        {
            "ID": "XFRPLUS_A3",
            "de": "Ausgleichsmöglichkeiten",
            "en-gb": "Closures",
            "en-us": "Fillers",
            "es": "Compensaciónes",
            "fr": "Compensations",
            "it": "Compensazioni",
            "hu": "Kiegyenlítési lehetőségek",
            "ru": "Доборы",
            "sk": "Možnosti vyrovnania",
            "pl": "Wypełnienia"
        },
        {
            "ID": "XFRPLUS_A3_AW1",
            "de": "Füllkörper mit Framax-Uni-Spanner",
            "en-gb": "Filler with Framax multi function clamp",
            "en-us": "Filler with Framax multi function clamp",
            "es": "Cuerpo de relleno con grapa de unión universal Framax",
            "fr": "Corps de remplissage avec tendeur rapide universel Framax",
            "it": "Compensazione con morsetto universale Framax",
            "hu": "Fabetét Framax univerzális kapcsolóval",
            "ru": "Заполнитель с Framax универсал. зажимное приспособление",
            "sk": "Vyrovnanie s Framax-univerzálnym upínačom",
            "pl": "Wypełnienie z zamkiem uniwersalnym Framax"
        },
        {
            "ID": "XFRPLUS_A3_AW2",
            "de": "Füllkörper mit Framax-Ausgleichsspanner",
            "en-gb": "Filler with Framax adjustable clamp",
            "en-us": "Filler with Framax adjustable clamp",
            "es": "Cuerpo de relleno con grapa de compensación Framax",
            "fr": "Corps de remplissage avec tendeur de compensation Framax",
            "it": "Compensazione con morsetto di compensazione Framax",
            "hu": "Fabetét Framax kiegyenlítő kapcsolóval",
            "ru": "Заполнитель с Framax пригоняемое зажимное приспособление",
            "sk": "Vyrovnanie s Framax-vyrovnávacím upínačom",
            "pl": "Wypełnienie z zamkiem wyrównującym Framax"
        },
        {
            "ID": "XFRPLUS_A3_AW3",
            "de": "mit Schalungsplatte 27mm und Klemmschiene",
            "en-gb": "with formwork sheet 27mm and universal waling",
            "en-us": "with formwork sheet 27mm and universal waling",
            "es": "con tablero de encofrado 27mm y riel de fijación",
            "fr": "avec contreplaqué 27mm et rails de blocage",
            "it": "con pannello 27mm e rotaia di fissaggio",
            "hu": "27mm-es zsaluhéjjal és kötősínnel",
            "ru": "с опалубочная плита 27мм и зажимная шина",
            "sk": "s debniacou doskou 27mm a upínacou koľajnicou",
            "pl": "ze sklejką 27mm i uniwersalnym ryglem ściennym"
        },
        {
            "ID": "XFRPLUS_A3_AW4",
            "de": "mit Schalungsplatte 21mm und Klemmschiene",
            "en-gb": "with formwork sheet 21mm and universal waling",
            "en-us": "with formwork sheet 21mm and univesal waling",
            "es": "con tablero de encofrado 21mm y riel de fijación",
            "fr": "avec contreplaqué 21mm et rails de blocage",
            "it": "con pannello 21mm e rotaia di fissaggio",
            "hu": "21mm-es zsaluhéjjal és kötősínnel",
            "ru": "с опалубочная плита 21мм и зажимная шина",
            "sk": "s debniacou doskou 21mm a upínacou koľajnicou",
            "pl": "ze sklejką 21mm i uniwersalnym ryglem ściennym"
        },
        {
            "ID": "XFRPLUS_A3_AW5",
            "de": "mit Schalungsplatte 18mm und Klemmschiene",
            "en-gb": "with formwork sheet 18mm and universal waling",
            "en-us": "with formwork sheet 18mm and univesal waling",
            "es": "con tablero de encofrado 18mm y riel de fijación",
            "fr": "avec contreplaqué 18mm et rails de blocage",
            "it": "con pannello 18mm e rotaia di fissaggio",
            "hu": "18mm-es zsaluhéjjal és kötősínnel",
            "ru": "с опалубочная плита 18мм и зажимная шина",
            "sk": "s debniacou doskou 18mm a upínacou koľajnicou",
            "pl": "ze sklejką 18mm i uniwersalnym ryglem ściennym"
        },
        {
            "ID": "XFRPLUS_A3_AW6",
            "de": "Alu-Ausgleich mit Framax-Uni-Spanner",
            "en-gb": "Alu-filler with Framax multi function clamp",
            "en-us": "Alu-filler with Framax multi function clamp",
            "es": "Compensación de alu con grapa de unión universal Framax",
            "fr": "Pièce de compensation alu avec tendeur rapide universel Framax",
            "it": "Compensazioni in alu con morsetto universale Framax",
            "hu": "Alu-betét Framax univerzális kapcsolóval",
            "ru": "Alu-компенсатор с Framax-унив. зажим. присп.",
            "sk": "Alu-vyrovnanie s Framax-univerzálnym upínačom",
            "pl": "Wstawka aluminiowa z zamkiem uniwersalnym Framax"
        },
        {
            "ID": "XFRPLUS_A3_AW7",
            "de": "Alu-Ausgleich mit Framax-Ausgleichsspanner",
            "en-gb": "Alu-filler with Framax adjustable clamp",
            "en-us": "Alu-filler with Framax adjustable clamp",
            "es": "Compensación de alu con grapa de compensación Framax",
            "fr": "Pièce de compensation alu avec tendeur de compensation Framax",
            "it": "Compensazioni in alu con morsetto di compensazione Framax",
            "hu": "Alu-betét Framax kiegyenlítő kapcsolóval",
            "ru": "Alu-компенсатор с Framax-пригон. зажим. присп.",
            "sk": "Alu-vyrovnanie s Framax-vyrovnávacím upínačom",
            "pl": "Wstawka aluminiowa z zamkiem wyrównującym Framax"
        },
        {
            "ID": "XFRPLUS_A4",
            "de": "Ecklösungen",
            "en-gb": "Corner solutions",
            "en-us": "Corner solutions",
            "es": "Solución de esquina",
            "fr": "Solutions d'angle",
            "it": "Soluzione d'angolo",
            "hu": "Sarok megoldások",
            "ru": "Угловое решение",
            "sk": "Riešenie rohov",
            "pl": "Warianty narożników"
        },
        {
            "ID": "XFRPLUS_A4_AW1",
            "de": "Außenecke und Innenecke",
            "en-gb": "Outside corner and inside corner",
            "en-us": "Outside corner and inside corner",
            "es": "Esquina exterior y esquina interior",
            "fr": "Angle extérieur et angle intérieur",
            "it": "Angolo esterno e angolo interno",
            "hu": "Külső sarokkal és belső sarokkal",
            "ru": "Внешней угловой частью и внутренней угловой частью",
            "sk": "Vonkajším rohom a vnútorným rohom",
            "pl": "Narożnik zewnętrzny i narożnik wewnętrzny"
        },
        {
            "ID": "XFRPLUS_A4_AW10",
            "de": "Scharnier-Innenecke innen und außen",
            "en-gb": "Hinged inside corner inside and outside",
            "en-us": "Hinged inside corner inside and outside",
            "es": "Esqu. bisagra int. int. y ext.",
            "fr": "Angle charnière int int et ext",
            "it": "Angolo cerniera int. int. e est.",
            "hu": "Csukl.belso sarok belül és kívül",
            "ru": "Шарнирный внутренний угол изнутри и снаружи",
            "sk": "Kĺbový vnútor. roh zvnútra a zvonka",
            "pl": "Narożnik wewnętrzny zawiasowy wewnątrz i na zewnątrz"
        },
        {
            "ID": "XFRPLUS_A4_AW11",
            "de": "Scharnier-Innenecke und Scharnier-Außenecke",
            "en-gb": "Hinged inside corner and hinged outside corner",
            "en-us": "Hinged inside corner and hinged outside corner",
            "es": "Esqu. bisagra int. y esqu. bisagra ext.",
            "fr": "Angle charnière int et angle charnière ext",
            "it": "Angolo cerniera int. e angolo cerniera est.",
            "hu": "Csukl.belso sarok és csukl.külso sarok",
            "ru": "Шарнирный внутренний угол и шарнирный внешний угол",
            "sk": "Kĺbový vnútor. roh a kĺb.vonk.roh",
            "pl": "Narożnik wewnętrzny zawiasowy i narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "XFRPLUS_A4_AW3",
            "de": "Uni-Element und Innenecke",
            "en-gb": "Universal panel and inside corner",
            "en-us": "Universal panel and inside corner",
            "es": "Elemento universal y esquina interior",
            "fr": "Panneau universel et angle intérieur",
            "it": "Elemento universale e angolo interno",
            "hu": "Uni elemmel és belső sarokkal",
            "ru": "Универс. элементом и внутренней угловой частью",
            "sk": "Univerzálnym panelom a vnútorným rohom",
            "pl": "Płyta uniwersalna i narożnik wewnętrzny"
        },
        {
            "ID": "XFRPLUS_A4_AW5",
            "de": "Außenecke und plus-Innenecke 30/30cm",
            "en-gb": "Outside corner and plus inside corner 30/30cm",
            "en-us": "Outside corner and plus inside corner 30/30cm",
            "es": "Esquina exterior y esquina interior plus 30/30cm",
            "fr": "Angle extérieur et angle intérieur plus 60/30cm",
            "it": "Angolo esterno e angolo interno plus 30/30cm",
            "hu": "Külső sarokkal és plus belső sarokkal 30/30cm",
            "ru": "Внешней угловой частью и plus внутренней угловой частью 30/30cм",
            "sk": "Vonkajším rohom a plus-vnútorným rohom 30/30cm",
            "pl": "Narożnik zewnętrzny i dodatkowy narożnik wewnętrzny 30/30 cm"
        },
        {
            "ID": "XFRPLUS_A4_AW6",
            "de": "Uni-Element und plus-Innenecke 30/30cm",
            "en-gb": "Universal panel and plus inside corner 30/30cm",
            "en-us": "Universal panel and plus inside corner 30/30cm",
            "es": "Elemento universal y esquina interior plus 30/30cm",
            "fr": "Panneau universel et angle intérieur plus 30/30cm",
            "it": "Elemento universale e angolo interno plus 30/30cm",
            "hu": "Uni elemmel és plus belső sarokkal 30/30cm",
            "ru": "Универс. элементом и plus внутренней угловой частью 30/30cм",
            "sk": "Univerzálnym panelom a plus-vnútorným rohom 30/30cm",
            "pl": "Płyta uniwersalna i narożnik wewnętrzny Framax plus 30/30cm"
        },
        {
            "ID": "XFRPLUS_A4_AW7",
            "de": "Plus-Außenecke 10/10cm und Innenecke",
            "en-gb": "Plus outside corner 10/10cm and inside corner",
            "en-us": "Plus outside corner 10/10cm and inside corner",
            "es": "Esquina exterior plus 10/10cm y esquina interior",
            "fr": "Angle extérieur plus 10/10cm et angle intérieur",
            "it": "Angolo esterno plus 10/10cm e angolo interno",
            "hu": "Plus külső sarokkal 10/10cm és belső sarokkal",
            "ru": "Plus Внешней угловой частью 10/10cm и внутренней угловой частью",
            "sk": "Plus vonkajším rohom 10/10cm a vnútorným rohom",
            "pl": "Dodatkowy narożnik zewnętrzny 10/10 cm i narożnik wewnętrzny"
        },
        {
            "ID": "XFRPLUS_A4_AW8",
            "de": "Plus-Außenecke 10/10cm und plus-Innenecke 30/30cm",
            "en-gb": "Plus outside corner 10/10cm and plus inside corner 30/30cm",
            "en-us": "Plus outside corner 10/10cm and plus inside corner 30/30cm",
            "es": "Esquina exterior plus 10/10cm y esquina interior plus 30/30cm",
            "fr": "Angle extérieur plus 10/10cm et angle intérieur plus 30/30cm",
            "it": "Angolo esterno plus 10/10cm e angolo interno plus 30/30cm",
            "hu": "Plus külső sarokkal 10/10cm és belső sarokkal plus 30/30cm",
            "ru": "Plus Внешней угловой частью 10/10cm и plus внутренней угловой частью 30/30cm",
            "sk": "Plus vonkajším rohom 10/10cm a plus vnútorným rohom 30/30cm",
            "pl": "Dodatkowy narożnik zewnętrzny 10/10 cm i dodatkowy narożnik wewnętrzny 30/30 cm"
        },
        {
            "ID": "XFRPLUS_A4_AW9",
            "de": "Ausschalecke Innen (Einseitige Ecke, siehe AI)",
            "en-gb": "Stripping corner, inside (one-sided corner, see also user infomation)",
            "en-us": "Bias cut corner, inside (one-sided corner, see also user infomation)",
            "es": "Esquina de desencofrado, interior (de un solo lado, consulte la información de usuario)",
            "fr": "Angle de décoffrage, intérieur (une face, voir information à l'attention de l'utilisateur)",
            "it": "Angolo di disarmo, interno (solo su un lato, see also User Information)",
            "hu": "Kizsaluzó sarok, belső (egyoldali, id. az alkalmazási útmutatóban is)",
            "ru": "Распалубочный уголок, внутр. (с одной стороны, see also User Infomation)",
            "sk": "Oddebňovací roh zvnútra (jednostranný roh, pozri návod na montáž a používanie)",
            "pl": "Narożnik rozszalowujący, wewnętrzny (narożnik jednostronny, zob. także w informacji dla użytkownika)"
        },
        {
            "ID": "XFRPLUS_A5",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "XFRPLUS_A5_AW1",
            "de": "Framax Xlife plus-Anker 15-30cm",
            "en-gb": "Framax Xlife plus form tie 15-30cm",
            "en-us": "Framax Xlife plus form tie 15-30cm",
            "es": "Anclaje Framax Xlife plus 15-30cm",
            "fr": "Ancrage Framax Xlife plus 15-30cm",
            "it": "Barra ancorante Framax Xlife plus 15-30cm",
            "hu": "Framax Xlife plus anker 15-30cm",
            "ru": "Framax Xlife plus анкер 15-30cм",
            "sk": "Framax Xlife plus-kotva 15-30cm",
            "pl": "Ściąg Framax Xlife plus 15-30cm"
        },
        {
            "ID": "XFRPLUS_A5_AW10",
            "de": "Ankersystem 15,0 unbehandelt ohne Reduzierstück",
            "en-gb": "Tie rod system 15.0 non-treated without reducer",
            "en-us": "Tie rod system 15.0 non-treated without reducer",
            "es": "Sistema de anclaje 15,0 non tratada sin pieza reductora",
            "fr": "Système d‘ancrage 15,0 non traitée sans embout de réduction",
            "it": "Barre ancoranti 15,0 non trattata senza riduttore",
            "hu": "Ankerrendszer 15,0 kezaletlen, szűkítőhüvely nélkül",
            "ru": "Анкерная система 15,0 необработанная без переходней муфты",
            "sk": "Kotevný systém 15,0 bez pov. úpravy bez reduk. kusa",
            "pl": "System ściągów 15,0 zwykłe bez reduktora"
        },
        {
            "ID": "XFRPLUS_A5_AW11",
            "de": "Kopfanker 15-40cm",
            "en-gb": "Head anchor 15-40cm",
            "en-us": "Head anchor 15-40cm",
            "es": "Anclaje superior 15-40cm",
            "fr": "Ancrage de tête 15-40cm",
            "it": "Ancorante di testa 15-40cm",
            "hu": "Fejanker 15-40cm",
            "ru": "Головной анкер 15-40см",
            "sk": "Vrchná kotva 15-40cm",
            "pl": "Dystans górny 15-40cm"
        },
        {
            "ID": "XFRPLUS_A5_AW12",
            "de": "Kopfanker 15-100cm",
            "en-gb": "Head anchor 15-100cm",
            "en-us": "Head anchor 15-100cm",
            "es": "Anclaje superior 15-100cm",
            "fr": "Ancrage de tête 15-100cm",
            "it": "Ancorante di testa 15-100cm",
            "hu": "Fejanker 15-100cm",
            "ru": "Головной анкер 15-100см",
            "sk": "Vrchná kotva 15-100cm",
            "pl": "Dystans górny 15-100cm"
        },
        {
            "ID": "XFRPLUS_A5_AW13",
            "de": "Framax Xlife plus-Anker 45-60cm",
            "en-gb": "Framax Xlife plus form tie 45-60cm",
            "en-us": "Framax Xlife plus form tie 45-60cm",
            "es": "Anclaje Framax Xlife plus 45-60cm",
            "fr": "Ancrage Framax Xlife plus 45-60cm",
            "it": "Barra ancorante Framax Xlife plus 45-60cm",
            "hu": "Framax Xlife plus anker 45-60cm",
            "ru": "Framax Xlife plus анкер 45-60cм",
            "sk": "Framax Xlife plus-kotva 45-60cm",
            "pl": "Ściąg Framax Xlife plus 45-60cm"
        },
        {
            "ID": "XFRPLUS_A5_AW14",
            "de": "Framax Xlife plus-Anker I 45-60cm",
            "en-gb": "Framax Xlife plus form tie I 45-60cm",
            "en-us": "Framax Xlife plus form tie I 45-60cm",
            "es": "Anclaje Framax Xlife plus I 45-60cm",
            "fr": "Ancrage Framax Xlife plus I 45-60cm",
            "it": "Barra ancorante Framax Xlife plus I 45-60cm",
            "hu": "Framax Xlife plus anker I 45-60cm",
            "ru": "Framax Xlife plus анкер I 45-60cм",
            "sk": "Framax Xlife plus-kotva I 45-60cm",
            "pl": "Ściąg Framax Xlife plus I 45-60cm"
        },
        {
            "ID": "XFRPLUS_A5_AW2",
            "de": "Ankersystem 20,0 verzinkt mit Reduzierstück",
            "en-gb": "Tie rod system 20.0 galvanised with reducer",
            "en-us": "Tie rod system 20.0 galvanized with reducer",
            "es": "Sistema de anclaje 20,0 galvanizada con pieza reductora",
            "fr": "Système d‘ancrage 20,0 galvanisée avec embout de réduction",
            "it": "Barre ancoranti 20,0 zincata con riduttore",
            "hu": "Ankerrendszer 20,0 horgayzott, szűkítőhüvellyel",
            "ru": "Анкерная система 20,0 оцинкованная с переходней муфтой",
            "sk": "Kotevný systém 20,0 pozink. s redukčným kusom",
            "pl": "System ściągów 20,0 ocynkowane z redukcją"
        },
        {
            "ID": "XFRPLUS_A5_AW3",
            "de": "Ankersystem 20,0 unbehandelt mit Reduzierstück",
            "en-gb": "Tie rod system 20.0 non-treated with reducer",
            "en-us": "Tie rod system 20.0 non-treated with reducer",
            "es": "Sistema de anclaje 20,0 non tratada con pieza reductora",
            "fr": "Système d‘ancrage 20,0 non traitée avec embout de réduction",
            "it": "Barre ancoranti 20,0 non trattata con riduttore",
            "hu": "Ankerrendszer 20,0 kezaletlen, szűkítőhüvellyel",
            "ru": "Анкерная система 20,0 необработанная с переходней муфтой",
            "sk": "Kotevný systém 20,0 bez povrch. úpravy s redukčným kusom",
            "pl": "System ściągów 20,0 nieocynkowany z redukcją"
        },
        {
            "ID": "XFRPLUS_A5_AW4",
            "de": "Framax Xlife plus-Anker 25-40cm",
            "en-gb": "Framax Xlife plus form tie 25-40cm",
            "en-us": "Framax Xlife plus form tie 25-40cm",
            "es": "Anclaje Framax Xlife plus 25-40cm",
            "fr": "Ancrage Framax Xlife plus 25-40cm",
            "it": "Barra ancorante Framax Xlife plus 25-40cm",
            "hu": "Framax Xlife plus anker 25-40cm",
            "ru": "Framax Xlife plus анкер 25-40cм",
            "sk": "Framax Xlife plus-kotva 25-40cm",
            "pl": "Ściąg Framax Xlife plus 25-40cm"
        },
        {
            "ID": "XFRPLUS_A5_AW5",
            "de": "Framax Xlife plus-Anker I 15-30cm",
            "en-gb": "Framax Xlife plus form tie I 15-30cm",
            "en-us": "Framax Xlife plus form tie I 15-30cm",
            "es": "Anclaje I Framax Xlife plus 15-30cm",
            "fr": "Ancrage I Framax Xlife plus 15-30cm",
            "it": "Barra ancorante I Framax Xlife plus 15-30cm",
            "hu": "Framax Xlife plus anker I 15-30cm",
            "ru": "Framax Xlife plus анкер I 15-30cм",
            "sk": "Framax Xlife plus-kotva I 15-30cm",
            "pl": "Ściąg Framax Xlife plus I 15-30cm"
        },
        {
            "ID": "XFRPLUS_A5_AW6",
            "de": "Framax Xlife plus-Anker I 25-40cm",
            "en-gb": "Framax Xlife plus form tie I 25-40cm",
            "en-us": "Framax Xlife plus form tie I 25-40cm",
            "es": "Anclaje I Framax Xlife plus 25-40cm",
            "fr": "Ancrage I Framax Xlife plus 25-40cm",
            "it": "Barra ancorante I Framax Xlife plus 25-40cm",
            "hu": "Framax Xlife plus anker I 25-40cm",
            "ru": "Framax Xlife plus анкер I 25-40cм",
            "sk": "Framax Xlife plus-kotva I 25-40cm",
            "pl": "Ściąg Framax Xlife plus I 25-40cm"
        },
        {
            "ID": "XFRPLUS_A5_AW7",
            "de": "Ankersystem 20,0 verzinkt ohne Reduzierstück",
            "en-gb": "Tie rod system 20.0 galvanised without reducer",
            "en-us": "Tie rod system 20.0 galvanized without reducer",
            "es": "Sistema de anclaje 20,0 galvanizada sin pieza reductora",
            "fr": "Système d‘ancrage 20,0 galvanisée sans embout de réduction",
            "it": "Barre ancoranti 20,0 zincata senza riduttore",
            "hu": "Ankerrendszer 20,0 horgayzott, szűkítőhüvely nélkül",
            "ru": "Анкерная система 20,0 оцинкованная без переходней муфты",
            "sk": "Kotevný systém 20,0 pozinkovaný bez reduk. kusa",
            "pl": "System ściągów 20,0 ocynkowane bez reduktora"
        },
        {
            "ID": "XFRPLUS_A5_AW8",
            "de": "Ankersystem 20,0 unbehandelt ohne Reduzierstück",
            "en-gb": "Tie rod system 20.0 non-treated without reducer",
            "en-us": "Tie rod system 20.0 non-treated without reducer",
            "es": "Sistema de anclaje 20,0 non tratada sin pieza reductora",
            "fr": "Système d‘ancrage 20,0 non traitée sans embout de réduction",
            "it": "Barre ancoranti 20,0 non trattata senza riduttore",
            "hu": "Ankerrendszer 20,0 kezaletlen, szűkítőhüvely nélkül",
            "ru": "Анкерная система 20,0 необработанная без переходней муфты",
            "sk": "Kotevný systém 20,0 bez pov. úpravy bez reduk. kusa",
            "pl": "System ściągów 20,0 nieocynkowany bez reduktora"
        },
        {
            "ID": "XFRPLUS_A5_AW9",
            "de": "Ankersystem 15,0 verzinkt ohne Reduzierstück",
            "en-gb": "Tie rod system 15.0 galvanised without reducer",
            "en-us": "Tie rod system 15.0 galvanized without reducer",
            "es": "Sistema de anclaje 15,0 galvanizada sin pieza reductora",
            "fr": "Système d‘ancrage 15,0 galvanisée sans embout de réduction",
            "it": "Barre ancoranti 15,0 zincata senza riduttore",
            "hu": "Ankerrendszer 15,0 horgayzott, szűkítőhüvely nélkül",
            "ru": "Анкерная система 15,0 оцинкованная без переходней муфты",
            "sk": "Kotevný systém 15,0 pozinkovaný bez reduk. kusa",
            "pl": "System ściągów 15,0 ocynkowane bez reduktora"
        },
        {
            "ID": "XFRPLUS_A6",
            "de": "Aussteifung",
            "en-gb": "Bracing",
            "en-us": "Bracing",
            "es": "Rigidización",
            "fr": "Contreventement",
            "it": "Controventatura",
            "hu": "Merevítés",
            "ru": "Элемент жесткости",
            "sk": "Vystuženie",
            "pl": "Stężenia"
        },
        {
            "ID": "XFRPLUS_A6_AW1",
            "de": "Klemmschiene 0,90m",
            "en-gb": "Universal waling 0.90m",
            "en-us": "Universal waling 0.90m",
            "es": "Riel de fijación 0,90m",
            "fr": "Rail de blocage 0,90m",
            "it": "Rotaia di fissaggio 0,90m",
            "hu": "Kötősín 0,90m",
            "ru": "Зажимная шина 0,90м",
            "sk": "Upínacia koľajnica 0,90m",
            "pl": "Szyna dociskowa 0,90m"
        },
        {
            "ID": "XFRPLUS_A6_AW2",
            "de": "Klemmschiene 1,50m",
            "en-gb": "Universal waling 1.50m",
            "en-us": "Universal waling 1.50m",
            "es": "Riel de fijación 1,50m",
            "fr": "Rail de blocage 1,50m",
            "it": "Rotaia di fissaggio 1,50m",
            "hu": "Kötősín 1,50m",
            "ru": "Зажимная шина 1,50м",
            "sk": "Upínacia koľajnica 1,50m",
            "pl": "Szyna dociskowa 1,50m"
        },
        {
            "ID": "XFRPLUS_A7",
            "de": "Basiselement",
            "en-gb": "Basic panel",
            "en-us": "Basic panel",
            "es": "Altura base",
            "fr": "Élément de base",
            "it": "Elemento di base",
            "hu": "Báziselem",
            "ru": "Базовый элемент",
            "sk": "Základný panel",
            "pl": "Płyta podstawowa"
        },
        {
            "ID": "XFRPLUS_A7_AW1",
            "de": "2,70m",
            "en-gb": "2.70m",
            "en-us": "2.70m",
            "es": "2,70m",
            "fr": "2,70m",
            "it": "2,70m",
            "hu": "2,70m",
            "ru": "2,70m",
            "sk": "2,70m",
            "pl": "2,70m"
        },
        {
            "ID": "XFRPLUS_A7_AW2",
            "de": "3,30m",
            "en-gb": "3.30m",
            "en-us": "3.30m",
            "es": "3,30m",
            "fr": "3,30m",
            "it": "3,30m",
            "hu": "3,30m",
            "ru": "3,30m",
            "sk": "3,30m",
            "pl": "3,30 m"
        },
        {
            "ID": "XFRPLUS_A7_AW3",
            "de": "0,60m",
            "en-gb": "0.60m",
            "en-us": "0.60m",
            "es": "0,60m",
            "fr": "0,60m",
            "it": "0,60m",
            "hu": "0,60m",
            "ru": "0,60m",
            "sk": "0,60m",
            "pl": "0,60m"
        },
        {
            "ID": "XFRPLUS_A7_AW4",
            "de": "1,35m",
            "en-gb": "1.35m",
            "en-us": "1.35m",
            "es": "1,35m",
            "fr": "1,35m",
            "it": "1,35m",
            "hu": "1,35m",
            "ru": "1,35m",
            "sk": "1,35m",
            "pl": "1,35m"
        },
        {
            "ID": "XFRPLUS_A8",
            "de": "T-Anschluss",
            "en-gb": "T-connection",
            "en-us": "T-connection",
            "es": "Unión T",
            "fr": "Raccord en T",
            "it": "Raccordo a T",
            "hu": "T-véglezárás",
            "ru": "Т-образный стык",
            "sk": "T-napojenie",
            "pl": "Połączenie typu T"
        },
        {
            "ID": "XFRPLUS_A8_AW3",
            "de": "Xlife-Innenecke (Ankersystem 20,0)",
            "en-gb": "Xlife inside corner (Tie rod system 20.0)",
            "en-us": "Xlife inside corner (Tie rod system 20.0)",
            "es": "Esquina interior Xlife (Sistema de anclaje 20,0)",
            "fr": "Angle intérieur Xlife (Système d‘ancrage 20,0)",
            "it": "Angolo interno Xlife (Barre ancoranti 20,0)",
            "hu": "Xlife belső sarokkal (Ankerrendszer 20,0)",
            "ru": "Xlife внутренней угловой частью (Анкерная система 20,0)",
            "sk": "Xlife-vnútorným rohom (Kotevný systém)",
            "pl": "Narożnik wewnętrzny Xlife (Ściągi 20,0)"
        },
        {
            "ID": "XFRPLUS_A8_AW4",
            "de": "Plus-Innenecke 30/30cm mit WS10",
            "en-gb": "Plus inside corner 30/30cm with WS10",
            "en-us": "Plus inside corner 30/30cm with WS10",
            "es": "Esquina interior plus 30/30cm con WS10",
            "fr": "Angle intérieur plus 30/30cm avec WS10",
            "it": "Angolo interno plus 30/30cm con WS10",
            "hu": "Plus belső sarok 30/30cm WS10 falhevederrel",
            "ru": "Plus внутренней угловой частью 30/30см с WS10",
            "sk": "Plus-vnútorným rohom 30/30cm s WS10",
            "pl": "Dodatkowy narożnik wewnętrzny 30/30 cm z WS10"
        },
        {
            "ID": "XFRPLUS_A8_AW5",
            "de": "Xlife-Innenecke mit WS10",
            "en-gb": "Xlife inside corner with WS10",
            "en-us": "Xlife inside corner with WS10",
            "es": "Esquina interior Xlife con WS10",
            "fr": "Angle intérieur Xlife avec WS10",
            "it": "Angolo interno Xlife con WS10",
            "hu": "Xlife belső sarok WS10 falhevederrel",
            "ru": "Xlife внутренней угловой частью с WS10",
            "sk": "Xlife-vnútorným rohom s WS10",
            "pl": "Narożnik wewnętrzny Xlife z WS10"
        },
        {
            "ID": "XFRPLUS_A9",
            "de": "Verbindung der Aufstockung",
            "en-gb": "Connection of stacking",
            "en-us": "Connection of stack",
            "es": "Conexión del apil.",
            "fr": "Connexion del rehausse",
            "it": "Giunzione della sovrapposizione",
            "hu": "Magasítás összekötés",
            "ru": "Соединение надстройки",
            "sk": "Spojenie nadstavenia",
            "pl": "Połączenie nadstawienia"
        },
        {
            "ID": "XFRPLUS_A9_AW1",
            "de": "Schnellspanner RU",
            "en-gb": "Quick acting clamp RU",
            "en-us": "Quick acting clamp RU",
            "es": "Grapa unión ráp.RU",
            "fr": "Serrage rapide RU",
            "it": "Morsetto rapido RU",
            "hu": "Gyorskapcsoló RU",
            "ru": "Быстродействующее зажимное приспособление RU",
            "sk": "Rýchloupínač RU",
            "pl": "Zamek RU"
        },
        {
            "ID": "XFRPLUS_A9_AW2",
            "de": "Uni-Spanner",
            "en-gb": "Multi function clamp",
            "en-us": "Multi function clamp",
            "es": "Grapa unión univ.",
            "fr": "Tendeur rapide universel",
            "it": "Morsetto universale",
            "hu": "Uni. kapcsoló",
            "ru": "Универсальный зажим",
            "sk": "Univ. upínač",
            "pl": "Zamek uniwersalny"
        },
        {
            "ID": "XLIGHT_A1",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "XLIGHT_A1_AW1",
            "de": "Ankerstab 15,0 verzinkt",
            "en-gb": "Tie rod 15.0 galvanised",
            "en-us": "Tie rod 15.0 galvanized",
            "es": "Barra de ancl. 15,0 galv.",
            "fr": "Tige d'ancrage 15,0 galvanisée",
            "it": "Barra ancorante 15,0 zincata",
            "hu": "Ankerrúd 15,0 horganyzott",
            "ru": "Анкерный стержень 15,0 оцинкованный",
            "sk": "Kotev. tyc 15,0 pozinkov.",
            "pl": "Ściąg 15,0 ocynkowany"
        },
        {
            "ID": "XLIGHT_A1_AW2",
            "de": "Ankerstab 15,0 unbehandelt",
            "en-gb": "Tie rod 15.0 non-treated",
            "en-us": "Tie rod 15.0 non-treated",
            "es": "Barra de ancl. 15,0 no tratada",
            "fr": "Tige d'ancrage 15,0 non traitée",
            "it": "Barra ancorante 15,0 non trattata",
            "hu": "Ankerrúd 15,0 kezeletlen",
            "ru": "Анкерный стержень 15,0 необработанный",
            "sk": "Kotev. tyc 15,0 neupravov.",
            "pl": "Ściąg 15,0 nieocynkowany"
        },
        {
            "ID": "XLIGHT_A2",
            "de": "Aufstockung",
            "en-gb": "Stacking",
            "en-us": "Stacking",
            "es": "Apilado",
            "fr": "Rehaussé",
            "it": "Sovrapposizione",
            "hu": "Magasítás",
            "ru": "Надстройка",
            "sk": "Nadstavenia",
            "pl": "Nadstawienie"
        },
        {
            "ID": "XLIGHT_A2_AW1",
            "de": "ohne Aufstockung",
            "en-gb": "w/o stacking",
            "en-us": "w/o stacking",
            "es": "Sin apilado",
            "fr": "sans rehausse",
            "it": "senza sopralzo",
            "hu": "Magasítás nélkül",
            "ru": "без надстройки",
            "sk": "bez nadstavenia",
            "pl": "bez nadstawienia"
        },
        {
            "ID": "XLIGHT_A2_AW2",
            "de": "Liegend aufgestockt",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz.",
            "es": "Apilado horizontal",
            "fr": "Rehaussé couchant",
            "it": "Sovrapposizione orizz.",
            "hu": "Fektetve magasítva",
            "ru": "надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "pl": "nadstawienie poziome"
        },
        {
            "ID": "XLIGHT_A2_AW3",
            "de": "Stehend aufgestockt",
            "en-gb": "stacked upright",
            "en-us": "stacked upright",
            "es": "Apilado vertical",
            "fr": "Rehaussé vertical",
            "it": "Sovrapposizione vert.",
            "hu": "Állítva magasítva",
            "ru": "Надстройка в вертикальном положении",
            "sk": "Nadstavenie nastojato",
            "pl": "nadstawienie pionowe"
        },
        {
            "ID": "XLIGHT_A3",
            "de": "Stehende oder liegende Elemente",
            "en-gb": "Upright or horiz. panels",
            "en-us": "Upright or horiz. panels",
            "es": "Elementos verticales o horizontales",
            "fr": "Px debouts ou couchés",
            "it": "Elementi vert. o orizz.",
            "hu": "Elemek állítva vagy fekvo",
            "ru": "Элементы, установленные горизонтально или вертикально",
            "sk": "Panely nastojato alebo naležato",
            "pl": "Płyty pionowe lub poziome"
        },
        {
            "ID": "XLIGHT_A3_AW1",
            "de": "Stehende Elemente",
            "en-gb": "Upright panels",
            "en-us": "Upright panels",
            "es": "Elementos verticales",
            "fr": "Px debout",
            "it": "Elementi vert.",
            "hu": "Elemek állítva",
            "ru": "Элементы, установленные вертикально",
            "sk": "Panely nastojato",
            "pl": "Płyty pionowe"
        },
        {
            "ID": "XLIGHT_A3_AW2",
            "de": "Liegende Elemente",
            "en-gb": "Horiz. panels",
            "en-us": "Horiz. panels",
            "es": "Elementos horizontales",
            "fr": "Px couchés",
            "it": "Elementi orizz.",
            "hu": "Elemek fekvo",
            "ru": "Элементы, установленные горизонтально",
            "sk": "Panely naležato",
            "pl": "Płyty poziome"
        },
        {
            "ID": "XLIGHT_A4",
            "de": "Stirnabschalung",
            "en-gb": "Stop-end formwork",
            "en-us": "Bulkhead",
            "es": "Tape",
            "fr": "Coffrage d'about",
            "it": "Chiusura di testa",
            "hu": "Véglezárás",
            "ru": "Торцевые участки опалубки",
            "sk": "Debnenie cela",
            "pl": "Szalunek czołowy"
        },
        {
            "ID": "XLIGHT_A4_AW1",
            "de": "Frami-Klemmschiene",
            "en-gb": "Frami universal waling",
            "en-us": "Frami universal waling",
            "es": "Riel de fijación Frami",
            "fr": "Rail de blocage Frami",
            "it": "Rotaia di fissaggio Frami",
            "hu": "Frami kötősín",
            "ru": "Frami зажимная шина",
            "sk": "Frami-upínacia koľajnica",
            "pl": "Uniwersalny rygiel ścienny Frami"
        },
        {
            "ID": "XLIGHT_A4_AW2",
            "de": "Frami-Stirnabschalzwinge",
            "en-gb": "Frami stop-end waler tie",
            "en-us": "Frami stop-end waler tie",
            "es": "Sargento para tape Frami",
            "fr": "Rail d'about Frami",
            "it": "Corrente di testata Frami",
            "hu": "Frami véglezáró sín",
            "ru": "Frami торцевая накладка",
            "sk": "Frami-zvierka debnenia čiel",
            "pl": "Zacisk deskowania czołowego Frami"
        },
        {
            "ID": "XLIGHT_A4_AW3",
            "de": "Uni-Element",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Panneau universel",
            "it": "Elemento uni",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Univerzálny panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XLIGHT_A5",
            "de": "Ausgleich",
            "en-gb": "Closure",
            "en-us": "Filler",
            "es": "Compensación",
            "fr": "Compensation",
            "it": "Estensione",
            "hu": "Kiegyenlítés",
            "ru": "Компенсация",
            "sk": "Vyrovnanie",
            "pl": "Wypełnienie"
        },
        {
            "ID": "XLIGHT_A5_AW1",
            "de": "Ankerung im Element",
            "en-gb": "Ties through panel",
            "en-us": "Ties through panel",
            "es": "Anclaje en elemento",
            "fr": "Ancrage dans le panneau",
            "it": "Anc. nell'elemento",
            "hu": "Ankerozás az elemen keresztül",
            "ru": "Анкерование в элемент",
            "sk": "Kotvenie v paneli",
            "pl": "Ściągi przez płytę"
        },
        {
            "ID": "XLIGHT_A5_AW2",
            "de": "Ankerung im Ausgleich",
            "en-gb": "Ties through closure",
            "en-us": "Ties through filler",
            "es": "Anclaje en compensación",
            "fr": "Ancrage dans le compens.",
            "it": "Anc. nella compens.",
            "hu": "Ankerozás a kiegyenlítésen keresztül",
            "ru": "Анкерование в компенсатор",
            "sk": "Kotvenie vo vyrovnaní",
            "pl": "Ściągi przez wstawką"
        },
        {
            "ID": "XLIGHT_A5_AW3",
            "de": "mit Schalungsplatte",
            "en-gb": "with formwork sheet",
            "en-us": "with formwork sheet",
            "es": "con tablero de encofrado",
            "fr": "avec contreplaqué",
            "it": "con pannello",
            "hu": "zsaluhéjjal",
            "ru": "C опалубочной  плитой",
            "sk": "s debniacou doskou",
            "pl": "ze sklejką"
        },
        {
            "ID": "XLIGHT_A6",
            "de": "Anschlüsse",
            "en-gb": "Connections",
            "en-us": "Connections",
            "es": "Conexónes",
            "fr": "Connexions",
            "it": "Collegamenta",
            "hu": "Csatlakozó",
            "ru": "Стыки",
            "sk": "Napojenia",
            "pl": "Połączenia"
        },
        {
            "ID": "XLIGHT_A6_AW1",
            "de": "ohne Ausgleich",
            "en-gb": "w/o closure",
            "en-us": "w/o filler",
            "es": "sin ajuste",
            "fr": "sans compens.",
            "it": "senza chiusura",
            "hu": "kiegyenlítés nélkül",
            "ru": "Без компенсации",
            "sk": "bez vyrovnania",
            "pl": "bez wypełnienia"
        },
        {
            "ID": "XLIGHT_A6_AW2",
            "de": "mit Ausgleich",
            "en-gb": "with closure",
            "en-us": "with filler",
            "es": "Con ajuste",
            "fr": "avec compens.",
            "it": "con chiusura",
            "hu": "kiegyenlítéssel",
            "ru": "C компенсацей",
            "sk": "s vyrovnaním",
            "pl": "z wypełnieniem"
        },
        {
            "ID": "XLIGHT_A6_AW3",
            "de": "mit Ausgleich und 0,30m Element",
            "en-gb": "with closure and 0,30m panel",
            "en-us": "with filler and 0,30m panel",
            "es": "Con ajuste y panel de 0,30m",
            "fr": "avec compens. et pan. 0,30m",
            "it": "con chiusura e pannello da 0,30m",
            "hu": "kiegyenlítéssel és 0,30m-es elemmel",
            "ru": "C компенсацей и элементом 0,30м",
            "sk": "s vyrovnaním a panelom 0,30m",
            "pl": "z wypełnieniem i płytą 0,30m"
        },
        {
            "ID": "XLIGHT_A6_AW4",
            "de": "Uni-Element",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Panneau universel",
            "it": "Elemento uni",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Univerzálny panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XLIGHT_A7",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "XLIGHT_A7_AW1",
            "de": "Innenecke und Außenecke",
            "en-gb": "Inside corner and outside corner",
            "en-us": "Inside corner and outside corner",
            "es": "Esqu. int. y esqu. ext.",
            "fr": "Angle int. et angle ext.",
            "it": "Angolo interno e angolo esterno",
            "hu": "Belso sarok és külso sarok",
            "ru": "Внутренний угол и внешний угол",
            "sk": "Vnút. rohy a vonkaj. roh",
            "pl": "Narożnik wewnętrzny i narożnik zewnętrzny"
        },
        {
            "ID": "XLIGHT_A7_AW2",
            "de": "Innenecke und Uni-Element",
            "en-gb": "Inside corner and universal panel",
            "en-us": "Inside corner and universal panel",
            "es": "Esqu. int. y elem. univ",
            "fr": "Angle intérieur et pann. univ.",
            "it": "Angolo interno ed elemento uni",
            "hu": "Belso sarok és uni elem",
            "ru": "Внутренний угол с унив.элементом",
            "sk": "Vnútorný roh + univerzálny panel",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna"
        },
        {
            "ID": "XLIGHT_A7_AW3",
            "de": "Scharnier-Innenecke und Scharnier-Außenecke",
            "en-gb": "Hinged inside corner and hinged outside corner",
            "en-us": "Hinged inside corner and hinged outside corner",
            "es": "Esqu. bisagra int. y esqu. bisagra ext.",
            "fr": "Angle charnière int et angle charnière ext",
            "it": "Angolo cerniera int. e angolo cerniera est.",
            "hu": "Csukl.belso sarok és csukl.külso sarok",
            "ru": "Шарнирный внутренний угол и шарнирный внешний угол",
            "sk": "Kĺbový vnútor. roh a kĺb.vonk.roh",
            "pl": "Narożnik wewnętrzny zawiasowy i narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "XLIGHT_A7_AW4",
            "de": "Scharnier-Innenecke innen und außen",
            "en-gb": "Hinged inside corner inside and outside",
            "en-us": "Hinged inside corner inside and outside",
            "es": "Esqu. bisagra int. int. y ext.",
            "fr": "Angle charnière int int et ext",
            "it": "Angolo cerniera int. int. e est.",
            "hu": "Csukl.belso sarok belül és kívül",
            "ru": "Шарнирный внутренний угол изнутри и снаружи",
            "sk": "Kĺbový vnútor. roh zvnútra a zvonka",
            "pl": "Narożnik wewnętrzny zawiasowy wewnątrz i na zewnątrz"
        },
        {
            "ID": "XLIGHT_A8",
            "de": "Grundelementhöhe",
            "en-gb": "Basic panel height",
            "en-us": "Basic panel height",
            "es": "Altura del elemento base",
            "fr": "Hauteur de base",
            "it": "Altezza dell’ elemento base",
            "hu": "Alapelem magasság",
            "ru": "Высота основного элемента",
            "sk": "Výška základného panelu",
            "pl": "Wysokość płyty podstawowej"
        },
        {
            "ID": "XLIGHT_A8_AW1",
            "de": "1,50m",
            "en-gb": "1.50m",
            "en-us": "1.50m",
            "es": "1,50m",
            "fr": "1,50m",
            "it": "1,50m",
            "hu": "1,50m",
            "ru": "1,50m",
            "sk": "1,50m",
            "pl": "1,50m"
        },
        {
            "ID": "XLIGHT_A8_AW2",
            "de": "1,00m",
            "en-gb": "1.00m",
            "en-us": "1.00m",
            "es": "1,00m",
            "fr": "1,00m",
            "it": "1,00m",
            "hu": "1,00m",
            "ru": "1,00m",
            "sk": "1,00m",
            "pl": "1,00m"
        },
        {
            "ID": "XLIGHT_A8_AW3",
            "de": "3,00m",
            "en-gb": "3.00m",
            "en-us": "3.00m",
            "es": "3,00m",
            "fr": "3,00m",
            "it": "3,00m",
            "hu": "3,00m",
            "ru": "3,00м",
            "sk": "3,00m",
            "pl": "3,00m"
        },
        {
            "ID": "XLIGHT_A9",
            "de": "Fundamentlösung",
            "en-gb": "Foundation solution",
            "en-us": "Foundation solution",
            "es": "Solución para fundaciones",
            "fr": "Solution de fondation",
            "it": "Soluzione per fondamenta",
            "hu": "Alapozás megoldása",
            "ru": "Решение для фундамента",
            "sk": "Riešenie základov",
            "pl": "Rozwiązanie dla fundamentów"
        },
        {
            "ID": "XLIGHT_A9_AW1",
            "de": "Einzelelement (für Zubehörsystem Abstützwinkel)",
            "en-gb": "Single panel (for supporting construction accessory system)",
            "en-us": "Single panel (for supporting construction accessory system)",
            "es": "Elementos individuales (para sistema de accesorio Soporte angular)",
            "fr": "Élément individuel (pour le système d’accessoires fermette)",
            "it": "Elemento singolo (per sistema accessori puntello triangolare)",
            "hu": "Egyedülálló elem (kitámasztó szögvas tartozékokhoz)",
            "ru": "Отдельный элемент (для комплектующих опорного раскоса)",
            "sk": "Jednotlivý panel (pre oporný uholník, systém príslušenstva)",
            "pl": "Płyta pojedyncza (do systemu osprzętu konstrukcji wspierającej)"
        },
        {
            "ID": "XLIGHT_A9_AW2",
            "de": "mit Ankerhaltewinkel",
            "en-gb": "with tie-holder bracket",
            "en-us": "with tie-holder bracket",
            "es": "con ang. de suj. de ancl.",
            "fr": "avec équerre d'ancrage",
            "it": "con squadretta d'ancoraggio",
            "hu": "ankertartó szögvassal",
            "ru": "с упорным уголком для анкера",
            "sk": "s držiakom kotevnej tyče",
            "pl": "z uchwytem kątowym do ściągów"
        },
        {
            "ID": "XLIGHT_A9_AW3",
            "de": "mit Fundamentspanner",
            "en-gb": "with foundation clamp",
            "en-us": "with foundation clamp",
            "es": "con grapa de cimentación",
            "fr": "avec serrage pour fondation",
            "it": "con morsetto per fondazioni",
            "hu": "ankerszalag feszítővel",
            "ru": "с фундаментный зажим",
            "sk": "s fundamentovým upínačom",
            "pl": "z zaciskiem szalunkowym"
        },
        {
            "ID": "XLIGHT_A9_AW4",
            "de": "Uni-Element",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Panneau universel",
            "it": "Elemento uni",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Univerzálny panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XLIGHT_A9_AW5",
            "de": "steh. El. mit Ankerhaltewinkel und Ankerstab",
            "en-gb": "Upright panels with tie-holder bracket and tie rod",
            "en-us": "Upright panels with tie-holder bracket and tie rod",
            "es": "Paneles verticales con ang. de suj. de ancl. y barra de anclaje",
            "fr": "Él. vert. avec équerre d'ancrage et tige d’ancrage",
            "it": "Pannelli montanti con squadretta d'ancoraggio e barra ancorante",
            "hu": "Állított táblák ankertartó szögvassal és ankerrúddal",
            "ru": "Вертикальные панели с упорным уголком для анкера и анкером",
            "sk": "stoj. panel s držiakom kot. tyče a kot. tyčou",
            "pl": "Płyty pionowo z uchwytem kątowym do ściągów i ściągiem"
        },
        {
            "ID": "XLIGHT_A9_AW6",
            "de": "steh. El. mit Ankerhaltewinkel und Bodenhalter",
            "en-gb": "Upright panels with tie-holder bracket and floor fixing plate",
            "en-us": "Upright panels with tie-holder bracket and bracing clip",
            "es": "Paneles verticales con ang. de suj. de ancl. y placa de fijación a suelo",
            "fr": "Él. vert. avec équerre d'ancrage et support d’ancrage",
            "it": "Pannelli montanti con squadretta d'ancoraggio e piastra di fissaggio",
            "hu": "Állított táblák ankertartó szögvassal és aljzatrögzítővel",
            "ru": "Вертикальные панели с упорным уголком для анкера и держателем для пола",
            "sk": "stoj. panel s držiak. kot.tyče a spodku debn.",
            "pl": "Płyty pionowo z uchwytem kątowym do ściągów i płytką mocującą do podłoża"
        },
        {
            "ID": "XLIGHT_A10",
            "de": "Bauwerksstütze",
            "en-gb": "Column",
            "en-us": "Column",
            "es": "Pilar",
            "fr": "Poteau",
            "it": "Pilastro",
            "hu": "Pillér",
            "ru": "Колонна",
            "sk": "Stĺp",
            "pl": "Słup"
        },
        {
            "ID": "XLIGHT_A10_AW1",
            "de": "Uni-Element mit Konsole",
            "en-gb": "Universal panel with bracket",
            "en-us": "Universal panel with bracket",
            "es": "Elem. univ. con ménsula",
            "fr": "Px univ avec console",
            "it": "Elemento uni con mensola",
            "hu": "Uni elem betonozó konzollal",
            "ru": "Универсальный элемент с консолью",
            "sk": "Univerzálny panel so stlp. plošinou",
            "pl": "Płyta uniwersalna z konsolami"
        },
        {
            "ID": "XLIGHT_A10_AW2",
            "de": "Uni-Element mit Konsole + Gegengeländer",
            "en-gb": "Univ. panel with bracket + counter railing",
            "en-us": "Univ. panel with bracket + counter railing",
            "es": "Elem. univ. con ménsula + contrabarandillas",
            "fr": "Px univ avec console+garde-corps face coff.",
            "it": "Elemento uni con mens.+arapetto di protez.",
            "hu": "Uni elem betonozó konzollal + ellenkorlátok",
            "ru": "Унив. эл. с консолью+компл. защит. огражд.",
            "sk": "Univ. panel so stlp. plošinou+protizábrad.",
            "pl": "Płyta uniwersalna z konsolami + barierki"
        },
        {
            "ID": "XLIGHT_A10_AW3",
            "de": "Uni-Element und Element",
            "en-gb": "Universal panel and panel",
            "en-us": "Universal panel and panel",
            "es": "Elemento universal y elemento",
            "fr": "Panneau universel et panneau",
            "it": "Elemento universale e elemento",
            "hu": "Uni elemmel és elem",
            "ru": "Универс. элементом и элемент",
            "sk": "Univerzálnym panelom a panel",
            "pl": "Płyta uniwersalna i płyta"
        },
        {
            "ID": "XLIGHT_A10_AW4",
            "de": "Elemente mit Außenecken",
            "en-gb": "Panels with outside corners",
            "en-us": "Panels with outside corners",
            "es": "Elem. con esqu. ext.",
            "fr": "Px avec angle ext",
            "it": "Elementi con angolo esterno",
            "hu": "Elem külso sarokkal",
            "ru": "Элементи с внешними углами",
            "sk": "Panely s vonkaj. roh",
            "pl": "Płyty z narożnikami zewnętrznymi"
        },
        {
            "ID": "XREFORMA_A1",
            "de": "Grundelementhöhe",
            "en-gb": "Basic panel height",
            "en-us": "Basic panel height",
            "es": "Altura del elemento base",
            "fr": "Hauteur de base",
            "it": "Altezza dell’ elemento base",
            "hu": "Alapelem magasság",
            "ru": "Высота основного элемента",
            "sk": "Výška základného panelu",
            "pl": "Wysokość płyty podstawowej"
        },
        {
            "ID": "XREFORMA_A1_AW1",
            "de": "1,50m",
            "en-gb": "1.50m",
            "en-us": "1.50m",
            "es": "1,50m",
            "fr": "1,50m",
            "it": "1,50m",
            "hu": "1,50m",
            "ru": "1,50м",
            "sk": "1,50m",
            "pl": "1,50m"
        },
        {
            "ID": "XREFORMA_A1_AW2",
            "de": "3,00m",
            "en-gb": "3.00m",
            "en-us": "3.00m",
            "es": "3,00m",
            "fr": "3,00m",
            "it": "3,00m",
            "hu": "3,00m",
            "ru": "3,00м",
            "sk": "3,00m",
            "pl": "3,00m"
        },
        {
            "ID": "XREFORMA_A1_AW3",
            "de": "3,30m",
            "en-gb": "3.30m",
            "en-us": "3.30m",
            "es": "3,30m",
            "fr": "3,30m",
            "it": "3,30m",
            "hu": "3,30m",
            "ru": "3,30м",
            "sk": "3,30m",
            "pl": "3,30m"
        },
        {
            "ID": "XREFORMA_A10",
            "de": "Anschlüsse",
            "en-gb": "Connections",
            "en-us": "Connections",
            "es": "Conexónes",
            "fr": "Connexions",
            "it": "Collegamenta",
            "hu": "Csatlakozó",
            "ru": "Стыки",
            "sk": "Napojenia",
            "pl": "Połączenia"
        },
        {
            "ID": "XREFORMA_A10_AW1",
            "de": "Uni-Elemente",
            "en-gb": "Universal panels",
            "en-us": "Universal panels",
            "es": "Elem. univ.",
            "fr": "Px. univ.",
            "it": "Elementi universali",
            "hu": "Uni elem",
            "ru": "Универсальные элементы",
            "sk": "Uni. panely",
            "pl": "Płyty uniwersalne"
        },
        {
            "ID": "XREFORMA_A10_AW2",
            "de": "Großelement",
            "en-gb": "Large panel",
            "en-us": "Large panel",
            "es": "Elemento largo",
            "fr": "Grande élément",
            "it": "Elemento grande",
            "hu": "Nagytábla",
            "ru": "Крупноразмерный элемент",
            "sk": "Velkoplošný panel",
            "pl": "Płyta duża"
        },
        {
            "ID": "XREFORMA_A10_AW3",
            "de": "Füllkörper",
            "en-gb": "Filler block",
            "en-us": "Filler block",
            "es": "Cuerpo de relleno",
            "fr": "Corps de remplissage",
            "it": "Compensazione",
            "hu": "Kiegészítő betét",
            "ru": "Вкладыш",
            "sk": "Výplň",
            "pl": "Wstawka"
        },
        {
            "ID": "XREFORMA_A11",
            "de": "Fundamentlösungen",
            "en-gb": "Foundation solutions",
            "en-us": "Foundation solutions",
            "es": "Solución para fundaciones",
            "fr": "Solution de fondation",
            "it": "Soluzione per fondamenta",
            "hu": "Alapozás megoldása",
            "ru": "Решение для фундамента",
            "sk": "Riešenie základov",
            "pl": "Rozwiązania dla fundamentów"
        },
        {
            "ID": "XREFORMA_A11_AW1",
            "de": "Fundamentspanner",
            "en-gb": "Foundation clamp",
            "en-us": "Foundation clamp",
            "es": "Grapa de cimentación",
            "fr": "Serrage pour fondation",
            "it": "Morsetto per fondazioni",
            "hu": "Anker szalag feszítő",
            "ru": "Фундаментный зажим",
            "sk": "Fundamentový upínač",
            "pl": "Napinacz"
        },
        {
            "ID": "XREFORMA_A11_AW2",
            "de": "Bodenhalter",
            "en-gb": "Floor fixing plate",
            "en-us": "Bracing clip",
            "es": "Placa de fijación a suelo",
            "fr": "Support d'ancrage",
            "it": "Piastra di fissaggio",
            "hu": "Aljzatrögzítő",
            "ru": "Держатель для пола",
            "sk": "Držiak spodku debnenia",
            "pl": "Płytka mocująca do podłoża"
        },
        {
            "ID": "XREFORMA_A11_AW3",
            "de": "ohne",
            "en-gb": "without",
            "en-us": "without",
            "es": "sin",
            "fr": "sans",
            "it": "senza",
            "hu": "nem",
            "ru": "без",
            "sk": "bez",
            "pl": "bez"
        },
        {
            "ID": "XREFORMA_A12",
            "de": "Einseitige Ecke",
            "en-gb": "One-sided corner",
            "en-us": "One-sided corner",
            "es": "Esquina de un solo lado",
            "fr": "Une face angle",
            "it": "Angolo unilaterale",
            "hu": "Egyoldali sarok",
            "ru": "Односторонний угол",
            "sk": "Jednostranný roh",
            "pl": "Narożnik jednostronny"
        },
        {
            "ID": "XREFORMA_A12_AW1",
            "de": "Außen- oder Innenecke",
            "en-gb": "Outside or inside corner",
            "en-us": "Outside or inside corner",
            "es": "Esqu. ext. o esqu. int.",
            "fr": "Angle ext. et int.",
            "it": "Angolo est. o int.",
            "hu": "Külso vagy Belso sarok",
            "ru": "Внешний или внутренний угол",
            "sk": "Vonk. roh al. vnút. roh",
            "pl": "Narożnik zewnętrzny lub wewnętrzny"
        },
        {
            "ID": "XREFORMA_A12_AW2",
            "de": "Ausschalecke I",
            "en-gb": "Stripping corner I",
            "en-us": "Bias cut corner I",
            "es": "Esquina de desencofrado int. I",
            "fr": "Angle de décoffrage I",
            "it": "Angolo di disarmo interno I",
            "hu": "Kizsaluzó sarok I",
            "ru": "Pаспалубочный угол I",
            "sk": "Oddebňovací roh I",
            "pl": "Narożnik wew. rozszalowujący I"
        },
        {
            "ID": "XREFORMA_A12_AW3",
            "de": "Scharnier-Innenecke oder Scharnier-Außenecke",
            "en-gb": "Hinged inside corner or hinged outside corner",
            "en-us": "Hinged inside corner or hinged outside corner",
            "es": "Esqu. bisagra int./esqu. bisagra ext.",
            "fr": "Angle charnière int ou angle charnière ext",
            "it": "Angolo cerniera int./angolo cerniera est.",
            "hu": "Csukl.belso sarok / csukl.külso sarok",
            "ru": "Шарнирный внутренний угол / шарнирный внешний угол",
            "sk": "Klbový vnútor. roh / klb.vonk.roh",
            "pl": "Narożnik wewnętrzny zawiasowy lub narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "XREFORMA_A2",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "XREFORMA_A2_AW1",
            "de": "Uni-Element und Innenecke",
            "en-gb": "Universal panel and inside corner",
            "en-us": "Universal panel and inside corner",
            "es": "Elemento universal y esquina interior",
            "fr": "Panneau universel et angle intérieur",
            "it": "Elemento universale e angolo interno",
            "hu": "Uni elemmel és belső sarokkal",
            "ru": "Универс. элементом и внутренней угловой частью",
            "sk": "Univerzálnym panelom a vnútorným rohom",
            "pl": "Płyta uniwersalna i narożnik wewnętrzny"
        },
        {
            "ID": "XREFORMA_A2_AW2",
            "de": "Außenecke und Innenecke",
            "en-gb": "Outside corner and inside corner",
            "en-us": "Outside corner and inside corner",
            "es": "Esquina exterior y esquina interior",
            "fr": "Angle extérieur et angle intérieur",
            "it": "Angolo esterno e angolo interno",
            "hu": "Külső sarokkal és belső sarokkal",
            "ru": "Внешней угловой частью и внутренней угловой частью",
            "sk": "Vonkajším rohom a vnútorným rohom",
            "pl": "Narożnik zewnętrzny i narożnik wewnętrzny"
        },
        {
            "ID": "XREFORMA_A2_AW3",
            "de": "Scharnier-Innenecke und Scharnier-Außenecke",
            "en-gb": "Hinged inside corner and hinged outside corner",
            "en-us": "Hinged inside corner and hinged outside corner",
            "es": "Esqu. bisagra int. y esqu. bisagra ext.",
            "fr": "Angle charnière int et angle charnière ext",
            "it": "Angolo cerniera int. e angolo cerniera est.",
            "hu": "Csukl.belso sarok és csukl.külso sarok",
            "ru": "Шарнирный внутренний угол и шарнирный внешний угол",
            "sk": "Kĺbový vnútor. roh a kĺb.vonk.roh",
            "pl": "Narożnik wewnętrzny zawiasowy i narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "XREFORMA_A2_AW4",
            "de": "Scharnier-Innenecke innen und außen",
            "en-gb": "Hinged inside corner inside and outside",
            "en-us": "Hinged inside corner inside and outside",
            "es": "Esqu. bisagra int. int. y ext.",
            "fr": "Angle charnière int int et ext",
            "it": "Angolo cerniera int. int. e est.",
            "hu": "Csukl.belso sarok belül és kívül",
            "ru": "Шарнирный внутренний угол изнутри и снаружи",
            "sk": "Kĺbový vnútor. roh zvnútra a zvonka",
            "pl": "Narożnik wewnętrzny zawiasowy wewnątrz i na zewnątrz"
        },
        {
            "ID": "XREFORMA_A3",
            "de": "Ausgleich",
            "en-gb": "Closure",
            "en-us": "Filler",
            "es": "Compensación",
            "fr": "Compensation",
            "it": "Estensione",
            "hu": "Kiegyenlítés",
            "ru": "Компенсация",
            "sk": "Vyrovnanie",
            "pl": "Wypełnienie"
        },
        {
            "ID": "XREFORMA_A3_AW1",
            "de": "Ankerung im Element",
            "en-gb": "Ties through panel",
            "en-us": "Ties through panel",
            "es": "Anclaje en elemento",
            "fr": "Ancrage dans le panneau",
            "it": "Anc. nell'elemento",
            "hu": "Ankerozás az elemen keresztül",
            "ru": "Анкерование в элемент",
            "sk": "Kotvenie v paneli",
            "pl": "Ściągi przez płytę"
        },
        {
            "ID": "XREFORMA_A3_AW2",
            "de": "Ankerung im Ausgleich",
            "en-gb": "Ties through closure",
            "en-us": "Ties through filler",
            "es": "Anclaje en compensación",
            "fr": "Ancrage dans le compens.",
            "it": "Anc. nella compens.",
            "hu": "Ankerozás a kiegyenlítésen keresztül",
            "ru": "Анкерование в компенсатор",
            "sk": "Kotvenie vo vyrovnaní",
            "pl": "Ściągi przez wstawką"
        },
        {
            "ID": "XREFORMA_A3_AW3",
            "de": "Ausgleichsblech 20 mit Ankerstab",
            "en-gb": "Closure plate 20 with tie rod",
            "en-us": "Closure plate 20 with tie rod",
            "es": "Chapa de compensación 20 con barra de anclaje",
            "fr": "Pièce de compensation 20 avec tige d’ancrage",
            "it": "Lamiera di compensazione 20 con barra anc.",
            "hu": "Kiegyenlítő lemez 20 ankerrúddal",
            "ru": "Компенсационный лист 20 с анкерным стержнем",
            "sk": "Vyrovnáv. plech 20 s kotevnim tyč",
            "pl": "Blacha wyrównująca 20 with tie rod"
        },
        {
            "ID": "XREFORMA_A3_AW4",
            "de": "Ausgleichsblech 20 mit Ausgleichsspanner",
            "en-gb": "Closure plate 20 w/ adjustable clamp",
            "en-us": "Closure plate 20 w/ adjustable clamp",
            "es": "Chapa de compensación 20 con grapa de comp.",
            "fr": "Pièce de compensation 20 avec tendeur de compensation",
            "it": "Lamiera di compensazione 20 con morsetto di compensazione",
            "hu": "Kiegyenlíto lemez 20 kiegyenlítő kapcsolóval",
            "ru": "Компенсационный лист 20 с пригон. зажимн. приспособл.",
            "sk": "Vyrovnáv. plech 20 s vyrovnávacím upínačom",
            "pl": "Blacha wyrównująca 20 w/ adjustable clamp"
        },
        {
            "ID": "XREFORMA_A4",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "XREFORMA_A4_AW1",
            "de": "Ankerstab 15,0 verzinkt",
            "en-gb": "Tie rod 15.0 galvanised",
            "en-us": "Tie rod 15.0 galvanized",
            "es": "Barra de ancl. 15,0 galv.",
            "fr": "Tige d'ancrage 15,0 galvanisée",
            "it": "Barra ancorante 15,0 zincata",
            "hu": "Ankerrúd 15,0 horganyzott",
            "ru": "Анкерный стержень 15,0 оцинкованный",
            "sk": "Kotev. tyc 15,0 pozinkov.",
            "pl": "Ściąg 15,0 ocynkowany"
        },
        {
            "ID": "XREFORMA_A4_AW2",
            "de": "Ankerstab 15,0 unbehandelt",
            "en-gb": "Tie rod 15.0 non-treated",
            "en-us": "Tie rod 15.0 non-treated",
            "es": "Barra de ancl. 15,0 no tratada",
            "fr": "Tige d'ancrage 15,0 non traitée",
            "it": "Barra ancorante 15,0 non trattata",
            "hu": "Ankerrúd 15,0 kezeletlen",
            "ru": "Анкерный стержень 15,0 необработанный",
            "sk": "Kotev. tyc 15,0 neupravov.",
            "pl": "Ściąg 15,0 nieocynkowany"
        },
        {
            "ID": "XREFORMA_A5",
            "de": "Bauwerksstütze",
            "en-gb": "Column",
            "en-us": "Column",
            "es": "Pilar",
            "fr": "Poteau",
            "it": "Pilastro",
            "hu": "Pillér",
            "ru": "Колонна",
            "sk": "Stĺp",
            "pl": "Słup"
        },
        {
            "ID": "XREFORMA_A5_AW1",
            "de": "Uni-Element mit Konsole",
            "en-gb": "Universal panel with bracket",
            "en-us": "Universal panel with bracket",
            "es": "Elemento universal con ménsula",
            "fr": "Panneau universel avec console",
            "it": "Elemento universale con mensola",
            "hu": "Uni elem betonozó konzollal",
            "ru": "Универсальный элемент с консолью",
            "sk": "Univerzálne panel s konzolou",
            "pl": "Płyta uniwersalna z konsolami"
        },
        {
            "ID": "XREFORMA_A5_AW2",
            "de": "Elemente mit Außenecken",
            "en-gb": "Panels with outside corners",
            "en-us": "Panels with outside corners",
            "es": "Elem. con esqu. ext.",
            "fr": "Px avec angle ext",
            "it": "Elementi con angolo esterno",
            "hu": "Elem külso sarokkal",
            "ru": "Элементи с внешними углами",
            "sk": "Panely s vonkaj. roh",
            "pl": "Płyty z narożnikami zewnętrznymi"
        },
        {
            "ID": "XREFORMA_A6",
            "de": "Aufstockungen",
            "en-gb": "Stackings",
            "en-us": "Stackings",
            "es": "Apilado",
            "fr": "Rehausse",
            "it": "Sovrapposizioni",
            "hu": "Magasítások",
            "ru": "Надстройки",
            "sk": "Nadstavieb",
            "pl": "Nadstawienia"
        },
        {
            "ID": "XREFORMA_A6_AW1",
            "de": "ohne Aufstockung",
            "en-gb": "w/o stacking",
            "en-us": "w/o stacking",
            "es": "Sin apilado",
            "fr": "sans rehausse",
            "it": "senza sopralzo",
            "hu": "Magasítás nélkül",
            "ru": "без надстройки",
            "sk": "bez nadstavenia",
            "pl": "bez nadstawienia"
        },
        {
            "ID": "XREFORMA_A6_AW2",
            "de": "Liegend aufgestockt",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz.",
            "es": "Apilado horizontal",
            "fr": "rehaussé couché",
            "it": "Sovrapposizione orizz.",
            "hu": "fektetve magasítva",
            "ru": "надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "pl": "nadstawienie poziome"
        },
        {
            "ID": "XREFORMA_A6_AW3",
            "de": "1,20m liegend + liegend aufgestockt",
            "en-gb": "1.20m horizontal + stacked horiz.",
            "en-us": "1.20m horizontal + stacked horiz.",
            "es": "1,20m horizontal + apilado horiz.",
            "fr": "1,20m couché + rehaussé couché",
            "it": "1,20m orizz. + sovrapposizione orizz.",
            "hu": "1,20m fektetve + fektetve magasítva",
            "ru": "1,20м горизонт. + надстройка в горизонт. полож.",
            "sk": "1,20m naležato + nadstavenie naležato",
            "pl": "1,20m poziomo + nadstawienie poziome"
        },
        {
            "ID": "XREFORMA_A6_AW4",
            "de": "1,35m liegend + liegend aufgestockt",
            "en-gb": "1.35m horizontal + stacked horiz.",
            "en-us": "1.35m horizontal + stacked horiz.",
            "es": "1,35m horizontal + apilado horiz.",
            "fr": "1,35m couché + rehaussé couché",
            "it": "1,35m orizz. + sovrapposizione orizz.",
            "hu": "1,35m fektetve + fektetve magasítva",
            "ru": "1,35м горизонт. + надстройка в горизонт. полож.",
            "sk": "1,35m naležato + nadstavenie naležato",
            "pl": "1,35m poziomo + nadstawienie poziome"
        },
        {
            "ID": "XREFORMA_A6_AW5",
            "de": "Stehend aufgestockt",
            "en-gb": "stacked upright",
            "en-us": "stacked upright",
            "es": "apilado vertical",
            "fr": "Rehaussé deboutant",
            "it": "Sovrapposizione vert.",
            "hu": "állítva magasítva",
            "ru": "надстройка в вертикальном положении",
            "sk": "nadstavenie nastojato",
            "pl": "nadstawienie pionowe"
        },
        {
            "ID": "XREFORMA_A6_AW6",
            "de": "Stehend und liegend aufgestockt",
            "en-gb": "Stacked upright and horiz.",
            "en-us": "Stacked upright and horiz.",
            "es": "Elementos verticales apilados vert. y horiz.",
            "fr": "Px debouts rehaussés deboutants et couchants",
            "it": "Elementi vert. sovrapposizione vert. e orizz.",
            "hu": "Elemek állítva állítva és fektetve magasítva",
            "ru": "Вертикальные элементы, надстройка в вертикальном и горизонтальном положении",
            "sk": "Panely nastojato, nadst. nastojato a naležato",
            "pl": "Nadstawienie pionowe i poziome"
        },
        {
            "ID": "XREFORMA_A7",
            "de": "Elementtyp",
            "en-gb": "Panel type",
            "en-us": "Panel type",
            "es": "Tipo de elemento",
            "fr": "Type d'élément",
            "it": "Tipo elemento",
            "hu": "Elemtípus",
            "ru": "Тип элемента",
            "sk": "Typ panelu",
            "pl": "Typ płyty"
        },
        {
            "ID": "XREFORMA_A7_AW1",
            "de": "Normalelement",
            "en-gb": "Standard panel",
            "en-us": "Standard panel",
            "es": "Elemento normal",
            "fr": "Élément étalon",
            "it": "Elemento normale",
            "hu": "Normál tábla",
            "ru": "Унифицированный элемент",
            "sk": "Štandardný panel",
            "pl": "Płyta standardowa"
        },
        {
            "ID": "XREFORMA_A7_AW2",
            "de": "Uni-Element",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elemento universal",
            "fr": "Panneau universel",
            "it": "Elemento universale",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Univerzálne panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XREFORMA_A7_AW3",
            "de": "Großelement",
            "en-gb": "Large panel",
            "en-us": "Large panel",
            "es": "Elemento largo",
            "fr": "Grande élément",
            "it": "Elemento grande",
            "hu": "Nagytábla",
            "ru": "Крупноразмерный элемент",
            "sk": "Velkoplošný panel",
            "pl": "Płyta duża"
        },
        {
            "ID": "XREFORMA_A8",
            "de": "1,35m Elementbreite",
            "en-gb": "1.35m panel width",
            "en-us": "1.35m panel width",
            "es": "1,35m Ancho de elemento",
            "fr": "1,35m largeur de panneau",
            "it": "1,35m largh. Elementi",
            "hu": "1,35m elemszélesség",
            "ru": "1,35м ширина элемента",
            "sk": "1,35m šírka panela",
            "pl": "szerokość płyty 1,35m"
        },
        {
            "ID": "XREFORMA_A8_AW1",
            "de": "Nein",
            "en-gb": "No",
            "en-us": "No",
            "es": "No",
            "fr": "Non",
            "it": "No",
            "hu": "Nem",
            "ru": "Нет",
            "sk": "Nie",
            "pl": "Nie"
        },
        {
            "ID": "XREFORMA_A8_AW2",
            "de": "Ja",
            "en-gb": "Yes",
            "en-us": "Yes",
            "es": "Sí",
            "fr": "Oui",
            "it": "Sì",
            "hu": "Igen",
            "ru": "Да",
            "sk": "Áno",
            "pl": "Tak"
        },
        {
            "ID": "XREFORMA_A9",
            "de": "Stirnabschalung",
            "en-gb": "Stop-end formwork",
            "en-us": "Bulkhead",
            "es": "Tape",
            "fr": "Coffrage d'about",
            "it": "Chiusura di testa",
            "hu": "Véglezárás",
            "ru": "Торцевые участки опалубки",
            "sk": "Debnenie cela",
            "pl": "Szalunek czołowy"
        },
        {
            "ID": "XREFORMA_A9_AW1",
            "de": "Uni-Element",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elemento universal",
            "fr": "Panneau universel",
            "it": "Elemento universale",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Univerzálne panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XREFORMA_A9_AW2",
            "de": "Klemmschiene mit Univerbinder",
            "en-gb": "Universal waling with universal fixing bolt",
            "en-us": "Universal waling with universal fixing bolt",
            "es": "Riel de fijación con conector univ.",
            "fr": "Rail de blocage avec boulon d'assemblage univ",
            "it": "Rotaia di fissaggio con tirante assemb. univ.",
            "hu": "Kötősín univerzális kötőcsavarral",
            "ru": "Зажимная шина c универсальной соединителью",
            "sk": "Upínacia koľajnica s univerzálnou spojkou",
            "pl": "Uniwersalny rygiel ścienny z łącznikiem uniwersalnym"
        },
        {
            "ID": "XREFORMA_A9_AW3",
            "de": "Klemmschiene mit Stirnanker",
            "en-gb": "Universal waling with stop-end tie",
            "en-us": "Universal waling with stop-end tie",
            "es": "Riel de fijación con anclaje de tape",
            "fr": "Rail de blocage avec ancrage d'about",
            "it": "Rotaia di fissaggio con morsetto per testata",
            "hu": "Kötősín homlokankerrel",
            "ru": "Зажимная шина с торцевым анкером",
            "sk": "Upín.koľajnica, čelová kotva",
            "pl": "Uniwersalny rygiel ścienny ze ściągiem czołowym"
        },
        {
            "ID": "XSAFEPLU_A11",
            "de": "Bauwerksstütze",
            "en-gb": "Column",
            "en-us": "Column",
            "es": "Pilar",
            "fr": "Poteau",
            "it": "Pilastro",
            "hu": "Pillér",
            "ru": "Колонна",
            "sk": "Stĺp",
            "pl": "Słup"
        },
        {
            "ID": "XSAFEPLU_A11_AW1",
            "de": "Uni-Element 0,90 mit Stützenbühne",
            "en-gb": "Universal panel 0.90 with column formwork platform",
            "en-us": "Universal panel 0.90 with supporting plattform",
            "es": "Elem. univ. 0.90 con plataforma encofrado pilares",
            "fr": "Px univ. 0,90 avec coffrage poteau",
            "it": "Elemento uni 0,90 con passerella di pilastri",
            "hu": "Uni elem 0,90 pillérállvány",
            "ru": "Универсальный элемент 0,90 с подмостями для колонн",
            "sk": "Uni. panel 0,90 so stĺp. ploš.",
            "pl": "Płyta uniwersalna 0,90 z pomostem do słupa"
        },
        {
            "ID": "XSAFEPLU_A11_AW2",
            "de": "Elemente mit Außenecken",
            "en-gb": "Panels with outside corners",
            "en-us": "Panels with outside corners",
            "es": "Elem. con esqu. ext.",
            "fr": "Px avec angle ext",
            "it": "Elementi con angolo esterno",
            "hu": "Elem külso sarokkal",
            "ru": "Элементы с внешними углами",
            "sk": "Panely s vonkaj. roh",
            "pl": "Płyty z narożnikami zewnętrznymi"
        },
        {
            "ID": "XSAFEPLU_A11_AW3",
            "de": "Uni-Element 1,20 mit Stützenbühne",
            "en-gb": "Universal panel 1.20 with column formwork platform",
            "en-us": "Universal panel 1.20 with supporting plattform",
            "es": "Elem. univ. 1.20 con plataforma encofrado pilares",
            "fr": "Px univ. 1,20 avec coffrage poteau",
            "it": "Elemento uni 1,20 con passerella di pilastri",
            "hu": "Uni elem 1,20 pillérállvány",
            "ru": "Универсальный элемент 1,20 с подмостями для колонн",
            "sk": "Uni. panel 1,20 so stĺp. ploš.",
            "pl": "Płyta uniwersalna 1,20 z pomostem do słupa"
        },
        {
            "ID": "XSAFEPLU_A11_AW4",
            "de": "Uni-Element 0,90 mit Konsole",
            "en-gb": "Universal panel 0.90 with bracket",
            "en-us": "Universal panel 0.90 with bracket.",
            "es": "Elem. univ. 0,90 con ménsula",
            "fr": "Px univ. 0,90 avec console",
            "it": "Elemento uni 0,90 con mensola",
            "hu": "Uni elem 0,90 betonozó konzollal",
            "ru": "Универсальный элемент 0,90 с консолями",
            "sk": "Uni. panel 0,90 s konzolou",
            "pl": "Płyta uniwersalna 0,90 z konsolami"
        },
        {
            "ID": "XSAFEPLU_A11_AW5",
            "de": "Uni-Element 1,20 mit Konsole",
            "en-gb": "Universal panel 1.20 with bracket",
            "en-us": "Universal panel 1.20 with bracket",
            "es": "Elem. univ. 1,20 con ménsula",
            "fr": "Px univ. 1,20 avec console",
            "it": "Elemento uni 1,20 con mensola",
            "hu": "Uni elem 1,20 betonozó konzollal",
            "ru": "Универсальный элемент 1,20 с консолями",
            "sk": "Uni. panel 1,20 s konzolou",
            "pl": "Płyta uniwersalna 1,20 z konsolami"
        },
        {
            "ID": "XSAFEPLU_A12",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "XSAFEPLU_A12_AW1",
            "de": "Ankerstab 15,0 unbehandelt",
            "en-gb": "Tie rod 15.0 non-treated",
            "en-us": "Tie rod 15.0 non-treated",
            "es": "Barra de ancl. 15,0 no tratada",
            "fr": "Tige d'ancrage 15,0 non traitée",
            "it": "Barra ancorante 15,0 non trattata",
            "hu": "Ankerrúd 15,0 kezeletlen",
            "ru": "Анкерный стержень 15,0 необработанный",
            "sk": "Kotev. tyc 15,0 neupravov.",
            "pl": "Ściąg 15,0 nieocynkowany"
        },
        {
            "ID": "XSAFEPLU_A12_AW2",
            "de": "Ankerstab 15,0 verzinkt",
            "en-gb": "Tie rod 15.0 galvanised",
            "en-us": "Tie rod 15.0 galvanized",
            "es": "Barra de ancl. 15,0 galv.",
            "fr": "Tige d'ancrage 15,0 galvanisée",
            "it": "Barra ancorante 15,0 zincata",
            "hu": "Ankerrúd 15,0 horganyzott",
            "ru": "Анкерный стержень 15,0 оцинкованный",
            "sk": "Kotev. tyc 15,0 pozinkov.",
            "pl": "Ściąg 15,0 ocynkowany"
        },
        {
            "ID": "XSAFEPLU_A12_AW3",
            "de": "Ankerstab 20,0 unbehandelt",
            "en-gb": "Tie rod 20.0 non-treated",
            "en-us": "Tie rod 20.0 non-treated",
            "es": "Barra de ancl. 20,0 no tratada",
            "fr": "Tige d'ancrage 20,0 non traitée",
            "it": "Barra ancorante 20,0 non trattata",
            "hu": "Ankerrúd 20,0 kezeletlen",
            "ru": "Анкерный стержень 20,0 необработанный",
            "sk": "Kotev. tyč 20,0 neupravovaná",
            "pl": "Ściąg 20,0 nieocynkowany"
        },
        {
            "ID": "XSAFEPLU_A12_AW4",
            "de": "Ankerstab 20,0 verzinkt",
            "en-gb": "Tie rod 20.0 galvanised",
            "en-us": "Tie rod 20.0 galvanized",
            "es": "Barra de ancl. 20,0 galv.",
            "fr": "Tige d'ancrage 20,0 galvanisée",
            "it": "Barra ancorante 20,0 zincata",
            "hu": "Ankerrúd 20,0 horganyzott",
            "ru": "Анкерный стержень 20,0 оцинкованный",
            "sk": "Kotev. tyc 20,0 pozinkov.",
            "pl": "Ściąg 20,0 ocynkowany"
        },
        {
            "ID": "XSAFEPLU_A13",
            "de": "Einseitige Ecke und Kreuzung",
            "en-gb": "One-sided corner and crossing",
            "en-us": "One-sided corner and crossing",
            "es": "Esquina de un solo lado y cruce",
            "fr": "Une face angle et croisement",
            "it": "Angolo unilaterale e incrocio",
            "hu": "Egyoldali sarok és keresztcsatlakozás",
            "ru": "Односторонний угол и крестообразное соединение",
            "sk": "Jednostranný roh a križovanie",
            "pl": "Narożnik jednostronny i połączenie krzyżowe"
        },
        {
            "ID": "XSAFEPLU_A13_AW1",
            "de": "Ausschalecke",
            "en-gb": "Stripping corner",
            "en-us": "Bias cut corner",
            "es": "Esqu. de desencofrado",
            "fr": "Angle de décoffrage",
            "it": "Angolo di disarmo",
            "hu": "Kizsaluzó sarokelem",
            "ru": "Распалубочный уголок",
            "sk": "Oddebnovací roh",
            "pl": "Narożnik rozszalowujący"
        },
        {
            "ID": "XSAFEPLU_A13_AW2",
            "de": "Außen- oder Innenecke",
            "en-gb": "Outside or inside corner",
            "en-us": "Outside or inside corner",
            "es": "Esqu. ext. o esqu. int.",
            "fr": "Angle ext. et int.",
            "it": "Angolo est. o int.",
            "hu": "Külso vagy Belso sarok",
            "ru": "Внешний или внутренний угол",
            "sk": "Vonk. roh al. vnút. roh",
            "pl": "Narożnik zewnętrzny lub wewnętrzny"
        },
        {
            "ID": "XSAFEPLU_A13_AW3",
            "de": "Scharnier-Außen- oder Innenecke",
            "en-gb": "Hinged outside or hinged inside corner",
            "en-us": "Hinged outside or hinged inside corner",
            "es": "Esqu. bisagra ext. o esqu. bisagra int.",
            "fr": "Angle charnière int et angle charnière ext",
            "it": "Ang. cerniera est. o Angolo interno",
            "hu": "Csukl.külso sarok vagy Belso sarok",
            "ru": "Шарнирный внешний или внутренний угол",
            "sk": "Klb.vonk.roh al. vnút. roh",
            "pl": "Narożnik zewnętrzny zawiasowy lub narożnik wewnętrzny zawiasowy"
        },
        {
            "ID": "XSAFEPLU_A13_AW4",
            "de": "Scharnier-Außen- oder Innenecke verz.",
            "en-gb": "Hinged outside or hinged inside corner galv.",
            "en-us": "Hinged outside or hinged inside corner galv.",
            "es": "Esqu. bisagra ext. o esqu. int.  galv.",
            "fr": "Angle charnière ext ou angle int  galv.",
            "it": "Ang. cerniera est. o Angolo interno  galv.",
            "hu": "Csukl.külso sarok vagy Belso sarok horg.",
            "ru": "Шарнирный внешний или внутренний угол оцинк.",
            "sk": "Klb.vonk.roh al. vnút. roh poz.",
            "pl": "Narożnik zewnętrzny zawiasowy lub narożnik wewnętrzny zawiasowy ocynk."
        },
        {
            "ID": "XSAFEPLU_A15",
            "de": "Längsanschluss",
            "en-gb": "Longitudinal connection",
            "en-us": "Longitudinal connection",
            "es": "Solape longitudinal",
            "fr": "Reprise sur la longueur",
            "it": "Sovrapposizione longitudinale",
            "hu": "Hosszanti csatlakozás",
            "ru": "Продольный стык",
            "sk": "Pozdlžne napojenie",
            "pl": "Połączenie wzdłużne"
        },
        {
            "ID": "XSAFEPLU_A15_AW1",
            "de": "Universalelement",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Px univ.",
            "it": "Elemento uni",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Uni. panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XSAFEPLU_A15_AW2",
            "de": "Normalelement",
            "en-gb": "Standard panel",
            "en-us": "Standard panel",
            "es": "Elemento normal",
            "fr": "Élément étalon",
            "it": "Elemento normale",
            "hu": "Normál tábla",
            "ru": "Унифицированный элемент",
            "sk": "Štandardný panel",
            "pl": "Płyta standardowa"
        },
        {
            "ID": "XSAFEPLU_A15_AW3",
            "de": "Großelement",
            "en-gb": "Large panel",
            "en-us": "Large panel",
            "es": "Elemento largo",
            "fr": "Grande élément",
            "it": "Elemento grande",
            "hu": "Nagytábla",
            "ru": "Крупноразмерный элемент",
            "sk": "Velkoplošný panel",
            "pl": "Płyta duża"
        },
        {
            "ID": "XSAFEPLU_A16",
            "de": "Ausgleichstyp",
            "en-gb": "Closure type",
            "en-us": "Filler type",
            "es": "Tipo de compensasión",
            "fr": "Type de compensation",
            "it": "Tipo di compensazione",
            "hu": "Kiegyenlítés típusa",
            "ru": "Тип компенсации",
            "sk": "Typ vyrovnania",
            "pl": "Rodzaj wypełnienia"
        },
        {
            "ID": "XSAFEPLU_A16_AW1",
            "de": "Passholz Doka, Ankerung im Element",
            "en-gb": "Fitting timber Doka, ties through panel",
            "en-us": "Fitting timber Doka, ties through panel",
            "es": "Tablón de ajuste Doka, anclaje en el elemento",
            "fr": "Fourrure en bois Doka, ancrage dans le panneau",
            "it": "Tavola di compensazione Doka, anc. nell'elemento",
            "hu": "Illeszto fabetét Doka, ankerozás az elemben",
            "ru": "Пригнанный брус Doka, анкерование в элементе",
            "sk": "Vyrovnávací hranol Doka, kotvenie v paneli",
            "pl": "Wstawka komp. Doka, ściagi przez płytę"
        },
        {
            "ID": "XSAFEPLU_A16_AW10",
            "de": "mit Schalungsplatte 21mm und Klemmschiene 0,90m",
            "en-gb": "with formwork sheet 21mm and universal waling 0.90m",
            "en-us": "with formwork sheet 21mm and universal waling 0.90m",
            "es": "con tablero de encofrado 21mm y riel de fijación 0,90m",
            "fr": "avec contreplaqué 21mm et rails de blocage 0,90m",
            "it": "con pannello 21mm e rotaia di fissaggio 0,90m",
            "hu": "21mm-es zsaluhéjjal és 0,90m-es kötősínnel",
            "ru": "с опалубочная плита 21мм и зажимная шина 0,90м",
            "sk": "s debniacou doskou 21mm a upínacou koľajnicou 0,90m",
            "pl": "ze sklejką 21mm i uniwersalnym ryglem ściennym 0,90m"
        },
        {
            "ID": "XSAFEPLU_A16_AW11",
            "de": "mit Schalungsplatte 21mm und Klemmschiene 1,50m",
            "en-gb": "with formwork sheet 21mm and universal waling 1.50m",
            "en-us": "with formwork sheet 21mm and universal waling 1.50m",
            "es": "con tablero de encofrado 21mm y riel de fijación 1,50m",
            "fr": "avec contreplaqué 21mm et rails de blocage 1,50m",
            "it": "con pannello 21mm e rotaia di fissaggio 1,50m",
            "hu": "21mm-es zsaluhéjjal és 1,50m-es kötősínnel",
            "ru": "с опалубочная плита 21мм и зажимная шина 1,50м",
            "sk": "s debniacou doskou 21mm a upínacou koľajnicou 1,50m",
            "pl": "ze sklejką 21mm i uniwersalnym ryglem ściennym 1,50m"
        },
        {
            "ID": "XSAFEPLU_A16_AW2",
            "de": "Füllkörper bauseits, Ankerung im Element",
            "en-gb": "Filler block by site, ties through panel",
            "en-us": "Filler block by site, ties through panel",
            "es": "Cuerpo de relleno (por parte de obra), anclaje en el elemento",
            "fr": "Corps de remplissage (chantier), ancrage dans le panneau",
            "it": "Compensazione (a carico cant.), anc. nell'elemento",
            "hu": "Kiegészítő betét építéshelyi, ankerozás az elemben",
            "ru": "Вкладыш (силами заказчика), анкерование в элементе",
            "sk": "Výplň (zo strany stavby), kotvenie v paneli",
            "pl": "Wstawka po str. budowy, ściagi przez płytę"
        },
        {
            "ID": "XSAFEPLU_A16_AW4",
            "de": "mit Schalungsplatte 27mm und Klemmschiene 0,90m",
            "en-gb": "with formwork sheet 27mm and universal waling 0.90m",
            "en-us": "with formwork sheet 27mm and universal waling 0.90m",
            "es": "con tablero de encofrado 27mm y riel de fijación 0,90m",
            "fr": "avec contreplaqué 27mm et rail de blocage 0,90m",
            "it": "con pannello 27mm e rotaia di fissaggio 0,90m",
            "hu": "27mm-es zsaluhéjjal és 0,90m-es kötősínnel",
            "ru": "с опалубочная плита 27мм и зажимная шина 0,90м",
            "sk": "s debniacou doskou 27mm a upínacou koľajnicou 0,90m",
            "pl": "ze sklejką 27mm i uniwersalnym ryglem ściennym 0,90m"
        },
        {
            "ID": "XSAFEPLU_A16_AW5",
            "de": "mit Schalungsplatte 27mm und Klemmschiene 1,50m",
            "en-gb": "with formwork sheet 27mm and universal waling 1.50m",
            "en-us": "with formwork sheet 27mm and universal waling 1.50m",
            "es": "con tablero de encofrado 27mm y riel de fijación 1,50m",
            "fr": "avec contreplaqué 27mm et rail de blocage 1,50m",
            "it": "con pannello 27mm e rotaia di fissaggio 1,50m",
            "hu": "27mm-es zsaluhéjjal és 1,50m-es kötősínnel",
            "ru": "с опалубочная плита 27мм и зажимная шина 1,50м",
            "sk": "s debniacou doskou 27mm a upínacou koľajnicou 1,50m",
            "pl": "ze sklejką 27mm i uniwersalnym ryglem ściennym 1,50m"
        },
        {
            "ID": "XSAFEPLU_A16_AW6",
            "de": "Passholz Doka, Ankerung im Ausgleich",
            "en-gb": "Fitting timber Doka, ties through closure",
            "en-us": "Fitting timber Doka, ties through filler",
            "es": "Tablón de ajuste Doka, anclaje en compensación",
            "fr": "Fourrure en bois Doka, ancrage dans le compens.",
            "it": "Tavola di compensazione Doka, anc. nella compens.",
            "hu": "Illeszto fabetét Doka, ankerozás a kiegyenlítésen keresztül",
            "ru": "Пригнанный брус Doka, анкерование в компенсатор",
            "sk": "Vyrovnávací hranol Doka, kotvenie vo vyrovnaní",
            "pl": "Wstawka komp. Doka, ściagi przez wstawkę"
        },
        {
            "ID": "XSAFEPLU_A16_AW7",
            "de": "Füllkörper bauseits, Ankerung im Ausgleich",
            "en-gb": "Filler block by site, ties through closure",
            "en-us": "Filler block by site, ties through filler",
            "es": "Cuerpo de relleno (por parte de obra), anclaje en compensación",
            "fr": "Corps de remplissage (chantier), ancrage dans le panneau",
            "it": "Compensazione (a carico cant.), anc. nella compens.",
            "hu": "Kiegészítő betét építéshelyi, ankerozás a kiegyenlítésen keresztül",
            "ru": "Вкладыш (силами заказчика), анкерование в компенсатор",
            "sk": "Výplň (zo strany stavby), kotvenie vo vyrovnaní",
            "pl": "Wstawka po str. budowy, ściagi przez wstawkę"
        },
        {
            "ID": "XSAFEPLU_A16_AW8",
            "de": "Alu-Ausgleich, Ankerung im Ausgleich",
            "en-gb": "Alu-filler, ties through closure",
            "en-us": "Alu-filler, ties through filler",
            "es": "Compensación de alu, anclaje en compensación",
            "fr": "Pièce de compensation alu, ancrage dans le compens.",
            "it": "Compensazioni in alu, anc. nella compens.",
            "hu": "Alu-betét, ankerozás a kiegyenlítésen keresztül",
            "ru": "Alu-компенсатор, анкерование в компенсатор",
            "sk": "Alu-vyrovnanie, kotvenie vo vyrovnaní",
            "pl": "Wstawka aluminiowa, ściagi przez wstawkę"
        },
        {
            "ID": "XSAFEPLU_A16_AW9",
            "de": "Alu-Ausgleich, Ankerung im Element",
            "en-gb": "Alu-filler, ties through panel",
            "en-us": "Alu-filler, ties through panel",
            "es": "Compensación de alu, anclaje en el elemento",
            "fr": "Pièce de compensation alu, ancrage dans le panneau",
            "it": "Compensazioni in alu, anc. nell'elemento",
            "hu": "Alu-betét, ankerozás az elemben",
            "ru": "Alu-компенсатор, анкерование в элементе",
            "sk": "Alu-vyrovnanie, kotvenie v paneli",
            "pl": "Wstawka aluminiowa, ściagi przez płytę"
        },
        {
            "ID": "XSAFEPLU_A17",
            "de": "Anzahl Bühnenebenen",
            "en-gb": "Number of platform levels",
            "en-us": "Number of platform levels",
            "es": "Número de niveles de plataforma",
            "fr": "Nombre plateformes",
            "it": "Numero di livelli della piattaforma",
            "hu": "Munkaszintek száma",
            "ru": "Количество уровней подмостей",
            "sk": "Počet úrovní plošín",
            "pl": "Liczba poziomów pomostu"
        },
        {
            "ID": "XSAFEPLU_A17_AW1",
            "de": "Maximal",
            "en-gb": "Maximal",
            "en-us": "Maximal",
            "es": "Máximo",
            "fr": "Max.",
            "it": "Massimale",
            "hu": "Maximális",
            "ru": "Максимальный",
            "sk": "Maximálne",
            "pl": "Maksimum"
        },
        {
            "ID": "XSAFEPLU_A17_AW2",
            "de": "Minimal",
            "en-gb": "Minimal",
            "en-us": "Minimal",
            "es": "Mínimo",
            "fr": "Min.",
            "it": "Minimale",
            "hu": "Minimális",
            "ru": "Минимальный",
            "sk": "Minimálne",
            "pl": "Minimum"
        },
        {
            "ID": "XSAFEPLU_A4",
            "de": "Elementtyp",
            "en-gb": "Panel type",
            "en-us": "Panel type",
            "es": "Tipo de elemento",
            "fr": "Type d'elément",
            "it": "Tipo elemento",
            "hu": "Elemtípus",
            "ru": "Тип элемента",
            "sk": "Typ panelu",
            "pl": "Typ płyty"
        },
        {
            "ID": "XSAFEPLU_A4_AW1",
            "de": "Element mit Bühne (1.35 breit) mit Leiter",
            "en-gb": "Panel with plattform (1.35 wide) with ladder",
            "en-us": "Panel with plattform (1.35 wide) with ladder",
            "es": "Elemento con plataforma (1.35 ancho) con escalera",
            "fr": "Px avec passerelle (1.35 large) avec échelle",
            "it": "Elemento con passerella (1.35 largo) con scala",
            "hu": "Elemek állvány (1,35 szélesség) létra",
            "ru": "Элемент с подмостями (ширина 1.35) с лестницей",
            "sk": "Panel s plošina (1,35 šírky) so rebrikom",
            "pl": "Płyta z pomostem (szerokość 1,35) z drabiną"
        },
        {
            "ID": "XSAFEPLU_A4_AW2",
            "de": "Normalelement (1.35,0.90,0.60,0.55,0.45,0.30 breit)",
            "en-gb": "Standard panel (1.35,0.90,0.60,0.55,0.45,0.30 wide)",
            "en-us": "Standard panel (1.35,0.90,0.60,0.55,0.45,0.30 wide)",
            "es": "Elemento normal (1.35,0.90,0.60,0.55,0.45,0.30 ancho)",
            "fr": "Élément étalon (1.35,0.90,0.60,0.55,0.45,0.30 large)",
            "it": "Elemento normale (1.35,0.90,0.60,0.55,0.45,0.30 largo)",
            "hu": "Normál tábla (1.35,0.90,0.60,0.55,0.45,0.30 szélesség)",
            "ru": "Унифицированный элемент (ширина 1.35,0.90,0.60,0.55,0.45,0.30)",
            "sk": "Štandardný panel (1.35,0.90,0.60,0.55,0.45,0.30 šírky)",
            "pl": "Płyta standardowa (szerokość 1.35,0.90,0.60,0.55,0.45,0.30)"
        },
        {
            "ID": "XSAFEPLU_A4_AW3",
            "de": "Uni-Element (0.90,0.75 breit)",
            "en-gb": "Universal panel (0.90,0.75 wide)",
            "en-us": "Universal panel (0.90,0.75 wide)",
            "es": "Elem. univ. (0.90,0.75 ancho)",
            "fr": "Panneau universel (0.90,0.75 large)",
            "it": "Elemento uni (0.90,0.75 largo)",
            "hu": "Uni elem (0.90,0.75 szélesség)",
            "ru": "Универсальный элемент (ширина 0.90,0.75)",
            "sk": "Uni. panel (0.90,0.75 šírky)",
            "pl": "Płyta uniwersalna (szerokość 0,90,0,75)"
        },
        {
            "ID": "XSAFEPLU_A4_AW4",
            "de": "Element mit Bühne (2.70 breit) mit Leiter",
            "en-gb": "Panel with plattform (2.70 wide) with ladder",
            "en-us": "Panel with plattform (2.70 wide) with ladder",
            "es": "Elemento con plataforma (2.70 ancho) con escalera",
            "fr": "Px avec passerelle (2.70 large) avec échelle",
            "it": "Elemento con passerella (2.70 largo) con scala",
            "hu": "Elemek állvány (2,70 szélesség) létra",
            "ru": "Элемент с подмостями (ширина 2.70) с лестницей",
            "sk": "Panel s plošina (2,70 šírky) so rebrikom",
            "pl": "Płyta z pomostem (szerokość 2,7) z drabiną"
        },
        {
            "ID": "XSAFEPLU_A4_AW5",
            "de": "Element mit Bühne (1.35 breit) ohne Leiter",
            "en-gb": "Panel with plattform (1.35 wide) without ladder",
            "en-us": "Panel with plattform (1.35 wide) without ladder",
            "es": "Elemento con plataforma (1.35 ancho) sin escalera",
            "fr": "Px avec passerelle (1.35 large) sans échelle",
            "it": "Elemento con passerella (1.35 largo) senza scala",
            "hu": "Elemek állvány (1,35 szélesség) létra nélkül",
            "ru": "Элемент с подмостями (ширина 1.35) без лестницы",
            "sk": "Panel s plošina (1,35 šírky) bez rebrika",
            "pl": "Płyta z pomostem (szerokość 1,35) bez drabiny"
        },
        {
            "ID": "XSAFEPLU_A4_AW6",
            "de": "Element mit Bühne (2.70 breit) ohne Leiter",
            "en-gb": "Panel with plattform (2.70 wide) without ladder",
            "en-us": "Panel with plattform (2.70 wide) without ladder",
            "es": "Elemento con plataforma (2.70 ancho) sin escalera",
            "fr": "Px avec passerelle (2.70 large) sans échelle",
            "it": "Elemento con passerella (2.70 largo) senza scala",
            "hu": "Elemek állvány (2,70 szélesség) létra nélkül",
            "ru": "Элемент с подмостями (ширина 2.70) без лестницы",
            "sk": "Panel s plošina (2,70 šírky) bez rebrika",
            "pl": "Płyta z pomostem (szerokość 2,7) bez drabiny"
        },
        {
            "ID": "XSAFEPLU_A5",
            "de": "Basiselement",
            "en-gb": "Basic panel",
            "en-us": "Basic panel",
            "es": "Altura base",
            "fr": "Élément de base",
            "it": "Elemento di base",
            "hu": "Báziselem",
            "ru": "Базовый элемент",
            "sk": "Základný panel",
            "pl": "Płyta podstawowa"
        },
        {
            "ID": "XSAFEPLU_A5_AW1",
            "de": "2,70 bevorzugt",
            "en-gb": "2.70 preferred",
            "en-us": "2.70 preferred",
            "es": "2.70 preferido",
            "fr": "2.70 préféré",
            "it": "2.70 preferito",
            "hu": "2,70 preferált",
            "ru": "предпочтительно 2.70",
            "sk": "2,70 uprednostnené",
            "pl": "2,70 preferowany"
        },
        {
            "ID": "XSAFEPLU_A5_AW2",
            "de": "3.30 bevorzugt",
            "en-gb": "3.30 preferred",
            "en-us": "3.30 preferred",
            "es": "3.30 preferido",
            "fr": "3.30 préféré",
            "it": "3.30 preferito",
            "hu": "3,30 preferált",
            "ru": "предпочтительно 3.30",
            "sk": "3,30 uprednostnené",
            "pl": "3,30 preferowany"
        },
        {
            "ID": "XSAFEPLU_A5_AW3",
            "de": "2,40x2,70 bevorzugt",
            "en-gb": "2.40x2.70 preferred",
            "en-us": "2.40x2.70 preferred",
            "es": "2.40x2.70 preferido",
            "fr": "2.40x2.70 préféré",
            "it": "2.40x2.70 preferito",
            "hu": "2,40x2,70 preferált",
            "ru": "предпочтительно 2.40x2.70",
            "sk": "2,40x2,70 uprednostnené",
            "pl": "2,40x2,70 preferowany"
        },
        {
            "ID": "XSAFEPLU_A5_AW4",
            "de": "2,70x2,70 bevorzugt",
            "en-gb": "2.70x2.70 preferred",
            "en-us": "2.70x2.70 preferred",
            "es": "2.70x2.70 preferido",
            "fr": "2.70x2.70 préféré",
            "it": "2.70x2.70 preferito",
            "hu": "2,70x2,70 preferált",
            "ru": "предпочтительно 2.70x2.70",
            "sk": "2,70x2,70 uprednostnené",
            "pl": "2,70x2,70 preferowany"
        },
        {
            "ID": "XSAFEPLU_A5_AW5",
            "de": "2,70x3,30 bevorzugt",
            "en-gb": "2.70x3.30 preferred",
            "en-us": "2.70x3.30 preferred",
            "es": "2.70x3.30 preferido",
            "fr": "2.70x3.30 préféré",
            "it": "2.70x3.30 preferito",
            "hu": "2,70x3,30 preferált",
            "ru": "предпочтительно 3.30x2.70",
            "sk": "3,30x2,70 uprednostnené",
            "pl": "2,70x3,30 preferowany"
        },
        {
            "ID": "XSAFEPLU_A6",
            "de": "Stirnabschalung",
            "en-gb": "Stop-end-formwork",
            "en-us": "Bulkhead",
            "es": "Tape",
            "fr": "Coffrage d'about",
            "it": "Chiusura di testa",
            "hu": "Véglezárás",
            "ru": "Торцевые участки опалубки",
            "sk": "Debnenie cela",
            "pl": "Szalunek czołowy"
        },
        {
            "ID": "XSAFEPLU_A6_AW1",
            "de": "Stirnanker ohne Element",
            "en-gb": "Stop-end tie without panel",
            "en-us": "Stop-end tie without panel",
            "es": "Anclaje de tape sin elemento",
            "fr": "Ancrage d'about sans px",
            "it": "Morsetto per testata senza elemento",
            "hu": "Univerzális kötocsavar elem nélkül",
            "ru": "Торцевой анкер без элемента",
            "sk": "Celová kotva bez panel",
            "pl": "Deskowanie czołowe bez płyty"
        },
        {
            "ID": "XSAFEPLU_A6_AW10",
            "de": "Stirnanker mit Element",
            "en-gb": "Stop-end tie with panel",
            "en-us": "Stop-end tie with panel",
            "es": "Anclaje de tape con elemento",
            "fr": "Ancrage d'about avec px",
            "it": "Morsetto per testata con elemento",
            "hu": "Univerzális kötocsavar elem",
            "ru": "Торцевой анкер с элементом",
            "sk": "Celová kotva so panel",
            "pl": "Deskowanie czołowe z płytą"
        },
        {
            "ID": "XSAFEPLU_A6_AW11",
            "de": "Universalelement (1.20 breit)",
            "en-gb": "Universal panel (1.20 wide)",
            "en-us": "universal panel (1.20 wide)",
            "es": "Elem. univ. (1,20 ancho)",
            "fr": "Px univ. (1.20 wide)",
            "it": "Elemento uni (1.20 largo)",
            "hu": "Uni elem (1,20 szélesség)",
            "ru": "Универсальный элемент (ширина 1,20)",
            "sk": "Uni. panel (1.20 šírky)",
            "pl": "Universal panel (szerokość 1,20)"
        },
        {
            "ID": "XSAFEPLU_A6_AW12",
            "de": "Universalverbinder mit Element+135Bühne mit Leiter",
            "en-gb": "Universal fixing bolt with panel+135plattform with ladder",
            "en-us": "Universal fixing bolt with panel+135plattform with ladder",
            "es": "Conector univ. con elem.+135plataforma con escalera",
            "fr": "Boulon d'assemblage univ. avec Px+135passerelle avec échelle",
            "it": "Tirante universale con elemento+135passerella con scala",
            "hu": "Univerzális kötocsavar elemmel+135állvány létra",
            "ru": "Универсальный соединитель с элементом+135подмостями",
            "sk": "Univerzálna spojka so panel+135plošina so rebrikom",
            "pl": "Łącznik uniwersalny z płytą + pomost 135 z drabiną"
        },
        {
            "ID": "XSAFEPLU_A6_AW13",
            "de": "Universalverbinder mit Element+270Bühne mit Leiter",
            "en-gb": "Universal fixing bolt with panel+270plattform with ladder",
            "en-us": "Universal fixing bolt with panel+270plattform with ladder",
            "es": "Conector univ. con elem.+270plataforma con escalera",
            "fr": "Boulon d'assemblage univ. avec Px+270passerelle avec échelle",
            "it": "Tirante universale con elemento+270passerella con scala",
            "hu": "Univerzális kötocsavar elemmel+270állvány létra",
            "ru": "Универсальный соединитель с элементом+270подмостями",
            "sk": "Univerzálna spojka so panel+270plošina so rebrikom",
            "pl": "Łącznik uniwersalny z płytą + pomost 270 z drabiną"
        },
        {
            "ID": "XSAFEPLU_A6_AW3",
            "de": "Universalverbinder mit Element",
            "en-gb": "Universal fixing bolt with panel",
            "en-us": "Universal fixing bolt with panel",
            "es": "Conector univ. con elem.",
            "fr": "Boulon d'assemblage univ. avec Px",
            "it": "Tirante universale con elemento",
            "hu": "Univerzális kötocsavar elemmel",
            "ru": "Универсальный соединитель с элементом",
            "sk": "Univerzálna spojka so panel",
            "pl": "Łącznik uniwersalny z płytą"
        },
        {
            "ID": "XSAFEPLU_A6_AW4",
            "de": "Universalverbinder mit Element und Fugenband",
            "en-gb": "Universal fixing bolt with panel and joint strip",
            "en-us": "Universal fixing bolt with panel and joint strip",
            "es": "Conector univ. con elem. y junta de estanqu.",
            "fr": "Boulon d'assemblage univ. avec Px et joint dil",
            "it": "Tirante universale con elemento e nastro giunti",
            "hu": "Univerzális kötocsavar elemmel és Fúgaszalag",
            "ru": "Универсальный соединитель с элементом и лентой для уплотнения швов",
            "sk": "Univerzálna spojka so panel a tesn.pás",
            "pl": "Łącznik uniwersalny z płytą i listwą"
        },
        {
            "ID": "XSAFEPLU_A6_AW5",
            "de": "Außenecke",
            "en-gb": "Outside corner",
            "en-us": "Outside corner",
            "es": "Esqu. ext.",
            "fr": "Angle extérieur",
            "it": "Angolo esterno",
            "hu": "Külso sarok",
            "ru": "Внешние угловые части",
            "sk": "Vonkajší roh",
            "pl": "Narożnik zewnętrzny"
        },
        {
            "ID": "XSAFEPLU_A6_AW6",
            "de": "Universalelement (0.90,0.75 breit)",
            "en-gb": "Universal panel (0.90,0.75 wide)",
            "en-us": "universal panel (0.90,0.75 wide)",
            "es": "Elem. univ. (0.90,0.75 ancho)",
            "fr": "Px univ. (0.90,0.75 large)",
            "it": "Elemento uni (0.90,0.75 largo)",
            "hu": "Uni elem (0,90;0,75 szélesség)",
            "ru": "Универсальный элемент (ширина 0.90,0.75)",
            "sk": "Uni. panel (0.90,0.75 šírky)",
            "pl": "Universal panel (szerokość 0,90,0,75)"
        },
        {
            "ID": "XSAFEPLU_A6_AW8",
            "de": "Profilholz Ausgleichsspanner",
            "en-gb": "Moulded timber adjustable clamp",
            "en-us": "molded timber adjustable clamp",
            "es": "Perfil de madeira grapa de comp.",
            "fr": "Liteau profilé tendeur de compensation",
            "it": "Profilo in legno morsetto di compensazione",
            "hu": "Profilos fabetét Kiegyenlíto kapcsoló",
            "ru": "Профильный брус компенсационный зажим",
            "sk": "Drev.prof. vyrovn. upínac",
            "pl": "Drewno profilowane – zacisk regulowany"
        },
        {
            "ID": "XSAFEPLU_A6_AW9",
            "de": "Profilholz Ausgleichsspanner und Fugenband",
            "en-gb": "Moulded timber adjustable clamp and joint strip",
            "en-us": "molded timber adjustable clamp and joint strip",
            "es": "Perfil de madeira grapa de comp. y junta de estanqu.",
            "fr": "Liteau profilé tendeur de comp. et joint dil",
            "it": "Profilo in legno morsetto di compensazione e nastro giunti",
            "hu": "Profilos fabetét Kiegyenlíto kapcsoló és Fúgaszalag",
            "ru": "Профильный брус компенсационный зажим и лента для уплотнения швов",
            "sk": "Drev.prof. vyrovn. upínac a tesn.pás",
            "pl": "Drewno profilowane – zacisk regulowany i listwa"
        },
        {
            "ID": "XSAFEPLU_A7",
            "de": "Ausgleichsverbindung",
            "en-gb": "Compensation connection",
            "en-us": "Compensation connection",
            "es": "Unión de compensación",
            "fr": "Connexion de compensation",
            "it": "Giunzione per compensazione",
            "hu": "Kiegyenlítés összekötés",
            "ru": "Компенсационное соединение",
            "sk": "Spojenie vo vyrovnaní",
            "pl": "Połączenie kompensacyjne"
        },
        {
            "ID": "XSAFEPLU_A7_AW1",
            "de": "Uni-Spanner",
            "en-gb": "Multi function clamp",
            "en-us": "Multi function clamp",
            "es": "Grapa unión univ.",
            "fr": "Tendeur rapide universel",
            "it": "Morsetto universale",
            "hu": "Uni. kapcsoló",
            "ru": "Универсальный зажим",
            "sk": "Univ. upínač",
            "pl": "Zamek uniwersalny"
        },
        {
            "ID": "XSAFEPLU_A7_AW2",
            "de": "Ausgleichsspanner",
            "en-gb": "Adjustable clamp",
            "en-us": "Adjustable clamp",
            "es": "Grapa de compensación",
            "fr": "Tendeur de compensation",
            "it": "Morsetto di compensazione",
            "hu": "Kiegyenlíto kapcsoló",
            "ru": "Компенсационный зажим",
            "sk": "Vyrovn. upínac",
            "pl": "Zamek wyrównujący"
        },
        {
            "ID": "XSAFEPLU_A8",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "XSAFEPLU_A8_AW1",
            "de": "Innenecke und Universal-Element (0.90,0.75 breit)",
            "en-gb": "Inside corner and universal panel (0.90,0.75 wide)",
            "en-us": "Inside corner and universal panel (0.90,0.75 wide)",
            "es": "Esqu. int. y elem. univ. (0.90,0.75 ancho)",
            "fr": "Angle int et px univ. (0.90,0.75 large)",
            "it": "Angolo interno e elemento uni (0.90,0.75 largo)",
            "hu": "Belso sarok és uni elem (0.90,0.75 szélesség)",
            "ru": "Внутренний угол и универсальный элемент (ширина 0.90,0.75)",
            "sk": "Vnút. rohy a uni. panel (0.90,0.75 šírky)",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna (szerokość 0,90,0,75)"
        },
        {
            "ID": "XSAFEPLU_A8_AW10",
            "de": "Innenecke und Außenecke mit Bühne",
            "en-gb": "Inside corner and outside corner with plattform",
            "en-us": "Inside corner and outside corner with plattform",
            "es": "Esqu. int. y esqu. ext. con plataforma",
            "fr": "Angle int et angle ext avec passerelle",
            "it": "Angolo interno e angolo esterno con passerella",
            "hu": "Belso sarok és külso sarok állvány",
            "ru": "Внутренний угол и внешний угол с подмостями",
            "sk": "Vnút. rohy a vonkaj. roh so rebrikom",
            "pl": "Narożnik wewnętrzny i narożnik zewnętrzny z pomostem"
        },
        {
            "ID": "XSAFEPLU_A8_AW11",
            "de": "Innenecke und Universal-Element mit Bühne",
            "en-gb": "Inside corner and universal panel with plattform",
            "en-us": "Inside corner and universal panel with plattform",
            "es": "Esqu. int. y elem. univ. con plataforma",
            "fr": "Angle int et px univ. avec passerelle",
            "it": "Angolo interno e elemento uni con passerella",
            "hu": "Belso sarok és uni elem állvány",
            "ru": "Внутренний угол и универсальный элемент с подмостями",
            "sk": "Vnút. rohy a uni. panel so rebrikom",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna z pomostem"
        },
        {
            "ID": "XSAFEPLU_A8_AW12",
            "de": "Scharnier-Innenecke verz. und Uni-Element",
            "en-gb": "Hinged inside corner galv. and universal panel",
            "en-us": "Hinged inside corner galv. and universal panel",
            "es": "Esqu. bisagra int. galv. y elem. univ.",
            "fr": "Angle charnière int galv. et Px univ",
            "it": "Angolo cerniera int. galv. ed elemento uni",
            "hu": "Csukl.belso sarok horg. és uni elem",
            "ru": "Шарнирный внутренний угол оцинк. и универсальный элемент",
            "sk": "Klbový vnútor. roh poz. a univ. panel",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i płyta uniwersalna"
        },
        {
            "ID": "XSAFEPLU_A8_AW13",
            "de": "Scharnier-Innenecke verz. und Außenecke",
            "en-gb": "Hinged inside corner galv.and outside corner",
            "en-us": "Hinged inside corner galv.and outside corner",
            "es": "Esqu. bisagra int. galv. y esqu. ext.",
            "fr": "Angle charnière int galv. et angle ext",
            "it": "Angolo cerniera int. galv. e angolo esterno",
            "hu": "Csukl.belso sarok horg. és külso sarok",
            "ru": "Шарнирный внутренний угол оцинк. и внешний угол",
            "sk": "Klbový vnútor. roh poz. a vonkaj. roh",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "XSAFEPLU_A8_AW14",
            "de": "Scharnier-Innenecke verz. innen und außen",
            "en-gb": "Hinged inside corner galv. inside and outside",
            "en-us": "Hinged inside corner galv. inside and outside",
            "es": "Esqu. bisagra int. galv. int. y ext.",
            "fr": "Angle charnière int galv. int et ext",
            "it": "Angolo cerniera int. galv. int. e est.",
            "hu": "Csukl.belso sarok horg. belül és kívül",
            "ru": "Шарнирный внутренний угол оцинкованный изнутри и снаружи",
            "sk": "Klbový vnútor. roh poz. zvnútra a zvonka",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. wewnątrz i na zewnątrz"
        },
        {
            "ID": "XSAFEPLU_A8_AW15",
            "de": "Scharnier-Innenecke verz. und Scharnier-Außenecke verz.",
            "en-gb": "Hinged inside corner galv.and hinged outside corner galv.",
            "en-us": "Hinged inside corner galv.and hinged outside corner galv.",
            "es": "Esqu. bisagra int. galv. y esqu. bisagra ext. galv.",
            "fr": "Angle charnière int galv. et angle charnière ext galv.",
            "it": "Angolo cerniera int. galv. e angolo cerniera est. galv.",
            "hu": "Csukl.belso sarok horg. és csukl.külso sarok horg.",
            "ru": "Шарнирный внутренний угол оцинк. и шарнирный внешний угол оцинк.",
            "sk": "Kĺbový vnútor. roh poz. a kĺb.vonk.roh poz.",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i narożnik zewnętrzny zawiasowy ocynk."
        },
        {
            "ID": "XSAFEPLU_A8_AW2",
            "de": "Innenecke und Außenecke",
            "en-gb": "Inside corner and outside corner",
            "en-us": "Inside corner and outside corner",
            "es": "Esqu. int. y esqu. ext.",
            "fr": "Angle int et angle ext",
            "it": "Angolo interno e angolo esterno",
            "hu": "Belso sarok és külso sarok",
            "ru": "Внутренний угол и внешний угол",
            "sk": "Vnút. rohy a vonkaj. roh",
            "pl": "Narożnik wewnętrzny i narożnik zewnętrzny"
        },
        {
            "ID": "XSAFEPLU_A8_AW3",
            "de": "Scharnier-Innenecke und Uni-Element",
            "en-gb": "Hinged inside corner and universal panel",
            "en-us": "Hinged inside corner and universal panel",
            "es": "Esqu. bisagra int. y elem. univ.",
            "fr": "Angle charnière int et Px univ",
            "it": "Angolo cerniera int. e elemento uni",
            "hu": "Csukl.belso sarok és uni elem",
            "ru": "Шарнирный внутренний угол и универсальный элемент",
            "sk": "Klbový vnútor. roh a uni. panel",
            "pl": "Narożnik wewnętrzny zawiasowy i płyta uniwersalna"
        },
        {
            "ID": "XSAFEPLU_A8_AW4",
            "de": "Scharnier-Innenecke und Außenecke",
            "en-gb": "Hinged inside corner and outside corner",
            "en-us": "Hinged inside corner and outside corner",
            "es": "Esqu. bisagra int. y esqu. ext.",
            "fr": "Angle charnière int et angle charnière ext",
            "it": "Angolo cerniera int. e angolo esterno",
            "hu": "Csukl.belso sarok és külso sarok",
            "ru": "Шарнирный внутренний угол и внешний угол",
            "sk": "Klbový vnútor. roh a vonkaj. roh",
            "pl": "Narożnik wewnętrzny zawiasowy i narożnik zewnętrzny"
        },
        {
            "ID": "XSAFEPLU_A8_AW5",
            "de": "Scharnier-Innenecke innen und außen",
            "en-gb": "Hinged inside corner inside and outside",
            "en-us": "Hinged inside corner inside and outside",
            "es": "Esqu. bisagra int. int. y ext.",
            "fr": "Angle charnière int int et ext",
            "it": "Angolo cerniera int. int. e est.",
            "hu": "Csukl.belso sarok belül és kívül",
            "ru": "Шарнирный внутренний угол изнутри и снаружи",
            "sk": "Klbový vnútor. roh zvnútra a zvonka",
            "pl": "Narożnik wewnętrzny zawiasowy wewnątrz i na zewnątrz"
        },
        {
            "ID": "XSAFEPLU_A8_AW6",
            "de": "Scharnier-Innenecke und Scharnier-Außenecke",
            "en-gb": "Hinged inside corner and hinged outside corner",
            "en-us": "Hinged inside corner and hinged outside corner",
            "es": "Esqu. bisagra int. y esqu. bisagra ext.",
            "fr": "Angle charnière int et angle charnière ext",
            "it": "Angolo cerniera int. e Angolo cerniera ext.",
            "hu": "Csukl.belso sarok és csukl.külso sarok",
            "ru": "Шарнирный внутренний угол и шарнирный внешний угол",
            "sk": "Klbový vnútor. roh a klb.vonk.roh",
            "pl": "Narożnik wewnętrzny zawiasowy i narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "XSAFEPLU_A8_AW7",
            "de": "Ausschalecke und Universal-Element",
            "en-gb": "Stripping corner and universal panel",
            "en-us": "Bias cut corner and universal panel",
            "es": "Esqu. de desencofrado y elem. univ.",
            "fr": "Angle de décoffrage et px univ.",
            "it": "Angolo di disarmo e elemento uni",
            "hu": "Kizsaluzó sarokelem és uni elem",
            "ru": "Распалубочный уголок и универсальный элемент",
            "sk": "Oddebnovací roh a uni. panel",
            "pl": "Narożnik rozszalowujący i płyta uniwersalna"
        },
        {
            "ID": "XSAFEPLU_A8_AW8",
            "de": "Ausschalecke und Außenecke",
            "en-gb": "Stripping corner and outside corner",
            "en-us": "Bias cut corner and outside corner",
            "es": "Esqu. de desencofrado y esqu. ext.",
            "fr": "Angle de décoffrage et angle ext",
            "it": "Angolo di disarmo e angolo esterno",
            "hu": "Kizsaluzó sarokelem és külso sarok",
            "ru": "Распалубочный уголок и внешний угол",
            "sk": "Oddebnovací roh a vonkaj. roh",
            "pl": "Narożnik rozszalowujący i narożnik zewnętrzny"
        },
        {
            "ID": "XSAFEPLU_A8_AW9",
            "de": "Innenecke und Universal-Element (1.20 breit)",
            "en-gb": "Inside corner and universal panel (1.20 wide)",
            "en-us": "Inside corner and universal panel (1.20 wide)",
            "es": "Esqu. int. y elem. univ.(1.20 ancho)",
            "fr": "Angle int et px univ. (1.20 large)",
            "it": "Angolo interno e elemento uni (1.20 largo)",
            "hu": "Belso sarok és uni elem (1.20 szélesség)",
            "ru": "Внутренний угол и универсальный элемент (ширина 1.20)",
            "sk": "Vnút. rohy a uni. panel (1.20 šírky)",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna (szerokość 1,20)"
        },
        {
            "ID": "XSAFEPLU_A9",
            "de": "Wandanschlüsse",
            "en-gb": "Wall connections",
            "en-us": "Wall connections",
            "es": "Acometidas de muros",
            "fr": "Connexions de mur",
            "it": "Agganci per parete",
            "hu": "Falcsatlakozás",
            "ru": "Стыки стен",
            "sk": "Napájanie stien",
            "pl": "Połączenia ścienne"
        },
        {
            "ID": "XSAFEPLU_A9_AW1",
            "de": "Passhölzer",
            "en-gb": "Fitting timbers",
            "en-us": "Fitting timbers",
            "es": "Tablónes de ajuste",
            "fr": "Fourrure bois",
            "it": "Tavoli di compensazione",
            "hu": "Fabetétek",
            "ru": "Пригнанные брусья",
            "sk": "Vyr. hranoly",
            "pl": "Wstawki kompensacyjne"
        },
        {
            "ID": "XSAFEPLU_A9_AW2",
            "de": "Passhölzer mit Uni-spanner",
            "en-gb": "Fitting timbers with multi function clamp",
            "en-us": "Fitting timbers with multi function clamp",
            "es": "Tablónes de ajuste con grapa unión univ.",
            "fr": "Fourrure bois avec tendeur rapide univ.",
            "it": "Tavoli di compensazione con morsetto universale",
            "hu": "Fabetétek univerzális kapcsolóval",
            "ru": "Пригнанные брусья с универсальными зажимами",
            "sk": "Vyr. hranoly s univ. upínac",
            "pl": "Wstawki kompensacyjne z zamkiem uniwersalnym"
        },
        {
            "ID": "XSAFEPLU_A9_AW3",
            "de": "Passhölzer mit Ausgl.spanner",
            "en-gb": "Fitting timbers with adjustable clamp",
            "en-us": "Fitting timbers with adjustable clamp",
            "es": "Tablónes de ajuste con grapa de comp",
            "fr": "Fourrure bois avec tend. comp.",
            "it": "Tavoli di compensazione con morsetto di compensazione",
            "hu": "Fabetétek kiegyenlíto kapcsolóval",
            "ru": "Пригнанные брусья с компенсационными зажимами",
            "sk": "Vyr. hranoly so vyrovnávací upínac",
            "pl": "Wstawka komp. z zaciskiem regulowanym"
        },
        {
            "ID": "XSAFEPLU_A9_AW4",
            "de": "Kanthölzer",
            "en-gb": "Squared timbers",
            "en-us": "Squared timbers",
            "es": "Listónes",
            "fr": "Bois",
            "it": "Travi squadrate",
            "hu": "Fabetét",
            "ru": "Бруски",
            "sk": "Hranoly a upínac.",
            "pl": "Kantówki"
        },
        {
            "ID": "XSTAHLFR_A1",
            "de": "Aufstockung Stahl auf Stahl oder Alu auf Stahl",
            "en-gb": "Stacking steel on steel or alu on steel",
            "en-us": "Stacking steel on steel or alu on steel",
            "es": "Apilado acero sobre acero o alu sobre acero",
            "fr": "Rehausse acier sur acier ou alu sur acier",
            "it": "Sovrapposizione acciaio su acciaio o alluminio su acciaio",
            "hu": "Magasítás acél acélon vagy alu acélon",
            "ru": "Надстройка сталь на сталь или алюминий на сталь",
            "sk": "Nadstavenia ocel na ocel alebo hliník na ocel",
            "pl": "Nadstawienie stal na stali lub aluminium na stali"
        },
        {
            "ID": "XSTAHLFR_A1_AW1",
            "de": "Stahl auf Stahl",
            "en-gb": "Steel on steel",
            "en-us": "Steel on steel",
            "es": "Acero sobre acero",
            "fr": "Acier sur acier",
            "it": "Acciaio su acciaio",
            "hu": "Acél acélon",
            "ru": "Сталь на сталь",
            "sk": "Ocel na ocel",
            "pl": "Stal na stali"
        },
        {
            "ID": "XSTAHLFR_A1_AW2",
            "de": "Alu auf Stahl",
            "en-gb": "Alu on steel",
            "en-us": "Alu on steel",
            "es": "Alu sobre acero",
            "fr": "Alu sur acier",
            "it": "Alluminio su acciaio",
            "hu": "Alu acélon",
            "ru": "Алюминий на сталь",
            "sk": "Hliník na ocel",
            "pl": "Aluminium na stali"
        },
        {
            "ID": "XSTAHLFR_A10",
            "de": "Stehende oder liegende Elemente",
            "en-gb": "Upright or horiz. panels",
            "en-us": "Upright or horiz. panels",
            "es": "Elementos verticales o horizontales",
            "fr": "Px debouts ou couchés",
            "it": "Elementi vert. o orizz.",
            "hu": "Elemek állítva vagy fekvo",
            "ru": "Элементы, установленные горизонтально или вертикально",
            "sk": "Panely nastojato alebo naležato",
            "pl": "Płyty pionowe lub poziome"
        },
        {
            "ID": "XSTAHLFR_A10_AW1",
            "de": "Stehende Elemente",
            "en-gb": "Upright panels",
            "en-us": "Upright panels",
            "es": "Elementos verticales",
            "fr": "Px debout",
            "it": "Elementi vert.",
            "hu": "Elemek állítva",
            "ru": "лементы, установленные вертикально",
            "sk": "Panely nastojato",
            "pl": "Płyty pionowe"
        },
        {
            "ID": "XSTAHLFR_A10_AW2",
            "de": "Liegende Elemente",
            "en-gb": "Horiz. panels",
            "en-us": "Horiz. panels",
            "es": "Elementos horizontales",
            "fr": "Px couchés",
            "it": "Elementi orizz.",
            "hu": "Elemek fekvo",
            "ru": "Элементы, установленные горизонтально",
            "sk": "Panely naležato",
            "pl": "Płyty poziome"
        },
        {
            "ID": "XSTAHLFR_A10_AW3",
            "de": "Liegende Elemente mit Fundamentspanner",
            "en-gb": "Horiz. panels with foundation clamp",
            "en-us": "Horiz. panels with foundation clamp",
            "es": "Elementos horizontales con grapa de cimentación",
            "fr": "Px couchés avec serrage pour fondation",
            "it": "Elementi orizz. con morsetto per fondazioni",
            "hu": "Fekvő elemek ankerszalag feszítővel",
            "ru": "Горизонтальные элементы с фундаментным зажимом",
            "sk": "Panely naležato s fundamentovým upínačom",
            "pl": "Płyty poziome z zaciskiem szalunkowym"
        },
        {
            "ID": "XSTAHLFR_A10_AW4",
            "de": "Liegende Elemente mit Bodenhalter",
            "en-gb": "Horiz. panels with floor fixing plate",
            "en-us": "Horiz. panels with bracing clip",
            "es": "Elementos horizontales con placa de fijación a suelo",
            "fr": "Px couchés avec support d'ancrage",
            "it": "Elementi orizz. con piastra di fissaggio",
            "hu": "Fekvő elemek aljzatrögzítővel",
            "ru": "Горизонтальные элементы с держателем для пола",
            "sk": "Panely naležato s držiakom spodku debnenia",
            "pl": "Płyty poziome z płytą montażową podłoża"
        },
        {
            "ID": "XSTAHLFR_A11",
            "de": "Bauwerksstütze",
            "en-gb": "Column",
            "en-us": "Column",
            "es": "Pilar",
            "fr": "Poteau",
            "it": "Pilastro",
            "hu": "Pillér",
            "ru": "Колонна",
            "sk": "Stĺp",
            "pl": "Słup"
        },
        {
            "ID": "XSTAHLFR_A11_AW1",
            "de": "Uni-Element 0,90 mit Stützenbühne",
            "en-gb": "Universal panel 0.90 with column formwork platform",
            "en-us": "Universal panel 0.90 with column formwork platform",
            "es": "Elem. univ. 0,90 con plataf. para pilar",
            "fr": "Px univ 0,90 avec passerelle p.coff.poteau",
            "it": "Elemento uni 0,90 con passerella per pilastri",
            "hu": "Uni elem 0,90 pillérállvány",
            "ru": "Универсальный элемент 0,90 с подмостями для колонн",
            "sk": "Univerzálny panel 0,90 so stlp. plošinou",
            "pl": "Płyta uniwersalna 0,90 z pomostem do słupa"
        },
        {
            "ID": "XSTAHLFR_A11_AW2",
            "de": "Elemente mit Außenecken",
            "en-gb": "Panels with outside corners",
            "en-us": "Panels with outside corners",
            "es": "Elem. con esqu. ext.",
            "fr": "Px avec angle ext",
            "it": "Elementi con angolo esterno",
            "hu": "Elem külso sarokkal",
            "ru": "Элементы с внешними углами",
            "sk": "Panely s vonkaj. roh",
            "pl": "Płyty z narożnikami zewnętrznymi"
        },
        {
            "ID": "XSTAHLFR_A11_AW3",
            "de": "Uni-Element 1,20 mit Stützenbühne",
            "en-gb": "Universal panel 1.20 with column formwork platform",
            "en-us": "Universal panel 1.20 with column formwork platform",
            "es": "Elem. univ. 1,20 con plataf. para pilar",
            "fr": "Px univ 1,20 avec passerelle p.coff.poteau",
            "it": "Elemento uni 1,20 con passerella per pilastri",
            "hu": "Uni elem 1,20 pillérállvány",
            "ru": "Универсальный элемент 1,20 с подмостями для колонн",
            "sk": "Univerzálny panel 1,20 so stlp. plošinou",
            "pl": "Płyta uniwersalna 1,20 z pomostem do słupa"
        },
        {
            "ID": "XSTAHLFR_A11_AW4",
            "de": "Uni-Element 0,90 mit Konsole",
            "en-gb": "Universal panel 0.90 with bracket",
            "en-us": "Universal panel 0.90 with bracket",
            "es": "Elem. univ. 0,90 con ménsula",
            "fr": "Px univ 0,90 avec console",
            "it": "Elemento uni 0,90 con mensola",
            "hu": "Uni elem 0,90 betonozó konzollal",
            "ru": "Универсальный элемент 0,90 с консолью",
            "sk": "Univerzálny panel 0,90 s konzolou",
            "pl": "Płyta uniwersalna 0,90 z konsolami"
        },
        {
            "ID": "XSTAHLFR_A11_AW5",
            "de": "Uni-Element 1,20 mit Konsole",
            "en-gb": "Universal panel 1.20 with bracket",
            "en-us": "Universal panel 1.20 with bracket",
            "es": "Elem. univ. 1,20 con ménsula",
            "fr": "Px univ 1,20 avec console",
            "it": "Elemento uni 1,20 con mensola",
            "hu": "Uni elem 1,20 betonozó konzollal",
            "ru": "Универсальный элемент 1,20 с консолью",
            "sk": "Univerzálny panel 1,20 s konzolou",
            "pl": "Płyta uniwersalna 1,20 z konsolami"
        },
        {
            "ID": "XSTAHLFR_A12",
            "de": "Ankerung",
            "en-gb": "Anchoring",
            "en-us": "Anchoring",
            "es": "Anclaje",
            "fr": "Ancrage",
            "it": "Ancoraggio",
            "hu": "Ankerozás",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "pl": "Kotwienie"
        },
        {
            "ID": "XSTAHLFR_A12_AW1",
            "de": "Ankerstab 15,0 unbehandelt",
            "en-gb": "Tie rod 15.0 non-treated",
            "en-us": "Tie rod 15.0 non-treated",
            "es": "Barra de ancl. 15,0 no tratada",
            "fr": "Tige d'ancrage 15,0 non traitée",
            "it": "Barra ancorante 15,0 non trattata",
            "hu": "Ankerrúd 15,0 kezeletlen",
            "ru": "Анкерный стержень 15,0 необработанный",
            "sk": "Kotev. tyc 15,0 neupravov.",
            "pl": "Ściąg 15,0 nieocynkowany"
        },
        {
            "ID": "XSTAHLFR_A12_AW2",
            "de": "Ankerstab 15,0 verzinkt",
            "en-gb": "Tie rod 15.0 galvanised",
            "en-us": "Tie rod 15.0 galvanized",
            "es": "Barra de ancl. 15,0 galv.",
            "fr": "Tige d'ancrage 15,0 galvanisée",
            "it": "Barra ancorante 15,0 zincata",
            "hu": "Ankerrúd 15,0 horganyzott",
            "ru": "Анкерный стержень 15,0 оцинкованный",
            "sk": "Kotev. tyc 15,0 pozinkov.",
            "pl": "Ściąg 15,0 ocynkowany"
        },
        {
            "ID": "XSTAHLFR_A12_AW3",
            "de": "Ankerstab 20,0 unbehandelt",
            "en-gb": "Tie rod 20.0 non-treated",
            "en-us": "Tie rod 20.0 non-treated",
            "es": "Barra de ancl. 20,0 no tratada",
            "fr": "Tige d'ancrage 20,0 non traitée",
            "it": "Barra ancorante 20,0 non trattata",
            "hu": "Ankerrúd 20,0 kezeletlen",
            "ru": "Анкерный стержень 20,0 необработанный",
            "sk": "Kotev. tyč 20,0 neupravovaná",
            "pl": "Ściąg 20,0 nieocynkowany"
        },
        {
            "ID": "XSTAHLFR_A12_AW4",
            "de": "Ankerstab 20,0 verzinkt",
            "en-gb": "Tie rod 20.0 galvanised",
            "en-us": "Tie rod 20.0 galvanized",
            "es": "Barra de ancl. 20,0 galv.",
            "fr": "Tige d'ancrage 20,0 galvanisée",
            "it": "Barra ancorante 20,0 zincata",
            "hu": "Ankerrúd 20,0 horganyzott",
            "ru": "Анкерный стержень 20,0 оцинкованный",
            "sk": "Kotev. tyc 20,0 pozinkov.",
            "pl": "Ściąg 20,0 ocynkowany"
        },
        {
            "ID": "XSTAHLFR_A12_AW5",
            "de": "Kopfanker 15-40cm",
            "en-gb": "Head anchor 15-40cm",
            "en-us": "Head anchor 15-40cm",
            "es": "Anclaje superior 15-40cm",
            "fr": "Ancrage de tête 15-40cm",
            "it": "Ancorante di testa 15-40cm",
            "hu": "Fejanker 15-40cm",
            "ru": "Головной анкер 15-40см",
            "sk": "Vrchná kotva 15-40cm",
            "pl": "Dystans górny 15-40cm"
        },
        {
            "ID": "XSTAHLFR_A12_AW6",
            "de": "Kopfanker 15-100cm",
            "en-gb": "Head anchor 15-100cm",
            "en-us": "Head anchor 15-100cm",
            "es": "Anclaje superior 15-100cm",
            "fr": "Ancrage de tête 15-100cm",
            "it": "Ancorante di testa 15-100cm",
            "hu": "Fejanker 15-100cm",
            "ru": "Головной анкер 15-100см",
            "sk": "Vrchná kotva 15-100cm",
            "pl": "Dystans górny 15-100cm"
        },
        {
            "ID": "XSTAHLFR_A13",
            "de": "Einseitige Ecke und Kreuzung",
            "en-gb": "One-sided corner and crossing",
            "en-us": "One-sided corner and crossing",
            "es": "Esquina de un solo lado y cruce",
            "fr": "Une face angle et croisement",
            "it": "Angolo unilaterale e incrocio",
            "hu": "Egyoldali sarok és keresztcsatlakozás",
            "ru": "Односторонний угол и крестообразное соединение",
            "sk": "Jednostranný roh a križovanie",
            "pl": "Narożnik jednostronny i połączenie krzyżowe"
        },
        {
            "ID": "XSTAHLFR_A13_AW1",
            "de": "Ausschalecke",
            "en-gb": "Stripping corner",
            "en-us": "Bias cut corner",
            "es": "Esqu. de desencofrado",
            "fr": "Angle de décoffrage",
            "it": "Angolo di disarmo",
            "hu": "Kizsaluzó sarokelem",
            "ru": "Распалубочный уголок",
            "sk": "Oddebnovací roh",
            "pl": "Narożnik rozszalowujący"
        },
        {
            "ID": "XSTAHLFR_A13_AW2",
            "de": "Außen. oder Innenecke",
            "en-gb": "Outside or inside corner",
            "en-us": "Outside or inside corner",
            "es": "Esqu. ext. o esqu. int.",
            "fr": "Angle ext. ou int.",
            "it": "Angolo est. o int.",
            "hu": "Külso vagy Belso sarok",
            "ru": "Внешний или внутренний угол",
            "sk": "Vonk. roh al. vnút. roh",
            "pl": "Narożnik zewnętrzny lub wewnętrzny"
        },
        {
            "ID": "XSTAHLFR_A13_AW3",
            "de": "Scharnier-Außen. oder Innenecke",
            "en-gb": "Hinged outside or hinged inside corner",
            "en-us": "Hinged outside or hinged inside corner",
            "es": "Esqu. bisagra ext. o esqu. int.",
            "fr": "Angle charnière ext ou angle int",
            "it": "Ang. cerniera est. o Angolo interno",
            "hu": "Csukl.külso sarok vagy Belso sarok",
            "ru": "Шарнирный внешний или внутренний угол",
            "sk": "Klb.vonk.roh al. vnút. roh",
            "pl": "Narożnik zewnętrzny zawiasowy lub narożnik wewnętrzny zawiasowy"
        },
        {
            "ID": "XSTAHLFR_A13_AW4",
            "de": "Uni-Element 0,90",
            "en-gb": "Universal panel 0.90",
            "en-us": "Universal panel 0.90",
            "es": "Elem. univ. 0,90",
            "fr": "Panneau universel 0,90",
            "it": "Elemento uni 0,90",
            "hu": "Uni elem 0,90",
            "ru": "Универсальный элемент 0,90",
            "sk": "Uni. panel 0,90",
            "pl": "Element uniwersalny 0,90"
        },
        {
            "ID": "XSTAHLFR_A13_AW5",
            "de": "Uni-Element 1,20",
            "en-gb": "Universal panel 1.20",
            "en-us": "Universal panel 1.20",
            "es": "Elem. univ. 1,20",
            "fr": "Panneau universel 1,20",
            "it": "Elemento uni 1,20",
            "hu": "Uni elem 1,20",
            "ru": "Универсальный элемент 1,20",
            "sk": "Uni. panel 1,20",
            "pl": "Element uniwersalny 1,20m"
        },
        {
            "ID": "XSTAHLFR_A13_AW6",
            "de": "Scharnier-Außen- oder Innenecke verz.",
            "en-gb": "Hinged outside or hinged inside corner galv.",
            "en-us": "Hinged outside or hinged inside corner galv.",
            "es": "Esqu. bisagra ext. o esqu. int.  galv.",
            "fr": "Angle charnière ext ou angle int  galv.",
            "it": "Ang. cerniera est. o Angolo interno  galv.",
            "hu": "Csukl.külso sarok vagy Belso sarok horg.",
            "ru": "Шарнирный внешний или внутренний угол оцинк.",
            "sk": "Klb.vonk.roh al. vnút. roh poz.",
            "pl": "Narożnik zewnętrzny zawiasowy lub narożnik wewnętrzny zawiasowy ocynk."
        },
        {
            "ID": "XSTAHLFR_A14",
            "de": "Stehende oder liegende Aufstockung der Elemente",
            "en-gb": "Upright or horiz. stacking of panels",
            "en-us": "Upright or horiz. stacking of panels",
            "es": "Apilado horizontal o vertical de los elementos",
            "fr": "Rehausse de px debout ou couchés",
            "it": "Sovrapposizione orizzontale o verticale degli elementi",
            "hu": "Állított ill. fektetett elemmagasítás",
            "ru": "Вертикальная или горизонтальная надстройка элементов",
            "sk": "Nadstavenie panelov nastojato alebo naležato",
            "pl": "Pionowe lub poziome nadstawianie płyt"
        },
        {
            "ID": "XSTAHLFR_A14_AW1",
            "de": "ohne Aufstockung",
            "en-gb": "w/o stacking",
            "en-us": "w/o stacking",
            "es": "Sin apilado",
            "fr": "sans rehausse",
            "it": "senza sopralzo",
            "hu": "Magasítás nélkül",
            "ru": "без надстройки",
            "sk": "bez nadstavenia",
            "pl": "bez nadstawienia"
        },
        {
            "ID": "XSTAHLFR_A14_AW2",
            "de": "Stehend aufgestockt",
            "en-gb": "stacked upright",
            "en-us": "stacked upright",
            "es": "Apilado vertical",
            "fr": "Rehaussé deboutant",
            "it": "Sovrapposizione vert.",
            "hu": "Állítva magasítva",
            "ru": "надстройка в вертикальном положении",
            "sk": "Nadstavenie nastojato",
            "pl": "nadstawienie pionowe"
        },
        {
            "ID": "XSTAHLFR_A14_AW3",
            "de": "Liegende Aufgestockt",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz.",
            "es": "Apilado horizontal",
            "fr": "Rehaussé couchant",
            "it": "Sovrapposizione orizz.",
            "hu": "Fektetve magasítva",
            "ru": "надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "pl": "nadstawienie poziome"
        },
        {
            "ID": "XSTAHLFR_A15",
            "de": "Längsanschluss",
            "en-gb": "Longitudinal connection",
            "en-us": "Longitudinal connection",
            "es": "Solape longitudinal",
            "fr": "Reprise sur la longueur",
            "it": "Sovrapposizione longitudinale",
            "hu": "Hosszanti csatlakozás",
            "ru": "Продольный стык",
            "sk": "Pozdlžne napojenie",
            "pl": "Połączenie wzdłużne"
        },
        {
            "ID": "XSTAHLFR_A15_AW1",
            "de": "Universalelement",
            "en-gb": "Universal panel",
            "en-us": "Universal panel",
            "es": "Elem. univ.",
            "fr": "Panneau universel",
            "it": "Elemento uni",
            "hu": "Uni elem",
            "ru": "Универсальный элемент",
            "sk": "Uni. panel",
            "pl": "Element uniwersalny"
        },
        {
            "ID": "XSTAHLFR_A15_AW2",
            "de": "Normalelement",
            "en-gb": "Standard panel",
            "en-us": "Standard panel",
            "es": "Elemento normal",
            "fr": "Élement étalon",
            "it": "Elemento normale",
            "hu": "Normál tábla",
            "ru": "Унифицированный элемент",
            "sk": "Štandardný panel",
            "pl": "Płyta standardowa"
        },
        {
            "ID": "XSTAHLFR_A15_AW3",
            "de": "Großelement",
            "en-gb": "Large panel",
            "en-us": "Large panel",
            "es": "Elemento largo",
            "fr": "Grande élement",
            "it": "Elemento grande",
            "hu": "Nagytábla",
            "ru": "Крупноразмерный элемент",
            "sk": "Velkoplošný panel",
            "pl": "Płyta duża"
        },
        {
            "ID": "XSTAHLFR_A15_AW4",
            "de": "Elemente vom Vorgängertakt verwenden",
            "en-gb": "Use panels of previous cycle",
            "en-us": "Use panels of previous pour",
            "es": "Utilizar los elementos del ciclo anterior",
            "fr": "Utiliser les éléments du coffrage précédent",
            "it": "Utilizza elementi del ciclo precedente",
            "hu": "Az előző ciklus elemeinek alkalmazása",
            "ru": "Использовать элементы предыдущего такта",
            "sk": "Použiť prvky z predchádzajúceho taktu",
            "pl": "Użyj płyt z poprzedniego taktu"
        },
        {
            "ID": "XSTAHLFR_A16",
            "de": "Ausgleichstyp",
            "en-gb": "Closure type",
            "en-us": "Filler type",
            "es": "Tipo de compensación",
            "fr": "Type de compensation",
            "it": "Tipo di compensazione",
            "hu": "Kiegyenlítés típusa",
            "ru": "Тип компенсации",
            "sk": "Typ vyrovnania",
            "pl": "Rodzaj wypełnienia"
        },
        {
            "ID": "XSTAHLFR_A16_AW1",
            "de": "Passholz Doka, Ankerung im Element",
            "en-gb": "Fitting timber Doka, ties through panel",
            "en-us": "Fitting timber Doka, ties through panel",
            "es": "Tablón de ajuste Doka, anclaje en el elemento",
            "fr": "Fourrure en bois Doka, ancrage dans le panneau",
            "it": "Tavola di compensazione Doka, anc. nell'elemento",
            "hu": "Illeszto fabetét Doka, ankerozás az elemben",
            "ru": "Пригнанный брус Doka, анкерование в элементе",
            "sk": "Vyrovnávací hranol Doka, kotvenie v paneli",
            "pl": "Wstawka komp. Doka, ściagi przez płytę"
        },
        {
            "ID": "XSTAHLFR_A16_AW10",
            "de": "mit Schalungsplatte 21mm und Klemmschiene 0,90m",
            "en-gb": "with formwork sheet 21mm and universal waling 0.90m",
            "en-us": "with formwork sheet 21mm and universal waling 0.90m",
            "es": "con tablero de encofrado 21mm y riel de fijación 0,90m",
            "fr": "avec contreplaqué 21mm et rails de blocage 0,90m",
            "it": "con pannello 21mm e rotaia di fissaggio 0,90m",
            "hu": "21mm-es zsaluhéjjal és 0,90m-es kötősínnel",
            "ru": "с опалубочная плита 21мм и зажимная шина 0,90м",
            "sk": "s debniacou doskou 21mm a upínacou koľajnicou 0,90m",
            "pl": "ze sklejką 21mm i uniwersalnym ryglem ściennym 0,90m"
        },
        {
            "ID": "XSTAHLFR_A16_AW11",
            "de": "mit Schalungsplatte 21mm und Klemmschiene 1,50m",
            "en-gb": "with formwork sheet 21mm and universal waling 1.50m",
            "en-us": "with formwork sheet 21mm and universal waling 1.50m",
            "es": "con tablero de encofrado 21mm y riel de fijación 1,50m",
            "fr": "avec contreplaqué 21mm et rails de blocage 1,50m",
            "it": "con pannello 21mm e rotaia di fissaggio 1,50m",
            "hu": "21mm-es zsaluhéjjal és 1,50m-es kötősínnel",
            "ru": "с опалубочная плита 21мм и зажимная шина 1,50м",
            "sk": "s debniacou doskou 21mm a upínacou koľajnicou 1,50m",
            "pl": "ze sklejką 21mm i uniwersalnym ryglem ściennym 1,50m"
        },
        {
            "ID": "XSTAHLFR_A16_AW2",
            "de": "Füllkörper bauseits, Ankerung im Element",
            "en-gb": "Filler block by site, ties through panel",
            "en-us": "Filler block by site, ties through panel",
            "es": "Cuerpo de relleno (por parte de obra), anclaje en el elemento",
            "fr": "Corps de remplissage (chantier), ancrage dans le panneau",
            "it": "Compensazione (a carico cant.), anc. nell'elemento",
            "hu": "Kiegészítő betét építéshelyi, ankerozás az elemben",
            "ru": "Вкладыш (силами заказчика), анкерование в элементе",
            "sk": "Výplň (zo strany stavby), kotvenie v paneli",
            "pl": "Wstawka po str. budowy, ściagi przez płytę"
        },
        {
            "ID": "XSTAHLFR_A16_AW4",
            "de": "mit Schalungsplatte 27mm und Klemmschiene 0,90m",
            "en-gb": "with formwork sheet 27mm and universal waling 0.90m",
            "en-us": "with formwork sheet 27mm and universal waling 0.90m",
            "es": "con tablero de encofrado 27mm y riel de fijación 0,90m",
            "fr": "avec contreplaqué 27mm et rail de blocage 0,90m",
            "it": "con pannello 27mm e rotaia di fissaggio 0,90m",
            "hu": "27mm-es zsaluhéjjal és 0,90m-es kötősínnel",
            "ru": "с опалубочная плита 27мм и зажимная шина 0,90м",
            "sk": "s debniacou doskou 27mm a upínacou koľajnicou 0,90m",
            "pl": "ze sklejką 27mm i uniwersalnym ryglem ściennym 0,90m"
        },
        {
            "ID": "XSTAHLFR_A16_AW5",
            "de": "mit Schalungsplatte 27mm und Klemmschiene 1,50m",
            "en-gb": "with formwork sheet 27mm and universal waling 1.50m",
            "en-us": "with formwork sheet 27mm and universal waling 1.50m",
            "es": "con tablero de encofrado 27mm y riel de fijación 1,50m",
            "fr": "avec contreplaqué 27mm et rail de blocage 1,50m",
            "it": "con pannello 27mm e rotaia di fissaggio 1,50m",
            "hu": "27mm-es zsaluhéjjal és 1,50m-es kötősínnel",
            "ru": "с опалубочная плита 27мм и зажимная шина 1,50м",
            "sk": "s debniacou doskou 27mm a upínacou koľajnicou 1,50m",
            "pl": "ze sklejką 27mm i uniwersalnym ryglem ściennym 1,50m"
        },
        {
            "ID": "XSTAHLFR_A16_AW6",
            "de": "Passholz Doka, Ankerung im Ausgleich",
            "en-gb": "Fitting timber Doka, ties through closure",
            "en-us": "Fitting timber Doka, ties through filler",
            "es": "Tablón de ajuste Doka, anclaje en compensación",
            "fr": "Fourrure en bois Doka, ancrage dans le compens.",
            "it": "Tavola di compensazione Doka, anc. nella compens.",
            "hu": "Illeszto fabetét Doka, ankerozás a kiegyenlítésen keresztül",
            "ru": "Пригнанный брус Doka, анкерование в компенсатор",
            "sk": "Vyrovnávací hranol Doka, kotvenie vo vyrovnaní",
            "pl": "Wstawka komp. Doka, ściagi przez wstawkę"
        },
        {
            "ID": "XSTAHLFR_A16_AW7",
            "de": "Füllkörper bauseits, Ankerung im Ausgleich",
            "en-gb": "Filler block by site, ties through closure",
            "en-us": "Filler block by site, ties through filler",
            "es": "Cuerpo de relleno (por parte de obra), anclaje en compensación",
            "fr": "Corps de remplissage (chantier), ancrage dans le panneau",
            "it": "Compensazione (a carico cant.), anc. nella compens.",
            "hu": "Kiegészítő betét építéshelyi, ankerozás a kiegyenlítésen keresztül",
            "ru": "Вкладыш (силами заказчика), анкерование в компенсатор",
            "sk": "Výplň (zo strany stavby), kotvenie vo vyrovnaní",
            "pl": "Wstawka po str. budowy, ściagi przez wstawkę"
        },
        {
            "ID": "XSTAHLFR_A16_AW8",
            "de": "Alu-Ausgleich, Ankerung im Ausgleich",
            "en-gb": "Alu-filler, ties through closure",
            "en-us": "Alu-filler, ties through filler",
            "es": "Compensación de alu, anclaje en compensación",
            "fr": "Pièce de compensation alu, ancrage dans le compens.",
            "it": "Compensazioni in alu, anc. nella compens.",
            "hu": "Alu-betét, ankerozás a kiegyenlítésen keresztül",
            "ru": "Alu-компенсатор, анкерование в компенсатор",
            "sk": "Alu-vyrovnanie, kotvenie vo vyrovnaní",
            "pl": "Wstawka aluminiowa, ściagi przez wstawkę"
        },
        {
            "ID": "XSTAHLFR_A16_AW9",
            "de": "Alu-Ausgleich, Ankerung im Element",
            "en-gb": "Alu-filler, ties through panel",
            "en-us": "Alu-filler, ties through panel",
            "es": "Compensación de alu, anclaje en el elemento",
            "fr": "Pièce de compensation alu, ancrage dans le panneau",
            "it": "Compensazioni in alu, anc. nell'elemento",
            "hu": "Alu-betét, ankerozás az elemben",
            "ru": "Alu-компенсатор, анкерование в элементе",
            "sk": "Alu-vyrovnanie, kotvenie v paneli",
            "pl": "Wstawka aluminiowa, ściagi przez płytę"
        },
        {
            "ID": "XSTAHLFR_A2",
            "de": "Verbindung der Aufstockung",
            "en-gb": "Connection of stacking",
            "en-us": "Connection of stack",
            "es": "Conexión del apil.",
            "fr": "Connexion del rehausse",
            "it": "Giunzione della sovrapposizione",
            "hu": "Magasítás összekötés",
            "ru": "Соединение надстройки",
            "sk": "Spojenie nadstavenia",
            "pl": "Połączenie nadstawienia"
        },
        {
            "ID": "XSTAHLFR_A2_AW1",
            "de": "Schnellspanner RU+KS",
            "en-gb": "Quick acting clamp RU+KS",
            "en-us": "Quick acting clamp RU+KS",
            "es": "Grapa unión ráp. RU+KS",
            "fr": "Serrage rapide RU+KS",
            "it": "Morsetto rapido RU+KS",
            "hu": "Gyorskapcsoló RU+KS",
            "ru": "Быстродействующий замок RU+KS",
            "sk": "Rýchloupínac RU+KS",
            "pl": "Zamek RU+KS"
        },
        {
            "ID": "XSTAHLFR_A2_AW2",
            "de": "Uni-Spanner",
            "en-gb": "Multi function clamp",
            "en-us": "Multi function clamp",
            "es": "Grapa unión univ.",
            "fr": "Tendeur rapide universel",
            "it": "Morsetto universale",
            "hu": "Uni. kapcsoló",
            "ru": "Универсальный зажим",
            "sk": "Univ. upínač",
            "pl": "Zamek uniwersalny"
        },
        {
            "ID": "XSTAHLFR_A2_AW3",
            "de": "nicht aufgestockt bevorzugt",
            "en-gb": "not stacked preferred",
            "en-us": "not stacked preferred",
            "es": "No apilado preferido",
            "fr": "Ne pas rehaussé",
            "it": "Preferibilmente senza sovrapposizione",
            "hu": "magasítás nélküli preferált",
            "ru": "предпочтительно без надстройки",
            "sk": "nadstavenie nie je uprednostnené",
            "pl": "bez nadstawienia, preferowany"
        },
        {
            "ID": "XSTAHLFR_A3",
            "de": "Umsetzeinheiten",
            "en-gb": "Shifting units",
            "en-us": "Shifting units",
            "es": "Unidades de desplazamiento",
            "fr": "Unités de translation",
            "it": "Unità movimentazione",
            "hu": "Áthelyez·egységek",
            "ru": "Перемещаемые блоки",
            "sk": "Transportné jednotky",
            "pl": "Zestawy do przestawiania"
        },
        {
            "ID": "XSTAHLFR_A3_AW1",
            "de": "bevorzugt",
            "en-gb": "preferred",
            "en-us": "preferred",
            "es": "preferido",
            "fr": "préféré",
            "it": "preferito",
            "hu": "preferált",
            "ru": "предпочтительно",
            "sk": "uprednostnené",
            "pl": "preferowany"
        },
        {
            "ID": "XSTAHLFR_A3_AW2",
            "de": "nicht bevorzugt",
            "en-gb": "not preferred",
            "en-us": "not preferred",
            "es": "No preferido",
            "fr": "Ne pas préféré",
            "it": "non preferito",
            "hu": "nem preferált",
            "ru": "не предпочтительно",
            "sk": "nie je uprednostnené",
            "pl": "niepreferowany"
        },
        {
            "ID": "XSTAHLFR_A4",
            "de": "Elementtyp",
            "en-gb": "Panel type",
            "en-us": "Panel type",
            "es": "Tipo de elemento",
            "fr": "Type d'élément",
            "it": "Tipo elemento",
            "hu": "Elemtípus",
            "ru": "Тип элемента",
            "sk": "Typ panelu",
            "pl": "Typ płyty"
        },
        {
            "ID": "XSTAHLFR_A4_AW1",
            "de": "Großelement (2.40,2.70 breit)",
            "en-gb": "Large panel (2.40,2.70 wide)",
            "en-us": "Large panel (2.40,2.70 wide)",
            "es": "Elemento largo (2.40,2.70 ancho)",
            "fr": "Grande élément (2.40,2.70 large)",
            "it": "Elemento grande (2.40,2.70 largo)",
            "hu": "Nagytábla (2.40,2.70szélesség)",
            "ru": "Крупноразмерный элемент (ширина 2.40, 2.70)",
            "sk": "Velkoplošný panel (2.40,2.70 šírky)",
            "pl": "Płyty duże (szerokość 2,40;2,70m)"
        },
        {
            "ID": "XSTAHLFR_A4_AW2",
            "de": "Normalelement (1.35,0.90,0.60,0.55,0.45,0.30 breit)",
            "en-gb": "Standard panel (1.35,0.90,0.60,0.55,0.45,0.30 wide)",
            "en-us": "Standard panel (1.35,0.90,0.60,0.55,0.45,0.30 wide)",
            "es": "Elemento normal (1.35,0.90,0.60,0.55,0.45,0.30 ancho)",
            "fr": "Élément étalon (1.35,0.90,0.60,0.55,0.45,0.30 large)",
            "it": "Elemento normale (1.35,0.90,0.60,0.55,0.45,0.30 largo)",
            "hu": "Normál tábla (1.35,0.90,0.60,0.55,0.45,0.30szélesség)",
            "ru": "Унифицированный элемент (ширина 1.35,0.90,0.60,0.55,0.45,0.30)",
            "sk": "Štandardný panel (1.35,0.90,0.60,0.55,0.45,0.30 šírky)",
            "pl": "Płyta standardowa (szerokość 1.35,0.90,0.60,0.55,0.45,0.30)"
        },
        {
            "ID": "XSTAHLFR_A4_AW3",
            "de": "Uni-Element (1.20,0.90,0.75 breit)",
            "en-gb": "Universal panel (1.20,0.90,0.75 wide)",
            "en-us": "Universal panel (1.20,0.90,0.75 wide)",
            "es": "Elem. univ. (1.20,0.90,0.75 ancho)",
            "fr": "Panneau universel (1.20,0.90,0.75 large)",
            "it": "Elemento uni (1.20,0.90,0.75 largo)",
            "hu": "Uni elem (1.20,0.90,0.75szélesség)",
            "ru": "Универсальный элемент (ширина 1.20,0.90,0.75)",
            "sk": "Uni. panel (1.20,0.90,0.75 šírky)",
            "pl": "Płyta uniwersalna (szerokość 1,20;0,90;0,75)"
        },
        {
            "ID": "XSTAHLFR_A5",
            "de": "Basiselement 3,30",
            "en-gb": "Basic panel 3.30",
            "en-us": "Basic panel 3.30",
            "es": "Altura base 3,30",
            "fr": "Élément de base 3,30",
            "it": "Elemento di base 3,30",
            "hu": "Báziselem 3,30",
            "ru": "Базовый элемент 3,30",
            "sk": "Základný panel 3,30",
            "pl": "Płyta podstawowa 3,30m"
        },
        {
            "ID": "XSTAHLFR_A5_AW1",
            "de": "nicht bevorzugt",
            "en-gb": "not preferred",
            "en-us": "not preferred",
            "es": "No preferido",
            "fr": "Ne pas préféré",
            "it": "non preferito",
            "hu": "nem preferált",
            "ru": "не предпочтительно",
            "sk": "nie je uprednostnené",
            "pl": "niepreferowany"
        },
        {
            "ID": "XSTAHLFR_A5_AW2",
            "de": "bevorzugt",
            "en-gb": "preferred",
            "en-us": "preferred",
            "es": "preferido",
            "fr": "préféré",
            "it": "preferito",
            "hu": "preferált",
            "ru": "предпочтительно",
            "sk": "uprednostnené",
            "pl": "preferowany"
        },
        {
            "ID": "XSTAHLFR_A6",
            "de": "Stirnabschalung",
            "en-gb": "Stop-end-formwork",
            "en-us": "Bulkhead",
            "es": "Tape",
            "fr": "Coffrage d'about",
            "it": "Chiusura di testa",
            "hu": "Véglezárás",
            "ru": "Торцевые участки опалубки",
            "sk": "Debnenie cela",
            "pl": "Szalunek czołowy"
        },
        {
            "ID": "XSTAHLFR_A6_AW1",
            "de": "Stirnanker ohne Element",
            "en-gb": "Stop-end tie without panel",
            "en-us": "Stop-end tie without panel",
            "es": "Anclaje de tape sin elem.",
            "fr": "Ancrage d'about sans Px",
            "it": "Morsetto per testata senza elemento",
            "hu": "Homlokanker elem nélkül",
            "ru": "Торцевой анкер без элемента",
            "sk": "Celová kotva bez panel",
            "pl": "Deskowanie czołowe bez płyty"
        },
        {
            "ID": "XSTAHLFR_A6_AW10",
            "de": "Stirnanker mit Element",
            "en-gb": "Stop-end tie with panel",
            "en-us": "Stop-end tie with panel",
            "es": "Anclaje de tape con elem.",
            "fr": "Ancrage d'about avec Px",
            "it": "Morsetto per testata con elemento",
            "hu": "Homlokanker elemmel",
            "ru": "Торцевой анкер с элементом",
            "sk": "Celová kotva so panel",
            "pl": "Deskowanie czołowe z płytą"
        },
        {
            "ID": "XSTAHLFR_A6_AW11",
            "de": "Universalelement (1.20 breit)",
            "en-gb": "Universal panel (1.20 wide)",
            "en-us": "Universal panel (1.20 wide)",
            "es": "Elem. univ. (1.20 ancho)",
            "fr": "Px univ. (1.20 large)",
            "it": "Elem. universale (1.20 largo)",
            "hu": "Uni elem (1.20szélesség)",
            "ru": "Универсальный элемент (ширина 1,20)",
            "sk": "Univerzálny panel (1,20 šírky)",
            "pl": "Universal panel (szerokość 1,20)"
        },
        {
            "ID": "XSTAHLFR_A6_AW12",
            "de": "Stirnabschalzwinge",
            "en-gb": "Stop-end waler tie",
            "en-us": "Stop-end waler tie",
            "es": "Sargento para tape",
            "fr": "Rail d'about",
            "it": "Corrente di testata",
            "hu": "Véglezáró sín",
            "ru": "Торцевая накладка",
            "sk": "Zvierka debnenia čiel",
            "pl": "Zacisk deskowania czołowego"
        },
        {
            "ID": "XSTAHLFR_A6_AW3",
            "de": "Universalverbinder mit Element",
            "en-gb": "Universal fixing bolt with panel",
            "en-us": "Universal fixing bolt with panel",
            "es": "Conector univ. con elem.",
            "fr": "Boulon d'assemblage universel avec Px",
            "it": "Tirante universale con elemento",
            "hu": "Univerzális kötocsavar elemmel",
            "ru": "Универсальный соединитель с элементом",
            "sk": "Univerzálna spojka so panel",
            "pl": "Łącznik uniwersalny z płytą"
        },
        {
            "ID": "XSTAHLFR_A6_AW4",
            "de": "Universalverbinder mit Element und Fugenband",
            "en-gb": "Universal fixing bolt with panel and joint strip",
            "en-us": "Universal fixing bolt with panel and joint strip",
            "es": "Conector univ. con elem. y junta de estanqu.",
            "fr": "Boulon d'assemblage universel avec Px et joint dil",
            "it": "Tirante universale con elemento e nastro giunti",
            "hu": "Univerzális kötocsavar elemmel és Fúgaszalag",
            "ru": "Универсальный соединитель с элементом и лентой для уплотнения швов",
            "sk": "Univerzálna spojka so panel a tesn.pás",
            "pl": "Łącznik uniwersalny z płytą i listwą"
        },
        {
            "ID": "XSTAHLFR_A6_AW5",
            "de": "Außenecke",
            "en-gb": "Outside corner",
            "en-us": "Outside corner",
            "es": "Esqu. ext.",
            "fr": "Angle ext",
            "it": "Angolo esterno",
            "hu": "Külso sarok",
            "ru": "Внешние угловые части",
            "sk": "Vonkajší roh",
            "pl": "Narożnik zewnętrzny"
        },
        {
            "ID": "XSTAHLFR_A6_AW6",
            "de": "Universalelement (0.90,0.75 breit)",
            "en-gb": "Universal panel (0.90,0.75 wide)",
            "en-us": "Universal panel (0.90,0.75 wide)",
            "es": "Elem. univ. (0.90,0.75 ancho)",
            "fr": "Px univ. (0.90,0.75 large)",
            "it": "Elem. universale (0.90,0.75 largo)",
            "hu": "Uni elem (0.90,0.75szélesség)",
            "ru": "Универсальный элемент (ширина 0.90,0.75)",
            "sk": "Univerzálny panel (0.90,0.75 šírky)",
            "pl": "Universal panel (szerokość 0,90,0,75)"
        },
        {
            "ID": "XSTAHLFR_A6_AW7",
            "de": "Universalelement mit Element und Fugenband",
            "en-gb": "Universal panel with panel and joint strip",
            "en-us": "Universal panel with panel and joint strip",
            "es": "Elem. univ. con elem y junta de estanqu.",
            "fr": "Px univ. avec Px et joint dil",
            "it": "Elem. universale con elemento e nastro giunti",
            "hu": "Uni elem elemmel és Fúgaszalag",
            "ru": "Универсальный элемент с элементом и лентой для уплотнения швов",
            "sk": "Univerzálna spojka so panel a tesn.pás",
            "pl": "Płyta uniwersalna z płytą i listwą"
        },
        {
            "ID": "XSTAHLFR_A6_AW8",
            "de": "Profilholz Ausgleichsspanner",
            "en-gb": "Moulded timber adjustable clamp",
            "en-us": "molded timber adjustable clamp",
            "es": "Perfil de madeira grapa de comp.",
            "fr": "Liteau profilé tendeur de compensation",
            "it": "Profilo in legno morsetto di compensazione",
            "hu": "Profilos fabetét Kiegyenlíto kapcsoló",
            "ru": "Профильный брус компенсационный зажим",
            "sk": "Drev.prof. vyrovn. upínac",
            "pl": "Drewno profilowane – zacisk regulowany"
        },
        {
            "ID": "XSTAHLFR_A6_AW9",
            "de": "Profilholz Ausgleichsspanner und Fugenband",
            "en-gb": "Moulded timber adjustable clamp and joint strip",
            "en-us": "molded timber adjustable clamp and joint strip",
            "es": "Perfil de madeira grapa de comp. y junta de estanqu.",
            "fr": "Liteau profilé tendeur de comp. et joint dil",
            "it": "Profilo in legno morsetto di compensazione e nastro giunti",
            "hu": "Profilos fabetét Kiegyenlíto kapcsoló és Fúgaszalag",
            "ru": "Профильный брус компенсационный зажим и лента для уплотнения швов",
            "sk": "Drev.prof. vyrovn. upínac a tesn.pás",
            "pl": "Drewno profilowane – zacisk regulowany i listwa"
        },
        {
            "ID": "XSTAHLFR_A7",
            "de": "Ausgleichsverbindung",
            "en-gb": "Compensation connection",
            "en-us": "Compensation connection",
            "es": "Unión de compensación",
            "fr": "Connexion de compensation",
            "it": "Giunzione per compensazione",
            "hu": "Kiegyenlítés összekötés",
            "ru": "Компенсационное соединение",
            "sk": "Spojenie vo vyrovnaní",
            "pl": "Połączenie kompensacyjne"
        },
        {
            "ID": "XSTAHLFR_A7_AW1",
            "de": "Uni-Spanner",
            "en-gb": "Multi function clamp",
            "en-us": "Multi function clamp",
            "es": "Grapa unión univ.",
            "fr": "Tendeur rapide universel",
            "it": "Morsetto universale",
            "hu": "Uni. kapcsoló",
            "ru": "Универсальный зажим",
            "sk": "Univ. upínač",
            "pl": "Zamek uniwersalny"
        },
        {
            "ID": "XSTAHLFR_A7_AW2",
            "de": "Ausgleichsspanner",
            "en-gb": "Adjustable clamp",
            "en-us": "Adjustable clamp",
            "es": "Grapa de compensación",
            "fr": "Tendeur de compensation",
            "it": "Morsetto di compensazione",
            "hu": "Kiegyenlíto kapcsoló",
            "ru": "Компенсационный зажим",
            "sk": "Vyrovn. upínac",
            "pl": "Zamek wyrównujący"
        },
        {
            "ID": "XSTAHLFR_A8",
            "de": "Ecklösung",
            "en-gb": "Corner solution",
            "en-us": "Corner solution",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "it": "Soluzione angolare",
            "hu": "Sarokmegoldás",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "pl": "Wariant narożnika"
        },
        {
            "ID": "XSTAHLFR_A8_AW1",
            "de": "Innenecke und Universal-Element (0.90,0.75 breit)",
            "en-gb": "Inside corner and universal panel (0.90,0.75 wide)",
            "en-us": "Inside corner and universal panel (0.90,0.75 wide)",
            "es": "Esqu. int. y elem. univ. (0.90,0.75 ancho)",
            "fr": "Angle int et Px univ (0.90,0.75 large)",
            "it": "Angolo interno e elemento uni (0.90,0.75 largo)",
            "hu": "Belso sarok és uni elem (0,90;0,75szélesség)",
            "ru": "Внутренний угол и универсальный элемент (ширина 0.90,0.75)",
            "sk": "Vnút. rohy + univ. panel (0.90,0.75 šírky)",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna (szerokość 0,90,0,75)"
        },
        {
            "ID": "XSTAHLFR_A8_AW10",
            "de": "Scharnier-Innenecke verz. und Uni-Element",
            "en-gb": "Hinged inside corner galv. and universal panel",
            "en-us": "Hinged inside corner galv. and universal panel",
            "es": "Esqu. bisagra int. galv. y elem. univ.",
            "fr": "Angle charnière int galv. et Px univ",
            "it": "Angolo cerniera int. galv. ed elemento uni",
            "hu": "Csukl.belso sarok horg. és uni elem",
            "ru": "Шарнирный внутренний угол оцинк. и универсальный элемент",
            "sk": "Klbový vnútor. roh poz. a univ. panel",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i płyta uniwersalna"
        },
        {
            "ID": "XSTAHLFR_A8_AW11",
            "de": "Scharnier-Innenecke verz. und Außenecke",
            "en-gb": "Hinged inside corner galv.and outside corner",
            "en-us": "Hinged inside corner galv.and outside corner",
            "es": "Esqu. bisagra int. galv. y esqu. ext.",
            "fr": "Angle charnière int galv. et angle ext",
            "it": "Angolo cerniera int. galv. e angolo esterno",
            "hu": "Csukl.belso sarok horg. és külso sarok",
            "ru": "Шарнирный внутренний угол оцинк. и внешний угол",
            "sk": "Klbový vnútor. roh poz. a vonkaj. roh",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "XSTAHLFR_A8_AW12",
            "de": "Scharnier-Innenecke verz. innen und außen",
            "en-gb": "Hinged inside corner galv. inside and outside",
            "en-us": "Hinged inside corner galv. inside and outside",
            "es": "Esqu. bisagra int. galv. int. y ext.",
            "fr": "Angle charnière int galv. int et ext",
            "it": "Angolo cerniera int. galv. int. e est.",
            "hu": "Csukl.belso sarok horg. belül és kívül",
            "ru": "Шарнирный внутренний угол оцинкованный изнутри и снаружи",
            "sk": "Klbový vnútor. roh poz. zvnútra a zvonka",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. wewnątrz i na zewnątrz"
        },
        {
            "ID": "XSTAHLFR_A8_AW13",
            "de": "Scharnier-Innenecke verz. und Scharnier-Außenecke verz.",
            "en-gb": "Hinged inside corner galv.and hinged outside corner galv.",
            "en-us": "Hinged inside corner galv.and hinged outside corner galv.",
            "es": "Esqu. bisagra int. galv. y esqu. bisagra ext. galv.",
            "fr": "Angle charnière int galv. et angle charnière ext galv.",
            "it": "Angolo cerniera int. galv. e angolo cerniera est. galv.",
            "hu": "Csukl.belso sarok horg. és csukl.külso sarok horg.",
            "ru": "Шарнирный внутренний угол оцинк. и шарнирный внешний угол оцинк.",
            "sk": "Kĺbový vnútor. roh poz. a kĺb.vonk.roh poz.",
            "pl": "Narożnik wewnętrzny zawiasowy ocynk. i narożnik zewnętrzny zawiasowy ocynk."
        },
        {
            "ID": "XSTAHLFR_A8_AW2",
            "de": "Innenecke und Außenecke",
            "en-gb": "Inside corner and outside corner",
            "en-us": "Inside corner and outside corner",
            "es": "Esqu. int. y esqu. ext.",
            "fr": "Angle int et angle ext",
            "it": "Angolo interno e angolo esterno",
            "hu": "Belso sarok és külso sarok",
            "ru": "Внутренний угол и внешний угол",
            "sk": "Vnút. rohy a vonkaj. roh",
            "pl": "Narożnik wewnętrzny i narożnik zewnętrzny"
        },
        {
            "ID": "XSTAHLFR_A8_AW3",
            "de": "Scharnier-Innenecke und Uni-Element",
            "en-gb": "Hinged inside corner and universal panel",
            "en-us": "Hinged inside corner and universal panel",
            "es": "Esqu. bisagra int. y elem. univ.",
            "fr": "Angle charnière int et Px univ",
            "it": "Angolo cerniera int. ed elemento uni",
            "hu": "Csukl.belso sarok és uni elem",
            "ru": "Шарнирный внутренний угол и универсальный элемент",
            "sk": "Klbový vnútor. roh a univ. panel",
            "pl": "Narożnik wewnętrzny zawiasowy i płyta uniwersalna"
        },
        {
            "ID": "XSTAHLFR_A8_AW4",
            "de": "Scharnier-Innenecke und Außenecke",
            "en-gb": "Hinged inside corner and outside corner",
            "en-us": "Hinge inside corner and outside corner",
            "es": "Esqu. bisagra int. y esqu. ext.",
            "fr": "Angle charnière int et angle ext",
            "it": "Angolo cerniera int. e angolo esterno",
            "hu": "Csukl.belso sarok és külso sarok",
            "ru": "Шарнирный внутренний угол и внешний угол",
            "sk": "Klbový vnútor. roh a vonkaj. roh",
            "pl": "Narożnik wewnętrzny zawiasowy i narożnik zewnętrzny"
        },
        {
            "ID": "XSTAHLFR_A8_AW5",
            "de": "Scharnier-Innenecke innen und außen",
            "en-gb": "Hinged inside corner inside and outside",
            "en-us": "Hinged inside corner inside and outside",
            "es": "Esqu. bisagra int. int. y ext.",
            "fr": "Angle charnière int int et ext",
            "it": "Angolo cerniera int. int. e est.",
            "hu": "Csukl.belso sarok belül és kívül",
            "ru": "Шарнирный внутренний угол изнутри и снаружи",
            "sk": "Klbový vnútor. roh zvnútra a zvonka",
            "pl": "Narożnik wewnętrzny zawiasowy wewnątrz i na zewnątrz"
        },
        {
            "ID": "XSTAHLFR_A8_AW6",
            "de": "Scharnier-Innenecke und Scharnier-Außenecke",
            "en-gb": "Hinged inside corner and hinged outside corner",
            "en-us": "Hinged inside corner and hinged outside corner",
            "es": "Esqu. bisagra int. y esqu. bisagra ext.",
            "fr": "Angle charnière int et angle charnière ext",
            "it": "Angolo cerniera int. e angolo cerniera est.",
            "hu": "Csukl.belso sarok és csukl.külso sarok",
            "ru": "Шарнирный внутренний угол и шарнирный внешний угол",
            "sk": "Klbový vnútor. roh a klb.vonk.roh",
            "pl": "Narożnik wewnętrzny zawiasowy i narożnik zewnętrzny zawiasowy"
        },
        {
            "ID": "XSTAHLFR_A8_AW7",
            "de": "Ausschalecke und Universal-Element",
            "en-gb": "Stripping corner and universal panel",
            "en-us": "Bias cut corner and universal panel",
            "es": "Esqu. de desencofrado y elem univ.",
            "fr": "Angle de décoffrage et Px univ",
            "it": "Angolo di disarmo e elemento uni",
            "hu": "Kizsaluzó sarokelem és uni elem",
            "ru": "Распалубочный уголок и универсальный элемент",
            "sk": "Oddebnovací roh a univ. panel",
            "pl": "Narożnik rozszalowujący i płyta uniwersalna"
        },
        {
            "ID": "XSTAHLFR_A8_AW8",
            "de": "Ausschalecke und Außenecke",
            "en-gb": "Stripping corner and outside corner",
            "en-us": "Bias cut corner and outside corner",
            "es": "Esqu. de desencofrado y esqu. ext.",
            "fr": "Angle de décoffrage et angle ext",
            "it": "Angolo di disarmo e angolo esterno",
            "hu": "Kizsaluzó sarokelem és külso sarok",
            "ru": "Распалубочный уголок и внешний угол",
            "sk": "Oddebnovací roh a vonkaj. roh",
            "pl": "Narożnik rozszalowujący i narożnik zewnętrzny"
        },
        {
            "ID": "XSTAHLFR_A8_AW9",
            "de": "Innenecke und Universal-Element (1.20 breit)",
            "en-gb": "Inside corner and universal panel (1.20 wide)",
            "en-us": "Inside corner and universal panel (1.20 wide)",
            "es": "Esqu. int. y elem. univ. (1.20 ancho)",
            "fr": "Angle int et Px univ (1.20 large)",
            "it": "Angolo interno e elemento uni (1.20 largo)",
            "hu": "Belso sarok és uni elem (1,20szélesség)",
            "ru": "Внутренний угол и универсальный элемент (ширина 1.20)",
            "sk": "Vnút. rohy + univ. panel (1,20 šírky)",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna (szerokość 1,20)"
        },
        {
            "ID": "XSTAHLFR_A9",
            "de": "Wandanschlüsse",
            "en-gb": "Wall connections",
            "en-us": "Wall connections",
            "es": "Acometidas de muros",
            "fr": "Connexions de mur",
            "it": "Agganci per parete",
            "hu": "Falcsatlakozás",
            "ru": "Стыки стен",
            "sk": "Napájanie stien",
            "pl": "Połączenia ścienne"
        },
        {
            "ID": "XSTAHLFR_A9_AW1",
            "de": "Passhölzer",
            "en-gb": "Fitting timbers",
            "en-us": "Fitting timbers",
            "es": "Tablónes de ajuste",
            "fr": "Fourrure bois",
            "it": "Tavoli di compensazione",
            "hu": "Fabetétek",
            "ru": "Пригнанные брусья",
            "sk": "Vyr. hranoly",
            "pl": "Wstawki kompensacyjne"
        },
        {
            "ID": "XSTAHLFR_A9_AW2",
            "de": "Passhölzer mit Uni-spanner",
            "en-gb": "Fitting timbers with multi function clamp",
            "en-us": "Fitting timbers with multi function clamp",
            "es": "Tablónes de ajuste con grapa unión univ.",
            "fr": "Fourrure bois avec tendeur rapide",
            "it": "Tavoli di compensazione con morsetto universale",
            "hu": "Fabetétek univerzális kapcsolóval",
            "ru": "Пригнанные брусья с универсальными зажимами",
            "sk": "Vyr. hranoly s univ. upínac",
            "pl": "Wstawki kompensacyjne z zamkiem uniwersalnym"
        },
        {
            "ID": "XSTAHLFR_A9_AW3",
            "de": "Passhölzer mit Ausgl.spanner",
            "en-gb": "Fitting timbers with adjustable clamp",
            "en-us": "Fitting timbers with adjustable clamp",
            "es": "Tablónes de ajuste con grapa de comp",
            "fr": "Fourrure bois avec tendeur de compensation",
            "it": "Tavoli di compensazione con morsetto di compensazione",
            "hu": "Fabetétek kiegyenlíto kapcsolóval",
            "ru": "Пригнанные брусья с компенсационными зажимами",
            "sk": "Vyr. hranoly so vyrovnávací upínac",
            "pl": "Wstawka komp. z zaciskiem regulowanym"
        },
        {
            "ID": "XSTAHLFR_A9_AW4",
            "de": "Kanthölzer",
            "en-gb": "Squared timbers",
            "en-us": "Squared timbers",
            "es": "Listónes",
            "fr": "Bois",
            "it": "Travi squadrate",
            "hu": "Fabetét",
            "ru": "Бруски",
            "sk": "Hranoly a upínac.",
            "pl": "Kantówki"
        },
        {
            "ID": "XTRA__A11",
            "de": "Xtra Unterzug Ausführung",
            "en-gb": "Xtra drop beam type",
            "en-us": "Xtra drop beam type",
            "es": "Diseño de jácenas Xtra",
            "fr": "Retombée poutre exécution Xtra",
            "it": "Xtra Trave Esecuzione",
            "hu": "Xtra gerenda kialakítás",
            "ru": "Исполнение прогона Xtra",
            "sk": "Xtra prievlaku vytvorenie",
            "pl": "Typ podciągu Xtra"
        },
        {
            "ID": "XTRA__A11_AW1",
            "de": "Balkenzwinge 20",
            "en-gb": "Beam forming support 20",
            "en-us": "Beam forming support 20",
            "es": "Soporte viga 20",
            "fr": "Équerre de poutre 20",
            "it": "Morsa a squadra per bloccaggio trave 20",
            "hu": "Gerendazsaluzó kaloda 20",
            "ru": "Балочный зажим 20",
            "sk": "Prievlaková klieština 20",
            "pl": "Zacisk belkowy 20"
        },
        {
            "ID": "XTRA__A11_AW2",
            "de": "bauseitige Kanthölzer",
            "en-gb": "Squared timber by site",
            "en-us": "Squared timber by site",
            "es": "Listónes por parte de obra",
            "fr": "Bois fourn. chantier",
            "it": "Travi squadrate in cantiere",
            "hu": "Fabetét helyi anyag",
            "ru": "деревянные брусья (силами заказчика)",
            "sk": "Hranoly dod. stavby",
            "pl": "Kantówka (po stronie budowy)"
        },
        {
            "ID": "XTRA__A11_AW3",
            "de": "Balkenzwinge 20 + H20 Träger variabel",
            "en-gb": "Beam forming support 20 + H20 beam variable",
            "en-us": "Beam forming support 20 + H20 beam variable",
            "es": "Soporte viga 20 + H20 viga variable",
            "fr": "Équerre de poutre 20 + H20 poutrelle variable",
            "it": "Morsa a sq. per blocc. trave 20 + trave H20 variabili",
            "hu": "Gerendazsaluzó kaloda 20 + H20 variábilis fatartó",
            "ru": "Балочный зажим 20 + Н20 балка различные",
            "sk": "Prievlaková klieština 20 + Variabilná nosník H20",
            "pl": "Zacisk belkowy 20 + H20"
        },
        {
            "ID": "XTRA__A12",
            "de": "Schaltafel Größe",
            "en-gb": "Shutter Board dimension",
            "en-us": "Shutter Board dimension",
            "es": "Tamaño del tablero de encofrado",
            "fr": "Contreplaqué taille",
            "it": "Grandezza della tavola di casseratura",
            "hu": "Zsaluhéj méret",
            "ru": "Размер опалубочного щита",
            "sk": "Veľkosť dosky stola",
            "pl": "Wymiary deski szalunkowej"
        },
        {
            "ID": "XTRA__A12_AW3",
            "de": "200/50 cm",
            "en-gb": "200/50 cm",
            "en-us": "200/50 cm",
            "es": "200/50 cm",
            "fr": "200/50 cm",
            "it": "200/50 cm",
            "hu": "200/50 cm",
            "ru": "200/50 cм",
            "sk": "200/50 cm",
            "pl": "200/50 cm"
        },
        {
            "ID": "XTRA__A12_AW4",
            "de": "250/50 cm",
            "en-gb": "250/50 cm",
            "en-us": "250/50 cm",
            "es": "250/50 cm",
            "fr": "250/50 cm",
            "it": "250/50 cm",
            "hu": "250/50 cm",
            "ru": "250/50 cм",
            "sk": "250/50 cm",
            "pl": "250/50 cm"
        },
        {
            "ID": "XTRA__A13",
            "de": "Querträgerlänge bevorzugt",
            "en-gb": "Secondary beam length preferred",
            "en-us": "Joist length preferred",
            "es": "Longitud de viga secundaria preferido",
            "fr": "Longueur de poutrelle sec. préféré",
            "it": "Lunghezza trave secondaria preferito",
            "hu": "Kereszttartóhossz preferált",
            "ru": "Предпочтительная длина поперечных балок",
            "sk": "Dĺžka priečnych nos. upred.",
            "pl": "Preferowana długośćdźwigara rusztu górnego"
        },
        {
            "ID": "XTRA__A13_AW12",
            "de": "8x20/100 cm",
            "en-gb": "8x20/100 cm",
            "en-us": "8x20/100 cm",
            "es": "8x20/100 cm",
            "fr": "8x20/100 cm",
            "it": "8x20/100 cm",
            "hu": "8x20/100 cm",
            "ru": "8x20/100 см",
            "sk": "8x20/100 cm",
            "pl": "8x20/100 cm"
        },
        {
            "ID": "XTRA__A13_AW13",
            "de": "8x20/125 cm",
            "en-gb": "8x20/125 cm",
            "en-us": "8x20/125 cm",
            "es": "8x20/125 cm",
            "fr": "8x20/125 cm",
            "it": "8x20/125 cm",
            "hu": "8x20/125 cm",
            "ru": "8x20/125 см",
            "sk": "8x20/125 cm",
            "pl": "8x20/125 cm"
        },
        {
            "ID": "XTRA__A13_AW14",
            "de": "8x20/150 cm",
            "en-gb": "8x20/150 cm",
            "en-us": "8x20/150 cm",
            "es": "8x20/150 cm",
            "fr": "8x20/150 cm",
            "it": "8x20/150 cm",
            "hu": "8x20/150 cm",
            "ru": "8x20/150 см",
            "sk": "8x20/150 cm",
            "pl": "8x20/150 cm"
        },
        {
            "ID": "XTRA__A13_AW15",
            "de": "8x20/175 cm",
            "en-gb": "8x20/175 cm",
            "en-us": "8x20/175 cm",
            "es": "8x20/175 cm",
            "fr": "8x20/175 cm",
            "it": "8x20/175 cm",
            "hu": "8x20/175 cm",
            "ru": "8x20/175 см",
            "sk": "8x20/175 cm",
            "pl": "8x20/175 cm"
        },
        {
            "ID": "XTRA__A13_AW16",
            "de": "8x20/200 cm",
            "en-gb": "8x20/200 cm",
            "en-us": "8x20/200 cm",
            "es": "8x20/200 cm",
            "fr": "8x20/200 cm",
            "it": "8x20/200 cm",
            "hu": "8x20/200 cm",
            "ru": "8x20/200 см",
            "sk": "8x20/200 cm",
            "pl": "8x20/200 cm"
        },
        {
            "ID": "XTRA__A13_AW2",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80m",
            "es": "1,80m",
            "fr": "1,80m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "XTRA__A13_AW4",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65m",
            "es": "2,65m",
            "fr": "2,65m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "XTRA__A14",
            "de": "Jochträgerlänge bevorzugt",
            "en-gb": "Primary beam length preferred",
            "en-us": "Stringer length preferred",
            "es": "Largo de vigas principales preferido",
            "fr": "Longueur dalle raid. préféré",
            "it": "Lunghezza trave principale preferito",
            "hu": "Főtartóhossz preferált",
            "ru": "Предпочтительная длина главных балок",
            "sk": "Dĺžka pozdĺžnych nos. upred.",
            "pl": "Preferowana długość dźwigara rusztu dolnego"
        },
        {
            "ID": "XTRA__A14_AW12",
            "de": "8x20/100 cm",
            "en-gb": "8x20/100 cm",
            "en-us": "8x20/100 cm",
            "es": "8x20/100 cm",
            "fr": "8x20/100 cm",
            "it": "8x20/100 cm",
            "hu": "8x20/100 cm",
            "ru": "8x20/100 см",
            "sk": "8x20/100 cm",
            "pl": "8x20/100 cm"
        },
        {
            "ID": "XTRA__A14_AW13",
            "de": "8x20/125 cm",
            "en-gb": "8x20/125 cm",
            "en-us": "8x20/125 cm",
            "es": "8x20/125 cm",
            "fr": "8x20/125 cm",
            "it": "8x20/125 cm",
            "hu": "8x20/125 cm",
            "ru": "8x20/125 см",
            "sk": "8x20/125 cm",
            "pl": "8x20/125 cm"
        },
        {
            "ID": "XTRA__A14_AW14",
            "de": "8x20/150 cm",
            "en-gb": "8x20/150 cm",
            "en-us": "8x20/150 cm",
            "es": "8x20/150 cm",
            "fr": "8x20/150 cm",
            "it": "8x20/150 cm",
            "hu": "8x20/150 cm",
            "ru": "8x20/150 см",
            "sk": "8x20/150 cm",
            "pl": "8x20/150 cm"
        },
        {
            "ID": "XTRA__A14_AW15",
            "de": "8x20/175 cm",
            "en-gb": "8x20/175 cm",
            "en-us": "8x20/175 cm",
            "es": "8x20/175 cm",
            "fr": "8x20/175 cm",
            "it": "8x20/175 cm",
            "hu": "8x20/175 cm",
            "ru": "8x20/175 см",
            "sk": "8x20/175 cm",
            "pl": "8x20/175 cm"
        },
        {
            "ID": "XTRA__A14_AW16",
            "de": "8x20/200 cm",
            "en-gb": "8x20/200 cm",
            "en-us": "8x20/200 cm",
            "es": "8x20/200 cm",
            "fr": "8x20/200 cm",
            "it": "8x20/200 cm",
            "hu": "8x20/200 cm",
            "ru": "8x20/200 см",
            "sk": "8x20/200 cm",
            "pl": "8x20/200 cm"
        },
        {
            "ID": "XTRA__A14_AW2",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80m",
            "es": "1,80m",
            "fr": "1,80m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "XTRA__A14_AW4",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65m",
            "es": "2,65m",
            "fr": "2,65m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "XTRA__A15",
            "de": "Deckentischausgleichszubehör",
            "en-gb": "Tableform closure accessories",
            "en-us": "Tableform filler accessories",
            "es": "Accesorios compens. para mesas",
            "fr": "Acc. de comp. de table coff.",
            "it": "Accessori per compensazione dei tavoli solaio",
            "hu": "Asztalzsalu kiegyenlítés tartozék",
            "ru": "Оснастка компенсации опалубочного стола",
            "sk": "Príslušenstvo pre vyrovnanie strop. stolov",
            "pl": "Akcesoria wypełnień między stołami"
        },
        {
            "ID": "XTRA__A15_AW1",
            "de": "100/50 cm",
            "en-gb": "100/50 cm",
            "en-us": "100/50 cm",
            "es": "100/50 cm",
            "fr": "100/50 cm",
            "it": "100/50 cm",
            "hu": "100/50 cm",
            "ru": "100/50 cм",
            "sk": "100/50 cm",
            "pl": "100/50 cm"
        },
        {
            "ID": "XTRA__A15_AW10",
            "de": "244/61 cm",
            "en-gb": "244/61 cm",
            "en-us": "244/61 cm",
            "es": "244/61 cm",
            "fr": "244/61 cm",
            "it": "244/61 cm",
            "hu": "244/61 cm",
            "ru": "244/61 cм",
            "sk": "244/61 cm",
            "pl": "244/61 cm"
        },
        {
            "ID": "XTRA__A15_AW12",
            "de": "122/61 cm",
            "en-gb": "122/61 cm",
            "en-us": "122/61 cm",
            "es": "122/61 cm",
            "fr": "122/61 cm",
            "it": "122/61 cm",
            "hu": "122/61 cm",
            "ru": "122/61 cм",
            "sk": "122/61 cm",
            "pl": "122/61 cm"
        },
        {
            "ID": "XTRA__A15_AW13",
            "de": "244/122 cm",
            "en-gb": "244/122 cm",
            "en-us": "244/122 cm",
            "es": "244/122 cm",
            "fr": "244/122 cm",
            "it": "244/122 cm",
            "hu": "244/122 cm",
            "ru": "244/122 cм",
            "sk": "244/122 cm",
            "pl": "244/122 cm"
        },
        {
            "ID": "XTRA__A15_AW14",
            "de": "14,7/200 cm",
            "en-gb": "14.7/200 cm",
            "en-us": "14.7/200cm",
            "es": "14,7/200cm",
            "fr": "14,7/200cm",
            "it": "14,7/200 cm",
            "hu": "14,7/200 cm",
            "ru": "14,7/200 cм",
            "sk": "14,7/200 cm",
            "pl": "14,7/200 cm"
        },
        {
            "ID": "XTRA__A15_AW15",
            "de": "14,7/250 cm",
            "en-gb": "14.7/250 cm",
            "en-us": "14.7/250cm",
            "es": "14,7/250cm",
            "fr": "14,7/250cm",
            "it": "14,7/250 cm",
            "hu": "14,7/250 cm",
            "ru": "14,7/250 cм",
            "sk": "14,7/250 cm",
            "pl": "14,7/250 cm"
        },
        {
            "ID": "XTRA__A15_AW2",
            "de": "150/50 cm",
            "en-gb": "150/50 cm",
            "en-us": "150/50 cm",
            "es": "150/50 cm",
            "fr": "150/50 cm",
            "it": "150/50 cm",
            "hu": "150/50 cm",
            "ru": "150/50 cм",
            "sk": "150/50 cm",
            "pl": "150/50 cm"
        },
        {
            "ID": "XTRA__A15_AW3",
            "de": "200/50 cm",
            "en-gb": "200/50 cm",
            "en-us": "200/50 cm",
            "es": "200/50 cm",
            "fr": "200/50 cm",
            "it": "200/50 cm",
            "hu": "200/50 cm",
            "ru": "200/50 cм",
            "sk": "200/50 cm",
            "pl": "200/50 cm"
        },
        {
            "ID": "XTRA__A15_AW4",
            "de": "250/50 cm",
            "en-gb": "250/50 cm",
            "en-us": "250/50 cm",
            "es": "250/50 cm",
            "fr": "250/50 cm",
            "it": "250/50 cm",
            "hu": "250/50 cm",
            "ru": "250/50 cм",
            "sk": "250/50 cm",
            "pl": "250/50 cm"
        },
        {
            "ID": "XTRA__A15_AW5",
            "de": "100/100 cm",
            "en-gb": "100/100 cm",
            "en-us": "100/100 cm",
            "es": "100/100 cm",
            "fr": "100/100 cm",
            "it": "100/100 cm",
            "hu": "100/100 cm",
            "ru": "100/100 см",
            "sk": "100/100 cm",
            "pl": "100/100 cm"
        },
        {
            "ID": "XTRA__A15_AW6",
            "de": "150/100 cm",
            "en-gb": "150/100 cm",
            "en-us": "150/100cm",
            "es": "150/100cm",
            "fr": "150/100cm",
            "it": "150/100 cm",
            "hu": "150/100 cm",
            "ru": "150/100 см",
            "sk": "150/100 cm",
            "pl": "150/100 cm"
        },
        {
            "ID": "XTRA__A15_AW7",
            "de": "200/100 cm",
            "en-gb": "200/100 cm",
            "en-us": "200/100 cm",
            "es": "200/100 cm",
            "fr": "200/100 cm",
            "it": "200/100 cm",
            "hu": "200/100 cm",
            "ru": "200/100 см",
            "sk": "200/100 cm",
            "pl": "200/100 cm"
        },
        {
            "ID": "XTRA__A15_AW8",
            "de": "35/200 cm",
            "en-gb": "35/200 cm",
            "en-us": "35/200 cm",
            "es": "35/200 cm",
            "fr": "35/200 cm",
            "it": "35/200 cm",
            "hu": "35/200 cm",
            "ru": "35/200 cм",
            "sk": "35/200 cm",
            "pl": "35/200 cm"
        },
        {
            "ID": "XTRA__A15_AW9",
            "de": "35/250 cm",
            "en-gb": "35/250 cm",
            "en-us": "35/250 cm",
            "es": "35/250 cm",
            "fr": "35/250 cm",
            "it": "35/250 cm",
            "hu": "35/250 cm",
            "ru": "35/250 cм",
            "sk": "35/250 cm",
            "pl": "35/250 cm"
        },
        {
            "ID": "XTRA__A16",
            "de": "Stützbeine",
            "en-gb": "Removable folding tripods",
            "en-us": "Removable folding tripods",
            "es": "Trípodes plegables",
            "fr": "Trépieds amovibles",
            "it": "Treppiedi amovibili",
            "hu": "Támasztólábak",
            "ru": "треноги",
            "sk": "Oporné trojnožky",
            "pl": "Trójnogi składane"
        },
        {
            "ID": "XTRA__A16_AW1",
            "de": "Stützbein",
            "en-gb": "Removable folding tripod",
            "en-us": "Removable folding tripod",
            "es": "Trípode plegable",
            "fr": "Trépied amovible",
            "it": "Treppiede amovibile",
            "hu": "Támasztóláb",
            "ru": "Тренога",
            "sk": "Oporná trojnožka",
            "pl": "Trójnóg"
        },
        {
            "ID": "XTRA__A16_AW2",
            "de": "Stützbein top",
            "en-gb": "Removable folding tripod top",
            "en-us": "Removable folding tripod top",
            "es": "Trípode plegable top",
            "fr": "Trépied amovible top",
            "it": "Treppiede amovibile top",
            "hu": "Támasztóláb top",
            "ru": "Тренога top",
            "sk": "Oporná trojnožka top",
            "pl": "Trójnóg top"
        },
        {
            "ID": "XTRA__A17",
            "de": "Einschubträger",
            "en-gb": "Insertion beam",
            "en-us": "Insertion beam",
            "es": "Viga de extensión",
            "fr": "Poutrelle de compensation",
            "it": "Prolunga orizzontale",
            "hu": "Kiegészítéstartó",
            "ru": "Вдвижная балка",
            "sk": "Zasúvací nosník",
            "pl": "Dźwigar wsuwany"
        },
        {
            "ID": "XTRA__A17_AW1",
            "de": "Einschubträger",
            "en-gb": "Insertion beam",
            "en-us": "Insertion beam",
            "es": "Viga de extensión",
            "fr": "Poutrelle de compensation",
            "it": "Prolunga orizzontale",
            "hu": "Kiegészítéstartó",
            "ru": "Вдвижная балка",
            "sk": "Zasúvací nosník",
            "pl": "Dźwigar wsuwany"
        },
        {
            "ID": "XTRA__A17_AW2",
            "de": "Dokamatic-Einschubträger",
            "en-gb": "Dokamatic insertion beam",
            "en-us": "Dokamatic insertion beam",
            "es": "Viga de extensión Dokamatic",
            "fr": "Poutrelle de compensation Dokamatic",
            "it": "Prolunga orizzontale Dokamatic",
            "hu": "Dokamatic kihúzható tartó",
            "ru": "Dokamatic вдвижная балка",
            "sk": "Dokamatic-zasúvací nosník",
            "pl": "Dźwigar wsuwany Dokamatic"
        },
        {
            "ID": "XTRA__A2",
            "de": "Deckentisch Ausführung",
            "en-gb": "Tableform type",
            "en-us": "Tableform type",
            "es": "Mesa del techo realisación",
            "fr": "Table exécution",
            "it": "Esecuzione per tavolo solaio",
            "hu": "Asztalzsalu kialakítás",
            "ru": "Исполнение опалубочного стола",
            "sk": "Stropný stôl-vytvorenie",
            "pl": "Typ stołu"
        },
        {
            "ID": "XTRA__A2_AW1",
            "de": "Dokamatic-Tisch",
            "en-gb": "Dokamatic table",
            "en-us": "Dokamatic table",
            "es": "Mesa Dokamatic",
            "fr": "Dokamatic-table",
            "it": "Tavolo Dokamatic",
            "hu": "Dokamatic asztal",
            "ru": "Стол Dokamatic",
            "sk": "Dokamatic-stôl",
            "pl": "Stolik Dokamatic"
        },
        {
            "ID": "XTRA__A2_AW2",
            "de": "Dokaflex-Tisch",
            "en-gb": "Dokaflex table",
            "en-us": "Dokaflex table",
            "es": "Mesa Dokaflex",
            "fr": "Table Dokaflex",
            "it": "Tavolo Dokaflex",
            "hu": "Dokaflex asztal",
            "ru": "Стол Dokaflex",
            "sk": "Dokaflex-stôl",
            "pl": "Stolik Dokaflex"
        },
        {
            "ID": "XTRA__A3",
            "de": "Deckentisch Schalhaut",
            "en-gb": "Tableform plywood",
            "en-us": "Tableform plywood",
            "es": "Mesa del techo forro",
            "fr": "Table contreplaqué",
            "it": "Pannello per tavolo solaio",
            "hu": "Asztalzsalu zsaluhéj",
            "ru": "Опалубочный стол палуба",
            "sk": "Stropný stôl-deb. doska",
            "pl": "Sklejka do stołu"
        },
        {
            "ID": "XTRA__A3_AW1",
            "de": "3SO-21",
            "en-gb": "3SO-21",
            "en-us": "3SO-21",
            "es": "3SO-21",
            "fr": "3SO-21",
            "it": "3SO-21",
            "hu": "3SO-21",
            "ru": "3SO-21",
            "sk": "3SO-21",
            "pl": "3SO-21"
        },
        {
            "ID": "XTRA__A3_AW2",
            "de": "3SO-27",
            "en-gb": "3SO-27",
            "en-us": "3SO-27",
            "es": "3SO-27",
            "fr": "3SO-27",
            "it": "3SO-27",
            "hu": "3SO-27",
            "ru": "3SO-27",
            "sk": "3SO-27",
            "pl": "3SO-27"
        },
        {
            "ID": "XTRA__A3_AW3",
            "de": "ohne Schalhaut",
            "en-gb": "without plywood",
            "en-us": "without plywood",
            "es": "Sin forro",
            "fr": "sans contreplaqué",
            "it": "senza pannello",
            "hu": "zsaluhéj nélkül",
            "ru": "без палубы",
            "sk": "bez deb. doska",
            "pl": "bez sklejki"
        },
        {
            "ID": "XTRA__A4",
            "de": "Deckentisch Unterstellung",
            "en-gb": "Tableform shoring",
            "en-us": "Tableform shoring",
            "es": "Mesa del techo partes inferiores",
            "fr": "Table étaiement",
            "it": "Puntellazione per tavolo solaio",
            "hu": "Asztalzsalu Alátámasztás",
            "ru": "Опалубочный стол основание",
            "sk": "Stropný stôl-podopretie",
            "pl": "System podpór stołu"
        },
        {
            "ID": "XTRA__A4_AW1",
            "de": "Eurex 20",
            "en-gb": "Eurex 20",
            "en-us": "Eurex 20",
            "es": "Eurex 20",
            "fr": "Eurex 20",
            "it": "Eurex 20",
            "hu": "Eurex 20",
            "ru": "Eurex 20",
            "sk": "Eurex 20",
            "pl": "Eurex 20"
        },
        {
            "ID": "XTRA__A4_AW2",
            "de": "Eurex 30",
            "en-gb": "Eurex 30",
            "en-us": "Eurex 30",
            "es": "Eurex 30",
            "fr": "Eurex 30",
            "it": "Eurex 30",
            "hu": "Eurex 30",
            "ru": "Eurex 30",
            "sk": "Eurex 30",
            "pl": "Eurex 30"
        },
        {
            "ID": "XTRA__A4_AW3",
            "de": "Stütze G",
            "en-gb": "Column G",
            "en-us": "Column G",
            "es": "Puntal G",
            "fr": "Étançon G",
            "it": "Puntello G",
            "hu": "Támasz G",
            "ru": "Колонна G",
            "sk": "Podpera G",
            "pl": "Podpora G"
        },
        {
            "ID": "XTRA__A4_AW4",
            "de": "Stütze N",
            "en-gb": "Column N",
            "en-us": "Column N",
            "es": "Puntal N",
            "fr": "Étançon N",
            "it": "Puntello N",
            "hu": "Támasz N",
            "ru": "Колонна N",
            "sk": "Podpera N",
            "pl": "Podpora N"
        },
        {
            "ID": "XTRA__A4_AW5",
            "de": "Stütze ECO",
            "en-gb": "Column ECO",
            "en-us": "Column ECO",
            "es": "Puntal Eco",
            "fr": "Étançon ECO",
            "it": "Puntello ECO",
            "hu": "Támasz ECO",
            "ru": "Колонна ЕСО",
            "sk": "Podpera ECO",
            "pl": "Podpora ECO"
        },
        {
            "ID": "XTRA__A4_AW6",
            "de": "Staxo",
            "en-gb": "Staxo",
            "en-us": "Staxo",
            "es": "Staxo",
            "fr": "Staxo",
            "it": "Staxo",
            "hu": "Staxo",
            "ru": "Staxo",
            "sk": "Staxo",
            "pl": "Staxo"
        },
        {
            "ID": "XTRA__A4_AW7",
            "de": "Aluxo",
            "en-gb": "Aluxo",
            "en-us": "Aluxo",
            "es": "Aluxo",
            "fr": "Aluxo",
            "it": "Aluxo",
            "hu": "Aluxo",
            "ru": "Aluxo",
            "sk": "Aluxo",
            "pl": "Aluxo"
        },
        {
            "ID": "XTRA__A4_AW8",
            "de": "Eurex 20 top",
            "en-gb": "Eurex 20 top",
            "en-us": "Eurex 20 top",
            "es": "Eurex 20 top",
            "fr": "Eurex 20 top",
            "it": "Eurex 20 top",
            "hu": "Eurex 20 top",
            "ru": "Eurex 20 top",
            "sk": "Eurex 20 top",
            "pl": "Eurex 20 top"
        },
        {
            "ID": "XTRA__A4_AW9",
            "de": "Eurex 30 top",
            "en-gb": "Eurex 30 top",
            "en-us": "Eurex 30 top",
            "es": "Eurex 30 top",
            "fr": "Eurex 30 top",
            "it": "Eurex 30 top",
            "hu": "Eurex 30 top",
            "ru": "Eurex 30 top",
            "sk": "Eurex 30 top",
            "pl": "Eurex 30 top"
        },
        {
            "ID": "XTRA__A5",
            "de": "Deckentisch Unterstellung Randstützen",
            "en-gb": "Tableform Shoring Edge props",
            "en-us": "Tableform Shoring Edge props",
            "es": "Mesa del techo partes inferiores puntales marginales",
            "fr": "Table étaiement étain de poutrelle de rive",
            "it": "Puntellazione per tavolo solaio Puntelli per i bordi",
            "hu": "Asztalzsalu Alátámasztás Szélso támasz",
            "ru": "Опалубочный стол основание краевые колонны",
            "sk": "Stropný stôl-podopretie okrajové podpery",
            "pl": "Stół – podpory krawędzi systemu podpór"
        },
        {
            "ID": "XTRA__A5_AW1",
            "de": "Standard",
            "en-gb": "Standard",
            "en-us": "Standard",
            "es": "Estándar",
            "fr": "Standard",
            "it": "Standard",
            "hu": "Standard",
            "ru": "Стандарт",
            "sk": "Standard",
            "pl": "Standard"
        },
        {
            "ID": "XTRA__A5_AW2",
            "de": "RS versetzt, 1ZS mit Schwenkkopf",
            "en-gb": "EP repositioned, 1 IP with swivel head",
            "en-us": "EP repositioned, 1 IP with swivel head",
            "es": "RS desplazado,1 PI con cabezal abatible",
            "fr": "RS déplacé, 1 ZS avec tête orientable",
            "it": "PP spostato, 1PI con testa d'inclinazione",
            "hu": "SZT áthelyezve, 1KT billentofejjel",
            "ru": "RS смещенный, 1ZS с поворотной головкой",
            "sk": "OP odsadená, 1MP s výkyvnou hlavicou",
            "pl": "Zmiana położenia EP, 1 IP z głowicą obrotową"
        },
        {
            "ID": "XTRA__A5_AW3",
            "de": "RS versetzt, 1ZS mit Stützenanschluss",
            "en-gb": "EP repositioned, 1 IP with prop connection",
            "en-us": "EP repositioned, 1 IP with prop connection",
            "es": "RS desplazado,1 PI con cabezal conector de puntales",
            "fr": "RS déplacé, 1 ZS avec raccord d'étai",
            "it": "PP spostato, 1PI con collegamento",
            "hu": "SZT áthelyezve, 1KT támaszcsatlakozóval",
            "ru": "RS смещенный, 1ZS с переходником для колонны",
            "sk": "OP odsadená, 1MP so spojkou podpery",
            "pl": "Zmiana położenia EP, 1 IP z połączeniem podpory"
        },
        {
            "ID": "XTRA__A5_AW4",
            "de": "RS versetzt, 2ZS mit Schwenkkopf",
            "en-gb": "EP repositioned, 2 IP with swivel head",
            "en-us": "EP repositioned, 2 IP with swivel head",
            "es": "RS desplazado,2 PI con cabezal abatible",
            "fr": "RS déplacé, 2 ZS avec tête orientable",
            "it": "PP spostato, 2PI con testa d'inclinazione",
            "hu": "SZT áthelyezve, 2KT billentofejjel",
            "ru": "RS смещенный, 2ZS с поворотной головкой",
            "sk": "OP odsadená, 2MP svýkyvnou hlavico",
            "pl": "Zmiana położenia EP, 2 IP z głowicą obrotową"
        },
        {
            "ID": "XTRA__A5_AW5",
            "de": "RS versetzt, 2ZS mit Stützenanschluss",
            "en-gb": "EP repositioned, 2 IP with prop connection",
            "en-us": "EP repositioned, 2 IP with prop connection",
            "es": "RS desplazado,2 PI con cabezal conector de puntales",
            "fr": "RS déplacé, 2 ZS avec raccord d'étai",
            "it": "PP spostato, 2PI con collegamento",
            "hu": "SZT áthelyezve, 2KT támaszcsatlakozóval",
            "ru": "RS смещенный, 2ZS с переходником для колонны",
            "sk": "OP odsadená, 2MP so spojkou podpery",
            "pl": "Zmiana położenia EP, 2 IP z połączeniem podpory"
        },
        {
            "ID": "XTRA__A6",
            "de": "Deckentisch Last-/Fußspindel",
            "en-gb": "Tableform heavy duty screw jack/screw jack foot",
            "en-us": "Floor table heavy duty screw jack/screw jack foot",
            "es": "Mesa del techo gato atornillable/pie con husillo",
            "fr": "Table vérin de reprise/vérin de pied",
            "it": "Piede / puntello registrabile per tavolo solaio",
            "hu": "Asztalzsalu Teherhordó orsó/Láborsó",
            "ru": "Опалубочный стол шпиндель повышенной нагрузки/опорный шпиндель",
            "sk": "Stropný stôl vret.noha/pätk.vret",
            "pl": "Stół – dźwignik śrubowy o dużej wytrzymałości / podstawa dźwignika śrubowego"
        },
        {
            "ID": "XTRA__A6_AW1",
            "de": "Lastspindel",
            "en-gb": "Heavy duty screw jack",
            "en-us": "Heavy duty screw jack",
            "es": "Gato atornillable",
            "fr": "Vérin de reprise",
            "it": "Puntello regolabile",
            "hu": "Teherhordó orsó",
            "ru": "Шпиндель повышенной нагрузки",
            "sk": "Vret.noha",
            "pl": "Stopa regulowana wzmocniona"
        },
        {
            "ID": "XTRA__A6_AW2",
            "de": "Fußspindel",
            "en-gb": "Screw jack foot",
            "en-us": "Screw jack foot",
            "es": "Pie con husillo",
            "fr": "Vérin de pied",
            "it": "Piede registrabile",
            "hu": "Láborsó",
            "ru": "Опорный шпиндель",
            "sk": "Pätk.vret",
            "pl": "Stopa regulowana"
        },
        {
            "ID": "XTRA__A6_AW3",
            "de": "ohne Spindel",
            "en-gb": "without spindle",
            "en-us": "without spindle",
            "es": "sin husillo",
            "fr": "sans vérin",
            "it": "senza puntello",
            "hu": "orsó nélkül",
            "ru": "без шпинделя",
            "sk": "bez vretena",
            "pl": "bez wrzeciona"
        },
        {
            "ID": "XTRA__A7",
            "de": "Schaltafel Typ",
            "en-gb": "Shutter Board type",
            "en-us": "Shutter Board type",
            "es": "Tipo di tablero de encofrado",
            "fr": "Type de panneau",
            "it": "Tipo tavola di casseratura",
            "hu": "Zsaluhéj típus",
            "ru": "Тип опалубочного щита",
            "sk": "Typ dosky stola",
            "pl": "Rodzaj deski szalunkowej"
        },
        {
            "ID": "XTRA__A7_AW1",
            "de": "Dokadur-Paneel 21",
            "en-gb": "Dokadur panel 21",
            "en-us": "Dokadur panel 21",
            "es": "Panel Dokadur 21",
            "fr": "Panneau Dokadur 21",
            "it": "Pannello Dokadur 21",
            "hu": "Dokadur tábla 21",
            "ru": "Dokadur панель 21",
            "sk": "Dokadur panel 21",
            "pl": "Panel Dokadur 21"
        },
        {
            "ID": "XTRA__A7_AW10",
            "de": "Schalungsplatte 3S basic 27mm",
            "en-gb": "Formwork sheet 3S basic 27mm",
            "en-us": "Formwork sheet 3S basic 27mm",
            "es": "Tablero de encofrado 3S basic 27mm",
            "fr": "Panneau 3S basic 27mm",
            "it": "Pannello 3S basic 27mm",
            "hu": "Zsaluhéj 3S basic 27mm",
            "ru": "Опалубочная плита 3S basic 27мм",
            "sk": "Debniaca doska 3S basic 27mm",
            "pl": "Sklejka  3S basic 27mm"
        },
        {
            "ID": "XTRA__A7_AW11",
            "de": "Schalungsplatte 3S cut 27mm",
            "en-gb": "Formwork sheet 3S cut 27mm",
            "en-us": "Formwork sheet 3S cut 27mm",
            "es": "Tablero de encofrado 3S cut 27mm",
            "fr": "Panneau 3S cut 27mm",
            "it": "Pannello 3S cut 27mm",
            "hu": "Zsaluhéj 3S cut 27mm",
            "ru": "Опалубочная плита 3S cut 27мм",
            "sk": "Debniaca doska 3S cut 27mm",
            "pl": "Sklejka  3S cut 27mm"
        },
        {
            "ID": "XTRA__A7_AW2",
            "de": "Schalungsplatte 3-SO 21 mm",
            "en-gb": "Formwork sheet 3-SO 21 mm",
            "en-us": "Formwork sheet 3-SO 21 mm",
            "es": "Tablero de encofrado 3-SO 21mm",
            "fr": "Panneau 3-SO 21mm",
            "it": "Pannello 3-SO 21mm",
            "hu": "Zsaluhéj 3-SO 21mm",
            "ru": "Опалубочная плита 3-SO 21мм",
            "sk": "Debniaca doska 3-SO 21mm",
            "pl": "Sklejka  3-SO 21 mm"
        },
        {
            "ID": "XTRA__A7_AW3",
            "de": "Dokadur-Plex Paneel 21",
            "en-gb": "Dokadur-Plex panel 21",
            "en-us": "Dokadur-Plex panel 21",
            "es": "Dokadur-Plex Panel 21",
            "fr": "Panneau Dokadur-Plex 21",
            "it": "Dokadur-Plex pannello 21",
            "hu": "Dokadur-Plex tábla 21",
            "ru": "Dokadur-Plex панель 21",
            "sk": "Dokadur-Plex panel 21",
            "pl": "Panel Dokadur Plex 21"
        },
        {
            "ID": "XTRA__A7_AW4",
            "de": "Sperrholzplatte",
            "en-gb": "Plywood sheet",
            "en-us": "Plywood sheet",
            "es": "Tablero contrachapado",
            "fr": "Pan. contre-plaqué",
            "it": "Pannello in legno compensato",
            "hu": "Rétegeltlemez",
            "ru": "Распорная плита",
            "sk": "Preglejka",
            "pl": "Arkusz sklejki"
        },
        {
            "ID": "XTRA__A7_AW5",
            "de": "Dokaflex-Tischplatte",
            "en-gb": "Dokaflex table sheet",
            "en-us": "Dokaflex table sheet",
            "es": "Dokaflex Tablero para mesa",
            "fr": "Dessus de table Dokaflex",
            "it": "Pannello tavolo Dokaflex",
            "hu": "Dokaflex-asztalhéj",
            "ru": "Опалубочная плита Dokaflex",
            "sk": "Dokaflex-doska stola",
            "pl": "Dokaflex – sklejka stołu"
        },
        {
            "ID": "XTRA__A7_AW6",
            "de": "Dokadur-Paneel 27",
            "en-gb": "Dokadur panel 27",
            "en-us": "Dokadur panel 27",
            "es": "Dokadur panel 27",
            "fr": "Panneau Dokadur 27",
            "it": "Pannello Dokadur 27",
            "hu": "Dokadur tábla 27",
            "ru": "Dokadur панель 27",
            "sk": "Dokadur panel 27",
            "pl": "Panel Dokadur 21"
        },
        {
            "ID": "XTRA__A7_AW7",
            "de": "Schalungsplatte 3-SO 27 mm",
            "en-gb": "Formwork sheet 3-SO 27 mm",
            "en-us": "Formwork sheet 3-SO 27mm",
            "es": "Tablero de encofrado 3-SO 27mm",
            "fr": "Panneau 3-SO 27mm",
            "it": "Pannello 3-SO 27mm",
            "hu": "Zsaluhéj 3-SO 27mm",
            "ru": "Опалубочная плита 3-SO 27мм",
            "sk": "Debniaca doska 3-SO 27mm",
            "pl": "Sklejka  3-SO 27 mm"
        },
        {
            "ID": "XTRA__A7_AW8",
            "de": "Schalungsplatte 3S basic 21mm",
            "en-gb": "Formwork sheet 3S basic 21mm",
            "en-us": "Formwork sheet 3S basic 21mm",
            "es": "Tablero de encofrado 3S basic 21mm",
            "fr": "Panneau 3S basic 21mm",
            "it": "Pannello 3S basic 21mm",
            "hu": "Zsaluhéj 3S basic 21mm",
            "ru": "Опалубочная плита 3S basic 21мм",
            "sk": "Debniaca doska 3S basic 21mm",
            "pl": "Sklejka  3S basic 21mm"
        },
        {
            "ID": "XTRA__A7_AW9",
            "de": "Schalungsplatte 3S cut 21mm",
            "en-gb": "Formwork sheet 3S cut 21mm",
            "en-us": "Formwork sheet 3S cut 21mm",
            "es": "Tablero de encofrado 3S cut 21mm",
            "fr": "Panneau 3S cut 21mm",
            "it": "Pannello 3S cut 21mm",
            "hu": "Zsaluhéj 3S cut 21mm",
            "ru": "Опалубочная плита 3S cut 21мм",
            "sk": "Debniaca doska 3S cut 21mm",
            "pl": "Sklejka  3S cut 21mm"
        },
        {
            "ID": "XTRA__A8",
            "de": "Schalungsträger Typ",
            "en-gb": "Formwork beam type",
            "en-us": "Formwork beam type",
            "es": "Tipo di viga de madera",
            "fr": "Type de poutrelle",
            "it": "Tipo trave",
            "hu": "Tartó típus",
            "ru": "Тип опалубочной балки",
            "sk": "Typ debniaceho nosníka",
            "pl": "Typ dźwigara"
        },
        {
            "ID": "XTRA__A8_AW1",
            "de": "Träger H20 top P",
            "en-gb": "Beam H20 top P",
            "en-us": "Beam H20 top P",
            "es": "Viga H20 top P",
            "fr": "Poutrelles H20 top P",
            "it": "Trave H20 top P",
            "hu": "Fatartó H20 top P",
            "ru": "Балка H20 top P",
            "sk": "Nosník H20 top P",
            "pl": "Dźwigar H20 top P"
        },
        {
            "ID": "XTRA__A8_AW2",
            "de": "Träger H20 top N",
            "en-gb": "Beam H20 top N",
            "en-us": "Beam H20 top N",
            "es": "Viga H20 top N",
            "fr": "Poutrelles H20 top N",
            "it": "Trave H20 top N",
            "hu": "Fatartó H20 top N",
            "ru": "Балка H20 top N",
            "sk": "Nosník H20 top N",
            "pl": "Dźwigar H20 top N"
        },
        {
            "ID": "XTRA__A8_AW3",
            "de": "Träger H20 eco P",
            "en-gb": "Beam H20 eco P",
            "en-us": "Beam H20 eco P",
            "es": "Viga H20 eco P",
            "fr": "Poutrelles H20 eco P",
            "it": "Trave H20 eco P",
            "hu": "Fatartó H20 eco P",
            "ru": "Балка H20 eco P",
            "sk": "Nosník H20 eco P",
            "pl": "Dźwigar H20 eco P"
        },
        {
            "ID": "XTRA__A8_AW4",
            "de": "Träger H20 eco N",
            "en-gb": "Beam H20 eco N",
            "en-us": "Beam H20 eco N",
            "es": "Viga H20 eco N",
            "fr": "Poutrelles H20 eco N",
            "it": "Trave H20 eco N",
            "hu": "Fatartó H20 eco N",
            "ru": "Балка H20 eco N",
            "sk": "Nosník H20 eco N",
            "pl": "Dźwigar H20 eco N"
        },
        {
            "ID": "XTRA__A8_AW5",
            "de": "Träger H20 Niet P",
            "en-gb": "Beam H20 rivet P",
            "en-us": "Beam H20 rivet P",
            "es": "Viga H20 remache P",
            "fr": "Poutrelles H20 rivet P",
            "it": "Trave H20 Niet P",
            "hu": "Fatartó H20 P szegecselt végű",
            "ru": "Балка H20 заклепка P",
            "sk": "Nosník H20 P s nitmi",
            "pl": "Dźwigar H20 Niet P"
        },
        {
            "ID": "XTRA__A8_AW6",
            "de": "Träger H20 Niet N",
            "en-gb": "Beam H20 rivet N",
            "en-us": "Beam H20 rivet N",
            "es": "Viga H20 remache N",
            "fr": "Poutrelles H20 rivet N",
            "it": "Trave H20 Niet N",
            "hu": "Fatartó H20 N szegecselt végű",
            "ru": "Балка H20 заклепка N",
            "sk": "Nosník H20 N s nitmi",
            "pl": "Dźwigar H20 Niet N"
        },
        {
            "ID": "XTRA__A8_AW7",
            "de": "Kantholz bauseits",
            "en-gb": "Squared timber by site",
            "en-us": "Squared timber by site",
            "es": "Listón por parte de obra",
            "fr": "Bois équarri chantier",
            "it": "Legno squadrato a carico cant.",
            "hu": "Furfa helyi anyag",
            "ru": "Деревянный брус (предоставляется заказчиком)",
            "sk": "Hranol dod. stavby",
            "pl": "Kantówka (po stronie budowy)"
        },
        {
            "ID": "XTRA__A9",
            "de": "Xtra Unterstellung",
            "en-gb": "Xtra shoring",
            "en-us": "Xtra shoring",
            "es": "Apoyo Xtra",
            "fr": "Etaiement Xtra",
            "it": "Xtra Puntellazione",
            "hu": "Xtra Alátámasztás",
            "ru": "Основание Xtra",
            "sk": "Xtra podopretie",
            "pl": "System podpór Xtra"
        },
        {
            "ID": "XTRA__A9_AW1",
            "de": "Eurex 20",
            "en-gb": "Eurex 20",
            "en-us": "Eurex 20",
            "es": "Eurex 20",
            "fr": "Eurex 20",
            "it": "Eurex 20",
            "hu": "Eurex 20",
            "ru": "Eurex 20",
            "sk": "Eurex 20",
            "pl": "Eurex 20"
        },
        {
            "ID": "XTRA__A9_AW2",
            "de": "Eurex 30",
            "en-gb": "Eurex 30",
            "en-us": "Eurex 30",
            "es": "Eurex 30",
            "fr": "Eurex 30",
            "it": "Eurex 30",
            "hu": "Eurex 30",
            "ru": "Eurex 30",
            "sk": "Eurex 30",
            "pl": "Eurex 30"
        },
        {
            "ID": "XTRA__A9_AW3",
            "de": "Eurex 60",
            "en-gb": "Eurex 60",
            "en-us": "Eurex 60",
            "es": "Eurex 60",
            "fr": "Eurex 60",
            "it": "Eurex 60",
            "hu": "Eurex 60",
            "ru": "Eurex 60",
            "sk": "Eurex 60",
            "pl": "Eurex 60"
        },
        {
            "ID": "XTRA__A9_AW4",
            "de": "Stütze G",
            "en-gb": "Column G",
            "en-us": "Column G",
            "es": "Puntal G",
            "fr": "Étançon G",
            "it": "Puntello G",
            "hu": "Támasz G",
            "ru": "Колонна G",
            "sk": "Podpera G",
            "pl": "Podpora G"
        },
        {
            "ID": "XTRA__A9_AW5",
            "de": "Stütze N",
            "en-gb": "Column N",
            "en-us": "Column N",
            "es": "Puntal N",
            "fr": "Étançon N",
            "it": "Puntello N",
            "hu": "Támasz N",
            "ru": "Колонна N",
            "sk": "Podpera N",
            "pl": "Podpora N"
        },
        {
            "ID": "XTRA__A9_AW6",
            "de": "Stütze ECO",
            "en-gb": "Column ECO",
            "en-us": "Column ECO",
            "es": "Puntal ECO",
            "fr": "Étançon ECO",
            "it": "Puntello ECO",
            "hu": "Támasz Eco",
            "ru": "Колонна ЕСО",
            "sk": "Podpera ECO",
            "pl": "Podpora ECO"
        },
        {
            "ID": "XTRA__A9_AW7",
            "de": "Eurex 20 top",
            "en-gb": "Eurex 20 top",
            "en-us": "Eurex 20 top",
            "es": "Eurex 20 top",
            "fr": "Eurex 20 top",
            "it": "Eurex 20 top",
            "hu": "Eurex 20 top",
            "ru": "Eurex 20 top",
            "sk": "Eurex 20 top",
            "pl": "Eurex 20 top"
        },
        {
            "ID": "XTRA__A9_AW8",
            "de": "Eurex 30 top",
            "en-gb": "Eurex 30 top",
            "en-us": "Eurex 30 top",
            "es": "Eurex 30 top",
            "fr": "Eurex 30 top",
            "it": "Eurex 30 top",
            "hu": "Eurex 30 top",
            "ru": "Eurex 30 top",
            "sk": "Eurex 30 top",
            "pl": "Eurex 30 top"
        },
        {
            "ID": "XTRA_DTISCH_KL01",
            "de": "Dokaflex-Tisch 21 mm, Aluxo, Lastsp.",
            "en-gb": "Dokaflex table 21 mm, Aluxo, heavy duty screw jack",
            "en-us": "Dokaflex table 21 mm, Aluxo, heavy duty screw jack",
            "es": "Mesa Dokaflex 21 mm, Aluxo, Gato atorn. gr. cargas",
            "fr": "Table Dokaflex 21 mm, Aluxo, Vérin rep",
            "it": "Tavolo Dokaflex 21 mm , Aluxo , piede registr.",
            "hu": "Dokaflex asztal 21 mm, Aluxo, teherh. orsó",
            "ru": "Стол Dokaflex 21 мм , Aluxo , шпинд.повыш.нагрузки",
            "sk": "Dokaflex stôl 21 mm, Aluxo, vret.noha",
            "pl": "Stolik Dokaflex 21 mm, Aluxo, stopa regulowana"
        },
        {
            "ID": "XTRA_DTISCH_KL02",
            "de": "Dokaflex-Tisch 27 mm, Aluxo, Lastsp.",
            "en-gb": "Dokaflex table 27 mm, Aluxo, heavy duty screw jack",
            "en-us": "Dokaflex table 27 mm, Aluxo, heavy duty screw jack",
            "es": "Mesa Dokaflex 27 mm, Aluxo , Gato atorn. gr. Cargas",
            "fr": "Table Dokaflex 27 mm , Aluxo , Vérin rep",
            "it": "Tavolo Dokaflex 27 mm , Aluxo , piede registr.",
            "hu": "Dokaflex asztal 27 mm, Aluxo, teherh. orsó",
            "ru": "Стол Dokaflex 27 мм , Aluxo , шпинд.повыш.нагрузки",
            "sk": "Dokaflex stôl 27 mm, Aluxo , vret.noha",
            "pl": "Stolik Dokaflex 27 mm, Staxo, stopa regulowana"
        },
        {
            "ID": "XTRA_DTISCH_KL03",
            "de": "Dokaflex-Tisch 21 mm, Aluxo, Fußsp.",
            "en-gb": "Dokaflex table 21 mm, Aluxo, screw jack foot",
            "en-us": "Dokaflex table 21 mm, Aluxo, screw jack foot",
            "es": "Mesa Dokaflex 21 mm, Aluxo, Gato atorn. f.",
            "fr": "Table Dokaflex 21 mm, Aluxo, Vérin pied",
            "it": "Tavolo Dokaflex 21 mm , Aluxo , piede registr.",
            "hu": "Dokaflex asztal 21 mm, Aluxo, láborsó",
            "ru": "Стол Dokaflex 21 мм , Aluxo , опорн.шпинд.",
            "sk": "Dokaflex stôl 21 mm, Aluxo, pätk.vret",
            "pl": "Stolik Dokaflex 21 mm, Aluxo, stopa regulowana"
        },
        {
            "ID": "XTRA_DTISCH_KL04",
            "de": "Dokaflex-Tisch 27 mm, Aluxo, Fußsp.",
            "en-gb": "Dokaflex table 27 mm, Aluxo, screw jack foot",
            "en-us": "Dokaflex table 27 mm, Aluxo, screw jack foot",
            "es": "Mesa Dokaflex 27 mm, Aluxo , Gato atorn. f.",
            "fr": "Table Dokaflex 27 mm , Aluxo , Vérin pied",
            "it": "Tavolo Dokaflex 27 mm , Aluxo , piede registr.",
            "hu": "Dokaflex asztal 27 mm, Aluxo, láborsó",
            "ru": "Стол Dokaflex 27 мм , Aluxo , опорн.шпинд.",
            "sk": "Dokaflex stôl 27 mm, Aluxo , pätk.vret",
            "pl": "Stolik Dokaflex 21 mm, Aluxo stopa regulowana wzmocniona"
        },
        {
            "ID": "XTRA_DTISCH_KL05",
            "de": "Dokaflex-Tisch 21 mm, Staxo, Lastsp.",
            "en-gb": "Dokaflex table 21 mm, Staxo, heavy duty screw jack",
            "en-us": "Dokaflex table 21 mm, Staxo, heavy duty screw jack",
            "es": "Mesa Dokaflex 21 mm, Staxo, Gato atorn. f.",
            "fr": "Table Dokaflex 21 mm, Staxo, Vérin rep",
            "it": "Tavolo Dokaflex 21 mm , Staxo , piede registr.",
            "hu": "Dokaflex asztal 21 mm, Staxo, teherh. orsó",
            "ru": "Стол Dokaflex 21 мм , Staxo , шпинд.повыш.нагрузки",
            "sk": "Dokaflex stôl 21 mm, Staxo, vret.noha",
            "pl": "Stolik Dokaflex 21 mm, Staxo, stopa regulowana"
        },
        {
            "ID": "XTRA_DTISCH_KL06",
            "de": "Dokaflex-Tisch 27 mm, Staxo, Lastsp.",
            "en-gb": "Dokaflex table 27 mm, Staxo, heavy duty screw jack",
            "en-us": "Dokaflex table 27 mm, Staxo, heavy duty screw jack",
            "es": "Mesa Dokaflex 27 mm, Staxo , Gato atorn. gr. cargas",
            "fr": "Table Dokaflex 27 mm , Staxo , Vérin rep",
            "it": "Tavolo Dokaflex 27 mm , Staxo , piede registr.",
            "hu": "Dokaflex asztal 27 mm, Staxo, teherh. orsó",
            "ru": "Стол Dokaflex 27 мм , Staxo , шпинд.повыш.нагрузки",
            "sk": "Dokaflex stôl 27 mm, Staxo , vret.noha",
            "pl": "Stolik Dokaflex 27 mm, Staxo, stopa regulowana"
        },
        {
            "ID": "XTRA_DTISCH_KL07",
            "de": "Dokaflex-Tisch 21 mm, Staxo, Fußsp.",
            "en-gb": "Dokaflex table 21 mm, Staxo, screw jack foot",
            "en-us": "Dokaflex table 21 mm, Staxo, screw jack foot",
            "es": "Mesa Dokaflex 21 mm, Staxo, Gato atorn. f.",
            "fr": "Table Dokaflex 21 mm, Staxo, Vérin pied",
            "it": "Tavolo Dokaflex 21 mm , Staxo , piede registr.",
            "hu": "Dokaflex asztal 21 mm, Staxo, láborsó",
            "ru": "Стол Dokaflex 21 мм , Staxo, опорн.шпинд.",
            "sk": "Dokaflex stôl 21 mm, Staxo, pätk.vret",
            "pl": "Stolik Dokaflex 21 mm, Staxo, stopa regulowana"
        },
        {
            "ID": "XTRA_DTISCH_KL08",
            "de": "Dokaflex-Tisch 27 mm, Staxo, Fußsp.",
            "en-gb": "Dokaflex table 27 mm, Staxo, screw jack foot",
            "en-us": "Dokaflex table 27 mm, Staxo, screw jack foot",
            "es": "Mesa Dokaflex 27 mm, Staxo , Gato atorn. f.",
            "fr": "Table Dokaflex 27 mm , Staxo , Vérin pied",
            "it": "Tavolo Dokaflex 27 mm , Staxo , piede registr.",
            "hu": "Dokaflex asztal 27 mm, Staxo, láborsó",
            "ru": "Стол Dokaflex 27 мм , Staxo, опорн.шпинд.",
            "sk": "Dokaflex stôl 27 mm, Staxo , pätk.vret",
            "pl": "Stolik Dokaflex 27 mm, Aluxo stopa regulowana wzmocniona"
        },
        {
            "ID": "XTRA_DTISCH_KL09",
            "de": "Dokaflex-Tisch 27 mm, Deckenstütze Eco",
            "en-gb": "Dokaflex table 27 mm, floor prop Eco",
            "en-us": "Dokaflex table 27 mm, floor prop ECO",
            "es": "Mesa Dokaflex 27 mm, Puntal Eco",
            "fr": "Table Dokaflex 27 mm , Etai ECO",
            "it": "Tavolo Dokaflex 27 mm , puntello Eco",
            "hu": "Dokaflex asztal 27 mm, födémtámasz Eco",
            "ru": "Стол Dokaflex 27 мм , стойка для перекрытий Eco",
            "sk": "Dokaflex stôl 27 mm, stropná podpera Eco",
            "pl": "Stolik Dokaflex 27 mm, podpora stropowa Eco"
        },
        {
            "ID": "XTRA_DTISCH_KL10",
            "de": "Dokaflex-Tisch 21 mm, Deckenstütze Eurex 20",
            "en-gb": "Dokaflex table 21 mm, floor prop Eurex 20",
            "en-us": "Dokaflex table 21 mm, floor prop Eurex 20",
            "es": "Mesa Dokaflex 21 mm, Puntal Eurex 20",
            "fr": "Table Dokaflex 21 mm, Etai Eurex 20",
            "it": "Tavolo Dokaflex 21 mm , puntello Eurex 20",
            "hu": "Dokaflex asztal 21 mm, födémtámasz Eurex 20",
            "ru": "Стол Dokaflex 21 мм , стойка для перекрытий Eurex 20",
            "sk": "Dokaflex stôl 21 mm, stropná podpera Eurex 20",
            "pl": "Stolik Dokaflex 21 mm, podpora stropowa Eurex 20"
        },
        {
            "ID": "XTRA_DTISCH_KL11",
            "de": "Dokaflex-Tisch 21 mm, Deckenstütze Eurex 30",
            "en-gb": "Dokaflex table 21 mm, floor prop Eurex 30",
            "en-us": "Dokaflex table 21 mm, floor prop Eurex 30",
            "es": "Mesa Dokaflex 21 mm, Puntal Eurex 30",
            "fr": "Table Dokaflex 21 mm, Etai Eurex 30",
            "it": "Tavolo Dokaflex 21 mm , puntello Eurex 30",
            "hu": "Dokaflex asztal 21 mm, födémtámasz Eurex 30",
            "ru": "Стол Dokaflex 21 мм , стойка для перекрытий Eurex 30",
            "sk": "Dokaflex stôl 21 mm, stropná podpera Eurex 30",
            "pl": "Stolik Dokaflex 21 mm, podpora stropowa Eurex 30"
        },
        {
            "ID": "XTRA_DTISCH_KL12",
            "de": "Dokaflex-Tisch 27 mm, Deckenstütze Eurex 20",
            "en-gb": "Dokaflex table 27 mm, floor prop Eurex 20",
            "en-us": "Dokaflex table 27 mm, floor prop Eurex 20",
            "es": "Mesa Dokaflex 27 mm, Puntal Eurex 20",
            "fr": "Table Dokaflex 27 mm , Etai Eurex 20",
            "it": "Tavolo Dokaflex 27 mm , puntello Eurex 20",
            "hu": "Dokaflex asztal 27 mm, födémtámasz Eurex 20",
            "ru": "Стол Dokaflex 27 мм , стойка для перекрытий Eurex 20",
            "sk": "Dokaflex stôl 27 mm, stropná podpera Eurex 20",
            "pl": "Stolik Dokaflex 27 mm, podpora stropowa Eurex 20"
        },
        {
            "ID": "XTRA_DTISCH_KL13",
            "de": "Dokaflex-Tisch 27 mm, Deckenstütze Eurex 30",
            "en-gb": "Dokaflex table 27 mm, floor prop Eurex 30",
            "en-us": "Dokaflex table 27 mm, floor prop Eurex 30",
            "es": "Mesa Dokaflex 27 mm, Puntal Eurex 30",
            "fr": "Table Dokaflex 27 mm , Etai Eurex 30",
            "it": "Tavolo Dokaflex 27 mm , puntello Eurex 30",
            "hu": "Dokaflex asztal 27 mm, födémtámasz Eurex 30",
            "ru": "Стол Dokaflex 27 мм , стойка для перекрытий Eurex 30",
            "sk": "Dokaflex stôl 27 mm, stropná podpera Eurex 30",
            "pl": "Stolik Dokaflex 27 mm, podpora stropowa Eurex 30"
        },
        {
            "ID": "XTRA_DTISCH_KL14",
            "de": "Dokaflex-Tisch 21 mm, Deckenstütze G",
            "en-gb": "Dokaflex table 21 mm, floor prop G",
            "en-us": "Dokaflex table 21 mm, floor prop G",
            "es": "Mesa Dokaflex 21 mm, Puntal G",
            "fr": "Table Dokaflex 21 mm, Etai G",
            "it": "Tavolo Dokaflex 21 mm , puntello G",
            "hu": "Dokaflex asztal 21 mm, födémtámasz G",
            "ru": "Стол Dokaflex 21 мм , стойка для перекрытий G",
            "sk": "Dokaflex stôl 21 mm, stropná podpera G",
            "pl": "Stolik Dokaflex 21 mm, podpora stropowa G"
        },
        {
            "ID": "XTRA_DTISCH_KL15",
            "de": "Dokaflex-Tisch 27 mm, Deckenstütze G",
            "en-gb": "Dokaflex table 27 mm, floor prop G",
            "en-us": "Dokaflex table 27 mm, floor prop G",
            "es": "Mesa Dokaflex 27 mm, Puntal G",
            "fr": "Table Dokaflex 27 mm , Etai G",
            "it": "Tavolo Dokaflex 27 mm , puntello G",
            "hu": "Dokaflex asztal 27 mm, födémtámasz G",
            "ru": "Стол Dokaflex 27 мм , стойка для перекрытий G",
            "sk": "Dokaflex stôl 27 mm, stropná podpera G",
            "pl": "Stolik Dokaflex 27 mm, podpora stropowa G"
        },
        {
            "ID": "XTRA_DTISCH_KL16",
            "de": "Dokaflex-Tisch 21 mm, Deckenstütze N",
            "en-gb": "Dokaflex table 21 mm, floor prop N",
            "en-us": "Dokaflex table 21 mm, floor prop N",
            "es": "Mesa Dokaflex 21 mm, Puntal N",
            "fr": "Table Dokaflex 21 mm, Etai N",
            "it": "Tavolo Dokaflex 21 mm , puntello N",
            "hu": "Dokaflex asztal 21 mm, födémtámasz N",
            "ru": "Стол Dokaflex 21 мм , стойка для перекрытий N",
            "sk": "Dokaflex stôl 21 mm, stropná podpera N",
            "pl": "Stolik Dokaflex 21 mm, podpora stropowa N"
        },
        {
            "ID": "XTRA_DTISCH_KL17",
            "de": "Dokaflex-Tisch 27 mm, Deckenstütze N",
            "en-gb": "Dokaflex table 27 mm, floor prop N",
            "en-us": "Dokaflex table 27 mm, floor prop N",
            "es": "Mesa Dokaflex 27 mm, Puntal N",
            "fr": "Table Dokaflex 27 mm , Etai N",
            "it": "Tavolo Dokaflex 27 mm , puntello N",
            "hu": "Dokaflex asztal 27 mm, födémtámasz N",
            "ru": "Стол Dokaflex 27 мм , стойка для перекрытий N",
            "sk": "Dokaflex stôl 27 mm, stropná podpera N",
            "pl": "Stolik Dokaflex 27 mm, podpora stropowa N"
        },
        {
            "ID": "XTRA_DTISCH_KL18",
            "de": "DM 21 Standard Eurex 20",
            "en-gb": "DM 21 standard Eurex 20",
            "en-us": "DM 21 standard Eurex 20",
            "es": "DM 21 Estándar Eurex 20",
            "fr": "DM 21 standard Eurex 20",
            "it": "DM 21 Standard Eurex 20",
            "hu": "DM 21 Standard Eurex 20",
            "ru": "DM 21 Standard Eurex 20",
            "sk": "DM 21 standard Eurex 20",
            "pl": "DM 21 standard Eurex 20"
        },
        {
            "ID": "XTRA_DTISCH_KL19",
            "de": "DM 21 RS versetzt, 1ZS mit Schwenkkopf Eurex 20",
            "en-gb": "DM 21 EP repositioned, 1 IP with swivel head Eurex 20",
            "en-us": "DM 21 EP repositioned, 1 IP with swivel head Eurex 20",
            "es": "DM 21 Estándar Eurex 20",
            "fr": "DM 21 EB déplacé, 1 EI avec tête orientable Eurex 20",
            "it": "DM 21 PP spostato, 1 PI con testa d'inclinazione Eurex 20",
            "hu": "DM 21 SZT áthelyezve, 1 KT billentofejjel Eurex 20",
            "ru": "DM 21 RS смещенный, 1ZS поворотная головка Eurex 20",
            "sk": "DM 21 OP odsadená, 1MP s výkyvnou hlavicou Eurex 20",
            "pl": "Zmiana położenia DM 21 EP, 1 IP z głowicą obrotową Eurex 20"
        },
        {
            "ID": "XTRA_DTISCH_KL20",
            "de": "DM 21 RS versetzt, 1ZS mit Stützenanschluss Eurex 20/30",
            "en-gb": "DM 21 EP repositioned, 1 IP with prop connection Eurex 20/30",
            "en-us": "DM 21 EP repositioned, 1 IP with prop connection Eurex 20/30",
            "es": "DM 21 PB desplazado, 1 PI con cabezal conector de puntales Eurex 20/30",
            "fr": "DM 21 EB déplacé, 1 EI avec raccord d'étai Eurex 20/30",
            "it": "DM 21 PP spostato, 1 PI con collegamento Eurex 20/30",
            "hu": "DM 21 SZT áthelyezve, 1 KT támaszcsatlakozóval Eurex 20/30",
            "ru": "DM 21 RS смещенный, 1ZS переходник для колонны Eurex 20/30",
            "sk": "DM 21 OP odsadená, 1MP so spojkou podpery Eurex 20/30",
            "pl": "Zmiana położenia DM 21 EP, 1 IP z połączeniem podpory Eurex 20/30"
        },
        {
            "ID": "XTRA_DTISCH_KL21",
            "de": "DM 21 RS versetzt, 2ZS mit Schwenkkopf Eurex 20",
            "en-gb": "DM 21 EP repositioned, 2 IP with swivel head Eurex 20",
            "en-us": "DM 21 EP repositioned, 2 IP with swivel head Eurex 20",
            "es": "DM 21 PB desplazado, 2 PI con cabezal abatible Eurex 20",
            "fr": "DM 21 EB déplacé, 2 EI avec tête orientable Eurex 20",
            "it": "DM 21 PP spostato, 2 PI con testa d'inclinazione Eurex 20",
            "hu": "DM 21 SZT áthelyezve, 2 KT billentofejjel Eurex 20",
            "ru": "DM 21 RS смещенный, 2ZS поворотная головка Eurex 20",
            "sk": "DM 21 OP odsadená, 2MP s výkyvnou hlavicou Eurex 20",
            "pl": "Zmiana położenia DM 21 EP, 2 IP z głowicą obrotową Eurex 20"
        },
        {
            "ID": "XTRA_DTISCH_KL22",
            "de": "DM 21 RS versetzt, 2ZS mit Stützenanschluss Eurex 20/30",
            "en-gb": "DM 21 EP repositioned, 2 IP with prop connection Eurex 20/30",
            "en-us": "DM 21 EP repositioned, 2 IP with prop connection Eurex 20/30",
            "es": "DM 21 PB desplazado, 2 PI con cabezal conector de puntales Eurex 20/30",
            "fr": "DM 21 EB déplacé, 2 EI avec raccord d'étai Eurex 20/30",
            "it": "DM 21 PP spostato, 2 PI con collegamento Eurex 20/30",
            "hu": "DM 21 SZT áthelyezve, 2 KT támaszcsatlakozóval Eurex 20/30",
            "ru": "DM 21 RS смещенный, 2ZS переходник для колонны Eurex 20/30",
            "sk": "DM 21 OP odsadená, 2MP so spojkou podpery Eurex 20/30",
            "pl": "Zmiana położenia DM 21 EP, 2 IP z połączeniem podpory Eurex 20/30"
        },
        {
            "ID": "XTRA_DTISCH_KL23",
            "de": "DM 27 Standard Eurex 20",
            "en-gb": "DM 27 standard Eurex 20",
            "en-us": "DM 27 standard Eurex 20",
            "es": "DM 27 Estándar Eurex 20",
            "fr": "DM 27 standard Eurex 20",
            "it": "DM 27 Standard Eurex 20",
            "hu": "DM 27 Standard Eurex 20",
            "ru": "DM 27 Standard Eurex 20",
            "sk": "DM 27 standard Eurex 20",
            "pl": "DM 27 standard Eurex 20"
        },
        {
            "ID": "XTRA_DTISCH_KL24",
            "de": "DM 27 RS versetzt, 1ZS mit Schwenkkopf Eurex 20",
            "en-gb": "DM 27 EP repositioned, 1 IP with swivel head Eurex 20",
            "en-us": "DM 27 EP repositioned, 1 IP with swivel head Eurex 20",
            "es": "DM 27 PB desplazado, 1 PI con cabezal abatible Eurex 20",
            "fr": "DM 27 EB déplacé, 1 EI avec tête orientable Eurex 20",
            "it": "DM 27 PP spostato, 1 PI con testa d'inclinazione Eurex 20",
            "hu": "DM 27 SZT áthelyezve, 1 KT billentofejjel Eurex 20",
            "ru": "DM 27 RS смещенный, 1ZS поворотная головка Eurex 20",
            "sk": "DM 27 OP odsadená, 1MPS s výkyvnou hlavicou Eurex 20",
            "pl": "Zmiana położenia DM 27 EP, 1 IP z głowicą obrotową Eurex 20"
        },
        {
            "ID": "XTRA_DTISCH_KL25",
            "de": "DM 27 RS versetzt, 1ZS mit Stützenanschluss Eurex 20/30",
            "en-gb": "DM 27 EP repositioned, 1 IP with prop connection Eurex 20/30",
            "en-us": "DM 27 EP repositioned, 1 IP with prop connection Eurex 20/30",
            "es": "DM 27 PB desplazado, 1 PI con cabezal conector de puntales Eurex 20/30",
            "fr": "DM 27 EB déplacé, 1 EI avec raccord d'étai Eurex 20/30",
            "it": "DM 27 PP spostato, 1 PI con collegamento Eurex 20/30",
            "hu": "DM 27 SZT áthelyezve, 1 KT támaszcsatlakozóval Eurex 20/30",
            "ru": "DM 27 RS смещенный, 1ZS переходник для колонны Eurex 20/30",
            "sk": "DM 27 OP odsadená, 1MPS so spojkou podpery Eurex 20/30",
            "pl": "Zmiana położenia DM 27 EP, 1 IP z połączeniem podpory Eurex 20/30"
        },
        {
            "ID": "XTRA_DTISCH_KL26",
            "de": "DM 27 RS versetzt, 2ZS mit Schwenkkopf Eurex 20",
            "en-gb": "DM 27 EP repositioned, 2 IP with swivel head Eurex 20",
            "en-us": "DM 27 EP repositioned, 2 IP with swivel head Eurex 20",
            "es": "DM 27 PB desplazado, 2 PI con cabezal abatible Eurex 20",
            "fr": "DM 27 EB déplacé, 2 EI avec tête orientable Eurex 20",
            "it": "DM 27 PP spostato, 2 PI con testa d'inclinazione Eurex 20",
            "hu": "DM 27 SZT áthelyezve, 2 KT billentofejjel Eurex 20",
            "ru": "DM 27 RS смещенный, 2ZS поворотная головка Eurex 20",
            "sk": "DM 27 OP odsadená, 2MPS s výkyvnou hlavicou Eurex 20",
            "pl": "Zmiana położenia DM 27 EP, 2 IP z głowicą obrotową Eurex 20"
        },
        {
            "ID": "XTRA_DTISCH_KL27",
            "de": "DM 27 RS versetzt, 2ZS mit Stützenanschluss Eurex 20/30",
            "en-gb": "DM 27 EP repositioned, 2 IP with prop connection Eurex 20/30",
            "en-us": "DM 27 EP repositioned, 2 IP with prop connection Eurex 20/30",
            "es": "DM 27 PB desplazado, 2 PI con cabezal conector de puntales Eurex 20/30",
            "fr": "DM 27 EB déplacé, 2 EI avec raccord d'étai Eurex 20/30",
            "it": "DM 27 PP spostato, 2 PI con collegamento Eurex 20/30",
            "hu": "DM 27 SZT áthelyezve, 2 KT támaszcsatlakozóval Eurex 20/30",
            "ru": "DM 27 RS смещенный, 2ZS переходник для колонны Eurex 20/30",
            "sk": "DM 27 OP odsadená, 2MPS so spojkou podpery Eurex 20/30",
            "pl": "Zmiana położenia DM 27 EP, 2 IP z połączeniem podpory Eurex 20/30"
        },
        {
            "ID": "XTRA_DTISCH_KL28",
            "de": "DM 00 Standard Eurex 20",
            "en-gb": "DM 00 standard Eurex 20",
            "en-us": "DM 00 standard Eurex 20",
            "es": "DM 00 Estándar Eurex 20",
            "fr": "DM 00 standard Eurex 20",
            "it": "DM 00 Standard Eurex 20",
            "hu": "DM 00 Standard Eurex 20",
            "ru": "DM 00 Standard Eurex 20",
            "sk": "DM 00 standard Eurex 20",
            "pl": "DM 00 standard Eurex 20"
        },
        {
            "ID": "XTRA_DTISCH_KL29",
            "de": "DM 00 RS versetzt, 1ZS mit Schwenkkopf Eurex 20",
            "en-gb": "DM 00 EP repositioned, 1 IP with swivel head Eurex 20",
            "en-us": "DM 00 EP repositioned, 1 IP with swivel head Eurex 20",
            "es": "DM 00 PB desplazado, 1 PI con cabezal abatible Eurex 20",
            "fr": "DM 00 EB déplacé, 1 EI avec tête orientable Eurex 20",
            "it": "DM 00 PP spostato, 1 PI con testa d'inclinazione Eurex 20",
            "hu": "DM 00 SZT áthelyezve, 1 KT billentofejjel Eurex 20",
            "ru": "DM 00 RS смещенный, 1ZS поворотная головка Eurex 20",
            "sk": "DM 00 OP odsadená, 1MP s výkyvnou hlavicou Eurex 20",
            "pl": "Zmiana położenia DM 00 EP, 1 IP z głowicą obrotową Eurex 20"
        },
        {
            "ID": "XTRA_DTISCH_KL30",
            "de": "DM 00 RS versetzt, 1ZS mit Stützenanschluss Eurex 20/30",
            "en-gb": "DM 00 EP repositioned, 1 IP with prop connection Eurex 20/30",
            "en-us": "DM 00 EP repositioned, 1 IP with prop connection Eurex 20/3",
            "es": "DM 00 PB desplazado, 1 PI con cabezal conector de puntales Eurex 20/30",
            "fr": "DM 00 EB déplacé, 1 EI avec raccord d'étai Eurex 20/30",
            "it": "DM 00 PP spostato, 1 PI con collegamento Eurex 20/30",
            "hu": "DM 00 SZT áthelyezve, 1 KT támaszcsatlakozóval Eurex 20/30",
            "ru": "DM 00 RS смещенный, 1ZS переходник для колонны Eurex 20/30",
            "sk": "DM 00 OP odsadená, 1MP so spojkou podpery Eurex 20/30",
            "pl": "Zmiana położenia DM 00 EP, 1 IP z połączeniem podpory Eurex 20/30"
        },
        {
            "ID": "XTRA_DTISCH_KL31",
            "de": "DM 00 RS versetzt, 2ZS mit Schwenkkopf Eurex 20",
            "en-gb": "DM 00 EP repositioned, 2 IP with swivel head Eurex 20",
            "en-us": "DM 00 EP repositioned, 2 IP with swivel head Eurex 20",
            "es": "DM 00 PB desplazado, 2 PI con cabezal abatible Eurex 20",
            "fr": "DM 00 EB déplacé, 2 EI avec tête orientable Eurex 20",
            "it": "DM 00 PP spostato, 2 PI con testa d'inclinazione Eurex 20",
            "hu": "DM 00 SZT áthelyezve, 2 KT billentofejjel Eurex 20",
            "ru": "DM 00 RS смещенный, 2ZS поворотная головка Eurex 20",
            "sk": "DM 00 OP odsadená, 2MP s výkyvnou hlavicou Eurex 20",
            "pl": "Zmiana położenia DM 00 EP, 2 IP z głowicą obrotową Eurex 20"
        },
        {
            "ID": "XTRA_DTISCH_KL32",
            "de": "DM 00 RS versetzt, 2ZS mit Stützenanschluss Eurex 20/30",
            "en-gb": "DM 00 EP repositioned, 2 IP with prop connection Eurex 20/30",
            "en-us": "DM 00 EP repositioned, 2 IP with prop connection Eurex 20/3",
            "es": "DM 00 PB desplazado, 2 PI con cabezal conector de puntales Eurex 20/30",
            "fr": "DM 00 EB déplacé, 2 EI avec raccord d'étai Eurex 20/30",
            "it": "DM 00 PP spostato, 2 PI con collegamento Eurex 20/30",
            "hu": "DM 00 SZT áthelyezve, 2 KT támaszcsatlakozóval Eurex 20/30",
            "ru": "DM 00 RS смещенный, 2ZS переходник для колонны Eurex 20/30",
            "sk": "DM 00 OP odsadená, 2MP so spojkou podpery Eurex 20/30",
            "pl": "Zmiana położenia DM 00 EP, 2 IP z połączeniem podpory Eurex 20/30"
        },
        {
            "ID": "XTRA_DTISCH_KL33",
            "de": "DM 21 Standard Eurex 30",
            "en-gb": "DM 21 standard Eurex 30",
            "en-us": "DM 21 standard Eurex 30",
            "es": "DM 21 Estándar Eurex 30",
            "fr": "DM 21 standard Eurex 30",
            "it": "DM 21 Standard Eurex 30",
            "hu": "DM 21 Standard Eurex 30",
            "ru": "DM 21 Standard Eurex 30",
            "sk": "DM 21 standard Eurex 30",
            "pl": "DM 21 standard Eurex 30"
        },
        {
            "ID": "XTRA_DTISCH_KL34",
            "de": "DM 21 RS versetzt, 1ZS mit Schwenkkopf Eurex 30",
            "en-gb": "DM 21 EP repositioned, 1 IP with swivel head Eurex 30",
            "en-us": "DM 21 EP repositioned, 1 IP with swivel head Eurex 30",
            "es": "DM 21 PB desplazado, 1 PI con cabezal abatible Eurex 30",
            "fr": "DM 21 EB déplacé, 1 EI avec tête orientable Eurex 30",
            "it": "DM 21 PP spostato, 1 PI con testa d'inclinazione Eurex 30",
            "hu": "DM 21 SZT áthelyezve, 1 KT billentofejjel Eurex 30",
            "ru": "DM 21 RS смещенный, 1ZS поворотная головка Eurex 30",
            "sk": "DM 21 OP odsadená, 1MP s výkyvnou hlavicou Eurex 30",
            "pl": "Zmiana położenia DM 21 EP, 1 IP z głowicą obrotową Eurex 30"
        },
        {
            "ID": "XTRA_DTISCH_KL36",
            "de": "DM 21 RS versetzt, 2ZS mit Schwenkkopf Eurex 30",
            "en-gb": "DM 21 EP repositioned, 2 IP with swivel head Eurex 30",
            "en-us": "DM 21 EP repositioned, 2 IP with swivel head Eurex 30",
            "es": "DM 21 PB desplazado, 2 PI con cabezal abatible Eurex 30",
            "fr": "DM 21 EB déplacé, 2 EI avec tête orientable Eurex 30",
            "it": "DM 21 PP spostato, 2 PI con testa d'inclinazione Eurex 30",
            "hu": "DM 21 SZT áthelyezve, 2 KT billentofejjel Eurex 30",
            "ru": "DM 21 RS смещенный, 2ZS поворотная головка Eurex 30",
            "sk": "DM 21 OP odsadená, 2MP s výkyvnou hlavicou Eurex 30",
            "pl": "Zmiana położenia DM 21 EP, 2 IP z głowicą obrotową Eurex 30"
        },
        {
            "ID": "XTRA_DTISCH_KL38",
            "de": "DM 00 Standard Eurex 30",
            "en-gb": "DM 00 standard Eurex 30",
            "en-us": "DM 00 standard Eurex 30",
            "es": "DM 00 Estándar Eurex 30",
            "fr": "DM 00 standard Eurex 30",
            "it": "DM 00 Standard Eurex 30",
            "hu": "DM 00 Standard Eurex 30",
            "ru": "DM 00 Standard Eurex 30",
            "sk": "DM 00 Standard Eurex 30",
            "pl": "DM 00 standard Eurex 30"
        },
        {
            "ID": "XTRA_DTISCH_KL39",
            "de": "DM 00 RS versetzt, 1ZS mit Schwenkkopf Eurex 30",
            "en-gb": "DM 00 EP repositioned, 1 IP with swivel head Eurex 30",
            "en-us": "DM 00 EP repositioned, 1 IP with swivel head Eurex 30",
            "es": "DM 00 PB desplazado, 1 PI con cabezal abatible Eurex 30",
            "fr": "DM 00 EB déplacé, 1 EI avec tête orientable Eurex 30",
            "it": "DM 00 PP spostato, 1 PI con testa d'inclinazione Eurex 30",
            "hu": "DM 00 SZT áthelyezve, 1 KT billentofejjel Eurex 30",
            "ru": "DM 00 RS смещенный, 1ZS поворотная головка Eurex 30",
            "sk": "DM 00 OP odsadená, 1MP s výkyvnou hlavicou Eurex 30",
            "pl": "Zmiana położenia DM 00 EP, 1 IP z głowicą obrotową Eurex 30"
        },
        {
            "ID": "XTRA_DTISCH_KL41",
            "de": "DM 00 RS versetzt, 2ZS mit Schwenkkopf Eurex 30",
            "en-gb": "DM 00 EP repositioned, 2 IP with swivel head Eurex 30",
            "en-us": "DM 00 EP repositioned, 2 IP with swivel head Eurex 30",
            "es": "DM 00 PB desplazado, 2 PI con cabezal abatible Eurex 30",
            "fr": "DM 00 EB déplacé, 2 EI avec tête orientable Eurex 30",
            "it": "DM 00 PP spostato, 2 PI con testa d'inclinazione Eurex 30",
            "hu": "DM 00 SZT áthelyezve, 2 KT billentofejjel Eurex 30",
            "ru": "DM 00 RS смещенный, 2ZS поворотная головка Eurex 30",
            "sk": "DM 00 OP odsadená, 2MP s výkyvnou hlavicou Eurex 30",
            "pl": "Zmiana położenia DM 00 EP, 2 IP z głowicą obrotową Eurex 30"
        },
        {
            "ID": "XTRA_DTISCH_KL43",
            "de": "DM 27 Standard Eurex 30",
            "en-gb": "DM 27 standard Eurex 30",
            "en-us": "DM 27 standard Eurex 30",
            "es": "DM 27 Estándar Eurex 30",
            "fr": "DM 27 standard Eurex 30",
            "it": "DM 27 Standard Eurex 30",
            "hu": "DM 27 Standard Eurex 30",
            "ru": "DM 27 Standard Eurex 30",
            "sk": "DM 27 standard Eurex 30",
            "pl": "DM 27 standard Eurex 30"
        },
        {
            "ID": "XTRA_DTISCH_KL44",
            "de": "DM 27 RS versetzt, 1ZS mit Schwenkkopf Eurex 30",
            "en-gb": "DM 27 EP repositioned, 1 IP with swivel head Eurex 30",
            "en-us": "DM 27 EP repositioned, 1 IP with swivel head Eurex 30",
            "es": "DM 27 PB desplazado, 1 PI con cabezal abatible Eurex 30",
            "fr": "DM 27 EB déplacé, 1 EI avec tête orientable Eurex 30",
            "it": "DM 27 PP spostato, 1 PI con testa d'inclinazione Eurex 30",
            "hu": "DM 27 SZT áthelyezve, 1 KT billentofejjel Eurex 30",
            "ru": "DM 27 RS смещенный, 1ZS поворотная головка Eurex 30",
            "sk": "DM 27 OP odsadená, 1PP so výkyvnou hlavicou Eurex 30",
            "pl": "Zmiana położenia DM 27 EP, 1 IP z głowicą obrotową Eurex 30"
        },
        {
            "ID": "XTRA_DTISCH_KL46",
            "de": "DM 27 RS versetzt, 2ZS mit Schwenkkopf Eurex 30",
            "en-gb": "DM 27 EP repositioned, 2 IP with swivel head Eurex 30",
            "en-us": "DM 27 EP repositioned, 2 IP with swivel head Eurex 30",
            "es": "DM 27 PB desplazado, 2 PI con cabezal abatible Eurex 30",
            "fr": "DM 27 EB déplacé, 2 EI avec tête orientable Eurex 30",
            "it": "DM 27 PP spostato, 2 PI con testa d'inclinazione Eurex 30",
            "hu": "DM 27 SZT áthelyezve, 2 KT billentofejjel Eurex 30",
            "ru": "DM 27 RS смещенный, 2ZS поворотная головка Eurex 30",
            "sk": "DM 27 OP odsadená, 2PPMPso výkyvnou hlavicou Eurex 30",
            "pl": "Zmiana położenia DM 27 EP, 2 IP z głowicą obrotową Eurex 30"
        },
        {
            "ID": "XTRA_DTISCH_KL50",
            "de": "DM 21 Standard Eurex top 20",
            "en-gb": "DM 21 standard Eurex top 20",
            "en-us": "DM 21 standard Eurex top 20",
            "es": "DM 21 Estándar Eurex top 20",
            "fr": "DM 21 standard Eurex top 20",
            "it": "DM 21 Standard Eurex top 20",
            "hu": "DM 21 Standard Eurex top 20",
            "ru": "DM 21 Standard Eurex top 20",
            "sk": "DM 21 standard Eurex top 20",
            "pl": "DM 21 standard Eurex top 20"
        },
        {
            "ID": "XTRA_DTISCH_KL51",
            "de": "DM 21 RS versetzt, 1ZS Schwenkkopf Eurex top 20",
            "en-gb": "DM 21 EP repositioned, 1 IP with swivel head Eurex top 20",
            "en-us": "DM 21 EP repositioned, 1 IP with swivel head Eurex top 20",
            "es": "DM 21 PB desplazado, 1 PI con cabezal abatible Eurex top 20",
            "fr": "DM 21 EB déplacé, 1 EI avec tête orientable Eurex top 20",
            "it": "DM 21 PP spostato, 1 PI con testa d'inclinazione Eurex top 20",
            "hu": "DM 21 SZT áthelyezve, 1 KT billentofejjel Eurex top 20",
            "ru": "DM 21 RS смещенный, 1ZS поворотная головка Eurex top 20",
            "sk": "DM 21 OP odsadená, 1MP s výkyvnou hlavicou Eurex top 20",
            "pl": "Zmiana położenia DM 21 EP, 1 IP z głowicą obrotową Eurex top 20"
        },
        {
            "ID": "XTRA_DTISCH_KL52",
            "de": "DM 21 RS versetzt, 1ZS Stützenanschluss Eurex top 20/30",
            "en-gb": "DM 21 EP repositioned, 1 IP with prop connection Eurex top 20/30",
            "en-us": "DM 21 EP repositioned, 1 IP with prop connection Eurex top 20/30",
            "es": "DM 21 PB desplazado, 1 PI con cabezal conector de puntales Eurex top 20/30",
            "fr": "DM 21 EB déplacé, 1 EI avec raccord d'étai Eurex top 20/30",
            "it": "DM 21 PP spostato, 1 PI con collegamento Eurex top 20/30",
            "hu": "DM 21 SZT áthelyezve, 1 KT támaszcsatlakozóval Eurex top 20/30",
            "ru": "DM 21 RS смещенный, 1ZS переходник для колонны Eurex top 20/30",
            "sk": "DM 21 OP odsadená, 1MP so spojkou podpery Eurex top 20/30",
            "pl": "Zmiana położenia DM 21 EP, 1 IP z połączeniem podpory Eurex top 20/30"
        },
        {
            "ID": "XTRA_DTISCH_KL53",
            "de": "DM 21 RS versetzt, 2ZS Schwenkkopf Eurex top 20",
            "en-gb": "DM 21 EP repositioned, 2 IP with swivel head Eurex top 20",
            "en-us": "DM 21 EP repositioned, 2 IP with swivel head Eurex top 20",
            "es": "DM 21 PB desplazado, 2 PI con cabezal abatible Eurex top 20",
            "fr": "DM 21 EB déplacé, 2 EI avec tête orientable Eurex top 20",
            "it": "DM 21 PP spostato, 2 PI con testa d'inclinazione Eurex top 20",
            "hu": "DM 21 SZT áthelyezve, 2 KT billentofejjel Eurex top 20",
            "ru": "DM 21 RS смещенный, 2ZS поворотная головка Eurex top 20",
            "sk": "DM 21 OP odsadená, 2MP s výkyvnou hlavicou Eurex top 20",
            "pl": "Zmiana położenia DM 21 EP, 2 IP z głowicą obrotową Eurex top 20"
        },
        {
            "ID": "XTRA_DTISCH_KL54",
            "de": "DM 21 RS versetzt, 2ZS Stützenanschluss Eurex top 20/30",
            "en-gb": "DM 21 EP repositioned, 2 IP with prop connection Eurex top 20/30",
            "en-us": "DM 21 EP repositioned, 2 IP with prop connection Eurex top 20/30",
            "es": "DM 21 PB desplazado, 2 PI con cabezal conector de puntales Eurex top 20/30",
            "fr": "DM 21 EB déplacé, 2 EI avec raccord d'étai Eurex top 20/30",
            "it": "DM 21 PP spostato, 2 PI con collegamento Eurex top 20/30",
            "hu": "DM 21 SZT áthelyezve, 2 KT támaszcsatlakozóval Eurex top 20/30",
            "ru": "DM 21 RS смещенный, 2ZS переходник для колонны Eurex top 20/30",
            "sk": "DM 21 OP odsadená, 2MP so spojkou podpery Eurex top 20/30",
            "pl": "Zmiana położenia DM 21 EP, 2 IP z połączeniem podpory Eurex top 20/30"
        },
        {
            "ID": "XTRA_DTISCH_KL55",
            "de": "Dokaflex-Tisch 21 mm, Deckenstütze Eurex top 20",
            "en-gb": "Dokaflex table 21 mm, floor prop Eurex top 20",
            "en-us": "Dokaflex table 21 mm, floor prop Eurex top 20",
            "es": "Mesa Dokaflex 21 mm , Puntal Eurex top 20",
            "fr": "Table Dokaflex 21 mm, Etai Eurex top 20",
            "it": "Tavolo Dokaflex 21 mm , puntello Eurex top 20",
            "hu": "Dokaflex asztal 21 mm , födémtámasz Eurex top 20",
            "ru": "Стол Dokaflex 21 мм , стойка для перекрытий Eurex top 20",
            "sk": "Dokaflex stôl 21 mm, stropná podpera Eurex top 20",
            "pl": "Stolik Dokaflex 21 mm, podpora stropowa Eurex top 20"
        },
        {
            "ID": "XTRA_DTISCH_KL56",
            "de": "Dokaflex-Tisch 21 mm, Deckenstütze Eurex top 30",
            "en-gb": "Dokaflex table 21 mm, floor prop Eurex top 30",
            "en-us": "Dokaflex table 21 mm, floor prop Eurex top 30",
            "es": "Mesa Dokaflex 21 mm , Puntal Eurex top 30",
            "fr": "Table Dokaflex 21 mm, Etai Eurex top 30",
            "it": "Tavolo Dokaflex 21 mm , puntello Eurex top 30",
            "hu": "Dokaflex asztal 21 mm , födémtámasz Eurex top 30",
            "ru": "Стол Dokaflex 21 мм , стойка для перекрытий Eurex top 30",
            "sk": "Dokaflex stôl 21 mm, stropná podpera Eurex top 30",
            "pl": "Stolik Dokaflex 21 mm, podpora stropowa Eurex top 30"
        },
        {
            "ID": "XTRA_DTISCH_KL57",
            "de": "DM 00 Standard Eurex top 20",
            "en-gb": "DM 00 standard Eurex top 20",
            "en-us": "DM 00 standard Eurex top 20",
            "es": "DM 00 Estándar Eurex top 20",
            "fr": "DM 00 standard Eurex top 20",
            "it": "DM 00 Standard Eurex top 20",
            "hu": "DM 00 Standard Eurex top 20",
            "ru": "DM 00 Standard Eurex top 20",
            "sk": "DM 00 Standard Eurex top 20",
            "pl": "DM 00 standard Eurex top 20"
        },
        {
            "ID": "XTRA_DTISCH_KL58",
            "de": "DM 00 RS versetzt, 1ZS Schwenkkopf Eurex top 20",
            "en-gb": "DM 00 EP repositioned, 1 IP with swivel head Eurex top 20",
            "en-us": "DM 00 EP repositioned, 1 IP with swivel head Eurex top 20",
            "es": "DM 00 PB desplazado, 1 PI con cabezal abatible Eurex top 20",
            "fr": "DM 00 EB déplacé, 1 EI avec tête orientable Eurex top 20",
            "it": "DM 00 PP spostato, 1 PI con testa d'inclinazione Eurex top 20",
            "hu": "DM 00 SZT áthelyezve, 1 KT billentofejjel Eurex top 20",
            "ru": "DM 00 RS смещенный, 1ZS поворотная головка Eurex top 20",
            "sk": "DM 00 OP odsadená, 1MP s výkyvnou hlavicou Eurex top 20",
            "pl": "Zmiana położenia DM 00 EP, 1 IP z głowicą obrotową Eurex top 20"
        },
        {
            "ID": "XTRA_DTISCH_KL59",
            "de": "DM 00 RS versetzt, 1ZS Stützenanschluss Eurex top 20/30",
            "en-gb": "DM 00 EP repositioned, 1 IP with prop connection Eurex top 20/30",
            "en-us": "DM 00 EP repositioned, 1 IP with prop connection Eurex top 20/30",
            "es": "DM 00 PB desplazado, 1 PI con cabezal conector de puntales Eurex top 20/30",
            "fr": "DM 00 EB déplacé, 1 EI avec raccord d'étai Eurex top 20/30",
            "it": "DM 00 PP spostato, 1 PI con collegamento Eurex top 20/30",
            "hu": "DM 00 SZT áthelyezve, 1 KT támaszcsatlakozóval Eurex top 20/30",
            "ru": "DM 00 RS смещенный, 1ZS переходник для колонны Eurex top 20/30",
            "sk": "DM 00 OP odsadená, 1MP so spojkou podpery Eurex top 20/30",
            "pl": "Zmiana położenia DM 00 EP, 1 IP z połączeniem podpory Eurex top 20/30"
        },
        {
            "ID": "XTRA_DTISCH_KL60",
            "de": "DM 00 RS versetzt, 2ZS Schwenkkopf Eurex top 20",
            "en-gb": "DM 00 EP repositioned, 2 IP with swivel head Eurex top 20",
            "en-us": "DM 00 EP repositioned, 2 IP with swivel head Eurex top 20",
            "es": "DM 00 PB desplazado, 2 PI con cabezal abatible Eurex top 20",
            "fr": "DM 00 EB déplacé, 2 EI avec tête orientable Eurex top 20",
            "it": "DM 00 PP spostato, 2 PI con testa d'inclinazione Eurex top 20",
            "hu": "DM 00 SZT áthelyezve, 2 KT billentofejjel Eurex top 20",
            "ru": "DM 00 RS смещенный, 2ZS поворотная головка Eurex top 20",
            "sk": "DM 00 OP odsadená, 2MP s výkyvnou hlavicou Eurex top 20",
            "pl": "Zmiana położenia DM 00 EP, 2 IP z głowicą obrotową Eurex top 20"
        },
        {
            "ID": "XTRA_DTISCH_KL61",
            "de": "DM 00 RS versetzt, 2ZS Stützenanschluss Eurex top 20/30",
            "en-gb": "DM 00 EP repositioned, 2 IP with prop connection Eurex top 20/30",
            "en-us": "DM 00 EP repositioned, 2 IP with prop connection Eurex top 20/30",
            "es": "DM 00 PB desplazado, 2 PI con cabezal conector de puntales Eurex top 20/30",
            "fr": "DM 00 EB déplacé, 2 EI avec raccord d'étai Eurex top 20/30",
            "it": "DM 00 PP spostato, 2 PI con collegamento Eurex top 20/30",
            "hu": "DM 00 SZT áthelyezve, 2 KT támaszcsatlakozóval Eurex top 20/30",
            "ru": "DM 00 RS смещенный, 2ZS переходник для колонны Eurex top 20/30",
            "sk": "DM 00 OP odsadená, 2MP so spojkou podpery Eurex top 20/30",
            "pl": "Zmiana położenia DM 00 EP, 2 IP z połączeniem podpory Eurex top 20/30"
        },
        {
            "ID": "XTRA_DTISCH_KL62",
            "de": "DM 21 Standard Eurex top 30",
            "en-gb": "DM 21 standard Eurex top 30",
            "en-us": "DM 21 standard Eurex top 30",
            "es": "DM 21 Estándar Eurex top 30",
            "fr": "DM 21 standard Eurex top 30",
            "it": "DM 21 Standard Eurex top 30",
            "hu": "DM 21 Standard Eurex top 30",
            "ru": "DM 21 Standard Eurex top 30",
            "sk": "DM 21 standard Eurex top 30",
            "pl": "DM 21 standard Eurex top 30"
        },
        {
            "ID": "XTRA_DTISCH_KL63",
            "de": "DM 21 RS versetzt, 1ZS Schwenkkopf Eurex top 30",
            "en-gb": "DM 21 EP repositioned, 1 IP with swivel head Eurex top 30",
            "en-us": "DM 21 EP repositioned, 1 IP with swivel head Eurex top 30",
            "es": "DM 21 PB desplazado, 1 PI con cabezal abatible Eurex top 30",
            "fr": "DM 21 EB déplacé, 1 EI avec tête orientable Eurex top 30",
            "it": "DM 21 PP spostato, 1 PI con testa d'inclinazione Eurex top 30",
            "hu": "DM 21 SZT áthelyezve, 1 KT billentofejjel Eurex top 30",
            "ru": "DM 21 RS смещенный, 1ZS поворотная головка Eurex top 30",
            "sk": "DM 21 OP odsadená, 1MP s výkyvnou hlavicou Eurex top 30",
            "pl": "Zmiana położenia DM 21 EP, 1 IP z głowicą obrotową Eurex top 30"
        },
        {
            "ID": "XTRA_DTISCH_KL64",
            "de": "DM 21 RS versetzt, 2ZS Schwenkkopf Eurex top 30",
            "en-gb": "DM 21 EP repositioned, 2 IP with swivel head Eurex top 30",
            "en-us": "DM 21 EP repositioned, 2 IP with swivel head Eurex top 30",
            "es": "DM 21 PB desplazado, 2 PI con cabezal abatible Eurex top 30",
            "fr": "DM 21 EB déplacé, 2 EI avec tête orientable Eurex top 30",
            "it": "DM 21 PP spostato, 2 PI con testa d'inclinazione Eurex top 30",
            "hu": "DM 21 SZT áthelyezve, 2 KT billentofejjel Eurex top 30",
            "ru": "DM 21 RS смещенный, 2ZS поворотная головка Eurex top 30",
            "sk": "DM 21 OP odsadená, 2MP s výkyvnou hlavicou Eurex top 30",
            "pl": "Zmiana położenia DM 21 EP, 2 IP z głowicą obrotową Eurex top 30"
        },
        {
            "ID": "XTRA_DTISCH_KL65",
            "de": "DM 00 Standard Eurex top 30",
            "en-gb": "DM 00 standard Eurex top 30",
            "en-us": "DM 00 standard Eurex top 30",
            "es": "DM 00 Estándar Eurex top 30",
            "fr": "DM 00 standard Eurex top 30",
            "it": "DM 00 Standard Eurex top 30",
            "hu": "DM 00 Standard Eurex top 30",
            "ru": "DM 00 Standard Eurex top 30",
            "sk": "DM 00 Standard Eurex top 30",
            "pl": "DM 00 standard Eurex top 30"
        },
        {
            "ID": "XTRA_DTISCH_KL66",
            "de": "DM 00 RS versetzt, 1ZS Schwenkkopf Eurex top 30",
            "en-gb": "DM 00 EP repositioned, 1 IP with swivel head Eurex top 30",
            "en-us": "DM 00 EP repositioned, 1 IP with swivel head Eurex top 30",
            "es": "DM 00 PB desplazado, 1 PI con cabezal abatible Eurex top 30",
            "fr": "DM 00 EB déplacé, 1 EI avec tête orientable Eurex top 30",
            "it": "DM 00 PP spostato, 1 PI con testa d'inclinazione Eurex top 30",
            "hu": "DM 00 SZT áthelyezve, 1 KT billentofejjel Eurex top 30",
            "ru": "DM 00 RS смещенный, 1ZS поворотная головка Eurex top 30",
            "sk": "DM 00 OP odsadená, 1MP s výkyvnou hlavicou Eurex top 30",
            "pl": "Zmiana położenia DM 00 EP, 1 IP z głowicą obrotową Eurex top 30"
        },
        {
            "ID": "XTRA_DTISCH_KL67",
            "de": "DM 00 RS versetzt, 2ZS Schwenkkopf Eurex top 30",
            "en-gb": "DM 00 EP repositioned, 2 IP with swivel head Eurex top 30",
            "en-us": "DM 00 EP repositioned, 2 IP with swivel head Eurex top 30",
            "es": "DM 00 PB desplazado, 2 PI con cabezal abatible Eurex top 30",
            "fr": "DM 00 EB déplacé, 2 EI avec tête orientable Eurex top 30",
            "it": "DM 00 PP spostato, 2 PI con testa d'inclinazione Eurex top 30",
            "hu": "DM 00 SZT áthelyezve, 2 KT billentofejjel Eurex top 30",
            "ru": "DM 00 RS смещенный, 2ZS поворотная головка Eurex top 30",
            "sk": "DM 00 OP odsadená, 2MP s výkyvnou hlavicou Eurex top 30",
            "pl": "Zmiana położenia DM 00 EP, 2 IP z głowicą obrotową Eurex top 30"
        },
        {
            "ID": "XTRA_DTISCH_KL68",
            "de": "DM 27 Standard Eurex top 20",
            "en-gb": "DM 27 standard Eurex top 20",
            "en-us": "DM 27 standard Eurex top 20",
            "es": "DM 27 Estándar Eurex top 20",
            "fr": "DM 27 standard Eurex top 20",
            "it": "DM 27 Standard Eurex top 20",
            "hu": "DM 27 Standard Eurex top 20",
            "ru": "DM 27 Standard Eurex top 20",
            "sk": "DM 27 standard Eurex top 20",
            "pl": "DM 27 standard Eurex top 20"
        },
        {
            "ID": "XTRA_DTISCH_KL69",
            "de": "DM 27 RS versetzt, 1ZS Schwenkkopf Eurex top 20",
            "en-gb": "DM 27 EP repositioned, 1 IP with swivel head Eurex top 20",
            "en-us": "DM 27 EP repositioned, 1 IP with swivel head Eurex top 20",
            "es": "DM 27 PB desplazado, 1 PI con cabezal abatible Eurex top 20",
            "fr": "DM 27 EB déplacé, 1 EI avec tête orientable Eurex top 20",
            "it": "DM 27 PP spostato, 1 PI con testa d'inclinazione Eurex top 20",
            "hu": "DM 27 SZT áthelyezve, 1 KT billentofejjel Eurex top 20",
            "ru": "DM 27 RS смещенный, 1ZS поворотная головка Eurex top 20",
            "sk": "DM 27 OP odsadená, 1MPS s výkyvnou hlavicou Eurex top 20",
            "pl": "Zmiana położenia DM 27 EP, 1 IP z głowicą obrotową Eurex top 20"
        },
        {
            "ID": "XTRA_DTISCH_KL70",
            "de": "DM 27 RS versetzt, 1ZS Stützenanschluss Eurex top 20/30",
            "en-gb": "DM 27 EP repositioned, 1 IP with prop connection Eurex top 20/30",
            "en-us": "DM 27 EP repositioned, 1 IP with prop connection Eurex top 20/30",
            "es": "DM 27 PB desplazado, 1 PI con cabezal conector de puntales Eurex top 20/30",
            "fr": "DM 27 EB déplacé, 1 EI avec raccord d'étai Eurex top 20/30",
            "it": "DM 27 PP spostato, 1 PI con collegamento Eurex top 20/30",
            "hu": "DM 27 SZT áthelyezve, 1 KT támaszcsatlakozóval Eurex top 20/30",
            "ru": "DM 27 RS смещенный, 1ZS переходник для колонны Eurex top 20/30",
            "sk": "DM 27 OP odsadená, 1MPS so spojkou podpery Eurex top 20/30",
            "pl": "Zmiana położenia DM 27 EP, 1 IP z połączeniem podpory Eurex top 20/30"
        },
        {
            "ID": "XTRA_DTISCH_KL71",
            "de": "DM 27 RS versetzt, 2ZS Schwenkkopf Eurex top 20",
            "en-gb": "DM 27 EP repositioned, 2 IP with swivel head Eurex top 20",
            "en-us": "DM 27 EP repositioned, 2 IP with swivel head Eurex top 20",
            "es": "DM 27 PB desplazado, 2 PI con cabezal abatible Eurex top 20",
            "fr": "DM 27 EB déplacé, 2 EI avec tête orientable Eurex top 20",
            "it": "DM 27 PP spostato, 2 PI con testa d'inclinazione Eurex top 20",
            "hu": "DM 27 SZT áthelyezve, 2 KT billentofejjel Eurex top 20",
            "ru": "DM 27 RS смещенный, 2ZS поворотная головка Eurex top 20",
            "sk": "DM 27 OP odsadená, 2MPS s výkyvnou hlavicou Eurex top 20",
            "pl": "Zmiana położenia DM 27 EP, 2 IP z głowicą obrotową Eurex top 20"
        },
        {
            "ID": "XTRA_DTISCH_KL72",
            "de": "DM 27 RS versetzt, 2ZS Stützenanschluss Eurex top 20/30",
            "en-gb": "DM 27 EP repositioned, 2 IP with prop connection Eurex top 20/30",
            "en-us": "DM 27 EP repositioned, 2 IP with prop connection Eurex top 20/30",
            "es": "DM 27 PB desplazado, 2 PI con cabezal conector de puntales Eurex top 20/30",
            "fr": "DM 27 EB déplacé, 2 EI avec raccord d'étai Eurex top 20/30",
            "it": "DM 27 PP spostato, 2 PI con collegamento Eurex top 20/30",
            "hu": "DM 27 SZT áthelyezve, 2 KT támaszcsatlakozóval Eurex top 20/30",
            "ru": "DM 27 RS смещенный, 2ZS переходник для колонны Eurex top 20/30",
            "sk": "DM 27 OP odsadená, 2MPS so spojkou podpery Eurex top 20/30",
            "pl": "Zmiana położenia DM 27 EP, 2 IP z połączeniem podpory Eurex top 20/30"
        },
        {
            "ID": "XTRA_DTISCH_KL73",
            "de": "Dokaflextisch 27 mm, Deckenstütze Eurex top 20",
            "en-gb": "Dokaflex table 27 mm, floor prop Eurex top 20",
            "en-us": "Dokaflex table 27 mm, floor prop Eurex top 20",
            "es": "Mesa Dokaflex 27 mm , Puntal Eurex top 20",
            "fr": "Table Dokaflex 27 mm , Etai Eurex top 20",
            "it": "Tavolo Dokaflex 27 mm , puntello Eurex top 20",
            "hu": "Dokaflex asztal 27 mm , födémtámasz Eurex top 20",
            "ru": "Стол Dokaflex 27 мм , стойка для перекрытий Eurex top 20",
            "sk": "Dokaflex stôl 27 mm, stropná podpera Eurex top 20",
            "pl": "Stolik Dokaflex 27 mm, podpora stropowa Eurex top 20"
        },
        {
            "ID": "XTRA_DTISCH_KL74",
            "de": "Dokaflextisch 27 mm, Deckenstütze Eurex top 30",
            "en-gb": "Dokaflex table 27 mm, floor prop Eurex top 30",
            "en-us": "Dokaflex table 27 mm, floor prop Eurex top 30",
            "es": "Mesa Dokaflex 27 mm , Puntal Eurex top 30",
            "fr": "Table Dokaflex 27 mm , Etai Eurex top 30",
            "it": "Tavolo Dokaflex 27 mm , puntello Eurex top 30",
            "hu": "Dokaflex asztal 27 mm , födémtámasz Eurex top 30",
            "ru": "Стол Dokaflex 27 мм , стойка для перекрытий Eurex top 30",
            "sk": "Dokaflex stôl 27 mm, stropná podpera Eurex top 30",
            "pl": "Stolik Dokaflex 27 mm, podpora stropowa Eurex top 30"
        },
        {
            "ID": "XTRA_DTISCH_KL75",
            "de": "DM 27 Standard Eurex top 30",
            "en-gb": "DM 27 standard Eurex top 30",
            "en-us": "DM 27 standard Eurex top 30",
            "es": "DM 27 Estándar Eurex top 30",
            "fr": "DM 27 standard Eurex top 30",
            "it": "DM 27 Standard Eurex top 30",
            "hu": "DM 27 Standard Eurex top 30",
            "ru": "DM 27 Standard Eurex top 30",
            "sk": "DM 27 standard Eurex top 30",
            "pl": "DM 27 standard Eurex top 30"
        },
        {
            "ID": "XTRA_DTISCH_KL76",
            "de": "DM 27 RS versetzt, 1ZS Schwenkkopf Eurex top 30",
            "en-gb": "DM 27 EP repositioned, 1 IP with swivel head Eurex top 30",
            "en-us": "DM 27 EP repositioned, 1 IP with swivel head Eurex top 30",
            "es": "DM 27 PB desplazado, 1 PI con cabezal abatible Eurex top 30",
            "fr": "DM 27 EB déplacé, 1 EI avec tête orientable Eurex top 30",
            "it": "DM 27 PP spostato, 1 PI con testa d'inclinazione Eurex top 30",
            "hu": "DM 27 SZT áthelyezve, 1 KT billentofejjel Eurex top 30",
            "ru": "DM 27 RS смещенный, 1ZS поворотная головка Eurex top 30",
            "sk": "DM 27 OP odsadená, 1PP so výkyvnou hlavicou Eurex top 30",
            "pl": "Zmiana położenia DM 27 EP, 1 IP z głowicą obrotową Eurex top 30"
        },
        {
            "ID": "XTRA_DTISCH_KL77",
            "de": "DM 27 RS versetzt, 2ZS Schwenkkopf Eurex top 30",
            "en-gb": "DM 27 EP repositioned, 2 IP with swivel head Eurex top 30",
            "en-us": "DM 27 EP repositioned, 2 IP with swivel head Eurex top 30",
            "es": "DM 27 PB desplazado, 2 PI con cabezal abatible Eurex top 30",
            "fr": "DM 27 EB déplacé, 2 EI avec tête orientable Eurex top 30",
            "it": "DM 27 PP spostato, 2 PI con testa d'inclinazione Eurex top 30",
            "hu": "DM 27 SZT áthelyezve, 2 KT billentofejjel Eurex top 30",
            "ru": "DM 27 RS смещенный, 2ZS поворотная головка Eurex top 30",
            "sk": "DM 27 OP odsadená, 2PPMPso výkyvnou hlavicou Eurex top 30",
            "pl": "Zmiana położenia DM 27 EP, 2 IP z głowicą obrotową Eurex top 30"
        },
        {
            "ID": "XTRA30__A11",
            "de": "Xtra Unterzug Ausführung",
            "en-gb": "Xtra drop beam type",
            "en-us": "Xtra drop beam type",
            "es": "Diseño de jácenas Xtra",
            "fr": "Retombée poutre exécution Xtra",
            "it": "Xtra Trave Esecuzione",
            "hu": "Xtra gerenda kialakítás",
            "ru": "Исполнение прогона Xtra",
            "sk": "Xtra prievlaku vytvorenie",
            "pl": "Typ podciągu Xtra"
        },
        {
            "ID": "XTRA30__A11_AW1",
            "de": "Balkenzwinge 20",
            "en-gb": "Beam forming support 20",
            "en-us": "Beam forming support 20",
            "es": "Soporte viga 20",
            "fr": "Équerre de poutre 20",
            "it": "Morsa a squadra per bloccaggio trave 20",
            "hu": "Gerendazsaluzó kaloda 20",
            "ru": "Балочный зажим 20",
            "sk": "Prievlaková klieština 20",
            "pl": "Zacisk belkowy 20"
        },
        {
            "ID": "XTRA30__A11_AW2",
            "de": "bauseitige Kanthölzer",
            "en-gb": "Squared timber by site",
            "en-us": "Squared timber by site",
            "es": "Listón por parte de obra",
            "fr": "Bois fourn. chantier",
            "it": "Travi squadrate in cantiere",
            "hu": "Fabetét helyi anyag",
            "ru": "деревянные брусья (силами заказчика)",
            "sk": "Hranoly dod. stavby",
            "pl": "Kantówka (po stronie budowy)"
        },
        {
            "ID": "XTRA30__A11_AW3",
            "de": "Balkenzwinge 20 + H20 Träger variabel",
            "en-gb": "Beam forming support 20 + H20 beam variable",
            "en-us": "Beam forming support 20 + H20 beam variable",
            "es": "Soporte viga 20 + H20 viga variable",
            "fr": "Équerre de poutre 20 + H20 poutrelle variable",
            "it": "Morsa a sq. per blocc. trave 20 + trave H20 variabili",
            "hu": "Gerendazsaluzó kaloda 20 + H20 variábilis fatartó",
            "ru": "Балочный зажим 20 + Н20 балка различные",
            "sk": "Prievlaková klieština 20 + Variabilná nosník H20",
            "pl": "Zacisk belkowy 20 + H20"
        },
        {
            "ID": "XTRA30__A12",
            "de": "Schaltafel Größe",
            "en-gb": "Shutter Board dimension",
            "en-us": "Shutter Board dimension",
            "es": "Tamaño del tablero de encofrado",
            "fr": "Contreplaqué taille",
            "it": "Grandezza della tavola di casseratura",
            "hu": "Zsaluhéj méret",
            "ru": "Размер опалубочного щита",
            "sk": "Veľkosť dosky stola",
            "pl": "Wymiary deski szalunkowej"
        },
        {
            "ID": "XTRA30__A12_AW3",
            "de": "200/50 cm",
            "en-gb": "200/50 cm",
            "en-us": "200/50 cm",
            "es": "200/50 cm",
            "fr": "200/50 cm",
            "it": "200/50 cm",
            "hu": "200/50 cm",
            "ru": "200/50 cм",
            "sk": "200/50 cm",
            "pl": "200/50 cm"
        },
        {
            "ID": "XTRA30__A12_AW4",
            "de": "250/50 cm",
            "en-gb": "250/50 cm",
            "en-us": "250/50 cm",
            "es": "250/50 cm",
            "fr": "250/50 cm",
            "it": "250/50 cm",
            "hu": "250/50 cm",
            "ru": "250/50 cм",
            "sk": "250/50 cm",
            "pl": "250/50 cm"
        },
        {
            "ID": "XTRA30__A13",
            "de": "Querträgerlänge bevorzugt",
            "en-gb": "Secondary beam length preferred",
            "en-us": "Joist length preferred",
            "es": "Longitud de viga secundaria preferido",
            "fr": "Longueur de poutrelle sec. préféré",
            "it": "Lunghezza trave secondaria preferito",
            "hu": "Kereszttartóhossz preferált",
            "ru": "Предпочтительная длина поперечных балок",
            "sk": "Dĺžka priečnych nos. upred.",
            "pl": "Preferowana długośćdźwigara rusztu górnego"
        },
        {
            "ID": "XTRA30__A13_AW12",
            "de": "8x20/100 cm",
            "en-gb": "8x20/100 cm",
            "en-us": "8x20/100 cm",
            "es": "8x20/100 cm",
            "fr": "8x20/100 cm",
            "it": "8x20/100 cm",
            "hu": "8x20/100 cm",
            "ru": "8x20/100 см",
            "sk": "8x20/100 cm",
            "pl": "8x20/100 cm"
        },
        {
            "ID": "XTRA30__A13_AW13",
            "de": "8x20/125 cm",
            "en-gb": "8x20/125 cm",
            "en-us": "8x20/125 cm",
            "es": "8x20/125 cm",
            "fr": "8x20/125 cm",
            "it": "8x20/125 cm",
            "hu": "8x20/125 cm",
            "ru": "8x20/125 см",
            "sk": "8x20/125 cm",
            "pl": "8x20/125 cm"
        },
        {
            "ID": "XTRA30__A13_AW14",
            "de": "8x20/150 cm",
            "en-gb": "8x20/150 cm",
            "en-us": "8x20/150 cm",
            "es": "8x20/150 cm",
            "fr": "8x20/150 cm",
            "it": "8x20/150 cm",
            "hu": "8x20/150 cm",
            "ru": "8x20/150 см",
            "sk": "8x20/150 cm",
            "pl": "8x20/150 cm"
        },
        {
            "ID": "XTRA30__A13_AW15",
            "de": "8x20/175 cm",
            "en-gb": "8x20/175 cm",
            "en-us": "8x20/175 cm",
            "es": "8x20/175 cm",
            "fr": "8x20/175 cm",
            "it": "8x20/175 cm",
            "hu": "8x20/175 cm",
            "ru": "8x20/175 см",
            "sk": "8x20/175 cm",
            "pl": "8x20/175 cm"
        },
        {
            "ID": "XTRA30__A13_AW16",
            "de": "8x20/200 cm",
            "en-gb": "8x20/200 cm",
            "en-us": "8x20/200 cm",
            "es": "8x20/200 cm",
            "fr": "8x20/200 cm",
            "it": "8x20/200 cm",
            "hu": "8x20/200 cm",
            "ru": "8x20/200 см",
            "sk": "8x20/200 cm",
            "pl": "8x20/200 cm"
        },
        {
            "ID": "XTRA30__A13_AW2",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80 m",
            "es": "1,80 m",
            "fr": "1,80m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "XTRA30__A13_AW4",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65 m",
            "es": "2,65 m",
            "fr": "2,65m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "XTRA30__A14",
            "de": "Jochträgerlänge bevorzugt",
            "en-gb": "Primary beam length preferred",
            "en-us": "Stringer length preferred",
            "es": "Largo de vigas principales preferido",
            "fr": "Longueur dalle raid. préféré",
            "it": "Lunghezza trave principale preferito",
            "hu": "Főtartóhossz preferált",
            "ru": "Предпочтительная длина главных балок",
            "sk": "Dĺžka pozdĺžnych nos. upred.",
            "pl": "Preferowana długość dźwigara rusztu dolnego"
        },
        {
            "ID": "XTRA30__A14_AW12",
            "de": "8x20/100 cm",
            "en-gb": "8x20/100 cm",
            "en-us": "8x20/100 cm",
            "es": "8x20/100 cm",
            "fr": "8x20/100 cm",
            "it": "8x20/100 cm",
            "hu": "8x20/100 cm",
            "ru": "8x20/100 см",
            "sk": "8x20/100 cm",
            "pl": "8x20/100 cm"
        },
        {
            "ID": "XTRA30__A14_AW13",
            "de": "8x20/125 cm",
            "en-gb": "8x20/125 cm",
            "en-us": "8x20/125 cm",
            "es": "8x20/125 cm",
            "fr": "8x20/125 cm",
            "it": "8x20/125 cm",
            "hu": "8x20/125 cm",
            "ru": "8x20/125 см",
            "sk": "8x20/125 cm",
            "pl": "8x20/125 cm"
        },
        {
            "ID": "XTRA30__A14_AW14",
            "de": "8x20/150 cm",
            "en-gb": "8x20/150 cm",
            "en-us": "8x20/150 cm",
            "es": "8x20/150 cm",
            "fr": "8x20/150 cm",
            "it": "8x20/150 cm",
            "hu": "8x20/150 cm",
            "ru": "8x20/150 см",
            "sk": "8x20/150 cm",
            "pl": "8x20/150 cm"
        },
        {
            "ID": "XTRA30__A14_AW15",
            "de": "8x20/175 cm",
            "en-gb": "8x20/175 cm",
            "en-us": "8x20/175 cm",
            "es": "8x20/175 cm",
            "fr": "8x20/175 cm",
            "it": "8x20/175 cm",
            "hu": "8x20/175 cm",
            "ru": "8x20/175 см",
            "sk": "8x20/175 cm",
            "pl": "8x20/175 cm"
        },
        {
            "ID": "XTRA30__A14_AW16",
            "de": "8x20/200 cm",
            "en-gb": "8x20/200 cm",
            "en-us": "8x20/200 cm",
            "es": "8x20/200 cm",
            "fr": "8x20/200 cm",
            "it": "8x20/200 cm",
            "hu": "8x20/200 cm",
            "ru": "8x20/200 см",
            "sk": "8x20/200 cm",
            "pl": "8x20/200 cm"
        },
        {
            "ID": "XTRA30__A14_AW2",
            "de": "1,80 m",
            "en-gb": "1.80 m",
            "en-us": "1.80m",
            "es": "1,80m",
            "fr": "1,80m",
            "it": "1,80 m",
            "hu": "1,80 m",
            "ru": "1,80 м",
            "sk": "1,80 m",
            "pl": "1,80 m"
        },
        {
            "ID": "XTRA30__A14_AW4",
            "de": "2,65 m",
            "en-gb": "2.65 m",
            "en-us": "2.65m",
            "es": "2,65m",
            "fr": "2,65m",
            "it": "2,65 m",
            "hu": "2,65 m",
            "ru": "2,65 м",
            "sk": "2,65 m",
            "pl": "2,65 m"
        },
        {
            "ID": "XTRA30__A15",
            "de": "Deckentischausgleichszubehör",
            "en-gb": "Tableform closure accessories",
            "en-us": "Tableform filler accessories",
            "es": "Accesorios compens. para mesas",
            "fr": "Acc. de comp. de table coff.",
            "it": "Accessori per compensazione dei tavoli solaio",
            "hu": "Asztalzsalu kiegyenlítés tartozék",
            "ru": "Оснастка компенсации опалубочного стола",
            "sk": "Príslušenstvo pre vyrov. strop. stolov",
            "pl": "Akcesoria wypełnień między stołami"
        },
        {
            "ID": "XTRA30__A15_AW1",
            "de": "100/50 cm",
            "en-gb": "100/50 cm",
            "en-us": "100/50 cm",
            "es": "100/50 cm",
            "fr": "100/50 cm",
            "it": "100/50 cm",
            "hu": "100/50 cm",
            "ru": "100/50 cм",
            "sk": "100/50 cm",
            "pl": "100/50 cm"
        },
        {
            "ID": "XTRA30__A15_AW10",
            "de": "244/61 cm",
            "en-gb": "244/61 cm",
            "en-us": "244/61 cm",
            "es": "244/61 cm",
            "fr": "244/61 cm",
            "it": "244/61 cm",
            "hu": "244/61 cm",
            "ru": "244/61 cм",
            "sk": "244/61 cm",
            "pl": "244/61 cm"
        },
        {
            "ID": "XTRA30__A15_AW12",
            "de": "122/61 cm",
            "en-gb": "122/61 cm",
            "en-us": "122/61 cm",
            "es": "122/61 cm",
            "fr": "122/61 cm",
            "it": "122/61 cm",
            "hu": "122/61 cm",
            "ru": "122/61 cм",
            "sk": "122/61 cm",
            "pl": "122/61 cm"
        },
        {
            "ID": "XTRA30__A15_AW13",
            "de": "244/122 cm",
            "en-gb": "244/122 cm",
            "en-us": "244/122 cm",
            "es": "244/122 cm",
            "fr": "244/122 cm",
            "it": "244/122 cm",
            "hu": "244/122 cm",
            "ru": "244/122 cм",
            "sk": "244/122 cm",
            "pl": "244/122 cm"
        },
        {
            "ID": "XTRA30__A15_AW14",
            "de": "14,7/200 cm",
            "en-gb": "14.7/200 cm",
            "en-us": "14.7/200cm",
            "es": "14,7/200cm",
            "fr": "14,7/200cm",
            "it": "14,7/200 cm",
            "hu": "14,7/200 cm",
            "ru": "14,7/200 см",
            "sk": "14,7/200 cm",
            "pl": "14,7/200 cm"
        },
        {
            "ID": "XTRA30__A15_AW15",
            "de": "14,7/250 cm",
            "en-gb": "14.7/250 cm",
            "en-us": "14.7/250cm",
            "es": "14,7/250cm",
            "fr": "14,7/250cm",
            "it": "14,7/250 cm",
            "hu": "14,7/250 cm",
            "ru": "14,7/250 cм",
            "sk": "14,7/250 cm",
            "pl": "14,7/250 cm"
        },
        {
            "ID": "XTRA30__A15_AW2",
            "de": "150/50 cm",
            "en-gb": "150/50 cm",
            "en-us": "150/50 cm",
            "es": "150/50 cm",
            "fr": "150/50 cm",
            "it": "150/50 cm",
            "hu": "150/50 cm",
            "ru": "150/50 cм",
            "sk": "150/50 cm",
            "pl": "150/50 cm"
        },
        {
            "ID": "XTRA30__A15_AW3",
            "de": "200/50 cm",
            "en-gb": "200/50 cm",
            "en-us": "200/50 cm",
            "es": "200/50 cm",
            "fr": "200/50 cm",
            "it": "200/50 cm",
            "hu": "200/50 cm",
            "ru": "200/50 cм",
            "sk": "200/50 cm",
            "pl": "200/50 cm"
        },
        {
            "ID": "XTRA30__A15_AW4",
            "de": "250/50 cm",
            "en-gb": "250/50 cm",
            "en-us": "250/50 cm",
            "es": "250/50 cm",
            "fr": "250/50 cm",
            "it": "250/50 cm",
            "hu": "250/50 cm",
            "ru": "250/50 cм",
            "sk": "250/50 cm",
            "pl": "250/50 cm"
        },
        {
            "ID": "XTRA30__A15_AW5",
            "de": "100/100 cm",
            "en-gb": "100/100 cm",
            "en-us": "100/100 cm",
            "es": "100/100 cm",
            "fr": "100/100 cm",
            "it": "100/100 cm",
            "hu": "100/100 cm",
            "ru": "100/100 см",
            "sk": "100/100 cm",
            "pl": "100/100 cm"
        },
        {
            "ID": "XTRA30__A15_AW6",
            "de": "150/100 cm",
            "en-gb": "150/100 cm",
            "en-us": "150/100cm",
            "es": "150/100cm",
            "fr": "150/100cm",
            "it": "150/100 cm",
            "hu": "150/100 cm",
            "ru": "150/100 см",
            "sk": "150/100 cm",
            "pl": "150/100 cm"
        },
        {
            "ID": "XTRA30__A15_AW7",
            "de": "200/100 cm",
            "en-gb": "200/100 cm",
            "en-us": "200/100 cm",
            "es": "200/100 cm",
            "fr": "200/100 cm",
            "it": "200/100 cm",
            "hu": "200/100 cm",
            "ru": "200/100 см",
            "sk": "200/100 cm",
            "pl": "200/100 cm"
        },
        {
            "ID": "XTRA30__A15_AW8",
            "de": "35/200 cm",
            "en-gb": "35/200 cm",
            "en-us": "35/200 cm",
            "es": "35/200 cm",
            "fr": "35/200 cm",
            "it": "35/200 cm",
            "hu": "35/200 cm",
            "ru": "35/200 см",
            "sk": "35/200 cm",
            "pl": "35/200 cm"
        },
        {
            "ID": "XTRA30__A15_AW9",
            "de": "35/250 cm",
            "en-gb": "35/250 cm",
            "en-us": "35/250 cm",
            "es": "35/250 cm",
            "fr": "35/250 cm",
            "it": "35/250 cm",
            "hu": "35/250 cm",
            "ru": "35/250 cм",
            "sk": "35/250 cm",
            "pl": "35/250 cm"
        },
        {
            "ID": "XTRA30__A16",
            "de": "Stützbeine",
            "en-gb": "Removable folding tripods",
            "en-us": "Removable folding tripods",
            "es": "Trípodes plegables",
            "fr": "Trépieds amovibles",
            "it": "Treppiedi amovibili",
            "hu": "Támasztólábak",
            "ru": "треноги",
            "sk": "Oporné trojnožky",
            "pl": "Trójnogi składane"
        },
        {
            "ID": "XTRA30__A16_AW1",
            "de": "Stützbein",
            "en-gb": "Removable folding tripod",
            "en-us": "Removable folding tripod",
            "es": "Trípode plegable",
            "fr": "Trépied amovible",
            "it": "Treppiede amovibile",
            "hu": "Támasztóláb",
            "ru": "Тренога",
            "sk": "Oporná trojnožka",
            "pl": "Trójnóg"
        },
        {
            "ID": "XTRA30__A16_AW2",
            "de": "Stützbein top",
            "en-gb": "Removable folding tripod top",
            "en-us": "Removable folding tripod top",
            "es": "Trípode plegable top",
            "fr": "Trépied amovible top",
            "it": "Treppiede amovibile top",
            "hu": "Támasztóláb top",
            "ru": "Тренога top",
            "sk": "Oporná trojnožka top",
            "pl": "Trójnóg top"
        },
        {
            "ID": "XTRA30__A17",
            "de": "Einschubträger",
            "en-gb": "Insertion beam",
            "en-us": "Insertion beam",
            "es": "Viga de extensión",
            "fr": "Poutrelle de compensation",
            "it": "Prolunga orizzontale",
            "hu": "Kiegészítéstartó",
            "ru": "Вдвижная балка",
            "sk": "Zasúvací nosník",
            "pl": "Dźwigar wsuwany"
        },
        {
            "ID": "XTRA30__A17_AW1",
            "de": "Einschubträger",
            "en-gb": "Insertion beam",
            "en-us": "Insertion beam",
            "es": "Viga de extensión",
            "fr": "Poutrelle de compensation",
            "it": "Prolunga orizzontale",
            "hu": "Kiegészítéstartó",
            "ru": "Вдвижная балка",
            "sk": "Zasúvací nosník",
            "pl": "Dźwigar wsuwany"
        },
        {
            "ID": "XTRA30__A17_AW2",
            "de": "Dokamatic-Einschubträger",
            "en-gb": "Dokamatic insertion beam",
            "en-us": "Dokamatic insertion beam",
            "es": "Viga de extensión Dokamatic",
            "fr": "Poutrelle de compensation Dokamatic",
            "it": "Prolunga orizzontale Dokamatic",
            "hu": "Dokamatic kihúzható tartó",
            "ru": "Dokamatic вдвижная балка",
            "sk": "Dokamatic-zasúvací nosník",
            "pl": "Dźwigar wsuwany Dokamatic"
        },
        {
            "ID": "XTRA30__A2",
            "de": "Deckentisch Ausführung",
            "en-gb": "Tableform type",
            "en-us": "Tableform type",
            "es": "Mesa del techo realisación",
            "fr": "Table exécution",
            "it": "Esecuzione per tavolo solaio",
            "hu": "Asztalzsalu kialakítás",
            "ru": "Исполнение опалубочного стола",
            "sk": "Stropný stôl-vytvorenie",
            "pl": "Typ stołu"
        },
        {
            "ID": "XTRA30__A2_AW1",
            "de": "Dokamatic-Tisch",
            "en-gb": "Dokamatic table",
            "en-us": "Dokamatic table",
            "es": "Mesa Dokamatic",
            "fr": "Dokamatic-table",
            "it": "Tavolo Dokamatic",
            "hu": "Dokamatic asztal",
            "ru": "Стол Dokamatic",
            "sk": "Dokamatic-stôl",
            "pl": "Stolik Dokamatic"
        },
        {
            "ID": "XTRA30__A2_AW2",
            "de": "Dokaflex-Tisch",
            "en-gb": "Dokaflex table",
            "en-us": "Dokaflex table",
            "es": "Mesa Dokaflex",
            "fr": "Table Dokaflex",
            "it": "Tavolo Dokaflex",
            "hu": "Dokaflex asztal",
            "ru": "Стол Dokaflex",
            "sk": "Dokaflex-stôl",
            "pl": "Stolik Dokaflex"
        },
        {
            "ID": "XTRA30__A3",
            "de": "Deckentisch Schalhaut",
            "en-gb": "Tableform plywood",
            "en-us": "Tableform plywood",
            "es": "Mesa del techo forro",
            "fr": "Table peau coffrante",
            "it": "Pannello per tavolo solaio",
            "hu": "Asztalzsalu zsaluhéj",
            "ru": "Опалубочный стол палуба",
            "sk": "Stropný stôl-deb. doska",
            "pl": "Sklejka do stołu"
        },
        {
            "ID": "XTRA30__A3_AW1",
            "de": "3SO-21",
            "en-gb": "3SO-21",
            "en-us": "3SO-21",
            "es": "3SO-21",
            "fr": "3SO-21",
            "it": "3SO-21",
            "hu": "3SO-21",
            "ru": "3SO-21",
            "sk": "3SO-21",
            "pl": "3SO-21"
        },
        {
            "ID": "XTRA30__A3_AW2",
            "de": "3SO-27",
            "en-gb": "3SO-27",
            "en-us": "3SO-27",
            "es": "3SO-27",
            "fr": "3SO-27",
            "it": "3SO-27",
            "hu": "3SO-27",
            "ru": "3SO-27",
            "sk": "3SO-27",
            "pl": "3SO-27"
        },
        {
            "ID": "XTRA30__A3_AW3",
            "de": "ohne Schalhaut",
            "en-gb": "without plywood",
            "en-us": "without plywood",
            "es": "Sin forro",
            "fr": "Sans peau coffrante",
            "it": "senza pannello",
            "hu": "zsaluhéj nélkül",
            "ru": "без палубы",
            "sk": "bez deb. doska",
            "pl": "bez sklejki"
        },
        {
            "ID": "XTRA30__A4",
            "de": "Deckentisch Unterstellung",
            "en-gb": "Tableform shoring",
            "en-us": "Tableform shoring",
            "es": "Mesa del techo partes inferiores",
            "fr": "Table étaiement",
            "it": "Puntellazione per tavolo solaio",
            "hu": "Asztalzsalu Alátámasztás",
            "ru": "Опалубочный стол основание",
            "sk": "Stropný stôl-podopretie",
            "pl": "System podpór stołu"
        },
        {
            "ID": "XTRA30__A4_AW1",
            "de": "Eurex 20",
            "en-gb": "Eurex 20",
            "en-us": "Eurex 20",
            "es": "Eurex 20",
            "fr": "Eurex 20",
            "it": "Eurex 20",
            "hu": "Eurex 20",
            "ru": "Eurex 20",
            "sk": "Eurex 20",
            "pl": "Eurex 20"
        },
        {
            "ID": "XTRA30__A4_AW2",
            "de": "Eurex 30",
            "en-gb": "Eurex 30",
            "en-us": "Eurex 30",
            "es": "Eurex 30",
            "fr": "Eurex 30",
            "it": "Eurex 30",
            "hu": "Eurex 30",
            "ru": "Eurex 30",
            "sk": "Eurex 30",
            "pl": "Eurex 30"
        },
        {
            "ID": "XTRA30__A4_AW3",
            "de": "Stütze G",
            "en-gb": "Column G",
            "en-us": "Column G",
            "es": "Puntal G",
            "fr": "Étançon G",
            "it": "Puntello G",
            "hu": "Támasz G",
            "ru": "Колонна G",
            "sk": "Podpera G",
            "pl": "Podpora G"
        },
        {
            "ID": "XTRA30__A4_AW4",
            "de": "Stütze N",
            "en-gb": "Column N",
            "en-us": "Column N",
            "es": "Puntal N",
            "fr": "Étançon N",
            "it": "Puntello N",
            "hu": "Támasz N",
            "ru": "Колонна N",
            "sk": "Podpera N",
            "pl": "Podpora N"
        },
        {
            "ID": "XTRA30__A4_AW5",
            "de": "Stütze ECO",
            "en-gb": "Column ECO",
            "en-us": "Column ECO",
            "es": "Puntal ECO",
            "fr": "Étançon ECO",
            "it": "Puntello ECO",
            "hu": "Támasz ECO",
            "ru": "Колонна ЕСО",
            "sk": "Podpera ECO",
            "pl": "Podpora ECO"
        },
        {
            "ID": "XTRA30__A4_AW6",
            "de": "Staxo",
            "en-gb": "Staxo",
            "en-us": "Staxo",
            "es": "Staxo",
            "fr": "Staxo",
            "it": "Staxo",
            "hu": "Staxo",
            "ru": "Staxo",
            "sk": "Staxo",
            "pl": "Staxo"
        },
        {
            "ID": "XTRA30__A4_AW7",
            "de": "Aluxo",
            "en-gb": "Aluxo",
            "en-us": "Aluxo",
            "es": "Aluxo",
            "fr": "Aluxo",
            "it": "Aluxo",
            "hu": "Aluxo",
            "ru": "Aluxo",
            "sk": "Aluxo",
            "pl": "Aluxo"
        },
        {
            "ID": "XTRA30__A4_AW8",
            "de": "Eurex 20 top",
            "en-gb": "Eurex 20 top",
            "en-us": "Eurex 20 top",
            "es": "Eurex 20 top",
            "fr": "Eurex 20 top",
            "it": "Eurex 20 top",
            "hu": "Eurex 20 top",
            "ru": "Eurex 20 top",
            "sk": "Eurex 20 top",
            "pl": "Eurex 20 top"
        },
        {
            "ID": "XTRA30__A4_AW9",
            "de": "Eurex 30 top",
            "en-gb": "Eurex 30 top",
            "en-us": "Eurex 30 top",
            "es": "Eurex 30 top",
            "fr": "Eurex 30 top",
            "it": "Eurex 30 top",
            "hu": "Eurex 30 top",
            "ru": "Eurex 30 top",
            "sk": "Eurex 30 top",
            "pl": "Eurex 30 top"
        },
        {
            "ID": "XTRA30__A5",
            "de": "Deckentisch Unterstellung Randstützen",
            "en-gb": "Tableform Shoring Edge props",
            "en-us": "Tableform Shoring Edge props",
            "es": "Mesa del techo partes inferiores puntales marginales",
            "fr": "Table étaiement étain de poutrelle de rive",
            "it": "Puntellazione per tavolo solaio Puntelli per i bordi",
            "hu": "Asztalzsalu Alátámasztás Szélso támasz",
            "ru": "Опалубочный стол основание краевые колонны",
            "sk": "Stropný stôl-podopretie okrajové podpery",
            "pl": "Stół – podpory krawędzi systemu podpór"
        },
        {
            "ID": "XTRA30__A5_AW1",
            "de": "Standard",
            "en-gb": "Standard",
            "en-us": "Standard",
            "es": "Estándar",
            "fr": "Standard",
            "it": "Standard",
            "hu": "Standard",
            "ru": "Стандарт",
            "sk": "Standard",
            "pl": "Standard"
        },
        {
            "ID": "XTRA30__A5_AW2",
            "de": "RS versetzt, 1ZS mit Schwenkkopf",
            "en-gb": "EP repositioned, 1 IP with swivel head",
            "en-us": "EP repositioned, 1 IP with swivel head",
            "es": "RS desplazado,1 PI con cabezal abatible",
            "fr": "RS déplacé, 1ZS avec tête orientable",
            "it": "PP spostato, 1PI con testa d'inclinazione",
            "hu": "SZT áthelyezve, 1KT billentofejjel",
            "ru": "RS смещенный, 1ZS с поворотной головкой",
            "sk": "OP odsadená, 1MP s výkyvnou hlavicou",
            "pl": "Zmiana położenia EP, 1 IP z głowicą obrotową"
        },
        {
            "ID": "XTRA30__A5_AW3",
            "de": "RS versetzt, 1ZS mit Stützenanschluss",
            "en-gb": "EP repositioned, 1 IP with prop connection",
            "en-us": "EP repositioned, 1 IP with prop connection",
            "es": "RS desplazado,1 PI con cabezal conector de puntales",
            "fr": "RS déplacé, 1ZS avec raccord d'étai",
            "it": "PP spostato, 1PI con collegamento",
            "hu": "SZT áthelyezve, 1KT támaszcsatlakozóval",
            "ru": "RS смещенный, 1ZS с переходником для колонны",
            "sk": "OP odsadená, 1MP so spojkou podpery",
            "pl": "Zmiana położenia EP, 1 IP z połączeniem podpory"
        },
        {
            "ID": "XTRA30__A5_AW4",
            "de": "RS versetzt, 2ZS mit Schwenkkopf",
            "en-gb": "EP repositioned, 2 IP with swivel head",
            "en-us": "EP repositioned, 2 IP with swivel head",
            "es": "RS desplazado2 PI con cabezal abatible",
            "fr": "RS déplacé, 2ZS avec tête orientable",
            "it": "PP spostato, 2PI con testa d'inclinazione",
            "hu": "SZT áthelyezve, 2KT billentofejjel",
            "ru": "RS смещенный, 2ZS с поворотной головкой",
            "sk": "OP odsadená, 2MP s výkyvnou hlavico",
            "pl": "Zmiana położenia EP, 2 IP z głowicą obrotową"
        },
        {
            "ID": "XTRA30__A5_AW5",
            "de": "RS versetzt, 2ZS mit Stützenanschluss",
            "en-gb": "EP repositioned, 2 IP with prop connection",
            "en-us": "EP repositioned, 2 IP with prop connection",
            "es": "RS desplazado,2 PI con cabezal conector de puntales",
            "fr": "RS déplacé, 2ZS avec raccord d'étai",
            "it": "PP spostato, 2PI con collegamento",
            "hu": "SZT áthelyezve, 2KT támaszcsatlakozóval",
            "ru": "RS смещенный, 2ZS с переходником для колонны",
            "sk": "OP odsadená, 2MP so spojkou podpery",
            "pl": "Zmiana położenia EP, 2 IP z połączeniem podpory"
        },
        {
            "ID": "XTRA30__A6",
            "de": "Deckentisch Last-/Fußspindel",
            "en-gb": "Tableform heavy duty screw jack/screw jack food",
            "en-us": "Floor table heavy duty screw jack/screw jack food",
            "es": "Mesa del techo gato atornillable/pie con husillo",
            "fr": "Table vérin de reprise/vérin de pied",
            "it": "Piede / puntello registrabile per tavolo solaio",
            "hu": "Asztalzsalu Teherhordó orsó/Láborsó",
            "ru": "Опалубочный стол шпиндель повышенной нагрузки/опорный шпиндель",
            "sk": "Stropný stôl vret.noha/pätk.vret",
            "pl": "Stół – dźwignik śrubowy o dużej wytrzymałości / podstawa dźwignika śrubowego"
        },
        {
            "ID": "XTRA30__A6_AW1",
            "de": "Lastspindel",
            "en-gb": "Heavy duty screw jack",
            "en-us": "Heavy duty screw jack",
            "es": "Gato atornillable",
            "fr": "Vérin de reprise",
            "it": "Puntello regolabile",
            "hu": "Teherhordó orsó",
            "ru": "Шпиндель повышенной нагрузки",
            "sk": "Vret.noha",
            "pl": "Stopa regulowana wzmocniona"
        },
        {
            "ID": "XTRA30__A6_AW2",
            "de": "Fußspindel",
            "en-gb": "Screw jack foot",
            "en-us": "Screw jack foot",
            "es": "Pie con husillo",
            "fr": "Vérin de pied",
            "it": "Piede registrabile",
            "hu": "Láborsó",
            "ru": "Опорный шпиндель",
            "sk": "Pätk.vret",
            "pl": "Stopa regulowana"
        },
        {
            "ID": "XTRA30__A6_AW3",
            "de": "ohne Spindel",
            "en-gb": "without spindle",
            "en-us": "without spindle",
            "es": "Sin husillo",
            "fr": "sans vérin",
            "it": "senza puntello",
            "hu": "orsó nélkül",
            "ru": "без шпинделя",
            "sk": "bez vretena",
            "pl": "bez wrzeciona"
        },
        {
            "ID": "XTRA30__A7",
            "de": "Schaltafel Typ",
            "en-gb": "Shutter Board type",
            "en-us": "Shutter Board type",
            "es": "Tipo di tablero de encofrado",
            "fr": "Type de panneau",
            "it": "Tipo tavola di cassertaura",
            "hu": "Zsaluhéj típus",
            "ru": "Тип опалубочного щита",
            "sk": "Typ dosky stola",
            "pl": "Rodzaj deski szalunkowej"
        },
        {
            "ID": "XTRA30__A7_AW1",
            "de": "Dokadur-Paneel 21",
            "en-gb": "Dokadur panel 21",
            "en-us": "Dokadur panel 21",
            "es": "Panel Dokadur 21",
            "fr": "Panneau Dokadur 21",
            "it": "Pannello Dokadur 21",
            "hu": "Dokadur tábla 21",
            "ru": "Dokadur панель 21",
            "sk": "Dokadur panel 21",
            "pl": "Panel Dokadur 21"
        },
        {
            "ID": "XTRA30__A7_AW10",
            "de": "Schalungsplatte 3S basic 27mm",
            "en-gb": "Formwork sheet 3S basic 27mm",
            "en-us": "Formwork sheet 3S basic 27mm",
            "es": "Tablero de encofrado 3S basic 27mm",
            "fr": "Panneau 3S basic 27mm",
            "it": "Pannello 3S basic 27mm",
            "hu": "Zsaluhéj 3S basic 27mm",
            "ru": "Опалубочная плита 3S basic 27мм",
            "sk": "Debniaca doska 3S basic 27mm",
            "pl": "Sklejka  3S basic 27mm"
        },
        {
            "ID": "XTRA30__A7_AW11",
            "de": "Schalungsplatte 3S cut 27mm",
            "en-gb": "Formwork sheet 3S cut 27mm",
            "en-us": "Formwork sheet 3S cut 27mm",
            "es": "Tablero de encofrado 3S cut 27mm",
            "fr": "Panneau 3S cut 27mm",
            "it": "Pannello 3S cut 27mm",
            "hu": "Zsaluhéj 3S cut 27mm",
            "ru": "Опалубочная плита 3S cut 27мм",
            "sk": "Debniaca doska 3S cut 27mm",
            "pl": "Sklejka  3S cut 27mm"
        },
        {
            "ID": "XTRA30__A7_AW2",
            "de": "Schalungsplatte 3-SO 21 mm",
            "en-gb": "Formwork sheet 3-SO 21 mm",
            "en-us": "Formwork sheet 3-SO 21 mm",
            "es": "Tablero de encofrado 3-SO 21mm",
            "fr": "Panneau 3-SO 21mm",
            "it": "Pannello 3-SO 21mm",
            "hu": "Zsaluhéj 3-SO 21mm",
            "ru": "Опалубочная плита 3-SO 21мм",
            "sk": "Debniaca doska 3-SO 21mm",
            "pl": "Sklejka  3-SO 21 mm"
        },
        {
            "ID": "XTRA30__A7_AW3",
            "de": "Dokadur-Plex Paneel 21",
            "en-gb": "Dokadur-Plex panel 21",
            "en-us": "Dokadur-Plex panel 21",
            "es": "Dokadur-Plex panel 21",
            "fr": "Panneau Dokadur-Plex 21",
            "it": "Dokadur-Plex pannello 21",
            "hu": "Dokadur-Plex tábla 21",
            "ru": "Dokadur-Plex панель 21",
            "sk": "Dokadur-Plex panel 21",
            "pl": "Panel Dokadur Plex 21"
        },
        {
            "ID": "XTRA30__A7_AW4",
            "de": "Sperrholzplatte",
            "en-gb": "Plywood sheet",
            "en-us": "Plywood sheet",
            "es": "Tablero contrachapado",
            "fr": "Pan. contre-plaqué",
            "it": "Pannello in legno compensato",
            "hu": "Rétegeltlemez",
            "ru": "Распорная плита",
            "sk": "Preglejka",
            "pl": "Arkusz sklejki"
        },
        {
            "ID": "XTRA30__A7_AW5",
            "de": "Dokaflex-Tischplatte",
            "en-gb": "Dokaflex table sheet",
            "en-us": "Dokaflex table sheet",
            "es": "Dokaflex Tablero para mesa",
            "fr": "Dessus de table Dokaflex",
            "it": "Pannello tavolo Dokaflex",
            "hu": "Dokaflex-asztalhéj",
            "ru": "Опалубочная плита Dokaflex",
            "sk": "Dokaflex-doska stola",
            "pl": "Dokaflex – sklejka stołu"
        },
        {
            "ID": "XTRA30__A7_AW6",
            "de": "Dokadur-Paneel 27",
            "en-gb": "Dokadur panel 27",
            "en-us": "Dokadur panel 27",
            "es": "Dokadur panel 27",
            "fr": "Panneau Dokadur 27",
            "it": "Pannello Dokadur 27",
            "hu": "Dokadur tábla 27",
            "ru": "Dokadur панель 27",
            "sk": "Dokadur panel 27",
            "pl": "Panel Dokadur 21"
        },
        {
            "ID": "XTRA30__A7_AW7",
            "de": "Schalungsplatte 3-SO 27 mm",
            "en-gb": "Formwork sheet 3-SO 27 mm",
            "en-us": "Formwork sheet 3-SO 27mm",
            "es": "Tablero de encofrado 3-SO 27mm",
            "fr": "Panneau 3-SO 27mm",
            "it": "Pannello 3-SO 27mm",
            "hu": "Zsaluhéj 3-SO 27mm",
            "ru": "Опалубочная плита 3-SO 27мм",
            "sk": "Debniaca doska 3-SO 27mm",
            "pl": "Sklejka  3-SO 27 mm"
        },
        {
            "ID": "XTRA30__A7_AW8",
            "de": "Schalungsplatte 3S basic 21mm",
            "en-gb": "Formwork sheet 3S basic 21mm",
            "en-us": "Formwork sheet 3S basic 21mm",
            "es": "Tablero de encofrado 3S basic 21mm",
            "fr": "Panneau 3S basic 21mm",
            "it": "Pannello 3S basic 21mm",
            "hu": "Zsaluhéj 3S basic 21mm",
            "ru": "Опалубочная плита 3S basic 21мм",
            "sk": "Debniaca doska 3S basic 21mm",
            "pl": "Sklejka  3S basic 21mm"
        },
        {
            "ID": "XTRA30__A7_AW9",
            "de": "Schalungsplatte 3S cut 21mm",
            "en-gb": "Formwork sheet 3S cut 21mm",
            "en-us": "Formwork sheet 3S cut 21mm",
            "es": "Tablero de encofrado 3S cut 21mm",
            "fr": "Panneau 3S cut 21mm",
            "it": "Pannello 3S cut 21mm",
            "hu": "Zsaluhéj 3S cut 21mm",
            "ru": "Опалубочная плита 3S cut 21мм",
            "sk": "Debniaca doska 3S cut 21mm",
            "pl": "Sklejka  3S cut 21mm"
        },
        {
            "ID": "XTRA30__A8",
            "de": "Schalungsträger Typ",
            "en-gb": "Formwork beam type",
            "en-us": "Formwork beam type",
            "es": "Tipo di viga de madera",
            "fr": "Type de poutrelle",
            "it": "Tipo trave",
            "hu": "Tartó típus",
            "ru": "Тип опалубочной балки",
            "sk": "Typ debniaceho nosníka",
            "pl": "Typ dźwigara"
        },
        {
            "ID": "XTRA30__A8_AW1",
            "de": "Träger H20 top P",
            "en-gb": "Beam H20 top P",
            "en-us": "Beam H20 top P",
            "es": "Viga H20 top P",
            "fr": "Poutrelle H20 top P",
            "it": "Trave H20 top P",
            "hu": "Fatartó H20 top P",
            "ru": "Балка H20 top P",
            "sk": "Nosník H20 top P",
            "pl": "Dźwigar H20 top P"
        },
        {
            "ID": "XTRA30__A8_AW2",
            "de": "Träger H20 top N",
            "en-gb": "Beam H20 top N",
            "en-us": "Beam H20 top N",
            "es": "Viga H20 top N",
            "fr": "Poutrelle H20 top N",
            "it": "Trave H20 top N",
            "hu": "Fatartó H20 top N",
            "ru": "Балка H20 top N",
            "sk": "Nosník H20 top N",
            "pl": "Dźwigar H20 top N"
        },
        {
            "ID": "XTRA30__A8_AW3",
            "de": "Träger H20 eco P",
            "en-gb": "Beam H20 eco P",
            "en-us": "Beam H20 eco P",
            "es": "Viga H20 eco P",
            "fr": "Poutrelle H20 eco P",
            "it": "Trave H20 eco P",
            "hu": "Fatartó H20 eco P",
            "ru": "Балка H20 eco P",
            "sk": "Nosník H20 eco P",
            "pl": "Dźwigar H20 eco P"
        },
        {
            "ID": "XTRA30__A8_AW4",
            "de": "Träger H20 eco N",
            "en-gb": "Beam H20 eco N",
            "en-us": "Beam H20 eco N",
            "es": "Viga H20 eco N",
            "fr": "Poutrelle H20 eco N",
            "it": "Trave H20 eco N",
            "hu": "Fatartó H20 eco N",
            "ru": "Балка H20 eco N",
            "sk": "Nosník H20 eco N",
            "pl": "Dźwigar H20 eco N"
        },
        {
            "ID": "XTRA30__A8_AW5",
            "de": "Träger H20 Niet P",
            "en-gb": "Beam H20 rivet P",
            "en-us": "Beam H20 rivet P",
            "es": "Viga H20 remache P",
            "fr": "Poutrelle H20 rivet P",
            "it": "Trave H20 Niet P",
            "hu": "Fatartó H20 P szegecselt végű",
            "ru": "Балка H20 заклепка P",
            "sk": "Nosník H20 P s nitmi",
            "pl": "Dźwigar H20 Niet P"
        },
        {
            "ID": "XTRA30__A8_AW6",
            "de": "Träger H20 Niet N",
            "en-gb": "Beam H20 rivet N",
            "en-us": "Beam H20 rivet N",
            "es": "Viga H20 remache N",
            "fr": "Poutrelle H20 rivet N",
            "it": "Trave H20 Niet N",
            "hu": "Fatartó H20 N szegecselt végű",
            "ru": "Балка H20 заклепка N",
            "sk": "Nosník H20 N s nitmi",
            "pl": "Dźwigar H20 Niet N"
        },
        {
            "ID": "XTRA30__A8_AW7",
            "de": "Kantholz bauseits",
            "en-gb": "Squared timber by site",
            "en-us": "Squared timber by site",
            "es": "Listón por parte de obra",
            "fr": "Bois équarri chantier",
            "it": "Legno squadrato a carico cant.",
            "hu": "Furfa helyi anyag",
            "ru": "Деревянный брус (предоставляется заказчиком)",
            "sk": "Hranol dod. stavby",
            "pl": "Kantówka (po stronie budowy)"
        },
        {
            "ID": "XTRA30__A9",
            "de": "Xtra Unterstellung",
            "en-gb": "Xtra shoring",
            "en-us": "Xtra shoring",
            "es": "Apoyo Xtra",
            "fr": "Etaiement Xtra",
            "it": "Xtra Puntellazione",
            "hu": "Xtra Alátámasztás",
            "ru": "Основание Xtra",
            "sk": "Xtra podopretie",
            "pl": "System podpór Xtra"
        },
        {
            "ID": "XTRA30__A9_AW1",
            "de": "Eurex 20",
            "en-gb": "Eurex 20",
            "en-us": "Eurex 20",
            "es": "Eurex 20",
            "fr": "Eurex 20",
            "it": "Eurex 20",
            "hu": "Eurex 20",
            "ru": "Eurex 20",
            "sk": "Eurex 20",
            "pl": "Eurex 20"
        },
        {
            "ID": "XTRA30__A9_AW2",
            "de": "Eurex 30",
            "en-gb": "Eurex 30",
            "en-us": "Eurex 30",
            "es": "Eurex 30",
            "fr": "Eurex 30",
            "it": "Eurex 30",
            "hu": "Eurex 30",
            "ru": "Eurex 30",
            "sk": "Eurex 30",
            "pl": "Eurex 30"
        },
        {
            "ID": "XTRA30__A9_AW3",
            "de": "Eurex 60",
            "en-gb": "Eurex 60",
            "en-us": "Eurex 60",
            "es": "Eurex 60",
            "fr": "Eurex 60",
            "it": "Eurex 60",
            "hu": "Eurex 60",
            "ru": "Eurex 60",
            "sk": "Eurex 60",
            "pl": "Eurex 60"
        },
        {
            "ID": "XTRA30__A9_AW4",
            "de": "Stütze G",
            "en-gb": "Column G",
            "en-us": "Column G",
            "es": "Puntal G",
            "fr": "Etançon G",
            "it": "Puntello G",
            "hu": "Támasz G",
            "ru": "Колонна G",
            "sk": "Podpera G",
            "pl": "Podpora G"
        },
        {
            "ID": "XTRA30__A9_AW5",
            "de": "Stütze N",
            "en-gb": "Column N",
            "en-us": "Column N",
            "es": "Puntal N",
            "fr": "Etançon N",
            "it": "Puntello N",
            "hu": "Támasz N",
            "ru": "Колонна N",
            "sk": "Podpera N",
            "pl": "Podpora N"
        },
        {
            "ID": "XTRA30__A9_AW6",
            "de": "Stütze ECO",
            "en-gb": "Column ECO",
            "en-us": "Column ECO",
            "es": "Puntal Eco",
            "fr": "Etançon ECO",
            "it": "Puntello ECO",
            "hu": "Támasz ECO",
            "ru": "Колонна ЕСО",
            "sk": "Podpera eco",
            "pl": "Podpora ECO"
        },
        {
            "ID": "XTRA30__A9_AW7",
            "de": "Eurex 20 top",
            "en-gb": "Eurex 20 top",
            "en-us": "Eurex 20 top",
            "es": "Eurex 20 top",
            "fr": "Eurex 20 top",
            "it": "Eurex 20 top",
            "hu": "Eurex 20 top",
            "ru": "Eurex 20 top",
            "sk": "Eurex 20 top",
            "pl": "Eurex 20 top"
        },
        {
            "ID": "XTRA30__A9_AW8",
            "de": "Eurex 30 top",
            "en-gb": "Eurex 30 top",
            "en-us": "Eurex 30 top",
            "es": "Eurex 30 top",
            "fr": "Eurex 30 top",
            "it": "Eurex 30 top",
            "hu": "Eurex 30 top",
            "ru": "Eurex 30 top",
            "sk": "Eurex 30 top",
            "pl": "Eurex 30 top"
        },
        {
            "ID": "ZBBETBO-FF",
            "de": "Framax-Betonierbühne O 1,25/2,70 m FF",
            "en-gb": "Framax pouring platform O 1.25/2.70 m FF",
            "en-us": "Framax pouring platform O 1.25/2.70 m FF",
            "es": "Ménsula hormigonado Framax O 1,25/2,70 m FF",
            "fr": "Passerelle de bétonnage Framax O 1,25/2,70 m FF",
            "it": "Passerella di getto Framax O 1,25/2,70 m FF",
            "hu": "Framax-betonozóállvány O 1,25/2,70 m FF",
            "ru": "Подмости для бетонирования Framax O 1,25/2,70 м FF",
            "sk": "Framax-bet. plošina O 1,25/2,70 m FF",
            "pl": "Pomost betoniarski Framax O 1,25/2,70 m FF"
        },
        {
            "ID": "ZBBETBO-FF__A1",
            "de": "Betonierbühne oder Konsole",
            "en-gb": "Pouring platform or bracket",
            "en-us": "Pouring platform or bracket",
            "es": "Ménsula de hormigonado o consola",
            "fr": "Passerelle de bétonnage ou console",
            "it": "Passerella di getto o mensola",
            "hu": "Betonozóállvány vagy konzol",
            "ru": "Подмости или консоль для бетонирования",
            "sk": "Betónovacia plošina alebo konzola",
            "pl": "Pomost betoniarski lub konsola"
        },
        {
            "ID": "ZBBETBO-FF__A1_AW1",
            "de": "Betonierbühne",
            "en-gb": "Pouring platform",
            "en-us": "Pouring platform",
            "es": "Ménsula de hormigonado",
            "fr": "Passerelle de bétonnage",
            "it": "Passerella di getto",
            "hu": "Betonozóállvány",
            "ru": "Подмости для бетонирования",
            "sk": "Betón. plošina",
            "pl": "Pomost betoniarski"
        },
        {
            "ID": "ZBBETBO-FF__A1_AW2",
            "de": "Konsole 90 (Bohlen bauseits)",
            "en-gb": "Bracket 90 (planks by site)",
            "en-us": "Bracket 90 (planks by site)",
            "es": "Consola 90 (tablones p.parte de obra)",
            "fr": "Console 90 (Madriers chantier)",
            "it": "Mensola 90 (Tavoloni in cantiere)",
            "hu": "Konzol 90 (Deszka helyi anyag)",
            "ru": "Консоль 90 (брусья силами заказчика)",
            "sk": "Konzola 90(fošne dod. stavby)",
            "pl": "Konsola 90 (deski po str. budowy)"
        },
        {
            "ID": "ZBBETBO-FO",
            "de": "Framax-Betonierbühne O 1,25/2,70 m Fo",
            "en-gb": "Framax pouring platform O 1.25/2.70 m Fo",
            "en-us": "Framax pouring platform O 1.25/2.70 m Fo",
            "es": "Ménsula hormigonado Framax O 1,25/2,70 m Fo",
            "fr": "Passerelle de bétonnage Framax O 1,25/2,70 m Fo",
            "it": "Passerella di getto Framax O 1,25/2,70 m Fo",
            "hu": "Framax-betonozóállvány O 1,25/2,70 m Fo",
            "ru": "Подмости для бетонирования Framax O 1,25/2,70 м Fo",
            "sk": "Framax-bet. plošina O 1,25/2,70 m Fo",
            "pl": "Pomost betoniarski Framax O 1,25/2,70 m Fo"
        },
        {
            "ID": "ZBBETBOFRA",
            "de": "Framax-Betonierbühne O 1,25/2,70 m",
            "en-gb": "Framax pouring platform O 1.25/2.70 m",
            "en-us": "Framax pouring platform O 1.25/2.70 m",
            "es": "Ménsula hormigonado Framax O 1,25/2,70 m",
            "fr": "Passerelle de bétonnage Framax O 1,25/2,70 m",
            "it": "Passerella di getto Framax O 1,25/2,70 m",
            "hu": "Framax-betonozóállvány O 1,25/2,70 m",
            "ru": "Подмости для бетонирования Framax O 1,25/2,70 м",
            "sk": "Framax-bet. plošina O 1,25/2,70 m",
            "pl": "Pomost betoniarski Framax O 1,25/2,70 m"
        },
        {
            "ID": "ZBBETBOFRA__A1",
            "de": "Betonierbühne oder Konsole",
            "en-gb": "Pouring platform or bracket",
            "en-us": "Pouring platform or bracket",
            "es": "Ménsula de hormigonado o consola",
            "fr": "Passerelle de bétonnage ou console",
            "it": "Passerella di getto o mensola",
            "hu": "Betonozóállvány vagy konzol",
            "ru": "Подмости или консоль для бетонирования",
            "sk": "Betónovacia plošina alebo konzola",
            "pl": "Pomost betoniarski lub konsola"
        },
        {
            "ID": "ZBBETBOFRA__A1_AW1",
            "de": "Betonierbühne",
            "en-gb": "Pouring platform",
            "en-us": "Pouring platform",
            "es": "Ménsula de hormigonado",
            "fr": "Passerelle de bétonnage",
            "it": "Passerella di getto",
            "hu": "Betonozóállvány",
            "ru": "Подмости для бетонирования",
            "sk": "Betón. plošina",
            "pl": "Pomost betoniarski"
        },
        {
            "ID": "ZBBETBOFRA__A1_AW2",
            "de": "Framax Konsole 90 (Bohlen bauseits)",
            "en-gb": "Framax bracket 90 (planks by site)",
            "en-us": "Framax bracket 90 (planks by site)",
            "es": "Framax consola 90(tablones p.parte de obra)",
            "fr": "Console Framax 90 (Madriers chantier)",
            "it": "Mensola Framax 90(Tavoloni in cantiere)",
            "hu": "Framax Konzol 90 (Deszka helyi anyag)",
            "ru": "Консоль Framax 90 (брусья силами заказчика)",
            "sk": "Framax konzola 90(fošne dod. stavby)",
            "pl": "Konsola Framax 90 mocowana (deski po str. budowy)"
        },
        {
            "ID": "ZBBETBU-FF",
            "de": "Framax-Betonierbühne U 1,25/2,70 m FF",
            "en-gb": "Framax pouring platform U 1.25/2.70 m FF",
            "en-us": "Framax pouring platform U 1.25/2.70 m FF",
            "es": "Ménsula hormigonado Framax U 1,25/2,70 m FF",
            "fr": "Passerelle de bétonnage Framax U 1,25/2,70 m FF",
            "it": "Passerella di getto Framax U 1,25/2,70 m FF",
            "hu": "Framax-betonozóállvány U 1,25/2,70 m FF",
            "ru": "Подмости для бетонирования Framax U 1,25/2,70 м FF",
            "sk": "Framax-bet. plošina U 1,25/2,70 m FF",
            "pl": "Pomost betoniarski Framax O 1,25/2,70 m FF"
        },
        {
            "ID": "ZBBETBU-FF__A1",
            "de": "Betonierbühne oder Konsole",
            "en-gb": "Pouring platform or bracket",
            "en-us": "Pouring platform or bracket",
            "es": "Ménsula de hormigonado o consola",
            "fr": "Passerelle de bétonnage ou console",
            "it": "Passerella di getto o mensola",
            "hu": "Betonozóállvány vagy konzol",
            "ru": "Подмости или консоль для бетонирования",
            "sk": "Betónovacia plošina alebo konzola",
            "pl": "Pomost betoniarski lub konsola"
        },
        {
            "ID": "ZBBETBU-FF__A1_AW1",
            "de": "Betonierbühne",
            "en-gb": "Pouring platform",
            "en-us": "Pouring platform",
            "es": "Ménsula de hormigonado",
            "fr": "Passerelle de bétonnage",
            "it": "Passerella di getto",
            "hu": "Betonozóállvány",
            "ru": "Подмости для бетонирования",
            "sk": "Betón. plošina",
            "pl": "Pomost betoniarski"
        },
        {
            "ID": "ZBBETBU-FF__A1_AW2",
            "de": "Konsole 90 (Bohlen bauseits)",
            "en-gb": "Bracket 90 (planks by site)",
            "en-us": "Bracket 90 (planks by site)",
            "es": "Consola 90(tablones p.parte de obra)",
            "fr": "Console 90 (Madriers chantier)",
            "it": "Mensola 90(Tavoloni in cantiere)",
            "hu": "Konzol 90 (Deszka helyi anyag)",
            "ru": "Консоль 90 (брусья силами заказчика)",
            "sk": "Konzola 90(fošne dod. stavby)",
            "pl": "Konsola 90 (deski po str. budowy)"
        },
        {
            "ID": "ZBBETBU-FO",
            "de": "Framax-Betonierbühne U 1,25/2,70 m Fo",
            "en-gb": "Framax pouring platform U 1.25/2.70 m Fo",
            "en-us": "Framax pouring platform U 1.25/2.70 m Fo",
            "es": "Ménsula hormigonado Framax U 1,25/2,70 m Fo",
            "fr": "Passerelle de bétonnage Framax U 1,25/2,70 m Fo",
            "it": "Passerella di getto Framax U 1,25/2,70 m Fo",
            "hu": "Framax-betonozóállvány U 1,25/2,70 m Fo",
            "ru": "Подмости для бетонирования Framax U 1,25/2,70 м Fo",
            "sk": "Framax-bet. plošina U 1,25/2,70 m Fo",
            "pl": "Pomost betoniarski Framax O 1,25/2,70 m Fo"
        },
        {
            "ID": "ZBBETBUFRA",
            "de": "Framax-Betonierbühne U 1,25/2,70 m",
            "en-gb": "Framax pouring platform U 1.25/2.70 m",
            "en-us": "Framax pouring platform U 1.25/2.70 m",
            "es": "Ménsula hormigonado Framax U 1,25/2,70 m",
            "fr": "Passerelle de bétonnage Framax U 1,25/2,70 m",
            "it": "Passerella di getto Framax U 1,25/2,70 m",
            "hu": "Framax-betonozóállvány U 1,25/2,70 m",
            "ru": "Подмости для бетонирования Framax U 1,25/2,70 м",
            "sk": "Framax-bet. plošina U 1,25/2,70 m",
            "pl": "Pomost betoniarski Framax O 1,25/2,70 m"
        },
        {
            "ID": "ZBBETBUFRA__A1",
            "de": "Betonierbühne oder Konsole",
            "en-gb": "Pouring platform or bracket",
            "en-us": "Pouring platform or bracket",
            "es": "Ménsula de hormigonado o consola",
            "fr": "Passerelle de bétonnage ou console",
            "it": "Passerella di getto o mensola",
            "hu": "Betonozóállvány vagy konzol",
            "ru": "Подмости или консоль для бетонирования",
            "sk": "Betónovacia plošina alebo konzola",
            "pl": "Pomost betoniarski lub konsola"
        },
        {
            "ID": "ZBBETBUFRA__A1_AW1",
            "de": "Betonierbühne",
            "en-gb": "Pouring platform",
            "en-us": "Pouring platform",
            "es": "Ménsula de hormigonado",
            "fr": "Passerelle de bétonnage",
            "it": "Passerella di getto",
            "hu": "Betonozóállvány",
            "ru": "Подмости для бетонирования",
            "sk": "Betón. plošina",
            "pl": "Pomost betoniarski"
        },
        {
            "ID": "ZBBETBUFRA__A1_AW2",
            "de": "Framax Konsole 90 (Bohlen bauseits)",
            "en-gb": "Framax bracket 90 (planks by site)",
            "en-us": "Framax bracket 90(planks by site)",
            "es": "Framax consola 90(tablones p.parte de obra)",
            "fr": "Console Framax 90(Madriers chantier)",
            "it": "Mensola Framax 90(Tavoloni in cantiere)",
            "hu": "Framax Konzol 90 (Deszka helyi anyag)",
            "ru": "Консоль Framax 90 (брусья силами заказчика)",
            "sk": "Framax konzola 90(fošne dod. stavby)",
            "pl": "Konsola Framax 90 mocowana (deski po str. budowy)"
        },
        {
            "ID": "ZBELFF100",
            "de": "Elementabstützung FF100",
            "en-gb": "Panel bracing FF100",
            "en-us": "Panel bracing FF100",
            "es": "Apuntalamiento de elementos FF100",
            "fr": "Stabilité FF100",
            "it": "Puntellazione sostegno FF100",
            "hu": "Elemmegtámasztás FF100",
            "ru": "Крепление для элемента FF100",
            "sk": "Podopretie panelov FF100",
            "pl": "Wyparcia FF100"
        },
        {
            "ID": "ZBELFF100__A1",
            "de": "Elementabstützung",
            "en-gb": "Panel bracing",
            "en-us": "Panel bracing",
            "es": "Apuntalamiento de elementos",
            "fr": "Stabilité",
            "it": "Puntellazione sostegno",
            "hu": "Elemmegtámasztás",
            "ru": "Крепление для элемента",
            "sk": "Podopretie panel.",
            "pl": "Wyparcia"
        },
        {
            "ID": "ZBELFF100__A1_AW1",
            "de": "Elementabstützung Bühne hoch",
            "en-gb": "Panel bracing platform top",
            "en-us": "Panel bracing platform top",
            "es": "Apuntalamiento de elementos plataforma alta",
            "fr": "Stabilité passerelle haut",
            "it": "Puntellazione sostegno passerella alto",
            "hu": "Elemmegtámasztás munkaszint felső",
            "ru": "Крепление для элемента подмостей верхнее",
            "sk": "Podopretie panel. pri plošine vyššie",
            "pl": "Wyparcia – pomost górny"
        },
        {
            "ID": "ZBELFF100__A1_AW2",
            "de": "Elementabstützung Bühne tief",
            "en-gb": "Panel bracing platform lower",
            "en-us": "Panel bracing platform lower",
            "es": "Apuntalamiento de elementos plataforma baja",
            "fr": "Stabilité passerelle bas",
            "it": "Puntellazione sostegno passerella basso",
            "hu": "Elemmegtámasztás munkaszint alsó",
            "ru": "Крепление для элемента подмостей нижнее",
            "sk": "Podopretie panel. pri plošine nižšie",
            "pl": "Wyparcia – pomost dolny"
        },
        {
            "ID": "ZBELFF100__A2",
            "de": "Element",
            "en-gb": "Panel",
            "en-us": "Panel",
            "es": "Elemento",
            "fr": "Panneau",
            "it": "Elemento",
            "hu": "Elem",
            "ru": "Элемент",
            "sk": "Panel",
            "pl": "Płyta"
        },
        {
            "ID": "ZBELFF100__A2_AW1",
            "de": "Element normal stehend",
            "en-gb": "Panel normal upright",
            "en-us": "Panel normal upright",
            "es": "Elemento normal de pie",
            "fr": "Panneau vertical normal",
            "it": "Pannello verticale",
            "hu": "Normál álló elem",
            "ru": "Элемент в нормальном положении",
            "sk": "Panel normálne stojaci",
            "pl": "Płyta standardowa pionowa"
        },
        {
            "ID": "ZBELFF100__A2_AW2",
            "de": "Element gedreht",
            "en-gb": "Panel rotated",
            "en-us": "Panel rotated",
            "es": "Elemento girado",
            "fr": "Panneau tourné",
            "it": "Pannello ruotato",
            "hu": "Elforgatott elem",
            "ru": "Элемент повернутый",
            "sk": "Panel obrátený",
            "pl": "Płyta obrócona"
        },
        {
            "ID": "ZBELSFRAMI",
            "de": "Frami - Elementstütze 340",
            "en-gb": "Frami panel strut 340",
            "en-us": "Frami panel strut 340",
            "es": "Frami - Puntal estabilizador 340 dúplex",
            "fr": "Frami - Étançon de banche 340",
            "it": "Puntellazione di sostegno Frami 340",
            "hu": "Frami - elemtámasz 340",
            "ru": "Frami - подпорный раскос 340",
            "sk": "Frami - panelová vzpera 340",
            "pl": "Wypora ukośna podwójna 340 Frami"
        },
        {
            "ID": "ZBELSFRA-S",
            "de": "Elementabstützung Framax S",
            "en-gb": "Panel bracing Framax S",
            "en-us": "Panel bracing Framax S",
            "es": "Apuntalamiento de elementos Framax S",
            "fr": "Stabilité Framax S",
            "it": "Puntellazione sostegno Framax S",
            "hu": "Elemmegtámasztás Framax S",
            "ru": "Крепление для элемента Framax S",
            "sk": "Podopretie panelov Framax S",
            "pl": "Wyparcia Framax S"
        },
        {
            "ID": "ZBELSFRA-S__A1",
            "de": "Elementabstützung Framax S",
            "en-gb": "Panel bracing Framax S",
            "en-us": "Panel bracing Framax S",
            "es": "Apuntalamiento de elementos Framax S",
            "fr": "Stabilité Framax S",
            "it": "Puntellazione sostegno Framax S",
            "hu": "Elemmegtámasztás Framax S",
            "ru": "Крепление для элемента Framax S",
            "sk": "Podopretie panel. pre Framax S",
            "pl": "Wyparcia Framax S"
        },
        {
            "ID": "ZBELSFRA-S__A1_AW1",
            "de": "Elementstütze 540 mit Rohrstütze 22'-0''-40'-0''",
            "en-gb": "Panel strut 540 with pipe brace 22'-0''-40'-0''",
            "en-us": "Panel strut 540 with pipe brace 22'-0''-40'-0''",
            "es": "Puntal estabilizador 540 con puntal de aplomado 22'-0''-40'-0''",
            "fr": "Étançon de banche 540 avec étai 22'-0''-40'-0''",
            "it": "Puntellazione di sostegno 540 con puntone 22'-0''-40'-0''",
            "hu": "Elemtámasz 540 csőtámasszal 22'-0''-40'-0''",
            "ru": "Подпорный раскос 540 с трубной стойкой 22'-0''-40'-0''",
            "sk": "Panelová vzpera 540 s rúrovej opory 22'-0''-40'-0''",
            "pl": "Wypora 540 z obejmą rury 22′-0”-40′-0”"
        },
        {
            "ID": "ZBELSFRA-S__A1_AW2",
            "de": "Elementstütze 540 mit Eurex 60 550",
            "en-gb": "Panel strut 540 with Eurex 60 550",
            "en-us": "Panel strut 540 with Eurex 60 550",
            "es": "Puntal estabilizador 540 con Eurex 60 550",
            "fr": "Étançon de banche 540 avec Eurex 60 550",
            "it": "Puntellazione di sostegno 540 con Eurex 60 550",
            "hu": "Elemtámasz 540 Eurex 60 550",
            "ru": "Подпорный раскос 540 с Eurex 60 550",
            "sk": "Panelová vzpera 540 s Eurex 60 550",
            "pl": "Wypora 540 z Eurex 60 550"
        },
        {
            "ID": "ZBELSFRA-S__A1_AW3",
            "de": "Rohrstütze 12'-0''-21'-0'' mit Rohrstütze 22'-0''-40'-0''",
            "en-gb": "Pipe brace 12'-0''-21'-0'' with pipe brace 22'-0''-40'-0''",
            "en-us": "Pipe brace 12'-0''-21'-0'' with pipe brace 22'-0''-40'-0''",
            "es": "Puntal de aplomado 12'-0''-21'-0'' con puntal de aplomado 22'-0''-40'-0''",
            "fr": "Étai 12'-0''-21'-0'' avec étai 22'-0''-40'-0''",
            "it": "Puntone 12'-0''-21'-0'' con Puntone 22'-0''-40'-0''",
            "hu": "Csőtámasszal 12'-0''-21'-0'' csőtámasszal 22'-0''-40'-0''",
            "ru": "трубной стойкой 12'-0''-21'-0'' с трубной стойкой 22'-0''-40'-0''",
            "sk": "Rúrovej opory 12'-0''-21'-0'' s rúrovej opory 22'-0''-40'-0''",
            "pl": "Obejma rury 12′-0”-21′-0” z obejmą rury 22′-0”-40′-0”"
        },
        {
            "ID": "ZBELSFRA-S__A1_AW4",
            "de": "Rohrstütze 12'-0''-21'-0'' mit Eurex 60 550",
            "en-gb": "Pipe brace 12'-0''-21'-0'' with Eurex 60 550",
            "en-us": "Pipe brace 12'-0''-21'-0'' with Eurex 60 550",
            "es": "Puntal de aplomado 12'-0''-21'-0'' con Eurex 60 550",
            "fr": "Étai 12'-0''-21'-0'' avec Eurex 60 550",
            "it": "Puntone 12'-0''-21'-0'' con Eurex 60 550",
            "hu": "Csőtámasszal 12'-0''-21'-0'' Eurex 60 550",
            "ru": "трубной стойкой 12'-0''-21'-0'' с Eurex 60 550",
            "sk": "Rúrovej opory 12'-0''-21'-0'' s Eurex 60 550",
            "pl": "Obejma rury 12′-0”-21′-0′′ z Eurex 60 550"
        },
        {
            "ID": "ZBELSFRA-S__A1_AW5",
            "de": "Elementstütze 340",
            "en-gb": "Panel strut 340",
            "en-us": "Panel strut 340",
            "es": "Puntal estabilizador 340",
            "fr": "Étançon de banche 340",
            "it": "Puntellazione di sostegno 340",
            "hu": "Elemtámasz 340",
            "ru": "Подпорный раскос 340",
            "sk": "Panelová vzpera 340",
            "pl": "Wypora ukośna podwójna 340"
        },
        {
            "ID": "ZBELSFRA-S__A1_AW6",
            "de": "Elementstütze 540",
            "en-gb": "Panel strut 540",
            "en-us": "Panel strut 540",
            "es": "Puntal estabilizador 540",
            "fr": "Étançon de banche 540",
            "it": "Puntellazione di sostegno 540",
            "hu": "Elemtámasz 540",
            "ru": "Подпорный раскос 540",
            "sk": "Panelová vzpera 540",
            "pl": "Wypora ukośna podwójna 540"
        },
        {
            "ID": "ZBELSFRA-S__A1_AW7",
            "de": "Rohrstütze 12'-0''-21'-0''",
            "en-gb": "Pipe brace 12'-0''-21'-0''",
            "en-us": "Pipe brace 12'-0''-21'-0''",
            "es": "Puntal de aplomado 12'-0''-21'-0''",
            "fr": "Étai 12'-0''-21'-0''",
            "it": "Puntone 12'-0''-21'-0''",
            "hu": "Csőtámasszal 12'-0''-21'-0''",
            "ru": "трубной стойкой 12'-0''-21'-0''",
            "sk": "Rúrovej opory 12'-0''-21'-0''",
            "pl": "Obejma rury 12′-0”-21′-0”"
        },
        {
            "ID": "ZBELSFRA-S__A2",
            "de": "Elementtyp",
            "en-gb": "Panel type",
            "en-us": "Panel type",
            "es": "Tipo de elemento",
            "fr": "Type d'élément",
            "it": "Tipo elemento",
            "hu": "Elemtípus",
            "ru": "Тип элемента",
            "sk": "Typ panelu",
            "pl": "Typ płyty"
        },
        {
            "ID": "ZBELSFRA-S__A2_AW1",
            "de": "Großelement (2.40,2.70 breit)",
            "en-gb": "Large panel (2.40,2.70 wide)",
            "en-us": "Large panel (2.40,2.70 wide)",
            "es": "Elemento largo (2.40,2.70 ancho)",
            "fr": "Grande élément (2.40,2.70 large)",
            "it": "Elemento grande (2.40,2.70 largo)",
            "hu": "Nagytábla (2.40,2.70szélesség)",
            "ru": "Крупноразмерный элемент (ширина 2.40, 2.70)",
            "sk": "Velkoplošný panel (2.40,2.70 šírky)",
            "pl": "Płyty duże (szerokość 2,40;2,70m)"
        },
        {
            "ID": "ZBELSFRA-S__A2_AW2",
            "de": "Normalelement (1.35,0.90,0.60,0.55,0.45,0.30 breit)",
            "en-gb": "Standard panel (1.35,0.90,0.60,0.55,0.45,0.30 wide)",
            "en-us": "Standard panel (1.35,0.90,0.60,0.55,0.45,0.30 wide)",
            "es": "Elementos norm.(1.35,0.90,0.60,0.55,0.45,0.30ancho)",
            "fr": "Élément étalon (1.35,0.90,0.60,0.55,0.45,0.30 large)",
            "it": "Elemento normale (1.35,0.90,0.60,0.55,0.45,0.30 largo)",
            "hu": "Normál tábla (1.35,0.90,0.60,0.55,0.45,0.30szélesség)",
            "ru": "Унифицированный элемент (ширина 1.35,0.90,0.60,0.55,0.45,0.30)",
            "sk": "Štandardný panel (1.35,0.90,0.60,0.55,0.45,0.30 šírky)",
            "pl": "Płyta standardowa (szerokość 1.35,0.90,0.60,0.55,0.45,0.30)"
        },
        {
            "ID": "ZBELSFRA-S__A3",
            "de": "Stehende oder liegende Aufstockung der Elemente",
            "en-gb": "Upright or horiz. stacking of panels",
            "en-us": "Upright or horiz. stacking of panels",
            "es": "Apilado horizontal o vertical de los elementos",
            "fr": "Rehausse de px debout ou couchés",
            "it": "Sovrapposizione orizzontale o verticale degli elementi",
            "hu": "Állított ill. fektetett elemmagasítás",
            "ru": "Вертикальная или горизонтальная надстройка элементов",
            "sk": "Nadstavenie panelov nastojato alebo naležato",
            "pl": "Pionowe lub poziome nadstawianie płyt"
        },
        {
            "ID": "ZBELSFRA-S__A3_AW1",
            "de": "Stehend aufgestockt",
            "en-gb": "stacked upright",
            "en-us": "stacked upright",
            "es": "Apilado vertical",
            "fr": "Rehaussé deboutant",
            "it": "Sovrapposizione vert.",
            "hu": "Állítva magasítva",
            "ru": "надстройка в вертикальном положении",
            "sk": "Nadstavenie nastojato",
            "pl": "nadstawienie pionowe"
        },
        {
            "ID": "ZBELSFRA-S__A3_AW2",
            "de": "Liegend aufgestockt",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz.",
            "es": "Apilado horizontal",
            "fr": "Rehaussé couchant",
            "it": "Sovrapposizione orizz.",
            "hu": "Fektetve magasítva",
            "ru": "надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "pl": "nadstawienie poziome"
        },
        {
            "ID": "ZBELSTZ-FF",
            "de": "Elementabstützung FF20 und FL20",
            "en-gb": "Panel bracing FF20 and FL20",
            "en-us": "Panel bracing FF20 and FL20",
            "es": "Apuntalamiento de elementos FF20 y FL20",
            "fr": "Stabilité FF20 et FL20",
            "it": "Puntellazione sostegno FF20 e FL20",
            "hu": "Elemmegtámasztás FF20 és FL20",
            "ru": "Крепление для элемента FF20 и FL20",
            "sk": "Podopretie panelov FF20 a FL20",
            "pl": "Wyparcia FF20 i FL20"
        },
        {
            "ID": "ZBELSTZ-FF__A1",
            "de": "Elementabstützung FF20",
            "en-gb": "Panel bracing FF20",
            "en-us": "Panel bracing FF20",
            "es": "Apuntalamiento de elementos FF20",
            "fr": "Stabilité FF20",
            "it": "Puntellazione sostegno FF20",
            "hu": "Elemmegtámasztás FF20",
            "ru": "Крепление для элемента FF20",
            "sk": "Podopretie panelov FF20",
            "pl": "Wyparcia FF20"
        },
        {
            "ID": "ZBELSTZ-FF__A1_AW1",
            "de": "Elementabstützung für FF20 mit Eurex 60 550",
            "en-gb": "Panel bracing for FF20 with Eurex 60 550",
            "en-us": "Panel bracing for FF20 with Eurex 60 550",
            "es": "Apuntalamiento de elementos para FF20 con Eurex 60 550",
            "fr": "Stabilité pour FF20 avec Eurex 60 550",
            "it": "Puntellazione sostegno per FF20 con Eurex 60 550",
            "hu": "Elemmegtámasztás FF20-hoz Eurex 60 550 födémtámasszal",
            "ru": "Крепление для элемента для FF20 с Eurex 60 550",
            "sk": "Podopr. panelov pre FF20 pomocou Eurex 60 550",
            "pl": "Wyparcia do FF20 z Eurex 60 550"
        },
        {
            "ID": "ZBELSTZ-FF__A1_AW2",
            "de": "Elementabstützung für FF20 mit Rohrstütze",
            "en-gb": "Panel bracing for FF20 with pipe brace",
            "en-us": "Panel bracing for FF20 with pipe brace",
            "es": "Apuntalamiento de elementos para FF20 con puntal de aplomado",
            "fr": "Stabilité pour FF20 avec étai",
            "it": "Puntellazione sostegno per FF20 con puntone",
            "hu": "Elemmegtámasztás FF20-hoz csőtámasszal",
            "ru": "Крепление для элемента FF20 с трубчатой опорой",
            "sk": "Podopr. panelov pre FF20 pomocou rúr. opory",
            "pl": "Wyparcia do FF20 z obejmą rury"
        },
        {
            "ID": "ZBELSTZ-FF__A2",
            "de": "Elementhöhe",
            "en-gb": "Panel height",
            "en-us": "Panel height",
            "es": "Altura del elemento",
            "fr": "Hauteur d'élément",
            "it": "Altezza del elemento",
            "hu": "Elemmagasság",
            "ru": "Высота элемента",
            "sk": "Výška panelu",
            "pl": "Wysokość płyty"
        },
        {
            "ID": "ZBELSTZ-FF__A2_AW1",
            "de": "mit 3,75 Element",
            "en-gb": "with 3.75 panel",
            "en-us": "with 3.75 panel",
            "es": "Con 3,75 elemento",
            "fr": "avec panneau 3,75",
            "it": "con 3,75 elemento",
            "hu": "3,75-ös elemmel",
            "ru": "с элементом 3,75",
            "sk": "s panelom 3,75",
            "pl": "z płytą 3,75"
        },
        {
            "ID": "ZBELSTZ-FF__A2_AW2",
            "de": "ohne 3,75 Element",
            "en-gb": "w/o 3.75 panel",
            "en-us": "w/o 3,75 panel",
            "es": "Sin 3,75 elemento",
            "fr": "sans panneau 3,75",
            "it": "senza 3,75 elemento",
            "hu": "3,75-ös elem nélkül",
            "ru": "без элемента 3,75",
            "sk": "bez panela 3,75",
            "pl": "bez płyty 3,75"
        },
        {
            "ID": "ZBELSTZFINI",
            "de": "Elementabstützung Framini",
            "en-gb": "Panel bracing Framini",
            "en-us": "Panel bracing Framini",
            "es": "Apuntalamiento de elementos Framini",
            "fr": "Stabilité Framini",
            "it": "Puntellazione sostegno Framini",
            "hu": "Elemmegtámasztás Framini",
            "ru": "Крепление для элемента Framini",
            "sk": "Podopretie panelov Framini",
            "pl": "Wyparcia Framini"
        },
        {
            "ID": "ZBELSTZFINI__A1",
            "de": "Elementstütze",
            "en-gb": "Panel strut",
            "en-us": "Panel strut",
            "es": "Puntal estabilizador",
            "fr": "Étançon de banche",
            "it": "Puntellazione di sostegno",
            "hu": "Elemtámasz",
            "ru": "Подпорный раскос",
            "sk": "Panelová vzpera",
            "pl": "Wypora"
        },
        {
            "ID": "ZBELSTZFINI__A1_AW1",
            "de": "Justierstütze 260",
            "en-gb": "Plumbing strut 260",
            "en-us": "Plumbing strut 260",
            "es": "Puntal de ajuste 260",
            "fr": "Bracon principal 260",
            "it": "Puntello di piombatura 260",
            "hu": "Beállítótámasz 260",
            "ru": "Стойка 260",
            "sk": "Nastavovacia vzpera 260",
            "pl": "Wypora 260"
        },
        {
            "ID": "ZBELSTZFINI__A1_AW2",
            "de": "Elementstütze 340",
            "en-gb": "Panel strut 340",
            "en-us": "Panel strut 340",
            "es": "Puntal estabilizador 340",
            "fr": "Étançon de banche 340",
            "it": "Puntellazione di sostegno 340",
            "hu": "Elemtámasz 340",
            "ru": "Подпорный раскос 340",
            "sk": "Panelová vzpera 340",
            "pl": "Wypora ukośna podwójna 340"
        },
        {
            "ID": "ZBELSTZ-FO",
            "de": "Elementabstützung Frameco",
            "en-gb": "Panel bracing Frameco",
            "en-us": "Panel bracing Frameco",
            "es": "Apuntalamiento de elementos Frameco",
            "fr": "Stabilité Frameco",
            "it": "Puntellazione sostegno Frameco",
            "hu": "Elemmegtámasztás Frameco",
            "ru": "Крепление для элемента Frameco",
            "sk": "Podopretie panelov Frameco",
            "pl": "Wyparcia Frameco"
        },
        {
            "ID": "ZBELSTZ-FO__A1",
            "de": "Elementabstützung Frameco",
            "en-gb": "Panel bracing Frameco",
            "en-us": "Panel bracing Frameco",
            "es": "Apuntalamiento de elementos Frameco",
            "fr": "Stabilité Frameco",
            "it": "Puntellazione sostegno Frameco",
            "hu": "Elemmegtámasztás Frameco",
            "ru": "Крепление для элемента Frameco",
            "sk": "Podopretie panel. Frameco",
            "pl": "Wyparcia Frameco"
        },
        {
            "ID": "ZBELSTZ-FO__A1_AW1",
            "de": "Elementabstützung Frameco",
            "en-gb": "Panel bracing Frameco",
            "en-us": "Panel bracing Frameco",
            "es": "Apuntalamiento de elementos Frameco",
            "fr": "Stabilité Frameco",
            "it": "Puntellazione sostegno Frameco",
            "hu": "Elemmegtámasztás Frameco",
            "ru": "Крепление для элемента Frameco",
            "sk": "Podopretie panel. Frameco",
            "pl": "Wyparcia Frameco"
        },
        {
            "ID": "ZBELSTZFRA",
            "de": "Elementabstützung Framax",
            "en-gb": "Panel bracing Framax",
            "en-us": "Panel bracing Framax",
            "es": "Apuntalamiento de elementos Framax",
            "fr": "Stabilité Framax",
            "it": "Puntellazione sostegno Framax",
            "hu": "Elemmegtámasztás Framax",
            "ru": "Крепление для элемента Framax",
            "sk": "Podopretie panelov Framax",
            "pl": "Wyparcia Framax"
        },
        {
            "ID": "ZBELSTZFRA__A1",
            "de": "Elementabstützung Framax",
            "en-gb": "Panel bracing Framax",
            "en-us": "Panel bracing Framax",
            "es": "Apuntalamiento de elementos Framax",
            "fr": "Stabilité Framax",
            "it": "Puntellazione sostegno Framax",
            "hu": "Elemmegtámasztás Framax",
            "ru": "Крепление для элемента Framax",
            "sk": "Podopretie panel. pre Framax",
            "pl": "Wyparcia Framax"
        },
        {
            "ID": "ZBELSTZFRA__A1_AW1",
            "de": "Elementabstützung mit Eurex 60 550",
            "en-gb": "Panel bracing with Eurex 60 550",
            "en-us": "Panel bracing with Eurex 60 550",
            "es": "Apuntalamiento de elementos con Eurex 60 550",
            "fr": "Stabilité avec Eurex 60 550",
            "it": "Puntellazione sostegno con Eurex 60 550",
            "hu": "Elemmegtámasztás Eurex 60 550 támasszal",
            "ru": "Крепление для элемента Eurex 60 550",
            "sk": "Podopretie panel. pomocou Eurex 60 550",
            "pl": "Wyparcia z Eurex 60 550"
        },
        {
            "ID": "ZBELSTZFRA__A1_AW2",
            "de": "Elementabstützung mit Rohrstütze",
            "en-gb": "Panel bracing with pipe brace",
            "en-us": "Panel bracing with pipe brace",
            "es": "Apuntalamiento de elementos con puntal de aplomado",
            "fr": "Stabilité avec étai",
            "it": "Puntellazione sostegno con puntone",
            "hu": "Elemmegtámasztás csőtámasszal",
            "ru": "Крепление для элемента с трубной стойкой",
            "sk": "Podopretie panel. pomocou rúrovej opory",
            "pl": "Wyparcia z obejmą rury"
        },
        {
            "ID": "ZBELSTZFRA__A1_AW3",
            "de": "Elementabstützung für Großelemente",
            "en-gb": "Panel bracing for large panels",
            "en-us": "Panel bracing for large panels",
            "es": "Apuntalamiento de elementos para elementos largos",
            "fr": "Stabilité pour grande éléments",
            "it": "Puntellazione sostegno per elemento grande",
            "hu": "Elemmegtámasztás a nagy táblákhoz",
            "ru": "Крепление для крупноразмерных элементов",
            "sk": "Podopretie veľkých panelov",
            "pl": "Wyparcia do płyt dużych"
        },
        {
            "ID": "ZBELSTZFRA__A2",
            "de": "Basiselement 3,30m bevorzugt Ja/Nein",
            "en-gb": "Basic element 3.30 m preferred Yes/No",
            "en-us": "Basic element 3.30m preferred yes/no",
            "es": "Altura base 3,30m preferido Si/No",
            "fr": "Élément de base 3,30m préféré Oui/Non",
            "it": "Elemento di base 3,30m preferito Si/Non",
            "hu": "Báziselem 3,30m preferált Igen/Nem",
            "ru": "Базовый элемент 3,30 м предпочтительно Да/Нет",
            "sk": "Uprednostnenie zákl. panela 3,30m áno/nie",
            "pl": "Element podstawowy 3,30m preferowany Tak/Nie"
        },
        {
            "ID": "ZBELSTZFRA__A2_AW1",
            "de": "bevorzugt",
            "en-gb": "preferred",
            "en-us": "preferred",
            "es": "preferido",
            "fr": "préféré",
            "it": "preferito",
            "hu": "preferált",
            "ru": "предпочтительно",
            "sk": "uprednostnené",
            "pl": "preferowany"
        },
        {
            "ID": "ZBELSTZFRA__A2_AW2",
            "de": "nicht bevorzugt",
            "en-gb": "not preferred",
            "en-us": "not preferred",
            "es": "no preferido",
            "fr": "ne pas préféré",
            "it": "non preferito",
            "hu": "nem preferált",
            "ru": "не предпочтительно",
            "sk": "nie je uprednostnené",
            "pl": "niepreferowany"
        },
        {
            "ID": "ZBELSTZFRA__A3",
            "de": "Aufstockung",
            "en-gb": "Stacking",
            "en-us": "Stacking",
            "es": "Apilado",
            "fr": "Rehaussé",
            "it": "Sovrapposizione",
            "hu": "Magasítás",
            "ru": "Надстройка",
            "sk": "Nadstavenie",
            "pl": "Nadstawienie"
        },
        {
            "ID": "ZBELSTZFRA__A3_AW1",
            "de": "Stehend aufgestockt",
            "en-gb": "stacked upright",
            "en-us": "stacked upright",
            "es": "Apilado vertical",
            "fr": "Rehaussé deboutant",
            "it": "Sovrapposizione vert.",
            "hu": "Állítva magasítva",
            "ru": "надстройка в вертикальном положении",
            "sk": "Nadstavenie nastojato",
            "pl": "nadstawienie pionowe"
        },
        {
            "ID": "ZBELSTZFRA__A3_AW2",
            "de": "Liegend aufgestockt",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz.",
            "es": "Apilado horizontal",
            "fr": "Rehaussé couchant",
            "it": "Sovrapposizione orizz.",
            "hu": "Fektetve magasítva",
            "ru": "Надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "pl": "nadstawienie poziome"
        },
        {
            "ID": "ZBELSTZREF",
            "de": "Elementabstützung ReForma SP",
            "en-gb": "Panel bracing ReForma SP",
            "en-us": "Panel bracing ReForma SP",
            "es": "Apuntalamiento de elementos ReForma SP",
            "fr": "Stabilité ReForma SP",
            "it": "Puntellazione sostegno ReForma SP",
            "hu": "Elemmegtámasztás ReForma SP",
            "ru": "Крепление для элемента ReForma SP",
            "sk": "Podopretie panelov ReForma SP",
            "pl": "Wyparcia ReForma SP"
        },
        {
            "ID": "ZBELSTZREF__A1",
            "de": "Grundelementhöhe",
            "en-gb": "Basic panel height",
            "en-us": "Basic panel height",
            "es": "Altura del elemento base",
            "fr": "Hauteur de base",
            "it": "Altezza dell’ elemento base",
            "hu": "Alapelem magasság",
            "ru": "Высота основного элемента",
            "sk": "Výška základného panelu",
            "pl": "Wysokość płyty podstawowej"
        },
        {
            "ID": "ZBELSTZREF__A1_AW1",
            "de": "3,00m",
            "en-gb": "3.00m",
            "en-us": "3.00m",
            "es": "3,00m",
            "fr": "3,00m",
            "it": "3,00m",
            "hu": "3,00m",
            "ru": "3,00м",
            "sk": "3,00m",
            "pl": "3,00 m"
        },
        {
            "ID": "ZBELSTZREF__A1_AW2",
            "de": "3,30m",
            "en-gb": "3.30m",
            "en-us": "3.30m",
            "es": "3,30m",
            "fr": "3,30m",
            "it": "3,30m",
            "hu": "3,30m",
            "ru": "3,30м",
            "sk": "3,30m",
            "pl": "3,30 m"
        },
        {
            "ID": "ZBELSTZREF__A1_AW3",
            "de": "2,40m",
            "en-gb": "2.40m",
            "en-us": "2.40m",
            "es": "2,40m",
            "fr": "2,40m",
            "it": "2,40m",
            "hu": "2,40m",
            "ru": "2,40м",
            "sk": "2,40m",
            "pl": "2,40m"
        },
        {
            "ID": "ZBELSTZREF__A2",
            "de": "Aufstockung",
            "en-gb": "Stacking",
            "en-us": "Stacking",
            "es": "Apilado",
            "fr": "Réhausse",
            "it": "Sovrapposizione",
            "hu": "Magasítás",
            "ru": "Надстройка",
            "sk": "Nadstavenie",
            "pl": "Nadstawienie"
        },
        {
            "ID": "ZBELSTZREF__A2_AW1",
            "de": "Stehend Aufgestockt",
            "en-gb": "stacked upright",
            "en-us": "Stacked upright",
            "es": "Apilado vertical",
            "fr": "Rehaussé deboutant",
            "it": "Sovrapposizione vert.",
            "hu": "Állítva magasítva",
            "ru": "Надстройка в вертикальном положении",
            "sk": "Nadstavenie nastojato",
            "pl": "nadstawienie pionowe"
        },
        {
            "ID": "ZBELSTZREF__A2_AW2",
            "de": "Liegend aufgestockt",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz.",
            "es": "Apilado horizontal",
            "fr": "Rehaussé couchant",
            "it": "Sovrapposizione orizz.",
            "hu": "Fektetve magasítva",
            "ru": "Надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "pl": "nadstawienie poziome"
        },
        {
            "ID": "ZBFRAMECO",
            "de": "Frameco-Konsole 90",
            "en-gb": "Frameco bracket 90",
            "en-us": "Frameco bracket 90",
            "es": "Ménsula Frameco 90",
            "fr": "Frameco - Console 90",
            "it": "Mensola Frameco 90",
            "hu": "Frameco konzol 90",
            "ru": "Консоль Frameco 90",
            "sk": "Frameco - konzola 90",
            "pl": "Konsola Frameco 90"
        },
        {
            "ID": "ZBGEGENSGfr",
            "de": "Gegengeländer - Schutzgeländer 1,10m",
            "en-gb": "Counter railing - handrail post 1.10m",
            "en-us": "Counter railing - handrail post 1.10m",
            "es": "Contrabarandilla - barandilla de seguridad 1,10m",
            "fr": "Garde-corps face coffrante - montant de garde-corps 1,10m",
            "it": "Parapetto di protezione - parapetto di protezione 1,10m",
            "hu": "Ellenkorlát - védőkorlát 1,10m",
            "ru": "Комплект защитных ограждений - защитные перила 1,10м",
            "sk": "Protizábradlie - ochranné zábradlie 1,10m",
            "pl": "Barierka zabezpieczająca - barierki ochronne 1,10m"
        },
        {
            "ID": "ZBGEGENSGFR__A1",
            "de": "Gegengeländer - Schutzgeländer 1,10m",
            "en-gb": "Counter railing - handrail post 1.10m",
            "en-us": "Counter railing - handrail post 1.10m",
            "es": "Contrabarandilla - barandilla de seguridad 1,10m",
            "fr": "Garde-corps face coffrante - montant de garde-corps 1,10m",
            "it": "Parapetto di protezione - parapetto di protezione 1,10m",
            "hu": "Ellenkorlát - védőkorlát 1,10m",
            "ru": "Комплект защитных ограждений - защитные перила 1,10м",
            "sk": "Protizábradlie - ochranné zábradlie 1,10m",
            "pl": "Barierka zabezpieczająca - barierki ochronne 1,10m"
        },
        {
            "ID": "ZBGEGENSGFR__A1_AW1",
            "de": "bis 4m - mit Gerüstrohr 48,4mm",
            "en-gb": "up to 4m - with scaffold tube 48.4mm",
            "en-us": "up to 4m - with scaffold tube 48.4mm",
            "es": "hasta 4m - con tubo de andamio 48,4mm",
            "fr": "jusque 4m - avec tube d'échafaudage 48,4mm",
            "it": "fino a 4m - con tubo 48,4mm",
            "hu": "4m-ig - 48,4mm-es állványcsővel",
            "ru": "до 4м - с каркасной трубкой 48,4 мм",
            "sk": "do 4m - s lešenárskou rúrou 48,4mm",
            "pl": "do 4 m – rura rusztowaniowa 48,4 mm"
        },
        {
            "ID": "ZBGEGENSGFR__A1_AW2",
            "de": "bis 4m - mit Geländerbretter 3x15",
            "en-gb": "up to 4m - with handrail boards 3x15",
            "en-us": "up to 4m - with handrail boards 3x15",
            "es": "hasta 4m - con tablones de barandilla 3x15",
            "fr": "jusque 4m - avec planches de garde-corps 3x15",
            "it": "fino a 4m - con tavola corrimano 3x15",
            "hu": "4m-ig - 3x15-ös korlátdeszkával",
            "ru": "до 4м - с досками для перил 3x15",
            "sk": "do 4m - s doskami zábradlia 3x15",
            "pl": "do 4 m – z obarierowaniem deskami 3x15"
        },
        {
            "ID": "ZBGEGENSGFR__A1_AW3",
            "de": "bis 4m - mit Geländerbretter 4x15",
            "en-gb": "up to 4m - with handrail boards 4x15",
            "en-us": "up to 4m - with handrail boards 4x15",
            "es": "hasta 4m - con tablones de barandilla 4x15",
            "fr": "jusque 4m - avec planches de garde-corps 4x15",
            "it": "fino a 4m - con tavola corrimano 4x15",
            "hu": "4m-ig - 4x15-ös korlátdeszkával",
            "ru": "до 4м - с досками для перил 4x15",
            "sk": "do 4m - s doskami zábradlia 4x15",
            "pl": "do 4 m – z obarierowaniem deskami 4x15"
        },
        {
            "ID": "ZBGEGENSGFR__A1_AW4",
            "de": "bis 4m - mit Geländerbretter 3x20",
            "en-gb": "up to 4m - with handrail boards 3x20",
            "en-us": "up to 4m - with handrail boards 3x20",
            "es": "hasta 4m - con tablones de barandilla 3x20",
            "fr": "jusque 4m - avec planches de garde-corps 3x20",
            "it": "fino a 4m - con tavola corrimano 3x20",
            "hu": "4m-ig - 3x20-as korlátdeszkával",
            "ru": "до 4м - с досками для перил 3x20",
            "sk": "do 4m - s doskami zábradlia 4x20",
            "pl": "do 4 m – z obarierowaniem deskami 3x20"
        },
        {
            "ID": "ZBGEGENSGFR__A1_AW5",
            "de": "4m-10m - mit Gerüstrohr 48,4mm",
            "en-gb": "4m-10m - with scaffold tube 48.4mm",
            "en-us": "4m-10m - with scaffold tube 48.4mm",
            "es": "4m-10m - con tubo de andamio 48,4mm",
            "fr": "4m-10m - avec tube d'échafaudage 48,4mm",
            "it": "4m-10m - con tubo 48,4mm",
            "hu": "4m-10m - 48,4mm-es állványcsővel",
            "ru": "4м-10м - с каркасной трубкой 48,4 мм",
            "sk": "4m-10m - s lešenárskou rúrou 48,4mm",
            "pl": "4m–10m – rura rusztowaniowa 48,4mm"
        },
        {
            "ID": "ZBGEGENSGFR__A1_AW6",
            "de": "4m-10m - mit Geländerbretter 3x15",
            "en-gb": "4m-10m - with handrail boards 3x15",
            "en-us": "4m-10m - with handrail boards 3x15",
            "es": "4m-10m - con tablones de barandilla 3x15",
            "fr": "4m-10m - avec planches de garde-corps 3x15",
            "it": "4m-10m - con tavola corrimano 3x15",
            "hu": "4m-10m - 3x15-ös korlátdeszkával",
            "ru": "4м-10м - с досками для перил 3x15",
            "sk": "4m-10m - s doskami zábradlia 3x15",
            "pl": "4m–10m – z deskami poręczy 3x15"
        },
        {
            "ID": "ZBGEGENSGFR__A1_AW7",
            "de": "4m-10m - mit Geländerbretter 4x15",
            "en-gb": "4m-10m - with handrail boards 4x15",
            "en-us": "4m-10m - with handrail boards 4x15",
            "es": "4m-10m - con tablones de barandilla 4x15",
            "fr": "4m-10m - avec planches de garde-corps 4x15",
            "it": "4m-10m - con tavola corrimano 4x15",
            "hu": "4m-10m - 4x15-ös korlátdeszkával",
            "ru": "4м-10м - с досками для перил 4x15",
            "sk": "4m-10m - s doskami zábradlia 4x15",
            "pl": "4m–10m – z deskami poręczy 4x15"
        },
        {
            "ID": "ZBGEGENSGFR__A1_AW8",
            "de": "4m-10m - mit Geländerbretter 3x20",
            "en-gb": "4m-10m - with handrail boards 3x20",
            "en-us": "4m-10m - with handrail boards 3x20",
            "es": "4m-10m - con tablones de barandilla 3x20",
            "fr": "4m-10m - avec planches de garde-corps 3x20",
            "it": "4m-10m - con tavola corrimano 3x20",
            "hu": "4m-10m - 3x20-as korlátdeszkával",
            "ru": "4м-10м - с досками для перил 3x20",
            "sk": "4m-10m - s doskami zábradlia 3x20",
            "pl": "4m–10m – z deskami poręczy 3x20"
        },
        {
            "ID": "ZBGEGENXPFI",
            "de": "Gegengeländer - Seitenschutz XP",
            "en-gb": "Counter railing - edge protection XP",
            "en-us": "Counter railing - edge protection XP",
            "es": "Contrabarandilla - protección lateral XP",
            "fr": "Garde-corps face coffrante - anti-chute XP",
            "it": "Parapetto di protezione - protezione laterale XP",
            "hu": "Ellenkorlát - oldalkorlát XP",
            "ru": "Комплект защитных ограждений - защитные перила XP",
            "sk": "Protizábradlie - bočná ochrana XP",
            "pl": "Poręcz – ochrona krawędzi XP"
        },
        {
            "ID": "ZBGEGENXPFI__A1",
            "de": "Gegengeländer - Seitenschutz XP",
            "en-gb": "Counter railing - edge protection XP",
            "en-us": "Counter railing - edge protection XP",
            "es": "Contrabarandilla - protección lateral XP",
            "fr": "Garde-corps face coffrante - anti-chute XP",
            "it": "Parapetto di protezione - protezione laterale XP",
            "hu": "Ellenkorlát - oldalkorlát XP",
            "ru": "Комплект защитных ограждений - защитные перила XP",
            "sk": "Protizábradlie - bočný ochranný XP",
            "pl": "Poręcz – ochrona krawędzi XP"
        },
        {
            "ID": "ZBGEGENXPFI__A1_AW1",
            "de": "mit Schutzgitter XP",
            "en-gb": "with protective barrier XP",
            "en-us": "with protective fence XP",
            "es": "con reja de protección XP",
            "fr": "avec barrière de protection XP",
            "it": "con griglia di protezione XP",
            "hu": "XP védőráccsal",
            "ru": "с защитной решёткой XP",
            "sk": "s ochrannou mrežou XP",
            "pl": "z siatką ochronną XP"
        },
        {
            "ID": "ZBGEGENXPFI__A1_AW2",
            "de": "mit Geländerbretter 2,4x15",
            "en-gb": "with handrail boards 2.4x15",
            "en-us": "with handrail boards 2.4x15",
            "es": "con tablones de barandilla 2,4x15",
            "fr": "avec planches de garde-corps 2,4x15",
            "it": "con tavola corrimano 2,4x15",
            "hu": "2,4x15-ös korlátdeszkával",
            "ru": "с досками для перил 2,4x15",
            "sk": "s doskami zábradlia 2,4x15",
            "pl": "z deskami poręczy 2,4x15"
        },
        {
            "ID": "ZBGEGENXPFI__A1_AW3",
            "de": "mit Geländerbretter 3x15",
            "en-gb": "with handrail boards 3x15",
            "en-us": "with handrail boards 3x15",
            "es": "con tablones de barandilla 3x15",
            "fr": "avec planches de garde-corps 3x15",
            "it": "con tavola corrimano 3x15",
            "hu": "3x15-ös korlátdeszkával",
            "ru": "с досками для перил 3x15",
            "sk": "s doskami zábradlia 3x15",
            "pl": "z deskami poręczy 3x15"
        },
        {
            "ID": "ZBGEGENXPFI__A1_AW4",
            "de": "mit Geländerbretter 4x15",
            "en-gb": "with handrail boards 4x15",
            "en-us": "with handrail boards 4x15",
            "es": "con tablones de barandilla 4x15",
            "fr": "avec planches de garde-corps 4x15",
            "it": "con tavola corrimano 4x15",
            "hu": "4x15-ös korlátdeszkával",
            "ru": "с досками для перил 4x15",
            "sk": "s doskami zábradlia 4x15",
            "pl": "z deskami poręczy 4x15"
        },
        {
            "ID": "ZBGEGENXPfr",
            "de": "Gegengeländer - Seitenschutz XP",
            "en-gb": "Counter railing - edge protection XP",
            "en-us": "Counter railing - edge protection XP",
            "es": "Contrabarandilla - protección lateral XP",
            "fr": "Garde-corps face coffrante - anti-chute XP",
            "it": "Parapetto di protezione - protezione laterale XP",
            "hu": "Ellenkorlát - oldalkorlát XP",
            "ru": "Комплект защитных ограждений - защитные перила XP",
            "sk": "Protizábradlie - bočná ochrana XP",
            "pl": "Poręcz – ochrona krawędzi XP"
        },
        {
            "ID": "ZBGEGENXPFR__A1",
            "de": "Gegengeländer - Seitenschutz XP",
            "en-gb": "Counter railing - edge protection XP",
            "en-us": "Counter railing - edge protection XP",
            "es": "Contrabarandilla - protección lateral XP",
            "fr": "Garde-corps face coffrante - anti-chute XP",
            "it": "Parapetto die protezione -protezione laterale XP",
            "hu": "Ellenkorlát - oldalkorlát XP",
            "ru": "Комплект защитных ограждений - защитные перила XP",
            "sk": "Protizábradlie - bočná ochrana XP",
            "pl": "Poręcz – ochrona krawędzi XP"
        },
        {
            "ID": "ZBGEGENXPFR__A1_AW1",
            "de": "mit Schutzgitter XP",
            "en-gb": "with protective barrier XP",
            "en-us": "with protective fence XP",
            "es": "con reja de protección XP",
            "fr": "avec barrière de protection XP",
            "it": "con griglia di protezione XP",
            "hu": "XP védőráccsal",
            "ru": "с защитной решёткой XP",
            "sk": "s ochrannou mrežou XP",
            "pl": "z siatką ochronną XP"
        },
        {
            "ID": "ZBGEGENXPFR__A1_AW2",
            "de": "mit Geländerbretter 2,4x15",
            "en-gb": "with handrail boards 2.4x15",
            "en-us": "with handrail boards 2.4x15",
            "es": "con tablones de barandilla 2,4x15",
            "fr": "avec planches de garde-corps 2,4x15",
            "it": "con tavola corrimano 2,4x15",
            "hu": "2,4x15-ös korlátdeszkával",
            "ru": "с досками для перил 2,4x15",
            "sk": "s doskami zábradlia 2,4x15",
            "pl": "z deskami poręczy 2,4x15"
        },
        {
            "ID": "ZBGEGENXPFR__A1_AW3",
            "de": "mit Geländerbretter 3x15",
            "en-gb": "with handrail boards 3x15",
            "en-us": "with handrail boards 3x15",
            "es": "con tablones de barandilla 3x15",
            "fr": "avec planches de garde-corps 3x15",
            "it": "con tavola corrimano 3x15",
            "hu": "3x15-ös korlátdeszkával",
            "ru": "с досками для перил 3x15",
            "sk": "s doskami zábradlia 3x15",
            "pl": "z deskami poręczy 3x15"
        },
        {
            "ID": "ZBGEGENXPFR__A1_AW4",
            "de": "mit Geländerbretter 4x15",
            "en-gb": "with handrail boards 4x15",
            "en-us": "with handrail boards 4x15",
            "es": "con tablones de barandilla 4x15",
            "fr": "avec planches de garde-corps 4x15",
            "it": "con tavola corrimano 4x15",
            "hu": "4x15-ös korlátdeszkával",
            "ru": "с досками для перил 4x15",
            "sk": "s doskami zábradlia 4x15",
            "pl": "z deskami poręczy 4x15"
        },
        {
            "ID": "ZBGEGENXPT",
            "de": "Gegengeländer - Seitenschutz XP",
            "en-gb": "Counter railing - edge protection XP",
            "en-us": "Counter railing - edge protection XP",
            "es": "Contrabarandilla - protección lateral XP",
            "fr": "Garde-corps face coffrante - anti-chute XP",
            "it": "Parapetto di protezione - protezione laterale XP",
            "hu": "Ellenkorlát - oldalkorlát XP",
            "ru": "Комплект защитных ограждений - защитные перила XP",
            "sk": "Protizábradlie - bočná ochrana XP",
            "pl": "Poręcz – ochrona krawędzi XP"
        },
        {
            "ID": "ZBGEGENXPT__A1",
            "de": "Gegengeländer - Seitenschutz XP",
            "en-gb": "Counter railing - edge protection XP",
            "en-us": "Counter railing - edge protection XP",
            "es": "Contrabarandilla - protección lateral XP",
            "fr": "Garde-corps face coffrante - anti-chute XP",
            "it": "Parapetto di protezione - protezione laterale XP",
            "hu": "Ellenkorlát - oldalkorlát XP",
            "ru": "Комплект защитных ограждений - защитные перила XP",
            "sk": "Protizábradlie - bočný ochranný XP",
            "pl": "Poręcz – ochrona krawędzi XP"
        },
        {
            "ID": "ZBGEGENXPT__A1_AW1",
            "de": "mit Schutzgitter XP",
            "en-gb": "with protective barrier XP",
            "en-us": "with protective fence XP",
            "es": "con reja de protección XP",
            "fr": "avec barrière de protection XP",
            "it": "con griglia di protezione XP",
            "hu": "XP védőráccsal",
            "ru": "с защитной решёткой XP",
            "sk": "s ochrannou mrežou XP",
            "pl": "z siatką ochronną XP"
        },
        {
            "ID": "ZBGEGENXPT__A1_AW2",
            "de": "mit Geländerbretter 2,4x15",
            "en-gb": "with handrail boards 2.4x15",
            "en-us": "with handrail boards 2.4x15",
            "es": "con tablones de barandilla 2,4x15",
            "fr": "avec planches de garde-corps 2,4x15",
            "it": "con tavola corrimano 2,4x15",
            "hu": "2,4x15-ös korlátdeszkával",
            "ru": "с досками для перил 2,4x15",
            "sk": "s doskami zábradlia 2,4x15",
            "pl": "z deskami poręczy 2,4x15"
        },
        {
            "ID": "ZBGEGENXPT__A1_AW3",
            "de": "mit Geländerbretter 3x15",
            "en-gb": "with handrail boards 3x15",
            "en-us": "with handrail boards 3x15",
            "es": "con tablones de barandilla 3x15",
            "fr": "avec planches de garde-corps 3x15",
            "it": "con tavola corrimano 3x15",
            "hu": "3x15-ös korlátdeszkával",
            "ru": "с досками для перил 3x15",
            "sk": "s doskami zábradlia 3x15",
            "pl": "z deskami poręczy 3x15"
        },
        {
            "ID": "ZBGEGENXPT__A1_AW4",
            "de": "mit Geländerbretter 4x15",
            "en-gb": "with handrail boards 4x15",
            "en-us": "with handrail boards 4x15",
            "es": "con tablones de barandilla 4x15",
            "fr": "avec planches de garde-corps 4x15",
            "it": "con tavola corrimano 4x15",
            "hu": "4x15-ös korlátdeszkával",
            "ru": "с досками для перил 4x15",
            "sk": "s doskami zábradlia 4x15",
            "pl": "z deskami poręczy 4x15"
        },
        {
            "ID": "ZBJUSPIXLI",
            "de": "Abstell- und Einrichthilfen",
            "en-gb": "Plumbing accessories",
            "en-us": "Plumbing accessories",
            "es": "Ayudas de estabilización y de aplomado",
            "fr": "Béquilles de réglage et de stabilité",
            "it": "Supporti e puntellazioni",
            "hu": "Beállítási segédeszközök",
            "ru": "Рабочие подмости и вспомогательные приспособления для рихтовки",
            "sk": "Stabilizačné a nastavovacie pomôcky",
            "pl": "Wypory"
        },
        {
            "ID": "ZBJUSPIXLI__A1",
            "de": "Abstell- und Einrichthilfen",
            "en-gb": "Plumbing accessories",
            "en-us": "Plumbing accessories",
            "es": "Ayudas de estabilización y de aplomado",
            "fr": "Béquilles de réglage et de stabilité",
            "it": "Supporti e puntellazioni",
            "hu": "Beállítási segédeszközök",
            "ru": "Рабочие подмости и вспомогательные приспособления для рихтовки",
            "sk": "Stabilizačné a nastavovacie pomôcky",
            "pl": "Wypory"
        },
        {
            "ID": "ZBJUSPIXLI__A1_AW1",
            "de": "Justierstütze 260",
            "en-gb": "Plumbing strut 260",
            "en-us": "Plumbing strut 260",
            "es": "Puntal de ajuste 260",
            "fr": "Bracon principal 260",
            "it": "Puntello di piombatura 260",
            "hu": "Beállítótámasz 260",
            "ru": "Стойка 260",
            "sk": "Nastavovacia vzpera 260",
            "pl": "Wypora 260"
        },
        {
            "ID": "ZBJUSPIXLI__A1_AW2",
            "de": "Elementstütze 340",
            "en-gb": "Panel strut 340",
            "en-us": "Panel strut 340",
            "es": "Puntal estabilizador 340",
            "fr": "Étançon de banche 340",
            "it": "Puntellazione di sostegno 340",
            "hu": "Elemtámasz 340",
            "ru": "Подпорный раскос 340",
            "sk": "Panelová vzpera 340",
            "pl": "Wypora ukośna podwójna 340"
        },
        {
            "ID": "ZBJUSPIXLI__A1_AW3",
            "de": "Elementstütze 540",
            "en-gb": "Panel strut 540",
            "en-us": "Panel strut 540",
            "es": "Puntal estabilizador 540",
            "fr": "Étançon de banche 540",
            "it": "Puntellazione di sostegno 540",
            "hu": "Elemtámasz 540",
            "ru": "Подпорный раскос 540",
            "sk": "Panelová vzpera 540",
            "pl": "Wypora ukośna podwójna 540"
        },
        {
            "ID": "ZBJUSPIXLI__A2",
            "de": "Aufstockung",
            "en-gb": "Stacking",
            "en-us": "Stacking",
            "es": "Apilado",
            "fr": "Rehaussé",
            "it": "Sovrapposizione",
            "hu": "Magasítás",
            "ru": "Надстройка",
            "sk": "Nadstavenie",
            "pl": "Nadstawienie"
        },
        {
            "ID": "ZBJUSPIXLI__A2_AW1",
            "de": "ohne Aufstockung",
            "en-gb": "w/o stacking",
            "en-us": "w/o stacking",
            "es": "Sin apilado",
            "fr": "sans rehausse",
            "it": "senza sopralzo",
            "hu": "Magasítás nélkül",
            "ru": "без надстройки",
            "sk": "bez nadstavenia",
            "pl": "bez nadstawienia"
        },
        {
            "ID": "ZBJUSPIXLI__A2_AW2",
            "de": "Liegend oder stehend aufgestockt",
            "en-gb": "stacked horiz. or upright",
            "en-us": "stacked horiz. or upright",
            "es": "Apilado horizontal/vertical",
            "fr": "Rehaussé couchant/vertical",
            "it": "Sovrapposizione orizz./vert.",
            "hu": "Magasítás fektetve vagy állítva",
            "ru": "Надстройка в горизонтальном a вертикальном положении",
            "sk": "nadstavenie naležato alebo nastojato",
            "pl": "nadstawienie poziome lub pionowe"
        },
        {
            "ID": "ZBJUSPIXLI__A3",
            "de": "Grundelementhöhe",
            "en-gb": "Basic panel height",
            "en-us": "Basic panel height",
            "es": "Altura del elemento base",
            "fr": "Hauteur de base",
            "it": "Altezza dell’ elemento base",
            "hu": "Alapelem magasság",
            "ru": "Высота основного элемента",
            "sk": "Výška základného panelu",
            "pl": "Wysokość płyty podstawowej"
        },
        {
            "ID": "ZBJUSPIXLI__A3_AW1",
            "de": "1,50m",
            "en-gb": "1.50m",
            "en-us": "1.50m",
            "es": "1,50m",
            "fr": "1,50m",
            "it": "1,50m",
            "hu": "1,50m",
            "ru": "1,50м",
            "sk": "1,50m",
            "pl": "1,50m"
        },
        {
            "ID": "ZBJUSPIXLI__A3_AW2",
            "de": "3,00m",
            "en-gb": "3.00m",
            "en-us": "3.00m",
            "es": "3,00m",
            "fr": "3,00m",
            "it": "3,00m",
            "hu": "3,00m",
            "ru": "3,00м",
            "sk": "3,00m",
            "pl": "3,00m"
        },
        {
            "ID": "ZBJUSPIXLI__A3_AW3",
            "de": "1,00m",
            "en-gb": "1.00m",
            "en-us": "1.00m",
            "es": "1,00m",
            "fr": "1,00m",
            "it": "1,00m",
            "hu": "1,00m",
            "ru": "1,00м",
            "sk": "1,00m",
            "pl": "1,00m"
        },
        {
            "ID": "ZBJUSTSPIN",
            "de": "Frami Abstell- und Einrichthilfen",
            "en-gb": "Frami plumbing accessories",
            "en-us": "Frami plumbing and installation accessories",
            "es": "Ayudas de estabilización y de aplomado Frami",
            "fr": "Béquilles de réglage et de stabilité Frami",
            "it": "Supporti e puntellazioni Frami",
            "hu": "Frami Beállítási segédeszközök",
            "ru": "Вспомогательные средства для установки и рихтовки Frami",
            "sk": "Frami Stabilizačné a nastavovacie pomôcky",
            "pl": "Wypory Frami"
        },
        {
            "ID": "ZBJUSTSPIN__A1",
            "de": "Abstell- und Einrichthilfen",
            "en-gb": "Plumbing accessories",
            "en-us": "Plumbing accessories",
            "es": "Ayudas de estabilización y de aplomado",
            "fr": "Béquilles de réglage et de stabilité",
            "it": "Supporti e puntellazioni",
            "hu": "Beállítási segédeszközök",
            "ru": "Рабочие подмости и вспомогательные приспособления для рихтовки",
            "sk": "Stabilizačné a nastavovacie pomôcky",
            "pl": "Wypory"
        },
        {
            "ID": "ZBJUSTSPIN__A1_AW1",
            "de": "Frami-Justierstütze 260",
            "en-gb": "Frami plumbing strut 260",
            "en-us": "Frami plumbing strut 260",
            "es": "Puntal de ajuste Frami 260",
            "fr": "Bracon principal Frami 260",
            "it": "Puntello di piombatura Frami 260",
            "hu": "Frami beállítótámasz 260",
            "ru": "Frami стойка 260",
            "sk": "Frami-nastavovacia vzpera 260",
            "pl": "Wypora ukośna pojedyńcza 260 Frami"
        },
        {
            "ID": "ZBJUSTSPIN__A1_AW2",
            "de": "Elementstütze 340",
            "en-gb": "Panel strut 340",
            "en-us": "Panel strut 340",
            "es": "Puntal estabilizador 340",
            "fr": "Étançon de banche 340",
            "it": "Puntellazione di sostegno 340",
            "hu": "Elemtámasz 340",
            "ru": "Подпорный раскос 340",
            "sk": "Panelová vzpera 340",
            "pl": "Wypora ukośna podwójna 340"
        },
        {
            "ID": "ZBJUSTSPIN__A1_AW3",
            "de": "Elementstütze 540",
            "en-gb": "Panel strut 540",
            "en-us": "Panel strut 540",
            "es": "Puntal estabilizador 540",
            "fr": "Étançon de banche 540",
            "it": "Puntellazione di sostegno 540",
            "hu": "Elemtámasz 540",
            "ru": "Подпорный раскос 540",
            "sk": "Panelová vzpera 540",
            "pl": "Wypora ukośna podwójna 540"
        },
        {
            "ID": "ZBJUSTSP-S",
            "de": "Frami S Abstell- und Einrichthilfen",
            "en-gb": "Frami S plumbing accessories",
            "en-us": "Frami S plumbing and installation accessories",
            "es": "Ayudas de estabilización y de aplomado Frami S",
            "fr": "Béquilles de réglage et de stabilité Frami S",
            "it": "Supporti e puntellazioni Frami S",
            "hu": "Frami S Beállítási segédeszközök",
            "ru": "Вспомогательные средства для установки и рихтовки Frami S",
            "sk": "Frami S Stabilizačné a nastavovacie pomôcky",
            "pl": "Wypory Frami S"
        },
        {
            "ID": "ZBJUSTSP-S__A1",
            "de": "Gerade Füllwand",
            "en-gb": "Straight infill wall",
            "en-us": "Straight infill wall",
            "es": "Muro recto",
            "fr": "Voile à compléter",
            "it": "Parete di riempimento dritta",
            "hu": "egyenes kitöltő falazat",
            "ru": "Прямая пустотелая стена",
            "sk": "Rovná plná stena",
            "pl": "Prosta ściana wypełniająca"
        },
        {
            "ID": "ZBJUSTSP-S__A1_AW1",
            "de": "Stehend aufgestockt",
            "en-gb": "stacked upright",
            "en-us": "stacked upright",
            "es": "Apilado vertical",
            "fr": "Rehaussé deboutant",
            "it": "Sovrapposizione vert.",
            "hu": "Állítva magasítva",
            "ru": "надстройка в вертикальном положении",
            "sk": "Nadstavenie nastojato",
            "pl": "nadstawienie pionowe"
        },
        {
            "ID": "ZBJUSTSP-S__A1_AW2",
            "de": "Liegend aufgestockt",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz.",
            "es": "Apilado horizontal",
            "fr": "Rehaussé couchant",
            "it": "Sovrapposizione orizz.",
            "hu": "Fektetve magasítva",
            "ru": "надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "pl": "nadstawienie poziome"
        },
        {
            "ID": "ZBJUSTSP-S__A1_AW3",
            "de": "Stehend und liegend aufgestockt",
            "en-gb": "Stacked upright and horiz.",
            "en-us": "Stacked upright and horiz.",
            "es": "Elementos verticales apilados vert. y horiz.",
            "fr": "Px debouts rehaussés deboutants et couchants",
            "it": "Elementi vert. sovrapposizione vert. e orizz.",
            "hu": "Elemek állítva állítva és fektetve magasítva",
            "ru": "Вертикальные элементы, надстройка в вертикальном и горизонтальном положении",
            "sk": "Panely nastojato, nadst. nastojato a naležato",
            "pl": "Nadstawienie pionowe i poziome"
        },
        {
            "ID": "ZBJUSTSP-S__A2",
            "de": "Fixierung an der Schalung (ab Höhe 12')",
            "en-gb": "Fixing the struts on the formwork (from height 12')",
            "en-us": "Fixing the struts to the formwork (from height 12')",
            "es": "Fijación en el encofrado (de altura 12')",
            "fr": "Fixation au coffrage (à partir de hauteur 12')",
            "it": "Fissare alla cassaforma (da h 12')",
            "hu": "Rögzítés a zsalun (12' magasságtól)",
            "ru": "Фиксация на опалубке (от высоты 12')",
            "sk": "Upevnenie na debnení (od výšky 12')",
            "pl": "Mocowanie wypór na szalunku (od wysokości 12')"
        },
        {
            "ID": "ZBJUSTSP-S__A2_AW1",
            "de": "Stützenkopf",
            "en-gb": "Prop head",
            "en-us": "Bracing head",
            "es": "Cabezal",
            "fr": "Tête d'étançon",
            "it": "Testa per puntello di regolazione",
            "hu": "Támasz fej",
            "ru": "Головка колонны",
            "sk": "Hlava opory",
            "pl": "Głowica wypory"
        },
        {
            "ID": "ZBJUSTSP-S__A2_AW2",
            "de": "Anschlussprofil",
            "en-gb": "Connection profile",
            "en-us": "Connection profile",
            "es": "Perfil de conexión",
            "fr": "Profilé de connexion",
            "it": "Profilo di collegamento",
            "hu": "Csatlakozóprofil",
            "ru": "Стыковой профиль",
            "sk": "Prípojný profil",
            "pl": "Profil połączenia"
        },
        {
            "ID": "ZBJUSTSP-S__A3",
            "de": "Stützenauswahl",
            "en-gb": "Prop selection",
            "en-us": "Prop selection",
            "es": "Prop selection",
            "fr": "Sélection d'étai",
            "it": "Seleziona puntello",
            "hu": "Födémtámasz kiválasztás",
            "ru": "Выбор стоек",
            "sk": "Výber opôr",
            "pl": "Prop selection"
        },
        {
            "ID": "ZBJUSTSP-S__A3_AW1",
            "de": "Höhe 6'-18': Stütze 340 oder 540",
            "en-gb": "Height 6'-18': prop 340 or 540",
            "en-us": "Height 6'-18': prop 340 or 540",
            "es": "Altura 6'-18': Puntal 340 o 540",
            "fr": "Hauteur 6'-18': Etançon 340 ou 540",
            "it": "Altezza 6'-18': Puntello 340 o 540",
            "hu": "Magassága 6'-18': Támasz 340 vagy 540",
            "ru": "Высота 6'-18': Колонна 340 или 540",
            "sk": "Výška 6'-18': Podpera 340 oder 540",
            "pl": "Wysokość 6'-18': podpora 340 or 540"
        },
        {
            "ID": "ZBJUSTSP-S__A3_AW2",
            "de": "Höhe 6'-18': Einrichtstrebe 12'-21'",
            "en-gb": "Height 6'-18': pipe brace 12'-21'",
            "en-us": "Height 6'-18': pipe brace 12'-21'",
            "es": "Altura 6'-18': Puntal estabilizador 12'-21'",
            "fr": "Hauteur 6'-18': Étançon grande hauteur 12'-21'",
            "it": "Altezza 6'-18': Puntonen messa in opera 12'-21'",
            "hu": "Magassága 6'-18': Beállító támaszrúd 12'-21'",
            "ru": "Высота 6'-18': Рихтующий раскос 12'-21'",
            "sk": "Výška 6'-18': Nastavovacia vzpera 12'-21'",
            "pl": "Wysokość 6'-18': obejma rury  12'-21'"
        },
        {
            "ID": "ZBJUSTSP-S__A3_AW3",
            "de": "Höhe 21'-24': Stütze 340 + Eurex 60 550",
            "en-gb": "Height 21'-24': prop 340 + Eurex 60 550",
            "en-us": "Height 21'-24': prop 340 + Eurex 60 550",
            "es": "Altura 21'-24': Puntal 340 + Eurex 60 550",
            "fr": "Hauteur 21'-24': Etançon 340 + Eurex 60 550",
            "it": "Altezza 21'-24': Puntello 340 + Eurex 60 550",
            "hu": "Magassága 21'-24': Támasz 340 + Eurex 60 550",
            "ru": "Высота 21'-24': Колонна 340 + Eurex 60 550",
            "sk": "Výška 21'-24': Podpera 340 + Eurex 60 550",
            "pl": "Wysokość 21′–24′: podpora 340 + Eurex 60 550"
        },
        {
            "ID": "ZBJUSTSP-S__A3_AW4",
            "de": "Höhe 21'-24': Stütze 340 + Einrichtstrebe 22'-40'",
            "en-gb": "Height 21'-24': prop 340 + pipe brace 22'-40'",
            "en-us": "Height 21'-24': prop 340 + pipe brace 22'-40'",
            "es": "Altura 21'-24': Puntal 340 + Puntal estabilizador 22'-40'",
            "fr": "Hauteur 21'-24': Etançon 340 + Étançon grande hauteur  22'-40'",
            "it": "Altezza 21'-24': Puntello 340 + Einrichtstrebe 22'-40'",
            "hu": "Magassága 21'-24': Támasz 340 + Beállító támaszrúd 22'-40'",
            "ru": "Высота 21'-24': Колонна 340 + Рихтующий раскос 22'-40'",
            "sk": "Výška 21'-24': Podpera 340 + Nastavovacia vzpera 22'-40'",
            "pl": "Wysokość 21'-24': podpora 340 + obejma rury 22'-40'"
        },
        {
            "ID": "ZBJUSTSP-S__A3_AW5",
            "de": "Höhe 21'-24': Stütze 540 + Eurex 60 550",
            "en-gb": "Height 21'-24': prop 540 + Eurex 60 550",
            "en-us": "Height 21'-24': prop 540 + Eurex 60 550",
            "es": "Altura 21'-24': Puntal 540 + Eurex 60 550",
            "fr": "Hauteur 21'-24': Etançon 540 + Eurex 60 550",
            "it": "Altezza 21'-24': Puntello 540 + Eurex 60 550",
            "hu": "Magassága 21'-24': Támasz 540 + Eurex 60 550",
            "ru": "Высота 21'-24': Колонна 540 + Eurex 60 550",
            "sk": "Výška 21'-24': Podpera 540 + Eurex 60 550",
            "pl": "Wysokość 21'-24': podpora 540 + Eurex 60 550"
        },
        {
            "ID": "ZBJUSTSP-S__A3_AW6",
            "de": "Höhe 27': Stütze 540 + Einrichtstrebe 22'-40'",
            "en-gb": "Height 27': prop 540 + pipe brace 22'-40'",
            "en-us": "Height 27': prop 540 + pipe brace 22'-40'",
            "es": "Altura 27': Puntal 540 + Puntal estabilizador 22'-40'",
            "fr": "Hauteur 27': Etançon 540 + Étançon grande hauteur  22'-40'",
            "it": "Altezza 27': Puntello 540 + Puntonen messa in opera 22'-40'",
            "hu": "Magassága 27': Támasz 540 + Beállító támaszrúd 22'-40'",
            "ru": "Высота 27': Колонна 540 + Рихтующий раскос 22'-40'",
            "sk": "Výška 27': Podpera 540 + Nastavovacia vzpera 22'-40'",
            "pl": "Wysokość 27': podpora 540 + obejma rury  22'-40'"
        },
        {
            "ID": "ZBJUSTSP-S__A3_AW7",
            "de": "Höhe 27': Einrichtstrebe 12'-21' + Eurex 60 550",
            "en-gb": "Height 27': pipe brace 12'-21' + Eurex 60 550",
            "en-us": "Height 27': pipe brace 12'-21' + Eurex 60 550",
            "es": "Altura 27': Puntal estabilizador 12'-21' + Eurex 60 550",
            "fr": "Hauteur 27': Étançon grande hauteur  12'-21' + Eurex 60 550",
            "it": "Altezza 27': Puntonen messa in opera 12'-21' + Eurex 60 550",
            "hu": "Magassága 27': Beállító támaszrúd 12'-21' + Eurex 60 550",
            "ru": "Высота 27': Рихтующий раскос 12'-21' + Eurex 60 550",
            "sk": "Výška 27': Nastavovacia vzpera 12'-21' + Eurex 60 550",
            "pl": "Wysokość 27': obejma rury  12'-21' + Eurex 60 550"
        },
        {
            "ID": "ZBJUSTSP-S__A3_AW8",
            "de": "Höhe 27': Einrichtstrebe 12'-21' + Einrichtstrebe 22'-40'",
            "en-gb": "Height 27': pipe brace 12'-21' + pipe brace 22'-40'",
            "en-us": "Height 27': pipe brace 12'-21' + pipe brace 22'-40'",
            "es": "Altura 27': Puntal estabilizador 12'-21' + Puntal estabilizador 22'-40'",
            "fr": "Hauteur 27': Étançon grande hauteur  12'-21' + Étançon grande hauteur  22'-40'",
            "it": "Altezza 27': Puntonen messa in opera 12'-21' + Puntonen messa in opera 22'-40'",
            "hu": "Magassága 27': Beállító támaszrúd 12'-21' + Beállító támaszrúd 22'-40'",
            "ru": "Высота 27': Рихтующий раскос 12'-21' + Рихтующий раскос 22'-40'",
            "sk": "Výška 27': Nastavovacia vzpera 12'-21' + Nastavovacia vzpera 22'-40'",
            "pl": "Wysokość 27': obejma rury  12'-21' + obejma rury  22'-40'"
        },
        {
            "ID": "ZBKON60XLI",
            "de": "DokaXlight-Konsole 60",
            "en-gb": "DokaXlight bracket 60",
            "en-us": "DokaXlight bracket 60",
            "es": "Ménsula DokaXlight 60",
            "fr": "Console DokaXlight 60",
            "it": "Mensola DokaXlight 60",
            "hu": "DokaXlight konzol 60",
            "ru": "Консоль DokaXlight 60",
            "sk": "DokaXlight-konzola 60",
            "pl": "Konsola DokaXlight 60"
        },
        {
            "ID": "ZBKON60XLI__A1",
            "de": "DokaXlight-Konsole 60",
            "en-gb": "DokaXlight bracket 60",
            "en-us": "DokaXlight bracket 60",
            "es": "Ménsula DokaXlight 60",
            "fr": "Console DokaXlight 60",
            "it": "Mensola DokaXlight 60",
            "hu": "DokaXlight konzol 60",
            "ru": "Консоль DokaXlight 60",
            "sk": "DokaXlight-konzola 60",
            "pl": "Konsola DokaXlight 60"
        },
        {
            "ID": "ZBKON60XLI__A1_AW1",
            "de": "DokaXlight-Konsole 60",
            "en-gb": "DokaXlight bracket 60",
            "en-us": "DokaXlight bracket 60",
            "es": "Ménsula DokaXlight 60",
            "fr": "Console DokaXlight 60",
            "it": "Mensola DokaXlight 60",
            "hu": "DokaXlight konzol 60",
            "ru": "Консоль DokaXlight 60",
            "sk": "DokaXlight-konzola 60",
            "pl": "Konsola DokaXlight 60"
        },
        {
            "ID": "ZbKon90-Al",
            "de": "Framax-Konsole 90",
            "en-gb": "Framax bracket 90",
            "en-us": "Framax bracket 90",
            "es": "Ménsula Framax 90",
            "fr": "Console Framax 90",
            "it": "Mensola Framax 90",
            "hu": "Framax konzol 90",
            "ru": "Консоль Framax 90",
            "sk": "Framax-konzola 90",
            "pl": "Konsola Framax 90"
        },
        {
            "ID": "ZbKon90-Al__A1",
            "de": "Framax-Konsole 90",
            "en-gb": "Framax bracket 90",
            "en-us": "Framax bracket 90",
            "es": "Ménsula Framax 90",
            "fr": "Console Framax 90",
            "it": "Mensola Framax 90",
            "hu": "Framax konzol 90",
            "ru": "Консоль Framax 90",
            "sk": "Framax-konzola 90",
            "pl": "Konsola Framax 90"
        },
        {
            "ID": "ZbKon90-Al__A1_AW1",
            "de": "Framax-Konsole 90 Einhängung Querprofil",
            "en-gb": "Framax bracket 90 fixed in cross profile",
            "en-us": "Framax bracket 90 fixed in cross profile",
            "es": "Ménsula Framax 90 Enganche en perfil transv.",
            "fr": "Console Framax 90 accrochage dans profilé transv.",
            "it": "Mensola Framax 90 Aggancio prof. trasversale",
            "hu": "Framax konzol 90 befüggesztés a keresztprofilba",
            "ru": "Консоль Framax 90 навешивание поперечный профиль",
            "sk": "Framax-konzola 90 zavesenie v priečnom profile",
            "pl": "Konsola Framax 90 mocowana w profilu poprzecznym"
        },
        {
            "ID": "ZbKon90-Al__A1_AW2",
            "de": "Framax-Konsole 90 Einhängung Querprofil Element liegend",
            "en-gb": "Framax bracket 90 fixed in cross profile element horiz.",
            "en-us": "Framax bracket 90 fixed in cross profile element horiz.",
            "es": "Ménsula Framax 90 Enganche en perfil transv. elemento horizontal",
            "fr": "Console Framax 90 accrochage dans profilé transv. px couchés",
            "it": "Mensola Framax 90 Aggancio prof. trasversale Elemento coricato",
            "hu": "Framax konzol 90 befüggesztés a keresztprofilba Elem vízszintes",
            "ru": "Консоль Framax 90 навешивание поперечный профиль элемент расположен горизонтально",
            "sk": "Framax-konzola 90 zavesenie v priečnom profile, panel naležato",
            "pl": "Konsola Framax 90 mocowana w profilu poprzecznym"
        },
        {
            "ID": "ZBKON90-FF",
            "de": "FF20 Universalkonsole 90",
            "en-gb": "FF20 Universal bracket 90",
            "en-us": "FF20 Universal bracket 90",
            "es": "FF20 Ménsula universal 90",
            "fr": "FF20 Console universelle 90",
            "it": "FF20 Mensola universale 90",
            "hu": "FF20 Univerzális konzol 90",
            "ru": "FF20 универсальная консоль 90",
            "sk": "FF20 Univerzálna konzola 90",
            "pl": "Konsola uniwersalna 90 FF20"
        },
        {
            "ID": "ZBKON90-FF__A1",
            "de": "FF20 Universalkonsole 90",
            "en-gb": "FF20 Universal bracket 90",
            "en-us": "FF20 Universal bracket 90",
            "es": "FF20 Ménsula universal 90",
            "fr": "FF20 Console universelle 90",
            "it": "FF20 Mensola universale 90",
            "hu": "FF20 univerzális konzol 90",
            "ru": "FF20 универсальная консоль 90",
            "sk": "FF20 Univerzálna konzola 90",
            "pl": "Konsola uniwersalna 90 FF20"
        },
        {
            "ID": "ZBKON90-FF__A1_AW1",
            "de": "FF20 Universalkonsole 90",
            "en-gb": "FF20 Universal bracket 90",
            "en-us": "FF20 Universal bracket 90",
            "es": "FF20 Ménsula universal 90",
            "fr": "FF20 Console universelle 90",
            "it": "FF20 Mensola universale 90",
            "hu": "FF20 univerzális konzol 90",
            "ru": "FF20 универсальная консоль 90",
            "sk": "FF20 Univerzálna konzola 90",
            "pl": "Konsola uniwersalna 90 FF20"
        },
        {
            "ID": "ZBKON90-FF__A1_AW2",
            "de": "FF20 Universalkonsole 90 Aufstockelement",
            "en-gb": "FF20 Universal bracket 90 stacking element",
            "en-us": "FF20 Universal bracket 90 Stacking element",
            "es": "FF20 Ménsula universal 90 Panel para apilar",
            "fr": "FF20 Console universelle 90 Px de rehausse",
            "it": "FF20 Mensola universale 90 Elemento sovrapp.",
            "hu": "FF20 univerzális konzol 90 magasító elem",
            "ru": "FF20 универсальная консоль 90 элемент надстройки",
            "sk": "FF20 Univerzálna konzola 90 Nadstavenia",
            "pl": "FF20 Element nadstawienia do konsoli uniwersalnej 90"
        },
        {
            "ID": "ZBKONFF100",
            "de": "FF100 Universalkonsole 90",
            "en-gb": "FF100 Universal bracket 90",
            "en-us": "FF100 Universal bracket 90",
            "es": "FF100 Ménsula universal 90",
            "fr": "FF100 Console universelle 90",
            "it": "FF100 Mensola universale 90",
            "hu": "FF100 Univerzális konzol 90",
            "ru": "FF100 универсальная консоль 90",
            "sk": "FF100 Univerzálna konzola 90",
            "pl": "FF100 Konsola uniwersalna 90"
        },
        {
            "ID": "ZBKONFF100__A1",
            "de": "FF100 Universalkonsole 90",
            "en-gb": "FF100 universal bracket 90",
            "en-us": "FF100 universal bracket 90",
            "es": "FF100 ménsula universal 90",
            "fr": "FF100 Console universelle 90",
            "it": "FF100 Mensola universale 90",
            "hu": "FF100 univerzális konzol 90",
            "ru": "FF100 универсальная консоль 90",
            "sk": "FF100 univerzálna konzola 90",
            "pl": "FF100 Konsola uniwersalna 90"
        },
        {
            "ID": "ZBKONFF100__A1_AW1",
            "de": "FF100 Universalkonsole 90 hoch",
            "en-gb": "FF100 universal bracket 90 top",
            "en-us": "FF100 universal bracket 90 top",
            "es": "FF100 ménsula universal 90 alta",
            "fr": "FF100 Console universelle 90 haut",
            "it": "FF100 Mensola universale 90 alto",
            "hu": "FF100 univerzális konzol 90 felső",
            "ru": "FF100 универсальная консоль 90 верхняя",
            "sk": "FF100 univerzálna konzola 90 vyššie",
            "pl": "FF100 konsola uniwersalna 90 wyżej"
        },
        {
            "ID": "ZBKONFF100__A1_AW2",
            "de": "FF100 Universalkonsole 90 tief",
            "en-gb": "FF100 universal bracket 90 lower",
            "en-us": "FF100 universal bracket 90 lower",
            "es": "FF100 ménsula universal 90 baja",
            "fr": "FF100 Console universelle 90 bas",
            "it": "FF100 Mensola universale 90 basso",
            "hu": "FF100 univerzális konzol 90 alsó",
            "ru": "FF100 универсальная консоль 90 нижняя",
            "sk": "FF100 univerzálna konzola 90 nižšie",
            "pl": "FF100 konsola uniwersalna 90 niżej"
        },
        {
            "ID": "ZBKONFINI",
            "de": "Framini-Konsole 60",
            "en-gb": "Framini bracket 60",
            "en-us": "Framini bracket 60",
            "es": "Framini - Ménsula 60",
            "fr": "Console Framini 60",
            "it": "Mensola Framini 60",
            "hu": "Framini konzol 60",
            "ru": "Консоль Framini 60",
            "sk": "Framini-konzola 60",
            "pl": "Konsola Framini 60"
        },
        {
            "ID": "ZBKONFINI__A1",
            "de": "Framini-Konsole 60",
            "en-gb": "Framini bracket 60",
            "en-us": "Framini bracket 60",
            "es": "Framini - Ménsula 60",
            "fr": "Console Framini 60",
            "it": "Mensola Framini 60",
            "hu": "Framini konzol 60",
            "ru": "Консоль Framini 60",
            "sk": "Framini-konzola 60",
            "pl": "Konsola Framini 60"
        },
        {
            "ID": "ZBKONFINI__A1_AW1",
            "de": "Framini-Konsole 60 am Elementstoß",
            "en-gb": "Framini bracket 60 fixed in panel connection",
            "en-us": "Framini bracket 60 fixed in panel connection",
            "es": "Ménsula Framini 60 en la junta del elemento",
            "fr": "Console Framini 60 sur joint d’élément",
            "it": "Mensola Framini 60 nella connessione dei casseri",
            "hu": "Framini konzol 60 elemcsatlakozáson",
            "ru": "Кронштейн Framini 60 закреплен на стыке элементов",
            "sk": "Framini-konzola 60 na styku panelov",
            "pl": "Konsola Framini 60 mocowana w łączeniu płyt"
        },
        {
            "ID": "ZBKONFINI__A1_AW2",
            "de": "Framini-Konsole 60 am Elementrand",
            "en-gb": "Framini bracket 60 fixed in frame profile",
            "en-us": "Framini bracket 60 fixed in frame profile",
            "es": "Ménsula Framini 60 en el borde del elemento",
            "fr": "Console Framini 60 sur bord d’élément",
            "it": "Mensola Framini 60 nel telaio del cassero",
            "hu": "Framini konzol 60 keretprofilon",
            "ru": "Кронштейн Framini 60 закреплен в рамном профиле",
            "sk": "Framini-konzola 60 na okraji panela",
            "pl": "Konsola Framini 60 mocowana w profilu ramowym"
        },
        {
            "ID": "ZBKONFINI__A1_AW3",
            "de": "Framini-Konsole 60 bei liegenden Elementen",
            "en-gb": "Framini bracket 60 fixed in cross profile",
            "en-us": "Framini bracket 60 fixed in cross profile",
            "es": "Ménsula Framini 60 en el perfil transversal",
            "fr": "Console Framini 60 pour éléments horizontaux",
            "it": "Mensola Framini 60 nei profili",
            "hu": "Framini konzol 60 fekvő elemen",
            "ru": "Кронштейн Framini 60 закреплен на поперечном профиле",
            "sk": "Framini-konzola 60 pri ležatých paneloch",
            "pl": "Konsola Framini 60 mocowana w profilu poprzecznym"
        },
        {
            "ID": "ZBKONL-FF",
            "de": "FF20 Betonierkonsole L",
            "en-gb": "FF20 Top scaffold bracket L",
            "en-us": "FF20 Top scaffold bracket L",
            "es": "FF20 Ménsula para hormigonar L",
            "fr": "FF20 Console de bétonnage L",
            "it": "FF20 Mensola di getto L",
            "hu": "FF20 Betonozókonzol L",
            "ru": "FF20 Консоль для бетонирования L",
            "sk": "FF20 Betónovacia konzola L",
            "pl": "Konsola betoniarska L FF20"
        },
        {
            "ID": "ZBKonL-FF__A1",
            "de": "FF20 Betonierkonsole L",
            "en-gb": "FF20 Top scaffold bracket L",
            "en-us": "FF20 Top scaffold bracket L",
            "es": "FF20 Ménsula para hormigonar L",
            "fr": "FF20 Console de bétonnage L",
            "it": "FF20 Mensola di getto L",
            "hu": "FF20 betonozókonzol L",
            "ru": "FF20 Консоль для бетонирования L",
            "sk": "FF20 Betónovacia konzola L",
            "pl": "Konsola betoniarska L FF20"
        },
        {
            "ID": "ZBKonL-FF__A1_AW1",
            "de": "FF20 Betonierkonsole L",
            "en-gb": "FF20 Top scaffold bracket L",
            "en-us": "FF20 Top scaffold bracket L",
            "es": "FF20 Ménsula para hormigonar L",
            "fr": "FF20 Console de bétonnage L",
            "it": "FF20 Mensola di getto L",
            "hu": "FF20 betonozókonzol L",
            "ru": "FF20 Консоль для бетонирования L",
            "sk": "FF20 Betónovacia konzola L",
            "pl": "Konsola betoniarska L FF20"
        },
        {
            "ID": "ZBKonL-FF__A1_AW2",
            "de": "FF20 Betonierkonsole L Aufstockelement",
            "en-gb": "FF20 Top scaffold bracket L stacking element",
            "en-us": "FF20 Top scaffold bracket L Stacking element",
            "es": "FF20 Ménsula para hormigonar L Panel para apilar",
            "fr": "FF20 Console de bétonnage L Px de rehausse",
            "it": "FF20 Mensola di getto L Elemento sovrapp.",
            "hu": "FF20 betonozókonzol L magasító elem",
            "ru": "FF20 Консоль для бетонирования L элемент надстройки",
            "sk": "FF20 Betónovacia konzola L Nadstavenia",
            "pl": "FF20 Element nadstawienia do górnej konsoli rusztowania L"
        },
        {
            "ID": "ZBKONREFOR",
            "de": "ReForma SP-Konsole",
            "en-gb": "ReForma SP bracket",
            "en-us": "ReForma SP bracket",
            "es": "Ménsula ReForma SP",
            "fr": "Console ReForma SP",
            "it": "Mensola ReForma SP",
            "hu": "ReForma SP konzol",
            "ru": "ReForma SP консоль",
            "sk": "ReForma SP konzola",
            "pl": "Konsola ReForma SP"
        },
        {
            "ID": "ZBKONREFOR__A1",
            "de": "ReForma SP-Konsole",
            "en-gb": "ReForma SP bracket",
            "en-us": "ReForma SP bracket",
            "es": "Ménsula ReForma SP",
            "fr": "Console ReForma SP",
            "it": "Mensola ReForma SP",
            "hu": "ReForma SP konzol",
            "ru": "ReForma SP консоль",
            "sk": "ReForma SP konzola",
            "pl": "Konsola ReForma SP"
        },
        {
            "ID": "ZBKONREFOR__A1_AW1",
            "de": "ReForma SP-Konsole Einhängung Querprofil",
            "en-gb": "ReForma SP bracket fixed in cross profile",
            "en-us": "ReForma SP bracket fixed in cross profile",
            "es": "Ménsula ReForma SP Enganche en perfil transv.",
            "fr": "Console ReForma SP accrochage dans profilé transv.",
            "it": "Mensola ReForma SP Aggancio prof. trasversale",
            "hu": "ReForma SP konzol befüggesztés a keresztprofilba",
            "ru": "Консоль ReForma SP навешивание поперечный профиль",
            "sk": "ReForma SP konzola zavesenie v priečnom profile",
            "pl": "Konsola ReForma SP mocowana w profilu poprzecznym"
        },
        {
            "ID": "ZBKONREFOR__A1_AW2",
            "de": "ReForma SP-Konsole Einhängung Rahmenprofil",
            "en-gb": "ReForma SP bracket fixed in frame profile",
            "en-us": "ReForma SP bracket fixed in frame profile",
            "es": "Ménsula ReForma SP Enganche en perfil marco",
            "fr": "Console ReForma SP accrochage dans profilé cadre",
            "it": "Mensola ReForma Aggancio prof. del telaio",
            "hu": "ReForma SP konzol befüggesztés a végprofilban",
            "ru": "ReForma SP консоль рамный профиль",
            "sk": "ReForma SP konzola zavesenie v rámovom profile",
            "pl": "Konsola ReForma SP mocowana w profilu ramowym"
        },
        {
            "ID": "ZBKONREFOR__A1_AW3",
            "de": "ReForma SP-Konsole Einhängung Querprofil Element liegend",
            "en-gb": "ReForma SP bracket fixed in cross profile element horiz.",
            "en-us": "ReForma SP bracket fixed in cross profile element horiz.",
            "es": "Ménsula ReForma SP Enganche en perfil transv. elemento horizontal",
            "fr": "Console ReForma SP accrochage dans profilé transv. px couchés",
            "it": "Mensola ReForma SP Aggancio prof. trasversale Elemento coricato",
            "hu": "ReForma SP konzol befüggesztés a keresztprofilba Elem vízszintes",
            "ru": "Консоль ReForma SP навешивание поперечный профиль элемент расположен горизонтально",
            "sk": "ReForma SP konzola zavesenie v priečnom profile, panel naležato",
            "pl": "Konsola ReForma SP mocowana w profilu poprzecznym – element poziomy"
        },
        {
            "ID": "ZBKONS60-S",
            "de": "Frami S - Konsole 60",
            "en-gb": "Frami S bracket 60",
            "en-us": "Frami S bracket 60",
            "es": "Frami S - Ménsula 60",
            "fr": "Console Frami S 60",
            "it": "Mensola Frami S 60",
            "hu": "Frami S - konzol 60",
            "ru": "Консоль Frami S 60",
            "sk": "Frami S-konzola 60",
            "pl": "Konsola Frami S 60"
        },
        {
            "ID": "ZBKONS60-S__A1",
            "de": "Frami S - Konsole 60",
            "en-gb": "Frami S bracket 60",
            "en-us": "Frami S bracket 60",
            "es": "Ménsula Frami S 60",
            "fr": "Console Frami S 60",
            "it": "Mensola Frami S 60",
            "hu": "Frami S konzol 60",
            "ru": "Консоль Frami S 60",
            "sk": "Frami S-konzola 60",
            "pl": "Konsola Frami S 60"
        },
        {
            "ID": "ZBKONS60-S__A1_AW1",
            "de": "Frami S - Konsole 60 Einhängung Rahmenprofil",
            "en-gb": "Frami S bracket 60 fixed in frame profile",
            "en-us": "Frami S bracket 60 fixed in frame profile",
            "es": "Ménsula Frami S 60 Enganche en perfil marco",
            "fr": "Console Frami S 60 accrochage dans profilé cadre",
            "it": "Mensola Frami S 60 Aggancio prof. del telaio",
            "hu": "Frami S konzol 60 befüggesztés a végprofilban",
            "ru": "Консоль Frami S 60 навешивание рамный профиль",
            "sk": "Frami S-konzola 60 zavesenie v rámovom profile",
            "pl": "Konsola Frami S 60 mocowana w profilu ramowym"
        },
        {
            "ID": "ZBKONS60-S__A1_AW2",
            "de": "Frami S - Konsole 60 Einhängung Querprofil",
            "en-gb": "Frami S bracket 60 fixed in cross profile",
            "en-us": "Frami S bracket 60 fixed in cross profile",
            "es": "Ménsula Frami S 60 Enganche en perfil transv.",
            "fr": "Console Frami S 60 accrochage dans profilé transv.",
            "it": "Mensola Frami S 60 Aggancio prof. trasversale",
            "hu": "Frami S konzol 60 befüggesztés a keresztprofilba",
            "ru": "Консоль Frami S 60 навешивание поперечный профиль",
            "sk": "Frami S-konzola 60 zavesenie v priečnom profile",
            "pl": "Konsola Frami S 60 mocowana w profilu poprzecznym"
        },
        {
            "ID": "ZBKONSOL60",
            "de": "Frami - Konsole 60",
            "en-gb": "Frami bracket 60",
            "en-us": "Frami bracket 60",
            "es": "Frami - Ménsula 60",
            "fr": "Console Frami 60",
            "it": "Mensola Frami 60",
            "hu": "Frami - konzol 60",
            "ru": "Консоль Frami 60",
            "sk": "Frami-konzola 60",
            "pl": "Konsola Frami 60"
        },
        {
            "ID": "ZBKONSOL60__A1",
            "de": "Frami-Konsole 60",
            "en-gb": "Frami bracket 60",
            "en-us": "Frami bracket 60",
            "es": "Ménsula Frami 60",
            "fr": "Console Frami 60",
            "it": "Mensola Frami 60",
            "hu": "Frami konzol 60",
            "ru": "Консоль Frami 60",
            "sk": "Frami-konzola 60",
            "pl": "Konsola Frami 60"
        },
        {
            "ID": "ZBKONSOL60__A1_AW1",
            "de": "Frami-Konsole 60 Einhängung Rahmenprofil",
            "en-gb": "Frami bracket 60 fixed in frame profile",
            "en-us": "Frami bracket 60 fixed in frame profile",
            "es": "Ménsula Frami 60 Enganche en perfil marco",
            "fr": "Console Frami 60 accrochage dans profilé cadre",
            "it": "Mensola Frami 60 Aggancio prof. del telaio",
            "hu": "Frami konzol 60 befüggesztés a végprofilban",
            "ru": "Консоль Frami 60 навешивание рамный профиль",
            "sk": "Frami-konzola 60 zavesenie v rámovom profile",
            "pl": "Konsola Frami 60 mocowana w profilu ramowym"
        },
        {
            "ID": "ZBKONSOL60__A1_AW2",
            "de": "Frami-Konsole 60 Einhängung Querprofil",
            "en-gb": "Frami bracket 60 fixed in cross profile",
            "en-us": "Frami bracket 60 fixed in cross profile",
            "es": "Ménsula Frami 60 Enganche en perfil transv.",
            "fr": "Console Frami 60 accrochage dans profilé transv.",
            "it": "Mensola Frami 60 Aggancio prof. trasversale",
            "hu": "Frami konzol 60 befüggesztés a keresztprofilba",
            "ru": "Консоль Frami 60 навешивание поперечный профиль",
            "sk": "Frami-konzola 60 zavesenie v priečnom profile",
            "pl": "Konsola Frami 60 mocowana w profilu poprzecznym"
        },
        {
            "ID": "ZBKONSOL60__A1_AW3",
            "de": "Frami-Konsole 60 Einhängung Querprofil Element liegend",
            "en-gb": "Frami bracket 60 fixed in cross profile element horiz.",
            "en-us": "Frami bracket 60 fixed in cross profile element horiz.",
            "es": "Ménsula Frami 60 Enganche en perfil transv. elemento horizontal",
            "fr": "Console Frami 60 accrochage dans profilé transv. px couchés",
            "it": "Mensola Frami 60 Aggancio prof. trasversale Elemento coricato",
            "hu": "Frami konzol 60 befüggesztés a keresztprofilba Elem vízszintes",
            "ru": "Консоль Frami 60 навешивание поперечный профиль элемент расположен горизонтально",
            "sk": "Frami-konzola 60 zavesenie v priečnom profile, panel naležato",
            "pl": "Konsola Frami 60 mocowana w profilu poprzecznym – element poziomy"
        },
        {
            "ID": "ZBKONSOL90",
            "de": "Framax - Konsole 90",
            "en-gb": "Framax bracket 90",
            "en-us": "Framax bracket 90",
            "es": "Framax - Consola 90",
            "fr": "Console Framax 90",
            "it": "Mensola Framax 90",
            "hu": "Framax - konzol 90",
            "ru": "Консоль Framax 90",
            "sk": "Framax-konzola 90",
            "pl": "Konsola Framax 90"
        },
        {
            "ID": "ZBKONSOL90__A1",
            "de": "Framax-Konsole 90",
            "en-gb": "Framax bracket 90",
            "en-us": "Framax bracket 90",
            "es": "Ménsula Framax 90",
            "fr": "Console Framax 90",
            "it": "Mensola Framax 90",
            "hu": "Framax konzol 90",
            "ru": "Консоль Framax 90",
            "sk": "Framax-konzola 90",
            "pl": "Konsola Framax 90"
        },
        {
            "ID": "ZBKONSOL90__A1_AW1",
            "de": "Framax-Konsole 90 Einhängung Querprofil",
            "en-gb": "Framax bracket 90 fixed in cross profile",
            "en-us": "Framax bracket 90 fixed in cross profile",
            "es": "Ménsula Framax 90 Enganche en perfil transv.",
            "fr": "Console Framax 90 accrochage dans profilé transv.",
            "it": "Mensola Framax 90 Aggancio prof. trasversale",
            "hu": "Framax konzol 90 befüggesztés a keresztprofilba",
            "ru": "Консоль Framax 90 навешивание поперечный профиль",
            "sk": "Framax-konzola 90 zavesenie v priečnom profile",
            "pl": "Konsola Framax 90 mocowana w profilu poprzecznym"
        },
        {
            "ID": "ZBKONSOL90__A1_AW2",
            "de": "Framax-Konsole 90 Einhängung Rahmenprofil",
            "en-gb": "Framax bracket 90 fixed in frame profile",
            "en-us": "Framax bracket 90 fixed in frame profile",
            "es": "Ménsula Framax 90 Enganche en perfil marco",
            "fr": "Console Framax 90 accrochage dans profilé cadre",
            "it": "Mensola Framax 90 Aggancio prof. del telaio",
            "hu": "Framax konzol 90 befüggesztés a végprofilban",
            "ru": "Консоль Framax 90 навешивание рамный профиль",
            "sk": "Framax-konzola 90 zavesenie v rámovom profile",
            "pl": "Konsola Framax 90 mocowana w profilu poprzecznym"
        },
        {
            "ID": "ZBKONSOL90__A1_AW3",
            "de": "Framax-Konsole 90 Einhängung Querprofil Element liegend",
            "en-gb": "Framax bracket 90 fixed in cross profile element horiz.",
            "en-us": "Framax bracket 90 fixed in cross profile element horiz",
            "es": "Ménsula Framax 90 Enganche en perfil transv. elemento horizontal",
            "fr": "Console Framax 90 accrochage dans profilé transv. px couchés",
            "it": "Mensola Framax 90 Aggancio prof. trasversale Elemento coricato",
            "hu": "Framax konzol 90 befüggesztés a keresztprofilba Elem vízszintes",
            "ru": "Консоль Framax 90 навешивание поперечный профиль элемент расположен горизонтально",
            "sk": "Framax-konzola 90 zavesenie v priečnom profile, panel naležato",
            "pl": "Konsola Framax 90 mocowana w profilu poprzecznym"
        },
        {
            "ID": "ZBRA-ABSTW",
            "de": "Abstützwinkel",
            "en-gb": "Supporting construction",
            "en-us": "Supporting construction",
            "es": "Soporte angular",
            "fr": "Fermette",
            "it": "Puntello triangolare",
            "hu": "Kitámasztó szögvas",
            "ru": "Угловая опора",
            "sk": "Oporný uholník",
            "pl": "Profil oporowy"
        },
        {
            "ID": "ZBRA-ABSTW__A1",
            "de": "Abstützwinkel",
            "en-gb": "Supporting construction",
            "en-us": "Supporting construction",
            "es": "Soporte angular",
            "fr": "Fermette",
            "it": "Puntello triangolare",
            "hu": "Kitámasztó szögvas",
            "ru": "Угловая опора",
            "sk": "Oporný uholník",
            "pl": "Profil oporowy"
        },
        {
            "ID": "ZBRA-ABSTW__A1_AW1",
            "de": "Abstützwinkel",
            "en-gb": "Supporting construction",
            "en-us": "Supporting construction",
            "es": "Soporte angular",
            "fr": "Fermette",
            "it": "Puntello triangolare",
            "hu": "Kitámasztó szögvas",
            "ru": "Угловая опора",
            "sk": "Oporný uholník",
            "pl": "Profil oporowy"
        },
        {
            "ID": "ZBRA-ABSTWI",
            "de": "Abstützwinkel",
            "en-gb": "Supporting construction",
            "en-us": "Supporting construction",
            "es": "Soporte angular",
            "fr": "Fermette",
            "it": "Puntello triangolare",
            "hu": "Kitámasztó szögvas",
            "ru": "Угловая опора",
            "sk": "Oporný uholník",
            "pl": "Profil oporowy"
        },
        {
            "ID": "ZBRA-ABSTWI__A1",
            "de": "Abstützwinkel",
            "en-gb": "Supporting construction",
            "en-us": "Supporting construction",
            "es": "Soporte angular",
            "fr": "Fermette",
            "it": "Puntello triangolare",
            "hu": "Kitámasztó szögvas",
            "ru": "Угловая опора",
            "sk": "Oporný uholník",
            "pl": "Profil oporowy"
        },
        {
            "ID": "ZBRA-ABSTWI__A1_AW1",
            "de": "Abstützwinkel",
            "en-gb": "Supporting construction",
            "en-us": "Supporting construction",
            "es": "Soporte angular",
            "fr": "Fermette",
            "it": "Puntello triangolare",
            "hu": "Kitámasztó szögvas",
            "ru": "Угловая опора",
            "sk": "Oporný uholník",
            "pl": "Profil oporowy"
        },
        {
            "ID": "ZBRA-ABSTWX",
            "de": "Abstützwinkel",
            "en-gb": "Supporting construction",
            "en-us": "Supporting construction",
            "es": "Soporte angular",
            "fr": "Fermette",
            "it": "Puntello triangolare",
            "hu": "Kitámasztó szögvas",
            "ru": "Угловая опора",
            "sk": "Oporný uholník",
            "pl": "Profil oporowy"
        },
        {
            "ID": "ZBRA-ABSTWX__A1",
            "de": "Abstützwinkel",
            "en-gb": "Supporting construction",
            "en-us": "Supporting construction",
            "es": "Soporte angular",
            "fr": "Fermette",
            "it": "Puntello triangolare",
            "hu": "Kitámasztó szögvas",
            "ru": "Угловая опора",
            "sk": "Oporný uholník",
            "pl": "Profil oporowy"
        },
        {
            "ID": "ZBRA-ABSTWX__A1_AW1",
            "de": "Abstützwinkel",
            "en-gb": "Supporting construction",
            "en-us": "Supporting construction",
            "es": "Soporte angular",
            "fr": "Fermette",
            "it": "Puntello triangolare",
            "hu": "Kitámasztó szögvas",
            "ru": "Угловая опора",
            "sk": "Oporný uholník",
            "pl": "Profil oporowy"
        },
        {
            "ID": "ZBSTANDKON",
            "de": "Frami-Standkonsole",
            "en-gb": "Frami adjustment frame",
            "en-us": "Frami adjust. frame",
            "es": "Frami - Ménsula de pie",
            "fr": "Console de sécurité Frami",
            "it": "Mensola di sicurezza Frami",
            "hu": "Frami állókonzol",
            "ru": "Стоечная консоль Frami",
            "sk": "Frami-stoj. konz.",
            "pl": "Konsola stojąca Frami"
        },
        {
            "ID": "ZBSTANDKON__A1",
            "de": "Frami-Standkonsole",
            "en-gb": "Frami adjustment frame",
            "en-us": "Frami adjustment bracket",
            "es": "Ménsula de pie Frami",
            "fr": "Console de sécurité Frami",
            "it": "Mensola di sicurezza Frami",
            "hu": "Frami állókonzol",
            "ru": "Стоечная консоль Frami",
            "sk": "Frami-stoj. konz.",
            "pl": "Konsola stojąca Frami"
        },
        {
            "ID": "ZBSTANDKON__A1_AW1",
            "de": "Frami-Standkonsole",
            "en-gb": "Frami adjustment frame",
            "en-us": "Frami adjustment bracket",
            "es": "Ménsula de pie Frami",
            "fr": "Console de sécurité Frami",
            "it": "Mensola di sicurezza Frami",
            "hu": "Frami állókonzol",
            "ru": "Стоечная консоль Frami",
            "sk": "Frami-stoj. konz.",
            "pl": "Konsola stojąca Frami"
        },
        {
            "ID": "XFR30_A1",
            "de": "Elementtyp",
            "es": "Tipo de elemento",
            "fr": "Type d'elément",
            "hu": "Elemtípus",
            "it": "Tipo elemento",
            "pl": "Typ płyty",
            "ru": "Тип элемента",
            "sk": "Typ panelu",
            "en-gb": "Panel type",
            "en-us": "Panel type"
        },
        {
            "ID": "XFR30_A1_AW1",
            "de": "Normalelement (1.35,0.90,0.60,0.45,0.30 breit)",
            "es": "Elemento normal (1.35,0.90,0.60,0.45,0.30 ancho)",
            "fr": "Élément étalon (1.35,0.90,0.60,0.45,0.30 large)",
            "hu": "Normál tábla (1.35,0.90,0.60,0.45,0.30 szélesség)",
            "it": "Elemento normale (1.35,0.90,0.60,0.45,0.30 largo)",
            "pl": "Płyta standardowa (szerokość 1.35,0.90,0.60,0.45,0.30)",
            "ru": "Унифицированный элемент (ширина 1.35,0.90,0.60,0.45,0.30)",
            "sk": "Štandardný panel (1.35,0.90,0.60,0.45,0.30 šírky)",
            "en-gb": "Standard panel (1.35,0.90,0.60,0.45,0.30 wide)",
            "en-us": "Standard panel (1.35,0.90,0.60,0.45,0.30 wide)"
        },
        {
            "ID": "XFR30_A1_AW2",
            "de": "Uni-Element",
            "es": "Elem. univ.",
            "fr": "Panneau universel",
            "hu": "Uni elem",
            "it": "Elemento uni",
            "pl": "Element uniwersalny",
            "ru": "Универсальный элемент",
            "sk": "Univerzálny panel",
            "en-gb": "Universal panel",
            "en-us": "Universal panel"
        },
        {
            "ID": "XFR30_A2",
            "de": "Stirnabschalung",
            "es": "Tape",
            "fr": "Coffrage d'about",
            "hu": "Véglezárás",
            "it": "Chiusura di testa",
            "pl": "Szalunek czołowy",
            "ru": "Торцевые участки опалубки",
            "sk": "Debnenie cela",
            "en-gb": "Stop-end-formwork",
            "en-us": "Bulkhead"
        },
        {
            "ID": "XFR30_A2_AW1",
            "de": "Stirnanker ohne Element",
            "es": "Anclaje de tape sin elem.",
            "fr": "Ancrage d'about sans px",
            "hu": "Univerzális kötocsavar elem nélkül",
            "it": "Morsetto per testata senza elemento",
            "pl": "Deskowanie czołowe bez płyty",
            "ru": "Торцевой анкер без элемента",
            "sk": "Celová kotva bez panel",
            "en-gb": "Stop-end tie without panel",
            "en-us": "Stop-end tie without panel"
        },
        {
            "ID": "XFR30_A2_AW2",
            "de": "Stirnanker mit Element",
            "es": "Anclaje de tape con elem.",
            "fr": "Ancrage d'about avec px",
            "hu": "Univerzális kötocsavar elem",
            "it": "Morsetto per testata con elemento",
            "pl": "Deskowanie czołowe z płytą",
            "ru": "Торцевой анкер с элементом",
            "sk": "Celová kotva so panel",
            "en-gb": "Stop-end tie with panel",
            "en-us": "Stop-end tie with panel"
        },
        {
            "ID": "XFR30_A2_AW3",
            "de": "Universalverbinder mit Element",
            "es": "Conector univ. con elem.",
            "fr": "Boulon d'assemblage univ. avec Px",
            "hu": "Univerzális kötőcsavar elemmel",
            "it": "Tirante universale con elemento",
            "pl": "Łącznik uniwersalny z płytą",
            "ru": "Универсальный соединитель с элемента",
            "sk": "Univerzálna spojka s panelom ",
            "en-gb": "Universal fixing bolt with panel",
            "en-us": "Universal fixing bolt with panel"
        },
        {
            "ID": "XFR30_A2_AW4",
            "de": "Universalelement",
            "es": "Elem. univ.",
            "fr": "Px univ.",
            "hu": "Uni elem",
            "it": "Elemento uni",
            "pl": "Element uniwersalny",
            "ru": "Универсальный элемент",
            "sk": "Univerzálny panel",
            "en-gb": "Universal panel",
            "en-us": "Universal panel"
        },
        {
            "ID": "XFR30_A2_AW5",
            "de": "Stirnabschalzwinge",
            "es": "Sargento para tape",
            "fr": "Rail d'about",
            "hu": "Véglezáró sín",
            "it": "Corrente di testata",
            "pl": "Zacisk deskowania czołowego",
            "ru": "Торцевая накладка",
            "sk": "Zvierka debnenia čiel",
            "en-gb": "Stop-end waler tie",
            "en-us": "Stop-end waler tie"
        },
        {
            "ID": "XFR30_A2_AW6",
            "de": "Universalverbinder ohne Element",
            "es": "Conector univ. con elem.",
            "fr": "Boulon d'assemblage univ. sans Px",
            "hu": "Univerzális kötocsavar elem nélkül",
            "it": "Tirante universale senza elemento",
            "pl": "Łącznik uniwersalny bez płyty",
            "ru": "Универсальный соединитель без элемента",
            "sk": "Univerzálna spojka bez panel",
            "en-gb": "Universal fixing bolt w/o panel",
            "en-us": "Universal fixing bolt w/o panel"
        },
        {
            "ID": "XFR30_A3",
            "de": "Ausgleiche",
            "es": "Compensaciónes",
            "fr": "Compensations",
            "hu": "Kiegyenlítés",
            "it": "Compensazioni",
            "pl": "Wypełnienia",
            "ru": "Компенсационные элементы",
            "sk": "Ručné vyrovnania",
            "en-gb": "Closures",
            "en-us": "Fillers"
        },
        {
            "ID": "XFR30_A3_AW1",
            "de": "Füllkörper mit Uni-Spanner, Ankerung im Element",
            "es": "Cuerpo de relleno con grapa unión univ., anclaje en el elemento",
            "fr": "Corps de remplissage avec tendeur rapide universelle, ancrage dans le panneau",
            "hu": "Építéshelyi kiegészítő betét univerzális kapcsolóval, ankerozás az elemben",
            "it": "Compensazione con morsetto universale, anc. nell'elemento",
            "pl": "Wstawka z zamkiem uniwersalnym, ściągi przez wstawkę",
            "ru": "Заполнитель с универсал. зажимным приспособлением, анкерование в элементе",
            "sk": "Vyrovnanie s univerzálnym upínačom, kotvenie v paneli",
            "en-gb": "Filler block w/ multifunction clamp, ties through panel",
            "en-us": "Filler block w/ multifunction clamp, ties through panel"
        },
        {
            "ID": "XFR30_A3_AW10",
            "de": "Füllkörper mit Universalverbinder 10-25cm",
            "es": "Cuerpo de relleno con conector univ. 10-25cm",
            "fr": "Corps de remplissage avec boulon d'assemblage univ. 10-25cm",
            "hu": "Kidobozolás univerzális kötőcsavarral 10-25cm",
            "it": "Compensazione con tirante universale 10-25cm",
            "pl": "Wstawka z łącznikiem uniwersalnym 10–25cm",
            "ru": "Закладной элемент с унив. соединителем 10-25см",
            "sk": "Vyrovnanie s univerzálnou spojkou 10-25cm",
            "en-gb": "Filler block w/ universal fixing bolt 10-25cm",
            "en-us": "Filler block w/ universal fixing bolt 10-25cm"
        },
        {
            "ID": "XFR30_A3_AW11",
            "de": "mit Schalungsplatte 21mm und Klemmschiene 0,90m",
            "es": "con tablero de encofrado 21mm y riel de fijación 0,90m",
            "fr": "avec contreplaqué 21mm et rails de blocage 0,90m",
            "hu": "21mm-es zsaluhéjjal és 0,90m-es kötősínnel ",
            "it": "con pannello 21mm e rotaia di fissaggio 0,90m",
            "pl": "ze sklejką 21mm i uniwersalnym ryglem ściennym 0,90m",
            "ru": "с опалубочная плита 21мм и зажимная шина 0,90м",
            "sk": "s debniacou doskou 21mm a upínacou koľajnicou 0,90m",
            "en-gb": "with formwork sheet 21mm and universal waling 0.90m",
            "en-us": "with formwork sheet 21mm and universal waling 0.90m"
        },
        {
            "ID": "XFR30_A3_AW12",
            "de": "mit Schalungsplatte 21mm und Klemmschiene 1,50m",
            "es": "con tablero de encofrado 21mm y riel de fijación 1,50m",
            "fr": "avec contreplaqué 21mm et rails de blocage 1,50m",
            "hu": "21mm-es zsaluhéjjal és 1,50m-es kötősínnel",
            "it": "con pannello 21mm e rotaia di fissaggio 1,50m",
            "pl": "ze sklejką 21mm i uniwersalnym ryglem ściennym 1,50m",
            "ru": "с опалубочная плита 21мм и зажимная шина 1,50м",
            "sk": "s debniacou doskou 21mm a upínacou koľajnicou 1,50m",
            "en-gb": "with formwork sheet 21mm and universal waling 1.50m",
            "en-us": "with formwork sheet 21mm and universal waling 1.50m"
        },
        {
            "ID": "XFR30_A3_AW2",
            "de": "Füllkörper mit Ausgleichsspanner",
            "es": "Cuerpo de relleno con grapa de compensación",
            "fr": "Corps de remplissage avec tendeur de compensation",
            "hu": "Építéshelyi fabetét kiegyenlítő kapcsolóval",
            "it": "Compensazione con morsetto di compensazione",
            "pl": "Wstawka z zamkiem uniwersalnym",
            "ru": "Закладной элем. с пригон. зажимн. приспособл.",
            "sk": "Vyrovnanie s vyrovnávacím upínačom",
            "en-gb": "Filler block w/ adjustable clamp",
            "en-us": "Filler block w/ adjustable clamp"
        },
        {
            "ID": "XFR30_A3_AW3",
            "de": "Passholz mit Uni-Spanner, Ankerung im Element",
            "es": "Tablón de ajuste con grapa unión univ., anclaje en el elemento",
            "fr": "Fourrure en bois avec tendeur rapide universelle, ancrage dans le panneau",
            "hu": "Illesztő fabetét univerzális kapcsolóval, ankerozás az elemben",
            "it": "Tavola di compensazione con morsetto universale, anc. nell'elemento",
            "pl": "Wstawka komp. z zamkiem uniwersalnym, ściagi przez płytę",
            "ru": "Пригнанный брус с универсал. зажимным приспособлением, анкерование в элементе",
            "sk": "Vyrovnávací hranol s univerzálnym upínačom, kotvenie v paneli",
            "en-gb": "Fitting timber with multifunction clamp, ties through panel",
            "en-us": "Fitting timber with multifunction clamp ties through panel"
        },
        {
            "ID": "XFR30_A3_AW4",
            "de": "Passholz mit Ausgleichsspanner",
            "es": "Tablón de ajuste con grapa de comp",
            "fr": "Fourrure en bois avec tendeur de compensation",
            "hu": "Illesztő fabetét kiegyenlítő kapcsolóval",
            "it": "Tavola di compensazione con morsetto di compensazione",
            "pl": "Wstawka komp. z zaciskiem regulowanym",
            "ru": "Пригнанный брус с пригоняемым зажимным приспособлением",
            "sk": "Vyrovnávací hranol s vyrovnávacím upínačom",
            "en-gb": "Fitting timber with adjustable clamp",
            "en-us": "Fitting timber with adjustable clamp"
        },
        {
            "ID": "XFR30_A3_AW5",
            "de": "mit Schalungsplatte 27mm und Klemmschiene 0,90m",
            "es": "con tablero de encofrado 27mm y riel de fijación 0,90m",
            "fr": "avec contreplaqué 27mm et rail de blocage 0,90m",
            "hu": "27mm-es zsaluhéjjal és 0,90m-es kötősínnel ",
            "it": "con pannello 27mm e rotaia di fissaggio 0,90m",
            "pl": "ze sklejką 27mm i uniwersalnym ryglem ściennym 0,90m",
            "ru": "с опалубочная плита 27мм и зажимная шина 0,90м",
            "sk": "s debniacou doskou 27mm a upínacou koľajnicou 0,90m",
            "en-gb": "with formwork sheet 27mm and universal waling 0.90m",
            "en-us": "with formwork sheet 27mm and universal waling 0.90m"
        },
        {
            "ID": "XFR30_A3_AW6",
            "de": "mit Schalungsplatte 27mm und Klemmschiene 1,50m",
            "es": "con tablero de encofrado 27mm y riel de fijación 1,50m",
            "fr": "avec contreplaqué 27mm et rail de blocage 1,50m",
            "hu": "27mm-es zsaluhéjjal és 1,50m-es kötősínnel ",
            "it": "con pannello 27mm e rotaia di fissaggio 1,50m",
            "pl": "ze sklejką 27mm i uniwersalnym ryglem ściennym 1,50m",
            "ru": "с опалубочная плита 27мм и зажимная шина 1,50м",
            "sk": "s debniacou doskou 27mm a upínacou koľajnicou 1,50m",
            "en-gb": "with formwork sheet 27mm and universal waling 1.50m",
            "en-us": "with formwork sheet 27mm and universal waling 1.50m"
        },
        {
            "ID": "XFR30_A3_AW7",
            "de": "Füllkörper mit Uni-Spanner, Ankerung im Ausgleich",
            "es": "Cuerpo de relleno con grapa unión univ., anclaje en compensación",
            "fr": "Corps de remplissage avec tendeur rapide univ., ancrage dans le compens.",
            "hu": "Építéshelyi kiegészítő betét univerzális kapcsolóval, ankerozás a kiegyenlítésen keresztül",
            "it": "Compensazione con morsetto universale, anc. nella compens.",
            "pl": "Wstawka z zamkiem uniwersalnym, ściągi przez wstawkę",
            "ru": "Заполнитель с универсал. зажимным приспособлением, анкерование в компенсатор",
            "sk": "Vyrovnanie s univerzálnym upínačom, kotvenie vo vyrovnaní",
            "en-gb": "Filler block w/ multifunction clamp, ties through closure",
            "en-us": "Filler block w/ multifunction clamp, ties through filler"
        },
        {
            "ID": "XFR30_A3_AW8",
            "de": "Passholz mit Uni-Spanner, Ankerung im Ausgleich",
            "es": "Tablón de ajuste con grapa unión univ., anclaje en compensación",
            "fr": "Fourrure en bois avec tendeur rapide univ., ancrage dans le compens.",
            "hu": "Illesztő fabetét univerzális kapcsolóval, ankerozás a kiegyenlítésen keresztül",
            "it": "Tavola di compensazione con morsetto universale, anc. nella compens.",
            "pl": "Wstawka komp. z zamkiem uniwersalnym, ściagi przez wstawkę ",
            "ru": "Пригнанный брус с универсал. зажимным приспособлением, анкерование в компенсатор",
            "sk": "Vyrovnávací hranol s univerzálnym upínačom, kotvenie vo vyrovnaní",
            "en-gb": "Fitting timber w/ multifunction clamp, ties through closure",
            "en-us": "Fitting timber w/ multifunction clamp, ties through filler"
        },
        {
            "ID": "XFR30_A3_AW9",
            "de": "Füllkörper mit Universalverbinder 10-16cm",
            "es": "Cuerpo de relleno con conector univ. 10-16cm",
            "fr": "Corps de remplissage avec boulon d'assemblage univ. 10-16cm",
            "hu": "Kidobozolás univerzális kötőcsavarral 10-16cm",
            "it": "Compensazione con tirante universale 10-16cm",
            "pl": "Wstawka z łącznikiem uniwersalnym 10–16cm",
            "ru": "Закладной элемент с унив. соединителем 10-16см",
            "sk": "Vyrovnanie s univerzálnou spojkou 10-16cm",
            "en-gb": "Filler block w/ universal fixing bolt 10-16cm",
            "en-us": "Filler block w/ universal fixing bolt 10-16cm"
        },
        {
            "ID": "XFR30_A4",
            "de": "Ecklösung",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "hu": "Sarokmegoldás",
            "it": "Soluzione angolare",
            "pl": "Wariant narożnika",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "en-gb": "Corner solution",
            "en-us": "Corner solution"
        },
        {
            "ID": "XFR30_A4_AW1",
            "de": "Innenecke und Universal-Element",
            "es": "Esqu. int. y elem. univ.",
            "fr": "Angle int et px univ.",
            "hu": "Csukl.bel.sarok és uni elem",
            "it": "Angolo interno e elemento uni",
            "pl": "Narożnik wewnętrzny i płyta uniwersalna",
            "ru": "Внутренний угол и унив.элементом",
            "sk": "Vnútorný roh + univerzálny panel",
            "en-gb": "Inside corner and universal panel",
            "en-us": "w/ formwork sheet and … 0.90m"
        },
        {
            "ID": "XFR30_A4_AW2",
            "de": "Innenecke und Außenecke",
            "es": "Esqu. int. y esqu. ext.",
            "fr": "Angle int. et angle ext",
            "hu": "Belso sarok és külso sarok",
            "it": "Angolo interno e angolo esterno",
            "pl": "Narożnik wewnętrzny i narożnik zewnętrzny",
            "ru": "Внутренний угол и внешний угол",
            "sk": "Vnútorný roh + vonkajší roh",
            "en-gb": "Inside corner and outside corner",
            "en-us": "Inside corner and outside corner"
        },
        {
            "ID": "XFR30_A5",
            "de": "Wandanschlüsse",
            "es": "Acometidas de muros",
            "fr": "Connexions de mur",
            "hu": "Falcsatlakozás",
            "it": "Agganci per parete",
            "pl": "Połączenia ścienne",
            "ru": "Стыки стен",
            "sk": "Napájanie stien",
            "en-gb": "Wall connections",
            "en-us": "Wall connections"
        },
        {
            "ID": "XFR30_A5_AW1",
            "de": "Normalelement",
            "es": "Elemento normal",
            "fr": "Élément étalon",
            "hu": "Normál tábla",
            "it": "Elemento normale",
            "pl": "Płyta standardowa",
            "ru": "Унифицированный элемент",
            "sk": "Štandardný panel",
            "en-gb": "Standard panel",
            "en-us": "Standard panel"
        },
        {
            "ID": "XFR30_A5_AW2",
            "de": "Uni-Element",
            "es": "Elem. univ.",
            "fr": "Px univ.",
            "hu": "Uni elem",
            "it": "Elemento uni",
            "pl": "Element uniwersalny",
            "ru": "Универсальный элемент",
            "sk": "Univerzálny panel",
            "en-gb": "Universal panel",
            "en-us": "Universal panel"
        },
        {
            "ID": "XFR30_A5_AW3",
            "de": "Kanthölzer-Klemmschiene",
            "es": "Listónes-Riel de fijación",
            "fr": "Bois-Rails de blocage",
            "hu": "Fabetét-Kötosín",
            "it": "Travi squadrate-Rotaia di fissaggio",
            "pl": "Kantówki – uniwersalny rygiel ścienny",
            "ru": "Бруски-Зажимная шина",
            "sk": "Hranoly a upínac.-Upín.koľajnica",
            "en-gb": "Squared timbers-Universal waling",
            "en-us": "Squared timbers-Universal waling"
        },
        {
            "ID": "XFR30_A5_AW4",
            "de": "Elemente vom Vorgängertakt verwenden",
            "es": "Utilizar los elementos del ciclo anterior",
            "fr": "Utiliser les éléments du coffrage précédent",
            "hu": "Az előző ciklus elemeinek alkalmazása",
            "it": "Utilizza elementi del ciclo precedente",
            "pl": "Użyj płyt z poprzedniego taktu",
            "ru": "Использовать элементы предыдущего такта",
            "sk": "Použiť prvky z predchádzajúceho taktu",
            "en-gb": "Use panels of previous cycle",
            "en-us": "Use panels of previous pour"
        },
        {
            "ID": "XFR30_A7",
            "de": "Stehende oder liegende Aufstockung der Elemente",
            "es": "Apilado horizontal o vertical de los elementos",
            "fr": "Rehausse de px debout ou couchés",
            "hu": "állított ill. fektetett elemmagasítás",
            "it": "Sovrapposizione orizzontale o verticale degli elementi",
            "pl": "Pionowe lub poziome nadstawianie płyt",
            "ru": "Вертикальная или горизонтальная надстройка элементов",
            "sk": "Nadstavenie panelov nastojato alebo naležato",
            "en-gb": "Upright or horiz. stacking of panels",
            "en-us": "Upright or horiz. stacking of panels"
        },
        {
            "ID": "XFR30_A7_AW1",
            "de": "ohne Aufstockung",
            "es": "Sin apilado",
            "fr": "sans rehausse",
            "hu": "Magasítás nélkül",
            "it": "senza sopralzo",
            "pl": "bez nadstawienia",
            "ru": "Без надстройки",
            "sk": "bez nadstavenia",
            "en-gb": "w/o stacking",
            "en-us": "w/o stacking"
        },
        {
            "ID": "XFR30_A7_AW2",
            "de": "Stehend aufgestockt",
            "es": "Apilado vertical",
            "fr": "Rehaussé deboutant",
            "hu": "Állítva magasítva",
            "it": "Sovrapposizione vert.",
            "pl": "nadstawienie pionowe",
            "ru": "надстройка в вертикальном положении",
            "sk": "Nadstavenie nastojato",
            "en-gb": "stacked upright",
            "en-us": "stacked upright"
        },
        {
            "ID": "XFR30_A7_AW3",
            "de": "Liegende Aufgestockt",
            "es": "Apilado horizontal",
            "fr": "Rehaussé couchant",
            "hu": "Fektetve magasítva",
            "it": "Sovrapposizione orizz.",
            "pl": "nadstawienie poziome",
            "ru": "надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz."
        },
        {
            "ID": "XFR30_A8",
            "de": "Stütze",
            "es": "Puntal",
            "fr": "Étançon",
            "hu": "Támasz",
            "it": "Puntello",
            "pl": "Słup",
            "ru": "Колонна",
            "sk": "Podpera",
            "en-gb": "Column",
            "en-us": "Column"
        },
        {
            "ID": "XFR30_A8_AW1",
            "de": "Uni-Element 0,90",
            "es": "Elem. univ. 0,90",
            "fr": "Px univ. 0,90",
            "hu": "Uni elem 0,90",
            "it": "Elemento uni 0,90",
            "pl": "Element uniwersalny 0,90",
            "ru": "Универсальный элемент 0,90",
            "sk": "Univerzálny panel 0,90",
            "en-gb": "Universal panel 0.90",
            "en-us": "Universal panel"
        },
        {
            "ID": "XFR30_A8_AW2",
            "de": "Elemente mit Außenecken",
            "es": "Elem. con esqu. ext.",
            "fr": "Px avec angle ext",
            "hu": "Elemek külső sarkokkal",
            "it": "Elemente con angoli esterni",
            "pl": "Płyty z narożnikami zewnętrznymi",
            "ru": "Элементы с внешними углами",
            "sk": "Panely s vonkaj. roh",
            "en-gb": "Panels with outside corners",
            "en-us": "Panels with outside corners"
        },
        {
            "ID": "XFR30_A9",
            "de": "Ankerung",
            "es": "Anclaje",
            "fr": "Ancrage",
            "hu": "Ankerozás",
            "it": "Ancoraggio",
            "pl": "Kotwienie",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "en-gb": "Anchoring",
            "en-us": "Anchoring"
        },
        {
            "ID": "XFR30_A9_AW1",
            "de": "Ankerstab 15,0 unbehandelt",
            "es": "Barra de ancl. 15,0 no tratada",
            "fr": "Tige d'ancrage 15,0 non traitée",
            "hu": "Ankerrúd 15,0 kezeletlen",
            "it": "Barra ancorante 15,0 non trattata",
            "pl": "Ściąg 15,0 nieocynkowany",
            "ru": "Анкерный стержень 15,0 необработанный",
            "sk": "Kotev. tyc 15,0 neupravov.",
            "en-gb": "Tie rod 15.0 non-treated",
            "en-us": "Tie rod 15.0 non-treated"
        },
        {
            "ID": "XFR30_A9_AW2",
            "de": "Ankerstab 15,0 verzinkt",
            "es": "Barra de ancl. 15,0 galv.",
            "fr": "Tige d'ancrage 15,0 galvanisée",
            "hu": "Ankerrúd 15,0 horganyzott",
            "it": "Barra ancorante 15,0 zincata",
            "pl": "Ściąg 15,0 ocynkowany",
            "ru": "Анкерный стержень 15,0 оцинкованный",
            "sk": "Kotev. tyc 15,0 pozinkov.",
            "en-gb": "Tie rod 15.0 galvanised",
            "en-us": "Tie rod 15.0 galvanized"
        },
        {
            "ID": "XFR30_A9_AW3",
            "de": "Ankerstab 20,0 unbehandelt",
            "es": "Barra de ancl. 20,0 no tratada",
            "fr": "Tige d'ancrage 20,0 non traitée",
            "hu": "Ankerrúd 20,0 kezeletlen",
            "it": "Barra ancorante 20,0 non trattata",
            "pl": "Ściąg 20,0 nieocynkowany",
            "ru": "Анкерный стержень 20,0 необработанный",
            "sk": "Kotev. tyč 20,0 neupravovaná",
            "en-gb": "Tie rod 20.0 non-treated",
            "en-us": "Tie rod 20.0 non-treated"
        },
        {
            "ID": "XFR30_A9_AW4",
            "de": "Ankerstab 20,0 verzinkt",
            "es": "Barra de ancl. 20,0 galv.",
            "fr": "Tige d'ancrage 20,0 galvanisée",
            "hu": "Ankerrúd 20,0 horganyzott",
            "it": "Barra ancorante 20,0 zincata",
            "pl": "Ściąg 20,0 ocynkowany",
            "ru": "Анкерный стержень 20,0 оцинкованный",
            "sk": "Kotev. tyc 20,0 pozinkov.",
            "en-gb": "Tie rod 20.0 galvanised",
            "en-us": "Tie rod 20.0 galvanized"
        },
        {
            "ID": "XREFORMX_A1",
            "de": "Grundelementhöhe",
            "es": "Altura del elemento base",
            "fr": "Hauteur de base",
            "hu": "Alapelem magasság",
            "it": "Altezza dell’ elemento base",
            "pl": "Wysokość płyty podstawowej",
            "ru": "Высота основного элемента",
            "sk": "Výška základného panelu",
            "en-gb": "Basic panel height",
            "en-us": "Basic panel height"
        },
        {
            "ID": "XREFORMX_A1_AW1",
            "de": "1,50m",
            "es": "1,50m",
            "fr": "1,50m",
            "hu": "1,50m",
            "it": "1,50m",
            "pl": "1,50m",
            "ru": "1,50м",
            "sk": "1,50m",
            "en-gb": "1.50m",
            "en-us": "1.50m"
        },
        {
            "ID": "XREFORMX_A1_AW2",
            "de": "3,00m",
            "es": "3,00m",
            "fr": "3,00m",
            "hu": "3,00m",
            "it": "3,00m",
            "pl": "3,00m",
            "ru": "3,00м",
            "sk": "3,00m",
            "en-gb": "3.00m",
            "en-us": "3.00m"
        },
        {
            "ID": "XREFORMX_A1_AW3",
            "de": "3,30m",
            "es": "3,30m",
            "fr": "3,30m",
            "hu": "3,30m",
            "it": "3,30m",
            "pl": "3,30m",
            "ru": "3,30м",
            "sk": "3,30m",
            "en-gb": "3.30m",
            "en-us": "3.30m"
        },
        {
            "ID": "XREFORMX_A10",
            "de": "Anschlüsse",
            "es": "Conexónes",
            "fr": "Connexions",
            "hu": "Csatlakozó",
            "it": "Collegamenta",
            "pl": "Połączenia",
            "ru": "Стыки",
            "sk": "Napojenia",
            "en-gb": "Connections",
            "en-us": "Connections"
        },
        {
            "ID": "XREFORMX_A10_AW1",
            "de": "Uni-Element 0,90",
            "es": "Elem. univ. 0,90",
            "fr": "Panneau universel 0,90",
            "hu": "Uni elem 0,90",
            "it": "Elemento uni 0,90",
            "pl": "Element uniwersalny 0,90",
            "ru": "Универсальный элемент 0,90",
            "sk": "Uni. panel 0,90",
            "en-gb": "Universal panel 0.90",
            "en-us": "Universal panel 0.90"
        },
        {
            "ID": "XREFORMX_A10_AW2",
            "de": "Uni-Element 1,20",
            "es": "Elem. univ. 1,20",
            "fr": "Panneau universel 1,20",
            "hu": "Uni elem 1,20",
            "it": "Elemento uni 1,20",
            "pl": "Element uniwersalny 1,20m",
            "ru": "Универсальный элемент 1,20",
            "sk": "Uni. panel 1,20",
            "en-gb": "Universal panel 1.20",
            "en-us": "Universal panel 1.20"
        },
        {
            "ID": "XREFORMX_A10_AW3",
            "de": "Füllkörper",
            "es": "Cuerpo de relleno",
            "fr": "Corps de remplissage",
            "hu": "Kiegészítő betét",
            "it": "Compensazione",
            "pl": "Wstawka",
            "ru": "Вкладыш",
            "sk": "Výplň",
            "en-gb": "Filler block",
            "en-us": "Filler block"
        },
        {
            "ID": "XREFORMX_A11",
            "de": "Fundamentlösungen",
            "es": "Solución para fundaciones",
            "fr": "Solution de fondation",
            "hu": "Alapozás megoldása",
            "it": "Soluzione per fondamenta",
            "pl": "Rozwiązania dla fundamentów",
            "ru": "Решение для фундамента",
            "sk": "Riešenie základov",
            "en-gb": "Foundation solutions",
            "en-us": "Foundation solutions"
        },
        {
            "ID": "XREFORMX_A11_AW1",
            "de": "Fundamentspanner",
            "es": "Grapa de cimentación",
            "fr": "Serrage pour fondation",
            "hu": "Anker szalag feszítő",
            "it": "Morsetto per fondazioni",
            "pl": "Napinacz",
            "ru": "Фундаментный зажим",
            "sk": "Fundamentový upínač",
            "en-gb": "Foundation clamp",
            "en-us": "Foundation clamp"
        },
        {
            "ID": "XREFORMX_A11_AW2",
            "de": "Bodenhalter",
            "es": "Placa de fijación a suelo ",
            "fr": "Support d'ancrage",
            "hu": "Aljzatrögzítő",
            "it": "Piastra di fissaggio",
            "pl": "Płytka mocująca do podłoża",
            "ru": "Держатель для пола",
            "sk": "Držiak spodku debnenia",
            "en-gb": "Floor fixing plate",
            "en-us": "Bracing clip"
        },
        {
            "ID": "XREFORMX_A11_AW3",
            "de": "ohne",
            "es": "sin",
            "fr": "sans",
            "hu": "nem",
            "it": "senza",
            "pl": "bez",
            "ru": "без",
            "sk": "bez",
            "en-gb": "without",
            "en-us": "without"
        },
        {
            "ID": "XREFORMX_A12",
            "de": "Einseitige Ecke",
            "es": "Esquina de un solo lado",
            "fr": "Une face angle",
            "hu": "Egyoldali sarok",
            "it": "Angolo unilaterale",
            "pl": "Narożnik jednostronny",
            "ru": "Односторонний угол",
            "sk": "Jednostranný roh",
            "en-gb": "One-sided corner",
            "en-us": "One-sided corner"
        },
        {
            "ID": "XREFORMX_A12_AW1",
            "de": "Außen- oder Innenecke",
            "es": "Esqu. ext. o esqu. int.",
            "fr": "Angle ext. et int.",
            "hu": "Külso vagy Belso sarok",
            "it": "Angolo est. o int.",
            "pl": "Narożnik zewnętrzny lub wewnętrzny",
            "ru": "Внешний или внутренний угол",
            "sk": "Vonk. roh al. vnút. roh",
            "en-gb": "Outside or inside corner",
            "en-us": "Outside or inside corner"
        },
        {
            "ID": "XREFORMX_A12_AW2",
            "de": "Ausschalecke I",
            "es": "Esquina de desencofrado int. I",
            "fr": "Angle de décoffrage I",
            "hu": "Kizsaluzó sarok I",
            "it": "Angolo di disarmo interno I",
            "pl": "Narożnik wew. rozszalowujący I",
            "ru": "Pаспалубочный угол I ",
            "sk": "Oddebňovací roh I ",
            "en-gb": "Stripping corner I",
            "en-us": "Bias cut corner I"
        },
        {
            "ID": "XREFORMX_A12_AW3",
            "de": "Scharnier-Innenecke oder Scharnier-Außenecke",
            "es": "Esqu. bisagra int./esqu. bisagra ext.",
            "fr": "Angle charnière int ou angle charnière ext",
            "hu": "Csukl.belso sarok / csukl.külso sarok",
            "it": "Angolo cerniera int./angolo cerniera est.",
            "pl": "Narożnik wewnętrzny zawiasowy lub narożnik zewnętrzny zawiasowy",
            "ru": "Шарнирный внутренний угол / шарнирный внешний угол",
            "sk": "",
            "en-gb": "Hinged inside corner or hinged outside corner",
            "en-us": "Hinged inside corner or hinged outside corner"
        },
        {
            "ID": "XREFORMX_A2",
            "de": "Ecklösung",
            "es": "Solución de esquina",
            "fr": "Solution d'angle",
            "hu": "Sarokmegoldás",
            "it": "Soluzione angolare",
            "pl": "Wariant narożnika",
            "ru": "Угловое решение",
            "sk": "Riešenie rohu",
            "en-gb": "Corner solution",
            "en-us": "Corner solution"
        },
        {
            "ID": "XREFORMX_A2_AW1",
            "de": "Uni-Element und Innenecke",
            "es": "Elemento universal y esquina interior",
            "fr": "Panneau universel et angle intérieur",
            "hu": "Uni elemmel és belső sarokkal",
            "it": "Elemento universale e angolo interno",
            "pl": "Płyta uniwersalna i narożnik wewnętrzny",
            "ru": "Универс. элементом и внутренней угловой частью",
            "sk": "Univerzálnym panelom a vnútorným rohom",
            "en-gb": "Universal panel and inside corner",
            "en-us": "Universal panel and inside corner"
        },
        {
            "ID": "XREFORMX_A2_AW2",
            "de": "Außenecke und Innenecke",
            "es": "Esquina exterior y esquina interior",
            "fr": "Angle extérieur et angle intérieur",
            "hu": "Külső sarokkal és belső sarokkal",
            "it": "Angolo esterno e angolo interno",
            "pl": "Narożnik zewnętrzny i narożnik wewnętrzny",
            "ru": "Внешней угловой частью и внутренней угловой частью",
            "sk": "Vonkajším rohom a vnútorným rohom",
            "en-gb": "Outside corner and inside corner",
            "en-us": "Outside corner and inside corner"
        },
        {
            "ID": "XREFORMX_A2_AW3",
            "de": "Scharnier-Innenecke und Scharnier-Außenecke",
            "es": "Esqu. bisagra int. y esqu. bisagra ext.",
            "fr": "Angle charnière int et angle charnière ext",
            "hu": "Csukl.belso sarok és csukl.külso sarok",
            "it": "Angolo cerniera int. e angolo cerniera est.",
            "pl": "Narożnik wewnętrzny zawiasowy i narożnik zewnętrzny zawiasowy",
            "ru": "Шарнирный внутренний угол и шарнирный внешний угол",
            "sk": "Kĺbový vnútor. roh a kĺb.vonk.roh",
            "en-gb": "Hinged inside corner and hinged outside corner",
            "en-us": "Hinged inside corner and hinged outside corner"
        },
        {
            "ID": "XREFORMX_A2_AW4",
            "de": "Scharnier-Innenecke innen und außen",
            "es": "Esqu. bisagra int. int. y ext.",
            "fr": "Angle charnière int int et ext",
            "hu": "Csukl.belso sarok belül és kívül",
            "it": "Angolo cerniera int. int. e est.",
            "pl": "Narożnik wewnętrzny zawiasowy wewnątrz i na zewnątrz",
            "ru": "Шарнирный внутренний угол изнутри и снаружи",
            "sk": "Kĺbový vnútor. roh zvnútra a zvonka",
            "en-gb": "Hinged inside corner inside and outside",
            "en-us": "Hinged inside corner inside and outside"
        },
        {
            "ID": "XREFORMX_A3",
            "de": "Ausgleich",
            "es": "Compensación",
            "fr": "Compensation",
            "hu": "Kiegyenlítés",
            "it": "Estensione",
            "pl": "Wypełnienie",
            "ru": "Компенсация",
            "sk": "Vyrovnanie",
            "en-gb": "Closure",
            "en-us": "Filler"
        },
        {
            "ID": "XREFORMX_A3_AW1",
            "de": "Ankerung im Element",
            "es": "Anclaje en elemento ",
            "fr": "Ancrage dans le panneau",
            "hu": "Ankerozás az elemen keresztül",
            "it": "Anc. nell'elemento",
            "pl": "Ściągi przez płytę",
            "ru": "Анкерование в элемент",
            "sk": "Kotvenie v paneli",
            "en-gb": "Ties through panel",
            "en-us": "Ties through panel"
        },
        {
            "ID": "XREFORMX_A3_AW2",
            "de": "Ankerung im Ausgleich",
            "es": "Anclaje en compensación",
            "fr": "Ancrage dans le compens.",
            "hu": "Ankerozás a kiegyenlítésen keresztül",
            "it": "Anc. nella compens.",
            "pl": "Ściągi przez wstawką",
            "ru": "Анкерование в компенсатор",
            "sk": "Kotvenie vo vyrovnaní",
            "en-gb": "Ties through closure",
            "en-us": "Ties through filler"
        },
        {
            "ID": "XREFORMX_A4",
            "de": "Ankerung",
            "es": "Anclaje",
            "fr": "Ancrage",
            "hu": "Ankerozás",
            "it": "Ancoraggio",
            "pl": "Kotwienie",
            "ru": "Анкерование",
            "sk": "Kotvenie",
            "en-gb": "Anchoring",
            "en-us": "Anchoring"
        },
        {
            "ID": "XREFORMX_A4_AW1",
            "de": "Ankerstab 15,0 verzinkt",
            "es": "Barra de ancl. 15,0 galv.",
            "fr": "Tige d'ancrage 15,0 galvanisée",
            "hu": "Ankerrúd 15,0 horganyzott",
            "it": "Barra ancorante 15,0 zincata",
            "pl": "Ściąg 15,0 ocynkowany",
            "ru": "Анкерный стержень 15,0 оцинкованный",
            "sk": "Kotev. tyc 15,0 pozinkov.",
            "en-gb": "Tie rod 15.0 galvanised",
            "en-us": "Tie rod 15.0 galvanized"
        },
        {
            "ID": "XREFORMX_A4_AW2",
            "de": "Ankerstab 15,0 unbehandelt",
            "es": "Barra de ancl. 15,0 no tratada",
            "fr": "Tige d'ancrage 15,0 non traitée",
            "hu": "Ankerrúd 15,0 kezeletlen",
            "it": "Barra ancorante 15,0 non trattata",
            "pl": "Ściąg 15,0 nieocynkowany",
            "ru": "Анкерный стержень 15,0 необработанный",
            "sk": "Kotev. tyc 15,0 neupravov.",
            "en-gb": "Tie rod 15.0 non-treated",
            "en-us": "Tie rod 15.0 non-treated"
        },
        {
            "ID": "XREFORMX_A5",
            "de": "Bauwerksstütze",
            "en-gb": "Column",
            "en-us": "Column",
            "es": "Pilar",
            "fr": "Poteau",
            "it": "Pilastro",
            "hu": "Pillér",
            "ru": "Колонна",
            "sk": "Stĺp",
            "pl": "Słup"
        },
        {
            "ID": "XREFORMX_A5_AW1",
            "de": "Uni-Element 0,90 mit Konsole",
            "es": "Elemento universal 0,90 con ménsula",
            "fr": "Panneau universel 0,90 avec console",
            "hu": "Uni elem 0,90 betonozó konzollal",
            "it": "Elemento uni 0,90 con mensola",
            "pl": "Płyta uniwersalna 0,90 z konsolami",
            "ru": "Универсальный элемент 0,90 с консолью",
            "sk": "Univerzálny panel 0,90 so stlp. plošinou",
            "en-gb": "Universal panel 0.90 with bracket",
            "en-us": "Universal panel 0.90 with bracket"
        },
        {
            "ID": "XREFORMX_A5_AW2",
            "de": "Elemente mit Außenecken",
            "es": "Elem. con esqu. ext.",
            "fr": "Px avec angle ext",
            "hu": "Elem külso sarokkal",
            "it": "Elementi con angolo esterno",
            "pl": "Płyty z narożnikami zewnętrznymi",
            "ru": "Элементи с внешними углами",
            "sk": "Panely s vonkaj. roh",
            "en-gb": "Panels with outside corners",
            "en-us": "Panels with outside corners"
        },
        {
            "ID": "XREFORMX_A5_AW3",
            "de": "Uni-Element 1,20 mit Konsole",
            "es": "Elemento universal 1,20 con ménsula",
            "fr": "Panneau universel 1,20 avec console",
            "hu": "Uni elem 1,20 betonozó konzollal",
            "it": "Elemento uni 1,20 con mensola",
            "pl": "Płyta uniwersalna 1,20 z konsolami",
            "ru": "Универсальный элемент 1,20 с консолью",
            "sk": "Univerzálny panel 1,20 so stlp. plošinou",
            "en-gb": "Universal panel 1.20 with bracket",
            "en-us": "Universal panel 1.20 with bracket"
        },
        {
            "ID": "XREFORMX_A6",
            "de": "Aufstockungen",
            "es": "Apilado",
            "fr": "Rehausse ",
            "hu": "Magasítások",
            "it": "Sovrapposizioni ",
            "pl": "Nadstawienia",
            "ru": "Надстройки",
            "sk": "Nadstavieb ",
            "en-gb": "Stackings",
            "en-us": "Stackings"
        },
        {
            "ID": "XREFORMX_A6_AW1",
            "de": "ohne Aufstockung",
            "es": "Sin apilado",
            "fr": "sans rehausse",
            "hu": "Magasítás nélkül",
            "it": "senza sopralzo",
            "pl": "bez nadstawienia",
            "ru": "без надстройки",
            "sk": "bez nadstavenia",
            "en-gb": "w/o stacking",
            "en-us": "w/o stacking"
        },
        {
            "ID": "XREFORMX_A6_AW2",
            "de": "Liegend aufgestockt",
            "es": "Apilado horizontal",
            "fr": "Rehaussé couchant",
            "hu": "Fektetve magasítva",
            "it": "Sovrapposizione orizz.",
            "pl": "Nadstawienie poziome",
            "ru": "надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz."
        },
        {
            "ID": "XREFORMX_A6_AW3",
            "de": "1,20m liegend + liegend aufgestockt",
            "es": "1,20m horizontal + apilado horiz.",
            "fr": "1,20m couché + rehaussé couché",
            "hu": "1,20m fektetve + fektetve magasítva",
            "it": "1,20m orizz. + sovrapposizione orizz.",
            "pl": "1,20m poziomo + nadstawienie poziome",
            "ru": "1,20м горизонт. + надстройка в горизонт. полож.",
            "sk": "1,20m naležato + nadstavenie naležato",
            "en-gb": "1.20m horizontal + stacked horiz.",
            "en-us": "1.20m horizontal + stacked horiz."
        },
        {
            "ID": "XREFORMX_A6_AW5",
            "de": "stehend aufgestockt",
            "es": "Apilado vertical",
            "fr": "Rehaussé deboutant",
            "hu": "Állítva magasítva",
            "it": "Sovrapposizione vert.",
            "pl": "Nadstawienie pionowe",
            "ru": "Надстройка в вертикальном положении",
            "sk": "Nadstavenie nastojato",
            "en-gb": "stacked upright",
            "en-us": "stacked upright"
        },
        {
            "ID": "XREFORMX_A6_AW6",
            "de": "Stehend und liegend aufgestockt",
            "es": "Elementos verticales apilados vert. y horiz.",
            "fr": "Px debouts rehaussés deboutants et couchants",
            "hu": "Elemek állítva állítva és fektetve magasítva",
            "it": "Elementi vert. sovrapposizione vert. e orizz.",
            "pl": "Nadstawienie pionowe i poziome",
            "ru": "Вертикальные элементы, надстройка в вертикальном и горизонтальном положении",
            "sk": "Panely nastojato, nadst. nastojato a naležato",
            "en-gb": "Stacked upright and horiz.",
            "en-us": "Stacked upright and horiz."
        },
        {
            "ID": "XREFORMX_A7",
            "de": "Elementtyp",
            "es": "Tipo de elemento",
            "fr": "Type d'élément",
            "hu": "Elemtípus",
            "it": "Tipo elemento",
            "pl": "Typ płyty",
            "ru": "Тип элемента",
            "sk": "Typ panelu",
            "en-gb": "Panel type",
            "en-us": "Panel type"
        },
        {
            "ID": "XREFORMX_A7_AW1",
            "de": "Normalelement",
            "es": "Elemento normal",
            "fr": "Élément étalon",
            "hu": "Normál tábla",
            "it": "Elemento normale ",
            "pl": "Płyta standardowa",
            "ru": "Унифицированный элемент",
            "sk": "Štandardný panel",
            "en-gb": "Standard panel",
            "en-us": "Standard panel"
        },
        {
            "ID": "XREFORMX_A7_AW2",
            "de": "Uni-Element",
            "es": "Elemento universal",
            "fr": "Panneau universel",
            "hu": "Uni elem",
            "it": "Elemento universale ",
            "pl": "Element uniwersalny",
            "ru": "Универсальный элемент ",
            "sk": "Uni. panel",
            "en-gb": "Universal panel",
            "en-us": "Universal panel"
        },
        {
            "ID": "XREFORMX_A9",
            "de": "Stirnabschalung",
            "es": "Tape",
            "fr": "Coffrage d'about",
            "hu": "Véglezárás",
            "it": "Chiusura di testa",
            "pl": "Szalunek czołowy",
            "ru": "Торцевые участки опалубки",
            "sk": "Debnenie cela",
            "en-gb": "Stop-end formwork",
            "en-us": "Bulkhead"
        },
        {
            "ID": "XREFORMX_A9_AW1",
            "de": "Uni-Element",
            "es": "Elemento universal",
            "fr": "Panneau universel",
            "hu": "Uni elem",
            "it": "Elemento universale ",
            "pl": "Element uniwersalny",
            "ru": "Универсальный элемент ",
            "sk": "Uni. panel",
            "en-gb": "Universal panel",
            "en-us": "Universal panel"
        },
        {
            "ID": "XREFORMX_A9_AW2",
            "de": "Klemmschiene mit Univerbinder",
            "es": "Riel de fijación con conector univ.",
            "fr": "Rail de blocage avec boulon d'assemblage univ",
            "hu": "Kötősín univerzális kötőcsavarral",
            "it": "Rotaia di fissaggio con tirante assemb. univ.",
            "pl": "Uniwersalny rygiel ścienny z łącznikiem uniwersalnym",
            "ru": "Зажимная шина c универсальной соединителью",
            "sk": "Upínacia koľajnica s univerzálnou spojkou",
            "en-gb": "Universal waling with universal fixing bolt",
            "en-us": "Universal waling with universal fixing bolt"
        },
        {
            "ID": "XREFORMX_A9_AW3",
            "de": "Klemmschiene mit Stirnanker",
            "es": "Riel de fijación con anclaje de tape",
            "fr": "Rail de blocage avec ancrage d'about",
            "hu": "Kötősín homlokankerrel",
            "it": "Rotaia di fissaggio con morsetto per testata",
            "pl": "Uniwersalny rygiel ścienny ze ściągiem czołowym",
            "ru": "Зажимная шина с торцевым анкером",
            "sk": "Upín.koľajnica, čelová kotva",
            "en-gb": "Universal waling with stop-end tie",
            "en-us": "Universal waling with stop-end tie"
        },
        {
            "ID": "ZBELSTZREX",
            "de": "Elementabstützung ReFormaX",
            "en-gb": "Panel bracing ReFormaX",
            "en-us": "Panel bracing ReFormaX",
            "es": "Apuntalamiento de elementos ReFormaX",
            "fr": "Stabilité ReFormaX",
            "it": "Puntellazione sostegno ReFormaX",
            "hu": "Elemmegtámasztás ReFormaX",
            "ru": "Крепление для элемента ReFormaX",
            "sk": "Podopretie panelov ReFormaX",
            "pl": "Wyparcia ReFormaX"
        },
        {
            "ID": "ZBELSTZREX__A1",
            "de": "Grundelementhöhe",
            "en-gb": "Basic panel height",
            "en-us": "Basic panel height",
            "es": "Altura del elemento base",
            "fr": "Hauteur de base",
            "it": "Altezza dell’ elemento base",
            "hu": "Alapelem magasság",
            "ru": "Высота основного элемента",
            "sk": "Výška základného panelu",
            "pl": "Wysokość płyty podstawowej"
        },
        {
            "ID": "ZBELSTZREX__A1_AW1",
            "de": "3,00m",
            "en-gb": "3.00m",
            "en-us": "3.00m",
            "es": "3,00m",
            "fr": "3,00m",
            "it": "3,00m",
            "hu": "3,00m",
            "ru": "3,00м",
            "sk": "3,00m",
            "pl": "3,00 m"
        },
        {
            "ID": "ZBELSTZREX__A1_AW2",
            "de": "3,30m",
            "en-gb": "3.30m",
            "en-us": "3.30m",
            "es": "3,30m",
            "fr": "3,30m",
            "it": "3,30m",
            "hu": "3,30m",
            "ru": "3,30м",
            "sk": "3,30m",
            "pl": "3,30 m"
        },
        {
            "ID": "ZBELSTZREX__A2",
            "de": "Aufstockung",
            "en-gb": "Stacking",
            "en-us": "Stacking",
            "es": "Apilado",
            "fr": "Réhausse",
            "it": "Sovrapposizione",
            "hu": "Magasítás",
            "ru": "Надстройка",
            "sk": "Nadstavenie",
            "pl": "Nadstawienie"
        },
        {
            "ID": "ZBELSTZREX__A2_AW1",
            "de": "Stehend aufgestockt",
            "en-gb": "stacked upright",
            "en-us": "stacked upright",
            "es": "Apilado vertical",
            "fr": "Rehaussé deboutant",
            "it": "Sovrapposizione vert.",
            "hu": "Állítva magasítva",
            "ru": "Надстройка в вертикальном положении",
            "sk": "Nadstavenie nastojato",
            "pl": "Nadstawienie pionowe"
        },
        {
            "ID": "ZBELSTZREX__A2_AW2",
            "de": "Liegend aufgestockt",
            "en-gb": "stacked horiz.",
            "en-us": "stacked horiz.",
            "es": "Apilado horizontal",
            "fr": "Rehaussé couchant",
            "it": "Sovrapposizione orizz.",
            "hu": "Fektetve magasítva",
            "ru": "Надстройка в горизонтальном положении",
            "sk": "Nadstavenie naležato",
            "pl": "Nadstawienie poziome"
        }
    ]
}`

export interface FavoritesLanguageConfig {
  translations: { [langKey: string]: string }[]
}
