import { HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

export enum SynchronizationState {
  SYNC_RUNNING = 'SYNC_RUNNING',
  SYNC_SAVED = 'SYNC_SAVED',
  SYNC_FAILED = 'SYNC_FAILED',
}

const HTTP_REQUEST_IGNORE_LIST = ['connect/token', 'v1/users', 'v1/calculations']

@Injectable({
  providedIn: 'root',
})
export class HttpRequestSyncService {
  private currentRunningSyncRequests: Map<string, boolean> = new Map<string, boolean>()

  public synchronizationStateSubject: BehaviorSubject<SynchronizationState> =
    new BehaviorSubject<SynchronizationState>(SynchronizationState.SYNC_SAVED)
  public synchronizationState$: Observable<SynchronizationState>

  public preventErrorMessage = false

  constructor() {
    this.synchronizationState$ = this.synchronizationStateSubject.asObservable()
  }

  public setLoading(loading: boolean, request: HttpRequest<unknown>): void {
    if (!request.url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function')
    }

    // We don't want to show the loading indicator for GET requests
    if (this.ignoreRequest(request)) {
      return
    }

    if (loading === true) {
      this.currentRunningSyncRequests.set(request.url, loading)
      this.synchronizationStateSubject.next(SynchronizationState.SYNC_RUNNING)
    } else if (loading === false && this.currentRunningSyncRequests.has(request.url)) {
      this.currentRunningSyncRequests.delete(request.url)
    }

    if (
      this.currentRunningSyncRequests.size === 0 &&
      this.synchronizationStateSubject.value !== SynchronizationState.SYNC_FAILED
    ) {
      this.synchronizationStateSubject.next(SynchronizationState.SYNC_SAVED)
    }
  }

  public showErrorMessage(request: HttpRequest<unknown>): void {
    if (!this.preventErrorMessage) {
      if (!request.url) {
        throw new Error(
          'The request URL must be provided to the LoadingService.setLoading function'
        )
      }
      if (this.ignoreRequest(request)) {
        return
      }
      if (this.currentRunningSyncRequests.has(request.url)) {
        this.currentRunningSyncRequests.delete(request.url)
      }
      this.synchronizationStateSubject.next(SynchronizationState.SYNC_FAILED)
    }
  }

  private ignoreRequest(req: HttpRequest<unknown>): boolean {
    return req.method === 'GET' || HTTP_REQUEST_IGNORE_LIST.some((r) => req.url.includes(r))
  }
}
