import { Injectable } from '@angular/core'
import { Project } from '../../../models/project'
import { DataService } from '../../data.service'
import { ProjectDao } from '../project.dao'
import { ProjectCommandParams } from '../../../../generated/efp-api'
import { PlanDao } from '../plan.dao'

@Injectable()
export class ProjectSqlDao implements ProjectDao {
  constructor(private readonly dataService: DataService, private readonly planDao: PlanDao) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private extractProjectFromRow(row: any): Project {
    return {
      id: row.id,
      name: row.name,
      stockId: row.stockId,
      plans: row.plans,
      defaultPlanSettings: row.defaultPlanSettings,
      showPlans: row.showPlans,
      defaultProject: row.defaultProject,
    }
  }

  private extractProjectsFromResult(result: SQLResultSet): Project[] {
    const projects: Project[] = []
    if (result.rows.length > 0) {
      for (let i = 0; i < result.rows.length; i++) {
        const row = result.rows.item(i)
        const project = this.extractProjectFromRow(row)
        projects.push(project)
      }
    }
    return projects
  }

  public async findAllByFavouriteId(favouriteId: number): Promise<Project[]> {
    const statement =
      'SELECT p.* FROM Projects p JOIN PlanSettings ps on p.defaultPlanSettings = ps.id WHERE ps.wallFavId = ? OR ps.slabFavId = ? '

    const result = await this.dataService.executeStatement(statement, [favouriteId, favouriteId])
    return this.extractProjectsFromResult(result)
  }

  public async findOne(projectId: number, includePlans: boolean): Promise<Project | undefined> {
    let project: Project
    const statement = 'SELECT * FROM Projects WHERE id=?'
    const values = [projectId]

    const projectResult = await this.dataService.executeStatement(statement, values)
    if (projectResult.rows.length > 0) {
      const row = projectResult.rows.item(0)
      project = this.extractProjectFromRow(row)
      if (includePlans) {
        project.plans = await this.planDao.findAllByProjectId(projectId)
      }
    } else {
      return undefined
    }

    return project
  }

  public async findAll(includePlans: boolean): Promise<Project[]> {
    const projects: Project[] = []
    const statement = 'SELECT * FROM Projects'

    const projectResult = await this.dataService.executeStatement(statement)
    if (projectResult.rows.length > 0) {
      for (let i = 0; i < projectResult.rows.length; i++) {
        const row = projectResult.rows.item(i)
        const project = this.extractProjectFromRow(row)
        if (includePlans) {
          project.plans = await this.planDao.findAllByProjectId(project.id)
        }
        projects.push(project)
      }
    }

    return projects
  }

  public async findAllByStockId(stockId: number): Promise<Project[]> {
    const statement = 'SELECT * FROM Projects WHERE stockId=?'

    const projectResult = await this.dataService.executeStatement(statement, [stockId])
    return this.extractProjectsFromResult(projectResult)
  }

  async create(params: ProjectCommandParams): Promise<number> {
    if (params.addNextProjectNumberToName) {
      const newProjectNumber = await this.getNextProjectNumber()
      params.name = `${params.name} ${newProjectNumber}`
    }

    const statement = 'INSERT INTO Projects (stockId, name, defaultPlanSettings) VALUES (?,?,?)'
    const values = [params.stockId, params.name, params.defaultPlanSettingsId]

    const result = await this.dataService.executeStatement(statement, values)
    return result.insertId
  }

  public async update(project: Project): Promise<void> {
    const statement = 'UPDATE Projects SET stockId = ?, name = ? WHERE id = ?'
    const values = [project.stockId, project.name, project.id]

    await this.dataService.executeStatement(statement, values)
  }

  public async delete(project: Project): Promise<void> {
    const statement = 'DELETE FROM Projects WHERE id = ?'
    const values = [project.id]

    await this.dataService.executeStatement(statement, values)
    await this.dataService.executeStatement('DELETE FROM PlanSettings WHERE id = ?', [
      project.defaultPlanSettings,
    ])

    const deletePlanPromises = project.plans.map(async (plan) => {
      return this.planDao.deleteWithAllRelated(plan)
    })
    await Promise.all(deletePlanPromises)
  }

  public async deleteSingleProject(project: Project): Promise<void> {
    const statement = 'DELETE FROM Projects WHERE id = ?'
    const values = [project.id]

    await this.dataService.executeStatement(statement, values)
    await this.dataService.executeStatement('DELETE FROM PlanSettings WHERE id = ?', [
      project.defaultPlanSettings,
    ])
  }

  /**
   * This returns the next project number based on the highest project id.
   * This is NOT necessarily the ID of a newly created project, do not use it for inserts or navigation.
   */
  private async getNextProjectNumber(): Promise<number> {
    const statement = 'SELECT id FROM Projects where id=(select max(id) from projects)'

    return this.dataService.executeStatement(statement, []).then((rs) => {
      let nextNumber: number
      if (rs.rows.length > 0) {
        const row = rs.rows.item(0)
        nextNumber = row.id
      } else {
        nextNumber = 0
      }

      return ++nextNumber
    })
  }
}
