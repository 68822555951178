<ion-backdrop
  *ngIf="this.primaryMenuService.sidebarShow"
  tappable="true"
  (ionBackdropTap)="closeMenu()"></ion-backdrop>
<div
  efpKeyboardShortcut
  (escShortcut)="closeMenu()"
  class="efp-collapsable-menu sidebar-slider"
  [ngClass]="{
    'sidebar-slide-in': this.primaryMenuService.sidebarShow,
    'sidebar-slide-in-immediate-collapse': this.primaryMenuService.immediateCollapse
  }">
  <efp-primary-menu [collapsable]="true"></efp-primary-menu>
</div>
