import { OnboardingHintSeries } from '../../models/onboarding/onboarding-hint'
import { OnboardingHintSeriesKey } from '../../models/onboarding/onboarding-hint-series-key'

export const ONBOARDING_ACCESSORIES_WEB: OnboardingHintSeries = {
  id: OnboardingHintSeriesKey.ACCESSORIES_WEB,
  hints: [
    {
      title: 'ONBOARDING.ACCESSORIES.WEB.ADD_ACCESSORIES.TITLE',
      textDescriptions: ['ONBOARDING.ACCESSORIES.WEB.ADD_ACCESSORIES.TEXT_DESCRIPTION'],
      steps: [
        'ONBOARDING.ACCESSORIES.WEB.ADD_ACCESSORIES.STEP_1',
        'ONBOARDING.ACCESSORIES.WEB.ADD_ACCESSORIES.STEP_2',
        'ONBOARDING.ACCESSORIES.WEB.ADD_ACCESSORIES.STEP_3',
        'ONBOARDING.ACCESSORIES.WEB.ADD_ACCESSORIES.STEP_4',
      ],
      tipDescription: 'ONBOARDING.ACCESSORIES.WEB.ADD_ACCESSORIES.TIPS.TIP_DESCRIPTION',
      tips: [
        'ONBOARDING.ACCESSORIES.WEB.ADD_ACCESSORIES.TIPS.TIP1',
        'ONBOARDING.ACCESSORIES.WEB.ADD_ACCESSORIES.TIPS.TIP2',
        'ONBOARDING.ACCESSORIES.WEB.ADD_ACCESSORIES.TIPS.TIP3',
      ],
    },
  ],
}

export const ONBOARDING_ACCESSORIES_NATIVE: OnboardingHintSeries = {
  id: OnboardingHintSeriesKey.ACCESSORIES_NATIVE,
  hints: [
    {
      title: 'ONBOARDING.ACCESSORIES.NATIVE.ADD_ACCESSORIES.TITLE',
      textDescriptions: ['ONBOARDING.ACCESSORIES.NATIVE.ADD_ACCESSORIES.TEXT_DESCRIPTION'],
      steps: [
        'ONBOARDING.ACCESSORIES.NATIVE.ADD_ACCESSORIES.STEP_1',
        'ONBOARDING.ACCESSORIES.NATIVE.ADD_ACCESSORIES.STEP_2',
        'ONBOARDING.ACCESSORIES.NATIVE.ADD_ACCESSORIES.STEP_3',
        'ONBOARDING.ACCESSORIES.NATIVE.ADD_ACCESSORIES.STEP_4',
      ],
      tipDescription: 'ONBOARDING.ACCESSORIES.NATIVE.ADD_ACCESSORIES.TIPS.TIP_DESCRIPTION',
    },
  ],
}
