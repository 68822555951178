import { Injectable } from '@angular/core'
import { Stock } from '../../../models/stock'
import { DataService } from '../../data.service'
import { StockDao } from '../stock.dao'
import { CreateArticleParams, CreateStockCommandParams } from '../../../../generated/efp-api'
import { Article } from '../../../models/article'
import { ArticleDao } from '../article.dao'
import { PlanDao } from '../plan.dao'
import { ProjectDao } from '../project.dao'

@Injectable()
export class StockSqlDao extends StockDao {
  constructor(
    private readonly dataService: DataService,
    private readonly planDao: PlanDao,
    private readonly projectDao: ProjectDao,
    private readonly articleDao: ArticleDao
  ) {
    super()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseFromRow(row: any): Stock {
    return {
      id: row.id,
      name: row.name,
      date: row.date,
      articles: [],
    }
  }

  public async createStockWithArticles(params: CreateStockCommandParams): Promise<Stock> {
    const statement = 'INSERT INTO Stock (name, date) VALUES (?,?)'
    const values = [params.name, params.date]
    const stockResult = await this.dataService.executeStatement(statement, values)
    const stockId = stockResult.insertId
    const stock: Stock = {
      id: stockId,
      name: params.name,
      date: new Date(params.date),
      articles: [],
    }
    if (!params.articles) {
      return stock
    }

    const articles = await Promise.all(
      params.articles.map(async (a) => this.createArticle(stockId, a))
    )
    stock.articles = articles

    return stock
  }

  private async createArticle(
    stockId: number,
    articleParam: CreateArticleParams
  ): Promise<Article> {
    const articleStatement =
      'INSERT INTO Article (stockId, articleId, name, amount) VALUES (?,?,?,?)'
    const articleValues = [stockId, articleParam.articleId, articleParam.name, articleParam.amount]

    return this.dataService.executeStatement(articleStatement, articleValues).then((result) => {
      const newArticle: Article = {
        id: result.insertId,
        stockId,
        articleId: articleParam.articleId,
        name: articleParam.name,
        amount: articleParam.amount,
      }
      return newArticle
    })
  }

  public async findAll(): Promise<Stock[]> {
    const stocks: Stock[] = []
    const statement = 'SELECT * FROM Stock'
    const stockResult = await this.dataService.executeStatement(statement)
    if (stockResult.rows.length === 0) {
      return stocks
    }
    for (let i = 0; i < stockResult.rows.length; i++) {
      const row = stockResult.rows.item(i)
      stocks[i] = this.parseFromRow(row)
    }
    return stocks
  }

  public async findAllRelatedPlanAndProjectNamesById(stockId: number): Promise<string[]> {
    const names: string[] = []
    const projects = await this.projectDao.findAllByStockId(stockId)
    const plans = await this.planDao.findAllByStockId(stockId)
    projects.forEach((project) => names.push(project.name))
    plans.forEach((plan) => names.push(plan.name))
    return names
  }

  public async findOneByIdWithArticles(id: number): Promise<Stock | undefined> {
    const statement = 'SELECT * FROM Stock WHERE id = ?'
    const values = [id]
    const stockResult = await this.dataService.executeStatement(statement, values)
    if (stockResult.rows.length === 0) {
      return
    }
    const stock = this.parseFromRow(stockResult.rows.item(0))
    stock.articles = await this.articleDao.findAllByStockId(id)
    return stock
  }

  public async delete(stock: Stock): Promise<void> {
    const statement = 'DELETE FROM Stock WHERE id = ?'
    const values = [stock.id]
    await this.dataService.executeStatement(statement, values)
  }

  public async deleteById(stockId: number): Promise<void> {
    const statement = 'DELETE FROM Stock WHERE id = ?'
    const values = [stockId]
    await this.dataService.executeStatement(statement, values)
  }

  public async updateName(stock: Stock, name: string): Promise<void> {
    const statement = 'UPDATE Stock SET name = ? WHERE id = ?'
    const values = [name, stock.id]
    await this.dataService.executeStatement(statement, values)
  }
}
