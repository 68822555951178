import { OnboardingHintSeries } from '../../models/onboarding/onboarding-hint'
import { OnboardingHintSeriesKey } from '../../models/onboarding/onboarding-hint-series-key'

export const ONBOARDING_CYCLES_WEB: OnboardingHintSeries = {
  id: OnboardingHintSeriesKey.CYCLES_WEB,
  hints: [
    {
      title: 'ONBOARDING.CYCLES.WEB.ADD_REPOSITION_CYCLES.TITLE',
      textDescriptions: ['ONBOARDING.CYCLES.NATIVE.ADD_REPOSITION_CYCLES.TEXT_DESCRIPTION'],
      videoUrl: 'assets/videos/AddRepositionCycles.mp4',
      steps: [
        'ONBOARDING.CYCLES.WEB.ADD_REPOSITION_CYCLES.STEP_1',
        'ONBOARDING.CYCLES.WEB.ADD_REPOSITION_CYCLES.STEP_2',
        'ONBOARDING.CYCLES.WEB.ADD_REPOSITION_CYCLES.STEP_3',
      ],
    },
  ],
}

export const ONBOARDING_CYCLES_NATIVE: OnboardingHintSeries = {
  id: OnboardingHintSeriesKey.CYCLES_NATIVE,
  hints: [
    {
      title: 'ONBOARDING.CYCLES.NATIVE.ADD_REPOSITION_CYCLES.TITLE',
      textDescriptions: ['ONBOARDING.CYCLES.NATIVE.ADD_REPOSITION_CYCLES.TEXT_DESCRIPTION'],
      videoUrl: 'assets/videos/AddRepositionCycles-S.mp4',
      steps: [
        'ONBOARDING.CYCLES.NATIVE.ADD_REPOSITION_CYCLES.STEP_1',
        'ONBOARDING.CYCLES.NATIVE.ADD_REPOSITION_CYCLES.STEP_2',
        'ONBOARDING.CYCLES.NATIVE.ADD_REPOSITION_CYCLES.STEP_3',
      ],
    },
  ],
}
