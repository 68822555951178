import { Injectable } from '@angular/core'
import { ApplicationInsights, IPageViewTelemetry } from '@microsoft/applicationinsights-web'
import { environment } from '../../environments/environment'
import { DeployEnvironment } from '../models/deploy-environment'
import { AppSettingsRepository } from '../repositories/app-settings.repository'
import { PlanSettingsRepository } from '../repositories/plan-settings.repository'
import { PlanType } from '../shared/formwork-planner/model/PlanType'
import { jwtDecode } from 'jwt-decode'
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router'
import { filter } from 'rxjs'

export enum ApplicationInsightsStates {
  PLAN_STEP_CHANGED = 'PLAN_STEP_CHANGED',
  PLAN_CREATED = 'PLAN_CREATED',
  CALCULATION_STARTED = 'CALCULATION_STARTED',
  FORMWORK_SYSTEM = 'FORMWORK_SYSTEM',
  COUNTRY = 'COUNTRY',
  PDF_CREATED = 'PDF_CREATED',
  PLAN_DELETED = 'PLAN_DELETED',
  VIEWER_ERROR = 'VIEWER_ERROR',
  INTERNAL_USER = 'INTERNAL_USER',
  EXTERNAL_USER = 'EXTERNAL_USER',
  CALCULATION_ERROR = 'CALCULATION_ERROR',
  CALCULATION_WARNING = 'CALCULATION_WARNING',
  CRITICAL_ERROR = 'CRITICAL_ERROR',
  ADVERTISEMENT_SEEN = 'ADVERTISEMENT_SEEN',
}

export enum ApplicationInsightsOrderShareStates {
  PLAN_SHARED = 'PLAN_SHARED',
  PIECELIST_SHARED_RESULT = 'PIECELIST_SHARED_RESULT',
  PIECELIST_SHARED_NEEDED = 'PIECELIST_SHARED_NEEDED',
  PIECELIST_SHARED_CHANGED = 'PIECELIST_SHARED_CHANGED',
  PIECELIST_SHARED_CYCLES = 'PIECELIST_SHARED_CYCLES',
  SHOP_CLICKED = 'SHOP_CLICKED',
  EMAIL_CLICKED = 'EMAIL_CLICKED',
  PIECELIST_CLICKED = 'PIECELIST_CLICKED',
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  public appInsights?: ApplicationInsights

  constructor(
    private readonly appSettingRepo: AppSettingsRepository,
    private readonly planSettingsRepo: PlanSettingsRepository,
    private readonly router: Router
  ) {
    this.router.events
      .pipe(filter((event: unknown): event is ResolveEnd => event instanceof ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root)
        if (activatedComponent)
          this.appInsights?.trackPageView({
            name: activatedComponent.name,
            uri: event.urlAfterRedirects,
          })
      })
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): IPageViewTelemetry | null {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild)
    }
    return snapshot.component
  }

  public enableApplicationInsights(token: string): void {
    // Don't track during development
    if (environment.deployment !== DeployEnvironment.LOCAL) {
      this.appInsights = new ApplicationInsights({
        config: {
          connectionString: environment.appInsightsConnectionString,
        },
      })
      this.appInsights.loadAppInsights()
      this.appInsights.trackPageView()

      this.appInsights?.setAuthenticatedUserContext(jwtDecode(token).sub ?? '')
    }
  }

  public addUserEvent(activity: string, planId?: number): void {
    this.appInsights?.trackEvent({ name: 'EFP Event', properties: { activity, planId } })
  }

  public async trackCalculation(
    planId: number,
    planSettingsId: number,
    buildingType: PlanType
  ): Promise<void> {
    const appSettings = await this.appSettingRepo.getAppSettings()
    const plansettings = await this.planSettingsRepo.findOneById(planSettingsId)

    this.addUserEvent(
      ApplicationInsightsStates.CALCULATION_STARTED +
        ' ' +
        ApplicationInsightsStates.FORMWORK_SYSTEM +
        ' ' +
        (buildingType === PlanType.WALL
          ? plansettings?.formworkWall ?? ''
          : (plansettings?.formworkSlab ?? '') +
            ' ' +
            ApplicationInsightsStates.COUNTRY +
            ' ' +
            appSettings.country),
      planId
    )
  }
}
