import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { SQLite, SQLiteDatabaseConfig, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx'
import { Platform } from '@ionic/angular'
import { Changelog } from '../models/changelog'
import { firstValueFrom } from 'rxjs'

/**
 * List of all changelog files, which should be applied on startup.
 * Order of files is important!
 *
 * Files should no longer be changed after merge to master to prevent issues in production.
 * The hashes of the provided files are stored and verified on startup to prevent issues.
 */
const dbChangelogsFiles = [
  'initial.sql',
  '21933-save_serialized_mesh.sql',
  '21934-add_stock_and_article_tables.sql',
  '21934-import_stock_piece_list.sql',
  '21715-save_tipos_xml.sql',
  '22206-app_settings.sql',
  '22207-project_settings.sql',
  '22208-plan_settings.sql',
  '21456-drawing_settings.sql',
  '21456-add-missing_measurementUnit.sql',
  '22203-guided_process.sql',
  '35641-contour-lines.sql',
  '22594-cycle_boundaries.sql',
  '37413-update_stock_id.sql',
  '22711-add-favourites-table.sql',
  '37897-calculated_flag.sql',
  '39853-add-last-used.sql',
  '39910-default_cycle_numbers.sql',
  '40560-changed-result-parts.sql',
  '41423-show_and_hide_magnifier.sql',
  '45757-support-multiple-favourites.sql',
  '44324-generate_PDF_from_3D.sql',
  '35689-add_sales_contact.sql',
  '40722-add_plan_outline.sql',
  '40722-use_plan_outline_accessories.sql',
  '61017-create-plan-visibility-settings.sql',
  '65354-add-formwork-fav-settings-table.sql',
  '62868-null-existing-favIDs.sql',
  '68359-default_magnifier_visibility.sql',
  '67798-add-result-db.sql',
  '71828-add_default_project_flag.sql',
  '71829-add_default_plansettings_id.sql',
  '73693-add_analytics_consent_to_appsettings.sql',
  '74876-add-article-db-id.sql',
  '75818-ensure-uniqueness-of-stock-name.sql',
  '83266-add-resultBase64Thumbnail.sql',
  '85453-add-onboarding-hints.sql',
  '86314_add_article_blacklis.sql',
  '82702_use-only-rentable-articles.sql',
  '88457-add-blacklist-article-table.sql',
  '88977-add-favorite-version.sql',
  '88081-add_show_sync_state.sql',
]

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private db!: SQLiteObject
  private sqLiteConfig: SQLiteDatabaseConfig = {
    name: 'efp.db',
    location: 'default',
  }

  constructor(
    private sqlite: SQLite,
    public platform: Platform,
    private readonly http: HttpClient
  ) {}

  async createDatabase(): Promise<void> {
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.db = (window as any).openDatabase('efp.db', '1.0', 'Data', 2 * 1024 * 1024)
    } else {
      try {
        this.db = await this.sqlite.create(this.sqLiteConfig)
      } catch (err: unknown) {
        console.error('DataService createDatabase efp.db error:', err)
        throw err
      }
    }

    await this.runDatabaseMigration()
  }

  async executeStatement(statement: string, values?: unknown[]): Promise<SQLResultSet> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any>((resolve, reject) => {
      void this.db.transaction((transaction: SQLTransaction) => {
        transaction.executeSql(
          statement,
          values,
          (_, result) => {
            resolve(result)
          },
          (_, error) => {
            console.error(
              `Failed to execute statement '${statement}': ${error.message}, with values: ${values}`
            )
            reject(error)
            return true
          }
        )
      })
    })
  }

  private async runDatabaseMigration(): Promise<void> {
    let appliedChangelogs: Changelog[]
    try {
      const result = await this.executeStatement('SELECT * from changelog', [])
      appliedChangelogs = []
      for (let i = 0; i < result.rows.length; i++) {
        const row = result.rows.item(i)
        appliedChangelogs.push({
          name: row.name,
          hash: row.hash,
        })
      }
    } catch (e: unknown) {
      appliedChangelogs = []
    }

    return this.applyChangelogs(appliedChangelogs)
  }

  private async applyChangelogs(alreadyAppliedChangelogs: Changelog[]): Promise<void> {
    for (const changelog of dbChangelogsFiles) {
      const changelogContent = await firstValueFrom(
        this.http.get('/assets/dbchangelog/' + changelog, { responseType: 'text' })
      )
      if (!changelogContent) {
        throw Error(`Failed to load changelog '${changelog}'`)
      }
      const hash = this.calculateChangelogHash(changelogContent)
      const appliedChangelog = alreadyAppliedChangelogs.find((it) => it.name === changelog)
      if (appliedChangelog && hash !== appliedChangelog.hash) {
        throw Error(
          `Already applied changelog '${changelog}' doesn't match stored hash.\nExpected: ${appliedChangelog.hash}\nActual: ${hash}`
        )
      } else if (!appliedChangelog) {
        try {
          // We have to create a Promise manually here, since this.db.transaction doesn't return a promise on desktop
          await new Promise<void>((resolve, reject) => {
            const errorHandler: SQLStatementErrorCallback = (_, error) => {
              reject(error)
              return true
            }
            void this.db.transaction((transaction: SQLTransaction) => {
              for (const statement of changelogContent.split(';')) {
                if (statement.trim().length > 0) {
                  transaction.executeSql(statement, [], undefined, errorHandler)
                }
              }
              transaction.executeSql(
                'INSERT INTO changelog(name, hash) values(?, ?)',
                [changelog, hash],
                () => resolve(),
                errorHandler
              )
            })
          })
        } catch (e: unknown) {
          const msg = e instanceof Error ? e.message : e
          throw new Error(`Failed to apply changelog '${changelog}': ${msg}`)
        }
      }
    }
  }

  // Taken from https://stackoverflow.com/a/7616484
  private calculateChangelogHash(content: string): string {
    let hash = 0
    if (content.length > 0) {
      for (let i = 0; i < content.length; i++) {
        const chr = content.charCodeAt(i)
        // eslint-disable-next-line no-bitwise
        hash = (hash << 5) - hash + chr
        // eslint-disable-next-line no-bitwise
        hash |= 0 // Convert to 32bit integer
      }
    }

    return hash.toString()
  }

  // Helper function for converting base64-string to blob for 3DViewer image
  public b64toBlob(b64Data: string, contentType: string, sliceSize: number = 512): Blob {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType ?? '' })
  }

  // after migration finished
  public async deleteDB(): Promise<void> {
    await this.sqlite.deleteDatabase(this.sqLiteConfig)
  }
}
