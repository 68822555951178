import { HighlightArea } from '../util/paper/HighlightArea'
import { HighlightAreaLayer } from '../util/paper/HighlightAreaLayer'
import { Edge, PlanType } from 'formwork-planner-lib'
import { HOVER_COLOR, HOVER_OUTLINE_COLOR } from '../../../constants/colors'
import paper from 'paper/dist/paper-core'

export enum CursorType {
  DEFAULT = 'default',
  POINTER = 'pointer',
  GRAB = 'grab',
  GRABBING = 'grabbing',
  CROSSHAIR = 'crosshair',
}

/**
 * Responsible for the selection and hover effect on wall and slab edges.
 * Creates and manages the HighlightAreas and the HighlightAreaLayer.
 * Handles the SelectionRectangle's interactions.
 */
export class SelectionService {
  public layerHighlightAreas: HighlightAreaLayer

  private selectedEdges: Edge[] = []
  private hoveredEdges: Edge[] = []
  private selectionRectangle?: paper.Path
  private htmlBody: HTMLElement

  constructor() {
    this.layerHighlightAreas = new HighlightAreaLayer()
    this.htmlBody = document.getElementsByTagName('body')[0]
  }

  setMouseCursor(type: CursorType): void {
    if (this.htmlBody) {
      this.htmlBody.style.cursor = type
    }
  }

  deselectAllEdges(): void {
    this.layerHighlightAreas.deselectAll()
    this.selectedEdges = []
  }

  getSelectedEdges(): Edge[] {
    return this.selectedEdges
  }

  createHighlightAreas(edges: Edge[], planType: PlanType): void {
    this.layerHighlightAreas.createHighlightAreas(edges, planType)
  }

  toggleEdge(edge: Edge): void {
    this.layerHighlightAreas.toggleSelection(edge)
    this.updateSelectedEdges()
  }

  setSelectedEdges(edges: Edge[], keepSelection = false): void {
    if (!keepSelection) {
      this.layerHighlightAreas.deselectAll()
    }
    edges.forEach((it) => this.layerHighlightAreas.toggleSelection(it))
    this.updateSelectedEdges()
  }

  findHighlightAreaNearPoint(point: paper.Point): HighlightArea | undefined {
    return this.layerHighlightAreas.findHighlightAreaNearPoint(point)
  }

  getIntersectingHighlightAreas(path: paper.Path.Rectangle): HighlightArea[] {
    return this.layerHighlightAreas.getIntersectingHighlightAreas(path)
  }

  unhoverAll(): void {
    this.layerHighlightAreas.unhoverAll()
    this.hoveredEdges = []
  }

  clearSelectionRectangle(selectItems = false, keepSelection = false): void {
    if (selectItems) {
      this.setSelectedEdges(this.hoveredEdges, keepSelection)
      this.hoveredEdges = []
    }
    this.selectionRectangle?.remove()
  }

  /**
   * Draws a selection rectangle between two points and hovers the items under the rectangle
   * @param startPoint starting point of the rectangle
   * @param endPoint end point of the rectangle
   */
  drawSelectionRectangle(startPoint: paper.Point, endPoint: paper.Point): void {
    this.clearSelectionRectangle()
    const rect = new paper.Path.Rectangle(startPoint, endPoint)
    rect.strokeColor = HOVER_OUTLINE_COLOR
    rect.fillColor = HOVER_COLOR
    rect.opacity = 0.4
    this.layerHighlightAreas.addChild(rect)
    this.selectionRectangle = rect

    const hoveredItems: HighlightArea[] = this.getIntersectingHighlightAreas(rect)
    this.unhoverAll()
    this.hoveredEdges = hoveredItems.map((item) => item.edge)
    hoveredItems.forEach((item) => item.hover())
  }

  private updateSelectedEdges(): void {
    this.selectedEdges = this.layerHighlightAreas.getSelectedEdges()
  }
}
