<ng-container *ngIf="initialized">
  <ng-container *ngIf="showScale">
    <efp-scale-bar
      *ngIf="zoomInMeters$ | async as zoomInMeters"
      [scaleLabel]="scaleLabel"
      [scaleUnit]="scaleUnit"
      [zoomInMeters]="zoomInMeters">
    </efp-scale-bar>
  </ng-container>
</ng-container>

<div style="position: relative; height: 100%">
  <canvas
    [class.initialized]="initialized"
    resize="true"
    #plannerCanvas
    efpWatchParentDimensions
    (dimensionsChange)="updateCanvasSize($event)"></canvas>
  <ng-content class="overlay" select="[overlay]"> </ng-content>
</div>
