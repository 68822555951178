import paper from 'paper/dist/paper-core'

/**
 * This method is used to retrieve CSS variable colors.
 * It is needed because these variables are not available in tests, so we fallback to pink there.
 *
 * Pink is used, so we would immediately see, if this happens outside of tests.
 */
const getPropertyColor = (property: string): string => {
  const style = getComputedStyle(document.body)
  if ('getPropertyValue' in style) {
    return style.getPropertyValue(property)
  } else {
    return 'pink'
  }
}

export const PRIMARY_COLOR = new paper.Color(getPropertyColor('--ion-color-primary'))
export const SECONDARY_COLOR = new paper.Color(getPropertyColor('--ion-color-secondary'))
export const BACKGROUND_COLOR = new paper.Color(getPropertyColor('--ion-background-color'))
export const DANGER_COLOR = new paper.Color(getPropertyColor('--ion-color-danger'))
export const PRIMARY_CONTRAST = new paper.Color(getPropertyColor('--ion-color-primary-contrast'))
export const DEFAULT_LABEL_COLOR = new paper.Color('#3B3B3C')
export const DEFAULT_TOUCH_AREA_COLOR = new paper.Color('#D5E0EB')
export const SLAB_TOUCH_AREA_COLOR = new paper.Color('#849fbe')
export const ERROR_TOUCH_AREA_COLOR = new paper.Color('#e59d9f')
export const WALL_COLOR = new paper.Color('#DEDFE0')
export const BACKGROUND_MESH_HIGHLIGHT_COLOR = new paper.Color('#c7c8c9')
export const AUX_GUIDELINE_COLOR = new paper.Color('#704F00')
export const ARROW_COLOR = new paper.Color('#00305E')
export const PREVIEW_COLOR = new paper.Color('#4878A8')
export const HOVER_COLOR = new paper.Color('#EBF0F6')
export const HOVER_OUTLINE_COLOR = new paper.Color('#003A73')
export const TRANSPARENT = new paper.Color('transparent')
export const TRANSPARENT_WORKAROUND = new paper.Color(1, 0, 0, 0.01) //completely transparent wont trigger mouse events; see AccessoryLineDisplay for more information
export const BLACK = new paper.Color('black')
export const WHITE = new paper.Color('white')

const CYCLE_COLOR_0 = new paper.Color('#EFF0F0')
const CYCLE_COLOR_1 = WALL_COLOR
const CYCLE_COLOR_2 = new paper.Color('#7AB9D4')
const CYCLE_COLOR_3 = new paper.Color('#FCEDA5')
const CYCLE_COLOR_4 = new paper.Color('#FBCD9A')
const CYCLE_COLOR_5 = new paper.Color('#BDA0CD')
const CYCLE_COLOR_6 = new paper.Color('#8E9DC8')

export const CYCLE_COLOR_MAPPING: { [key: string]: paper.Color } = {
  0: CYCLE_COLOR_0,
  1: CYCLE_COLOR_1,
  2: CYCLE_COLOR_2,
  3: CYCLE_COLOR_3,
  4: CYCLE_COLOR_4,
  5: CYCLE_COLOR_5,
  6: CYCLE_COLOR_6,
}

export const ACCESSORY_CIRCLE_STROKE_COLOR = new paper.Color('#000')
export const ACCESSORY_COLOR_1 = CYCLE_COLOR_3
export const ACCESSORY_COLOR_2 = CYCLE_COLOR_4
export const ACCESSORY_COLOR_3 = CYCLE_COLOR_5
export const ACCESSORY_COLOR_4 = CYCLE_COLOR_6
export const ACCESSORY_COLOR_5 = CYCLE_COLOR_2
export const ACCESSORY_COLOR_6 = new paper.Color('#9ACCA5')
export const ACCESSORY_COLOR_7 = CYCLE_COLOR_1
