import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'

import { AuthenticationRepository } from '../../repositories/authentication-repository.service'
import { AuthenticationService } from '../../services/authentication.service'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private readonly router: Router,
    private authService: AuthenticationService,
    private authenticationRepository: AuthenticationRepository
  ) {}

  async canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    await this.authService.waitUntilInitialized

    if (this.authenticationRepository.authenticated$.value) {
      return true
    } else {
      await this.authService.configureOauth2()
      await this.router.navigate(['/login'], {})
      return false
    }
  }
}
