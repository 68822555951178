import { AfterViewInit, Component, EventEmitter, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
  PlannerInteractionEvent,
  PlannerStateService,
  UnitOfLength,
  WallMesh,
} from 'formwork-planner-lib'
import { Observable, of, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { defaultDrawSettings } from '../../models/draw-settings'
import { Plan } from '../../models/plan'
import { PlanVisibilitySettings } from '../../models/plan-visibility-settings'
import { PlanSettings } from '../../models/planSettings'
import { PlanRepository } from '../../repositories/plan.repository'
import { AppSettingService } from '../../services/app-setting.service'
import { DevicePreferencesService } from '../../services/device-preferences.service'
import { FavouritesService } from '../../services/favourites.service'
import { HistoryService } from '../../services/history.service'
import { PlanSettingsService } from '../../services/plan-settings.service'
import { PlanService } from '../../services/plan.service'
import { EfpExportService } from '../../services/share/efp-export.service'
import { ZoomAndPanService } from '../../services/zoom-and-pan.service'
import { Model } from '../planner/model/Model'
import {
  ACCESSORY_LINE_CLICK_DISTANCE,
  HAS_MOVED_SUFFICIENTLY_TRESHOLD,
} from '../planner/model/snapping/constants'
import { WallModel } from '../planner/model/WallModel'
import { WallRenderService } from '../planner/services/wall-render.service'
import { AccessoryLineDisplay } from './model/AccessoryLineDisplay'
import { AccessoryLinesContainer } from './model/AccessoryLinesContainer'
import { AccessoryPart } from './model/AccessoryPart'
import { AccessorySelectionChoice } from './model/AccessorySelectionChoice'
import { AccessoryUndoRedoHistory, LineAccessoryPart } from './model/AccessoryUndoRedoHistory'
import { AccessoryService } from './services/accessory.service'
import { SidebarPosition } from '../../shared/components/efp-sidebar-container/efp-sidebar-container.component'
import { OnboardingHintSeriesKey } from '../../models/onboarding/onboarding-hint-series-key'
import { Capacitor } from '@capacitor/core'
import { TriggerType } from '../../shared/directives/onboarding-trigger.directive'

@Component({
  templateUrl: './accessory-page.component.html',
  styleUrls: ['./accessory-page.component.scss', '../style/canvas-style.scss'],
  providers: [PlannerStateService],
})
export class AccessoryPage implements AfterViewInit, OnDestroy {
  /**
   * This flag is used to signal the planner to initialize.
   * This is set via ngOnInit is, that the page DOM needs to be fully ready so the canvas inside can be initialized.
   */
  pageReady = false
  planSettings?: PlanSettings
  plan?: Plan
  model?: Model<WallMesh>
  renderService?: WallRenderService
  planVisibilitySettings?: PlanVisibilitySettings

  onboardingHintKey$?: Observable<OnboardingHintSeriesKey>

  accessoryLineLayer!: paper.Layer
  accessoryLines: AccessoryLinesContainer = new AccessoryLinesContainer([])
  supportedAccessories: AccessoryPart[] = []
  showSelector = false
  accessoryChoices: AccessorySelectionChoice[] = []
  showLegend = true

  history?: AccessoryUndoRedoHistory

  ctrlPressed = false

  public selectAllKeyboardShortcutEventEmitter = new EventEmitter<void>()
  public deselectAllKeyboardShortcutEventEmitter = new EventEmitter<void>()
  public invertKeyboardShortcutEventEmitter = new EventEmitter<void>()
  public selectAdjacentKeyboardShortcutEventEmitter = new EventEmitter<void>()

  private readonly pageLeave$ = new Subject<void>()

  constructor(
    public readonly efpExportService: EfpExportService,
    private readonly activeRoute: ActivatedRoute,
    private readonly planSettingsService: PlanSettingsService,
    private readonly plannerStateService: PlannerStateService,
    private readonly accessoryService: AccessoryService,
    private readonly favService: FavouritesService,
    private readonly zoomAndPanService: ZoomAndPanService,
    private readonly historyService: HistoryService,
    private readonly devicePreferences: DevicePreferencesService,
    private readonly appSettingService: AppSettingService,
    private readonly planRepository: PlanRepository,
    private readonly planService: PlanService
  ) {
    void this.favService.initTranslation()
  }
  protected readonly SidebarPosition = SidebarPosition
  protected readonly TriggerType = TriggerType

  get plannerInitialized(): boolean {
    return this.pageReady && !!this.plan && !!this.model && !!this.renderService
  }

  get enableAddButton(): boolean {
    return this.accessoryLines.accessoryLines.some((line) => line.isSelected)
  }

  get selectedAccessoryLines(): AccessoryLineDisplay[] {
    return this.accessoryLines.accessoryLines.filter((line) => line.isSelected)
  }

  get planId(): number | undefined {
    return this.plan ? this.plan?.id : undefined
  }

  ngAfterViewInit(): void {
    this.onboardingHintKey$ = of(this.accessoriesOnboardingId())
    this.pageReady = true
  }

  ngOnDestroy(): void {
    this.pageLeave$.next()
    this.pageReady = false
    this.plan = undefined
    this.model = undefined
    this.renderService = undefined
    this.onboardingHintKey$ = undefined
    this.accessoryLines.lines.forEach((line) => line.destroy())
  }

  accessoriesOnboardingId(): OnboardingHintSeriesKey {
    return Capacitor.isNativePlatform()
      ? OnboardingHintSeriesKey.ACCESSORIES_NATIVE
      : OnboardingHintSeriesKey.ACCESSORIES_WEB
  }

  onPlannerInitialized(): void {
    // Start listening for the plan id after everything paper is setup
    this.activeRoute.paramMap.pipe(takeUntil(this.pageLeave$)).subscribe((params) => {
      const planId = params.get('planId')
      if (planId) {
        void this.loadPlan(+planId)
      }
    })
  }

  async onOnboardingHintIndexChanged(hintIndex: number): Promise<void> {
    await this.devicePreferences.setOnboardingHintIndexAccessories(hintIndex)
  }

  onMouseUp(event: PlannerInteractionEvent): void {
    const hasMovedSufficiently = event.dragDirection.length > HAS_MOVED_SUFFICIENTLY_TRESHOLD
    if (!hasMovedSufficiently) {
      // a normal click
      const clickedLine = this.calculateClickedLine(event.point)

      if (!this.ctrlPressed && !Capacitor.isNativePlatform())
        this.triggerDeselectallKeyboardShortcut()

      clickedLine?.toggleSelection()

      if (!clickedLine?.isSelected && this.showSelector === true) {
        if (!this.checkIfSelectedAccessoryLines()) this.closeAccessorySelector()
      }
    }
  }

  onCtrl(ctrlPressed: boolean): void {
    this.ctrlPressed = ctrlPressed
  }

  checkIfSelectedAccessoryLines(): boolean {
    return this.selectedAccessoryLines.some((line) => line.isSelected)
  }

  handleDeselectionAndOpenAccessorySelection(): void {
    if (this.showSelector && !this.checkIfSelectedAccessoryLines()) {
      this.closeAccessorySelector()
    }
  }

  async onDelete(): Promise<void> {
    await this.onDeleteAccessories()
  }

  get disableDeleteButton(): boolean {
    return !this.accessoryLines.accessoryLines
      ?.filter((line) => line.isSelected)
      .some((line) => line.getAccessories().length > 0)
  }

  /**
   * Returns the closest accessory line to a point
   * @param point the point where the user clicked
   */
  calculateClickedLine(point: paper.Point): AccessoryLineDisplay | undefined {
    let clickedLine: AccessoryLineDisplay | undefined
    let minDistance = Number.MAX_VALUE
    this.accessoryLines.accessoryLines.forEach((line) => {
      const distance = line.minimumDistance(point)
      if (distance < minDistance) {
        minDistance = distance
        clickedLine = line
      }
    })
    return minDistance < ACCESSORY_LINE_CLICK_DISTANCE ? clickedLine : undefined
  }

  onZoomUpdated(zoomInMeters: number): void {
    this.zoomAndPanService.setZoom(zoomInMeters)
    this.renderService?.drawLabels()
    this.renderService?.updateAngleIndicator()
    this.accessoryLines.lines.forEach((line) => {
      line.adaptStylingToZoom(zoomInMeters)
    })
  }

  onPanUpdated(pan: paper.Point): void {
    this.zoomAndPanService.setPan(pan)
  }

  openAccessorySelection(): void {
    this.updateSelectionChoices()
    this.showSelector = true
  }

  async onDeleteAccessories(): Promise<void> {
    this.accessoryLines.accessoryLines
      .filter((line) => line.isSelected)
      .forEach((line) => line.setAccessories([]))

    this.history?.addSnapshot(this.getHistorySnapshot())
    await this.updateAccessories()
  }

  private async loadPlan(planId: number): Promise<void> {
    this.plan = await this.planService.findOne(planId)
    await this.planRepository.updateCurrentStep(this.plan.id, 'accessory')
    this.planSettings = await this.planSettingsService.getPlanSettingsAndSetLastUnit(
      this.plan.settingsId
    )
    this.planVisibilitySettings = (
      await this.appSettingService.getAppSettings()
    ).assessoryVisibilitySettings
    if (!this.plan || !this.planSettings || !this.planVisibilitySettings) {
      throw new Error(
        'AccessoryPage.loadPlan - Plan, plan settings or plan visibility settings not found in loadPlan'
      )
    }

    //We only use wallModels
    const wallModel = new WallModel(this.plannerStateService.paper, this.planSettings)

    this.model = wallModel
    this.renderService = new WallRenderService(
      wallModel,
      this.plannerStateService.paper,
      this.planVisibilitySettings,
      undefined,
      true
    )
    this.renderService.angleLabelsVisible = false
    this.renderService.meshHighlightVisible = false
    this.accessoryLineLayer = this.plannerStateService.createLayer('AccessoryLineLayer')

    if (this.plan.serializedMesh) {
      this.model?.loadSerializedMeshes(this.plan.serializedMesh)
      this.renderService.draw()
    }
    this.zoomAndPanService.initializeZoomAndPan(this.plannerStateService, this.model)

    this.supportedAccessories = this.accessoryService.getSupportedAccessories(
      this.planSettings.formworkWall
    )
    this.accessoryLines = await this.accessoryService.loadOrGenerateAccessoryLineDrawableForPlan(
      this.plan.id,
      this.planSettings.formworkWall,
      this.zoomAndPanService.getZoom()
    )
    this.accessoryLineLayer.addChildren(this.accessoryLines.lines)

    const cachedHistory = this.historyService.getAccessoryHistory(planId)
    this.history =
      cachedHistory ??
      new AccessoryUndoRedoHistory(this.getHistorySnapshot(), this.model.drawSetting)

    if (cachedHistory === undefined) {
      this.historyService.setAccessoryHistory(this.history, planId)
    }
  }

  closeAccessorySelector(): void {
    this.showSelector = false
  }

  async changeAccessoryKeyboardListener(selectedAccessory: number): Promise<void> {
    this.updateSelectionChoices()
    if (this.accessoryChoices.length >= selectedAccessory - 1) {
      this.accessoryChoices[selectedAccessory - 1].isSelected =
        !this.accessoryChoices[selectedAccessory - 1].isSelected
    }
    void this.saveSelectedAccessories()
  }

  async saveSelectedAccessories(): Promise<void> {
    this.selectedAccessoryLines.forEach((line) => {
      const newAccessories: AccessoryPart[] = [] // completely new list to preserve the accessory order
      this.accessoryChoices.forEach((choice) => {
        const alreadyAssigned = line.hasAccessory(choice.accessory)
        if (choice.added || (alreadyAssigned && !choice.removed)) {
          newAccessories.push(choice.accessory)
        }
      })
      line.setAccessories(newAccessories)
    })

    await this.updateAccessories()

    this.history?.addSnapshot(this.getHistorySnapshot())
    this.updateSelectionChoices()
  }

  getHistorySnapshot(): LineAccessoryPart[] {
    const lineAccessoryParts: LineAccessoryPart[] = []

    this.accessoryLines.accessoryLines.forEach((line) => {
      if (line.databaseId !== undefined) {
        lineAccessoryParts.push(new LineAccessoryPart(line.databaseId, line.getAccessories()))
      }
    })

    return lineAccessoryParts
  }

  async onUndo(): Promise<void> {
    await this.updateFromHistory(this.history?.getSnapshotToUndo())
  }

  async onRedo(): Promise<void> {
    await this.updateFromHistory(this.history?.getSnapshotToRedo())
  }

  private async updateFromHistory(
    lineAccessoryParts: LineAccessoryPart[] | undefined
  ): Promise<void> {
    if (this.model && this.plan && lineAccessoryParts) {
      this.accessoryLines.accessoryLines.forEach((line) => {
        const historyLineData = lineAccessoryParts.filter((it) => it.id === line.databaseId)
        if (historyLineData.length > 0) {
          // restore parts from supportedAccessories to get a paper.js Color object
          const parts = historyLineData[0].parts.map(
            (it) => this.supportedAccessories.find((accessory) => accessory.matches(it)) ?? it
          )
          line.setAccessories(parts)
        }
      })

      await this.updateAccessories()
    }
  }

  async updateAccessories(): Promise<void> {
    if (this.model && this.plan) {
      await this.accessoryService.updateAccessoryLineAssignments(this.accessoryLines)
    }
  }

  private updateSelectionChoices(): void {
    const choices: AccessorySelectionChoice[] = []
    this.supportedAccessories.forEach((supported) => {
      // preselect if EVERY selected line has the supported accessory assigned
      const preselect = this.selectedAccessoryLines.every((line) =>
        line.getAccessories().some((assigned) => assigned.matches(supported))
      )
      choices.push(new AccessorySelectionChoice(supported, preselect))
    })
    this.accessoryChoices = choices
  }

  public hasLegend(): boolean {
    return this.usedAccessoryParts().length > 0
  }

  public usedAccessoryParts(): AccessoryPart[] {
    return this.supportedAccessories.filter((part) =>
      this.accessoryLines.accessoryLines.some((line) =>
        line.getAccessories().some((accessory) => accessory.id === part.id)
      )
    )
  }

  get unit(): UnitOfLength {
    return this.model?.drawSetting?.measurementUnit ?? defaultDrawSettings.measurementUnit
  }

  public triggerSelectallKeyboardShortcut(): void {
    this.selectAllKeyboardShortcutEventEmitter.emit()
  }

  public triggerDeselectallKeyboardShortcut(): void {
    this.deselectAllKeyboardShortcutEventEmitter.emit()
  }

  public triggerInvertKeyboardShortcut(): void {
    this.invertKeyboardShortcutEventEmitter.emit()
  }

  public triggerSelectAdjacentKeyboardShortcut(): void {
    this.selectAdjacentKeyboardShortcutEventEmitter.emit()
  }
}
