import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'
import { PlanType } from 'formwork-planner-lib'
import { Subject } from 'rxjs'
import { Plan } from '../../../../../models/plan'
import { Screenshot } from '../../../../../models/screenshot'
import { FileService } from '../../../../../services/file.service'
import { LoadingSpinnerService } from '../../../../../services/loading-spinner.service'
import { PlanService } from '../../../../../services/plan.service'
import { ScreenshotService } from '../../../../../services/screenshot.service'
import { Translation } from '../../../../../services/translation.service'
import { PlanSettings } from '../../../../../models/planSettings'
import { PlanSettingsService } from '../../../../../services/plan-settings.service'
import { GeneratePDFService } from '../../../../../services/generate-pdf.service'

@Component({
  selector: 'efp-pdf-export',
  templateUrl: './pdf-export.component.html',
  styleUrls: ['./pdf-export.component.scss'],
})
export class PdfExportComponent implements OnInit, OnDestroy {
  @Input() screens!: Screenshot[]
  @Input() planId!: number

  public includeTotalStock = true
  public includeCycleStock = true
  private readonly pageLeave$ = new Subject<void>()
  private plan?: Plan
  private planSettings?: PlanSettings
  private buildingType?: PlanType
  public showStockPerCycle = false

  get hasIncludedScreens(): boolean {
    return this.screens.some((screen) => screen.useInPdf)
  }

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly alertCtrl: AlertController,
    private readonly translate: Translation,
    private readonly loadingSpinner: LoadingSpinnerService,
    private readonly fileService: FileService,
    private readonly screenshotService: ScreenshotService,
    private readonly planService: PlanService,
    private readonly planSettingsService: PlanSettingsService,
    private readonly generatePDFService: GeneratePDFService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.planId) {
      this.plan = await this.planService.findOne(this.planId)
      this.planSettings = await this.planSettingsService.getPlanSettingsAndSetLastUnit(
        this.plan.settingsId
      )
      this.buildingType = this.plan.buildingType
      this.showStockPerCycle = this.plan.buildingType === PlanType.WALL

      if (this.screens.length === 0 && this.planId) {
        this.screens = await this.screenshotService.loadData(this.plan)
        this.screens.forEach((screen) => (screen.useInPdf = true))
      } else {
        this.screens.forEach((screen) => (screen.useInPdf = true))
      }
    }
  }

  ngOnDestroy(): void {
    this.pageLeave$.next()
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }

  public async exportPDF(): Promise<void> {
    if (!this.plan) {
      throw new Error('PdfExportComponent.exportPDF - Plan not found')
    }

    const alert = await this.alertCtrl.create({
      cssClass: ['alertStyle', 'alertStyleTwoButtons'],
      header: this.translate.translate('SCREENSHOT.SET_NAME'),
      inputs: [
        {
          type: 'text',
          name: 'name',
          placeholder: this.plan.name,
          value: this.plan.name,
          attributes: { required: true },
        },
      ],
      buttons: [
        {
          text: this.translate.translate('GENERAL.CANCEL'),
        },
        {
          text: this.translate.translate('GENERAL.OK'),
          handler: async (data) =>
            await this.generatePDF(data.name ? data.name + '.pdf' : 'export.pdf'),
        },
      ],
    })
    await alert.present()
  }

  private async generatePDF(name: string): Promise<void> {
    return this.loadingSpinner.doWithLoadingSpinner(async () => {
      try {
        const screens = this.screens.filter((x) => x.useInPdf)

        if (this.plan && this.planSettings) {
          const pdfResult = await this.generatePDFService.generatePDF(
            this.plan,
            this.planSettings,
            screens,
            this.includeTotalStock,
            this.includeCycleStock,
            ''
          )
          if (pdfResult) {
            await this.fileService.shareOrDownloadBlobFile(pdfResult.pdfBlob, `PDF/${name}`, name)
          }
          await this.closeModal()
        }
      } catch (err: unknown) {
        console.error(err)

        const alert = await this.alertCtrl.create({
          backdropDismiss: false,
          header: this.translate.translate('PDF.ERROR_HEADLINE'),
          message: this.translate.translate('PDF.ERROR_MESSAGE'),
          buttons: [
            {
              text: this.translate.translate('GENERAL.CANCEL'),
            },
            {
              text: this.translate.translate('GENERAL.OK'),
              handler: async () => await this.generatePDF(name),
            },
          ],
        })
        await alert.present()
      }
    })
  }
}
