<ion-content>
  <ion-item
    *ngFor="let item of items"
    lines="none"
    detail="false"
    button
    (click)="toggleVisibility(item)"
    data-test-selector="visibility-menu-3d">
    <ion-toggle
      [checked]="item.Visible"
      color="primary"
      labelPlacement="start"
      justify="space-between"
      >{{ item.Translation }}
    </ion-toggle>
  </ion-item>
</ion-content>
