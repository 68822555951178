import { inject, Injectable } from '@angular/core'
import { AdvertisementModel, AdvertisementsApiClient } from '@efp/api'
import { firstValueFrom } from 'rxjs'

export enum TargetPlatform {
  WEB = 'web',
  MOBILE = 'mobile',
  BOTH = 'both',
}

@Injectable()
export class AdvertisementDao {
  private readonly advertisementApiClient = inject(AdvertisementsApiClient)

  async findAll(
    languageCode: string,
    countryCode: string,
    platform: TargetPlatform
  ): Promise<AdvertisementModel[]> {
    return firstValueFrom(
      this.advertisementApiClient.getAllAdvertisements({
        countryCode,
        languageCode,
        platform,
      })
    )
  }
}
