<div class="sidebar-wrapper d-flex-col fixed-sidebar">
  <efp-safe-area-top [backgroundColor]="topSafeAreaBackgroundColor"></efp-safe-area-top>
  <div class="d-flex stretch">
    <div class="header-wrapper">
      <div class="title">Easy Formwork Planner</div>
    </div>

    <ion-button
      class="close-button"
      *ngIf="collapsable"
      fill="clear"
      shape="round"
      (click)="closeMenu()">
      <ion-icon slot="icon-only" src="/assets/icon/ic_close.svg"></ion-icon>
    </ion-button>
  </div>
  <ion-item-divider></ion-item-divider>

  <div class="d-flex-col menu-sidebar">
    <div class="d-flex-col gap-8 primary-menu-wrapper">
      <ion-button
        fill="clear"
        shape="round"
        routerLink="/homepage"
        routerLinkActive="active"
        (click)="closeMenu()">
        <ion-icon slot="start" src="assets/icon/ic_home.svg"></ion-icon>
        <div class="inner-span">
          <span> {{ 'HOME.TITLE' | translate }} </span>
        </div>
      </ion-button>
      <ion-button
        routerLink="/projectoverview"
        (click)="closeMenu()"
        routerLinkActive="active"
        fill="clear"
        shape="round"
        expand="block"
        class="text-start">
        <ion-icon slot="start" src="assets/icon/ic_project.svg"></ion-icon>
        <div class="inner-span">
          <span>{{ 'HOME.PROJECTOVERVIEW' | translate }}</span>
        </div>
      </ion-button>
      <ion-button
        shape="round"
        fill="clear"
        routerLink="/stock"
        (click)="closeMenu()"
        routerLinkActive="active">
        <ion-icon slot="start" src="assets/icon/ic_OwnMaterial.svg"></ion-icon>
        <div class="inner-span">
          <span>{{ 'HOME.STOCK' | translate }}</span>
        </div>
      </ion-button>
      <ion-button
        (click)="closeMenu()"
        fill="clear"
        shape="round"
        routerLink="/favourites-overview"
        routerLinkActive="active">
        <ion-icon slot="start" src="assets/icon/ic_favorites_2.svg"></ion-icon>
        <div class="inner-span">
          <span>{{ 'SETTINGS.FAVOURITES_OVERVIEW' | translate }}</span>
        </div>
      </ion-button>
      <ion-item-divider></ion-item-divider>
      <ion-button
        (click)="closeMenu()"
        fill="clear"
        shape="round"
        routerLink="/settings"
        routerLinkActive="active">
        <ion-icon slot="start" src="assets/icon/ic_settings.svg"></ion-icon>
        <div class="inner-span">
          <span>{{ 'HOME.SETTINGS' | translate }}</span>
        </div>
      </ion-button>
      <ion-item-divider> </ion-item-divider>
      <!-- routerLink="/feedback" -->
      <ion-button
        fill="clear"
        shape="round"
        routerLinkActive="active"
        (click)="startFeedback()"
        data-test-selector="homepage-feedback">
        <ion-icon slot="start" src="assets/icon/ic_feedback.svg"></ion-icon>
        <div class="inner-span">
          <span> {{ 'HOME.FEEDBACK' | translate }}</span>
        </div>
      </ion-button>
    </div>
    <div class="secondary-menu-wrapper">
      <ion-item-divider></ion-item-divider>
      <div class="inner-wrapper d-flex-col">
        <a
          *ngIf="privacyPolicy"
          class="secondary-nav-item"
          data-test-selector="more-link-agb"
          target="_blank"
          href="{{ privacyPolicy.gdprUrl }}">
          <span>{{ 'LEGAL_MODAL.SITE_2.GDRP' | translate }}</span>
        </a>
        <a
          *ngIf="privacyPolicy"
          class="secondary-nav-item"
          data-test-selector="more-link-privacy"
          target="_blank"
          href="{{ privacyPolicy.dtdsUrl }}">
          <span> {{ 'LEGAL_MODAL.SITE_2.DTS' | translate }}</span>
        </a>
        <a
          class="secondary-nav-item"
          (click)="closeMenu()"
          routerLink="/licenses"
          routerLinkActive="active">
          <span> {{ 'LICENSES.NAV_TITLE' | translate }} </span>
        </a>
        <a class="secondary-nav-item" (click)="contact()" data-test-selector="more-link-contact">
          <span>{{ 'CONTACT.NAV_TITLE' | translate }}</span>
        </a>
        <a
          (click)="openUserInformation()"
          class="secondary-nav-item"
          routerLinkActive="active"
          data-test-selector="more-link-user-information">
          <span>{{ 'USER_INFORMATION.NAV_TITLE' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
  <efp-safe-area-bottom></efp-safe-area-bottom>
</div>
