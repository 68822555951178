import { Component, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { IonInput } from '@ionic/angular'
import { LengthUtil, UnitOfLength } from 'formwork-planner-lib'

export abstract class FocusAble {
  public abstract focus(): Promise<void>
}

@Component({
  selector: 'efp-length-input',
  templateUrl: 'length-input.component.html',
  styleUrls: ['length-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LengthInputComponent),
      multi: true,
    },
  ],
})
export class LengthInputComponent implements ControlValueAccessor, OnChanges, FocusAble {
  @ViewChild('lengthInput', { static: false }) lengthInput?: IonInput
  @Input() unit: UnitOfLength = 'cm'
  @Input() showUnit = true
  @Input() minWidth = 80
  @Input() textAlign: 'left' | 'right' | 'center' = 'right'
  @Input() shouldResizeInput = false

  valueInCm?: number
  valueInUnit?: number | string
  disabled = false

  inputWidth = 'auto'

  private onChange: (val: number | undefined) => void = () => {}
  private onBlur: (val: number | undefined) => void = () => {}

  get isImperial(): boolean {
    return this.unit === 'inch'
  }

  public async focus(select = true): Promise<void> {
    setTimeout(() => {
      void this.lengthInput?.setFocus()
      if (select) {
        void this.lengthInput?.getInputElement().then((elem) => elem.select())
      }
    }, 100)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onBlur = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  /* ControlValueAccessor implementation
     Callback function that is called when the control's value changes in the UI

     The assigned formControlName for efp-length-input will consume this function
     So you should add only cm values to the formControl in other components
  */
  writeValue(valueInCm?: number): void {
    this.valueInCm = valueInCm
    if (!valueInCm) {
      this.valueInUnit = valueInCm
    } else {
      if (this.isImperial) {
        this.valueInUnit =
          typeof valueInCm === 'number'
            ? LengthUtil.formatCmToInch(valueInCm)
            : LengthUtil.formatCmToInch(parseFloat(valueInCm))
      } else {
        // JS Decimal Math problem, workaround for this: 280 * 0.01 = 2.8000000000000003
        const convertedValue = LengthUtil.convertCmToUnit(valueInCm, this.unit)
        const decimalValueParts = convertedValue.toString().split('.')
        if (decimalValueParts.length > 1 && decimalValueParts[1].length > 0) {
          this.valueInUnit = convertedValue.toFixed(2)
        } else {
          this.valueInUnit = convertedValue
        }
      }
    }

    this.resizeInput()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.unit && this.valueInCm) {
      this.writeValue(this.valueInCm)
    }

    this.resizeInput()
  }

  onValueChanged(value: number | string): void {
    this.valueInUnit = value
    if (this.isImperial) {
      const cmValue = LengthUtil.parseInchAsCm(value as string)
      this.valueInCm = Number.isNaN(cmValue) ? undefined : cmValue
    } else {
      this.valueInCm = LengthUtil.convertUnitToCm(value as number, this.unit)
    }
    this.onChange(this.valueInCm)
    this.onBlur(this.valueInCm)

    this.resizeInput()
  }

  resizeInput(): void {
    if (this.shouldResizeInput) {
      this.inputWidth = this.shouldResizeInput
        ? `${Math.max(this.minWidth, (this.valueInUnit?.toString().length ?? 0) * 10)}px`
        : 'auto'
    }
  }
}
