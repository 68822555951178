import {
  CycleBoundaryDrawable,
  CycleSymbolDrawable,
  Edge,
  MeshPoint,
  PlanType,
  SNAPPING_ERROR_TOLERANCE,
} from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { BLACK, CYCLE_COLOR_MAPPING, TRANSPARENT, WALL_COLOR } from '../../../constants/colors'
import { PlanVisibilitySettings } from '../../../models/plan-visibility-settings'
import { parseSimplifiedWallMesh, serializeMesh } from '../model/MeshSerializer'
import { WallModel } from '../model/WallModel'
import { RenderService } from './render.service'
import { SelectionService } from './selection.service'
import { AuxiliaryGuideline } from '../model/guidelines/AuxiliaryGuideline'
import { Line2D } from '../../../utils/geometry/Line2D'
import { AuxiliaryGuidelineItem } from '../model/paper/AuxiliaryGuidelineItem'

const DEFAULT_WALL_STYLE = {
  strokeColor: BLACK,
  strokeWidth: 1,
  dashArray: [] as number[],
  fillRule: 'evenodd',
  fillColor: WALL_COLOR,
} as paper.Style

const ACCESSORY_WALL_STYLE = {
  strokeColor: TRANSPARENT, // the color will be transparent because the accessory line styling happens on the accessory layer
  strokeWidth: 2,
  dashArray: [] as number[],
  fillRule: 'evenodd',
  fillColor: WALL_COLOR,
} as paper.Style

const DEFAULT_CYCLE_NUMBER = 1
const FLOOD_FILLING_POINT_DISTANCE = 1

export class WallRenderService extends RenderService<WallModel> {
  public constructor(
    model: WallModel,
    paperScope: paper.PaperScope,
    planVisibilitySettings: PlanVisibilitySettings,
    selectionService?: SelectionService,
    public accessoryPageStyling = false
  ) {
    super(model, paperScope, PlanType.WALL, planVisibilitySettings, selectionService)
  }

  protected getMeshStyle(): paper.Style {
    if (this.accessoryPageStyling) {
      return ACCESSORY_WALL_STYLE
    } else {
      return DEFAULT_WALL_STYLE
    }
  }

  /**
   * Generates labels for the outline path of the model
   * @param cycleBoundaries if given, edges with cycle boundaries on them will be split and labels for each cycle segment will be generated
   * @param selectedTWall if given, the labels of the t-wall will be drawn with arrows to show that the t-wall can be moved
   */
  protected refreshLabels(cycleBoundaries?: CycleBoundaryDrawable[], selectedTWall?: Edge): void {
    const path = this.previewPath ?? this.path
    if (!path.children) {
      return
    }

    path.children.forEach((childPath) => {
      this.labelService.generateAngleLabelsForPath(childPath, false)
      this.labelService.generateLengthLabelsForPath(
        childPath,
        true,
        this.model.drawSetting.measurementUnit,
        cycleBoundaries,
        selectedTWall
      )
    })

    const model = this.previewModel ?? this.model
    this.labelService.generateWidthLabelsForConnectedWalls(
      model.getConnectingAndCloseDisconnectedMeshPoints(),
      model.drawSetting.measurementUnit
    )
  }

  /**
   * Creates the coloring for the cycles
   * @param cycleBoundaries list of cycle boundaries
   * @param cycleSymbols list of cycle symbols on the edges (used to choose the color of the fill)
   * @param fillLayer the layer the cycle coloration is added to
   */
  public applyFloodFill(
    cycleBoundaries: CycleBoundaryDrawable[],
    cycleSymbols: CycleSymbolDrawable[],
    fillLayer: paper.Layer
  ): void {
    if (!this.path.children) {
      return
    }

    const cycleMesh = parseSimplifiedWallMesh(this.paperScope, serializeMesh(this.model.mesh))

    // for every cycle boundary the edges are split and "mock" edges are created
    for (const boundary of cycleBoundaries) {
      const boundaryPoint = boundary.getCenter()
      const edge = cycleMesh.findClosestEdge(boundaryPoint)

      if (!edge) {
        continue
      }

      const boundaryMeshEndPoint = new MeshPoint(boundaryPoint, cycleMesh)
      const boundaryMeshStartPoint = new MeshPoint(boundaryPoint, cycleMesh)

      const newStartEdge = new Edge(edge.startPoint, boundaryMeshEndPoint, edge.thickness)
      const newEndEdge = new Edge(boundaryMeshStartPoint, edge.endPoint, edge.thickness)

      const distanceToStart = newStartEdge.length()
      const distanceToEnd = newEndEdge.length()

      if (distanceToStart > SNAPPING_ERROR_TOLERANCE) {
        cycleMesh.points.add(boundaryMeshEndPoint)
        boundaryMeshEndPoint.edges.add(newStartEdge)
        edge.startPoint.edges.add(newStartEdge)
        edge.startPoint.edges.delete(edge)
        if (distanceToEnd <= SNAPPING_ERROR_TOLERANCE) {
          cycleMesh.points.delete(edge.endPoint)
        }
      }

      if (distanceToEnd > SNAPPING_ERROR_TOLERANCE) {
        cycleMesh.points.add(boundaryMeshStartPoint)
        boundaryMeshStartPoint.edges.add(newEndEdge)
        edge.endPoint.edges.add(newEndEdge)
        edge.endPoint.edges.delete(edge)
        if (distanceToStart <= SNAPPING_ERROR_TOLERANCE) {
          cycleMesh.points.delete(edge.startPoint)
        }
      }
    }

    // the mock edges are used to generate a closed edge outline for every cycle
    const cycleMeshPaths = cycleMesh
      .groupConnectedEdges(cycleBoundaries)
      .map((mesh) => mesh.generatePath())

    for (const boundary of cycleBoundaries) {
      const leftCycleMesh = cycleMeshPaths.find((path) =>
        path.contains(boundary.getLeftPositionFromCenter(FLOOD_FILLING_POINT_DISTANCE))
      )
      const rightCycleMesh = cycleMeshPaths.find((path) =>
        path.contains(boundary.getRightPositionFromCenter(FLOOD_FILLING_POINT_DISTANCE))
      )

      if (leftCycleMesh != null && Number.isNaN(+leftCycleMesh.data)) {
        leftCycleMesh.data = boundary.cycleNumberLeft.toString()
      }

      if (rightCycleMesh != null && Number.isNaN(+rightCycleMesh.data)) {
        rightCycleMesh.data = boundary.cycleNumberRight.toString()
      }
    }

    for (const symbol of cycleSymbols) {
      const currentCycleMesh = cycleMeshPaths.find((path) => path.contains(symbol.position))

      if (currentCycleMesh != null && Number.isNaN(+currentCycleMesh.data)) {
        currentCycleMesh.data = symbol.cycleNumber.toString()
      }
    }

    cycleMeshPaths.forEach((path) => {
      const cycleNumber = Number.isNaN(+path.data) ? DEFAULT_CYCLE_NUMBER : path.data
      path.data = cycleNumber

      // the paths are colored according to the cycle number
      path.style = {
        ...DEFAULT_WALL_STYLE,
        fillRule: 'evenodd',
        fillColor: WallRenderService.getCycleColor(cycleNumber),
      }

      fillLayer.addChild(path)
    })
  }

  private static getCycleColor(cycleNumber: number): paper.Color {
    const maxCycleNumbers = Object.keys(CYCLE_COLOR_MAPPING).length
    return new paper.Color(CYCLE_COLOR_MAPPING[`${cycleNumber % maxCycleNumbers}`] ?? '')
  }

  /**
   * this code is used for debugging purposes. It visualizes the current snapping line
   * of a selected length label/cycle boundary
   * @param line
   */
  drawSnappingLine(line: paper.Path.Line): void {
    const guideLine: AuxiliaryGuideline = {
      line: new Line2D(line.firstCurve.point1, line.firstCurve.point2),
      crossingPoints: [],
    }

    this.auxiliaryGuidelineLayer.addChild(new AuxiliaryGuidelineItem(guideLine))
    this.auxiliaryGuidelineLayer.bringToFront()
  }
}
