import { EventEmitter, Injectable } from '@angular/core'
import { generateUUID } from '@ionic/cli/lib/utils/uuid'
import { getRegistry } from '@ngneat/elf'
import { LastActiveCacheChangedEvent } from '../../repositories/event.repository'

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private _lastActiveCacheId: string = this.localCacheId
  private _lastChangedAt: Date = new Date()

  private _localCacheId: string = generateUUID()
  public get localCacheId(): string {
    return this._localCacheId
  }

  public invalidLocalCacheError$ = new EventEmitter<void>()

  public setLastActiveCache(event: LastActiveCacheChangedEvent): void {
    const hasNewLastActiveCache = event.lastActiveCacheId !== this._lastActiveCacheId
    const hasNewLastChangedAt = event.lastChangedAt > this._lastChangedAt
    if (hasNewLastActiveCache || hasNewLastChangedAt) {
      this.resetStores()
      this.invalidLocalCacheError$.emit()
    }

    this._lastActiveCacheId = event.lastActiveCacheId
    this._lastChangedAt = event.lastChangedAt
  }

  public resetStores(): void {
    getRegistry().forEach((store) => store.destroy())
  }
}
