import { Component, Input } from '@angular/core'

export enum TopSafeAreaBackgroundColor {
  Secondary = 'SECONDARY',
  White = 'WHITE',
  Transparent = 'TRANSPARENT',
}
@Component({
  selector: 'efp-safe-area-top',
  templateUrl: './safe-area-top.component.html',
  styleUrls: ['./safe-area-top.component.scss'],
})
export class SafeAreaTopComponent {
  @Input() backgroundColor: TopSafeAreaBackgroundColor = TopSafeAreaBackgroundColor.Secondary
  constructor() {}
}
