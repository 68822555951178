import {
  ANGLE_SNAPPING_TOLERANCE,
  Edge,
  getClosestEdgesOnPoint,
  getDestinationOuter,
  getLineSide,
  intersectLines,
  LineSide,
  MeshPoint,
  SnappingInfo,
  xor,
} from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'

/**
 * If the points of an edge are moved or a new edge is created, adjusts the position of the start and end point so that the angles between edges remain correct
 * @param edge - the newly created  / modified edge
 * @param startCorner - the outer corner at the start of the edge (depends on neighboring edges)
 * @param endCorner - the outer corner at the end of the edge (depends on neighboring edges)
 */
export function updateAnchorPositions(
  edge: Edge,
  startCorner?: paper.Point,
  endCorner?: paper.Point
): void {
  const update = (anchor: MeshPoint, destination: paper.Point, outerCorner: paper.Point): void => {
    const neighbors = getClosestEdgesOnPoint(anchor, destination, edge)

    if (
      neighbors[LineSide.LEFT] !== edge &&
      neighbors.minAngle >= 180 + ANGLE_SNAPPING_TOLERANCE / 2
    ) {
      updateAnchorPosition(
        {
          anchor,
          destination,
          edge: neighbors[LineSide.LEFT],
          thickness: edge.thickness,
        },
        outerCorner
      )
    }

    if (
      neighbors[LineSide.RIGHT] !== edge &&
      neighbors.maxAngle <= 180 - ANGLE_SNAPPING_TOLERANCE / 2
    ) {
      updateAnchorPosition(
        {
          anchor,
          destination,
          edge: neighbors[LineSide.RIGHT],
          thickness: edge.thickness,
        },
        outerCorner
      )
    }
  }

  const startPosition = edge.startPoint.clone()
  const endPosition = edge.endPoint.clone()

  if (startCorner && endCorner) {
    // Updating one endpoint requires the other to be updated, forming
    // a circular dependency. As a workaround, we iterate a few times, to reduce
    // the error.
    for (let i = 0; i < 3; i++) {
      update(edge.startPoint, edge.endPoint, startCorner)
      update(edge.endPoint, edge.startPoint, endCorner)
    }
  } else if (startCorner) {
    update(edge.startPoint, edge.endPoint, startCorner)
  } else if (endCorner) {
    update(edge.endPoint, edge.startPoint, endCorner)
  }

  if (edge.startPoint.hasSmallAngle()) {
    edge.startPoint.set(startPosition)
  }

  if (edge.endPoint.hasSmallAngle()) {
    edge.endPoint.set(endPosition)
  }
}

export function updateAnchorPosition(info: SnappingInfo, outerCorner: paper.Point): void {
  if (!info.edge.hasPoint(info.anchor)) {
    throw new Error('Param `anchor` should be a point of param `neighborEdge`')
  }

  const destinationOuter = getDestinationOuter(info, outerCorner)

  if (!destinationOuter) {
    return
  }

  const destinationInner = info.destination.add(info.destination.subtract(destinationOuter))

  const innerCorner = getInnerCorner(info, outerCorner, destinationInner)

  if (innerCorner) {
    info.anchor.set(outerCorner.add(innerCorner).divide(2))
  }
}

function getInnerCorner(
  info: SnappingInfo,
  outerCorner: paper.Point,
  destinationInner: paper.Point
): paper.Point | undefined {
  const outerCornerSide = getLineSide(
    info.anchor,
    info.edge.getOtherPoint(info.anchor),
    info.destination
  )
  const neighborInnerLine = info.edge.getLineOnSide(
    xor(outerCornerSide === LineSide.LEFT, info.edge.startPoint === info.anchor)
      ? LineSide.RIGHT
      : LineSide.LEFT
  )

  const innerLine = {
    start: destinationInner,
    end: outerCorner.add(destinationInner.subtract(info.destination).multiply(2)),
  }

  const edgeDirection = innerLine.start.subtract(innerLine.end)
  const neighborDirection = neighborInnerLine.start.subtract(neighborInnerLine.end)
  const angle = Math.abs(edgeDirection.getAngle(neighborDirection))

  if (angle < 0.1 || (angle > 179.9 && angle < 180.1) || angle > 359.9) {
    let minDistance = Infinity
    let bestPoint: paper.Point | undefined
    ;[neighborInnerLine.start, neighborInnerLine.end].forEach((p0) => {
      ;[innerLine.start, innerLine.end].forEach((p1) => {
        const distance = p0.subtract(p1).length
        if (distance < minDistance) {
          minDistance = distance
          bestPoint = p0.add(p1).divide(2)
        }
      })
    })
    return bestPoint
  } else {
    return intersectLines(innerLine, neighborInnerLine)
  }
}
