import { Component, Input } from '@angular/core'

@Component({
  selector: 'efp-number-indicator',
  templateUrl: './number-indicator.component.html',
  styleUrls: ['./number-indicator.component.scss'],
})
export class NumberIndicatorComponent {
  @Input() color!: string
  @Input() number!: number
}
