import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { FavouriteSystem } from '../../../models/favourites'
import { FavouritesService } from '../../../services/favourites.service'
import { Translation } from '../../../services/translation.service'

@Component({
  selector: 'efp-favourites-systems',
  templateUrl: './favourites-systems.page.html',
  styleUrls: ['./favourites-systems.page.scss'],
})
export class FavouritesSystemsPage implements OnInit {
  @Input() favouriteFormworksystems?: FavouriteSystem[]

  constructor(
    public favService: FavouritesService,
    public translate: Translation,
    private modalCtrl: ModalController
  ) {}

  async ngOnInit(): Promise<void> {
    await this.favService.initTranslation()
  }

  async selectFavoriteSystem(system: FavouriteSystem): Promise<void> {
    await this.modalCtrl.dismiss({ system })
  }
}
