import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { OAuthService } from 'angular-oauth2-oidc'
import { BehaviorSubject } from 'rxjs'
import { CacheService } from '../services/cache/cache.service'

@Injectable({
  providedIn: 'root',
})
export class AuthenticationRepository {
  public authenticated$ = new BehaviorSubject<boolean>(false)

  constructor(
    private oauthService: OAuthService,
    private readonly router: Router,
    private readonly cacheService: CacheService
  ) {}

  public updateAuthentication(authenticated: boolean): void {
    this.authenticated$.next(authenticated)

    if (!authenticated) {
      this.cacheService.resetStores()
    }
  }

  public async logoutAndRedirectToLogin(): Promise<void> {
    this.updateAuthentication(false)
    this.oauthService.logOut(true)

    await this.router.navigate(['/login'], {})
  }
}
