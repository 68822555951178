import { Injectable } from '@angular/core'
import { PlanVisibilitySettings } from '../../../models/plan-visibility-settings'
import { DataService } from '../../data.service'
import { PlanVisibilitySettingsDao } from '../plan-visibility-settings.dao'

@Injectable()
export class PlanVisibilitySettingsSqlDao extends PlanVisibilitySettingsDao {
  constructor(private readonly dataService: DataService) {
    super()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseFromRow(row: any): PlanVisibilitySettings {
    return {
      id: row.id,
      showGripPoints: row.showGripPoints === 1,
      showAngles: row.showAngles === 1,
      showAuxiliaryLines: row.showAuxiliaryLines === 1,
      showDimensions: row.showDimensions === 1,
      showAccessoryLegend: row.showAccessoryLegend === 1,
    }
  }

  public async findOne(id: number): Promise<PlanVisibilitySettings | undefined> {
    const statement = 'SELECT * FROM PlanVisibilitySettings WHERE id=?'
    const values = [id]
    let settings: PlanVisibilitySettings | undefined = undefined
    const result = await this.dataService.executeStatement(statement, values)
    if (result.rows.length === 1) {
      settings = this.parseFromRow(result.rows.item(0))
    }
    return settings
  }
  public async update(planVisibilitySettings: PlanVisibilitySettings): Promise<void> {
    const statement =
      'UPDATE PlanVisibilitySettings SET showGripPoints=?, showAngles=?, showAuxiliaryLines=?, showDimensions=? WHERE id=?'
    const values = [
      planVisibilitySettings.showGripPoints ? 1 : 0,
      planVisibilitySettings.showAngles ? 1 : 0,
      planVisibilitySettings.showAuxiliaryLines ? 1 : 0,
      planVisibilitySettings.showDimensions ? 1 : 0,
      planVisibilitySettings.showAccessoryLegend ? 1 : 0,
      planVisibilitySettings.id,
    ]
    await this.dataService.executeStatement(statement, values)
  }
}
