<ion-list class="d-flex-col">
  <efp-list-header>
    <ion-label>
      {{ 'ACC.ACCOUNTS' | translate }}
    </ion-label>
  </efp-list-header>

  <div
    class="d-flex-col gap-12 flex-1 p-bottom-24"
    *ngIf="{ enabled: this.accConnectorEnabled$ | async } as obj">
    <div
      class="d-flex form-container d-flex-responsive-wrap d-flex-responsive-gap"
      *ngIf="obj.enabled === false">
      <p class="header-description">
        <ion-note>
          {{ 'ACC.ACCOUNT_LINK_INFO' | translate }}
        </ion-note>
      </p>
    </div>

    <div class="d-flex form-container d-flex-responsive-wrap d-flex-responsive-gap">
      <div class="label-container flex-1 d-flex">
        <ion-label>Autodesk Construction Cloud</ion-label>
      </div>
      <div *ngIf="!getAccUserMail(); else connectedAcc">
        <ion-button (click)="connectToAcc()" class="d-flex flex-1 cta-btn" fill="outline">
          <span class="btn-text">
            {{ 'ACC.CONNECT' | translate }}
          </span>
          <ion-icon src="assets/icon/arrow-forward-outline.svg" slot="end"></ion-icon>
        </ion-button>
      </div>
      <ng-template #connectedAcc>
        <div class="d-flex account-container flex-1">
          <div class="d-flex gap-8 inner">
            <ion-badge
              [ngClass]="{ success: obj.enabled }"
              efpTooltip
              [tooltipTitle]="getAuthText(obj.enabled)"
              [placement]="tooltipPosition.TOP">
              <ion-icon *ngIf="obj.enabled; else connectorNotEnabled" name="lock-open-outline">
              </ion-icon>
              <ng-template #connectorNotEnabled>
                <ion-icon *ngIf="obj.enabled === false" name="lock-closed-outline"> </ion-icon>
              </ng-template>
            </ion-badge>
            <span class="mail">{{ getAccUserMail() }}</span>
          </div>

          <ion-button fill="clear" class="remove-account" (click)="unlinkAlert()" color="danger">
            <ion-icon name="remove-circle-outline" size="small"></ion-icon>
          </ion-button>
        </div>
      </ng-template>
    </div>
  </div>
</ion-list>
