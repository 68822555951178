import { UnitOfLength } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import {
  DEFAULT_LABEL_COLOR,
  HOVER_OUTLINE_COLOR,
  PRIMARY_COLOR,
} from '../../../../constants/colors'
import {
  CLICK_AREA_HIDDEN_OPACITY,
  LENGTH_LABEL_TICK_SIZE,
  MeasurementLabel,
} from './MeasurementLabel'

export class LengthLabel extends MeasurementLabel {
  constructor(
    private readonly from: paper.Point,
    private readonly to: paper.Point,
    fontSize: number,
    strokeWidth: number,
    public readonly isCombinedEdgeLength: boolean,
    public readonly bold: boolean,
    public readonly unit: UnitOfLength,
    public readonly interactive?: boolean
  ) {
    super(from, to, fontSize, strokeWidth, bold, unit, interactive)

    if (this.isCombinedEdgeLength) {
      this.opacity = 0.5
      this.path.style.dashArray = [4, 10]
    }
    const textVector = to.subtract(from).rotate(-90).normalize(fontSize)
    this.moveText(this.path.bounds.center.add(textVector))
  }

  get arrowPositions(): paper.Point[] {
    const text = this.text.clone({ insert: false })
    text.rotate(-text.rotation, text.bounds.center)
    return [
      text.bounds.leftCenter
        .add(new paper.Point(-10, 0))
        .rotate(this.text.rotation, text.bounds.center),
      text.bounds.rightCenter
        .add(new paper.Point(10, 0))
        .rotate(this.text.rotation, text.bounds.center),
    ]
  }

  public getDirectionVector(): paper.Point {
    return this.to.subtract(this.from).normalize()
  }

  public get isClickable(): boolean {
    return !this.isCombinedEdgeLength
  }

  public hover(): void {
    if (this.isCombinedEdgeLength) {
      return
    }

    this.text.fillColor = HOVER_OUTLINE_COLOR
    this.path.strokeColor = HOVER_OUTLINE_COLOR
    this.clickArea.opacity = 1
  }

  public unhover(): void {
    if (this.isCombinedEdgeLength) {
      return
    }

    this.clickArea.opacity = CLICK_AREA_HIDDEN_OPACITY
    this.interactive ? this.setColor(PRIMARY_COLOR) : this.setColor(DEFAULT_LABEL_COLOR)
    this.path.strokeColor = DEFAULT_LABEL_COLOR
  }

  protected generateLengthPath(pointFrom: paper.Point, pointTo: paper.Point): paper.CompoundPath {
    const line = new paper.Path()
    line.strokeColor = DEFAULT_LABEL_COLOR
    line.strokeWidth = this.strokeWidth

    const lengthVector = pointTo.subtract(pointFrom)
    const awayVector = lengthVector.normalize(LENGTH_LABEL_TICK_SIZE).rotate(-90)
    const upVector = awayVector.multiply(Math.max(2, 1 / this.view.zoom))
    const downVector = upVector.rotate(180)

    line.add(pointFrom.add(awayVector))
    line.lineBy(upVector.multiply(2))
    line.lineBy(downVector)
    line.lineBy(lengthVector)
    line.lineBy(upVector)
    line.lineBy(downVector.multiply(2))

    this.addChild(line)
    return line
  }
}
