import { Injectable, inject } from '@angular/core'
import {
  PlansApiClient,
  NavStep as ApiNavStep,
  PlanType as ApiPlanType,
  PlanUpdateCommandParams,
  PlanListModel,
} from '@efp/api'
import { PlanType } from 'formwork-planner-lib' // Assuming you have an API client for Plans
import { PlanDao } from '../plan.dao'
import { Plan } from '../../../models/plan'
import { NavStep } from '../../../models/navSteps'
import { firstValueFrom } from 'rxjs'
import { PlanService } from '../../plan.service'

@Injectable()
export class PlanHttpDao extends PlanDao {
  private readonly plansApiClient = inject(PlansApiClient)

  constructor() {
    super()
  }

  public async updatePlan(
    plan: Pick<Plan, 'id' | 'name' | 'date' | 'buildingType' | 'stockId' | 'projectId' | 'lastUsed'>
  ): Promise<Plan> {
    const body: PlanUpdateCommandParams = {
      name: plan.name,
      date: (plan.date ?? new Date()).toISOString(),
      buildingType: plan.buildingType === PlanType.SLAB ? ApiPlanType.Slab : ApiPlanType.Wall,
      stockId: plan.stockId,
      projectId: plan.projectId,
    }
    await firstValueFrom(this.plansApiClient.patchPlan({ planId: plan.id, body }))

    const newPlan = await this.findOne(plan.id)
    if (!newPlan) {
      throw new Error(`PlanDao.updatePlan - Plan with id ${plan.id} not found`)
    }
    return newPlan
  }

  public async updateSerializedMesh(id: number, serializedMesh?: string): Promise<Plan> {
    await firstValueFrom(
      this.plansApiClient.patchPlan({
        planId: id,
        resetCalculation: true,
        body: { serializedMesh, date: new Date().toISOString() },
      })
    )
    const newPlan = await this.findOne(id)
    if (!newPlan) {
      throw new Error(`PlanDao.updatePlan - Plan with id ${id} not found`)
    }
    return newPlan
  }

  public async updateCurrentStep(planId: number, currentStep: NavStep): Promise<Plan> {
    await firstValueFrom(
      this.plansApiClient.patchPlan({
        planId,
        body: { currentStep: currentStep as ApiNavStep, date: new Date().toISOString() },
      })
    )

    const newPlan = await this.findOne(planId)
    if (!newPlan) {
      throw new Error(`PlanDao.updateCurrentStep - Plan with id ${planId} not found`)
    }
    return newPlan
  }

  public async findOne(planId: number): Promise<Plan | undefined> {
    const response = await firstValueFrom(this.plansApiClient.getPlan({ planId }))
    return PlanService.mapPlanModelToPlan(response)
  }

  public async findOneBySettingsId(settingsId: number): Promise<Plan | undefined> {
    const response = await firstValueFrom(this.plansApiClient.getAllPlans({ settingsId }))

    if (response.plans.length > 0) {
      return PlanService.mapPlanModelToPlan(response.plans[0])
    }
    return undefined
  }

  public async findAllByProjectId(projectId: number): Promise<Plan[]> {
    const response = await firstValueFrom(this.plansApiClient.getAllPlans({ projectId }))
    return response.plans.map(PlanService.mapPlanModelToPlan)
  }

  public async findAllByFavouriteId(favouriteId: number): Promise<Plan[]> {
    const response = await firstValueFrom(this.plansApiClient.getAllPlans({ favouriteId }))
    return response.plans.map(PlanService.mapPlanModelToPlan)
  }

  public async findAllByStockId(stockId: number): Promise<Plan[]> {
    const response = await firstValueFrom(this.plansApiClient.getAllPlans({ stockId }))
    return response.plans.map(PlanService.mapPlanModelToPlan)
  }

  public async findAllWithSerializedMesh(): Promise<Plan[]> {
    const response = await firstValueFrom(
      this.plansApiClient.getAllPlans({ querySerialized: true })
    )
    return response.plans.map(PlanService.mapPlanModelToPlan)
  }

  public async findRecentPlans(amount: number): Promise<Plan[]> {
    const response = await firstValueFrom(this.plansApiClient.getAllPlans({ limit: amount }))
    return response.plans.map(PlanService.mapPlanModelToPlan)
  }

  public async findAllPlans(): Promise<Plan[]> {
    const response = await firstValueFrom(this.plansApiClient.getAllPlans())
    return response.plans.map(PlanService.mapPlanModelToPlan)
  }

  public async findAllPlansWith(limit: number, offset: number): Promise<PlanListModel> {
    const response = await firstValueFrom(this.plansApiClient.getAllPlans({ limit, offset }))
    return response
  }

  public async count(): Promise<number> {
    const response = await firstValueFrom(this.plansApiClient.getPlansCount())
    return response
  }

  public async countStocksById(stockId: number): Promise<number> {
    const response = await firstValueFrom(this.plansApiClient.getPlansCount({ stockId }))
    return response
  }

  public async deleteWithAllRelated(plan: Plan): Promise<void> {
    await firstValueFrom(this.plansApiClient.deletePlan({ planId: plan.id }))
  }
}
