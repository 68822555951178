import {
  calculateOuterCornerPoint,
  Edge,
  ExtendedMeshPoint,
  getDestinationOuter,
  isExtendedMeshPoint,
  Line,
  LinePosition,
  Mesh,
  MeshPoint,
  SNAP_ANGLE_INTERVAL,
  SNAPPING_DISTANCE,
  SnapResult,
  TransformationLengthDirection,
  TransformationThicknessDirection,
} from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { Observable, Subject } from 'rxjs'
import { defaultDrawSettings, DrawSettings } from '../../../models/draw-settings'
import { UndoRedoHistory } from '../../../models/history/undoRedoHistory'
import { Line2D } from '../../../utils/geometry/Line2D'
import { AngleInfo } from '../types/AngleInfo'
import { EdgeAttributes } from '../types/edgeAttributes'
import { SelectAngleRay } from '../types/selectAngleRay'
import { getLengthSnappedPoint } from './snapping/snapPoint'

/**
 * Contains mesh data for a wall or slab model, methods to manipulate the underlying mesh,
 * as well as an undo/redo history.
 */
export abstract class Model<MESH extends Mesh> {
  public history: UndoRedoHistory<MESH>
  public mesh: MESH

  private path: paper.CompoundPath | undefined
  private modelChanged = new Subject<void>()

  /**
   * This is fired every time the current model state changes in any way.
   */
  get modelChanged$(): Observable<void> {
    return this.modelChanged
  }

  /**
   * This is fired everytime the current history state is changed.
   * This can happen on undo, redo or when performing actions which are committed to the history.
   */
  get historyChanged$(): Observable<void> {
    return this.history.historyChanged
  }

  public constructor(
    protected readonly paperScope: paper.PaperScope,
    public readonly drawSetting: DrawSettings = defaultDrawSettings,
    private cachedHistory?: UndoRedoHistory<MESH>
  ) {
    this.mesh = this.createEmptyMesh()
    this.history = cachedHistory ?? this.createUndoRedoHistory()
  }

  public abstract clone(): Model<MESH>

  abstract getSurroundingLines(edges: Edge[], generateOuterEdges?: boolean): Line2D[]

  protected abstract generatePath(): paper.CompoundPath

  protected abstract createUndoRedoHistory(): UndoRedoHistory<MESH>

  protected abstract createEmptyMesh(): MESH

  /**
   * Creates a new edge in the actual mesh. See:
   *
   * @param startPoint The start point of the new edge, can either be a completely new point, or an existing point in the mesh.
   * @param endPoint The end point of the new edge, can either be a completely new point, or an existing point in the mesh.
   * @param thickness The thickness of the edge to create. Will be ignored for slabs.
   * @param skipEndSnapping if true, the end point of the edge won't be snapped to existing points
   * @param updateAnchor if true, the anchor positions will be updated
   * @param intoEdge if set, no new edge will be created but an existing one will be updated to move to the given points.
   */
  protected abstract createEdgeInMesh(
    startPoint: MeshPoint | paper.Point,
    endPoint: MeshPoint | paper.Point,
    thickness: number,
    skipEndSnapping: boolean,
    updateAnchor: boolean,
    intoEdge?: Edge
  ): Edge | undefined

  protected abstract getEdgeAttributes(edge: Edge): EdgeAttributes

  protected abstract updateEdgeThickness(
    edge: Edge,
    thickness: number,
    thicknessDirection: TransformationThicknessDirection
  ): void

  protected abstract updateEdgeLength(
    edge: Edge,
    outerLength: number,
    lengthDirection: TransformationLengthDirection
  ): void

  /**
   *
   * @param point to raster
   * @param edge to raster the point on
   * @param movedEdge of the moved point
   * @returns a point with adjusted distance from the edges endpoints
   */
  protected abstract rasterOnEdge(point: paper.Point, edge: Edge, movedEdge: Edge): paper.Point

  /**
   * Perform any updated that need to happen after edges have been moved.
   *
   * @param movedEdges The edges which have been moved.
   * @returns Any new edges which are also affected by movedEdges edges.
   */
  protected abstract updatedEdgesAfterMove(movedEdges: Edge[]): Edge[]

  /**
   * snaps a point to an edge or a mesh point
   * @param movedPoint - the position used for snapping
   * @param originalPoint - the original point that is being moved
   * @param edges - edges that the point should be snapped to
   * @returns a snapping result if one was found, undefined otherwise
   */
  protected abstract snapToEdgeOrMeshPoint(
    movedPoint: paper.Point,
    originalPoint: MeshPoint,
    edges: Edge[]
  ): SnapResult

  /**
   * Generate and set the path. Update edge validity per default.
   *
   * @param shouldValidate Update the edge validity if true.
   * @returns Generated path.
   */
  createPath(shouldValidate: boolean = true): paper.CompoundPath {
    if (!this.path) {
      if (shouldValidate) {
        this.mesh.updateEdgeValidity()
      }
      this.path = this.generatePath()
    }

    return this.path
  }

  // =================
  // === ACCESSORS ===
  // =================

  public isValid(): boolean {
    return this.mesh.updateEdgeValidity() === 0
  }

  getBoundingBox(): paper.Rectangle | undefined {
    const path = this.createPath()

    if (path.children.length > 0) {
      return new paper.Rectangle(path.bounds.point, path.bounds.size)
    } else {
      return undefined
    }
  }

  getPoints(): MeshPoint[] {
    return Array.from(this.mesh.points)
  }

  getAllEdges(): Edge[] {
    return Array.from(this.mesh.getAllEdges())
  }

  /**
   * Tries to find an Edge around the given point, returning undefined if no edge is close
   */
  findEdgeNearPoint(point: paper.Point): Edge | undefined {
    return this.mesh.snapPointToEdge(point)?.edge
  }

  /**
   * Finds the closest Edge around the given point or undefined if no edges are defined
   */
  getNearestEdge(point: paper.Point): Edge | undefined {
    return this.mesh.findClosestEdge(point)
  }

  /**
   * Gets the nearest edge to a point that is parallel to a given direction
   */
  getNearestParallelEdge(point: paper.Point, direction: paper.Point): Edge | undefined {
    const parallelEdges = this.getAllEdges().filter((edge) =>
      edge.getDirection().isCollinear(direction)
    )
    return this.mesh.snapPointToEdge(point, parallelEdges)?.edge
  }

  public getClosestAngle(
    originPoint: paper.Point,
    centerPoint: paper.Point
  ): AngleInfo | undefined {
    const closestMeshPoint = this.mesh.snapPointToMeshPoints(originPoint, Infinity)

    let bestLeftAngle = 180
    let bestLeftEndpoint: MeshPoint | undefined

    let bestRightAngle = -180
    let bestRightEndpoint: MeshPoint | undefined

    if (closestMeshPoint) {
      if (closestMeshPoint.edges.size > 1) {
        const vectorToDownPoint = centerPoint.subtract(closestMeshPoint)

        Array.from(closestMeshPoint.edges).forEach((edge) => {
          const currentEndPoint = edge.getOtherPoint(closestMeshPoint)
          const vectorToCurrent = currentEndPoint.subtract(closestMeshPoint)
          const currentAngle = vectorToCurrent.getDirectedAngle(vectorToDownPoint)

          if (currentAngle < 0 && currentAngle > bestRightAngle) {
            bestRightAngle = currentAngle
            bestRightEndpoint = currentEndPoint
          }

          if (currentAngle > 0 && currentAngle < bestLeftAngle) {
            bestLeftAngle = currentAngle
            bestLeftEndpoint = currentEndPoint
          }
        })

        if (bestLeftEndpoint && bestRightEndpoint) {
          return {
            angleVertex: closestMeshPoint,
            leftEndpoint: bestLeftEndpoint,
            rightEndpoint: bestRightEndpoint,
          }
        }
      }
    }
    return undefined
  }

  getAttributes(selectedEdges: Edge[]): EdgeAttributes {
    if (selectedEdges.length !== 1) {
      return {
        innerLength: 0,
        outerLength: 0,
        thickness: 0,
      }
    }

    return this.getEdgeAttributes(selectedEdges[0])
  }

  // =================
  // === MODIFIERS ===
  // =================

  /**
   * Moves a point in the model somewhere else.
   * Depending on what is located at the given downPoint, either a whole edge is moved or
   * only the start or end of an edge is moved.
   *
   * If nothing is located at or near the downPoint, nothing will happen.
   *
   * @param downPoint The start point of movement.
   * @param destination The destination to move to.
   * @param selectedEdges All selected edges, which should be moved.
   * @param skipSnapping If true, moved walls won't be snapped
   */
  public move(
    downPoint: paper.Point,
    destination: paper.Point,
    selectedEdges: Edge[],
    skipSnapping: boolean = false
  ): Line | Edge[] | undefined {
    this.revert()
    const snapResult = this.mesh.snapPointToEdgeOrPoint(downPoint)
    const snapToEdgeResult = this.mesh.snapPointToEdge(downPoint)

    if (!snapResult) {
      return undefined
    }

    let lineResult: Line | undefined
    if (
      snapToEdgeResult &&
      (skipSnapping ||
        !(snapResult instanceof MeshPoint) ||
        snapToEdgeResult.edge.getCenterPoint().getDistance(downPoint) <
          snapResult.getDistance(downPoint))
    ) {
      //Move all selected Edges + the clicked one
      const vector = destination.subtract(snapToEdgeResult.point)

      // Add original of all selected edges
      const edgesToMove: Edge[] = Array.from(this.mesh.getAllEdges()).filter((edge) =>
        selectedEdges.some((selectedEdge) => selectedEdge.isSameEdge(edge))
      )
      if (!snapToEdgeResult.edge.isInEdgeList(edgesToMove)) {
        // Add clicked edge if not already selected
        edgesToMove.push(snapToEdgeResult.edge)
      }
      const movedEdges = this.moveEdges(edgesToMove, vector, skipSnapping)

      this.modelChanged.next()
      return movedEdges
    } else if (snapResult instanceof MeshPoint) {
      let edge: Edge | undefined
      if (snapResult.edges.size === 1) {
        edge = Array.from(snapResult.edges)[0]
      }

      const newPoint = this.movePoint(snapResult, destination)
      if (edge) {
        lineResult = {
          [LinePosition.START]: edge.getOtherPoint(newPoint),
          [LinePosition.END]: newPoint,
        }
      }
      this.modelChanged.next()
      return lineResult
    }

    return undefined
  }

  /**
   * Sets length, width/thickness and height for the edge and returns the updated edge.
   * <b>Note:</b> The returned edge  is not the same object as the one passed in!
   *
   * @returns Returns the updated Edge or undefined, if the passed edge isn't contained in this model.
   */
  public setEdgeAttributes(
    edge: Edge,
    attributes: EdgeAttributes,
    thicknessDirection: TransformationThicknessDirection,
    lengthDirection: TransformationLengthDirection
  ): Edge | undefined {
    this.revert()
    // Force re-calculation of path for correct resize-behavior
    this.createPath()

    const updatedEdge = this.getAllEdges().find((it) => it.isSameEdge(edge))
    if (!updatedEdge) {
      return undefined
    }

    this.updateEdgeThickness(updatedEdge, attributes.thickness, thicknessDirection)
    this.updateEdgeLength(updatedEdge, attributes.outerLength, lengthDirection)
    this.path = undefined
    this.modelChanged.next()

    return updatedEdge
  }

  public createEdge(startPoint: paper.Point, endPoint: paper.Point): Edge | undefined {
    this.revert()
    const edge = this.createEdgeInMesh(
      startPoint,
      endPoint,
      this.drawSetting.wallThickness,
      false,
      true
    )
    this.modelChanged.next()

    return edge
  }

  public changeAngle(angleInfo: AngleInfo, rotationDegree: number, movedLeg: SelectAngleRay): void {
    this.revert()
    const angleVertex = angleInfo.angleVertex

    const currentAngleVertex = Array.from(this.mesh.points).find(
      (it) => it.x === angleVertex.x && it.y === angleVertex.y
    )
    if (!currentAngleVertex || currentAngleVertex.edges.size < 2) {
      return
    }

    let correctedRotationDegree = rotationDegree

    let movedEndPoint: MeshPoint | undefined
    switch (movedLeg) {
      case SelectAngleRay.LEFT:
        movedEndPoint = angleInfo.leftEndpoint
        break
      case SelectAngleRay.RIGHT:
        movedEndPoint = angleInfo.rightEndpoint
        break
      case SelectAngleRay.CENTER:
        correctedRotationDegree = rotationDegree / 2
        break
    }

    if (movedEndPoint) {
      this.rotateEdge(
        currentAngleVertex,
        movedEndPoint,
        movedLeg === SelectAngleRay.LEFT ? correctedRotationDegree * -1 : correctedRotationDegree
      )
    } else {
      const movedEndPoint1 = angleInfo.leftEndpoint
      this.rotateEdge(currentAngleVertex, movedEndPoint1, correctedRotationDegree * -1)

      const movedEndPoint2 = angleInfo.rightEndpoint
      this.rotateEdge(currentAngleVertex, movedEndPoint2, correctedRotationDegree)
    }
    this.modelChanged.next()
  }

  public removeEdges(edges: Edge[]): void {
    let didRemove = false
    edges.forEach((edge) => {
      const didRemoveEdge = this.mesh.removeEdge(edge, false)

      if (didRemoveEdge) {
        // merge only if the edges of the point aren't also to be deleted
        if (edge.startPoint.canMergeEdges() && !edges.some((e) => edge.startPoint.edges.has(e))) {
          edge.startPoint.mergeEdges()
        }

        if (edge.endPoint.canMergeEdges() && !edges.some((e) => edge.endPoint.edges.has(e))) {
          edge.endPoint.mergeEdges()
        }
      }

      didRemove = didRemoveEdge || didRemove
    })

    if (didRemove) {
      this.path = undefined
      this.addSnapshot()
    }
  }

  public mergeEdges(edges: Edge[]): void {
    const endPoints = new Set<MeshPoint>()
    edges.forEach((edge) => {
      endPoints.add(edge.startPoint)
      endPoints.add(edge.endPoint)
    })

    Array.from(endPoints)
      .filter((point) => edges.filter((e) => e.hasPoint(point)).length === 2)
      .forEach((point) => {
        if (point.canMergeEdges()) {
          point.mergeEdges()
        }
      })

    this.path = undefined
    this.addSnapshot()
  }

  protected snapPointToAuxiliaryGuideline(
    point: paper.Point,
    direction: paper.Point,
    otherEdges: Edge[]
  ): paper.Point | undefined {
    const sourceStartPoint = point.add(direction.rotate(90))
    const sourceEndPoint = point.add(direction.rotate(-90))
    const sourceLine = new Line2D(sourceStartPoint, sourceEndPoint)

    const otherLines = this.getSurroundingLines(otherEdges)

    const overlappingLines = sourceLine.findOverlappingLines(otherLines, SNAPPING_DISTANCE)

    if (overlappingLines.length === 0) {
      return undefined
    }

    // index 0 is the one with the minimum distance
    const selectedGuideline = overlappingLines[0]
    const directionLine = new Line2D(point, point.add(direction))
    return directionLine.intersect(selectedGuideline) ?? undefined
  }

  /**
   * Pops the current stack before applying some operations.
   *
   * TODO This is heavily used to display the current state while dragging/moving and is very inefficient.
   *  Most drawing code calls this to undo the previous rendering and then re-calculates the current state with the
   *  given input.
   */
  private revert(): void {
    this.path = undefined

    const lastSnapShot = this.history.peekLastSnapshot()
    this.mesh.points = lastSnapShot.points
  }

  public finalize(): void {
    const lastSnapShot = this.history.peekLastSnapshot()
    const previousNumberOfInvalidEdges = lastSnapShot.updateEdgeValidity()
    const currentNumberOfInvalidEdges = this.mesh.updateEdgeValidity()

    if (previousNumberOfInvalidEdges < currentNumberOfInvalidEdges) {
      this.revert()
      this.modelChanged.next()
    } else {
      // Don't call addSnapshot to fire events here,
      // as in finalize, the model doesn't actually change anymore if everything is valid.
      this.history.addSnapshot(this.mesh)
      this.history.historyChanged.next()
    }
  }

  // ===================
  // === UNDO / REDO ===
  // ===================

  /**
   * Un-dos the last operation and returns the new target for selection
   */
  public undo(): void {
    const undo = this.history.getSnapshotToUndo()

    this.mesh.points = undo.points
    this.path = undefined
    this.modelChanged.next()
    this.history.historyChanged.next()
  }

  public hasRedoAction(): boolean {
    return this.history.hasRedoActions()
  }

  /**
   * Re-dos the last operation and returns the new target for selection
   */
  public redo(): void {
    const redo = this.history.getSnapshotToRedo()
    if (redo) {
      this.mesh.points = redo.points
      this.path = undefined
      this.modelChanged.next()
      this.history.historyChanged.next()
    }
  }

  // ==================
  // === SERIALIZER ===
  // ==================

  public getSerializedMeshes(): string {
    return this.history.serialize(this.mesh)
  }

  /**
   * Loads the mesh of the model from the history
   * @param resetHistory if true, the history will be replaced with a new one
   * @param mergePoints if true, points that can be merged are merged after loading the mesh (used on the cycle page)
   */
  public loadSerializedMeshes(
    serializedMesh: string,
    resetHistory: boolean = true,
    mergePoints: boolean = false
  ): void {
    this.path = undefined

    this.mesh = this.history.deserialize(serializedMesh)

    // edges that can be merged are merged
    if (mergePoints) {
      this.mesh.points.forEach((p) => {
        if (p.canMergeEdges()) {
          p.mergeEdges()
        }
      })
    }

    if (resetHistory) {
      this.history = this.createUndoRedoHistory()
    }

    this.modelChanged.next()
  }

  // ===============
  // === PRIVATE ===
  // ===============

  private addSnapshot(): void {
    this.history.addSnapshot(this.mesh)
    this.modelChanged.next()
    this.history.historyChanged.next()
  }

  private rotateEdge(
    currentAngleVertex: MeshPoint,
    movedEndPoint: MeshPoint,
    rotationDegree: number
  ): void {
    const angleEdges = Array.from(currentAngleVertex.edges)
    const rotatedEdge = angleEdges.find(
      (edge) =>
        edge.getOtherPoint(currentAngleVertex).x === movedEndPoint?.x &&
        edge.getOtherPoint(currentAngleVertex).y === movedEndPoint.y
    )
    if (rotatedEdge) {
      if (rotatedEdge.getOtherPoint(currentAngleVertex).edges.size > 1) {
        return
      }

      let destination: paper.Point
      let originalAngle: number | undefined
      let shouldRotateOuterSide = true
      if (angleEdges.length === 2) {
        const neighbour = angleEdges.find((e) => !e.isSameEdge(rotatedEdge))
        if (!neighbour) {
          return
        }

        originalAngle = neighbour.getAngle(rotatedEdge)
        if (!originalAngle) {
          return
        }

        // new position of the mesh point is calculated from the outer corners to keep the wall length intact
        let angleCorner = calculateOuterCornerPoint(currentAngleVertex)
        if (!angleCorner) {
          return
        }

        let endCorner = getDestinationOuter(
          {
            anchor: currentAngleVertex,
            destination: movedEndPoint,
            edge: neighbour,
            thickness: rotatedEdge.thickness,
          },
          angleCorner
        )

        if (!endCorner) {
          return
        }

        const newAngle = Math.abs(originalAngle + rotationDegree)
        shouldRotateOuterSide =
          rotatedEdge.thickness === neighbour.thickness ||
          newAngle > 180 ||
          newAngle < 180 - SNAP_ANGLE_INTERVAL / 2

        if (shouldRotateOuterSide) {
          let thicknessCorrectionVector: paper.Point
          if (Math.abs(originalAngle + rotationDegree) > 180) {
            // if the angle is bigger than 180° we need to calculate the new outer corner
            // new outer corner will be the on the inner side of the edge which is not moved
            const neighborThicknessVector = neighbour
              .getOtherPoint(currentAngleVertex)
              .subtract(currentAngleVertex)
              .rotate(originalAngle < 0 ? -90 : 90)
              .normalize(neighbour.thickness)
            thicknessCorrectionVector = movedEndPoint
              .subtract(currentAngleVertex)
              .rotate(originalAngle < 0 ? -90 : 90)
              .normalize(rotatedEdge.thickness / 2)
            const newInnerAngleCorner = angleCorner.clone()
            angleCorner = angleCorner.add(neighborThicknessVector)
            endCorner = angleCorner.add(endCorner.subtract(newInnerAngleCorner))
          } else {
            thicknessCorrectionVector = movedEndPoint
              .subtract(currentAngleVertex)
              .rotate(originalAngle < 0 ? 90 : -90)
              .normalize(rotatedEdge.thickness / 2)
          }

          if (!endCorner) {
            return
          }

          const destinationCorner = endCorner?.rotate(rotationDegree, angleCorner)
          const rotatedThicknessVector = thicknessCorrectionVector.rotate(rotationDegree)
          destination = destinationCorner.add(rotatedThicknessVector)
        } else {
          destination = new paper.Point(movedEndPoint.rotate(rotationDegree, currentAngleVertex))
        }
      } else {
        // if the edge is connected to more than one other edge we cannot calculate corner points
        // we also have to remove the rotatedEdge from the edgeSet -> clone to bare Point instance
        destination = new paper.Point(movedEndPoint.rotate(rotationDegree, currentAngleVertex))
      }

      this.mesh.removeEdge(rotatedEdge, !shouldRotateOuterSide)
      this.createEdgeInMesh(
        currentAngleVertex,
        destination,
        rotatedEdge.thickness,
        true,
        shouldRotateOuterSide,
        rotatedEdge
      )
    }
  }

  // documentation: https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/4283/Technical-Documentation?anchor=moveedges
  private moveEdges(
    edgesToMove: Edge[],
    moveVector: paper.Point,
    skipSnapping: boolean = false
  ): Edge[] {
    // if all edges of the mesh are moved, the edges are simply set to the new position, no snapping is needed
    if (edgesToMove.length === this.getAllEdges().length) {
      ;[...this.mesh.points]
        .filter((p) => edgesToMove.some((e) => e.startPoint.equals(p) || e.endPoint.equals(p)))
        .forEach((p) => {
          const updatedPoint = p.add(moveVector)
          p.set(updatedPoint)
        })
      return edgesToMove
    }

    const movingPoints = this.detachEdgeGroup(edgesToMove)
    const detachedEdges: Edge[] = []
    movingPoints.forEach((p) => {
      ;[...p.edges].forEach((e) => {
        if (!detachedEdges.includes(e)) {
          detachedEdges.push(e)
        }
      })
    })

    const movedEdges = detachedEdges.filter((e) => edgesToMove.some((other) => other.isSameEdge(e)))

    // only snap to edges that are not moved
    const otherEdges = this.getAllEdges().filter(
      (e) => !movedEdges.some((other) => other.isSameEdge(e))
    )
    let snapResult: SnapResult
    let snapVector: paper.Point | undefined
    let snappingDistance = Number.MAX_VALUE
    let hasSnappedToMeshPointGlobal = false

    let guidelineSnapVector: paper.Point | undefined
    let guidelineSnapDistance = Number.MAX_VALUE
    let directionCount = -1

    if (!skipSnapping) {
      movingPoints.forEach((point) => {
        const movedPoint = point.add(moveVector)
        let guidelineSnapResult: [paper.Point, number] | undefined

        const currentSnapResult: SnapResult =
          this.snapToEdgeOrMeshPoint(movedPoint, point, otherEdges) ??
          this.snapToExtendedEdgeLines(movedPoint, point, otherEdges)

        // ensuring we are not snapping to moved mesh points
        const hasSnappedToMovedPoint =
          currentSnapResult instanceof MeshPoint &&
          movingPoints.has(currentSnapResult) &&
          currentSnapResult !== point

        if (currentSnapResult && !hasSnappedToMovedPoint) {
          const hasSnappedToMeshPoint =
            !!(isExtendedMeshPoint(currentSnapResult) && currentSnapResult.staticPoint) ||
            (!isExtendedMeshPoint(currentSnapResult) && currentSnapResult instanceof MeshPoint)

          // if snapped to an edge or extended edge line, snapping to guidelines is possible
          if (!hasSnappedToMeshPoint) {
            currentSnapResult.set(
              this.snapToGuideline(currentSnapResult, point, otherEdges, false)[0]
            )
            if (isExtendedMeshPoint(currentSnapResult)) {
              currentSnapResult.insertionPoint.set(
                this.snapToGuideline(currentSnapResult.insertionPoint, point, otherEdges, false)[0]
              )
            }
          }

          const distance = movedPoint.getDistance(currentSnapResult)
          const isMoreImportant =
            (!hasSnappedToMeshPointGlobal &&
              (hasSnappedToMeshPoint || distance < snappingDistance)) ||
            (hasSnappedToMeshPointGlobal && hasSnappedToMeshPoint && distance < snappingDistance)

          // snapping to mesh points has a bigger priority than snapping to edges
          if (isMoreImportant) {
            snapResult = currentSnapResult
            snappingDistance = distance
            hasSnappedToMeshPointGlobal = hasSnappedToMeshPoint
            snapVector = snapResult.subtract(point)
          }
        } else {
          // if snapping to a mesh point or an edge wasn't possible, we can still snap to guidelines
          guidelineSnapResult = this.snapToGuideline(movedPoint, point, otherEdges)
          const distance = guidelineSnapResult[0].getDistance(movedPoint)
          if (
            guidelineSnapResult[1] > directionCount ||
            (guidelineSnapResult[1] === directionCount && distance <= guidelineSnapDistance)
          ) {
            guidelineSnapDistance = distance
            guidelineSnapVector = guidelineSnapResult[0].subtract(point)
            directionCount = guidelineSnapResult[1]
          }
        }
      })
    }

    const correctedMoveVector = snapVector ?? guidelineSnapVector ?? moveVector

    // Move all points of the selected edges by the correctedMoveVector
    movingPoints.forEach((point) => {
      const updatedPoint = point.add(correctedMoveVector)
      point.set(updatedPoint)
    })

    // processing the snapping result
    // documentation: https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/4283/Technical-Documentation?anchor=extendedmeshpoints
    if (snapResult instanceof ExtendedMeshPoint) {
      if (snapResult.staticPoint) {
        snapResult.staticPoint.set(snapResult.insertionPoint)
        snapResult.movedPoint.set(snapResult.insertionPoint)
      } else {
        snapResult.movedPoint.set(snapResult.insertionPoint)
      }
    }

    this.updatedEdgesAfterMove(detachedEdges)
    return movedEdges
  }

  /**
   * snaps a point to guidelines
   * @param movedPoint - the position that should be snapped to guidelines
   * @param originalPoint - the original meshpoint that is being moved
   * @param otherEdges - edges that the point should be snapped to
   * @param snapFrontSide - determines whether only the sides or the front side of the edge too should be snapped
   * @returns the snapped point and the number of guidelines it snapped to
   */
  private snapToGuideline(
    movedPoint: paper.Point,
    originalPoint: MeshPoint,
    otherEdges: Edge[],
    snapFrontSide: boolean = true
  ): [paper.Point, number] {
    // point with new position - without snapping
    const edge = [...originalPoint.edges][0]

    // try to snap to an auxiliary guideline
    const direction = edge.getDirection().normalize()
    const halfThickness = edge.thickness / 2
    const leftVector = direction.rotate(90)
    const leftVectorHalfThickness = leftVector.normalize(halfThickness)
    const rightVector = direction.rotate(-90)
    const rightVectorHalfThickness = rightVector.normalize(halfThickness)

    let sideSnappingVector: paper.Point | undefined
    const frontFacingSnappingVector = snapFrontSide
      ? this.snapPointToAuxiliaryGuideline(movedPoint, direction, otherEdges)?.subtract(movedPoint)
      : null

    const leftSnappingPoint = this.snapPointToAuxiliaryGuideline(
      movedPoint.add(leftVectorHalfThickness),
      leftVector,
      otherEdges
    )

    const rightSnappingPoint = this.snapPointToAuxiliaryGuideline(
      movedPoint.add(rightVectorHalfThickness),
      rightVector,
      otherEdges
    )

    let leftSnappingVector, rightSnappingVector
    let leftDistance, rightDistance
    if (leftSnappingPoint) {
      leftSnappingVector = leftSnappingPoint.subtract(leftVectorHalfThickness).subtract(movedPoint)
      leftDistance = leftSnappingVector.length
    }
    if (rightSnappingPoint) {
      rightSnappingVector = rightSnappingPoint
        .subtract(rightVectorHalfThickness)
        .subtract(movedPoint)
      rightDistance = rightSnappingVector.length
    }

    if (leftSnappingVector && rightSnappingVector && leftDistance && rightDistance) {
      sideSnappingVector = leftDistance < rightDistance ? leftSnappingVector : rightSnappingVector
    } else {
      sideSnappingVector = leftSnappingVector ?? rightSnappingVector
    }

    const vectorArray = [frontFacingSnappingVector, sideSnappingVector].filter(
      (p) => p != null
    ) as paper.Point[]
    const summedVector = vectorArray.reduce((prev, curr) => prev.add(curr), new paper.Point(0, 0))

    return [movedPoint.add(summedVector), vectorArray.length]
  }

  /**
   * snaps a point to its edges extended lines
   * @param movedPoint - the position used for snapping
   * @param originalPoint - the original mesh point that is being moved
   * @param edges - edges that the point should be snapped to
   */
  private snapToExtendedEdgeLines(
    movedPoint: paper.Point,
    originalPoint: MeshPoint,
    edges: Edge[]
  ): paper.Point | undefined {
    if (originalPoint.edges.size > 1) {
      let extensionResult: paper.Point | undefined
      let extensionDistance = Infinity
      // projecting onto extended lines of edges
      Array.from(originalPoint.edges)
        .filter((e) => edges.includes(e))
        .forEach((e) => {
          const projectedMoveVector = movedPoint.subtract(originalPoint).project(e.getDirection())
          const snappedDestination = getLengthSnappedPoint(
            originalPoint.add(projectedMoveVector),
            e.getOtherPoint(originalPoint),
            this.drawSetting.lengthRastering
          )
          const distance = snappedDestination.subtract(movedPoint).length
          if (distance < extensionDistance && distance < SNAPPING_DISTANCE) {
            extensionResult = snappedDestination
            extensionDistance = distance
          }
        })
      return extensionResult
    }
    return undefined
  }

  protected mergePoints(point1: MeshPoint, point2: MeshPoint): void {
    const edgesToBeRemoved: Edge[] = []

    point2.edges.forEach((edge) => {
      this.mesh.setEdge(point1, edge.getOtherPoint(point2), edge.thickness)
      edgesToBeRemoved.push(edge)
    })

    edgesToBeRemoved.forEach((edge) => this.mesh.removeEdge(edge, true))
  }

  /**
   * detaches an array of edges from the edges that are not in the edge group
   * @param edgeGroup to be detached, edges within the group should stay together
   * @returns a set of mesh points that belong to the edge group after detaching
   */
  private detachEdgeGroup(edgeGroup: Edge[]): Set<MeshPoint> {
    const pointsToDetach = new Set<[MeshPoint, Edge]>()
    const updatedPoints = new Set<MeshPoint>()
    edgeGroup.forEach((edge) => {
      if (edge.startPoint.canMergeEdges(true) || edge.startPoint.edges.size > 2) {
        pointsToDetach.add([edge.startPoint, edge])
      } else {
        updatedPoints.add(edge.startPoint)
      }

      if (edge.endPoint.canMergeEdges(true) || edge.endPoint.edges.size > 2) {
        pointsToDetach.add([edge.endPoint, edge])
      } else {
        updatedPoints.add(edge.endPoint)
      }
    })

    pointsToDetach.forEach(([point, edge]) => {
      const detachedPoint = point.detachFromEdge(edge)
      if (detachedPoint) {
        if (point.edges.size === 0) {
          this.mesh.points.delete(point)
        }
        this.mesh.points.add(detachedPoint)
        updatedPoints.add(detachedPoint)
      }
    })

    // merge the original mesh point after detachment if possible
    pointsToDetach.forEach(([point]) => {
      if (point.canMergeEdges()) {
        point.mergeEdges()
      }
    })

    // detached meshpoints which have the same position are merged again
    updatedPoints.forEach((p1) => {
      ;[...updatedPoints]
        .filter((p) => p !== p1)
        .forEach((p2) => {
          if (p1.isClose(p2, 0)) {
            this.mergePoints(p1, p2)
            updatedPoints.delete(p2)
          }
        })
    })
    return updatedPoints
  }

  private movePoint(
    point: MeshPoint, // point to be moved
    destination: paper.Point // destination of the point
  ): MeshPoint {
    const edgeInfos: {
      startPoint: paper.Point
      endPoint: paper.Point
      thickness: number
      edge: Edge
    }[] = []

    // projecting onto edge lines
    let snapResult: paper.Point | undefined
    let snappingDistance = Number.MAX_VALUE
    if (point.edges.size > 1) {
      Array.from(point.edges).forEach((e) => {
        // projecting onto edge
        const moveVector = destination.subtract(point)
        const projectedMoveVector = moveVector.project(e.getDirection())

        const snappedDestination = getLengthSnappedPoint(
          point.add(projectedMoveVector),
          e.getOtherPoint(point),
          this.drawSetting.lengthRastering
        )

        const distance = snappedDestination.subtract(destination).length
        if (distance < snappingDistance && distance < SNAPPING_DISTANCE) {
          snapResult = snappedDestination
          snappingDistance = distance
        }
      })
    }

    Array.from(point.edges).map((edge) => {
      const clonedEdge = new Edge(
        edge.getOtherPoint(point).clone(true),
        point.clone(true),
        edge.thickness
      )

      edgeInfos.push({
        startPoint: edge.getOtherPoint(point),
        endPoint: point,
        thickness: edge.thickness,
        edge: clonedEdge,
      })
    })

    if (!snapResult) {
      point.edges.forEach((edge) => {
        this.mesh.removeEdge(edge, false)
      })
    }

    point.set(snapResult ?? destination)
    let newPoint = point

    edgeInfos.forEach((info) => {
      this.createEdgeInMesh(
        info.startPoint,
        info.endPoint,
        info.thickness,
        edgeInfos.length > 1,
        true,
        info.edge
      )

      newPoint = info.edge.endPoint
    })

    return newPoint
  }

  protected insertPointOnEdge(point: paper.Point, edge: Edge): MeshPoint {
    const insertedPoint = new MeshPoint(point, this.mesh)
    this.mesh.points.add(insertedPoint)

    let thickness = this.drawSetting.wallThickness
    if (edge !== undefined) {
      thickness = edge.thickness
    }

    this.mesh.setEdge(edge.startPoint, insertedPoint, thickness)
    this.mesh.setEdge(insertedPoint, edge.endPoint, thickness)
    this.mesh.removeEdge(edge, true)

    return insertedPoint
  }
}
