import { Injectable } from '@angular/core'
import { AlertController, AlertInput } from '@ionic/angular'
import JSZip from 'jszip'
import {
  ACCESSORY_LINES_FILE_NAME,
  CHANGED_PART_LIST_FILE_NAME,
  CYCLE_BOUNDARIES_FILE_NAME,
  CYCLE_SYMBOLS_FILE_NAME,
  FAVOURITES_BLACKLIST_ARTICLES,
  FAVOURITES_PROFILE_FILE_NAME,
  PLAN_FOLDER,
  PLAN_JSON_FILE_NAME,
  PLAN_OUTLINE_JSON_FILE_NAME,
  PLAN_SETTINGS_JSON_FILE_NAME,
  PROJECT_XML_FILE_NAME,
  PROTOCOL_JSON_FILE_NAME,
  RESULT_JSON_FILE_NAME,
  RESULT_PNG_FILE_NAME,
  RESULT_THUMBNAIL_PNG_FILE_NAME,
  RESULT_XML_FILE_NAME,
  SCREENSHOTS_FILE_NAME,
  STOCK_FILE_NAME,
  VERSION_FILE_NAME,
} from '../../constants/files'
import { SHARE_ZIP_VERSION } from '../../constants/versions'
import { Part, PartList } from '../../models/part'
import { Plan } from '../../models/plan'
import { PlanAccessoryLine } from '../../models/plan/PlanAccessoryLine'
import { AccessoryService } from '../../pages/accessory/services/accessory.service'
import { PartListItem } from '../../pages/result/components/part-list/model/partListItem'
import { CycleRepository } from '../../repositories/cycle.repository'
import { FavouriteRepository } from '../../repositories/favourite.repository'
import { PlanOutlineRepository } from '../../repositories/plan-outline.repository'
import { PlanResultRepository } from '../../repositories/plan-result.repository'
import { PlanSettingsRepository } from '../../repositories/plan-settings.repository'
import { StockRepository } from '../../repositories/stock.repository'
import { FileService } from '../file.service'
import { PartListService } from '../part-list-service'
import { ScreenshotService } from '../screenshot.service'
import { TiposXmlService } from '../tipos/tipos-xml.service'
import { Translation } from '../translation.service'
import { ResultZip } from '../../models/resultZip'
import { EFPZipVersions } from './efp-import.service'
import {
  ApplicationInsightsService,
  ApplicationInsightsOrderShareStates,
} from '../applicationInsights.service'
import { BlacklistArticleRepository } from '../../repositories/blacklist-article.repository'
import { AccDataService } from '../acc-data.service'
import { ProjectRepository } from '../../repositories/project.repository'
import { GeneratePDFService } from '../generate-pdf.service'
import { PlanSettingsService } from '../plan-settings.service'
import { AuthenticationService } from '../authentication.service'
import { ACCAuthenticationService } from '../acc-auth.service'

@Injectable({
  providedIn: 'root',
})
export class EfpExportService {
  public isLoading = false

  constructor(
    private readonly fileService: FileService,
    private readonly accessoryService: AccessoryService,
    private readonly cycleRepository: CycleRepository,
    private readonly partListService: PartListService,
    private readonly translation: Translation,
    private readonly planResultRepository: PlanResultRepository,
    private readonly alertController: AlertController,
    private readonly screenshotService: ScreenshotService,
    private readonly tiposXmlService: TiposXmlService,
    private readonly planOutlineRepository: PlanOutlineRepository,
    private readonly planSettingsRepository: PlanSettingsRepository,
    private readonly favouriteRepository: FavouriteRepository,
    private readonly stockRepository: StockRepository,
    private readonly appInsightsService: ApplicationInsightsService,
    private readonly blacklistArticleRepository: BlacklistArticleRepository,
    private readonly accDataService: AccDataService,
    private readonly projectRepo: ProjectRepository,
    private readonly generatePDFService: GeneratePDFService,
    private readonly planSettingsService: PlanSettingsService,
    private readonly authService: AuthenticationService,
    private readonly accAuthService: ACCAuthenticationService
  ) {}

  public async showShareDialog(plan: Plan): Promise<void> {
    const inputs: AlertInput[] = []

    let partList: PartList | undefined
    try {
      this.isLoading = true
      partList = await this.planResultRepository.getArticleListWithCycleUsageForPlan(plan.id)
    } catch (e: unknown) {
      const msg = e instanceof Error ? e.message : JSON.stringify(e)
      console.error('EfpExportService.showShareDialog - Error while loading part list', msg)
      return
    } finally {
      this.isLoading = false
    }

    const isCalculated = await this.planResultRepository.getIsCalculated(plan.id)
    if (isCalculated) {
      // create PDF
      inputs.push({
        type: 'radio',
        handler: () => {
          void alert.dismiss()
          this.screenshotService.generatePDF([], plan.id)
        },
        label: this.translation.translate('PLAN.SHARE_PDF'),
        cssClass: 'share-option share-option-pdf',
      })
    }

    // export plan.efp
    inputs.push({
      type: 'radio',
      handler: () => {
        void alert.dismiss()
        this.isLoading = true
        void this.shareZip(plan)
        this.appInsightsService.addUserEvent(
          ApplicationInsightsOrderShareStates.PLAN_SHARED,
          plan.id
        )
        this.appInsightsService.addUserEvent(
          ApplicationInsightsOrderShareStates.PLAN_SHARED,
          plan.id
        )
      },
      label: this.translation.translate('PLAN.SHARE_ZIP'),
      cssClass: 'share-option share-option-zip',
    })

    const partListToUnwrapped = partList
    if (partListToUnwrapped !== undefined) {
      inputs.push({
        type: 'radio',
        handler: () => {
          void alert.dismiss()
          void this.showPieceListDialog(plan, partListToUnwrapped)
        },
        label: this.translation.translate('PLAN.SHARE_PIECE_LIST'),
        cssClass: 'share-option share-option-csv',
      })
    }

    const alert = await this.alertController.create({
      cssClass: ['alertStyle', 'projectOverviewAlertDelete'],
      header: this.translation.translate('PLAN.SHARE'),
      backdropDismiss: true,
      inputs,
      buttons: [
        {
          text: this.translation.translate('GENERAL.CANCEL'),
          role: 'cancel',
        },
      ],
    })
    await alert.present()
  }

  // own stock subtracted
  private exportNeededCsv(plan: Plan, partList: PartList): void {
    const neededStock: Part[] = []
    partList.parts.forEach((part) => {
      const calcPart = part
      calcPart.amount = part.demand > part.stock ? part.demand - part.stock : 0
      neededStock.push(calcPart)
    })

    void this.shareResultArticlesCsv(plan, neededStock, 'SHARE_CSV_NEEDED_NAME', undefined, false)
  }

  public async showPieceListDialog(plan: Plan, partList: PartList): Promise<void> {
    const inputs: AlertInput[] = []
    inputs.push({
      type: 'radio',
      handler: () => {
        void alert.dismiss()
        const demand = this.prepareExportCalculatedCsc(partList)
        this.exportCalculatedCsv(plan, demand)
        this.appInsightsService.addUserEvent(
          ApplicationInsightsOrderShareStates.PIECELIST_SHARED_RESULT,
          plan.id
        )
      },
      label: this.translation.translate('PLAN.SHARE_CSV_RESULT'),
      cssClass: 'share-option share-option-csv',
    })

    // Export piece list without own stock
    if (plan.stockId !== null) {
      inputs.push({
        type: 'radio',
        handler: () => {
          void alert.dismiss()
          this.exportNeededCsv(plan, partList)
          this.appInsightsService.addUserEvent(
            ApplicationInsightsOrderShareStates.PIECELIST_SHARED_NEEDED,
            plan.id
          )
        },
        label: this.translation.translate('PLAN.SHARE_CSV_NEEDED'),
        cssClass: 'share-option share-option-csv',
      })
    }

    // Export revised piece list
    inputs.push({
      type: 'radio',
      handler: () => {
        void alert.dismiss()
        void this.exportRevisedCsv(plan, partList)
        this.appInsightsService.addUserEvent(
          ApplicationInsightsOrderShareStates.PIECELIST_SHARED_CHANGED,
          plan.id
        )
      },
      label: this.translation.translate('PLAN.SHARE_CSV_CHANGED'),
      cssClass: 'share-option share-option-csv',
    })

    // Export piece list by cycles
    if (partList.usedCycleNumbers.size > 1) {
      inputs.push({
        type: 'radio',
        handler: () => {
          const cycleCheckboxes: AlertInput[] = []

          for (const cycleNumber of partList.usedCycleNumbers) {
            cycleCheckboxes.push({
              type: 'radio',
              label: this.translation
                .translate('CYCLE.SINGLE')
                .replace('{{cycleNumber}}', cycleNumber.toString()),
              value: cycleNumber,
            })
          }

          // all cycles
          cycleCheckboxes.push({
            type: 'radio',
            label: this.translation.translate('PLAN.SHARE_ALL_CYCLES'),
            value: -1,
          })

          void this.alertController
            .create({
              cssClass: ['alertStyle', 'projectOverviewAlertDelete'],
              header: this.translation.translate('PLAN.SHARE'),
              backdropDismiss: true,
              inputs: cycleCheckboxes,
              buttons: [
                {
                  text: this.translation.translate('GENERAL.CANCEL'),
                  role: 'cancel',
                },
                {
                  text: this.translation.translate('GENERAL.OK'),
                  handler: async (data: number) => {
                    if (data !== -1) {
                      partList.parts.forEach((part) => {
                        part.amount = part.cycleUsage[data]
                      })
                      await this.shareResultArticlesCsv(plan, partList.parts, 'CYCLE', data, false)
                      this.appInsightsService.addUserEvent(
                        ApplicationInsightsOrderShareStates.PIECELIST_SHARED_CYCLES,
                        plan.id
                      )
                    } else {
                      await this.shareResultArticlesCsv(
                        plan,
                        partList.parts,
                        'SHARE_ALL_CYCLES',
                        undefined,
                        false,
                        partList.usedCycleNumbers
                      )
                      this.appInsightsService.addUserEvent(
                        ApplicationInsightsOrderShareStates.PIECELIST_SHARED_CYCLES,
                        plan.id
                      )
                    }
                    void alert.dismiss()
                  },
                },
              ],
            })
            .then(async (cycleAlert) => {
              await cycleAlert.present()
            })
        },
        label: this.translation.translate('PLAN.SHARE_CSV_CYCLE'),
        cssClass: 'share-option share-option-csv',
      })
    }

    const alert = await this.alertController.create({
      cssClass: ['alertStyle', 'projectOverviewAlertDelete'],
      header: this.translation.translate('PLAN.SHARE_PIECE_LIST'),
      backdropDismiss: true,
      inputs,
      buttons: [
        {
          text: this.translation.translate('GENERAL.CANCEL'),
          role: 'cancel',
        },
      ],
    })
    await alert.present()
  }

  private prepareExportCalculatedCsc(partList: PartList): Part[] {
    const stockDemand: Part[] = []
    partList.parts.forEach((part) => {
      const calcPart = part
      calcPart.amount = part.demand
      stockDemand.push(calcPart)
    })
    return stockDemand
  }

  // calculated amounts
  private exportCalculatedCsv(plan: Plan, demand: Part[]): void {
    void this.shareResultArticlesCsv(plan, demand, 'SHARE_CSV_RESULT_NAME', undefined, false)
  }

  // user edited amounts
  private async exportRevisedCsv(plan: Plan, partList: PartList): Promise<void> {
    const partsWithoutZero = partList.parts.filter((part) => part.amount > 0) // we want only the parts which are changed to zero, not all zero parts

    this.isLoading = true
    try {
      const partListWithChangedAmounts = await this.partListService.updatePartListForPlan(
        plan.id,
        partList.parts
      )
      const changedPartList = partListWithChangedAmounts.filter(
        (part) => part.amount !== 0 || partsWithoutZero.find((p) => p.articleId === part.articleId)
      ) // filtering parts that were zero and didn't change
      await this.shareResultArticlesCsv(
        plan,
        changedPartList,
        'SHARE_CSV_CHANGED_NAME',
        undefined,
        true
      )
      this.isLoading = false
    } catch (e: unknown) {
      this.isLoading = false
    }
  }

  private async shareResultArticlesCsv(
    plan: Plan,
    parts: Part[],
    translation: string,
    cycleNumber: number | undefined,
    showZeroAmount: boolean,
    includeAllCycles?: Set<number>
  ): Promise<void> {
    let fileName: string

    if (cycleNumber && !includeAllCycles) {
      fileName = this.translation.translate('VIEWER.CYCLE') + '_' + cycleNumber + '.csv'
    } else if (includeAllCycles) {
      fileName = this.translation.translate('PLAN.' + translation) + '.zip'
    } else {
      fileName = this.translation.translate('PLAN.' + translation) + '.csv'
    }

    fileName = plan.name + '_' + fileName
    const localUrl = `${PLAN_FOLDER}/${plan.id}/` + fileName

    if (!includeAllCycles) {
      const cycleIndex = cycleNumber ? cycleNumber - 1 : undefined
      const csvString = await this.buildArticleCsv(parts, showZeroAmount, cycleIndex)

      await this.fileService.shareOrDownloadTextFile(csvString, localUrl, fileName)
    } else {
      const zip = new JSZip()
      for (const usedCycleNumber of includeAllCycles) {
        const cycleIndex = usedCycleNumber - 1
        const cycleParts = parts.filter((part) => part.cycleUsage[cycleIndex] > 0)
        const cycleCSV = await this.buildArticleCsv(cycleParts, showZeroAmount, cycleIndex)
        zip.file(
          this.translation.translate('VIEWER.CYCLE') + '_' + usedCycleNumber.toString() + '.csv',
          cycleCSV
        )
      }

      const zipContent = await zip.generateAsync({ type: 'blob' })
      await this.fileService.shareOrDownloadBlobFile(zipContent, localUrl, fileName)
    }
  }

  public async buildArticleCsv(
    parts: Part[],
    showZeroAmount: boolean,
    cycleIndex?: number
  ): Promise<string> {
    let result = '' + this.translation.translate('EXPORT.ARTICLE_CSV.ARTICLE_ID.HEADER') + ';'
    result +=
      this.translation.translate('EXPORT.ARTICLE_CSV.AMOUNT.HEADER') +
      ';' +
      this.translation.translate('EXPORT.ARTICLE_CSV.NAME.HEADER') +
      ';' +
      '\n'

    parts.forEach((part) => {
      const amount = cycleIndex !== undefined ? part.cycleUsage[cycleIndex] : part.amount
      if (amount > 0 || (showZeroAmount && amount >= 0)) {
        result += `${part.articleId};${amount};${part.name};\n`
      }
    })

    return result
  }

  // also used by Feedback function
  // eslint-disable-next-line complexity
  public async generateZip(plan: Plan): Promise<Blob> {
    const planSettings = await this.planSettingsRepository.findOneById(plan.settingsId)

    if (!planSettings) {
      throw new Error('EfpExportService.generateZip - Plan settings not found')
    }
    const planOutline = await this.planOutlineRepository.findAllOutlinesByPlanId(plan.id)
    const planData = JSON.stringify(plan)
    const planSettingsData = JSON.stringify(planSettings)

    const zip = new JSZip()
    zip.file(PLAN_JSON_FILE_NAME, planData)
    zip.file(PLAN_SETTINGS_JSON_FILE_NAME, planSettingsData)
    zip.file(PLAN_OUTLINE_JSON_FILE_NAME, JSON.stringify(planOutline))

    const tiposXML = await this.tiposXmlService.generateXmlForPlan(plan)
    if (tiposXML) {
      zip.file(PROJECT_XML_FILE_NAME, tiposXML)
    }

    /// Add version file
    const version: EFPZipVersions = { version: SHARE_ZIP_VERSION }
    zip.file(VERSION_FILE_NAME, JSON.stringify(version))

    /// Add used stock, if existing in the plan
    if (plan.stockId != null) {
      const stock = await this.stockRepository.loadByIdWithArticles(plan.stockId)
      const stockData = JSON.stringify(stock)
      zip.file(STOCK_FILE_NAME, stockData)
    }

    /// Add favourites
    const favouriteId =
      plan.buildingType === 'WALL' ? planSettings.wallFavId : planSettings.slabFavId
    if (favouriteId != null) {
      const favouriteProfile = await this.favouriteRepository.findOneById(favouriteId)
      if (!favouriteProfile) {
        throw new Error('EfpExportService.generateZip - Favourite profile not found')
      }
      const favouritesData = JSON.stringify(favouriteProfile)
      zip.file(FAVOURITES_PROFILE_FILE_NAME, favouritesData)

      const blacklistArticles = await this.blacklistArticleRepository.findAllByFavouriteProfileId(
        favouriteProfile.id
      )
      const blacklistData = JSON.stringify(blacklistArticles)
      zip.file(FAVOURITES_BLACKLIST_ARTICLES, blacklistData)
    }

    /// Add accessory data, if existing in the plan
    const accessoryLines: PlanAccessoryLine[] =
      await this.accessoryService.loadAccessoryLinesForPlanAndFormwork(
        plan.id,
        planSettings.formworkWall
      )
    if (accessoryLines.length > 0) {
      zip.file(ACCESSORY_LINES_FILE_NAME, JSON.stringify(accessoryLines))
    }

    // Add cycleBoundaries, if existing in the plan
    const cycleBoundaries = await this.cycleRepository.findAllCycleBoundariesByPlanId(plan.id)
    if (cycleBoundaries != null && cycleBoundaries.length > 0) {
      // cast type CycleBoundaryDrawable to CycleBoundary; otherwise errors with Point2D in import
      const newCyleBoundaries = cycleBoundaries.map((boundary) => {
        return {
          cycleNumberLeft: boundary.cycleNumberLeft,
          cycleNumberRight: boundary.cycleNumberRight,
          id: boundary.id,
          start: { x: boundary.start.x, y: boundary.start.y },
          end: { x: boundary.end.x, y: boundary.end.y },
        }
      })

      const cycleBoundaryData = JSON.stringify(newCyleBoundaries)
      zip.file(CYCLE_BOUNDARIES_FILE_NAME, cycleBoundaryData)
    }

    // Add default cycleSymbols, if existing in the plan
    const cycleSymbols = await this.cycleRepository.findAllCycleSymbolsByPlanId(plan.id)
    if (cycleSymbols != null && cycleSymbols.length > 0) {
      const cycleSymbolData = JSON.stringify(cycleSymbols)
      zip.file(CYCLE_SYMBOLS_FILE_NAME, cycleSymbolData)
    }

    // Add screenshots, if existing in the plan
    const screenshots = await this.screenshotService.getScreensFromPlan(plan.id)
    if (screenshots != null && screenshots.length > 0) {
      const screenshotsData = JSON.stringify(screenshots)
      zip.file(SCREENSHOTS_FILE_NAME, screenshotsData)
    }

    /// Try to add result data
    const protocolJson = await this.planResultRepository.getResultMessages(plan.id)
    if (protocolJson) {
      zip.file(PROTOCOL_JSON_FILE_NAME, JSON.stringify(protocolJson))
    }

    const articleList = await this.planResultRepository.getArticleListForPlanExport(plan.id)
    if (articleList) {
      const resultZip: ResultZip = {
        partlist: articleList,
        zipfile: '',
      }

      zip.file(RESULT_JSON_FILE_NAME, JSON.stringify(resultZip))
    }

    const resultPNG = await this.planResultRepository.getResultImage(plan.id)

    if (resultPNG) {
      const pngContent = await this.convertBase64ToImage(resultPNG)
      if (pngContent) {
        zip.file(RESULT_PNG_FILE_NAME, pngContent)
      }
    }

    const resultThumbnailPNG = await this.planResultRepository.getResultImage(plan.id, true)

    if (resultThumbnailPNG) {
      const pngContent = await this.convertBase64ToImage(resultThumbnailPNG)
      if (pngContent) {
        zip.file(RESULT_THUMBNAIL_PNG_FILE_NAME, pngContent)
      }
    }

    const resultXml = await this.planResultRepository.getResultXML(plan.id)
    if (resultXml) {
      zip.file(RESULT_XML_FILE_NAME, resultXml)
    }

    const partList = await this.planResultRepository.getArticleListWithCycleUsageForPlan(plan.id)
    if (partList) {
      const changedResultPartList: PartListItem[] =
        await this.partListService.loadChangedResultPartListForPlan(plan.id, partList.parts)
      if (changedResultPartList.length > 0) {
        const changedResultPartListData = JSON.stringify(changedResultPartList)
        zip.file(CHANGED_PART_LIST_FILE_NAME, changedResultPartListData)
      }
    }

    return new Promise((resolve) => {
      void zip.generateAsync({ type: 'blob' }).then(async (content) => {
        resolve(content)
      })
    })
  }

  public getLocalZipUrl(plan: Plan): string {
    return `${PLAN_FOLDER}/${plan.id}/${this.getLocalZipFilename(plan)}`
  }

  private getLocalZipFilename(plan: Plan): string {
    return `${plan.name.replace(/\s/g, '_')}_share.efp`
  }

  private async shareZip(plan: Plan): Promise<void> {
    try {
      const zip = await this.generateZip(plan)
      const localZipUrl = this.getLocalZipUrl(plan)
      await this.fileService.shareOrDownloadBlobFile(
        zip,
        localZipUrl,
        this.getLocalZipFilename(plan)
      )
    } finally {
      this.isLoading = false
    }
  }

  public async uploadDataToACC(plan: Plan): Promise<void> {
    if (this.accAuthService.connectorEnabledSource.value) {
      const project = await this.projectRepo.findOne(plan.projectId, false)
      if (project?.accId && project.accHubId) {
        const EFPWebLink = document.location.href

        const planSettings = await this.planSettingsService.getPlanSettingsAndSetLastUnit(
          plan.settingsId
        )
        const screens = await this.screenshotService.loadData(plan)
        screens.forEach((screen) => (screen.useInPdf = true))

        // PDF & CSV (calculated) Upload
        if (planSettings) {
          const pdfResult = await this.generatePDFService.generatePDF(
            plan,
            planSettings,
            screens,
            true,
            true,
            EFPWebLink
          )

          const partList = await this.planResultRepository.getArticleListWithCycleUsageForPlan(
            plan.id
          )

          if (pdfResult && partList) {
            const base64Pdf = await this.blobToBase64(pdfResult.pdfBlob)

            const demand = this.prepareExportCalculatedCsc(partList)
            const csvString = await this.buildArticleCsv(demand, false, undefined)
            const base64CSV = this.csvToBase64(csvString)

            void this.accDataService.uploadresult(
              'EFP by ' + (await this.authService.getUserName()),
              project.accHubId,
              plan.name,
              project.accId,
              base64Pdf,
              base64CSV
            )
          }
        }
      }
    }
  }

  private async convertBase64ToImage(resultPNG: string): Promise<Uint8Array | undefined> {
    const arr = resultPNG.split(',')

    if (arr.length > 0) {
      // The base64 string might not contain prefix data:image/png;base64,
      const bstr = atob(arr.length === 2 ? arr[1] : arr[0])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return u8arr
    } else {
      return undefined
    }
  }

  private async blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        if (reader.result) {
          const base64String = (reader.result as string).split(',')[1] // Remove the data URL prefix
          resolve(base64String)
        } else {
          reject(new Error('Failed to convert Blob to Base64'))
        }
      }
      reader.onerror = () => {
        reject(new Error('Failed to read Blob'))
      }
    })
  }

  private csvToBase64(csv: string): string {
    // Add UTF-8 BOM
    const bom = '\uFEFF'
    const utf8Csv = bom + csv

    // Encode UTF-8 string to Base64
    const base64Csv = btoa(unescape(encodeURIComponent(utf8Csv)))

    return base64Csv
  }
}
