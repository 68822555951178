/* eslint-disable efp/no-dao-import */
import { Injectable } from '@angular/core'
import { DevicePreferencesService } from '../device-preferences.service'
import { PlanSqlDao } from '../dao/sql/plan.sql-dao'
import { StockSqlDao } from '../dao/sql/stock.sql-dao'
import { FavouriteSqlDao } from '../dao/sql/favourite.sql-dao'
import { ProjectSqlDao } from '../dao/sql/project.sql-dao'
import { PlanResultSqlDao } from '../dao/sql/plan-result.sql-dao'
import { PlanSettingsSqlDao } from '../dao/sql/plan-settings.sql-dao'
import { CycleSqlDao } from '../dao/sql/cycle.sql-dao'
import { PlanOutlineSqlDao } from '../dao/sql/plan-outline.sql-dao'
import { PlanAccessoryLineSqlDao } from '../dao/sql/plan-accessory-line.sql-dao'
import { ChangedResultPartSqlDao } from '../dao/sql/changed-result-part.sql-dao'
import { PlanType } from '../../shared/formwork-planner'
import {
  NavStep,
  PlanType as ApiPlanType,
  UnitOfLength,
  ChangedResultPartsCommandParam,
  CycleBoundaryCreateCommandParam,
  CycleSymbolModel,
  ImportPlanOutlineCommandParam,
  CreatePlanResultCommandParam,
  CreatePlanSettingsCommandParams,
  ScreenshotCommandParams,
  CreateStockCommandParams,
} from '../../../generated/efp-api'
import { ScreenshotSqlDao } from '../dao/sql/screenshot.sql-dao'
import { ModalController } from '@ionic/angular'
import { Translation } from '../translation.service'
import { OLD_FAVOURITES_VERSION } from '../../constants/versions'
import { FavouriteProfile } from '../../models/favourites'
import {
  ModalDismissAction,
  SimpleModalComponent,
} from '../../shared/components/simple-modal/simple-modal.component'
import { BehaviorSubject } from 'rxjs'
import { ModalStyle } from '../../models/modalStyle'
import {
  UploadProgressComponent,
  UploadProperties,
} from '../../shared/upload-progress/upload-progress.component'
import { DataService } from '../data.service'
import { HttpRequestSyncService } from '../http-request-sync.service'
import { StockDao } from '../dao/stock.dao'
import { FavouriteDao } from '../dao/favourite.dao'
import { PlanSettingsDao } from '../dao/plan-settings.dao'
import { ProjectDao } from '../dao/project.dao'
import { PlanCreationDao } from '../dao/plan-creation.dao'
import { CacheService } from '../cache/cache.service'
import { PlanResultMigrationService } from './plan-result-migration.service'
import { Plan } from '../../models/plan'
import { PlanResultResetCalculationSqlDao } from '../dao/sql/plan-result-reset-calculation.sql-dao'
import { PlanSettings } from '../../models/planSettings'
import { ArticleSqlDao } from '../dao/sql/article.sql-dao'
import { Article } from '../../models/article'

export interface IdMatching {
  sqlId: number
  httpId: number
}

@Injectable({
  providedIn: 'root',
})
export class NativeMigrationService {
  public uploadCountFavourites = new BehaviorSubject<number>(0)
  public uploadCountStocks = new BehaviorSubject<number>(0)
  public uploadCountPlans = new BehaviorSubject<number>(0)
  public uploadCountProjects = new BehaviorSubject<number>(0)

  private alreadyUploadedFavIds: Set<IdMatching> = new Set<IdMatching>()
  private alreadyUploadedStocks: Set<IdMatching> = new Set<IdMatching>()

  private localCountFavourites = 0
  private localCountStocks = 0
  private localCountPlans = 0
  private localCountProjects = 0

  private localPlans: Plan[] = []

  constructor(
    private readonly translate: Translation,
    private readonly devicePreferenceService: DevicePreferencesService,
    private readonly dataService: DataService,
    private readonly planSqlDao: PlanSqlDao,
    private readonly stockSqlDao: StockSqlDao,
    private readonly favouritesSqlDao: FavouriteSqlDao,
    private readonly projectSqlDao: ProjectSqlDao,
    private readonly planResultSqlDao: PlanResultSqlDao,
    private readonly planSettingsSqlDao: PlanSettingsSqlDao,
    private readonly cycleSqlDao: CycleSqlDao,
    private readonly planOutlineSqlDao: PlanOutlineSqlDao,
    private readonly planAccessoryLineSqlDao: PlanAccessoryLineSqlDao,
    private readonly changedResultPartSqlDao: ChangedResultPartSqlDao,
    private readonly screenshotSqlDao: ScreenshotSqlDao,
    private readonly planResultResetCalculationSqlDao: PlanResultResetCalculationSqlDao,
    private readonly articleSqlDao: ArticleSqlDao,
    private readonly stockHttpDao: StockDao,
    private readonly favouriteHttpDao: FavouriteDao,
    private readonly planSettingsHttpDao: PlanSettingsDao,
    private readonly projectHttpDao: ProjectDao,
    private readonly planCreationHttpDao: PlanCreationDao,
    private readonly modalCtrl: ModalController,
    private readonly httpRequestSyncService: HttpRequestSyncService,
    private readonly cacheService: CacheService,
    private readonly planResultMigrationService: PlanResultMigrationService
  ) {}

  public async isMigrationNeeded(): Promise<boolean> {
    if (await this.devicePreferenceService.fetchMigrationSucceeded()) {
      return false
    }

    return (
      (await this.getTotalAmountPlans()) > 0 ||
      (await this.getTotalAmountStock()) > 0 ||
      (await this.getTotalAmountProjects()) > 1 || // ignore default project
      (await this.getTotalAmountOfCustomFavourites()) > 1
    )
  }

  public async checkIfMigrationNeeded(): Promise<void> {
    const migrationNeeded = await this.isMigrationNeeded()
    if (migrationNeeded) {
      await this.showMigrationDialog()
    }
  }

  private async showMigrationDialog(): Promise<void> {
    //notify that upload necessary, wait for users response
    const modal = await this.modalCtrl.create({
      componentProps: {
        title: this.translate.translate('NATIVE_TO_BACKEND_MIGRATION.MIGRATION_HEADER'),
        text: this.translate.translate('NATIVE_TO_BACKEND_MIGRATION.MIGRATION_DESC'),
        iconPath: '/assets/icon/cloud-upload-outline.svg',
        confirmButtonLabel: this.translate.translate('NATIVE_TO_BACKEND_MIGRATION.UPLOAD_BTN'),
        criticalActionButtonLabel: this.translate.translate(
          'NATIVE_TO_BACKEND_MIGRATION.DELETE_BTN'
        ),
        modalStyle: ModalStyle.INFO,
      },
      component: SimpleModalComponent,
      backdropDismiss: false,
      cssClass: 'soft-edges-modal modal-responsive-max-w-400',
    })
    await modal.present()
    const { role } = await modal.onDidDismiss()

    if (role === ModalDismissAction.CONFIRM) {
      await this.startMigration()
    } else {
      await this.showConfirmDoNotMigrateDialog()
    }
  }

  private async showConfirmDoNotMigrateDialog(): Promise<void> {
    const modal = await this.modalCtrl.create({
      componentProps: {
        title: this.translate.translate('NATIVE_TO_BACKEND_MIGRATION.DELETE_MODAL_HEADER'),
        text: this.translate.translate('NATIVE_TO_BACKEND_MIGRATION.DELETE_MODAL_DESC'),
        criticalActionButtonLabel: this.translate.translate(
          'NATIVE_TO_BACKEND_MIGRATION.DELETE_BTN'
        ),
        confirmButtonLabel: this.translate.translate('GENERAL.CANCEL'),
        modalStyle: ModalStyle.ERROR,
      },
      component: SimpleModalComponent,
      backdropDismiss: false,
      cssClass: 'soft-edges-modal modal-responsive-max-w-400',
    })
    await modal.present()
    const { role } = await modal.onDidDismiss()

    if (role === ModalDismissAction.CONFIRM) {
      await this.showMigrationDialog()
    } else {
      await this.deleteLocalDbDialog()
    }
  }

  private async deleteLocalDbDialog(): Promise<void> {
    await this.dataService.deleteDB()
    await this.devicePreferenceService.setMigrationSucceeded(true)

    const modal = await this.modalCtrl.create({
      componentProps: {
        title: this.translate.translate(
          'NATIVE_TO_BACKEND_MIGRATION.DATA_DELETED_CONFIRMATION_HEADER'
        ),
        text: this.translate.translate(
          'NATIVE_TO_BACKEND_MIGRATION.DATA_DELETED_CONFIRMATION_DESC'
        ),
        confirmButtonLabel: this.translate.translate('GENERAL.CLOSE'),
        modalStyle: ModalStyle.ERROR,
        dismissable: true,
      },
      component: SimpleModalComponent,
      backdropDismiss: true,
      cssClass: 'soft-edges-modal modal-responsive-max-w-400',
    })
    await modal.present()
  }

  private async showUploadErrorDialog(): Promise<void> {
    const modal = await this.modalCtrl.create({
      componentProps: {
        title: this.translate.translate('GENERAL.ERROR'),
        text: this.translate.translate('NATIVE_TO_BACKEND_MIGRATION.UPLOAD_ERROR_CONTENT'),
        iconPath: '/assets/icon/ic_warning_red.svg',
        confirmButtonLabel: this.translate.translate('NATIVE_TO_BACKEND_MIGRATION.UPLOAD_BTN'),
        criticalActionButtonLabel: this.translate.translate('GENERAL.CANCEL'),
        modalStyle: ModalStyle.ERROR,
      },
      component: SimpleModalComponent,
      backdropDismiss: false,
      cssClass: 'soft-edges-modal modal-responsive-max-w-400',
    })
    await modal.present()
    const { role } = await modal.onDidDismiss()

    if (role === ModalDismissAction.CONFIRM) {
      await this.showMigrationDialog()
    } else {
      await this.showConfirmDoNotMigrateDialog()
    }
  }

  private async showUploadCompleted(): Promise<void> {
    const modal = await this.modalCtrl.create({
      componentProps: {
        title: this.translate.translate('NATIVE_TO_BACKEND_MIGRATION.UPLOAD_SUCCESSFUL_TITLE'),
        text: this.translate.translate('NATIVE_TO_BACKEND_MIGRATION.UPLOAD_SUCCESSFUL_CONTENT'),
        confirmButtonLabel: this.translate.translate('GENERAL.OK'),
        modalStyle: ModalStyle.SUCCESS,
      },
      component: SimpleModalComponent,
      backdropDismiss: true,
      cssClass: 'soft-edges-modal modal-responsive-max-w-400',
    })
    await modal.present()

    await modal.onDidDismiss()
    this.cacheService.resetStores()
    window.location.reload()
  }

  private async createRunningMigrationDialog(): Promise<HTMLIonModalElement> {
    const uploadProperties: UploadProperties[] = []

    uploadProperties.push({
      name: 'FAVOURITES',
      maxSize: await this.getTotalAmountFavourites(),
      uploadCount: this.uploadCountFavourites,
    })

    uploadProperties.push({
      name: 'STOCK',
      maxSize: await this.getTotalAmountStock(),
      uploadCount: this.uploadCountStocks,
    })

    uploadProperties.push({
      name: 'PLANS',
      maxSize: await this.getTotalAmountPlans(),
      uploadCount: this.uploadCountPlans,
    })

    uploadProperties.push({
      name: 'PROJECTS',
      maxSize: await this.getTotalAmountProjects(),
      uploadCount: this.uploadCountProjects,
    })

    return this.modalCtrl.create({
      componentProps: {
        title: this.translate.translate('NATIVE_TO_BACKEND_MIGRATION.UPLOADING'),
        dismissable: false,
        dynamicComponent: UploadProgressComponent,
        dynamicComponentProps: {
          allUploadProperties: uploadProperties,
        },
      },
      component: SimpleModalComponent,
      backdropDismiss: false,
      cssClass: 'soft-edges-modal modal-responsive-max-w-400',
    })
  }

  public async retriggerUploadProcess(): Promise<void> {
    await this.startMigration()
  }

  private async startMigration(): Promise<void> {
    this.resetCounter()

    // prevent the sync error (with reload option) during the upload progress
    this.httpRequestSyncService.preventErrorMessage = true

    const runningMigrationModal = await this.createRunningMigrationDialog()
    await runningMigrationModal.present()

    try {
      // localFavourites are just for reading, they are not modified in any way
      const localFavourites = await this.favouritesSqlDao.findAll()

      // the localPlans need to be public because i have to change some IDs before we can start uploading
      this.localPlans = await this.planSqlDao.findAllPlans()

      // upload projects and everything that belongs to it (planSettings, favourites)
      await this.uploadProjects(localFavourites)

      // check if result still saved in filesystem and move it to native db before uploading
      await this.planResultMigrationService.migratePlanResultZipToDatabase()

      // upload Plans
      await this.uploadPlans(localFavourites)

      // uplaod remaining stock
      // stocks which were not needed in projects or plans
      await this.uploadRemainingStock()

      // upload remaining favourites
      // favourite profiles which were not needed in projects or plans
      await this.uploadRemainingFavourites(localFavourites)

      // data upload finished at this point

      await this.devicePreferenceService.setMigrationSucceeded(true)
      await this.dataService.deleteDB()
      this.httpRequestSyncService.preventErrorMessage = false
      await runningMigrationModal.dismiss()
      await this.showUploadCompleted()
    } catch (err: unknown) {
      await runningMigrationModal.dismiss()
      await this.showUploadErrorDialog()
    }
  }

  private async uploadProjects(localFavourites: FavouriteProfile[]): Promise<void> {
    const localProjects = await this.projectSqlDao.findAll(false)

    // get the default project
    const allHttpProjects = await this.projectHttpDao.findAll(false)
    const defaultProject = allHttpProjects.filter((x) => x.defaultProject === true)

    // change the id from plans which are in the default folder to the backend folder default Id
    if (defaultProject.length > 0) {
      this.localPlans
        .filter((plan) => plan.projectId === 1)
        .map((plan) => (plan.projectId = defaultProject[0].id))
    }

    for (const project of localProjects) {
      // ignore default project
      if (!project.defaultProject) {
        // upload favourites
        const planSettings = await this.uploadFavouritesForProject(
          project.defaultPlanSettings,
          localFavourites
        )

        // upload planSettings
        const httpPlanSettingsId = await this.uploadPlanSettings(
          project.defaultPlanSettings,
          planSettings?.wallFavId,
          planSettings?.slabFavId
        )

        // upload stock
        let httpStockId = undefined
        if (project.stockId) {
          httpStockId = await this.prepareAndUploadStock(project.stockId)
        }

        if (httpPlanSettingsId) {
          const httpProjectId = await this.projectHttpDao.create({
            defaultPlanSettingsId: httpPlanSettingsId,
            name: project.name,
            accHubId: null, // acc properties have to be null
            accId: null,
            addNextProjectNumberToName: false,
            stockId: httpStockId,
          })

          // update projectId in local Plans
          this.localPlans
            .filter((plans) => plans.projectId === project.id)
            .map((plan) => (plan.projectId = httpProjectId))

          await this.projectSqlDao.deleteSingleProject(project)
        }
      }
      this.uploadCountProjects.next(++this.localCountProjects)
    }
  }

  // create all params for plans and upload plans
  private async uploadPlans(localFavourites: FavouriteProfile[]): Promise<void> {
    for (const plan of this.localPlans) {
      // upload favourites and return planSettings with the new favIds
      const planSettings = await this.uploadFavouritesForPlan(plan, localFavourites)

      // this cannot happen because we load it from the local DB with a ID
      if (!planSettings?.id) {
        throw new Error('planSettings invalid')
      }

      const httpPlanSettingsId = await this.uploadPlanSettings(
        planSettings?.id,
        planSettings?.wallFavId,
        planSettings?.slabFavId
      )

      // Stock
      let httpStockId: number | null = null
      if (plan.stockId) {
        // check if stock already uploaded
        httpStockId = await this.prepareAndUploadStock(plan.id)
      }

      // PlanOutlines
      const importPlanOutlineParams = await this.preparePlanOutlines(plan.id)

      // cycleSymbols
      const cycleSymbolModels = await this.prepareCycleSymbols(plan.id)

      // cycleBoundaries
      const cycleBoundaryParams = await this.prepareCycleBoundaries(plan.id)

      // Screenshot
      const screenshotParams = await this.prepareScreenshots(plan.id)

      // planResult
      const planResultParams = await this.preparePlanResult(plan.id)

      // changedResultParts
      const changedResultPartsParams = await this.prepareChangedResult(plan.id)

      await this.planCreationHttpDao.createPlan({
        name: plan.name,
        buildingType: plan.buildingType === 'SLAB' ? ApiPlanType.Slab : ApiPlanType.Wall,
        currentStep: plan.currentStep as NavStep,
        projectId: plan.projectId,
        serializedMesh: plan.serializedMesh,
        planSettingsParams: undefined,
        stockParams: undefined,
        favouriteProfileParams: undefined,
        importPlanOutlineParams,
        cycleSymbolModels,
        cycleBoundaryParams,
        screenshotParams,
        planResultParams,
        changedResultPartsParams,
        createBlacklistArticleParams: null, // IGNORE - blacklist was not available on mobile before the migration
        addNextPlanNumberToName: false,
        settingsId: httpPlanSettingsId,
        stockId: httpStockId,
        import: true,
      })
      this.uploadCountPlans.next(++this.localCountPlans)

      await this.deletePlanWithAllRelated(plan)
    }
  }

  private async uploadFavouritesForPlan(
    plan: Plan,
    localFavourites: FavouriteProfile[]
  ): Promise<PlanSettings | undefined> {
    const planSettings = await this.planSettingsSqlDao.findOneById(plan.settingsId)
    let httpWallFavId: number | null = null
    let httpSlabFavId: number | null = null

    // check if favourites from plansettings are already upload
    // if not upload favourites and upload plansettings with new favourite Id
    if (planSettings && plan.buildingType === PlanType.WALL && planSettings.wallFavId) {
      const uploadedFavourites = this.hasItemWithProperty(
        this.alreadyUploadedFavIds,
        'sqlId',
        planSettings.wallFavId
      )

      if (uploadedFavourites?.httpId) {
        httpWallFavId = uploadedFavourites.httpId
      } else {
        const profile = localFavourites.find((x) => x.id === planSettings.wallFavId)
        if (profile) {
          httpWallFavId = await this.uploadFavouriteProfile(profile)
          this.alreadyUploadedFavIds.add({
            sqlId: planSettings.wallFavId,
            httpId: httpWallFavId,
          })
          this.uploadCountFavourites.next(++this.localCountFavourites)
        }
      }
      planSettings.wallFavId = httpWallFavId
    } else if (planSettings && plan.buildingType === PlanType.SLAB && planSettings.slabFavId) {
      const uploadedFavourites = this.hasItemWithProperty(
        this.alreadyUploadedFavIds,
        'sqlId',
        planSettings.slabFavId
      )

      if (uploadedFavourites?.httpId) {
        httpSlabFavId = uploadedFavourites.httpId
      } else {
        const profile = localFavourites.find((x) => x.id === planSettings.slabFavId)
        if (profile) {
          httpSlabFavId = await this.uploadFavouriteProfile(profile)
          this.alreadyUploadedFavIds.add({
            sqlId: planSettings.slabFavId,
            httpId: httpSlabFavId,
          })
          this.uploadCountFavourites.next(++this.localCountFavourites)
        }
      }
      planSettings.slabFavId = httpSlabFavId ?? null
    }

    return planSettings
  }

  private async uploadFavouritesForProject(
    defaultPlanSettingsId: number,
    localFavourites: FavouriteProfile[]
  ): Promise<PlanSettings | undefined> {
    const planSettings = await this.planSettingsSqlDao.findOneById(defaultPlanSettingsId)
    const favProfileWall = localFavourites.find((x) => x.id === planSettings?.wallFavId)
    const favProfileSlab = localFavourites.find((x) => x.id === planSettings?.slabFavId)

    let httpFavIdWall = 0
    let httpFavIdSlab = 0
    if (planSettings) {
      if (favProfileWall && planSettings.wallFavId) {
        // check if favourites are already uploaded
        const uploadedFavourites = this.hasItemWithProperty(
          this.alreadyUploadedFavIds,
          'sqlId',
          planSettings.wallFavId
        )

        if (uploadedFavourites?.httpId) {
          httpFavIdWall = uploadedFavourites.httpId
        } else if (!uploadedFavourites) {
          // upload and delete favorites
          httpFavIdWall = await this.uploadFavouriteProfile(favProfileWall)
          this.alreadyUploadedFavIds.add({
            sqlId: planSettings.wallFavId,
            httpId: httpFavIdWall,
          })
          planSettings.wallFavId = httpFavIdWall
          this.uploadCountFavourites.next(++this.localCountFavourites)
        }
      }
      if (favProfileSlab && planSettings.slabFavId) {
        // check if favourites are already uploaded
        const uploadedFavourites = this.hasItemWithProperty(
          this.alreadyUploadedFavIds,
          'sqlId',
          planSettings.slabFavId
        )

        if (uploadedFavourites?.httpId) {
          httpFavIdSlab = uploadedFavourites.httpId
        } else if (!uploadedFavourites) {
          // upload and delete favorites
          httpFavIdSlab = await this.uploadFavouriteProfile(favProfileSlab)
          this.alreadyUploadedFavIds.add({
            sqlId: planSettings.slabFavId,
            httpId: httpFavIdSlab,
          })
          this.uploadCountFavourites.next(++this.localCountFavourites)
        }
        planSettings.slabFavId = httpFavIdSlab
      }
    }
    return planSettings
  }

  private async uploadFavouriteProfile(profile: FavouriteProfile): Promise<number> {
    profile.isStandard = false
    profile.formworkVersion = OLD_FAVOURITES_VERSION
    return await this.favouriteHttpDao.create(profile)
  }

  private async uploadPlanSettings(
    settingsId: number,
    wallFavId: number | null = null,
    slabFavId: number | null = null
  ): Promise<number | undefined> {
    const planSettingsParams = await this.createPlanSettingsCommandParams(
      settingsId,
      wallFavId !== 0 ? wallFavId : null,
      slabFavId !== 0 ? wallFavId : null
    )

    if (planSettingsParams) {
      return await this.planSettingsHttpDao.create(planSettingsParams, -1)
    }
    return undefined
  }

  private async createPlanSettingsCommandParams(
    settingsId: number,
    wallFavId: number | null = null,
    slabFavId: number | null = null
  ): Promise<CreatePlanSettingsCommandParams | undefined> {
    const planSettingsPlan = await this.planSettingsSqlDao.findOneById(settingsId)
    let planSettingsParams: CreatePlanSettingsCommandParams | undefined

    if (planSettingsPlan) {
      planSettingsParams = {
        angleRastering: planSettingsPlan.angleRastering,
        formworkSlab: planSettingsPlan.formworkSlab,
        formworkWall: planSettingsPlan.formworkWall,
        lengthRastering: planSettingsPlan.lengthRastering,
        measurementUnit: planSettingsPlan.measurementUnit as UnitOfLength,
        slabHeight: planSettingsPlan.slabHeight,
        slabThickness: planSettingsPlan.slabThickness,
        wallHeight: planSettingsPlan.wallHeight,
        wallThickness: planSettingsPlan.wallThickness,
        slabFavouriteProfileId: slabFavId,
        wallFavouriteProfileId: wallFavId,
      }
    }

    return planSettingsParams
  }

  private async prepareAndUploadStock(stockId: number): Promise<number | null> {
    let httpStockId: number | null = null

    if (stockId) {
      // check if stock is already uploaded
      const uploadedStock = this.hasItemWithProperty(this.alreadyUploadedStocks, 'sqlId', stockId)

      if (uploadedStock?.httpId) {
        httpStockId = uploadedStock.httpId
      } else {
        httpStockId = await this.uploadStock(stockId)
        this.uploadCountStocks.next(++this.localCountStocks)

        if (httpStockId) {
          this.alreadyUploadedStocks.add({ sqlId: stockId, httpId: httpStockId })
        }
      }
    }
    return httpStockId
  }

  private async preparePlanOutlines(planId: number): Promise<ImportPlanOutlineCommandParam[]> {
    const importPlanOutline = await this.planOutlineSqlDao.findAllOutlinesByPlanId(planId)
    const accessoryLines = await this.planAccessoryLineSqlDao.getAccessoryLinesForPlan(planId)

    const importPlanOutlineParams = importPlanOutline.map((outline) => {
      let accessoriesAsString: string | null = null
      if (accessoryLines) {
        const accessoryLine = accessoryLines.find((line) => line.outlineId === outline.id)
        accessoriesAsString = accessoryLine?.accessoriesAsString ?? null
      }
      const model: ImportPlanOutlineCommandParam = {
        accessoriesAsString,
        endX: outline.end.x,
        endY: outline.end.y,
        planId: outline.planId,
        startX: outline.start.x,
        startY: outline.start.y,
        outlineType: outline.outlineType === PlanType.WALL ? ApiPlanType.Wall : ApiPlanType.Slab,
      }

      return model
    })

    return importPlanOutlineParams
  }

  private async prepareCycleSymbols(planId: number): Promise<CycleSymbolModel[]> {
    const cycleSymbolPerPlan = await this.cycleSqlDao.findAllCycleSymbolsByPlanId(planId)
    const cycleSymbolModels: CycleSymbolModel[] = []

    cycleSymbolPerPlan.map((cycleSymbol) => {
      cycleSymbolModels.push({
        cycleNumber: cycleSymbol.cycleNumber,
        planId,
        posX: cycleSymbol.position.x,
        posY: cycleSymbol.position.y,
      })
    })
    return cycleSymbolModels
  }

  private async prepareCycleBoundaries(planId: number): Promise<CycleBoundaryCreateCommandParam[]> {
    const cycleBoundaryPerPlan = await this.cycleSqlDao.findAllCycleBoundariesByPlanId(planId)
    const cycleBoundaryParams: CycleBoundaryCreateCommandParam[] = []
    cycleBoundaryPerPlan.map((cycleBoundary) => {
      cycleBoundaryParams.push({
        cycleNumberLeft: cycleBoundary.cycleNumberLeft,
        cycleNumberRight: cycleBoundary.cycleNumberRight,
        endx: cycleBoundary.end.x,
        endy: cycleBoundary.end.y,
        planId,
        startx: cycleBoundary.start.x,
        starty: cycleBoundary.start.y,
      })
    })

    return cycleBoundaryParams
  }

  private async prepareScreenshots(planId: number): Promise<ScreenshotCommandParams[]> {
    const screenshotPerPlan = await this.screenshotSqlDao.findAllByPlanId(planId)
    const screenshotParams: ScreenshotCommandParams[] = []
    screenshotPerPlan.map((screenShot) => {
      screenshotParams.push({
        cycle: screenShot.cycle,
        date: new Date(screenShot.date).toISOString(),
        defaultView: screenShot.defaultView,
        height: Math.trunc(screenShot.height), // remove decimal point; Backend requires a int
        name: screenShot.name,
        planId,
        screenshotData: screenShot.screenshot,
        width: Math.trunc(screenShot.width),
      })
    })
    return screenshotParams
  }

  private async preparePlanResult(
    planId: number
  ): Promise<CreatePlanResultCommandParam | undefined> {
    const planResult = await this.planResultSqlDao.getPlanResult(planId)
    let planResultParams: CreatePlanResultCommandParam | undefined
    if (planResult?.resultProtocol && planResult.partList) {
      planResultParams = {
        resultBase64Image: planResult.resultBase64Image ?? '',
        resultBase64Thumbnail: planResult.resultBase64Thumbnail ?? '',
        resultProtocol: JSON.stringify(planResult.resultProtocol),
        resultXML: planResult.resultXML ?? '',
        partList: JSON.stringify(planResult.partList),
        planId: planResult.planId,
      }
    }
    return planResultParams
  }

  private async prepareChangedResult(planId: number): Promise<ChangedResultPartsCommandParam[]> {
    const changedResultPartsParams: ChangedResultPartsCommandParam[] = []
    const changedResultPartsPerPlan = await this.changedResultPartSqlDao.findAllByPlanId(planId)
    changedResultPartsPerPlan.forEach((changedResultPart) => {
      changedResultPartsParams.push({
        amount: changedResultPart.amount,
        articleId: changedResultPart.articleId,
        planId,
      })
    })
    return changedResultPartsParams
  }

  private async uploadRemainingStock(): Promise<void> {
    // upload the rest which was not part of a plan or project
    const stocks = await this.stockSqlDao.findAll()

    await Promise.all(
      stocks.map(async (stock) => {
        const uploadedStock = this.hasItemWithProperty(
          this.alreadyUploadedStocks,
          'sqlId',
          stock.id
        )
        if (!uploadedStock) {
          await this.uploadStock(stock.id)
          this.uploadCountStocks.next(++this.localCountStocks)
        }
        await this.stockSqlDao.delete(stock)
      })
    )
  }

  private async uploadRemainingFavourites(localFavourites: FavouriteProfile[]): Promise<void> {
    await Promise.all(
      localFavourites.map(async (favourite) => {
        const uploadedFavourites = this.hasItemWithProperty(
          this.alreadyUploadedFavIds,
          'sqlId',
          favourite.id
        )
        if (!uploadedFavourites) {
          const profile = localFavourites.find((x) => x.id === favourite.id)
          if (profile) {
            await this.uploadFavouriteProfile(profile)
            this.uploadCountFavourites.next(++this.localCountFavourites)
          }
        }
        await this.favouritesSqlDao.deleteById(favourite.id)
      })
    )
  }

  private async uploadStock(stockId: number): Promise<number | null> {
    const createStockCommandParams = await this.createStockCommandParams(stockId)
    if (createStockCommandParams) {
      try {
        return (await this.stockHttpDao.createStockWithArticles(createStockCommandParams)).id
      } catch (err) {
        // stock names need to be unique in backend
        // when the upload crashes it is mostly because of the name violation
        // i can't add a check if the name was the reason for the upload crashing -> we dont receive the HTTP error here

        createStockCommandParams.name = createStockCommandParams.name + new Date().toISOString()
        return (await this.stockHttpDao.createStockWithArticles(createStockCommandParams)).id
      }
    }
    return null
  }

  private async createStockCommandParams(
    stockId: number
  ): Promise<CreateStockCommandParams | null> {
    const stockPlan = await this.stockSqlDao.findOneByIdWithArticles(stockId)

    if (stockPlan) {
      // because stockSqlDao.findOneByIdWithArticles uses the articleDao and in the case of migration it uses the HTTP DAO
      stockPlan.articles = this.removeDuplicateArticleIdsFromStock(
        await this.articleSqlDao.findAllByStockId(stockId)
      )

      return {
        date: new Date(stockPlan?.date).toISOString(),
        name: stockPlan?.name,
        articles: stockPlan?.articles,
      }
    }
    return null
  }

  // in the past it was possible to upload a stock that contains the same articleId multiple times
  // if we try to import such a stock we get a unique constraint violation error from the backend
  private removeDuplicateArticleIdsFromStock(articles: Article[]): Article[] {
    return Object.values(
      articles.reduce((acc, item) => {
        if (!acc[+item.articleId]) {
          acc[+item.articleId] = { ...item }
        } else {
          acc[+item.articleId].amount += item.amount
        }
        return acc
      }, {} as { [key: number]: Article })
    )
  }

  public async deletePlanWithAllRelated(plan: Plan): Promise<void> {
    await this.planResultResetCalculationSqlDao.resetCalculation(plan.id)
    await this.cycleSqlDao.removeAllCycleBoundariesByPlanId(plan.id)
    await this.cycleSqlDao.removeAllSymbolsByPlanId(plan.id)
    await this.planAccessoryLineSqlDao.deleteAccessoryLinesForPlan(plan.id)
    await this.planOutlineSqlDao.removeAllByPlanId(plan.id)

    await this.dataService.executeStatement('DELETE FROM Plans WHERE id = ?', [plan.id])
    await this.dataService.executeStatement('DELETE FROM PlanSettings WHERE id = ?', [
      plan.settingsId,
    ])
  }

  private async getTotalAmountStock(): Promise<number> {
    return (await this.stockSqlDao.findAll()).length
  }

  private async getTotalAmountFavourites(): Promise<number> {
    return (await this.favouritesSqlDao.findAll()).length
  }

  private async getTotalAmountOfCustomFavourites(): Promise<number> {
    return (await this.favouritesSqlDao.findAll()).filter((favourite) => !favourite.isStandard)
      .length
  }

  private async getTotalAmountPlans(): Promise<number> {
    return (await this.planSqlDao.findAllPlans()).length
  }

  private async getTotalAmountProjects(): Promise<number> {
    return (await this.projectSqlDao.findAll(false)).length
  }

  private hasItemWithProperty(
    set: Set<IdMatching>,
    propertyName: keyof IdMatching,
    value: number
  ): IdMatching | undefined {
    for (const item of set) {
      if (item[propertyName] === value) {
        return item
      }
    }
    return undefined
  }

  private resetCounter(): void {
    this.uploadCountFavourites = new BehaviorSubject<number>(0)
    this.uploadCountStocks = new BehaviorSubject<number>(0)
    this.uploadCountPlans = new BehaviorSubject<number>(0)
    this.uploadCountProjects = new BehaviorSubject<number>(0)
    this.localCountFavourites = 0
    this.localCountStocks = 0
    this.localCountPlans = 0
    this.localCountProjects = 0
  }
}
