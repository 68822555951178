import { Component, Input } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { Slide } from '../../../models/slide'

@Component({
  selector: 'efp-multi-step-modal',
  templateUrl: './multi-step-modal.component.html',
})
export class MultiStepModalComponent {
  constructor(public readonly modalCtrl: ModalController) {}

  @Input() slides!: Slide[]

  public index = 0

  goToSlide(targetIndex: number): void {
    this.index = targetIndex
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }
}
