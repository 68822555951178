import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'
import { Observable, catchError, throwError } from 'rxjs'
import { CacheService } from '../../services/cache/cache.service'

@Injectable()
export class AddLocalCacheIdHeaderInterceptor implements HttpInterceptor {
  constructor(private readonly cacheService: CacheService) {}

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const headers = req.headers.set('LocalCacheId', this.cacheService.localCacheId)

    const appVersionReq = req.clone({
      headers,
    })

    return next.handle(appVersionReq).pipe(
      catchError((error) => {
        return throwError(() => new Error(error))
      })
    )
  }
}
