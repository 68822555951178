import { Injectable } from '@angular/core'
import { AppSettingsModel, AppUserType, Country } from '@efp/api'
import { createStore, select } from '@ngneat/elf'
import { getAllEntities, setEntities, updateEntities, withEntities } from '@ngneat/elf-entities'
import { firstValueFrom } from 'rxjs'
import { AppSettingDao } from '../services/dao/app-settings.dao'
import { AdvertisementRepository } from './advertisement.repository'
import { AuthenticationRepository } from './authentication-repository.service'

const store = createStore({ name: 'appSettings' }, withEntities<AppSettingsModel>())

@Injectable({
  providedIn: 'root',
})
export class AppSettingsRepository {
  public appSettings$ = store.pipe(
    select((state) => (state.ids.length > 0 ? state.entities[state.ids[0]] : null))
  )

  constructor(
    private readonly appSettingDao: AppSettingDao,
    private readonly advertisementRepository: AdvertisementRepository,
    private readonly authenticationRepository: AuthenticationRepository
  ) {
    this.authenticationRepository.authenticated$.subscribe((authenticated) => {
      if (authenticated) {
        void this.loadAppSettings()
      }
    })
  }

  public async updateLanguage(newLang: string): Promise<void> {
    const appSettings = store.query(getAllEntities())
    if (appSettings && appSettings.length > 0) {
      store.update(updateEntities(appSettings[0].id, { language: newLang }))
    }

    await this.appSettingDao.updateLanguage(newLang)
    void this.advertisementRepository.fetchAll(newLang, appSettings[0].country)
  }

  public async updateCountry(newCountry: Country, appUserType: AppUserType): Promise<void> {
    await this.appSettingDao.updateCountry(newCountry, appUserType)
    const appSettings = store.query(getAllEntities())
    if (appSettings && appSettings.length > 0) {
      store.update(
        updateEntities(appSettings[0].id, {
          country: newCountry,
          appUserType,
          privacyPolicyAcceptedAt: new Date().toUTCString(),
        })
      )
    }

    void this.advertisementRepository.fetchAll(appSettings[0].language, newCountry)
  }

  public async updateShowSync(newShowState: boolean): Promise<void> {
    const appSettings = store.query(getAllEntities())
    if (appSettings && appSettings.length > 0) {
      store.update(updateEntities(appSettings[0].id, { showSyncIndicator: newShowState }))
    }

    await this.appSettingDao.updateSyncState(newShowState)
  }

  public async getAppSettings(): Promise<AppSettingsModel> {
    const appSettings = await firstValueFrom(this.appSettings$)
    return appSettings ?? (await this.appSettingDao.getAppSettings())
  }

  private async loadAppSettings(): Promise<Readonly<AppSettingsModel>> {
    const newAppSettings = await this.appSettingDao.getAppSettings()
    store.update(setEntities([newAppSettings]))
    void this.advertisementRepository.fetchAll(newAppSettings.language, newAppSettings.country)

    return newAppSettings
  }
}
