import { Injectable, inject } from '@angular/core'
import { Project } from '../../../models/project'
import { PlansModel, ProjectCommandParams, ProjectsApiClient, ProjectsModel } from '@efp/api' // Assuming you have an API client for Projects
import { ProjectDao } from '../project.dao'
import { Plan } from '../../../models/plan'
import { firstValueFrom } from 'rxjs'
import { PlanType } from '../../../shared/formwork-planner'

@Injectable()
export class ProjectHttpDao extends ProjectDao {
  private readonly projectApiClient = inject(ProjectsApiClient)

  private static mapPlanModelToPlan(model: PlansModel): Plan {
    return {
      id: model.id,
      name: model.name,
      date: new Date(model.date),
      lastUsed: new Date(model.lastUsed),
      buildingType: model.buildingType === 'SLAB' ? PlanType.SLAB : PlanType.WALL,
      projectId: model.projectId,
      settingsId: model.settingsId,
      currentStep: model.currentStep,
      stockId: model.stockId ?? null,
      serializedMesh: model.serializedMesh ?? undefined,
    }
  }

  private static mapModelToProject(model: ProjectsModel): Project {
    return {
      id: model.id,
      name: model.name,
      stockId: model.stockId ?? null,
      plans: model.plans?.map(ProjectHttpDao.mapPlanModelToPlan) ?? ([] as Plan[]),
      defaultPlanSettings: model.defaultPlanSettingsId,
      defaultProject: model.defaultProject ?? undefined,
      accId: model.accId ?? undefined,
      accHubId: model.accHubId ?? undefined,
    }
  }

  public async findAllByFavouriteId(favouriteId: number): Promise<Project[]> {
    const response = await firstValueFrom(this.projectApiClient.getAllProjects({ favouriteId }))
    return response.map(ProjectHttpDao.mapModelToProject)
  }

  public async findOne(projectId: number, includePlans: boolean): Promise<Project | undefined> {
    const response = await firstValueFrom(
      this.projectApiClient.getProject({ projectId, includePlans })
    )
    return ProjectHttpDao.mapModelToProject(response)
  }

  public async findAll(includePlans: boolean): Promise<Project[]> {
    const response = await firstValueFrom(this.projectApiClient.getAllProjects({ includePlans }))
    return response.map(ProjectHttpDao.mapModelToProject)
  }

  public async findAllByStockId(stockId: number): Promise<Project[]> {
    const response = await firstValueFrom(this.projectApiClient.getAllProjects({ stockId }))
    return response.map(ProjectHttpDao.mapModelToProject)
  }

  async create(params: ProjectCommandParams): Promise<number> {
    const response = await firstValueFrom(this.projectApiClient.createProject({ body: params }))
    return response
  }

  public async update(project: Project): Promise<void> {
    const body = {
      defaultPlanSettingsId: project.defaultPlanSettings,
      name: project.name,
      stockId: project.stockId,
      accHubId: project.accHubId,
      accId: project.accId,
    }
    const params = {
      projectId: project.id,
      body,
    }

    await firstValueFrom(this.projectApiClient.putProject(params))
  }

  public async delete(project: Project): Promise<void> {
    await firstValueFrom(this.projectApiClient.deleteProject({ projectId: project.id }))
  }
}
