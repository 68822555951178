import { Component, Input } from '@angular/core'
import { OnboardingHint } from '../../../../models/onboarding/onboarding-hint'
import { ModalController } from '@ionic/angular'
import { MultiStepModalComponent } from '../../multi-step-modal/multi-step-modal.component'

@Component({
  selector: 'efp-onboarding-hint-template',
  templateUrl: './onboarding-hint-template.component.html',
  styleUrls: ['./onboarding-hint-template.component.scss'],
})
export class OnboardingHintTemplateComponent extends MultiStepModalComponent {
  public hintIndex = 0

  @Input() hints!: OnboardingHint[]

  constructor(modalCtrl: ModalController) {
    super(modalCtrl)
  }
}
