import { Injectable } from '@angular/core'
import { PartList, ResultPart } from '../../../models/part'
import { PlanResult } from '../../../models/planResult'
import { PlanResultProtocol } from '../../../models/planResultProtocol'
import { Stock } from '../../../models/stock'
import { DataService } from '../../data.service'
import { PlanResultService } from '../../plan-result.service'
import { PlanResultDao } from '../plan-result.dao'
import { PlanResultImageModel, PlanResultThumbnailModel } from '../../../../generated/efp-api'
import { PlanDao } from '../plan.dao'
import { StockDao } from '../stock.dao'
import { ScreenshotDao } from '../screenshot.dao'
import { ChangedResultPartDao } from '../changed-result-part.dao'
import { PlanType } from '../../../shared/formwork-planner'

@Injectable()
export class PlanResultSqlDao extends PlanResultDao {
  constructor(
    private readonly dataService: DataService,
    private readonly planDao: PlanDao,
    private readonly stockDao: StockDao,
    private readonly screenshotDao: ScreenshotDao,
    private readonly changedResultPartDao: ChangedResultPartDao
  ) {
    super()
  }

  public async savePlanResult(result: Omit<PlanResult, 'planId'>, planId: number): Promise<void> {
    if (!result || !planId) {
      throw new Error('Provided plan id or result are empty')
    }
    await this.deletePlanResult(planId)

    await this.insertIntoPlanResult({ ...result, planId })
  }

  private async insertIntoPlanResult(result: PlanResult): Promise<void> {
    if (result.resultBase64Image && !result.resultBase64Thumbnail) {
      result.resultBase64Thumbnail = await PlanResultService.createThumbnail(
        result.resultBase64Image
      )
    }

    const statement = `INSERT INTO PlanResult (planId, resultXml, partList, resultProtocol, resultBase64Image, resultBase64Thumbnail)
                       VALUES (?, ?, ?, ?, ?, ?);`
    const values = [
      result.planId,
      result.resultXML,
      JSON.stringify(result.partList),
      JSON.stringify(result.resultProtocol),
      result.resultBase64Image ?? '',
      result.resultBase64Thumbnail ?? '',
    ]
    await this.dataService.executeStatement(statement, values)
  }

  public async getArticleListForPlanExport(planId: number): Promise<ResultPart[] | undefined> {
    const result = await this.dataService.executeStatement(
      `SELECT partList
       FROM PlanResult
       WHERE planId = ?`,
      [planId]
    )

    if (result.rows.length === 0) {
      return undefined
    } else {
      const row = result.rows.item(0)
      return <ResultPart[]>JSON.parse(row.partList)
    }
  }

  public async getArticleListWithCycleUsageForPlan(planId: number): Promise<PartList | undefined> {
    const articleList = await this.getArticleListForPlanExport(planId)

    if (!articleList) {
      return undefined
    } else {
      const partlist = articleList
      const plan = await this.planDao.findOne(planId)
      let stock: Stock | undefined
      if (plan?.stockId) {
        stock = await this.stockDao.findOneByIdWithArticles(plan.stockId)
      }

      return await PlanResultService.calculateCycleUsage(partlist, stock)
    }
  }

  public async getResultMessages(planId: number): Promise<PlanResultProtocol> {
    const result = await this.dataService.executeStatement(
      `SELECT resultProtocol
       FROM PlanResult
       WHERE planId = ?`,
      [planId]
    )

    if (result.rows.length === 0) {
      return {
        Messages: [],
        Errors: [],
        Warnings: [],
      }
    } else {
      const row = result.rows.item(0)
      return <PlanResultProtocol>JSON.parse(row.resultProtocol)
    }
  }

  public async getResultXML(planId: number): Promise<string> {
    const result = await this.dataService.executeStatement(
      `SELECT resultXml
       FROM PlanResult
       WHERE planId = ?`,
      [planId]
    )
    if (result.rows.length === 1) {
      const row = result.rows.item(0)
      // const dec = new TextDecoder('utf-8')
      return row.resultXml
    } else {
      return ''
    }
  }

  private async deletePlanResult(planId: number): Promise<void> {
    await this.dataService.executeStatement(
      `DELETE
                                             FROM PlanResult
                                             WHERE planId = ?`,
      [planId]
    )
  }

  public async getIsCalculated(planId: number): Promise<boolean> {
    const result = await this.dataService.executeStatement(
      `SELECT *
       FROM PlanResult
       WHERE planId = ?`,
      [planId]
    )
    if (result.rows.length === 1) {
      return true
    } else {
      return false
    }
  }

  public async getPlanResult(planId: number): Promise<PlanResult | undefined> {
    const result = await this.dataService.executeStatement(
      `SELECT *
       FROM PlanResult
       WHERE planId = ?`,
      [planId]
    )

    if (result.rows.length === 0) {
      return undefined
    } else {
      const row = result.rows.item(0)
      return {
        planId: row.planId,
        resultXML: row.resultXml,
        partList: JSON.parse(row.partList),
        resultProtocol: JSON.parse(row.resultProtocol),
        resultBase64Image: row.resultBase64Image,
        resultBase64Thumbnail: row.resultBase64Thumbnail,
      }
    }
  }

  public async getPlanResultImage(planId: number): Promise<PlanResultImageModel | undefined> {
    const result = await this.dataService.executeStatement(
      `SELECT resultBase64Image
       FROM PlanResult
       WHERE planId = ?`,
      [planId]
    )

    if (result.rows.length === 0) {
      return undefined
    } else {
      const row = result.rows.item(0)
      const model: PlanResultImageModel = {
        resultBase64Image: row.resultBase64Image,
        planId,
      }
      return model
    }
  }

  public async getPlanResultThumbnail(
    planId: number
  ): Promise<PlanResultThumbnailModel | undefined> {
    const result = await this.dataService.executeStatement(
      `SELECT resultBase64Thumbnail
       FROM PlanResult
       WHERE planId = ?`,
      [planId]
    )

    if (result.rows.length === 0) {
      return undefined
    } else {
      const row = result.rows.item(0)
      const model: PlanResultThumbnailModel = {
        resultBase64Thumbnail: row.resultBase64Thumbnail,
        planId,
      }
      return model
    }
  }

  /**
   * Sets the plan calculation state to false, updates the current navigation step and remove changed result parts.
   */
  public async resetCalculation(planId: number): Promise<void> {
    const resultSet = await this.dataService.executeStatement(
      'SELECT currentStep, buildingType FROM Plans WHERE id = ?',
      [planId]
    )
    const calculated = await this.getIsCalculated(planId)
    let currentStep = resultSet.rows.item(0).currentStep
    const buildingType: PlanType = resultSet.rows.item(0).buildingType
    if (calculated) {
      if (currentStep === 'result') {
        if (buildingType === PlanType.WALL) {
          currentStep = 'cycles'
        } else {
          currentStep = 'planner'
        }
      }
    }

    await this.dataService.executeStatement(`DELETE FROM PlanResult WHERE planId = ?`, [planId])
    await this.planDao.updateCurrentStep(planId, currentStep)
    await this.changedResultPartDao.deleteAllByPlanId(planId)
    await this.screenshotDao.deleteAllByPlanId(planId)
  }
}
