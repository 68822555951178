import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { AlertController } from '@ionic/angular'
import { PlanType } from 'formwork-planner-lib'
import { Subject } from 'rxjs'
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators'
import { FavouriteProfile } from '../../../models/favourites'
import { PlanSettings } from '../../../models/planSettings'
import { AccessoryService } from '../../../pages/accessory/services/accessory.service'
import { AppSettingsRepository } from '../../../repositories/app-settings.repository'
import { FavouriteRepository } from '../../../repositories/favourite.repository'
import { FormworkSystemService } from '../../../services/formworkSystemService'
import { PlanSettingsService } from '../../../services/plan-settings.service'
import { Translation } from '../../../services/translation.service'
import { PlanResultRepository } from '../../../repositories/plan-result.repository'
import { AppSettingsModel } from '../../../../generated/efp-api'

@Component({
  selector: 'efp-formwork-settings',
  templateUrl: './formwork-settings.component.html',
  styleUrls: ['./formwork-settings.component.scss'],
})
export class FormworkSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() exclusiveBuildingType?: PlanType
  @Input() planSettings?: PlanSettings
  @Input() planId?: number

  @Output() readonly formworkSettingsChanged = this.planSettingsService.formworkSettingsChanged
  @Output() readonly navigateToFavouritesClicked = new EventEmitter<PlanType>()

  settingsForm = new FormGroup({
    formworkWall: new FormControl('', {
      validators: [
        Validators.required,
        this.formworkSystemService.validateFormworkExists(PlanType.WALL),
      ],
      nonNullable: true,
    }),
    formworkSlab: new FormControl('', {
      validators: [
        Validators.required,
        this.formworkSystemService.validateFormworkExists(PlanType.SLAB),
      ],
      nonNullable: true,
    }),
    wallFavouriteProfile: new FormControl(0, {
      validators: [Validators.required],
    }),
    slabFavouriteProfile: new FormControl(0, {
      validators: [Validators.required],
    }),
  })

  private readonly destroy$ = new Subject<void>()
  public availableFavouriteWallProfiles?: FavouriteProfile[]
  public availableFavouriteSlabProfiles?: FavouriteProfile[]

  get formworkSlab(): AbstractControl {
    return this.settingsForm.controls.formworkSlab
  }

  get formworkWall(): AbstractControl {
    return this.settingsForm.controls.formworkWall
  }

  get isWallFormworkInvalid(): boolean {
    return this.formworkWall.invalid
  }

  get isSlabFormworkInvalid(): boolean {
    return this.formworkSlab.invalid
  }

  get wallFavouriteProfile(): AbstractControl {
    return this.settingsForm.controls.wallFavouriteProfile
  }

  get slabFavouriteProfile(): AbstractControl {
    return this.settingsForm.controls.slabFavouriteProfile
  }

  constructor(
    public readonly formworkSystemService: FormworkSystemService,
    private readonly accessoryService: AccessoryService,
    private readonly translate: Translation,
    private readonly alertCtrl: AlertController,
    private readonly planSettingsService: PlanSettingsService,
    private readonly favouriteRepository: FavouriteRepository,
    private readonly appSettingsRepository: AppSettingsRepository,
    private readonly planResultRepository: PlanResultRepository
  ) {}

  async ngOnInit(): Promise<void> {
    this.settingsForm.patchValue(
      {
        formworkWall: this.planSettings?.formworkWall,
        formworkSlab: this.planSettings?.formworkSlab,
        wallFavouriteProfile: this.planSettings?.wallFavId,
        slabFavouriteProfile: this.planSettings?.slabFavId,
      },
      { emitEvent: false }
    )

    this.settingsForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      void this.updateFormworkSettings()
    })

    void this.planSettingsService.planSettingsChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((planSettings) => {
        void this.onPlanSettingsUpdated(planSettings)
      })

    this.appSettingsRepository.appSettings$
      .pipe(
        takeUntil(this.destroy$),
        filter((appsettings): appsettings is AppSettingsModel => !!appsettings),
        map((it) => it.country),
        distinctUntilChanged()
      )
      .subscribe(() => void this.onAppCountryChanged())
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (
      'planSettings' in changes &&
      changes.planSettings.previousValue !== changes.planSettings.currentValue
    ) {
      await this.onPlanSettingsUpdated(changes.planSettings.currentValue)
    }
  }

  private async onAppCountryChanged(): Promise<void> {
    await this.formworkSystemService.updateAvailableFormworkSystems(this.planSettings)
    this.settingsForm.updateValueAndValidity()
  }

  private async onPlanSettingsUpdated(planSettings?: PlanSettings): Promise<void> {
    // This is done here instead of onInit, as we add the currently selected planSettings to the formworks.
    // If the planSettings change again, we refresh the supported list and re-add missing formworks.
    await this.formworkSystemService.updateAvailableFormworkSystems(this.planSettings)
    if (planSettings) {
      if (this.exclusiveBuildingType === PlanType.WALL) {
        this.availableFavouriteWallProfiles =
          await this.favouriteRepository.findAllByFormworkSystemId(planSettings.formworkWall)
      } else if (this.exclusiveBuildingType === PlanType.SLAB) {
        this.availableFavouriteSlabProfiles =
          await this.favouriteRepository.findAllByFormworkSystemId(planSettings.formworkSlab)
      } else {
        this.availableFavouriteWallProfiles =
          await this.favouriteRepository.findAllByFormworkSystemId(planSettings.formworkWall)
        this.availableFavouriteSlabProfiles =
          await this.favouriteRepository.findAllByFormworkSystemId(planSettings.formworkSlab)
      }

      this.planSettings = planSettings
    }

    this.settingsForm.patchValue(
      {
        formworkWall: this.planSettings?.formworkWall,
        formworkSlab: this.planSettings?.formworkSlab,
        wallFavouriteProfile: this.planSettings?.wallFavId,
        slabFavouriteProfile: this.planSettings?.slabFavId,
      },
      { emitEvent: false }
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  async goToFavourites(planTypeString: 'WALL' | 'SLAB'): Promise<void> {
    const planType = planTypeString as PlanType
    this.navigateToFavouritesClicked.emit(planType)
  }

  async updateFormworkSettings(): Promise<void> {
    if (this.planSettings) {
      const newValue = { ...this.planSettings }
      let hasNewWallValue = false
      if (this.formworkSlab.valid && this.planSettings.formworkSlab !== this.formworkSlab.value) {
        newValue.formworkSlab = this.formworkSlab.value
        const standardFavourite = await this.favouriteRepository.findOneStandardByFormworkSystemId(
          this.formworkSlab.value
        )
        if (!standardFavourite) {
          throw new Error(
            'FormworkSettingsComponent.updateFormworkSettings - No standard favourite found for formwork system ' +
              this.formworkSlab.value
          )
        }
        newValue.slabFavId = standardFavourite.id
      }
      if (this.formworkWall.valid && this.planSettings.formworkWall !== this.formworkWall.value) {
        newValue.formworkWall = this.formworkWall.value
        const standardFavourite = await this.favouriteRepository.findOneStandardByFormworkSystemId(
          this.formworkWall.value
        )
        if (!standardFavourite) {
          throw new Error(
            'FormworkSettingsComponent.updateFormworkSettings - No standard favourite found for formwork system ' +
              this.formworkWall.value
          )
        }
        newValue.wallFavId = standardFavourite.id
        hasNewWallValue = true
      }

      if (newValue !== this.planSettings && (await this.showWarningDisplay(hasNewWallValue))) {
        newValue.wallFavId = this.wallFavouriteProfile.value
        newValue.slabFavId = this.slabFavouriteProfile.value

        this.formworkSettingsChanged.emit(newValue)
      } else {
        this.settingsForm.patchValue(
          {
            formworkWall: this.planSettings.formworkWall,
            formworkSlab: this.planSettings.formworkSlab,
          },
          { emitEvent: false }
        )
      }
    }
  }

  /**
   * Returns true, if the changes to the planSettings should be done, otherwise false
   */
  private async showWarningDisplay(hasNewWallValue: boolean): Promise<boolean> {
    if (this.planId && hasNewWallValue && this.planSettings) {
      return new Promise<boolean>((resolve) => {
        void (async () => {
          if (this.planId && hasNewWallValue && this.planSettings) {
            const accessoryLines = await this.accessoryService.loadAccessoryLinesForPlanAndFormwork(
              this.planId,
              this.planSettings.formworkWall
            )
            const hasAccessories =
              accessoryLines.length > 0 &&
              accessoryLines.some((it) => it.accessoriesAsString !== '')
            if (hasAccessories || (await this.planResultRepository.getIsCalculated(this.planId))) {
              const alert = await this.alertCtrl.create({
                cssClass: ['alertStyle', 'alertStyleTwoButtons'],
                backdropDismiss: true,
                header: this.translate.translate('GENERAL.WARNING'),
                message: this.translate.translate('PLAN.SETTINGS_CHANGE_WARNING'),
                buttons: [
                  {
                    text: this.translate.translate('GENERAL.CANCEL'),
                    role: 'cancel',
                    handler: () => resolve(false),
                  },
                  {
                    text: this.translate.translate('GENERAL.OK'),
                    handler: () => resolve(true),
                  },
                ],
              })
              await alert.present()
            } else {
              resolve(true)
            }
          }
        })()
      })
    } else {
      return Promise.resolve(true)
    }
  }
}
