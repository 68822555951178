import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { AlertController } from '@ionic/angular'
import { PlanType } from 'formwork-planner-lib'
import { BehaviorSubject, Subject, debounceTime, firstValueFrom, takeUntil } from 'rxjs'
import { NavStep, navStepsSlab, navStepsWall } from '../../../models/navSteps'
import { PlanSettings } from '../../../models/planSettings'
import { PlanRepository } from '../../../repositories/plan.repository'
import { FormworkSystemService } from '../../../services/formworkSystemService'

import { ResultTabService } from '../../../services/result-tab.service'
import { Translation } from '../../../services/translation.service'
import { ScrollbarComponent } from '../scrollbar/scrollbar.component'
import {
  HttpRequestSyncService,
  SynchronizationState,
} from '../../../services/http-request-sync.service'
import { Router } from '@angular/router'
import { ResultStep } from '../../../models/resultStep'
import { PlanResultRepository } from '../../../repositories/plan-result.repository'

@Component({
  selector: 'efp-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ScrollbarComponent) scrollbarComponent!: ScrollbarComponent
  @Input() currentStep: NavStep = 'plansettings'
  @Input() planId!: number
  @Input() planType!: PlanType

  @Input() navDisabledAndGray = false
  @Input() planSettings?: PlanSettings

  // Used to disable navigation from this components
  @Input() enableNavigation = true

  // Currently used to check valid states and navigate in the planner components
  @Output() readonly navButtonClicked = new EventEmitter<string[]>()

  public isCalculated$ = new BehaviorSubject<boolean>(false)
  public wallIsCreated = false
  public resultStep = ResultStep
  public syncRunning = false
  public waitingNavStep: NavStep | undefined

  private readonly destroy$ = new Subject<void>()

  get pages(): NavStep[] {
    return this.planType === PlanType.WALL ? navStepsWall : navStepsSlab
  }

  constructor(
    public resultTabService: ResultTabService,
    private readonly translate: Translation,
    private readonly formworkSystemService: FormworkSystemService,
    private readonly alertCtrl: AlertController,
    private readonly planRepository: PlanRepository,
    private readonly httpRequestSyncService: HttpRequestSyncService,
    private readonly router: Router,
    private readonly planResultRepository: PlanResultRepository
  ) {}

  async ngOnInit(): Promise<void> {
    this.httpRequestSyncService.synchronizationState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.syncRunning = state === SynchronizationState.SYNC_RUNNING
      })

    this.httpRequestSyncService.synchronizationState$
      .pipe(debounceTime(1000))
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        const stateSaved =
          !this.syncRunning &&
          this.httpRequestSyncService.synchronizationStateSubject.value ===
            SynchronizationState.SYNC_SAVED &&
          state === SynchronizationState.SYNC_SAVED

        if (this.waitingNavStep !== undefined && stateSaved) {
          void this.navToStep(this.waitingNavStep)
          this.waitingNavStep = undefined
        }

        if (stateSaved) {
          this.scrollToResultIfNecessary()
        }
      })

    this.planRepository
      .getPlanSerializedMesh$(this.planId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((serializedMesh) => {
        if (serializedMesh && JSON.parse(serializedMesh).points.length > 0) {
          this.wallIsCreated = true
        } else {
          this.wallIsCreated = false
        }
      })

    this.planResultRepository.planResultCalculationStates$
      .pipe(takeUntil(this.destroy$))
      .subscribe((planResultCalculationStates) => {
        if (!this.planId) {
          return
        }

        const isCalculated = planResultCalculationStates.some(
          (state) => state.calculated && state.id === this.planId
        )
        this.isCalculated$.next(isCalculated)
      })

    this.setActiveState()
    void this.planResultRepository.getIsCalculated(this.planId)
  }

  ngAfterViewInit(): void {
    this.isCalculated$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.currentStep === 'result') {
        this.scrollbarComponent?.manageScrollbarFog()
        this.scrollToResultIfNecessary()
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  setActiveState(): void {
    const route = this.router.url
    const parts = route.split('/')
    const resultStep = parts[parts.length - 1]
    const currentStep = parts[1]

    if (currentStep === 'result') {
      this.resultTabService.setResultStep(resultStep as ResultStep)
    }

    this.scrollToResultIfNecessary()
  }

  async navToStep(navStep: NavStep): Promise<void> {
    // Not blocking the navigation from the result page back
    if (this.currentStep !== 'result') {
      // Set the waiting nav step if the sync is running
      if (this.syncRunning) {
        this.waitingNavStep = navStep !== this.currentStep ? navStep : undefined
        return
      }

      // Block the navigation if the sync state is running or failed
      if (
        this.httpRequestSyncService.synchronizationStateSubject.value !==
        SynchronizationState.SYNC_SAVED
      ) {
        return
      }
    }

    const route = ['/' + navStep + '/' + this.planId]
    const isCalculated = await firstValueFrom(this.isCalculated$)
    const isInvalid = await this.isInvalidFormworkSystem()

    if (navStep === 'result' && isInvalid && !isCalculated) {
      await this.showInvalidFormworkSystemAlert()
      return
    }

    if (navStep !== 'result') {
      this.resultTabService.setResultStep(ResultStep.NONE)
    } else {
      this.resultTabService.setResultStep(ResultStep.Result3D)
      this.scrollbarComponent?.scrollToRight()
    }

    if (!this.navDisabledAndGray) {
      this.navButtonClicked.next(route)
    }

    if (this.enableNavigation && !this.navDisabledAndGray) {
      await this.router.navigate(route)
    }
  }

  async navToResultTab(tab: string): Promise<void> {
    if (
      this.currentStep !== 'result' &&
      this.httpRequestSyncService.synchronizationStateSubject.value !==
        SynchronizationState.SYNC_SAVED
    ) {
      return
    }

    const navStep: NavStep = 'result'
    const route = ['/' + navStep + '/' + this.planId + '/' + tab]
    const isCalculated = await firstValueFrom(this.isCalculated$)
    const isInvalid = await this.isInvalidFormworkSystem()

    if (isInvalid && !isCalculated) {
      this.resultTabService.setResultStep(ResultStep.NONE)
      await this.showInvalidFormworkSystemAlert()
      return
    }

    this.resultTabService.setResultStep(tab as ResultStep, this.planId)

    if (!this.navDisabledAndGray) {
      this.navButtonClicked.next(route)
    }

    if (this.enableNavigation && !this.navDisabledAndGray) {
      await this.router.navigate(route)
    }

    this.scrollbarComponent?.scrollToRight()
  }

  private async isInvalidFormworkSystem(): Promise<boolean> {
    if (!this.planSettings) {
      return false
    }

    await this.formworkSystemService.updateAvailableFormworkSystems(this.planSettings)
    const isInvalid = this.formworkSystemService.isInvalidFormworkSystem(
      this.planType === PlanType.WALL
        ? this.planSettings?.formworkWall
        : this.planSettings?.formworkSlab
    )
    return isInvalid
  }

  private async showInvalidFormworkSystemAlert(): Promise<void> {
    const alert = await this.alertCtrl.create({
      cssClass: ['alertStyle', 'alertStyleTwoButtons'],
      header: this.translate.translate('ERROR.FORMWORK.TITLE'),
      message: this.translate.translate('ERROR.FORMWORK.COUNTRY'),
      buttons: [
        {
          text: this.translate.translate('GENERAL.APP_SETTINGS'),
          handler: () => {
            void this.navToStep('plansettings')
          },
        },
        {
          text: this.translate.translate('GENERAL.OK'),
        },
      ],
    })
    await alert.present()
  }

  private scrollToResultIfNecessary(): void {
    if (this.currentStep === 'result') {
      this.scrollbarComponent?.scrollToRight()
    }
  }
}
