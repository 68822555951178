import { Injectable } from '@angular/core'
import { AppSettingsApiClient, AppSettingsModel, AppUserType, Country } from '@efp/api'
import { AppSettingDao } from '../app-settings.dao'
import { firstValueFrom } from 'rxjs'

@Injectable()
export class AppSettingHttpDao extends AppSettingDao {
  constructor(private readonly appSettingsApiClient: AppSettingsApiClient) {
    super()
  }

  async getAppSettings(): Promise<Readonly<AppSettingsModel>> {
    return firstValueFrom(this.appSettingsApiClient.getAppSetting())
  }

  async updateCountry(newCountry: Country, appUserType: AppUserType): Promise<void> {
    await firstValueFrom(
      this.appSettingsApiClient.updateAppSettingCountry({
        appSettingsId: (await this.getAppSettings()).id,
        country: newCountry,
        body: {
          appUserType,
          isPrivacyPolicyAccepted: true,
        },
      })
    )
  }

  async updateSyncState(newState: boolean): Promise<void> {
    await firstValueFrom(
      this.appSettingsApiClient.updateAppSetting({
        appSettingsId: (await this.getAppSettings()).id,
        body: {
          showSyncIndicator: newState,
        },
      })
    )
  }

  async updateLanguage(newLang: string): Promise<void> {
    await firstValueFrom(
      this.appSettingsApiClient.updateAppSetting({
        appSettingsId: (await this.getAppSettings()).id,
        body: {
          language: newLang,
        },
      })
    )
  }

  async findLanguage(): Promise<string> {
    return (await this.getAppSettings()).language
  }
}
