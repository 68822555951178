import { Injectable } from '@angular/core'
import { AccUser, JwtAccUser } from './acc-types'
import { AccDataService } from './acc-data.service'
import { jwtDecode } from 'jwt-decode'
import { BehaviorSubject, firstValueFrom } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ACCAuthenticationService {
  public accUser: AccUser = new AccUser()
  public connectorEnabledSource = new BehaviorSubject<boolean>(false)
  public connectorEnabled$ = this.connectorEnabledSource.asObservable()

  constructor(private readonly accDataService: AccDataService) {}

  public async initAccAuth(): Promise<void> {
    const token = await firstValueFrom(this.accDataService.initAccAuth())
    if (token) {
      this.readToken(token)
      this.checkIfConnectorEnabled()
    }
  }

  createAccToken(code: string): void {
    this.accDataService.createAccToken(code).subscribe((token) => {
      this.readToken(token)
      this.checkIfConnectorEnabled()
    })
  }

  getClientB64(clientId: string, clientSecret: string): string {
    return btoa(clientId + ':' + clientSecret)
  }

  public getAccMail(): string {
    return this.accUser.email
  }

  private readToken(idToken: string): void {
    const jwtAccUser = jwtDecode<JwtAccUser>(idToken)
    this.accUser.name = jwtAccUser.user_name
    this.accUser.email = jwtAccUser.user_email
    this.accUser.firstName = jwtAccUser.first_name
    this.accUser.lastName = jwtAccUser.last_name
  }

  async logout(): Promise<void> {
    this.accUser = new AccUser()
    await firstValueFrom(this.accDataService.logout())
    this.connectorEnabledSource.next(false)
  }

  public checkIfConnectorEnabled(): void {
    if (this.getAccMail() !== '') {
      this.accDataService
        .getHubs()
        .subscribe((hubs) => this.connectorEnabledSource.next(hubs.length > 0))
    } else {
      this.connectorEnabledSource.next(false)
    }
  }
}
