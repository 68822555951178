import { Component } from '@angular/core'
import { HttpRequestSyncService } from '../../../services/http-request-sync.service'
import { AppSettingsRepository } from '../../../repositories/app-settings.repository'
import { filter, map } from 'rxjs'
import { AppSettingsModel } from '../../../../generated/efp-api'

@Component({
  selector: 'efp-request-sync-indicator',
  templateUrl: './request-sync.indicator.component.html',
  styleUrls: ['./request-sync-indicator.component.scss'],
})
export class RequestSyncIndicatorComponent {
  constructor(
    public readonly httpRequestSyncService: HttpRequestSyncService,
    private readonly appsettingsRepo: AppSettingsRepository
  ) {}

  public readonly showSyncIndicator$ = this.appsettingsRepo.appSettings$.pipe(
    filter((appsettings): appsettings is AppSettingsModel => !!appsettings),
    map((appsettings) => appsettings.showSyncIndicator)
  )
}
