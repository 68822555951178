<ion-header class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" src="assets/icon/ic_close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title size="large"> {{ slides[index].title }} </ion-title>
  </ion-toolbar>
</ion-header>
<efp-modal-form>
  <div class="modal-inner d-flex d-flex-col">
    <ng-container *ngFor="let slide of slides; let i = index">
      <div *ngIf="this.index === i" [innerHTML]="slide.content"></div>
    </ng-container>
  </div>
</efp-modal-form>

<efp-steps-modal-footer>
  <ion-button
    button-start
    *ngIf="index !== 0"
    (click)="goToSlide(this.index - 1)"
    class="navigation-button light">
    <ion-icon slot="icon-only" src="assets/icon/ic_arrow-left.svg"></ion-icon>
  </ion-button>

  <ng-container status-indicators *ngIf="this.slides.length > 1">
    <ng-container *ngFor="let slide of slides; let i = index">
      <ion-icon
        (click)="goToSlide(i)"
        data-test-selector="feedback-modal-hint-status-indicator"
        aria-hidden="true"
        src="assets/icon/ellipse.svg"
        [ngClass]="{ active: index === i }"></ion-icon>
    </ng-container>
  </ng-container>

  <ng-container button-end>
    <ion-button
      slot="end"
      *ngIf="index < this.slides.length - 1; else lastSlide"
      (click)="goToSlide(this.index + 1)"
      class="navigation-button dark"
      data-test-selector="feedback-modal-next-slide">
      <ion-icon color="white" slot="icon-only" src="assets/icon/ic_arrow-right.svg"></ion-icon>
    </ion-button>
    <ng-template #lastSlide>
      <ion-button
        (click)="closeModal()"
        class="navigation-button dark"
        data-test-selector="onboarding-modal-next-hint">
        <ion-icon color="white" slot="icon-only" src="assets/icon/check.svg"></ion-icon>
      </ion-button>
    </ng-template>
  </ng-container>
</efp-steps-modal-footer>
