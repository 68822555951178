<form [formGroup]="planSettingsService.drawSettingsForm">
  <!--DRAWING SETTINGS-->
  <ion-list *ngIf="this.planSettings && measurementUnit">
    <efp-list-header>
      <ion-label>{{ 'SETTINGS.DRAWING_SETTINGS' | translate }}</ion-label>
    </efp-list-header>

    <efp-list-divider-item
      (contentClick)="unitSelect.open()"
      data-test-selector="drawingsettings-open-unit-selection">
      <ng-container slot="start">
        <ion-icon
          class="iconSizeS ion-padding-end"
          src="assets/icon/dimensions.svg"
          color="primary"></ion-icon>
        <ion-label>{{ 'SETTINGS.MEASUREMENT' | translate }}</ion-label>
      </ng-container>
      <ion-select
        justify="end"
        tabindex="-1"
        #unitSelect
        [interfaceOptions]="selectCustomHeaders"
        slot="end"
        interface="alert"
        cancelText="{{ 'GENERAL.CANCEL' | translate }}"
        multiple="false"
        placeholder="Select unit"
        formControlName="measurementUnit"
        data-test-selector="drawingsettings-unit-selection"
        class="p-right-24">
        <ion-select-option class="selectHeader" disabled>{{
          'GENERAL.METRIC' | translate
        }}</ion-select-option>
        <ng-container *ngFor="let item of unitsOfLength; index as i">
          <ion-select-option
            color="primary"
            [value]="item"
            *ngIf="i !== unitsOfLength.length - 1"
            attr.data-test-selector="drawingsettings-unit-selection-{{ item }}">
            {{ item }}
          </ion-select-option>
        </ng-container>
        <ion-select-option tabindex="-1" class="selectHeader" disabled>{{
          'GENERAL.IMPERIAL' | translate
        }}</ion-select-option>
        <ion-select-option value="inch" data-test-selector="drawingsettings-unit-selection-inch">{{
          'GENERAL.INCH' | translate
        }}</ion-select-option>
      </ion-select>
    </efp-list-divider-item>
    <efp-list-divider-item
      mode="md"
      *ngIf="isDrawingMode"
      (contentClick)="angleInput.setFocus()"
      data-test-selector="drawingsettings-input-angle">
      <ng-container slot="start">
        <ion-icon
          class="ion-padding-end iconSizeS"
          src="assets/icon/angle.svg"
          color="primary"></ion-icon>
        <ion-label>{{ 'SETTINGS.ANGLE_RASTERING' | translate }}</ion-label>
      </ng-container>
      <ion-input
        #angleInput
        efpInputOverwritingSelect
        formControlName="angleRastering"
        slot="end"
        type="number"
        inputmode="numeric"
        placeholder="0"
        class="ion-text-right"
        color="primary"
        efpInputKeyboard
        [disabled]="this.buildingType === 'SLAB'"></ion-input>
      <ion-label color="primary" class="measureUnit" slot="end">°</ion-label>

      <ion-note note color="danger" *ngIf="angleRastering.invalid">
        {{
          'SETTINGS.ANGLE_ERROR'
            | translate
              : {
                  minAngle: angleRasteringInterval[0],
                  maxAngle: angleRasteringInterval[1]
                }
        }}
      </ion-note>
    </efp-list-divider-item>

    <efp-list-divider-item
      mode="md"
      *ngIf="isDrawingMode || isCycleMode"
      (contentClick)="lengthRasteringInput.focus(false)"
      data-test-selector="drawingsettings-input-length-rastering">
      <ng-container slot="start">
        <ion-icon
          class="ion-padding-end iconSizeS"
          src="assets/icon/length_raster.svg"
          color="primary"></ion-icon>
        <ion-label>{{ 'SETTINGS.LENGTH_RASTERING' | translate }}</ion-label>
      </ng-container>
      <efp-length-input
        #lengthRasteringInput
        [unit]="measurementUnit.value"
        formControlName="lengthRastering">
      </efp-length-input>

      <ion-note note color="danger" *ngIf="lengthRastering.invalid">
        {{
          'SETTINGS.LENGTH_ERROR'
            | translate
              : {
                  minLength: lengthRasteringInterval[0] | efpLength : measurementUnit.value,
                  maxLength: lengthRasteringInterval[1] | efpLength : measurementUnit.value
                }
        }}
      </ion-note>
    </efp-list-divider-item>

    <!--Plan settings - Wall-->
    <ng-container
      *ngIf="(isPlanMode || isDrawingMode) && (buildingType === 'WALL' || !buildingType)">
      <efp-list-divider-item
        mode="md"
        (contentClick)="wallThicknessInput.focus(false)"
        data-test-selector="drawingsettings-input-wall-thickness">
        <ng-container slot="start">
          <ion-icon
            class="ion-padding-end iconSizeS"
            src="assets/icon/wall_thickness.svg"
            color="primary"></ion-icon>
          <ion-label>{{ 'SETTINGS.WALL_THICKNESS' | translate }}</ion-label>
        </ng-container>
        <efp-length-input
          #wallThicknessInput
          [unit]="measurementUnit.value"
          formControlName="wallThickness">
        </efp-length-input>
        <ion-note note color="danger" *ngIf="wallThickness.invalid">
          {{
            'SETTINGS.WALL_THICKNESS_ERROR'
              | translate
                : {
                    minThickness: wallThicknessInterval[0] | efpLength : measurementUnit.value,
                    maxThickness: wallThicknessInterval[1] | efpLength : measurementUnit.value
                  }
          }}
        </ion-note>
      </efp-list-divider-item>
      <efp-list-divider-item
        mode="md"
        (contentClick)="wallHeightInput.focus(false)"
        data-test-selector="drawingsettings-input-wall-height">
        <ng-container slot="start">
          <ion-icon
            class="ion-padding-end iconSizeS"
            src="assets/icon/wall_height.svg"
            color="primary"></ion-icon>
          <div class="height-note">
            <ion-label>{{ 'SETTINGS.WALL_HEIGHT' | translate }}</ion-label>
            <ion-note>
              {{ 'PLAN.MEASURE.WARNING_HEIGHT_CHANGE' | translate }}
            </ion-note>
          </div>
        </ng-container>
        <efp-length-input
          #wallHeightInput
          [unit]="measurementUnit.value"
          formControlName="wallHeight">
        </efp-length-input>

        <ion-note note *ngIf="wallHeight.invalid" color="danger">
          {{
            'SETTINGS.WALL_HEIGHT_ERROR'
              | translate
                : {
                    minHeight: wallHeightInterval[0] | efpLength : measurementUnit.value,
                    maxHeight: wallHeightInterval[1] | efpLength : measurementUnit.value
                  }
          }}
        </ion-note>
      </efp-list-divider-item>
    </ng-container>

    <!--Plan settings - Slab-->
    <ng-container *ngIf="buildingType === 'SLAB' || !buildingType">
      <efp-list-divider-item
        mode="md"
        (contentClick)="slabThicknessInput.focus(false)"
        data-test-selector="drawingsettings-input-slab-thickness">
        <ng-container slot="start">
          <ion-icon
            class="ion-padding-end iconSizeS"
            src="assets/icon/wall_thickness.svg"
            color="primary"></ion-icon>
          <ion-label>{{ 'SETTINGS.SLAB_THICKNESS' | translate }}</ion-label>
        </ng-container>
        <efp-length-input
          #slabThicknessInput
          [unit]="measurementUnit.value"
          formControlName="slabThickness">
        </efp-length-input>

        <ion-note note *ngIf="slabThickness.invalid" color="danger">
          {{
            'SETTINGS.SLAB_THICKNESS_ERROR'
              | translate
                : {
                    minThickness: slabThicknessInterval[0] | efpLength : measurementUnit.value,
                    maxThickness: slabThicknessInterval[1] | efpLength : measurementUnit.value
                  }
          }}
        </ion-note>
      </efp-list-divider-item>
      <efp-list-divider-item
        mode="md"
        (contentClick)="slabHeightInput.focus(false)"
        data-test-selector="drawingsettings-input-slab-height">
        <ng-container slot="start">
          <ion-icon
            class="ion-padding-end iconSizeS"
            src="assets/icon/wall_height.svg"
            color="primary"></ion-icon>
          <div class="height-note">
            <ion-label>{{ 'SETTINGS.SLAB_HEIGHT' | translate }}</ion-label>
            <ion-note>
              {{ 'PLAN.MEASURE.WARNING_HEIGHT_CHANGE' | translate }}
            </ion-note>
          </div>
        </ng-container>
        <efp-length-input
          #slabHeightInput
          [unit]="measurementUnit.value"
          formControlName="slabHeight">
        </efp-length-input>
        <ion-note note *ngIf="slabHeight.invalid" color="danger">
          {{
            'SETTINGS.SLAB_HEIGHT_ERROR'
              | translate
                : {
                    minHeight: slabHeightInterval[0] | efpLength : measurementUnit.value,
                    maxHeight: slabHeightInterval[1] | efpLength : measurementUnit.value
                  }
          }}
        </ion-note>
      </efp-list-divider-item>
    </ng-container>
  </ion-list>
</form>
