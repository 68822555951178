import { Edge, PlanType } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { HighlightArea } from './HighlightArea'

/**
 * The layer which contains all highlight areas required for the hover and selected effects of walls or slab edges
 */
export class HighlightAreaLayer extends paper.Layer {
  constructor() {
    super()
    this.name = 'Highlight Area Layer'
  }

  createHighlightAreas(edges: Edge[], target: PlanType): void {
    this.removeChildren()

    edges.forEach((edge) => {
      this.addChild(new HighlightArea(edge, target))
    })
  }

  public toggleSelection(edge: Edge): void {
    this.children
      .filter(isHighlightArea)
      .filter((highlightArea) => highlightArea.edge === edge)
      .forEach((highlightArea) => highlightArea.changeSelection())
  }

  public deselectAll(): void {
    this.children.filter(isHighlightArea).forEach((child) => child.hide())
  }

  public unhoverAll(): void {
    this.children.filter(isHighlightArea).forEach((child) => child.unhover())
  }

  getSelectedEdges(): Edge[] {
    const selectedHighlightAreas: HighlightArea[] = []

    this.children.forEach((key) => {
      if (key instanceof HighlightArea && key.isSelected) {
        selectedHighlightAreas.push(key)
      }
    })

    return selectedHighlightAreas.map((area) => area.edge)
  }

  findHighlightAreaNearPoint(point: paper.Point): HighlightArea | undefined {
    return this.children
      .filter(isHighlightArea)
      .find((highlightArea) => highlightArea.contains(point))
  }

  /**
   * Required for the selection rectangle - highlight areas intersecting the selection rectangle will be marked as selected
   */
  getIntersectingHighlightAreas(path: paper.Path.Rectangle): HighlightArea[] {
    const highlightAreas = this.children
      .filter(isHighlightArea)
      .filter((area) => area.intersects(path) || area.isInside(path.internalBounds))
    highlightAreas.forEach((item) => item.hover())
    return highlightAreas
  }
}

const isHighlightArea = (item: paper.Item): item is HighlightArea =>
  !!item && (item as HighlightArea).edge !== undefined
