import paper from 'paper/dist/paper-core'
import {
  Edge,
  CompoundPathWithEdgeMap,
  getOtherLinePosition,
  getOtherLineSide,
  LinePosition,
  LineSide,
  SNAPPING_ERROR_TOLERANCE,
  SNAP_ANGLE_INTERVAL,
  WallMesh,
} from 'formwork-planner-lib'
import { EdgeMap, PathStep } from '../../model/edge/EdgeMap'
import { intersectLines } from './intersectLines'

type VisitedEdgeSides = { [x in LineSide]: Set<Edge> }

interface ProcessedPath {
  path: paper.Path
  edgeMap: EdgeMap
}

/**
 * Function responsible for processing the mesh and creating the outline of the walls by traversing through all sides of all edges, calculating intersections where two edges share a mesh point
 */
export function createWallPath(mesh: WallMesh): CompoundPathWithEdgeMap {
  const allEdges = mesh.getAllEdges()
  if (allEdges.size < 1) {
    return new CompoundPathWithEdgeMap({})
  }

  allEdges.forEach((edge) => {
    edge.realLength = undefined
  })

  const edgeMaps: EdgeMap = new Map<paper.Segment, [PathStep, PathStep]>()
  const paths: paper.Path[] = []
  const visited: VisitedEdgeSides = {
    [LineSide.LEFT]: new Set<Edge>(),
    [LineSide.RIGHT]: new Set<Edge>(),
  }

  // processing edge sides & creating wall path
  ;[LineSide.LEFT, LineSide.RIGHT].forEach((startSide) => {
    ;[...allEdges]
      .sort((e1, e2) => e1.getNeighbours().length - e2.getNeighbours().length)
      .forEach((startEdge) => {
        if (visited[startSide].has(startEdge)) {
          return
        }

        const processedPath = processPath(
          {
            edge: startEdge,
            side: startSide,
            position: LinePosition.START,
          },
          visited
        )
        paths.push(processedPath.path)
        ;[...processedPath.edgeMap].forEach(([key, value]) => edgeMaps.set(key, value))
        fixOrientation(paths)
      })
  })

  // merging possible paths (see: https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/7302/T-walls-with-different-thickness)
  const mergedPaths = mergeOverlappingPaths(paths)

  // calculating edge lengths
  allEdges.forEach((edge) => {
    if (!edge.realLength) {
      edge.realLength = edge.endPoint.subtract(edge.startPoint).length
    }
  })

  // adding red outlines of invalid edges
  ;[...allEdges]
    .filter((e) => !e.isValid)
    .forEach((e) => {
      const path = e.getOutlinePath()
      paths.push(path)
    })

  return new CompoundPathWithEdgeMap({ children: mergedPaths }, edgeMaps)
}

/**
 * Tries to merge all generated subpaths if they are in the right position
 * See https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/7302/T-walls-with-different-thickness
 */
export function mergeOverlappingPaths(paths: paper.Path[]): paper.Path[] {
  const newPaths: paper.Path[] = [...paths]
  const checkedPaths = new Set<paper.Path>()
  let i = 0

  // all paths have to be compared with every path (even themselves)
  while (i < newPaths.length) {
    if (checkedPaths.has(newPaths[i])) {
      i++
      continue
    }

    let unitedPaths: paper.Path[] = []
    let j = i
    while (j < newPaths.length) {
      if (checkedPaths.has(newPaths[j])) {
        j++
        continue
      }

      unitedPaths = unitePaths(newPaths[i], newPaths[j])
      // if the paths can be united, the united path will be added and the two disconnected paths are removed
      if (unitedPaths.length > 0) {
        checkedPaths.add(newPaths[i])
        checkedPaths.add(newPaths[j])
        newPaths.push(...unitedPaths)
        break
      }
      j++
    }
    i++
  }

  return newPaths.filter((p) => !checkedPaths.has(p))
}

/**
 * Unites two disconnected paths if they are overlapping on one curve
 * See https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/7302/T-walls-with-different-thickness
 */
function unitePaths(path: paper.Path, other: paper.Path): paper.Path[] {
  let overlappingShortCurve: paper.Curve | null = null
  let overlappingLongCurve: paper.Curve | null = null

  // checking all curves of the path with all curves of the second path for overlaps
  path.curves.forEach((curve) => {
    other.curves.forEach((otherCurve) => {
      if (
        // if a short and long overlapping curves are found, the process can stop
        !overlappingShortCurve &&
        !overlappingLongCurve &&
        // the two overlapping curves can't be neighbors (this check is necessary because the two paths can be the same)
        curve !== otherCurve &&
        curve.next !== otherCurve &&
        curve.previous !== otherCurve &&
        // the two curves have to be parallel
        curve.isCollinear(otherCurve) &&
        // and they can't have the same length - otherwise walls with the same thickness would also be merged
        Math.round(curve.length) !== Math.round(otherCurve.length)
      ) {
        const shortCurve = curve.length < otherCurve.length ? curve : otherCurve
        const longCurve = curve.length < otherCurve.length ? otherCurve : curve

        // one of the endpoints of the short curve need to be in the same position with one of the endpoints of the long curve
        let shortCurveFirstPointOverlapping = false
        if (
          shortCurve.point1.isClose(longCurve.point1, SNAPPING_ERROR_TOLERANCE) ||
          shortCurve.point1.isClose(longCurve.point2, SNAPPING_ERROR_TOLERANCE)
        ) {
          shortCurveFirstPointOverlapping = true
        } else if (
          !(
            shortCurve.point2.isClose(longCurve.point1, SNAPPING_ERROR_TOLERANCE) ||
            shortCurve.point2.isClose(longCurve.point2, SNAPPING_ERROR_TOLERANCE)
          )
        ) {
          return
        }

        if (
          (shortCurveFirstPointOverlapping &&
            longCurve
              .getNearestPoint(shortCurve.point2)
              .isClose(shortCurve.point2, SNAPPING_ERROR_TOLERANCE)) ||
          (!shortCurveFirstPointOverlapping &&
            longCurve
              .getNearestPoint(shortCurve.point1)
              .isClose(shortCurve.point1, SNAPPING_ERROR_TOLERANCE))
        ) {
          overlappingShortCurve = shortCurve
          overlappingLongCurve = longCurve
        }
      }
    })
  })

  // if no overlapping curves are found, the paths cant be merged
  if (!overlappingShortCurve || !overlappingLongCurve) {
    return []
  }

  if (path === other) {
    // special case for uniting path with itself (closed results in two new subpaths)
    return unitePathWithSelf(overlappingShortCurve, overlappingLongCurve)
  } else {
    // otherwise the two paths are merged (resulting in one path only)
    return uniteDistinctPaths(overlappingShortCurve, overlappingLongCurve)
  }
}

/**
 * Unites a path with itself if it overlaps itself on one curve, resulting in a closed loop with made out of two new paths (for the inner sides and the outer sides)
 * @param shortCurve the shorter overlapping curve
 * @param longCurve the longer overlapping curve
 */
function unitePathWithSelf(shortCurve: paper.Curve, longCurve: paper.Curve): paper.Path[] {
  const shortCurveFirstPointOverlapping = longCurve.points.some((p) =>
    p.isClose(shortCurve.point1, SNAPPING_ERROR_TOLERANCE)
  )
  const longCurveFirstPointOverlapping = shortCurve.points.some((p) =>
    p.isClose(longCurve.point1, SNAPPING_ERROR_TOLERANCE)
  )

  const pathSide1 = new paper.Path()
  const pathSide2 = new paper.Path()
  const endSegment = longCurveFirstPointOverlapping ? longCurve.segment1 : longCurve.segment2

  // since we are adding new segments to the path that the long overlapping curve belongs to, we need to keep the orientation of that path
  // depending on this orientation the segments of the path that the short curve belongs to might need to be added in reverse order
  if (!shortCurveFirstPointOverlapping) {
    addSegmentsToPath(pathSide1, shortCurve.segment2, endSegment, false)
    addSegmentsToPath(pathSide2, shortCurve.segment1, endSegment, false, true, true)
  } else {
    addSegmentsToPath(pathSide1, shortCurve.segment1, endSegment, false, true, true)
    addSegmentsToPath(pathSide2, shortCurve.segment2, endSegment, false)
  }

  pathSide1.closePath()
  pathSide2.closePath()
  return [pathSide1, pathSide2]
}

/* Unites a path with another one resulting in a single new path
 * @param shortCurve the shorter overlapping curve
 * @param longCurve the longer overlapping curve
 */
function uniteDistinctPaths(shortCurve: paper.Curve, longCurve: paper.Curve): paper.Path[] {
  const path = new paper.Path()
  const shortCurveFirstPointOverlapping = longCurve.points.some((p) =>
    p.isClose(shortCurve.point1, SNAPPING_ERROR_TOLERANCE)
  )
  const longCurveFirstPointOverlapping = shortCurve.points.some((p) =>
    p.isClose(longCurve.point1, SNAPPING_ERROR_TOLERANCE)
  )

  addSegmentsToPath(path, longCurve.segment2, longCurve.segment1)

  // since we are adding new segments to the path that the long overlapping curve belongs to, we need to keep the orientation of that path
  // depending on this orientation the segments of the path that the short curve belongs to might need to be added in reverse order
  if (longCurveFirstPointOverlapping) {
    if (shortCurveFirstPointOverlapping) {
      addSegmentsToPath(path, shortCurve.segment1.previous, shortCurve.segment2, true, true, false)
    } else {
      addSegmentsToPath(path, shortCurve.segment2.next, shortCurve.segment1)
    }
  } else {
    if (shortCurveFirstPointOverlapping) {
      addSegmentsToPath(path, shortCurve.segment2, shortCurve.segment1, false)
    } else {
      addSegmentsToPath(path, shortCurve.segment1, shortCurve.segment2, false, true, false)
    }
  }

  path.closePath()
  return [path]
}

/**
 * Adds segments to a path
 * @param path the path that the segments are added to
 * @param startSegment the starting segment
 * @param endSegment the segment where the traversal stops
 * @param addLastCurve if true, the end segment is added as the last segment of the path
 * @param reverse if true, the segments are added in a reverse order
 * @param insertFront if true, new segments are added at the front of the path as the new first segment
 */
function addSegmentsToPath(
  path: paper.Path,
  startSegment: paper.Segment,
  endSegment: paper.Segment,
  addLastCurve = true,
  reverse = false,
  insertFront = false
): void {
  let currentSegment = startSegment
  while (currentSegment !== endSegment) {
    if (insertFront) {
      path.insert(0, currentSegment)
    } else {
      path.add(currentSegment)
    }

    if (reverse) {
      currentSegment = currentSegment.previous
    } else {
      currentSegment = currentSegment.next
    }
  }
  if (addLastCurve) {
    path.add(currentSegment)
  }
}

/**
 * Creates a closed path from a starting step, traverses the edges until the path is closed
 * @param startStep the starting step
 * @param visited the already visited edge sides which should be excluded from the traversal
 */
function processPath(startStep: PathStep, visited: VisitedEdgeSides): ProcessedPath {
  const path = new paper.Path()
  const edgeMap = new Map<paper.Segment, [PathStep, PathStep]>()
  const endStep = getNextStep(startStep, true)
  const startStepPoints = calculateStepPoints(endStep, startStep)
  path.moveTo(<paper.Point>startStepPoints.pop())
  edgeMap.set(path.lastSegment, [endStep, startStep])

  let currStep = { ...startStep }
  do {
    visited[currStep.side].add(currStep.edge)
    const { points, nextStep } = processStep(currStep, path.lastSegment.point)

    points.forEach((p) => {
      if (p && !p.isClose(path.lastSegment.point, 0)) {
        path.lineTo(p)
      }
      edgeMap.set(path.lastSegment, [currStep, nextStep])
    })

    currStep = nextStep
  } while (
    !visited[currStep.side]?.has(currStep.edge) &&
    !(startStep.edge === currStep.edge && startStep.side === currStep.side)
  )

  const firstSegmentPoint = path.segments[0].point
  const lastSegmentPoint = path.segments[path.segments.length - 1].point
  if (firstSegmentPoint.getDistance(lastSegmentPoint) < 0.1) {
    path.removeSegment(path.segments.length - 1)
  }
  path.closed = true

  return { path, edgeMap }
}

function processStep(
  prevStep: PathStep,
  lastPoint: paper.Point
): { nextStep: PathStep; points: paper.Point[] } {
  const nextStep = getNextStep(prevStep)

  const points = calculateStepPoints(prevStep, nextStep)

  prevStep.edge.realLength = Math.max(
    prevStep.edge.realLength ?? 0,
    lastPoint.subtract(points[0]).length
  )

  return { nextStep, points }
}

/**
 * During path traversal, this function finds and returns the next path step to process
 * @param prev the previously processed path step
 * @param reverse used to find the last step from the start point, that will end the traversal
 */
function getNextStep(prev: PathStep, reverse: boolean = false): PathStep {
  const edge = prev.edge.getNeighborOnSide(
    prev.side,
    reverse ? prev.position : getOtherLinePosition(prev.position)
  )
  const connectedPoint = edge.getConnectedPoint(prev.edge)

  let position: LinePosition
  let side: LineSide
  if (prev.edge !== edge && connectedPoint) {
    position = <LinePosition>edge.getPosition(connectedPoint)
    side = position === LinePosition.START ? LineSide.LEFT : LineSide.RIGHT

    if (prev.position === LinePosition.START && prev.side === LineSide.RIGHT) {
      side = getOtherLineSide(side)
    }
  } else {
    position = getOtherLinePosition(prev.position)
    side = getOtherLineSide(prev.side)
  }

  const nextStep: PathStep = { edge, position, side }

  if (reverse) {
    return {
      edge: nextStep.edge,
      side: getOtherLineSide(nextStep.side),
      position: getOtherLinePosition(nextStep.position),
    }
  }

  return nextStep
}

/**
 * Calculates the corner points of the path between two path step
 * @param prev the previous path step
 * @param next the next path step
 */
function calculateStepPoints(prev: PathStep, next: PathStep): paper.Point[] {
  if (next.edge === prev.edge) {
    return [
      prev.edge.getLineOnSide(prev.side)[next.position],
      prev.edge.getLineOnSide(next.side)[next.position],
    ]
  }

  const angle = Math.round(Math.abs(<number>prev.edge.getAngle(next.edge)))
  const diff = Math.abs(angle - 180)

  let point1 = prev.edge.getLineOnSide(prev.side)[getOtherLinePosition(prev.position)]
  let point2 = next.edge.getLineOnSide(next.side)[next.position]

  if (prev.edge.thickness !== next.edge.thickness && diff <= SNAP_ANGLE_INTERVAL / 2) {
    if (point1.isClose(point2, SNAPPING_ERROR_TOLERANCE)) {
      return [point1]
    } else {
      if (next.edge.thickness < prev.edge.thickness) {
        point2 =
          intersectLines(
            {
              start: prev.edge.getLineOnSide(prev.side)[getOtherLinePosition(prev.position)],
              end: prev.edge.getLineOnSide(getOtherLineSide(prev.side))[
                getOtherLinePosition(prev.position)
              ],
            },
            next.edge.getLineOnSide(next.side)
          ) ?? point2
      } else {
        point1 =
          intersectLines(prev.edge.getLineOnSide(prev.side), {
            start: next.edge.getLineOnSide(next.side)[next.position],
            end: next.edge.getLineOnSide(getOtherLineSide(next.side))[next.position],
          }) ?? point1
      }
      return [point1, point2]
    }
  }

  if (diff > SNAPPING_ERROR_TOLERANCE) {
    return [
      <paper.Point>(
        intersectLines(prev.edge.getLineOnSide(prev.side), next.edge.getLineOnSide(next.side))
      ),
    ]
  }

  return [next.edge.getLineOnSide(next.side)[next.position]]
}

function fixOrientation(paths: paper.Path[]): void {
  paths.forEach((path) => {
    let depth = 0
    paths.forEach((otherPath) => {
      if (
        otherPath !== path &&
        path.segments.map((segment) => segment.point).every((point) => otherPath.contains(point))
      ) {
        depth++
      }
    })

    if (depth % 2 === 0 ? !path.clockwise : path.clockwise) {
      path.reverse()
    }
  })
}
