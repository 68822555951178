<ion-header class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <ion-button data-test-selector="onboarding-modal-close-hint" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title size="large">{{ 'ONBOARDING.MODAL_TITLE' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<div class="onboarding-content">
  <efp-onboarding-hint-content [onboardingHint]="this.hints[this.index]">
  </efp-onboarding-hint-content>
</div>
<efp-steps-modal-footer>
  <ion-button
    button-start
    *ngIf="index !== 0"
    (click)="goToSlide(this.index - 1)"
    class="navigation-button light">
    <ion-icon slot="icon-only" src="assets/icon/ic_arrow-left.svg"></ion-icon>
  </ion-button>

  <ng-container *ngFor="let slide of hints; let i = index" status-indicators>
    <ion-icon
      (click)="goToSlide(i)"
      data-test-selector="feedback-modal-hint-status-indicator"
      aria-hidden="true"
      src="assets/icon/ellipse.svg"
      [ngClass]="{ active: index === i }"></ion-icon>
  </ng-container>

  <ng-container button-end>
    <ion-button
      slot="end"
      *ngIf="index < this.hints.length - 1; else lastSlide"
      (click)="goToSlide(this.index + 1)"
      class="navigation-button dark"
      data-test-selector="feedback-modal-next-slide">
      <ion-icon color="white" slot="icon-only" src="assets/icon/ic_arrow-right.svg"></ion-icon>
    </ion-button>
    <ng-template #lastSlide>
      <ion-button
        (click)="closeModal()"
        class="navigation-button dark"
        data-test-selector="onboarding-modal-next-hint">
        <ion-icon color="white" slot="icon-only" src="assets/icon/check.svg"></ion-icon>
      </ion-button>
    </ng-template>
  </ng-container>
</efp-steps-modal-footer>
