import { EventEmitter, Injectable } from '@angular/core'
// eslint-disable-next-line no-restricted-imports
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { AppSettingsRepository } from '../repositories/app-settings.repository'
import { firstValueFrom } from 'rxjs'

export const defaultLanguage = 'en-gb'

const allLanguages = [
  defaultLanguage,
  'en-us',
  'de',
  'es',
  'fr',
  'hu',
  'it',
  'pl',
  'ru',
  'sk',
  'no',
] as const

const tiposLanguageMapping: Record<(typeof allLanguages)[number], string> = {
  'en-gb': 'en-gb',
  'en-us': 'en-us',
  de: 'de-de',
  es: 'es',
  fr: 'fr',
  hu: 'hu',
  it: 'it',
  pl: 'pl',
  ru: 'ru',
  sk: 'sk',
  no: 'no',
}

@Injectable({
  providedIn: 'root',
})
export class Translation {
  constructor(
    private readonly translateService: TranslateService,
    private readonly appSettingsRepository: AppSettingsRepository
  ) {}

  public getAvailableLanguages(): Readonly<string[]> {
    return allLanguages.concat()
  }

  public async initTranslation(isLoggedIn: boolean): Promise<void> {
    this.translateService.addLangs(this.getAvailableLanguages().concat())

    let languageCode = 'en-gb'
    try {
      if (isLoggedIn) {
        languageCode = (await this.appSettingsRepository.getAppSettings()).language
      } else {
        languageCode = this.translateService.getBrowserLang() ?? 'en-gb'
      }
    } catch (err: unknown) {
      console.error('Not logged in yet and settings are not available', err)
    }

    await this.changeLanguage(languageCode)
  }

  public getCurrentTiposLanguage(): string {
    return (
      tiposLanguageMapping[this.getCurrentLanguage() as (typeof allLanguages)[number]] ??
      defaultLanguage
    )
  }

  public async changeLanguage(newLanguage: string): Promise<void> {
    let languageCode = newLanguage
    if (languageCode === 'en' || !allLanguages.find((lang) => lang === languageCode)) {
      languageCode = defaultLanguage
    }

    await firstValueFrom(this.translateService.use(languageCode))
  }

  public getCurrentLanguage(): string {
    return this.translateService.currentLang
  }

  public langChangedEvent(): EventEmitter<LangChangeEvent> {
    return this.translateService.onLangChange
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public translate(key: string, params?: Object): string {
    return this.translateService.instant(key, params)
  }

  public async updateLanguageFromAppSettings(): Promise<void> {
    const languageCode = (await this.appSettingsRepository.getAppSettings()).language
    await this.changeLanguage(languageCode)
  }
}
