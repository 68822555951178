import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subject, Subscription, takeUntil } from 'rxjs'
import { ACCAuthenticationService } from '../../../services/acc-auth.service'
import { TooltipPosition } from '../../directives/tooltip.directive'
import { AlertController } from '@ionic/angular'
import { Translation } from '../../../services/translation.service'
import { accAuthConfig } from '../../../../environments/environment'

@Component({
  selector: 'efp-acc-settings',
  templateUrl: './acc-settings.component.html',
  styleUrls: ['./acc-settings.component.scss'],
})
export class AccSettingsComponent implements OnInit, OnDestroy {
  private readonly pageLeave$ = new Subject<void>()
  private queryParamsSubscription: Subscription | undefined
  public accMail = ''
  public accConnectorEnabled$ = this.accAuthService.connectorEnabled$
  public tooltipPosition = TooltipPosition

  constructor(
    private readonly accAuthService: ACCAuthenticationService,
    public readonly activatedRoute: ActivatedRoute,
    public readonly router: Router,
    private readonly translate: Translation,
    private readonly alertCtrl: AlertController
  ) {}

  ngOnInit(): void {
    this.queryParamsSubscription?.unsubscribe()
    this.queryParamsSubscription = this.activatedRoute.queryParams
      .pipe(takeUntil(this.pageLeave$))
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      .subscribe(async (params) => {
        if (Object.keys(params).length > 0 && 'code' in params) {
          // call Auth endpoint
          this.accAuthService.createAccToken(params.code)

          this.deleteQueryParam('code')
        }
      })
  }

  ngOnDestroy(): void {
    this.pageLeave$.next()
  }

  public async connectToAcc(): Promise<void> {
    window.location.href =
      accAuthConfig.url +
      `?response_type=code` +
      `&client_id=` +
      accAuthConfig.client_id +
      `&redirect_uri=` +
      window.document.URL +
      `&scope=` +
      accAuthConfig.scope
  }

  public getAccUserMail(): string {
    return this.accAuthService.getAccMail()
  }

  private deleteQueryParam(paramKey: string): void {
    // Obtain the current query parameters
    const queryParams = { ...this.activatedRoute.snapshot.queryParams }

    // Set the parameter you want to delete to null
    queryParams[paramKey] = null

    // Navigate without changing the route and replace the URL
    void this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
      replaceUrl: true, // remove this if you want to keep navigation history
    })
  }

  public async unlinkAccAccount(): Promise<void> {
    await this.accAuthService.logout()
  }

  public async unlinkAlert(): Promise<void> {
    const alert = await this.alertCtrl.create({
      cssClass: ['alertStyle', 'alertStyleTwoButtons'],
      backdropDismiss: true,
      header: this.translate.translate('ACC.DISCONNECT_TITLE'),
      message: this.translate.translate('ACC.DISCONNECT_TEXT'),
      buttons: [
        {
          text: this.translate.translate('GENERAL.CANCEL'),
          role: 'cancel',
        },
        {
          text: this.translate.translate('GENERAL.OK'),
          handler: async () => {
            void this.unlinkAccAccount()
          },
        },
      ],
    })
    await alert.present()
  }

  getAuthText(checkIfConnectorEnabled: boolean | null): string {
    if (checkIfConnectorEnabled) return this.translate.translate('ACC.AUTHORIZED')
    else return this.translate.translate('ACC.UNAUTHORIZED')
  }
}
