// export interface AccRootObject {
//   data: AccObject[] | AccObject
// }

// export interface AccObject {
//   type: string
//   id?: string
//   attributes: Attributes
//   links?: Links
//   relationships: Relationships
// }

// export interface Attributes {
//   name: string
//   scopes?: string[]
//   extension?: Extension
//   region?: string
// }

// export interface Extension {
//   type: string
//   version: string
//   schema: Schema
//   data: Record<string, unknown> // Using Record type for an empty object or any object structure
// }

// export interface Schema {
//   href: string
// }

// export interface Links {
//   self: Href
//   webView: Href
// }

// export interface Relationships {
//   target?: {
//     data: {
//       type: string
//       id: string
//     }
//   }
//   projects?: {
//     links: {
//       related: Href
//     }
//   }
//   hub?: {
//     data: {
//       type: string
//       id: string
//     }
//     links: {
//       related: {
//         href: string
//       }
//     }
//   }
//   rootFolder?: {
//     data: {
//       type: string
//       id: string
//     }
//     meta: {
//       link: {
//         href: string
//       }
//     }
//   }
//   topFolders?: {
//     links: {
//       related: {
//         href: string
//       }
//     }
//   }
//   issues?: {
//     data: {
//       type: string
//       id: string
//     }
//     meta: {
//       link: {
//         href: string
//       }
//     }
//   }
//   submittals?: {
//     data: {
//       type: string
//       id: string
//     }
//     meta: {
//       link: {
//         href: string
//       }
//     }
//   }
//   rfis?: {
//     data: {
//       type: string
//       id: string
//     }
//     meta: {
//       link: {
//         href: string
//       }
//     }
//   }
//   markups?: {
//     data: {
//       type: string
//       id: string
//     }
//     meta: {
//       link: {
//         href: string
//       }
//     }
//   }
//   checklists?: {
//     data: {
//       type: string
//       id: string
//     }
//     meta: {
//       link: {
//         href: string
//       }
//     }
//   }
//   cost?: {
//     data: {
//       type: string
//       id: string
//     }
//     meta: {
//       link: {
//         href: string
//       }
//     }
//   }
//   locations?: {
//     data: {
//       type: string
//       id: string
//     }
//     meta: {
//       link: {
//         href: string
//       }
//     }
//   }
// }

// export interface Href {
//   href: string
// }

// End of general object types

export interface AccAuthResponse {
  access_token: string
  expires_in: string
  refresh_token: string
  token_type: string
  id_token: string
}

export interface JwtAccUser {
  user_name: string
  user_email: string
  first_name: string
  last_name: string
}

export class AccUser {
  public name: string
  public firstName: string
  public lastName: string
  public email: string

  constructor() {
    this.name = ''
    this.firstName = ''
    this.lastName = ''
    this.email = ''
  }
}
