/* eslint-disable max-len */
import { Directive, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { AdvertisementRepository } from '../../repositories/advertisement.repository'
import { MultiStepModalComponent } from '../components/multi-step-modal/multi-step-modal.component'
import { Slide } from '../../models/slide'
import {
  ApplicationInsightsService,
  ApplicationInsightsStates,
} from '../../services/applicationInsights.service'

@Directive({
  selector: '[efpAdvertisement]',
})
export class AdvertisementDirective implements OnInit {
  constructor(
    private readonly modalCtrl: ModalController,
    private readonly adsRepo: AdvertisementRepository,
    private readonly appInsightsService: ApplicationInsightsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.adsRepo.handleRetrigger()

    const ads = await this.adsRepo.getFirst()
    if (ads && ads?.advertisementPages.length > 0) {
      const slides: Slide[] = []
      ads?.advertisementPages.forEach((page) => {
        slides.push({ title: page.title, content: page.htmlContent })
      })

      const modal = await this.modalCtrl.create({
        componentProps: {
          slides,
        },
        component: MultiStepModalComponent,
        backdropDismiss: false,
        cssClass: 'soft-edges-modal',
      })
      await modal.present()
      await modal.onDidDismiss()
      await this.adsRepo.increaseActionCount(ads)

      this.appInsightsService.addUserEvent(
        ApplicationInsightsStates.ADVERTISEMENT_SEEN + ' ' + ads?.id
      )
    }
  }
}
