import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core'

export function isSelectorPresented(selector: string): boolean {
  const modal = document.querySelector(selector)
  return modal ? true : false
}
@Directive({
  selector: '[efpKeyboardShortcut]',
})
export class KeyboardShortcutDirective {
  @Output() readonly deletionShortcut = new EventEmitter<void>()
  @Output() readonly enterShortcut = new EventEmitter<void>()
  @Output() readonly tabShortcut = new EventEmitter<boolean>()
  @Output() readonly escShortcut = new EventEmitter<void>()
  @Output() readonly numberShortcut = new EventEmitter<number>()
  @Output() readonly dShortcut = new EventEmitter<void>()
  @Output() readonly cShortcut = new EventEmitter<void>()
  @Output() readonly eShortcut = new EventEmitter<void>()
  @Output() readonly iShortcut = new EventEmitter<void>()
  @Output() readonly aShortcut = new EventEmitter<void>()
  @Output() readonly ctrlShortcut = new EventEmitter<boolean>()
  @Output() readonly ctrlAShortcut = new EventEmitter<void>()
  @Output() readonly ctrlZShortcut = new EventEmitter<void>()
  @Output() readonly ctrlYShortcut = new EventEmitter<void>()

  private ctrlPressed = false

  constructor(private element: ElementRef) {}

  @HostListener('window:keydown', ['$event'])
  // eslint-disable-next-line complexity
  onKeydown(event: KeyboardEvent): void {
    if (isSelectorPresented('efp-feedback')) return

    switch (event.key) {
      case 'Delete':
        this.deletionShortcut.emit()
        break
      case 'Backspace':
        this.deletionShortcut.emit()
        break
      case 'Enter':
        this.enterShortcut.emit()
        break
      case 'Escape':
        this.escShortcut.emit()
        break
      case 'Tab':
        this.tabShortcut.emit(event.shiftKey)
        break
      case 'c':
        this.cShortcut.emit()
        break
      case 'd':
        this.dShortcut.emit()
        break
      case 'e':
        this.eShortcut.emit()
        break
      case 'i':
        this.iShortcut.emit()
        break
      case 'Control':
        this.ctrlShortcut.emit(true)
        this.ctrlPressed = true
        break
      case 'a':
        if (this.ctrlPressed) {
          // necessary - otherwise all text of the page is selected
          event.preventDefault()
          this.ctrlAShortcut.emit()
        } else this.aShortcut.emit()
        break
      case 'y':
        if (this.ctrlPressed) {
          this.ctrlYShortcut.emit()
        }
        break
      case 'z':
        if (this.ctrlPressed) {
          this.ctrlZShortcut.emit()
        }
        break
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
        this.numberShortcut.emit(+event.key)
        break
      default:
        break
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Control') {
      this.ctrlShortcut.emit(false)
      this.ctrlPressed = false
    }
  }
}
