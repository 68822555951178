import paper from 'paper/dist/paper-core'
import {
  ACCESSORY_CIRCLE_STROKE_COLOR,
  BLACK,
  PRIMARY_COLOR,
  TRANSPARENT_WORKAROUND,
} from '../../../constants/colors'

/*
Deals with everything regarding the assignment of accessories to the side of an edge.
Represents one straight line of the outer shell of the construct, regardless of edges and mesh-points.
 */
import {
  ACCESSORY_DASHED_ARRAY,
  ACCESSORY_DASHED_ARRAY_HOVER,
  ACCESSORY_LINE_HOVER_AREA,
  ACCESSORY_STROKE_WIDTH,
  ACCESSORY_ZOOM_INCREASE_FACTOR,
  HIGHLIGHTED_STROKE_WIDTH,
} from '../../planner/model/snapping/constants'
import {
  ACCESSORY_CIRCLE_RADIUS,
  ACCESSORY_CIRCLE_STROKE_WIDTH,
  ACCESSORY_LABEL_FONT_SIZE,
  ACCESSORY_LABEL_VERTICAL_OFFSET,
  ACCESSORY_WALL_MIN_LENGTH,
} from '../accessory-constants'
import { AccessoryPart } from './AccessoryPart'
import { BehaviorSubject, Subject, takeUntil } from 'rxjs'
import { SNAPPING_ERROR_TOLERANCE } from '../../../shared/formwork-planner'

export class AccessoryLineDisplay extends paper.Group {
  private accessories: AccessoryPart[] = []
  private line: paper.Path
  private hoverLine: paper.Path | undefined
  private circles: paper.Path.Circle[] = []
  private labels: paper.PointText[] = []
  private lineSelected = new BehaviorSubject<boolean>(false)
  private currentZoom: number

  private readonly destroy$ = new Subject<void>()

  constructor(
    public readonly databaseId: number,
    public start: paper.Point,
    public end: paper.Point,
    accessories: AccessoryPart[],
    private _zoom: number
  ) {
    super()
    this.line = this.createHighlightLine()
    this.currentZoom = _zoom
    this.accessories = accessories
    this.drawAccessories()
    this.setStylingOnSelectedChange()
  }

  public destroy(): void {
    this.destroy$.next()
  }

  public toggleSelection(): void {
    this.lineSelected.next(!this.lineSelected.value)
  }

  public setSelection(sel: boolean): void {
    this.lineSelected.next(sel)
  }

  get isSelected(): boolean {
    return this.lineSelected.value
  }

  private getStrokeWidthWithZoom(current: number): number {
    return current * this.currentZoom * ACCESSORY_ZOOM_INCREASE_FACTOR
  }

  private getDashArrayWithZoom(current: number[]): number[] {
    return current.map((x) => x * this.currentZoom * ACCESSORY_ZOOM_INCREASE_FACTOR)
  }

  // returns the minimum distance from the given point to the AccessoryLine
  public minimumDistance(point: paper.Point): number {
    return this.line.getNearestPoint(point).getDistance(point)
  }

  public isNeighbour(potentialNeighbour: AccessoryLineDisplay): boolean {
    return (
      this.minimumDistance(potentialNeighbour.start) < SNAPPING_ERROR_TOLERANCE ||
      this.minimumDistance(potentialNeighbour.end) < SNAPPING_ERROR_TOLERANCE
    )
  }

  public setAccessories(accessories: AccessoryPart[]): void {
    this.accessories = accessories
    this.drawAccessories()
  }

  public getAccessories(): AccessoryPart[] {
    return this.accessories
  }

  public hasAccessory(accessory: AccessoryPart): boolean {
    return this.accessories.some((assigned) => assigned.matches(accessory))
  }

  public hasAccessories(): boolean {
    return this.accessories.length !== 0
  }

  private createHighlightLine(): paper.Path {
    this.line?.remove()
    const line = new paper.Path.Line(this.start, this.end)
    line.selectedColor = PRIMARY_COLOR
    line.strokeWidth = this.getStrokeWidthWithZoom(ACCESSORY_STROKE_WIDTH)
    this.line = line
    this.addChild(line)

    if (line.length > ACCESSORY_WALL_MIN_LENGTH) {
      this.addHoverLine(line)
    }

    return line
  }

  private addHoverLine(line: paper.Path.Line): void {
    this.hoverLine = line.clone()
    this.hoverLine.strokeColor = TRANSPARENT_WORKAROUND // transparent wont trigger the mouse events
    this.hoverLine.strokeWidth = ACCESSORY_LINE_HOVER_AREA // increase the area for the hover effect

    this.addChild(this.hoverLine)

    this.hoverLine.onMouseEnter = () => {
      this.setHoverStyling(true)
    }
    this.hoverLine.onMouseLeave = () => {
      this.setHoverStyling(false)
    }
  }

  private setStylingOnSelectedChange(): void {
    // this is called on init with false
    this.lineSelected.pipe(takeUntil(this.destroy$)).subscribe((selected) => {
      if (selected) {
        this.line.strokeWidth = this.getStrokeWidthWithZoom(HIGHLIGHTED_STROKE_WIDTH)
        this.line.dashArray = []
      } else {
        this.line.strokeWidth = this.getStrokeWidthWithZoom(ACCESSORY_STROKE_WIDTH)

        if (this.line.length > ACCESSORY_WALL_MIN_LENGTH) {
          this.line.dashArray = this.getDashArrayWithZoom(ACCESSORY_DASHED_ARRAY)
          this.line.strokeColor = PRIMARY_COLOR
        } else {
          // own styling for not selectable walls
          this.line.strokeColor = BLACK
        }
      }
    })
  }

  private setHoverStyling(visible: boolean): void {
    if (visible) {
      if (!this.lineSelected.value) {
        this.line.dashArray = this.getDashArrayWithZoom(ACCESSORY_DASHED_ARRAY_HOVER)
      }
    } else {
      if (!this.lineSelected.value) {
        this.line.dashArray = this.getDashArrayWithZoom(ACCESSORY_DASHED_ARRAY)
      }
    }
  }

  public adaptStylingToZoom(zoom: number): void {
    this.currentZoom = zoom
    if (!this.lineSelected.value) {
      this.line.strokeWidth = this.getStrokeWidthWithZoom(ACCESSORY_STROKE_WIDTH)
    } else {
      this.line.strokeWidth = this.getStrokeWidthWithZoom(HIGHLIGHTED_STROKE_WIDTH)
    }

    if (this.line.length > ACCESSORY_WALL_MIN_LENGTH && !this.lineSelected.value) {
      this.line.dashArray = this.getDashArrayWithZoom(ACCESSORY_DASHED_ARRAY)
    }
  }

  private drawAccessories(): void {
    this.circles.forEach((circle) => circle.remove())
    this.circles = []
    this.labels.forEach((label) => label.remove())
    this.labels = []

    this.accessories.forEach((accessory, i) => {
      const circle = new paper.Path.Circle(this.getCirclePosition(i), ACCESSORY_CIRCLE_RADIUS)
      circle.strokeColor = ACCESSORY_CIRCLE_STROKE_COLOR
      circle.strokeWidth = this.getStrokeWidthWithZoom(ACCESSORY_CIRCLE_STROKE_WIDTH)
      circle.fillColor = accessory.color
      this.addChild(circle)
      this.circles.push(circle)

      const label = new paper.PointText(
        circle.position.add(new paper.Point(0, ACCESSORY_LABEL_VERTICAL_OFFSET))
      )
      label.content = accessory.orderPosition.toString()
      label.justification = 'center'
      label.fontSize = ACCESSORY_LABEL_FONT_SIZE
      this.addChild(label)
      this.labels.push(label)
    })
  }

  private getCirclePosition(index: number): paper.Point {
    const amount = this.accessories.length
    const circleDiameter =
      2 * ACCESSORY_CIRCLE_RADIUS + this.getStrokeWidthWithZoom(ACCESSORY_CIRCLE_STROKE_WIDTH)

    const line = this.end.subtract(this.start)
    const length = this.start.getDistance(this.end)
    const middle = this.start.add(line.normalize(length / 2))
    const first = middle.add(line.normalize(circleDiameter * (amount / 2 - 0.5)))

    return first.subtract(line.normalize(circleDiameter * index))
  }

  public get accessoriesAsString(): string {
    return this.accessories.map((it) => it.id).join(',')
  }

  public get length(): number {
    return this.start.getDistance(this.end)
  }
}
