import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { ModalController } from '@ionic/angular'
import { Swiper } from 'swiper/types'
import { Screenshot } from '../../../../../models/screenshot'
import { PlanSettings } from '../../../../../models/planSettings'
import { PlanType } from '../../../../../shared/formwork-planner'
import { TopSafeAreaBackgroundColor } from '../../../../../shared/components/safe-area-top/safe-area-top.component'

@Component({
  selector: 'efp-screenshot-modal',
  templateUrl: './screenshot-modal.component.html',
  styleUrls: ['./screenshot-modal.component.scss'],
})
export class ScreenshotModalComponent implements AfterViewInit {
  @Input() initialScreen?: Screenshot
  @Input() screens: Screenshot[] = []
  @Input() isSlab = false
  @Input() planSettings: PlanSettings | undefined
  @Input() buildingType: PlanType | undefined

  @Input() singleImgSrc?: string
  @Input() title?: string

  @ViewChild('swiperContainer') swiperContainerRef!: ElementRef

  public swiper?: Swiper
  public topSafeAreaBackgroundColor = TopSafeAreaBackgroundColor.Transparent

  get currentIndex(): number {
    return this.swiper?.activeIndex ?? 0
  }

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly sanitizer: DomSanitizer
  ) {}

  ngAfterViewInit(): void {
    const swiperElement = this.swiperContainerRef.nativeElement
    const initialIndex =
      this.screens.findIndex((screen) => screen.id === this.initialScreen?.id) ?? 0

    const swiperOpts = {
      initialSlide: initialIndex,
      slidesPerView: 1,
      zoom: {
        toggle: true,
        minRatio: 1,
        maxRatio: 3,
      },
    }

    Object.assign(swiperElement, swiperOpts)
    swiperElement.initialize()
    this.swiper = swiperElement.swiper
  }

  // woraround for ios
  getSafeUrl(imageData: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageData)
  }

  closeModal(): void {
    void this.modalCtrl.dismiss()
  }
}
