import { Component, Input, OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

export interface UploadProperties {
  name: string
  maxSize: number
  uploadCount: BehaviorSubject<number>
}

@Component({
  selector: 'efp-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss'],
})
export class UploadProgressComponent implements OnInit {
  @Input({ required: true }) allUploadProperties: UploadProperties[] = []

  constructor() {}

  ngOnInit(): void {}

  public getProgress(uploadProps: UploadProperties): number {
    return uploadProps.uploadCount.value / uploadProps.maxSize
  }
}
