/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AccCreateFolderCommandParams } from '../models/acc-create-folder-command-params';
import { AccGetFolderDataCommandParams } from '../models/acc-get-folder-data-command-params';
import { AccGetTopFoldersCommandParams } from '../models/acc-get-top-folders-command-params';
import { AccHubData } from '../models/acc-hub-data';
import { AccProjectData } from '../models/acc-project-data';
import { AccUploadDataCommandParams } from '../models/acc-upload-data-command-params';
import { FolderContentsData } from '../models/folder-contents-data';
import { TopFoldersData } from '../models/top-folders-data';

@Injectable({ providedIn: 'root' })
export class AccApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `auth()` */
  static readonly AuthPath = '/api/v1/acc/auth';

  /**
   * Get new Token with the refresh Token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auth$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  auth$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.AuthPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get new Token with the refresh Token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auth$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  auth$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.auth$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * Get new Token with the refresh Token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auth()` instead.
   *
   * This method doesn't expect any request body.
   */
  auth$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.AuthPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get new Token with the refresh Token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  auth(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.auth$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `token()` */
  static readonly TokenPath = '/api/v1/acc/token/{code}';

  /**
   * Get Access Token and store in DB.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `token$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  token$Plain$Response(
    params: {
      code: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.TokenPath, 'post');
    if (params) {
      rb.path('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get Access Token and store in DB.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `token$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  token$Plain(
    params: {
      code: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.token$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * Get Access Token and store in DB.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `token()` instead.
   *
   * This method doesn't expect any request body.
   */
  token$Response(
    params: {
      code: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.TokenPath, 'post');
    if (params) {
      rb.path('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get Access Token and store in DB.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `token$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  token(
    params: {
      code: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.token$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `logout()` */
  static readonly LogoutPath = '/api/v1/acc/logout';

  /**
   * Logout from Acc.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logout()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.LogoutPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Logout from Acc.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.logout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `hubs()` */
  static readonly HubsPath = '/api/v1/acc/hubs';

  /**
   * Get all Hubs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hubs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  hubs$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AccHubData>>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.HubsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccHubData>>;
      })
    );
  }

  /**
   * Get all Hubs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hubs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hubs$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AccHubData>> {
    return this.hubs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccHubData>>): Array<AccHubData> => r.body)
    );
  }

  /**
   * Get all Hubs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hubs()` instead.
   *
   * This method doesn't expect any request body.
   */
  hubs$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AccHubData>>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.HubsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccHubData>>;
      })
    );
  }

  /**
   * Get all Hubs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hubs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hubs(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AccHubData>> {
    return this.hubs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccHubData>>): Array<AccHubData> => r.body)
    );
  }

  /** Path part for operation `projects()` */
  static readonly ProjectsPath = '/api/v1/acc/projects';

  /**
   * Get all Projects.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projects$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  projects$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AccProjectData>>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.ProjectsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccProjectData>>;
      })
    );
  }

  /**
   * Get all Projects.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projects$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projects$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AccProjectData>> {
    return this.projects$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccProjectData>>): Array<AccProjectData> => r.body)
    );
  }

  /**
   * Get all Projects.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projects()` instead.
   *
   * This method doesn't expect any request body.
   */
  projects$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AccProjectData>>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.ProjectsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccProjectData>>;
      })
    );
  }

  /**
   * Get all Projects.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projects(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AccProjectData>> {
    return this.projects$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccProjectData>>): Array<AccProjectData> => r.body)
    );
  }

  /** Path part for operation `project()` */
  static readonly ProjectPath = '/api/v1/acc/project';

  /**
   * Get project by Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `project$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  project$Plain$Response(
    params?: {
      projectId?: string;
      hubId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccProjectData>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.ProjectPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('hubId', params.hubId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccProjectData>;
      })
    );
  }

  /**
   * Get project by Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `project$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  project$Plain(
    params?: {
      projectId?: string;
      hubId?: string;
    },
    context?: HttpContext
  ): Observable<AccProjectData> {
    return this.project$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccProjectData>): AccProjectData => r.body)
    );
  }

  /**
   * Get project by Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `project()` instead.
   *
   * This method doesn't expect any request body.
   */
  project$Response(
    params?: {
      projectId?: string;
      hubId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccProjectData>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.ProjectPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('hubId', params.hubId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccProjectData>;
      })
    );
  }

  /**
   * Get project by Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `project$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  project(
    params?: {
      projectId?: string;
      hubId?: string;
    },
    context?: HttpContext
  ): Observable<AccProjectData> {
    return this.project$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccProjectData>): AccProjectData => r.body)
    );
  }

  /** Path part for operation `folder()` */
  static readonly FolderPath = '/api/v1/acc/folder';

  /**
   * Create Folder.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `folder$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  folder$Plain$Response(
    params: {
      body: AccCreateFolderCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.FolderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create Folder.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `folder$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  folder$Plain(
    params: {
      body: AccCreateFolderCommandParams
    },
    context?: HttpContext
  ): Observable<string> {
    return this.folder$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * Create Folder.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `folder()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  folder$Response(
    params: {
      body: AccCreateFolderCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.FolderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create Folder.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `folder$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  folder(
    params: {
      body: AccCreateFolderCommandParams
    },
    context?: HttpContext
  ): Observable<string> {
    return this.folder$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `topfolder()` */
  static readonly TopfolderPath = '/api/v1/acc/topfolder';

  /**
   * Get All Topfolders.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `topfolder$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  topfolder$Plain$Response(
    params: {
      body: AccGetTopFoldersCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<TopFoldersData>>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.TopfolderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TopFoldersData>>;
      })
    );
  }

  /**
   * Get All Topfolders.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `topfolder$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  topfolder$Plain(
    params: {
      body: AccGetTopFoldersCommandParams
    },
    context?: HttpContext
  ): Observable<Array<TopFoldersData>> {
    return this.topfolder$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TopFoldersData>>): Array<TopFoldersData> => r.body)
    );
  }

  /**
   * Get All Topfolders.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `topfolder()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  topfolder$Response(
    params: {
      body: AccGetTopFoldersCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<TopFoldersData>>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.TopfolderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TopFoldersData>>;
      })
    );
  }

  /**
   * Get All Topfolders.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `topfolder$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  topfolder(
    params: {
      body: AccGetTopFoldersCommandParams
    },
    context?: HttpContext
  ): Observable<Array<TopFoldersData>> {
    return this.topfolder$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TopFoldersData>>): Array<TopFoldersData> => r.body)
    );
  }

  /** Path part for operation `folderData()` */
  static readonly FolderDataPath = '/api/v1/acc/folderData';

  /**
   * Get Data from Folder.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `folderData$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  folderData$Plain$Response(
    params: {
      body: AccGetFolderDataCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<FolderContentsData>>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.FolderDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FolderContentsData>>;
      })
    );
  }

  /**
   * Get Data from Folder.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `folderData$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  folderData$Plain(
    params: {
      body: AccGetFolderDataCommandParams
    },
    context?: HttpContext
  ): Observable<Array<FolderContentsData>> {
    return this.folderData$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FolderContentsData>>): Array<FolderContentsData> => r.body)
    );
  }

  /**
   * Get Data from Folder.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `folderData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  folderData$Response(
    params: {
      body: AccGetFolderDataCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<FolderContentsData>>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.FolderDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FolderContentsData>>;
      })
    );
  }

  /**
   * Get Data from Folder.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `folderData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  folderData(
    params: {
      body: AccGetFolderDataCommandParams
    },
    context?: HttpContext
  ): Observable<Array<FolderContentsData>> {
    return this.folderData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FolderContentsData>>): Array<FolderContentsData> => r.body)
    );
  }

  /** Path part for operation `upload()` */
  static readonly UploadPath = '/api/v1/acc/upload';

  /**
   * Upload result data from EFP.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upload()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upload$Response(
    params: {
      body: AccUploadDataCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccApiClient.UploadPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Upload result data from EFP.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upload$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upload(
    params: {
      body: AccUploadDataCommandParams
    },
    context?: HttpContext
  ): Observable<void> {
    return this.upload$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
