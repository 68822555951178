import { Injectable } from '@angular/core'
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, catchError, throwError } from 'rxjs'
import { APP_VERSION_REQUEST } from '../../constants/versions'
import { HttpRequestCheckAppVersionService } from '../../services/http-request-check-app-version.service'

@Injectable()
export class CheckAppVersionInterceptor implements HttpInterceptor {
  constructor(private readonly httpRequestAppVersionService: HttpRequestCheckAppVersionService) {}

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const appVersionReq = req.clone({
      headers: req.headers.set('X-App-Version', APP_VERSION_REQUEST.toString()),
    })

    return next.handle(appVersionReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 426) {
            this.httpRequestAppVersionService.appVersionError$.next()
          }
        }
        return throwError(() => new Error(error))
      })
    )
  }
}
