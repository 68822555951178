import { Injectable } from '@angular/core'
import { Preferences } from '@capacitor/preferences'
import { Country } from '@efp/api'
import { allCountries } from '../models/country'

const DEVICE_COUNTRY_CODE_USED = 'DEVICE_COUNTRY_CODE_USED'
const ONBOARDING_HINT_IDX_PLANNER = 'ONBOARDING_HINT_INDEX_PLANNER'
const ONBOARDING_HINT_IDX_CYCLES = 'ONBOARDING_HINT_INDEX_CYCLES'
const ONBOARDING_HINT_IDX_ACCESSORIES = 'ONBOARDING_HINT_INDEX_ACCESSORIES'
const MIGRATION_SUCCEEDED = 'MIGRATION_SUCCEEDED'
const CLOUD_SAVE_ACCEPTED = 'CLOUD_SAVE_ACCEPTED'

/**
 * Stores preferences per locally per device.
 * This data  can be used to store device-specific data and
 * survives a page-reload in Web and is persistent for a mobile device.
 *
 * Should <b>not</b> be used to store user specific data, store those in the AppSettings
 */
@Injectable({ providedIn: 'root' })
export class DevicePreferencesService {
  public async fetchDeviceCountryCode(): Promise<Country | undefined> {
    const countryCodeResult = await Preferences.get({ key: DEVICE_COUNTRY_CODE_USED })

    return allCountries.find((it) => it.toString() === countryCodeResult.value)
  }

  public async setDeviceCountryCode(value: Country): Promise<void> {
    return Preferences.set({
      key: DEVICE_COUNTRY_CODE_USED,
      value: value.toString(),
    })
  }

  public async fetchCloudSaveAccepted(): Promise<boolean> {
    return Preferences.get({ key: CLOUD_SAVE_ACCEPTED }).then((result) => result.value === 'true')
  }

  public async setCloudSaveAccepted(value: boolean): Promise<void> {
    return Preferences.set({
      key: CLOUD_SAVE_ACCEPTED,
      value: value.toString(),
    })
  }

  public async fetchMigrationSucceeded(): Promise<boolean> {
    return await Preferences.get({ key: MIGRATION_SUCCEEDED }).then(
      (result) => result.value === 'true'
    )
  }

  public async setMigrationSucceeded(value: boolean): Promise<void> {
    return Preferences.set({
      key: MIGRATION_SUCCEEDED,
      value: value.toString(),
    })
  }

  /* ONBOARDING HINTS */
  /**
   * @deprecated should be stored on the backend to be persisted
   */
  public async fetchOnboardingHintIndexPlanner(): Promise<number> {
    return Preferences.get({ key: ONBOARDING_HINT_IDX_PLANNER }).then(
      (result) => +(result.value ?? 0)
    )
  }

  /**
   * @deprecated should be stored on the backend to be persisted
   */
  public async setOnboardingHintIndexPlanner(index: number): Promise<void> {
    return Preferences.set({
      key: ONBOARDING_HINT_IDX_PLANNER,
      value: index.toString(),
    })
  }

  /**
   * @deprecated should be stored on the backend to be persisted
   */
  public async fetchOnboardingHintIndexCycles(): Promise<number> {
    return Preferences.get({ key: ONBOARDING_HINT_IDX_CYCLES }).then(
      (result) => +(result.value ?? 0)
    )
  }

  /**
   * @deprecated should be stored on the backend to be persisted
   */
  public async setOnboardingHintIndexCycles(index: number): Promise<void> {
    return Preferences.set({
      key: ONBOARDING_HINT_IDX_CYCLES,
      value: index.toString(),
    })
  }

  /**
   * @deprecated should be stored on the backend to be persisted
   */
  public async fetchOnboardingHintIndexAccessories(): Promise<number> {
    return Preferences.get({ key: ONBOARDING_HINT_IDX_ACCESSORIES }).then(
      (result) => +(result.value ?? 0)
    )
  }

  /**
   * @deprecated should be stored on the backend to be persisted
   */
  public async setOnboardingHintIndexAccessories(index: number): Promise<void> {
    return Preferences.set({
      key: ONBOARDING_HINT_IDX_ACCESSORIES,
      value: index.toString(),
    })
  }
}
