<div class="content-container">
  <div>
    <b> {{ onboardingHint.title | translate }} </b>
  </div>
  <p *ngFor="let textDescriptionLine of onboardingHint.textDescriptions">
    {{ textDescriptionLine | translate }}
  </p>
  <div *ngIf="onboardingHint.videoUrl" class="video-container">
    <ion-spinner color="primary" name="circular" *ngIf="this.showSpinner"></ion-spinner>
    <ng-container *ngIf="isVideo(); else image">
      <video
        *efpRecreateView="onboardingHint.videoUrl"
        class="onboarding-video"
        [autoplay]="'autoplay'"
        (loadstart)="loadStart()"
        (canplaythrough)="loadEnd()"
        loop
        [muted]="'muted'"
        onpause="this.play()"
        [poster]="onboardingHint.videoUrl"
        playsinline>
        <source [src]="onboardingHint.videoUrl" type="video/mp4" />
      </video>
    </ng-container>
    <ng-template #image>
      <img [src]="onboardingHint.videoUrl" (load)="loadEnd()" />
    </ng-template>
  </div>
  <ol>
    <li *ngFor="let step of onboardingHint.steps">
      {{ step | translate }}
    </li>
  </ol>
  <p *ngIf="this.onboardingHint.tipDescription">{{ onboardingHint.tipDescription | translate }}</p>
  <ul *ngIf="this.onboardingHint.tips">
    <li *ngFor="let tip of this.onboardingHint.tips">
      {{ tip | translate }}
    </li>
  </ul>
</div>
