import {
  CycleBoundaryDrawable,
  Edge,
  Line,
  MeshPoint,
  SNAPPING_ERROR_TOLERANCE,
  TransformationThicknessDirection,
  UnitOfLength,
} from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { ARROW_COLOR, PRIMARY_COLOR } from '../../../constants/colors'
import { AngleLabel } from '../model/paper/AngleLabel'
import { InputLabel } from '../model/paper/InputLabel'
import { LengthLabel } from '../model/paper/LengthLabel'
import { WidthLabel } from '../model/paper/WidthLabel'
import { CENTER_POINT_LENGTH } from '../model/snapping/constants'
import { AngleInfo } from '../types/AngleInfo'
import { calculateAngleArcPoints } from '../util/paper/calculateAngleArcPoints'

export class LabelRenderService {
  public selectedAngle: AngleInfo | undefined
  public selectionPoint: paper.Point | undefined
  public hideLabelPosition: paper.Point | undefined
  private angleLayer!: paper.Layer
  private lengthLayer!: paper.Layer
  private previewArrowLayer!: paper.Layer

  private readonly view: paper.View
  private arrowSymbol!: paper.SymbolDefinition

  private arrowLeftSymbol?: paper.SymbolDefinition
  private arrowRightSymbol?: paper.SymbolDefinition
  private arrowTopSymbol?: paper.SymbolDefinition
  private arrowBottomSymbol?: paper.SymbolDefinition

  public constructor(private readonly paperScope: paper.PaperScope) {
    this.view = paperScope.view
    this.reset()

    paperScope.project.importSVG('/assets/icon/direction_change_arrow.svg', {
      onLoad: (symbolItem: paper.Item) => {
        symbolItem.fillColor = ARROW_COLOR
        symbolItem.rotate(180)
        this.arrowLeftSymbol = new paper.SymbolDefinition(symbolItem)
      },
    })

    paperScope.project.importSVG('/assets/icon/direction_change_arrow.svg', {
      onLoad: (symbolItem: paper.Item) => {
        symbolItem.fillColor = ARROW_COLOR
        this.arrowRightSymbol = new paper.SymbolDefinition(symbolItem)
      },
    })

    paperScope.project.importSVG('/assets/icon/arrow-top.svg', {
      onLoad: (symbolItem: paper.Item) => {
        symbolItem.scale(1, 1.5)
        symbolItem.fillColor = ARROW_COLOR
        this.arrowTopSymbol = new paper.SymbolDefinition(symbolItem)
      },
    })

    paperScope.project.importSVG('/assets/icon/arrow-bottom.svg', {
      onLoad: (symbolItem: paper.Item) => {
        symbolItem.scale(1, 1.5)
        symbolItem.fillColor = ARROW_COLOR
        this.arrowBottomSymbol = new paper.SymbolDefinition(symbolItem)
      },
    })

    paperScope.project.importSVG('/assets/icon/t_wall_arrow.svg', {
      onLoad: (symbolItem: paper.Item) => {
        symbolItem.fillColor = PRIMARY_COLOR
        // The icon is stretched horizontally otherwise
        symbolItem.scale(0.75, 1)
        this.arrowSymbol = new paper.SymbolDefinition(symbolItem)
      },
    })
  }

  public reset(): void {
    this.lengthLayer = this.resetLayer('Length Layer', this.lengthLayer)
    this.angleLayer = this.resetLayer('Angle Layer', this.angleLayer)
    this.previewArrowLayer = this.resetLayer('Preview Arrow Layer', this.previewArrowLayer)
  }

  public unhoverAll(): void {
    this.lengthLayer.children
      .filter((item) => item instanceof InputLabel)
      .forEach((item) => (item as InputLabel).unhover())
    this.angleLayer.children
      .filter((item) => item instanceof InputLabel)
      .forEach((item) => (item as InputLabel).unhover())
  }

  public set anglesVisible(visible: boolean) {
    this.angleLayer.visible = visible
  }

  public get anglesVisible(): boolean {
    return this.angleLayer.visible
  }

  public set lengthsVisible(visible: boolean) {
    this.lengthLayer.visible = visible
  }

  public get lengthsVisible(): boolean {
    return this.lengthLayer.visible
  }

  public generateAngleLabelsForPath(path: paper.Item, isSlab: boolean): void {
    if (!(path instanceof paper.Path) || !path.segments) {
      return
    }

    const lineSegments = path.segments.map((segment, i, segments) => {
      const nextIndex = segments[i + 1] ? i + 1 : 0
      const end = segments[nextIndex].point
      const start = segment.point
      const direction = end.subtract(start)

      return { start, end, direction }
    })

    lineSegments.forEach((l1, i, segments) => {
      const nextIndex = segments[i + 1] ? i + 1 : 0
      const l2 = segments[nextIndex]

      if ((nextIndex === 0 || nextIndex === lineSegments.length - 1) && !path.closed) {
        return
      }

      const angle = l2.direction.getDirectedAngle(l1.direction)
      if (!isSlab && Math.round(angle) <= 0) {
        return
      }

      if (isSlab) {
        if (Math.round(angle) % 180 !== 0) {
          this.drawAngleLabel(l1, l2, 180 - Math.abs(angle))
        }
      } else {
        if (Math.round(180 - angle) !== 0) {
          this.drawAngleLabel(l1, l2, 180 - angle)
        }
      }
    })
  }

  /**
   * Generates the length labels for the wall outline
   * @param path the outline of the walls
   * @param displayMergedLenghts boolean value deciding whether connected walls that are the direct continuation of each other should have a label that shows their total length
   * @param unit the unit of length used for the labels
   * @param cycleBoundaries if given, paths will be split at cycle boundaries and labels are generated for each cycle segment
   * @param selectedTWall if given, labels of the t-wall will be created with arrows to show that the t-wall is movable
   */
  public generateLengthLabelsForPath(
    path: paper.Item,
    displayMergedLenghts: boolean,
    unit: UnitOfLength,
    cycleBoundaries?: CycleBoundaryDrawable[],
    selectedTWall?: Edge,
    interactive = true
  ): void {
    if (path.children !== undefined) {
      path.children.forEach((pathChild) => {
        this.generateLengthLabelsForPath(
          pathChild,
          displayMergedLenghts,
          unit,
          cycleBoundaries,
          selectedTWall,
          interactive
        )
      })
    }

    if (!(path instanceof paper.Path) || !path.segments) {
      return
    }

    const mergedCurves: paper.Curve[] = []
    const cycleCurves: paper.Curve[] = []
    path.curves.forEach((curve) => {
      if (
        !cycleBoundaries ||
        cycleBoundaries.length === 0 ||
        !this.hasBoundaryOnCurve(curve, cycleBoundaries)
      ) {
        this.drawLengthLabelForCurve(curve, unit, selectedTWall)
      } else {
        this.handleCycleCurves(curve, cycleCurves, unit, cycleBoundaries)
      }

      if (displayMergedLenghts && !mergedCurves.includes(curve)) {
        this.handleMergedCurves(curve, mergedCurves, unit)
      }
    })
  }

  /**
   * Tests if there are any cycle boundaries on a curve, and if yes, the function splits them and creates labels for both sides of the cycle boundary
   * @param curve the currently tested curve
   * @param cycleCurves the array of curves already tested
   * @param cycleBoundaries list of cycle boundaries in the model
   */
  private handleCycleCurves(
    curve: paper.Curve,
    cycleCurves: paper.Curve[],
    unit: UnitOfLength,
    cycleBoundaries: CycleBoundaryDrawable[]
  ): void {
    if (
      !cycleCurves.includes(curve) &&
      !(
        this.hasBoundaryOnCurve(curve.previous, cycleBoundaries) &&
        curve.previous.isCollinear(curve)
      )
    ) {
      let cycleStart = curve.point1
      let cycleEnd = curve.point2
      let currentCurve: paper.Curve = curve
      let isNextSegmentCollinear = true
      do {
        const boundaryPoints: paper.Point[] = []
        cycleBoundaries.forEach((boundary) => {
          const boundaryPoint = this.getCurvePointOfBoundary(currentCurve, boundary)
          if (boundaryPoint) {
            boundaryPoints.push(boundaryPoint)
          }
        })

        // if a segment is split by more cycle boundaries, labels need to be generated between each boundary
        if (boundaryPoints.length > 0) {
          boundaryPoints
            .sort((p1, p2) => p1.getDistance(cycleStart) - p2.getDistance(cycleStart))
            .forEach((point) => {
              cycleEnd = point
              if (cycleStart.getDistance(cycleEnd) > 0) {
                this.drawLengthLabel(
                  cycleStart,
                  cycleEnd,
                  false,
                  this.calculateStrokeWidth(),
                  unit,
                  false,
                  true
                )
              }
              cycleStart = point
            })
        }

        isNextSegmentCollinear = currentCurve.isCollinear(currentCurve.next)
        if (isNextSegmentCollinear) {
          cycleEnd = currentCurve.next.point2
          cycleCurves.push(currentCurve)
        } else {
          cycleEnd = currentCurve.point2
        }
        currentCurve = currentCurve.next
      } while (
        currentCurve.next &&
        !cycleCurves.includes(currentCurve.next) &&
        isNextSegmentCollinear
      )

      // if cycle length is greater 0
      if (cycleStart.getDistance(cycleEnd) > 0) {
        this.drawLengthLabel(
          cycleStart,
          cycleEnd,
          false,
          this.calculateStrokeWidth(),
          unit,
          false,
          true
        )
      }
    }
  }

  /**
   * Searches curves that are direct continuation of each other and draws merged length labels for them (with dotted lines)
   * @param curve the current curve
   * @param mergedCurves the array of curves already tested for
   */
  private handleMergedCurves(
    curve: paper.Curve,
    mergedCurves: paper.Curve[],
    unit: UnitOfLength
  ): void {
    const start = curve.point1
    let end = curve.point2
    let currentCurve = curve
    while (
      currentCurve.next &&
      !mergedCurves.includes(currentCurve.next) &&
      currentCurve.next &&
      currentCurve.isCollinear(currentCurve.next)
    ) {
      currentCurve = currentCurve.next
      mergedCurves.push(currentCurve)
      end = currentCurve.point2
    }
    if (end !== curve.point2) {
      const offset = end.subtract(start).normalize(10).rotate(-90)
      this.drawLengthLabel(
        start.add(offset),
        end.add(offset),
        true,
        this.calculateStrokeWidth(),
        unit
      )
    }
  }

  /**
   * Generates width labels inside walls for connected walls (drawing normal width labels at the end of walls would not be possible in this case)
   * @param connectingPoints the points that are connecting walls (labels need to be generated on the edges close to these points)
   * @param unit unit of length used for the labels
   */
  public generateWidthLabelsForConnectedWalls(
    connectingPoints: MeshPoint[],
    unit: UnitOfLength
  ): void {
    connectingPoints.forEach((point) => {
      point.edges.forEach((edge) => {
        const angleRadius = this.calculateAngleRadius()
        const labelDistance = angleRadius + edge.thickness / 2
        const direction = edge.getDirection(edge.getPosition(point)).normalize(labelDistance)
        const middlePoint = point.add(direction)
        const thicknessVector = direction.rotate(90).normalize(edge.thickness / 2)
        const start = middlePoint.add(thicknessVector)
        const end = middlePoint.subtract(thicknessVector)
        const label = new WidthLabel(
          start,
          end,
          this.calculateFontSize(),
          this.calculateStrokeWidth(),
          false,
          unit
        )

        // labels are created only if there is enough space for them on the edge (with a higher zoom level there is more space available)
        if (
          label.textHeight <= edge.thickness * 0.8 &&
          labelDistance + label.textLength < (edge.length() - CENTER_POINT_LENGTH) / 2
        ) {
          this.lengthLayer.addChild(label)
        }
      })
    })
  }

  public findLengthLabelNearPoint(point: paper.Point, tolerance: number): LengthLabel | undefined {
    let closestLabel: LengthLabel | undefined = undefined
    let bestDistance = Infinity
    let labelLength = 0
    this.lengthLayer.children.forEach((label) => {
      if (
        label instanceof LengthLabel &&
        (label.centerPoint.getDistance(point) < bestDistance ||
          (label.centerPoint.getDistance(point) === bestDistance &&
            label.length.valueInUnit > labelLength)) &&
        label.centerPoint.getDistance(point) < tolerance
      ) {
        closestLabel = label
        bestDistance = label.centerPoint.getDistance(point)
        labelLength = label.length.valueInUnit
      }
    })

    return closestLabel
  }

  /**
   * Returns the point where a cycle boundary intersects a segment of the wall outline path or undefined if there is no intersection
   */
  private getCurvePointOfBoundary(
    curve: paper.Curve,
    cycleBoundary: CycleBoundaryDrawable
  ): paper.Point | undefined {
    const cycleBoundaryDirection = cycleBoundary.end.subtract(cycleBoundary.start).normalize()
    const curveDirection = curve.point2.subtract(curve.point1).normalize()
    // cycle boundary can not be parallel with the curve it is on (see: https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/4283/Technical-Documentation?anchor=getcurvepointofboundary-(label.render.service.ts))
    if (cycleBoundaryDirection.isCollinear(curveDirection)) {
      return undefined
    }

    const closestStartPoint = curve.getNearestPoint(cycleBoundary.start)
    if (closestStartPoint.getDistance(cycleBoundary.start) < SNAPPING_ERROR_TOLERANCE) {
      return closestStartPoint
    }

    const closestEndPoint = curve.getNearestPoint(cycleBoundary.end)
    if (closestEndPoint.getDistance(cycleBoundary.end) < SNAPPING_ERROR_TOLERANCE) {
      return closestEndPoint
    }

    return undefined
  }

  /**
   * Returns true if the curve, or it's direct continuation intersects with a cycle boundary
   * See https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/4283/Technical-Documentation?anchor=hasboundaryoncurve
   */
  private hasBoundaryOnCurve(
    curve: paper.Curve,
    cycleBoundaries: CycleBoundaryDrawable[],
    checkedCurves: paper.Curve[] = []
  ): boolean {
    const hasOwnBoundary = cycleBoundaries.some((boundary) =>
      this.getCurvePointOfBoundary(curve, boundary)
    )

    if (hasOwnBoundary) {
      return hasOwnBoundary
      //recursive calls needed in case the next curve is a direct continuation of the current one (similarly to the merged wall labels)
    } else if (
      curve.previous &&
      curve.isCollinear(curve.previous) &&
      !checkedCurves.includes(curve.previous)
    ) {
      checkedCurves.push(curve)
      return this.hasBoundaryOnCurve(curve.previous, cycleBoundaries, checkedCurves)
    } else if (curve.next && curve.isCollinear(curve.next) && !checkedCurves.includes(curve.next)) {
      checkedCurves.push(curve)
      return this.hasBoundaryOnCurve(curve.next, cycleBoundaries, checkedCurves)
    }

    return false
  }

  /**
   * Draws a length label for a given segment, calculating the start and endpoint of the label as well as if highlighting is needed for the t-wall
   * @param curve that the length label is drawn for
   * @param selectedTWall is the t-wall which requires highlighted length labels to show that it can be moved
   */
  private drawLengthLabelForCurve(
    curve: paper.Curve,
    unit: UnitOfLength,
    selectedTWall?: Edge,
    interactive?: boolean
  ): void {
    const path = new paper.Path()
    path.moveTo(curve.point1)
    path.lineTo(curve.point2)
    const labelCurve = path.curves[0]

    if (selectedTWall) {
      const neighbours = selectedTWall.getNeighbours()
      const tNeighbours = neighbours.filter((edge) =>
        neighbours.filter((e) => e !== edge).some((other) => edge.canMerge(other))
      )

      // the wall width of the thickest neighbor is used as tolerance to ensure that the t-walls are recognized with different wall widths as well
      const tolerance = Math.max(selectedTWall.thickness, ...tNeighbours.map((e) => e.thickness))
      const highlightedSegments = tNeighbours
        .map((e) =>
          e
            .getOutlinePath()
            .segments.filter(
              (s) =>
                s.curve.length > tolerance + SNAPPING_ERROR_TOLERANCE &&
                ((labelCurve.point1.isClose(s.curve.point1, tolerance) &&
                  labelCurve.point2.isClose(s.curve.point2, tolerance)) ||
                  (labelCurve.point2.isClose(s.curve.point1, tolerance) &&
                    labelCurve.point1.isClose(s.curve.point2, tolerance)))
            )
        )
        .flat()
      if (highlightedSegments.length !== 0) {
        this.drawLengthLabel(
          labelCurve.point1,
          labelCurve.point2,
          false,
          this.calculateStrokeWidth(),
          unit,
          true
        )
      } else {
        this.drawLengthLabel(
          labelCurve.point1,
          labelCurve.point2,
          false,
          this.calculateStrokeWidth(),
          unit,
          false,
          interactive
        )
      }
    } else {
      this.drawLengthLabel(
        labelCurve.point1,
        labelCurve.point2,
        false,
        this.calculateStrokeWidth(),
        unit
      )
    }
  }

  /**
   * Draws a length label between two points
   * @param from the starting point of the label
   * @param to the end point of the label
   * @param isCombinedEdgeLength if true, the label will be displayed with a dotted line
   * @param strokeWidth the strokeWidth of the label font
   * @param bold if set true, the label is drawn with bold fonts and arrows are added to signal that the length can be changed (for t-walls)
   * @param interactive if set true, the label is drawn blue and can be clicked
   */
  private drawLengthLabel(
    from: paper.Point,
    to: paper.Point,
    isCombinedEdgeLength: boolean,
    strokeWidth: number,
    unit: UnitOfLength,
    bold = false,
    interactive?: boolean
  ): void {
    const lengthLabel = new LengthLabel(
      from,
      to,
      this.calculateFontSize(),
      strokeWidth,
      isCombinedEdgeLength,
      bold,
      unit,
      interactive
    )
    const distance = this.hideLabelPosition?.getDistance(lengthLabel.position)
    if (distance !== undefined && distance < 1) {
      // Using distance instead of equal points because of small differences in floating point numbers
      lengthLabel.hide()
    }
    this.lengthLayer.addChild(lengthLabel)
    if (this.arrowSymbol && bold) {
      this.drawDimensionArrows(lengthLabel)
    }
  }

  // draws dimension arrows for t-walls to signal that they are movable
  private drawDimensionArrows(lengthLabel: LengthLabel): void {
    const placedSymbolRight = this.arrowSymbol.place(lengthLabel.arrowPositions[0])
    placedSymbolRight.rotate(lengthLabel.textRotation + 180, placedSymbolRight.bounds.center)

    const placedSymbolLeft = this.arrowSymbol.place(lengthLabel.arrowPositions[1])
    placedSymbolLeft.rotate(lengthLabel.textRotation, placedSymbolLeft.bounds.center)

    this.lengthLayer.addChild(placedSymbolLeft)
    this.lengthLayer.addChild(placedSymbolRight)
  }

  public drawPreviewRightArrowLabel(arrowPoint: paper.Point, edgeAngle: number): void {
    if (this.arrowRightSymbol) {
      const placedSymbol = this.arrowRightSymbol.place(arrowPoint)
      placedSymbol.rotate(edgeAngle)
      this.previewArrowLayer.addChild(placedSymbol)
    }
  }

  public drawPreviewLeftArrowLabel(arrowPoint: paper.Point, edgeAngle: number): void {
    if (this.arrowLeftSymbol) {
      const placedSymbol = this.arrowLeftSymbol.place(arrowPoint)
      placedSymbol.rotate(edgeAngle)
      this.previewArrowLayer.addChild(placedSymbol)
    }
  }

  public drawPreviewThicknessArrowLabels(
    arrowPoint: paper.Point,
    thickness: number,
    direction: TransformationThicknessDirection,
    angle: number,
    vector: paper.Point
  ): void {
    if (this.arrowTopSymbol && this.arrowBottomSymbol) {
      let topPoint = arrowPoint.clone()
      let bottomPoint = arrowPoint.clone()

      const offsetToPreventOverlapping = 6
      const offsetToAdjustArrowOnLine = 7

      switch (direction) {
        case TransformationThicknessDirection.insideOut:
          topPoint = topPoint.add(
            vector.normalize(thickness / 2 - offsetToPreventOverlapping).rotate(90)
          )
          bottomPoint = bottomPoint.add(
            vector.normalize(thickness / 2 + offsetToPreventOverlapping).rotate(90)
          )
          break
        case TransformationThicknessDirection.outsideIn:
          topPoint = topPoint.subtract(
            vector.normalize(thickness / 2 + offsetToPreventOverlapping).rotate(90)
          )
          bottomPoint = bottomPoint.subtract(
            vector.normalize(thickness / 2 - offsetToPreventOverlapping).rotate(90)
          )
          break
        case TransformationThicknessDirection.evenly:
          topPoint = topPoint.subtract(
            vector.normalize(thickness / 2 + offsetToAdjustArrowOnLine).rotate(90)
          )
          bottomPoint = bottomPoint.add(
            vector.normalize(thickness / 2 + offsetToAdjustArrowOnLine).rotate(90)
          )
          break
      }

      const placedTopSymbol = this.arrowTopSymbol.place(topPoint)
      const placedBottomSymbol = this.arrowBottomSymbol.place(bottomPoint)
      placedTopSymbol.rotate(angle)
      placedBottomSymbol.rotate(angle)

      this.previewArrowLayer.addChild(placedTopSymbol)
      this.previewArrowLayer.addChild(placedBottomSymbol)
    }
  }

  private drawAngleLabel(l1: Line, l2: Line, angle: number): void {
    const { from, through, to, center } = calculateAngleArcPoints(
      l1,
      l2,
      this.calculateAngleRadius()
    )

    const angleSelectionPoint =
      this.selectionPoint && this.selectedAngle ? this.selectionPoint : null

    this.angleLayer.addChild(
      new AngleLabel(
        angle,
        from,
        through,
        to,
        center,
        angleSelectionPoint,
        this.calculateFontSize(),
        this.calculateStrokeWidth()
      )
    )
  }

  private resetLayer(name: string, existingLayer?: paper.Layer): paper.Layer {
    if (existingLayer) {
      // Removing the layer and re-creating it instead of just clearing all children seems to
      // prevent memory leak issues from paper
      existingLayer.remove()
    }
    const layer = this.paperScope.project.addLayer(new paper.Layer())
    layer.visible = existingLayer?.visible ?? true
    layer.name = name

    return layer
  }

  private calculateStrokeWidth(): number {
    return Math.min(2, 1 / this.view.zoom)
  }

  private calculateAngleRadius(): number {
    return Math.min(75, 25 / this.view.zoom + 10)
  }

  private calculateFontSize(): number {
    return Math.min(50, 13 / this.view.zoom + 2)
  }
}
