import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { Capacitor } from '@capacitor/core'
import {
  Edge,
  LengthUtil,
  Mesh,
  PlanType,
  TransformationLengthDirection,
  TransformationThicknessDirection,
  UnitOfLength,
} from 'formwork-planner-lib'
import { formworkSystems } from '../../../../constants/formworkSystems'
import { defaultDrawSettings } from '../../../../models/draw-settings'
import { MeasureType } from '../../../../models/measureType'
import { OnboardingHintSeriesKey } from '../../../../models/onboarding/onboarding-hint-series-key'
import { Plan } from '../../../../models/plan'
import { PlanSettingsService } from '../../../../services/plan-settings.service'
import { KeypadComponent } from '../../../../shared/components/keypad/keypad.component'
import { TriggerType } from '../../../../shared/directives/onboarding-trigger.directive'
import { Model } from '../../model/Model'
import { MeasurementLabel } from '../../model/paper/MeasurementLabel'
import { EdgeAttributes } from '../../types/edgeAttributes'
import { LIMITS } from '../../util/constants/limits'
import { PlanSettings } from '../../../../models/planSettings'
import convertCmToUnit = LengthUtil.convertCmToUnit
import convertUnitToCm = LengthUtil.convertUnitToCm
import formatCmToInch = LengthUtil.formatCmToInch
import parseInchAsCm = LengthUtil.parseInchAsCm
import roundForDisplay = LengthUtil.roundForDisplay
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Subject, takeUntil } from 'rxjs'
import { LengthInputComponent } from '../../../../shared/components/length-input/length-input.component'

export const MEASUREMENT_EDITOR_BUTTON_CONTAINER_WIDTH = 172
export const MEASUREMENT_EDITOR_BUTTON_CONTAINER_HEIGHT = 110
export const MEASUREMENT_EDITOR_INPUT_WIDTH = 46
export const MEASUREMENT_EDITOR_INPUT_HEIGHT = 40
export const MEASUREMENT_EDITOR_ERROR_MESSAGE_WIDTH = 150

@Component({
  selector: 'efp-measurement-editor',
  templateUrl: 'measurement-editor.component.html',
  styleUrls: ['measurement-editor.component.scss'],
})
export class MeasurementEditorComponent implements OnChanges, OnDestroy, OnInit, AfterViewInit {
  get unit(): UnitOfLength {
    return this.model?.drawSetting?.measurementUnit ?? defaultDrawSettings.measurementUnit
  }

  @Input() plan!: Plan
  @Input() model?: Model<Mesh>
  @Input() previewModel?: Model<Mesh>
  @Input() edge?: Edge
  @Input() previewEdge?: Edge
  @Input() selectedMeasure?: MeasurementLabel
  @Input() showButton = true

  @Output() readonly directionChanged = new EventEmitter<void>()
  @Output() readonly changesApplied = new EventEmitter<Edge>()
  @Output() readonly closeMenu = new EventEmitter<void>()
  @Output() readonly destroy = new EventEmitter<void>()

  @ViewChild('efpKeypad') efpKeypad?: KeypadComponent
  @ViewChild('efpLengthInput') efpLengthInput?: LengthInputComponent

  selectedTab = MeasureType.outerLength
  currentMeasure = ''
  updatedMeasure = ''

  edgeLengthLocked = false
  edgeHasLockedNeighbour = false
  edgeHasOnlyRectangularNeighbors = false

  isChangingEditableEdgeAttribute = false
  isWebversion = false
  showTWallOnboardingHint = false

  public measurementForm = new FormGroup({
    measurementInput: new FormControl('', {
      validators: [Validators.required, Validators.min(0)],
      nonNullable: true,
    }),
  })

  private readonly destroy$ = new Subject<void>()

  ngAfterViewInit(): void {
    void this.efpLengthInput?.focus()

    this.measurementForm.controls.measurementInput.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        let stringValue: string
        if (value) {
          stringValue = value.toString()
        } else {
          stringValue = '0'
        }

        const valueInUnit =
          this.unit === 'inch'
            ? LengthUtil.formatCmToInch(parseFloat(stringValue))
            : LengthUtil.convertCmToUnit(parseFloat(stringValue), this.unit)
        void this.updateMeasure(valueInUnit.toString())
      })
  }

  prevThicknessDirection = TransformationThicknessDirection.outsideIn
  prevLengthDirection = TransformationLengthDirection.toRight

  readonly measureType = MeasureType
  readonly transformationDirectionWidth = TransformationThicknessDirection
  readonly transformationDirectionLength = TransformationLengthDirection

  private updatedEdge?: Edge
  private edgeAttributes?: EdgeAttributes
  private internalThicknessDirection = TransformationThicknessDirection.outsideIn
  private internalLengthDirection = TransformationLengthDirection.toRight
  private maxHeightFS = 0

  private planSettings: PlanSettings | undefined

  get thicknessDirection(): TransformationThicknessDirection {
    return this.internalThicknessDirection
  }

  set thicknessDirection(value: TransformationThicknessDirection) {
    this.prevThicknessDirection = this.internalThicknessDirection
    this.internalThicknessDirection = value
    if (this.edge) {
      this.setEdgeTransformationDirection(this.edge)
    }
    void this.updateMeasure()
    this.efpKeypad?.focusInput()
  }

  get lengthDirection(): TransformationLengthDirection {
    return this.internalLengthDirection
  }

  set lengthDirection(value: TransformationLengthDirection) {
    this.prevLengthDirection = this.internalLengthDirection
    this.internalLengthDirection = value
    if (this.edge) {
      this.setEdgeTransformationDirection(this.edge)
    }
    void this.updateMeasure()
    this.efpKeypad?.focusInput()
  }

  get showLockedInError(): boolean {
    return (
      (this.selectedTab === MeasureType.outerLength ||
        this.selectedTab === MeasureType.innerLength) &&
      this.edgeLengthLocked
    )
  }

  get showLockedThicknessError(): boolean {
    return (
      this.plan.buildingType === PlanType.WALL &&
      this.selectedTab === MeasureType.width &&
      this.edgeHasLockedNeighbour
    )
  }

  get showDirectionSegment(): boolean {
    return this.selectedTab !== this.measureType.height && this.plan.buildingType === 'WALL'
  }

  constructor(private readonly planSettingsService: PlanSettingsService) {
    this.isWebversion = Capacitor.getPlatform() === 'web'
  }

  async ngOnInit(): Promise<void> {
    if (this.edge !== undefined) {
      const isOnlyOnLeftOpen =
        !this.edge.isVertical && this.edge.isLeftOpen && !this.edge.isRightOpen
      const isOnlyOnBottomOpen =
        this.edge.isVertical && this.edge.isBottomOpen && !this.edge.isTopOpen

      if (isOnlyOnLeftOpen || isOnlyOnBottomOpen) {
        this.lengthDirection = TransformationLengthDirection.toLeft
      }

      this.edgeHasOnlyRectangularNeighbors =
        this.edge
          .getNeighbours()
          .map((edge) => {
            const angle = Math.round(Math.abs(this.edge?.getAngle(edge) ?? 90))
            return angle === 90 || angle === 180
          })
          .filter((isARectangularNeigbour) => !isARectangularNeigbour).length === 0

      if (!this.edgeHasOnlyRectangularNeighbors) {
        this.thicknessDirection = TransformationThicknessDirection.evenly
      }

      this.setEdgeTransformationDirection(this.edge)
      await this.updateMeasure()
    }
  }

  private shouldShowOnboardingHint(): boolean {
    const tWallNeighbours = this.edge?.getNeighbours().find((e) => e.isTWall())
    if ((!tWallNeighbours && !this.edge) || this.plan.buildingType === PlanType.SLAB) {
      return false
    }
    const nonTWallNeighbours = this.edge?.getNeighbours().filter((e) => !e.isTWall())
    const canMergeWithNonTWallNeighbour = nonTWallNeighbours?.some((e) => this.edge?.canMerge(e))

    const measureType = this.selectedMeasure?.getMeasureType(
      this.edgeAttributes,
      this.plan.buildingType
    )
    return !!canMergeWithNonTWallNeighbour && measureType !== MeasureType.width
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.isChangingEditableEdgeAttribute = true
    if (
      changes.edge &&
      !!changes.edge.previousValue &&
      !(changes.edge.previousValue as Edge).isSameEdge(changes.edge.currentValue)
    ) {
      this.model?.finalize()
      this.efpKeypad?.focusInput()
    }

    if (
      changes.previewEdge &&
      !!changes.previewEdge.previousValue &&
      !(changes.previewEdge.previousValue as Edge).isSameEdge(changes.previewEdge.currentValue)
    ) {
      this.previewModel?.finalize()
    }

    if (changes.edge || changes.selectedMeasure || changes.model) {
      void this.efpLengthInput?.focus()
      this.updatedEdge = this.edge
      if (this.edge && this.model) {
        this.edgeAttributes = this.model.getAttributes([this.edge])
      }

      if (this.selectedMeasure != null) {
        this.selectedTab = this.selectedMeasure.getMeasureType(
          this.edgeAttributes,
          this.plan.buildingType
        )
      }

      this.updateCurrentMeasure()

      if (this.edge) {
        this.setEdgeTransformationDirection(this.edge)
        await this.updateMeasure()
      }
    }

    this.isChangingEditableEdgeAttribute = false
    this.showTWallOnboardingHint = this.shouldShowOnboardingHint()
  }

  ngOnDestroy(): void {
    this.destroy.emit()
  }

  async updateSelectedTab(newTab: MeasureType): Promise<void> {
    this.isChangingEditableEdgeAttribute = true
    await this.save()
    this.changesApplied.emit(this.updatedEdge)

    if (newTab !== this.selectedTab) {
      // wait for this.apply() to the main model
      // otherwise the preview edge would be the updatedEdge
      this.edge = this.updatedEdge
    }

    this.selectedMeasure = undefined
    this.selectedTab = newTab
    this.updateCurrentMeasure()
    this.isChangingEditableEdgeAttribute = false
    this.efpKeypad?.focusInput()
  }

  get newValue(): number {
    return this.unit === 'inch'
      ? parseInchAsCm(this.updatedMeasure)
      : convertUnitToCm(Number(this.updatedMeasure), this.unit)
  }

  async apply(
    targetModel: Model<Mesh> | undefined = this.model,
    edge: Edge | undefined = this.edge
  ): Promise<void> {
    if (
      targetModel &&
      edge &&
      this.edgeAttributes &&
      !Number.isNaN(this.newValue) &&
      this.selectedTab !== MeasureType.height
    ) {
      const updatedAttributes: EdgeAttributes = { ...this.edgeAttributes }
      const newLength = this.newValue

      if (this.planSettings === undefined || this.planSettings.id !== this.plan.settingsId) {
        this.planSettings = await this.planSettingsService.getPlanSettingsAndSetLastUnit(
          this.plan.settingsId
        )
      }
      if (!this.planSettings) {
        throw new Error('MeasurementEditorComponent.apply - Plan settings not found')
      }
      if (this.maxHeightFS === 0) {
        const formwork = formworkSystems.find((fs) => fs.id === this.planSettings?.formworkWall)
        this.maxHeightFS = formwork ? formwork?.maxHeight : 0
      }
      switch (this.selectedTab) {
        case MeasureType.outerLength:
        case MeasureType.innerLength:
          const editedLength =
            this.selectedTab === MeasureType.outerLength
              ? updatedAttributes.outerLength
              : updatedAttributes.innerLength
          const lengthDiff = newLength - editedLength
          updatedAttributes.outerLength += lengthDiff
          updatedAttributes.innerLength += lengthDiff
          break
        case MeasureType.width:
          if (this.plan.buildingType === PlanType.SLAB) {
            this.planSettings.slabThickness = newLength
          }
          updatedAttributes.thickness = newLength
          break
      }

      this.updatedEdge = targetModel.setEdgeAttributes(
        edge,
        updatedAttributes,
        this.thicknessDirection,
        this.lengthDirection
      )
      if (!this.updatedEdge) {
        console.error('Tried to set edge attributes for missing edge')
        this.closeMenu.next()
      }
    }
  }

  get valid(): boolean {
    return !this.showLengthError && !this.showThicknessError
  }

  public async onSaveClicked(): Promise<void> {
    await this.save()
    this.updateCurrentMeasure()
    this.closeMenu.next()
  }

  private async save(): Promise<void> {
    if (this.valid && this.currentMeasure !== this.updatedMeasure) {
      await this.apply()
      void this.savePlanSettingsAndResetCalculation()
      this.model?.finalize()
    }
  }

  private async savePlanSettingsAndResetCalculation(): Promise<void> {
    if (this.planSettings) {
      await this.planSettingsService.update(this.planSettings)
    } else {
      this.planSettings = await this.planSettingsService.getPlanSettingsAndSetLastUnit(
        this.plan.settingsId
      )
      if (this.planSettings) {
        await this.planSettingsService.update(this.planSettings)
      } else {
        throw new Error(
          'MeasurementEditorComponent.savePlanSettingsAndResetCalculation - Plan settings not found'
        )
      }
    }
  }

  public cancel(): void {
    this.closeMenu.next()
  }

  public repositionTWallOnboardingHintsId(): OnboardingHintSeriesKey {
    return Capacitor.isNativePlatform()
      ? OnboardingHintSeriesKey.REPOSITION_T_WALL_NATIVE
      : OnboardingHintSeriesKey.REPOSITION_T_WALL_WEB
  }

  private updateCurrentMeasure(): void {
    if (this.edgeAttributes) {
      let length = 0
      switch (this.selectedTab) {
        case MeasureType.outerLength:
          // Length can sometimes have a lot of decimals due to paper/float precision errors (149.99999999999999)
          length = this.edgeAttributes.outerLength
          break
        case MeasureType.innerLength:
          length = this.edgeAttributes.innerLength
          break
        case MeasureType.width:
          length = this.edgeAttributes.thickness
          break
        case MeasureType.height:
          length = 0
          break
      }

      if (this.unit === 'inch') {
        this.currentMeasure = formatCmToInch(length)
        this.updatedMeasure = this.currentMeasure
        this.measurementForm.controls.measurementInput.setValue(length.toString())
      } else {
        this.currentMeasure = '' + roundForDisplay(convertCmToUnit(length, this.unit), this.unit)
        this.updatedMeasure = this.currentMeasure
        this.measurementForm.controls.measurementInput.setValue(length.toString())
      }
    }
  }

  private setEdgeTransformationDirection(edge: Edge): void {
    if (
      this.selectedTab === MeasureType.innerLength ||
      this.selectedTab === MeasureType.outerLength
    ) {
      edge.thicknessTransformationDirection = undefined
      edge.lengthTransformationDirection = this.lengthDirection
    } else if (this.selectedTab === MeasureType.width) {
      edge.lengthTransformationDirection = undefined
      edge.thicknessTransformationDirection = this.thicknessDirection
    } else {
      edge.lengthTransformationDirection = undefined
      edge.thicknessTransformationDirection = undefined
    }

    this.directionChanged.next()
  }

  async updateMeasure($event: string = this.updatedMeasure): Promise<void> {
    this.updatedMeasure = $event
    if (this.previewModel != null && this.previewEdge != null) {
      await this.apply(this.previewModel, this.previewEdge)
    }
  }

  convertCmValueToCurrentUnit(value: number): string {
    return this.unit === 'inch' ? formatCmToInch(value) : `${convertCmToUnit(value, this.unit)}`
  }

  get showLengthError(): boolean {
    return (
      (this.selectedTab === MeasureType.innerLength ||
        this.selectedTab === MeasureType.outerLength) &&
      (this.newValue < this.minLength || this.newValue > this.maxLength)
    )
  }

  get showThicknessError(): boolean {
    return (
      this.selectedTab === MeasureType.width &&
      (this.newValue < this.minThickness || this.newValue > this.maxThickness)
    )
  }

  get minLength(): number {
    return LIMITS[this.plan.buildingType].METRIC.LENGTH.MIN
  }

  get maxLength(): number {
    return LIMITS[this.plan.buildingType].METRIC.LENGTH.MAX
  }

  get minThickness(): number {
    if (this.unit === 'inch') {
      return LIMITS[this.plan.buildingType].IMPERIAL.THICKNESS.MIN
    }
    return LIMITS[this.plan.buildingType].METRIC.THICKNESS.MIN
  }

  get maxThickness(): number {
    if (this.unit === 'inch') {
      return LIMITS[this.plan.buildingType].IMPERIAL.THICKNESS.MAX
    }
    return LIMITS[this.plan.buildingType].METRIC.THICKNESS.MAX
  }

  async switchTab(shiftActive: boolean): Promise<void> {
    const segmentButtons = document.querySelectorAll('ion-segment-button')
    const currentIndex = Array.from(segmentButtons).findIndex((button) =>
      button.classList.contains('segment-button-checked')
    )
    const nextIndex = (shiftActive ? currentIndex - 1 : currentIndex + 1) % segmentButtons.length
    await this.updateSelectedTab(segmentButtons[nextIndex].value as MeasureType)
  }

  protected readonly PlanType = PlanType
  protected readonly TriggerType = TriggerType
}
