<ion-header class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" src="assets/icon/ic_close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title size="large">{{ header }}</ion-title>
  </ion-toolbar>
</ion-header>
<div class="p-top-24 p-left-20 p-right-20 p-bottom-24">
  {{ message }}
  <div class="d-flex align-center gap-2">
    <a [href]="'mailto:' + email">
      {{ email }}
    </a>
    <ion-button (click)="copyToClipboard()" shape="round" fill="clear" size="small">
      <ion-icon
        *ngIf="!copiedToClipboard; else copied"
        name="copy-outline"
        slot="icon-only"></ion-icon>
      <ng-template #copied>
        <ion-icon src="/assets/icon/check.svg" slot="icon-only" color="primary"></ion-icon>
      </ng-template>
    </ion-button>
  </div>
</div>
