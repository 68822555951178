import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, HostListener } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Capacitor } from '@capacitor/core'
import { AlertController, PopoverController } from '@ionic/angular'
import { EfpViewerComponent, ScreenshotViewer, ViewerLoggingService } from 'efp-viewer'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Log, LogLevel } from '../../../../models/log'
import { OnboardingHintSeriesKey } from '../../../../models/onboarding/onboarding-hint-series-key'
import { Screenshot } from '../../../../models/screenshot'
import { AppSettingsRepository } from '../../../../repositories/app-settings.repository'
import { PlanResultRepository } from '../../../../repositories/plan-result.repository'
import {
  ApplicationInsightsService,
  ApplicationInsightsStates,
} from '../../../../services/applicationInsights.service'
import { ResultTabService } from '../../../../services/result-tab.service'
import { ScreenshotService } from '../../../../services/screenshot.service'
import { Translation } from '../../../../services/translation.service'
import { TriggerType } from '../../../../shared/directives/onboarding-trigger.directive'
import {
  MessageSeverity,
  ResultMessageService,
  SEVERITY_ERROR,
  SEVERITY_INFO,
  SEVERITY_WARNING,
} from '../../services/result-message.service'
import { VisibilityMenu3DComponent } from '../../../../shared/components/visibility-menu-3d/visibility-menu-3d.component'
import { HighlightMenu3DComponent } from '../../../../shared/components/highlight-menu-3d/highlight-menu-3d.component'
import { checkIfMobileLayout } from '../../../../constants/layout'
import { CycleMenu3DComponent } from '../../../../shared/components/cycle-menu-3d/cycle-menu-3d.component'
import { PlanSettings } from '../../../../models/planSettings'
import { Plan } from '../../../../models/plan'
import { PlanSettingsService } from '../../../../services/plan-settings.service'
import { PlanService } from '../../../../services/plan.service'
import { PlanType } from '../../../../shared/formwork-planner'
import { FormworkId } from '../../../../../generated/efp-api'
import { isSelectorPresented } from '../../../../shared/directives/keyboard-shortcut.directive'

const SOURCE_ID = '3DVIEWER'

export enum CameraMode {
  PERSPECTIVE = 'PERSPECTIVE',
  ORTHOGRAPHIC = 'ORTHOGRAPHIC',
}
@Component({
  selector: 'efp-result-3d',
  styleUrls: ['result-3d.component.scss'],
  templateUrl: 'result-3d.component.html',
})
export class Result3dComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(EfpViewerComponent, { static: true }) viewer!: EfpViewerComponent

  @HostListener('window:resize', ['$event'])
  resize(_: Event): void {
    if (checkIfMobileLayout()) {
      this.viewer.toolbarControl.setViewCubePosition(30, 80)
    } else {
      this.viewer.toolbarControl.setViewCubePosition(80, 30)
    }
  }

  public cycleMenuText = ''
  public static viewerCustomScreen?: EfpViewerComponent

  private planId?: number
  private readonly pageLeave$ = new Subject<void>()
  private viewerCustomScreenSub?: Subscription
  public planSettings: PlanSettings | undefined = undefined
  public plan: Plan | undefined = undefined

  public planType: typeof PlanType = PlanType

  public cameraMode: CameraMode = CameraMode.PERSPECTIVE

  public items?: Map<string, string>

  public isOpen?: boolean

  constructor(
    private readonly resultTabService: ResultTabService,
    private readonly activeRoute: ActivatedRoute,
    private readonly planResultRepository: PlanResultRepository,
    private readonly messageService: ResultMessageService,
    private readonly translate: Translation,
    private readonly appInsightsService: ApplicationInsightsService,
    private readonly screenshotService: ScreenshotService,
    private readonly alertCtrl: AlertController,
    private readonly appSettingsRepository: AppSettingsRepository,
    public popoverController: PopoverController,
    private readonly planService: PlanService,
    private readonly planSettingsService: PlanSettingsService
  ) {}

  ngOnInit(): void {
    this.activeRoute.paramMap.pipe(takeUntil(this.pageLeave$)).subscribe(() => {
      const params = this.activeRoute.snapshot.parent?.paramMap

      if (params?.has('planId')) {
        this.planId = Number(params?.get('planId'))
        void this.loadViewer(this.planId)
      } else {
        this.planId = undefined
      }
    })
  }

  ngAfterViewInit(): void {
    Result3dComponent.viewerCustomScreen = this.viewer

    this.viewer.domElementsInit()

    this.viewer.toolbarControl.setNativeToolbarVisibilty(false)
    if (checkIfMobileLayout()) {
      this.viewer.toolbarControl.setViewCubePosition(30, 80)
    } else {
      this.viewer.toolbarControl.setViewCubePosition(80, 30)
    }
    this.viewer.viewerDidLoad.subscribe(() => {
      this.cycleMenuText =
        this.viewer.toolbarControl.availabeCycles.find(
          (a) => a.CycleId === this.viewer.toolbarControl.cycle
        )?.Translation ?? 'missing'
    })
    this.viewer.cycleDidChange.subscribe(() => {
      this.updateCycleMenuLabel()
    })
    this.viewerCustomScreenSub?.unsubscribe()
    this.viewerCustomScreenSub = this.viewer.customViewEmitter.subscribe(
      (screen: ScreenshotViewer) => {
        if (screen.direction === undefined && screen.cycle === undefined) {
          void this.alertCtrl
            .create({
              cssClass: ['alertStyle', 'alertStyleTwoButtons'],
              id: 'screenshotNameModal',
              header: this.translate.translate('SCREENSHOT.SET_NAME'),
              inputs: [
                {
                  type: 'text',
                  name: 'screenName',
                  placeholder: 'Screenshot',
                  value: 'Screenshot',
                  attributes: { required: true },
                },
              ],
              buttons: [
                {
                  text: this.translate.translate('GENERAL.CANCEL'),
                },
                {
                  text: this.translate.translate('GENERAL.OK'),
                  handler: (data) => {
                    void this.insertScreen(
                      data.screenName,
                      screen.base64,
                      screen.cycle,
                      false,
                      screen.width,
                      screen.height
                    )
                  },
                },
              ],
            })
            .then(async (alert) => alert.present())
        }
      }
    )
  }

  async insertScreen(
    name: string,
    base64: string,
    cycle: number | undefined,
    defaultView: boolean,
    width: number,
    height: number
  ): Promise<void> {
    this.resultTabService.setAllowTabChange(false)
    if (this.planId) {
      const screen: Screenshot = {
        id: 0,
        name,
        date: new Date(),
        planId: this.planId,
        screenshot: base64,
        defaultView,
        cycle: cycle ?? 0,
        width,
        height,
      }
      await this.screenshotService.insertScreen(screen)
    }
    this.resultTabService.setAllowTabChange(true)
  }

  private async loadViewer(planId: number): Promise<void> {
    this.resultTabService.setAllowTabChange(false)
    const articles = await this.planResultRepository.getArticleListWithCycleUsageForPlan(planId)
    const planXML = await this.planResultRepository.getResultXML(planId)

    this.plan = await this.planService.findOne(planId)
    this.planSettings = await this.planSettingsService.getPlanSettingsAndSetLastUnit(
      this.plan.settingsId
    )

    if (!planXML) {
      throw new Error('No plan XML found')
    }

    const lang = (await this.appSettingsRepository.getAppSettings()).language
    const langCode = lang.indexOf('en') !== -1 ? 'en' : lang

    this.messageService.clearFromSource(SOURCE_ID)

    ViewerLoggingService.logs.pipe(takeUntil(this.pageLeave$)).subscribe((log: Log) => {
      let logMessage: string
      // Regex to check if Transaltions string contains an article number, in that case do not translate
      if (/(\D*\d){8,10}/.test(log.message)) {
        logMessage = log.message
      } else {
        logMessage = this.translate.translate('VIEWER.' + log.message)
      }

      this.messageService.postMessage({
        severity: Result3dComponent.logLevelToSeverity(log.level),
        message: logMessage,
        sourceId: SOURCE_ID,
      })

      this.appInsightsService.addUserEvent(
        ApplicationInsightsStates.VIEWER_ERROR + ' ' + logMessage,
        planId
      )
    })

    // ÜBERSETZUNG , articles
    const translations: Map<string, string> = new Map()
    translations.set('cycle', this.translate.translate('VIEWER.CYCLE'))
    translations.set('concrete', this.translate.translate('VIEWER.CONCRETE'))
    translations.set('plan', this.translate.translate('VIEWER.PLAN'))
    translations.set('labeling', this.translate.translate('VIEWER.LABELING'))
    translations.set('layout', this.translate.translate('VIEWER.LAYOUT'))
    translations.set('measurement', this.translate.translate('VIEWER.MEASUREMENT'))
    translations.set('all', this.translate.translate('VIEWER.ALL'))
    translations.set('done', this.translate.translate('VIEWER.DONE'))
    translations.set('connections', this.translate.translate('VIEWER.HIGHLIGHTING.CONNECTIONS'))
    translations.set('walings', this.translate.translate('VIEWER.HIGHLIGHTING.WALINGS'))
    translations.set('anchors', this.translate.translate('VIEWER.HIGHLIGHTING.ANCHORS'))
    translations.set('plywood', this.translate.translate('VIEWER.HIGHLIGHTING.PLYWOOD'))
    translations.set('woods', this.translate.translate('VIEWER.HIGHLIGHTING.WOODS'))

    // use always cm for plan measuremnt unit
    // use always cm for article measuremnt unit except for XFramiS use inch
    await this.viewer.initViewerWithData(
      [planXML],
      langCode,
      this.planSettings?.measurementUnit ?? 'cm',
      this.planSettings?.formworkWall === FormworkId.XframiS ? 'inch' : 'cm',
      translations,
      articles,
      undefined
    )

    this.resultTabService.setAllowTabChange(true)
  }

  public checkAndShareResultOnboardingHint(): OnboardingHintSeriesKey {
    return Capacitor.isNativePlatform()
      ? OnboardingHintSeriesKey.CHECK_AND_SHARE_RESULT_NATIVE
      : OnboardingHintSeriesKey.CHECK_AND_SHARE_RESULT_WEB
  }

  async presentVisibilityPopover(ev: Event): Promise<void> {
    const popover = await this.popoverController.create({
      component: VisibilityMenu3DComponent,
      cssClass: 'visibility-menu',
      event: ev,
      showBackdrop: false,
      translucent: true,
      componentProps: {
        items: this.viewer.toolbarControl.availableVisibilityGroups,
      },
      alignment: 'start',
      side: 'end',
    })

    if (checkIfMobileLayout()) popover.side = 'bottom'

    await popover.present()
  }

  async presentCyclePopover(ev: Event): Promise<void> {
    this.isOpen = true
    const popover = await this.popoverController.create({
      component: CycleMenu3DComponent,
      cssClass: 'visibility-menu cycle-menu',
      event: ev,
      showBackdrop: false,
      translucent: true,
      componentProps: {
        cycles: this.viewer.toolbarControl.availabeCycles,
        toolbarControl: this.viewer.toolbarControl,
      },
      alignment: 'center',
      side: 'top',
    })

    await popover.present()

    const { role } = await popover.onDidDismiss()
    if (role) {
      this.isOpen = false
    }
  }

  async presentHighlightPopover(ev: Event): Promise<void> {
    const popover = await this.popoverController.create({
      component: HighlightMenu3DComponent,
      cssClass: 'visibility-menu',
      event: ev,
      showBackdrop: false,
      translucent: true,
      componentProps: {
        items: this.viewer.toolbarControl.availableHighlightGroups,
      },
      alignment: 'start',
      side: 'end',
    })

    if (checkIfMobileLayout()) popover.side = 'bottom'

    await popover.present()
  }

  @HostListener('window:keydown.c')
  public resetCamera(): void {
    if (isSelectorPresented('efp-feedback') || document.getElementById('screenshotNameModal'))
      return
    this.viewer.toolbarControl.resetCamera()
  }

  public createScreenshot(): void {
    this.viewer.toolbarControl.createCustomScreenshot()
  }

  public switchCameraMode(mode: CameraMode): void {
    this.cameraMode = mode

    this.viewer.toolbarControl.cameraModeOrthographic = this.cameraMode === CameraMode.ORTHOGRAPHIC
  }

  public incrementCycle(): void {
    this.viewer.toolbarControl.incrementCycle()
  }

  public decrementCycle(): void {
    this.viewer.toolbarControl.decrementCycle()
  }

  public updateCycleMenuLabel(): void {
    this.cycleMenuText =
      this.viewer.toolbarControl.availabeCycles.find(
        (a) => a.CycleId === this.viewer.toolbarControl.cycle
      )?.Translation ?? 'missing'
  }

  public get cycleIsDoneOrAll(): boolean {
    return (
      this.cycleMenuText !== '' &&
      (this.viewer.toolbarControl.cycle === 777 || this.viewer.toolbarControl.cycle === 999)
    )
  }

  public get cycleIsPlan(): boolean {
    return this.cycleMenuText !== '' && this.viewer.toolbarControl.cycle === 888
  }

  ngOnDestroy(): void {
    this.pageLeave$.next()
  }

  private static logLevelToSeverity(level: LogLevel): MessageSeverity {
    switch (level) {
      case LogLevel.info:
        return SEVERITY_INFO
      case LogLevel.warning:
        return SEVERITY_WARNING
      case LogLevel.error:
        return SEVERITY_ERROR
    }
  }

  public checkIfWallPlan(): boolean {
    return this.plan?.buildingType === PlanType.WALL
  }

  protected readonly CameraMode = CameraMode
  protected readonly TriggerType = TriggerType
}
