import { Component, Input } from '@angular/core'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'efp-simple-modal-wrapper',
  templateUrl: './simple-modal-wrapper.component.html',
})
export class SimpleModalWrapperComponent {
  @Input() isOpen = true
  @Input() dismissable = true

  constructor(private readonly modalCtrl: ModalController) {}

  public async closeModal(): Promise<void> {
    if (this.dismissable) {
      await this.modalCtrl.dismiss()
    }
  }
}
