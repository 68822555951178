import paper from 'paper/dist/paper-core'
import {
  Edge,
  SNAP_MINIMUM_ANGLE,
  SNAPPING_ERROR_TOLERANCE,
  CompoundPathWithEdgeMap,
} from 'formwork-planner-lib'
import { EdgeMap } from '../../model/edge/EdgeMap'
import { PlanType } from '../../model/PlanType'
import { snapToEdge } from '../snapping/snapToEdge'

export function detectPathIntersections(
  compoundPath: CompoundPathWithEdgeMap,
  edges: Edge[]
): Set<Edge> {
  const invalidEdges = new Set<Edge>()

  // subpaths should not intersect, in case an intersection is found, the respective edge is added to the invalid edges
  compoundPath.children.forEach((path) => {
    compoundPath.children.forEach((other) => {
      const intersections = (path as paper.Path).getIntersections(other as paper.Path)
      intersections.forEach((ix) => {
        const edge1 = compoundPath.edgeMap.get(ix.segment)
        if (edge1) {
          invalidEdges.add(edge1[0].edge)
        }

        const edge2 = compoundPath.edgeMap.get(ix.intersection.segment)
        if (edge2) {
          invalidEdges.add(edge2[0].edge)
        }

        // if no edge is found in the edge map, we invalidate the closest edge
        if (!edge1 && !edge2) {
          const edge = snapToEdge(ix.point, edges, true)
          if (edge) {
            invalidEdges.add(edge.edge)
          }
        }
      })
    })
  })

  return invalidEdges
}

export function detectSmallAnglesAtPath(
  edgeMap: EdgeMap,
  compoundPath: paper.CompoundPath,
  target: PlanType
): Set<Edge> {
  const invalidEdges = new Set<Edge>()
  const curves: paper.Curve[][] = []

  compoundPath.children.map((item) => {
    if (item instanceof paper.Path) {
      curves.push(item.segments.map((segment) => segment.curve))
    }
  })

  curves.forEach((pathCurves) => {
    for (let i = 0; i < pathCurves.length; i++) {
      const j = i + 1 >= pathCurves.length ? 0 : i + 1
      const curve1 = pathCurves[i]
      const curve2 = pathCurves[j]

      const curve1Direction = curve1.point1.subtract(curve1.point2)
      const curve2Direction = curve2.point2.subtract(curve2.point1)

      const angle = curve1Direction.getDirectedAngle(curve2Direction)

      const roundedAngle = Math.round(Math.abs(angle))

      if (roundedAngle < SNAP_MINIMUM_ANGLE[target] && Math.abs(angle) > SNAPPING_ERROR_TOLERANCE) {
        const steps = edgeMap.get(curve1.segment2)
        steps?.forEach((step) => invalidEdges.add(step.edge))
      }
    }
  })

  return invalidEdges
}

export function detectOverlappingEdges(edges: Edge[]): Set<Edge> {
  const overlappingEdges = new Set<Edge>()

  const edgeOutlineMap = new Map<Edge, paper.Path>()
  edges.forEach((edge) => edgeOutlineMap.set(edge, edge.getOutlinePath()))

  for (const a of edges) {
    for (const b of edges) {
      if (a === b) {
        continue
      }

      const outlineA = <paper.Path>edgeOutlineMap.get(a)
      const outlineB = <paper.Path>edgeOutlineMap.get(b)
      const ixs = outlineA.getCrossings(outlineB)
      const containedPoints = outlineB.segments
        .map((segment) => segment.point)
        .filter((point) => outlineA.contains(point))

      const outlinePoints = outlineA.getIntersections(outlineB)

      if (
        ((ixs.length >= 4 || containedPoints.length >= 4) && a.isParallelTo(b)) ||
        outlinePoints.length > 2
      ) {
        overlappingEdges.add(a)
        overlappingEdges.add(b)
      }
    }
  }

  return overlappingEdges
}
