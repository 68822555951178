<efp-app-header
  class="no-header-shadow"
  [isNavStep]="true"
  navLink="/homepage"
  title="{{ plan?.name }}"
  [planId]="planId"
  [webCollapsableEnabled]="true"
  [showTitleOnWeb]="true">
  <ng-container buttons *ngIf="plan">
    <ion-button
      [disabled]="efpExportService.isLoading"
      fill="clear"
      (click)="efpExportService.showShareDialog(plan)">
      <ion-icon slot="icon-only" src="assets/icon/share.svg"></ion-icon>
    </ion-button>
  </ng-container>
</efp-app-header>
<efp-sidebar-container [sidebarPosition]="SidebarPosition.RIGHT">
  <ng-container
    *ngIf="onboardingHintKey$ | async as onboardingHintKey"
    [efpOnboardingTrigger]="onboardingHintKey"
    [triggeredBy]="TriggerType.COMPONENT_RENDERED">
    <efp-onboarding-hints [onboardingHintSeriesKey]="onboardingHintKey"> </efp-onboarding-hints>
  </ng-container>
  <div
    class="drawing-area-container"
    efpKeyboardShortcut
    (deletionShortcut)="onDelete()"
    (numberShortcut)="changeAccessoryKeyboardListener($event)"
    (ctrlZShortcut)="onUndo()"
    (ctrlYShortcut)="onRedo()"
    (ctrlAShortcut)="triggerSelectallKeyboardShortcut()"
    (escShortcut)="triggerDeselectallKeyboardShortcut()"
    (ctrlShortcut)="onCtrl($event)"
    (aShortcut)="triggerSelectAdjacentKeyboardShortcut()"
    (iShortcut)="triggerInvertKeyboardShortcut()">
    <efp-planner
      [initialize]="pageReady"
      [showScale]="false"
      [scaleUnit]="unit"
      [scaleLabel]="'PLAN.SCALE' | translate"
      (ready)="onPlannerInitialized()"
      (interactionUp)="onMouseUp($event)"
      (zoomChanged)="onZoomUpdated($event)"
      (panChanged)="onPanUpdated($event)">
    </efp-planner>
    <efp-accessory-context-menu
      *ngIf="plannerInitialized && model && renderService && plan"
      [planVisibilitySettings]="planVisibilitySettings"
      [disableAccessoryMenu]="!enableAddButton"
      [accessoryLines]="accessoryLines.accessoryLines"
      (openAccessorySelection)="openAccessorySelection()"
      (linesDeselected)="handleDeselectionAndOpenAccessorySelection()"
      [renderService]="renderService"
      [accessoryChoices]="this.accessoryChoices"
      (closeSelector)="closeAccessorySelector()"
      (submitChanges)="saveSelectedAccessories()"
      [history]="history"
      [selectAllKeyboardShortcutEventEmitterInput]="selectAllKeyboardShortcutEventEmitter"
      [deselectAllKeyboardShortcutEventEmitterInput]="deselectAllKeyboardShortcutEventEmitter"
      [invertKeyboardShortcutEventEmitterInput]="invertKeyboardShortcutEventEmitter"
      [selectAdjacentKeyboardShortcutEventEmitter]="selectAdjacentKeyboardShortcutEventEmitter">
    </efp-accessory-context-menu>
    <efp-measurement-legend
      [wallHeight]="planSettings ? planSettings.wallHeight : undefined"
      [slabThickness]="planSettings ? planSettings.slabThickness : undefined"
      [slabHeight]="planSettings ? planSettings.slabHeight : undefined"
      [measurementUnitPlanner]="planSettings ? planSettings.measurementUnit : undefined"
      [buildingType]="plan ? plan.buildingType : undefined"></efp-measurement-legend>
  </div>

  <ng-container *ngIf="hasLegend() && this.planVisibilitySettings?.showAccessoryLegend">
    <div *ngIf="showLegend" class="legend">
      <div class="part-list d-flex-col d-flex gap-8">
        <div *ngFor="let part of usedAccessoryParts()" class="part ion-padding-horizontal">
          <efp-number-indicator
            [color]="part.color.toCSS(true)"
            [number]="part.orderPosition"></efp-number-indicator>
          <span class="part-label ion-padding-horizontal">
            {{ part.id | favTranslate }}
          </span>
        </div>
      </div>
    </div>
  </ng-container>

  <efp-bottom-menu-container *ngIf="plannerInitialized && model && renderService && plan">
    <efp-primary-planner-toolbar
      *ngIf="planVisibilitySettings"
      [renderService]="renderService"
      [model]="model"
      [planVisibilitySettings]="planVisibilitySettings">
      <efp-undo-redo
        [history]="history"
        [selectionOpen]="showSelector"
        (undo)="onUndo()"
        (redo)="onRedo()">
      </efp-undo-redo>
      <ion-button
        (click)="onDelete()"
        [disabled]="disableDeleteButton || showSelector"
        efpTooltip
        [tooltipTitle]="'TOOLBAR.DELETE' | translate">
        <ion-icon slot="icon-only" src="/assets/icon/delete.svg"></ion-icon>
      </ion-button>
    </efp-primary-planner-toolbar>
  </efp-bottom-menu-container>
  <efp-context-menu-center-button-container
    [useCenterView]="true"
    [centerContextMenu]="true"
    [model]="model">
  </efp-context-menu-center-button-container>
  <ng-container *ngIf="planSettings && plan" rightSidebar>
    <efp-navbar
      *efpHideOnMobile="showSelector"
      currentStep="accessory"
      [planId]="plan.id"
      [planType]="plan.buildingType"
      [planSettings]="planSettings">
    </efp-navbar>
  </ng-container>
</efp-sidebar-container>
