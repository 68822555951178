import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ModalController } from '@ionic/angular'
import { Translation } from '../../../services/translation.service'
import { LoadingSpinnerService } from '../../../services/loading-spinner.service'
import { ErrorPopupService } from '../../../services/error-popup.service'
import { MultiStepModalComponent } from '../multi-step-modal/multi-step-modal.component'
import { AppUserType, Country, PrivacyPolicyModel } from '../../../../generated/efp-api'
import { AppSettingsRepository } from '../../../repositories/app-settings.repository'
import { allCountries } from '../../../models/country'
import { PrivacyPolicyRepository } from '../../../repositories/privacy-policies.repository'
import { Subject, takeUntil } from 'rxjs'
import { AuthenticationService } from '../../../services/authentication.service'
import { DevicePreferencesService } from '../../../services/device-preferences.service'

@Component({
  selector: 'efp-privacy-policy-modal',
  templateUrl: './privacy-policy-modal.component.html',
  styleUrls: ['./privacy-policy-modal.component.scss'],
})
export class PrivacyPolicyModalComponent
  extends MultiStepModalComponent
  implements OnInit, OnDestroy
{
  @Input() canDismiss = false
  @Input() shouldShowCloudSaveAcceptance = false

  private destroy$ = new Subject<void>()

  public readonly countries = allCountries

  public gdprAccepted = false
  public dtdsAccepted = false

  public cloudSaveAccepted = false

  public index = 0
  public privacyUpdateSuccess = false
  public requestRunning = false

  public privacyPolicy?: PrivacyPolicyModel

  public gdprForm = new FormGroup({
    privacyType: new FormControl('', {
      validators: [Validators.required],
    }),
    country: new FormControl<Country>(Country.At, {
      validators: Validators.required,
      nonNullable: true,
    }),
  })

  public get isFormValid(): boolean {
    return this.gdprForm.valid
  }

  public get privacyTypeValid(): boolean {
    return this.gdprForm.get('privacyType')?.valid ?? false
  }

  public get countrySiteIndex(): number {
    return this.shouldShowCloudSaveAcceptance ? 1 : 0
  }

  public get gdprSiteIndex(): number {
    return this.shouldShowCloudSaveAcceptance ? 2 : 1
  }

  public get gdprUrl(): string | undefined {
    return this.privacyPolicy?.gdprUrl
  }

  public get dtdsUrl(): string | undefined {
    return this.privacyPolicy?.dtdsUrl
  }

  constructor(
    modalCtrl: ModalController,
    private readonly translate: Translation,
    private readonly loadingSpinner: LoadingSpinnerService,
    private readonly appSettingsRepository: AppSettingsRepository,
    private readonly errorPopupService: ErrorPopupService,
    private readonly privacyPolicyRepository: PrivacyPolicyRepository,
    private readonly authService: AuthenticationService,
    private readonly devicePreferencesService: DevicePreferencesService
  ) {
    super(modalCtrl)
  }

  async ngOnInit(): Promise<void> {
    const appSettings = await this.appSettingsRepository.getAppSettings()

    this.gdprForm.patchValue(
      {
        country: appSettings.country,
      },
      { emitEvent: false }
    )

    this.gdprForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (this.privacyPolicy?.country !== value.country && value.country) {
        this.privacyPolicy = undefined
        void this.privacyPolicyRepository
          .fetchByCountryId(value.country, appSettings.language)
          .then((privacyPolicy) => {
            this.privacyPolicy = privacyPolicy
          })
      }
    })

    await this.loadingSpinner.doWithLoadingSpinner(async (load) => {
      this.privacyPolicy = await this.privacyPolicyRepository.fetchByCountryId(
        appSettings.country,
        appSettings.language
      )
      await load.dismiss()
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  goToSlide(targetIndex: number): void {
    const isGoingBack = targetIndex < this.index
    if (isGoingBack) {
      this.index = targetIndex
      return
    }

    if (this.shouldShowCloudSaveAcceptance && !this.cloudSaveAccepted) {
      return
    }
    if (!this.privacyTypeValid || !this.privacyPolicy) {
      if (this.shouldShowCloudSaveAcceptance && this.index === 0 && targetIndex === 1) {
        this.index = targetIndex
      }
      return
    }

    this.index = targetIndex
  }

  setGDPR(event: CustomEvent): void {
    this.gdprAccepted = event.detail.checked
  }

  setDTDS(event: CustomEvent): void {
    this.dtdsAccepted = event.detail.checked
  }

  setCloudSave(event: CustomEvent): void {
    this.cloudSaveAccepted = event.detail.checked
  }

  public findModalTitle(): string {
    if (this.index === 0 && this.shouldShowCloudSaveAcceptance) {
      return this.translate.translate('LEGAL_MODAL.SITE_CLOUD_SAVE.TITLE')
    } else if (
      (this.index === 1 && this.shouldShowCloudSaveAcceptance) ||
      (this.index === 0 && !this.shouldShowCloudSaveAcceptance)
    ) {
      return this.translate.translate('LEGAL_MODAL.SITE_1.TITLE')
    } else {
      return this.translate.translate('LEGAL_MODAL.SITE_2.TITLE')
    }
  }

  async onSubmit(): Promise<void> {
    this.requestRunning = true
    await this.loadingSpinner.doWithLoadingSpinner(async (load) => {
      this.privacyUpdateSuccess = await this.sendAcceptPrivacy(load)
      if (this.privacyUpdateSuccess !== true) {
        const errMessage = this.translate.translate('LEGAL_MODAL.SEND_ERROR')
        await this.errorPopupService.showError(errMessage)
      } else {
        void this.closeModal()
      }
      this.requestRunning = false
    })
  }

  async sendAcceptPrivacy(loadingElement: HTMLIonLoadingElement): Promise<boolean> {
    const country = this.gdprForm.value.country
    const privacyType: AppUserType = this.gdprForm.value.privacyType as AppUserType
    if (!country || !privacyType) {
      return false
    }

    await this.appSettingsRepository.updateCountry(country, privacyType)
    if (this.shouldShowCloudSaveAcceptance) {
      await this.devicePreferencesService.setCloudSaveAccepted(true)
    }
    await loadingElement.dismiss()
    return true
  }

  public openProfilManagement(): void {
    void this.authService.openProfilManagement()
  }
}
