import { Injectable } from '@angular/core'
import { authConfigWeb } from '../../environments/environment'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  public get isOnWidgetHostname(): boolean {
    return window.location.hostname.includes('efp-widget')
  }

  public get isOnWidgetPath(): boolean {
    return this.router.url === '/widget'
  }

  constructor(private readonly router: Router) {}

  navigateInNewWebTab(path: string): void {
    const url = authConfigWeb.redirectUri + path
    window.open(url, '_blank')
  }
}
