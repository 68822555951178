// layout.ts
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { checkIfMobileLayout, checkIfPortraitOnly } from '../constants/layout'
import { Platform } from '@ionic/angular'

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private mobileLayoutSubject = new BehaviorSubject<boolean>(checkIfMobileLayout())

  constructor(private readonly platform: Platform) {
    const resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        const mobileLayout = checkIfMobileLayout()
        this.mobileLayoutSubject.next(mobileLayout)
      })
    })
    resizeObserver.observe(document.documentElement)
  }

  getMobileLayoutSize(): Observable<boolean> {
    return this.mobileLayoutSubject.asObservable()
  }

  getPortraitOnlySize(): boolean {
    return checkIfPortraitOnly(this.platform.width(), this.platform.height())
  }
}
