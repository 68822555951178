import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'efp-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() placeholderKey = 'Search'
  @Output() readonly searchTextChanged = new EventEmitter<string>()

  searchText = ''
  showClearButton = false

  constructor() {}

  ngOnInit(): void {
    this.emitSearchText()
  }

  onSearchTextChanged(): void {
    this.showClearButton = this.searchText.length > 0
    this.emitSearchText()
  }

  clearSearch(): void {
    this.searchText = ''
    this.showClearButton = false
    this.emitSearchText()
  }

  private emitSearchText(): void {
    this.searchTextChanged.emit(this.searchText)
  }
}
