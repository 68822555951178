import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'efp-toggle-cell',
  templateUrl: './toggle-cell.component.html',
  styleUrls: ['./toggle-cell.component.scss'],
})
export class ToggleCellComponent {
  @Input() iconName!: string
  @Input() title!: string
  @Input() checked!: boolean
  @Input() boldLabelStyle?: boolean = false

  @Output() readonly checkedChanged = new EventEmitter<boolean>()

  public toggle(): void {
    this.checked = !this.checked
    this.checkedChanged.emit(this.checked)
  }
}
