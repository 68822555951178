import {
  ApplicationRef,
  ComponentRef,
  Directive,
  EnvironmentInjector,
  HostListener,
  Input,
  Type,
  ViewContainerRef,
  createComponent,
  OnDestroy,
} from '@angular/core'
import { Capacitor } from '@capacitor/core'

@Directive({
  selector: '[efpHoverPortal]',
})
export class HoverPortalDirective implements OnDestroy {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private injector: EnvironmentInjector,
    private appRef: ApplicationRef
  ) {}

  ngOnDestroy(): void {
    this.onMouseLeave()
  }

  @Input() componentTemplate?: Type<unknown>
  @Input() inputsForComponentTemplate?: { [key: string]: unknown | undefined }[]
  private component?: ComponentRef<unknown>
  private offset = 16

  @HostListener('mouseenter') async onMouseEnter(): Promise<void> {
    await this.show()
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    if (this.component) {
      this.component.location.nativeElement.style.display = 'none'
      this.component = undefined
    }
  }

  setInputs(inputs: { [key: string]: unknown | undefined }[]): void {
    inputs.forEach((inputObj) => {
      Object.keys(inputObj).forEach((key) => {
        const value = inputObj[key]
        this.component?.setInput(key, value)
      })
    })
  }

  async show(): Promise<void> {
    if (Capacitor.isNativePlatform()) return
    if (!this.component) {
      this.create()
    }
  }

  private create(): void {
    if (!this.componentTemplate) {
      console.error('Component template not provided.')
      return
    }

    this.component = createComponent(this.componentTemplate, {
      environmentInjector: this.injector,
    })

    if (this.inputsForComponentTemplate) this.setInputs(this.inputsForComponentTemplate)
    this.setPosition()
    document.body.appendChild(this.component.location.nativeElement)
    this.appRef.attachView(this.component.hostView)
  }

  private setPosition(): void {
    if (!this.component) return
    const elementRect = this.viewContainerRef.element.nativeElement.getBoundingClientRect()
    this.component.location.nativeElement.style.left =
      elementRect.x + elementRect.width + this.offset + 'px'
    const containerRect = window.innerHeight
    const upperPart = containerRect * 0.6
    if (elementRect.y <= upperPart)
      this.component.location.nativeElement.style.top = elementRect.y + 'px'
    else
      this.component.location.nativeElement.style.bottom =
        containerRect - elementRect.y - elementRect.height + 'px'
  }
}
