<div class="searchbar-input-container">
  <input
    class="searchbar-input"
    [placeholder]="placeholderKey | translate"
    aria-label="search text"
    [(ngModel)]="searchText"
    (ngModelChange)="onSearchTextChanged()" />
  <ion-icon
    src="assets/icon/search.svg"
    *ngIf="!showClearButton; else clearButton"
    class="iconSize24"></ion-icon>
  <ng-template #clearButton>
    <ion-icon name="close-outline" class="iconSize24" (click)="clearSearch()"></ion-icon>
  </ng-template>
</div>
