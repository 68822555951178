<ion-toolbar>
  <div class="row" #scrollRow (scroll)="manageScrollbarFog()">
    <div class="shadow-overlay left" #shadowOverlayLeft></div>
    <ion-col class="block">
      <div class="flex scrollbar-container" #scrollbar>
        <ng-content></ng-content>
      </div>
    </ion-col>
    <div class="shadow-overlay right" #shadowOverlayRight></div>
  </div>
  <efp-safe-area-bottom *ngIf="useBottomSafeArea"></efp-safe-area-bottom>
</ion-toolbar>
