import { Component, Input, OnInit } from '@angular/core'
import { OnboardingHint } from '../../../../models/onboarding/onboarding-hint'

@Component({
  selector: 'efp-onboarding-hint-content',
  templateUrl: './onboarding-hint-content.component.html',
  styleUrls: ['./onboarding-hint-content.component.scss'],
})
export class OnboardingHintContentComponent implements OnInit {
  @Input() onboardingHint!: OnboardingHint
  public showSpinner = false

  ngOnInit(): void {
    if (this.onboardingHint.videoUrl && !this.isVideo()) {
      this.showSpinner = true
    }
  }

  public isVideo(): boolean {
    return this.onboardingHint.videoUrl?.endsWith('.mp4') ?? false
  }

  public loadStart(): void {
    this.showSpinner = true
  }

  public loadEnd(): void {
    this.showSpinner = false
  }
}
