/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Country } from '../models/country';
import { PrivacyPolicyModel } from '../models/privacy-policy-model';

@Injectable({ providedIn: 'root' })
export class PrivacyPolicyApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllPrivacyPolicies()` */
  static readonly GetAllPrivacyPoliciesPath = '/api/v1/privacy-policies';

  /**
   * Get all latest country privacy policies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPrivacyPolicies$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPrivacyPolicies$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PrivacyPolicyModel>>> {
    const rb = new RequestBuilder(this.rootUrl, PrivacyPolicyApiClient.GetAllPrivacyPoliciesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PrivacyPolicyModel>>;
      })
    );
  }

  /**
   * Get all latest country privacy policies.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPrivacyPolicies$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPrivacyPolicies$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<PrivacyPolicyModel>> {
    return this.getAllPrivacyPolicies$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PrivacyPolicyModel>>): Array<PrivacyPolicyModel> => r.body)
    );
  }

  /**
   * Get all latest country privacy policies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPrivacyPolicies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPrivacyPolicies$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PrivacyPolicyModel>>> {
    const rb = new RequestBuilder(this.rootUrl, PrivacyPolicyApiClient.GetAllPrivacyPoliciesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PrivacyPolicyModel>>;
      })
    );
  }

  /**
   * Get all latest country privacy policies.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPrivacyPolicies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPrivacyPolicies(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<PrivacyPolicyModel>> {
    return this.getAllPrivacyPolicies$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PrivacyPolicyModel>>): Array<PrivacyPolicyModel> => r.body)
    );
  }

  /** Path part for operation `getCountryPrivacyPolicy()` */
  static readonly GetCountryPrivacyPolicyPath = '/api/v1/privacy-policies/{country}/{language}';

  /**
   * Get privacy policy per country.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountryPrivacyPolicy$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountryPrivacyPolicy$Plain$Response(
    params: {
      country: Country;
      language: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PrivacyPolicyModel>> {
    const rb = new RequestBuilder(this.rootUrl, PrivacyPolicyApiClient.GetCountryPrivacyPolicyPath, 'get');
    if (params) {
      rb.path('country', params.country, {});
      rb.path('language', params.language, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PrivacyPolicyModel>;
      })
    );
  }

  /**
   * Get privacy policy per country.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCountryPrivacyPolicy$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountryPrivacyPolicy$Plain(
    params: {
      country: Country;
      language: string;
    },
    context?: HttpContext
  ): Observable<PrivacyPolicyModel> {
    return this.getCountryPrivacyPolicy$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrivacyPolicyModel>): PrivacyPolicyModel => r.body)
    );
  }

  /**
   * Get privacy policy per country.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountryPrivacyPolicy()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountryPrivacyPolicy$Response(
    params: {
      country: Country;
      language: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PrivacyPolicyModel>> {
    const rb = new RequestBuilder(this.rootUrl, PrivacyPolicyApiClient.GetCountryPrivacyPolicyPath, 'get');
    if (params) {
      rb.path('country', params.country, {});
      rb.path('language', params.language, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PrivacyPolicyModel>;
      })
    );
  }

  /**
   * Get privacy policy per country.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCountryPrivacyPolicy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountryPrivacyPolicy(
    params: {
      country: Country;
      language: string;
    },
    context?: HttpContext
  ): Observable<PrivacyPolicyModel> {
    return this.getCountryPrivacyPolicy$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrivacyPolicyModel>): PrivacyPolicyModel => r.body)
    );
  }

}
