import { Injectable } from '@angular/core'
import { Mesh, Outline, paperPointToPoint2D, PlanType } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { CreatePlanOutlineCommandParam, PlanType as ApiPlanType } from '../../generated/efp-api'
import { Plan } from '../models/plan'
import { AccessoryService } from '../pages/accessory/services/accessory.service'
import { Model } from '../pages/planner/model/Model'
import { SlabModel } from '../pages/planner/model/SlabModel'
import { CycleRepository } from '../repositories/cycle.repository'
import { PlanOutlineRepository } from '../repositories/plan-outline.repository'
import { PlanRepository } from '../repositories/plan.repository'

@Injectable({
  providedIn: 'root',
})
export class PlanMeshService {
  constructor(
    private readonly planOutlineRepository: PlanOutlineRepository,
    private readonly planRepository: PlanRepository,
    private readonly accessoryService: AccessoryService,
    private readonly cycleRepository: CycleRepository
  ) {}

  /**
   * Updates the mesh and plan outline for the given plan.
   * @param plan The plan for which to store the outline.
   * @param model The model holding the current plan mesh
   * @param reset if the plan calculation should be reset and all objects depending on the outline removed.
   * Defaults to true.
   */
  public async updatePlanSerializedMesh(
    plan: Plan,
    model: Model<Mesh>,
    reset: boolean = true
  ): Promise<void> {
    if (reset) {
      await Promise.all([
        this.accessoryService.removeAccessoriesForPlan(plan.id),
        this.cycleRepository.removeAllCycleBoundariesAndSymbolsByPlanId(plan.id),
      ])
    }

    await this.planOutlineRepository.removeAllByPlanId(plan.id)
    const outlines: CreatePlanOutlineCommandParam[] = []
    if (plan.buildingType === PlanType.SLAB) {
      outlines.push(
        ...this.getPlanOutline((model as SlabModel).createWallForSlab()).map((outline) => ({
          startX: outline.start.x,
          startY: outline.start.y,
          endX: outline.end.x,
          endY: outline.end.y,
          planId: plan.id,
          outlineType: ApiPlanType.Wall,
        }))
      )
    }
    outlines.push(
      ...this.getPlanOutline(model.mesh.generatePath()).map((outline) => ({
        startX: outline.start.x,
        startY: outline.start.y,
        endX: outline.end.x,
        endY: outline.end.y,
        planId: plan.id,
        outlineType: plan.buildingType === PlanType.SLAB ? ApiPlanType.Slab : ApiPlanType.Wall,
      }))
    )

    await Promise.all([
      this.planOutlineRepository.create(outlines),
      this.planRepository.updateSerializedMesh(plan.id, model.getSerializedMeshes()),
    ])
  }

  private getPlanOutline(path: paper.Item): Outline[] {
    const outline: Outline[] = []

    // More than one structure
    if (path.children !== undefined) {
      path.children.forEach((pathChild) => {
        outline.push(...this.getPlanOutline(pathChild))
      })
    }
    if (!(path instanceof paper.Path) || !path.curves) {
      return outline
    }

    const mergedCurves: paper.Curve[] = []
    path.curves.forEach((curve) => {
      const currentOutline: Outline = {
        start: paperPointToPoint2D(curve.point1),
        end: paperPointToPoint2D(curve.point2),
      }
      if (!mergedCurves.includes(curve)) {
        let current = curve
        while (
          current.next &&
          !mergedCurves.includes(current.next) &&
          current.isCollinear(current.next)
        ) {
          current = current.next
          mergedCurves.push(current)
          currentOutline.end = paperPointToPoint2D(current.point2)
        }

        while (
          current.previous &&
          !mergedCurves.includes(current.previous) &&
          current.isCollinear(current.previous)
        ) {
          current = current.previous
          mergedCurves.push(current)
          currentOutline.start = paperPointToPoint2D(current.point1)
        }

        outline.push(currentOutline)
      }
    })

    return outline
  }
}
