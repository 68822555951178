import { AppSettingsModel, AppUserType, Country } from '@efp/api'

export abstract class AppSettingDao {
  abstract updateLanguage(newLang: string): Promise<void>

  abstract updateCountry(newCountry: Country, appUserType: AppUserType): Promise<void>

  abstract updateSyncState(newState: boolean): Promise<void>

  abstract getAppSettings(): Promise<Readonly<AppSettingsModel>>

  abstract findLanguage(): Promise<string>
}
