import { Component, EventEmitter, Input, OnInit, Output, Type } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { ModalStyle } from '../../../models/modalStyle'

export enum ModalDismissAction {
  CANCEL = 'cancel',
  CONFIRM = 'confirm',
  CRITICAL_ACTION = 'critical_action',
}
//to use this component for all different modals we need to fully implement the ModalStyles for all different variants

@Component({
  selector: 'efp-simple-modal',
  templateUrl: './simple-modal.component.html',
  styleUrls: ['./simple-modal.component.scss'],
})
export class SimpleModalComponent implements OnInit {
  @Input() title!: string
  @Input() text?: string
  @Input() confirmButtonLabel?: string
  @Input() confirmButtonDisabled = false
  @Output() readonly confirm = new EventEmitter<void>()

  @Input() cancelButtonLabel?: string
  @Output() readonly cancel = new EventEmitter<void>()

  @Input() criticalActionButtonLabel?: string
  @Output() readonly criticalAction = new EventEmitter<void>()

  @Input() modalStyle = ModalStyle.NONE
  @Input() dismissable = true
  @Input() iconPath?: string

  @Input() dynamicComponent: Type<unknown> | null = null
  @Input() dynamicComponentProps: Record<string, unknown> | undefined

  public modalStyleEnum = ModalStyle
  public modalClass?: string
  public modalAction = ModalDismissAction

  constructor(public readonly modalCtrl: ModalController) {}

  ngOnInit(): void {
    if (!this.iconPath) this.iconPath = this.getModalIcon(this.modalStyle)
  }

  getModalIcon(style: ModalStyle): string | undefined {
    const assetPath = '/assets/icon/'

    switch (style) {
      case ModalStyle.INFO:
        return assetPath + 'ic_info.svg'
      case ModalStyle.SUCCESS:
        return assetPath + 'ic_success.svg'
      case ModalStyle.WARNING:
        return assetPath + 'ic_warning.svg'
      case ModalStyle.ERROR:
        return assetPath + 'ic_warning_red.svg'

      default:
        return undefined
    }
  }

  public async closeModal(): Promise<void> {
    if (this.dismissable) await this.modalCtrl.dismiss()
  }

  async dismissModalWithAction(action?: ModalDismissAction): Promise<void> {
    if (this.dismissable) {
      await this.modalCtrl.dismiss(null, action)
      return
    }

    if (action === ModalDismissAction.CANCEL) this.cancel.emit()
    else if (action === ModalDismissAction.CONFIRM) this.confirm.emit()
    else if (action === ModalDismissAction.CRITICAL_ACTION) this.criticalAction.emit()
  }
}
