import paper from 'paper/dist/paper-core'
import {
  degreesToRadians,
  isMultipleOf,
  calculateEdgeOutline,
  getClosestEdgesOnPoint,
  ANGLE_ERROR_TOLERANCE,
  SNAPPING_ERROR_TOLERANCE,
} from '../../utils'
import { MeshPoint } from '../mesh/MeshPoint'
import { Line } from './Line'
import { getOtherLinePosition, LinePosition } from './LinePosition'
import { getOtherLineSide, LineSide } from './LineSide'

export enum TransformationLengthDirection {
  evenly = 'evenly',
  toRight = 'toRight',
  toLeft = 'toLeft',
}

export enum TransformationThicknessDirection {
  evenly = 'evenly',
  insideOut = 'insideOut',
  outsideIn = 'outsideIn',
}

/**
 * This class describes walls or slabs.
 */
export class Edge {
  public isValid: boolean
  private _realLength: number | undefined

  public thicknessTransformationDirection: TransformationThicknessDirection | undefined
  public lengthTransformationDirection: TransformationLengthDirection | undefined

  public constructor(
    public startPoint: MeshPoint,
    public endPoint: MeshPoint,
    public thickness: number
  ) {
    this.isValid = true
  }

  public get realLength(): number | undefined {
    return this._realLength
  }

  public set realLength(length: number | undefined) {
    if (length && length < 0) {
      throw new Error('realLength must be positive!')
    }
    if (length && length === 0) {
      throw new Error('realLength must not be 0!')
    }
    this._realLength = length
  }

  public get startsFromLeft(): boolean {
    return this.startPoint.x < this.endPoint.x
  }

  public get startsFromBottom(): boolean {
    return this.startPoint.y > this.endPoint.y
  }

  public get isLeftOpen(): boolean {
    return (
      (this.startsFromLeft && this.startPoint.edges.size === 1) ||
      (!this.startsFromLeft && this.endPoint.edges.size === 1)
    )
  }

  public get isRightOpen(): boolean {
    return (
      (!this.startsFromLeft && this.startPoint.edges.size === 1) ||
      (this.startsFromLeft && this.endPoint.edges.size === 1)
    )
  }

  public get isVertical(): boolean {
    // eslint-disable-next-line no-bitwise
    return ~~this.startPoint.x === ~~this.endPoint.x
  }

  public get isBottomOpen(): boolean {
    return (
      (this.isVertical && this.startsFromBottom && this.startPoint.edges.size === 1) ||
      (!this.startsFromBottom && this.endPoint.edges.size === 1)
    )
  }

  public get isTopOpen(): boolean {
    return (
      (this.isVertical && this.startsFromBottom && this.endPoint.edges.size === 1) ||
      (!this.startsFromBottom && this.startPoint.edges.size === 1)
    )
  }

  /**
   * Returns true if the current edge is a T-wall
   * @param point if given, the edge is only checked on one side (where the point is provided)
   */
  public isTWall(point?: MeshPoint): boolean {
    if (point) {
      const clone = point.clone()
      clone.edges.delete(this)
      return clone.canMergeEdges()
    }

    const startClone = this.startPoint.clone()
    const endClone = this.endPoint.clone()
    startClone.edges.delete(this)
    endClone.edges.delete(this)

    return startClone.canMergeEdges() || endClone.canMergeEdges()
  }

  public isParallelTo(edge: Edge): boolean {
    const direction = this.getDirection().normalize()
    const otherDirection = edge.getDirection().normalize()
    return (
      direction.isClose(otherDirection, SNAPPING_ERROR_TOLERANCE) ||
      direction.isClose(otherDirection.multiply(-1), SNAPPING_ERROR_TOLERANCE)
    )
  }

  public isPerpendicular(edge: Edge): boolean {
    const direction = this.getDirection().normalize()
    const otherDirection = edge.getDirection().normalize()
    return (
      direction.isClose(otherDirection.rotate(90), SNAPPING_ERROR_TOLERANCE) ||
      direction.isClose(otherDirection.rotate(-90), SNAPPING_ERROR_TOLERANCE)
    )
  }

  public length(): number {
    return this._realLength ?? this.startPoint.subtract(this.endPoint).length
  }

  public getVectorForResize(
    length: number,
    linePosition: LinePosition = LinePosition.END
  ): paper.Point | undefined {
    if (Math.abs(length - this.length()) < Number.EPSILON) {
      return undefined
    }

    const currentLength = this.startPoint.subtract(this.endPoint).length
    const diffToRealLength = this.length() - currentLength

    const direction = this.getDirection(getOtherLinePosition(linePosition)).normalize()

    return direction.multiply(length - currentLength - diffToRealLength)
  }

  public getCenterPoint(): paper.Point {
    const centerX = (this.startPoint.x + this.endPoint.x) / 2
    const centerY = (this.startPoint.y + this.endPoint.y) / 2

    return new paper.Point(centerX, centerY)
  }

  public getConnectedPoint(edge: Edge): MeshPoint | undefined {
    if (this.isSameEdge(edge)) {
      return undefined
    }

    if (this.startPoint === edge.startPoint || this.startPoint === edge.endPoint) {
      return this.startPoint
    }

    if (this.endPoint === edge.startPoint || this.endPoint === edge.endPoint) {
      return this.endPoint
    }
    return undefined
  }

  /**
   * Checks if there is another edge that has a very close, but disconnected mesh point to this edge
   * @param edge the other edge
   * @param toleranceParam manual tolerance for the distance calculation
   * @returns two mesh points in an array or undefined (if no disconnected points are found)
   * the first one is a meshpoint of this edge, the second one is the close disconnected point of the other edge
   * see https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/7302/T-walls-with-different-thickness?anchor=model-modifications---wall-width-change-%26-cycle-calculations for more details
   */
  public getCloseDisconnectedPoints(
    edge: Edge,
    toleranceParam: number | null = null
  ): MeshPoint[] | undefined {
    if (edge.isSameEdge(this)) {
      return undefined
    }

    // if the edge is connected to the other edge, there are no close disconnected points possible
    if (edge.getConnectedPoint(this)) {
      return undefined
    }

    let tolerance = toleranceParam
    if (tolerance == null) {
      // we are using a 1.5 (approximately the square root of 2) multiplier because the two points aren't necessarily on parallel edges (T-walls)
      tolerance = edge.thickness > this.thickness ? this.thickness * 1.5 : edge.thickness * 1.5
    }

    if (this.startPoint.isClose(edge.startPoint, tolerance)) {
      return [this.startPoint, edge.startPoint]
    }

    if (this.startPoint.isClose(edge.endPoint, tolerance)) {
      return [this.startPoint, edge.endPoint]
    }

    if (this.endPoint.isClose(edge.startPoint, tolerance)) {
      return [this.endPoint, edge.startPoint]
    }

    if (this.endPoint.isClose(edge.endPoint, tolerance)) {
      return [this.endPoint, edge.endPoint]
    }

    return undefined
  }

  public getAngle(edge: Edge): number | undefined {
    const connectedPoint = this.getConnectedPoint(edge)
    let thisVector: paper.Point | undefined
    let otherVector: paper.Point | undefined

    if (!connectedPoint) {
      const closeDisconnectedPoints = this.getCloseDisconnectedPoints(edge)
      if (closeDisconnectedPoints) {
        thisVector = closeDisconnectedPoints[0].subtract(
          this.getOtherPoint(closeDisconnectedPoints[0])
        )
        otherVector = closeDisconnectedPoints[1].subtract(
          edge.getOtherPoint(closeDisconnectedPoints[1])
        )
      }
    } else {
      thisVector = connectedPoint.subtract(this.getOtherPoint(connectedPoint))
      otherVector = connectedPoint.subtract(edge.getOtherPoint(connectedPoint))
    }

    if (thisVector && otherVector) {
      return thisVector.getDirectedAngle(otherVector)
    }

    return undefined
  }

  public calculateAngleFromOrigin(fromStartPoint: boolean): number {
    const startPoint = fromStartPoint ? this.startPoint : this.endPoint
    const endPoint = fromStartPoint ? this.endPoint : this.startPoint

    let angle = endPoint.subtract(startPoint).angle
    if (angle < 0) {
      angle += 360
    }

    return angle
  }

  // documentation: https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/4283/Technical-Documentation?anchor=getangleoffset-%7C-getouterintersectionoffset
  public getAngleOffset(otherEdge: Edge): number {
    const angle = this.getAngle(otherEdge)

    if (angle == null || isMultipleOf(angle, 180)) {
      return 0
    }

    const alpha = degreesToRadians(angle - 90)
    return Math.abs((Math.cos(alpha) * this.thickness) / 2)
  }

  // documentation: https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/4283/Technical-Documentation?anchor=getangleoffset-%7C-getouterintersectionoffset
  public getOuterIntersectionOffset(otherEdge: Edge): number {
    const angle = Math.abs(this.getAngle(otherEdge) ?? 0)

    if (angle == null || isMultipleOf(angle, 180)) {
      return 0
    }

    const angleOffset = this.getAngleOffset(otherEdge)
    const heightOffset = Math.tan(degreesToRadians(180 - angle - 90)) * angleOffset
    const height = heightOffset + otherEdge.thickness / 2
    return height / Math.tan(degreesToRadians(angle))
  }

  public getOtherPoint(point: MeshPoint): MeshPoint {
    return this.startPoint.getDistance(point) < this.endPoint.getDistance(point)
      ? this.endPoint
      : this.startPoint
  }

  public getLineOnSide(side: LineSide): Line {
    return calculateEdgeOutline(this)[side]
  }

  getNeighbours(): Edge[] {
    return Array.from(this.startPoint.edges)
      .concat(Array.from(this.endPoint.edges))
      .filter((it) => it !== this)
  }

  /**
   * Gets all neighbors of an edge recursively - returning the immediately connected edges as well as the neighbors of the immediate neighbors
   * The process continues until all the direct or indirect neighbors of an edge are added
   * Close disconnected points for the special case with different wall width are also considered
   * @param allEdges the set of edges that are searched for neighbors
   * @param visited already visited edges (ensures no endless loop is created)
   * @param excludedPoints points that should not be considered when searching for close disconnected points for the special case (used for cycle boundaries)
   */
  getAllRecursiveNeighbours(
    allEdges: Set<Edge>,
    visited: Set<Edge>,
    excludedPoints: paper.Point[]
  ): Set<Edge> {
    const neighbors = new Set<Edge>()
    const connectedNeighbors = new Set<Edge>(this.getNeighbours())

    // finding neighbouring disconnected edges for edge case with different wall widths
    const closeDisconnectedNeighbors = this.getCloseDisconnectedNeighbors(allEdges, excludedPoints)

    connectedNeighbors.forEach((e) => neighbors.add(e))
    closeDisconnectedNeighbors.forEach((e) => neighbors.add(e))
    visited.add(this)

    this.getNeighbours()
      .concat(closeDisconnectedNeighbors)
      .filter((n) => !visited.has(n))
      .map((neighbour) => neighbour.getAllRecursiveNeighbours(allEdges, visited, excludedPoints))
      .forEach((ns) => ns.forEach((n) => neighbors.add(n)))

    return neighbors
  }

  /**
   * finding neighbouring disconnected edges for edge case with different wall widths
   * @link https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/7302/T-walls-with-different-thickness?anchor=getclosedisconnectedpoints
   * @param allEdges the edges to be searched for close disconnected neighbors
   * @param excludedPoints points that are not valid for the special case (parameter used to ensure cycle boundaries are not registered as close disconnected walls on the cycle page)
   */
  public getCloseDisconnectedNeighbors(allEdges: Set<Edge>, excludedPoints: paper.Point[]): Edge[] {
    return [...allEdges].filter((other) => {
      if (this.thickness === other.thickness) {
        return false
      }

      const disconnectedPoints = other.getCloseDisconnectedPoints(this)
      if (!disconnectedPoints) {
        return false
      }

      // the excluded disconnected locations are not considered
      if (
        disconnectedPoints.some((point) =>
          excludedPoints.some((otherPoint) => otherPoint.isClose(point, SNAPPING_ERROR_TOLERANCE))
        )
      ) {
        return false
      }

      const distance = disconnectedPoints[0].getDistance(disconnectedPoints[1])
      return distance > SNAPPING_ERROR_TOLERANCE
    })
  }

  public getNeighborOnSide(side: LineSide, position: LinePosition): Edge {
    const point = this.getPoint(position)
    const otherPoint = this.getOtherPoint(point)

    const connectedNeighbors = getClosestEdgesOnPoint(point, otherPoint, this)

    return connectedNeighbors[position === LinePosition.START ? side : getOtherLineSide(side)]
  }

  public getPosition(point: MeshPoint): LinePosition | undefined {
    if (point === this.startPoint) {
      return LinePosition.START
    } else if (point === this.endPoint) {
      return LinePosition.END
    } else {
      return undefined
    }
  }

  public getPoint(position: LinePosition): MeshPoint {
    switch (position) {
      case LinePosition.START:
        return this.startPoint
      case LinePosition.END:
        return this.endPoint
    }
  }

  public getDirection(fromPositon: LinePosition = LinePosition.START): paper.Point {
    return this.endPoint
      .subtract(this.startPoint)
      .multiply(fromPositon === LinePosition.END ? -1 : 1)
  }

  public getOutlinePath(): paper.Path {
    const outlines = calculateEdgeOutline(this)

    const outlinePath = new paper.Path([
      outlines[LineSide.LEFT].start,
      outlines[LineSide.LEFT].end,
      outlines[LineSide.RIGHT].end,
      outlines[LineSide.RIGHT].start,
    ])
    outlinePath.closed = true

    return outlinePath
  }

  public hasPoint(point: MeshPoint | paper.Point): boolean {
    if (point instanceof MeshPoint) {
      return this.startPoint === point || this.endPoint === point
    } else {
      return this.startPoint.equals(point) || this.endPoint.equals(point)
    }
  }

  isSameEdge(other: Edge): boolean {
    return (
      this.startPoint.isClose(other.startPoint, SNAPPING_ERROR_TOLERANCE) &&
      this.endPoint.isClose(other.endPoint, SNAPPING_ERROR_TOLERANCE)
    )
  }

  canMerge(other: Edge): boolean {
    return (
      (this.hasPoint(other.startPoint) || this.hasPoint(other.endPoint)) &&
      this.getDirection().isCollinear(other.getDirection())
    )
  }

  isInEdgeList(others: Edge[]): boolean {
    return others.some((other) => this.isSameEdge(other))
  }

  hasNeighbour(otherEdge: Edge): boolean {
    return otherEdge.hasPoint(this.startPoint) || otherEdge.hasPoint(this.endPoint)
  }

  changeLength(length: number, lengthDirection: TransformationLengthDirection): void {
    let linePosition: LinePosition = LinePosition.END
    // If the edge is vertical, toRight should expand the edge to the top
    if (lengthDirection === TransformationLengthDirection.toLeft) {
      linePosition =
        (this.startsFromLeft && !this.isVertical) || (this.isVertical && this.startsFromBottom)
          ? LinePosition.START
          : LinePosition.END
    } else if (lengthDirection === TransformationLengthDirection.toRight) {
      linePosition =
        (!this.startsFromLeft && !this.isVertical) || (this.isVertical && !this.startsFromBottom)
          ? LinePosition.START
          : LinePosition.END
    }

    const pointToMove = this.getPoint(linePosition)
    const moveVector = this.getVectorForResize(length, linePosition)
    if (moveVector) {
      // If this edge has no neighbours, or has neighbours on both sides, move both points
      if (lengthDirection === TransformationLengthDirection.evenly) {
        const startMoveVector = this.startPoint
          .subtract(this.endPoint)
          .normalize(moveVector.length / 2)
          .multiply(length < this.length() ? -1 : 1)

        this.movePointOfEdge(this.startPoint, startMoveVector)
        this.movePointOfEdge(this.endPoint, startMoveVector.multiply(-1))
      } else {
        this.movePointOfEdge(pointToMove, moveVector)
      }
    }
  }

  private movePointOfEdge(
    pointToMove: MeshPoint,
    moveVector: paper.Point,
    alreadyMovedParam?: Set<MeshPoint>,
    originalMoveVector?: paper.Point
  ): void {
    let alreadyMoved = alreadyMovedParam
    if (!alreadyMoved) {
      const unmovedPoint = this.getOtherPoint(pointToMove)
      const unmovedNeighbours = Array.from(unmovedPoint.edges)
        .map((e) => [e.startPoint, e.endPoint])
        .flat()
      alreadyMoved = new Set<MeshPoint>(unmovedNeighbours)
    } else {
      alreadyMoved.add(pointToMove)
    }

    Array.from(pointToMove.edges).map((e) => {
      if (e !== this) {
        const otherPoint = e.getOtherPoint(pointToMove)
        // recursively moving all connected points
        if (!alreadyMoved?.has(otherPoint)) {
          if (!originalMoveVector) {
            e.movePointOfEdge(
              otherPoint,
              moveVector,
              alreadyMoved,
              pointToMove.subtract(this.getOtherPoint(pointToMove))
            )
          } else if (
            !pointToMove
              .subtract(otherPoint)
              .normalize()
              .isClose(originalMoveVector.normalize(), ANGLE_ERROR_TOLERANCE)
          ) {
            e.movePointOfEdge(otherPoint, moveVector, alreadyMoved, originalMoveVector)
          }
        }
      }
    })

    const movedPoint = pointToMove.add(moveVector)
    pointToMove.set(movedPoint.x, movedPoint.y)
  }
}
