import { Injectable, NgZone } from '@angular/core'
import { AuthenticationRepository } from './authentication-repository.service'
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source'
import { OAuthService } from 'angular-oauth2-oidc'
import { CacheService } from '../services/cache/cache.service'
import { environment } from '../../environments/environment'
import { EventsApiClient } from '../../generated/efp-api'

class RetriableError extends Error {}
class FatalError extends Error {}

export interface LastActiveCacheChangedEvent {
  lastActiveCacheId: string
  lastChangedAt: Date
}

@Injectable({
  providedIn: 'root',
})
export class EventRepository {
  private abortController: AbortController | null = null
  private eventSource: EventSource | null = null

  constructor(
    private readonly authenticationRepository: AuthenticationRepository,
    private readonly oauthService: OAuthService,
    private readonly cacheService: CacheService,
    private readonly zone: NgZone
  ) {
    authenticationRepository.authenticated$.subscribe((authenticated: boolean) => {
      if (authenticated) {
        void this.connectToServerSentEvents()
      } else {
        this.close()
      }
    })
  }

  public async connectToServerSentEvents(): Promise<void> {
    const currentCacheId = this.cacheService.localCacheId
    const url =
      environment.backendUrl +
      EventsApiClient.GetCacheEventsPath.replace('{cacheId}', currentCacheId)

    this.abortController = new AbortController()
    const cacheService = this.cacheService
    const zone = this.zone

    return fetchEventSource(url, {
      method: 'GET',
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${this.oauthService.getAccessToken()}`,
      },
      signal: this.abortController.signal,
      async onopen(response) {
        if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
          return // everything's good
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          // client-side errors are usually non-retriable:
          throw new FatalError()
        } else {
          throw new RetriableError()
        }
      },
      onmessage(msg) {
        if (msg.event === 'LastActiveCacheIdChanged' && msg.data) {
          const event = JSON.parse(msg.data) as LastActiveCacheChangedEvent
          zone.run(() => {
            cacheService.setLastActiveCache(event)
          })
        }

        // if the server emits an error message, throw an exception
        // so it gets handled by the onerror callback below:
        if (msg.event === 'FatalError') {
          throw new FatalError(msg.data)
        }
      },
      onclose() {
        // if the server closes the connection unexpectedly, retry:
        throw new RetriableError()
      },
      onerror(err) {
        if (err instanceof FatalError) {
          throw err // rethrow to stop the operation
        } else {
          // do nothing to automatically retry. You can also
          // return a specific retry interval here.
        }
      },
    })
  }

  private close(): void {
    if (!this.eventSource) {
      return
    }

    this.eventSource.close()
    this.eventSource = null
  }
}
