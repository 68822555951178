import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, Input, OnInit } from '@angular/core'
import { LayoutService } from '../../../services/layout.service'
import { checkIfMobileLayout } from '../../../constants/layout'

@Component({
  selector: 'efp-topbar-filter-container',
  templateUrl: 'efp-topbar-filter-container.component.html',
  styleUrls: ['./efp-topbar-filter-container.component.scss'],
  animations: [
    trigger('openCloseCollapse', [
      state('closed', style({ height: '0' })),
      state('open', style({ height: '*' })),
      transition('open => closed', [animate('0.4s ease-out')]),
      transition('closed => open', [animate('0.4s ease-out')]),
    ]),
  ],
})
export class EfpTopbarFilterContainerComponent implements OnInit {
  public isCollapsed = checkIfMobileLayout()
  public showOnMobile$ = this.layoutService.getMobileLayoutSize()
  public showOnMobile?: boolean

  @Input() defaultOpened = false

  constructor(private readonly layoutService: LayoutService) {}

  toggle(): void {
    this.isCollapsed = !this.isCollapsed
  }

  ngOnInit(): void {
    this.isCollapsed = !this.defaultOpened

    this.showOnMobile$.subscribe((val) => {
      this.showOnMobile = val
    })
  }
}
